<template>
  <div class="w-full">
    <div class="p-4 border rounded container m-auto">
      <div class="xl:w-1/4 md:w-1/2">
        <FormulateForm name="emailPreferences"  @submit="onSubmit" class="formulate-input-element-decorator">
          <FormulateInput
            type="checkbox"
            label="Email Preferences"
            v-model="notifications"
            :options="webProfile.notifications"
            :style="{color: theme.primaryColor}"
            class="custom-options checkbox"
            help="Select email preferences that you want to receive."
          />
          <div class="flex items-center justify-between">
            <StyledSubmit :backgroundColor="theme.secondaryColor">
              <FormulateInput
                v-if="!loading"
                type="submit"
                label="Submit"
                input-class="btn btn-primary"
                :disabled="loading"
              />
              <div v-if="loading" class="text-center">
                <ui-spinner class="m-auto"></ui-spinner>
              </div>
            </StyledSubmit>
          </div>
        </FormulateForm>
        <div>
          <span class="text-sm"><b>Receiving Email: </b> {{ email }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { StyledSubmit } from "@/styled-components";

export default {
  name: 'EmailPreferences',
  components: { StyledSubmit },

  data () {
    return {
      webProfile: {},
      notifications: [],
      uid: '',
      loading: false,
      email: ''
    }
  },

  async mounted () {
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    this.uid = me.uid;
    this.email = me?.email || 'N/A';
    const webProfile = await this.$store.dispatch(
      "rolesandrespo/getWebProfileByUID", this.uid
    );
    this.webProfile = this.formatItem(webProfile);
    this.$emit("updateLoading", false);
  },

  methods: {
    async onSubmit () {
      this.loading = true;

      let finalNotifications = [];

      for(let notification of this.webProfile.notifications) {
        let isActive = false;
        if(this.notifications.includes(notification.value)) {
          isActive = true;
        }
        const item = {
          notifType: notification.value,
          isActive: isActive
        }
        finalNotifications.push(item);
      }

      const payLoad = {
        id: this.webProfile.id,
        notifications: finalNotifications
      }

      try {
        let res = await this.$store.dispatch(`rolesandrespo/updateEmailPreferences`, payLoad);
        if (res.success) {
          this.toast('success', 'Email Preferences set successfully!');
          this.loading = false;
        }
      } catch (error) {
        this.toast('error', error?.message);
        this.loading = false;
      }
      this.loading = false;
    },

    formatItem (item) {
      let notifications = [];
      if (item['notifications']) {
        item['notifications'].map(notification => {
          let notificationName = '';
          if (notification['notifType']) {
            if(notification['notifType'] === 'fitnessDecleration') { //TODO Temporary fix until back-up resume
              notificationName = "Fitness Declaration"
            } else {
              const notifType = notification['notifType'].replace(/([A-Z])/g, " $1");
              notificationName = notifType.charAt(0).toUpperCase() + notifType.slice(1);
            }
          }
          const formattedNotification = {
            value: notification['notifType'] || '',
            label: notificationName,
          }
          notifications.push(formattedNotification);

          //Setting selected preferences
          if(notification['isActive']) {
            this.notifications.push(notification['notifType']);
          }
        });
      }
      return {
        id: item['_id'],
        notifications: notifications,
      };
    },

    toast (state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },

  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>