<template>
  <div
    :data-type="context.type"
    :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
  >
    <list-select
      ref="selectRef"
      :options="options"
      :context="context"
      :isLoading="isLoading"
      noResultText="No users found."
    >
      <template slot="option" slot-scope="option">
        <div class="text-sm">
          <h3 class="m-0 font-semibold">{{ option.label }}</h3>
          <em class="text-xs">{{ option.email }}</em>
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected text-sm">
          {{ option.label }}
        </div>
      </template>
    </list-select>
  </div>
</template>
<script>
import ListSelect from "@/components/commons/ui/list-select/ListSelect";

const WebProfileSelect = {
  name: "WebProfileSelect",
  components: {
    ListSelect,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.onFetch();
  },
  data: () => ({
    users: [],
    options: [],
    isLoading: false,
  }),
  methods: {
    async onFetch() {
      this.options = [];
      this.isLoading = true;

      const users = await this.$store.dispatch(`rolesandrespo/getUsersV2`);
      if (users) {
        this.users = users;
        this.options = this.formatResult(this.users);
      }
      this.isLoading = false;
    },

    formatResult(users) {
      return users.map((user) => {
        return {
          label: user.userName,
          value: user._id,
          email: user.email,
        };
      });
    },
  },
};

export const VueFormulateWebProfileSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      WebProfileSelect,
    },
    library: {
      "web-profile-select": {
        classification: "select",
        component: "WebProfileSelect",
      },
    },
  });
};
export default WebProfileSelect;
</script>
