<template>
  <div class="w-full mt-10 flex">
    <div class="flex-1 mx-10">
      <FormulateForm v-model="themeForm" @submit="updateTheme">
        <FormulateInput
          type="text"
          name="themeName"
          label="Custom Theme Name"
          validation="^required"
          placeholder="Midnight Blue"
          :validation-messages="{
            matches: 'Name is required',
          }"
        />
        <!-- Primary -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Primary Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.primaryColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Secondary -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Secondary Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.secondaryColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Accent -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Accent Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.accentColor"
            model="hex"
            menuPosition="right"
          />
        </div>
        <!-- Error -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Error Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.errorColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Info -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Info Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.infoColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Success -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Success Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.successColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Warning -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Warning Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.warningColor"
            model="hex"
            menuPosition="right"
          />
        </div>
        <div>
          <FormulateInput
            v-if="hasTheme"
            type="submit"
            label="Update Theme"
            input-class="btn btn-primary"
          />
          <FormulateInput
            v-if="!hasTheme"
            type="submit"
            label="Create Theme"
            input-class="btn btn-primary"
          />
        </div>
      </FormulateForm>
    </div>
    <div class="d-flex flex-1 mx-1">
      <!-- Alerts -->
      <div class="pb-3">
        <!-- info -->
        <div
          class="bg-teal border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mb-3"
          role="alert"
          :style="{
            backgroundColor: colorScheme.infoColor,
            borderColor: this.LightenDarkenColor(colorScheme.infoColor, -50),
          }"
        >
          <div class="flex">
            <div class="py-1">
              <svg
                class="fill-current h-6 w-6 text-teal-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path
                  d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"
                />
              </svg>
            </div>
            <div>
              <p class="font-bold">Our privacy policy has changed</p>
              <p class="text-sm">
                Make sure you know how these changes affect you.
              </p>
            </div>
          </div>
        </div>
        <!-- Warning -->
        <div
          class="bg-teal border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mb-3"
          role="alert"
          :style="{
            backgroundColor: colorScheme.warningColor,
            borderColor: this.LightenDarkenColor(colorScheme.warningColor, -50),
          }"
        >
          <div class="flex">
            <div class="py-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
                />
              </svg>
            </div>
            <div>
              <p class="font-bold">Be careful changing driver settings</p>
              <p class="text-sm">
                Make sure you know how these changes affect you.
              </p>
            </div>
          </div>
        </div>
        <!-- Error -->
        <div
          class="bg-teal border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mb-3"
          role="alert"
          :style="{
            backgroundColor: colorScheme.errorColor,
            borderColor: this.LightenDarkenColor(colorScheme.errorColor, -50),
          }"
        >
          <div class="flex">
            <div class="py-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
                />
              </svg>
            </div>
            <div>
              <p class="font-bold">Ops something went wrong</p>
              <p class="text-sm">Please try again later.</p>
            </div>
          </div>
        </div>
        <!-- Success -->
        <div
          class="bg-teal border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md mb-3"
          role="alert"
          :style="{
            backgroundColor: colorScheme.successColor,
            borderColor: this.LightenDarkenColor(colorScheme.successColor, -50),
          }"
        >
          <div class="flex">
            <div class="py-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                />
              </svg>
            </div>
            <div>
              <p class="font-bold">Cheers life is good</p>
              <p class="text-sm">Keep at it yeah.</p>
            </div>
          </div>
        </div>
      </div>
      <!-- Table -->

      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table
          class="w-full text-sm text-left text-blue-100 dark:text-blue-100"
          :style="{
            backgroundColor: colorScheme.accentColor,
            borderColor: this.LightenDarkenColor(colorScheme.accentColor, -50),
          }"
        >
          <thead
            class="text-xs text-white uppercase bg-blue-600 dark:text-white"
          >
            <tr>
              <th scope="col" class="px-6 py-3">Full Name</th>
              <th scope="col" class="px-6 py-3">Email Address</th>
              <th scope="col" class="px-6 py-3">Licence</th>
              <th scope="col" class="px-6 py-3">Enable/Disable</th>
              <th scope="col" class="px-6 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-blue-500 border-b border-blue-400">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-blue-50 whitespace-nowrap dark:text-blue-100"
              >
                John Doe
              </th>
              <td class="px-6 py-4">johndoe@gmail.com</td>
              <td class="px-6 py-4">ABCD6789</td>
              <td class="px-6 py-4">Enabled</td>
              <td class="px-6 py-4">
                <a href="#" class="font-medium text-white hover:underline"
                  >Edit</a
                >
              </td>
            </tr>
            <tr class="bg-blue-500 border-b border-blue-400">
              <th
                scope="row"
                class="px-6 py-4 font-medium text-blue-50 whitespace-nowrap dark:text-blue-100"
              >
                Alice Work
              </th>
              <td class="px-6 py-4">alicebob@gmail.com</td>
              <td class="px-6 py-4">7890123ABC</td>
              <td class="px-6 py-4">Enabled</td>
              <td class="px-6 py-4">
                <a href="#" class="font-medium text-white hover:underline"
                  >Edit</a
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- Buttons -->
      <div class="d-flex mt-3">
        <button
          type="button"
          class="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800"
          :style="{
            borderColor: this.LightenDarkenColor(
              colorScheme.secondaryColor,
              -50
            ),
          }"
        >
          Secondary
        </button>
        <button
          type="button"
          class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          :style="{
            backgroundColor: colorScheme.primaryColor,
            borderColor: this.LightenDarkenColor(colorScheme.primaryColor, -50),
          }"
        >
          Primary
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Verte from "verte";
import "verte/dist/verte.css";
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "Theme-Configurator",
  components: { Verte },
  data() {
    return {
      themeForm: { themeName: "", isActive: "" },
      hasTheme: false,
      colorScheme: {
        primaryColor: "",
        secondaryColor: "",
        accentColor: "",
        errorColor: "",
        infoColor: "",
        successColor: "",
        warningColor: "",
      },
    };
  },
  async mounted() {
    this.accountDetails = await this.$store.getters["account/me"];
    const themeOptions = await this.$store.dispatch(
      "theme/getUserThemes",
      this.accountDetails.uid
    );
    if (themeOptions) {
      this.hasTheme = true;
      const themeData = themeOptions[0].themeData;
      this.themeForm.themeName = themeOptions[0].themeName;
      this.colorScheme = themeData;
    } else {
      alert("No theme found");
      //set default logmaster colors
      this.colorScheme = {
        primaryColor: "#2972a4",
        secondaryColor: "#f17226",
        accentColor: "#78a5c5",
        errorColor: "#EF5350",
        infoColor: "#81D4FA",
        successColor: "#81C784",
        warningColor: "#FDD835",
      };
    }
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    ...mapMutations("theme", {}),
  },
  methods: {
    onCloseDynamicTheme() {
      this.$store.commit("theme/updateEnableDynamicTheme", false);
    },
    async updateTheme() {
      this.accountDetails = this.$store.getters["account/me"];
      const { primaryColor, secondaryColor, accentColor, successColor } =
        this.colorScheme;

      const theme = {
        accountId: this.accountDetails.uid,
        themeName: this.themeForm.themeName,
        themeData: this.colorScheme,
        isActive: true,
        themeColors: [primaryColor, secondaryColor, accentColor, successColor],
      };

      await this.$store.dispatch("theme/createTheme", theme);
      this.$store.commit("theme/updateActiveTheme", theme);
    },
    LightenDarkenColor(col, amt) {
      var usePound = false;

      if (col[0] == "#") {
        col = col.slice(1);
        usePound = true;
      }

      var num = parseInt(col, 16);

      var r = (num >> 16) + amt;

      if (r > 255) r = 255;
      else if (r < 0) r = 0;

      var b = ((num >> 8) & 0x00ff) + amt;

      if (b > 255) b = 255;
      else if (b < 0) b = 0;

      var g = (num & 0x0000ff) + amt;

      if (g > 255) g = 255;
      else if (g < 0) g = 0;

      return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
  },
};
</script>
