<template>
  <div>
    <div v-if="readReports"> 
      <div class="flex flex-col h-full">
        <div class="p-5 bg-white shadow-md rounded overflow-hidden relative compliance-container">
          <div class="mb-3 pb-4 flex justify-between items-center w-full border-b border-gray-300">
            <div class="flex items-center">
              <div class="mr-3" style="width: 13.5rem;">
                <div class="block font-bold text-xs text-grey-500 mb-1">Driver</div>
                <FormulateInput
                  name="selectedDriver"
                  type="driver-select"
                  placeholder="Select a driver"
                  style="margin-bottom: 0px"
                  :getOptionKey="option => option.driver.udi"
                  :getOptionLabel="option => option.driver.driverName"
                  @selected="selectedDriver = $event"
                  :filterable="false"
                  :clearable="false"
                  :isIncludeDelinked="true"
                  validation="required"
                />
              </div>
              <div class="mr-3" style="width: 13.5rem;">
                <div class="block font-bold text-xs text-grey-500 mb-1">Date of Report</div>
                <DatePicker 
                  ref="childMainDatePicker" 
                  @selected="selectedDate = $event"
                  :date="selectedDate" 
                  :selectedDriver="selectedDriver"
                  :displayBreach="true"
                  :displayEvent="true"
                  :disabledBefore="disabledBefore" 
                  :disabledAfter="disabledAfter"
                  :clearable="false"
                />
              </div>
            </div>
            <div>
              <div class="">&nbsp;</div>
              <button 
                :disabled="!selectedDriver" 
                :class="{ 'cursor-not-allowed': !selectedDriver }"
                class="btn btn-primary flex items-center" 
                @click.prevent="onGetReport" 
                style="height: 38px;" 
                :style="{backgroundColor: theme.secondaryColor}"
              >
                Get Report
              </button>
            </div>
          </div>

          <div v-if="isActive" class="flex w-full items-center driver-info-container">
            <Compliance 
              ref="childCompliance"
              :selectedDate="selectedDate"
              :selectedDriver="selectedDriver"
              :disabledBefore="disabledBefore"
              :disabledAfter="disabledAfter" 
              :selectedDateRange="selectedDateRange"
              @sendReportloading="sendReportloading = $event"
              @updateActiveTab="activeTab = $event"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!readReports && hasRole" class="w-full min-h-full text-center mt-5">
      <h1>Not authorized to view reports. Please contact your business admin.</h1>
    </div>
    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content == 'new'">
        <div class="mb-16">
            <div v-if="!sendReportloading">
                <FormulateForm v-model="sendReportModel" @submit="onSubmitSendReport">
                    <div class="items-center justify-between">
                        <FormulateInput
                            type="email"
                            name="email"
                            label="Email address"
                            validation="required|email"
                            @input="handleStritEmailCase($event)"
                        />                
                        <div>
                            <label class="font-medium text-sm mb-1">Report Date Range</label>
                            <DatePicker 
                                ref="childDatePicker" 
                                :date="selectedDateRange"
                                :disabledBefore="disabledBefore"
                                :disabledAfter="disabledAfter"
                                :displayEvent="true"
                                :displayBreach="true"
                                :selectedDriver="selectedDriver"
                                @selected="selectedDateRange = $event" 
                                range noNavigation :clearable="false" 
                                class="mb-5 mt-1 send-datepicker"
                            />
                        </div>
                        <div>
                            <label class="font-medium text-sm mb-1 block">Include Breach?</label>
                            <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                                <input type="checkbox" v-model="includeBreach" name="toggle" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"/>
                                <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer" @click.prevent="includeBreach = !includeBreach"></label>
                            </div>
                        </div>
                        <FormulateInput
                            type="submit"
                            label="Submit"
                            input-class="btn btn-primary w-full mt-5"
                            :disabled="!canSend() || sendReportloading"
                        />
                    </div>
                </FormulateForm>
            </div>

            <div v-else class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center">
                <font-awesome-icon icon="cog" spin size="3x" class="text-gray-400"/>
                <h6 class="mt-4">Sending the report...</h6>
            </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
  .active {
    @apply border-orange-primary
  }
  .vertical {
    min-width: 7px;
    line-height: 7px;
    writing-mode: tb-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
    text-align: center; 
    transform: rotate(180deg);
  }
  .compliance-container {
    min-height: calc(100vh - 92px);
  }
  .send-datepicker::v-deep > div {
    @apply w-full
  }
</style>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { Modal } from '@/components/commons';
import DatePicker from '@/components/commons/field/DatePicker';
import Compliance from '@/components/view/compliance';

export default {
  name: "ComplianceReport",
  components: { Modal, DatePicker, Compliance },
  props: {
  isActive: {
    type: Boolean,
    default: false
  }
  },
  data() {
    return {
      modal: {
        title: '',
        content: null,
        height: '50vh',
      },
      activeTab: null,
      sendReportModel: null,
      sendReportloading: false,
      includeBreach: false,
      dateFormat: 'YYYY-MM-DD',
      selectedDate: moment().format('YYYY-MM-DD'),
      selectedDateRange: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      selectedDriver: null,
      readReports: false,
      writeReports: false,
      isContractor: false,
      disabledBefore: null,
      disabledAfter: moment().format('YYYY-MM-DD'),
      eventDates: [],
      breachDates: [],
      hasRole: true
    };
  },
  watch: {
    selectedDriver: {
      handler: function () {
        this.onSearch()
      },
      deep: true
    },
    selectedDate: {
      handler: function () {
        this.onSearch()
      },
      deep: true
    }
  },
  async mounted(){
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readReports = securityAccessObj.readReports;
    } else {
      this.hasRole = false;
    }

    await this.onSearch();
    this.$emit("updateLoading", false);
  },
  methods: {
    canSearch() {
      return !_.isEmpty(this.selectedDriver) && this.selectedDate != '';
    },
    canSend() {
      return !_.isEmpty(this.sendReportModel?.email) && this.selectedDateRange.length > 1 && !_.isEmpty(this.selectedDateRange[0]);
    },
    setSecurityAccess: (securityRoles) => {
      let initialReadReports = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.reports.readReports?.value) {
          initialReadReports = securityConfig.reports.readReports.value;
        }
      });
      return {
        readReports: initialReadReports
      };
    },
    async onSearch() {
      if (this.canSearch()) {
        const me = this.$store.getters[`account/me`];
        await this.$store.dispatch(
          `business/getContractByDriver`, 
          { 
            uid: this.selectedDriver.driverId, 
            query: { skip:0, limit:10, filter: { businessId: me?.businessId, driverId: this.selectedDriver.driverId} }
          } 
        ).then(({ results }) => {
          if (results.length) {
            const sortedContracts = _.orderBy(results, ['startDate'], ['desc']);
            const contract = _.first(sortedContracts);
            this.isContractor = contract?.isContractor ? true : false;
            const contractDate = contract.startDate ? contract.startDate : contract.createdAt;
            const dateToDisable = this.isContractor && contract?.contractorAccess  == 'moving-forward' ? moment(contractDate, this.dateFormat) : moment(contractDate, this.dateFormat).subtract(56, 'days');
            this.disabledBefore = dateToDisable.format(this.dateFormat);
            if (contract?.endDate) this.disabledAfter = moment(contract.endDate).format('YYYY-MM-DD');
          } else {
            this.disabledBefore = moment(this.selectedDriver.createdAt).subtract(56, 'days').format(this.dateFormat);
          }
        });
        this.$refs.childCompliance.onSearch(this.selectedDriver.driverId, this.selectedDate, this.activeTab);
      }
    },
    onGetReport() {
      this.$refs.modal.openModal()
      this.modal.content = 'new';
      this.modal.title = 'Send Report To Email';
    },
    handleStritEmailCase(value){
      this.sendReportModel.email = value.toLowerCase()
    },
    async onSubmitSendReport() {
      const recipientEmail = this.sendReportModel.email;
      await this.$refs.childCompliance.onSubmitSendReport(recipientEmail, this.includeBreach);
    }
  },
  computed:{
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>