<template>
  <div class="border-2 border-gray-800 rounded-lg my-4 p-4">
    <FormulateInput
      v-if="renderTypeSelect(field.type)"
      type="select"
      v-model="field.type"
      label="Additional
                      Type"
      :options="additionalsTypeOptions"
      :disabled="!isDisabled"
    />

    <div v-if="field.type === 'noPoint'">
      <div class="flex flex-col">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">No Point</p>
        </div>
        <FormulateInput
          v-model="field.noPoint"
          @blur="formatAdditionals($event, 'noPoint', false)"
          @click="clearField"
          validation="^required"
          :validation-messages="{ required: 'No Point is required.' }"
        />
      </div>
    </div>

    <div v-else-if="field.type === 'longNight'">
      <div class="flex justify-center p-2">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">Long Night</p>
        </div>

        <div class="w-1/2">
          <FormulateInput
            type="checkbox"
            name="longNight"
            v-model="field.longNight"
            @click="checkboxClick"
          />
        </div>
      </div>

      <div class="flex flex-col">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">Long</p>
        </div>
        <FormulateInput
          @keypress="isNumber"
          v-model="field.long"
          @blur="formatAdditionals($event, 'long', false)"
          @click="clearField"
          validation="^required"
          :validation-messages="{ required: 'Long is required.' }"
        />
      </div>

      <div class="flex flex-col">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">Night Start</p>
        </div>
        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'nightStart', false)"
          @click="clearField"
          v-model="field.nightStart"
          validation="^required"
          :validation-messages="{ required: 'Night Start is required.' }"
        />
      </div>

      <div class="flex flex-col">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">Night End</p>
        </div>
        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'nightEnd', false)"
          @click="clearField"
          v-model="field.nightEnd"
          validation="^required"
          :validation-messages="{ required: 'Night End is required.' }"
        />
      </div>

      <div class="flex justify-center p-2">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">Long Night Compound</p>
        </div>
        <div class="w-1/2">
          <FormulateInput
            type="checkbox"
            name="longNightCompound"
            v-model="field.longNightCompound"
            @click="checkboxClick"
          />
        </div>
      </div>

      <div class="flex flex-col">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">Day Counting</p>
        </div>
        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'dayCounting', false)"
          @click="clearField"
          v-model="field.dayCounting"
          validation="^required"
          :validation-messages="{ required: 'Day Counting is required.' }"
        />
      </div>
    </div>

    <div v-else-if="field.type === 'count'">
      <div class="flex flex-col">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">Count</p>
        </div>

        <FormulateInput
          @keypress="isNumber"
          @click="clearField"
          v-model="field.count"
          validation="^required"
          :validation-messages="{ required: 'Count is required.' }"
        />

        <div v-if="isDisabled" class="flex flex-row">
          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'firstMaxWork')"
          >
            Add First Max Work
            <font-awesome-icon icon="plus-circle" />
          </button>

          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'consecutiveCount')"
          >
            Consecutive Count
            <font-awesome-icon icon="plus-circle" />
          </button>

          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'replacement')"
          >
            Replacement
            <font-awesome-icon icon="plus-circle" />
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="field.type === 'blocks'">
      <div class="flex flex-col">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">Blocks</p>
        </div>
        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'blocks', false)"
          @click="clearField"
          v-model="field.blocks"
          validation="^required"
          :validation-messages="{ required: 'Block is required.' }"
        />
      </div>
    </div>

    <div v-else-if="field.type === 'consecutiveCount'">
      <div class="flex flex-col">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">Consecutive Count</p>
        </div>
        <FormulateInput
          @keypress="isNumber"
          @click="clearField"
          v-model="field.consecutiveCount"
          validation="^required"
          :validation-messages="{ required: 'Consecutive Count is required.' }"
        />
        <div v-if="isDisabled" class="flex flex-row">
          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'firstMaxWork')"
          >
            Add First Max Work
            <font-awesome-icon icon="plus-circle" />
          </button>

          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'consecutiveCount')"
          >
            Consecutive Count
            <font-awesome-icon icon="plus-circle" />
          </button>

          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'replacement')"
          >
            Replacement
            <font-awesome-icon icon="plus-circle" />
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="field.type === 'replacement'">
      <div class="flex flex-col">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">Replacement</p>
        </div>
        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'replacement', false)"
          @click="clearField"
          v-model="field.replacement"
          validation="^required"
          :validation-messages="{ required: 'Replacement is required.' }"
        />
        <div v-if="isDisabled" class="flex flex-row">
          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'firstMaxWork')"
          >
            Add First Max Work
            <font-awesome-icon icon="plus-circle" />
          </button>

          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'consecutiveCount')"
          >
            Consecutive Count
            <font-awesome-icon icon="plus-circle" />
          </button>

          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'replacement')"
          >
            Replacement
            <font-awesome-icon icon="plus-circle" />
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="field.type === 'firstMaxWork'">
      <div class="flex flex-col">
        <div class="w-1/2">
          <p class="font-medium text-sm mb-1">First Max Work</p>
        </div>
        <FormulateInput
          @keypress="isNumber"
          @click="clearField"
          @blur="formatAdditionals($event, 'firstMaxWork', false)"
          v-model="field.firstMaxWork"
          validation="^required"
          :validation-messages="{ required: 'First Max Work is required.' }"
        />
        <div v-if="isDisabled" class="flex flex-row">
          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'firstMaxWork')"
          >
            Add First Max Work
            <font-awesome-icon icon="plus-circle" />
          </button>

          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'consecutiveCount')"
          >
            Consecutive Count
            <font-awesome-icon icon="plus-circle" />
          </button>

          <button
            class="btn btn-secondary text-center"
            @click.prevent="addAdditionals(afmRuleIndex, 'replacement')"
          >
            Replacement
            <font-awesome-icon icon="plus-circle" />
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="field.type === 'or'">
      <div class="w-1/2">
        <p class="font-medium text-sm mb-1">Or</p>
      </div>

      <div class="flex flex-col mb-8">
        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 0, 'A')"
          @click="clearField"
          v-model="field.or[0].A"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 0, 'B')"
          @click="clearField"
          v-model="field.or[0].B"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 0, 'C')"
          @click="clearField"
          v-model="field.or[0].C"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 0, 'D')"
          @click="clearField"
          v-model="field.or[0].D"
        />
      </div>

      <div class="flex flex-col mb-8">
        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 1, 'A')"
          @click="clearField"
          v-model="field.or[1].A"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 1, 'B')"
          @click="clearField"
          v-model="field.or[1].B"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 1, 'C')"
          @click="clearField"
          v-model="field.or[1].C"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 1, 'D')"
          @click="clearField"
          v-model="field.or[1].D"
        />
      </div>

      <div class="flex flex-col mb-8">
        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 2, 'A')"
          @click="clearField"
          v-model="field.or[2].A"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 2, 'B')"
          @click="clearField"
          v-model="field.or[2].B"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 2, 'C')"
          @click="clearField"
          v-model="field.or[2].C"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 2, 'D')"
          @click="clearField"
          v-model="field.or[2].D"
        />
      </div>

      <div class="flex flex-col mb-8">
        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 3, 'A')"
          @click="clearField"
          v-model="field.or[3].A"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 3, 'B')"
          @click="clearField"
          v-model="field.or[3].B"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 3, 'C')"
          @click="clearField"
          v-model="field.or[3].C"
        />

        <FormulateInput
          @keypress="isNumber"
          @blur="formatAdditionals($event, 'or', true, 3, 'D')"
          @click="clearField"
          v-model="field.or[3].D"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash';
export default {
  name: 'additional-field',

  computed: {
    AFMAdditionals() {
      return this.$store;
    },

    fieldValue: {
      get() {
        const additionalRuleIndex =
          this.$store.state.accreditation.AFMrules[this.afmRuleIndex]
            .additionals[this.currentAdditionalIndex];

        return this.$store.state.accreditation.additionalRules[
          additionalRuleIndex
        ];
      },
    },

    rules() {
      return this.$store.state.accreditation.AFMrules;
    },

    additionalRules() {
      return this.$store.state.accreditation.additionalRules;
    },
  },

  props: {
    dataField: {
      type: Object,
      default: () => {},
    },

    afmRuleIndex: {
      type: Number,
      default: () => {},
    },

    currentAdditionalIndex: {
      type: Number,
      default: () => {},
    },

    isDisabled: {
      type: Boolean,
      default: () => {},
    },
  },

  data() {
    return {
      field: false,
      additionalsTypeOptions: [
        { value: 'noPoint', label: 'No Point' },
        { value: 'longNight', label: 'Long Night' },
        { value: 'count', label: 'Count' },
        { value: 'blocks', label: 'Blocks' },
        { value: 'or', label: 'Or' },
      ],
    };
  },

  mounted() {
    this.field = this.dataField;
  },

  methods: {
    clamp(val, min, max, isHours) {
      const ret = val > max ? max : val < min ? min : val;
      if (isHours) {
        if (ret.length < 2) {
          const zeroes = '0'.repeat(1) + ret;
          return zeroes;
        }
        return ret;
      }
      return ret.toString().length % 2 === 0 ? ret : '0' + ret;
    },

    // eslint-disable-next-line no-unused-vars
    formatAdditionals(event, field, isOr, orIndex, orKey) {
      const inputVal = event.target.value;
      let final;
      //only seconds
      if (inputVal?.length > 0 && inputVal?.length <= 2) {
        let minutes = this.clamp(inputVal.slice(0), 0, 59);
        final = '0:' + minutes;
        //hours and minutes
      } else if (inputVal?.length > 2 && inputVal?.length <= 6) {
        const secondsIndex = inputVal.length - 2;
        const hours = this.clamp(
          inputVal.slice(0, secondsIndex),
          0,
          9999,
          true,
        );
        const minutes = this.clamp(
          inputVal.slice(secondsIndex, inputVal.length),
          0,
          59,
        );
        final = hours + ':' + minutes;
      } else {
        if (isOr) {
          this.fieldValue.or[orIndex][`${orKey}`] = '';
        } else {
          this.fieldValue[`${field}`] = '';
        }
      }

      if (isOr) {
        this.fieldValue.or[orIndex][`${orKey}`] = final;
      } else {
        this.fieldValue[`${field}`] = final;
      }
    },

    isNumber(event) {
      event = event ? event : window.event;
      let charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },

    renderTypeSelect(type) {
      let render = true;

      switch (type) {
        case 'firstMaxWork':
          render = false;
          break;
        case 'consecutiveCount':
          render = false;
          break;
        case 'replacement':
          render = false;
          break;
      }

      return render;
    },

    clearField(event) {
      event.target.value = '';
    },

    checkboxClick(event) {
      return event.target.checked;
    },

    addAdditionals(afmIndex, type) {
      this.$emit('addAdditionals', afmIndex, type);
    },
  },
};
</script>

<style></style>
