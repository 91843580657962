<template>
<div class="flex justify-start items-stretch space-x-2">
  <div class="flex-1 block bg-white rounded-lg rounded-lg border border-neutral-100 w-1/2">
    <div class="flex justify-between items-center border-b border-neutral-100 px-6 py-3 text-gray-800 bg-neutral-bg font-bold">
      <div>
        Update Business/Solo Payment & Invoice
      </div>
    </div>
    <div class="p-6">
      <FormulateForm
        v-model="values"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <div class="flex flex-col w-full">
            <FormulateInput
              type="select"
              label="Select Entity"
              name="entityType"
              :options="['Business', 'Driver']"
              validation="required"
              @change="onSelectEntity($event)"
            />
            <FormulateInput
              type="business-contract-select"
              placeholder="Select a business"
              :filterable="false"
              :clearable="false"
              validation="required"
              @input="onChange($event)"
              v-if="values.entityType == 'Business'"
              :label="`Select ${values.entityType}`"
              :getOptionKey="(option) => option._id"
              :getOptionLabel="(option) => option.businessMetaData?.persona?.businessName"
              name="entityValue"
            />
            <FormulateInput
              type="solo-driver-contract-select"
              placeholder="Select a solo driver"
              :filterable="false"
              :clearable="false"
              validation="required"
              @input="onChange($event)"
              v-if="values.entityType == 'Driver'"
              :label="`Select ${values.entityType}`"
              :getOptionKey="(option) => option._id"
              :getOptionLabel="(option) => option.businessMetaData?.driverName"
              name="entityValue"
              class="w-3/4"
              outer-class="mb-3"
              input-class="shadow-sm border border-gray-300 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mt-1 mb-2 font-medium text-sm text-neutral-dark-500"
              label-class=""
            />
        </div>
        <div v-if="values.entityValue" class="flex flex-col">
          <div class="pt-4 pb-2 border-b-2 border-neutral-100 mb-4">
            <h2 class="font-bold text-gray-500 text-md">Invoice Details</h2>
          </div>
          <div class="flex justify-between items-center space-x-4 form border-b-2 border-neutral-100 mb-4">
            <template v-for="(module, index) in selectedEntity.businessModules">
              <div :key="index">
                <div v-if="module.name != 'One API'">
                  <div class="font-bold text-sm">
                    {{ module.name }}
                  </div>
                  <div class="flex justify-between items-center">
                    <div class="flex justify-start space-x-2 items-center mb-2">
                      <FormulateInput
                        type="number"
                        :name="`${addedSeats[index].module}-seat`"
                        v-model="addedSeats[index].value"
                        input-class="shadow-sm border border-gray-300 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mt-1 mb-2 font-medium text-sm text-neutral-dark-500"
                        outer-class="z-50"
                        validation="required|number|min:0"
                        min="0"
                        max="100"
                        error-behavior="live"
                        :disabled="submitLoading"
                      />
                      <div class="text-sm text-gray-800"> seat(s) @ ${{ getActualPrice(module) }} each.</div> 
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <div class="flex w-full space-x-4 justify-start items-center">
            <div>    
              <FormulateInput
                type="date"
                class="formulate-input mb-2"
                name="invoiceStartDate"
                label="Invoice Start Date"
                :validation="`required|before:${new Date(Date.now())}`"
                :max="`${new Date(Date.now())}`"
                error-behavior="live"
              />
            </div>   
            <div>         
              <FormulateInput
                v-if="values.invoiceStartDate"
                type="number"
                class="formulate-input mb-2"
                name="invoiceDateCount"
                :label="`Number of ${billPeriod}(s) to be billed`"
                validation="required|number|min:1"
                min="1"
                max="100"
                @input="onSetDateCount($event)"
              />
            </div>
            <div>    
              <FormulateInput
                type="date"
                class="formulate-input mb-2"
                name="invoiceEndDate"
                label="Invoice End Date"
                validation="required"
                error-behavior="live"
                readonly
              />
            </div> 
          </div>
          <div class="mt-8">
            <FormulateInput
              type="checkbox"
              class="formulate-input"
              name="setNextBillingDate"
              label="Set next billing date"
              v-model="setNextBillingDate"
              label-class="formulate-input-label formulate-input-label--after"
              input-class=""
              wrapper-class="formulate-input-wrapper"
              outer-class=""
              :help="`*Check this box if you want to set the next billing date for the ${values.entityType}`"
              element-class="formulate-input-element formulate-input-element--checkbox"
            />
          </div>
          <div class="mt-8">
            <FormulateInput
              v-if="setNextBillingDate"
              type="date"
              class="formulate-input mb-2"
              name="nextBillingDate"
              label="Next Billing Date"
              :validation="`required`"
              error-behavior="live"
            />
          </div>
        </div>
        <StyledSubmit class="mt-4" :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{backgroundColor: theme.secondaryColor}"
          >
            <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" />Submitting</span>  
            <span v-if="!submitLoading">{{ submitText }}</span> 
          </FormulateInput>
        </StyledSubmit>
      </FormulateForm>
    </div>
  </div>
    <div v-if="values.entityValue" class="flex-1 block bg-white overflow-auto rounded-lg rounded-lg border border-neutral-100 w-1/2">
      <div class="h-full">
        <div class="flex justify-between items-center border-b border-neutral-100 px-6 py-3 text-gray-800 bg-neutral-bg font-bold">
          <div>
            Invoice Summary
          </div>
        </div>
        <div class="p-6 flex flex-col space-y-4">
          <div class="flex flex-col space-y-2">
            <div class="text-gray-800 text-base font-bold mb-2">Contract Details</div>
            <div class="text-gray-500 text-sm">Contract Duration: <span class="font-semibold text-gray-800">{{ selectedEntity.contractDuration.name }}</span></div>
            <div class="text-gray-500 text-sm">Billing Period: <span class="font-semibold text-gray-800">{{ selectedEntity.billingPeriod.name }}</span></div>
          </div>
          <div class="text-gray-800 text-base font-bold">Invoice Details</div>
          <div class="text-left mx-auto w-full">
            <div class="relative overflow-x-auto">
              <table class="w-full text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-100">
                  <tr>
                    <th scope="col" class="px-6 py-3 rounded-l-lg">
                      Description
                    </th>
                    <th scope="col" class="px-6 py-3 text-center">
                      Quantity
                    </th>
                    <th scope="col" class="px-6 py-3 text-right">
                      Unit Price
                    </th>
                    <th scope="col" class="px-6 py-3 rounded-r-lg text-right">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white" v-for="(seat, index) in addedSeats" :key="index">
                    <template v-if="seat.module !== 'One API'">
                      <th scope="row" class="px-6 py-2 font-medium text-black font-bold whitespace-nowrap">
                        {{seat.module}}
                      </th>
                      <td class="px-6 py-2 text-center text-gray-500 font-bold text-sm">
                        {{seat.value}}
                      </td>
                      <td class="px-6 py-2 text-right text-gray-500 font-bold text-sm">
                        ${{seat.unitPrice}}
                      </td>
                      <td class="px-6 py-2 text-right text-gray-500 font-bold text-sm">
                        ${{seat.unitPrice * seat.value}}
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>  
            </div>
            <div class="flex flex-col w-full pt-10" v-if="values.entityValue">
              <div class="pt-4 px-4 border-t">
                <div class="flex justify-between mb-4 items-center">
                  <div class="font-bold text-sm text-gray-600 text-left flex-1">Subtotal</div>
                  <div class="text-right w-40">
                    <div class="text-gray-500 font-bold text-sm" x-html="totalGST">${{ totals.subTotal.toFixed(2) }} per {{ billPeriod }}</div>
                  </div>
                </div>
                <div class="flex justify-between mb-4 items-center">
                  <div class="font-bold text-sm text-gray-600 text-left flex-1">Number of {{ billPeriod }}(s)</div>
                  <div class="text-right w-40">
                    <div class="text-gray-500 font-bold text-sm" x-html="totalGST">{{ values.invoiceDateCount }}</div>
                  </div>
                </div>
                <div class="flex justify-between mb-4 items-center">
                  <div class="font-bold text-sm text-gray-600 text-left flex-1">GST(10%)</div>
                  <div class="text-right w-40">
                    <div class="text-gray-500 font-bold text-sm" x-html="totalGST">${{ totals.gst.toFixed(2) }}</div>
                  </div>
                </div>
                <div class="flex justify-between mb-4 items-center" v-if="totals.billType=='payment gateway'">
                  <div class="font-bold text-sm text-gray-600 text-left flex-1">Gateway Fee({{ paymentGateway.surchargePercentageFee }}% + ${{ paymentGateway.otherFees }})</div>
                  <div class="text-right w-40">
                    <div class="text-gray-500 font-bold text-sm" x-html="totalGST">${{ totals.gatewayFee.toFixed(2) }}</div>
                  </div>
                </div>
                <div class="flex justify-between items-center mt-4">
                  <div class="text-xl font-bold text-gray-800 text-left flex-1">Total</div>
                  <div class="text-right w-40">
                    <div class="text-xl text-gray-800 font-bold" x-html="netTotal">${{ totals.amountDue.toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { StyledSubmit } from '@/styled-components'
  export default {
    name: "Update-Entity-Payment-Invoice-Form",
    props: {

    },
    components: {
      StyledSubmit
    },
    data() {
      return {
        values: {
          invoiceDateCount: 1,
          invoiceStartDate: moment(new Date()).format('YYYY-MM-DD')
        },
        loading: false,
        addedSeats: [],
        selectedEntity: {},
        billPeriod: '',
        selectedStartDate: '',
        setNextBillingDate: false,
        isInvoice: false,
        paymentGateway: {
          gstPercentageFee: 10, //TODO clarify the percent
          surchargePercentageFee: 0,
          otherFees: 0,
        },
        hasActivePaymentGateway: false,
        publishableKey: null,
        paymentGatewayId: null,
        paymentMethod: {},
        paymentHistory: {},
        totals: {
          priceperseat: 0,
          seatCount: 0,
          subTotal: 0,
          totalGST: 0,
          gst: 0,
          gatewayFee: 0,
          amountDue: 0,
          paymentGateway: false
        },
        submitLoading: false,
        submitText: 'Submit'
      }
    },

    created() {
    },

    watch: {
      addedSeats: {
        handler () {
          this.calculateTotal();
        },
        deep: true
      },
      values: {
        handler () {
          this.calculateTotal();
        },
        deep: true
      }
    },

    methods: {
      async onSubmit() {
        this.submitLoading = true
        console.log('Form Values--', this.values);
        console.log('All Totals--', this.totals);
        console.log('Seats--', this.addedSeats);

        const formData = {
          entityRole: this.values.entityType,
          contract: this.values.entityValue,
          invoiceStartDate: this.values.invoiceStartDate,
          invoiceEndDate: this.values.invoiceEndDate,
          invoiceDateCount: this.values.invoiceDateCount,
          setNextBillingDate: this.setNextBillingDate,
          nextBillingDate: this.values.nextBillingDate,
          total: this.totals.overAllTotal,
          billType: this.totals.billType
        }
        formData.invoiceItems = []

        this.addedSeats.forEach((seat) => {
          formData.invoiceItems.push({
            businessModuleId: seat.contractModuleId,
            contractId: this.values.entityValue._id,
            itemDescription: `${seat.module} x ${this.values.invoiceDateCount} ${this.billPeriod}(s)`,
            itemQuantity: seat.value,
            unitPrice: seat.unitPrice,
            isProration: false,
          })
        })

        console.log("Form Data--", formData);
        await this.$store.dispatch(`billinginvoice/createBacktrackInvoice`, formData)
          .then(async (response) => {
            console.log(response)
            this.$emit("invoiceCreated", true)
          })
          .catch(async(err) => {
            console.error(err)
            this.$emit("invoiceCreated", false)
          })
          .finally(() => {
            this.submitLoading = true
          })
      },
      onSelectEntity(event) {
        console.log(event.target.value)
        const entityType = event.target.value

        if(entityType == 'Business') {
          //fetch business contract

        }
        else if (entityType == 'Driver') {
          //fetch solo contract
        }
      },
      async onChange(entity) {
        this.selectedEntity = entity;
        let addedSeats = [...this.selectedEntity.businessModules]
        addedSeats.forEach((seat) => {
          this.addedSeats.push({
            contractModuleId: seat._id,
            module: seat.name,
            value: 0,
            unitPrice: this.getActualPrice(seat)
          })
        });
        await this.getContractPaymentDetails();
      },

      async getContractPaymentDetails() {
        if(this.selectedEntity.activationOption === 'recurring_payment' || this.selectedEntity.hasRecurringPayment === true) {
          this.isInvoice = false;
          const paymentGateway = await this.$store.dispatch("payment/getActivePaymentGatewayByEntityId", this.selectedEntity.partnerMongoId);
          if(paymentGateway) {
            this.paymentGatewayId = paymentGateway[0]._id;
            this.publishableKey = paymentGateway[0].apiKey;
            this.hasActivePaymentGateway = true;
            this.paymentGateway = paymentGateway[0];

            let subTotal = 0;
            //get totals
            this.addedSeats.forEach((seat) => {
              subTotal += seat.unitPrice * seat.value
            })

            let overAllTotal = subTotal * this.values.invoiceDateCount
            let gst = (overAllTotal / 100) * this.paymentGateway.gstPercentageFee;
            let totalGST = overAllTotal + gst;
            let gatewayFee = (( totalGST/100 ) * this.paymentGateway.surchargePercentageFee) + this.paymentGateway.otherFees;
            let amountDue = totalGST + gatewayFee;
            if(subTotal === 0) {
              gatewayFee = 0
              amountDue = 0
            }
            this.totals = {
              totalGST: totalGST,
              subTotal: subTotal,
              overAllTotal: overAllTotal,
              gst: gst,
              gatewayFee: gatewayFee,
              amountDue: amountDue,
              billType: "payment gateway"
            }
          }
        }
      },

      async calculateTotal() {
        let subTotal = 0;
        this.addedSeats.forEach((seat) => {
          subTotal += seat.unitPrice * seat.value
        });
        let overAllTotal = subTotal * this.values.invoiceDateCount
        let gst = (overAllTotal / 100) * this.paymentGateway.gstPercentageFee;
        let totalGST = overAllTotal + gst;
        let gatewayFee = (( totalGST/100 ) * this.paymentGateway.surchargePercentageFee) + this.paymentGateway.otherFees;
        let amountDue = totalGST + gatewayFee;
        if(subTotal === 0) {
          gatewayFee = 0
          amountDue = 0
          this.submitText = 'Submit'
        } else {
          this.submitText = `Submit & Charge ${this.values.entityType}`
        }
        this.totals = {
          totalGST: totalGST,
          subTotal: subTotal,
          overAllTotal: overAllTotal,
          gst: gst,
          gatewayFee: gatewayFee,
          amountDue: amountDue,
          billType: "payment gateway"
        }

        console.log("Totals--", this.totals)
      },

      async onSetDateCount(invoiceDateCount) {
        let invoiceEndDate;
        if(this.billPeriod == 'month') {
          invoiceEndDate = moment(this.values.invoiceStartDate).add(invoiceDateCount, 'months').format('YYYY-MM-DD');
        } else if(this.billPeriod == 'year') {
          invoiceEndDate = moment(this.values.invoiceStartDate).add(invoiceDateCount, 'years').format('YYYY-MM-DD');
        }
        // this.calculateTotal();
        this.values.invoiceEndDate = invoiceEndDate
      },

      getActualPrice(modules) {
        let amount;
        switch(this.selectedEntity.billingPeriod.name.toUpperCase()) {
          case "MONTHLY":
            this.billPeriod = "month"
            amount = modules.price.monthly.toFixed(2)
          break;
          case "QUARTERLY":
            this.billPeriod = "quarter"
            amount = modules.price.quarterly.toFixed(2)
          break;
          case "YEARLY":
            this.billPeriod = "year"
            amount = modules.price.yearly.toFixed(2)
        }
        this.onSetDateCount(this.values.invoiceDateCount);
        return amount;
      },
      async filterByDate(event){
        this.selectedStartDate = event
      },
    },
    computed: {
      ...mapGetters('theme', {
      theme: 'getColorScheme'
      })
    },
  }
</script>