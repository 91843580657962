<template>
  <FormulateForm
    #default="{ isValid }"
    @submit="onSubmit"
   >      
      <div class="mb-20">
        <div class="loading mt-32 h-32" v-if="loading">
          <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <div class="overflow-x-auto">
          <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table v-if="!loading" class="table-auto leading-normal p-4">
              <thead>
                <tr>
                  <th class="bg-gray-100 w-2/5 text-left">
                    Product
                  </th>
                  <th class="bg-gray-200">
                    Monthly
                  </th>
                  <th class="bg-gray-200">
                    Quarterly
                  </th>
                  <th class="bg-gray-200">
                    Yearly
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product,index) in values" :key="index" class="border-t-2">
                  <td class="w-80 pl-5 pr-9 text-sm bg-gray-100">
                    {{ ( product.name == 'EWD-FT')? 'EWD' :  product.name}}
                  </td>
                  <td class="pt-5 px-5 text-center">
                    <div class="flex relative pb-9 h-40">
                      <span class="pt-2 w-0">$</span>
                      <FormulateInput
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          class="p-0 w-32"
                          input-class="w-28 h-7 ml-3 text-right py-4 px-0 border outline-none"
                          error-behavior="live"
                          :value="parsePrice(product.rrp.monthly)"
                          :name="`${removeSpaces(product.name)}.rrp.monthly`"
                          v-on:input="perMonthInput($event,index,false,product.pricing.monthly)"
                        />
                        <ul 
                          ref="perMonthRef" 
                          v-html="perMonthDesc[index]" 
                          class="absolute mt-12 ml-2 opacity-90 text-xs list-disc text-left"
                        ></ul>                       
                    </div>
                  </td>
                  <td class="pt-5 px-5 text-center">
                    <div class="flex relative pb-9 h-40">
                      <span class="pt-2 w-0">$</span>
                      <FormulateInput
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          class="p-0 w-32"
                          input-class="w-28 h-7 ml-3 text-right py-4 px-0 border outline-none"
                          error-behavior="live"
                          :value="parsePrice(product.rrp.quarterly)"
                          :name="`${removeSpaces(product.name)}.rrp.quarterly`"
                          v-on:input="quarterlyPerMonthInput($event,index,false,product.pricing.quarterly)"
                        />
                        <ul 
                          ref="quarterlyPerMonthRef" 
                          class="absolute mt-12 ml-2 opacity-90 text-xs list-disc text-left"
                          v-html="quarterlyPerMonthDesc[index]">
                        </ul>
                    </div>
                  </td>
                  <td class="pt-5 px-5 text-center">
                    <div class="flex relative pb-9 h-40">
                      <span class="pt-2 w-0">$</span>
                      <FormulateInput
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          class="p-0 w-32"
                          input-class="w-28 h-7 ml-3 text-right py-4 px-0 border outline-none"
                          error-behavior="live"
                          :value="parsePrice(product.rrp.yearly)"
                          :name="`${removeSpaces(product.name)}.rrp.yearly`"
                          v-on:input="yearlyPerMonthInput($event,index,false,product.pricing.quarterly)"
                        />
                        <ul 
                          ref="yearlyPerMonthRef" 
                          class="absolute mt-12 ml-2 opacity-90 text-xs list-disc text-left"
                          v-html="yearlyPerMonthDesc[index]"
                        ></ul>               
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="mt-5 mb-4" v-if="prepaidEWDRrp">
              <div class="rounded-lg">
                <table v-if="!loading" class="table-auto leading-normal">
                <tr>
                  <td class="bg-gray-100 w-2/5 text-left pl-6 pr-2" rowspan="6">
                    <p class="w-32">Pre-paid EWD</p>
                  </td>
                  <th class="bg-gray-200">              
                    <p class="text-center"> Packs </p>
                  </th>
                  <th class="bg-gray-200">              
                    <p class="text-center"> RRP </p>
                  </th>
                </tr>
                <tr class="border-t-2">
                  <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                    1 Day
                  </td>
                  <td class="w-full pt-3 pr-7 pb-12 text-center">
                    <div class="flex relative">
                      <span class="pt-4 pl-11 w-0">$</span>
                        <FormulateInput
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          class="p-0 w-full	"
                          input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                          :value="parsePrice(prepaidEWDRrp.oneDay)"
                          :name="`rrpOneDay`"
                          v-on:input="prepaidPerDayInput($event,prepaidEWDPricing.oneDay,1)"
                        />
                        <ul 
                          class="absolute mt-12 ml-12 pl-6 opacity-90 text-xs list-disc text-left"
                          v-html="prepaidOneDayDesc"
                        ></ul>     
                      </div>
                    </td>
                    </tr>
                    <tr class="border-t-2">
                      <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                        5 Days
                      </td>
                      <td class="w-full pt-3 pr-7 pb-12 text-center">
                      <div class="flex relative">
                        <span class="pt-4 pl-11 w-0">$</span>
                        <FormulateInput
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          class="p-0 w-full	"
                          input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                          :value="parsePrice(prepaidEWDRrp.fiveDays)"
                          :name="`rrpFiveDays`"
                          v-on:input="prepaidPerDayInput($event,prepaidEWDPricing.fiveDays,5)"
                        />
                        <ul 
                          class="absolute mt-12 ml-12 pl-6 opacity-90 text-xs list-disc text-left"
                          v-html="prepaidFiveDaysDesc"
                        ></ul>
                      </div>
                      </td>
                      </tr>
                      <tr class="border-t-2">
                        <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                          10 Days
                        </td>
                        <td class="w-full pt-3 pr-7 pb-12 text-center">
                          <div class="flex relative">
                            <span class="pt-4 pl-11 w-0">$</span>
                            <FormulateInput
                              type="number"
                              step="0.01"
                              min="0"
                              placeholder="0"
                              class="p-0 w-full	"
                              input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                              :value="parsePrice(prepaidEWDRrp.tenDays)"
                              :name="`rrpTenDays`"
                              v-on:input="prepaidPerDayInput($event,prepaidEWDPricing.tenDays,10)"
                            />
                            <ul 
                              class="absolute mt-12 ml-12 pl-6 opacity-90 text-xs list-disc text-left"
                              v-html="prepaidTenDaysDesc"
                            ></ul>
                          </div>
                        </td>
                      </tr>
                      <tr class="border-t-2">
                        <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                          25 Days
                        </td>
                        <td class="w-full pt-3 pr-7 pb-12 text-center">
                          <div class="flex relative">
                            <span class="pt-4 pl-11 w-0">$</span>
                            <FormulateInput
                              type="number"
                              step="0.01"
                              min="0"
                              placeholder="0"
                              class="p-0 w-full	"
                              input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                              :value="parsePrice(prepaidEWDRrp.twentyFiveDays)"
                              :name="`rrpTwentyFiveDays`"
                              v-on:input="prepaidPerDayInput($event,prepaidEWDPricing.twentyFiveDays,25)"
                            />
                            <ul 
                              class="absolute mt-12 ml-12 pl-6 opacity-90 text-xs list-disc text-left"
                              v-html="prepaidTwentyFiveDaysDesc"
                            ></ul>
                          </div>
                        </td>
                      </tr>
                      <tr class="border-t-2">
                        <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                          50 Days
                        </td>
                        <td class="w-full pt-3 pr-7 pb-12 text-center">
                          <div class="flex relative">
                            <span class="pt-3 pl-11 w-0">$</span>
                            <FormulateInput
                              type="number"
                              step="0.01"
                              min="0"
                              placeholder="0"
                              class="p-0 w-full	"
                              input-class="h-7 ml-3 mt-1 text-right py-4 px-0 border outline-none"
                              :value="parsePrice(prepaidEWDRrp.fiftyDays)"
                              :name="`rrpFiftyDays`"
                              v-on:input="prepaidPerDayInput($event,prepaidEWDPricing.twentyFiveDays,50)"
                            />
                            <ul 
                              ref="prepaidFiftyDaysRef" 
                              class="absolute mt-12 ml-12 pl-6 opacity-90 text-xs list-disc text-left"
                              v-html="prepaidFiftyDaysDesc"
                            ></ul>     
                        </div>
                      </td>
                    </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Button 
          label="Update"
          submittingLabel="Submitting..."
          :submitting="submitting"
          :disabled="!isValid"
          />
      </div>
  </FormulateForm>
</template>
<script>
import Button from '@/components/controls/Button';
import _ from 'lodash';
export default {
  name: 'Edit-Partner-RRP-Pricing',
  components: {Button},
  props: {
    buyPricing: Array,
    prepaid: Object,
    partner: Object
  },
  data() {
    return {
      errorMessage: '',
      loading: false,
      values: {},
      perMonthDesc: [],
      quarterlyPerMonthDesc: [],
      yearlyPerMonthDesc: [],
      prepaidOneDayDesc: null,
      prepaidFiveDaysDesc: null,
      prepaidTenDaysDesc: null,
      prepaidTwentyFiveDaysDesc: null,
      prepaidFiftyDaysDesc: null,
      prepaidEWDRrp: null,
      prepaidEWDPricing: null,
      submitting: false,
      errors: []
    };
  },
  mounted() {
    this.values = this.buyPricing;
    this.prepaidEWDRrp = this.prepaid.rrp;
    this.prepaidEWDPricing = this.prepaid.pricing;
    this.values.forEach((value, index) => {
      this.perMonthInput(
        this.parsePrice(value.rrp.monthly),index,true,this.parsePrice(value.pricing.monthly)
      )
      this.quarterlyPerMonthInput(
        this.parsePrice(value.rrp.quarterly),index,true,this.parsePrice(value.pricing.quarterly)
      )
      this.yearlyPerMonthInput(
        this.parsePrice(value.rrp.yearly),index,true,this.parsePrice(value.pricing.yearly)
      )
    })
    // Pre-paid init
    this.prepaidPerDayInput(
      this.prepaidEWDRrp.oneDay,this.prepaidEWDPricing.oneDay,1
    )
    this.prepaidPerDayInput(
      this.prepaidEWDRrp.fiveDays,this.prepaidEWDPricing.fiveDays,5
    )
    this.prepaidPerDayInput(
      this.prepaidEWDRrp.tenDays,this.prepaidEWDPricing.tenDays,10
    )
    this.prepaidPerDayInput(
      this.prepaidEWDRrp.twentyFiveDays,this.prepaidEWDPricing.twentyFiveDays,25
    )
    this.prepaidPerDayInput(
      this.prepaidEWDRrp.fiftyDays,this.prepaidEWDPricing.fiftyDays,50
    )
  },
  methods: {
    parsePrice(price){
      return parseFloat(price).toFixed(2);
    },
    parsePriceString(marginPercent, margenProfit, profit, price){
      if(parseFloat(price.RRP) >= parseFloat(price.buyPrice)){
        return `<li>${marginPercent}% Margin per month</li><li>$${profit} Margin per month</li><li>$${margenProfit} Per month per seat</li>`;
      } else {
        return `<div class="text-red-500">RRP must not lower than the product buy price.</div>`;
      }
    },
    parsePrepaidPriceString(marginPercent, margenProfit, profit, price){
      if(parseFloat(price.RRP) > parseFloat(price.buyPrice)){
        return `<li>${marginPercent}% Margin</li><li>$${profit} Margin</li><li>$${margenProfit} Per day</li>`;
      } else {
        return `<li class="text-red-500">RRP must not lower than the product buy price.</li>`;
      }
    },
    perMonthInput(RRP, index, init, buyPrice){
      let percentage = this.parsePrice(((RRP-buyPrice)/RRP)*100);
      let margenProfit = this.parsePrice(RRP/1);
      const profit = this.parsePrice(RRP-buyPrice);

      const price = {
        RRP: RRP,
        buyPrice: buyPrice
      }

      if(init){
        this.perMonthDesc.push(this.parsePriceString(percentage,margenProfit,profit, price));
      } else {
        this.$parent.$children[0].$refs.perMonthRef[index].innerHTML 
          = this.parsePriceString(percentage,margenProfit,profit, price);
      }
    },
    quarterlyPerMonthInput(RRP, index, init, buyPrice){
      let percentage = this.parsePrice(((RRP-buyPrice)/RRP)*100);
      let margenProfit = this.parsePrice(RRP/3);
      const profit = this.parsePrice((RRP-buyPrice)/3);

      const price = {
        RRP: RRP,
        buyPrice: buyPrice
      }

      if(init){
        this.quarterlyPerMonthDesc.push(this.parsePriceString(percentage,margenProfit,profit, price));
      } else {
        this.$parent.$children[0].$refs.quarterlyPerMonthRef[index].innerHTML 
          = this.parsePriceString(percentage,margenProfit,profit, price);
      }
    },
    yearlyPerMonthInput(RRP, index, init, buyPrice){
      let percentage = this.parsePrice(((RRP-buyPrice)/RRP)*100);
      let margenProfit = this.parsePrice(RRP/12);
      const profit = this.parsePrice((RRP-buyPrice)/12);
      const price = {
        RRP: RRP,
        buyPrice: buyPrice
      }
      if(init){
        return this.yearlyPerMonthDesc.push(this.parsePriceString(percentage,margenProfit,profit, price));
      } else {
        this.$parent.$children[0].$refs.yearlyPerMonthRef[index].innerHTML 
          = this.parsePriceString(percentage,margenProfit,profit, price)
      }
    },
    prepaidPerDayInput(RRP, buyPrice, days){
      let percentage = this.parsePrice(((RRP-buyPrice)/RRP)*100);
      let margenProfit = this.parsePrice(RRP/days);
      const profit = this.parsePrice(((RRP-buyPrice)/days)*days);
      const price = {
        RRP: RRP,
        buyPrice: buyPrice
      }
      let prepaid = this.parsePrepaidPriceString(percentage,margenProfit,profit, price);
      switch (days) {
        case 1:
          this.prepaidOneDayDesc = prepaid;
          break;
        case 5:
          this.prepaidFiveDaysDesc = prepaid;
          break;
        case 10:
          this.prepaidTenDaysDesc = prepaid;
          break;
        case 25:
          this.prepaidTwentyFiveDaysDesc = prepaid;
          break;
        case 50:
          this.prepaidFiftyDaysDesc = prepaid;
          break;
      }
    },
    removeSpaces(string){
      return string.replace(/\s+/g, '');
    },
    async onSubmit(formData) {
      this.errors = [];
      let nRrp = [];
      this.submitting = true;
      this.values.forEach((obj) => {
        if(obj){
          const data = Object(formData)
          const rrp = {
            monthly: this.parsePrice(data[`${this.removeSpaces(obj.name)}.rrp.monthly`]),
            quarterly: this.parsePrice(data[`${this.removeSpaces(obj.name)}.rrp.quarterly`]),
            yearly: this.parsePrice(data[`${this.removeSpaces(obj.name)}.rrp.yearly`]),
          }
          const rrpDto = {
            standardPricingMasterId: obj.standardPricingMaster._id,
            rrp: rrp
          }
          nRrp.push(rrpDto);
        }
      });
      const prepaidData = formData;
      const prepaidRRP = {
        oneDay: this.parsePrice(prepaidData.rrpOneDay),
        fiveDays: this.parsePrice(prepaidData.rrpFiveDays),
        tenDays: this.parsePrice(prepaidData.rrpTenDays),
        twentyFiveDays: this.parsePrice(prepaidData.rrpTwentyFiveDays),
        fiftyDays: this.parsePrice(prepaidData.rrpFiftyDays)
      }
      const nPrepaidRrp = {
        rrp: prepaidRRP,
        standardPricingMasterId: this.prepaid.standardPricingMasterId,
      }
      const partnerId = this.partner.id;
      const RRPPayload = this.parsePricingPayload(nRrp, partnerId);
      const RRPPrepaidPayload = this.parsePrepaidPricingPayload(nPrepaidRrp, partnerId);
      const mergedPayload = this.parseMergedPayload([...RRPPayload, ...RRPPrepaidPayload]);

      try {
          await this.$store.dispatch(`standardpricing/createNewRRPVersion`, mergedPayload).then(() => {
          this.submitting = false;
          this.$emit('closeModal')
          this.$store.commit('setDialogNotify',true);
          this.$store.commit('setMessageNotify',{state:'success',message:'Partner successfully updated.'});
          setTimeout(()=>{location.reload()},4000);
        });
      } catch (err) {
          this.submitting = false;
          this.$store.commit('setDialogNotify',true);
          this.$store.commit('setMessageNotify',{state:'error',message:err.message});
      }
    },
    parseMergedPayload(standardPricings){
      const standardPricingData = {};
      if(!_.isEmpty(standardPricings)){
        standardPricingData.standardPricings = standardPricings;
        standardPricingData.isRrp = true;
      }
      return standardPricingData;
    },
    parsePricingPayload(pricing, partnerId){
      let standardPricings = []
      if(!_.isEmpty(pricing)){
      standardPricings = pricing.map(data => {
        const standardPricing = {};
        standardPricing.ownerMongoId = partnerId;
        standardPricing.ownerRole = 'partner';
        standardPricing.isRrp = true;
        standardPricing.pricing = {
          monthly:data.rrp.monthly,
          quarterly:data.rrp.quarterly,
          yearly:data.rrp.yearly
        };
        standardPricing.standardPricingMasterId = data.standardPricingMasterId;
        return standardPricing;
      });
      }
      return standardPricings;
    },
    parsePrepaidPricingPayload(data, partnerId){
      let standardPricing = {};
      standardPricing.ownerMongoId = partnerId;
      standardPricing.ownerRole = 'partner';
      standardPricing.isRrp = true;
      standardPricing.pricing = {
        oneDay:data.rrp.oneDay,
        fiveDays:data.rrp.fiveDays,
        tenDays:data.rrp.tenDays,
        twentyFiveDays:data.rrp.twentyFiveDays,
        fiftyDays:data.rrp.fiftyDays
      }
      standardPricing.standardPricingMasterId = data.standardPricingMasterId;
      return [standardPricing];
    },
  }
}
</script>
<style scoped lang="scss">
th {
  @apply px-5 py-3 border-gray-200 text-sm font-semibold text-gray-600 uppercase;
}
</style>