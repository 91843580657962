<template>
  <div class="profile-container w-full flex">
    <div class="container m-auto">
      <div class="login-container max-w-screen-lg mx-auto">
        <div class="rounded-lg flex items-center justify-center">
    
          <section class="w-1/2 mx-4 bg-gray-800 hover:bg-gray-700 rounded-2xl px-8 py-6 shadow-lg">
            <div class="flex items-center justify-between">
                <!-- <span class="text-gray-400 text-sm"></span>
                <span class="text-white">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z" />
                  </svg>
                </span> -->
            </div>
            <div class="mt-6 w-fit mx-auto">
                <img src="https://api.lorem.space/image/face?w=120&h=120&hash=bart89fe" class="rounded-full w-28 " alt="profile picture" srcset="">
            </div>

            <div class="mt-5">
                <h2 class="text-white font-bold text-2xl tracking-wide">{{ profile.driverName }}</h2>
            </div>
            <p class="text-gray-300">{{ role.name }}</p>
            <p class="text-gray-300">Licence: {{ profile.driversLicenseNumber }}</p>
            <p class="text-gray-300">Status: <span class="text-green-500" v-if="profile.isActive">Active</span><span class="text-gray-500" v-else>Inactive</span></p>

            <div class="mt-6">
              <router-link to="profile/edit" tag="button" class="btn btn-primary w-full" 
              :style="{backgroundColor: theme.secondaryColor}"
              >Edit Profile</router-link>
            </div>

          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default{
  name:"ProfilePage",
  components:{
  },
  data() {
    return {
      isLoading: false,
      profile: {},
      role: {}
    }
  },
  async created(){
    this.isLoading = true;
    this.$emit("updateLoading", true);
    this.init();
  },
  methods: {
    async init() {
      console.log("Test")
      const me = await this.$store.getters[`account/me`];
      this.role = me.role
      console.log(me)

      if (this.role.name === 'driver') {
        this.profile = me.driver
      }
      console.log(me)
      this.isLoading = false;
      this.$emit("updateLoading", false);
    }
  },
  computed: {
    ...mapGetters('theme', {
    theme: 'getColorScheme'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.profile-container {
  height: 80vh;
}
</style>
