<template>
  <div>
    <div v-if="writeForms" class="w-full min-h-full flex-row items-center">
      <FormBuilder
        :isForm="true"
        :editMode="true"
        :_id="formId"
        :pulledFormData="pulledFormData"
        />
    </div>
    <div v-if="!writeForms" class="w-full min-h-full text-center mt-5">
      <h1>Not authorized to view form versions. Please contact your business admin.</h1>
    </div>
  </div>
</template>
<script>
import FormBuilder from '@/components/commons/FormBuilder'

export default {
  components: {
    FormBuilder
  },
  data: () => ({
    formData: null,
    isRenderer: true,
    readOnly: false,
    formId: null,
    pulledFormData: null,
    writeForms: false
  }),
  methods: {
    async getFormById(){
      const formId = this.formId = this.$route.params.id;
      const data = this.pulledFormData = await this.$store.dispatch(`form/getBusinessFormByFormId`, formId)
          .catch((err)=>{
            this.$store.commit('setDialogNotify',true)
            this.$store.commit('setMessageNotify',{state:'error', message: err.message})
          })
      
      if(!data) window.location.href = "/error-404"
    },
    setSecurityAccess(securityRoles){
      let initialReadForms = false;
      let initialWriteUsers = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.forms.readForms?.value) {
          initialReadForms = securityConfig.forms.readForms.value;
        }
        if (securityConfig.forms.writeForms?.value) {
          initialWriteUsers = securityConfig.forms.writeForms.value;
        }
      });
      return {
        readForms: initialReadForms,
        writeForms: initialWriteUsers,
      };
    },
  },
  async mounted(){
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readForms = securityAccessObj.readForms;
      this.writeForms = securityAccessObj.writeForms;
    }
    this.getFormById();
    this.$emit("updateLoading", false);
  }
}
</script>