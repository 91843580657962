export default [
  {
    name: 'Driver Navigation',
    items: [
      {
        name: 'Dashboard',
        link: '/driver/',
        production: true,
        hiddenOnSuspendedAccount: false
      },
      {
        name: 'Businesses',
        link: '',
        subitems: [
          {
            name: 'Linked Businesses',
            link: '/driver/linked-businesses',
            production: true
          },
          {
            name: 'User Created Businesses',
            link: '/driver/user-created-businesses',
            production: true
          },
        ],
        hiddenOnSuspendedAccount: true
      },
      {
        name: 'Billing',
        link: '',
        subitems: [
          {
            name: 'Billing Centre',
            link: '/driver/billing-centre',
            production: true
          },
        ],
        hiddenOnSuspendedAccount: false
      },
      {
        name: 'Compliance',
        link: '',
        subitems: [
          {
            name: 'Compliance Report',
            link: '/driver/compliance-report',
          },
          {
            name: 'Report Scheduling',
            link: '/driver/scheduled-report',
          },
          {
            name: 'Manual Event',
            link: '/driver/manual-event',
          },
        ],
        production: true,
        hiddenOnSuspendedAccount: true
      },
      {
        name: 'Driver Wallet',
        link: '',
        subitems: [
          {
            name: 'Certificates',
            link: '/driver/certificates'
          },
          {
            name: 'Documents',
            link: '/driver/documents'
          }
        ],
        production: true,
        hiddenOnSuspendedAccount: true
      },
      {
        name: 'Non Conformance',
        link: '/driver/non-conformance-documents',
        production: true,
        hiddenOnSuspendedAccount: true
      },
      {
        name: 'Licence Details',
        link: '/driver/licence-details',
        production: true,
        hiddenOnSuspendedAccount: true
      },
      {
        name: 'Platform Training',
        link: '/driver/training',
        production: true,
        hiddenOnSuspendedAccount: false
      },
    ]
  },
]
