<template>
  <div>
    <Doughnut
      :chart-options="finalChartOptions"
      :chart-data="finalChartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :class="classes"
      ref="pieChart"
    />
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'
import { Chart, registerables } from 'chart.js'
// import $ from 'jquery'

Chart.register(...registerables)

export default {
  name: 'PieChart',
  components: { Doughnut },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    },
    chartData: {
        type: Object,
        required: true
      },
    chartOptions: {
      type: Object,
      default: () => {}
    },
    classes: {
      type: String,
      default: "w-64"
    }
  },
  data() {
    return {
    }
  },
  computed: {
    finalChartData() {
      return {...this.chartData}
    },
    finalChartOptions() {
      return {...this.chartOptions}
    },
  },
  methods: {
    saveImage() {
      const component = this.$refs['pieChart'] 
      const canvas = component.$refs.canvas  
      return canvas.toDataURL()
    }
  }
}
</script>