<template>
  <div>
    <div>
      <Tabs>
        <Tab title="Investigation">
          <InvestigationRegister></InvestigationRegister>
        </Tab>
        <Tab title="Non Conformance">
          <NonConformance></NonConformance>
        </Tab>
        <Tab title="Performance Management">
          <Performance></Performance>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>


<script>
import Tabs from "@/components/commons/tab/Tabs.vue";
import Tab from "@/components/commons/tab/Tab.vue";
import InvestigationRegister from "@/pages/business/InvestigationRegister.vue";
import NonConformance from "@/pages/NonConformanceDocuments.vue";
import Performance from "@/pages/ViewPerformanceManagement.vue";
export default {
  name: "Conformance",
  components: {Performance, NonConformance, Tab, Tabs, InvestigationRegister},
}
</script>