<template>
  <draggable tag="div" :list="basicComponents" group="controls">
    Toggleable
    <div
      class="border border-dashed border-gray-400 text-gray-400 rounded-md text-center w-full p-4 draggable-zone flex flex-column justify-center items-center"
    >
      <!-- <p>You can drag and drop the item from the left to add components</p> -->
      <div v-for="element in basicComponents" :key="element.name">
        {{ element.name }}
      </div>
    </div>
  </draggable>
</template>
<script>
import Draggable from 'vuedraggable'
export default {
  components: {
    Draggable
  },
  data: () => ({
    basicComponents: [
      {
        name: 'Test'
      }
    ]
  })
}
</script>
