<template>
  <div class="w-full">
    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
    <div class="p-10 xl:w-1/2 md:-1/2" :style="{ color: theme.primaryColor }">
      <FormulateForm
        name="securityConfig"
        @submit="onSubmit"
        #default="{ hasErrors }"
        class="formulate-input-element-decorator"
      >
        <div
          v-for="(config, indexOne) in securityConfigKeys"
          :key="indexOne"
          class="formulate-input-element-decorator"
        >
          <b class="text-transform: capitalize">{{ config }}</b>
          <br />
          <br />
          <div
            v-for="(subConfig, indexTwo) in securityConfigs[config]"
            :key="indexTwo"
          >
            <div class="flex row-reverse items-baseline px-2 mb-2">
              <FormulateInput
                type="checkbox"
                v-model="subConfig.value"
                :style="{ color: theme.primaryColor }"
                class="custom-options checkbox"
                :disabled="
                  (role && role.isAdmin) ||
                  hasErrors ||
                  loading ||
                  !writeSecurityConfig
                "
              />
              <p>{{ subConfig.label }}</p>
            </div>
          </div>
        </div>
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            v-if="writeSecurityConfig"
            type="submit"
            label="Save"
            input-class="btn btn-primary w-full"
            :disabled="(role && role.isAdmin) || hasErrors || loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          />
        </StyledSubmit>
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            v-if="!writeSecurityConfig"
            type="submit"
            label="Save"
            input-class="btn btn-primary w-full"
            :disabled="!writeSecurityConfig || hasErrors || loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          />
        </StyledSubmit>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";

export default {
  name: "SecurityConfig",
  components: {
    StyledSubmit,
  },
  data() {
    return {
      error: false,
      errorMessage: "",
      loading: false,
      securityConfigs: {},
      securityConfigKeys: [],
      role: null,
      roleId: null,
      writeSecurityConfig: false,
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.writeSecurityConfig = securityAccessObj.writeSecurityConfig;
    }
    this.roleId = this.$route.params["id"];
    const role = await this.$store.dispatch(
      `rolesandrespo/getRoles`,
      this.roleId
    );
    if (role) {
      this.role = role;
    }
    const securityConfigs = await this.$store.dispatch(
      `rolesandrespo/getSecurityConfig`,
      this.roleId
    );
    if (securityConfigs) {
      this.securityConfigs = securityConfigs;
      this.securityConfigKeys = Object.keys(securityConfigs);
    }
    this.$emit("updateLoading", false);
  },
  methods: {
    async onSubmit() {
      try {
        const payload = {
          roleId: this.roleId,
          securityConfigs: this.securityConfigs,
        };
        await this.$store.dispatch(
          `rolesandrespo/patchSecurityConfig`,
          payload
        );
        location.reload();
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) {
          this.errorMessage = error.message;
        }
      }
    },
    setSecurityAccess: (securityRoles) => {
      let initialWriteSecurityConfig = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.permissions.writeSecurityConfig.value) {
          initialWriteSecurityConfig =
            securityConfig.permissions.writeSecurityConfig.value;
        }
      });
      return {
        writeSecurityConfig: initialWriteSecurityConfig,
      };
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
