<template>
  <div>
    <div
      :style="offset"
      :class="[
        depth == 0 ? 'main-business bg-gray-100 py-2 mb-1' : 'py-3 mb-1',
      ]"
      class="flex items-center border pl-4 relative"
      :data-id="item._id"
      :data-depth="depth"
      :data-leaf="item.isLeaf"
    >
      <input
        type="checkbox"
        v-model="item.isChecked"
        @change="onTick(item)"
        class="depot-checkbox w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2 cursor-pointer"
      />
      <label
        for="default-checkbox"
        class="ml-2 text-sm font-medium pl-2"
        :class="[
          item.isLeaf ? ' text-green-400' : ' text-gray-600',
          { 'text-lg font-bold': depth == 0 },
        ]"
      >
        <font-awesome-icon title="Site" v-if="item.isLeaf" icon="building" />
        <font-awesome-icon
          title="Region"
          v-if="!item.isLeaf"
          icon="map-marked-alt"
        />&nbsp; {{ item.name }}
      </label>
      <div v-if="item.isLeaf" class="absolute right-1 top-2 bottom-2">
        <button
          type="button"
          :id="'default' + item._id"
          :data-id="item._id"
          :style="{ backgroundColor: theme.secondaryColor, color: 'white' }"
          class="btn-defaultsite isNotDefault focus:outline-none font-medium rounded-full text-xs px-2 py-1 mr-2 mb-2"
          @click.prevent="onSetDefault(item)"
        >
          Default
        </button>
      </div>
    </div>

    <template v-if="item.children">
      <DepotTree
        v-for="depot in item.children"
        :key="depot._id"
        :item="depot"
        :depth="depth + 1"
        @tick="onTick($event)"
        @default="onSetDefault($event)"
        @born="handleBorn()"
      />
    </template>
  </div>
</template>

<style scoped>
.btn-defaultsite {
  display: none;
}
.isNotDefault {
  @apply bg-white text-gray-900 !important;
}
.depot-checkbox:checked .btn-defaultsite {
  display: block;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DepotTree",

  props: {
    item: { type: Object, required: true },
    depth: { type: Number, default: 0 },
  },

  data() {
    return {
      childrenCount: 0,
    };
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),

    direct() {
      if (Array.isArray(this.item.children)) return this.item.children.length;
      return 0;
    },

    offset() {
      return { "margin-left": this.depth * 35 + "px" };
    },
  },

  mounted() {
    this.$emit("born");
  },

  methods: {
    getDepotRef(id) {
      return "ref" + id;
    },
    handleBorn() {
      this.childrenCount++;
      this.$emit("born");
    },
    onTick(item) {
      this.$emit("tick", item);
    },
    onSetDefault(item) {
      this.$emit("default", item);
    },
  },
};
</script>
