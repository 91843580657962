<template>
  <div class="h-full">
    <aside class="w-96">
      <div class="flex flex-row justify-between">
        <button class="w-1/2" @click.prevent="addBullet()">
          <font-awesome-icon icon="plus-circle" /> Add Bullet
        </button>

        <button class="w-1/2" @click.prevent="addWildCard()">
          <font-awesome-icon icon="plus-circle" /> Add Wildcard
        </button>
      </div>
      <div class="flex flex-col p-4">
        <div v-for="(fields, index) in content" :key="index">
          <div v-if="fields.type && fields.type === 'wildcard'">
            <div class="flex flex-row justify-between">
              <button @click.prevent="removeBullet(index)" class="pb-4">
                <font-awesome-icon :icon="['fas', 'trash']" />
              </button>
              <FormulateInput
                type="select"
                v-model="fields.value"
                :options="[
                  { value: '{{driverName}}', label: 'Driver Name' },
                  { value: '{{inductionDate}}', label: 'Induction Date' },
                  {
                    value: '{{accreditationNumber}}',
                    label: 'Accreditation Number',
                  },
                  // { value: '{{todayDate}}', label: 'Today(Date)' },
                  { value: '{{adminNumber}}', label: 'Admin Number' },
                ]"
                class="w-10/12"
              />
            </div>
          </div>
          <div v-else>
            <div class="flex flex-row justify-between">
              <button @click.prevent="removeBullet(index)" class="pb-4">
                <font-awesome-icon :icon="['fas', 'trash']" />
              </button>
              <FormulateInput
                type="text"
                v-model="fields.value"
                class="w-10/12"
              />
            </div>
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    selectedType: {
      type: String,
    },

    selectedUUID: {
      type: String,
    },
  },

  computed: {
    docParts: {
      get() {
        return this.$store.state.accreditation.docParts;
      },
      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    content: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.selectedUUID;
          }
        );

        return model.content;
      },
    },

    contentVariables: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.selectedUUID;
          }
        );

        return model?.contentVariables;
      },
    },
  },

  watch: {
    selectedUUID: function (data) {
      this.fetchCurrentPart(data);
    },
  },

  mounted() {
    if (this.selectedUUID) {
      this.fetchCurrentPart(this.selectedUUID);
    }
  },

  data() {
    return {
      currentContainerVariables: [],
      currentContent: "",
      currentSelectedPart: false,
      currentSelectedIndex: -1,
    };
  },

  methods: {
    fetchCurrentPart(partUUID) {
      const currentPart = _.find(this.docParts, (part) => {
        return part.uuid == partUUID;
      });

      if (currentPart) {
        const currentIndex = _.findIndex(this.docParts, (part) => {
          return part.uuid == partUUID;
        });

        this.currentSelectedPart = currentPart;
        this.currentSelectedIndex = currentIndex;
      } else {
        this.currentSelectedPart = false;
        this.currentSelectedIndex = -1;
      }
    },

    addWildCard() {
      if (this.docParts[this.currentSelectedIndex].content.length < 5) {
        this.docParts[this.currentSelectedIndex].content.push({
          value: " {{variable}}",
          type: "wildcard",
        });
      } else {
        this.toast("error", "Only 5 bullets per variable");
      }
    },

    addBullet() {
      if (this.docParts[this.currentSelectedIndex].content.length < 5) {
        this.docParts[this.currentSelectedIndex].content.push({
          value: "",
        });
      } else {
        this.toast("error", "Only 5 bullets per variable");
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    removeBullet(index) {
      this.content.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
