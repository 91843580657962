<template>
  <div class="mb-16">
        <div class="loading centered" v-if="loading">
            <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <div v-if="!loading">
            <div class="text-center mb-10">
                <div class="flex text-right justify-end"> 
                    <a @click.prevent="onClickEdit()" class="flex cursor-pointer"><font-awesome-icon icon="edit" class="my-auto mx-1.5 hover:text-orange text-xs"/></a>
                    <a @click.prevent="onClickDelete()" class="flex cursor-pointer"><font-awesome-icon icon="trash" class="my-auto mx-1.5 hover:text-orange text-xs"/></a>
                </div>
                <div class="font-bold text-gray-900 text-2xl"> 
                    {{techprovider.name}}
                </div>
                <div class="font-bold text-xs" :class="[techprovider.isActive ? 'text-green-500' : 'text-red-500']">{{techprovider.isActive ? 'ACTIVE' : 'INACTIVE'}}</div>
            </div>
            <div class="mb-5 w-full border-b pb-1">
                <div class="text-xs font-bold">Details</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-gray-900 text-base">{{techprovider.inboundApiKey}}</div>
                <div class="text-xs">Inbound API Key</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-gray-900 text-base">{{techprovider.outboundApiKey}}</div>
                <div class="text-xs">Outbound API Key</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-gray-900 text-base">{{techprovider.url}}</div>
                <div class="text-xs">URL</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TechProvider-Details-View",
    props: {
        detailsId:{
            type: String,
            default: '',
        }
    },
    data() {
        return {
            techprovider: {},
            loading: false
        }
    },
    async mounted() {
        this.loading = true
        const techProviderDetails = await this.$store.dispatch(`techprovider/getTechProvider`, {id: this.detailsId})
        if(techProviderDetails){
            this.techprovider = this.parseTechProvider(techProviderDetails)
            this.loading = false
        }
    },
    methods: {
        parseTechProvider(techprovider) {
            return {
                name: techprovider.name,
                inboundApiKey: techprovider.inboundApiKey,
                outboundApiKey: techprovider.outboundApiKey,
                url: techprovider.url,
                isActive: techprovider.isActive
            }
        },
        onClickEdit() {
            this.$emit('editTechProvider', this.detailsId)
        },
        onClickDelete() {
            this.$emit('deleteTechProvider', this.detailsId)
        }
    }
}
</script>

<style>

</style>