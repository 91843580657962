<template>
  <div>
    <div
      v-if="loading"
      class="loading absolute mx-auto my-24 w-full h-full translate-y-2/4 flex flex-col items-center"
    >
      <ui-spinner class="mx-auto mb-3"></ui-spinner>
      Loading....
    </div>
    <div v-if="!loading">
      <hr />

      <div v-if="isDriverFromDB">
        <section>
          <div class="flex items-center w-full mt-3 border-gray-300">
            <div class="mr-3 w-60 pt-1">
              <label class="font-medium text-sm">Select data to link:</label>
              <FormulateInput
                name="formType"
                type="select"
                style="margin-bottom: 0px"
                :clearable="false"
                autocomplete="off"
                :options="linkDataTypeOption"
                v-model="linkDataType"
                @change.prevent="selectDataType"
              />
            </div>

            <div class="mr-3 w-60">
              <label class="font-medium text-sm mb-1">Date range:</label>
              <DatePickerWithRange
                ref="childDatePicker"
                @selected="selectedDateRange"
                :date="selectedDateRangeData"
              />
            </div>
          </div>
          <div v-if="!mainLoading">
            <div
              v-if="!hasResult && !hasLinkedData"
              class="flex h-32 items-center pb-4 pl-9 mb-40 bg-gray-100 rounded"
            >
              <font-awesome-icon
                icon="info-circle"
                size="2x"
                class="text-blue"
              />
              <strong class="ml-1 uppercase font-semibold text-sm"
                >&nbsp; Search report.</strong
              >
            </div>

            <div
              v-if="hasResult"
              class="items-center p-4 bg-gray-100 rounded overflow-y-auto h-96"
            >
              <button
                @click="closeSearchBox"
                title="Reset fields"
                class="text-red-900 float-right pb-8"
              >
                <span class="cursor-pointer">
                  <font-awesome-icon icon="times" class="text-gray-400" />
                </span>
              </button>
              <h3>Result found ({{ resultFoundCount }})</h3>

              <div
                class="overflow-x-auto relative shadow-md sm:rounded-lg mt-5"
              >
                <table
                  class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                >
                  <thead
                    class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                  >
                    <tr>
                      <th scope="col" class="py-3 px-6">Date Submitted</th>
                      <th scope="col" class="py-3 px-6">Status</th>
                      <th scope="col" class="py-3 px-6 text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody v-if="commonData.length > 0">
                    <tr
                      v-for="(item, index) in commonData"
                      :key="index"
                      class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <!-- Fit to drive and prestart -->
                      <td
                        v-if="
                          linkDataType == PRESTART ||
                          linkDataType == FITNESS_DECLARATION
                        "
                        class="py-4 px-6"
                      >
                        {{ cleanDateFormat(item.savedData.date) }}
                      </td>
                      <td
                        v-if="
                          linkDataType == PRESTART ||
                          linkDataType == FITNESS_DECLARATION
                        "
                        class="py-4 px-6"
                      >
                        <div
                          class="font-bold text-xs"
                          :class="[
                            item.isPassed ? 'text-green-500' : 'text-red-500',
                          ]"
                        >
                          {{ item.isPassed ? "PASSED" : "FAILED" }}
                        </div>
                      </td>

                      <!-- Breach -->
                      <td v-if="linkDataType == BREACH" class="py-4 px-6">
                        {{ cleanDateFormat(item.time) }}
                      </td>
                      <td v-if="linkDataType == BREACH" class="py-4 px-6">
                        <div class="font-bold text-xs text-red-500">
                          {{ item.level }}
                        </div>
                      </td>

                      <!-- Event -->
                      <td v-if="linkDataType == EVENT" class="py-4 px-6">
                        {{ cleanDateFormatShort(item.startDate) }} to
                        {{ cleanDateFormatShort(item.endDate) }}
                      </td>
                      <td v-if="linkDataType == EVENT" class="py-4 px-6">
                        <div class="font-bold text-xs text-red-500">
                          {{ item.status }}
                        </div>
                      </td>

                      <td class="py-2 text-center">
                        <button
                          @click="selectCommonData(item)"
                          class="h-8 px-4 m-2 text-sm text-indigo-100 transition-colors duration-150 btn-primary rounded focus:shadow-outline"
                        >
                          Select
                        </button>
                      </td>
                    </tr>
                  </tbody>

                  <tbody v-if="!commonData.length">
                    <tr class="border-b border-gray-200 dark:border-gray-700">
                      <td colspan="9" class="py-8 px-5 text-sm text-center">
                        No items found
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="mainLoading" class="relative">
            <div class="mx-auto my-24 w-full">
              <ui-spinner class="mx-auto mb-3"></ui-spinner>
            </div>
          </div>
        </section>
        <br />
        <hr />

        <section v-if="hasLinkedData">
          <div class="flex w-full">
            <aside class="w-36 border-r h-40">
              <div class="overflow-y-auto flex flex-col bg-gray-100 border-b">
                <strong class="py-4 pl-4 text-gray-500 uppercase text-sm"
                  >Linked ({{ linkedData.length }})</strong
                >
                <hr />
              </div>

              <div v-if="!asideLoading">
                <div
                  v-if="linkedData.length && activeLinkedData"
                  class="w-full overflow-y-auto h-60"
                >
                  <div
                    class="text-sm py-3 pl-3 border-b cursor-pointer"
                    v-for="(data, key) in linkedData"
                    :key="key"
                    :class="[
                      activeLinkedData._id == data._id ? 'bg-green-100' : '',
                    ]"
                    @click="setActiveLinked(data)"
                  >
                    <span
                      v-if="data.isPassed != null"
                      :class="[
                        data.isPassed ? 'text-green-800' : 'text-red-800',
                      ]"
                      >{{
                        cleanLinkedDataType(data.linkDataType).substring(0, 6) +
                        "..."
                      }}
                    </span>
                    <span v-else
                      >{{
                        cleanLinkedDataType(data.linkDataType).substring(0, 6) +
                        "..."
                      }}
                    </span>
                    <FolderIcon :a="activeLinkedData._id" :b="data._id" />
                  </div>
                </div>
                <div
                  v-if="!linkedData.length"
                  class="w-full px-1 py-2 border-b border-gray-300 opacity-40"
                >
                  <small><em>No linked found</em></small>
                </div>
              </div>

              <div v-if="asideLoading">
                <div class="loading translate-y-2/4 mt-12">
                  <ui-spinner class="mx-auto mb-3"></ui-spinner>
                </div>
              </div>
            </aside>

            <div class="h-full w-full bg-gray-100">
              <div v-if="!activeLinkedLoading">
                <div v-if="activeLinkedData" class="pb-4 px-5 pt-3">
                  <button
                    @click="removeActiveLinkedData"
                    title="Remove linked data"
                    class="text-red-900 float-right"
                  >
                    <span class="cursor-pointer">
                      <font-awesome-icon
                        icon="trash"
                        class="text-gray-400 hover:text-orange-primary"
                      />
                    </span>
                  </button>
                  <div class="mt-3 min-h-60">
                    <div
                      v-if="
                        activeLinkedData.linkDataType == FITNESS_DECLARATION
                      "
                    >
                      <FitnessDeclarationPartialView
                        :activeFitnessDeclaration="activeLinkedData"
                      />
                    </div>
                    <div v-if="activeLinkedData.linkDataType == PRESTART">
                      <PrestartPartialView
                        :activePreStartCheck="activeLinkedData"
                      />
                    </div>
                    <div v-if="activeLinkedData.linkDataType == BREACH">
                      <BreachDetailsPartialView
                        :activeBreach="activeLinkedData"
                      />
                    </div>

                    <div v-if="activeLinkedData.linkDataType == EVENT">
                      <EventBasicView :activeEvent="activeLinkedData" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />
      </div>
      <div v-if="!isDriverFromDB">
        <div class="flex h-32 items-center pb-4 pl-9 mb-40 bg-gray-100 rounded">
          <font-awesome-icon icon="info-circle" size="2x" class="text-blue" />
          <strong class="ml-1 uppercase font-semibold text-sm"
            >&nbsp; Link data is not availble for the selected driver.
          </strong>
        </div>
      </div>
      <br />
    </div>

    <FormulateInput
      type="submit"
      label="Next"
      input-class="btn btn-primary w-full"
      @click="nextStep"
      :style="{ backgroundColor: theme.secondaryColor }"
    />

    <button class="btn btn-cancel w-full mb-2" @click.prevent="onPrevious()">
      Back
    </button>
  </div>
</template>
<script>
import DatePickerWithRange from "@/components/commons/field/DatePickerWithRange";
import FitnessDeclarationPartialView from "@/components/view/business/fitness-declaration/fitness-declaration-partial-view";
import PrestartPartialView from "@/components/view/business/prestart/prestart-partial-view";
import BreachDetailsPartialView from "@/components/view/breach/breach-partial-view";
import EventBasicView from "@/components/view/compliance/event-basic-view";
import moment from "moment";
import { DATA_TYPES } from "@/_helper/constants";
import _ from "lodash";
import { removeStringUnderscore, cleanDateFormat } from "@/_helper";
import FolderIcon from "@/components/commons/icons/folder-icon-open-close";
import { HELPER } from "@/api/helper";
import { mapGetters } from "vuex";

export default {
  name: "Linking-Data",
  components: {
    DatePickerWithRange,
    FitnessDeclarationPartialView,
    PrestartPartialView,
    BreachDetailsPartialView,
    EventBasicView,
    FolderIcon,
  },
  props: {
    preData: null,
    postData: null,
  },
  data() {
    return {
      loading: true,
      activeLinkedLoading: false,
      asideLoading: false,
      mainLoading: false,
      interChangingData: [],
      linkDataType: "fitness_declaration",
      linkDataTypeOption: [
        { value: DATA_TYPES.FITNESS_DECLARATION, label: "Fitness Declaration" },
        { value: DATA_TYPES.BREACH, label: "Breach Detection" },
        { value: DATA_TYPES.PRESTART, label: "Prestart" },
        { value: DATA_TYPES.EVENT, label: "Event" },
      ],
      hasResult: false,
      isDirty: true,
      commonData: [],
      resultFoundCount: 0,
      selectedCommonData: null,
      selectedDateRangeData: [],
      FITNESS_DECLARATION: DATA_TYPES.FITNESS_DECLARATION,
      PRESTART: DATA_TYPES.PRESTART,
      BREACH: DATA_TYPES.BREACH,
      EVENT: DATA_TYPES.EVENT,
      showFormButtons: false,
      activeLinkedData: null,
      linkedData: [],
      hasLinkedData: false,
      isDriverFromDB: false,
    };
  },
  mounted() {
    let data = this.preData || this.postData || null;
    if (data.steps) {
      this.driverDetails = data.steps?.driverDetails;
      this.activeLinkedData =
        data.steps?.linkedDataDetails?.activeLinkedData || null;
      this.linkedData = data.steps?.linkedDataDetails?.linkedData || [];
      this.interChangingData = data;
      if (!_.isEmpty(this.linkedData)) this.hasLinkedData = true;
      if (!_.isEmpty(this.driverDetails.driverName)) this.isDriverFromDB = true;
    }
    setTimeout(() => (this.loading = false), 300);
  },
  methods: {
    selectDataType() {
      if (!this.selectedDateRangeData.length) {
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "error",
          message: "Date range is required.",
        });
        return false;
      }
      this.selectedDateRange(this.selectedDateRangeData);
    },
    async selectedDateRange(selectedDateRangeData) {
      this.mainLoading = true;

      const me = this.$store.getters[`account/me`];
      const businessId = me?.business?._id;

      const payload = {
        businessId: businessId,
        driverId: this.driverDetails?.driver,
        startDate: moment(selectedDateRangeData[0], "YYYY-MM-DD").format(),
        endDate: moment(selectedDateRangeData[1], "YYYY-MM-DD").format(),
      };

      let eventByDateRange, eventByDateRangeTrimmed, resultFoundCount;

      switch (this.linkDataType) {
        case this.FITNESS_DECLARATION:
          this.commonData = await this.$store
            .dispatch(`driver/getFitToDriveByDateRange`, payload)
            .catch((err) => {
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: err.message,
              });
            });
          resultFoundCount = !_.isEmpty(this.commonData)
            ? this.commonData.length
            : 0;
          break;
        case this.PRESTART:
          this.commonData = await this.$store
            .dispatch(`vehicle/getPrestartByDateRange`, payload)
            .catch((err) => {
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: err.message,
              });
            });
          resultFoundCount = !_.isEmpty(this.commonData)
            ? this.commonData.length
            : 0;
          break;
        case this.BREACH:
          payload.from = payload.startDate;
          payload.to = payload.endDate;
          payload.entityId = payload.businessId;
          this.commonData = await this.$store
            .dispatch(`report/getBreachesByDateRange`, payload)
            .catch((err) => {
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: err.message,
              });
            });
          resultFoundCount = !_.isEmpty(this.commonData)
            ? this.commonData.length
            : 0;
          break;
        case this.EVENT:
          payload.from = payload.startDate;
          payload.to = payload.endDate;
          eventByDateRange = await this.$store
            .dispatch(`business/getEventByDateRange`, payload)
            .catch((err) => {
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: err.message,
              });
            });

          eventByDateRange.map((item) => ({
            _id: item._id,
            status: item.status,
            type: item.type,
            created: item.startTimeWithTimezone,
          }));

          eventByDateRangeTrimmed = {
            _id: HELPER.getUUIDv4(),
            status: "-",
            events: eventByDateRange,
            totalEventsFound: eventByDateRange.length,
            ...payload,
          };
          if (eventByDateRange.length) {
            this.commonData = [eventByDateRangeTrimmed];
          } else {
            this.commonData = [];
          }

          resultFoundCount = !_.isEmpty(this.commonData)
            ? eventByDateRange.length
            : 0;
          break;
      }
      this.resultFoundCount = resultFoundCount;
      this.showFormButtons = false;
      setTimeout(() => {
        this.mainLoading = false;
        this.hasResult = true;
      }, 300);
      this.selectedDateRangeData = [
        new Date(moment(selectedDateRangeData[0])),
        new Date(moment(selectedDateRangeData[1])),
      ];
    },
    cleanDateFormat(date) {
      return cleanDateFormat(date);
    },
    cleanDateFormatShort(date) {
      return cleanDateFormat(date, true);
    },
    async selectCommonData(item) {
      let existing = this.linkedData.filter(
        (index) => index._id == item._id
      )[0];
      if (!_.isEmpty(existing)) {
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "error",
          message: "Data already linked.",
        });
        return false;
      }
      if (!this.activeLinkedData) {
        this.setActiveLinked(item, false);
      }
      this.linkedData.push(item);
      item.linkDataType = this.linkDataType;
      this.hasLinkedData = true;
      this.reloadAside();
    },
    async setActiveLinked(item, fromAside = true) {
      this.activeLinkedLoading = true;
      let linkDataType = fromAside ? item.linkDataType : this.linkDataType;
      switch (linkDataType) {
        case this.FITNESS_DECLARATION:
          this.activeLinkedData = item;
          if (this.activeLinkedData)
            this.activeLinkedData.linkDataType = this.FITNESS_DECLARATION;
          break;

        case this.PRESTART:
          this.activeLinkedData = await this.$store
            .dispatch(`vehicle/getPrestartById`, item._id)
            .catch((err) => {
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: err.message,
              });
            });
          if (this.activeLinkedData)
            this.activeLinkedData.linkDataType = this.PRESTART;
          break;

        case this.BREACH:
          this.activeLinkedData = item;
          if (this.activeLinkedData)
            this.activeLinkedData.linkDataType = this.BREACH;
          break;

        case this.EVENT:
          this.activeLinkedData = item;
          if (this.activeLinkedData)
            this.activeLinkedData.linkDataType = this.EVENT;
          break;
      }
      fromAside ? (this.hasResult = false) : "";
      setTimeout(() => {
        this.activeLinkedLoading = false;
      }, 500);
    },
    removeActiveLinkedData() {
      this.linkedData = this.linkedData.filter(
        (index) => index._id != this.activeLinkedData._id
      );
      if (this.linkedData.length) {
        this.setActiveLinked(this.linkedData[0]);
      } else this.activeLinkedData = null;
      this.hasResult = false;
      this.reloadAside();
    },
    closeSearchBox() {
      this.hasResult = false;
    },
    cleanLinkedDataType(str) {
      return removeStringUnderscore(str);
    },
    reloadAside() {
      this.asideLoading = true;
      setTimeout(() => {
        this.asideLoading = false;
      }, 200);
    },
    showFormButtonsBtn() {
      this.showFormButtons = !this.showFormButtons;
    },
    parseLinkedDataDetails() {
      return {
        activeLinkedData: this.activeLinkedData,
        linkedData: this.linkedData,
      };
    },
    onPrevious() {
      const linkedDataDetails = this.parseLinkedDataDetails();
      const preData = {
        ...this.interChangingData,
        steps: {
          ...this.interChangingData.steps,
          ...{ linkedDataDetails: linkedDataDetails },
        },
      };
      this.$emit("backStep", preData);
    },
    nextStep() {
      const linkedDataDetails = this.parseLinkedDataDetails();
      const preData = {
        ...this.interChangingData,
        steps: {
          ...this.interChangingData?.steps,
          ...{ linkedDataDetails: linkedDataDetails },
        },
      };
      this.$emit("nextStep", preData);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
