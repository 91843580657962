<template>
  <div class="mb-16">
    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
    <FormulateForm
      v-model="values"
      :schema="conditionalSchema"
      @submit="onSubmit"
      #default="{ hasErrors }"
    >
    <StyledSubmit :backgroundColor="theme.secondaryColor">
      <FormulateInput
        type="submit"
        label="Submit"
        input-class="btn btn-primary w-full"
        :disabled="hasErrors || loading"
      />
    </StyledSubmit>
    </FormulateForm>
  </div>
</template>

<script>
import schema from "./new-role-schema";
import { mapGetters } from 'vuex'
import {StyledSubmit} from '@/styled-components'

export default {
  name: "New-Role-Form",
  props: {
    msg: String,
  },
  components: {StyledSubmit},
  data() {
    return {
      error: false,
      errorMessage: "",
      loading: false,
      values: {},
      schema: schema,
    };
  },
  methods: {
    async onSubmit() {
      try {
        const newRole = JSON.parse(JSON.stringify(this.values));
        await this.$store.dispatch(`rolesandrespo/createRole`, newRole);

        location.reload();
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) this.errorMessage = error.message[0];
      }
    },
  },
  computed: {
    conditionalSchema() {
      return schema;
    },
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
</style>
