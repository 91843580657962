<template>
    <div class="page-container px-2">
        <div class="w-full" v-if="loading">
            <div class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center">
                <Spinner class="mx-auto mb-2"></Spinner>
                <span class="font-semibold text-gray-300">Loading...</span>
            </div>
        </div>
        
        <div class="w-full flex h-10">
            <StyledButton
                @click.prevent="goToDate(true)"
                :disabled="disabledPrevBtn"
                :borderColor="theme.secondaryColor"
                :hoverBorderColor="theme.secondaryColor"
                class="px-3 text-sm text-gray-300 border-2 border-gray-300 disabled:opacity-50"
            >
                <font-awesome-icon icon="chevron-left" />
            </StyledButton>
            <div
                class="manual-event-date flex-1 text-center justify-center border-2 border-gray-300 border-l-0 border-r-0 text-gray-300 text-sm relative leading-10"
                :class="[ value.isActive ? 'active' : 'hidden', `manual-event-date-${value.dateOfSheet}` ]"
                :style="{ color: theme.secondaryColor }"
                v-for="value in Object.values(values)"
                :key="value.dateOfSheet"
            >
                <span>{{ formatDate(value.dateOfSheet, 'MMM DD, YYYY') }}</span>
            </div>
            <StyledButton
                @click.prevent="goToDate(false)"
                :disabled="disabledNextBtn"
                :borderColor="theme.secondaryColor"
                :hoverBorderColor="theme.secondaryColor"
                class="px-3 text-sm text-gray-300 border-2 border-gray-300 disabled:opacity-50"
            >
                <font-awesome-icon icon="chevron-right" />
            </StyledButton>
        </div>
        
        <div class="flex overflow-x-hidden">
            <div
                class="manual-event-container w-full"
                v-for="value in Object.values(values)"
                :class="[ value.isActive ? 'active' : 'hidden', `manual-event-date-${value.dateOfSheet}` ]"
                :key="value.dateOfSheet"
            >
                <div
                    :id="`childLhv${value.dateOfSheet.replace(/-/g, '')}`"
                    class="manual-events inline-block w-full"
                ></div>

                <Tabs class="manual-event-tabs mt-3">
                    <Tab title="Events">
                        <div class="overflow-x-auto border-2 border-gray-300 mt-3">
                            <table class="min-w-full">
                                <thead class="bg-gray-300">
                                    <tr class="text-gray-700 text-xs tracking-wider uppercase">
                                        <th scope="col" class="py-3 px-5 text-center">Activity</th>
                                        <th scope="col" class="py-3 px-5 text-center">
                                            Time of<br />Activity
                                        </th>
                                        <th scope="col" class="py-3 px-5 text-left">Location</th>
                                        <th scope="col" class="py-3 px-5 text-center">Odometer</th>
                                        <th scope="col" class="py-3 px-5 text-center">Vehicle</th>
                                        <th
                                            scope="col"
                                            class="py-3 px-5 text-center"
                                            style="min-width: 130px"
                                        >
                                            Work and<br />Rest Option
                                        </th>
                                        <th scope="col" class="py-3 px-5 text-left">Comments</th>
                                        <th scope="col" class="py-3 px-5 text-center">Origin</th>
                                        <th scope="col" class="py-3 px-5 text-left">Entry Timestamp</th>
                                        <th scope="col" class="py-3 px-5 text-left">Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-show="value.events.length > 0"
                                        v-for="(event, index) in value.events"
                                        :key="index"
                                        class="bg-white border-b text-xs text-gray-500 font-medium"
                                    >
                                        <td class="py-3 px-5 text-center text-black-primary capitalize">
                                            {{ event.type }}
                                        </td>
                                        <td class="py-3 px-5 text-center">
                                            {{ event.timeOfActivity }}
                                        </td>
                                        <td class="py-3 px-5">
                                            {{ event.locationAddress }}
                                        </td>
                                        <td class="py-3 px-5 text-center">
                                            {{ event.odometer }}
                                        </td>
                                        <td class="py-3 px-5 text-center">
                                            {{ event.vehicle }}
                                        </td>
                                        <td class="py-3 px-5 text-center uppercase">
                                            {{ event.fatiguePlan }}
                                        </td>
                                        <td class="py-3 px-5">
                                            {{ event.comment }}
                                        </td>
                                        <td class="py-3 px-5 text-center capitalize">
                                            {{ event.origin }}
                                        </td>
                                        <td class="py-3 px-5">
                                            {{ event.originalStartTime }}
                                        </td>
                                        <td class="py-3 px-5 uppercase">
                                            {{ event.status }}
                                        </td>
                                    </tr>
                                    <tr v-show="value.events.length == 0">
                                        <td colspan="10" class="py-3 px-5 text-center text-sm">
                                            No events found.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Tab>
                    <Tab title="Breaches" :badge="value.breaches.length">
                        <div class="overflow-x-auto border-2 border-gray-300 mt-2">
                            <table class="min-w-full">
                                <thead class="bg-gray-300">
                                    <tr class="text-gray-700 text-xs tracking-wider uppercase">
                                    <th scope="col" class="py-3 px-5 text-center">Breach Time</th>
                                    <th scope="col" class="py-3 px-5 text-left">Option</th>
                                    <th scope="col" class="py-3 px-5 text-left">Rule</th>
                                    <th scope="col" class="py-3 px-5 text-left">Rule Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-show="value.breaches.length > 0"
                                        v-for="(breach, index) in value.breaches"
                                        :key="index"
                                        class="bg-white border-b text-xs text-gray-500 font-medium"
                                    >
                                        <td class="py-3 px-5 text-center text-black-primary capitalize">
                                            {{ formatTime(breach.time, breach.timezone) }}
                                        </td>
                                        <td class="py-3 px-5">
                                            {{ breach.option }}
                                        </td>
                                        <td class="py-3 px-5">
                                            {{ breach.period }}
                                        </td>
                                        <td class="py-3 px-5">
                                            {{ breach.type }}
                                        </td>
                                    </tr>
                                    <tr v-show="value.breaches.length == 0">
                                        <td colspan="10" class="py-3 px-5 text-center text-sm">
                                            No breaches found.
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>

        <div class="mt-4">
            <h6 class="text-gray-500 text-sm font-bold py-1 border-b-2 border-orange-primary inline">Uploaded Document</h6>
        </div>
        <div class="mt-2">
            <span v-if="!attachedFile" class="text-sm">No document found.</span>
            <a 
                v-if="attachedFile" 
                :href="attachedFile" 
                class="flex hover:text-orange-primary text-sm" 
                target="_blank"
            >
                <font-awesome-icon icon="file-alt" class="my-auto mx-1.5"/> 
                {{ formatFileName(attachedFile) }}
            </a>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import {getStateSubInAddress} from '@/_helper';
import Tab from '@/components/commons/tab/Tab';
import Tabs from '@/components/commons/tab/Tabs';
import Spinner from '@/components/commons/ui/Spinner';
import LocationData from '@/assets/location/location.json';
import LogmasterManualEvents from '@logmaster/logmaster-manual-events';
import { StyledButton } from '@/styled-components';
import { mapGetters } from 'vuex';

export default {
    name: "EventDetailsView",
    props: {
      eventsData:{
        type: Object,
        default: null,
      }
    },
    components: { Spinner, Tabs, Tab, StyledButton },
    data() {
        return {
            loading: false,
            attachedFile: null,
            values: [],
            dateFormat: 'YYYY-MM-DD',
            disabledPrevBtn: false,
            disabledNextBtn: false,
            dateRange: []
        }
    },
    async mounted() {
        const me = this.$store.getters[`account/me`];
        let businessId = me?.business?._id;
        if (me.role.name == 'driver') businessId = me[me.role.name].entityId;

        // Setup manual event styles
        const logmasterManualEvents = new LogmasterManualEvents();
        $('head').append(`<style type="text/css">${logmasterManualEvents.getStyles()}</style>`);
        
        this.loading = true;
        this.attachedFile = !_.isEmpty(this.eventsData['uploadedFileUrl']) ? this.eventsData['uploadedFileUrl'] : null;
                
        if (this.eventsData?.dateRange) {
            this.dateRange = this.eventsData.dateRange.split(' to ');
        } else {
            this.dateRange = [
                this.eventsData.startTimeWithTimezone,
                this.eventsData.startTimeWithTimezone
            ];
        }
        
        const from = moment(this.dateRange[0], this.dateFormat);
        const to = moment(this.dateRange[1], this.dateFormat);

        // Loop the date range first to dynamically add the dom elements
        for (var m = from; m.diff(to, 'days') <= 0; m.add(1, 'days')) {
            const currentDate = m.format(this.dateFormat);
            this.values[currentDate] = {
                ...this.eventsData,
                isActive: false,
                dateOfSheet: currentDate,
                breaches: [],
                events: []
            }
        }

        // Set first value as active
        const values = Object.values(this.values);
        values[0].isActive = true;
        this.disabledPrevBtn = true;
        this.disabledNextBtn = values.length > 1 ? false : true;

        // After dom elements were created in a loop, initialize manual event to each of those element
        for (let value of Object.values(this.values)) {
            const currentDate = value.dateOfSheet;
            const requestData = {
                filter: { entityId: businessId, driverId: this.eventsData.driverId },
                driverId: this.eventsData.driverId,
                from: moment(currentDate, 'YYYY-MM-DD').format(),
                to: moment(currentDate, 'YYYY-MM-DD').format()
            }

            await Promise.all([
                this.$store.dispatch(`business/getEventByDateRange`, requestData),
                this.$store.dispatch(`business/getPreviousEvent`, requestData),
                this.$store.dispatch(`report/getBreachesByDateRange`, requestData)
            ]).then(data => {
                value.events = this.formatEvent(data[0].reverse());

                const previousEvent = data[1];
                const previousEventType = !_.isEmpty(previousEvent) ? previousEvent.type : 'rest';

                value.manualEvent = $(`#childLhv${currentDate.replace(/-/g, '')}`);
                value.manualEvent.ManualEvent({
                    isNZMode: true,
                    isViewMode: true,
                    isDateVisible: true,
                    locationData: LocationData,
                    currentDate: currentDate,
                    currentEvents: value.events,
                    previousEventType,
                    previousEvent
                });

                value.breaches = !_.isEmpty(data[2]) ? data[2] : [];
                value.manualEvent.ManualEvent('setBreaches', value.breaches);
            });
        }
        
        this.loading = false;
    },
    methods: {
        formatDate(date) {
            return moment(date, this.dateFormat).format('MMMM DD, YYYY')
        },
        isDateNotBefore(date) {
            return moment(date, this.dateFormat).isBefore(this.dateRange[0]);
        },
        isDateNotAfter(date) {
            return moment(date, this.dateFormat).isAfter(this.dateRange[1]);
        },
        isPrevDisable(dateValue) {
            return this.isDateNotBefore(moment(dateValue, this.dateFormat).subtract(1, 'days').format(this.dateFormat));
        },
        isNextDisable(dateValue) {
            return this.isDateNotAfter(moment(dateValue, this.dateFormat).add(1, 'days').format(this.dateFormat))
                || moment().format(this.dateFormat) == moment(dateValue, this.dateFormat).format(this.dateFormat)
        },
        goToDate(isPrevDate = false) {
            let currentActive = Object.values(this.values).find(x => x.isActive);

            const newDate = isPrevDate ? moment(currentActive.dateOfSheet, this.dateFormat).subtract(1, 'days') : moment(currentActive.dateOfSheet, this.dateFormat).add(1, 'days');
            const activeDate = newDate.format(this.dateFormat);
            
            // Set current active to false, send new value as active
            currentActive.isActive = false;
            this.values[activeDate].isActive = true;
            $(`.manual-event-date-${currentActive.dateOfSheet}`).hide();
            $(`.manual-event-date-${this.values[activeDate].dateOfSheet}`).show();
            this.setNavigationButton(this.values[activeDate].dateOfSheet);
        },
        setNavigationButton(date) {
            this.disabledPrevBtn = this.isPrevDisable(date) ? true : false;
            this.disabledNextBtn = this.isNextDisable(date) ? true : false;
        },
        formatFileName(filename) {
            let file = filename.split('/');
            file = file[file.length - 1];
            return `${file.split('.')[0].substr(0, 55)}.${file.split('.')[1]}`;
        },
        formatEvent(events) {
            let formattedEvents = [];
            events.forEach(event => {
                const location = !_.isEmpty(event['location']['address']) ? event['location']['address'] : event['location']['formatted_address'];
                const rawLocation = getStateSubInAddress(location);
                event['suburb'] = !_.isEmpty(rawLocation) ? rawLocation['suburb'] : null;
                event['locationAddress'] = !_.isEmpty(event['suburb']) ? event['suburb'] : location;

                if (_.isEmpty(event['origin'])) event['origin'] = 'EWD';
                if (_.isEmpty(event['timezone'])) event['timezone'] = this.eventsData.timezone;
                const timezone = !_.isEmpty(event['timezone']) && event['timezone'].split(' ').length > 1 ? event['timezone'].split(' ')[1] : event['timezone'];
                event['offset'] = timezone ? timezone : '+10:00';
                event['timeOfActivity'] = moment(event.startTimeWithTimezone).utcOffset(event['offset']).format('HH:mm');

                if (_.isEmpty(event['date'])) event['date'] = moment(event.startTimeWithTimezone, 'YYYY-MM-DD').format('YYYY-MM-DD');
                
                formattedEvents.push(event);
            });
            return formattedEvents;
        },
        formatTime(time, timezone){
            return moment(time).utcOffset(timezone).format('YYYY-MM-DD HH:mm:ssZ')
        },
        cleanLevel(level){
            return _.startCase(level == 'no-breach' ? level.replace('-', ' ') : level.split('-')[0]);
        },
    },
    computed: {
        ...mapGetters('theme', {
            theme: 'getColorScheme'
        })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .page-container {
        min-height: calc(100vh - 136px);
    }
    .manual-events::v-deep {
        .timetable-container {
            @apply border-2 border-gray-300 rounded-b-none rounded-r-none;

            .manual-event-date {
                @apply border-r-0 rounded-none;
            }
            .timetable-header_work, .timetable-header_rest {
                @apply border-r-0;
            }

            #events-table .g-bg-blue-200 {
                @apply border-gray-300;
            }

           .ruler-text .time-block:last-child, 
           .ruler-text .time-block:first-child span {
                top: 2px !important
           }
        }
    }
    .manual-event-tabs::v-deep ul li button {
        @apply text-sm font-bold
    }
</style>