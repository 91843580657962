<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <FormulateInput
          type="text"
          name="holderName"
          label="Holder Name"
          validation="required"
        />

        <FormulateInput
          type="text"
          name="tslNumber"
          label="TSL Number"
          validation="required"
        />

        <FormulateInput
          label="TSL Type"
          name="tslType"
          type="general-select"
          element-class="mb-2 mt-1"
          placeholder="Select a TSL type"
          :clearable="false"
          :options="tslTypes"
          :reduce="(option) => option.value"
          validation="required"
        />

        <br />
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { TSL_TYPES } from '@/constants/tsl-type';
import { mapGetters } from 'vuex';

export default {
  name: 'create-tsl',

  data() {
    return {
      values: {},
      errorMessage: false,
      loading: false,
      tslTypes: TSL_TYPES,
    };
  },
  methods: {
    async onSubmit() {
        const me = this.$store.getters[`account/me`];
        const newTSL = JSON.parse(JSON.stringify(this.values));

        await this.$store.dispatch(`tsl/createTSL`, { entityId: me?.businessId, isActive: true, ...newTSL })
            .then(() => {
                this.toast("success", "TSL created.");
                this.loading = false;
                this.$emit('closeModal');
            })
            .catch((error) => {
                this.loading = false;
                this.errorMessage = `Something went wrong please try again.`;
                if (error.message) this.errorMessage = error.message;
                this.toast("error", this.errorMessage);
            });
    },
    toast(state, msg) {
        const message = {
            state: state,
            message: msg,
        };
        this.$store.commit('setDialogNotify', true);
        this.$store.commit('setMessageNotify', message);
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
};
</script>
