<template>
  <div class="mb-16">
    <FormulateForm
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
      #default="{ hasErrors }"
    >
      <div class="items-center justify-between">
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{backgroundColor: theme.secondaryColor}"
          />
        </StyledSubmit>
      </div>
    </FormulateForm>
    <FormulateForm @submit="onSubmitLicenseStatus" #default="{ hasErrors }">
      <FormulateInput
        v-model="isLicensed"
        type="select"
        label="Licenced"
        :disabled="hasErrors || loading"
        :options="licenseOptions"
      />
      <FormulateInput
        v-if="isLicensed == 0"
        v-model="unlicensedStartDate"
        type="date"
        label="Unlicenced Start Date"
        :min="new Date().toISOString().substr(0, 10)"
        :disabled="hasErrors || loading"
      />
      <div class="text-red-500 text-center mb-3" v-if="error">
        {{ errorMessage }}
      </div>
      <StyledSubmit :backgroundColor="theme.secondaryColor">
        <FormulateInput
          type="submit"
          label="Update"
          input-class="btn btn-primary w-full"
          :disabled="
            hasErrors || loading || (isLicensed == 0 && !unlicensedStartDate)
          "
          :style="{backgroundColor: theme.secondaryColor}"
        />
       </StyledSubmit>
    </FormulateForm>
  </div>
</template>

<script>
//import { convertAddressObject } from '@/_helper'
import schema from "./new-business-schema";
import { mapGetters } from 'vuex'
import {StyledSubmit} from '@/styled-components'

export default {
  name: "Edit-Business-Form",
  props: {
    businessId: {
      type: String,
      default: "",
    },
    msg: String,
  },
  components: {StyledSubmit}, 
  data() {
    return {
      loading: false,
      values: {
        persona: [
          {
            businessName: "Logmaster Business",
            tradingName: "Logmaster Trading",
            abn: "Test NZBN",
            businessAddress: [
              {
                street: "27 Arthur Street BURROWAY, New South Wales(NSW), 2821",
                city: "Richardchester",
                state: "NSW",
              },
            ],
            contactUserName: "User Name",
            contactEmail: "contact@email.com",
            contactPhoneNumber: "(023) 123 1324",
          },
        ],
        supportEmail: "support@email.com",
        supportPhoneNumber: "(023) 321 3214",
      },
      schema: schema,
      isLicensed: null,
      unlicensedStartDate: "",
      licenseOptions: [
        {
          value: 1,
          label: "Enabled",
        },
        {
          value: 0,
          label: "Disabled",
        },
      ],
      error: false,
      errorMessage: null,
    };
  },
  async mounted() {
    this.loading = true;
    const business = await this.$store.dispatch(
      "business/getBusiness",
      {id: this.businessId}
    );
    if (business) {
      this.unlicensedStartDate = business.unlicensedStartDate
      if (business.isLicensed) {
        this.isLicensed = 1;
      } else {
        this.isLicensed = 0;
      }
    }
    this.loading = false;
  },
  methods: {
    onSubmit() {
      //update function here
    },
    async onSubmitLicenseStatus() {
      try {
        const payload = {
          isLicensed: this.isLicensed == 1 ? true : false,
          unlicensedStartDate: this.unlicensedStartDate,
          businessId: this.businessId
        };
        await this.$store.dispatch(`business/updateLicenseStatus`, payload);
        location.reload();
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) {
          this.errorMessage = error.message;
        }
      }
    },
  },
   computed: {
      ...mapGetters('theme', {
      theme: 'getColorScheme'
      })
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
