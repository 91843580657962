<template>
    <div class="mb-16">
        <div class="loading centered" v-if="loading">
            <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <div v-if="!loading">
            <div class="text-center mb-10">
                <div class="font-bold text-black-primary text-2xl"> 
                    {{businessPersona.businessName}}
                </div>
                <div class="font-bold text-xs text-blue-300">{{status}}</div>
            </div>
             <div class="mb-5 w-full border-b pb-1">
                <div class="text-xs font-bold">Business Profile</div>
            </div>
             <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{businessPersona.businessAddress}}</div>
                <div class="text-xs">Address</div>
            </div>
             <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{businessPersona.contactEmail}}</div>
                <div class="text-xs">Contact Email</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{businessPersona.contactPhoneNumber}}</div>
                <div class="text-xs">Phone Number</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{businessPersona.tradingName}}</div>
                <div class="text-xs">Trading Name</div>
            </div>

            {{businessProfile}}
        </div>
    </div>
</template>
<script>
import {convertAddressObject} from '@/_helper'
export default {
    name: 'Driver-Details-View',
    props: {
        detailsId:{
            type: String,
            default: '',
        }
    },
    data(){
        return{
            driver: {},
            profile: {},
            businessPersona: {},
            businessProfile: {},
            status: '',
            businessid: '',
            driverid: '',
            loading : false
        }
    },
   async mounted(){
        this.loading = true
        const driverDetails = await this.$store.dispatch(`business/getContractById`, this.detailsId)
        if(driverDetails){
            this.businessPersona = driverDetails?.business?.persona
            this.businessProfile = driverDetails?.business?.profile
            this.status = driverDetails?.status
            console.log(' driverDetails?.business',  driverDetails);
            this.loading = false
        }
    },
    methods: {
        parseProfile(profile){
            return{
                baseLocation: convertAddressObject(profile.baseLocation) || "-",
                baseTimeZone: profile.baseTimeZone || "-",
                BFMNumber: profile.BFMNumber || "-",
                recordKeeperAddress: profile.recordKeeperAddress || "-",
            }
        }
    }

}
</script>