<template>
  <div class="mb-16">
    <div class="loading mt-32 h-32" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div> 
    <FormulateForm v-if="!loading" @submit="onSubmit">
      <div v-if="loading" class="text-center"><ui-spinner class="m-auto"></ui-spinner> Loading...</div>
      <div v-if="!loading && preData.accountInfo" class="items-center justify-between">

        <FormulateInput
          type="text"
          label="Username"
          name="contactUserName"
          :value="preData.accountInfo.contactUserName"
          validation="required:trim"
          aria-autocomplete="off"
          autocomplete="off"
          @keydown.space.prevent        
        />

        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
              type="text"
              label="Contact Email"
              name="contactEmail"
              :value="preData.accountInfo.contactEmail"
              validation="email"
              @keydown.space.prevent
              autocomplete="off"
            />

            <FormulateInput
              type="text"
              label="Contact Phone Number"
              name="contactPhoneNumber"
              error-behavior="live"
              :value="preData.accountInfo.contactPhoneNumber"
              validation="required:trim"
              @keydown.space.prevent
              autocomplete="off"
            />
        </div>
        <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
          <button class="btn col-span-2 btn-primary mt-2" :style="{backgroundColor: theme.secondaryColor}">Submit</button>
          <button @click.prevent="onPrevious" class="btn bg-transparent border col-span-2 btn-info mt-2">Back</button>
        </div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>
<script>
import schema from './connection-account-schema';
import _ from 'lodash';
import { mapGetters } from 'vuex'

export default {
  name: 'Edit-Account-Form',
  props: {
    msg: String,
    preData: null
  },
  data() {
    return {
      error:false,
      errorMessage: '',
      loading: false,
      values: {},
      schema: schema,
      accountInfo: {},
      interChangingData: []
    };
  },
  mounted(){
    this.interChangingData = this.preData ? this.preData: this.postData
    this.accountInfo = this.interChangingData.accountInfo
  },
  methods: {
    async onSubmit(data) {
      this.loading = true;
      const businessDetails = this.preData.businessDetails;
      const pricing = this.preData.pricing;
      const accountInfo = JSON.parse(JSON.stringify(this.values));
      if(businessDetails && accountInfo && pricing){
        const businessAddress = businessDetails.businessAddress;
        const conData = {
          persona: {
            _id: businessDetails.personaId,
            businessName: businessDetails.businessName,
            tradingName: businessDetails.tradingName,
            abn: businessDetails.abn,
            businessAddress,
            contactUserName: data.contactUserName,
            contactEmail: data.contactEmail,
            contactPhoneNumber: data.contactPhoneNumber
          },
          id:businessDetails.id,
          supportEmail: businessDetails.supportEmail,
          supportPhone: businessDetails.supportPhone
        }
        // const prepaid = this.preData.prepaid;
        let resCon, resConType;
        
        switch (this.preData.connectionType) {
          case 'partner':
            resCon = await this.$store.dispatch(`partner/updatePartner`, conData).catch(error => {
                this.loading = false
                this.errorMessage = error?.message
            });
            resConType = 'partner';
            break;

          case 'reseller':
            resCon = await this.$store.dispatch(`reseller/updateReseller`, conData).catch(error => {
                this.loading = false
                this.errorMessage = error?.message
            });
            resConType = 'reseller';
            break;
        }
      
        if(resCon && resCon._id){
          const buyPricingPayload = this.parsePricingPayload(pricing, resCon, resConType, false)
          const RRPPayload = this.parsePricingPayload(pricing, resCon, resConType, true)
          // const buyPricingPreapaidPayload = this.parsePrepaidPricingPayload(prepaid, resCon, resConType, false)
          // const RRPPrepaidPayload = this.parsePrepaidPricingPayload(prepaid, resCon, resConType, true)

          const mergedPayload = this.parseMergedPayload([
              ...buyPricingPayload,
              ...RRPPayload,
              // ...buyPricingPreapaidPayload,
              // ...RRPPrepaidPayload
            ]);

          try {
            this.$store.dispatch(`standardpricing/updateStandardPricing`, mergedPayload).then(() => {
              this.submitting = false;
              this.loading = false
              this.$emit('closeModal')
              this.$store.commit('setDialogNotify',true);
              this.$store.commit('setMessageNotify',{state:'success',message:`${resConType.charAt(0).toUpperCase() + resConType.slice(1)} successfully updated.`});
              setTimeout(()=>{location.reload()},4000);
            });
          } catch (err) {
              this.submitting = false;
              this.loading = false
              this.$store.commit('setDialogNotify',true);
              this.$store.commit('setMessageNotify',{state:'error',message:err.message});
          }
        }
      }
    },
    parseMergedPayload(standardPricings){
      const standardPricingData = {};
      if(!_.isEmpty(standardPricings)){
        standardPricingData.standardPricings = standardPricings;
      }
      return standardPricingData;
    },
    parsePricingPayload(pricing, resCon, resConType, isRrp) {
      let standardPricings = []
      if(!_.isEmpty(pricing)){
        standardPricings = pricing.map(data => {
          let standardPricing = {};
          standardPricing.ownerMongoId = resCon._id;
          standardPricing.ownerRole = resConType;
          standardPricing.isRrp = isRrp;
          standardPricing.pricing = isRrp? {
            monthly:data.rrp.monthly,
            quarterly:data.rrp.quarterly,
            yearly:data.rrp.yearly
          } : {
            monthly:data.pricing.monthly,
            quarterly:data.pricing.quarterly,
            yearly:data.pricing.yearly
          }
          standardPricing.standardPricingMasterId = data.standardPricingMasterId;
          return standardPricing;
        });
      }
      return standardPricings;
    },
    // parsePrepaidPricingPayload(data, resCon, resConType, isRrp) {
    //   let standardPricing = {};
    //   standardPricing.ownerMongoId = resCon._id;
    //   standardPricing.ownerRole = resConType;
    //   standardPricing.isRrp = isRrp;
    //   standardPricing.pricing = isRrp? {
    //     oneDay:data.rrp.oneDay,
    //     fiveDays:data.rrp.fiveDays,
    //     tenDays:data.rrp.tenDays,
    //     twentyFiveDays:data.rrp.twentyFiveDays,
    //     fiftyDays:data.rrp.fiftyDays
    //   } : {
    //     oneDay:data.pricing.oneDay,
    //     fiveDays:data.pricing.fiveDays,
    //     tenDays:data.pricing.tenDays,
    //     twentyFiveDays:data.pricing.twentyFiveDays,
    //     fiftyDays:data.pricing.fiftyDays
    //   }
    //   standardPricing.standardPricingMasterId = data.standardPricingMasterId;
    //   return [standardPricing];
    // },
    onPrevious() {
      const data = {...this.interChangingData, ...{
        accountInfo: this.accountInfo,
      }}

      this.$emit('backStep', data)
    }
  },
   computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
   }
}
</script>