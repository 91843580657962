<template>
  <div>
    <div class="text-center mt-3">
      <div class="font-semi text-black-primary text-2xl mb-4">
        {{term.name}}
      </div>
    </div>
    <div class="flex">

      <div class="mb-5 bg-white pt-3 pb-8 px-3" v-if="standardTerm">
        <div class="flex items-center font-bold mb-5">
          <div class="flex items-center">
            <div class="flex items-center">
              <div class="flex flex-col">
                <div class="flex-auto bg-white">{{standardTerm.name}}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-9" v-for="section in standardTerm.sections" :key="section.uniqueId">
          <h3 class="mb-1 text-base">{{section.headline}}</h3><hr>
          <div class="mt-2">
            <div v-for="control in section.controls" :key="control.uniqueId">
              <small  class="font-medium">{{control.label}}</small>
              <div class="text-xs mb-4 mt-1">
                {{ control.value }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mb-5 bg-white pt-3 pb-8 px-3" v-if="term">
        <div class="flex items-center font-bold mb-5">
          <div class="flex items-center">
            <div class="flex items-center">
              <div class="flex flex-col">
                <div class="flex-auto bg-white">{{term.name}} Change Request</div>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-9" v-for="section in term.sections" :key="section.uniqueId">
          <h3 class="mb-1 text-base">{{section.headline}}</h3><hr>
          <div class="mt-2">
            <div v-for="control in section.controls" :key="control.uniqueId">
              <small  class="font-medium">{{control.label}}</small>
              <div class="text-xs mb-4 mt-1">
                {{ control.value }}
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="flex space-x-2 items-left flex-row-reverse">
      <button @click.prevent="reject()" class="btn btn-light bg-transparent text-blue-700 font-semibold py-2 px-4 border border-blue-500 rounded">
        Reject
      </button>
      <button @click.prevent="approve()" class="btn btn-primary bg-transparent text-blue-700 font-semibold py-2 px-4 border border-blue-500 rounded"
       :style="{backgroundColor: theme.secondaryColor}"
      >
        Approve
      </button>
    </div>

    <br/><br/>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    activeTerm: {
      type: Object,
      default: () => {}
    },
  },
  watch: {
    activeTerm: {
      deep: true,
      handler() {
        this.setActiveTerm()
      }
    }
  },
  created() {
    this.setActiveTerm()
  },
  data() {
    return {
      term: {},
      collapsed: true,
      standardTerm: {},
    };
  },
  async mounted() {
    this.standardTerm = await this.$store.dispatch(`contract/getContractTermsByUID`, '628702ffeefaa60073652565');
  },
  methods: {
    setActiveTerm(){
      this.term = this.activeTerm;
    },
    approve() {
      console.log("approved");
    },
    reject() {
      console.log("rejected");
    }
  },
   computed: {
     ...mapGetters('theme', {
			theme: 'getColorScheme'
		})
  }
}
</script>

<style>
.signature {
  width: 150px;
  height: 75px;
}
</style>
