<template>
  <div class="mb-16">
    <div class="loading mt-32 h-32" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div> 
    <FormulateForm v-if="!loading" @submit="onSubmit">
      <div v-if="loading" class="text-center"><ui-spinner class="m-auto"></ui-spinner> Loading...</div>
      <div v-if="!loading && preData.accountInfo" class="items-center justify-between">

        <FormulateInput
          type="text"
          label="Username"
          name="contactUserName"
          :value="preData.accountInfo.contactUserName"
          validation="required:trim"
          aria-autocomplete="off"
          autocomplete="off"
          @keydown.space.prevent        
        />

        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
              type="text"
              label="Contact Email"
              name="contactEmail"
              :value="preData.accountInfo.contactEmail"
              validation="email"
              @keydown.space.prevent
              autocomplete="off"
            />

            <FormulateInput
              type="text"
              label="Contact Phone Number"
              name="contactPhoneNumber"
              error-behavior="live"
              :value="preData.accountInfo.contactPhoneNumber"
              validation="required:trim"
              @keydown.space.prevent
              autocomplete="off"
            />
        </div>
        <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
          <button class="btn col-span-2 btn-primary mt-2" :style="{backgroundColor: theme.secondaryColor}">Submit</button>
          <button @click.prevent="onPrevious" class="btn bg-transparent border col-span-2 btn-info mt-2">Back</button>
        </div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>
<script>
import schema from './partner-account-schema';
import _ from 'lodash';
import { mapGetters } from 'vuex'

export default {
  name: 'Edit-Partner-Form',
  props: {
    msg: String,
    preData: null
  },
  data() {
    return {
      error:false,
      errorMessage: '',
      loading: false,
      values: {},
      schema: schema,
      accountInfo: {}
    };
  },
  mounted(){
    this.accountInfo = this.preData.accountInfo;
  },
  methods: {
    onSubmit(data) {
      this.loading = true;
      const businessProfile = this.preData.businessProfile;
      const pricing = this.preData.pricing;
      const accountInfo = JSON.parse(JSON.stringify(this.values));
      if(businessProfile && accountInfo && pricing){
        const businessAddress = businessProfile.businessAddress;
        let partnerData = {
          persona: {
            _id: businessProfile.personaId,
            businessName: businessProfile.businessName,
            tradingName: businessProfile.tradingName,
            abn: businessProfile.abn,
            businessAddress,
            contactUserName: data.contactUserName,
            contactEmail: data.contactEmail,
            contactPhoneNumber: data.contactPhoneNumber
          },
          id:businessProfile.id,
          supportEmail: businessProfile.supportEmail,
          supportPhone: businessProfile.supportPhone
        }
        const prepaid = this.preData.prepaid;

        this.$store.dispatch(`partner/updatePartner`, partnerData).then(partner => {
          if(partner && partner._id){
            const buyPricingPayload = this.parsePricingPayload(pricing, partner, false)
            const RRPPayload = this.parsePricingPayload(pricing, partner, true)
            const buyPricingPreapaidPayload = this.parsePrepaidPricingPayload(prepaid, partner, false)
            const RRPPrepaidPayload = this.parsePrepaidPricingPayload(prepaid, partner, true)

            const mergedPayload = this.parseMergedPayload([
                ...buyPricingPayload,
                ...RRPPayload,
                ...buyPricingPreapaidPayload,
                ...RRPPrepaidPayload
              ]);

            try {
              this.$store.dispatch(`standardpricing/updateStandardPricing`, mergedPayload).then(() => {
                this.submitting = false;
                this.$emit('closeModal')
                this.$store.commit('setDialogNotify',true);
                this.$store.commit('setMessageNotify',{state:'success',message:'Partner successfully updated.'});
                setTimeout(()=>{location.reload()},4000);
              });
            } catch (err) {
                this.submitting = false;
                this.$store.commit('setDialogNotify',true);
                this.$store.commit('setMessageNotify',{state:'error',message:err.message});
            }
          }
        }).catch(error => {
            this.loading = false
            this.errorMessage = error?.message
        });
      }
    },
    parseMergedPayload(standardPricings){
      const standardPricingData = {};
      if(!_.isEmpty(standardPricings)){
        standardPricingData.standardPricings = standardPricings;
      }
      return standardPricingData;
    },
    parsePricingPayload(pricing, partner, isRrp) {
      let standardPricings = []
      if(!_.isEmpty(pricing)){
        standardPricings = pricing.map(data => {
          let standardPricing = {};
          standardPricing.ownerMongoId = partner._id;
          standardPricing.ownerRole = 'partner';
          standardPricing.isRrp = isRrp;
          standardPricing.pricing = isRrp? {
            monthly:data.rrp.monthly,
            quarterly:data.rrp.quarterly,
            yearly:data.rrp.yearly
          } : {
            monthly:data.pricing.monthly,
            quarterly:data.pricing.quarterly,
            yearly:data.pricing.yearly
          }
          standardPricing.standardPricingMasterId = data.standardPricingMasterId;
          return standardPricing;
        });
      }
      return standardPricings;
    },
    parsePrepaidPricingPayload(data, partner, isRrp) {
      let standardPricing = {};
      standardPricing.ownerMongoId = partner._id;
      standardPricing.ownerRole = 'partner';
      standardPricing.isRrp = isRrp;
      standardPricing.pricing = isRrp? {
        oneDay:data.rrp.oneDay,
        fiveDays:data.rrp.fiveDays,
        tenDays:data.rrp.tenDays,
        twentyFiveDays:data.rrp.twentyFiveDays,
        fiftyDays:data.rrp.fiftyDays
      } : {
        oneDay:data.pricing.oneDay,
        fiveDays:data.pricing.fiveDays,
        tenDays:data.pricing.tenDays,
        twentyFiveDays:data.pricing.twentyFiveDays,
        fiftyDays:data.pricing.fiftyDays
      }
      standardPricing.standardPricingMasterId = data.standardPricingMasterId;
      return [standardPricing];
    },
    onPrevious() {
      this.$emit('backStep',{
        pricing: this.preData.pricing,
        prepaid: this.preData.prepaid,
        businessProfile: this.preData.businessProfile,
        accountInfo: this.accountInfo
      })
    }
  },
  computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
  },
}
</script>