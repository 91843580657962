
export default{
    // @Vehicle
    newCreatedVehicle: {},
    setNewCreatedVehicleClass: {},
    setNewCreatedVehicleType: {},
    setNewCreatedRearCoupling: {},
    setNewCreatedFrontCoupling: {},
    setNewCreatedCouplingCompatibility: {},
    setNewCreatedWiringPlugConnector: {},
    setNewCreatedWiringPlugVoltage: {},
    setNewCreatedBrakeFoundation: {},
    setNewCreatedBrakeType: {}
}