<template>
  <div class="card">
    <div class="flex items-center font-bold">
      <div class="flex items-center">
          <div class="flex items-center">
            <div class="icon-container" :class="[cardIcon.bg]" :style="{backgroundColor: theme.primaryColor}">
              <font-awesome-icon :icon="cardIcon.name" />
            </div>
            <div class="flex flex-col">
              <div class="text-2xl" :class="[cardIcon.textBg]" :style="{color: theme.secondaryColor}">
                {{notificationCount}}
              </div>
              <div class="flex-auto" :style="{color: theme.secondaryColor}">{{cardTitle}}</div>
            </div>
          </div>
      </div>
      <div class="ml-auto cursor-pointer">
        <font-awesome-icon
          :icon="collapsed ? 'caret-down' : 'caret-right'"
          class="text-2xl"
          @click="collapsed = !collapsed"
          :style="{color: theme.primaryColor}"
        />
      </div>
    </div>
     <div class="text-right">
         <slot :props="{collapsed:collapsed}" />
      </div>
    <div v-show="collapsed" class="separator-h my-2" />
    <div>
      <Transition name="slide-down">
      <div v-show="collapsed" class="overflow-hidden bg-gray-100 rounded-lg">
        <slot name="cardTable" />
      </div>
      </Transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: "Dashboard-Card",
    props: {
      cardIcon: {
        type: Object,
        default: function () {
          return {
            name: "",
            bg: "",
          };
        },
      },
      cardTitle: {
        type: String,
        default: "",
      },
      notificationCount: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        collapsed: false,
      };
    },
    components: {},
    mounted() {},
    computed: {
       ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .card {
    position: relative;
    width: 50%;
    padding: 1rem 2rem;
    border-radius: 10px;
    background: #ffffff;
    margin-bottom: 2rem;
    box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.15);
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    height: fit-content;
    .icon-container {
      margin-right: 1rem;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: #ffffff;
      }
    }
    .separator-v {
      border-right: 2px solid #e5e7eb;
      margin: 0 2rem;
      height: 35px;
    }
    .separator-h {
      border-bottom: 2px solid #e5e7eb;
      width: 100%;
    }
  }

</style>
