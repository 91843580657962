<template>
  <div class="w-full">
    <div class="flex w-full items-center driver-info-container">
      <div class="flex-1 flex">
        <div>
          <div
            class="flex w-full flex-col sm:flex-col md:flex-col lg:flex-row flex-wrap"
          >
            <Card
              :cardBackgroundColor="theme.accentColor"
              :cardWidth="'100%'"
              class="sm:w-full sm:mr-2 md:mr-5 flex-1 marketplace-card"
              v-for="(marketPlaceItem, index) in marketplaceItems"
              :key="index"
              @click.native="showDetails(index)"
            >
              <template slot="cardContent">
                <div class="flex items-center w-full justify-between p-10">
                  <div class="w-1/3">
                    <div
                      class="h-20 w-20 bg-gray-200 border rounded-full overflow-hidden border-3 border-gray-300 flex"
                    >
                      <font-awesome-icon
                        icon="user"
                        class="text-5xl m-auto text-gray-600"
                      />
                    </div>
                  </div>
                  <div class="w-2/3">
                    <div
                      class="text-sm font-bold pb-2"
                      :style="{ color: theme.secondaryColor }"
                    >
                      {{ marketPlaceItem.name }}
                    </div>
                    <div
                      class="text-xs pb-2"
                      :style="{ color: theme.secondaryColor }"
                    >
                      {{ marketPlaceItem.description }}
                    </div>
                    <div
                      class="text-sm"
                      :style="{ color: theme.secondaryColor }"
                    >
                      Paid Solution
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { formatDate } from "@/_helper";
import { Card } from "@/components/commons";
import { PARTNER_ROLE, RESELLER_ROLE } from "@/constants/usertype";

export default {
  name: "Marketplace",
  components: { Card },
  data() {
    return {
      isLoading: false,
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
      marketplaceItems: [],
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      const me = this.$store.getters[`account/me`];
      let parentId;

      if (me?.business?.parentRole === RESELLER_ROLE.name) {
        const reseller = await this.$store.dispatch(
          `reseller/getResellerByUID`,
          me?.business?.parentUid
        );
        parentId = reseller?._id;
      } else if (me?.business?.parentRole === PARTNER_ROLE.name) {
        const reseller = await this.$store.dispatch(
          `partner/getPartnerByUID`,
          me?.business?.parentUid
        );
        parentId = reseller?._id;
      }

      let items = await this.$store.dispatch(
        `business/getMarketplaceItems`,
        parentId
      );
      this.marketplaceItems = this.formatMarketplaceItems(items);

      this.isLoading = false;
    },
    showDetails(index) {
      if (this.marketplaceItems[index]) {
        const item = this.marketplaceItems[index];
        this.$router.push(`/business/services/${item.id}/details`);
      }
    },
    momentFormat(date, isShort = false) {
      if (date) {
        if (isShort) {
          return date ? moment(date).format("MMM D, YYYY") : "-";
        } else {
          return date ? moment(date).format("MMMM D, YYYY") : "-";
        }
      }
    },
    formatMarketplaceItems(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const marketplaceItem = {
            id: item["_id"],
            name: item["standardPricingMaster"]["name"] || "",
            createdAt: formatDate(item["createdAt"]) || "",
            isActive: item["isActive"] ? "Active" : "Inactive" || "",
            isRrp: item["isRrp"] ? "RRP" : "Non RRP" || "",
            ownerMongoId: item["ownerMongoId"] || "",
            ownerRole: item["ownerRole"] || "",
            type: item["type"] || "",
            logo: item["logo"] || "",
            pricing: item["pricing"] || {},
            standardPricingMaster: item["standardPricingMaster"] || {},
            description: item["standardPricingMaster"]["description"] || "",
          };
          nItems.push(marketplaceItem);
        });
      }
      return nItems;
    },
    toast(state, message) {
      this.isLoading = false;
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.marketplace-card {
  flex: 1 1 32%;
  list-style: none;
}
</style>
