import { STATES } from '@/constants/location'
export default [
  
  {
    type: 'text',
    name: 'name',
    label: 'Name',
    validation: 'required'
  },
  {
    type: 'text',
    name: 'licenseNumber',
    label: 'Licence Number',
    validation: 'required'
  },
  {
    type: 'date',
    name: 'dateOfBirth',
    label: 'Date Of Birth',
    validation: 'required'
  },
  {
    name: 'state',
    type: 'select',
    label: 'State',
    options: STATES,
    validation: 'required'
  },
]