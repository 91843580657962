<template>
  <div class="mb-4">
    <div
      class="border border-dashed border-gray-400 hover:border-orange-100 transition ease-in-out hover:duration-300 text-gray-400 rounded-md w-full p-4 flex flex-col items-stretch"
    >
      <div v-if="dynamicContent.type === 'Paragraph'">
        <ParagraphPreview :dynamicContent="dynamicContent" />
      </div>
      <div v-if="dynamicContent.type === 'bulletPoints'">
        <BulletPointsPreview :dynamicContent="dynamicContent" />
      </div>
      <div v-if="dynamicContent.type === 'Headers'">
        <HeaderPreview :dynamicContent="dynamicContent" />
      </div>
      <div v-if="dynamicContent.type === 'Closing'">
        <ClosingPreview :dynamicContent="dynamicContent" />
      </div>
      <div v-if="dynamicContent.type === 'Title'">
        <TitlePreview :dynamicContent="dynamicContent" />
      </div>
      <div v-if="dynamicContent.type === 'Addresses'">
        <AddressesPreview :dynamicContent="dynamicContent" />
      </div>
      <div v-if="dynamicContent.type === 'schemeType'">
        <SchemeTypePreview :dynamicContent="dynamicContent" />
      </div>
    </div>
  </div>
</template>

<script>
import ParagraphPreview from "@/components/form-controls/part-preview/paragraph-preview.vue";
import BulletPointsPreview from "@/components/form-controls/part-preview/bullet-points-preview.vue";
import HeaderPreview from "@/components/form-controls/part-preview/header-preview.vue";
import ClosingPreview from "@/components/form-controls/part-preview/closing-preview.vue";
import TitlePreview from "@/components/form-controls/part-preview/title-preview.vue";
import AddressesPreview from "@/components/form-controls/part-preview/addresses-preview.vue";
import SchemeTypePreview from "@/components/form-controls/part-preview/scheme-type.preview.vue";

import _ from "lodash";
export default {
  props: {
    dynamicContent: {
      type: Object,
    },
  },
  name: "document-part-container",

  components: {
    ParagraphPreview,
    BulletPointsPreview,
    HeaderPreview,
    ClosingPreview,
    TitlePreview,
    AddressesPreview,
    SchemeTypePreview,
  },

  data() {
    return {
      variables: false,
      preview: "",

      docPartData: false,
      htmlPreview: "",
    };
  },

  computed: {
    partModel: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid == this.dynamicContent.uuid;
          }
        );

        return model;
      },

      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    dynamicHTMLPreview: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.dynamicContent.uuid;
          }
        );

        const preview = model.contentPreview
          ? "<p>" + model.contentPreview + "</p>"
          : "";

        return preview;
      },
    },

    currentSelected: {
      get() {
        return this.$store.state.accreditation.selectedContainer;
      },
    },

    partIndex: {
      get() {
        const model = _.findIndex(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.dynamicContent.uuid;
          }
        );

        return model;
      },
    },

    docParts: {
      get() {
        return this.$store.state.accreditation.docParts;
      },

      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },
  },
};
</script>

<style scoped></style>
