var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"w-full",style:({
    backgroundColor: _vm.theme.accentColor,
  })},[_c('ul',{staticClass:"top-nav"},_vm._l((_vm.menu),function(menuItem,index){return _c('li',{key:index,staticClass:"nav-item",on:{"click":function($event){$event.preventDefault();return _vm.toggleDropDown(menuItem.name)}}},[(menuItem.link && !menuItem.isExternalLink)?_c('div',[_c('router-link',{attrs:{"to":menuItem.link}},[_c('div',{staticClass:"text-sm tracking-wide truncate"},[_vm._v(" "+_vm._s(menuItem.name)+" ")])])],1):(menuItem.link && menuItem.isExternalLink)?_c('div',[_c('a',{attrs:{"href":menuItem.link,"target":"_blank"}},[_c('div',{staticClass:"text-sm tracking-wide truncate",on:{"click":function($event){return _vm.navigateToExternalLink(menuItem.link)}}},[_vm._v(" "+_vm._s(menuItem.name)+" ")])])]):_c('div',[_c('div',{staticClass:"text-sm tracking-wide truncate",style:({
            ...(menuItem.isHeaderItem && {
              fontWeight: 'bold',
              cursor: 'default',
            }),
          })},[_vm._v(" "+_vm._s(menuItem.name)+" ")])]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeSubMenu === menuItem.name),expression:"activeSubMenu === menuItem.name"}],staticClass:"submenu",style:({
          backgroundColor: _vm.theme.primaryColor,
        })},_vm._l((_vm.filteredItems(
            menuItem.subitems
          )),function(subMenuItem,subMenuIndex){return _c('li',{key:subMenuIndex},[_c('router-link',{class:{ active: _vm.isActive(subMenuItem.link) },attrs:{"index":subMenuIndex,"to":subMenuItem.link}},[_c('span',{staticClass:"text-xs"},[_vm._v(_vm._s(subMenuItem.name))])])],1)}),0)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }