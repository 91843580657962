<template>
  <div
    @click="onSelectComponent"
    class="relative h-full flex flex-row justify-between space-x-4 border-solid border-2 p-2 hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
  >
    <div
      class="border border-gray-400 hover:border-orange-100 transition ease-in-out hover:duration-300 text-gray-400 rounded-md w-full p-4 flex flex-col items-stretch"
    >
      <div class="flex" v-if="leftHeaderContent?.value">
        <img v-if="leftHeaderContent?.value" :src="leftHeaderContent?.value" />
      </div>
    </div>

    <div
      class="border border-gray-400 hover:border-orange-100 transition ease-in-out hover:duration-300 text-gray-400 rounded-md w-full p-4 flex flex-col items-stretch"
    >
      <div v-if="rightHeaderContent?.value">
        <img
          class="w-full h-full"
          v-if="rightHeaderContent?.value"
          :src="rightHeaderContent?.value"
        />
      </div>
    </div>
    <div
      class="absolute bottom-0 right-0 h-6 w-fit space-x-2 bg-orange-100 flex justify-between items-center py-2 px-1"
      v-if="currentSelected.uuid === dynamicContent.uuid"
    >
      <button class="text-white" @click.prevent="removeComponent">
        <font-awesome-icon :icon="['fas', 'trash']" />
      </button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    dynamicContent: {
      type: Object,
    },
  },


  computed: {
    currentSelected: {
      get() {
        return this.$store.state.accreditation.selectedContainer;
      },

      set(newVal) {
        this.$store.state.accreditation.selectedContainer = newVal;
      },
    },

    docParts: {
      get() {
        return this.$store.state.accreditation.docParts;
      },

      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    partIndex: {
      get() {
        const model = _.findIndex(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.dynamicContent.uuid;
          }
        );

        return model;
      },
    },

    content: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.dynamicContent.uuid;
          }
        );

        return model.content;
      },
    },

    currentPart: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.dynamicContent.uuid;
          }
        );

        return model;
      },
    },

    leftHeaderContent: {
      get() {
        const model = _.find(this.currentPart?.content, (data) => {
          return data.part === "left";
        });

        return model;
      },
      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    rightHeaderContent: {
      get() {
        const model = _.find(this.currentPart?.content, (data) => {
          return data.part === "right";
        });

        return model;
      },
      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    selectedContainer: {
      get() {
        return this.$store.state.accreditation.selectedContainer;
      },

      set(newVal) {
        this.$store.state.accreditation.selectedContainer = newVal;
      },
    },
  },

  methods: {
    onSelectComponent() {
      this.selectedContainer = this.currentPart;
    },

    removeComponent() {
      this.$store.state.accreditation.docParts.splice(this.partIndex, 1);
      this.$store.state.accreditation.selectedContainer = false;
    },

  },
};
</script>

<style scoped></style>
