<template>
  <div class="w-full">
    <Tabs clickEvent>
      <Tab title="Partner Services">
        <div class="flex flex-row-reverse container mb-5 mt-5">
          <button
            class="btn btn-primary"
            @click.prevent="showCreate()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Add Service
          </button>
        </div>
        <div class="flex w-full items-center driver-info-container">
          <div class="flex-1 flex">
            <div>
              <div
                class="flex w-full flex-col sm:flex-col md:flex-col lg:flex-row flex-wrap"
              >
                <Card
                  :cardBackgroundColor="theme.accentColor"
                  :cardWidth="'100%'"
                  class="sm:w-full sm:mr-2 md:mr-5 flex-1 marketplace-card"
                  v-for="(marketPlaceItem, index) in partnerItems"
                  :key="index"
                >
                  <template slot="cardContent">
                    <div class="flex items-center w-full justify-between p-10">
                      <div class="w-1/3">
                        <div
                          class="h-24 w-24 bg-gray-200 border rounded-full overflow-hidden border-3 border-gray-300 flex"
                        >
                          <img
                            v-if="marketPlaceItem.logo"
                            :src="marketPlaceItem.logo"
                            alt=""
                            class="object-cover"
                            style="max-width: 200px"
                          />
                          <font-awesome-icon
                            v-if="!marketPlaceItem.logo"
                            icon="user"
                            class="text-5xl m-auto text-gray-600"
                          />
                        </div>
                      </div>
                      <div class="w-2/3">
                        <div
                          class="text-sm flex flex-row-reverse"
                          :style="{ color: theme.secondaryColor }"
                        >
                          <button
                            class="btn btn-primary"
                            @click.prevent="showEdit(marketPlaceItem)"
                            :style="{ backgroundColor: theme.secondaryColor }"
                          >
                            Edit
                          </button>
                          <button
                            class="btn btn-primary"
                            @click.prevent="showDetails(marketPlaceItem)"
                            :style="{ backgroundColor: theme.secondaryColor }"
                          >
                            Details
                          </button>
                          <button
                            class="btn btn-primary"
                            @click.prevent="showLogo(marketPlaceItem)"
                            :style="{ backgroundColor: theme.secondaryColor }"
                          >
                            Logo
                          </button>
                        </div>
                        <div
                          class="text-xl font-bold pb-2"
                          :style="{ color: theme.secondaryColor }"
                        >
                          {{ marketPlaceItem.name }}
                        </div>
                        <div
                          class="text-base pb-2"
                          :style="{ color: theme.secondaryColor }"
                        >
                          {{ marketPlaceItem.description }}
                        </div>
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Tab>
      <Tab title="Reseller Services">
        <div class="flex flex-row-reverse container mb-5 mt-5">
          <button
            class="btn btn-primary"
            @click.prevent="showCreate()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Add Service
          </button>
        </div>
        <div class="flex w-full items-center driver-info-container">
          <div class="flex-1 flex">
            <div>
              <div
                class="flex w-full flex-col sm:flex-col md:flex-col lg:flex-row flex-wrap"
              >
                <Card
                  :cardBackgroundColor="theme.accentColor"
                  :cardWidth="'100%'"
                  class="sm:w-full sm:mr-2 md:mr-5 flex-1 marketplace-card"
                  v-for="(marketPlaceItem, index) in resellerItems"
                  :key="index"
                >
                  <template slot="cardContent">
                    <div class="flex items-center w-full justify-between p-10">
                      <div class="w-1/3">
                        <div
                          class="h-24 w-24 bg-gray-200 border rounded-full overflow-hidden border-3 border-gray-300 flex"
                        >
                          <img
                            v-if="marketPlaceItem.logo"
                            :src="marketPlaceItem.logo"
                            alt=""
                            class="object-cover"
                            style="max-width: 200px"
                          />
                          <font-awesome-icon
                            v-if="!marketPlaceItem.logo"
                            icon="user"
                            class="text-5xl m-auto text-gray-600"
                          />
                        </div>
                      </div>
                      <div class="w-2/3">
                        <div
                          class="text-sm flex flex-row-reverse"
                          :style="{ color: theme.secondaryColor }"
                        >
                          <button
                            class="btn btn-primary"
                            @click.prevent="showEdit(marketPlaceItem)"
                            :style="{ backgroundColor: theme.secondaryColor }"
                          >
                            Edit
                          </button>
                          <button
                            class="btn btn-primary"
                            @click.prevent="showDetails(marketPlaceItem)"
                            :style="{ backgroundColor: theme.secondaryColor }"
                          >
                            Details
                          </button>
                          <button
                            class="btn btn-primary"
                            @click.prevent="showLogo(marketPlaceItem)"
                            :style="{ backgroundColor: theme.secondaryColor }"
                          >
                            Logo
                          </button>
                        </div>
                        <div
                          class="text-xl font-bold pb-2"
                          :style="{ color: theme.secondaryColor }"
                        >
                          {{ marketPlaceItem.name }}
                        </div>
                        <div
                          class="text-base pb-2"
                          :style="{ color: theme.secondaryColor }"
                        >
                          {{ marketPlaceItem.description }}
                        </div>
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </Tabs>
    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content === 'view'">
        <NewMarketplaceItem @closeModal="closeModal"></NewMarketplaceItem>
      </div>
      <div v-if="modal.content === 'edit'">
        <EditMarketplaceItem
          :id="selectedItemID"
          @closeModal="closeModal"
        ></EditMarketplaceItem>
      </div>
      <div v-if="modal.content === 'logo'">
        <UploadMarketplaceItemLogo
          :id="selectedItemID"
          @closeModal="closeModal"
        ></UploadMarketplaceItemLogo>
      </div>
      <div v-if="modal.content === 'detail'">
        <MarketplaceItemDetail
          :id="selectedItemID"
          @closeModal="closeModal"
        ></MarketplaceItemDetail>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/_helper";
import { Card, Modal } from "@/components/commons";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import NewMarketplaceItem from "@/components/forms/marketplace/new-marketplace-item.vue";
import EditMarketplaceItem from "@/components/forms/marketplace/edit-marketplace-item.vue";
import Tab from "@/components/commons/tab/Tab.vue";
import Tabs from "@/components/commons/tab/Tabs.vue";
import UploadMarketplaceItemLogo from "@/components/forms/marketplace/upload-marketplace-item-logo.vue";
import MarketplaceItemDetail from "@/components/forms/marketplace/marketplace-item-detail.vue";

export default {
  name: "Marketplace",
  components: {
    MarketplaceItemDetail,
    UploadMarketplaceItemLogo,
    Card,
    Modal,
    Tab,
    Tabs,
    NewMarketplaceItem,
    EditMarketplaceItem,
  },
  data() {
    return {
      isLoading: false,
      modal: {
        title: "",
        content: null,
        height: DEFAULT_MODAL_HEIGHT,
      },
      partnerItems: [],
      resellerItems: [],
      selectedItemID: "",
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;

      let partnerItems = await this.$store.dispatch(
        `standardpricing/getStandardPricingMasterPartner`
      );
      let resellerItems = await this.$store.dispatch(
        `standardpricing/getStandardPricingMasterReseller`
      );
      this.partnerItems = this.formatMarketplaceItems(partnerItems);
      this.resellerItems = this.formatMarketplaceItems(resellerItems);

      this.isLoading = false;
    },
    showCreate() {
      this.modal.content = "view";
      this.modal.title = "New Service";
      this.$refs.modal.openModal();
    },
    showEdit(item) {
      this.modal.content = "edit";
      this.modal.title = `Edit Service`;
      this.selectedItemID = item.id;
      this.$refs.modal.openModal();
    },
    showLogo(item) {
      this.modal.content = "logo";
      this.modal.title = `Upload ${item.name} Logo`;
      this.selectedItemID = item.id;
      this.$refs.modal.openModal();
    },
    showDetails(item) {
      this.modal.content = "detail";
      this.modal.title = `Edit  ${item.name} Details Page`;
      this.selectedItemID = item.id;
      this.$refs.modal.openModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
    formatMarketplaceItems(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const marketplaceItem = {
            id: item["_id"],
            name: item["name"] || "",
            createdAt: formatDate(item["createdAt"]) || "",
            isActive: item["isActive"] ? "Active" : "Inactive" || "",
            margin: item["margin"] || 0,
            apiTag: item["apiTag"] || "",
            category: item["category"] || "",
            type: item["type"] || "",
            logo: item["logo"] || "",
            pricing: item["pricing"] || {},
            rrp: item["rrp"] || {},
            classes: item["classes"] || [],
            description: item["description"] || "",
          };
          nItems.push(marketplaceItem);
        });
      }
      return nItems;
    },
    toast(state, message) {
      this.isLoading = false;
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.marketplace-card {
  flex: 1 1 32%;
  list-style: none;
}
</style>
