import { STATES } from '@/constants/location'

export default [
    {
        type: 'text',
        name: 'businessName',
        label: 'Business Name',
        validation: 'required'
    },
    {
        type: 'text',
        name: 'tradingName',
        label: 'Trading Name',
        validation: 'required'
    },
    {
        type: 'text',
        name: 'abn',
        label: 'NZBN',
        validation: 'required'
    },
    {
        name: 'street',
        label: 'Street Address',
        validation: 'required'
    },
                
    {
        name: 'city',
        label: 'City',
        validation: 'required'
    },
    {
        name: 'state',
        type: 'select',
        label: 'State',
        options: STATES,
        validation: 'required'
    },
        
    {
        type: 'text',
        name: 'contactUserName',
        label: 'Contact Username',
        validation: 'required'
    },
    {
        type: 'email',
        name: 'contactEmail',
        label: 'Contact Email',
        validation: '^required|email'
    },
    {
        type: 'text',
        name: 'contactPhoneNumber',
        label: 'Contact Phone Number',
        validation: 'required'
    },
    
    {
        type: 'email',
        name: 'supportEmail',
        label: 'Support Email',
        validation: '^required|email'
    },
    {
        type: 'text',
        name: 'supportPhoneNumber',
        label: 'Support Phone Number',
        validation: 'required'
    },
    ]   