<template>
    <StyledLink :href="link" class="block px-4 py-3 border-b" :hoverBackgroundColor="`${theme.primaryColor}1C`">
        <div class="text-sm px-2">
          <span class="font-bold" :style="{color: theme.primaryColor}">{{title}}</span>
        </div>
        <p class="text-xs px-2" :style="{color: theme.primaryColor}">{{description}}</p>
        <div class="w-full text-right mt-2">
            <p v-if="formattedDate" class="text-xs italic opacity-80" :style="{color: theme.primaryColor}">
            {{formattedDate}}
            </p>
        </div>
       
    </StyledLink>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import {StyledLink} from '@/styled-components'
export default {
  name: 'NotificationItemMin',
  components: {StyledLink},
  props: {
    title:String,
    description:String,
    createdAt: {
      required: true
    },
    link: {
      type: String,
      default: function () {
        return '#' //this.$store.getters['accountURL']['notifications']
      }
    },
  },
  data(){
    return {
      formattedDate: null
    }
  },
  mounted(){
    this.formattedDate = moment(this.createdAt).fromNow();
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
}
</script>