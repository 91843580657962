<template>
    <div class="mb-16">
        <div class="loading centered" v-if="loading">
            <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <StyledSubmit :backgroundColor="theme.secondaryColor">
            <FormulateForm v-if="!loading" v-model="values" :schema="schema" @submit="onSubmit" #default="{ hasErrors }">
                <div class="items-center justify-between">
                    <FormulateInput
                    type="submit"
                    label="Submit"
                    input-class="btn btn-primary w-full"
                    :disabled="hasErrors || loading"
                    :style="{backgroundColor: theme.secondaryColor}"
                    />
                </div>
            </FormulateForm>
        </StyledSubmit>
    </div>
</template>

<script>
import schema from './new-techprovider-schema';
import { mapGetters } from 'vuex'
import {StyledSubmit} from '@/styled-components'

export default {
    name: 'New-TechProvider-Form',
    props: {
        msg: String
    },
    components: {StyledSubmit},
    data() {
        this.$emit('schema', schema)
        return {
            loading: false,
            values: {},
            schema: schema,
            errorMessage: ''
        }
    },
    methods: {
        updateSchema: function(schema) {
            this.schema = schema
        },
        async onSubmit() {
            this.loading = true
            let newTechProvider = JSON.parse(JSON.stringify(this.values))
            if(newTechProvider) {
                newTechProvider['isActive'] = true;
                this.$store.dispatch(`techprovider/createTechProvider`, newTechProvider).then(techprovider => {
                this.loading = false;
                if(techprovider && techprovider._id){
                    this.$emit('closeRefresh');
                }
                }).catch(error => {
                    this.errorMessage = `Something went wrong please try again.`;
                    if(error.message) this.errorMessage = error.message[0]
                });
            }
        }
    },
    computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
  },
}
</script>

<style>

</style>