<template>
  <div>
    <div class="my-2 flex sm:flex-row flex-col">
      <div class="flex">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
              <option>5</option>
              <option>10</option>
              <option>20</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
          <div class="relative">
            <select class="appearance-none h-full rounded-r border-t sm:rounded-r-none sm:border-r-0 border-r border-b block appearance-none w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500">
              <option>All</option>
              <option>Enabled</option>
              <option>Disabled</option>
            </select>
            <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
              </svg>
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
              <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
            </svg>
          </span>
          <input placeholder="Search" class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
        </div>
      </div>
      <div class="ml-auto">
        <button class="btn btn-primary" @click.prevent="newDriver()" :style="{backgroundColor: theme.secondaryColor}">New Driver</button>
      </div>
    </div>
    
    <div class="my-5 overflow-x-auto">
      <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
        <table class="min-w-full leading-normal">
          <thead>
            <tr>
              <th class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> Full Name </th>
              <th class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> Email Address </th>
              <th class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> Licence </th>
              <th class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> Phone </th>
              <th class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> Date Created </th>
              <th class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> Enable/Disable </th>
              <th class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"> Actions </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(driver, index) in drivers" :key="index" :class="`driver-${driver.id}`">
              <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                <div class="flex items-center">
                  <div class="flex-shrink-0 w-10 h-10">
                    <div class="w-full h-full rounded-full overflow-hidden flex bg-gray-200">
                      <font-awesome-icon icon="user" class="text-xl m-auto text-gray-600" />
                    </div>
                  </div>
                  <div class="ml-3">
                    <p class="text-black-primary whitespace-no-wrap">{{driver.name}}</p>
                  </div>
                </div>
              </td>
              <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                <p class="text-black-primary whitespace-no-wrap">{{driver.email}}</p>
              </td>
              <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                <p class="text-black-primary whitespace-no-wrap">{{driver.licence}}</p>
              </td>
              <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                <p class="text-black-primary whitespace-no-wrap">{{driver.phone}}</p>
              </td>
              <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                <p class="text-black-primary whitespace-no-wrap">{{driver.created}}</p>
              </td>
              <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm">
                <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                    <input type="checkbox" :checked="driver.status"  name="toggle" :id="driver.id" class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"/>
                    <label for="toggle" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
                </div>
              </td>
              <td class="px-5 py-2 border-b border-gray-200 bg-white">
                <p class="text-black-primary whitespace-no-wrap text-lg flex"> 
                  <a @click.prevent="viewDriver()" class="flex cursor-pointer"><font-awesome-icon icon="eye" class="my-auto mx-1.5 hover:text-orange-primary"/></a>
                  <a @click.prevent="editDriver()" class="flex cursor-pointer"><font-awesome-icon icon="edit" class="my-auto mx-1.5 hover:text-orange-primary"/></a>
                  <a href="#" class="flex cursor-pointer"><font-awesome-icon icon="level-down-alt" class="my-auto mx-1.5 hover:text-orange-primary"/></a>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        
        <Pagination :total-pages="totalPages" :current-page="page" :total-records="totalRecords" 
                    :visible-page-item-count="visiblePageItemCount" @pagechanged="onPageChange"
        />

      </div>
    </div>

    <Modal ref="modal" :title="modalTitle" size="lg" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <NewDriver />
      </div>
      <div v-if="modalContent == 'view'">
        <DriverDetailsView @editDriver="editDriver"/>
      </div>
      <div v-if="modalContent == 'edit'">
        <EditDriver/>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Pagination } from '@/components/commons'
import {NewDriver, EditDriver} from '@/components/forms/driver/'
import DriverDetailsView from '@/components/view/driver/view-driver-with-contract'
import {DEFAULT_MODAL_HEIGHT} from '@/_helper/constants'
import { mapGetters } from 'vuex'


export default{
  name:"Partner-Businesses",
  components:{ Modal, NewDriver, EditDriver, DriverDetailsView, Pagination },
  data(){
    return { 
      modalContent: false,
      modalTitle: '',
      drivers:[],
      modalHeight: DEFAULT_MODAL_HEIGHT,
      page: 1,
      totalPages: 5,
      totalRecords: 50,
      visiblePageItemCount: 3,
    }
  },
  async mounted(){
    const soloDrivers = await this.$store.dispatch(`driver/getDriver`);
    if(soloDrivers){
      this.drivers = this.formatItem(soloDrivers);
    }
  },
  methods: {
    newDriver(){
      this.$refs.modal.openModal();
      this.modalContent = 'new';
      this.modalTitle = 'New Driver'
    },
    viewDriver(){
      this.$refs.modal.openModal();
      this.modalContent = 'view';
      this.modalTitle = 'Driver Info'
    },
    editDriver(){ 
      this.$refs.modal.openModal();
      this.modalContent = 'edit';
      this.modalTitle = 'Driver Edit'
    },  
    formatItem(items) {
      let nItems = [];
      if(items){
        items.map(item => {
          const driver = {
            id:item['_id'],
            name:item['driverName'],
            email:item['emailAddress'],
            licence:item['driversLicenseNumber'],
            phone:item['supportPhone'],
            created:item['createdAt'],
            status:item['isActive'],
          }
          nItems.push(driver)
        });
      }
      return nItems;
    },
    onPageChange (page) {
      this.page = page
      // TODO: Set the api method here for page change
    },
  },
   computed: {
     ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>