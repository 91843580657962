<template>
  <div v-if="editData">
    <DocumentBuilder
      :editMode="true"
      :editModeName="editModeName"
      :editData="editData"
    />
  </div>
</template>

<script>
import DocumentBuilder from "@/components/forms/business/accreditation/document-builder";
export default {
  name: "document-builder-edit",
  components: {
    DocumentBuilder,
  },

  data() {
    return {
      termId: false,
      editData: false,

      editModeName: false,
    };
  },

  destroyed() {
    this.$store.state.accreditation.docPartContents = [];
    this.$store.state.accreditation.docVariables = [];
    this.$store.state.accreditation.docParts = [];
    this.editData = false;
  },

  watch: {
    "$route.params.id": {
      handler: async function () {
        await this.init();
      },
      immediate: true,
    },
  },

  methods: {
    async init() {
      const templateId = this.$route.params["id"];

      this.$store.state.accreditation.docPartContents = [];
      this.$store.state.accreditation.docVariables = [];
      this.$store.state.accreditation.docParts = [];

      const template = await this.$store.dispatch(
        "accreditation/fetchOneTemplate",
        {
          templateId,
        }
      );

      const { content, name } = template?.data?.data;
      this.editData = content;
      this.editModeName = name;
    },
  },
};
</script>
