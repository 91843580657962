export default [
    {
    
        type:'text',
        name:'depotName',
        label: 'Depot Name',
        validation: 'required'

    },
  
]