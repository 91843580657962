<template>
  <div class="min-h-full flex-row account-view">
   <div v-if="pricingData.length > 0 && hasPreActiveAlert" class="mb-6 flex p-4 bg-blue-400 rounded border-t-4 border-blue-500 dark:bg-blue-200" role="alert">
      <svg class="flex-shrink-0 w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd"></path></svg>
      <div class="ml-3 text-sm font-medium text-white">
         New pricing date come into effect within {{preActiveEffectDate}}.
      </div>
      <button @click="closeAlert" type="button" class="ml-auto -mx-1.5 -my-1.5 bg-blue-400 dark:bg-blue-200 text-blue-500 rounded-lg focus:ring-2 focus:ring-blue-400 p-1.5 hover:bg-blue-200 dark:hover:bg-blue-300 inline-flex h-8 w-8" data-dismiss-target="#alert-border-1" aria-label="Close">
         <span class="sr-only">Dismiss</span>
         <svg class="w-5 h-5" fill="#fff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
      </button>
   </div>

    <div v-if="true" class="w-full min-h-full flex-row items-left">
      <div class="flex w-full mt-5">
        <aside class="w-72  h-full border-r">
          <div class="overflow-y-auto flex flex-col border-b mb-2">
              <p class="text-sm py-4 px-3" :style="{color: theme.primaryColor}">Status</p>
          </div>
          <div class="mt-2 overflow-y-auto h-80 w-60">
            <div>
               <button 
                  class="text-left w-full px-1 py-2 border-b border-gray-200"
                  :class="{ 'bg-green-100': selectedPricing == 'active' }"
                  type="button"
                  @click="updatedSelectedPricing('active')"
                  :style="{color: selectedPricing == 'active' ? 'rgb(55 65 81)' : theme.primaryColor}"
                  >
                  <font-awesome-icon
                        v-if="selectedPricing == 'active'"
                        :icon="['fas', 'folder-open']"
                        class="my-auto mx-1.5"
                        size="sm"
                  >
                  </font-awesome-icon>
                  <font-awesome-icon 
                        v-else
                        :icon="['fas', 'folder']"
                        class="my-auto mx-1.5"
                        size="sm"
                  >
                  </font-awesome-icon>
                  <small>Active
                     <sup 
                        v-show="hasPreActive"
                        class="bg-red-500 py-1 px-2 rounded-xl text-white"
                        >Expire within {{activeExpireDate}}
                     </sup>
                  </small>
               </button>
            </div>
            <div>
               <button
                  v-show="hasPreActive"
                  class="text-left w-full px-1 py-2 border-b border-gray-200" 
                  type="button"
                  :class="{ 'bg-green-100': selectedPricing == 'pre-active' }"
                  @click="updatedSelectedPricing('pre-active')"
                  :style="{color: selectedPricing == 'pre-active' ? 'rgb(55 65 81)' : theme.primaryColor}"
                  >
                  <font-awesome-icon 
                        v-if="selectedPricing == 'pre-active'"
                        :icon="['fas', 'folder-open']"
                        class="my-auto mx-1.5"
                        size="sm"
                  >
                  </font-awesome-icon>
                  <font-awesome-icon 
                        v-else
                        :icon="['fas', 'folder']"
                        class="my-auto mx-1.5"
                        size="sm"
                  >
                  </font-awesome-icon>
                  <small>Pre-Active 
                        <sup class="bg-orange-50 py-1 px-2 rounded-xl text-black">Effect within {{preActiveEffectDate}}
                  </sup></small>
               </button>
            </div>
      
            <!-- <div v-for="(datas, index) in computedForms" :key="index">
              <div v-for="(ndata, index) in datas" :key="index">
                <button 
                  v-show="ndata.name" 
                  :title="ndata.name"
                  :class="{'btn-primary': activePricing.id === ndata.id}"
                  class="text-left w-full px-1 py-2 border-b border-gray-200" 
                  type="button"
                  @click="selectForm(ndata.id)"
                >
                  <font-awesome-icon 
                    v-if="activePricing.id === ndata.id"
                    :icon="['fas', 'folder-open']"
                    class="my-auto mx-1.5"
                    size="sm"
                  >
                  </font-awesome-icon>
                  <font-awesome-icon 
                      v-else
                      :icon="['fas', 'folder']"
                      class="my-auto mx-1.5"
                      size="sm"
                    >
                  </font-awesome-icon>
                  <small>{{ndata.name}}</small>
                </button>                
              </div>
            </div> -->
          </div>
        </aside>
        <div class="w-full flex flex-col">
          <div class="flex justify-between items-center border-b mb-2">
            <div class="w-full">
               <div class="flex border-b">
                  <div class="w-1/2">
                     <p class="text-sm p-4" :style="{color: theme.primaryColor}">Pricing Details</p>
                  </div>
               </div>
            </div>
          </div>

            <div v-if="!isPricingLoading" class="p-4 overflow-y-scroll">
               <div class="overflow-x-auto">
                  <div class="inline-block min-w-full rounded-lg overflow-hidden">
                     <table v-if="buyData.length > 0" class="table-auto text-center leading-normal w-full border-collapse border border-gray-200 mb-6 pb-5">
                           <thead>
                              <tr>
                                 <th class="bg-gray-100 border-b w-3/5 text-left px-5 py-3 border-gray-200 text-sm font-semibold text-gray-600 uppercase">
                                    Buy Product Pricing
                                 </th>
                                 <th class="bg-gray-200">Monthly</th>
                                 <th class="bg-gray-200">Yearly</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="(product, index) in buyData" :key="index">
                                 <td class="px-5 py-2 text-left text-sm bg-gray-100">
                                 {{ product.name }}
                                 </td>
                                 <td class="px-2 py-1">
                                 ${{ parseFloat(product.pricing.monthly).toFixed(2) }}
                                 </td>
                                 <td class="px-2 py-1">
                                 ${{ parseFloat(product.pricing.yearly).toFixed(2) }}
                                 </td>
                              </tr>
                           </tbody>
                     </table>
<!--                     <table v-if="prepaidPricing && prepaidData" class="table-auto text-center leading-normal w-full border-collapse border border-gray-200 mb-6 pb-5">-->
<!--                           <thead>-->
<!--                              <tr>-->
<!--                                 <td class="bg-gray-100 py-3 w-2/5 text-left pl-6 pr-2" rowspan="6">-->
<!--                                 </td>-->
<!--                                 <th class="px-12 py-2 bg-gray-200">-->
<!--                                    Packs-->
<!--                                 </th>-->
<!--                                 <th class="px-12 py-2 bg-gray-200">Buy</th>-->
<!--                              </tr>-->
<!--                           </thead>-->
<!--                           <tbody>-->
<!--                              <td class="w-3/5 text-left px-5 py-2 text-sm bg-gray-100" rowspan="6">-->
<!--                                 <p class="w-32">Pre-paid EWD</p>-->
<!--                              </td>-->
<!--                              <tr>-->
<!--                                 <td class="px-2 py-1">-->
<!--                                    1 Day-->
<!--                                 </td>-->
<!--                                 <td class="px-2 py-1">-->
<!--                                    ${{prepaidData.pricing.oneDay}}-->
<!--                                 </td>-->
<!--                              </tr>-->
<!--                              <tr>-->
<!--                                 <td class="px-2 py-1">-->
<!--                                    5 Days-->
<!--                                 </td>-->
<!--                                 <td class="px-2 py-1">-->
<!--                                    ${{prepaidData.pricing.fiveDays}}-->
<!--                                 </td>-->
<!--                              </tr>-->
<!--                              <tr>-->
<!--                                 <td class="px-2 py-1">-->
<!--                                    10 Days-->
<!--                                 </td>-->
<!--                                 <td class="px-2 py-1">-->
<!--                                    ${{prepaidData.pricing.tenDays}}-->
<!--                                 </td>-->
<!--                              </tr>-->
<!--                              <tr>-->
<!--                                 <td class="px-2 py-1">-->
<!--                                    25 Days-->
<!--                                 </td>-->
<!--                                 <td class="px-2 py-1">-->
<!--                                    ${{prepaidData.pricing.twentyFiveDays}}-->
<!--                                 </td>-->
<!--                              </tr>-->
<!--                              <tr>-->
<!--                                 <td class="px-2 py-1">-->
<!--                                    50 Days-->
<!--                                 </td>-->
<!--                                 <td class="px-2 py-1">-->
<!--                                    ${{prepaidData.pricing.fiftyDays}}-->
<!--                                 </td>-->
<!--                              </tr>-->
<!--                           </tbody>-->
<!--                     </table>-->
                  </div>
               </div>
            </div>
            <div v-if="isPricingLoading" class="mb-80 p-4 h-80">
               Loading...
            </div>
        </div>
      </div>
   </div>
   <div v-if="!true" class="w-full min-h-full text-center mt-5">
      <h1>Not authorized to view terms RRPVersions. Please contact your business admin.</h1>
   </div>
   </div>
</template>
<script>
import {DEFAULT_MODAL_HEIGHT} from '@/_helper/constants';
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex'

export default {
   data(){
      return{
         reseller: {},
         pricingData: [],
         buyData: [],
         loading: false,
         detailsId: null,
         modalHeight: DEFAULT_MODAL_HEIGHT,
         modalTitle: '',
         pricing: null,
         prepaidPricing: null,
         activePricing: null,
         preActiveEffectDate: null,
         activeExpireDate: null,
         arrVersions: [],
         isPricingLoading: false,
         selectedPricing: 'active',
         hasPreActive: true,
         hasPreActiveAlert: false
      }
   },
   async mounted(){
      this.$emit("updateLoading", true);
      this.detailsId = this.$store.getters[`account/me`].uid;
      const partnerDetails = this.reseller = await this.$store.dispatch(`reseller/getResellerByUID`, this.detailsId)
         if(partnerDetails){
            const allPricingVersions = await this.$store.dispatch(`standardpricing/getStandardPricingVersionsByMongoId`, partnerDetails._id);
            if(allPricingVersions){

               Object.keys(allPricingVersions).forEach(key => {
                  this.arrVersions.push(allPricingVersions[key])
               });

               this.setActive();
            }
         this.reseller = this.parseReseller(partnerDetails)
      }
   },
   methods: {
      setActive(){
         this.isPricingLoading = true;
         let activePricing, isActivePricing=[], isPreActivePricing=[];

         this.arrVersions.forEach((item) => {
            // Is Active
            let active = item.filter(obj=>obj.isActive == true);
            if(!_.isEmpty(active)){
               let expireGiven = moment(active[0].expiryDate);
               let current = moment().startOf('day');
               let expireDate = parseInt(moment.duration(expireGiven.diff(current)).asDays());
               this.activeExpireDate = `${expireDate} ${'day'}${expireDate !== 1 ? 's' : ''}`;
               isActivePricing.push(active);
            }

            // Is Pre-active
            let preActive = item.filter(obj=>obj.isPreActive == true);
            if(!_.isEmpty(preActive)){
               let effectGiven = moment(preActive[0].effectiveDate);
               let current = moment().startOf('day');
               let effDate = parseInt(moment.duration(effectGiven.diff(current)).asDays());
               this.preActiveEffectDate = `${effDate} ${'day'}${effDate !== 1 ? 's' : ''}`;
               this.hasPreActiveRRP = true;
               isPreActivePricing.push(preActive);
            }
         });

         // Fixed Selection
         switch (this.selectedPricing){
            case 'pre-active':
               activePricing = isPreActivePricing[0];
               break;
            case 'active':
               activePricing = isActivePricing[0];
               break;
         }
      
         if(activePricing){
            // const data = activePricing.filter(item => item.name === 'Pre-Paid EWD');
            // if(data) {
            //    this.prepaidPricingData = data.filter((item) =>
            //       item.isRrp === false
            //    )[0];
            //    this.prepaidData = data.filter((item) =>
            //       item.isRrp === false
            //    )[0];
            // }
            //
            // this.prepaidPricing = {
            //    pricing: this.prepaidPricingData.pricing,
            //    rrp: this.prepaidData.pricing,
            //    standardPricingMasterId: this.prepaidData.standardPricingMaster._id
            // }
            const nPricing = activePricing.filter((item) => item.name != 'Pre-Paid EWD');
            if(nPricing) {
               this.pricingData = nPricing.filter((item) =>
                  item.isRrp === false
               )
               this.buyData = nPricing.filter((item) =>
                  item.isRrp === false
               )
               const pricing  = [];
               this.pricingData.forEach((i) => {
                  this.buyData.forEach(j => {
                     if(i.name === j.name){
                        i.rrp = j.pricing;
                        pricing.push(i)
                     }
                  });
               });
               this.pricing = pricing;
            }
         }
         this.$emit("updateLoading", false);
         setTimeout(()=>{this.isPricingLoading = false},350);
      },
      parseReseller(reseller){
         return {
            id: reseller._id,
            abn: reseller.persona.abn,
            businessName: reseller.persona.businessName,
            tradingName: reseller.persona.tradingName,
            businessAddress: reseller.persona.businessAddress,
            currentStep: reseller.persona.currentStep,
            contactPhoneNumber: reseller.persona.contactPhoneNumber,
            contactEmail: reseller.persona.contactEmail,
            contactUserName: reseller.persona.contactUserName,
            supportEmail: reseller.supportEmail,
            supportPhone: reseller.supportPhone
         }
      },
      editRRP(){
         this.$refs.modal.openModal();
         this.modalTitle = 'Edit RRP'
      },
      // closeModal(){
      //    this.$refs.modal.closeModal();
      // },
      closeAlert(){
         this.hasPreActiveAlert = false
      },
      async updatedSelectedPricing(pricing, versionId = null){
         if(pricing){
               this.selectedPricing = pricing; 
         }
         await this.setActive(versionId);
      }
   },
   computed: {
     ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>