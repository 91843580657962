<template>
  <div>
    <div>
      <div v-if="!readDepots && hasRole" class="text-center">
        <h1>
          Not authorized to view depots. Please contact your business admin.
        </h1>
      </div>
      <div v-if="!hasRole" class="text-center">
        <h1>
          You are currently not associated with a role. Please contact support.
        </h1>
      </div>
      <DepotTree @reLoaded="reLoaded" v-if="readDepots" />
    </div>
  </div>
</template>

<script>
import DepotTree from "@/components/view/business/depot/depot-tree.vue"

export default {
  name: "Depot-Configs",
  components: {
    DepotTree,
  },
  data() {
    return {
      depots: [],
      readDepots: false,
      writeDepots: false,
      hasRole: true,
      isLoading: false
    };
  },
  async mounted() {
    this.$emit("updateLoading", true)
    const me = this.$store.getters[`account/me`]
    let securityRoles = me?.securityRoles
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles)
      this.readDepots = securityAccessObj.readDepots
      this.writeDepots = securityAccessObj.writeDepots
    }
  },
  methods: {
    reLoaded(){
      this.$emit("updateLoading", false)
    },
    setSecurityAccess(securityRoles) {
      let initialReadDepots = false;
      let initialWriteDepots = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole?.securityConfig;
        if (securityConfig.permissions?.readDepots?.value) {
          initialReadDepots = securityConfig?.permissions?.readDepots.value;
        }
        if (securityConfig.permissions?.writeDepots?.value) {
          initialWriteDepots = securityConfig?.permissions?.writeDepots?.value;
        }
      })

      console.log(initialReadDepots, initialWriteDepots);

      return {
        readDepots: true,
        writeDepots: true, // temporary true
      };
    },
  },
};
</script>