<template>
  <div class="mb-16">
    <div
      v-if="loading"
      class="loading absolute mx-auto w-full h-full translate-y-2/4 flex flex-col items-center"
    >
      <ui-spinner class="mx-auto mb-3"></ui-spinner>
      Loading....
    </div>
    <div v-else>
      <FormulateForm
        v-model="values"
        :schema="schema"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <div class="items-center justify-between">
          <StyledSubmit :backgroundColor="theme.secondaryColor">
            <FormulateInput
              type="submit"
              label="Next"
              input-class="btn btn-primary w-full"
              :disabled="hasErrors || loading"
              :style="{ backgroundColor: theme.secondaryColor }"
            />
          </StyledSubmit>
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
<script>
import schema from "./details-schema.js";
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";

export default {
  name: "Business-Details",
  components: { StyledSubmit },
  props: {
    detailsId: {
      type: String,
      default: null,
    },
    msg: String,
    postData: null,
  },
  data() {
    return {
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
      interChangingData: {},
      business: {},
    };
  },

  async mounted() {
    this.loading = true;
    if (this.postData?.steps) {
      this.values = this.postData.steps?.businessDetails || null;
      this.interChangingData = this.postData;
    } else {
      const businessDetails = await this.$store.dispatch(
        "business/getBusiness",
        { id: this.detailsId }
      );

      const businessContractDetails = await this.$store.dispatch(
        "contract/getSingleBusinessContractByBusinessMongoId",
        this.detailsId
      );

      if (businessDetails) {
        this.values = await this.parseBusiness(businessDetails);
        this.accountDetails = await this.parseBusinessAccount(businessDetails);
      }

      if (businessContractDetails) {
        this.moduleSelectionDetails = await this.parseModuleSelection(
          businessContractDetails
        );
        this.durationTermsDetails = await this.parseDurationTerms(
          businessDetails,
          businessContractDetails
        );
      }
    }
    this.loading = false;
  },

  methods: {
    async onSubmit() {
      this.loading = true;
      if (this.postData?.steps) {
        const businessDetails = this.values;
        if (businessDetails) {
          const preData = {
            ...this.interChangingData,
            steps: {
              ...this.interChangingData?.steps,
              ...{ businessDetails: businessDetails },
            },
          };
          this.$emit("nextStep", preData);
        }
      } else {
        const businessDetails = JSON.parse(JSON.stringify(this.values));
        const accountDetails = JSON.parse(JSON.stringify(this.accountDetails));
        const moduleSelectionDetails = JSON.parse(
          JSON.stringify(this.moduleSelectionDetails)
        );
        const durationTermsDetails = JSON.parse(
          JSON.stringify(this.durationTermsDetails)
        );

        const preData = {
          ...this.interChangingData,
          steps: {
            ...this.interChangingData?.steps,
            ...{
              businessDetails: businessDetails,
              accountDetails: accountDetails,
              moduleSelectionDetails: moduleSelectionDetails,
              durationTermsDetails: durationTermsDetails,
            },
          },
        };
        this.$emit("nextStep", preData);
      }
    },
    parseBusiness(business, origin = true) {
      if (!origin) {
        return {
          demoOption: business.demoOption,
        };
      } else {
        return {
          id: business._id,
          personaId: business.persona ? business?.persona._id : "",
          abn: business.persona ? business.persona.abn : "",
          businessName: business.persona ? business.persona?.businessName : "",
          currentStep: business.persona ? business.persona.currentStep : "",
          phoneNumber: business.persona
            ? business.persona.contactPhoneNumber
            : "",
          location: [{businessAddress: business.persona.businessAddress}],

        };
      }
    },
    parseBusinessAccount(data) {
      return {
        emailAddress: data.persona ? data.persona?.contactEmail : "",
        contactUserName: data.persona ? data.persona?.contactUserName : "",
      };
    },
    async parseModuleSelection(data, origin = true) {
      let moduleSelection = Object;
      if (data.businessModules.length) {
        let selectedCPL = [],
          specialty = [],
          specialtyModule = [];

        const pricingSchema = await this.$store.dispatch(
          `standardpricing/getContractModuleMaster`
        );

        if (pricingSchema) {
          const excludedFromCPL = [
            "EWD-PT",
            "Maintenance Compliance",
            "Mass Compliance",
            "Pre-Paid EWD",
          ];
          const includeForSpecialtyModules = [
            "Maintenance Compliance",
            "Mass Compliance",
          ];

          const corePlatformLicences = pricingSchema.filter(
            (s) => !excludedFromCPL.includes(s.name)
          );
          const specialtyModules = pricingSchema.filter((s) =>
            includeForSpecialtyModules.includes(s.name)
          );

          data.businessModules.forEach((i) => {
            let module = corePlatformLicences.filter(
              (j) => j._id == i.master
            )[0];
            if (module) {
              selectedCPL.push(module._id);
            }
          });

          data.businessModules.forEach((i) => {
            let module = specialtyModules.filter((j) => j._id == i.master)[0];
            if (module) {
              specialty.push(module._id);
              let moduleSp = {
                _id: module._id,
                minimums: i.minimums,
              };
              specialtyModule.push(moduleSp);
            }
          });
        }

        moduleSelection = {
          corePlatformLicencesCount: data.businessModules[0].minimums,
          selectedCPL: selectedCPL,
          selectedSpecialty: specialty,
        };

        if (specialtyModule.length) {
          specialtyModule.forEach((item) => {
            moduleSelection[item._id] = item.minimums;
          });
        }

        if (!origin) {
          moduleSelection.contractDuration = data?.contractDuration._id;
          (moduleSelection.billingPeriod = data?.billingPeriod._id),
            (moduleSelection.yearCount = data?.contractDurationYears),
            (moduleSelection.contractId = data._id);
          (moduleSelection.partnerMongoId = data?.partnerMongoId),
            (moduleSelection.contractType = data?.contractType);
        }
      }
      return moduleSelection;
    },
    async parseDurationTerms(business, contract) {
      let nContract,
        nBusiness = await this.parseBusiness(business, false);
      nContract = await this.parseModuleSelection(contract, false);

      if (nBusiness && nContract) {
        nContract.demoOption = nBusiness.demoOption;
      }
      return nContract;
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
