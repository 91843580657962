<template>
  <div class="py-8 px-5" style="min-height: calc(100vh - (112px + 2.75rem))">
    <div>
      <div class="flex">
        <DriverCount/>
        <VehicleCount/>
      </div>
      <div class="flex">
        <BusinessLinked/>
        <Notifications/>
      </div>
    </div>
  </div>
</template>

<script>
import { DriverCount, VehicleCount, BusinessLinked, Notifications } from './dashboard-cards'
import { mapGetters } from 'vuex'

export default {
  name: "Reseller-Dashboard",
  components: { DriverCount, VehicleCount, BusinessLinked, Notifications },
  data () {
    return {
      view: "list",
    };
  },
  mounted () {},
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
