<template>
  <div class="mb-16">
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!isLoading">
      <div class="text-center mb-10">
        <div class="font-bold text-black-primary text-2xl">
          {{ performance.auditee.userName }}
        </div>
        <div class="text-s">Auditee</div>
      </div>

      <div class="mb-5" v-if="performance.status == 'CONCLUDED'">
        <div class="text-red-500 text-center mt-3" v-if="error">
          {{ errorMessage }}
        </div>
        <div class="flex justify-center">
          <div class="mx-5">
            <div class="font-bold text-black-primary text-base">Start Date</div>
            <div class="text-s">{{ startDate }}</div>
          </div>
          <div class="mx-5">
            <div class="font-bold text-black-primary text-base">End Date</div>
            <div class="text-s">{{ endDate }}</div>
          </div>
          <div class="mx-5">
            <div class="font-bold text-black-primary text-base">
              Check Up Date
            </div>
            <div class="text-s">{{ nextCheckUpDate }}</div>
          </div>
        </div>

        <FormulateForm
          v-model="payload"
          @submit="onSubmitConcluded"
          #default="{ hasErrors }"
        >
          <FormulateInput
            v-model="performance.durationInMonths"
            class="text-s"
            type="number"
            label="Duration (Months)"
            name="durationInMonths"
            min="1"
            @change="onChangeDuration($event)"
          />
          <FormulateInput
            v-model="performance.periodicCheck"
            class="text-s"
            type="select"
            label="Periodic Check"
            name="periodicCheck"
            :options="periodicCheckOptions"
            @change="onChangePeriodicCheckup($event)"
          />

          <FormulateInput
            type="password"
            class="text-s"
            label="Password"
            name="password"
            :validation="'required'"
          />

          <FormulateInput
            class="text-s"
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || isLoading"
          />
        </FormulateForm>
      </div>
      <div class="mb-5" v-if="performance.status == 'ONGOING'">
        <div class="text-red-500 text-center mt-3" v-if="error">
          {{ errorMessage }}
        </div>
        <FormulateForm
          v-model="payload"
          @submit="onSubmitOnGoing"
          #default="{ hasErrors }"
        >
          <FormulateInput
            class="text-s"
            type="date"
            label="Date Created"
            name="date"
            :validation="!isConcluded ? 'required' : ''"
          />
          <FormulateInput
            class="text-s"
            type="textarea"
            label="Add Note"
            name="note"
            :validation="!isConcluded ? 'required' : ''"
          />
          <div class="flex justify-center p-2">
            <div class="w-1/2">
              <p class="font-medium text-sm mb-1">Conclude Performance</p>
            </div>
            <div class="w-1/2">
              <FormulateInput
                type="checkbox"
                :checked="isConcluded"
                @click="onIsConcluded"
              />
            </div>
          </div>
          <div v-if="isConcluded">
            <FormulateInput
              type="text"
              class="text-s"
              label="Performance Outcome"
              name="outcome"
              :validation="isConcluded ? 'required' : ''"
            />
          </div>

          <div v-if="isConcluded">
            <FormulateInput
              type="password"
              class="text-s"
              label="Password"
              name="password"
              :validation="isConcluded ? 'required' : ''"
            />
          </div>

          <FormulateInput
            class="text-s"
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || isLoading"
          />
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { PERFORMANCE_STATUSES, PERIODIC_CHECKS } from "../../_helper/constants";

export default {
  name: "update-performance",
  components: {},
  props: {
    propsPerformance: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    propsPerformance: {
      deep: true,
      handler() {
        this.setActivePerformance();
      },
    },
  },
  created() {
    this.me = this.$store.getters["account/me"];
    this.entityWebProfile = this.me.entity.webProfiles.find((profile) => {
      return profile.uid === this.me.uid;
    });
    this.isLoading = true;
    this.setActivePerformance();
    setTimeout(() => (this.isLoading = false), 300);
  },

  data() {
    return {
      isLoading: false,
      payload: { note: null },
      error: false,
      errorMessage: "",
      performance: "",
      isConcluded: false,
      me: null,
      entityWebProfile: null,
      periodicCheckOptions: PERIODIC_CHECKS,
      startDate: null,
      endDate: null,
      nextCheckUpDate: null,
    };
  },

  methods: {
    formattedDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    async onSubmitOnGoing() {
      const { date, note, outcome, password } = this.payload;
      try {
        if (this.isConcluded) await this.authenticate(password);
        const payload = {
          id: this.performance._id,
          checkUpNotes: date && note ? { date, note } : null,
          isConcluded: this.isConcluded,
          outcome,
        };
        await this.$store.dispatch("performance/updatePerformance", payload);
        location.reload();
      } catch (e) {
        this.error = true;
        this.errorMessage = "Something went wrong!";
      }
    },

    async onSubmitConcluded() {
      const { durationInMonths, periodicCheck, password } = this.payload;

      const payload = {
        id: this.performance._id,
        periodicCheck,
        durationInMonths,
        isConcluded: false,
      };
      try {
        await this.authenticate(password);
        await this.$store.dispatch("performance/updatePerformance", payload);
        location.reload();
      } catch (e) {
        this.error = true;
        this.errorMessage = "Something went wrong!";
      }
    },

    setActivePerformance() {
      this.performance = this.propsPerformance;
      if (this.performance.status == PERFORMANCE_STATUSES.ONGOING) {
        this.isConcluded = false;
      } else if (this.performance.status == PERFORMANCE_STATUSES.CONCLUDED) {
        this.isConcluded = true;
      }

      this.startDate = moment().format("YYYY-MM-DD");
      this.endDate = moment()
        .add(this.performance.durationInMonths, "months")
        .format("YYYY-MM-DD");

      const periodicCheckInDays = this.getPeriodicCheckUpInDays(
        this.performance.periodicCheck
      );
      this.nextCheckUpDate = moment()
        .add(periodicCheckInDays, "days")
        .format("YYYY-MM-DD");
    },
    onIsConcluded() {
      this.isConcluded = !this.isConcluded;
    },
    onChangeDuration(event) {
      const duration = event.target.value;
      this.endDate = moment().add(duration, "months").format("YYYY-MM-DD");
    },
    onChangePeriodicCheckup(event) {
      const periodicCheck = event.target.value;
      const result = this.getPeriodicCheckUpInDays(periodicCheck);
      this.nextCheckUpDate = moment().add(result, "days").format("YYYY-MM-DD");
    },
    getPeriodicCheckUpInDays(periodicCheck) {
      let periodicCheckInDays = 0;
      switch (periodicCheck) {
        case "DAILY":
          periodicCheckInDays = 1;
          break;
        case "WEEKLY":
          periodicCheckInDays = 7;
          break;
        case "FORTNIGHTLY":
          periodicCheckInDays = 14;
          break;
        case "MONTHLY":
          periodicCheckInDays = 30;
          break;
      }
      return periodicCheckInDays;
    },
    async authenticate(password) {
      const authPayload = {
        usernameEmail: this.entityWebProfile.email,
        password,
      };
      await this.$store.dispatch("account/login", authPayload);
    },
  },
};
</script>

<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
