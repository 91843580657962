import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class TSL {
    // @TSL
    createTSL(data) {
        return API.ACCOUNTS.post(`/tsl`, data)
    }
    getTSL({ id = null, query }) {
        if(id){
            return API.ACCOUNTS.get(`/tsl/${id}`)
        }else{
            let filter = '';
            if (typeof query.filter === 'object') try { filter = JSON.stringify(query.filter) } catch (e) { throw 'Invalid Filter object'}
            query.filter = filter;
            if (query) query = '?'+ObjectToQueryString(query);
            return API.ACCOUNTS.get(`/tsl${query}`)
        }
    }
    getTSLChangesByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/tsl/find-by-entity/${businessId}${query}`)
    }
    getTSLByEntity( entityId ){
        return API.ACCOUNTS.get(`/tsl/find-by-entity-id/${entityId}`)
    }
    patchTSL( data ) {
        return API.ACCOUNTS.patch(`/tsl/${data.id}`, data)
    }
    deleteTSL( id = null ) {
        return API.ACCOUNTS.delete(`/tsl/${id}`)
    }
}
