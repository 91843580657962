<template>
  <div class="w-full flex">
    <div class="w-10/12 mx-auto max-w-xl">
      <FormulateForm class="bg-white border border-gray-300 rounded">
        <div class="mb-5">
          <div class="text-sm text-neutral-dark font-bold form-label pr-2 p-5 rounded-t border-b border-gray-300">Two-Factor Authentication (2FA)</div>
          
          <div class="mx-5 mt-2 text-sm text-neutral-dark">
            <span>Two-Factor authentication will improve the security of your account.</span>
          </div>

          <div class="flex items-center mt-5 mr-1 mx-5">
            <font-awesome-icon v-if="submitLoading" icon="spinner" spin />
            <div v-else class="relative block w-12 align-middle select-none transition duration-200 ease-in">
              <input
                type="checkbox"
                name="is2FAEnabled"
                v-model="is2FAEnabled"
                @change="onSubmit"
                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
              />
              <label
                for="toggle"
                class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
              ></label>
            </div>
            
            <div v-if="submitLoading" class="text-neutral-dark font-semibold text-sm ml-2">Updating...</div>
            <div v-else class="text-neutral-dark font-semibold text-sm ml-2">{{ is2FAEnabled ? 'Enabled' : 'Disabled' }}</div>
          </div>


          <div v-if="is2FAEnabled" class="border-t border-gray-200 mb-10 mt-5 pt-6 mx-5 text-neutral-dark">
            <div class="flex flex-col">
              <small class="font-semibold">Step 1</small>
              <span class="text-sm inline-block">
                Download Google Authenticator on 
                <a class="font-bold" href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" :style="{ color: theme.primaryColor }">App Store</a> 
                or 
                <a class="font-bold" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US" target="_blank" :style="{ color: theme.primaryColor }">Google Play</a> 
                for your device.
              </span>
            </div>
            <div class="flex flex-col mt-5">
              <small class="font-semibold">Step 2</small>
              <span class="text-sm inline-block">
                Scan a barcode or enter a secret key provided.
              </span>
              <div class="flex mt-3">
                <img :src="imageUrl" width="196px" height="196px">
                <div class="flex flex-col ml-3">
                  <span class="font-semibold text-xs mt-2">Secret Key</span>
                  <span class="font-semibold text-lg">{{ secret }}</span>
                  <div class="mt-2 p-3 text-sm rounded bg-yellow-100">Please back up your secret key! You will need it in case of resetting two-factor authentication.</div>
                </div>
              </div>
            </div>
            <div class="flex flex-col mt-5">
              <small class="font-semibold">Step 3</small>
              <span class="text-sm inline-block">
                Enter the generated code upon logging in to Logmaster.
              </span>
            </div>
          </div>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      me: null,
      submitLoading: false,
      is2FAEnabled: false,
      imageUrl: null,
      secret: null
    }
  },
  mounted() {
    this.me = this.$store.getters[`account/me`];
    const twoFactorData = this.me?.webProfile?.twoFactorData;
    if (twoFactorData) {
      this.is2FAEnabled = true;
      this.imageUrl = twoFactorData?.imageUrl;
      this.secret = twoFactorData?.secret;
    }
  },
  methods: {
    async onSubmit() {
      this.submitLoading = true;

      await this.$store
        .dispatch(`rolesandrespo/setTwoFactor`, {
          webProfileId: this.me?.webProfile?._id,
          is2FAEnabled: this.is2FAEnabled
        })
        .then((data) => {
          if (data) {
            this.imageUrl = data.imageUrl;
            this.secret = data.secret;
          }

          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: `Two-Factor authentication ${ this.is2FAEnabled ? 'enabled' : 'disabled' }.`,
          });
        })
        .catch((err) => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
        });
      
      this.submitLoading = false;
    }
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
}
</script>

<style>

</style>