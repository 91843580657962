<template>
  <div class="w-full min-h-full flex-row account-view">
    <div class="w-full min-h-full flex-row items-left">
      <header class="flex justify-between text-lg font-bold items-center pt-3">
        {{pageTitle()}} Form
      </header><hr>
      <FormulateForm @submit="simulateForm" v-model="activeSemulatedForm"  class="flex w-full mt-5">
        <aside class="w-80 h-full border-r">
          <div class="overflow-y-auto flex flex-col border-b mb-2">
              <p class="text-sm text-gray-700 py-4 px-3">Names</p>
          </div>
          <div v-if="activeForm" class="mt-2 overflow-y-auto h-80">
            <div v-for="(datas, index) in computedForms" :key="index">
              <div v-for="(ndata, index) in datas" :key="index">
                <button 
                  v-show="ndata.name" 
                  :title="ndata.name"
                  :class="{'btn-primary': activeForm.id == ndata.id}"
                  class="text-left w-full px-1 py-2 border-b border-gray-200" 
                  type="button"
                  @click="selectForm(ndata.id)"
                  :style="{backgroundColor: theme.secondaryColor}"
                >
                <font-awesome-icon 
                  v-if="activeForm.id == ndata.id"
                  :icon="['fas', 'folder-open']"
                  class="my-auto mx-1.5"
                  size="sm"
                >
                </font-awesome-icon>
                <font-awesome-icon 
                  v-else
                  :icon="['fas', 'folder']"
                  class="my-auto mx-1.5"
                  size="sm"
                >
              </font-awesome-icon>
                <small>{{ndata.name}}</small>
              </button>                
              </div>
            </div>
          </div>
        </aside>
        <div class="w-full flex flex-col">
          <div class="flex justify-between items-center border-b mb-2">
              <p class="text-sm text-gray-700 p-4">Form Preview</p>
          </div>
          <div class="p-4 overflow-y-scroll">
            <div v-if="resSemForm">
              <div class="p-4 bg-white"> 
                Form Data: <br>
                <pre class="p-4 bg-green-100 rounded">
                  <code>{{resSemForm}}</code>
                </pre>
              </div><br>
            </div>
            <div class="w-full" v-if="activeForm">
              <div v-if="forms.filter(item => item.id == activeForm.id)[0]" class="bg-white shadow-md rounded px-5 mt-4 pt-5 pb-8 mb-4">
                <h6 class="font-medium text-center pb-3 leading-tight text-base mt-5 mb-3 text-blue-600">{{activeForm.name}}</h6>
                <section v-for="(section, index) in activeForm.sections" :key="index">
                  <h6 class="text-large font-semibold text-left leading-tight inline-block align-middle mt-1 pb-1">{{section.headline}}</h6>
                  <span v-if="section.subHeadline"> - </span><small class="italic text-small inline-block pt-4">{{section.subHeadline}} </small><hr>
                  <div class="mt-5" v-for="(control, index) in section.controls" :key="index">
                    <FieldContainer :component="control"/>
                  </div>
                </section>
              </div>
            </div>
            <div v-if="!activeForm" class="bg-white-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
              <p class="font-bold">Empty</p>
              <p>Form is not found.</p>
            </div>
          </div>
        </div>
        <aside class="w-24 h-full border-l" selected-control="[object Object]">
          <div class="overflow-y-auto flex flex-col border-b mb-2">
            <p class="text-sm text-gray-700 py-4 px-3">Actions</p>
          </div>
          <div class="pl-5 pt-2">
            <ul v-if="activeForm">
              <li class="hover:text-orange-primary py-4 pl-1 cursor-pointer">
                <a 
                  :href="`/admin/form/builder/${activeForm.id}/edit`" 
                  class="flex cursor-pointer"
                  title="Edit Form"
                  >
                  <font-awesome-icon 
                    :icon="['fas', 'edit']"
                    class="my-auto mx-1.5"
                    size="lg"
                    >
                  </font-awesome-icon>
                </a>
              </li>
              <li class="hover:text-orange-primary py-4  cursor-pointer">
                <button 
                    type="submit"
                    title="Simulate Form" 
                    class="flex cursor-pointer pl-1"
                  >
                    <font-awesome-icon
                      v-if="!formSimulating"
                      :icon="['fas', 'play-circle']"
                      class="my-auto mx-1.5"
                      size="lg"
                      >
                    </font-awesome-icon>
                    <font-awesome-icon
                      v-else
                      spin
                      :icon="['fas', 'cog']"
                      class="my-auto mx-1.5 spinning"
                      size="lg"
                      >
                    </font-awesome-icon>
                </button>
              </li>
            </ul>
          </div>
        </aside>
      </FormulateForm>
    </div>
  </div>
</template>
<script>
import {formatDate} from '@/_helper'
import _ from 'lodash'
import FieldContainer from '@/views/builder/containers/FieldContainer.vue'
import { mapGetters } from 'vuex'

export default {
  name:"Forms",
  components:{FieldContainer},
  data(){
    return {
      forms: [],
      activeForm: null,
      BusinessId: '',
      isFormActivated: false,
      formSimulating: false,
      activeSemulatedForm: null,
      resSemForm: null,
    }
  },
  async mounted(){
    this.$emit("updateLoading", true);
    this.init();
    this.$emit("updateLoading", false);
  },
  methods: {
    async init() {
      const qformType = this.$route.params.formType;
      const formTypes = [
        'incident', 
        'prestart',
        'fit-to-drive',
        'templates',
        'hazard'
      ];
      const formType = formTypes.filter(item => item == qformType)[0];
      if(!formType) window.location.href = "/error-404";

      const forms = await this.$store.dispatch(`form/getDefaultFormsByType`, formType);

      if(forms){
        const formData = this.formFormatItem(_.orderBy(forms, ['createdAt'],['desc']));
        this.forms = formData;
        this.activeForm = formData[0];
      }
    },
    formFormatItem(items){
      let nItems = [];
      if(items){
        items.map(item => {
          const form = {
            id: item._id,
            isActive: item.isActive,
            name: item.name,
            created: formatDate(item.createdAt),
            sections: item.sections,
            parentId: item.parentId
          }
          nItems.push(form);
        });
      }
      return nItems;
    },
    parseForms(data){
      return data.map((formData) => ([
        { id: formData.id, name: formData.name, itemType: 'string'},
        { id: formData.id, isActive: formData.isActive, itemType: 'string'},
        { id: formData.id, sections: formData.sections, itemType: 'array'},
        { id: formData.id, createdAt: formData.createdAt, itemType: 'createdAt'},
        { id: formData.id, parentId: formData.parentId, itemType: 'string'},
      ]));
    },
    async updateFormStatus(id){
      const data = {
        id: id,
      }
      await this.$store.dispatch('form/updateBusinessFormStatus', data)
      .then(res => {
        this.activeForm.isActive = res.isActive;
      }).catch(err => {
        this.toast('error', err.message);
      })
    },
    selectForm(formId){
      this.activeForm = this.forms.filter(item => item.id == formId)[0];
    },
    async simulateForm(){
      this.formSimulating = true;
      const form = this.activeSemulatedForm;
      await this.$store.dispatch('form/simulateForm', form)
      .then(res => {
        console.log(res);
          this.resSemForm = res;
          this.toast('success', 'Form successesfully submitted!');
          this.formSimulating = false;
      }).catch(() => {
          this.toast('error', 'Error required fields.');
          this.formSimulating = false;
      })
    },
    toast(state, msg){
      const message = {
        state: state,
        message: msg
      }
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', message);
    },
    pageTitle(){
      const string = this.$route.params.formType.replace(/-/g, " ");
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  computed: {
    computedForms(){
      return this.forms? this.parseForms(this.forms) : [];
    },
     ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>