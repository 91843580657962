<template>
  <div>
    <div v-if="readReports">
      <div class="flex flex-col h-full">
        <div class="p-5 bg-white shadow-md rounded overflow-hidden relative compliance-container">
          <div class="mb-3 pb-4 flex justify-between items-center w-full border-b border-gray-300">
            <div class="flex items-center">
              <div class="mr-3" style="width: 14rem;">
                <div class="block font-bold text-xs text-grey-500 mb-1">Date Range:</div>
                <DatePicker
                    ref="childDatePicker"
                    :date="selectedDateRange"
                    placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                    :displayBreach="false"
                    @selected="selectedDateRange = $event"
                    range noNavigation :clearable="false"
                />
              </div>
            </div>
            <div>
              <div class="">&nbsp;</div>
              <button
                  :disabled="!selectedDateRange"
                  :class="{ 'cursor-not-allowed': !selectedDateRange }"
                  class="btn btn-primary flex items-center"
                  @click.prevent="onGetReport"
                  style="height: 38px;"
                  :style="{backgroundColor: theme.secondaryColor}"
              >
                Get Report
              </button>
            </div>
          </div>

          <div class="flex w-full items-center driver-info-container">
            <VehicleReport
                ref="childVehicleReport"
                :disabledBefore="disabledBefore"
                @sendReportloading="sendReportloading = $event"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!readReports && hasRole" class="w-full min-h-full text-center mt-5">
      <h1>Not authorized to view reports. Please contact your business admin.</h1>
    </div>
    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content === 'send-report'">
        <div class="mb-16">
          <div v-if="!sendReportloading">
            <FormulateForm v-model="sendReportModel" @submit="onSubmitSendReport">
              <div class="items-center justify-between">
                <FormulateInput
                    type="email"
                    name="email"
                    label="Email address"
                    validation="required|email"
                    @input="handleStripEmailCase($event)"
                />
                <div>
                  <label class="font-medium text-sm mb-1">Report Date Range</label>
                  <DatePicker
                      ref="childDatePicker"
                      :date="selectedDateRange"
                      :disabledBefore="disabledBefore"
                      :displayEvent="false"
                      :displayBreach="false"
                      @selected="selectedDateRange = $event"
                      range noNavigation :clearable="false"
                      class="mb-5 mt-1 send-datepicker"
                  />
                </div>
                <FormulateInput
                    type="submit"
                    label="Submit"
                    input-class="btn btn-primary w-full mt-5"
                    :disabled="!canSend() || sendReportloading"
                />
              </div>
            </FormulateForm>
          </div>

          <div v-else class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center">
            <font-awesome-icon icon="cog" spin size="3x" class="text-gray-400"/>
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
.active {
  @apply border-orange-primary
}
.vertical {
  min-width: 7px;
  line-height: 7px;
  writing-mode: tb-rl;
  white-space: nowrap;
  text-align: center;
  transform: rotate(180deg);
}
.compliance-container {
  min-height: calc(100vh - 92px);
}
.send-datepicker::v-deep > div {
  @apply w-full
}
</style>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { Modal } from '@/components/commons';
import DatePicker from '@/components/commons/field/DatePicker';
import VehicleReport from "@/components/view/report/vehicle.vue";

export default {
  name: "VehiclesReport",
  components: { VehicleReport, Modal, DatePicker },
  data() {
    return {
      modal: {
        title: '',
        content: null,
        height: '50vh',
      },
      sendReportModel: null,
      sendReportloading: false,
      selectedDateRange: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      readReports: false,
      disabledBefore: null,
      hasRole: true,
      initialFalse: false,
    };
  },
  watch: {
    selectedDateRange: {
      handler: function () {
        this.onSearch()
      },
      deep: true
    }
  },
  async mounted(){
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = this.setSecurityAccess(securityRoles);
      this.readReports = securityAccessObj.readReports;
    } else {
      this.hasRole = false;
    }

    await this.onSearch();
    this.$emit("updateLoading", false);
    this.initialFalse = true;
  },
  methods: {

    canSearch() {
      return this.selectedDateRange.length > 1 && !_.isEmpty(this.selectedDateRange[0]);
    },

    canSend() {
      return !_.isEmpty(this.sendReportModel?.email) && this.selectedDateRange.length > 1 && !_.isEmpty(this.selectedDateRange[0]);
    },

    setSecurityAccess: (securityRoles) => {
      let initialReadReports = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.reports.readReports?.value) {
          initialReadReports = securityConfig.reports.readReports.value;
        }
      });
      return {
        readReports: initialReadReports
      };
    },

    async onSearch() {
      if (this.canSearch() && this.initialFalse) {
        await this.$refs.childVehicleReport.onSearch(this.selectedDateRange);
      }
    },

    onGetReport() {
      this.$refs.modal.openModal()
      this.modal.content = 'send-report';
      this.modal.title = 'Send Report To Email';
    },

    handleStripEmailCase(value){
      this.sendReportModel.email = value.toLowerCase()
    },

    async onSubmitSendReport() {
      const recipientEmail = this.sendReportModel.email;
      await this.$refs.childVehicleReport.onSubmitSendReport(recipientEmail);
    }

  },
  computed:{
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>