<template>
    <Stepper 
        :stepperSteps="stepperSteps" 
        @completed-step="completeStep" 
        @active-step="isStepActive" 
        :showNavigation="false"
        @closeModal="closeModal"
     />
</template>
<script>
import { Stepper } from '@/components/commons'
import { NewConnectionPricingProduct, EditConnectionAccount, NewConnectionPricingRRP, EditConnectionBusinessProfile }  from './stepper-component'
export default {
    name: 'Connection-Create',
    components: { Stepper },
    props: {
        editMode:{
            type: Boolean,
            default: false,
        },
        detailsId:{
            type: String,
            default: '',
        },
        connectionType:{
            type: String,
            default: 'partner',
        },
    },
    data(){
        return{
            stepperSteps: [
                {
                    stepIcon: 'briefcase',
                    stepTitle: 'Business Profile',
                    stepSequence: 'first',
                    stepComponent: EditConnectionBusinessProfile,
                    isCompleted: true,
                    detailsId: this.detailsId,
                    connectionType: this.connectionType,
                },
                {
                    stepIcon: 'table',
                    stepTitle: 'Buy Product',
                    stepSequence: 'second',
                    stepComponent: NewConnectionPricingProduct,
                    isCompleted: false,
                    detailsId: this.detailsId,
                    connectionType: this.connectionType
                },
                {
                    stepIcon: 'table',
                    stepTitle: 'RRP',
                    stepSequence: 'third',
                    stepComponent: NewConnectionPricingRRP,
                    isCompleted: false,
                    detailsId: this.detailsId,
                    connectionType: this.connectionType
                },
                {
                    stepIcon: 'user',
                    stepTitle: 'Account Details',
                    stepSequence: 'fourth',
                    stepComponent: EditConnectionAccount,
                    isCompleted: false,
                    detailsId: this.detailsId,
                    connectionType: this.connectionType,
                },
            ],
            activeStep: 0,
        }
    },
    methods: {
        completeStep(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    step.isCompleted = true;
                }
            })
        },
        isStepActive(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    if(step.isCompleted === true) {
                        step.isCompleted = false;
                    }
                }
            })
        },
        closeModal(){ 
            this.$emit('closeModal')
        },
    },
}
</script>