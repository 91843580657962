import styled from 'vue-styled-components'


const colorSchemeProps = {
    color: String, 
    backgroundColor: String, 
    hoverColor: String, 
    hoverBackgroundColor: String, 
    activeBorderColorMenu: String, 
    activeBorderColorSubMenu: String,
    borderColor: String,
    hoverBorderColor: String
}

const colorIconSchemeProps = {
    iconColor: String,
    iconHoverColor: String,
}


export const StyledRouterList = styled("li", colorSchemeProps)`
    a {
        color: ${() => "#FFF" || '#FFFFFF'};
    }
    background-color: ${({backgroundColor}) => backgroundColor || '#2873A5'};

    &:hover{
        a {
            color: ${() =>  '#FFFFFF'};
        }
        background-color: ${({hoverBackgroundColor}) => hoverBackgroundColor  || '#13669e'};
    }
    a.active, a.activesub{
        color: ${() =>  '#FFFFFF'};
        background-color: ${({hoverBackgroundColor}) =>  hoverBackgroundColor|| '#13669e'};
        font-weight: 500;
        outline: none;
    }
    a.active{
        border-color: ${() =>  '#FF9900'};
    }
    a.activesub{
        border-color: ${() =>  'rgb(255 237 213)'};
    }
    
    
`

export const StyledDiv = styled("div", colorSchemeProps)`
     color: ${({color}) => color || '#FFFFFF'}!important;
     background-color: ${({backgroundColor}) => backgroundColor || '#2873A5'};
     border-color: ${({borderColor}) => borderColor};
     
     &:hover{
        color: ${({hoverColor}) => hoverColor }!important;
        border-color: ${({hoverBorderColor}) => hoverBorderColor};
     }

`

export const StyledP = styled("p", colorSchemeProps)`
     color: ${({color}) => color};
     background-color: ${({backgroundColor}) => backgroundColor};

`

export const StyledLink = styled("a", colorSchemeProps)`
    color: ${({color}) => color || '#FFFFFF'};

    &:hover{
        background-color: ${({hoverBackgroundColor}) => hoverBackgroundColor || 'rgba(58, 121, 162, 0.1)'};
    }
`

export const StyledIconedLink = styled(StyledLink, colorIconSchemeProps)`
    color: ${({iconColor}) => iconColor || '#333333'};

    svg{
        path{
            fill:${({iconColor}) => iconColor || '#333333'};
        }
    }
    &:hover{
        background: none;
        color: ${({iconHoverColor}) => iconHoverColor || '#FF0099'};
        svg{
            path{
                fill:${({iconHoverColor}) => iconHoverColor || '#FF0099'};
                background: transparent;
            }
        }
        
    }
`

export const StyledIconed = styled('div', colorIconSchemeProps)`
    svg{
        path{
            fill:${({iconColor}) => iconColor || '#333333'};
        }
    }
    &:hover{
        background: none;
        svg{
            path{
                fill:${({iconHoverColor}) => iconHoverColor || '#FF0099'};
                background: transparent;
            }
        }
        
    }
`
export const StyledSpan = styled("span", colorSchemeProps)`
     color: ${({color}) => color};
     background-color: ${({backgroundColor}) => backgroundColor};
`

export const StyledSubmit = styled("div", colorSchemeProps)`
    button{
        background-color: ${({backgroundColor}) => backgroundColor};
        &:disabled{
                filter: brightness(0.6);
                background-color: ${({backgroundColor}) => backgroundColor}!important;

            &:hover{
                filter: brightness(0.6);
            }
        }
    }

    &:hover{
        button{
            filter: brightness(0.8);
            background-color: ${({backgroundColor}) => backgroundColor};
        }
    }
`

export const StyledButton = styled("button", colorSchemeProps)`
    
    background-color: ${({backgroundColor}) => backgroundColor};
    border-color: ${({borderColor}) => borderColor};
    &:disabled{
        filter: brightness(0.6);
        background-color: ${({backgroundColor}) => backgroundColor}!important;

        &:hover{
            filter: brightness(0.6);
        }
    }
    
    &:hover{
            filter: brightness(0.8);
            background-color: ${({backgroundColor}) => backgroundColor};
            border-color: ${({hoverBorderColor}) => hoverBorderColor};
    }
`
export const InputElementWrapper = styled("div", colorSchemeProps)`
    input{
        background-color: ${({backgroundColor}) => backgroundColor}!important;
        color: ${({backgroundColor}) => backgroundColor}!important;
        border-color: ${({borderColor}) => borderColor}!important;

        &:hover{
            background-color: ${({backgroundColor}) => backgroundColor}!important;
            border-color: ${({hoverBorderColor}) => hoverBorderColor}!important;
        }
    }

`