<template>
  <header class="header py-3 px-4 w-full z-20">
    <div class="header-content flex items-center flex-row">
      <div class="md:hidden">
        <button
          class="w-10 h-10 mr-3 relative focus:outline-none text-logmaster-400"
          @click="showSidebar(!sidebarDisplay)"
        >
          <div
            class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
          >
            <span
              aria-hidden="true"
              class="block absolute h-0.5 w-5 bg-current transform transition duration-300 ease-in-out"
              :class="{
                'rotate-45': sidebarDisplay,
                ' -translate-y-1.5': !sidebarDisplay,
              }"
            ></span>
            <span
              aria-hidden="true"
              class="block absolute h-0.5 w-5 bg-current transform transition duration-300 ease-in-out"
              :class="{ 'opacity-0': sidebarDisplay }"
            ></span>
            <span
              aria-hidden="true"
              class="block absolute h-0.5 w-5 bg-current transform transition duration-300 ease-in-out"
              :class="{
                '-rotate-45': sidebarDisplay,
                ' translate-y-1.5': !sidebarDisplay,
              }"
            ></span>
          </div>
        </button>
      </div>
      <h1
        class="font-bold text-sm md:text-xl lg:text-2xl hidden lg:block"
        :style="{ color: theme.primaryColor }"
      >
        {{ pageTitle }}
      </h1>
      <div class="flex ml-auto">
        <div v-if="isDemoPeriod" class="my-auto">
          <div class="relative inline-block text-center">
            <button class="mx-2 btn btn-primary" @click="toggleDemoPeriod">
              DEMO: {{ remainingDemoDays }} days remaining
            </button>
          </div>
        </div>
        <div v-if="!isGeotabAccount" class="my-auto">
          <div class="relative inline-block text-left dropdown">
            <button
              @click="toggleDashboardSettings"
              class="mx-2 hover:text-orange-primary text-xl"
            >
              <font-awesome-icon
                icon="cog"
                :style="{ color: theme.primaryColor }"
              />
            </button>
            <div
              v-if="dashboardToggle"
              class="transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
            >
              <DashboardSettings @toggleSetting="closeDashboardSettings" />
            </div>
          </div>
        </div>
        <div class="my-auto" v-if="me?.role?.name == 'business' || me?.roles?.includes('business')">
          <!-- <div v-if="!isGeotabAccount" class=" relative inline-block text-left dropdown"> -->
          <div class="relative inline-block text-left dropdown">
            <button class="mx-2 hover:text-orange-primary text-xl">
              <font-awesome-icon
                icon="exchange-alt"
                :style="{ color: theme.primaryColor }"
              />
            </button>
            <div
              class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
            >
              <div
                class="absolute right-0 bg-white rounded-md shadow-lg overflow-hidden w-64 px-5 py-2"
              >
                <div
                  class="w-full text-sm font-bold"
                  :style="{ color: theme.primaryColor }"
                >
                  {{
                    accountRoles.length > 0
                      ? "Switch to Profile"
                      : "Profile Switch"
                  }}
                </div>

                <div v-if="accountRoles.length > 0" class="mb-2 mt-1">
                  <button
                    v-for="(role, index) in accountRoles"
                    :key="index"
                    class="w-full btn btn-primary text-sm text-blue-300 capitalize mt-1"
                    type="submit"
                    @click="drillDown(role)"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    {{ role }}
                  </button>
                </div>
                <p v-else class="my-2">
                  <span class="text-sm">No profile to switch to.</span>
                  <button
                    class="w-full btn btn-primary text-sm text-blue-300 capitalize mt-1"
                    type="submit"
                    @click="createDriverProfile()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Create Driver Profile
                  </button>
                </p>

                <div v-if="showBusinessSiteToggle">
                  <hr class="my-5" />
                  <div
                    class="w-full text-sm font-bold"
                    :style="{ color: theme.secondaryColor }"
                  >
                    Switch to Business Site
                  </div>
                  <div v-if="businessSites.length" class="mb-2 mt-1">
                    <button
                      v-for="(site, index) in businessSites"
                      :key="index"
                      class="w-full btn btn-primary text-sm text-blue-300 capitalize mt-1"
                      type="submit"
                      @click="drillDown('business', site, site?.isMainBusiness)"
                      :style="{ backgroundColor: theme.secondaryColor }"
                    >
                      {{ site.name }}
                    </button>
                  </div>
                  <p v-else class="my-2">
                    <span class="text-sm">No business site to switch to.</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!isGeotabAccount" class="my-auto mx-3">
          <div class="relative inline-block text-left dropdown">
            <button class="mx-2 hover:text-orange-primary text-xl">
              <font-awesome-icon
                icon="bell"
                :style="{ color: theme.primaryColor }"
              />
              <font-awesome-icon
                v-show="notificationCount && notificationCount > 0"
                icon="circle"
                class="absolute top-1.5 right-1 text-red-500 text-xs border-2 border-white rounded-full"
              />
            </button>
            <div
              class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
            >
              <Notification></Notification>
            </div>
          </div>
        </div>

        <div class="my-auto">
          <div class="relative inline-block text-left dropdown">
            <button class="hover:text-orange-primary text-xl">
              <AccountInfo></AccountInfo>
            </button>
            <div
              class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95"
            >
              <AccountMenu></AccountMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h1
      class="font-bold mt-5 md:text-xl block lg:hidden text-center"
      :style="{ color: theme.primaryColor }"
    >
      {{ pageTitle }}
    </h1>

    <Modal
      v-if="isDemoPeriod"
      ref="demoPeriodModal"
      title="Manage Subscription"
      size="3xl"
      :permanent-modal="false"
    >
      <ManageSubscription
        :activeSolo="this.me.contract"
        :is-demo-popup="true"
        @closeModal="closeModal"
      />
    </Modal>
  </header>
</template>

<script>
import { Modal } from "@/components/commons";
import VueCookies from "vue-cookies";
import DashboardSettings from "./dashboard-settings";
import Notification from "./notification/Notification";
import AccountInfo from "./account/Info";
import AccountMenu from "./account/Menu";
import moment from "moment";
import _ from "lodash";
import ManageSubscription from "@/components/forms/payment/manage-subscription";

export default {
  name: "PortalHeader",
  components: {
    Notification,
    AccountInfo,
    AccountMenu,
    DashboardSettings,
    ManageSubscription,
    Modal,
  },
  props: {},
  data() {
    return {
      pageTitle: this.$route.meta["title"]
        ? this.$route.meta["title"]
        : this.$route.name,
      sidebarDisplay: false,
      notifications: [],
      businessSites: [],
      notificationCount: 0,
      dashboardToggle: false,
      showBusinessSiteToggle: false,
      accountRoles: [],
      notificationToggle: false,
      depots: [],
      me: null,
      isDemoPeriod: false,
      remainingDemoDays: null,
      currentRole: '',
      contract: {},
      entityDetails: {}
    };
  },
  methods: {
    async manageSoloDriverSubscription(userData) {
      console.log('User Data--', userData);
      if(userData?.driver?.parentRole == 'partner' || userData?.driver?.parentRole == 'reseller') {
        this.contract = userData.contract
        if(this.contract == undefined) {
          this.$refs.pricingModal.toggleModal();
          this.driverType = "partner-driver"
          this.soloPricingFormEntityId = userData.driver.entityId
        } else {
          this.entityDetails.role = this.contract.contractType == 'business' ? 'business' : 'driver'
          this.entityDetails.entityId = this.contract.businessMongoId
          this.parentEntityId = userData.driver?.entityId
          if(this.contract.hasRecurringPayment == true && this.contract.lastPaymentSuccessful == false) {
            await this.getPaymentMethod(userData.driver?._id);
          } else if(this.contract.status.name == 'Cancelled') {
            console.log("Contract expiring");
          } else {
            await this.getActivePaymentMethod(userData.driver?._id);
          }
        }
      }
    },
    showSidebar(value) {
      this.sidebarDisplay = value;
      this.$emit("show-sidebar", value);
    },
    toggleDashboardSettings(e) {
      e.stopPropagation();
      this.dashboardToggle = !this.dashboardToggle;
    },
    closeDashboardSettings() {
      this.dashboardToggle = false;
    },
    toggleNotificationSettings() {
      this.notificationToggle = !this.notificationToggle;
    },
    async updateMe(user) {
      await this.$store.dispatch(`account/updateMe`, user).then((response) => {
        this.$emit("fetchedProfile", response);
      });
    },
    createDriverProfile() {
      if (this.$route.path != "/business/drivers") {
        this.$router.push("/business/drivers");
      }
      this.$store.commit("driver/setShowDriverProfileModal", true);
    },
    isBusinessDriverPage() {
      return this.$route.path == "/business/drivers";
    },
    drillDown: async function (
      roleName,
      proxySite = null,
      isMainBusiness = false
    ) {
      const mainRole = this.$store.getters[`account/mainRole`];
      this.currentRole = roleName
      if (mainRole == roleName && _.isEmpty(proxySite)) {
        VueCookies.remove('proxyUser');
        VueCookies.remove('proxyRole');
        VueCookies.remove('proxySite');
        VueCookies.remove("proxyDriverProfileUid");
        VueCookies.remove('accessProxyToken');
        VueCookies.remove('refreshProxyToken');
      } else {
        let uid = this.me.uid;

        // Check if proxyDriverProfileUid is set, if its not null then driver is moving
        const proxyDriverProfileUid = this.$store.getters['account/proxyDriverProfileUid'];
        if (proxyDriverProfileUid && roleName == 'driver') {
          VueCookies.remove("proxyDriverProfileUid");

          uid = proxyDriverProfileUid;
        }

        // If current user is a driver and drilldown to business profile, set driverProfile
        if (this.me.role.name == 'driver' && roleName == 'business') {
          this.me.webProfiles = await this.$store.dispatch(`rolesandrespo/getWebProfilesByEmail`, this.me.email);
          const webProfile = this.me.webProfiles.find(profile => profile.parentRole === roleName);
          uid = webProfile.uid;
          
          await this.$store.commit("account/setProxyDriverProfileUid", this.me.uid);
        }

        this.$store.commit("account/setProxyRole", roleName);
        await this.$store.dispatch(`account/proxyUser`, {
          uid: uid,
          proxySite,
        });
      }

      if (!_.isEmpty(proxySite) && _.isObject(proxySite) && !isMainBusiness) {
        this.$store.commit("account/setProxySite", proxySite);
      } else {
        VueCookies.remove("proxySite");
      }

      window.location.href = this.$store.getters["allURL"][roleName]["default"];
    },
    async filterAccountRoles() {
      const role = this.me?.role?.name;
      this.accountRoles = [...this.$store.getters["account/roleNames"], role];
      const proxyRole = this.$store.getters["account/proxyRole"];
      const filterRole = proxyRole ? proxyRole : role;
      this.showBusinessSiteToggle = filterRole == "business" ? true : false;
      this.accountRoles = this.accountRoles.filter(
        (accountRole) => accountRole != filterRole
      );
    },
    async setBusinessSites() {
      const me = await this.$store.getters["account/me"];
      const proxySite = this.$store.getters["account/proxySite"];

      if (me.role.name == "business") {
        this.depots = await this.$store.dispatch(`business/getSites`, {
          depotId: me?.business?._id,
          isDeleted: false,
        });

        let sites = me?.webProfile?.site
          ? me?.webProfile?.depotSites
          : this.depots;
        if (
          !me?.webProfile?.site &&
          !_.isEmpty(proxySite) &&
          _.isObject(proxySite)
        ) {
          sites.unshift({
            _id: me?._id,
            name: "Main Business",
            isMainBusiness: true,
          });
        }

        this.businessSites = sites.filter((item) => {
          if (
            _.isEmpty(proxySite) ||
            (!_.isEmpty(proxySite) && item._id != proxySite._id)
          ) {
            return { ...item, uid: me.uid };
          }
        });
      }
    },
    async getDemoPeriod(contract) {
      if (contract && contract?.businessMetaData?.demoOption !== "NO_DEMO") {
        if (
          contract.status?.name !== "Cancelled" &&
          contract.status?.name !== "Suspended"
        ) {
          let startDate = moment(contract.startDate);
          let today = moment();
          if (startDate.isAfter(today)) {
            let remainingDays = startDate.diff(today, "days");
            this.remainingDemoDays = remainingDays;
            this.isDemoPeriod = true;
          } else {
            this.isDemoPeriod = false;
          }
        }
      }
    },
    async toggleDemoPeriod() {
      this.$refs.demoPeriodModal.toggleModal();
    },
  },

  watch: {
    $route() {
      this.pageTitle = this.$route.meta["title"]
        ? this.$route.meta["title"]
        : this.$route.name;
    },
    "$store.state.account.roleNames": async function () {
      await this.filterAccountRoles();
    },
    "$store.state.driver.updateHeaderSites": async function () {
      await this.setBusinessSites();
    },
  },
  async mounted() {
    this.me = await this.$store.getters["account/me"];

    await this.updateMe(this.me);
    await this.setBusinessSites();
    await this.filterAccountRoles();

    if (this.me?.role?.name === "business") {
      await this.getDemoPeriod(this.me.contract);
    }

    else if (this.me?.role?.name === 'driver') {
      await this.manageSoloDriverSubscription(this.me);
    }

    this.notifications = await this.$store.dispatch(
      `notification/getAllUnAcknowledgedNotifications`
    );
    if (this.notifications) {
      this.notificationCount = this.notifications.length;
    }
  },
  computed: {
    theme() {
      return this.$store.getters["theme/getColorScheme"];
    },
    isGeotabAccount() {
      return this.$store.getters["account/getIsGeotabAccount"];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dropdown:focus-within .dropdown-menu {
  opacity: 1;
  transform: translate(0) scale(1);
  visibility: visible;
}
</style>
