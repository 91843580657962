import { Contract, ContractConfig } from "@/api";
import _ from 'lodash'

export default{
    // @Contract
    async createContract({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Contract.createContract(data)
            commit("setNewCreatedBusinessContract", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async createDriverContract({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Contract.createDriverContract(data)
            commit("setNewCreatedDriverContract", response.data.data);

            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async createContractAndPayment({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Contract.createContractAndPayment(data)
            commit("setNewCreatedDriverContract", response.data.data);

            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async getContract( { state }, id ){
        try{
            const response = await Contract.getContract(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getContractByName( { state }, name ){
        if(!name) throw 'Invalid name '+state.me;
        try{
            const response = await Contract.getContractByUID(name);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getSingleBusinessContractByBusinessMongoId( { state }, id ){
        try{
            const response = await Contract.getSingleBusinessContractByBusinessMongoId(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getSingleBusinessContractByPartnerId( { state }, id ){
        try{
            const response = await Contract.getSingleBusinessContractByPartnerId(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getActiveBusinessContractFormSetupByBusinessId( { state }, id ){
        try{
            const response = await Contract.getActiveBusinessContractFormSetupByBusinessId(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    
    async getAllContracts({ state }, payload) {
        console.log(state);
        const pagination = payload.query
        try {
            const response = await Contract.getAllContracts(pagination);
            if(response.data?.resultsMeta){
                return {
                    results: response.data.data,
                    metadata: response.data.resultsMeta
                }
            }else{
                return response.data
            }
        } catch(error) {
            throw error.response.data;
        }
    },
    

    async getBusinessContract({ state }, payload) {
        if(!payload.businessId) throw 'Invalid businessID' + state.me;
        const params = {
            businessId: payload.businessId,
        }

        const pagination = payload.query

        try {
            const response = await Contract.getBusinessContracts(params, pagination);
            if(response.data?.resultsMeta){
                return {
                    results: response.data.data,
                    metadata: response.data.resultsMeta
                }
            }else{
                return response.data
            }
        } catch(error) {
            throw error.response.data;
        }
    },

    async getMyContract({ state }, payload) {
        if(!payload.entityId) throw 'Invalid driverID' + state.me;
        const params = {
            entityId: payload.entityId
        }
        try {
            const response = await Contract.getMyContract(params);
            return response.data.data;
        } catch(error) {
            throw error.response.data;
        }
    },

    async getPartnerBusinessContract({ state }, payload) {
        if(!payload.partnerId) throw 'Invalid partnerID' + state.me;
        const params = {
            partnerId: payload.partnerId
        }

        const pagination = payload.query

        try {
            const response = await Contract.getPartnerBusinessContracts(params, pagination);
            if(response.data?.resultsMeta){
                return {
                    results: response.data.data,
                    metadata: response.data.resultsMeta
                }
            }else{
                return response.data
            }
        } catch(error) {
            throw error.response.data;
        }
    },

    async getPartnerDriverContract({ state }, payload) {
        if(!payload.partnerId) throw 'Invalid partnerID' + state.me;
        const params = {
            partnerId: payload.partnerId
        }

        const pagination = payload.query

        try {
            const response = await Contract.getPartnerDriverContracts(params, pagination);
            if(response.data?.resultsMeta){
                return {
                    results: response.data.data,
                    metadata: response.data.resultsMeta
                }
            }else{
                return response.data
            }
        } catch(error) {
            throw error.response.data;
        }
    },

    async getResellerDriverContract({ state }, payload) {
        if(!payload.resellerId) throw 'Invalid resellerID' + state.me;
        const params = {
            resellerId: payload.resellerId
        }

        const pagination = payload.query

        try {
            const response = await Contract.getResellerDriverContracts(params, pagination);
            if(response.data?.resultsMeta){
                return {
                    results: response.data.data,
                    metadata: response.data.resultsMeta
                }
            }else{
                return response.data
            }
        } catch(error) {
            throw error.response.data;
        }
    },

    async generateBillingReport({ state }, payload) {
        if(!payload.entityId) throw 'Invalid entityId' + state.me;

        try {
            const response = await Contract.generateBillingReport(payload);
            return response.data
        } catch(error) {
            throw error.response.data
        }
    },

    async updateContract( { state }, payload ){
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'+state.me
        try{
            const response = await Contract.updateContract(payload.contractId, payload.data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async acceptContractAndChargePayment( { state }, payload ){
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'+state.me
        try{
            const response = await Contract.acceptContractAndChargePayment(payload);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async updateDriverContract( { state }, payload ){
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'+state.me
        try{
            const response = await Contract.updateDriverContract(payload.contractId, payload.data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async updateLastPaymentDate( { state }, payload ){
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'+state.me
        try{
            const response = await Contract.updateLastPaymentDate(payload.contractId, payload);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async updatePaymentTypeAndContract( { state }, payload ){
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'+state.me
        try{
            const response = await Contract.updatePaymentTypeAndContract(payload.contractId, payload);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteContract( { state }, id ){
        try{
            const response = await Contract.deleteContract(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async resendInvite( { state }, contractId ){
        if(!contractId) throw 'Invalid contract id'+state.me
        try{
            const response = await Contract.resendInvite(contractId);
            return response.data.data
        }catch(error){
            throw error.response.data
        } 
    },

    //GetStatuses
    async getContractStatus( { state }, id ){ //<- TODO: What ID is needed here?
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await ContractConfig.getStatus(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // Contract Terms
    async createContractTerms({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object '+state.me;
        try{
            const response = await Contract.createContractTerms(data)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getContractTerms(){
        try{
            const response = await Contract.getContractTerms();
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getLogmasterTermByPartner({ state }, id){
        try{
            const response = await Contract.getLogmasterTermByPartner(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getLogmasterTermByReseller({ state }, id){
        try{
            const response = await Contract.getLogmasterTermByReseller(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getPartnerTermByReseller({ state }, data){
        try{
            const response = await Contract.getPartnerTermByReseller(data);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getLogmasterTerms(){
        try{
            const response = await Contract.getLogmasterTerms();
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getPartnerTermsByPartner({ state }, id){
        try{
            const response = await Contract.getPartnerTermsByPartner(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getResellerTermsByReseller({ state }, id){
        try{
            const response = await Contract.getResellerTermsByReseller(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    
    async getContractTermsByUID({ state }, id){
        try{
            const response = await Contract.getContractTermsByUID(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    
    async updateContractTerms( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Contract.updateContractTerms(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async updateContractTermUser( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Contract.updateContractTermUser(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    
    async updateContractCredit( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Contract.updateContractCredit(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    
    async updateContractBillingPeriod( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Contract.updateContractBillingPeriod(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async cloneContractTerms( { state }, uid ){
        if(!uid) throw 'Invalid id '+state.me;
        try{
            const response = await Contract.cloneContractTerms(uid);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async deactivateContractTerms( { state }, uid ){
        if(!uid) throw 'Invalid id '+state.me;
        try{
            const response = await Contract.deactivateContractTerms(uid);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async setContractTermsActivateStatus( { state }, uid ){
        if(!uid) throw 'Invalid id '+state.me;
        try{
            const response = await Contract.setContractTermsActivateStatus(uid);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    // Contract Terms Group
    async createContractTermGroup({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object '+state.me;
        try{
            const response = await Contract.createContractTermGroup(data)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getContractTermsGroups(){
        try{
            const response = await Contract.getContractTermsGroups();
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getContractTermGroupsByPartner({state}, id){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Contract.getContractTermGroupsByPartner(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getContractTermGroupsByReseller({state}, id){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Contract.getContractTermGroupsByReseller(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getContractTermsGroupByBusiness({state}, id){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Contract.getContractTermsGroupByBusiness(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getContractTermsGroupsByType( { state }, type ){
        if(!type) throw 'Invalid type '+state.me;
        try{
            const response = await Contract.getContractTermsGroupsByType(type);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async saveSignatureContractTermGroup( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Contract.saveSignatureContractTermGroup(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    //Action for create business contract
    async createBusinessContract({state}, data){

        if(!data) throw `Invalid mongoId ${state.me}`

        try{
            const response = await Contract.createBusinessContract(data)
            return response.data
        }catch(error){
            throw error.response.data || error.response.message
        }
    },

    async acceptContract( { state }, contractId ){
        if(!contractId) throw 'Invalid contractId '+state.me;
        try{
            const response = await Contract.acceptContract({contractId, remarks: 'Accepted'});
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async declineContract( { state }, contractId ){
        if(!contractId) throw 'Invalid contractId '+state.me;
        try{
            const response = await Contract.declineContract({contractId, remarks: 'Declined'});
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async cancelSubscription({state}, payload) {
        if(!payload.entityId) throw 'Invalid entityId '+state.me;
        try{
            const response = await Contract.cancelSubscription(payload.entityId);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    
    async cancelDemoAndContract({state}, payload) {
        if(!payload.entityId) throw 'Invalid entityId '+state.me;
        try{
            const response = await Contract.cancelDemoAndContract(payload.entityId);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async addSeatCount({state}, payload) {
        if(!payload.contractId) throw 'Invalid contractId '+state.me;
        try{
            const response = await Contract.addSeatCount(payload);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
}