import { DriverManagement } from '@/api';

export default {
    // @Driver Management
    async getDevicesByUninstalledFlag( { state }, payload) {
        if(!payload.uninstalledFlag) throw 'Invalid uninstalledFlag' + state.me;
        const params = {
            uninstalledFlag: payload.uninstalledFlag
        }

        const pagination = payload.query
        try {
            const response = await DriverManagement.getAllDeviceIdentityByUninstalledFlag(params, pagination);
            if(response.data?.resultsMeta){
                return {
                    results: response.data.data,
                    metadata: response.data.resultsMeta,
                    status: response.data.statusCode,
                    message: response.data.message
                }
            }else{
                return response.data.data
            }
        } catch(error) {
            throw error.response.data;
        }
    },
    
    async getBusinessDevicesByUnistalledFlag({ state }, payload) {
        if(!payload.uninstalledFlag) throw 'Invalid uninstalledFlag' + state.me;
        if(!payload.businessId) throw 'Invalid businessID' + state.me;
        const params = {
            businessId: payload.businessId,
            uninstalledFlag: payload.uninstalledFlag
        }

        const pagination = payload.query
        try {
            const response = await DriverManagement.getBusinessDeviceIdentityByUninstalledFlag(params, pagination);
            if(response.data?.resultsMeta){
                return {
                    results: response.data.data,
                    metadata: response.data.resultsMeta,
                    status: response.data.statusCode,
                    message: response.data.message
                }
            }else{
                return response.data.data
            }
        } catch(error) {
            throw error.response.data;
        }
    },

    async getDeviceIdentity({ state }, id) {
        if(!id) throw 'Invalid device ID' + state.me;
        try {
            const response = await DriverManagement.getDeviceIdentity(id);
            return response.data.data;
        } catch(error) {
            throw error.response.data;
        }
    },

    async acknowledgeDevice( { state }, data) {
        if(!data.id) throw 'Invalid device ID' + state.me;
        const params = {
            id: data.id,
            isAcknowledged: data.isAcknowledged
        }
        try {
            const response = await DriverManagement.acknowledgeDevice(params);
            return response.data.data;
        }catch(error) {
            throw error.response.data
        }
    },

    async addDeviceRemarks( { state }, data) {
        if(!data.id) throw 'Invalid device ID' + state.me;
        const params = {
            id: data.id,
            remarks: data.remarks
        }
        try {
            const response = await DriverManagement.addDeviceRemarks(params);
            return response.data.data;
        }catch(error) {
            throw error.response.data
        }
    }
}