<template>
  <div class="min-h-screen h-full bg-gray-900">
    <div class="container py-10 h-full flex">
      <div class="login-container w-96 m-auto h-full">
        <div class="text-center m-5">
          <a :href="$store.getters['baseURL']"><img src="/assets/images/logo.png" alt="" class="w-64 m-auto"></a>
        </div>
        <div class="rounded-lg bg-gray-50 p-10 shadow">

          <div v-if="!loading">
            <div v-if="accepted">
              <h1 class="text-2xl text-green-600 text-center font-bold">Business Link already accepted</h1>
              <div class="text-sm text-center">Redirecting...</div>
            </div>
            <div v-if="success">
              <h1 class="text-2xl text-green-500 text-center font-bold">Business Link successfully accepted</h1>
              <div class="text-sm text-center">Redirecting...</div>
            </div>
            <div v-if="decline">
              <h1 class="text-2xl text-red-500 text-center font-bold">Business Link has been declined</h1>
              <div class="text-sm text-center">Redirecting...</div>
            </div>
            <div v-if="!success && !accepted && !decline">
              <div v-if="!isContractor">
                <p class="mb-5"><strong>Linking Details:</strong></p>
                <div class="tc">
                  <p><span class="font-bold">{{businessName}}</span> has requested you to link your Logmaster Australia EWD to their business portal.</p>
                  <p>If you accept the request, <span class="font-bold">{{businessName}}</span> will have access to your last 56 days of records prior to the link being accepted and any records moving forward  until your EWD in delinked from <span class="font-bold">{{businessName}}</span>.</p>
                  <p>You can delink a {{contractType}} through your driver portal at any time.</p>
                  <p>If you accept this link, click the accept button on the left below, if you wish to decline the request, select the decline button on the right below and we will notify <span class="font-bold">{{businessName}}</span>.</p>
                </div>

                <div v-if="!isSubmitting" class="w-full flex">
                  <button class="btn hover:bg-orange btn-primary w-full mr-2" @click="acceptContract">Accept</button>
                  <button class="btn btn-cancel w-full ml-2" @click="declineContract">Decline</button>
                </div>
                <div
                  v-else
                  class="rounded-lg bg-gray-50 p-10 flex flex-col items-center h-full mx-auto"
                >
                  <ui-spinner class="mx-auto mb-4"></ui-spinner>
                  Submitting...
                </div>

              </div>

              <div v-if="isContractor">
                <p class="mb-5"><strong>Contractor Linking Details:</strong></p>
                <div class="tc">
                  <p><span class="font-bold">{{businessName}}</span> has requested you to link your Logmaster Australia EWD to their business portal.</p>
                  <div class="mb-3">
                    <h2 class="font-bold mb-1">Choose contractor access:</h2>
                    <ul class="grid grid-cols-1 gap-1 w-full">
                      <li
                        v-for="access in contractorAccessOptions"
                        :key="access"
                        @click="setContractorAccess(access)"
                      >
                        <label
                          :class="{ 'border-orange-200 text-orange-200 hover:text-orange-200': contractorAccess == access }"
                          class="inline-flex justify-between items-center p-5 w-full text-grey-600 bg-white rounded-lg border border-gray-200 cursor-pointer hover:bg-gray-100"
                        >
                          <div class="block">
                              <div class="w-full text-md font-semibold mb-2 flex justify-between">
                               <span class="uppercase">{{ access.replace('-', ' ') }} Access</span>
                               <font-awesome-icon v-if="contractorAccess == access" size="lg" :icon="['fas', 'check-circle']" />
                              </div>
                              <div class="w-full text-xs text-grey-600" v-if="access == 'historical'">
                                <span class="font-bold">{{businessName}}</span> will have access to your last 56 days of records prior to the link being accepted and any records moving forward until your EWD is delinked.
                              </div>
                              <div class="w-full text-xs text-grey-600" v-if="access == 'moving-forward'">
                                <span class="font-bold">{{businessName}}</span> will only have access to your records the moment this link is accepted and any records moving forward until your EWD is delinked.
                              </div>
                          </div>
                        </label>
                      </li>
                  </ul>

                  </div>
                  <p>You can delink a {{contractType}} through your driver portal at any time.</p>
                  <p>If you accept this link, click the accept button on the left below, if you wish to decline the request, select the decline button on the right below and we will notify <span class="font-bold">{{businessName}}</span>.</p>
                </div>


                <div class="w-full flex">
                  <button class="btn hover:bg-orange btn-primary w-full mr-2" @click="acceptContract" :style="{backgroundColor: theme.secondaryColor}">Accept</button>
                  <button class="btn btn-cancel w-full ml-2" @click="declineContract">Decline</button>
                </div>



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default{
  name:"Solo-Driver-Accept-Contract",
  data(){
    return {
      loading:true,
      contractId:null,
      success: false,
      accepted: false,
      decline: false,
      businessName: null,
      contractType: null,
      isContractor: false,
      contractorAccess: 'historical',
      contractorAccessOptions: ['historical', 'moving-forward'],
      isSubmitting: false
    }
  },
  async mounted(){
    this.loading = false;
    this.contractId = this.$route.params['contractId'];
    this.contractType = this.$route.params['contractType'];
    try{
      if(this.contractType == 'business-link') {
        const contract = await this.$store.dispatch(`driver/getContract`,this.contractId);
        if(contract && contract?.business?.persona?.businessName){
          this.businessName = contract?.business?.persona?.businessName
        }
        this.accepted = contract.driverAccepted
        this.isContractor = contract.isContractor ? true : false
        if(!contract){
          window.location.href = '/login?message=Invalid request&state=error'
        }
        if(this.accepted){
          setTimeout(() => {
              window.location.href = '/login?message=Already Accepted&state=error'
            }, 3003);
        }
      } else if(this.contractType == 'partner-link') {
        await this.$store.dispatch(`contract/getContract`, this.contractId)
          .then((response) => {
            if (response && response.partnerMetaData?.persona?.businessName) {
              this.businessName = response?.partnerMetaData?.persona?.businessName
            }
            this.accepted = response.contractAccepted
            if(this.accepted){
              setTimeout(() => {
                  window.location.href = '/login?message=Already Accepted&state=error'
                }, 3003);
            }
          }).catch((error) => {
            window.location.href('/login?message=Invalid request&state=error')
            console.log(error)
          })
      } else if(this.contractType == 'reseller-link') {
        await this.$store.dispatch(`contract/getContract`, this.contractId)
          .then((response) => {
            if (response && response.resellerMetaData?.persona?.businessName) {
              this.businessName = response?.resellerMetaData?.persona?.businessName
            }
            this.accepted = response.contractAccepted
            if(this.accepted){
              setTimeout(() => {
                  window.location.href = '/login?message=Already Accepted&state=error'
                }, 3003);
            }
          }).catch((error) => {
            window.location.href('/login?message=Invalid request&state=error')
            console.log(error)
          })
      }
    }catch {
      window.location.href = '/login?message=Invalid request&state=error'
    }
  },
  methods: {
    setContractorAccess(access) {
      this.contractorAccess = access;
    },
    acceptContract: async function(){
      this.isSubmitting = true
      if(this.contractType == 'business-link') {
        const contractorAccess = this.isContractor ? { contractorAccess: this.contractorAccess } : {};
        const accept = await this.$store.dispatch(`driver/acceptContract`, { contractId: this.contractId, ...contractorAccess });
        if(accept){
          this.success = true;
          setTimeout(() => {
            window.location.href = '/login'
          }, 3003);
        }
      } else if(this.contractType == 'partner-link' || this.contractType == 'reseller-link') {
        const accept = await this.$store.dispatch(`contract/acceptContract`, { contractId: this.contractId });
        if(accept){
          this.success = true;
          setTimeout(() => {
            window.location.href = '/login'
          }, 3003);
        }
      }
      this.isSubmitting = false
    },
    declineContract(){
      this.decline = true
      setTimeout(() => {
            window.location.href = '/login?message=Decline success'
          }, 3003);
    }
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tc{
  @apply drop-shadow-lg overflow-y-auto m-0 p-4 bg-white mb-5 text-sm
}
p{
  @apply mb-2
}
</style>
