<template>
  <div>
    <div>
      <Tabs>
        <Tab title="Incidents">
          <RegisterTable :type="'incident'"></RegisterTable>
        </Tab>
        <Tab title="Hazards">
          <RegisterTable :type="'hazard'"></RegisterTable>
        </Tab>
        <Tab title="Defects">
          <RegisterTable :type="'defect'"></RegisterTable>
        </Tab>
        <Tab title="Infringements">
          <RegisterTable :type="'infringement'"></RegisterTable>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/commons/tab/Tabs.vue";
import Tab from "@/components/commons/tab/Tab.vue";
import RegisterTable from "@/pages/business/RegisterTable";
export default {
  name: "Register",
  components: { Tab, Tabs, RegisterTable },
};
</script>
