<template>
  <div>
    <Table
      v-if="readBusinesses"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="businessData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewBusiness(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="clipboard" class="my-auto mr-2" />
                  View
                </StyledIconedLink>
              </li>
              <li v-if="writeBusinesses">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="editBusiness(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                </StyledIconedLink>
              </li>
              <li v-if="!data.uid">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="deleteBusiness(data)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="trash" class="my-auto mr-2" /> Delete
                </StyledIconedLink>
              </li>
              <li v-if="!data.uid">
                <StyledIconedLink
                  title="Resend Invite Email"
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="resendInvite(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="paper-plane" class="my-auto mr-2" />
                  Resend
                </StyledIconedLink>
              </li>
              <li v-if="!noPaymentGateway && data.uid">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="updatePaymentType(data)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="exchange-alt" class="my-auto mr-2" />
                  Change
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="showBilling(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="money-bill-wave" class="my-auto mr-2" />
                  Billing
                </StyledIconedLink>
              </li>
              <li v-if="data.uid">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="showIntegrationLinks(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="globe" class="my-auto mr-2" /> Show Link
                </StyledIconedLink>
              </li>
              <li>
                <DrillDown
                  v-if="writeBusinesses"
                  :uid="data.uid"
                  role="business"
                />
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <template slot="callToActionTop">
        <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
          <button
            class="btn btn-primary"
            @click.prevent="showInviteLink()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Show invite link
          </button>
          <button
            v-if="writeBusinesses"
            class="btn btn-primary"
            @click.prevent="newBusiness()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            New Business
          </button>
        </div>
      </template>
    </Table>
    <div v-if="!readBusinesses && hasRole" class="text-center">
      <h1>
        Not authorized to view businesses. Please contact your {{ userType }}.
      </h1>
    </div>

    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>
    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <BusinessPartnerContractStep
          @closeModal="closeModal"
          @submitSuccess="submitSuccess"
        />
      </div>
      <div v-if="modalContent == 'view'">
        <BusinessDetailView
          :activeBusiness="activeBusiness"
          @editBusiness="editBusiness"
          @submitSuccess="submitSuccess"
        />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditBusinessPartnerStep
          :detailsId="modalId"
          @closeModal="closeModal"
          @submitSuccess="submitSuccess"
        />
      </div>
      <div v-if="modalContent == 'changePaymentType'">
        <ChangePaymentTypeForm
          :activeData="activeBusinessContract"
          @closeModal="closeModal"
          @submitSuccess="submitSuccess"
        />
      </div>
      <div v-if="modalContent == 'inviteLink'">
        <InviteLink
          :isSolo="false"
          :isUrlAvailable="isUrlAvailable"
          :shareableUrl="shareableUrl"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalContent == 'integrationLink'">
        <IntegrationLinks
          :entity-id="businessId"
          link-target="business"
          link-type="integration-links"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Table, Dropdown } from "@/components/commons/";
import BusinessDetailView from "@/components/view/business";
import ChangePaymentTypeForm from "@/components/forms/business/change-payment-type-form";
import {
  BusinessPartnerContractStep,
  EditBusinessPartnerStep,
} from "@/components/forms/business/";
import DrillDown from "@/components/modules/DrillDown.vue";
import IntegrationLinks from "@/pages/IntegrationLinks";
import { formatDate, formatPaginationSettings } from "@/_helper";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import _ from "lodash";
import { mapGetters } from "vuex";
import InviteLink from "./InviteLink.vue";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Partner-Business",
  components: {
    IntegrationLinks,
    Modal,
    Dropdown,
    BusinessPartnerContractStep,
    EditBusinessPartnerStep,
    Table,
    BusinessDetailView,
    DrillDown,
    InviteLink,
    StyledIconedLink,
    ChangePaymentTypeForm,
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      businesses: [],
      businessesOrigin: [],
      activeBusiness: {},
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        "Business Name",
        "Email",
        "Phone Number",
        "NZBN",
        "Contract Duration",
        "Billing Period",
        "Payment Type",
      ],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: {
        all: "All",
        linked: "Linked",
        pending: "Pending",
      },
      readBusinesses: false,
      writeBusinesses: false,
      userType: null,
      hasRole: true,
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      isUrlAvailable: false,
      submitLoading: false,
      copyLink: false,
      shareableUrl: "",
      me: {},
      noPaymentGateway: false,
      rawData: [],
      activeBusinessContract: {},
      businessId: {},
    };
  },

  async mounted() {
    this.init();
  },

  methods: {
    async init(paged = 1) {
      this.isLoading = true;
      this.me = this.$store.getters[`account/me`];
      this.shareableUrl = this.me?.entity?.businessUrl;
      if (this.shareableUrl) {
        this.isUrlAvailable = true;
      }

      let securityRoles = this.me?.securityRoles;
      if (securityRoles && securityRoles.length > 0) {
        this.userType = securityRoles[0].type;
        const securityAccessObj = await this.setSecurityAccess(securityRoles);
        this.readBusinesses = securityAccessObj.readBusinesses;
        this.writeBusinesses = securityAccessObj.writeBusinesses;
      } else {
        this.hasRole = false;
      }
      // const me = this.$store.getters[`account/me`];
      const entityID = this.$store.getters[`account/me`].entity._id;

      const payments = await this.$store.dispatch(
        `payment/getActivePaymentGatewayByEntityId`,
        entityID
      );
      if (payments.length <= 0) {
        this.noPaymentGateway = true;
        this.paymentModalHeight = "250px";
        // this.$refs.paymentModal.toggleModal();
      } else {
        this.noPaymentGateway = false;
      }

      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status != "all")
        query.filter.status = { $eq: this.filter.status };

      await this.$store
        .dispatch(`contract/getPartnerBusinessContract`, {
          partnerId: entityID,
          query,
        })
        .then((response) => {
          if (response) {
            if (response?.metadata) {
              this.paginationSettings = formatPaginationSettings(
                response?.metadata
              );
            } else {
              this.paginationSettings = formatPaginationSettings();
            }

            this.rawData = response.results;

            const businessTableData = this.formatItem(
              _.orderBy(response.results, ["createdAt"], ["desc"])
            );
            this.businesses = businessTableData;
          } else {
            this.businesses = [];
          }
        })
        .catch((error) => {
          this.businesses = [];
          console.log(error);
        });

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },

    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async resendInvite(businessId) {
      if (confirm("Resend invite email to this business?")) {
        await this.$store
          .dispatch("business/resendBusinessInvite", businessId)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: res.message,
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: res.message,
              });
            }
            this.$store.commit("setDialogNotify", true);

            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: errorMessage,
            });
          });
      }
    },

    async deleteBusiness(data) {
      if (
        confirm(
          `Are you sure you want to delete ${data.name}? It cannot be undone.`
        )
      ) {
        await this.$store
          .dispatch("business/deleteBusiness", data.id)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: res.message,
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: res.message,
              });
            }
            this.$store.commit("setDialogNotify", true);

            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: errorMessage,
            });
          });
      }
    },

    async updatePaymentType(data) {
      this.activeBusinessContract = this.rawData.filter(
        (item) => item._id === data.contractId
      )[0];
      this.modalContent = "changePaymentType";
      this.modalTitle = "Change Payment Type";
      this.$refs.modal.openModal();
      this.modalId = data.contractId;
      this.modalHeight = "60vh";
    },


    showBilling(id) {
      window.location.href = `/partner/businesses/billing/${id}`;
    },

    async filterChange() {
      await this.init();
    },

    async submitSuccess() {
      await this.init();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    debouncedSearchString() {
      this.init();
    },

    newBusiness() {
      this.modalContent = "new";
      this.modalTitle = "New Business";
      this.$refs.modal.toggleModal();
    },

    showInviteLink() {
      this.modalContent = "inviteLink";
      this.modalTitle = "Invite Businesses";
      this.$refs.modal.toggleModal();
    },

    showIntegrationLinks(id) {
      this.modalContent = "integrationLink";
      this.modalTitle = "Integration Links";
      this.$refs.modal.toggleModal();
      this.businessId = id;
    },

    viewBusiness(id) {
      this.activeBusiness = this.businessesOrigin.filter(
        (item) => item._id === id
      )[0];
      this.modalContent = "view";
      this.modalTitle = "Business Info";
      this.$refs.modal.toggleModal();
      this.modalId = id;
    },

    editBusiness(id) {
      this.modalContent = "edit";
      this.modalTitle = "Business Edit";
      this.$refs.modal.openModal();
      this.modalId = id;
    },

    formatItem(items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const business = {
            uid: item?.businessMetaData?.uid,
            contractId: item?._id,
            id: item?.businessMongoId,
            name: item?.businessMetaData?.persona?.businessName,
            email: item?.businessMetaData?.supportEmail,
            phone: item?.businessMetaData?.persona?.contactPhoneNumber,
            abn: item?.businessMetaData?.persona?.abn,
            created: formatDate(item?.createdAt),
            status: item?.isActive,
            isLicensed: item?.isLicensed,
            contractDuration: item?.contractDuration?.name,
            billingPeriod: item?.billingPeriod?.name,
            paymentType:
              item?.activationOption == "recurring_payment"
                ? "Credit Card"
                : "Invoice",
          };
          nItems.push(business);
        });
      }
      return nItems;
    },

    queryForKeywords(value) {
      this.searchKeyword = value;
    },

    parseBusiness(data) {
      return data.map((businessData) => [
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.name,
          contractId: businessData.contractId,
          itemType: "name",
          hasImage: true,
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.email,
          contractId: businessData.contractId,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.phone,
          contractId: businessData.contractId,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.abn,
          contractId: businessData.contractId,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.contractDuration,
          contractId: businessData.contractId,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.billingPeriod,
          contractId: businessData.contractId,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.paymentType,
          contractId: businessData.contractId,
          itemType: "string",
        },
      ]);
    },
    selectFilteredStatus() {
      this.selectedStatus =
        this.filterStatuses[this.selectedFilteredStatus].value;
    },
    setSecurityAccess(securityRoles) {
      let initialReadBusinesses = false;
      let initialWriteBusinesses = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig?.businesses?.readBusinesses?.value) {
          initialReadBusinesses =
            securityConfig.businesses.readBusinesses.value;
        }
        if (securityConfig?.businesses?.writeBusinesses?.value) {
          initialWriteBusinesses =
            securityConfig.businesses.writeBusinesses.value;
        }
      });

      return {
        readBusinesses: initialReadBusinesses,
        writeBusinesses: initialWriteBusinesses,
      };
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
  },
  computed: {
    businessData() {
      if (this.businesses) {
        return this.parseBusiness(this.businesses);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
