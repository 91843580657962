<template>
  <div>
    <div
      v-if="loading"
      class="loading absolute mx-auto my-24 w-full h-full translate-y-2/4 flex flex-col items-center"
    >
      <ui-spinner class="mx-auto mb-3"></ui-spinner>
      Loading....
    </div>
    <div v-else>
      <div v-if="!hasDriver" class="h-full">
        <button class="absolute ml-16 z-30" @click.prevent="showDriverList">
          <small
            v-if="!isDriverListOpen"
            class="btn-primary btn-xs px-3 shadow rounded-lg"
          >
            + Select from driver list
          </small>
          <small
            v-else
            class="bg-blue-200 text-white btn-xs px-3 shadow rounded-lg"
          >
            <font-awesome-icon icon="times" />
          </small>
        </button>

        <div class="relative">
          <FormulateForm @submit="nextStep">
            <FormulateInput
              type="text"
              label="Driver"
              validation="required"
              aria-autocomplete="off"
              autocomplete="off"
              placeholder="Write driver's name"
              v-model="driverStringBasedName"
            />

            <FormulateInput
              v-if="performance && performance._id"
              name="performanceId"
              type="text"
              label="Performance"
              v-model="performance._id"
              disabled
            />

            <FormulateInput
              v-if="filledForm && filledForm.id"
              name="formId"
              type="text"
              :label="filledForm.type"
              v-model="filledForm.id"
              disabled
            />

            <FormulateInput
              v-if="!isDriverListOpen"
              type="submit"
              label="Next"
              input-class="btn btn-primary w-full mt-8"
            />
          </FormulateForm>

          <div
            v-if="isDriverListOpen"
            class="w-full px-3 h-96 pb-3 -mt-16 pt-7 absolute z-30 bg-white shadow-lg"
          >
            <div class="content-center items-center w-full">
              <div classs="w-full content-center">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="username"
                >
                  Search driver
                </label>
                <FormulateInput
                  type="driver-select"
                  placeholder="Select a driver"
                  style="margin-bottom: 0px"
                  class="pb-4 content-center"
                  :getOptionKey="(option) => option.driver.udi"
                  :getOptionLabel="(option) => option.driver.driverName"
                  :filterable="false"
                  :clearable="false"
                  validation="required"
                  @input="onChange($event)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <button
          @click="clearDriver"
          title="Reset fields"
          class="text-red-900 float-right pr-4"
        >
          <span class="cursor-pointer">
            <font-awesome-icon icon="times" class="text-gray-400" />
          </span>
        </button>
        <br />
      </div>

      <div class="flex w-full pt-2" v-if="searchLoading">
        <div class="m-auto">
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300">Loading...</span>
        </div>
      </div>
      <div v-else>
        <div
          class="text-red-500 font-bold text-sm text-center"
          v-if="errorMessage.length"
        >
          {{ errorMessage[0] }}
        </div>

        <div class="mr-3">
          <FormulateForm
            v-if="hasDriver"
            v-model="driver"
            :schema="schema"
            @submit="nextStep"
          >
            <div class="items-center justify-between">
              <FormulateInput
                type="submit"
                label="Next"
                input-class="btn btn-primary w-full mt-2"
                :style="{ backgroundColor: theme.secondaryColor }"
              />
            </div>
          </FormulateForm>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/commons/ui/Spinner";
import schema from "./new-solo-driver-schema";
import * as moment from "moment";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Driver-Lookup",
  components: { Spinner },
  data() {
    return {
      driver: {},
      seachKeyword: "",
      errorMessage: [],
      searchLoading: false,
      schema: schema,
      hasDriver: false,
      driverStringBasedName: "",
      interChangingData: {},
      selectedDriver: null,
      loading: true,
      isDriverListOpen: false,
      test: null,
    };
  },
  props: {
    preData: null,
    postData: null,
    performance: {
      type: Object,
      default: () => {
        return {
          _id: null,
        };
      },
    },
    filledForm: {
      type: Object,
      default: () => {
        return {
          _id: null,
          type: "",
        };
      },
    },
  },
  mounted() {
    let data = this.preData || this.postData || null;
    if (data.steps) {
      this.driver = data.steps?.driverDetails;
      if (!_.isEmpty(this.driver?.driver)) {
        this.hasDriver = true;
      } else {
        this.driverStringBasedName = this.driver?.driverStringBasedName;
        this.driver = null;
      }
      this.interChangingData = data;
    }
    setTimeout(() => (this.loading = false), 400);
  },
  methods: {
    parshDriver(driver) {
      return {
        driver: driver._id,
        driverDateOfBirth: moment(driver.driverDateOfBirth).format("L"),
        driverName: driver.driverName,
        driversLicenseExpiry: moment(driver.driversLicenseExpiry).format("L"),
        driversLicenseNumber: driver.driversLicenseNumber,
      };
    },
    nextStep() {
      let driverDetails;
      if (_.isEmpty(this.driver?.driverName)) {
        let driver = {
          driverStringBasedName: this.driverStringBasedName,
        };
        driverDetails = driver;
      } else {
        driverDetails = this.driver;
      }

      const preData = {
        ...this.interChangingData,
        steps: {
          ...this.interChangingData?.steps,
          ...{
            driverDetails: driverDetails,
            performance: this.performance?._id,
            filledForm: this.filledForm.id,
            filledFormType: this.filledForm.type.toLowerCase(),
          },
        },
      };
      this.$emit("nextStep", preData);
    },
    clearDriver() {
      this.driver = null;
      this.hasDriver = false;
      this.seachKeyword = "";
      this.isDriverListOpen = false;
    },
    onChange(data) {
      if (data) {
        this.driver = this.parshDriver(data.driver);
        this.hasDriver = true;
        this.searchLoading = false;
      }
    },
    showDriverList() {
      this.isDriverListOpen = !this.isDriverListOpen;
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
