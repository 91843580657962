<template>
  <div>
    <FormulateForm
      name="documentBuilder"
      class="w-full"
      @submit="submitFormV2"
      #default="{ hasErrors }"
    >
      <div class="w-full h-fit flex-row items-center">
        <!-- TOP BAR-->
        <div class="flex flex-wrap -mx-3 mb-6 items-stretch md:items-center">
          <div class="flex justify-between items-center border-b mb-2 pl-5">
            <div>
              <FormulateInput
                type="submit"
                label="Save Changes"
                input-class="btn btn-primary w-40"
                :disabled="hasErrors"
              />
            </div>
            <label
              class="block uppercase tracking-wide text-xs font-bold mb-2 ml-4"
            >
              Name
            </label>
            <FormulateInput
              placeholder="Name"
              required
              name="name"
              validation="required"
              validation-error="required"
              class="block w-full px-4 ml-4 mb-3 mt-2 leading-tight"
              autocomplete="off"
              :value="editModeName"
            />
            <label
              class="block uppercase tracking-wide text-xs font-bold mb-2 ml-4"
            >
              Document Type
            </label>
            <FormulateInput
              type="select"
              placeholder="Name"
              required
              name="documentType"
              validation="required"
              validation-error="required"
              class="block w-full px-4 ml-4 mb-3 mt-2 leading-tight"
              autocomplete="off"
              :options="typeOption"
              value="INDUCTION_LETTER"
            />
          </div>
        </div>
        <!-- TOP BAR-->

        <div class="flex w-full mt-5">
          <DocumentController @selectedDocPart="selectedDocPart" />

          <div class="w-full flex flex-col">
            <!-- UPPER MIDDLE-->
            <div class="flex justify-between items-center border-b mb-2">
              <p class="text-sm p-4">Document Builder</p>
            </div>
            <!-- UPPER MIDDLE-->

            <!-- MIDDLE/ PREVIEW PART-->
            <div class="p-4">
              <div class="overflow-y-auto px-3" style="height: 669px">
                <DocumentPartContainer
                  v-for="parts in sections"
                  :key="parts.uuid"
                  :dynamicContent="parts"
                  :editMode="editMode"
                />
              </div>
            </div>
            <!-- MIDDLE/ PREVIEW PART-->
          </div>

          <!-- LEFT AND MIDDLE PART CONTAINER-->

          <!-- RIGHT PART MAKE THIS REACTIVE-->

          <div class="h-full border-l">
            <aside class="w-96">
              <div class="overflow-y-auto p-0" style="height: 669px">
                <SettingsContainer />
              </div>
            </aside>
          </div>

          <!-- RIGHT PART-->
        </div>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import DocumentPartContainer from "./document-part-container.vue";
import DocumentController from "./document-control-container.vue";
import SettingsContainer from "./settings-container.vue";
import { HELPER } from "@/api/helper";

import _ from "lodash";
export default {
  components: {
    DocumentPartContainer,
    DocumentController,
    SettingsContainer,
  },

  props: {
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },

    editModeName: {
      type: String,
      required: false,
      default: "",
    },

    editData: {
      type: Array,
      required: false,
      default: null,
    },
  },

  destroyed() {
    this.$store.state.accreditation.docPartContents = [];
    this.$store.state.accreditation.docVariables = [];
  },

  data: () => ({
    typeOption: [{ value: "INDUCTION_LETTER", label: "Induction Letter" }],
    previewing: false,

    paragraphs: [],
    bulletPoints: [],

    leftHeaderComponent: false,
    rightHeaderComponent: false,

    leftClosingComponent: false,
    rightClosingComponent: false,

    docSections: [],
  }),

  async created() {
    this.sections = this.editData;
  },

  computed: {
    content: {
      get() {
        return this.$store.state.accreditation.docPartContents;
      },
      set(newVal) {
        this.$store.state.accreditation.docPartContents = newVal;
      },
    },

    variables: {
      get() {
        return this.$store.state.accreditation.docVariables;
      },
      set(newVal) {
        this.$store.state.accreditation.docVariables = newVal;
      },
    },

    docParts: {
      get() {
        return this.$store.state.accreditation.docParts;
      },

      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    selectedContainer: {
      get() {
        return this.$store.state.accreditation.selectedContainer;
      },

      set(newVal) {
        this.$store.state.accreditation.selectedContainer = newVal;
      },
    },

    sections: {
      get() {
        const sectionArr = this.$store.state.accreditation.docParts.filter(
          (data) => data.isSection
        );

        return sectionArr;
      },

      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },
  },

  methods: {
    selectedDocPart(type) {
      const generatedId = `${type}-` + HELPER.getUUIDv4();

      if (type === "Headers") {
        let content = [];

        const existingHeader = _.find(this.docParts, (data) => {
          return data.type === "Headers";
        });

        content = [
          {
            part: "left",
            value: "",
            uuid: `Header-` + HELPER.getUUIDv4(),
            isSection: false,
            parentComponent: generatedId,
          },
          {
            part: "right",
            value: "",
            uuid: `Header-` + HELPER.getUUIDv4(),
            isSection: false,
            parentComponent: generatedId,
          },
        ];

        if (!existingHeader) {
          this.docParts.unshift({
            uuid: generatedId,
            type,
            content,
            contentPreview: "",
            contentVariables: [],
            isConfiguring: false,
            isSection: true,
          });
        } else {
          this.toast("error", "Document can only have one header");
        }

        return;
      }

      // if (type === "Closing") {
      //   const existingFooter = _.find(this.docParts, (data) => {
      //     return data.type === "Closing";
      //   });

      //   if (!existingFooter) {
      //     this.docParts.push({
      //       uuid: generatedId,
      //       type,
      //       content: [],
      //       contentPreview: "",
      //       contentVariables: [],
      //       isConfiguring: false,
      //       isSection: true,
      //     });
      //   } else {
      //     this.toast("error", "Document can only have one closing");
      //   }

      //   return;
      // }

      if (
        type === "Paragraph" ||
        type === "bulletPoints" ||
        type === "Addresses" ||
        type === "Title" ||
        type === "Closing" ||
        type === "schemeType"
      ) {
        let content = [];

        if (type === "Addresses") {
          content = [
            { part: "left", value: "" },
            { part: "right", value: "" },
          ];
        }

        // const existingFooter = _.find(this.docParts, (data) => {
        //   return data.type === "Closing";
        // });

        // if (existingFooter) {
        //   const footerIndex = _.findIndex(this.docParts, (data) => {
        //     return data.type === "Closing";
        //   });

        //   const newElement = {
        //     uuid: generatedId,
        //     type,
        //     content,
        //     contentPreview: "",
        //     contentVariables: [],
        //     isConfiguring: false,
        //     isSection: true,
        //   };

        //   this.docParts.splice(footerIndex, 0, newElement);

        //   return;
        // }

        this.docParts.push({
          uuid: generatedId,
          type,
          content,
          contentPreview: "",
          contentVariables: [],
          isConfiguring: false,
          isSection: true,
        });
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    async submitFormV2(e) {
      try {
        const headers = _.find(this.docParts, (data) => {
          return data.type === "Headers";
        });

        let contentCopy;

        if (headers?.content) {
          contentCopy = headers.content.map((data) => {
            const hasFile = data?.filePayload ? true : false;
            let payloadSize;

            if (data?.filePayload && data?.filePayload?.files[0]?.file?.size) {
              payloadSize = data?.filePayload?.files[0]?.file?.size;
            }

            return {
              part: data.part,
              value: data.value,
              filePayload: hasFile,
              fileSize: payloadSize,
              uuid: data.uuid,
              isSection: data.isSection,
              parentComponent: data.parentComponent,
              type: data.type,
            };
          });
        }

        let leftFile;

        let rightFile;

        if (headers && contentCopy) {
          //payload does not exist if no change/no file
          contentCopy.map((data) => {
            if (data.part === "left" && data.filePayload) {
              leftFile = {
                file: data.value,
                uuid: data.uuid,
                type: data.type,
                hasChanges: true,
              };

              if (data.fileSize > 500000) {
                throw new Error("File size too large. Max file size is 500kb");
              }
              delete data.filePayload;
            }

            if (data.part === "right" && data.filePayload) {
              rightFile = {
                file: data.value,
                uuid: data.uuid,
                type: data.type,
                hasChanges: true,
              };
              if (data.fileSize > 500000) {
                throw new Error("File size too large. Max file size is 500kb");
              }
              delete data.filePayload;
            }

            return JSON.parse(JSON.stringify(data));
          });

          const currentHeaderIndex = _.findIndex(this.docParts, (data) => {
            return data.type === "Headers";
          });

          this.docParts[currentHeaderIndex].content = contentCopy;
        }

        let sanitized = JSON.parse(JSON.stringify(this.docParts));

        const extractedParagraphs = this.generateVariables(sanitized);

        if (extractedParagraphs && extractedParagraphs.length > 0) {
          extractedParagraphs.map((data) => {
            const index = _.findIndex(sanitized, (item) => {
              return item.uuid === data.parentUuid;
            });

            sanitized[index].contentVariables.push(data);
            sanitized[index].isConfiguring = true;
            sanitized[index].content =
              sanitized[index].contentPreview.split(" ");
          });
        }

        const { name, documentType } = e;

        if (!this.editMode) {
          const createdCert = await this.$store.dispatch(
            `accreditation/createTemplate`,
            {
              sanitized,
              name,
              documentType,
            }
          );

          if (createdCert?.data?.success) {
            if (leftFile) {
              const { uuid, type, file } = leftFile;

              await this.$store
                .dispatch(`accreditation/uploadAccreditationFile`, {
                  fileType: "templateImage",
                  certId: createdCert.data.data._id.trim(),
                  increaseVersion: false,
                  file,
                  mimetype: type.trim(),
                  uuid,
                })
                .catch((err) => {
                  throw err;
                });
            }

            if (rightFile) {
              const { uuid, type, file } = rightFile;

              await this.$store
                .dispatch(`accreditation/uploadAccreditationFile`, {
                  fileType: "templateImage",
                  certId: createdCert.data.data._id.trim(),
                  increaseVersion: false,
                  file,
                  mimetype: type.trim(),
                  uuid,
                })
                .catch((err) => {
                  throw err;
                });
            }

            this.docParts = [];
            this.selectedContainer = {};

            this.toast("success", "Template created successfully");

            location.reload();
          }
        } else {
          const templateID = this.$route.params.id;

          await this.$store
            .dispatch(`accreditation/updateCertTemplate`, {
              content: sanitized,
              templateId: this.$route.params.id,
            })
            .catch((err) => {
              throw err;
            });
          this.toast("success", "Template saved");

          if (leftFile && leftFile?.hasChanges) {
            const { uuid, type, file } = leftFile;

            await this.$store
              .dispatch(`accreditation/uploadAccreditationFile`, {
                fileType: "templateImage",
                certId: templateID,
                increaseVersion: false,
                file: file,
                mimetype: type.trim(),
                uuid,
              })
              .catch((err) => {
                throw err;
              });

            delete leftFile.hasChanges;
          }

          if (rightFile && rightFile?.hasChanges) {
            const { uuid, type, file } = rightFile;

            await this.$store
              .dispatch(`accreditation/uploadAccreditationFile`, {
                fileType: "templateImage",
                certId: templateID,
                increaseVersion: false,
                file: file,
                mimetype: type.trim(),
                uuid,
              })
              .catch((err) => {
                throw err;
              });
            delete rightFile.hasChanges;
          }

          location.reload();
        }
      } catch (e) {
        console.log("err:", e);
        this.toast("error", e);
      }
    },

    untogglePreview() {
      this.previewing = !this.previewing;
    },

    generateVariables(data) {
      const paragraphs = data.filter((parts) => {
        return (
          (parts.type === "Paragraph" || parts.type === "Title") &&
          parts.isConfiguring === false
        );
      });

      let i;

      const paragraphVariables = [];

      let arrContent;

      for (i = 0; i < paragraphs.length; i++) {
        const partId = "part-" + "section-" + HELPER.getUUIDv4();
        arrContent = paragraphs[i].contentPreview.split(" ");
        const parentUuid = paragraphs[i].uuid;

        arrContent.map((data, idx) => {
          if (data.match(/{{\s*[\w.]+\s*}}/g)) {
            const match = data.match(/{{\s*[\w.]+\s*}}/g);
            paragraphVariables.push({
              type: match[0],
              index: idx,
              partId,
              parentUuid,
            });
          }
        });
      }

      return paragraphVariables;
    },
  },
};
</script>
