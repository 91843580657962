


export default {
    getFormSections(state) {        
        return state.formSections
    },

    getSelectedControlSection(state) {        
        return state.selectedControlSection
    },
    
    getSelectedControl(state) {        
        return state.selectedControl
    },
    
}