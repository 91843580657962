<template>
  <div class="w-full">
    <div class="w-full max-w-full flex flex-col rounded overflow-hidden bg-white shadow-md p-4">
      <div class="flex flex-col">
          <div class="md:text-base text-sm text-left text-neutral-text font-sans font-semibold">
            Monthly Seats Count
          </div>
        </div>
      <div class="w-full mt-4 flex flex-row justify-center items-center">
         <div v-if="isLoading" class="text-center"><Spinner class="m-auto mb-2"></Spinner> Loading chart data...</div>
        <BarChart classes="w-full" v-else :chart-data="chartData"  :chart-options="chartOptions" />
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from '@/components/commons/charts/BarChart'
import Spinner from '@/components/commons/ui/Spinner'

export default {
  components: { 
    BarChart,
    Spinner
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      chartData: {
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December'
        ],
        datasets: [
          {
            label: "Contract",
            fill: false,
            borderColor: '#2873a5',
            backgroundColor: '#2873a5',
            data: [200, 200, 200, 200, 200, 200 ,200, 200, 200, 200, 200 ,200],
            lineTension: 0.1
          },
          {
            type: 'line',
            label: "Extra",
            fill: false,
            borderColor: '#ff9900',
            backgroundColor: '#ff9900',
            data: [250, 250, 220, 230, 220, 250 ,250, 220, 230, 220, 250 ,250],
            lineTension: 0.1
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            fontColor: '#5b717b',
            fontSize: 10,
            boxWidth: 10,
            usePointStyle: true
          },
          align: 'left'
        },
        scales: {
          y: {
            stacked: false,
            ticks: {
              suggestedMin: 0,
              maxTicksLimit: 5,
              callback (value) {
                if (value < 0) {
                  return (value > -1000000) ? (value > -1000) ? value : value / 1000 + 'K' : value / 1000000 + 'M'
                } else {
                  return (value < 1000000) ? (value < 1000) ? value : value / 1000 + 'K' : value / 1000000 + 'M'
                }
              }
            }
          },
          x: {
            stacked: true,
            gridLines: {
              display: false
            }
          }
        }
      }
    }
  }
}
</script>