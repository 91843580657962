<template>
  <div>
    <Table
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="tslsData"
      @onPageChange="onPageChange($event)"
    >
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown>
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewTSL(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <template slot="callToActionTop">
        <button
          class="btn btn-primary"
          @click.prevent="newTSL()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Add TSL
        </button>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <div class="mb-16">
          <NewTSL @closeModal="closeModal"/>
        </div>
      </div>
      <div v-if="modalContent == 'view'">
          <div class="mb-16">
            <div class="text-center mb-10">
                <div class="font-bold text-black-primary text-2xl"> 
                    {{modalData.holderName}}
                </div>
                <div class="font-bold text-xs" :class="[modalData.isActive ? 'text-green-500' : 'text-red-500']">{{modalData.isActive ? 'ACTIVE' : 'INACTIVE'}}</div>
            </div>
             <div class="mb-5 w-full border-b pb-1"></div>
             <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{modalData.tslNumber}}</div>
                <div class="text-xs">TSL Number</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{modalData.tslTypeFull}} ({{modalData.tslType}})</div>
                <div class="text-xs">TSL Type</div>
            </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { Modal, Table, Dropdown } from "@/components/commons";
import { formatPaginationSettings } from "@/_helper";
import { StyledIconedLink } from "@/styled-components";
import { NewTSL } from '@/components/forms/business/tsl';
import { TSL_TYPES } from '@/constants/tsl-type';

export default {
  name: "TSL-Management",
  components: {
    Modal,
    Table,
    Dropdown,
    StyledIconedLink,
    NewTSL
  },
  data() {
    return {
      tsls: [],
      modalHeight: "65vh",
      modalContent: false,
      modalTitle: "",
      modalData: null,
      tableHeader: ["Holder Name", "TSL Number", "TSL Type", "Created"],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 10,
        visiblePageItemCount: 3,
      },
      filter: {
        limit: 10,
        search: "",
      },
      searchKeyword: "",
      selectedFilteredStatus: 0
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    await this.init();
    this.$emit("updateLoading", false);
  },
  methods: {
    async init() {
      const me = this.$store.getters[`account/me`];
      const tsls = await this.$store.dispatch(
        `tsl/getTSLByEntity`, me?.businessId
      );

      if (tsls) {
        if (tsls?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            tsls?.metadata
          );
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const tslTableData = _.orderBy(
          tsls,
          ["createdAt"],
          ["desc"]
        );
        this.tsls = tslTableData;

        this.$emit("updateLoading", false);
      } else {
        this.tsls = [];
        this.$emit("updateLoading", false);
      }
    },
    viewTSL(id) {
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Transport Service License";
      this.modalData = _.find(this.tsls, (tsl) => tsl._id == id);
      const tslTypeFull = _.find(TSL_TYPES, type => type.value == this.modalData.tslType);
      this.modalData.tslTypeFull = tslTypeFull ? tslTypeFull.label : '';
    },
    newTSL() {
      this.$refs.modal.openModal();
      this.modalContent = "new";
      this.modalTitle = "Add TSL";
    },
    parseTSLs(data) {
      let parseData = [];
      Object.values(data).forEach((tslData) => {
        parseData.push([
          {
            id: tslData.id,
            name: tslData.holderName,
            itemType: "name",
            hasImage: false,
          },
          { id: tslData.id, name: tslData.tslNumber, itemType: "string" },
          { id: tslData.id, name: tslData.tslType, itemType: "string" },
          { id: tslData.id, name: tslData.created, itemType: "string" },
        ]);
      });
      return parseData;
    },
    async onPageChange(event) {
      if (event.page) await this.init(event.page);
    },

    async filterChange() {
      await this.init();
    },
    filterRow(keyword, attributeToFilter) {
      return keyword
        .toLowerCase()
        .split(" ")
        .every((v) => attributeToFilter.trim().toLowerCase().includes(v));
    },
    async onSearch() {
      await this.init();
    },
    async toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    async closeModal() {
      this.$refs.modal.closeModal();
      this.$emit("updateLoading", true);
      await this.init();
      this.$emit("updateLoading", false);
    },
  },
  computed: {
    tslsData() {
      if (this.tsls) {
        let tslsComputedData = [];
        this.tsls.map((tsl) => {
          tslsComputedData.push({
            id: tsl._id,
            holderName: tsl.holderName,
            tslNumber: tsl.tslNumber,
            tslType: tsl.tslType,
            created: moment(tsl.createdAt).format('YYYY-MM-DD')
          });
        });

        return this.parseTSLs(tslsComputedData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      isDarkModeValue: "getIsDarkMode",
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
::v-deep .lhv-datepicker.no-nav .lhv-input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
</style>
