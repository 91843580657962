<template>
  <div>
    <div v-if="readVehicles" class="h-full flex flex-col">
      <Tabs
        clickEvent
        @click="fetchTab"
        :activeTab="0"
        class="flex flex-col h-full"
      >
        <Tab title="Active" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                          class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                          @click.prevent="viewVehicle(data.id)"
                          :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto" /> View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                          class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                          @click.prevent="editVehicle(data.id)"
                          :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto" /> Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="disableVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye-slash" class="my-auto" />
                        Disable
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="setAsDefect(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="car-crash" class="my-auto" />
                        Defect
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="setOdometer(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="tachometer-alt"
                          class="my-auto"
                        />
                        Odometer
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Vehicle Report
                  </button>
                </div>
                <a
                  class="flex cursor-pointer text-2xl mr-2"
                  @click.prevent="showConfig()"
                  ><font-awesome-icon
                    icon="cog"
                    class="my-auto mx-1.5 hover:text-orange"
                    :style="{ color: theme.primaryColor }"
                /></a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="downloadVehicles()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                    title="Download All Vehicles"
                  >
                    <font-awesome-icon icon="download" class="my-auto" />
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import Vehicle
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add Vehicle
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Defected Vehicles" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto" /> View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto" /> Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="setAsNonDefect(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="car" class="my-auto" /> Clear
                        Defect
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Vehicle Report
                  </button>
                </div>
                <a
                  class="flex cursor-pointer text-2xl mr-2"
                  @click.prevent="showConfig()"
                  ><font-awesome-icon
                    icon="cog"
                    class="my-auto mx-1.5 hover:text-orange"
                    :style="{ color: theme.primaryColor }"
                /></a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import Vehicle
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add Vehicle
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Disabled Vehicles" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto" /> View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto" /> Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="activateVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="check" class="my-auto" />
                        Re-enable
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Vehicle Report
                  </button>
                </div>
                <a
                  class="flex cursor-pointer text-2xl mr-2"
                  @click.prevent="showConfig()"
                  ><font-awesome-icon
                    icon="cog"
                    class="my-auto mx-1.5 hover:text-orange"
                    :style="{ color: theme.primaryColor }"
                /></a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import Vehicle
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add Vehicle
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Driver Pending Vehicles">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown :tableHeight="tableHeight">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto" /> View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto" /> Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="disableVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye-slash" class="my-auto" />
                        Disable
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <a
                  class="flex cursor-pointer text-2xl mr-2"
                  @click.prevent="showConfig()"
                  ><font-awesome-icon
                    icon="cog"
                    class="my-auto mx-1.5 hover:text-orange"
                    :style="{ color: theme.primaryColor }"
                /></a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import Vehicle
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add Vehicle
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Duplicated Vehicles" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="duplicatedTableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto" /> View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto" /> Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="mergeVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="clone" class="my-auto" /> Merge
                        Vehicle
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Vehicle Report
                  </button>
                </div>
                <a
                  class="flex cursor-pointer text-2xl mr-2"
                  @click.prevent="showConfig()"
                  ><font-awesome-icon
                    icon="cog"
                    class="my-auto mx-1.5 hover:text-orange"
                    :style="{ color: theme.primaryColor }"
                /></a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import Vehicle
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add Vehicle
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab
          v-if="isRootBusiness && depots.length > 0"
          title="All Sites"
          class="flex-1 flex flex-col"
        >
          <Table
            v-if="tabIndex == 4"
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto mr-2" />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Vehicle Report
                  </button>
                </div>
                <a
                  class="flex cursor-pointer text-2xl mr-2"
                  @click.prevent="showConfig()"
                  ><font-awesome-icon
                    icon="cog"
                    class="my-auto mx-1.5 hover:text-orange"
                    :style="{ color: theme.primaryColor }"
                /></a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import Vehicle
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add Vehicle
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab
          v-if="isGeotabAccount"
          title="Geotab Unlinked Vehicles"
          class="flex-1 flex flex-col"
        >
          <Table
            :isLoading="isLoading"
            :tableHeader="geotabTableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2">
                <div class="text-black-primary whitespace-no-wrap text-lg flex">
                  <a
                    class="flex cursor-pointer"
                    @click.prevent="newGeotabVehicle(data.id)"
                  >
                    <font-awesome-icon
                      icon="sign-in-alt"
                      class="my-auto mx-1.5 hover:text-orange-primary"
                  /></a>
                </div>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <a
                  class="flex cursor-pointer text-2xl mr-2"
                  @click.prevent="syncGeotab()"
                >
                  <font-awesome-icon
                    icon="undo"
                    class="my-auto mx-1.5 hover:text-orange"
                    :style="{ color: theme.primaryColor }"
                /></a>
                <a
                  class="flex cursor-pointer text-2xl mr-2"
                  @click.prevent="showConfig()"
                >
                  <font-awesome-icon
                    icon="cog"
                    class="my-auto mx-1.5 hover:text-orange"
                    :style="{ color: theme.primaryColor }"
                /></a>
                <button
                  class="btn btn-primary"
                  @click.prevent="newVehicle()"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Add Vehicle
                </button>
              </div>
            </template>
          </Table>
        </Tab>
      </Tabs>
    </div>
    <div
      v-if="!readVehicles && hasRole"
      class="w-full min-h-full text-center mt-5"
    >
      <h1>
        Not authorized to view vehicles. Please contact your business admin.
      </h1>
    </div>
    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal
      ref="modal"
      :title="modal.modalTitle"
      :size="modal.modalSize"
      :height="modal.modalHeight"
    >
      <div v-if="modal.modalContent == 'new'">
        <NewVehicle @closeRefresh="closeRefresh" />
      </div>
      <div v-if="modal.modalContent == 'view'">
        <VehicleDetailsView
          @closeRefresh="closeRefresh"
          @editVehicle="editVehicle"
          :detailsId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent == 'edit'">
        <EditVehicle @closeRefresh="closeRefresh" :detailsId="selectedId" />
      </div>
      <div v-if="modal.modalContent == 'notification'">
        <ApprovalVehicle />
      </div>
      <div v-if="modal.modalContent == 'new-vehicle-import'">
        <NewVehicleImport @closeRefresh="closeRefresh" />
      </div>
      <div v-if="modal.modalContent == 'new-geotab-vehicle'">
        <NewVehicle
          @closeRefresh="closeRefresh"
          :defaultData="modal.modalData"
        />
      </div>
      <div v-if="modal.modalContent === 'disable'">
        <ApprovalDisableVehicle
          @closeRefresh="closeRefresh"
          :detailsId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent === 'defect'">
        <ApprovalDefectVehicle
          @closeRefresh="closeRefresh"
          :detailsId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent === 'activate'">
        <ApprovalActivateVehicle
          @closeRefresh="closeRefresh"
          :detailsId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent === 'non-defect'">
        <ApprovalNonDefectVehicle
          @closeRefresh="closeRefresh"
          :detailsId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent === 'merge'">
        <MergeVehicle @closeRefresh="closeRefresh" :detailsId="selectedId" />
      </div>
      <div v-if="modal.modalContent === 'odometer'">
        <EditOdometer @closeRefresh="closeRefresh" :detailsId="selectedId" />
      </div>
    </Modal>

    <Modal
      ref="configModal"
      title="Vehicle Configuration"
      size="5xl"
      :usepadding="false"
      :height="modal.modalHeight"
    >
      <VehicleConfiguration />
    </Modal>
  </div>
</template>

<script>
import { Dropdown, Modal, Table } from "@/components/commons";
import {
  ApprovalVehicle,
  EditVehicle,
  NewVehicle,
} from "@/components/forms/vehicle";
import { NewVehicleImport } from "@/components/forms/vehicle/vehicle-import";
import VehicleDetailsView from "@/components/view/vehicle/";
import { VehicleConfiguration } from "@/components/config/vehicle";
import {
  DEFAULT_MODAL_HEIGHT,
  GEOTAB_COOKIE,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import {
  formatDate,
  formatPaginationSettings,
  convertJSONToCSV,
  downloadCSV,
} from "@/_helper";
import _, { isEmpty } from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import Tabs from "@/components/commons/tab/Tabs";
import Tab from "@/components/commons/tab/Tab";
import VueCookies from "vue-cookies";
import ApprovalDisableVehicle from "@/components/forms/vehicle/approval-disable-vehicle.vue";
import ApprovalDefectVehicle from "@/components/forms/vehicle/approval-defect-vehicle.vue";
import ApprovalActivateVehicle from "@/components/forms/vehicle/approval-activate-vehicle.vue";
import ApprovalNonDefectVehicle from "@/components/forms/vehicle/approval-non-defect-vehicle.vue";
import MergeVehicle from "@/components/forms/vehicle/merge-vehicle.vue";
import EditOdometer from "@/components/forms/vehicle/edit-odometer.vue";

export default {
  name: "Vehicles",
  components: {
    EditOdometer,
    Dropdown,
    MergeVehicle,
    ApprovalNonDefectVehicle,
    ApprovalActivateVehicle,
    ApprovalDefectVehicle,
    ApprovalDisableVehicle,
    Table,
    Modal,
    NewVehicle,
    EditVehicle,
    VehicleDetailsView,
    VehicleConfiguration,
    NewVehicleImport,
    ApprovalVehicle,
    Tabs,
    Tab,
    StyledIconedLink,
  },
  data() {
    return {
      isLoading: false,
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: DEFAULT_MODAL_HEIGHT,
        modalSize: "lg",
        modalData: "",
      },
      vehicles: [],
      tableHeight: 0,
      tabIndex: 0,
      // tableHeader: [
      //   "Plate Number",
      //   "Registration Expiry",
      //   "Fleet ID",
      //   "GVM",
      //   "Vehicle Class",
      //   "Vehicle Type",
      //   "Last Known Odometer",
      //   "Date Created",
      //   "Status",
      //   // TODO: Optional to enable if needed
      //   // 'Drive Vehicle',
      //   // 'Concrete Agitator',
      //   // 'Livestock Vehicle',
      // ],
      tableHeader: [
        {
          name: "Plate Number",
          type: "text",
          fieldName: "vehiclePlate",
          isFilterable: true
        },
        {
          name: "Registration Expiry",
          type: "date",
          fieldName: "registrationExpiry",
          isFilterable: true
        },
        {
          name: "Fleet ID",
          type: "text",
          fieldName: "fleetId",
          isFilterable: true
        },
        {
          name: "GVM",
          type: "number",
          fieldName: "GMV",
          isFilterable: true
        },
        {
          name: "Vehicle Class",
          type: "text",
          fieldName: "vehicleClass.name",
          isFilterable: true
        },
        {
          name: "Vehicle Type",
          type: "text",
          fieldName: "vehicleType.name",
          isFilterable: true
        },
        {
          name: "Last Known Odometer",
          type: "text",
          fieldName: "lastKnownOdometer",
          isFilterable: true
        },
        {
          name: "Date Created",
          type: "text",
          fieldName: "createdAt",
          isFilterable: true
        },
        {
          name: "Status",
          type: "boolean",
          fieldName: "isActive",
          isFilterable: true
        },
      ],
      
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      vehicleData: {},
      selectedId: "",
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Drive",
          value: true,
        },
        {
          label: "Non Drive",
          value: false,
        },
      ],
      readVehicles: false,
      writeVehicles: false,
      hasRole: true,
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      geotabVehicles: null,
      geotabTableHeader: [
        {
          name: "Plate Number",
          type: "text",
          fieldName: "vehiclePlate",
          isFilterable: true
        },
        {
          name: "Fleet",
          type: "text",
          fieldName: "fleetId",
          isFilterable: true
        },
        {
          name: "Registration Expiry",
          type: "date",
          fieldName: "registrationExpiry",
          isFilterable: true
        },
        {
          name: "GVM",
          type: "number",
          fieldName: "GMV",
          isFilterable: true
        },
        {
          name: "Vehicle Class",
          type: "text",
          fieldName: "vehicleClass.name",
          isFilterable: true
        },
        {
          name: "Vehicle Type",
          type: "text",
          fieldName: "vehicleType.name",
          isFilterable: true
        }
      ],
      isGeotabPage: false,
      isRootBusiness: false,
      depots: [],
      duplicatedTableHeader: [
        {
          name: "Plate Number",
          type: "text",
          fieldName: "vehiclePlate",
          isFilterable: false
        },
        {
          name: "Registration Expiry",
          type: "date",
          fieldName: "registrationExpiry",
          isFilterable: false
        },
        {
          name: "Fleet ID",
          type: "text",
          fieldName: "fleetId",
          isFilterable: false
        },
        {
          name: "GVM",
          type: "number",
          fieldName: "GMV",
          isFilterable: false
        },
        {
          name: "Vehicle Class",
          type: "text",
          fieldName: "vehicleClass.name",
          isFilterable: false
        },
        {
          name: "Vehicle Type",
          type: "text",
          fieldName: "vehicleType.name",
          isFilterable: false
        },
        {
          name: "Last Known Odometer",
          type: "text",
          fieldName: "lastKnownOdometer",
          isFilterable: false
        },
        {
          name: "Date Created",
          type: "text",
          fieldName: "createdAt",
          isFilterable: false
        },
        {
          name: "Status",
          type: "boolean",
          fieldName: "isActive",
          isFilterable: false
        },
        {
          name: "Source",
          type: "text",
          fieldName: "source",
          isFilterable: false
        },
      ],
      multiFilter: {}
    };
  },
  async mounted() {
    this.isGeotabPage = !isEmpty(VueCookies.get(GEOTAB_COOKIE));
    const me = this.$store.getters[`account/me`];

    if (me?.business) {
      const proxySite = this.$store.getters[`account/proxySite`];
      const isProxySite = !_.isEmpty(proxySite) && _.isObject(proxySite);
      this.isRootBusiness = isProxySite ? false : true;
    }

    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readVehicles = securityAccessObj.readVehicles;
      this.writeVehicles = securityAccessObj.writeVehicles;
    } else {
      this.hasRole = false;
    }

    this.depots = await this.$store.dispatch(`business/getSites`, {
      depotId: me?.business?._id,
      isDeleted: false,
    });
    this.init();
  },
  methods: {
    async onMultiFilter(data) {
      this.multiFilter = {...data};
      this.init();
    },
    async init(paged = 1) {
      this.isLoading = true;

      if (
        (this.isGeotabAccount || this.isGeotabPage) &&
        this.filter.status === "geotabPendingVehicles"
      ) {
        await this.fetchGeotabVehicles(paged);
        this.vehicles = this.geotabVehicles;
        this.isLoading = false;
        return;
      }
      const entityId = this.$store.getters[`account/me`].business._id;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status == "all-sites") {
        const siteIds = this.depots.map((x) => x._id);
        query.filter.siteId = { $in: siteIds };
      }

      if (this.filter.status === "active") {
        query.filter.isActive = { $eq: true };
        query.filter.isDefected = { $nin: true };
        query.filter.isPending = { $exists: true, $eq: true };
      }

      if (this.filter.status === "defected") {
        query.filter.isDefected = { $eq: true };
      }

      if (this.filter.status === "disabled") {
        query.filter.isActive = { $eq: false };
      }

      if (this.selectedFilteredStatus !== 0) {
        query.filter.driveVehicle = { $eq: this.selectedStatus };
      }

      if (this.filter.status === "driver-pending") {
        query.filter.isConfirmedByBusiness = { $in: [false] };
        query.filter.source = { $eq: "app" };
      }

      if (this.filter.status === "duplicated") {
        const response = await this.$store.dispatch(
          `vehicle/getDuplicatedVehicleByEntityId`,
          entityId
        );
        this.vehicles = this.formatItem(response.results);
        this.isLoading = false;
        return;
      }

      if(Object.keys(this.multiFilter).length > 0) {
        query.filter = {...this.multiFilter}
      }

      const vehicle = await this.$store.dispatch(
        `vehicle/getVehicleByEntityId`,
        { entityId, query }
      );
      if (vehicle) {
        if (vehicle?.metadata) {
          this.paginationSettings = formatPaginationSettings(vehicle?.metadata);
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const vehicleTableData = this.formatItem(vehicle.results);
        this.vehicles = vehicleTableData;

        this.$emit("updateLoading", false);
      } else {
        this.$emit("updateLoading", false);
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async filterChange() {
      await this.init();
    },
    setSecurityAccess(securityRoles) {
      let initialReadVehicles = false;
      let initialWriteVehicles = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.vehicles.readVehicles?.value) {
          initialReadVehicles = securityConfig.vehicles.readVehicles.value;
        }
        if (securityConfig.vehicles.writeVehicles?.value) {
          initialWriteVehicles = securityConfig.vehicles.writeVehicles.value;
        }
      });
      return {
        readVehicles: initialReadVehicles,
        writeVehicles: initialWriteVehicles,
      };
    },
    vehicleReport: function () {
      this.$router.push({ path: "reports/vehicles" });
    },
    newVehicle: function () {
      this.modal.modalContent = "new";
      this.modal.modalTitle = "Add Vehicle";
      this.$refs.modal.openModal();
    },
    newVehicleImport() {
      this.modal.modalContent = "new-vehicle-import";
      this.modal.modalTitle = "Import Vehicle";
      this.modal.modalSize = "2xl";
      this.$refs.modal.openModal();
    },
    newGeotabVehicle: function (id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "new-geotab-vehicle";
      this.modal.modalTitle = "Add Geotab Vehicle";
      const targetVehicle = this.vehicles.find(
        (vehicle) => vehicle.plateNumber === id
      );

      const defaultDataPayload = {
        ...targetVehicle,
        externalEntityId: this.$store.getters[`account/me`]?.business?.externalSiteId
      }
      this.modal.modalData = defaultDataPayload
    },
    viewVehicle: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "view";
      this.modal.modalTitle = "Vehicle Info";
      this.$refs.modal.openModal();
    },
    editVehicle: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "edit";
      this.modal.modalTitle = "Vehicle Edit";
      this.$refs.modal.openModal();
    },
    disableVehicle: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "disable";
      this.modal.modalTitle = "Vehicle Disable";
      this.$refs.modal.openModal();
    },
    setAsDefect: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "defect";
      this.modal.modalTitle = "Vehicle Defect";
      this.$refs.modal.openModal();
    },
    setOdometer: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "odometer";
      this.modal.modalTitle = "Edit Odometer";
      this.$refs.modal.openModal();
    },
    activateVehicle: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "activate";
      this.modal.modalTitle = "Vehicle Re-enable";
      this.$refs.modal.openModal();
    },
    setAsNonDefect: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "non-defect";
      this.modal.modalTitle = "Vehicle Clear Defect";
      this.$refs.modal.openModal();
    },
    mergeVehicle: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "merge";
      this.modal.modalTitle = "Merge Vehicles";
      this.$refs.modal.openModal();
    },
    notifyVehicle: function () {
      this.modal.modalContent = "notification";
      this.modal.modalTitle = "Vehicle Approval";
      this.modal.modalSize = "4xl";
      this.$refs.modal.openModal();
    },
    closeRefresh() {
      this.$refs.modal.closeModal();
      this.init();
    },
    showConfig: function () {
      this.$refs.configModal.openModal();
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const vehicle = {
            id: item._id,
            vehiclePlate: item?.vehiclePlate,
            registrationExpiry: formatDate(
              item?.registrationExpiry,
              "DD/MM/YYYY"
            ),
            gvm: item?.GMV,
            vehicleClass: item?.vehicleClass?.name,
            vehicleType: item?.vehicleType?.name,
            fleetId: item?.fleetId || "-",
            created: formatDate(item.createdAt),
            status: item?.isActive,
            lastKnownOdometer: item?.lastKnownOdometer || 0,
            siteId: this.filter.status == "all-sites" ? item?.siteId : null,
            source:
              item?.source === "app" ? "Driver Created " : "Business Created ",
          };
          nItems.push(vehicle);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseVehicles(data) {
      return data.map((vehicle) => {
        const mapped = [
          {
            id: vehicle.id,
            name: vehicle.vehiclePlate,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.registrationExpiry,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.fleetId,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.gvm,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.vehicleClass,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.vehicleType,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.lastKnownOdometer,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.created,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.status,
            itemType: "toggle",
          },
        ];

        if (this.filter.status == "all-sites") {
          if (!this.tableHeader.includes("Site"))
            this.tableHeader.unshift("Site");
          const depot = _.find(this.depots, (x) => x._id == vehicle.siteId);
          mapped.unshift({
            id: vehicle.id,
            name: depot?.name,
            itemType: "string",
          });
        } else {
          const index = this.tableHeader.indexOf("Site");
          if (index >= 0) this.tableHeader.splice(index, 1);
          if (this.filter.status === "duplicated") {
            mapped.push({
              id: vehicle.id,
              name: vehicle.source,
              itemType: "string",
            });
          }
        }

        return mapped;
      });
    },
    parseGeotabVehicles(data) {
      return data.map((vehicle) => [
        {
          id: vehicle.uid,
          name: vehicle.vehiclePlate,
          itemType: "string",
        },
        {
          id: vehicle.uid,
          name: vehicle.fleetId,
          itemType: "string",
        },
        {
          id: vehicle.uid,
          name: vehicle.registrationExpiry,
          itemType: "string",
        },
        {
          id: vehicle.uid,
          name: vehicle.gmv,
          itemType: "string",
        },
        {
          id: vehicle.uid,
          name: vehicle.vehicleClass?.name,
          itemType: "string",
        },
        {
          id: vehicle.uid,
          name: vehicle.vehicleType?.name,
          itemType: "string",
        },
      ]);
    },
    selectFilteredStatus() {
      this.selectedStatus =
        this.filterStatuses[this.selectedFilteredStatus].value;
      this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    async fetchTab(data, tabIndex) {
      this.multiFilter = {}
      this.tabIndex = tabIndex;
      switch (data) {
        case "Geotab Unlinked Vehicles":
          this.filter.status = "geotabPendingVehicles";
          break;
        case "All Sites":
          this.filter.status = "all-sites";
          break;
        case "Active":
          this.filter.status = "active";
          break;
        case "Defected Vehicles":
          this.filter.status = "defected";
          break;
        case "Disabled Vehicles":
          this.filter.status = "disabled";
          break;
        case "Driver Pending Vehicles":
          this.filter.status = "driver-pending";
          break;
        case "Duplicated Vehicles":
          this.filter.status = "duplicated";
          break;
      }
      this.init();
    },
    async fetchGeotabVehicles(paged) {
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };
      const geotabVehiclesQuery = await this.$store.dispatch(
        "geotabVehicles/fetchGeotabVehicles",
        { query }
      );

      if (geotabVehiclesQuery.success) {
        this.paginationSettings = formatPaginationSettings(
          geotabVehiclesQuery?.metadata
        );
        this.geotabVehicles = geotabVehiclesQuery.data;
      } else {
        this.paginationSettings = {
          page: 1,
          totalPages: 1,
          totalRecords: 1,
          visiblePageItemCount: 1,
        };
        this.geotabVehicles = [];
      }
    },

    async syncGeotab() {
      if (this.isLoading) {
        alert("Syncing is ongoing..");
        return;
      }

      if (!confirm("Sync Geotab data?")) {
        return;
      }

      this.isLoading = true;

      const payload = {
        businessId: this.$store.getters[`account/me`].business._id,
        entity: "VEHICLES",
      };

      try {
        await this.$store.dispatch("geotab/syncGeotabData", payload);
        this.toast("success", "Geotab vehicles sync successful");
        this.init();
      } catch (error) {
        const errorMessage = error.errors?.message
          ? error.errors.message
          : "Geotab data sync failed";
        this.toast("error", errorMessage);
      }

      this.isLoading = false;
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    async downloadVehicles() {
      const me = this.$store.getters[`account/me`];
      const business = me.business;

      const query = {
        skip: 0,
        limit: -1,
        filter: {},
      };

      const vehicles = await this.$store.dispatch(
        `vehicle/getVehicleByEntityId`,
        {
          entityId: me?.businessId,
          query,
        }
      );
      if (vehicles.results && vehicles.results.length > 1) {
        let vehicleJSON = [];
        vehicles.results.forEach((vehicle) => {
          vehicleJSON.push({
            vehiclePlate: vehicle.vehiclePlate ?? "",
            registrationExpiry:
              formatDate(vehicle.registrationExpiry, "DD/MM/YYYY") ?? "",
            GMV: vehicle.GMV ?? "",
            driveVehicle: vehicle?.driveVehicle ?? false,
            concreteAgitator: vehicle?.concreteAgitator ?? false,
            livestockVehicle: vehicle?.livestockVehicle ?? false,
            VIN: vehicle?.VIN ?? "",
            fleetId: vehicle?.fleetId ?? "",
            vehicleClass: vehicle.vehicleClass?.name ?? "",
            vehicleType: vehicle.vehicleType?.name ?? "",
          });
        });

        let csvString =
          "Plate Number,Registration Expiry,GVM (Tonnes),Driver Vehicle (Yes/No),Concrete Agitator (Yes/No),Livestock Vehicle (Yes/No),VIN (Optional),Fleet ID (Optional),Vehicle Class,Vehicle Type\r\n";
        csvString += convertJSONToCSV(JSON.parse(JSON.stringify(vehicleJSON)));
        downloadCSV(
          csvString,
          `${business.persona?.businessName
            .toLowerCase()
            .replace(/\s/g, "-")}-vehicles`
        );
        await this.toast("success", "CSV successfully generated.");
      } else {
        await this.toast("error", "No vehicles found.");
      }
    },
  },
  computed: {
    vehiclesData() {
      if (this.vehicles) {
        if (this.filter.status === "geotabPendingVehicles") {
          return this.parseGeotabVehicles(this.vehicles);
        } else {
          return this.parseVehicles(this.vehicles);
        }
      } else return [];
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    isGeotabAccount() {
      return this.$store.getters["account/getIsGeotabAccount"];
    },
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
  watch: {
    isGeotabAccount(value) {
      if (value) {
        this.fetchTab("Geotab Unlinked Vehicles");
      }
    },
  },
};
</script>
