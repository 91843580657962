<template>
  <router-view class="mail-view"></router-view>
</template>

<script>
//import PageHeader from  '@/components/commons/PageHeader.vue'

export default{
  components:{ },
  data(){
    return {
      headingTitle:this.$route.name
    }
  },
  mounted(){
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
