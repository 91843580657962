<template>
  <div>
    <div class="w-full min-h-full flex-row items-left">
      <div class="bg-yellow-400 text-white p-5 mb-3 rounded" :style="{backgroundColor: theme.primaryColor}">
        <div v-if="!isTrainingCompleted" class="flex items-center">
          <svg class="w-7 h-7" fill="#fff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path>
          </svg>
          <span class="ml-2">Training not completed, please complete before {{ trainingCompletionDate }}.</span>
        </div>
        <span v-else>Great, you have completed all trainings.</span>
      </div>
      <div class="flex w-full mt-5 flex-col-reverse lg:flex-row">
        <aside class="w-full lg:w-96 h-full lg:border-r rounded-md bg-white mt-5 lg:mt-0 lg:mr-5">
          <div class="border-b">
            <p class="font-bold text-base text-black-primary px-8 py-4 lg:px-4">Portal Trainings</p>
          </div>
          <div class="h-96">
            <div>
              <div v-if="trainings.length > 0">
                <div v-for="(training, index) in trainings" :key="index">
                  <button
                      class="text-left w-full flex px-8 lg:px-4 py-3 border-b border-gray-200"
                      :class="{ 'bg-green-100': activeTrainingId === training.id }"
                      type="button"
                      :style="{color: activeTrainingId === training.id ? 'rgb(55 65 81)' : theme.primaryColor}"
                      @click="setActiveTraining(training.id)"
                  >
                    <div class="w-full flex justify-between">
                      <div>
                        <font-awesome-icon
                            v-if="activeTrainingId === training.id"
                            :icon="['fas', 'book-open']"
                            class="my-auto mr-1.5"
                            size="lg"
                        >
                        </font-awesome-icon>
                        <font-awesome-icon
                            v-else
                            :icon="['fas', 'book']"
                            class="my-auto mr-1.5"
                            size="lg"
                        >
                        </font-awesome-icon>
                        <small class="relative ml-1">{{training.name}}</small>
                      </div>
                      <StyledIconedLink
                          class=""
                          :iconColor="training.color"
                          :iconHoverColor="theme.secondaryColor">
                        <font-awesome-icon
                            icon="circle"
                            class="my-auto mx-1.5"
                        />
                      </StyledIconedLink>
                    </div>
                  </button>
                </div>
              </div>
              <div v-else class="w-full px-1 py-2 border-b border-gray-200 opacity-40" :style="{color: theme.primaryColor}">
                <small><em>No training found</em></small>
              </div>
            </div>
          </div>
        </aside>
        <div class="w-full flex flex-col">
          <div v-if="!trainingLoading">
            <div class="bg-white py-4 px-8 rounded-md">

              <div class="mb-5">
                <div class="font-bold text-black-primary text-base">
                  {{ activeTraining.name }}
                  <span 
                    class="text-xs capitalize px-3 py-2 ml-2 rounded" 
                    :style="{ 
                      'background-color': `${activeTraining.statusColor}`, 
                      'border': `1px solid ${activeTraining.color}`,
                      'color': `${activeTraining.color}`
                    }"
                  >
                    {{ activeTraining.statusAlt }}
                  </span>
                </div>
              </div>

              <div class="frame-container mb-5 -mt-1">
                <iframe class="responsive-iframe w-full" ref="youtubePlayer"
                        :src="activeTraining.videoLink"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen></iframe>
              </div>

              <div class="mb-5" v-if="activeTraining.status != 'finished'">
                <div class="font-normal text-black-primary text-base mb-3">
                  I confirm that I have understood the content of the video and declare I have been trained.
                </div>
                <button
                    class="btn btn-primary ml-auto"
                    @click.prevent="declare()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Declare
                </button>
              </div>
            </div>
          </div>
          <div v-else class="w-full text-center overflow-y-auto px-3 text-lg inset-0 flex flex-col justify-center items-center" style="height: 669px;">
            <font-awesome-icon icon="cog" spin size="3x" class="text-gray-400"/>
            <h6 class="mt-4">Loading</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import {mapGetters} from "vuex";
import {StyledIconedLink} from '@/styled-components'
import {DRIVER_TRAINING_STATUSES} from "@/_helper/constants";

export default {
  name: 'Training',
  components: { StyledIconedLink },
  data () {
    return {
      me: {},
      isLoading: false,
      trainingLoading: false,
      trainingCompletionDate: null,
      isTrainingCompleted: false,
      activeTrainingId: "",
      activeTraining: {},
      trainings: [
        {
          id: "123",
          name: "Sign up and Login",
          videoLink: "https://www.youtube.com/embed/seN6SY-nIHQ",
          status: "FINISHED"
        },
        {
          id: "234",
          name: "Business Management",
          videoLink: "https://www.youtube.com/embed/raoc4ztbF9o",
          status: "NOT_FINISHED"
        },
        {
          id: "345",
          name: "Work and Rest Events",
          videoLink: "https://www.youtube.com/embed/irNL3UA0H8U",
          status: "YET_FINISHED"
        }
      ],
    }
  },

  async mounted () {
    this.isLoading = true;
    this.$emit("updateLoading", true);
    this.me = this.$store.getters[`account/me`];
    this.trainingCompletionDate = moment(this.me?.driver?.trainingCompletionDate).format('MMMM DD, YYYY');
    await this.init(this.me?.driver?._id);
    this.$emit("updateLoading", false);
    this.isLoading = false;
  },
  methods: {

    async init(driverMongoId) {
      this.isLoading = true
      await this.getTrainings(driverMongoId);
      this.activeTraining = this.trainings[0];
      this.activeTrainingId = this.activeTraining?.id;
      this.isLoading = false;

      const pendingTraining = this.trainings.filter(x => x.status != 'finished');
      this.isTrainingCompleted = pendingTraining.length > 0 ? false : true;
      console.log(this.trainings)
    },


    async getTrainings(driverMongoId) {
      let trainings = await this.$store.dispatch(`driver/getAllTrainingStatus`, driverMongoId);
      if(trainings) {
        this.trainings = this.formatItems(trainings);
      }
    },


    setActiveTraining(trainingId) {
      this.trainingLoading = true;
      this.activeTraining = this.trainings.filter(item => item.id === trainingId)[0];
      this.activeTrainingId = this.activeTraining.id
      setTimeout(()=> {
        this.trainingLoading = false;
      }, 300)
    },


    async declare() {
      const payload = {
        driverMongoId: this.me?.driver?._id,
        trainingMongoId: this.activeTrainingId,
      };

      this.$store.dispatch('driver/declare', payload)
          .then(async () => {
            await this.init(this.me?.driver?._id);
            this.toast('success', 'Declared successfully.')})
          .catch((err) => {
            this.toast('error', ...err.message);});
    },


    formatItems(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          if(item?.status) {
            switch (item?.status) {
              case DRIVER_TRAINING_STATUSES.FINISHED:
                item.color = "#03543f";
                item.statusColor = "#def7ec";
                item.statusAlt = 'Completed';
                break;
              case DRIVER_TRAINING_STATUSES.YET_FINISHED:
                item.color = "#723b13";
                item.statusColor = "#fdf6b2";
                item.statusAlt = 'In Progress';
                break;
              case DRIVER_TRAINING_STATUSES.NOT_FINISHED:
                item.color = "#9b1c1c";
                item.statusColor = "#fde8e8";
                item.statusAlt = 'Not Started';
                break;
            }
          }
          const driver = {
            id: item?.id,
            name: item?.name,
            videoLink: item?.videoLink,
            isMandatory: item?.isMandatory,
            mandatoryDate: item?.mandatoryDate || null,
            status: item?.status || DRIVER_TRAINING_STATUSES.NOT_FINISHED,
            statusAlt: item?.statusAlt || 'Not Started',
            color: item?.color || "#fde8e8",
            statusColor: item?.statusColor || "#C00000",
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },


    toast(state,message){
      this.loading = false;
      this.$store.commit('setDialogNotify',true);
      this.$store.commit('setMessageNotify',{state, message});
    },

  },

  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>

<style scoped>
.frame-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  border: none;
}
</style>