<template>
    <div>
      <common-header></common-header>
      <div class="container py-10">
          <div class="grid grid-cols-1 gap-4">
            <div class="flex px-3">
              <img src="@/assets/images/404.svg" alt="" class="m-auto w-full max-w-4xl">
            </div>
            <div class="flex mb-10">
              <h1 class="text-5xl font-bold bg-gray-800 mx-auto py-4 px-10 text-orange-primary">Page Not Found</h1>
            </div>
          </div>
      </div>
      <common-footer></common-footer>
    </div>
</template>

<script>
export default{
  name:"PageNotFound",
  metaInfo: {
    title: '404 - Page Not Found',
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
p{
  @apply mb-2
}
ul{
  @apply ml-5 list-disc
}
</style>
