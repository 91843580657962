<template>
  <div>
    <div v-if="readReports"> 
      <div class="flex flex-col h-full">
        <div class="p-5 bg-white shadow-md rounded overflow-hidden relative compliance-container">
          <div class="mb-3 pb-4 flex justify-between w-full border-b border-gray-300">
            <div class="flex">
              <div class="mr-3" style="width: 13.5rem;">
                <div class="block font-bold text-xs text-grey-500 mb-1">Driver:</div>
                <FormulateInput
                  type="driver-multiselect"
                  placeholder="Select a driver"
                  style="margin-bottom: 0px"
                  validation="required"
                  @selected="onSelect"
                  @clear="onClear"
                />
              </div>
              <div class="mr-3" style="width: 14rem;">
                <div class="block font-bold text-xs text-grey-500 mb-1">Date Range:</div>
                <DatePicker 
                  ref="childDatePicker" 
                  :date="selectedDateRange"
                  placeholder="YYYY-MM-DD ~ YYYY-MM-DD" 
                  :selectedDrivers="selectedDrivers"
                  :selectAllData="selectAllData"
                  :displayBreach="true"
                  @selected="selectedDateRange = $event"
                  range noNavigation :clearable="false"
                />
              </div>
              <div class="mr-3" style="width: 14rem;">
                <div class="block font-bold text-xs text-grey-500 mb-1">Options:</div>
                <FormulateInput
                    placeholder="--please select--"
                    name="options"
                    style="margin-bottom: 0px"
                    autocomplete="off"
                    type="select"
                    input-class="appearance-none rounded border border-gray-300 pl-12 pr-4 py-3 w-full bg-white text-sm placeholder-gray-300 focus:bg-white focus:placeholder-gray-600 focus:text-gray-600 focus:outline-none"
                    :options="fatigueOptions"
                    v-model="selectedOption"
                    @selected="selectedOption = $event"
                />
              </div>
            </div>
            <div>
              <div class="">&nbsp;</div>
              <button 
                :disabled="selectedDrivers.length == 0" 
                :class="{ 'cursor-not-allowed': selectedDrivers.length == 0 }"
                class="btn btn-primary flex items-center" 
                @click.prevent="onGetReport" 
                style="height: 38px;" 
                :style="{backgroundColor: theme.secondaryColor}"
              >
                Get Summary Report
              </button>
            </div>
          </div>

          <div class="flex w-full items-center driver-info-container">
            <BreachEngine
              ref="childBreach"
              @sendReportloading="sendReportloading = $event"
              @sendDriverBreach="onSendDriverBreach"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!readReports && hasRole" class="w-full min-h-full text-center mt-5">
      <h1>Not authorized to view reports. Please contact your business admin.</h1>
    </div>
    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height" @closeModal="onCloseModal">
      <div v-if="modal.content == 'new'">
        <div class="mb-16">
          <div v-if="!sendReportloading">
            <FormulateForm v-model="sendReportModel" @submit="onSubmitSendBreachReport">
              <div class="items-center justify-between">
                <div class="text-neutral-dark text-sm mb-2">
                  <strong class="font-semibold">Date Range:&nbsp;</strong> {{ momentFormat(selectedDateRange[0], true) }} to {{ momentFormat(selectedDateRange[1], true) }}
                </div>
                
                <FormulateInput
                  type="email"
                  name="email"
                  label="Email Address"
                  validation="required|email"
                  @input="handleStritEmailCase($event)"
                />

                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="btn btn-primary w-full"
                  :disabled="selectedDateRange[0] == null || selectedDateRange[1] == null || sendReportloading" 
                  :style="{backgroundColor: theme.secondaryColor}"
                />
              </div>
            </FormulateForm>
          </div>

          <div v-else class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center">
            <font-awesome-icon icon="cog" spin size="3x" class="text-gray-400"/>
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { Modal } from '@/components/commons';
import DatePicker from '@/components/commons/field/DatePicker';
import BreachEngine from '@/components/view/business/breach-engine';

export default {
  name: "NHVR-Breaches-Report-Page",
  components: { Modal, DatePicker, BreachEngine },
  data() {
    return {
      modal: {
        title: '',
        content: null,
        height: '50vh',
      },
      dateFormat: 'YYYY-MM-DD',
      selectedDateRange: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      sendReportModel: null,
      sendReportloading: false,
      selectedDrivers: [],
      selectedDriver: null,
      breachDates: [],
      readReports: false,
      writeReports: false,
      selectAllData: null,
      isSummaryReport: true,
      hasRole: true,
      fatigueOptions: [
        { value: '', label: 'All' },
        { value: 'Standard', label: 'Standard' },
        { value: 'StandardBus', label: 'Standard Bus' },
        { value: 'Standard 2Up', label: 'Standard 2 Up' },
        { value: 'BFM', label: 'BFM' },
        { value: 'AFM', label: 'AFM' },
        { value: 'BFM 2-Up', label: 'BFM 2 Up' },
        { value: '14 Day Solo', label: '14 Day Solo' },
        { value: '28 Day Solo', label: '28 Day Solo' },
        { value: '2-Up 7 Day', label: '2-Up 7 Day' },
        { value: '2-Up 48 Hour', label: '2-Up 48 Hour' },
        { value: 'Exemption', label: 'Exemption' },
      ],
      selectedOption: '',
      me: null
    };
  },
  watch: {
    selectedDateRange: {
      handler: function() {
        this.onSearch()
      },
      deep: true
    },
    selectedOption: {
      handler: function () {
        this.onSearch()
      },
      deep: true
    }
  },
  async mounted(){
    this.$emit("updateLoading", true)
    this.me = this.$store.getters[`account/me`]
    let securityRoles = this.me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles)
      this.readReports = securityAccessObj.readReports
    } else {
      this.hasRole = false
    }
    this.$emit("updateLoading", false)
  },
  methods: {
    onClear() {
      this.$refs.childBreach.onClear();
    },
    async onSelect(drivers, selectAllData = null) {
      this.selectAllData = selectAllData;
      this.selectedDrivers = drivers;
      
      if (this.selectedDrivers.length > 0) this.onSearch()
    },
    canSearch() {
      return this.selectedDrivers.length > 0 && this.selectedDateRange.length > 1 && !_.isEmpty(this.selectedDateRange[0]);
    },
    setSecurityAccess: (securityRoles) => {
      let initialReadReports = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig
        if (securityConfig.reports.readReports?.value) {
          initialReadReports = securityConfig.reports.readReports.value
        }
      });
      return {
        readReports: initialReadReports
      };
    },
    async onSearch() {
      if (this.canSearch()) {
        await this.$refs.childBreach.onSearch(this.selectedDrivers, this.selectedDateRange, this.selectAllData, this.selectedOption, this.me?.business?._id)
      }
    },
    async onSendDriverBreach(driverId) {
      this.onGetReport('Send Driver Report To Email');
      this.isSummaryReport = false;
      this.selectedDriver = driverId;
    },
    onGetReport(title = null) {
      this.$refs.modal.openModal()
      this.modal.content = 'new';
      this.modal.title = typeof title == 'string' ? title : 'Send Report Summary To Email';
    },
    handleStritEmailCase(value){
      this.sendReportModel.email = value.toLowerCase()
    },
    momentFormat(date, isShort = false){
      if(date){
        if(isShort) {
          return date ? moment(date).format('MMM D, YYYY') : '-'
        } else {
          return date ? moment(date).format('MMMM D, YYYY') : '-'
        }
      }
    },
    async onSubmitSendBreachReport() {
      this.sendReportloading = true;
      const recipientEmail = this.sendReportModel.email;
      const businessId = this.me?.business?._id;

      let data = {
        recipientEmail: recipientEmail,
        startDate: moment().format(`${this.selectedDateRange[0]}THH:mm:ssZ`),
        endDate: moment().format(`${this.selectedDateRange[1]}THH:mm:ssZ`),
        businessId: businessId,
        requestOrigin: 'web'
      }

      if (this.isSummaryReport) {
        if (this.selectAllData) {
          data.filter = { 
            businessId: businessId, 
            selectAll: true
          }
          
          if (this.selectAllData?.except) data.filter.driverId = { $nin: this.selectAllData?.except }
        } else {
          data.driverIds = this.selectedDrivers.map(driver => driver.driverId);
        }
      } else {
        data.driverId = this.selectedDriver;
      }
      if(this.selectedOption && this.selectedOption !== '') { data.filter.option = this.selectedOption}

      await this.$store.dispatch(`report/sendBreachReport`, data)
        .then((res) => {
            this.toast('success', res.message)
        }).catch(err => {
            this.toast('error', err.message)
        });

      this.sendReportloading = false;
    },
    toast(state,message){
      this.$emit('sendReportloading', false);
      this.$store.commit('setDialogNotify', true)
      this.$store.commit('setMessageNotify', {state, message})
    },
    onCloseModal() {
      this.isSummaryReport = true;
      this.selectedDriver = null;
    }
  },
  computed:{
    ...mapGetters('theme', {
          theme: 'getColorScheme'
      })
  }
}
</script>
<style scoped lang="scss">
  .active {
    @apply border-orange-primary
  }
  .vertical {
    min-width: 7px;
    line-height: 7px;
    writing-mode: tb-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
    text-align: center; 
    transform: rotate(180deg);
  }
  .compliance-container {
    min-height: calc(100vh - 92px);
  }
</style>