<template>
  <div>
    <FormulateForm
        v-model="values"
        :schema="schema"
        @submit="onSubmit">
      <div class="items-center justify-between">
        <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="loading"
        >
          <span v-if="loading"
          ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
            data...</span
          >
          <span v-if="!loading">Submit</span>
        </FormulateInput>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import marketplaceItemSchema from "./new-marketplace-item";

export default {
  name: "Edit-Marketplace-Item",
  props: {
    id: {
      type: String,
      default: null,
    }
  },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: "",
      hasErrorMessage: false,
      schema: marketplaceItemSchema,
    };
  },

  async mounted() {
    this.loading = true;
    let item = await this.$store.dispatch(`standardpricing/getStandardPricingMaster`, this.id);
    this.values = this.parseMarketplaceItem(item);
    this.loading = false;
  },
  methods: {
    async getFormData() {
      this.loading = false;
    },

    closeModal(){
      this.$emit('closeModal')
    },

    parseMarketplaceItem(item){
      return {
        name: item["name"],
        apiTag: item["apiTag"],
        category: item["category"],
        type: item["type"],
        description: item["description"],
        monthly: item["pricing"]["monthly"],
        yearly: item["pricing"]["yearly"],
        monthlyRrp: item["rrp"]["monthly"],
        yearlyRrp: item["rrp"]["yearly"],
      }
    },

    async onSubmit() {
      this.loading = true;
      let formValues = JSON.parse(JSON.stringify(this.values));
      const payload = {
        id: this.id,
        name: formValues['name'],
        apiTag: formValues['apiTag'],
        category: formValues['category'],
        type: formValues['type'],
        description: formValues['description'],
        pricing: {
          monthly: +formValues['monthly'],
          yearly: +formValues['yearly']
        },
        rrp: {
          monthly: +formValues['monthlyRrp'],
          yearly: +formValues['yearlyRrp']
        }
      }
      await this.$store.dispatch(`standardpricing/updateStandardPricingMaster`, payload).then((res) => {
        if (res.success) {
          this.loading = false
          this.closeModal()
          this.$store.commit('setDialogNotify', true)
          this.$store.commit('setMessageNotify', {state: 'success', message: res.message})
        }
      })
          .catch((err) => {
            this.$store.commit('setDialogNotify', true)
            this.$store.commit('setMessageNotify', {state: 'error', message: err.message})
            this.loading = false
          })
      this.loading = false;
    },
  },
};
</script>
