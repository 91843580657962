<template>
  <Stepper
    :stepperSteps="stepperSteps"
    @completed-step="completeStep"
    @active-step="isStepActive"
    :showNavigation="false"
    @closeModal="closeModal"
  />
</template>

<script>
  import { Stepper } from "@/components/commons";
  import {
    MoveDrivers,
    MoveVehicles,
  } from "./steps";
  export default {
    name: "Deactivate-Site-Step",
    components: { Stepper },
    props: {
      siteId: String
    },
    data() {
      return {
        stepperSteps: [
          {
            stepIcon: "user",
            stepTitle: "Move Drivers",
            stepSequence: "first",
            stepComponent: MoveDrivers,
            isCompleted: true,
            detailsId: this.siteId
          },
          {
            stepIcon: "truck",
            stepTitle: "Move Vehicles",
            stepSequence: "second",
            stepComponent: MoveVehicles,
            isCompleted: false,
            detailsId: this.siteId
          },

        ],
        activeStep: 0,
      };
    },
    methods: {
      completeStep(payload) {
        this.stepperSteps.forEach((step) => {
          if (step.stepSequence === payload.stepSequence) {
            step.isCompleted = true;
          }
        });
      },
      isStepActive(payload) {
        this.stepperSteps.forEach((step) => {
          if (step.stepSequence === payload.stepSequence) {
            if (step.isCompleted === true) {
              step.isCompleted = false;
            }
          }
        });
      },
      closeModal(){ 
        this.$emit('closeModal')
      }
    },
  };
</script>
