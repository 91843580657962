import { DriverManagement, Partner } from "@/api";
import { findRootUID } from "@/_helper";
import _ from "lodash";

export default {
  // @Partner
  async createPartner({ commit, dispatch }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object";
    try {
      const response = await Partner.createPartner(data);
      commit("setNewCreatedPartner", response.data.data);
      if (!_.isEmpty(data["create_password"])) {
        // Create partner with Password
        const newPassword = await dispatch("createPartnerPassword", {
          id: response.data.data._id,
          password: data["create_password"],
        });
        return newPassword;
      } else {
        return response.data.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },
  async updatePartner({ state }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object";
    try {
      const response = await Partner.patchPartner(data.id, data);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async generateShareableUrl({ state }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object";
    try {
      const response = await Partner.generateShareableUrl(data.id, data);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async createPartnerPassword({ commit, state }, data) {
    if (!data.id) throw "Invalid partner id";
    if (!data.password) throw "Invalid data password";
    try {
      const response = await Partner.patchPartnerCreatePassword(data.id, {
        password: data.password,
        confirmPassword: data.confirmPassword,
      });
      if (!_.isEmpty(state.newCreatedPartner))
        commit("setNewCreatedPartner", {
          ...state.newCreatedPartner,
          password: response.data.data,
        });
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPartner({ state }, param) {
    try {
      const response = await Partner.getPartner(param);
      if (!response?.data?.data) return [];
      if (param.id) {
        response.data.data.uid = findRootUID(response.data.data);
        return response.data.data;
      } else {
        const data = response?.data?.data.map((data) => {
          data.uid = findRootUID(data);
          return data;
        });
        return { results: data, metadata: response.data?.resultsMeta };
      }
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async getPartnersRaw() {
    try {
      const response = await Partner.getPartnersRaw();
      response.data.data.uid = findRootUID(response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPartnersWithPaymentGateway() {
    try {
      const response = await Partner.getPartnersWithPaymentGateway();
      response.data.data.uid = findRootUID(response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPartnerByUID({ state }, uid) {
    if (!uid) throw "Invalid uid " + state.me;
    try {
      const response = await Partner.getPartnerByUID(uid);
      response.data.data.uid = findRootUID(response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getPartnerByURL({ state }, data) {
    if (!data) throw "Invalid uid " + state.me;
    try {
      const response = await Partner.getPartnerByURL(data);
      response.data.data.uid = findRootUID(response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getPartnerByWebProfileID({ state }, id) {
    if (!id) throw "Invalid id " + state.me;
    try {
      const response = await Partner.getPartnerByWebProfileId(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getResellersByPartner({ state }, uid) {
    if (!uid) throw "Invalid uid " + state.me;
    try {
      const response = await Partner.getResellersByPartner(uid);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getBusinessByPartnerUID({ state }, param) {
    if (!param) throw "Invalid uid " + state.me;
    const response = await Partner.getBusinessByPartner(param);
    if (!response?.data?.data) return [];
    try {
      if (response?.data?.data) {
        return {
          results: response?.data?.data,
          metadata: response.data?.resultsMeta,
        };
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async getPartnerOfLogmasterByUID({ state }, uid) {
    if (!uid) throw "Invalid uid " + state.me;
    try {
      const response = await Partner.getPartnerOfLogmasterByUID(uid);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPartnerResellersByUID({ state }, param) {
    if (!param.id) throw "Invalid uid " + state.me;
    const response = await Partner.getPartnerResellersByUID(param);

    if (!response?.data?.data) return [];
    try {
      if (response?.data?.data) {
        return {
          results: response?.data?.data,
          metadata: response.data?.resultsMeta,
        };
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async getDriverContractByPartnerUID(_, param) {
    if (typeof param === "string")
      param = { uid: param, query: { skip: 0, limit: 999 } };
    if (!param.uid) throw "Invalid UID or ID";
    try {
      const response = await DriverManagement.getContractByPartnerUID(
        param.uid,
        param.query
      );
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response.data.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  //Get Partner data via ID

  async getPartnerResellersByID({ state }, param) {
    if (!param.id) throw "Invalid uid " + state.me;
    const response = await Partner.getPartnerResellersById(param);

    if (!response?.data?.data) return [];
    try {
      if (response.data?.resultsMeta) {
        const data = response?.data?.data.map((data) => {
          data.uid = findRootUID(data);
          return data;
        });
        return {
          results: data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response?.data?.data.map((data) => {
          data.uid = findRootUID(data);
          return data;
        });
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async getPartnerBusinessesByID({ state }, param) {
    if (!param.id) throw "Invalid uid " + state.me;
    const response = await Partner.getPartnerBusinessesById(param);

    if (!response?.data?.data) return [];
    try {
      if (response.data?.resultsMeta) {
        const data = response?.data?.data.map((data) => {
          data.uid = findRootUID(data);
          return data;
        });
        return {
          results: data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response?.data?.data.map((data) => {
          data.uid = findRootUID(data);
          return data;
        });
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async acceptPartner({ state }, id) {
    if (!id) throw `Invalid id ${Number(state.me)}`;

    try {
      const response = await Partner.acceptPartner(id);
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  },

  async resendInvite({ state }, id) {
    if (!id) throw "Invalid partner id" + state.me;
    try {
      const data = {
        id: id,
      };
      const response = await Partner.resendInvite(data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async deletePartner({ state }, id) {
    if (!id) throw "Invalid partner id" + state.me;
    try {
      const response = await Partner.deletePartner(id);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getPartnerByEmail({ state }, email) {
    if (!email) throw "Invalid emailaddress " + state.me;
    try {
      const response = await Partner.getPartnerByEmail(email);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updatePartnersSoloOrphan({ state }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object";
    try {
      const response = await Partner.updatePartnersSoloOrphan(data.ids);

      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },

  async removeFromOrphan({ state }, id) {
    if (!id) throw "Invalid partner id" + state.me;
    try {
      const data = {
        id: id,
      };
      const response = await Partner.removeFromOrphan(data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async addModuleToPartners({ state }, standardPricingMasterId) {
    if (!standardPricingMasterId)
      throw "Invalid standardPricingMasterId " + state.me;
    try {
      const response = await Partner.addModuleToPartners(
        standardPricingMasterId
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getDashboardData({ state }, uid) {
    if (!uid) throw "Invalid uid " + state.me;
    try {
      const response = await Partner.getDashboardData(uid);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
