export default{
    // @Account / Auth
    accessToken:(state) =>{
        return state.isProxy ? state.accessProxyToken : state.accessToken
    },
    refreshToken:(state) =>{
        return state.isProxy ? state.refreshProxyToken : state.refreshToken
    },
    me:(state) =>{
        return state.me
    },
    mainRole:(state) =>{
        return state.mainRole
    },
    roleNames:(state) =>{
        return state.roleNames
    },
    roleName:(state) =>{
        return state.roleName
    },
    role:(state) =>{
        return state.role
    },
    proxyUser:(state) =>{
        return state.isProxy
    },
    proxyRole:(state) =>{
        return state.proxyRole
    },
    proxySite:(state) =>{
        return state.proxySite
    },
    proxyDriverProfileUid:(state) =>{
        return state.proxyDriverProfileUid
    },
    // @Partner
    newCreatedPartner:(state) =>{
        return state.newCreatedPartner
    },
    // @ Roles & Respos
    securityRoles: (state) => {
        return state.securityRoles;
    },
    getIsGeotabAccount: (state) => {
        return state.isGeotabAccount
    }
}