<template>
    <div class="login-container max-w-md mx-auto">
      <div class="rounded-lg bg-gray-100 p-10">
        <div v-if="success">
          <h1 class="text-2xl text-green-500 text-center font-bold">Contract successfully decline</h1>
          <div class="text-sm text-center">Redirecting...</div>
        </div>
      </div>
    </div>
</template>
<script>
export default{
  name:"Solo-Driver-Decline-Contract",
  components:{},
  data(){
    return {
      loading:true,
      contractId:null,
      success: false,
    }
  },
  async mounted(){
    this.loading = false;
    this.contractId = this.$route.params['contractId'];
    try{
      const contract = await this.$store.dispatch(`driver/getContract`,this.contractId);
      if(contract && contract._id){
        const decline = await this.$store.dispatch(`driver/declineContract`,this.contractId);
        if(decline && decline._id){
          this.success = true;
          setTimeout(() => {
            window.location.href = '/?message=Decline success'
          }, 3003);
        }
      }else{
        window.location.href = '/404?message=Invalid request'
      }
    }catch{
      window.location.href = '/404?message=Invalid request'
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
