<template>

    <div v-if="driverId">
      <div v-if="loading" class="text-center"><ui-spinner class="m-auto"></ui-spinner> Loading...</div>
      <div v-if="!loading">
        <FormulateForm v-model="values" :schema="schema" @submit="onSubmit" #default="{ hasErrors }">
        <div v-if="!loading" class="items-center justify-between">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{backgroundColor: theme.secondaryColor}"
          />
        </div>
      </FormulateForm>
      </div>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
    </div>
</template>

<script>
import schema from './new-driver-password-schema';
//import _ from 'lodash';
import { mapGetters } from 'vuex'

export default {
  name: 'New-Driver-Accept',
  props: {
    msg: String,
    driverId: String
  },
  data() {
    this.$emit('schema', schema);
    return {
      loading: true,
      values: {},
      schema: schema,
      errorMessage:'',
    };
  },
  async mounted(){
    this.loading = false;
  },
  methods: {
    updateSchema: function(schema) {
      this.schema = schema
    },
    async onSubmit() {
        this.loading = true
        let newDriverPassword = JSON.parse(JSON.stringify(this.values));
        if(newDriverPassword){
          if(this.driverId){
            const createdDriver = await this.$store.dispatch(`driver/createDriverPassword`, { id:this.driverId, password: newDriverPassword});
            if(createdDriver){
              const accepted = await this.$store.dispatch(`driver/acceptDriver`, this.driverId);
              if(accepted && accepted._id){
                window.location.href = '/login?message=Create password success'
              }
            }
          }
        }

    },
  },
  computed: {
     ...mapGetters('theme', {
    theme: 'getColorScheme'
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
