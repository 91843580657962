<template>
  <div>
    <div v-show="!loading">
      <div v-if="formControl">
        <FormulateInput
          type="text"
          name="control_id"
          v-model="formControl.id"
          hidden
          validation="required"
        />
        <FormulateInput
          type="text"
          name="name"
          v-model="formControl.name"
          validation="required"
          hidden
          class="mb-4"
        />
        <FormulateInput
          type="text"
          name="control_label"
          v-model="formControl.label"
          label="Label"
          placeholder="Enter label here"
          validation="required"
          class="mb-4"
        />
        <FormulateInput
          type="text"
          name="control_help"
          v-model="formControl.help"
          label="Helper"
          placeholder="Enter helper here"
          class="mb-4"
        />
        <div class="font-medium text-sm mb-2 border-b pb-1">Validations</div>
        <FormulateInput
          v-model="formControl.required"
          wrapper-class="flex h-5"
          input-class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
          label-class="ml-2 text-sm font-medium"
          type="checkbox"
          label="Required"
          class="mb-4"
        />
      </div>
    </div>
    <div v-if="loading" class="w-full text-center overflow-y-auto px-12 text-lg inset-0 flex flex-col justify-center items-center">
      <font-awesome-icon icon="cog" spin size="3x" class="text-gray-400 mt-12"/>
      <h6 class="mt-4">Loading</h6>
    </div>
  </div>
</template>
<script>
import { HELPER } from '@/api/helper'
import _ from 'lodash'

export default {
  props: {
    watchedCurrentSection: {
      type: Object,
      default: null
    },
    watchedCurrentControl: {
      type: Object,
      default: null
    },
    uniqueId: {
      type: String,
      default: ''
    },
  },

  data: () => ({
    required: false,
    formControl: {},
    currentSection: {},
    loading: false
  }),

  watch: {
    formControl: {
      deep: true,
      handler(newVal) {
        const formData = {
          control: newVal,
          sectionUniqueId: this.currentSection?.uniqueId
        }
        this.$emit('updateFormControl', formData)
      }
    },

    uniqueId(newVal) {
      this.updateSelectedData(newVal)
    },

    required(newVal) {
      if(newVal == true) {
        this.formControl.validation.push('required')
      } else {
        const index = this.formControl.validation.indexOf('required')
        this.formControl.validation.splice(index, 1)
      }
    }
  },

  created() {
    this.updateSelectedData()
  },

  methods: {
    addRequiredValidation() {
      this.formControl.validation.push('required')
    },
    
    async updateSelectedData(){
      this.loading = true
      
      this.currentSection = HELPER.cloneDeep(this.watchedCurrentSection)
      let control = HELPER.cloneDeep(this.watchedCurrentControl)

      if(!_.isEmpty(control)){
        this.formControl = control
        this.formControl.validation = control.validation || []
      } else {
        this.formControl = null
      }
      
      setTimeout(() => {
        this.loading = false
      }, 300)
    }
  }
}
</script>