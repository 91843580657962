<template>
    <div class="bg-gray-900">
    <div class="container py-10 h-screen flex">
      <div class="login-container w-96 m-auto">
        <div class="text-center m-5 -mt-40">
          <a :href="$store.getters['baseURL']"><img src="/assets/images/logo.png" alt="" class="w-64 m-auto"></a>
        </div>
        <div class="rounded-lg bg-gray-50 p-10 shadow">

          <div v-if="!loading">
            <div v-if="accepted">
              <h1 class="text-2xl text-green-600 text-center font-bold">Business Link already accepted</h1>
              <div class="text-sm text-center">Redirecting...</div>
            </div>
            <div v-if="success">
              <h1 class="text-2xl text-green-500 text-center font-bold">Business Link successfully accepted</h1>
              <div class="text-sm text-center">Redirecting...</div>
            </div>
            <div v-if="decline">
              <h1 class="text-2xl text-red-500 text-center font-bold">Business Link has been declined</h1>
              <div class="text-sm text-center">Redirecting...</div>
            </div>
            <div v-if="!success && !accepted && !decline">
              <p class="mb-5"><strong>Linking Details:</strong></p>
              <div class="tc">
                <p><span class="font-bold">{{businessName}}</span> has requested you to link your Logmaster Australia EWD to their business portal.</p> 
                <p>If you accept the request, <span class="font-bold">{{businessName}}</span> will have access to your last 56 days of records prior to the link being accepted and any records moving forward  until your EWD in delinked from <span class="font-bold">{{businessName}}</span>.</p>
                <p>You can delink a business through your driver portal at any time.</p>
                <p>If you accept this link, click the accept button on the left below, if you wish to decline the request, select the decline button on the right below and we will notify <span class="font-bold">{{businessName}}</span>.</p>
              </div>
              <div class="w-full flex">
                <button class="btn hover:bg-orange btn-primary w-full mr-2" @click="acceptContract">Accept</button>
                <button class="btn btn-cancel w-full ml-2" @click="declineContract">Decline</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default{
  name:"Partner-Accept-Contract",
  components:{},
  data(){
    return {
      loading:true,
      partnerId:null,
      success: false,
      accepted: false,
      decline: false,
      businessName: null
    }
  },
  async mounted(){
    this.loading = false;
    this.partnerId = this.$route.params['partnerId'];
    // try{
    //   const contract = await this.$store.dispatch(`partner/getPartner`,this.partnerId);
    //   console.log("contract", contract)
    //   if(contract && contract?.business?.persona?.businessName){
    //     this.businessName = contract?.business?.persona?.businessName
    //   }
    //   this.accepted = contract.driverAccepted
    //   if(!contract){
    //     window.location.href = '/404?message=Invalid request'
    //   }
    //   if(this.accepted){
    //     setTimeout(() => {
    //         window.location.href = '/?message=Already Accepted'
    //       }, 3003);
    //   }
    // }catch{
    //   window.location.href = '/404?message=Invalid request'
    // }
  },
  methods: {
    // acceptContract: async function(){
    //   const accept = await this.$store.dispatch(`driver/acceptContract`,this.partnerId);
    //     if(accept && accept._id){
    //       this.success = true;
    //       setTimeout(() => {
    //         window.location.href = '/'
    //       }, 3003);
    //     }
    // },
    // declineContract(){
    //   this.decline = true
    //   setTimeout(() => {
    //         window.location.href = '/?message=Decline success'
    //       }, 3003);
    // } 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tc{
  max-height: 500px;
  @apply overflow-y-scroll m-0 p-4 bg-white mb-5 text-sm
}
p{
  @apply mb-2
}
</style>
