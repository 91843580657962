<template>
<div>
  <div v-if="loading" class="loading centered">
    <ui-spinner class="mx-auto"></ui-spinner>
  </div>
  <div id="printCovered" v-if="!loading" class="mb-16">
    <div class="text-center mb-10">
      <div class="flex text-right justify-end">
        <button id="printBtn" class="h-8 pl-2 pr-4 m-2 text-sm transition-colors duration-150 bg-blue-300 text-white hover:bg-blue-200 rounded-md focus:shadow-outline"
          @click="printThis('printCovered')">
          <font-awesome-icon
            icon="print"
            class="my-auto mx-1.5 text-sm"/>
            Print
        </button>
      </div>
      <div v-if="vehicle" class="font-bold text-black-primary text-2xl mt-3">
        {{vehicle.vehiclePlate}}
      </div>
      <div
         class="font-bold text-xs"
        :class="[isPassed ? 'text-green-500' : 'text-red-500']"
      >
        VEHICLE CHECK {{isPassed ? 'PASSED' : 'FAILED'}}
      </div>
    </div>

    <div class="w-full"  v-if="isDefault">
      <div v-for="(section, j) in sections" :key="j" class="mb-3">

        <div v-if="section.value" class="mb-3">
          <div v-for="(control, ja) in section.value" :key="ja">

            <div
                v-if="control.component == formControls.CHECKBOX
                || control.component == formControls.RADIO"
                >

              <div  class="mb-2 flex items-center" v-if="isPillet(control?.value?.value)">
                <div class="option-pill-view">
                  
                  <div v-if="control.value?.isIssue" class='bg-red-400 text-white w-11 text-center'> {{control?.value?.value}} </div>
                  <div v-else class='bg-green-400 text-white w-11 text-center'> {{control?.value?.value}} </div>
                  <div class="bg-gray-200">&nbsp;-&nbsp;&nbsp;</div>

                </div>

                <div class="text-xs">
                  {{ control.label }}
                </div>
              </div>
            </div>

          </div>
        </div>
        <br><hr>
      </div>
    </div>

    <div class="w-full" v-if="!isDefault">
      <section v-for="(section, i) in sections" :key="i" class="mb-3">
        <div v-if="section.value">
          <div v-for="(control, ia) in section.value" :key="ia" class="mb-3">
            <div>
              <div
                v-if="control.component === formControls.TEXT
                      || control.component === formControls.PLAIN_TEXT
                      || control.component === formControls.NUMBER
                      || control.component === formControls.PASSWORD
                      || control.component === formControls.EMAIL
                      || control.component === formControls.TEXTAREA
                      || control.component === formControls.SELECT
                      || control.component === formControls.RADIO
                      || control.component === formControls.SWITCH"
              >
                <div class="mb-1">
                  <div v-if="control.value?.value && control.value?.value?.value"
                    class="font-bold text-black-primary text-base">
                    {{ control.value?.value?.value }}
                    <span v-if="control.value?.value?.value === 'Pass'">
                      <font-awesome-icon
                        icon="check-circle"
                        class="text-1xl text-green-400"
                      />
                    </span>
                  </div>
                  <div v-else-if="control.value?.value" class="font-bold text-black-primary text-base">
                    {{ control.value?.value }}&nbsp;
                    <span v-if="control.value?.value === 'Pass'">
                      <font-awesome-icon
                        icon="check-circle"
                        class="text-1xl text-green-400"
                      />
                    </span>
                  </div>
                  <div v-else class="font-bold text-black-primary text-base">
                    {{ !control.value?.value ? '—' : control.value }}&nbsp;
                  </div>
                  <div class="text-xs"> {{ control.label }}</div>
                </div>
              </div>
              <div v-if="control.component === formControls.CHECKBOX">
                <div class="mb-1">
                  <div v-if="control.value?.value?.value">
                    <div v-for="(checkbox, ky) in control.value?.value?.value" :key="ky"
                      class="font-bold text-black-primary text-base">
                      {{ checkbox.value }}
                    </div>
                  </div>
                  <div class="text-xs"> {{ control.label }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br><hr>
      </section>
    </div>

    <Collapse :isCollapsed="collapsed">
      <template slot="collapseHeader">
        <div class="flex items-center font-bold">
          <div class="flex items-center">
            <div class="flex items-center">
              <div class="icon-container mr-2 bg-gray-700">
                <font-awesome-icon
                  icon="file"
                  style="height: 15px; width: 15px"
                />
              </div>
              <div class="flex flex-col">
                <div class="flex-auto">Pre Start Check Consent</div>
              </div>
            </div>
          </div>
          <div class="ml-auto cursor-pointer">
            <font-awesome-icon
              :icon="collapsed ? 'caret-down' : 'caret-right'"
              class="text-2xl"
              @click="onCollapsed()"
            />
          </div>
        </div>
      </template>
      <template #default="{props}">
        <Transition name="slide">
          <div v-if="props.collapsed" class="items-center px-5">
            <div class="mb-3 text-xs">
              You are about to submit your pre-start check declaration for the
              day. Once you have submitted it, it cannot be corrected.
            </div>
            <div class="mb-3 text-xs">
              By submitting your pre-start check declaration, you are declaring
              that the entries:
            </div>
            <ul class="list-disc mb-3 ml-8 text-xs">
              <li>Were made by you personally</li>
              <li>Are not false or misleading</li>
            </ul>
            <div class="mb-3 text-xs">
              Confirm that the information in the pre-start check form is
              correct, [OR], if you are not sure that the information in the
              pre-start check form is correct, correct it now prior to
              submitting your pre-start check declaration record.
            </div>
            <div class="mb-3 text-xs">
              Entries may be used in legal proceedings for an offence against
              the Heavy Vehicle National Law Act 2012, or another law of a State
              or the Commonwealth of Australia. Making a false or misleading
              entry in an electronic work diary is an offence punishable by a
              fine of over $10,000.
            </div>
            <hr>

            <div class="flex flex-col">
              <div class="w-full text-left items-start pt-7 mr-2">
                <span class="pl-6 text-sm">{{ cleanDateFormat(savedData?.date) }}</span>
                <div class="w-40 border-t mt-1 pt-1 text-center text-xs">Date submitted</div>
              </div>
              <div class="w-full text-right flex flex-col items-end mr-2">
                <section v-for="(section, l) in sections" :key="l" class="w-full text-right flex flex-col items-end">
                  <div v-if="section.value" class="mb-3">
                    <div v-for="(control, la) in section.value" :key="la">
                      <div v-if="control.component == formControls.SIGNATURE || control.component == formControls.IMAGE"  class="mt-5">
                        <img class="border-2 w-60 bg-gray-700 h-32" :src="control.value?.value ? control.value?.value : control.value" :alt="control.label"/>
                        <div class="text-left mt-1 text-xs">{{ control.label }}: <b>{{ driverName }}</b></div>
                      </div>
                    </div>
                  </div>
                  <br><hr>
                </section>
              </div>
            </div>

          </div>
        </Transition>
      </template>
    </Collapse>

  </div>
</div>
</template>
<script>
import { Collapse } from "@/components/commons"
import { print } from '@/_helper'
import { FORM_CONTROLS } from '@/_helper/constants'
import { cleanDateFormat, isPilletOptionAvailable } from '@/_helper'

export default {
  name: "Pre-Start-Check-Details-View",
  components: { Collapse },
  props: {
    activePreStartCheck: {
      type: Object,
      default: () => {}
    },
  },
  watch: {
    activePreStartCheck: {
      deep: true,
      handler() {
        this.setActivePreStartCheckData()
      }
    }
  },
  async created() {
    this.loading = true
    this.setActivePreStartCheckData()
    const driverId = this.activePreStartCheck?.driverId;
    const driver = await this.$store.dispatch(`driver/getDriverByID`, driverId);
    if(driver) {
        this.driverName = driver.driverName;
    }
    setTimeout(() => this.loading = false, 400)
  },
  data() {
    return {
      vehicle: {},
      vehiclePrestart: {},
      driverName: '',
      isPassed: false,
      collapsed: true,
      loading: true,
      isDefault: true,
      formControls: FORM_CONTROLS,
      savedData: {},
      sections: [],
    };
  },
  methods: {
    setActivePreStartCheckData(){
      this.vehicle = this.activePreStartCheck?.vehicle;
      this.isPassed = this.activePreStartCheck?.isPassed;
      this.sections = this.activePreStartCheck?.savedData?.sections;
      this.isDefault = this.activePreStartCheck?.isDefault;
      this.savedData = this.activePreStartCheck?.savedData;
    },
    onCollapsed() {
      this.collapsed = !this.collapsed;
    },
    isPillet(option){
      return isPilletOptionAvailable(option)
    },
    printThis(toPrintId) {
      return print(toPrintId)
    },
    cleanDateFormat(date){
      return cleanDateFormat(date, true, true)
    }
  }
}
</script>
<style>
  .prestart-signature{
    width: 200px!important;
    height: 90px!important;
    background: rgb(68, 63, 63);
    border: 3px solid;
    margin-top: 10px;
  }
</style>