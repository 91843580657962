<template>
  <div>
    <div class="font-bold text-lg mb-3 text-green-500">
      Events from {{ cleanDateFormatShort(event.startDate) }} to {{ cleanDateFormatShort(event.endDate) }}
    </div>
    <div v-if="event">
      <h3 class="mt-4"> Found ({{event.events.length}})</h3>
      <div class="overflow-x-auto relative sm:rounded-lg mt-8 h-40 px-1">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 py-5">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" class="py-3 px-6 text-center">
                     Start Time
                  </th>
                  <th scope="col" class="py-3 px-6 text-center">
                      Type
                  </th>
                  <th scope="col" class="py-3 px-6 text-center">
                      Vehicle
                  </th>
                </tr>
            </thead>
            <tbody v-if="event.events.length > 0">
                <tr v-for="(item, index) in event.events" :key="index" class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                  <td class="py-3 px-6 text-center">
                      {{ cleanDateFormatShort(item.startTimeWithTimezone, false) }}
                  </td>
                  <td class="py-3 px-6 text-center">
                      {{lodash.upperFirst(item.type)}}
                  </td>
                  <td class="py-3 px-6 text-center">
                      {{item.vehicle}}
                  </td>
                </tr>
            </tbody>
            <tbody v-if="!event.events.length">
                <tr class="border-b border-gray-200 dark:border-gray-700">
                  <td colspan="9" class="py-8 px-5 text-sm text-center">
                      No items found
                  </td>
                </tr>
            </tbody>
          </table>
      </div>
    <div v-if="event">
      <div class="mb-4">
          <div class="font-bold text-lg text-red-500 mb-3">{{cleanLevel(event.type)}}</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{formatDate(event.startTimeWithTimezone)}}</div>
          <div class="text-xs">Time of event</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{event.odometer}}</div>
          <div class="text-xs">Odometer</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{event.vehicle}}</div>
          <div class="text-xs">vehicle</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{event.status}}</div>
          <div class="text-xs">Status</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{event.comment || '-'}}</div>
          <div class="text-xs">Comment</div>
      </div>
      <div class="mb-2" v-if="event.location">
          <div class="font-bold text-black-primary text-base">{{event.location.address}}</div>
          <div class="text-xs">Location</div>
      </div>
      <br>
  </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { formatDate, cleanDateFormat } from '@/_helper'

export default {
    name: "event-Details-View",
    props: {
      activeEvent: {
        type: Object,
      },
    },
    watch: {
      activeEvent: {
        deep: true,
        handler() {
          this.setActivePreStartCheckData()
        }
      }
    },
    created() {
      this.setActivePreStartCheckData()
    },
    data() {
      return {
        event: null,
        driverData: null,
        lodash: _ 
      };
    },
    methods: {
      setActivePreStartCheckData(){
        this.event = this.activeEvent
        this.driverData = this.activeEvent.driverData
      },
      cleanLevel(level){
        if (!level) return 'N/A';
        return _.startCase(level == 'no-breach' ? level.replace('-', ' ') : level.split('-')[0]);
      },
      formatDate(time){
        return time? formatDate(time) : null
      },
      cleanDateFormatShort(date, state = true){
        return cleanDateFormat(date, state)
      }
    },
  }
</script>