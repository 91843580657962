<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onNext">
      <div v-if="loading" class="text-center mt-28"><ui-spinner class="m-auto"></ui-spinner> Loading...</div>
      <div v-if="!loading" class="items-center justify-between">
        <div class="formulate-input-group-repeatable">
            <FormulateInput
              type="text"
              name="businessName"
              label= 'Business Name'
              validation= 'required'
            />
            <div class="grid grid-cols-2 gap-4">
              <FormulateInput
                type="text"
                name="tradingName"
                label= 'Trading Name'
                validation= 'required'
              />
              <FormulateInput
                type="text"
                name="abn"
                label= 'NZBN'
                validation= 'required'
              />
            </div>
            <div class="grid grid-cols-2 gap-4">
              <FormulateInput
                type="email"
                name="supportEmail"
                label= 'Support Email'
                validation= 'optional|email'
                @input="handleStritEmailCase($event)"
              />
              <FormulateInput
                type="text"
                name="supportPhoneNumber"
                label= 'Support Phone Number'
              />
            </div>
            <div class="border-t-2 mt-2 pt-4">
               <div class="formulate-input-group-repeatable">
                <FormulateInput
                  type="places-search-input"
                  name= "businessAddress"
                  label= 'Business address'
                  validation= 'required'
                />
               </div>
            </div>
         </div>
        <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
          <button class="btn col-span-2 btn-primary mt-2" :style="{backgroundColor: theme.secondaryColor}">Next</button>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters } from 'vuex'

export default {
  name: 'Edit-Partner-Business-Profile-Form',
  props: {
    detailsId:{
      type: String,
      default: null,
    },
    connectionType:{
      type: String
    }
  },
  data() {
    return {
      errorMessage: '',
      loading: false,
      conType: {},
      values: {},
      stateDataFromApi: null,
      accountInfo: null,
      pricing: null,
      prepaid: null,
      supportEmail: null,
      interChangingData: {},
    };
  },
  async mounted(){
    this.loading = true;
    let conDetails;

    if(this.postData){
      this.values = this.postData?.businessDetails || null
      this.interChangingData = this.postData

    } else {

      switch (this.connectionType) {
        case 'partner':
          conDetails = await this.$store.dispatch(`partner/getPartner`, {id: this.detailsId})
          break;
        case 'reseller':
          conDetails = await this.$store.dispatch(`reseller/getReseller`, {id: this.detailsId})
          break;
      }

      if(conDetails){
        if(!_.isEmpty(this.localData?.pricing)){
          this.pricing = this.localData.pricing;
          this.prepaid = this.localData.prepaid;
          this.accountInfo = this.localData.accountInfo;
        }

        this.values = this.parsePartner(conDetails);

        this.supportEmail = this.conType.supportEmail
        this.stateDataFromApi = this.conType.state;
        this.accountInfo = this.localData?.accountInfo || {
          contactUserName: conDetails.persona.contactUserName,
          contactEmail: conDetails.persona.contactEmail,
          contactPhoneNumber: conDetails.persona.contactPhoneNumber
        };

      }
    }
    this.loading = false;
  },
  methods: {
    parsePartner(conType){
    
      return {
        id: conType._id,
        personaId: conType.persona._id,
        abn: conType.persona.abn,
        businessName: conType.persona.businessName,
        tradingName: conType.persona.tradingName,
        currentStep: conType.persona.currentStep,
        contactPhoneNumber: conType.persona.contactPhoneNumber,
        contactEmail: conType.persona.contactEmail,
        contactUserName: conType.persona.contactUserName,
        supportEmail: conType.supportEmail,
        supportPhone: conType.supportPhone,
        businessAddress: conType.persona.businessAddress
      }
    },
    onNext(values) {
      if(!this.postData){
        const businessAddress = `${values.street}, ${values.city}, ${values.state}`;
        values.businessAddress = businessAddress;
        delete values.street; delete values.city; delete values.state;
        values.id = this.conType.id;
        values.personaId = this.conType.personaId;

        const businessDetails = JSON.parse(JSON.stringify(this.values))
        if(businessDetails){

          this.$emit('nextStep', { 
            businessDetails: businessDetails,
            pricing: this.pricing,
            prepaid: this.prepaid,
            accountInfo: this.accountInfo,
            detailsId: this.detailsId,
            connectionType: this.connectionType
          })

        } else {

          const data = {...this.interChangingData, ...{
            businessDetails: businessDetails,
            connectionType: this.connectionType
          }}
          
          this.$emit('nextStep', data)
        }
      }
    },
    handleStritEmailCase(value){
      this.supportEmail = value? value.toLowerCase() : ''
    }
  },
  computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
   }
}
</script>