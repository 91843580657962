<template>
   <div>
      <div v-if="loading" class="loading absolute mx-auto my-24 w-full h-full translate-y-2/4 flex flex-col items-center">
         <ui-spinner class="mx-auto mb-3"></ui-spinner>
         Loading....
      </div>
      <div v-if="!loading && investigation">     
         <header v-if="investigation.investigationStatus">
            <div class="font-bold text-black-primary text-2xl  text-center">
               {{investigation._id}}
            </div>
            <div class="font-bold text-gray-300 text-lg mb-8 text-center"
               :class="[(investigation.investigationStatus == 'completed') ? 'text-green-500' : '']"
               >
               {{ investigation.investigationStatus }}
            </div>
         </header>

         <section v-if="investigation" class="h-full w-full mt-5 border rounded-lg group bg-gray-100">
            <header class="flex">
               <div class="pb-2 font-bold pt-3 px-3">
                  Details
               </div>
            </header>
            <hr/>
            <div class="flex w-full px-5 py-5 bg-white">
               <table>
                  <tr>
                     <td class="pb-1">
                        <div class="font-semibold">Date Raised: </div>
                     </td>
                     <td class="pb-1">
                        <div class="ml-3">{{momentFormat(investigation.dateRaised)}}</div>
                     </td>
                  </tr>
                  <tr>
                     <td class="pb-1">
                        <div class="font-semibold">People who raise:</div>
                     </td>
                     <td class="pb-1">
                        <ul class="ml-3" :class="[(investigation.raisers.length > 1) ? 'list-decimal pl-4 pt-3 text-sm' : '']">
                        <li v-for="(item, index) in investigation.raisers" :key="index">
                           {{ item.name }}
                        </li>
                        </ul>
                     </td>
                  </tr>
                  <tr>
                     <td class="pb-1">
                        <div class="font-semibold">Investigator{{(investigation.investigators.length == 1)? '' : 's' }}:</div>
                     </td>
                     <td class="pb-1">
                        <ul class="ml-3" :class="[(investigation.investigators.length > 1) ? 'list-decimal pl-4 pt-3 text-sm' : '']">
                        <li v-for="(item, index) in investigation.investigators" :key="index">
                           {{ item.name }}
                        </li>
                        </ul>
                     </td>
                  </tr>
                  <tr>
                     <td class="pb-1">
                        <div class="font-semibold">Decision Maker{{(investigation.decisionMakers.length == 1)? '' : 's' }}: </div>
                     </td>
                     <td class="pb-1">
                        <ul class="ml-3" :class="[(investigation.decisionMakers.length > 1) ? 'list-decimal pl-4 pt-3 text-sm' : '']">
                        <li v-for="(item, index) in investigation.decisionMakers" :key="index">
                              {{ item.name }}
                        </li>
                        </ul>
                     </td>
                  </tr>
               </table>
            </div>
         </section>
         <br/>


         <section class="h-full w-full mt-3 border rounded-lg group bg-gray-100">
            <header class="flex">
               <div class="pb-2 font-bold pt-3 px-3">
                  Driver
               </div>
            </header>
            <hr/>

            <div class="flex w-full px-5 py-5 bg-white">
               <table v-if="driver">
                  <tr>
                     <td class="pb-1">
                     <div class="font-semibold">Name: </div>
                     </td>
                     <td class="pb-1">
                     <div class="ml-3">{{driver.driverName}}</div>
                     </td>
                  </tr>
                  <tr>
                     <td class="pb-1">
                     <div class="font-semibold">Email: </div>
                     </td>
                     <td class="pb-1">
                     <div class="ml-3">{{driver.emailAddress}}</div>
                     </td>
                  </tr>
                  <tr>
                     <td class="pb-1">
                     <div class="font-semibold">Licence Number: </div>
                     </td>
                     <td class="pb-1">
                     <div class="ml-3">{{driver.driversLicenseNumber}}</div>
                     </td>
                  </tr>
                  <tr>
                     <td class="pb-1">
                     <div class="font-semibold">UDI: </div>
                     </td>
                     <td class="pb-1">
                     <div class="ml-3">{{driver.udi}}</div>
                     </td>
                  </tr>
               </table>
               <table v-else>
                  <tr>
                     <td class="pb-1">
                     <div class="font-semibold">Name: </div>
                     </td>
                     <td class="pb-1">
                     <div class="ml-3">{{investigation.driverStringBasedName}}</div>
                     </td>
                  </tr>
               </table>
            </div>
         </section>
         <br/>

         <section v-if="investigation.performance" class="h-full w-full mt-3 border rounded-lg group bg-gray-100">
            <header class="flex">
               <div class="pb-2 font-bold pt-3 px-3">
                  Performance
               </div>
            </header>
            <hr/>

            <div class="flex w-full px-5 py-5 bg-white">
               <table>
                  <tr>
                     <td class="pb-1">
                     <div class="font-semibold">Status: </div>
                     </td>
                     <td class="pb-1">
                     <div class="ml-3">{{investigation.performance.status}}</div>
                     </td>
                  </tr>
                  <tr>
                     <td class="pb-1">
                     <div class="font-semibold">Start Date: </div>
                     </td>
                     <td class="pb-1">
                     <div class="ml-3">{{investigation.performance.startDate}}</div>
                     </td>
                  </tr>
                  <tr>
                     <td class="pb-1">
                     <div class="font-semibold">End Date: </div>
                     </td>
                     <td class="pb-1">
                     <div class="ml-3">{{investigation.performance.endDate}}</div>
                     </td>
                  </tr>
                  <tr>
                     <td class="pb-1">
                     <div class="font-semibold">Next Check Date: </div>
                     </td>
                     <td class="pb-1">
                     <div class="ml-3">{{investigation.performance.nextPeriodicCheck}}</div>
                     </td>
                  </tr>
               </table>
            </div>
         </section>
         <br/>

         <section class="h-full w-full mt-3 border rounded-lg group bg-gray-100">
            <header class="flex">
               <div class="w-3/4 pb-2 xt-xl font-bold pt-4 px-3">
                  Documents ({{createdDocuments.length}})
               </div>
               <div class="w-1/4 pb-2 xt-xl font-bold pt-3">
               <button
                  v-if="!loading && unfinished(investigation.investigationStatus)"
                  @click="addDocuments(investigation._id)" 
                  class="btn btn-primary float-right mr-3 h-9 pr-9 text-sm transition-colors duration-150 focus:shadow-outline text-gray-600 hover:text-orange-100">
                  <font-awesome-icon 
                     icon="plus" 
                     class="my-auto mr-1.5 text-sm"/>
                  Add Docs 
               </button>
               </div>
             
            </header>
            <hr/>

            <div class="flex w-full">
               <aside class="w-36 border-r h-40">
                  <div v-if="!asideLoading">
                     <div v-if="createdDocuments.length && activeDocView" class="rounded w-full overflow-y-auto h-60">
                        <div class="text-sm py-3 pl-3 border-b cursor-pointer bg-white"
                           v-for="(data, key) in createdDocuments" :key="key"
                           :class="[(activeDocView.docId == data.docId) ? 'bg-green-100' : '']"
                           @click="setActiveDocView(data)">
                           <span
                              v-if="data"                 
                              >{{ cleanDataType(data.docFormType).substring(0,6)+"..."  }}
                           </span>
                           <FolderIcon :a="activeDocView.docId" :b="data.docId"/>
                        </div>
                     </div>
                     <div v-if="!createdDocuments.length" class="w-full pl-2 py-2 border-b border-gray-300 opacity-40">
                        <small><em>No docs found</em></small>
                     </div>
                  </div>
                  <div v-if="asideLoading">
                     <div class="loading translate-y-2/4 mt-12">
                        <ui-spinner class="mx-auto mb-3"></ui-spinner>
                     </div>
                  </div>
               </aside>

               <div class="h-full w-full bg-white px-6 py-7 shadow rounded-lg mb-6">

                  <div v-if="!activeDocViewLoading">

                     <div v-if="createdDocuments.length && activeDocView">

                        <ImmediateActionView :activeDocView="activeDocView" v-if="activeDocView.docFormType == docFormTypeList.IMMEDIATE_ACTION"/>

                        <InterviewView :activeDocView="activeDocView" v-if="activeDocView.docFormType == docFormTypeList.INTERVIEW"/>

                        <EvidenceCollectionView :activeDocView="activeDocView" v-if="activeDocView.docFormType == docFormTypeList.EVIDENCE_COLLECTION"/>

                        <FindingsView :activeDocView="activeDocView" v-if="activeDocView.docFormType == docFormTypeList.FINDINGS"/>
                     </div>

                      <div v-if="!createdDocuments.length" class="h-full w-full">
                        <div class="bg-white-100 h-80 border-orange-500 text-orange-700 py-4">
                           <p class="font-bold">Empty</p>
                           <p>Empty.</p>
                        </div>
                     </div>
                     
                  </div>
                  
                  <div v-if="activeDocViewLoading" >
                     <div class="loading my-24 translate-y-2/4 flex flex-col items-center mx-auto py-20 h-auto mb-20 w-full pl-5 ">
                        <ui-spinner class="mx-auto mb-3"></ui-spinner>
                        Loading....
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <br/>

         <section class="h-full w-full mt-3 border rounded-lg group bg-gray-100">
            <header class="flex">
               <div class="pb-2 font-bold pt-3 px-3">
                  Linked Data ({{linkedData.length}})
               </div>
            </header>
            <hr/>
            <div class="flex w-full">
               <aside class="w-36 border-r h-40">
                  <div v-if="!asideLoading">
                     <div v-if="linkedData.length && activeLinkedDataView" class="w-full overflow-y-auto h-60">
                        <div class="text-sm py-3 pl-3 border-b cursor-pointer bg-white"
                           v-for="(data, key) in linkedData" :key="key"
                           :class="[(activeLinkedDataView._id == data._id) ? 'bg-green-100' : '']"
                           @click="setActiveLinkedDataView(data)">
                           <span
                              v-if="data"                 
                              >{{ cleanDataType(data.linkDataType).substring(0,8)+"..."  }}
                           </span>
                           <FolderIcon :a="activeLinkedDataView._id" :b="data._id"/>
                        </div>
                     </div>
                     <div v-if="!linkedData.length" class="w-full pl-4 py-2 border-b border-gray-300 opacity-40">
                        <small><em>Empty</em></small>
                     </div>
                  </div>
                  <div v-if="asideLoading" class="h-96">
                     <div class="loading translate-y-2/4 mt-12">
                        <ui-spinner class="mx-auto mb-3"></ui-spinner>
                     </div>
                  </div>
               </aside>

               <div class="h-full w-full bg-white px-6 py-7 shadow rounded-lg mb-6">

                  <div v-if="!activeLinkedDataViewLoading">

                     <div v-if="linkedData.length && activeLinkedDataView">

                        <div v-if="activeLinkedDataView.linkDataType == FITNESS_DECLARATION">
                           <FitnessDeclarationPartialView
                              :activeFitnessDeclaration="activeLinkedDataView"
                           />
                        </div>

                        <div v-if="activeLinkedDataView.linkDataType == PRESTART">
                           <PrestartPartialView
                              :activePreStartCheck="activeLinkedDataView"
                           />
                        </div>

                        <div v-if="activeLinkedDataView.linkDataType == BREACH">
                           <BreachDetailsPartialView
                              :activeBreach="activeLinkedDataView"
                           />
                        </div>

                        <div v-if="activeLinkedDataView.linkDataType == EVENT">
                           <EventBasicView
                              :activeEvent="activeLinkedDataView"
                           />
                        </div>

                     </div>

                      <div v-if="!linkedData.length" class="h-full w-full">
                        <div class="bg-white-100 h-80 border-orange-500 text-orange-700 py-4">
                           <p class="font-bold">Empty</p>
                           <p>Empty.</p>
                        </div>
                     </div>

                  </div>
                  
                  <div v-if="activeLinkedDataViewLoading" >
                     <div class="loading my-24 translate-y-2/4 flex flex-col items-center mx-auto py-20 h-auto mb-20 w-full pl-5 ">
                        <ui-spinner class="mx-auto mb-3"></ui-spinner>
                        Loading....
                     </div>
                  </div>
               </div>
            </div>
         </section>

         <div v-if="investigation.investigationStatus" class="float-right mt-5 mb-1">
            <div v-if="unfinished(investigation.investigationStatus)">
               <button @click="finalise" class="btn bg-orange-primary hover:bg-orange-200 text-white w-60 text-sm mr-3">
                  <font-awesome-icon icon="save" class="text-white"/>&nbsp; 
                  <span v-if="!finaliseLoading"> Finalise Investigation </span>
                  <span v-else> Finalising... </span>      
               </button>
               <button @click="raiseNonConformance(investigation)" class="btn bg-gray-500 w-60 text-sm text-white">
                  <font-awesome-icon icon="save" class="text-white"/>&nbsp; Raise Non-Conformance    
               </button>
            </div>
            <div v-if="finished(investigation.investigationStatus)">
               <button @click="reopen(investigation._id)" class="btn bg-orange-primary hover:bg-orange-200 text-white w-60 text-sm mr-3">
                  <font-awesome-icon icon="history" class="text-white"/>&nbsp; 
                  <span v-if="!reOpenLoading"> Re-open Investigation </span>
                  <span v-else>Re-opening... </span>
               </button>
               <button @click="raiseNonConformance(investigation)" class="btn bg-gray-500 w-60 text-sm text-white">
                  <font-awesome-icon icon="save" class="text-white"/>&nbsp; Raise Non-Conformance    
               </button>
            </div>
         </div>
      </div>
   </div>
</template>
<script>
import {
   EvidenceCollectionView,
   FindingsView,
   ImmediateActionView,
   InterviewView
} from '@/components/forms/business/investigation-register/documents/doc-view-data'
import FitnessDeclarationPartialView from '@/components/view/business/fitness-declaration/fitness-declaration-partial-view'
import PrestartPartialView from '@/components/view/business/prestart/prestart-partial-view'
import { DOCUMENT_FORM_TYPES, UNFINISHED_INVESTIGATION, FINISHED_INVESTIGATION } from '@/_helper/constants'
import { removeStringUnderscore } from '@/_helper'
import _ from 'lodash'
import { DATA_TYPES } from '@/_helper/constants'
import moment from 'moment'
import BreachDetailsPartialView from '@/components/view/breach/breach-partial-view'
import EventBasicView from '@/components/view/compliance/event-basic-view';
import FolderIcon from '@/components/commons/icons/folder-icon-open-close'

export default {
   name: 'View-Investigation-Details',
   components: { 
      EvidenceCollectionView,
      FindingsView,
      ImmediateActionView,
      InterviewView,
      FitnessDeclarationPartialView,
      PrestartPartialView,
      BreachDetailsPartialView,
      EventBasicView,
      FolderIcon
   },
   props: {
      activeInvestigation: null
   },
   data(){
      return {
         loading: true,
         asideLoading: false,
         finaliseLoading: false,
         reOpenLoading: false,
         driver: null,
         investigation: null,
         docFormTypeList: {
            IMMEDIATE_ACTION: DOCUMENT_FORM_TYPES.IMMEDIATE_ACTION,
            INTERVIEW: DOCUMENT_FORM_TYPES.INTERVIEW,
            EVIDENCE_COLLECTION: DOCUMENT_FORM_TYPES.EVIDENCE_COLLECTION,
            FINDINGS: DOCUMENT_FORM_TYPES.FINDINGS
         },
         createdDocuments: [],
         activeDocViewLoading: false,
         activeDocView: null,
         linkedData: [],
         activeLinkedDataViewLoading: false,
         activeLinkedDataView: null,
         FITNESS_DECLARATION: DATA_TYPES.FITNESS_DECLARATION,
         PRESTART: DATA_TYPES.PRESTART,
         BREACH: DATA_TYPES.BREACH,
         EVENT: DATA_TYPES.EVENT,
         UNFINISHED_INVESTIGATION: UNFINISHED_INVESTIGATION,
         FINISHED_INVESTIGATION: FINISHED_INVESTIGATION
      }
   },
   watch: {
      activeInvestigation: {
         deep: true,
            handler() {
            this.setActiveInvestigation()
         }
      }
   },
   async mounted() {
      await this.setActiveInvestigation()
      setTimeout(() => this.loading = false, 300)
   },
   methods: {
      setLinkedData(){
         this.linkedData = this.activeInvestigation?.linkedData || []
         if(!_.isEmpty(this.linkedData)){
            this.activeLinkedDataView = this.linkedData[0]
         }
      },
      setInvestigationDocuments(){
         let Arr = [];
         if(!_.isEmpty(this.activeInvestigation?.findings)){
            Arr.push(...this.activeInvestigation.findings)
         }
         if(!_.isEmpty(this.activeInvestigation?.evidenceCollections)){
            Arr.push(...this.activeInvestigation.evidenceCollections)
         }
         if(!_.isEmpty(this.activeInvestigation?.immediateActions)){
            Arr.push(...this.activeInvestigation.immediateActions)
         }
         if(!_.isEmpty(this.activeInvestigation?.interviews)){
            Arr.push(...this.activeInvestigation.interviews)
         }
         this.createdDocuments = Arr
         if(this.createdDocuments.length){
            this.activeDocView = this.createdDocuments[0]
         }
      },
      setActiveLinkedDataView(item){
         this.activeLinkedDataViewLoading = true
         this.activeLinkedDataView = item
         setTimeout(() => {
            this.activeLinkedDataViewLoading = false
         }, 200)
      },
      setActiveDocView(item){
         this.activeDocViewLoading = true
         this.activeDocView = item
         setTimeout(() => {
            this.activeDocViewLoading = false
         }, 200)
      },
      async setActiveInvestigation(){
         if(this.activeInvestigation){
            this.driver = this.activeInvestigation.driver
            this.investigation = this.activeInvestigation
            await this.setInvestigationDocuments()
            await this.setLinkedData()
         }
      },
      momentFormat(date, isShort = false){
         if(date){
            if(isShort) {
               return date ? moment(date).format('MMM D, YYYY') : '-'
            } else {
               return date ? moment(date).format('MMMM D, YYYY') : '-'
            }
         }
      },
      cleanDataType(str){
        return removeStringUnderscore(str)
      },
      addDocuments(itemId){
         this.$emit('addDocuments', itemId)
      },
      async finalise(){
         this.finaliseLoading = true
         const me = this.$store.getters[`account/me`]
         const businessId = me?.business?._id

         let payload = {};
         payload.id = this.investigation._id
         payload.businessId = businessId

         await this.$store.dispatch('investigation/finalise', payload)
         .then(() =>{
            setTimeout(() =>{
               this.$emit('closeModal')
               this.$store.commit('setDialogNotify',true)
               this.$store.commit('setMessageNotify',{state: 'success', message: 'Investigation successfully finalise.'})
            }, 400)
         })
         .catch((err) =>{
            this.submitLoading = false
            this.$store.commit('setDialogNotify',true)
            this.$store.commit('setMessageNotify',{state: 'error', message: err.message})
         })
         this.finaliseLoading = false
      },
      async reopen(investigationId){
         this.reOpenLoading = true
         const me = this.$store.getters[`account/me`]
         const businessId = me?.business?._id

         let payload = {};
         payload.id = investigationId
         payload.businessId = businessId

         await this.$store.dispatch('investigation/reOpenInvestigation', payload)
         .then(() =>{
            setTimeout(() =>{
               this.$emit('closeModal')
               this.$store.commit('setDialogNotify',true)
               this.$store.commit('setMessageNotify',{state: 'success', message: 'Investigation successfully re-opened.'})
            }, 400)
         })
         .catch((err) =>{
            this.submitLoading = false
            this.$store.commit('setDialogNotify',true)
            this.$store.commit('setMessageNotify',{state: 'error', message: err.message})
         })
         this.reOpenLoading = false
      },
      raiseNonConformance(investigation){ 
         this.$emit('raiseNonConformance', investigation)
      },
      unfinished(status){
         return UNFINISHED_INVESTIGATION.includes(status)
      },
      finished(status){
         return FINISHED_INVESTIGATION.includes(status)
      }
   }
}
</script>