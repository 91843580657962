<template>
  <Stepper
    :stepperSteps="stepperSteps"
    @completed-step="completeStep"
    @active-step="isStepActive"
    :showNavigation="false"
    @closeModal="closeModal"
  />
</template>
<script>
import { Stepper } from "@/components/commons";
import {
  DurationTerms,
  ModuleSelection,
  Pricing,
  Summary
} from "./lead-stepper-component";

export default {
  name: "Convert-lead",
  components: { Stepper },
  props: {
    leadId: String
  },
  data() {
    return {
      stepperSteps: [
        {
          stepIcon: "check",
          stepTitle: "Module Selection",
          stepSequence: "first",
          stepComponent: ModuleSelection,
          isCompleted: false,
          detailsId: this.leadId
        },
        {
          stepIcon: "calendar",
          stepTitle: "Duration Terms",
          stepSequence: "second",
          stepComponent: DurationTerms,
          isCompleted: false,
        },
        {
          stepIcon: "dollar-sign",
          stepTitle: "Pricing",
          stepSequence: "third",
          stepComponent: Pricing,
          isCompleted: false,
        },
        {
          stepIcon: "file",
          stepTitle: "Contract Summary",
          stepSequence: "fourth",
          stepComponent: Summary,
          isCompleted: false,
        },

      ],
      activeStep: 0,
    };
  },
  methods: {
    completeStep(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          step.isCompleted = true;
        }
      });
    },
    isStepActive(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          if (step.isCompleted === true) {
            step.isCompleted = false;
          }
        }
      });
    },
    closeModal(){ 
      this.$emit('closeModal')
    }
  },
};
</script>
