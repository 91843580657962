<template>
  <div class="w-full h-full max-w-full">
    <div v-if="loading" class="text-center w-full h-screen flex items-center justify-center">
      <div>
        <ui-spinner class="m-auto"></ui-spinner> Loading...
      </div>
    </div>

    <template v-if="!loading">
      <UpdateEntityPaymentInvoiceForm @invoiceCreated="invoiceCreatedAction" />
    </template>
  </div>
</template>

<script>
import UpdateEntityPaymentInvoiceForm from "@/components/forms/payment/update-entity-payment-invoice-form";
export default {
  name: "Adhoc-Billing-Tool",
  components: {
    UpdateEntityPaymentInvoiceForm
  },
  data () {
    return {
      loading: false
    }
  },

  methods: {
    async invoiceCreatedAction(isSuccessful) {
      if(isSuccessful) {
        this.$store.commit('setDialogNotify',true);
        this.$store.commit('setMessageNotify',{state:'success',message:'Back billing successfully processed.'});
        setTimeout(()=>{location.reload()},3000);
      } else {
        this.$store.commit('setDialogNotify',true);
        this.$store.commit('setMessageNotify',{state:'error',message:'Back billing failed to process.'});
      }
    }
  }
}
</script>