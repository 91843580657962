
export const STATES = [
    { value: 'ACT', label: 'ACT' },
    { value: 'NSW', label: 'NSW' },
    { value: 'NT', label: 'NT' },
    { value: 'QLD', label: 'QLD' },
    { value: 'SA', label: 'SA' },
    { value: 'TAS', label: 'TAS' },
    { value: 'VIC', label: 'VIC' },
    { value: 'WA', label: 'WA' },
]

export const TIMEZONE = [
    { value: "NZST +12:00", label: "NZST +12:00" },
    { value: "NZDT +13:00", label: "NZDT +13:00" },
]