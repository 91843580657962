<template>
  <div>
    <Table
      v-if="readUserRoleAuditTrail"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="auditTrailsData"
      :hasActions="false"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="recordsPerPage"
              class="
                appearance-none
                h-full
                rounded-l
                border
                block
                w-full
                bg-white
                border-gray-400
                text-gray-700
                py-2
                px-4
                pr-8
                leading-tight
                focus:outline-none focus:bg-white focus:border-gray-500
              "
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="selectedFilteredStatus"
              @change="selectFilteredStatus"
              class="
                appearance-none
                h-full
                border border-r-none
                rounded-r-none
                -backdrop-hue-rotate-15block
                w-full
                bg-white
                border-gray-400
                text-gray-700
                py-2
                px-4
                pr-8
                leading-tight
                focus:outline-none
                focus:border-l
                focus:border-r
                focus:bg-white
                focus:border-gray-500
              "
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="
                pointer-events-none
                absolute
                inset-y-0
                right-0
                flex
                items-center
                px-2
                text-gray-700
              "
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="
              h-full
              appearance-none
              rounded-r rounded-l
              sm:rounded-l-none
              border border-gray-400 border-b
              block
              pl-8
              pr-6
              py-2
              w-full
              bg-white
              text-sm
              placeholder-gray-400
              text-gray-700
              focus:bg-white
              focus:placeholder-gray-600
              focus:text-gray-700
              focus:outline-none
            "
            @input="queryForKeywords($event.target.value)"
          />
        </div>
      </template>
    </Table>

    <div v-if="!readUserRoleAuditTrail && hasRole" class="text-center">
      <h1>
        Not authorized to view audit trails. Please contact your {{ userType }}.
      </h1>
    </div>
    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>
  </div>
</template>

<script>
import { Table } from "@/components/commons";
import { formatDate } from "@/_helper";
import _ from "lodash";
export default {
  name: "Business-Audit-Trail",
  components: {
    Table,
  },
  data() {
    return {
      auditTrails: [],
      tableHeader: ["Role", "Affected User", "Done By", "Action", "Created At"],
      tableData: [],
      recordsPerPage: 50,
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: ["All", "Active", "Inactive"],
      readUserRoleAuditTrail: false,
      roleId: {
        linkageId: null,
      },
      role: null,
      userId: null,
      userType: null,
      hasRole: true,
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      this.userType = securityRoles[0].type;
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readUserRoleAuditTrail = securityAccessObj.readUserRoleAuditTrail;
    } else {
      this.hasRole = false;
    }
    this.roleId = this.$route.params["id"];
    this.userId = this.$route.params["userId"];
    let role;
    if (this.roleId && this.roleId != "null") {
      role = await this.$store.dispatch(`rolesandrespo/getRoles`, this.roleId);
      if (role) {
        this.role = role;
      }
    }
    const auditTrailPayload = {};
    if (this.roleId && this.roleId != "null") {
      auditTrailPayload.roleId = this.roleId;
    }
    if (this.role) {
      auditTrailPayload.linkageId = this.role.linkageId._id;
    }
    if (this.userId && this.userId != "null") {
      auditTrailPayload.targetUid = this.userId;
    }
    const auditTrails = await this.$store.dispatch(
      `rolesandrespo/getAuditTrails`,
      auditTrailPayload
    );
    if (auditTrails) {
      this.auditTrails = this.formatItem(auditTrails);
    }
    this.$emit("updateLoading", false);
  },
  methods: {
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const auditTrail = {
            id: item["_id"] || "",
            role: item["roleId"]["name"] || "",
            targetUid:
              (item["targetUid"] && item["targetUid"]["userName"]) || "N/A",
            doneByUid: item["doneByUid"]["userName"] || "",
            action: item["action"] || "",
            createdAt: formatDate(item["createdAt"]) || "",
          };
          nItems.push(auditTrail);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseAuditTrails(data) {
      return data.map((auditTrailData) => [
        {
          id: auditTrailData.id,
          name: auditTrailData.role,
          itemType: "string",
        },
        {
          id: auditTrailData.id,
          name: auditTrailData.targetUid,
          itemType: "string",
        },
        {
          id: auditTrailData.id,
          name: auditTrailData.doneByUid,
          itemType: "string",
        },
        {
          id: auditTrailData.id,
          name: auditTrailData.action,
          itemType: "string",
        },
        {
          id: auditTrailData.id,
          name: auditTrailData.createdAt,
          itemType: "string",
        },
      ]);
    },
    selectFilteredStatus() {
      this.selectedStatus = this.filterStatuses[this.selectedFilteredStatus];
    },
    //TODO: set a proper naming convention and move to helper file??
    filterRow(keyword, attributeToFilter) {
      return keyword
        .toLowerCase()
        .split(" ")
        .every((v) => attributeToFilter.trim().toLowerCase().includes(v));
    },
    setSecurityAccess: (securityRoles) => {
      console.log("securityRoles: ", securityRoles);
      let initialReadUserRoleAuditTrail = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.permissions.readUserRoleAuditTrail.value) {
          initialReadUserRoleAuditTrail =
            securityConfig.permissions.readUserRoleAuditTrail.value;
        }
      });
      return {
        readUserRoleAuditTrail: initialReadUserRoleAuditTrail,
      };
    },
  },
  computed: {
    auditTrailsData() {
      if (this.auditTrails) {
        let auditTrailsComputedData = this.auditTrails;
        if (this.selectedFilteredStatus) {
          auditTrailsComputedData = auditTrailsComputedData.filter((item) =>
            this.filterRow(this.selectedStatus, item.status)
          );
        }
        if (this.recordsPerPage) {
          auditTrailsComputedData = _.take(
            auditTrailsComputedData,
            this.recordsPerPage
          );
        }
        if (this.searchKeyword) {
          auditTrailsComputedData = auditTrailsComputedData.filter((item) =>
            this.filterRow(this.searchKeyword, item.role)
          );
        }
        if (!this.searchKeyword && !this.selectedStatus) {
          return this.parseAuditTrails(this.auditTrails);
        }
        return this.parseAuditTrails(auditTrailsComputedData);
      } else {
        return [];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>