import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class Prestart {
  getPrestartByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
    let filter = '';
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/prestart/find-by-businessId/${businessId}${query}`)
  }

  getPrestartById( query ){
    return API.ACCOUNTS.get(`/prestart/${query}`);
  }

  getOldPrestartById( query ){
    return API.ACCOUNTS.get(`/prestart/old/${query}`);
  }

  saveNote(payload){
    return API.ACCOUNTS.post(`/prestart/note/${payload.id}`, payload);
  }

}