<template>
  <div>
    <FormulateInput
      v-if="isDisplayValue"
      type="textarea"
      :id="control.id"
      :name="control.name"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
      v-model="control.value"
    />
   <FormulateInput
      v-else
      type="textarea"
      :id="control.id"
      :name="control.name"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
    />
  </div>
</template>
<script>
export default {
  props: {
    control: {
      type: Object,
      default: () => {}
    },
    isDisplayValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>
