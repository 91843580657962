<template>
  <div class="container mx-auto p-6">
    <div class="flex items-stretch lg:flex-row flex-col -mx-4">
      <div class="flex-1 p-4">
        <LinkCard v-if="loadedEWDData" card-title="EWD Forms" card-url="/business/form-setup/EWD/set" />
      </div>
      <div v-if="loadedFatigueCompliancedData" class="flex-1 p-4">
        <LinkCard card-title="Fatigue Forms" card-url="/business/form-setup/fatigue_compliance/set" />
      </div>
    </div>
  </div>
</template>
<script>
import LinkCard from '@/components/commons/ui/LinkCard'
export default {
  components: {
    LinkCard
  },
  data(){
    return {
      loadedEWDData: null,
      loadedFatigueCompliancedData: null,
    }
  },
  created(){
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`]
    // Reads from businessId to get business site id, otherwise use main business id
    this.businessId = me.businessId ? me.businessId : me?.business?._id;
    this.$store.dispatch('form/getFormSettingByBusinessId', this.businessId)
      .then(data => {
        this.loadedEWDData = data?.EWD
        this.loadedFatigueCompliancedData = data?.fatigueCompliance;
      })
      .catch(err => {
        this.$store.commit('setDialogNotify',true)
        this.$store.commit('setMessageNotify',{state: 'error', message: err.message})
      })
      this.$emit("updateLoading", false);
  }
}
</script>