
import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper'

class Breach {

   // Form
   sendSingleDriverBreachReport(data){
      return API.ACCOUNTS.post(`/report/breach-single-driver`, data);
   }

   // Form
   sendMultipleDriverBreachReport(data){
      return API.ACCOUNTS.post(`/report/breach-multiple-driver`, data);
   }
   
   updateNote(data){
      return API.ACCOUNTS.patch(`/breach-engine/update-note/${data.id}`, data)
   }

   getBreachByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
      let filter = '';
      if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
      query.filter = filter;
      if(query) query = '?'+ObjectToQueryString(query);
      return API.ACCOUNTS.get(`/breach-engine/find-by-entity-with-fixed-all-drivers/${businessId}${query}`)
   }

   getBreach( payload ){
      return API.ACCOUNTS.get(`/breach-engine/${payload.id}`);
   }

   sendBreachReport(data){
      return API.ACCOUNTS.post(`/report/breach/send`, data)
   }
}

export default new Breach