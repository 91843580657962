<template>
  <div class="relative w-full min-h-full items-center">
    <div v-if="!hasDriver" class="h-full">
      <button class="absolute ml-32 z-30" @click.prevent="showDriverList">
        <small v-if="!isDriverListOpen" class="btn-primary btn-xs px-3 shadow rounded-lg"> + Select from driver list </small>
        <small v-else class="bg-red-600  text-white btn-xs px-3 shadow rounded-lg"> 
          <font-awesome-icon icon="times"/>&nbsp; Close
        </small>
      </button>

      <div class="relative">
        <FormulateInput
          type="text"
          label="Affected Driver"
          validation="required"
          aria-autocomplete="off"
          autocomplete="off"
          placeholder="Write driver's name"
          v-model="formValues.actionDriverStringBasedName"
        />
              
        <div v-if="isDriverListOpen" class="w-full px-3 h-96 pb-3 -mt-16 pt-7 absolute z-30 bg-white shadow-lg">
          <div class="content-center items-center w-full">
            <div classs="w-full content-center">
              <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                Search driver
              </label>
              <FormulateInput
                type="driver-select"
                placeholder="Select a driver"
                style="margin-bottom: 0px"
                class="pb-4 content-center"
                :getOptionKey="option => option.driver.udi"
                :getOptionLabel="option => option.driver.driverName"
                :filterable="false"
                :clearable="false"
                validation="required"
                @input="onChange($event)" 
              />
              <hr/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="hasDriver">
      <div class="items-center justify-between">
        <button @click="clearDriver" title="Reset" class="absolute  ml-32 text-red-900 float-left pr-4">
          <span class="cursor-pointer">
            <font-awesome-icon icon="times" class="text-red-900"/>
          </span>
        </button>
        <FormulateInput
          type="text"
          label="Affected Driver"
          validation="required"
          aria-autocomplete="off"
          autocomplete="off"
          placeholder="Write driver's name"
          aria-readonly="true"
          :value="formValues.actionDriverName"
          readonly
          />
      </div>
    </div>

    <FormulateInput
        type="date"
        name="actionDate"
        label="Action Date"
        validation="required"
        min="2018-12-01"
        v-model="formValues.actionDate"
      />

    <FormulateInput
      type="textarea"
      name="actionTakenDetails"
      label="Action Taken Details"
      validation="required"
      placeholder="Write the action taken details here..."
      v-model="formValues.actionTakenDetails"
      rows="8"
    />

  </div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'Immediate-Action-Form',
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    activeDocForm: {
      default: null,
    }
  },
  data(){
    return {
      formValues: {},
      hasDriver: false,
      isDriverListOpen: false
    }
  },
  watch: {
    activeDocForm: {
      deep: true,
      handler() {
        this.setActiveDocForm()
      }
    }
  },
  created() {
    this.setActiveDocForm();
  },
  methods: {
    setActiveDocForm(){
      if(this.isEditMode){
        this.formValues = this.activeDocForm
        if(!_.isEmpty(this.formValues?.actionDriverId)){
          this.hasDriver = true
        }
      }
    },
    clearDriver(){
      this.driver = null
      this.hasDriver = false
      this.seachKeyword = ''
      this.isDriverListOpen = false
      this.formValues.driver = null
    },
    onChange(data){
      if(data){
        this.hasDriver = true
        this.searchLoading = false
        this.formValues.actionDriverId = data.driver._id
        this.formValues.actionDriverName = data.driver.driverName
        this.formValues.actionDriverStringBasedName = null
      }
    },
    showDriverList(){
      this.isDriverListOpen = !this.isDriverListOpen
    }
  }
}
</script>>