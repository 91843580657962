export default [  
  {
    component: 'div',
    class: 'grid grid-cols-2 gap-4',
    children: [
      {
        type: 'text',
        name: 'driverName',
        label: 'Full Name',
        readonly:true
      },
      {
        type: 'text',
        name: 'driverDateOfBirth',
        label: 'Date Of Birth',
        readonly:true,
      },
    ]
  },
  {
    component: 'div',
    class: 'grid grid-cols-2 gap-4',
    children: [
      {
        type: 'text',
        name: 'driversLicenseNumber',
        label: 'Licence Number',
        readonly:true,
      },
      {
        type: 'text',
        name: 'driversLicenseExpiry',
        label: 'Licence Expiry',
        readonly: true
      },
    ]
  }
]