<template>
  <div class="mb-16">
    <FormulateForm
      name="editBusinessForm"
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
    >

    <div class="text-sm mb-1 font-semibold form-label pr-2 text-neutral-dark">Transport Service Licence</div>
    <div class="border rounded p-5 mb-5 mt-1 relative">
      <FormulateInput
        name="tsl"
        label="Licences"
        type="general-select"
        element-class="mb-2 mt-1"
        placeholder="Select a TSL type"
        :clearable="false"
        :options="tsls"
        :getOptionKey="(option) => option.tslNumber"
        :getOptionLabel="(option) => option.tslNumber"
        validation="required"
        multiple
      />
      <div v-if="showTlsExtraFields">
        <FormulateInput
          type="date"
          name="passengerEndorsementExpiry"
          label="Passenger Endorsement Expiry"
          validation="required"
        />
        <FormulateInput
          type="date"
          name="driverLicenceRenewalChecked"
          label="Driver Licence Renewal Checked"
          validation="required"
        />
        <FormulateInput
          type="date"
          name="passengerEndorsementRenewalChecked"
          label="Passenger Endorsement Renewal Checked"
          validation="required"
        />
      </div>
    </div>
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>

    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import schema from "./business-profile-create-schema";
import moment from "moment";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Add-Business-Profile-Form",
  props: {
    msg: String,
    isBusinessDriverProfile: {
      type: Boolean,
      default: false,
    },
    preData: null,
  },
  data() {
    this.$emit("schema", schema);
    return {
      showTlsExtraFields: false,
      driverId: null,
      newDriver: {},
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
      tsls: []
    };
  },

  watch: {
    "values.tsl": async function () {
      const hasSpslOrLpsl = this.values.tsl.filter(tsl => tsl.tslType == 'SPSL' || tsl.tslType == 'LPSL');
      this.showTlsExtraFields = hasSpslOrLpsl.length > 0 ? true : false;
    },
  },

  async mounted() {
    const me = this.$store.getters[`account/me`];
    this.tsls = await this.$store.dispatch(
        `tsl/getTSLByEntity`, me?.businessId
    );

    if (!_.isEmpty(this.preData)) {
      if (!_.isEmpty(this.preData["linkDriver"])) {
        this.driverId = this.preData["linkDriver"];
      }
      if (!_.isEmpty(this.preData["newDriver"])) {
        this.newDriver = this.preData["newDriver"];
      }
    }
  },
  methods: {
    async onSubmit() {
      this.errorMessage = "";
      this.loading = true;
      const me = this.$store.getters[`account/me`];
      const entityID = me?.businessId;

      let newContract = JSON.parse(JSON.stringify(this.values));
      delete newContract.tsl;
      delete newContract.passengerEndorsementExpiry;
      delete newContract.driverLicenceRenewalChecked;
      delete newContract.passengerEndorsementRenewalChecked;

      newContract["profile"] = newContract["profile"][0];
      newContract["profile"]["recordKeeperAddress"] =
        newContract["profile"]["recordKeeperAddress"][0][
          "record-keeper-street"
        ];
      newContract["profile"]["baseLocation"] =
      newContract["profile"]["baseLocation"][0]["base-location-street"];

      
      const proxySite = this.$store.getters[`account/proxySite`];
      if (!_.isEmpty(proxySite) && _.isObject(proxySite)) newContract["siteId"] = proxySite._id;
      newContract["businessId"] = entityID;

      if (this.values.tsl && this.values.tsl.length > 0) {
        newContract["tslMetadata"] = {
          licenses: this.values.tsl.map(x => x._id)
        }

        if (this.showTlsExtraFields) {
          newContract["tslMetadata"]["passengerEndorsementExpiry"] = this.values.passengerEndorsementExpiry;
          newContract["tslMetadata"]["driverLicenceRenewalChecked"] = this.values.driverLicenceRenewalChecked;
          newContract["tslMetadata"]["passengerEndorsementRenewalChecked"] = this.values.passengerEndorsementRenewalChecked;
        }
      }

      if (this.driverId) {
        newContract["driverId"] = this.driverId.id;
        this.submitNewContract(newContract);
      } else {
        if (this.newDriver) {
          this.newDriver["entityId"] = entityID;
          this.newDriver["isDemoAccount"] = false;
          if (this.isBusinessDriverProfile) this.newDriver["isBusinessDriverProfile"] = true;

          await this.$store
            .dispatch(`driver/createDriver`, this.newDriver)
            .then(async (driver) => {
              if (driver && driver._id) {
                newContract["driverId"] = driver._id;

                await this.submitNewContract(newContract);
              }
            })
            .catch((error) => {
              this.loading = false;
              this.errorMessage = error?.message;
            });
        }
      }
    },
    async submitNewContract(data) {
      data["from"] = "business";
      data["startDate"] = moment();

      await this.$store
        .dispatch(`business/createContract`, data)
        .then(async () => {
          if (this.isBusinessDriverProfile) {
            console.log("ACCEPTING INVITATION");
            await this.autoAcceptInvitation(data.driverId);
          }

          this.loading = false;
          this.$emit("closeRefresh");
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error?.message;
        });
    },
    async autoAcceptInvitation(driverId) {
      await this.$store.dispatch(`driver/acceptDriver`, driverId);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 2s linear infinite;
}
</style>
