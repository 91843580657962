<template>
    <v-select
        ref="selectRef"
        v-model="model"
        :options="options"
        v-bind="omit(context.attributes, 'class')"
        @search="onSearch"
        @input="onInput"
    >
        <template slot="spinner" :slot-scope="isLoading">
            <ui-spinner class="dropdown-loader text-xs" v-show="isLoading"></ui-spinner>
        </template>
        <template slot="no-options" :slot-scope="isLoading">
            <span class="text-xs" v-if="!isLoading">{{ noResultText }}</span>
            <span class="text-xs" v-if="isLoading">Searching...</span>
        </template>
        <template slot="option" slot-scope="option">
            <slot name="option" v-bind="option"></slot>
        </template>
        <template slot="selected-option" slot-scope="option" class="flex">
            <slot name="selected-option" v-bind="option" class="flex"></slot>
        </template>
        <li slot="list-footer" class="pagination" v-if="!isLoading && (hasPrevPage || hasNextPage)">
            <button :disabled="!hasPrevPage" @click.prevent="onPrevPage" class="rounded-tl rounded-bl" :class="{ disabled: !hasPrevPage }">Prev</button>
            <button :disabled="!hasNextPage" @click.prevent="onNextPage" class="rounded-tr rounded-br" :class="{ disabled: !hasNextPage }">Next</button>
        </li>
    </v-select>
</template>
<script>
import _ from 'lodash';
import vSelect from 'vue-select';

export default {
    components: {
        vSelect,
    },
    props: {
        context: {
            type: Object,
            required: true
        },
        noResultText: {
            type: String,
            default: () => 'No results found.'
        },
        hasPrevPage: {
            type: Boolean,
            default: () => false
        },
        hasNextPage: {
            type: Boolean,
            default: () => false
        },
        isLoading: {
            type: Boolean,
            default: () => false
        },
        closeOnSelect: {
            type: Boolean,
            default: () => false
        },
        options: {
            type: Array,
            required: true,
            default: () => []
        },
    },
    computed: {
        model: {
            get() {
                if (!this.context.model || this.context.model === "") {
                    return null;
                }
                return this.context.model;
            },
            set(val) {
                this.context.model = val;
            },
        }
    },
    methods: {
        dropdownShouldOpen() {
            return true
        },
        omit(obj, keys) {
            let result = {};
            for (const [key, value] of Object.entries(obj)) {
                if (!keys.includes(key)) {
                    result[key] = value;
                }
            }
            return result;
        },
        getOptionLabel(option) {
            return _.isEmpty(this.attributes['optionLabel']) ? 'label' : _.get(option, this.attributes['optionLabel']);
        },
        getOptionKey(option, isForKey = false) {
            if (_.isEmpty(this.attributes['optionKey'])) {
                if (isForKey) {
                    return !_.isEmpty(option['_id']) ? option['_id'] : option['id'];
                }
                return option;
            }
            
            return _.get(option, this.attributes['optionKey']);
        },
        getOptionHtml(option) {
            let optionHtml = this.context.attributes['optionHtml'];
            if (_.isEmpty(optionHtml)) optionHtml = this.getOptionLabel(option);
            else {
                // Replace variables with its value
                const keys = optionHtml.match(/{([^}]+)}/g).map(res => res.replace(/{|}/g , ''));
                keys.forEach(key => {
                    const textToReplace = `{{${key}}}`;
                    const value = _.get(option, key.trim().replace('option.', ''));
                    optionHtml = optionHtml.replace(textToReplace, value);
                });
            }
            return optionHtml;
        },
        onInput(option) {
            this.context.value = option;
            this.context.rootEmit("selected", option);
        },
        onSelect(option) {
            option.isChecked = !option.isChecked;
            if (option.isChecked) this.context.rootEmit("checked", option);
            else this.context.rootEmit("unchecked", option);
        },
        async onSearch(search = '') {
            search = search || this.$refs.selectRef.search;
            this.$emit("search", search);
        },
        onPrevPage() {
            if (this.hasPrevPage) {
                this.$emit("prevPage");
            }
        },
        onNextPage() {
            if (this.hasNextPage) {
                this.$emit("nextPage");
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .v-select::v-deep {
        @apply m-0;

        &:not(.vs--open) .vs__selected-options span ~ .vs__search {
            color: transparent
        }

        .vs__dropdown-menu .vs__dropdown-option {
            @apply px-3
        }
    }
    .dropdown-loader {
        @apply absolute bg-white;
        border-width: 4px;
        height: 20px;
        width: 20px;
        right: 5px;
    }
    .pagination {
        @apply flex text-sm;
        margin: 0.25rem 0.25rem 0;
    }
    .pagination button {
        flex-grow: 1;
        @apply text-gray-900 focus:outline-none bg-white border border-gray-400 hover:bg-gray-100;

        &.disabled {
            @apply text-gray-300 border-gray-200 bg-gray-50 hover:bg-gray-50 cursor-not-allowed
        }
    }
    .pagination button:hover {
        cursor: pointer;
    }
</style>