<template>
  <div>
    <div v-if="loading" class="mx-auto my-24 h-80 w-full translate-y-2/4 flex flex-col items-center">
        <ui-spinner class="mx-auto mb-3"></ui-spinner>
        Loading....
    </div>
    <div v-if="!loading">
      <div class="w-full h-full items-center">

        <div class="mb-5 relative">
          <div class="h-full">
            <button class="absolute ml-32 z-30" @click.prevent="showDriverListFromAttendee">
              <small v-if="!isDriverListOpenFromAttendee" class="btn-primary btn-xs px-3 shadow rounded-lg"> + Select from driver list </small>
              <small v-else class="bg-red-600  text-white btn-xs px-3 shadow rounded-lg"> 
                <font-awesome-icon icon="times"/>&nbsp; Close
              </small>
            </button>

            <div class="relative">
              <div v-if="isDriverListOpenFromAttendee" class="w-full px-3 h-96 pb-3 -mt-6 pt-7 absolute z-30 bg-white shadow-lg">
                <div class="content-center items-center w-full">
                  <div classs="w-full content-center">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                      Search driver
                    </label>
                    <FormulateInput
                      type="driver-select"
                      placeholder="Select a driver"
                      style="margin-bottom: 0px"
                      class="pb-4 content-center"
                      :getOptionKey="option => option.driver.udi"
                      :getOptionLabel="option => option.driver.driverName"
                      :filterable="false"
                      :clearable="false"
                      validation="required"
                      @input="onChange($event, 'attendee')" 
                    />
                    <hr/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <label>Attendees</label>
          <div class="pb-2 border rounded-lg w-full">
            <input 
              class="shadow-sm border border-gray-400 rounded px-3  py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2" 
              v-model="newAttendee" 
              @keydown.enter.prevent="addAttendee"
              placeholder="Add attendee"
              onkeypress="return /[0-9a-zA Z]/i.test(event.key)"
              />

            <ul v-if="newAttendee && leftOverAttendees.length" class="w-full px-3 pb-3 pt-1 absolute z-30 bg-white shadow-lg overflow-y-auto max-h-44">
              <li v-for="(result, i) in leftOverAttendees"
                :key="i" 
                @click="addAttendee(result, true)" 
                class="cursor-pointer py-2 px-4 w-full border-b border-gray-200 dark:border-gray-600">
                {{ result.name }}
              </li>
            </ul>

            <div class="flex items-center flex-wrap px-2 pb-1">
                <span class="py-2 invisible">:</span>
                <small v-for="(data, index) in selectedAttendees" class="bg-green-400 text-white px-4 py-2 my-1 mr-1 rounded-full" :key="index">
                  {{ data.name }}<button @click.prevent="removeAttendee(data)" class="ml-2 mr-1 px-1"><b>x</b></button>
                </small>
            </div>
          </div>
        </div>
        <br/>

        <div class="mb-5 relative">
          <div class="h-full">
            <button class="absolute ml-32" @click.prevent="showDriverListFromInterview">
              <small v-if="!isDriverListOpenFromInterviewer" class="btn-primary btn-xs px-3 shadow rounded-lg"> + Select from driver list </small>
              <small v-else class="bg-red-600  text-white btn-xs px-3 shadow rounded-lg"> 
                <font-awesome-icon icon="times"/>&nbsp; Close
              </small>
            </button>

            <div class="relative">
              <div v-if="isDriverListOpenFromInterviewer" class="w-full px-3 h-96 pb-3 -mt-6 pt-7 absolute bg-white shadow-lg">
                <div class="content-center items-center w-full">
                  <div classs="w-full content-center">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                      Search driver
                    </label>
                    <FormulateInput
                      type="driver-select"
                      placeholder="Select a driver"
                      style="margin-bottom: 0px"
                      class="pb-4 content-center"
                      :getOptionKey="option => option.driver.udi"
                      :getOptionLabel="option => option.driver.driverName"
                      :filterable="false"
                      :clearable="false"
                      validation="required"
                      @input="onChange($event, 'interview')" 
                    />
                    <hr/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <label>Interviewers</label>
          <div class="pb-2 border rounded-lg w-full">
            <input 
              class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2" 
              v-model="newInterviewer" 
              @keydown.enter.prevent="addInterviewer"
              placeholder="Add interviewer"
              onkeypress="return /[0-9a-zA Z]/i.test(event.key)"
              />

            <ul v-if="newInterviewer && leftOverInterviewers.length" class="w-full px-3 pb-3 pt-1 absolute bg-white shadow-lg overflow-y-auto max-h-44">
                <li v-for="(result, i) in leftOverInterviewers"
                  :key="i" @click="addInterviewer(result, true)" 
                  class="cursor-pointer py-2 px-4 w-full border-b border-gray-200 dark:border-gray-600">
                  {{ result.name }}
                </li>
            </ul>

            <div class="flex items-center flex-wrap px-2 pb-1">
                <span class="py-2 invisible">:</span>
                <small v-for="(data, index) in selectedInterviewers" class="bg-green-400 text-white px-4 py-2 my-1 mr-1 rounded-full" :key="index">
                  {{ data.name }}<button @click.prevent="removeInterviewer(data)" class="ml-2 mr-1 px-1"><b>x</b></button>
                </small>
            </div>
          </div>
        </div>

        <FormulateInput
          type="datetime-local"
          name="interviewDate"
          label="Interview Date"
          validation="required"
          min="2018-12-01"
          v-model="formValues.interviewDate"
        />

        <FormulateInput
          type="textarea"
          name="interviewDetails"
          label="Interview Details"
          validation="required"
          placeholder="Write the details here..."
          v-model="formValues.interviewDetails"
          rows="8"
        />

      </div>
    </div>
</div>
</template>
<script>
import _ from 'lodash'

export default {
  name: 'Interview-Form',
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    activeDocForm: {
      default: null,
    }
  },
  data(){
    return {
      loading: true,
      formValues: {},
      businessUsers: [],

      newAttendee: '',
      selectedAttendees: [],
      alreadyAssignedAttendees: [],

      newInterviewer: '',
      selectedInterviewers: [],
      alreadyAssignedInterviewers: [],

      hasDriver: false,
      isDriverListOpenFromAttendee: false,
      isDriverListOpenFromInterviewer: false
    }
  },
  computed: {
    leftOverAttendees(){
      let setArr = [], results = []
      if(this.alreadyAssignedAttendees.length > 0 && this.businessUsers.length > 0){
        let thisUsers = this.alreadyAssignedAttendees

        if(this.alreadyAssignedAttendees.length > 0){
            setArr = this.businessUsers.filter(function (val) {
              for (let i = 0; i < thisUsers.length; i++) {
                  let arr = thisUsers[i];
                  if(val.userId == arr.userId) return false
              }
              return true
            })
        }
        else {
          setArr = this.businessUsers
        }
      }
      else {
        setArr = this.businessUsers
      }

      // Search accurate match query
      if((this.newAttendee) && setArr.length){
        const thisType = this.newAttendee
        results = setArr.filter((item) => thisType.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v)))
      } else {
        results = setArr
      }

      // Sort by name
      if(results.length){
        return results.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        return results
      }
    },
    leftOverInterviewers(){
      let setArr = [], results = []
      if(this.alreadyAssignedInterviewers.length > 0 && this.businessUsers.length > 0){
        let thisUsers = this.alreadyAssignedInterviewers

        if(this.alreadyAssignedInterviewers.length > 0){
            setArr = this.businessUsers.filter(function (val) {
              for (let i = 0; i < thisUsers.length; i++) {
                  let arr = thisUsers[i];
                  if(val.userId == arr.userId) return false
              }
              return true
            })
        }
        else {
          setArr = this.businessUsers
        }
      }
      else {
        setArr = this.businessUsers
      }

      // Search accurate match query
      if((this.newInterviewer) && setArr.length){
        const thisType = this.newInterviewer
        results = setArr.filter((item) => thisType.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v)))
      } else {
        results = setArr
      }

      // Sort by name
      if(results.length){
        return results.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        return results
      }
    },
  },
  async created() {
    const users = await this.$store.dispatch(
        `rolesandrespo/getUsersV2`,
        null
    )
    if(users.length > 0) this.businessUsers = this.formatUsers(users)
    this.setActiveDocForm()
    this.loading = false
  },
  methods: {
    setActiveDocForm(){
      if(this.isEditMode) {
        this.formValues = this.activeDocForm
        this.selectedAttendees = this.activeDocForm.attendees || []
        this.selectedInterviewers = this.activeDocForm.interviewers || []
      }
    },
    addAttendee(data, fromList = false) {
      let name = data.name || this.newAttendee
      let exiting = this.selectedAttendees.filter(item => item.name == name)
      if(_.isEmpty(exiting)){
        if(!fromList){
          if(_.isEmpty(this.newAttendee)) return
          data = {
            userId: null,
            name: this.newAttendee,
            userType: 'stringbase'
          }
        } else {
          data.userType = 'business'
        }
        this.selectedAttendees.push(data)
        this.alreadyAssignedAttendees.push(data)
      } 
      this.newAttendee = ''
      this.formValues.attendees = this.selectedAttendees
    },
    removeAttendee(user) {
      this.selectedAttendees.splice(this.selectedAttendees.indexOf(user), 1)
      this.alreadyAssignedAttendees.splice(this.alreadyAssignedAttendees.indexOf(user), 1)
    },
    addInterviewer(data, fromList = false) {
      let name = data.name || this.newInterviewer
      let exiting = this.selectedInterviewers.filter(item => item.name == name)
      if(_.isEmpty(exiting)){
        if(!fromList){
            if(_.isEmpty(this.newInterviewer)) return
            data = {
              userId: null,
              name: this.newInterviewer
            }
        } else {
          data.userType = 'business'
        }
        this.selectedInterviewers.push(data)
        this.alreadyAssignedInterviewers.push(data)
      } 
      this.newInterviewer = ''
      this.formValues.interviewers = this.selectedInterviewers
    },
    removeInterviewer(user) {
      this.selectedInterviewers.splice(this.selectedInterviewers.indexOf(user), 1)
      this.alreadyAssignedInterviewers.splice(this.alreadyAssignedInterviewers.indexOf(user), 1)
    },
    formatUsers(users) {
      let nItems = [];
      if (users) {
        const mainProfile = {
          userId: users[0]._id,
          name: users[0].userName
        }
        nItems.push(mainProfile);
        users[0].depotLinks.map((user) => {
            if (user["linkageWebProfileId"]) {
              const obj = {
                  userId: user["linkageWebProfileId"]["_id"],
                  name: user["linkageWebProfileId"]["userName"] || "",
              };
              nItems.push(obj);
            }
        })
      } return nItems
    },
    showDriverListFromAttendee(){
      this.isDriverListOpenFromAttendee = !this.isDriverListOpenFromAttendee
    },
    showDriverListFromInterview(){
      this.isDriverListOpenFromInterviewer = !this.isDriverListOpenFromInterviewer
    },
    onChange(data, from){
      if(data){
        data = {
          userId: data.driver._id,
          name: data.driver.driverName,
          userType: 'driver'
        }
      }

      if(from == 'attendee'){
        this.isDriverListOpenFromAttendee = false
        let name = data.name
        let exiting = this.selectedAttendees.filter(item => item.name == name)
        if(_.isEmpty(exiting)){
          this.selectedAttendees.push(data)
          this.alreadyAssignedAttendees.push(data)
        }
      }
      else if(from == 'interview'){
        this.isDriverListOpenFromInterviewer = false
        let name = data.name
        if(!_.isEmpty(this.selectedInterviewers)){
          let exiting = this.selectedInterviewers.filter(item => item.name == name)
          if(_.isEmpty(exiting)){
            this.selectedInterviewers.push(data)
            this.alreadyAssignedInterviewers.push(data)
          }
        }
      }
    },
  }
}
</script>>