<template>
  <div class="w-full min-h-full items-center">
    <div class="font-bold text-lg mb-3 focus-within: text-green-500">
      Findings
    </div>
    <hr>

    <section class="my-6">
      <div class="mb-5">
        <div class="text-xs">Person:</div>
        <ul v-for="(person, index) in activeDocView.persons" :key="index" class="font-bold text-black-primary text-base">
          <li>{{person.name}}&nbsp;</li>
        </ul>
      </div>

      <div class="mb-5">
        <div class="text-xs">Date:</div>
        <div class="font-bold text-black-primary text-base">
          {{cleanDateFormat(activeDocView.findingsDate)}}&nbsp; 
        </div>
      </div>

      <div class="mb-5">
        <div class="text-xs">Details:</div>
        <div class="text-black-primary text-base h-auto">
          {{activeDocView.findingsDetails}}&nbsp; 
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import { cleanDateFormat } from '@/_helper'

export default {
  name: 'Findings-View',
  props: {
    activeDocView: null,
  },
  methods: {
    cleanDateFormat(date){
      return cleanDateFormat(date)
    }
  }
}
</script>