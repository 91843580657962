<template>
  <div>
    <common-header></common-header>
    <PageHeader title="Contact Us"></PageHeader>
    <div class="container py-10">
      <div class="login-container max-w-md mx-auto">
        <div class="rounded-lg bg-gray-100 p-10">
          <ContactForm></ContactForm>
        </div>
      </div>
    </div>
    <common-footer></common-footer>
  </div>
</template>

<script>
import { ContactForm } from  '@/components/forms'
import PageHeader from  '@/components/commons/PageHeader.vue'

export default{
  components:{
    PageHeader,
    ContactForm
  },
  mounted(){
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
