export default {
  // @Role
  setNewCreatedRole(state, value) {
    state.newCreatedRole = value;
  },
  // @Responsibility
  setNewCreatedRespo(state, value) {
    state.newCreatedRespo = value;
  },
};
