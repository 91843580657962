<template>
  <div class="portal-container">
    <div v-if="initializedTheme" class="flex flex-col h-screen">
      <div class="flex flex-1 overflow-hidden bg-gray-100 text-black-primary min-h-screen">
        <aside 
          v-if="!isGeotabAccount && !isLoading"
          :class="[showSidebar? 'relative -translate-x-0' : '-translate-x-full']"
          class="sidebar bg-gray-900 z-20 w-64 space-y-6
            fixed inset-y-0 left-0
            md:relative md:-translate-x-0
            transform transition duration-200 ease-in-out">
          <Sidebar></Sidebar>
        </aside>
        <main class="main flex flex-1 flex-col w-screen min-h-screen md:ml-0 transition-all duration-150 ease-in" :style="{backgroundColor: theme.bodyColor}">
          <div class="flex">
            <Topbar v-if="isGeotabAccount"></Topbar>
          </div>
            <div class="flex">
            <PortalHeader @show-sidebar="toggleSidebar" @fetchedProfile="isLoading = false"></PortalHeader>
          </div>
          <div class="overflow-y-auto main-content-wrapper">
            <div v-if="!isLoading" class="main-content flex flex-1 w-full py-3 px-4 min-h-full">
              <slot></slot>
            </div>
            <div v-else class="main-content flex flex-col justify-center items-center w-full h-screen py-3 px-4 -mt-20">
              <ui-spinner class="mx-auto mb-2"></ui-spinner>
              <p class="font-bold" :style="{color: theme.textColorPrimary}">Loading Page...</p>
            </div>
             <Modal ref="modal" :title="activeModalData.title" size="6xl" :height="activeModalData.height">
              <div v-if="activeModalData.name" @mouseover="hover = true"  @mouseleave="hover = false"  >
                <div class="font-bold mb-2 flex items-center justify-space-between">
                  <span class="mr-2">Portal Logo</span>
                  <div v-if="hover" class="flex items-center">
                    <span v-if="portalLogoURL" @click="toggleUpdateLogo" class="mr-2 cursor-pointer"><RefreshImageIcon :width="'18px'" :height="'18px'" :color="'#3A79A2'"/></span>
                    <span v-if="portalLogoURL" @click="deletePortalLogo" class="cursor-pointer"><font-awesome-icon icon="trash" :style="{color: '#3A79A2'}"/></span>
                  </div>
                </div>
                <div v-if="!portalLogoURL || isUpdateLogo" class="w-1/2" v-click-outside="onClickOutside">

                  <Dropzone :dropzoneOpts="dropzoneOpts" :id="'portalLogo'" @getFile="getFile">
                      <div class="dropzone-custom-content">
                          <h3 class="dropzone-custom-title text-sm" :style="{ color: theme.white}">Drag & drop or <u class="cursor-pointer">click</u> to browse to choose a Web Portal logo</h3>
                      </div>
                  </Dropzone>
                  <button class="upload-button w-full btn btn-primary text-sm text-blue-300" @click="handleSubmit" :disabled="!file || submitLoading" :style="{backgroundColor: theme.primaryButtonBg}">
                    <font-awesome-icon v-if="submitLoading" :icon="['fas', 'spinner']" spin size="lg" class="text-white mr-2"/>
                    
                    <span v-if="!submitLoading">Upload</span>
                    <span v-if="submitLoading">Uploading . . .</span>
                    </button>
                  <div v-if="logoError" class="text-center text-red-700"> <span class="mr-2"><font-awesome-icon icon="exclamation-circle"/></span>{{logoError}}</div>
                </div>
                <div v-if="portalLogoURL && !isUpdateLogo" class="w-1/2 flex flex-col justify-center items-center border-4 border-dashed p-2">
                   <img :src="portalLogoURL" alt="portal-logo-preview" class="w-auto max-h-40">
                </div>
                 <div v-if="portalLogoURL && deletingLoading" class="w-1/2  flex items-center justify-center mt-2">
                    <font-awesome-icon v-if="deletingLoading" :icon="['fas', 'spinner']" spin size="lg" class="text-orange mr-2"/>
                    <span class="text-orange">Deleting file</span>
                </div>
              </div>
            </Modal>
            <!--PortalFooter></PortalFooter-->
          </div>
        </main>
      </div>
    </div>
    <div v-else class="flex w-full h-screen">
      <div class="m-auto">
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from '@/components/commons/ui/Spinner'
import Sidebar from '../commons/sidebar/Sidebar.vue'
import PortalHeader from '../commons/PortalHeader.vue'
import Topbar from '../commons/sidebar/Topbar.vue'
import { Modal } from '@/components/commons'
import Dropzone from '@/components/commons/dropzone/dropzone.vue'
import RefreshImageIcon from '@/components/commons/icons/RefreshIcon.vue'
import {LOGO_FILE_UPLOAD_LIMIT_SIZE, ENABLED_LOGO_UPLOAD_ACCOUNT, GEOTAB_COOKIE} from '@/_helper/constants'
import {isEmpty} from 'lodash'
import VueCookies from 'vue-cookies'

export default {
  name: 'PortalContainer',
  components:{
    Sidebar,
    PortalHeader,
    Modal,
    Dropzone,
    RefreshImageIcon,
    Spinner,
    Topbar
  },
  data() {
    return {
      showSidebar: false,
      isLoading: true,
      dropzoneOpts: {
          url: 'https://httpbin.org/post',
          thumbnailWidth: null,
          thumbnailHeight: null,
          maxFilesize: LOGO_FILE_UPLOAD_LIMIT_SIZE,
          headers: { "My-Awesome-Header": "header value" },
          uploadMultiple: false,
          addRemoveLinks: false,
          maxFiles: 1,
          acceptedFiles: '',
          resizeHeight: '40px'
      },
      logoError: '',
      file: null,
      hover: false,
      isUpdateLogo: false,
      accountDetails: '',
      submitLoading: false,
      deletingLoading: false,

    }
  },
   async mounted(){
    this.isLoading = true;
    this.accountDetails = await this.$store.getters['account/me'];

    if (!this.accountDetails?.updated) {
      this.$emit('dataLoading', 'Loading user data, please wait...');
      await this.$store.dispatch(`account/updateMe`, this.accountDetails);
    }

    const geotabCookie = await VueCookies.get(GEOTAB_COOKIE);
    // TODO: Look for better way to handle this
    // const geotabHostname = window.parent.location.hostname.includes('geotab');
    // const geotabView = !isEmpty(geotabCookie) && geotabHostname;
    const geotabView = !isEmpty(geotabCookie);
    this.$store.commit('account/setIsGeotabAccount', geotabView);
    
    // Manual creation of settings per account if not found
    if (isEmpty(this.accountDetails.themeOptions)) {
      await this.setThemeData(this.accountDetails.themeOptions);
    } else {
      const themeId = this.accountDetails.userSettings?.isDarkMode ? this.accountDetails.userSettings?.data?.darkModeThemeId : this.accountDetails.userSettings?.themeId;
      const activeTheme = this.accountDetails.themeOptions.find((theme) => theme?._id === themeId);

      if (activeTheme) await this.updateThemeData({accountId: this.accountDetails.uid, themeData: activeTheme}, activeTheme, true)
      else await this.setThemeData(this.accountDetails.themeOptions);
      
      
      const existingActiveTheme = JSON.parse(localStorage.getItem('activeTheme'));
      if (isEmpty(existingActiveTheme) && 
        !existingActiveTheme?.themeId && 
        this.accountDetailsuserSettings?.themeId && 
        existingActiveTheme?.themeId !== this.accountDetailsuserSettings?.themeId
      ) {
        localStorage.setItem('activeTheme', JSON.stringify(activeTheme))
      }
    }

    // Restrict usage of upload logo in a certain account
    if(this.accountDetails && ENABLED_LOGO_UPLOAD_ACCOUNT.includes(this.accountDetails.role.name)){
      this.$store.dispatch('settings/getLogoById', this.accountDetails.uid)
    }

    this.isLoading = false;
    this.$emit('dataLoading', null);
  },
  methods: {
    async setThemeData(themeOptions) {
      // Set the initial value of themeData to settings
      const createThemeData = await this.$store.dispatch('settings/createSettings', {
        accountId: this.accountDetails.uid,
        themeId: themeOptions.find((theme) => theme.isDefault)?._id,
        darkModeThemeId: themeOptions.find((theme) => theme.isDarkMode)?._id,
        isDarkMode: false
      })

      if (createThemeData.success) {
        const activeTheme = themeOptions.find((theme) => theme._id === createThemeData?.data?.themeId)
         localStorage.setItem('activeTheme', JSON.stringify(activeTheme))
         await this.$store.commit('theme/updateActiveTheme', activeTheme)
         await this.$store.commit('theme/setInitializedTheme', true)
         await this.$store.commit('theme/updateTheme', activeTheme?.themeData)
         await this.$store.commit('theme/updateIsDarkMode', activeTheme?.isDarkMode)
      }
    },
    toggleSidebar(value) {
      this.showSidebar = value
    },
    toggleUpdateLogo(){
      this.logoError = ''
      this.isUpdateLogo = !this.isUpdateLogo
    },
     getFile(file){
      this.file = file
    },
    async handleSubmit(){
      this.submitLoading = true
      if((this.file.size / (1024*1024) > LOGO_FILE_UPLOAD_LIMIT_SIZE)) {
        this.submitLoading = false
        this.logoError = 'File size exceed limit! Please choose another file'
        return
      }
  
      const fileUploadPayload = {
        accountId: this.accountDetails.uid,
        dataURL: this.file.dataURL,
        logoType: 'portal-logo',
        name: this.file.name,
        type: this.file.type,
      }
      
      await this.$store.dispatch('settings/uploadLogo', {...fileUploadPayload})
        .then((res) => {
              if(res.success){
                this.isUpdateLogo = false
                this.submitLoading = false
                this.$store.commit('setDialogNotify', true)
                this.$store.commit('setMessageNotify', { state:'success', message: res.message })
                this.$store.commit('settings/setPortalLogoURL', res.portalLogo.url)
              }
        })
        .catch((err) => {
           if([413].includes(err.stateCode)){
              this.logoError = err.message
            }
            this.submitLoading = false
        })
    },
    async deletePortalLogo(){
        this.deletingLoading = true
        this.$store.dispatch('settings/deleteLogoById', {accountId: this.accountDetails.uid, type: 'portal-logo'})
          .then((res) => {
            if(res.success){
              this.$store.commit('settings/setPortalLogoURL', res.portalLogo || null)
              this.isUpdateLogo = true
              this.deletingLoading = false
              this.$store.commit('setDialogNotify', true)
              this.$store.commit('setMessageNotify', { state:'success', message: res.message })
            }
          }).catch((err) => {
            this.logoError = err.message
            this.deletingLoading = false
       })
    },
    onClickOutside(){
      this.logoError = ''
    },
    async updateThemeData(activeTheme, colorSchemeData, reInitializedTheme){
      await this.$store.dispatch('theme/updateTheme', activeTheme)
      await this.$store.commit('theme/updateActiveTheme', colorSchemeData)
      await this.$store.commit('theme/setInitializedTheme', reInitializedTheme)
    }
  },
  computed: {
    activeModalData(){
      return this.$store.getters['modal/getActiveModalData']
    },
    theme(){
      return this.$store.getters['theme/getColorScheme']
    },
     portalLogoURL(){
      return this.$store.getters['settings/getPortalLogoURL']
    },
    activeStateTheme(){
      return this.$store.getters['theme/getActiveTheme']
    },
    themeOptions(){
      return this.$store.getters['theme/getThemeLists']
    },
    themeId(){
      return this.$store.getters['settings/getThemeId']
    },
    initializedTheme(){
      return this.$store.getters['theme/getIsInitializedTheme']
    },
    isGeotabAccount(){
      return this.$store.getters['account/getIsGeotabAccount']
    },
  },
  watch: {
    activeModalData(){
      this.$refs.modal.openModal()
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  .sidebar { min-width: 16rem; }
  .main {
    width: calc(100vw - 16rem) !important;
  }
  .footer {
    width: calc(100vw - 16rem) !important;
    position: absolute;
    bottom: 0;
  }

  .main-content-wrapper {
    min-height: calc(100% - 64px);
  }

    #portalLogo {
    background-color: #2873A5;
    font-family: 'Arial', sans-serif;
    letter-spacing: 0.2px;
    color: #777;
    transition: background-color .2s linear;
    padding: 40px;
    position: relative;
    display: flex;
    min-height: 200px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .dz-preview {
      width: 160px;
      display: inline-block;
      margin: 0 16px;
    }

    .dz-preview .dz-image {
      width: 80px;
      height: 80px;
      margin-left: 40px;
      margin-bottom: 10px;
    }

    .dz-preview .dz-image > div {
      width: inherit;
      height: inherit;
      background-size: contain;
      background-repeat: no-repeat;
      background-position-x: center;
      background-position-y: center;
    }
    .dz-preview .dz-image > img {
      width: 100%;
    }
    .dz-preview .dz-details {
      color: white;
      transition: opacity .2s linear;
      text-align: center;
      font-size: 14px;
    }
    .dz-success-mark, .dz-error-mark {
      display: none;
    }
    .dropzone-custom-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
    
  }
  .dz-started > .dz-message{
      display: none;
  }

  .dropzone-remove{
    color: #FFFFFF;
    #dropzone-remove{
      height: 14px;
      width: 14px;
      margin-right: 5px;
      path{
        fill: #FFFFFF;
      }
    }
  }
  .dz-progress, 
  .dz-error-message, 
  .dz-success-mark,
  .dz-error-mark{
    font-size: 14px;
    text-align: center;
    color: #FFFFFF;
  }

  .upload-button{
       border-radius: 0!important; 
       border-bottom-left-radius: 5px!important;
       border-bottom-right-radius: 5px!important;
       width: 100%;
    }

</style>
