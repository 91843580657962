<template>
    <div>
      <common-header></common-header>
      <PageHeader title="Welcome to Logmaster Portal Page"></PageHeader>
      <div class="container py-10">
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="flex px-3">
              <img src="assets/images/safety-papers.jpeg" alt="" class="mx-auto md:ml-auto md:mr-5 my-auto">
            </div>
            <div class="flex px-3">
              <div class="my-auto">
                <h2 class="text-xl mb-2"><b>Record Keeper Signup</b></h2>
                <p><b>Record keepers</b> must keep a record of specific information for drivers of fatigue regulated heavy vehicles. A record keeper may be the: employer, if the driver is employed. accredited operator, if the driver is working under Basic Fatigue Management or Advanced Fatigue Management accreditation.</p>
                <p><b>Record keepers are responsible for:</b></p>
                <ul>
                  <li>Receiving and storing EWD information for drivers</li>
                  <li>Maintaining EWDs in proper working order including notifying the NHVR of any issues</li>
                  <li>Providing reports of EWD information to drivers and authorised officers as required by the HVNL</li>
                  <li>Cooperating with the NHVR and jurisdictional authorities with investigations relating to EWD operational activities.</li>
                </ul>
              </div>
            </div>
          </div>
      </div>
      <common-footer></common-footer>
    </div>
</template>

<script>
import PageHeader from  '@/components/commons/PageHeader.vue'

export default{
  components:{
    PageHeader
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
p{
  @apply mb-2
}
ul{
  @apply ml-5 list-disc
}
</style>
