<template>
    <div>
      <div class="flex w-full" v-if="loading">
        <div class="m-auto">
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300">Loading...</span>
        </div>
      </div>
       <div v-if="passwordCreated">
        <h1 class="text-2xl text-green-600 text-center font-bold">Welcome to Logmaster Australia, your account has been created.</h1>
        <div class="text-sm text-center">Redirecting...</div>
      </div>
      <div v-if="!passwordCreated">
      <FormulateForm v-model="driverPasswordForm" @submit="onSubmit" v-if="!loading">
      <div class="mb-5 font-italic text-sm">*Please set your preferred driver account password here:</div>
      <FormulateInput
        type="password"
        name="password"
        label="Password"
        validation="^required"
        :validation-messages="{matches: 'Password must contain at least 1 number.'}"
      />
      <FormulateInput
        type="password"
        name="confirmPassword"
        label="Confirm Password"
        validation="^required"
        :validation-messages="{matches: 'Password must contain at least 1 number.'}"
      />
      <div class="flex items-center justify-between w-full">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          class="w-full"
        />
      </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
      </div>
    </div>
</template>

<script>
import Spinner from '@/components/commons/ui/Spinner'

    export default{
        name: 'Business-Accept-Form',
        components: { Spinner },
        data() {
          return {
            driverPasswordForm: {},
            loading: true,
            errorMessage: '',
            isValidDriver: false,
            passwordCreated: false,
            driver: {}
          }
        },
        // props: {
        //   driverId: null
        // },
        async mounted(){
          this.loading = true
          if(!this.driverId){
            this.driverId = this.$route.params['acceptid'];
            this.driverId = this.driverId ? this.driverId:this.$route.params['id'];
          }
          if(this.driverId){
              await this.$store.dispatch('driver/getDriver', {id: this.driverId})
              .then(driver => {
                if(driver){
                  if(driver.uid) window.location.href = '/login?message=Request is already accepted&state=error'
                  this.loading = false
                  this.isValidDriver = true
                  this.driver = driver;
                }else{
                  this.loading = false
                  this.errorMessage = 'No drivers account found!'
                  window.location.href = '/login?message=Driver not found&state=error'
                }
              }).catch(error => {
                  this.loading = false
                  this.errorMessage = error?.message
                  window.location.href = '/login?message=Invalid request&state=error'
              })
          }
        },
        methods: {
          async onSubmit(){
            this.loading = true;
            const driverPasswordData = JSON.parse(JSON.stringify(this.driverPasswordForm))
            if(driverPasswordData && this.isValidDriver){
               await this.$store.dispatch('driver/createDriverPassword', {id: this.driverId, password: driverPasswordData})
               .then(driver => {
                 if(driver){
                      try{
                        const acceptDriver = this.$store.dispatch('driver/acceptDriver', this.driverId)
                        this.loading = false
                        if(acceptDriver){
                          this.passwordCreated = true
                          this.loginDriver(driverPasswordData);
                        }else{
                          this.errorMessage = 'Driver has accepted the invitation'
                          window.location.href = '/login?message=Request already accepted&state=error'
                        }
                        }
                        catch(error){
                           this.loading = false
                            this.errorMessage = 'Something went wrong'
                            window.location.href = '/login?message=Something went wrong&state=error'
                        }
                  }
                }).catch(error => {
                    this.loading = false
                    this.errorMessage = error?.message
                    window.location.href = '/'
              })
            }
          },

          async loginDriver(driverPasswordData) {
            console.log("test")
            try {
              const login = await this.$store.dispatch(`account/login`, {
                usernameEmail: this.driver.emailAddress,
                password: driverPasswordData.password
              })
              if(login && login._id){
                let redirect = '/';
                //if(login['role']['name'] == 'companyOwner') login['role']['name'] = BUSINESS_ROLE.name; // Temp to rename the companyOwner as business
                if(login['role']) redirect = this.$store.getters['allURL'][login['role']['name']]['default'];
                window.location.href = redirect
              }
            } catch(error) {
              this.error = true
              this.errorMessage = error.message
              this.loading = false
            }
          }
        }
    }
</script>