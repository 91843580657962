<template>
  <div class="mb-16">
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!isLoading">
      <div class="text-center mb-10">
        <div class="font-bold text-black-primary text-2xl">
          {{ document.ncrNumber }}
        </div>
        <div class="text-s">NCR Number</div>
        <div class="font-bold text-s text-green-500">ACCEPTED</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ formattedDate(document.ncrDate) }}
        </div>
        <div class="text-s">NCR Date</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ document.description }}
        </div>
        <div class="text-s">Description</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ document.raisedBy.userName }}
        </div>
        <div class="text-s">Raised By</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ document.auditee.userName }}
        </div>
        <div class="text-s">Raised Against</div>
      </div>

      <div class="mb-5">
        <div class="text-red-500 text-center mt-3" v-if="error">
          {{ errorMessage }}
        </div>
        <div class="flex justify-center">
          <div class="mx-5">
            <div class="font-bold text-black-primary text-base">Start Date</div>
            <div class="text-s">{{ startDate }}</div>
          </div>
          <div class="mx-5">
            <div class="font-bold text-black-primary text-base">End Date</div>
            <div class="text-s">{{ endDate }}</div>
          </div>
          <div class="mx-5">
            <div class="font-bold text-black-primary text-base">
              Check Up Date
            </div>
            <div class="text-s">{{ nextCheckUpDate }}</div>
          </div>
        </div>
        <FormulateForm
          v-model="payload"
          @submit="onSubmit"
          #default="{ hasErrors }"
        >
          <FormulateInput
            class="text-s"
            type="number"
            label="Duration (Months)"
            name="duration"
            min="1"
            @change="onChangeDuration($event)"
          />
          <FormulateInput
            class="text-s"
            type="select"
            label="Periodic Check"
            name="periodicCheck"
            :options="periodicCheckOptions"
            @change="onChangePeriodicCheckup($event)"
          />
          <FormulateInput
            class="text-s"
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || isLoading"
          />
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { PERIODIC_CHECKS } from "../../_helper/constants";

export default {
  name: "performance-management-details",
  components: {},

  props: {
    propsDocument: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    propsDocument: {
      deep: true,
      handler() {
        this.setActiveDocument();
      },
    },
  },
  created() {
    this.isLoading = true;
    this.setActiveDocument();
    this.startDate = moment().format("YYYY-MM-DD");
    this.endDate = moment()
      .add(this.payload.duration, "months")
      .format("YYYY-MM-DD");

    const periodicCheckInDays = this.getPeriodicCheckUpInDays("DAILY");
    this.nextCheckUpDate = moment()
      .add(periodicCheckInDays, "days")
      .format("YYYY-MM-DD");
    setTimeout(() => (this.isLoading = false), 300);
  },

  data() {
    return {
      isLoading: false,
      payload: { duration: 1 },
      error: false,
      errorMessage: "",
      document: "",
      periodicCheckOptions: PERIODIC_CHECKS,
      startDate: null,
      endDate: null,
      nextCheckUpDate: null,
    };
  },

  methods: {
    formattedDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    async onSubmit() {
      try {
        const payload = {
          nonConformanceId: this.document._id,
          durationInMonths: this.payload.duration,
          periodicCheck: this.payload.periodicCheck,
        };
        await this.$store.dispatch("performance/createPerformance", payload);
        this.$emit("closeModal");
        this.$emit("toast", "success", "Performance successfully created.");
        setTimeout(() => {
          location.reload();
        }, 2000);
      } catch (e) {
        this.error = true;
        this.errorMessage = "Something went wrong!";
      }
    },

    setActiveDocument() {
      this.document = this.propsDocument;
    },
    onChangeDuration(event) {
      const duration = event.target.value;
      this.endDate = moment().add(duration, "months").format("YYYY-MM-DD");
    },
    onChangePeriodicCheckup(event) {
      const periodicCheck = event.target.value;
      const result = this.getPeriodicCheckUpInDays(periodicCheck);
      this.nextCheckUpDate = moment().add(result, "days").format("YYYY-MM-DD");
    },
    getPeriodicCheckUpInDays(periodicCheck) {
      let periodicCheckInDays = 0;
      switch (periodicCheck) {
        case "DAILY":
          periodicCheckInDays = 1;
          break;
        case "WEEKLY":
          periodicCheckInDays = 7;
          break;
        case "FORTNIGHTLY":
          periodicCheckInDays = 14;
          break;
        case "MONTHLY":
          periodicCheckInDays = 30;
          break;
      }
      return periodicCheckInDays;
    },
  },
};
</script>

<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
