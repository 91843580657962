<template>
  <div>
    <div>
      <Tabs>
        <Tab title="Pre Start">
          <PreStart></PreStart>
        </Tab>
        <Tab title="Fitness Declaration">
          <FitnessDeclarations></FitnessDeclarations>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>


<script>
import Tabs from "@/components/commons/tab/Tabs.vue";
import Tab from "@/components/commons/tab/Tab.vue";
import FitnessDeclarations from '@/pages/business/FitnessDeclarations';
import PreStart from '@/pages/business/PreStart';
export default {
  name: "ReportDaily",
  components: { FitnessDeclarations, PreStart, Tab, Tabs },
}
</script>