<template>
  <div class="h-auto shadow p-6">
    <FormulateForm
      name="changeEmail"
      v-model="changeEmailForm"
      @submit="onSubmit"
    >
      <FormulateInput
        type="email"
        name="newEmailAddress"
        label="New Email Address"
        validation="^required"
        autocomplete="off"
      />
      <hr />
      <br />
      <FormulateInput
        type="password"
        name="password"
        label="Password"
        validation="required"
        error-behavior="submit"
        help="To save this setting, please enter your password."
        autocomplete="off"
      />
      <div class="items-center mt-7">
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="loading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" />
            Submitting...</span
          >
          <span v-if="!loading">Submit</span>
        </FormulateInput>
        <button
          @click.prevent="$router.go(-1)"
          class="text-center block btn btn-cancel w-full mb-2"
        >
          Cancel
        </button>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ChangePassword",
  data() {
    return {
      changeEmailForm: {},
      userId: "",
      uid: "",
      errorMessage: "",
      successMessage: "",
      loading: false,
    };
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];
    this.userId = me._id;
    this.uid = me.uid;
  },
  methods: {
    async onSubmit() {
      if (!this.uid)
        this.errorMessage = `Something went wrong please try again.`;
      this.loading = true;
      const payLoad = {
        uid: this.uid,
        newEmailAddress: this.changeEmailForm.newEmailAddress,
        password: this.changeEmailForm.password,
      };
      try {
        let res = await this.$store.dispatch(`account/changeEmail`, payLoad);
        if (res.success) {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message:
              "Change email request is successfully sent to your new email!",
          });
          this.$formulate.reset("changeEmail");
          this.loading = false;
          window.location.href = "/logout";
        }
      } catch (error) {
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "error",
          message: error.message,
        });
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
