var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"border-2 rounded-lg p-2 justify-center mb-6"},[_c('FormulateInput',{attrs:{"type":"select","label":"Left Content","options":[
        { value: '{{driverName}}', label: 'Driver Name' },
        { value: '{{inductionDate}}', label: 'Induction Date' },
        { value: '{{accreditationNumber}}', label: 'Accreditation Number' },
        // { value: '{{todayDate}}', label: 'Today(Date)' },
        { value: '{{adminNumber}}', label: 'Admin Number' },
      ]},model:{value:(_vm.leftContent.value),callback:function ($$v) {_vm.$set(_vm.leftContent, "value", $$v)},expression:"leftContent.value"}})],1),_c('div',{staticClass:"border-2 rounded-lg p-2 justify-center mb-6"},[_c('FormulateInput',{attrs:{"type":"select","label":"Right Content","options":[
        { value: '{{driverName}}', label: 'Driver Name' },
        { value: '{{inductionDate}}', label: 'Induction Date' },
        { value: '{{accreditationNumber}}', label: 'Accreditation Number' },
        // { value: '{{todayDate}}', label: 'Today(Date)' },
        { value: '{{adminNumber}}', label: 'Admin Number' },
      ]},model:{value:(_vm.rightContent.value),callback:function ($$v) {_vm.$set(_vm.rightContent, "value", $$v)},expression:"rightContent.value"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }