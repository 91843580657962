<template>
  <div>
    <div
      class="text-red-500 font-bold text-sm text-center mt-0 my-5"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </div>
    <FormulateForm
      name="resetpassword"
      v-model="forgotPasswordForm"
      @submit="onSubmit"
    >
      <FormulateInput
        type="password"
        name="newPassword"
        label="New Password"
        validation="^required"
      />
      <FormulateInput
        type="password"
        name="newPassword_confirm"
        label="Confirm New Password"
        validation="required|confirm"
        error-behavior="submit"
      />
      <div>
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="loading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" />
            Submitting...</span
          >
          <span v-if="!loading">Submit</span>
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ForgotPasswordForm",
  props: {
    msg: String,
  },
  data() {
    return {
      forgotPasswordForm: {},
      error: false,
      errorMessage: "",
      token: "",
      loading: false,
    };
  },
  mounted() {
    this.token = this.$route.params.token;
    if (!this.token) window.location.href = "/404?message=Invalid request";
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const payLoad = {
        token: this.token,
        password: this.forgotPasswordForm.newPassword,
        confirm: this.forgotPasswordForm.newPassword_confirm,
      };
      await this.$store
        .dispatch(`account/changeEmailSetPassword`, payLoad)
        .then((resetPassword) => {
          if (resetPassword && resetPassword.success) {
            window.location.href =
              "/login?message=Password and email are already updated.";
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = "Invalid token or password please try again.";
          this.$formulate.reset("resetpassword");
        });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
