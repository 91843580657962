<template>
  <Table
    :isLoading="isLoading"
    :tableHeader="tableHeader"
    :paginationSettings="paginationSettings"
    :data="tableData"
    @onPageChange="onPageChange($event)"
  >
    <template #default="{ data }">
      <td class="px-5 py-2">
        <div class="text-black-primary whitespace-no-wrap text-lg flex">
          <StyledIconedLink
            class="flex cursor-pointer mx-1.5"
            :href="data.document"
            :iconHoverColor="theme.secondaryColor"
            ><font-awesome-icon icon="download" class="my-auto"
          /></StyledIconedLink>
        </div>
      </td>
    </template>
  </Table>
</template>

<script>
import { Table } from "@/components/commons";
import { formatPaginationSettings } from "@/_helper/";
import moment from "moment";

import { TARGET_MOMENT_TIMEZONE } from "../_helper/index";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Invoices",
  components: { Table, StyledIconedLink },

  data() {
    return {
      filter: {
        limit: 10,
      },

      invoices: [],
      tableHeader: ["ID", "Invoice Date", "Generated At"],
      isLoading: false,

      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
    };
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init(paged = 1) {
      this.isLoading = true;
      const me = await this.$store.getters[`account/me`];

      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      const invoices = await this.$store.dispatch(
        `billinginvoice/getAllInvoice`,
        { pagination: query, userMongoId: me.entity._id.toString() }
      );

      if (invoices?.data?.data) {
        this.invoices = invoices.data.data;
      }

      if (invoices?.data?.resultsMeta) {
        this.paginationSettings = formatPaginationSettings(
          invoices.data.resultsMeta
        );
      }

      this.isLoading = false;
    },

    parseData(data) {
      return data.map((invoiceData) => {
        return [
          {
            id: invoiceData._id,
            name: invoiceData._id,
            itemType: "string",
            document: invoiceData.document,
          },
          {
            id: invoiceData._id,
            name: invoiceData.invoiceMonth
              ? invoiceData.invoiceMonth
              : this.formatTimeYearMonth(invoiceData.createdAt),
            itemType: "string",
            document: invoiceData.document,
          },
          {
            id: invoiceData._id,
            name: this.formatTimeZone(invoiceData.createdAt),
            itemType: "string",
            document: invoiceData.document,
          },
        ];
      });
    },

    formatTimeYearMonth(data) {
      return moment(data).format("YYYY MMMM");
    },

    formatTimeZone(data) {
      return moment(data)
        .tz(TARGET_MOMENT_TIMEZONE)
        .format("YYYY-MM-DD hh:mm A");
    },

    async onPageChange(event) {
      await this.refetchData(event.page);
    },
  },

  computed: {
    tableData() {
      if (this.invoices) {
        return this.parseData(this.invoices);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style></style>
