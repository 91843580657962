<template>
    <div :data-type="context.type"
        :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`">
        <VueGoogleAutocomplete 
            :id="context.id" 
            classname="form-control shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mt-1 mb-2" 
            placeholder="" 
            autocomplete="none"
            :google="google"
            v-on:placechanged="getAddressData"
            :value="context.model"  
            @inputChange="handleInputChange($event)"
            >
        </VueGoogleAutocomplete>
     
    </div>  
</template>

<script>
import Vue from 'vue';
import VueGoogleAutocomplete from "@logmaster/logmaster-google-autocomplete";

const PlacesSearchInput = {
   name: 'Places-Search-Input',
   components:{VueGoogleAutocomplete},
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    data() {
      return {
        google: Vue.prototype.$google
      }
    },
    methods: {
       getAddressData(placeResultData) {
        this.context.model = `${placeResultData.route}, ${placeResultData.locality} ${placeResultData.administrative_area_level_1}, ${placeResultData.country}`
      },
      handleInputChange({newVal}){
        this.context.model  = newVal 
      }
    },
}


export const VueFormulatePlacesSearch = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      PlacesSearchInput,
    },
    library: {
      "places-search-input": {
        classification: "text",
        component: "PlacesSearchInput",
      },
    },
  });
}

export default PlacesSearchInput

</script>

<style lang="scss">
  .pac-container:after {
    background-image: none !important;
    height: 0px;
}
</style>
