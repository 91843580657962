<template>
  <div>
    <div
      class="flex mt-2 flex-col border rounded-lg pt-2 cursor-pointer py-2 w-full relative"
      @click="toggleColorSchemeSelect"
      v-click-outside="onClickColorSchemeOutside"
    >
      <div class="text-xs text-left mb-2 mx-3">{{activeColor?.themeName}}</div>
      <div class="flex mx-3">
        <StyledMiniBox
          v-for="(pallete, index) in activeColor?.themeColors"
          :key="index"
          :style="{backgroundColor: pallete}"
        />
      </div>

      <div
        v-if="colorSchemeToggle"
        class="transition-all duration-300 transform w-full"
      >
        <!-- <DashboardSettings @toggleSetting="closeDashboardSettings"/> -->
        <div class="shadowed absolute w-full right-0 mt-3 bg-white">
          <div
            v-for="(option, index) in options"
            :key="index"
            class="flex items-center py-2"
            @click="onSelectColor($event, option._id)"
          >
            <div class="text-xs text-left mx-3">{{option.themeName}}</div>
            <div class="flex mx-3 ml-auto">
              <StyledMiniBox
                v-for="(pallete, index) in option.themeColors"
                :key="index"
                :style="{backgroundColor: pallete}"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import styled from "vue-styled-components";

  const styledProps = {
    color: String,
    backgroundColor: String,
  };

  const StyledMiniBox = styled("div", styledProps)`
    width: 10px;
    height: 10px;
    color: ${({ color }) => color};
    background-color: ${({ backgroundColor }) => backgroundColor};
    margin-right: 5px;
  `;

  export default {
    name: "Color-Select",
    components: { StyledMiniBox },
    data() {
      return {
        colorSchemeToggle: false,
        activeColor: {},
      };
    },
    props: {
      options: {
        type: Array,
        default: null,
      },
      value: {
        type: Object,
        default: null,
      },
    },
    mounted() {
      this.activeColor = this.value;
    },
    methods: {
      onClickColorSchemeOutside() {
        this.colorSchemeToggle = false;
      },
      toggleColorSchemeSelect() {
        this.colorSchemeToggle = !this.colorSchemeToggle;
      },
      onSelectColor(e, colorId) {
        e.stopPropagation();
        this.colorSchemeToggle = false;
        this.activeColorOptions = colorId;
        this.selectActiveColor(colorId);
      },
      selectActiveColor(colorId) {
        this.activeColor = this.options.find((color) => color._id === colorId);
        this.$emit("onChange", this.activeColor)
      },
    },
  };
</script>
