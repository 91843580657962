<template>
  <div class="mb-16">
    <FormulateForm
      v-model="values"
      @submit="onSubmit"
      #default="{ hasErrors }"
    >
      <FormulateInput
        type="select"
        name="paymentPlatform"
        label="Payment Platform"
        :options="{ 0: ' - Select - ', 'stripe': 'Stripe' }"
        validation="required"
      />
      <FormulateInput
        type="text"
        name="apiKey"
        label="API Key"
        validation="required"
      />
      <FormulateInput
        type="text"
        name="apiSecret"
        label="API Secret"
        validation="required"
      />
      <div class="grid grid-cols-2 gap-4">
        <div>
          <div class="font-medium text-sm mb-1">Surcharge Fee (%)</div>
          <div class="relative flex">
            <div class="formulate-input-icon">%</div>
            <FormulateInput
              type="text"
              name="surchargePercentageFee"
              placeholder="0.00"
              :outer-class="['w-full']"
              :wrapper-class="['flex flex-col']"
              :input-class="['pl-16']"
            />
          </div>
        </div>
        <div>
          <div class="font-medium text-sm mb-1">Other Fees</div>
          <div class="relative flex">
            <div class="formulate-input-icon">$</div>
            <FormulateInput
              type="text"
              name="otherFees"
              placeholder="0.00"
              :outer-class="['w-full']"
              :wrapper-class="['flex flex-col']"
              :input-class="['pl-16']"
            />
          </div>
        </div>
      </div>
      <div class="custom-options">
        <FormulateInput
          type="radio"
          name="default"
          label="Set as default payment?"
          :options="{ 'true': 'Yes', 'false': 'No' }"
          value="false"
          validation="required"
        />
      </div>
      <div class="custom-options">
        <FormulateInput
          type="radio"
          name="status"
          label="Set as active?"
          :options="{ 'true': 'Yes', 'false': 'No' }"
          value="true"
          validation="required"
        />
      </div>
      
      <FormulateInput
        type="submit"
        label="Submit"
        input-class="btn btn-primary w-full"
        :disabled="hasErrors || loading"
        :style="{backgroundColor: theme.secondaryColor}"
      >
        <span v-if="loading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving data...</span>  
        <span v-if="!loading">Submit</span>  
      </FormulateInput>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "New-Payment-Gateway-Form",
  props: {
    msg: String,
  },
  data() {
    return {
      error: false,
      errorMessage: "",
      loading: false,
      values: {
        surchargePercentageFee: 2.9,
        otherFees: 0.30
      }
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      try {
        const me = this.$store.getters[`account/me`];
        await this.$store.dispatch("payment/createPaymentGateway", {
          entityId: me?.entity?._id,
          entityRole: me.role?.name,
          apiKey: this.values.apiKey,
          apiSecret: this.values.apiSecret,
          platform: this.values.paymentPlatform,
          surchargePercentageFee: this.values.surchargePercentageFee,
          otherFees: this.values.otherFees,
          isDefault: this.values.default === "true" ? true : false,
          isActive: this.values.status === "true" ? true : false,
        });
        this.loading = false;
        this.$emit('closeRefresh');
      } catch (error) {
        this.loading = false;
        this.errorMessage = error.message;
      }
    },
  },
  computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
  },
};
</script>

<style lang="scss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>
