<template>
  <div>
    <FormulateInput
      v-if="isDisplayValue"
      type="switch"
      :id="control.id"
      :name="control.name"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
      @input="isChecked"
      v-model="control.value"
    />
    <FormulateInput
      v-else
      type="switch"
      :id="control.id"
      :name="control.name"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
      @input="isChecked"
      v-model="control.value"
    />
  </div>
</template>

<script>
export default {
  props: {
    control: {
      type: Object,
      default: () => {}
    },
    isDisplayValue: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    isChecked() {
      console.log('test')
    },
  }
}
</script>
