<template>
  <div>
    <div v-if="breach">
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{formatTime(breach.time, breach.timezone)}}</div>
          <div class="text-xs">Time of Breach</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{breach.breachTime || '-'}}</div>
          <div class="text-xs">Breach Time</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{breach.period}}</div>
          <div class="text-xs">Period</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{breach.option}}</div>
          <div class="text-xs">Option</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{breach.type}}</div>
          <div class="text-xs">Type</div>
      </div>
      <div class="mb-2">
          <div class="font-bold text-black-primary text-base">{{breach.location || '-'}}</div>
          <div class="text-xs">Location</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{ breach.startPoint ? formatTime(breach.startPoint, breach.timezone) : '-' }}</div>
          <div class="text-xs">Counting Point Start</div>
      </div>
      <br>
  </div>
</div>
</template>
<script>
import _ from 'lodash'
import moment from 'moment'
  export default {
    name: "Breach-Details-View",
    props: {
      activeBreach: {
        type: Object,
      },
    },
    watch: {
      activeBreach: {
        deep: true,
        handler() {
          this.setActivePreStartCheckData()
        }
      }
    },
    created() {
      this.setActivePreStartCheckData()
    },
    data() {
      return {
        breach: null,
        driverData: null
      };
    },
    methods: {
      setActivePreStartCheckData(){
        this.breach = this.activeBreach
        this.driverData = this.activeBreach.driverData
      },
      cleanLevel(level){
        if (!level) return 'N/A';
        return _.startCase(level == 'no-breach' ? level.replace('-', ' ') : level.split('-')[0]);
      },
      formatTime(time, timezone){
        return moment(time).utcOffset(timezone).format('YYYY-MM-DD HH:mm:ssZ')
      },
    },
  }
</script>