<template>
  <div>
    <div class="h-full flex flex-col space-y-4">
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="driverStatistics"
        @onPageChange="onPageChange($event)"
      >
        <template #tableFilters>
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.status"
                @change="filterChange"
                class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
              >
                <option
                  v-for="(filterStatus, key) in filterStatuses"
                  :key="key"
                  :value="key"
                >
                  {{ filterStatus }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.sortingField"
                @change="sortBy"
                class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
              >
                <option
                  v-for="(field, key) in sortingFields"
                  :key="key"
                  :value="key"
                >
                  {{ field }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400" />
            </span>
            <input
              placeholder="Driver"
              class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
        </template>
        <template #callToActionTop>
          <div class="flex">
            <a
              class="flex cursor-pointer text-2xl mr-2"
              @click.prevent="toggleStatsPolling()"
            >
              <font-awesome-icon
                icon="database"
                class="my-auto mx-1.5 hover:text-orange"
                :style="{
                  color: business?.statsPolling ? theme.primaryColor : 'grey',
                }"
            /></a>
            <a
              class="flex cursor-pointer text-2xl mr-2"
              @click.prevent="init()"
            >
              <font-awesome-icon
                icon="undo"
                class="my-auto mx-1.5 hover:text-orange"
                :style="{ color: theme.primaryColor }"
            /></a>
          </div>
        </template>
        <template #default="{ data }">
          <td class="px-5 py-2">
            <div class="text-black-primary whitespace-no-wrap text-lg flex">
              <StyledIconedLink
                class="flex mx-1.5"
                :iconColor="data.breachLight"
                :iconHoverColor="data.breachLight"
              >
                <font-awesome-icon icon="circle" class="my-auto mx-1.5" />
              </StyledIconedLink>
            </div>
          </td>
          <td class="px-5 py-2 text-center relative">
            <Dropdown ref="dropdown">
              <ul class="py-2 text-sm">
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="
                      $router.push(
                        `/business/fatigue-management/dashboard/detailed/${data.driverId}`
                      )
                    "
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                  </StyledIconedLink>
                </li>
              </ul>
            </Dropdown>
          </td>
        </template>
      </Table>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { Table, Dropdown } from "@/components/commons";
import { formatPaginationSettings, formatDate } from "@/_helper";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Active-Fatigue-Dashboard",
  components: {
    Table,
    Dropdown,
    StyledIconedLink,
  },
  data() {
    return {
      isLoading: false,
      tableHeader: [
        "Driver",
        "Vehicle",
        "Last Location",
        "Fatigue Plan",
        "Work Status",
        "Last Event",
        "Rule",
        "Latest Break Time",
        "Break Required",
        "Current 24hr Total Work",
        "Last Updated",
        "Breach Light",
      ],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
        paginationDescription: "Displaying drivers with active counting points"
      },
      filter: {
        limit: 20,
        search: "",
        status: "all",
        sortingField: "none",
      },
      filterStatuses: {
        all: "All",
        work: "Work",
        rest: "Rest",
      },
      sortingFields: {
        none: "Sort By",
        remainingWork: "Current 24hr Total Work",
        lastUpdated: "Last Updated",
        lastEventAt: "Last Event",
        driverName: "Driver",
        breachLight: "Breach Light",
      },
      intervalId: null,
      rawDriverStatistics: [],
      me: null,
      business: null,
    };
  },

  created() {
    this.me = this.$store.getters[`account/me`];
    this.business = this.me?.business;
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
  mounted() {
    this.me = this.$store.getters[`account/me`];
    this.init();
  },

  methods: {
    async init(page = 1) {
      try {
        this.isLoading = true;
        const query = {
          skip: page * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {},
          businessId: this.me.businessId? this.me.businessId: this.me?.business?._id
        };
        if (this.filter.status !== "all") {
          query.filter = {
            status: this.filter.status,
          };
        }
        const result = await this.$store.dispatch(
          "driver/getActiveDriversStatistic",
          query
        );

        this.paginationSettings = {
          ...this.paginationSettings,
          ...formatPaginationSettings(result.resultsMeta)
        }
        this.rawDriverStatistics = result.data;
      } catch (error) {
        console.log("error: ", error);
        this.toast("error", "Something went wrong");
      } finally {
        this.isLoading = false;
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    getBadgeAndBreachMapping(breachLight) {
      switch (breachLight.toLowerCase()) {
        case "green":
          return "success";
        case "red":
          return "error";
        case "orange":
          return "warning";
      }
    },

    formatData(items = []) {
      return items.map((item) => {
        const {
          id,
          vehicle,
          lastLocation,
          fatiguePlan,
          status,
          lastEventAt,
          rule,
          latestTimeToBreak,
          restRequired,
          lastUpdated,
          breachLight,
          remainingWork,
          driverName,
          driverId,
        } = item;
        return [
          {
            id,
            name: driverName,
            itemType: "string",
            breachLight,
            driverId,
          },
          {
            id,
            name: vehicle,
            itemType: "string",
            breachLight,
            driverId,
          },
          {
            id,
            name: lastLocation,
            itemType: "string",
            breachLight,
            driverId,
          },
          {
            id,
            name: fatiguePlan,
            itemType: "string",
            breachLight,
            driverId,
          },
          {
            id,
            name: status,
            itemType: "string",
            breachLight,
            driverId,
          },
          {
            id,
            name: formatDate(lastEventAt, undefined, true),
            itemType: "string",
            breachLight,
            driverId,
          },
          {
            id,
            name: rule,
            itemType: "string",
            breachLight,
            driverId,
          },
          {
            id,
            name: formatDate(latestTimeToBreak, undefined, true),
            itemType: "string",
            breachLight,
            driverId,
          },
          {
            id,
            name: restRequired,
            itemType: "string",
            breachLight,
            driverId,
          },
          {
            id,
            name: remainingWork,
            itemType: "string",
            breachLight,
            driverId,
          },
          {
            id,
            name: formatDate(lastUpdated, undefined, true, true),
            itemType: "string",
            breachLight,
            driverId,
          },
        ];
      });
    },

    onPageChange(event) {
      if (event.page) {
        this.init(event.page);
      }
    },

    filterChange() {
      this.init();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },

    sortBy() {
      if (this.filter.sortingField === "none") {
        return this.init();
      }

      this.rawDriverStatistics = _.orderBy(this.rawDriverStatistics, [
        `${this.filter.sortingField}`,
      ]);
    },
    formatDate(date) {
      return formatDate(date, undefined, true);
    },
    async toggleStatsPolling() {
      if (this.statsPollingUpdating) {
        alert(
          "Polling of driver statistics is undergoing an update. Please wait."
        );
        return;
      }
      if (
        confirm(
          `You are about to ${
            this.business.statsPolling ? "disable" : "enable"
          } the polling of the driver statistics. Do you want to proceed?`
        )
      ) {
        this.statsPollingUpdating = true;
        try {
          const response = await this.$store.dispatch(
            "business/toggleStatsPolling"
          );

          const { statsPolling: updatedPollingValue } = response;
          this.business.statsPolling = updatedPollingValue;
          this.toast(
            "success",
            `Driver statistics polling ${
              updatedPollingValue ? "enabled" : "disabled"
            }`
          );
        } catch (error) {
          this.toast("error", "Something went wrong.");
        } finally {
          this.statsPollingUpdating = false;
        }
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    driverStatistics() {
      return this.formatData(this.rawDriverStatistics);
    },
  },
};
</script>
