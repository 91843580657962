<template>
  <Tabs clickEvent @click="fetchTab">
    <Tab title="Partner & Partner Users">
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :data="tableData"
        :paginationSettings="paginationSettings"
        @onPageChange="onPageChange($event)"
        :hasActions="false"
      >
        <template slot="tableFilters">
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400" />
            </span>
            <input
              placeholder="Search"
              class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
        </template>
        <template slot="callToActionTop">
          <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
            <button :style="{backgroundColor: theme.primaryColor}" class="btn btn-primary" @click.prevent="exportEmailList">
              Download Emails
            </button>
          </div>
        </template>
      </Table>
    </Tab>
    <Tab title="Reseller & Reseller Users">
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :data="tableData"
        :paginationSettings="paginationSettings"
        @onPageChange="onPageChange($event)"
        :hasActions="false"
      >
        <template slot="tableFilters">
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400" />
            </span>
            <input
              placeholder="Search"
              class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
        </template>
        <template slot="callToActionTop">
          <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
            <button class="btn btn-primary" @click.prevent="exportEmailList">
              Download Emails
            </button>
          </div>
        </template>
      </Table>
    </Tab>
    <Tab title="Businesses & Business Users">
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :data="tableData"
        :paginationSettings="paginationSettings"
        @onPageChange="onPageChange($event)"
        :hasActions="false"
      >
        <template slot="tableFilters">
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400" />
            </span>
            <input
              placeholder="Search"
              class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
        </template>
        <template slot="callToActionTop">
          <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
            <button class="btn btn-primary" @click.prevent="exportEmailList">
              Download Emails
            </button>
          </div>
        </template>
      </Table>
    </Tab>
    <Tab title="Drivers">
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :data="tableData"
        :paginationSettings="paginationSettings"
        @onPageChange="onPageChange($event)"
        :hasActions="false"
      >
        <template slot="tableFilters">
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400" />
            </span>
            <input
              placeholder="Search"
              class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
        </template>
        <template slot="callToActionTop">
          <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
            <button class="btn btn-primary" @click.prevent="exportEmailList">
              Download Emails
            </button>
          </div>
        </template>
      </Table>
    </Tab>
    <!-- <Tab title="Solo Driver">
      <h2>Solo Driver</h2>
    </Tab> -->
  </Tabs>
</template>

<script>
import { Table } from "@/components/commons";
import Tab from "@/components/commons/tab/Tab";
import Tabs from "@/components/commons/tab/Tabs";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { formatPaginationSettings } from "@/_helper";
import moment from "moment";
import { utils, writeFile } from "xlsx";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Email-List",
  components: {
    Table,
    Tab,
    Tabs,
  },

  data() {
    return {
      isLoading: false,
      tableHeader: ["Email"],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      filter: {
        limit: 10,
        search: "",
        parentRole: "partner",
      },
      filterStatuses: {
        partner: "partner",
        driver: "driver",
        business: "business",
        reseller: "reseller",
      },
      emailList: false,
      currentTab: "Partner & Partner Users",
    };
  },

  async mounted() {
    await this.init();
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    tableData() {
      if (this.emailList) {
        return this.parseEmailListData(this.emailList);
      } else {
        return [];
      }
    },
  },

  methods: {
    async fetchTab(data) {
      switch (data) {
        case "Partner & Partner Users":
          this.filter.parentRole = "partner";
          this.currentTab = data;
          break;
        case "Reseller & Reseller Users":
          this.filter.parentRole = "reseller";
          this.currentTab = data;
          break;
        case "Businesses & Business Users":
          this.filter.parentRole = "business";
          this.currentTab = data;
          break;
        case "Drivers":
          this.filter.parentRole = "driver";
          this.currentTab = data;
          break;
        // case "Solo Driver":
        //   this.filter.parentRole = "solo_driver";
        //   break;
      }
      this.init(1);
    },

    async exportEmailList() {
      let query = {
        filter: {
          parentRole: this.filter.parentRole,
          search: this.filter.search,
        },
      };

      const emails = await this.$store.dispatch(
        "emailList/exportEmails",
        query.filter
      );
      const parsedEmails = this.parseEmails(emails.data);
      const userType = this.parsedUserType();

      const data = utils.json_to_sheet(parsedEmails);
      const wb = utils.book_new();
      utils.book_append_sheet(wb, data, "data");
      writeFile(wb, `${userType}.csv`);
    },

    parseEmails(data) {
      if (data) {
        return data.map((emailData) => {
          return { email: emailData.email };
        });
      }
    },

    parsedUserType() {
      const dateFormat = "DD/MM/YYYY HH:mm";
      const todayDate = moment().format(dateFormat);
      switch (this.filter.parentRole) {
        case "partner":
          return `partner-emails-${todayDate}`;
        case "reseller":
          return `reseller-emails-${todayDate}`;
        case "business":
          return `business-emails-${todayDate}`;
        case "driver":
          return `driver-emails-${todayDate}`;
      }
    },

    async onPageChange(event) {
      await this.init(event.page);
    },

    async init(paged = 1) {
      this.isLoading = true;

      let query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {
          parentRole: this.filter.parentRole,
        },
      };

      // if(this.currentTab === "Drivers") {
      //   query.filter.parentRole = "driver";
      // }

      let emailList;

      if (this.currentTab !== "Solo Driver") {
        emailList = await this.$store.dispatch(
          "emailList/fetchEmailList",
          query
        );

        this.emailList = emailList.data.targetUser;
      } else {
        emailList = await this.$store.dispatch("driver/getDriver");
      }

      if (emailList.data.resultsMeta) {
        this.paginationSettings = formatPaginationSettings(
          emailList.data.resultsMeta
        );
      }

      this.isLoading = false;
    },

    debouncedSearchString() {
      this.init();
    },

    async filterChange() {
      await this.init();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    parseEmailListData(data) {
      return data.map((emailData) => {
        return [
          {
            id: emailData._id,
            name: emailData.email,
            itemType: "string",
          },
        ];
      });
    },
  },

  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
