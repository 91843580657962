<template>
  <div>
    <div class="border-2 rounded-lg p-2 justify-center mb-6">
      <FormulateInput type="image" v-model="leftContent.filePayload" name="Left
      Logo" v-on:file-upload-complete="retrieveImgBuffer($event, 'left')"
      v-on:file-removed="removeBuffer($event,'left')"
      validation=mime:image/jpeg,image/png,image/gif
      label-class="formulate-input-label formulate-input-label--before"
      outer-class="formulate-input" input-class=""
      wrapper-class="formulate-input-wrapper"
      upload-area-class="formulate-input-upload-area"
      upload-area-mask-class="formulate-input-upload-area-mask" />
    </div>

    <div class="border-2 rounded-lg p-2 justify-center mb-6">
      <FormulateInput type="image" v-model="rightContent.filePayload"
      name="Right Logo" v-on:file-upload-complete="retrieveImgBuffer($event,
      'right')" v-on:file-removed="removeBuffer($event, 'right')"
      validation=mime:image/jpeg,image/png,image/gif
      label-class="formulate-input-label formulate-input-label--before"
      outer-class="formulate-input" input-class=""
      wrapper-class="formulate-input-wrapper"
      upload-area-class="formulate-input-upload-area"
      upload-area-mask-class="formulate-input-upload-area-mask" />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    selectedType: {
      type: String,
    },
    selected: {
      type: Object,
    },
  },

  computed: {
    currentSelected: {
      get() {
        return this.$store.state.accreditation.selectedContainer;
      },
      set(newVal) {
        this.$store.state.accreditation.selectedContainer = newVal;
      },
    },

    docParts: {
      get() {
        return this.$store.state.accreditation.docParts;
      },
    },

    currentContent: {
      get() {
        const model = _.find(this.docParts, (data) => {
          return data.uuid === this.selected.uuid;
        });

        return model;
      },
    },
  },

  data() {
    return {
      leftContent: false,
      rightContent: false,
    };
  },

  mounted() {
    const { uuid } = this.selected;
    if (uuid) {
      this.fetchCurrentPart(uuid);
    }
  },

  methods: {
    removeContent(uuid) {
      const index = _.findIndex(this.currentContent.content, (data) => {
        return data.uuid === uuid;
      });

      this.currentContent.content.splice(index, 1);
    },

    retrieveImgBuffer(e, side) {
      if (side === "left") {
        this.leftContent.value = e.previewData;
        this.leftContent.type = e.file.type;
        return;
      }

      this.rightContent.value = e.previewData;
      this.rightContent.type = e.file.type;
    },

    removeBuffer(e, side) {
      if (side === "left") {
        this.leftContent.value = "";
        return;
      }

      this.rightContent.value = "";
    },

    fetchCurrentPart(partUUID) {
      this.isLoading = true;
      const currentPart = _.find(this.docParts, (part) => {
        return part.uuid == partUUID;
      });

      if (currentPart) {
        const currentIndex = _.findIndex(this.docParts, (part) => {
          return part.uuid == partUUID;
        });

        this.leftContent = _.find(currentPart.content, (data) => {
          return data.part === "left";
        });

        this.rightContent = _.find(currentPart.content, (data) => {
          return data.part === "right";
        });

        this.currentSelectedPart = currentPart;
        this.currentSelectedIndex = currentIndex;
      } else {
        this.currentSelectedPart = false;
        this.currentSelectedIndex = -1;
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped></style>
