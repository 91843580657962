import { API } from '@/api/config';
import { ObjectToQueryString } from '../../../_helper';

export default new (class ReportScheduling {
  createReportSchedule(payload) {
    return API.ACCOUNTS.post('/setting/email/schedule', payload);
  }

  getReportSchedule(pagination) {
    let filter = '';
    if (typeof pagination.filter === 'object') {
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw 'Invalid Filter object';
      }
    }

    pagination.filter = filter;

    filter = JSON.stringify(pagination.filter);
    if (pagination) pagination = '?' + ObjectToQueryString(pagination) + '&';
    return API.ACCOUNTS.get(`/setting/email/schedule${pagination}`);
  }

  updateSchedule(payload) {
    const certId = payload?._id;
    delete payload._id;
    return API.ACCOUNTS.patch(`/setting/email/schedule/${certId}`, payload);
  }

  deleteSchedule(payload) {
    return API.ACCOUNTS.delete(`/setting/email/schedule/${payload}`);
  }
})();