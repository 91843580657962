<template>
    <Stepper 
        :stepperSteps="stepperSteps" 
        @completed-step="completeStep" 
        @active-step="isStepActive" 
        :showNavigation="false"  
        @closeRefresh="closeRefresh"
        detailsId: detailsId
    />
</template>
<script>
import { Stepper } from '@/components/commons'
import {CreateDriver, CreateBusinessProfile }  from './stepper-to-ewd'
export default {
    name: 'Set-to-EWD-Driver',
    components: {Stepper},
    props: {
      detailsId:{
        type: String,
        default: '',
      },
    },
    data(){
        return{
            stepperSteps: [
                 {
                    stepIcon: 'user',
                    stepTitle: 'Driver Details',
                    stepSequence: 'second',
                    stepComponent: CreateDriver,
                    isCompleted: true,
                    detailsId: this.detailsId
                },
                 {
                    stepIcon: 'briefcase',
                    stepTitle: 'Business Profile',
                    stepSequence: 'third',
                    stepComponent: CreateBusinessProfile,
                    isCompleted: false,
                },
            ],
            activeStep: 0,
        }
    },
     methods: {
        completeStep(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    step.isCompleted = true;
                }
            })
        },
        isStepActive(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    if(step.isCompleted === true) {
                        step.isCompleted = false;
                    }
                }
            })
        },
        closeRefresh(){ 
            this.$emit('closeRefresh')
        }
    },
    
}
</script>