<template>
    <div>
        <div class="flex flex-row mb-5 w-full border-b pb-1">
            <div class="flex md:ml-0 ml-12 mt-3">
                <div class="font-bold">{{ configTitle }}</div>
            </div>
            <div class="ml-auto">
                <button class="btn btn-primary" @click.prevent="itemAdd" v-if="!loading" :style="{backgroundColor: theme.secondaryColor}">
                    Add
                </button>
            </div>
        </div>
        <div class="overflow-y-auto">
            <div class="loading mt-32 h-32" v-if="loading">
                <ui-spinner class="mx-auto"></ui-spinner>
            </div> 
            <table class="min-w-full leading-normal table-fixed" v-if="!loading"> 
                <thead>
                    <tr>
                        <th class="px-5 py-3 w-4/5 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Name
                        </th>
                        <th class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Status
                        </th>
                        <th class="px-5 py-3 border-gray-200 bg-gray-200 text-right text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="(configItem, index) in types" :key="index"
                    class="border-b border-gray-200 text-sm bg-white hover:bg-gray-100 cursor-default"
                    :class="configItem.isEdit? 'bg-yellow-50 hover:bg-yellow-50' : ''"
                    >
                        <td class="px-5 py-2">
                            <input class="w-11/12" :disabled="!configItem.isEdit" configItem="text" v-model="configItem.name">
                        </td>
                        <td class="px-2 py-2 text-center">
                            <div v-if="!configItem.isDefault && configItem.id">
                                <input type="checkbox"
                                :disabled="!configItem.isEdit"
                                v-model="configItem.isActive">
                            </div>
                            <div v-if="configItem.isDefault">
                                <span class="text-xs text-gray-500 italic">default</span>
                            </div>
                        </td>
                        <td class="px-2 py-2 flex justify-end">
                            <div v-if="!configItem.isDefault">
                                <div v-if="!configItem.isEdit" class="flex">
                                    <a class="flex cursor-pointer"
                                    @click.prevent="itemEdit(index)"
                                    ><font-awesome-icon
                                        icon="edit"
                                        class="my-auto mx-1.5 hover:text-orange-primary"
                                    /></a>
                                </div>
                                <div v-if="configItem.isEdit" class="flex">
                                    <a class="flex cursor-pointer"
                                    @click.prevent="itemSave(configItem.id, configItem.name, configItem.isActive)"
                                    ><font-awesome-icon
                                        icon="check"
                                        class="my-auto mx-1.5 hover:text-orange-primary"
                                    /></a>
                                    <a class="flex cursor-pointer"
                                    @click.prevent="itemDiscard(index)"
                                    ><font-awesome-icon
                                        icon="times"
                                        class="my-auto mx-1.5 hover:text-orange-primary"
                                    /></a>
                                </div>
                            </div>
                            <div v-if="configItem.isDefault">
                                <div v-if="!configItem.isEdit" class="flex">
                                    <font-awesome-icon
                                        icon="edit"
                                        class="my-auto mx-1.5 text-gray-200"
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { orderBy } from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name: "Vehicle-Config-Types",
    props: {
        vehicleClass: String
    },
    data() {
        return {
            types: [],
            loading: false,
            title: 'Vehicle Types',
            dataholder: {
                name: ''
            },
            dataFilter: ''
        }
    },
    async mounted() {
        this.loading = true
        var filter = 'LV'
        if(this.vehicleClass === 'vehicle-types-hv')
            filter = "HV"
        else if(this.vehicleClass === 'vehicle-types-mv')
            filter = "MV"

        await this.$store.dispatch(`vehicle/getVehicleClassSearch`,{searchStr: filter}).then(item => {
            if(item){
                this.datafilter = item[0]._id
                this.init(this.datafilter)
            }
        })
        
    },
    methods: {
        async init(filter) {
            // const me = this.$store.getters[`account/me`];
            if(filter) {
                const configData = await this.$store.dispatch(`vehicle/getVehicleTypeSearch`, {vehicleClassId:filter})
                if(configData){
                    this.types = this.formatItem(orderBy(configData, ['createdAt'],['asc']));
                    this.loading = false
                }
            }
        },
        itemEdit(index) {
            this.dataholder.name = this.types[index].name
            this.dataholder.select = this.types[index].class
            this.types[index].isEdit = true;
        },
        itemDelete(index) {
            if(confirm('Are you sure you want to delete this item?')) {
                this.types.splice(index, 1);
            }
        },
        itemSave(id, name, status) {
            if(id) {
                this.$store.dispatch(`vehicle/updateVehicleType`, {id:id, name:name, isActive:status, vehicleClassId: this.datafilter}).then(item => {
                      if(item && item._id){
                        this.init(this.datafilter)
                      }
                    })
                } else {
                    this.$store.dispatch(`vehicle/createVehicleType`, {name:name, vehicleClassId: this.datafilter}).then(item => {
                    if(item && item._id){
                        this.init(this.datafilter)
                    }
                })
            }
        },
        itemDiscard(index) {
            if(this.types[index].id == null) {
                this.types.splice(this.types.indexOf(index), 1);
            }
            else {
                this.types[index].name = this.dataholder.name
                this.types[index].vehicleclass = this.dataholder.select
                this.types[index].isEdit = false;
            }
        },
        itemAdd() {
            var valObj = this.types.filter(function(elem){
                if(elem.id == null && elem.isEdit == true) {
                    return elem;
                }
            });

            if(valObj.length == 0) {
                this.types.push({
                    id: null,
                    name: '',
                    compat: null,
                    status: false,
                    isEdit: true
                })
            }
        },
        formatItem(items) {
            let nItems = [];
            if(items){
                items.map(item => {
                const tempItem = {
                    id:item['_id'],
                    name:item['name'],
                    isActive:item['isActive'],
                    isDefault:item['isDefault'],
                    isEdit:false
                }
                nItems.push(tempItem)
                });
            }
            return nItems;
        },
    },
    computed: {
        configTitle() {
            if(this.vehicleClass === 'vehicle-types-lv')
                return 'Light Vehicles'
            else if(this.vehicleClass === 'vehicle-types-mv')
                return 'Medium Vehicles'
            else
                return 'Heavy Vehicles'
        },
         ...mapGetters('theme', {
        theme: 'getColorScheme'
        })
    }
}
</script>

<style>

</style>