<template>
  <div
    @click="onSelectComponent"
    class="relative h-20 flex flex-row justify-between space-x-4 border-solid border-2 p-2 hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
  >
    <div
      class="border border-gray-400 hover:border-orange-100 transition ease-in-out hover:duration-300 text-gray-400 rounded-md w-full p-4 flex flex-col items-stretch"
    >
      <div class="max-w-sm">
        <p class="text-sm">
          {{ choiceLabel(leftContent?.value) }}
        </p>
      </div>
    </div>

    <div
      class="border border-gray-400 hover:border-orange-100 transition ease-in-out hover:duration-300 text-gray-400 rounded-md w-full p-4 flex flex-col items-stretch"
    >
      <div class="max-w-sm">
        <p class="text-sm">
          {{ choiceLabel(rightContent?.value) }}
        </p>
      </div>
    </div>
    <div
      v-if="currentSelected.uuid === dynamicContent.uuid"
      class="absolute bottom-0 right-0 h-6 w-fit space-x-2 bg-orange-100 flex justify-between items-center py-2 px-1"
    >
      <!-- FIRST OR LAST  -->
      <template v-if="isFirst || isLast">
        <button class="text-white" @click.prevent="copyComponent">
          <font-awesome-icon :icon="['fas', 'copy']" />
        </button>
        <button class="text-white" @click.prevent="removeComponent">
          <font-awesome-icon :icon="['fas', 'trash']" />
        </button>

        <button
          v-if="isLast && docParts.length > 1 && !belowHeader"
          class="text-white"
          @click.prevent="moveUpComponent"
        >
          <font-awesome-icon :icon="['fas', 'arrow-up']" />
        </button>

        <button
          v-if="isFirst && docParts.length > 1 && !aboveFooter"
          class="text-white"
          @click.prevent="moveDownComponent"
        >
          <font-awesome-icon :icon="['fas', 'arrow-down']" />
        </button>
      </template>

      <!-- ABOVE HEADER OR BELOW FOOTER -->
      <template v-else-if="belowHeader">
        <button class="text-white" @click.prevent="copyComponent">
          <font-awesome-icon :icon="['fas', 'copy']" />
        </button>
        <button class="text-white" @click.prevent="removeComponent">
          <font-awesome-icon :icon="['fas', 'trash']" />
        </button>

        <!-- <button
          v-if="!belowHeader"
          class="text-white"
          @click.prevent="moveUpComponent()"
        >
          <font-awesome-icon :icon="['fas', 'arrow-up']" />
        </button> -->

        <button
          v-if="belowHeader"
          class="text-white"
          @click.prevent="moveDownComponent()"
        >
          <font-awesome-icon :icon="['fas', 'arrow-down']" />
        </button>
      </template>

      <!-- IN THE MIDDLE CAN MOVE FREELY -->
      <template v-else>
        <button class="text-white" @click.prevent="copyComponent">
          <font-awesome-icon :icon="['fas', 'copy']" />
        </button>
        <button class="text-white" @click.prevent="removeComponent">
          <font-awesome-icon :icon="['fas', 'trash']" />
        </button>

        <button class="text-white" @click.prevent="moveUpComponent">
          <font-awesome-icon :icon="['fas', 'arrow-up']" />
        </button>

        <button class="text-white" @click.prevent="moveDownComponent">
          <font-awesome-icon :icon="['fas', 'arrow-down']" />
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { HELPER } from "@/api/helper";
export default {
  props: {
    dynamicContent: {
      type: Object,
    },
  },

  computed: {
    currentSelected: {
      get() {
        return this.$store.state.accreditation.selectedContainer;
      },
      set(newVal) {
        this.$store.state.accreditation.selectedContainer = newVal;
      },
    },

    docParts: {
      get() {
        return this.$store.state.accreditation.docParts;
      },

      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    partIndex: {
      get() {
        const model = _.findIndex(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.dynamicContent.uuid;
          }
        );

        return model;
      },
    },

    currentPart: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.dynamicContent.uuid;
          }
        );

        return model;
      },
    },

    hasOneElement: {
      get() {
        return this.docParts.length > 1 ? true : false;
      },
    },

    isLast: {
      get() {
        return this.partIndex === this.docParts.length - 1 ? true : false;
      },
    },

    isFirst: {
      get() {
        return this.partIndex === 0 ? true : false;
      },
    },

    belowHeader: {
      get() {
        const index = this.partIndex - 1;

        if (index > -1 && this.docParts[index].type === "Headers") {
          return true;
        }

        return false;
      },
    },

    aboveFooter: {
      get() {
        const index = this.partIndex + 1;

        if (
          index < this.docParts.length &&
          this.docParts[index].type === "Closing"
        ) {
          return true;
        }

        return false;
      },
    },

    currentuuid: {
      get() {
        return this.dynamicContent.uuid;
      },
    },

    leftContent: {
      get() {
        const model = _.find(this.currentPart?.content, (data) => {
          return data.part === "left";
        });

        return model;
      },
    },

    rightContent: {
      get() {
        const model = _.find(this.currentPart?.content, (data) => {
          return data.part === "right";
        });

        return model;
      },
    },
  },

  methods: {
    onSelectComponent() {
      // this.$emit("updateSelectedControl", this.dynamicContent.uuid);
      console.log("selecting:", this.currentPart);
      this.currentSelected = this.currentPart;
    },

    removeComponent() {
      const index = this.partIndex;

      this.$store.state.accreditation.docParts.splice(index, 1);
      this.$store.state.accreditation.selectedContainer = false;
    },

    copyComponent() {
      let component = JSON.parse(JSON.stringify(this.currentSelected));
      delete component.uuid;
      const generatedId = `${component.type}` + HELPER.getUUIDv4();
      component.uuid = generatedId;

      const existingFooter = _.find(this.docParts, (data) => {
        return data.type === "Closing";
      });

      if (existingFooter) {
        const footerIndex = _.findIndex(this.docParts, (data) => {
          return data.type === "Closing";
        });

        this.docParts.splice(footerIndex, 0, component);
        return;
      }

      this.docParts.push(component);
    },

    moveUpComponent() {
      const mutatedArr = this.swap(
        this.docParts,
        this.docParts[this.partIndex - 1],
        this.docParts[this.partIndex]
      );

      this.docParts = mutatedArr;
    },

    moveDownComponent() {
      const mutatedArr = this.swap(
        this.docParts,
        this.docParts[this.partIndex + 1],
        this.docParts[this.partIndex]
      );

      this.docParts = mutatedArr;
    },

    swap(list, a, b) {
      return list.map(function (item) {
        if (item === a) {
          return b;
        } else if (item === b) {
          return a;
        }

        return item;
      });
    },

    choiceLabel(choice) {
      switch (choice) {
        case "{{accreditationNumber}}":
          return "Accred No.";

        case "{{driverName}}":
          return "Driver Name";

        case "{{inductionDate}}":
          return "Induction Date";

        case "{{accreditationName}}":
          return "Accred Name";

        case "{{todayDate}}":
          return "Today Date";

        case "{{adminNumber}}":
          return "Admin No.";
      }
    },
  },
};
</script>
