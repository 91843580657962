<template>
  <div class="text-white">
    <div class="h-36 bg-black flex">
      <h2 class="m-auto text-2xl font-bold">Make your business compliance technology work smarter with Logmaster.</h2>
    </div>
    <div class="footer bg-black border-t border-blue" >
      <div class="container py-10">
        <div class="grid grid-cols-4 gap-4">
            <div class="text-center">
              <img src="assets/images/logo-min.png" alt="" class="m-auto" style="max-width:200px">
            </div>
            <div>Location</div>
            <div>Contact Us</div>
            <div>Links</div>
        </div>
      </div>
    </div>
    <div class="copyright bg-black text-center p-5 border-t border-orange-primary">© 2022 Logmaster Australia – All Rights Reserved.</div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
