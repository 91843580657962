<template>
  <div class="container mx-auto p-6">
    <div class="flex items-stretch lg:flex-row flex-col -mx-4">
      <div class="flex-1 p-4">
        <LinkCard card-title="User List" card-url="/business/users" />
      </div>
      <div class="flex-1 p-4">
        <LinkCard card-title="Role List" card-url="/business/roles" />
      </div>
    </div>
    <!-- <div class="flex items-stretch lg:flex-row flex-col -mx-4">
      <div class="flex-1 p-4">
        <LinkCard card-title="Responsibility List" card-url="/business/responsibilities" />
      </div>
      <div class="flex-1 p-4">
      </div>
    </div> -->
  </div>
</template>
<script>
import LinkCard from '@/components/commons/ui/LinkCard'
export default {
  components: {
    LinkCard
  }
}
</script>