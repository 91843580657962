import { TSL } from "@/api";
import _ from 'lodash'

export default{
    // @TSL
    async createTSL({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await TSL.createTSL(data)
            commit("setNewCreatedTSL", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getTSL( { state }, param ){
        if(!param) throw 'Invalid entityId '+state.me;
        try{
            const response = await TSL.getTSL(param);
            if(param.id){
                
                if(!response?.data?.data) return [];
                return response.data.data;
            }else{
                return { results : response?.data?.data, metadata:response.data.resultsMeta }
            }
        }catch(error){
            throw error.response.data
        }
    },
    async getTSLChangesByBusinessId( _ , param ){
        if(typeof param === 'string') param = { businessId:param, query: { skip: 0, limit: 999 } }
        if(!param.businessId) throw 'Invalid UID or ID';
        try{
            const response = await TSL.getTSLChangesByBusinessId(param.businessId, param.query)
            if(response.data?.resultsMeta){
                return { results : response.data.data, metadata:response.data.resultsMeta }
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },
    async getTSLByEntity( { state }, entityId ){
        if(!entityId) throw 'Invalid entityId '+state.me;
        try{
            const response = await TSL.getTSLByEntity(entityId);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async updateTSL( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await TSL.patchTSL(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteTSL( { state }, id ){
        try{
            const response = await TSL.deleteTSL(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
}