<template>
  <div class="flex flex-col items-center">
    <div class="w-full border-2 p-8 mb-20">
      <FormulateForm @submit="onSubmit" #default="{ hasErrors }"  class="formulate-input-element-decorator" :aria-disabled="submitLoading">

        <FormulateInput type="select" label="Usage Disable Options" v-model="disable" :options="disableOptions" />
        <FormulateInput type="help"  label="One API will be disabled before excess usage." v-if="disable === 'before'"/>
        <FormulateInput type="help"  label="One API will be disabled at chosen number of excess usage." v-if="disable === 'at'"/>

        <FormulateInput type="number" max="100" label="Disable Percent" v-model="percent" v-if="disable === 'before'"/>
        <FormulateInput type="number" label="Excess Usage Count" v-model="excess" v-if="disable === 'at'"/>

        <FormulateInput
            type="submit"
            label="Save"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors"
        />

      </FormulateForm>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'Disable-Usage-Options',

  data() {
    return {
      name: '',
      disable: '',
      excess: 0,
      percent: 0,

      disableOptions: [
        { value: 'before', label: 'Before Excess Usage' },
        { value: 'at', label: 'Disable at Excess Usage' },
        { value: 'month', label: 'Disable for this Month' },
        { value: 'permanent', label: 'Disable permanent' },
      ],

      submitLoading: false,
    };
  },

  async mounted() {
    this.submitLoading = true
    const me = await this.$store.getters[`account/me`];
    const disableOption = await this.$store.dispatch(`business/getApiUsageDisableOption`, {businessId: me?.business?._id});
    if(disableOption) {
      this.disable = disableOption.disable;
      this.excess = disableOption.excess;
      this.percent = disableOption.percent;
    }
    this.submitLoading = false;
  },

  methods: {
    async onSubmit() {
      this.submitLoading = true
      const me = await this.$store.getters[`account/me`];
      const now = moment();
      const month = now.format('YYYY-MM');

      const payload = {
        businessId: me?.business?._id,
        disable: this.disable,
        percent: this.percent,
        excess: this.excess,
        month: month
      };

      await this.$store.dispatch(`business/saveApiUsageDisableOption`, payload).then((response) => {
        if(response) {
          this.toast('success', 'Usage disable option saved successfully!');
          this.$emit('closeModal');
        }
      })
          .catch((error) => {
            this.toast('error', 'Failed to save Usage disable option');
            console.error(error);
          })
      this.submitLoading = false;
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', message);
    },
  },
};
</script>

<style scoped></style>
