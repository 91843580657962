<template>
  <div class="">
    <FormulateForm v-model="values" @submit="onSubmit" #default="{ hasErrors }">
      <div v-if="loading" class="text-center">
        <ui-spinner class="m-auto"></ui-spinner> Loading...
      </div>
      <div v-if="!loading">
        <div class="form border-b-2 border-neutral-100 mb-4">
          <template v-for="(module, index) in contract.businessModules">
            <div :key="index">
              <div v-if="module.name != 'One API'">
                <div class="font-bold text-sm border-b-2 mb-2 border-neutral" >
                  {{ module.name }}
                </div>
                <div class="flex justify-between items-center">
                  <div class="flex justify-start space-x-2 items-center mb-2">
                    <FormulateInput
                      type="number"
                      :name="`${addedSeats[index].module}-seat`"
                      v-model="addedSeats[index].value"
                      input-class="shadow-sm border border-gray-300 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mt-1 mb-2 font-medium text-sm text-neutral-dark-500"
                      outer-class="z-50"
                      validation="required|number|min:0"
                      min="0"
                      max="100"
                      error-behavior="live"
                      :disabled="submitLoading"
                    />
                    <div class="text-sm text-gray-800">new seat @ ${{ getActualPrice(module) }}/{{ billPeriod }} each.</div>
                  </div>
                  <div class="font-bold">
                    {{ getProratedSeatAmount(module, index) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <!-- <div v-if="proratedDates.remainingDays > 0" class="flex flex-col space-y-1 mb-4 pb-4 border-b-2 border-neutral-100">
          <div class="flex justify-between items-center">
            <div class="text-gray-500 font-semibold">
              Subtotal
            </div>
            <div class="text-gray-500 font-semibold">
              ${{ billTotal.subTotal.toFixed(2) }}
            </div>
          </div>         
          <div class="flex justify-between items-center">
            <div class="text-gray-500 font-semibold">
              GST(10%)
            </div>
            <div class="text-gray-500 font-semibold">
              ${{ billTotal.gst.toFixed(2) }}
            </div>
          </div>
          <div class="flex justify-between items-center">
            <div class="text-gray-500 font-semibold">
              Gateway Fee({{ paymentGateway.surchargePercentageFee }}% + ${{ paymentGateway.otherFees }})
            </div>
            <div class="text-gray-500 font-semibold">
              ${{ billTotal.gatewayFee.toFixed(2) }}
            </div>
          </div>
          <div class="flex text-lg justify-between items-center">
            <div class="text-gray-800 font-bold">
              Total
            </div>
            <div class="text-gray-800 font-bold">
              ${{ billTotal.amountDue.toFixed(2) }}
            </div>
          </div>
        </div> -->
        <div class="flex flex-col mb-4 pb-4 border-b-2 border-neutral-100">
          <div class="flex text-lg justify-between items-center">
            <div class="text-gray-800 font-bold">
              Total
            </div>
            <div class="text-gray-800 font-bold">
              ${{ billTotal.subTotal.toFixed(2) }}
            </div>
          </div>
          <div class="text-xs text-gray-500">
            *This will be added on to your next bill.
          </div>
        </div>
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{backgroundColor: theme.secondaryColor}"
          >
            <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" />Submitting</span>  
            <span v-if="!submitLoading">Submit</span> 
          </FormulateInput>
        </StyledSubmit>
      </div>
    </FormulateForm>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex'
import { StyledSubmit } from '@/styled-components'
export default {
  name: "Manage-Seats-Form",
  props: {
    contract: {
      type: Object,
      default: () => {}
    },
    currentSeatCounts: {
      type: Object,
      default: () => {}
    },
    paymentMethod: {
      type: Object,
      default: () => {}
    },
    paymentGateway: {
      type: Object,
      default: () => {}
    },
    totals: {
      type: Object,
      default: () => {}
    }
  },
  components: {StyledSubmit}, 
  data() {
    return {
      loading: false,
      values: {
        addedSeatCount: 1
      },
      billPeriod: '',
      addedSeats: [],
      proratedDates: {},
      billTotal: {},
      submitLoading: false
    }
  },
  async created() {
    this.loading = true
    //set seat availability
    let addedSeats = [...this.contract.businessModules]
    addedSeats.forEach((seat) => {
      this.addedSeats.push({
        contractModuleId: seat._id,
        module: seat.name,
        value: 0,
        total: 0
      })
    })
    await this.calculateProratedAmount()
    this.loading = false
  },
  methods: {
    async onSubmit() {
      this.submitLoading = true
      //Form data
      let formData = {
        addedSeats: this.addedSeats,
        businessId: this.contract.businessMongoId,
        contractId: this.contract._id,
        remainingDays: this.proratedDates.remainingDays,
        daysPassed: this.proratedDates.daysPassed
      }

      if(this.proratedDates.remainingDays > 0) {
        formData.isProrated = true
        formData.total = this.billTotal.subTotal
      } else {
        formData.isProrated = false
        formData.total = 0
      }
      await this.$store.dispatch(`contract/addSeatCount`, formData)
        .then(async (response) => {
          console.log(response)
          this.$emit("addedSeats", true)
        })
        .catch(async(err) => {
          console.error(err)
          this.$emit("addedSeats", false)
        })
        .finally(() => {
          this.submitLoading = true
        })
    },
    init() {

    },

    getActualPrice(modules) {
      let amount;
      switch(this.contract.billingPeriod.name.toUpperCase()) {
        case "MONTHLY":
          this.billPeriod = "month"
          amount = modules.price.monthly.toFixed(2)
        break;
        case "QUARTERLY":
          this.billPeriod = "quarter"
          amount = modules.price.quarterly.toFixed(2)
        break;
        case "YEARLY":
          this.billPeriod = "year"
          amount = modules.price.yearly.toFixed(2)
      }
      return amount;
    },

    getSeatCount(module) {
      let moduleCount;
      switch(module.name) {
        case "EWD-FT":
          moduleCount = this.currentSeatCounts.ewdCount
        break;
        case "Fatigue Compliance":
          moduleCount = this.currentSeatCounts.fatigueCount
        break;
        case "KM-TIME":
          moduleCount = this.currentSeatCounts.kmTimeCount
        break;
        case "One API":
          moduleCount = this.currentSeatCounts.ewdCount
        break;
        default:
          moduleCount = 0
      }

      return moduleCount;
    },

    async calculateProratedAmount() {
      let cutOffDate, remainingDays, daysPassed, remainingDurationName;
      const lastPaymentDate =  moment(this.contract.lastPaymentDate).format('MM-DD-YYYY')
      const currentDate = moment(new Date()).format('MM-DD-YYYY')
      if(this.contract.billingPeriod.name.toUpperCase() === 'MONTHLY') {
        cutOffDate = moment(lastPaymentDate, 'MM-DD-YYYY').add(28, 'days').format('MM-DD-YYYY');
        remainingDays = moment(cutOffDate).diff(currentDate, "days")
        daysPassed = moment(currentDate).diff(lastPaymentDate, "days")
        remainingDurationName = 'day(s)'
      } else {
        cutOffDate = moment(lastPaymentDate, 'MM-DD-YYYY').add(12, 'months').format('MM-DD-YYYY');
        remainingDays = moment(cutOffDate).diff(currentDate, "months")
        daysPassed = moment(currentDate).diff(lastPaymentDate, "months")
        remainingDurationName = 'month(s)'
      }
      //if days passed is more than 28 days, there will be no prorated amount.

      this.proratedDates = {
        lastPaymentDate: lastPaymentDate,
        currentDate: currentDate,
        cutOffDate: cutOffDate,
        remainingDays: remainingDays,
        daysPassed: daysPassed,
        remainingDurationName: remainingDurationName // days, day, months, month
      }
    },
    
    getProratedSeatAmount(module, index) {
      const actualPrice = this.getActualPrice(module)
      let totalProratedPrice, perSeatPrice
      //if days passed is more than 28 days, there will be no prorated amount.
      // if(this.proratedDates.remainingDays <= 0) {
      totalProratedPrice = parseFloat(actualPrice * this.addedSeats[index].value).toFixed(2);
      perSeatPrice = parseFloat(actualPrice).toFixed(2);
      // }
      //  else {
      //   //price / days passed
      //   if(this.contract.billingPeriod.name.toUpperCase() === 'MONTHLY') {
      //     pricePerDayPerSeat = actualPrice / 28;
      //   } else {
      //     pricePerDayPerSeat = actualPrice / 12;
      //   }
      //   perSeatPrice = (pricePerDayPerSeat * this.proratedDates.remainingDays).toFixed(2);

      //   //Total Amount per module
      //   const computedPrice = pricePerDayPerSeat * this.addedSeats[index].value;
      //   totalProratedPrice = (computedPrice * this.proratedDates.remainingDays).toFixed(2);
      // }
      this.addedSeats[index].total = parseFloat(totalProratedPrice)
      this.addedSeats[index].unitPrice = parseFloat(perSeatPrice)
      console.log(this.addedSeats)
      return `$${totalProratedPrice}`
    },

    getProratedTotalAmount() {
      let totalAmount = 0;

      this.addedSeats.forEach((data) => {
        totalAmount += data.total
      })
      const gst = (totalAmount / 100) * this.paymentGateway.gstPercentageFee
      const totalGST = totalAmount + gst
      const gatewayFee = ((totalGST / 100) * this.paymentGateway.surchargePercentageFee) + this.paymentGateway.otherFees
      const amountDue = totalGST + gatewayFee
      this.billTotal = {
        subTotal: totalAmount,
        totalGST: totalGST,
        gst: gst,
        gatewayFee: gatewayFee,
        amountDue: amountDue
      }
    }
  },
   computed: {
      ...mapGetters('theme', {
      theme: 'getColorScheme'
      })
  },
  watch: {
    addedSeats: {
      handler: function() {
        this.getProratedTotalAmount()
      },
      deep: true
    }
  }
}
</script>