<template>
  <div>
    <div
      class="loading absolute mx-auto w-full h-full translate-y-2/4 flex flex-col items-center"
      v-if="loading"
    >
      <ui-spinner class="mx-auto"></ui-spinner>
      Generating duration terms options...
    </div>
    <FormulateForm v-if="!loading" v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <FormulateInput
          name="contractDuration"
          :options="contractDurationOptions"
          v-model="selectedContractDuration"
          type="radio"
          placeholder="Select an option"
          label="Select a contract duration"
          class="custom-options"
          validation="^required"
          :validation-messages="{ required: 'Contract Duration is required.' }"
        />

        <div
          v-if="
            selectedContractDurationFinal &&
            selectedContractDurationFinal.label === 'Yearly'
          "
        >
          <FormulateInput
            type="number"
            label="Set number of years"
            name="yearCount"
            placeholder="Input number of year(s)"
            class="w-max"
            validation="^required"
            :validation-messages="{ required: `Number of years required` }"
          />
        </div>

        <FormulateInput
          name="billingPeriod"
          v-if="selectedContractDuration"
          v-model="selectedBillingPeriod"
          :options="billingPeriodFilteredOptions"
          type="radio"
          placeholder="Select an option"
          label="Select a billing period"
          class="custom-options"
          validation="^required"
          :validation-messages="{ required: 'Billing Period is required.' }"
        />

        <FormulateInput
          name="demoOption"
          :options="demoOptions"
          type="radio"
          placeholder="Select an option"
          label="Select a demo option"
          class="custom-options"
          validation="^required"
          :validation-messages="{ required: 'Demo Option is required.' }"
        />

        <div class="border-t border-neutral-light mt-8">
          <div class="flex items-center justify-end w-full mt-4 space-x-4">
            <FormulateInput
              type="button"
              label="Back"
              input-class="btn btn-cancel w-full"
              @click.prevent="onPrevious()"
            />
            <FormulateInput
              type="submit"
              label="Next"
              input-class="btn btn-primary w-full"
              :style="{ backgroundColor: theme.secondaryColor }"
            />
          </div>
        </div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Duration-Terms",
  props: {
    preData: {
      type: Object,
      required: false,
      default: null,
    },
    postData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: "",
      contractDurationOptions: [],
      billingPeriodOptions: [],
      billingPeriodFilteredOptions: [],
      demoOptions: [],
      selectedContractDuration: "",
      selectedBillingPeriod: "",
      interChangingData: {},
      selectedContractDurationFinal: {},
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const contractDuration = await this.$store.dispatch(
        "lookup/getContractDurationOptions"
      );

      if (this.preData?.steps) {
        this.values = this.preData.steps?.durationTermsDetails || null;

        this.interChangingData = this.preData;
      }
      if (this.postData?.steps) {
        this.values = this.postData.steps?.durationTermsDetails || null;
        this.interChangingData = this.postData;
      }

      if (contractDuration) {
        this.contractDurationOptions = contractDuration
          .map((options) => ({
            value: options._id,
            label: options.name,
          }))
          .filter((options) => ["Monthly", "Yearly"].includes(options.label));
        this.contractDurationOptions = orderBy(
          this.contractDurationOptions,
          "label",
          "asc"
        );
      }

      const billingPeriod = await this.$store.dispatch(
        "lookup/getBillingPeriodOptions"
      );

      if (billingPeriod) {
        this.billingPeriodOptions = billingPeriod
          .map((options) => ({
            value: options._id,
            label: options.name,
          }))
          .filter((options) => ["Monthly", "Yearly"].includes(options.label));

        this.billingPeriodOptions = orderBy(
          this.billingPeriodOptions,
          "label",
          "asc"
        );
        this.billingPeriodFilteredOptions = this.billingPeriodOptions;
      }

      const demoOptions = await this.$store.dispatch("business/getDemoOptions");

      this.demoOptions = demoOptions.filter(
        (demo) => demo.value !== "NO_ACCOUNT"
      );

      if (contractDuration && billingPeriod && demoOptions) {
        this.loading = false;
      }
    },
    async onSubmit() {
      const findBillingPeriod = this.billingPeriodOptions.find(
        (option) => option.value === this.values.billingPeriod
      );
      const findContractDuration = this.contractDurationOptions.find(
        (option) => option.value === this.values.contractDuration
      );
      const updatedValues = {
        ...this.values,
        ...{
          billingPeriod: findBillingPeriod,
          contractDuration: findContractDuration,
          demoOption: this.values.demoOption,
        },
      };

      const formData = {
        ...this.interChangingData,
        ...updatedValues,
        steps: {
          ...this.interChangingData.steps,
          ...{ durationTermsDetails: this.values },
        },
      };

      this.$emit("nextStep", JSON.parse(JSON.stringify(formData)));
    },
    onPrevious() {
      const durationTermsData = this.values;
      const postData = {
        ...this.interChangingData,
        steps: {
          ...this.interChangingData.steps,
          ...{ durationTermsDetails: durationTermsData },
        },
      };
      this.$emit("backStep", postData);
    },
  },
  watch: {
    selectedContractDuration(newVal) {
      this.selectedContractDurationFinal = this.contractDurationOptions.find(
        (data) => data.value === newVal
      );
      this.billingPeriodFilteredOptions = this.billingPeriodOptions.filter(
        (value) => value.label === this.selectedContractDurationFinal.label
      );
    },
    deep: true,
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
