<template>
  <div>
    <div v-if="!isLoading && !isRulesLoading">
      <FormulateForm
        @submit="onSubmit"
        v-model="afmFormModel"
        #default="{ hasErrors }"
        ref="test"
      >
        <FormulateInput
          type="text"
          name="ruleSetName"
          label="Rule Set Name"
          validation="^required"
        />

        <div class="flex justify-center p-2">
          <div class="w-1/2">
            <p class="font-medium text-sm mb-1">Require Admin Signature</p>
          </div>
          <div class="w-1/2">
            <FormulateInput
              type="checkbox"
              name="isSignatureApprovalRequired"
            />
          </div>
        </div>

        <div class="flex justify-center p-2">
          <div class="w-1/2">
            <p class="font-medium text-sm mb-1">Require Training Certificate</p>
          </div>
          <div class="w-1/2">
            <FormulateInput type="checkbox" name="isTrainingCertRequired" />
          </div>
        </div>

        <div class="flex justify-center p-2">
          <div class="w-1/2">
            <p class="font-medium text-sm mb-1">Require Medical Certificate</p>
          </div>
          <div class="w-1/2">
            <FormulateInput type="checkbox" name="isMedicalCertRequired" />
          </div>
        </div>

        <FormulateInput
          type="hidden"
          name="ruleSetRegion"
          value="NHVR"
          validation="^required"
        />

        <FormulateInput
          type="date"
          name="ruleSetEffectiveDate"
          label="Rule Set Effective Date"
          validation="^required"
        />

        <FormulateInput
          type="date"
          name="rulesetExpiryDate"
          label="Rule Set Expiration Date"
        />

        <FormulateInput
          type="select"
          name="twoUp"
          label="Two Up"
          :options="twoUpOptions"
          v-model="twoUpChoice"
          validation="^required"
        />

        <div v-if="!isEdit" class="flex flex-col pb-6 pt-6">
          <FormulateInput
            type="hidden"
            name="adminSignature"
            class="invisible"
            validation="^required"
            v-model="imageBuffer"
          />
          <!-- DRAWN SIGNATURE -->
          <div
            v-if="!isTypeSignature && !isUploadSignature"
            class="flex flex-col p-2"
          >
            <div class="w-full p-2">
              {{ isEdit ? "Update Signature" : "Sign Below" }}
            </div>
            <VueSignaturePad
              :options="{ onEnd }"
              id="signature"
              class="canvas bg-gray-200"
              ref="signaturePad"
              scaleToDevicePixelRatio
            />
            <button @click.prevent="toggleTypeSignature">Type Signature</button>
            <button @click.prevent="toggleUploadSignature">
              Upload Signature
            </button>
            <button @click.prevent="clearSignature">Clear Signature</button>
          </div>

          <!-- UPLOADED SIGNATURE -->

          <div
            v-else-if="isUploadSignature && !typeSignature"
            class="w-full items-center"
          >
            <div class="center-text">Upload Signature</div>
            <div class="flex">
              <input
                type="file"
                ref="fileSignatureInput"
                accept="image/png, image/jpeg"
                name="uploadedSignature"
                @change="onFilePicked($event, 'image')"
              />

              <button
                class="btn btn-primary"
                @click.prevent="onSignatureFilePick"
              >
                Upload file
              </button>
            </div>

            <div v-if="!imageBuffer" class="items-center flex flex-col">
              <button @click.prevent="cancelTypeSignature">Back</button>
            </div>
          </div>

          <!-- TYPED SIGNATURE -->

          <div
            v-else-if="typeSignature && !isUploadSignature"
            class="flex flex-col"
          >
            <div class="w-full p-2">Typed Signature</div>
          </div>

          <div class="flex flex-col" v-else>
            <div class="w-full p-2">Type Signature Below</div>

            <v-select
              :options="options"
              label="title"
              :searchable="false"
              :clearable="false"
              v-model="selectedFont"
              v-on:input="onFontChange"
              class="mb-5"
            >
              <template slot="option" slot-scope="option">
                {{ option.title }}
                <img :src="option.cardImage" />
              </template>
            </v-select>

            <input
              class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2"
              id="text"
              v-model="signature"
            />

            <button class="p-2" @click.prevent="saveSignature">
              Save Signature
            </button>
            <button @click.prevent="cancelTypeSignature">Back</button>
          </div>
        </div>
        <div class="flex flex-col pb-6 pt-6" v-else>
          <div class="flex flex-col" v-if="!willUpdateSignature">
            <button
              class="btn btn-primary mt-2"
              @click.prevent="willUpdateSignature = true"
            >
              Update Signature
            </button>
          </div>

          <div class="flex flex-col pb-6" v-else>
            <FormulateInput
              type="hidden"
              name="adminSignature"
              class="invisible"
              validation="^required"
              v-model="imageBuffer"
            />
            <!-- DRAWN SIGNATURE -->
            <div
              v-if="!isTypeSignature && !isUploadSignature"
              class="flex flex-col p-2"
            >
              <div class="w-full p-2">
                {{ isEdit ? "Update Signature" : "Sign Below" }}
              </div>
              <VueSignaturePad
                :options="{ onEnd }"
                id="signature"
                class="canvas bg-gray-200"
                ref="signaturePad"
                scaleToDevicePixelRatio
              />
              <button @click.prevent="toggleTypeSignature">
                Type Signature
              </button>
              <button @click.prevent="toggleUploadSignature">
                Upload Signature
              </button>
              <button @click.prevent="clearSignature">Clear Signature</button>
            </div>

            <!-- UPLOADED SIGNATURE -->

            <div
              v-else-if="isUploadSignature && !typeSignature"
              class="w-full items-center"
            >
              <div class="center-text">Upload Signature</div>
              <div class="flex">
                <input
                  type="file"
                  ref="fileSignatureInput"
                  accept="image/png, image/jpeg"
                  name="uploadedSignature"
                  @change="onFilePicked($event, 'image')"
                />

                <button
                  class="btn btn-primary"
                  @click.prevent="onSignatureFilePick"
                >
                  Upload file
                </button>
              </div>

              <div v-if="!imageBuffer" class="items-center flex flex-col">
                <button @click.prevent="cancelTypeSignature">Back</button>
              </div>
            </div>

            <!-- TYPED SIGNATURE -->

            <div
              v-else-if="typeSignature && !isUploadSignature"
              class="flex flex-col"
            >
              <div class="w-full p-2">Typed Signature</div>
            </div>

            <div class="flex flex-col" v-else>
              <div class="w-full p-2">Type Signature Below</div>

              <v-select
                :options="options"
                label="title"
                :searchable="false"
                :clearable="false"
                v-model="selectedFont"
                v-on:input="onFontChange"
                class="mb-5"
              >
                <template slot="option" slot-scope="option">
                  {{ option.title }}
                  <img :src="option.cardImage" />
                </template>
              </v-select>

              <input
                class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2"
                id="text"
                v-model="signature"
              />

              <button class="p-2" @click.prevent="saveSignature">
                Save Signature
              </button>
              <button @click.prevent="cancelTypeSignature">Back</button>
            </div>
            <button
              class="btn btn-primary mt-2"
              @click.prevent="willUpdateSignature = false"
            >
              Cancel
            </button>
          </div>
        </div>

        <div
          class="p-2 items-center flex flex-col"
          v-if="imageBuffer && renderPreview"
        >
          <div class="p-2 items-center flex flex-col">Preview</div>
          <img v-bind:src="imageBuffer" alt="" />
          <button @click.prevent="revertTypeSignature">Cancel</button>
        </div>

        <div class="mt-4">
          <FormulateInput
            name="selectedTemplate"
            label="Induction Template"
            type="template-select"
            placeholder="Select a template"
            style="margin-top: 0px"
            :getOptionKey="(option) => option.id"
            :getOptionLabel="(option) => option.name"
            @selected="selectedDriver = $event"
            :filterable="false"
            :clearable="true"
          />
        </div>

        <div class="flex flex-col px-2" v-if="isEdit">
          <div class="flex flex-col" v-if="!willUpdateCert">
            <button
              class="btn btn-primary mt-2"
              @click.prevent="willUpdateCert = true"
            >
              Update Certificate
            </button>
          </div>
          <div v-else>
            <div>
              <div class="center-text">Update Certificate</div>
              <input
                type="file"
                ref="fileInput"
                accept="application/pdf"
                @change="onFilePicked($event, 'pdf')"
              />

              <button
                class="btn btn-primary mb-4"
                @click.prevent="onPickFile"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Upload file
              </button>
            </div>
            <div class="flex flex-col">
              <button
                class="btn btn-primary mt-2"
                @click.prevent="willUpdateCert = false"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="center-text">Upload Certificate</div>
          <input
            type="file"
            ref="fileInput"
            accept="application/pdf"
            @change="onFilePicked($event, 'pdf')"
          />

          <button
            class="btn btn-primary mb-4"
            @click.prevent="onPickFile"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Upload file
          </button>
        </div>

        <div v-if="!isRulesLoading">
          <AFMRulesContainer
            :afmRules="rules"
            @deleteAdditional="deleteAdditional"
            @addAdditionals="addAdditionals"
            @deleteRule="deleteRule"
          />
        </div>

        <div v-else class="text-center">
          <font-awesome-icon
            icon="circle-notch"
            spin
            size="2x"
            class="text-gray-400"
          />
        </div>

        <div class="flex justify-end">
          <button
            class="btn btn-secondary text-center"
            @click.prevent="addMore()"
          >
            Add Rules <font-awesome-icon icon="plus-circle" />
          </button>
        </div>

        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors || isLoading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </FormulateForm>
    </div>
    <div v-else>
      <div class="text-center">
        <font-awesome-icon
          icon="circle-notch"
          spin
          size="2x"
          class="text-gray-400"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import AFMRulesContainer from "./afm-rules-container.vue";
import { mapGetters } from "vuex";
import { createCanvas } from "canvas";
// import { HELPER } from "@/api/helper";

export default {
  name: "afm-form",

  props: {
    editData: {
      type: Object,
      default: () => null,
    },

    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    AFMRulesContainer,
  },

  async mounted() {
    this.isLoading = true;

    const fontOptions = this.fonts.map((data) => {
      const buffer = this.drawSamples(data);
      return { cardImage: buffer, title: data };
    });

    this.options = fontOptions;

    if (this.editData && !_.isEmpty(this.editData)) {
      let docTemplate;

      const {
        ruleSetName,
        rulesetExpiryDate,

        ruleSetEffectiveDate,
        twoUp,
      } = this.editData.ruleSet;

      const {
        isMedicalCertRequired,
        isSignatureApprovalRequired,
        isTrainingCertRequired,
      } = this.editData;

      // console.log("isMedicalCertRequired:", this.editData);

      if (this.editData?.documentTemplate) {
        docTemplate = await this.$store.dispatch(
          "accreditation/fetchOneTemplate",
          {
            templateId: this.editData?.documentTemplate,
          },
        );
      }

      this.afmFormModel = {
        ruleSetName: ruleSetName ?? "",
        rulesetExpiryDate: rulesetExpiryDate ?? "",
        ruleSetRegion: "NHVR",
        isMedicalCertRequired,
        isTrainingCertRequired,
        ruleSetEffectiveDate,
        isSignatureApprovalRequired,
        twoUp,
      };

      if (docTemplate && docTemplate.data && docTemplate.data.data) {
        const { _id, name } = docTemplate.data.data;
        this.afmFormModel["selectedTemplate"] = {
          id: _id,
          name,
        };
      }

      this.formatEditToForm(this.editData.rules);
    }
    this.isLoading = false;
  },

  data() {
    return {
      isLoading: false,
      isRulesLoading: false,

      AFMrules: [
        {
          rule: false,
          countingPoint: false,
          countingPeriod: false,
          countingType: "Working",
          consecutiveCount: false,
          replacement: false,
          maxWork: null,
          restRequired: null,
          continuousRest: false,
          nightRest: false,
          consecutive: false,
          stationary: false,
          additional: [],

          latestIndex: -1,
          validMaxWork: false,
        },
      ],

      twoUpOptions: [
        { value: "true", label: "True" },
        { value: "false", label: "False" },
      ],

      countingTypeOptions: [
        { value: "Working", label: "Working" },
        { value: "Rest", label: "Rest" },
      ],

      additionalsTypeOptions: [
        { value: "noPoint", label: "No Point" },
        { value: "longNight", label: "Long Night" },
        { value: "count", label: "Count" },
        { value: "blocks", label: "Blocks" },
        { value: "or", label: "Or" },
      ],

      isSignatureApprovalRequired: false,
      isMedicalCertRequired: false,
      isTrainingCertRequired: false,

      twoUpChoice: "false",
      countingTypeChoice: "Working",
      pdf: false,

      imageBuffer: false,
      options: false,
      selectedFont: "Arial",
      fonts: [
        "Arial",
        "Arial Black",
        "Comic Sans MS",
        "Courier New",
        "Impact",
        "PT Sans",
      ],
      isTypeSignature: false,
      isUploadSignature: false,
      renderPreview: false,

      signature: "",
      typeSignature: false,

      willUpdateCert: false,
      willUpdateSignature: false,

      afmFormModel: {},
    };
  },

  computed: {
    rules: {
      get() {
        return this.$store.state.accreditation.AFMrules;
      },
      set(newVal) {
        this.$store.state.accreditation.AFMrules = newVal;
      },
    },

    additionalRules: {
      get() {
        return this.$store.state.accreditation.additionalRules;
      },
      set(newVal) {
        this.$store.state.accreditation.AFMrules = newVal;
      },
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },

  methods: {
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.imageBuffer = data;
    },

    onSignatureFilePick() {
      this.$refs.fileSignatureInput.click();
    },

    clearSignature() {
      this.$refs.signaturePad.openSignaturePad();
      this.isTypeSignature = false;
      this.$refs.signaturePad.clearSignature();
      this.imageBuffer = false;
    },

    cancelTypeSignature() {
      this.isTypeSignature = false;
      this.isUploadSignature = false;
      this.renderPreview = false;
    },

    saveSignature() {
      if (!_.isEmpty(this.imageBuffer) && this.isTypeSignature) {
        this.isTypeSignature = true;
        this.$refs.signaturePad.clearSignature();
      }

      const width = 1200;
      const height = 200;

      const canvas = createCanvas(width, height);
      const context = canvas.getContext("2d");

      context.font = `bold 25pt '${this.selectedFont}'`;
      context.textAlign = "center";
      context.fillStyle = "#000000";

      context.lineWidth = 5;

      context.beginPath();
      context.moveTo(0, 120);
      context.lineTo(500, 120);
      context.stroke();

      context.beginPath();
      context.moveTo(600, 120);
      context.lineTo(1200, 120);
      context.stroke();
      let initials;

      initials = this.signature
        .match(/(\b\S)?/g)
        .join("")
        .toUpperCase();

      if (!_.isEmpty(initials) && !_.isEmpty(this.signature)) {
        context.fillText(initials.trim(), 900, 100);
        context.fillText(this.signature.trim(), 300, 100);
        const buffer = canvas.toDataURL("image/png");

        this.typeSignature = true;
        this.imageBuffer = buffer;
      }
    },

    toggleTypeSignature() {
      if (this.isUploadSignature) {
        this.isUploadSignature = false;
      }

      this.isTypeSignature = true;
      this.$refs.signaturePad.clearSignature();
      this.$refs.signaturePad.openSignaturePad();
      this.imageBuffer = false;
      this.renderPreview = true;
    },

    toggleUploadSignature() {
      if (this.isTypeSignature) {
        this.typeSignature = false;
      }
      this.isUploadSignature = true;
      this.imageBuffer = false;
      this.renderPreview = true;
    },

    drawSamples(font) {
      const width = 1200;
      const height = 200;

      const canvas = createCanvas(width, height);
      const context = canvas.getContext("2d");

      context.font = `bold 25pt '${font}'`;
      context.textAlign = "center";
      context.fillStyle = "#000000";

      context.lineWidth = 5;

      context.beginPath();
      context.moveTo(0, 120);
      context.lineTo(500, 120);
      context.stroke();

      context.beginPath();
      context.moveTo(600, 120);
      context.lineTo(1200, 120);
      context.stroke();

      context.fillText("JD", 900, 100);
      context.fillText("John Doe", 300, 100);
      const buffer = canvas.toDataURL("image/png");

      return buffer;
    },

    onFontChange(data) {
      this.selectedFont = data.title;
    },

    onSubmit(data) {
      // console.log("data:", data);

      // this.isLoading = true;

      if (this.rules.length == 0) {
        this.toast("error", "No rules declared");
        return;
      }

      if (this.willUpdateSignature && this.isEdit && !this.imageBuffer) {
        this.toast("error", "No Admin Signature");
        return;
      }

      if (this.willUpdateCert && this.isEdit && !this.pdf) {
        this.toast("error", "No Certificate");
        return;
      }

      delete data.rules;
      delete data.adminSignature;

      // verification if all maxwork and rest required values are valid
      let isValidMaxWork = true;

      let i;

      for (i = 0; i < this.rules.length && isValidMaxWork !== false; i++) {
        if (this.rules[i].validMaxWork === false) {
          isValidMaxWork = false;
        }
      }

      if (isValidMaxWork) {
        const rulesData = this.rules.map((data) => {
          let formattedAdditional = null;
          if (data.additionals && data.additionals.length > 0) {
            formattedAdditional = data.additionals.map((additional) => {
              let formattedOr;

              const additionalToAdd = this.removeEmpty(
                this.additionalRules[additional],
              );

              if (this.checkOr(additionalToAdd.or)) {
                formattedOr = this.formatOr(additionalToAdd.or);
                additionalToAdd.or = formattedOr;
              } else {
                delete additionalToAdd.or;
              }
              delete additionalToAdd.type;

              return additionalToAdd;
            });
          }

          delete data.latestIndex;

          delete data.validMaxWork;

          data.additionals = formattedAdditional;

          return data;
        });

        if (_.isEmpty(data?.rulesetExpiryDate)) {
          data.rulesetExpiryDate = null;
        }

        let RuleSet = _.pick(data, [
          "ruleSetName",
          "isMedicalCertRequired",
          "isTrainingCertRequired",
          "isSignatureApprovalRequired",
          "ruleSetEffectiveDate",
          "rulesetExpiryDate",
          "ruleSetRegion",
          "twoUp",
          "type",
        ]);

        const {
          isSignatureApprovalRequired,
          isMedicalCertRequired,
          isTrainingCertRequired,
        } = data;

        this.$emit("submit", {
          ruleSet: RuleSet,
          rules: rulesData,
          certNumber: data.ruleSetName.trim(),
          certExpiryDate: RuleSet.rulesetExpiryDate,
          documentTemplate: data?.selectedTemplate ?? null,
          isSignatureApprovalRequired,
          isMedicalCertRequired,
          isTrainingCertRequired,
          file: this.pdf,
          adminSig: this.imageBuffer,
          isEdit: this.isEdit,
          type: "AFM",
        });
      } else {
        this.toast("error", "Max Work and Rest Required Cannot be empty");
      }
    },

    addMore() {
      this.isRulesLoading = true;
      const fields = {
        rule: false,
        countingPoint: false,
        countingPeriod: false,
        countingType: "Work",
        consecutiveCount: false,
        replacement: false,
        maxWork: null,
        restRequired: null,
        continuousRest: false,
        nightRest: false,
        consecutive: false,
        stationary: false,
        breaches: false,
        additionals: [],

        latestIndex: -1,
        validMaxWork: false,
      };
      this.$store.commit("accreditation/setAFMrules", fields);
      this.$forceUpdate();
      this.isRulesLoading = false;
    },

    revertTypeSignature() {
      this.isTypeSignature = false;
      this.isUploadSignature = false;
      this.imageBuffer = false;
      this.typeSignature = false;
      this.renderPreview = false;

      if (this.$refs.fileSignatureInput?.value) {
        this.$refs.fileSignatureInput.value = null;
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    onPickFile() {
      this.$refs.fileInput.click();
    },

    deleteAdditional(afmRuleIndex, additionalIndex) {
      const afmAdditionalKey =
        this.rules[afmRuleIndex].additionals[additionalIndex];

      this.additionalRules[afmAdditionalKey] = null;

      this.rules[afmRuleIndex].additionals.splice(additionalIndex, 1);

      this.rules[afmRuleIndex].latestIndex -= 1;
    },

    removeEmpty(obj) {
      if (obj?.type !== "longNight") {
        return Object.fromEntries(
          Object.entries(obj).filter(
            // eslint-disable-next-line no-unused-vars
            ([_, v]) => {
              if (v !== "" || v !== false) {
                return v;
              }
            },
          ),
        );
      } else {
        return Object.fromEntries(
          // eslint-disable-next-line no-unused-vars
          Object.entries(obj).filter(([_, v]) => v !== ""),
        );
      }
    },

    checkOr(orArr) {
      let isValid = false;

      const objKeys = ["A", "B", "C", "D"];

      for (let i = 0; i < orArr.length; i++) {
        if (isValid) {
          break;
        }
        for (let key = 0; key < objKeys.length; key++) {
          if (orArr[i][objKeys[key]] !== "") {
            isValid = true;
            break;
          }
        }
      }
      return isValid;
    },

    getSetCount(obj) {
      let count = -1;

      const objKeys = ["A", "B", "C", "D"];

      for (let key = 0; key < objKeys.length; key++) {
        if (obj[objKeys[key]] !== "") {
          count++;
        }
      }

      return count;
    },

    formatOr(obj) {
      let orString = "";

      const objKeys = ["A", "B", "C", "D"];

      for (let i = 0; i < obj.length; i++) {
        for (let key = 0; key < objKeys.length; key++) {
          //last index no comma
          if (obj[i][objKeys[key]] !== "") {
            if (key === this.getSetCount(obj[i])) {
              orString += `${obj[i][objKeys[key]]}`;
            } else {
              orString += `${obj[i][objKeys[key]]}, `;
            }
          }
        }

        if (i !== obj.length - 1) {
          orString += " | ";
        }
      }

      return orString;
    },

    getExtension(filename) {
      if (filename) {
        let parts = filename.split(".");
        return parts[parts.length - 1];
      } else {
        return;
      }
    },

    onFilePicked(event, type) {
      const extension = this.getExtension(event?.target?.files[0]?.name);
      let isValid = false;
      if (type === "pdf" && extension === "pdf") {
        isValid = true;
      }
      if (type === "image" && (extension === "jpg" || extension === "png")) {
        isValid = true;
      }
      if (isValid && extension) {
        if (type === "pdf") {
          const files = event.target.files;
          this.pdf = files[0];
        }
      } else {
        this.toast("error", "Invalid file format");

        if (type === "pdf") {
          // eslint-disable-next-line no-unused-vars
          let fileInput = this.$refs.fileInput;
          fileInput.type = "text";
          fileInput.type = "file";
          this.pdf = false;
        }
      }
    },

    deleteRule(index) {
      if (this.rules[index].additionals.length > 0) {
        const additionalsToDelete = this.rules[index].additionals;

        for (let i = 0; i < additionalsToDelete.length; i++) {
          this.additionalRules[additionalsToDelete[i]] = null;
        }
      }

      const indexOfSection = this.rules.findIndex((object, idx) => {
        return idx === index;
      });

      this.rules.splice(indexOfSection, 1);
    },

    addAdditionals(index, type) {
      let obj = {
        type: "noPoint",
        noPoint: "",

        longNight: false,
        long: "",
        nightStart: "",
        nightEnd: "",
        longNightCompound: false,
        dayCounting: "",
        count: "",
        blocks: "",
        or: [
          {
            A: "",
            B: "",
            C: "",
            D: "",
          },
          {
            A: "",
            B: "",
            C: "",
            D: "",
          },
          {
            A: "",
            B: "",
            C: "",
            D: "",
          },
          {
            A: "",
            B: "",
            C: "",
            D: "",
          },
        ],
      };

      if (type) {
        switch (type) {
          case "firstMaxWork":
            obj.type = "firstMaxWork";
            break;

          case "consecutiveCount":
            obj.type = "consecutiveCount";
            break;

          case "replacement":
            obj.type = "replacement";
            break;
        }
      }

      const pushedIndex = this.additionalRules.push(obj) - 1;

      this.rules[index].latestIndex = pushedIndex;

      const AFMpushedIndex =
        this.rules[index].additionals.push(pushedIndex) - 1;

      this.rules[index].latestIndex = AFMpushedIndex;
    },

    checkboxClick(event) {
      return event.target.checked;
    },

    formatEditToForm(rule) {
      this.isRulesLoading = true;
      rule.map((data) => {
        const formatted = { ...data };

        delete formatted.breaches;

        formatted["additionals"] =
          formatted.additionals && formatted.additionals?.length > 0
            ? this.formatAdditionalsToForm(formatted.additionals)
            : [];

        formatted["latestIndex"] =
          formatted.additionals && formatted.additionals?.length
            ? formatted.additionals?.length - 1
            : -1;

        this.$store.commit("accreditation/setAFMrules", formatted);
      });

      this.isRulesLoading = false;
    },

    formatAdditionalsToForm(additionals) {
      // eslint-disable-next-line no-unused-vars
      const allFields = [
        "blocks",
        "count",
        "dayCounting",
        "long",
        "longNight",
        "longNightCompound",
        "nightEnd",
        "nightStart",
        "noPoint",
        "or",
        "firstMaxWork",
        "consecutiveCount",
        "replacement",
      ];
      // eslint-disable-next-line no-unused-vars
      const formattedAdditionals = additionals.map((data) => {
        // eslint-disable-next-line no-unused-vars
        const finalAdditional = {};

        const initalField = Object.keys(data);

        finalAdditional["type"] = initalField[0];

        for (let field in allFields) {
          if (
            allFields[field] == "longNightCompound" ||
            allFields[field] == "longNight"
          ) {
            finalAdditional[allFields[field]] =
              data[`${allFields[field]}`] || false;
          } else if (allFields[field] == "or") {
            if (data[allFields[field]]) {
              const stringOr = data[allFields[field]];

              const arrayOr = stringOr.split(" | ").map((item) => {
                const values = item.split(", ").map((value) => value.trim());
                const obj = {
                  A: "",
                  B: "",
                  C: "",
                  D: "",
                };

                if (values.length >= 1) {
                  obj.A = values[0];
                }
                if (values.length >= 2) {
                  obj.B = values[1];
                }
                if (values.length >= 3) {
                  obj.C = values[2];
                }
                if (values.length >= 4) {
                  obj.D = values[3];
                }

                return obj;
              });
              finalAdditional[allFields[field]] = arrayOr;
            } else {
              finalAdditional[allFields[field]] = [
                { A: "", B: "", C: "", D: "" },
                { A: "", B: "", C: "", D: "" },
                { A: "", B: "", C: "", D: "" },
                { A: "", B: "", C: "", D: "" },
              ];
            }
          } else {
            finalAdditional[allFields[field]] =
              data[`${allFields[field]}`] || "";
          }
        }
        const pushedIndex = this.additionalRules.push(finalAdditional) - 1;

        return pushedIndex;
      });

      return formattedAdditionals;
    },
  },
};
</script>
<style scoped>
input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
</style>
