/* eslint-disable no-control-regex */
import { API } from "@/api/config";
import { ObjectToQueryString } from "@/_helper";

export default new (class Performance {
  createPerformance(payload) {
    return API.ACCOUNTS.post("/performance", payload);
  }

  getPerformance(pagination = { skip: 0, limit: 10, filter: {} }) {
    let filter = "";
    if (typeof pagination.filter === "object") {
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }
    }

    pagination.filter = filter;

    filter = JSON.stringify(pagination.filter);

    if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";
    return API.ACCOUNTS.get(`/performance/${pagination}`);
  }

  updatePerformance(payload) {
    const id = payload.id;
    delete payload.id;
    return API.ACCOUNTS.patch(`/performance/${id}`, payload);
  }

  getByRegisterId(payload) {
    let { registerId, pagination = { skip: 0, limit: 10 } } = payload;
    if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";

    return API.ACCOUNTS.get(
      `/performance/register/${registerId}${pagination}`
    );
  }

  getCountByRegisterType(payload){
    const {type, startDate, endDate} = payload
    return API.ACCOUNTS.get(`performance/register-type/${type}/count?startDate=${startDate}&endDate=${endDate}`)
 }

 getSummaryReport(paylaod) {
  return API.ACCOUNTS.post(`performance/summary-report`, paylaod)
}

getChainOfActions(id) {
  return API.ACCOUNTS.get(`performance/${id}/chain/actions`)
}
})();
