<template>
<!-- Payment Failed -->
  <div class="py-4 px-4 mx-auto w-full max-w-full h-full lg:py-4 lg:px-4 overflow-auto">
    <div class="flex flex-col justify-center py-4 items-center space-y-4">
      <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="my-auto text-5xl text-orange-100" />
      <div class="font-semibold text-xl text-neutral-dark">Update your payment method</div>
      <div class="text-base text-neutral-main text-center">We can't find any active payment method in your account. To continue using our services, please update your payment method.</div>
    </div>
    <div class="flex space-x-4 mt-4 w-full">
      <FormulateInput
        type="button"
        class="w-full"
        input-class="btn btn-primary w-full text-sm md:text-base"
        :disabled="submitLoading"
        @click.prevent="openUpdatePaymentForm"
      >
      <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving data...</span>  
      <span v-if="!submitLoading">Update Card Details</span>  
      </FormulateInput>
    </div>    

    <!-- Update Card Details Form -->
    <Modal ref="updatePaymentFormModal" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-12 sm:w-12">
          <font-awesome-icon icon="credit-card" class="text-4xl text-neutral-dark" />
        </div>
        <div class="text-center mt-4 w-full">
          <h3 class="text-xl leading-6 font-semibold text-neutral-dark" id="modal-title">Update Card Details</h3>
        </div>
        <div v-if="!modalLoading" class="text-center mt-2 w-full">
            <FormulateForm v-model="values" @submit="onSubmit" class="text-left">
              <div class="text-md mb-2 text-gray-600 text-left flex-1">Card Details</div>
              <stripe-element-card
                class="mb-5"
                ref="cardRef"
                :pk="publishableKey"
                @token="tokenCreated"
                @element-change="updateSubmitButton"
                @element-blur="cardChange"
                :hidePostalCode="true"
                @error="onCardError"
              />
              <FormulateInput
                type="submit"
                input-class="btn btn-primary w-full"
                :disabled="!cardCheck"
              >
              <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" />{{ buttonText }}</span>  
              <span v-if="!submitLoading">Update</span>  
              </FormulateInput>
            </FormulateForm>
        </div>
        <div v-else class="flex space-x-2 items-center justify-center text-center mt-2">
          <ui-spinner class=""></ui-spinner>
          <div class="text-neutral-dark">Loading</div>
        </div>
      </div>
    </Modal>
    
    <!-- Payment Processing Modal -->
    <Modal ref="processingPaymentModal" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-12 sm:w-12">
          <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <div class="text-center mt-4 w-full">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Processing your payment</h3>
        </div>
        <div class="text-center mt-2">
            <p class="text-sm text-gray-500">Please wait while we process your payment request.</p>
        </div>
      </div>
    </Modal>
    <!-- Payment Successful Modal -->
    <Modal ref="paymentSuccessfulModal" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-12 sm:w-12">
          <font-awesome-icon icon="check" class="h-6 w-6 text-green-800" />
        </div>
        <div class="text-center mt-4 w-full">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Payment successful</h3>
        </div>
        <div class="text-center mt-2">
            <p class="text-sm text-gray-500">We have received your payment successfully. Please wait as we redirect you back to the main page.</p>
        </div>
      </div>
    </Modal>
    <!-- Payment Successful Modal -->
    <Modal ref="paymentFailedModal" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
      <div class="flex flex-col justify-center py-4 items-center space-y-4">
          <font-awesome-icon icon="times-circle" class="my-auto text-5xl text-red-500" />
        </div>
        <div class="text-center mt-4 w-full">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Payment failed</h3>
        </div>
        <div class="text-center mt-2">
            <p class="text-sm text-gray-500">We can't process your payment at the moment.</p>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal } from '@/components/commons'
import { StripeElementCard } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeElementCard,
    Modal,
  },
  props: {
    entityDetails: {
      type: Object,
      default: () => {}
    },
    deactivationDate: {
      type: String,
      default: ''
    },
    amount: {
      type: Number,
      default: 0
    },
    parentEntityId: {
      type: String,
      default: ''
    },
    accountSuspended: {
      type: Boolean,
      default: false
    },
    suspensionDate: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: 'driver'
    }
  },

  data() {
    return {
      submitLoading: false,
      paymentModalHeight: '20vh',
      values: {},
      publishableKey: null,
      paymentGatewayId: null,
      paymentGateway: {},
      token: null,
      cardCheck: false,
      buttonText: "Saving data...",
      modalLoading: false,
      errorMessage: '',
      clonedEntityDetails: {}
    }
  },

  watch: {
    entityDetails() {
      this.clonedEntityDetails = {...this.entityDetails}
    }
  },

  async created() {
    this.clonedEntityDetails = {...this.entityDetails}
  },

  methods: {
    updateSubmitButton() {
      this.submitLoading = true
      this.cardCheck = false
      this.buttonText = 'Validating Card Details...'
    },
    cardChange() {
      this.$refs.cardRef.submit();
    },
    tokenCreated(token) {
      this.token = token.id;
      if(this.token) {
        this.submitLoading = false
        this.cardCheck = true
        this.buttonText = 'Saving data...'
      }
    },

    async openUpdatePaymentForm() {
      this.modalLoading = true
      this.$refs.updatePaymentFormModal.toggleModal();
      await this.$store.dispatch("payment/getActivePaymentGatewayByEntityId", this.parentEntityId)
        .then((response) => {
          if(response) {
            this.paymentGatewayId = response[0]._id
            this.publishableKey = response[0].apiKey
            this.paymentGateway = response[0]
          }
          this.modalLoading = false
        }).catch((error) => {
          this.errorMessage = error
          this.modalLoading = false
        })
    },

    async onSubmit() {
      this.submitLoading = true
      this.buttonText = "Saving data..."
      const payload = {
        entityId: this.entityDetails.entityId,
        entityRole: this.role,
        paymentGatewayId: this.paymentGatewayId,
        cardToken: this.token,
        isDefault: true,
        isActive: true
      }
      await this.$store.dispatch("payment/createCardPaymentMethod", payload)
        .then((response) => {
          if(response) {
          // this.$refs.paymentSuccessfulModal.closeModal();
          this.$refs.updatePaymentFormModal.closeModal();
          setTimeout(() => {
            this.submitLoading = false
            this.cardCheck = false
            this.$emit('closeModal')
            this.$store.commit('setDialogNotify',true);
            this.$store.commit('setMessageNotify',{state:'success',message:'Payment method updated successfully.'});
            setTimeout(()=>{location.reload()},4000);
          }, 2500);
          }
        })
        .catch((error) => {
          this.submitLoading = false
          this.cardCheck = true
          this.errorMessage = error?.message
        });
    },

    async onCardError() {
      this.cardCheck = false
      this.buttonText = 'Saving data...'
      this.submitLoading = false
    }
  }
}
</script>