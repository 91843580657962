<template>
  <div class="mb-16">
    <FormulateForm
      name="createBusinessForm"
      v-model="values"
      :schema="schema"
      @submit="nextPage"
    >
      <div>
        <FormulateInput
          type="group"
          name="certificate"
          label="Accreditation (Optional)"
          labelClass="font-semibold text-sm block -top-7 -left-0 absolute"
          class="border rounded p-5 mb-5 mt-10 relative"
        >
          <button @click.prevent="showDriverList">
            <small
              v-if="!isDriverListOpen"
              class="btn-primary btn-xs px-3 shadow rounded-lg"
            >
              + Select from accreditation list
            </small>
            <small
              v-else
              class="bg-blue-200 text-white btn-xs px-3 shadow rounded-lg"
            >
              <font-awesome-icon icon="times" />
            </small>
          </button>

          <FormulateInput
            v-if="!isDriverListOpen"
            type="text"
            label="BFM Number"
            v-model="BFMStringBased"
            valdation="optional"
          />

          <FormulateInput
            v-else
            type="cert-select"
            name="BFMNumber"
            style="margin-bottom: 0px"
            label="BFM Number"
            class="pb-4 content-center"
            validation="optional"
            @input="onChange($event)"
            v-model="BFMselectionBased"
            :clearable="false"
          />

          <FormulateInput
            v-if="isDriverListOpen"
            type="date"
            name="inductionDate"
            label="Induction Date"
            validation="optional"
            v-model="inductionDate"
          />
        </FormulateInput>
      </div>

      <div class="items-center justify-between">
        <FormulateInput
            type="submit"
            label="Next"
            input-class="btn btn-primary w-full mt-5"
            :disabled="loading"
        />
      </div>
    </FormulateForm>

    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import schema from "./business-profile-form-schema";
// import moment from "moment";
import _ from "lodash";
import driver from '../../../../commons/sidebar/driver';
import { mapGetters } from "vuex";

export default {
  name: "Add-Business-Profile-Form",
  props: {
    msg: String,
    isBusinessDriverProfile: {
      type: Boolean,
      default: false,
    },
    preData: null,
  },
  data () {
    this.$emit("schema", schema);
    return {
      driversEmail: [],
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
      isDriverListOpen: false,
      BFMStringBased: "",
      BFMselectionBased: {},
      inductionDate: ""
    }
  },
  watch: {
    isDriverListOpen: function() {
      this.BFMselectionBased = {};
      this.inductionDate = "";
    }
  },

  mounted() {
    this.driversEmail = this.preData.drivers
    console.log(this.preData)
  },
  methods: {
    onSubmit() {
      this.errorMessage = "";
      this.loading = true;
      const me = this.$store.getters[`account/me`];
      const entityID = me?.businessId;

      let newContract = JSON.parse(JSON.stringify(this.values));

      newContract["profile"] = newContract["profile"][0];
      newContract["profile"]["recordKeeperAddress"] =
      newContract["profile"]["recordKeeperAddress"][0][
          "record-keeper-street"
        ];
      newContract["profile"]["baseLocation"] =
      newContract["profile"]["baseLocation"][0]["base-location-street"];

      const proxySite = this.$store.getters[`account/proxySite`];
      if (!_.isEmpty(proxySite) && _.isObject(proxySite)) newContract["siteId"] = proxySite._id;
      newContract["businessId"] = entityID;
    },
    nextPage() {
      const driverProfile = JSON.parse(JSON.stringify(this.values));

      driverProfile["profile"] = driverProfile["profile"][0];
      driverProfile["profile"]["recordKeeperAddress"] =
      driverProfile["profile"]["recordKeeperAddress"][0][
          "record-keeper-street"
        ];
      driverProfile["profile"]["baseLocation"] =
      driverProfile["profile"]["baseLocation"][0]["base-location-street"];
      driver
      const preData = {
        ...this.preData,
        businessProfile: driverProfile
      }
      this.$emit('nextStep', preData)
    },

    onChange(data) {
      if (data) {
        this.BFMselectionBased = data;
      }
    },

    showDriverList() {
      this.isDriverListOpen = !this.isDriverListOpen;
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>