import styled from 'vue-styled-components'

const checkboxProps = {toggleColor: String, toggleDotColor: String}

export const StyledDiv = styled("div", checkboxProps)`
    input:checked ~ .dot {
    transform: translateX(100%);
    background-color: ${({toggleDotColor}) => toggleDotColor || '#FFFFFF'};
    }
    input:checked ~ .line {
    background-color: ${({toggleColor}) => toggleColor || '#FFFFFF'};
    }
`