import { Auth } from "@/api";
import _ from 'lodash'
import {FATIGUE, KM_TIME, ONE_API} from '@/constants/modules'

export default{
    // @Account / Auth
    async isLogged({ state }){
        if(state.accessToken) return true;
        return false;
    },
    async isAuthenticated({ dispatch }){
        const response = await dispatch(`getMe`);

        if (!response || (response && response.isDisabled)) return false;

        return response;
    },
    async loginUID({ commit }, payload){
        if(!payload || _.isEmpty(payload)) throw 'Invalid uid data'
        try{
            const response = await Auth.authenticateUID(payload);
            commit("setProxyTokens", response.data.data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },
    async loginDriverUID({ commit }, payload){
        if(!payload || _.isEmpty(payload)) throw 'Invalid uid data'
        try{
            const response = await Auth.authenticateUID(payload);
            commit("setTokens", response.data.data);
            return response.data.data?.user
        }catch(error){
            throw error.response.data
        }
    },
    async login({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid credentials data'
        try{
            const response = await Auth.authenticate(data);
            commit("setTokens", response.data.data);
            return response.data.data?.user
        }catch(error){
            throw error.response.data
        }
    },
    async authenticateToken({state, commit}, data){
        if(!data) return {success: false, message: `Invalid credential token ${state.id}`}
        try{
            const response = await Auth.authenticateToken(data);
            commit("setTokens", response.data.data);
            return response.data
        }catch(error){
            return error.response.data
        }
    },
    async verifyCode({ state }, data){
        if(!data) throw `Invalid verification code. ${state.id}`
        try{
            const response = await Auth.verifyCode(data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },
    async changePassword( { state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data';
        if(!data.uid) throw 'Invalid uid'+state
        try{
            const uid = data.uid;
            delete data.uid;
            const response = await Auth.accountChangePassword(uid,data);
            return response.data;
        }catch(error){
            throw error.response.data
        }
    },
    async changeEmail( { state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data';
        if(!data.uid) throw 'Invalid uid'+state
        try{
            const uid = data.uid;
            delete data.uid;
            const response = await Auth.changeEmail(uid,data);
            return response.data;
        }catch(error){
            throw error.response.data
        }
    },
    async requestResetPassword( { state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid credentials data'+state;
        try{
            const response = await Auth.requestResetPassword(data);
            return response.data;
        }catch(error){
            throw error.response.data
        }
    },
    async resetPassword( { state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid credentials data';
        if(!data.token) throw 'Invalid token'+state
        try{
            const rToken = data.token;
            delete data.token;
            const response = await Auth.resetPassword(rToken,data);
            return response.data;
        }catch(error){
            throw error.response.data
        }
    },
    async changeEmailSetPassword( { state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid credentials data';
        if(!data.token) throw 'Invalid token'+state
        try{
            const token = data.token;
            delete data.token;
            const response = await Auth.changeEmailSetPassword(token,data);
            return response.data;
        }catch(error){
            throw error.response.data
        }
    },
    async disputeChangeEmail( { state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid credentials data';
        if(!data.token) throw 'Invalid token'+state
        try{
            const response = await Auth.disputeChangeEmail(data);
            return response.data;
        }catch(error){
            throw error.response.data
        }
    },
    async getMe({ commit }){
        try{
            let response;
            response = await Auth.getMe();
            response = response.data.data
            await commit('setMe', response);
            return response;
        }catch(error){
            return false
        }
    },

    async updateMe({dispatch, commit, getters}, response) {
        if (response.role && response.role.name) {
            let promises = [];
            const roles = response.roles ? response.roles : [];
            const isProxy = getters['proxyUser'] !== '';
            const proxySite = getters['proxySite'];
            const proxyRole = getters['proxyRole'];
            const role = response.role.name;
            let uid = response.uid;

            const proxyDriverProfileUid = getters["proxyDriverProfileUid"];
            if (proxyDriverProfileUid) {
                if (role == 'driver') uid = proxyDriverProfileUid;

                if (role == 'business') {
                    const driverBusinessWebProfile = await dispatch(`rolesandrespo/getWebProfilesByUID`, proxyDriverProfileUid, { root: true })
                    if (driverBusinessWebProfile) {
                        response.email = driverBusinessWebProfile[0].email;
                        response.username = driverBusinessWebProfile[0].email;
                    }
                }
            }

            response.webProfiles = await dispatch(`rolesandrespo/getWebProfilesByUID`, uid, { root: true })
            const webProfile = response.webProfiles.find(profile => profile.parentRole === role)
            response.webProfile = webProfile;

            // Get security roles
            promises.push(dispatch('getSecurityRoles', { uid: uid, role: role }));

            // Get user settings and theme options
            promises.push(dispatch('theme/getThemeListsById', uid, { root: true }));
            promises.push(dispatch('settings/getUserSettings', uid, { root: true }));

            if (role == 'admin') {
                promises.push(dispatch('getLogmasterByWebProfileID', webProfile?._id));
            }
            
            if (role == 'partner') {
                promises.push(dispatch('partner/getPartnerByWebProfileID', webProfile?._id, { root: true }));
            }
            
            if (role == 'reseller') {
                promises.push(dispatch('reseller/getResellerByWebProfileID', webProfile?._id, { root: true }));
            }
            
            if (
                (role === 'business' && (!isProxy || proxyRole === 'business')) ||
                (role === 'driver' && (isProxy && proxyRole === 'business' && roles.includes('business')))
            ) {
                // If current webprofile is a sub business, auto login to sub business
                if (webProfile?.site && _.isEmpty(proxySite)) {
                    commit('setProxySite', webProfile.site)
                    commit('setProxyRole', response.role.name)
                    await dispatch(`proxyUser`, { uid: uid, proxySite: webProfile.site })
                }
                
                promises.push(dispatch('business/getBusinessByUID', uid, { root: true }));

                if (!_.isEmpty(proxySite) && _.isObject(proxySite)) {
                    promises.push(dispatch('business/getBusinessBySiteId', proxySite._id, { root: true }));
                }
            }

            if (role === 'driver' || (isProxy && proxyRole === 'driver' && roles.includes('driver'))) {
                promises.push(dispatch('driver/getDriverByUID', uid, { root: true }));
            }

            await Promise.all(promises).then(async (data) => {
                response.securityRoles = data[0];
                response.themeOptions = data[1];
                response.userSettings = data[2] && data[2]?.data ? data[2]?.data : null;
                
                if (['admin', 'partner', 'reseller'].includes(role)) {
                    response.entity = data[3];
                }

                if (role === 'driver' || (isProxy && proxyRole === 'driver' && roles.includes('driver'))) {
                    response.driver = data[3];
                    response.entity = response.driver

                    // let driverContract = await dispatch(`business/getContractByDriver`, response.driver._id, { root: true })
                    // if (driverContract?.results) {
                    //     response.driverContract = driverContract?.results[0];
                    //     //check if the driver is a contractor
                    //     if(response.driverContract.isContractor === true) {
                    //         console.log('contractor driver contract found, proceed to look for solo contract');
                    //         response.contract = await dispatch(`contract/getMyContract`, {entityId: response.driver._id}, { root: true })
                    //         response.contractType = 'contractor-contract'
                    //     } 
                    // } else {
                    //     console.log('no driver contract found, proceed to look for solo contract');
                    //     await dispatch(`contract/getMyContract`, {entityId: response.driver._id}, { root: true })
                    //     .then((res) => {
                    //         response.contract = res
                    //         console.log('Solo contract found');
                    //         response.contractType = 'solo-contract'
                    //     }).catch(async () => {
                    //         console.log("No contract found, checking contractor data");
                    //         console.log(response.driver)
                    //         await dispatch('driver/getContractorDriverByEmail', response.driver.emailAddress, {root: true})
                    //         .then((contractor) => {
                    //             response.contractType = 'contractor-contract'
                    //             response.contractor = contractor
                    //         }).catch((err) => {
                    //             console.log(err)
                    //         })
                    //     })
                    // }

                        
                    //check if the driver has a solo contract
                    await dispatch(`contract/getMyContract`, { entityId: response.driver._id }, { root: true })
                        .then(async (soloContract) =>{
                            response.contract = soloContract
                            //if the driver has no solo contract, re-validate if he is a contractor with pending business setup
                            await dispatch('driver/getContractorDriverByEmail', response.driver.emailAddress, {root: true})
                            .then((contractor) => {
                                response.contractor = contractor
                            }).catch((err) => {
                                console.log(err)
                            })
                        })
                        .catch(async () => {
                            //if the driver has no solo contract, re-validate if he is a contractor with pending business setup
                            await dispatch('driver/getContractorDriverByEmail', response.driver.emailAddress, {root: true})
                            .then((contractor) => {
                                response.contractor = contractor
                            }).catch((err) => {
                                console.log(err)
                            })
                        })


                    //check if driver has a business-driver contract
                    await dispatch(`business/getContractByDriver`, response.driver._id, { root: true })
                        .then((driverContract) => {
                            response.driverContract = driverContract.results[0]
                        })
                        .catch((err) => {
                            console.log("No business-driver contract found--", err)
                        })

                }

                if (
                    (role === 'business' && (!isProxy || proxyRole === 'business')) ||
                    (role === 'driver' && (isProxy && proxyRole === 'business' && roles.includes('business')))
                ) {
                    response.business = data[3];
                    response.entity = response.business;
                    response.businessId = response.business._id;
                    response.contract = await dispatch(`contract/getMyContract`, {entityId: response.businessId}, { root: true });

                    if (response?.business?.modules?.length > 0) {
                        let menu = [];
                        response.business.modules.map(module => {
                            switch (module.apiTag) {
                                case "fatigue-compliance":
                                    menu = menu.concat(FATIGUE);
                                    break;
                                case "km-time":
                                    menu = menu.concat(KM_TIME);
                                    break;
                                case "one-api":
                                    menu = menu.concat(ONE_API);
                                    break;
                            }
                        });
                        response.menuItems = menu;
                    }

                    if (!_.isEmpty(proxySite) && _.isObject(proxySite)) {
                        response.businessSite = data[4];
                        response.businessId = response.businessSite._id;
                    }
                    
                    // Set any value to trigger fetching the latest depot sites
                    commit('driver/setUpdateHeaderSites', true, { root: true });
                }
            }).catch(error => {
                console.log(`Cannot fetch user data. ${error}`)
            })
        }
        response['updated'] = true;
        commit('setMe', response);
        return response;
    },

    async updateMenuItems({dispatch, commit, getters}, response) {
        if(response.role && response.role.name){
            if(response.role.name === 'business'){
                try {
                    const roles = response.roles;
                    const isProxy = getters['proxyUser'] !== '';
                    const proxyRole = getters['proxyRole'];
                    const role = response.role.name;
                    let uid = response.uid;

                    let webProfile = null;
                    if (role === 'driver' && (isProxy && proxyRole === 'business' && roles.includes('business'))) {
                        response.webProfiles = await dispatch(`rolesandrespo/getWebProfilesByEmail`, response.email, { root: true })
                        webProfile = response.webProfiles.find(profile => profile.parentRole === proxyRole)
                        uid = webProfile?.uid;
                    }
                    
                    response.business = await dispatch(`business/getBusinessByUID`, uid, { root: true })
                    response.entity = response.business;
                    if (response?.business?.modules?.length > 0) {
                        let menu = [];
                        response.business.modules.map(module => {
                            switch (module.apiTag) {
                                case "fatigue-compliance":
                                    menu = menu.concat(FATIGUE);
                                    break;
                                case "km-time":
                                    menu = menu.concat(KM_TIME);
                                    break;
                                case "one-api":
                                    menu = menu.concat(ONE_API);
                                    break;
                            }
                        });
                        response.menuItems = menu;
                    }
                } catch { console.error('Error setting menu items') }
                commit('setMe', response);
            }
        }
        return response;
    },

    async getSecurityRoles({ state }, data) {
        if(!data) throw 'Invalid data '+state.me;
        try{
            const response = await Auth.getSecurityRoles(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getLogmasterByWebProfileID({ state }, id) {
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Auth.getLogmasterByWebProfileId(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getUser({ state }, data = { id:null, by:'uid' }){
        if(!data.id) throw 'Invalid user id'+state.me;
        const id = data.id;
        const by = data.by ? data.by : 'uid';
        try{
            let response;
            if(by == 'uid'){
                response = await Auth.getUserByUID(id);
            }else{
                response = await Auth.getUser(id);
            }
            return response.data;
        }catch(error){
            return false
        }
    },
    async updateUserPassword( { state }, id, data ){
        if(!id || !data || _.isEmpty(data)) throw 'Invalid id/data object'+state.me
        try{
            const response = await Auth.updateUserPassword( id, data );
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async proxyUser({ commit, dispatch, getters }, param){ //proxy User is a user end simulation also known as drill down for user prespective
        if(!param.uid) throw 'Invalid user uid'
        else {
            let payload = { uid: param.uid }
            if (param?.proxySite) {
                payload['siteId'] = param.proxySite._id;
            }

            payload.mainRole = getters["mainRole"];
            payload.proxyRole = getters['proxyRole'];
            
            const loginUID = await dispatch('loginUID', payload)
            if(loginUID && loginUID.success){
                commit("setProxyUser", payload.uid);
                commit("setProxySite", param.proxySite);
            }else{
                throw 'Login via UID failed.'
            }
        }
    },

    async refreshToken({ commit }, data) {
        try {
          const isProxy = data.isProxy;
          delete data.isProxy;
          const response = await Auth.refreshToken(data);
          if (!isProxy) {
            commit("setTokens", response.data.data);
          } else {
            commit("setProxyTokens", response.data.data);
          }
          return response.data.data.user;
        } catch (error) {
          throw error.response.data;
        }
    },

}