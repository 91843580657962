<template>
    <div class="mb-16">
      <div class="btns">
      <div class="pb-2">
            <button class="btn btn-primary w-full" @click="deleteDepot" :style="{backgroundColor: theme.secondaryColor}"> YES</button>
      </div>
            <button class="border-2 btn btn-secondary w-full" @click="closeModal">NO</button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Delete-Association',
  props: {
    node: Object,
  },

  data() {
    return {
      error:false,
      errorMessage: '',
      loading: false,
      values: {},
      newTree: false,
    };
  },
  mounted(){
      console.log('nodeContent', this.node)
  },
  methods: {
    closeModal: function(){
        this.$parent.closeModal()
    },

    deleteDepot: async function() {
    try{
        await this.$store.dispatch('accreditation/fetchAssociatedDrivers')
        location.reload()
      } catch(e){
        console.log(e)
      }
    },
    
  },
  computed: {
      ...mapGetters('theme', {
      theme: 'getColorScheme'
      })
  }
}
</script>
