<template>
  <div>
    <div class="overflow-y-auto flex flex-col border-b p-0 b-0">
      <p class="text-sm py-4 px-3">Document Part Settings</p>
    </div>

    <div v-if="currentSelected">
      <div v-if="currentSelected.type === 'Paragraph'" class="p-2 mt-2">
        <template>
          <ParagraphSettings
            :selectedType="currentSelected.type"
            :selectedUUID="currentSelected.uuid"
          />
        </template>
      </div>
      <div v-if="currentSelected.type === 'Closing'" class="p-2 mt-2">
        <template>
          <ClosingSettings
            :selectedType="currentSelected.type"
            :selected="currentSelected"
          />
        </template>
      </div>
      <div v-if="currentSelected.type === 'centeredParagraph'" class="p-2 mt-2">
        <template>
          <h2>CENTERED PARAGRAPH</h2>
        </template>
      </div>
      <div v-if="currentSelected.type === 'bulletPoints'" class="p-2 mt-2">
        <template>
          <BulletSettings
            :selectedType="currentSelected.type"
            :selectedUUID="currentSelected.uuid"
          />
        </template>
      </div>
      <div v-if="currentSelected.type === 'Headers'" class="p-2 mt-2">
        <template>
          <HeaderSettings
            :selectedType="currentSelected.type"
            :selected="currentSelected"
          />
        </template>
      </div>

      <div v-if="currentSelected.type === 'Title'" class="p-2 mt-2">
        <template>
          <TitleSettings
            :selectedType="currentSelected.type"
            :selectedUUID="currentSelected.uuid"
          />
        </template>
      </div>

      <div v-if="currentSelected.type === 'Addresses'">
        <template>
          <AddressesSettings
            :selectedType="currentSelected.type"
            :selectedUUID="currentSelected.uuid"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import ParagraphSettings from "./document-part-controller.vue";
import ParagraphSettings from "@/components/form-controls/paragraph-controls.vue";
import BulletSettings from "@/components/form-controls/bullet-points-controls.vue";
import HeaderSettings from "@/components/form-controls/header-controls.vue";
import ClosingSettings from "@/components/form-controls/closing-controls.vue";
import TitleSettings from "@/components/form-controls/title-controls.vue";
import AddressesSettings from "@/components/form-controls/addresses-controls.vue";

export default {
  components: {
    ParagraphSettings,
    BulletSettings,
    HeaderSettings,
    ClosingSettings,
    TitleSettings,
    AddressesSettings,
  },

  name: "settings-container",

  computed: {
    currentSelected: {
      get() {
        return this.$store.state.accreditation.selectedContainer;
      },
    },

    docParts: {
      get() {
        return this.$store.state.accreditation.docParts;
      },
      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },
  },

  watch: {
    currentSelected: function () {
      this.currentSelectedPart = this.currentSelected;
    },
  },
};
</script>

<style scoped></style>
