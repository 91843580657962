import { API } from "@/api/config";
import { ObjectToQueryString } from "@/_helper";

export default new (class GeotabUsers {
  getGeotabUsers({ query }) {
    let filter = "";
    if (typeof query.filter === "object")
      try {
        filter = JSON.stringify(query.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }
    query.filter = filter;
    if (query) query = "?" + ObjectToQueryString(query);
    return API.ACCOUNTS.get(
      `/web-profile-job/find-all-not-yet-synced-to-logmaster/${query}`
    );
  }
})();
