<template>
  <router-link :to="cardUrl" class="flex justify-between items-center bg-white overflow-hidden shadow-md cursor-pointer h-full transform hover:scale-105 p-4 transition ease-in-out delay-150 duration-150">
    <div class="font-bold text-lg">{{ cardTitle }}</div>
    <div>
      <font-awesome-icon icon="caret-right" />
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    cardTitle: {
      type: String,
      default: 'Card Link'
    },
    cardUrl: {
      type: String,
      default: '/'
    }
  }
}
</script>