import styled from 'vue-styled-components'

const iconProps = {
    fillColor: String, 
    fillHoverColor: String, 
    svgHeight: String,
    svgWidth: String,
}

export const StyledIconWrapper = styled("div", iconProps)`
    svg{
        width: ${({svgWidth}) => svgWidth};
        height: ${({svgHeight}) => svgHeight};

        path{
            fill: ${({fillColor}) => fillColor};
        }
    }
`