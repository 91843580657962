

export default {
    // @Driver
    setNewCreatedDriver(state, value){
        state.newCreatedDriver = value
    },
    setShowDriverProfileModal(state, value){
        state.showDriverProfileModal = value
    },
    setUpdateHeaderSites(state, value){
        state.updateHeaderSites = value
    }
}