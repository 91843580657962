<template>
  <div class="w-full">
    <div v-if="!isSuccess" class="h-full w-full">
      <div v-if="!isLoading" class="flex items-center p-4 bg-gray-100 rounded">
          <font-awesome-icon icon="info-circle" size="2x" class="text-blue"/>
          <strong class="ml-1 uppercase font-semibold text-sm">&nbsp; Search a form report.</strong>
      </div>
      <div v-if="isLoading" class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center">
          <font-awesome-icon icon="cog" spin size="4x" class="text-gray-400"/>
          <h6 class="mt-4">Searching driver details...</h6>
      </div>
    </div>

    <div v-if="isSuccess" class="flex w-full items-center driver-info-container">
      <div class="flex-1 flex">
        <div class="driver-photo">
          <div class="h-20 w-20 bg-gray-200 border rounded-full overflow-hidden border-3 border-gray-300 flex">
            <font-awesome-icon icon="user" class="text-4xl m-auto text-gray-600"/>
          </div>
        </div>
        <div class="flex-initial w-full px-4 driver-details">
          <span class="uppercase font-semibold flex items-center">
            <strong class="mr-1">{{ driverData.driverName || 'N/A' }}</strong>
            <span class="text-white text-xs mr-2 px-2 rounded" :style="{backgroundColor: theme.secondaryColor}">{{driverData['fatiguePlan'] || 'N/A'}}</span>
          </span>
          <div class="grid grid-cols-2 gap-x-4 mt-1 max-w-lg">
            <div class="text-gray-500 text-sm inline">
              <span><strong class="text-xs mr-1">UDI:</strong>{{ driverData.udi || 'N/A' }}</span>
            </div>
            <div class="text-gray-500 text-sm inline">
              <span><strong class="text-xs mr-1">Vehicle:</strong>{{ driverData['vehicle'] || 'N/A' }}</span>
            </div>
            <div class="text-gray-500 text-sm inline">
              <span><strong class="text-xs mr-1">Licence #:</strong>{{ driverData.driversLicenseNumber || 'N/A' }}</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="inline-flex">
        <button @click.prevent="sendReport()" class="flex justify-center items-center text-xs rounded-full hover:bg-blue text-gray-400 hover:text-white border border-gray-400 hover:border-blue p-2">
          <font-awesome-icon icon="envelope" class="h-3 w-3" />
          <span class="font-normal ml-1" title="Send Report to E-mail" v-if="false">Send</span>
        </button>
      </div> -->
    </div>

    <div v-if="isSuccess" class="w-full mt-5 compliance-tabs-wrapper">

      <div class="flex px-4">
        <div class="fl ex-initial w-64">
          <small v-if="result.formType">
            <strong>Form Type:&nbsp;</strong> 
              {{ result.formType }}
          </small>
        </div>
        <div class="fl ex-initial w-80">
          <small v-if="result.dateRange">
            <strong>Date Range:&nbsp;</strong> 
              {{ result.dateRange }}
          </small>
        </div>
        <div class="flex-initial w-48">
          <small>
            <strong>Found:&nbsp;</strong> 
              {{formsCount}} Item{{formsCount ? 's' : ''}}
          </small>
        </div>
      </div>

      <div class="overflow-x-auto relative shadow-md sm:rounded-lg mt-5">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                    <th scope="col" class="py-3 px-6">
                        Date Submitted
                    </th>
                    <th scope="col" class="py-3 px-6 bg-gray-50 dark:bg-gray-800">
                        Results
                    </th>
                    <th scope="col" class="py-3 px-6">
                        Status
                    </th>
                </tr>
            </thead>

            <tbody v-if="prestartData.length > 0">
              <tr v-for="(item, index) in prestartData" :key="index" class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td v-if="item.savedData" class="py-4 px-6">
                    {{ momentFormat(item.savedData.date) }}
                </td>
                <td class="py-4 px-6 bg-gray-50 dark:bg-gray-800">
                  <div
                    class="font-bold text-xs"
                    :class="[(item.isPassed) ? 'text-green-500' : 'text-red-500']"
                  >
                    VEHICLE CHECK {{item.isPassed ? 'PASSED' : 'FAILED'}}
                  </div>
                </td>
                <td class="py-4 px-6">
                  <div
                    class="font-bold text-xs"
                    :class="[(item.isPassed) ? 'text-green-500' : 'text-red-500']"
                  >
                    {{item.isPassed ? 'PASSED' : 'FAILED'}}
                  </div>
                </td>
              </tr>
            </tbody>
            
            <tbody v-if="fitToDriveData.length > 0">
              <tr v-for="(item, index) in fitToDriveData" :key="index" class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <td v-if="item.savedData" class="py-4 px-6">
                    {{ momentFormat(item.savedData.date) }}
                </td>
                <td class="py-4 px-6 bg-gray-50 dark:bg-gray-800">
                  <div
                    class="font-bold text-xs"
                    :class="[(item.isPassed) ? 'text-green-500' : 'text-red-500']"
                  >
                    {{item.isPassed ? 'FIT' : 'UNFIT'}} TO DRIVE
                  </div>
                </td>
                <td class="py-4 px-6">
                  <div
                    class="font-bold text-xs"
                    :class="[(item.isPassed) ? 'text-green-500' : 'text-red-500']"
                  >
                    {{item.isPassed ? 'PASSED' : 'FAILED'}}
                  </div>
                </td>
              </tr>
            </tbody>

            <tbody v-if="!prestartData.length && !fitToDriveData.length">
              <tr class="border-b border-gray-200 dark:border-gray-700">
                <td colspan="9" class="py-8 px-5 text-sm text-center">
                  No items found
                </td>
              </tr>
            </tbody>
        </table>

        <div v-if="formsCount" class="w-64 my-9 mx-auto">
          <PieChart
            :chartData="chartData" 
            :options="chartOptions"
            ref="parentChart"
          ></PieChart>
        </div>
      </div>
    </div>

    <Modal ref="modal" :title="modalTitle" size="lg" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <div class="mb-16">
          <div v-if="!sendReportloading">
            <FormulateForm v-model="sendReportModel" @submit="onSubmitSendReport()">
              <div class="items-center justify-between">
                <FormulateInput
                  type="email"
                  name="email"
                  label="Email address"
                  validation="required|email"
                  @input="handleStritEmailCase($event)"
                />

                <div>
                  <label class="font-medium text-sm mb-1">Report Date Range</label>
                  <DatePicker 
                    ref="childDatePicker" 
                    :date="selectedDateRange" 
                    @selected="selectedDateRange = $event" 
                    range noNavigation :clearable="false"
                    class="mb-5 mt-1"
                  />
                </div>

                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="btn btn-primary w-full"
                  :disabled="selectedDateRange[0] == null || selectedDateRange[1] == null || sendReportloading" 
                  :style="{backgroundColor: theme.secondaryColor}"
                />
              </div>
            </FormulateForm>
          </div>

          <div v-else class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center">
            <font-awesome-icon icon="cog" spin size="3x" class="text-gray-400"/>
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import moment from 'moment'
import { Modal } from '@/components/commons'
import DatePicker from '@/components/commons/field/DatePicker'
import PieChart from '@/components/commons/charts/PieChart'
import {removeStringUnderscore} from '@/_helper'
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name: "Forms-Report",
    components: { Modal, DatePicker, PieChart },
    props: {
      selectedDateRange: Array,
      selectedDriver: Object,
      formType: String
    },
    data() {
      return {
        isLoading: false,
        isSuccess: false,
        driverData: {},
        annotationData: [],
        modalTitle: '',
        modalContent: false,
        modalHeight: '50vh',
        sendReportModel: null,
        sendReportloading: false,
        prestartData: [],
        fitToDriveData: [],
        formsCount: 0,
        result: {
          formType: '',
          dateRange: '',
        },
        chartOptions: {
          hoverBorderWidth: 20
        },

        chartData: {
          hoverBackgroundColor: "red",
          hoverBorderWidth: 10,
          labels: ["Passed", "Failed"],
          datasets: [
            {
              label: "Data One",
              backgroundColor: ["#4ce089", "#ef4550"],
              data: [0, 0]
            }
          ]
        }
      }
    },
    methods: {
      async onSearch() {
        this.isLoading = true;
        this.isSuccess = false;

        const me = this.$store.getters[`account/me`];
        const businessId = me?.business?._id;

        const payload = {
          businessId: businessId,
          driverId: this.selectedDriver.driverId,
          startDate: moment(this.selectedDateRange[0], 'YYYY-MM-DD').format(),
          endDate: moment(this.selectedDateRange[1], 'YYYY-MM-DD').format()
        }

        let dynamicPromise = [this.$store.dispatch(`business/getContractByDriver`, this.selectedDriver.driverId)];

        switch (this.formType) {
          case 'prestart':
            dynamicPromise.push(this.$store.dispatch(`vehicle/getPrestartByDateRange`, payload))
          break
          case 'fitness_declaration':
            dynamicPromise.push(this.$store.dispatch(`driver/getFitToDriveByDateRange`, payload))
          break
        }

        await Promise.all(dynamicPromise).then(data => {
          let drivers = !_.isEmpty(data[0]) ? data[0].results : null
          let forms = !_.isEmpty(data[1]) ? data[1] : []
          this.formsCount = !_.isEmpty(data[1]) ? data[1].length : 0

          if (drivers)
            this.driverData = drivers[0]?.driver
          else {
            const me = this.$store.getters[`account/me`]
            if (me.role.name === 'driver') this.driverData = me.driver
          }

          if(forms){
            if(forms.length > 0) {
              const passedForms = forms.filter(item => item.isPassed == true)
              const failedForms = forms.filter(item => item.isPassed == false)
              this.passedFormsCount = passedForms.length;
              this.failedFormsCount = failedForms.length;

              // Set Data
              this.chartData.datasets[0].data = [this.passedFormsCount, this.failedFormsCount];
            }
          }

          this.prestartData = []
          this.fitToDriveData = []

          switch (this.formType) {
            case 'prestart':
              this.prestartData = data[1]
              this.result.formType = this.cleanFormType(this.formType)
            break
            case 'fitness_declaration':
              this.fitToDriveData = data[1]
              this.result.formType = this.cleanFormType(this.formType)
            break
          }
          this.result.dateRange = `${this.momentFormat(this.selectedDateRange[0], true)} to ${ this.momentFormat(this.selectedDateRange[1], true)}`
          this.driverData['driverDateOfBirth'] = moment(this.driverData['driverDateOfBirth']).format('YYYY-MM-DD');
        })

        this.isLoading = false
        this.isSuccess = true
      },
      sendReport(){
        this.$refs.modal.openModal()
        this.modalContent = 'new';
        this.modalTitle = 'Send Report To Email'
      },
      async onSubmitSendReport(){
        this.sendReportloading = true

        const pieChartImage = this.$refs.parentChart.saveImage()

        const me = this.$store.getters[`account/me`]
        const businessId = me?.business?._id

        const data = {
          recipientEmail: this.sendReportModel.email,
          driverId: this.selectedDriver.driverId,
          startDate: moment().format(`${this.selectedDateRange[0]}THH:mm:ssZ`),
          endDate: moment().format(`${this.selectedDateRange[1]}THH:mm:ssZ`),
          businessId: businessId,
          requestOrigin: 'web',
          formType: this.formType,
          pieChartImage: pieChartImage
        }

        await this.$store.dispatch(`business/sendFormsReport`, data)
            .then((res) => {
                this.toast('success', res.message)
            }).catch(err => {
                this.toast('error', err.message)
            });
      },
      handleStritEmailCase(value){
        this.sendReportModel.email = value.toLowerCase()
      },
      momentFormat(date, isShort = false){
        if(date){
          if(isShort) {
            return date ? moment(date).format('MMM D, YYYY') : '-'
          } else {
            return date ? moment(date).format('MMMM D, YYYY') : '-'
          }
        }
      },
      toast(state,message){
        this.sendReportloading = false
        this.$store.commit('setDialogNotify', true)
        this.$store.commit('setMessageNotify', {state, message})
      },
      cleanFormType(string){
        return removeStringUnderscore(string)
      },
  },
  computed: {
     ...mapGetters('theme', {
			theme: 'getColorScheme'
      })
    }
}
</script>