<template>
  <div>
    <div class="w-full" v-click-outside="onClickOutside">
      <Dropzone
        :dropzoneOpts="dropzoneOpts"
        :id="'portalLogo'"
        @getFile="getFile"
      >
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title text-sm" :style="{ color: 'white' }">
            Drag & drop or <u class="cursor-pointer">click</u> to browse to
            choose a {{ marketplaceItem.name }} logo
          </h3>
        </div>
      </Dropzone>
      <button
        class="upload-button w-full btn btn-primary text-sm text-blue-300"
        @click="handleSubmit"
        :disabled="!file || loading"
        :style="{ backgroundColor: theme.secondaryColor }"
      >
        <font-awesome-icon
          v-if="loading"
          :icon="['fas', 'spinner']"
          spin
          size="lg"
          class="text-white mr-2"
        />

        <span v-if="!loading">Upload</span>
        <span v-if="loading">Uploading . . .</span>
      </button>
      <div v-if="logoError" class="text-center text-red-700">
        <span class="mr-2"><font-awesome-icon icon="exclamation-circle" /></span
        >{{ logoError }}
      </div>
    </div>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import Dropzone from "@/components/commons/dropzone/dropzone.vue";
import { LOGO_FILE_UPLOAD_LIMIT_SIZE } from "@/_helper/constants";

export default {
  name: "Upload-Marketplace-Item-logo",
  components: { Dropzone },
  props: {
    id: { required: true },
  },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: "",
      hasErrorMessage: false,
      logoError: "",
      dropzoneOpts: {
        url: "https://httpbin.org/post",
        thumbnailWidth: null,
        thumbnailHeight: null,
        maxFilesize: LOGO_FILE_UPLOAD_LIMIT_SIZE,
        headers: { "My-Awesome-Header": "header value" },
        uploadMultiple: false,
        addRemoveLinks: false,
        maxFiles: 1,
        acceptedFiles: "",
        resizeHeight: "40px",
      },
      file: null,
      marketplaceItem: {},
    };
  },

  async mounted() {
    this.loading = true;
    let item = await this.$store.dispatch(
      `standardpricing/getStandardPricingMaster`,
      this.id
    );
    this.marketplaceItem = this.parseMarketplaceItem(item);
    this.loading = false;
  },
  methods: {
    async getFormData() {
      this.loading = false;
    },

    onClickOutside() {
      this.logoError = "";
    },

    closeModal() {
      this.$emit("closeModal");
    },

    getFile(file) {
      this.file = file;
    },

    parseMarketplaceItem(item) {
      return {
        name: item["name"],
        apiTag: item["apiTag"],
        category: item["category"],
        type: item["type"],
        description: item["description"],
        monthly: item["pricing"]["monthly"],
        yearly: item["pricing"]["yearly"],
        monthlyRrp: item["rrp"]["monthly"],
        yearlyRrp: item["rrp"]["yearly"],
        logo: item["logo"],
      };
    },

    async handleSubmit() {
      this.loading = true;
      if (this.file.size / (1024 * 1024) > LOGO_FILE_UPLOAD_LIMIT_SIZE) {
        this.loading = false;
        this.logoError = "File size exceed limit! Please choose another file";
        return;
      }

      let image = new FormData();

      image.append("standardPricingMasterId", this.id);
      image.append("file", this.file);

      await this.$store
        .dispatch("standardpricing/uploadLogo", image)
        .then((res) => {
          if (res.success) {
            this.loading = false;
            this.closeModal();
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          if ([413].includes(err.stateCode)) {
            this.logoError = err.message;
          }
          this.loading = false;
        });
    },
  },
  computed: {
    theme() {
      return this.$store.getters["theme/getColorScheme"];
    },
  },
};
</script>
