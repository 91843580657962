<template>
  <div>
    <div v-for="(additionalIndex, index) in additionalIndeces" :key="index">
      <div>
        <div class="flex justify-end">
          <div v-if="latestIndex === index" class="flex justify-end">
            <font-awesome-icon
              icon="times"
              class="my-auto text-xl mx-1.5 hover:text-orange-primary cursor-pointer"
              @click.prevent="deleteAdditional(afmRuleIndex, index)"
            />
          </div>
        </div>

        <AdditionalField
          :dataField="additionalRule[additionalIndex]"
          :afmRuleIndex="afmRuleIndex"
          :currentAdditionalIndex="index"
          :isDisabled="latestIndex === index"
          @addAdditionals="addAdditionals"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AdditionalField from './additional-field.vue';
export default {
  name: 'additional-containers',

  components: { AdditionalField },

  computed: {
    additionalRule() {
      return this.$store.state.accreditation.additionalRules;
    },
  },

  props: {
    afmRuleIndex: {
      type: Number,
      default: () => {},
    },

    additionalIndeces: {
      type: Array,
      default: () => {},
    },

    latestIndex: {
      type: Number,
    },
  },

  methods: {
    deleteAdditional(afmRuleIndex, addtionalIndex) {
      this.$emit('deleteAdditional', afmRuleIndex, addtionalIndex);
    },

    addAdditionals(afmIndex, type) {
      this.$emit('addAdditionals', afmIndex, type);
    },
  },
};
</script>

<style scoped></style>
