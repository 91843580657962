<template>
  <div v-if="rendered" class="h-full flex flex-col">
    <button
      class="btn btn-primary p-0 mb-3"
      @click.prevent="addDocuments()"
      :style="{ backgroundColor: theme.secondaryColor }"
    >
      Add Documents
    </button>
    <Tabs clickEvent @click="fetchTab" class="flex flex-col h-full">
      <Tab title="Induction Letters" class="flex-1 flex flex-col">
        <div>
          <div v-if="!loading">
            <Table
              :tableHeader="inductionHeaders"
              :paginationSettings="paginationSettings"
              :data="inductionData"
              @onPageChange="onPageChange($event)"
            >
              <template #tableFilters>
                <div class="flex flex-row mb-1 sm:mb-0">
                  <div class="relative">
                    <select
                      v-model="filter.limit"
                      @change="filterChange"
                      class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="50">50</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <font-awesome-icon
                        icon="caret-down"
                        class="text-gray-400"
                      />
                    </div>
                  </div>

                  <div class="relative">
                    <select
                      v-model="filter.isActive"
                      @change="filterChange"
                      class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option :value="'all'">All</option>
                      <option :value="true">Active</option>
                      <option :value="false">Inactive</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <font-awesome-icon
                        icon="caret-down"
                        class="text-gray-400"
                      />
                    </div>
                  </div>

                  <div class="relative">
                    <span
                      class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                    >
                      <font-awesome-icon icon="search" class="text-gray-400" />
                    </span>
                    <input
                      placeholder="Search"
                      class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                      v-model="filter.search"
                      @keydown="handleSearchKeyDown"
                    />
                  </div>
                </div>
              </template>

              <template #default="{ data }">
                <td class="px-5 py-2 text-center relative">
                  <Dropdown ref="dropdown">
                    <ul class="py-2 text-sm">
                      <li>
                        <StyledIconedLink
                          class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                          @click.prevent="viewFileUrl(data.document)"
                          :iconHoverColor="theme.secondaryColor"
                        >
                          <font-awesome-icon icon="eye" class="my-auto mr-2" />
                          View
                        </StyledIconedLink>
                      </li>
                      <li v-if="data.isActive">
                        <StyledIconedLink
                          class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                          @click.prevent="toggleRegenerate(data)"
                          :iconHoverColor="theme.secondaryColor"
                        >
                          <font-awesome-icon
                            icon="certificate"
                            class="my-auto mr-2"
                          />
                          Toggle
                        </StyledIconedLink>
                      </li>
                    </ul>
                  </Dropdown>
                </td>
              </template>
            </Table>
          </div>
          <div v-else class="p-2 w-full text-center text-lg">
            <font-awesome-icon
              icon="circle-notch"
              spin
              size="2x"
              class="text-gray-400"
            />
          </div>
        </div>
      </Tab>

      <Tab title="Medical Certificates" class="flex-1 flex flex-col">
        <div>
          <div v-if="!loading">
            <Table
              :tableHeader="tableHeader"
              :paginationSettings="paginationSettings"
              :data="documentData"
              @onPageChange="onPageChange($event)"
            >
              <template #tableFilters>
                <div class="flex flex-row mb-1 sm:mb-0">
                  <div class="relative">
                    <select
                      v-model="filter.limit"
                      @change="filterChange"
                      class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="50">50</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <font-awesome-icon
                        icon="caret-down"
                        class="text-gray-400"
                      />
                    </div>
                  </div>

                  <div class="relative">
                    <select
                      v-model="filter.isActive"
                      @change="filterChange"
                      class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option :value="'all'">All</option>
                      <option :value="true">Active</option>
                      <option :value="false">Inactive</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <font-awesome-icon
                        icon="caret-down"
                        class="text-gray-400"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <template #default="{ data }">
                <td class="px-5 py-2 text-center relative">
                  <Dropdown ref="dropdown">
                    <ul class="py-2 text-sm">
                      <li>
                        <StyledIconedLink
                          class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                          @click.prevent="viewFileUrl(data.document)"
                          :iconHoverColor="theme.secondaryColor"
                        >
                          <font-awesome-icon icon="eye" class="my-auto mr-2" />
                          View
                        </StyledIconedLink>
                      </li>
                    </ul>
                  </Dropdown>
                </td>
              </template>
            </Table>
          </div>
          <div v-else class="p-2 w-full text-center text-lg">
            <font-awesome-icon
              icon="circle-notch"
              spin
              size="2x"
              class="text-gray-400"
            />
          </div>
        </div>
      </Tab>

      <Tab title="Training Certificates" class="flex-1 flex flex-col">
        <div>
          <div v-if="!loading">
            <Table
              :tableHeader="tableHeader"
              :paginationSettings="paginationSettings"
              :data="documentData"
              @onPageChange="onPageChange($event)"
            >
              <template #tableFilters>
                <div class="flex flex-row mb-1 sm:mb-0">
                  <div class="relative">
                    <select
                      v-model="filter.limit"
                      @change="filterChange"
                      class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="50">50</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <font-awesome-icon
                        icon="caret-down"
                        class="text-gray-400"
                      />
                    </div>
                  </div>

                  <div class="relative">
                    <select
                      v-model="filter.isActive"
                      @change="filterChange"
                      class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option :value="'all'">All</option>
                      <option :value="true">Active</option>
                      <option :value="false">Inactive</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <font-awesome-icon
                        icon="caret-down"
                        class="text-gray-400"
                      />
                    </div>
                  </div>
                </div>
              </template>

              <template #default="{ data }">
                <td class="px-5 py-2 text-center relative">
                  <Dropdown ref="dropdown">
                    <ul class="py-2 text-sm">
                      <li>
                        <StyledIconedLink
                          class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                          :iconHoverColor="theme.secondaryColor"
                          @click.prevent="viewFileUrl(data.document)"
                        >
                          <font-awesome-icon icon="eye" class="my-auto mr-2" />
                          View
                        </StyledIconedLink>
                      </li>
                      <li>
                        <StyledIconedLink
                          class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                          :iconHoverColor="theme.secondaryColor"
                          @click.prevent="updateCertActivity(data)"
                        >
                          <font-awesome-icon icon="eye" class="my-auto mr-2" />
                          {{ data.isActive ? "Deactivate" : "Activate" }}
                        </StyledIconedLink>
                      </li>
                    </ul>
                  </Dropdown>
                </td>
              </template>
            </Table>
          </div>
          <div v-else class="p-2 w-full text-center text-lg">
            <font-awesome-icon
              icon="circle-notch"
              spin
              size="2x"
              class="text-gray-400"
            />
          </div>
        </div>
      </Tab>
    </Tabs>
    <Modal
      ref="modal"
      :title="modalTitle"
      :size="modalSize"
      :height="modalHeight"
    >
      <div v-if="modalContent === 'consentDocuments'">
        <div v-if="!consent">
          <div class="mb-16">
            <div class="btns">
              <div class="pb-2">
                <button
                  class="btn btn-primary w-full"
                  @click="consentDocument"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  YES
                </button>
              </div>
              <button
                class="border-2 btn btn-secondary w-full"
                @click="closeModal"
              >
                NO
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <h2>You have already shared this document</h2>
        </div>
      </div>

      <div v-if="modalContent === 'regenerate'">
        <div class="text-center my-4 w-full">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Only generate if letter is outdated or did not generate
          </h3>
        </div>

        <div class="mt-16">
          <div class="btns">
            <div class="pb-2">
              <button
                class="btn btn-primary w-full"
                @click="regenerateInduction"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                YES
              </button>
            </div>
            <button
              :disabled="hasErrors || loading"
              class="btn btn-primary"
              @click.prevent="onPickMed"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              NO
            </button>
          </div>
        </div>
      </div>
      <div v-if="modalContent === 'addDocument'">
        <FormulateForm @submit="onSubmit" #default="{ hasErrors }">
          <div class="pb-10 border-2 p-6 rounded-lg mb-6">
            <FormulateInput type="group" name="medCertData">
              <FormulateInput
                type="file"
                name="medCertFile"
                label="Upload Medical Certificate"
                accept="application/pdf"
                validation="mime:application/pdf"
                v-model="medCert"
                label-class="formulate-input-label formulate-input-label--before"
                outer-class="formulate-input"
                input-class=""
                wrapper-class="formulate-input-wrapper"
                upload-area-class="formulate-input-upload-area"
                upload-area-mask-class="formulate-input-upload-area-mask"
                @file-removed="clearInputFile('med')"
              />
              <div class="flex justify-center p-2">
                <div class="w-1/2">
                  <p class="font-medium text-sm mb-1">Expiry Date</p>
                </div>
                <FormulateInput
                  v-model="medExpiryToggle"
                  type="checkbox"
                  name="expire"
                />
              </div>

              <div v-if="medExpiryToggle">
                <FormulateInput
                  type="date"
                  label="Medical Certificate Expiry"
                  name="expiryDate"
                  required
                />
              </div>
            </FormulateInput>
          </div>
          <div class="pb-10 border-2 p-6 rounded-lg">
            <FormulateInput type="group" name="trainingCertData">
              <FormulateInput
                type="file"
                name="trainingCertFile"
                label="Upload Training Certificate"
                accept="application/pdf"
                validation="mime:application/pdf"
                v-model="trainingCert"
                label-class="formulate-input-label formulate-input-label--before"
                outer-class="formulate-input"
                input-class=""
                wrapper-class="formulate-input-wrapper"
                upload-area-class="formulate-input-upload-area"
                upload-area-mask-class="formulate-input-upload-area-mask"
                @file-removed="clearInputFile('training')"
              />

              <div class="flex justify-center p-2">
                <div class="w-1/2">
                  <p class="font-medium text-sm mb-1">Expiry Date</p>
                </div>
                <FormulateInput
                  v-model="trainingExpiryToggle"
                  type="checkbox"
                  name="expire"
                />
              </div>

              <div v-if="trainingExpiryToggle">
                <FormulateInput
                  type="date"
                  label="Training Certificate Expiry"
                  name="expiryDate"
                  required
                />
              </div>
            </FormulateInput>
          </div>

          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full mt-3"
            :disabled="hasErrors"
          />

          <div class="text-red-500 text-center mb-3" v-if="errorMessage">
            {{ errorMessage }}
          </div>
        </FormulateForm>
      </div>
    </Modal>
  </div>
  <div v-else class="p-2 w-full text-center text-lg">
    <font-awesome-icon
      icon="circle-notch"
      spin
      size="2x"
      class="text-gray-400"
    />
  </div>
</template>

<script>
import { Table, Modal, Dropdown } from "@/components/commons";
import { filterRow, formatPaginationSettings, viewFileUrl } from "@/_helper/";
import { formatDate } from "@/_helper";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
  ROLES
} from "@/_helper/constants";
import Tabs from "@/components/commons/tab/Tabs";
import Tab from "@/components/commons/tab/Tab";
import { mapGetters } from "vuex";
import _ from "lodash";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "driver-documents",
  components: { Table, Modal, Dropdown, Tabs, Tab, StyledIconedLink },

  async mounted() {
    const me = await this.$store.dispatch(`account/getMe`);
    this.soloDriver = await this.$store.dispatch(
      `driver/getDriverByEmail`,
      me.email
    );
    await this.fetchTab(this.currentTab);
  },

  data() {
    return {
      tableHeader: ["Document", "Status", "Created On"],
      inductionHeaders: ["Cert Number", "Type", "Status", "Created On"],
      data: false,

      soloDriver: false,
      certificates: false,
      searchKeyword: "",

      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },

      filter: {
        limit: 10,
        search: "",
        isActive: "all",
      },

      certToConsent: "",
      consent: false,

      loading: true,
      modalTitle: "",
      modalSize: "2x1",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalContent: "",

      medCert: null,
      trainingCert: null,

      hasErrors: false,
      currentTab: "Induction Letters",

      medExpiryToggle: false,
      trainingExpiryToggle: false,

      errorMessage: "",

      accreditationToRegenerate: null,

      rendered: true,
    };
  },

  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },

  methods: {
    async toggleRegenerate(data) {
      this.accreditationToRegenerate = data;
      this.$refs.modal.openModal();
      this.modalContent = "regenerate";
      this.modalTitle = "Regenerate Letter";
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
      this.modalSize = "2xl";
    },

    onPickMed() {
      this.$refs.medCertFile.click();
    },

    onPickTrain() {
      this.$refs.trainingFile.click();
    },

    clearInputFile(type) {
      if (type == "med") {
        this.medCert = null;
      } else {
        this.trainingCert = null;
      }
    },

    async onPageChange(event) {
      if (event.page) {
        await this.fetchTab(this.currentTab, undefined, event.page);
      }
    },

    async filterChange() {
      await this.fetchTab(this.currentTab);
    },

    /* eslint-disable */
    async fetchTab(currentTab, tabIndex = 0, paged = 1) {
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        filter: this.filter,
        documentType: "",
      };

      this.currentTab = currentTab;
      this.loading = true;

      const me = await this.$store.dispatch(`account/getMe`);
      const driver = await this.$store.dispatch(
        `driver/getDriverByEmail`,
        me.email
      );

      switch (currentTab) {
        case "Induction Letters":
          query.filter.documentType = "INDUCTION_LETTER";
          break;
        case "Medical Certificates":
          query.filter.documentType = "DRIVER_MEDICAL";
          break;

        case "Training Certificates":
          query.filter.documentType = "DRIVER_TRAINING";
          break;
      }

      const certs = await this.$store.dispatch(
        `accreditation/fetchUserDocuments`,
        {
          id: driver._id,
          query,
        }
      );

      if (certs?.data?.data?.resultsMeta) {
        this.paginationSettings = formatPaginationSettings(
          certs?.data?.data?.resultsMeta
        );
      }

      if (certs?.data?.data?.results) {
        this.certificates = JSON.parse(JSON.stringify(certs.data.data.results));
      } else {
        this.certificates = [];
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
      this.loading = false;
      return;
    },

    async toggleConsentDocument(id) {
      const consent = await this.$store.dispatch(
        `accreditation/checkConsentDriverUserDocument`,
        { docId: id, driverId: this.soloDriver._id }
      );
      this.consent = consent.data.data;

      this.$refs.modal.openModal();
      this.modalTitle = "Consent Document";
      this.modalContent = "consentDocuments";
      this.certToConsent = id;

      this.modalHeight = "30vh";
      this.modalSize = "2xl";
    },

    closeModal() {
      this.$refs.modal.closeModal();
    },

    async consentDocument() {
      const me = await this.$store.dispatch(`account/getMe`);
      const driver = await this.$store.dispatch(
        `driver/getDriverByEmail`,
        me.email
      );

      await this.$store.dispatch(`accreditation/consentDriverUserDocument`, {
        docId: this.certToConsent,
        driverId: driver._id,
      });
      this.closeModal();
      this.fetchTab(this.currentTab);
      this.$forceUpdate();
    },

    getDocType(docType) {
      let type;
      switch (docType) {
        case "DRIVER_MEDICAL":
          type = "Medical Cert";
          break;
        case "DRIVER_TRAINING":
          type = "Training Cert";
          break;
        case "INDUCTION_LETTER":
          type = "Induction Letter";
          break;
        default:
          type = "";
      }
      return type;
    },

    addDocuments() {
      this.$refs.modal.openModal();
      this.modalContent = "addDocument";
      this.modalTitle = "Add Document";
      this.modalSize = "2xl";
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
    },

    parseDocuments(data) {
      if (data) {
        return this.certificates.map((driverData) => {
          return [
            {
              id: driverData._id,
              name: driverData._id,
              itemType: "string",
              document: driverData.document,
              type: this.getDocType(driverData.documentType),
              isActive: driverData.isActive,
            },
            {
              id: driverData._id,
              name: this.getDocStatus(driverData.isActive),
              itemType: "string",
              document: driverData.document,
              type: this.getDocType(driverData.documentType),
              isActive: driverData.isActive,
            },
            {
              id: driverData._id,
              name: formatDate(driverData.createdAt, "DD/MM/YYYY"),
              itemType: "string",
              document: driverData.document,
              type: this.getDocType(driverData.documentType),
              isActive: driverData.isActive,
            },
          ];
        });
      }
      return [];
    },

    triggerLoading(status) {
      this.$emit("updateLoading", status);
      this.loading = status;
    },

    onFilePicked(event, cert) {
      const files = event.target.files;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.pdf = fileReader.result;
      });
      this.pdf = files[0];

      if (cert === "med") {
        this.medCert = files[0];
      }

      if (cert === "train") {
        this.trainingCert = files[0];
      }
    },

    async onSubmit(data) {
      const medCertFile = _.get(
        data,
        "medCertData[0].medCertFile.fileList[0]",
        null
      );

      const trainingCertFile = _.get(
        data,
        "trainingCertData[0].trainingCertFile.fileList[0]",
        null
      );

      const me = await this.$store.dispatch(`account/getMe`);
      const driver = await this.$store.dispatch(
        `driver/getDriverByEmail`,
        me.email
      );

      if (medCertFile || trainingCertFile) {
        try {
          this.loading = true;

          let medCertPayload = null;
          let trainingCertPayload = null;

          if (medCertFile) {
            medCertPayload = {
              fileType: "DRIVER_MEDICAL",
              userMongoId: driver._id,
              userType: ROLES.DRIVERS,
              file: medCertFile,
            };

            if (this.medExpiryToggle) {
              const expiryDate = _.get(data, "medCertData[0].expiryDate", null);
              if (!expiryDate) {
                this.errorMessage = "Medical Certificate details incomplete";
                this.triggerLoading(false);

                return;
              }
              medCertPayload["expiryDate"] = expiryDate;
            }
          }

          if (trainingCertFile) {
            trainingCertPayload = {
              fileType: "DRIVER_TRAINING",
              userMongoId: driver._id,
              userType: ROLES.DRIVERS,
              file: trainingCertFile,
            };

            if (this.trainingExpiryToggle) {
              const expiryDate = _.get(data, "trainingCertData[0].expiryDate");
              if (!expiryDate) {
                this.errorMessage = "Training Certificate details incomplete";
                this.triggerLoading(false);
                return;
              }
              trainingCertPayload["expiryDate"] = expiryDate;
            }
          }

          await this.$store.dispatch(`accreditation/uploadUserDocument`, {
            medCertPayload,
            trainingCertPayload,
          });
          this.loading = false;
          this.medCert = false;
          this.trainingCert = false;
          this.toast("success", "Document succesfully added");
          this.closeModal();
          this.$forceUpdate();
          this.fetchTab(this.currentTab);
        } catch (e) {
          this.triggerLoading(false);
          this.toast("error", "Something went wrong");
          throw e;
        }
      } else {
        this.errorMessage = "No documents selected";
        return;
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    async regenerateInduction() {
      try {
        const me = await this.$store.dispatch(`account/getMe`);
        const driver = await this.$store.dispatch(
          `driver/getDriverByEmail`,
          me.email
        );

        const docData = this.accreditationToRegenerate;

        const query = {
          filter: {
            businessId: docData.businessId,
            accreditationType: "BFM",
          },
        };

        const cert = await this.$store.dispatch(
          "accreditation/fetchCertificationByCertNumber",
          { certNumber: this.accreditationToRegenerate, query }
        );

        const certId = cert.data.data[0]._id;

        this.rendered = false;

        await this.$store.dispatch(`accreditation/regenerate`, {
          certId: certId,
          userId: driver._id,
        });

        this.rendered = true;

        this.toast("success", "Letter regenerated");
      } catch (e) {
        this.toast("error", `${e.message}`);
      }
    },

    getDocStatus(status) {
      switch (status) {
        case true:
          return "Active";
        case false:
          return "Inactive";
      }
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    async debouncedSearchString() {
      await this.fetchTab(this.currentTab);
    },

    parseInduction(data) {
      if (data) {
        return this.certificates.map((docData) => {
          return [
            {
              id: docData._id,
              name: docData.certNumber,
              itemTtype: "string",
              document: docData.document,
              isActive: docData.isActive,
              certNumber: docData.certNumber,
              businessId: docData.businessId,
              accreditationType: docData.accreditationType,
            },
            {
              id: docData._id,
              name: docData.accreditationType,
              itemTtype: "string",
              document: docData.document,
            },
            {
              id: docData._id,
              name: this.getDocStatus(docData.isActive),
              itemType: "string",
              document: docData.document,
            },
            {
              id: docData._id,
              name: formatDate(docData.createdAt, "DD/MM/YYYY"),
              itemType: "string",
              document: docData.document,
            },
          ];
        });
      }
    },

    viewFileUrl(url) {
      viewFileUrl(url);
    },

    async updateCertActivity(cert) {
      try {
        this.loading = true;
        await this.$store.dispatch("accreditation/updateActivity", cert.id);
        await this.fetchTab(this.currentTab);
        this.toast("success", "Activity Updated");
        this.loading = false;
      } catch (error) {
        this.toast("error", "Something went wrong");
        this.loading = false;
      }
    },
  },

  computed: {
    documentData() {
      if (this.certificates) {
        let tableData = this.certificates;
        if (this.searchKeyword) {
          tableData = tableData.filter((item) =>
            filterRow(this.searchKeyword, item.linkageId)
          );
        }
        return this.parseDocuments(tableData);
      } else {
        return [];
      }
    },

    inductionData() {
      if (this.certificates && this.certificates.length > 0) {
        let tableData = this.certificates;
        if (this.searchKeyword) {
          tableData = tableData.filter((item) =>
            filterRow(this.searchKeyword, item.linkageId)
          );
        }
        return this.parseInduction(tableData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style scoped>
input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
</style>
