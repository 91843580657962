<template>
  <div class="mb-16">
    <FormulateForm v-model="values" :schema="schema" @submit="onSubmit">
      <StyledSubmit :backgroundColor="theme.secondaryColor">
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-full"
          :disabled="submitLoading"
          :style="{backgroundColor: theme.secondaryColor}"
          >
          <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving data...</span>  
          <span v-if="!submitLoading">Submit</span>  
        </FormulateInput>
      </StyledSubmit>
    </FormulateForm>
  </div>
</template>

<script>
import schema from './new-root-region-schema'
import { mapGetters } from 'vuex'
import { StyledSubmit } from '@/styled-components'

export default {
  name: 'New-Root-Region-Form',
  props: {
    tree: Object,
    newLeaf: Object,
    entityId: String,
  },
  components: { StyledSubmit },
  data() {
    return {
      error:false,
      errorMessage: '',
      loading: false,
      values: {},
      schema: schema,
      newTree: false,
      submitLoading: false,
    };
  },
  mounted(){
    this.newTree = this.tree
  },
  methods: {
    async onSubmit(data) {
      this.submitLoading = true
      let newNode = {
        children: [],
        isLeaf: false,
        name: data.regionName,
        entityId: this.entityId,
        type: "",
        id: new Date().valueOf(),
        isRoot: false,
        isActive: true,
        pid: this.newLeaf.pid,
        editNodeDisabled: false,
        dragDisabled: true,
        isRegion: true
      }
      this.newTree.addChildren(newNode)
      await this.getNewTree()
      this.submitLoading = false
    },

    async getNewTree() {
      var vm = this
      function _dfs(oldNode) {
        var newNode = {}

        for (var k in oldNode) {
          if (k !== 'children' && k !== 'parent') {
            newNode[k] = oldNode[k]
          }
        }

        if (oldNode.children && oldNode.children.length > 0) {
          newNode.children = []
          for (var i = 0, len = oldNode.children.length; i < len; i++) {
            newNode.children.push(_dfs(oldNode.children[i]))
          }
        }
        return newNode
      }

      vm.newTree = _dfs(vm.newTree)
      await this.createDepot(vm.newTree.children)
    },

    async createDepot(tree) {
      await this.$store.dispatch('business/createDepot', tree[tree.length-1])
      .then(()=> {
        this.$emit('refetch')
        this.$emit('closeModal')
        this.$store.commit('setDialogNotify',true)
        this.$store.commit('setMessageNotify',{state:'success',message:'New region successfully added.'})
        this.submitLoading = false
      })
      .catch(err => {
        this.$emit('refetch')
        this.$emit('closeModal')
        this.$store.commit('setDialogNotify',true)
        this.$store.commit('setMessageNotify',{state:'error',message: err.message})
        this.submitLoading = false
      })
    }
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
}
</script>