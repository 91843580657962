import VueCookies from 'vue-cookies'

export default{
    accessToken: VueCookies.get('accessToken') || '',
    accessProxyToken: VueCookies.get('accessProxyToken') || '',
    refreshToken: VueCookies.get('refreshToken') || '',
    refreshProxyToken: VueCookies.get('refreshProxyToken') || '',
    firebaseToken:"",
    me: {},
    mainRole: VueCookies.get('mainRole') || '',
    roleName: '',
    role: {},
    roleNames: [],
    isProxy: VueCookies.get('proxyUser') || '',
    proxyRole: VueCookies.get('proxyRole') || '',
    proxySite: VueCookies.get('proxySite') || null,
    proxyDriverProfileUid: VueCookies.get('proxyDriverProfileUid') || '',
    // @Partner
    newCreatedPartner: {},
    // @Business
    newCreatedBusiness: {},
    // @Driver
    newCreatedDriver: {},
    // @Roles & Respos
    securityRoles: VueCookies.get("securityRoles") || [],
    soloNoContract: false,
    disabledSubscription: false,
    // @Geotab : State to determine if the account belongs to a geotab account
    isGeotabAccount: false
}