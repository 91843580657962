<template>
  <div class="-mt-8 mb-8">
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="vehiclesData"
      @onPageChange="onPageChange($event)"
    >
      <template #default="{ data }">
        <td class="px-5 py-2">
          <FormulateInput
            class="mt-3"
            type="general-select"
            element-class="w-40"
            placeholder="Do nothing"
            :options="depots"
            :getOptionKey="(option) => option._id"
            :getOptionLabel="(option) => option.name"
            validation="required"
            :clearable="false"
            @input="selectSite($event, data)"
          >
            <template slot="option" slot-scope="option">
              <div class="text-sm">
                <h3 class="m-0 font-semibold">{{ option.value }}</h3>
                <em class="text-xs">{{ option.label }}</em>
              </div>
            </template>
          </FormulateInput>
        </td>
      </template>
    </Table>

    <FormulateForm v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-full"
          :disabled="isSubmitting || isLoading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="isSubmitting"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" />
            Submitting...</span
          >
          <span v-if="!isSubmitting">Submit</span>
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { orderBy, findIndex, isEmpty } from "lodash";
import { mapGetters } from "vuex";
import { Table } from "@/components/commons";
import { formatDate, formatPaginationSettings } from "@/_helper";

export default {
  name: "Move-Vehicle",
  props: {
    preData: null,
    detailsId: null,
  },
  components: {
    Table,
  },
  data() {
    return {
      isLoading: false,
      isSubmitting: false,
      values: {},
      drivers: [],
      vehicles: [],
      errorMessage: "",
      updatedVehicles: [],
      depots: [],
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      tableHeader: ["Plate Number", "Registration Expiry"],
      me: null,
      businessSite: null,
    };
  },
  async mounted() {
    if (!isEmpty(this.preData["drivers"])) {
      this.drivers = this.preData["drivers"];
    }

    this.me = this.$store.getters[`account/me`];
    await Promise.all([
      this.$store.dispatch(`business/getBusinessBySiteId`, this.detailsId, {
        root: true,
      }),
      this.$store.dispatch(`business/getSites`, {
        depotId: this.me?.business?._id,
        isDeleted: false,
      }),
    ]).then((data) => {
      this.businessSite = data[0];
      this.depots = data[1].filter((x) => x._id != this.detailsId);
    });

    this.init();
  },
  methods: {
    async init(paged = 1) {
      this.isLoading = true;

      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        filter: { siteId: this.detailsId },
      };

      let vehicle = await this.$store.dispatch(`vehicle/getVehicleByEntityId`, {
        entityId: this.businessSite._id,
        query,
      });
      if (vehicle) {
        if (vehicle?.metadata) {
          this.paginationSettings = formatPaginationSettings(vehicle?.metadata);
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const vehicleTableData = this.formatItem(
          orderBy(vehicle.results, ["createdAt"], ["desc"])
        );
        this.vehicles = vehicleTableData;

        this.$emit("updateLoading", false);
      } else {
        this.$emit("updateLoading", false);
      }
      this.isLoading = false;
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const vehicle = {
            id: item._id,
            vehiclePlate: item?.vehiclePlate,
            registrationExpiry: formatDate(
              item?.registrationExpiry,
              "DD/MM/YYYY"
            ),
            gvm: item?.GMV,
            vehicleClass: item?.vehicleClass?.name,
            vehicleType: item?.vehicleType?.name,
            created: formatDate(item.createdAt),
            status: item?.isActive,
          };
          nItems.push(vehicle);
        });
      }
      return nItems;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    parseVehicles(data) {
      return data.map((vehicle) => [
        {
          id: vehicle.id,
          name: vehicle.vehiclePlate,
          itemType: "string",
        },
        {
          id: vehicle.id,
          name: vehicle.registrationExpiry,
          itemType: "string",
        },
      ]);
    },
    async onSubmit() {
      this.isSubmitting = true;
      const payload = {
        siteId: this.detailsId,
        businessId: this.businessSite._id,
        rootBusinessId: this.me?.business?._id,
        drivers: this.drivers,
        vehicles: Object.values(this.updatedVehicles),
      };
      await this.$store
        .dispatch(`business/moveSiteDriverVehicle`, payload)
        .then(() => {
          this.$emit("closeModal");
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: `Site's driver and/or vehicle has been moved.`,
          });
        })
        .catch((err) => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
        });

      this.isSubmitting = false;
    },
    selectSite(site, vehicle) {
      const vehicleIndex = findIndex(this.vehicles, (x) => x.id == vehicle.id);

      const updatedVehicle = this.vehicles[vehicleIndex];
      this.updatedVehicles[updatedVehicle.id] = {
        vehicleId: updatedVehicle.id,
        siteId: site._id,
      };
    },
  },
  computed: {
    vehiclesData() {
      return this.vehicles ? this.parseVehicles(this.vehicles) : [];
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
