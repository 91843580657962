import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class FitToDrive {
  getFitToDriveByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
    let filter = '';
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/fit-to-drive/find-by-businessId/${businessId}${query}`)
  }

  getFitToDriveById( query ){
    return API.ACCOUNTS.get(`/fit-to-drive/${query}`);
  }

  getOldFitToDriveById( query ){
    return API.ACCOUNTS.get(`/fit-to-drive/old/${query}`);
  }

  saveNote(payload){
    return API.ACCOUNTS.post(`/fit-to-drive/note/${payload.id}`, payload);
  }
}