<template>
  <div class="mb-16">
      <div class="w-full" v-if="loading">
        <div class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center">
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300">Fetching data...</span>
        </div>
      </div>
      <FormulateForm v-model="values" :schema="schema" @submit="onSubmit" #default="{ hasErrors } ">
        <div class="items-center justify-between">
          <FormulateInput
            type="checkbox"
            label="Include UID?"
            name="isIncludingUid"
            v-model="values.isIncludingUid"
            class="formulate-input"
            label-class="formulate-input-label formulate-input-label--after"
            input-class=""
            wrapper-class="formulate-input-wrapper"
            outer-class=""
            element-class="formulate-input-element formulate-input-element--checkbox"
            help="*Check this box if you want to include the UID of the driver in the link"
          />          
          <FormulateInput
            type="checkbox"
            label="Is Main Menu?"
            name="isMainMenu"
            v-model="values.isMainMenu"
            class="formulate-input"
            label-class="formulate-input-label formulate-input-label--after"
            input-class=""
            wrapper-class="formulate-input-wrapper"
            outer-class=""
            element-class="formulate-input-element formulate-input-element--checkbox"
            help="*Check this box if you want to the link to be shown on the main menu instead of the integration link sub-menu"
          />
          <FormulateInput
            v-if="values.httpRequestMethod"
            type="select"
            name="requestType"
            label="Request Type"
            v-model="values.httpRequestMethod"
            :options="requestTypes"
            placeholder="Select a request type"
          />
          <div class="mb-4" v-if="values.queryParameters">
            <div class="mb-2 font-semibold text-sm block form-label text-neutral-dark">
              <h4>Query Parameters</h4>
            </div>
            <div class="border rounded p-5">
              <template v-for="(queryParameter,index) in editQueryParameters">
                <div class="flex space-x-4 mb-4" :key="index">
                  <FormulateInput
                    name="fieldName"
                    v-model="queryParameter.fieldName"
                    label="Field"
                    type="text"
                    validation="required"
                    validation-error="required"
                    class=" h-12 leading-tight"
                  />
                  <FormulateInput
                    name="fieldType"
                    v-model="queryParameter.fieldType"
                    label="Type"
                    type="select"
                    :options="fieldTypes"
                    validation="required"
                    validation-error="required"
                    class="h-12 leading-tight"
                  />
                  <FormulateInput
                    v-if="queryParameter.fieldType === 'string'"
                    name="fieldValueString"
                    v-model="queryParameter.fieldValue"
                    label="Value"
                    type="text"
                    validation="required"
                    validation-error="required"
                    class="h-12 leading-tight"
                  />
                  <FormulateInput
                    v-else-if="queryParameter.fieldType === 'number'"
                    name="fieldValueNumber"
                    v-model="queryParameter.fieldValue"
                    label="Value"
                    type="number"
                    validation="required|number"
                    validation-error="required"
                    class="h-12 leading-tight"
                    min="0"
                  />
                  <div v-else-if="queryParameter.fieldType === 'dateTime'" class="flex space-x-2">
                    <FormulateInput
                      name="dateValue"
                      v-model="queryParameter.dateValue"
                      label="Date"
                      type="date"
                      validation="required"
                      validation-error="required"
                      class="h-12 leading-tight"
                      min="0"
                    />
                    <FormulateInput
                      name="timeValue"
                      v-model="queryParameter.timeValue"
                      label="Time"
                      type="time"
                      validation="required"
                      validation-error="required"
                      class="h-12 leading-tight"
                      min="0"
                    />
                  </div>
                  <div class="flex items-end mb-1">
                    <button
                      class="bg-red-400 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
                      @click.prevent="removeRow(index)"
                    >
                      <font-awesome-icon icon="minus" class="my-auto" />
                    </button>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors||formLoading"
            :style="{backgroundColor: theme.secondaryColor}"
          />
        </div>
      </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>
<script>
import schema from './create-integration-link-schema';
// import { StyledIconedLink } from "@/styled-components";
// import { StyledSubmit } from "@/styled-components";
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
  name: 'Edit-Integration-Link-Form',
  components: {
  },
  props: {
    msg: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: ""
    },
    entityId: {
      type: String,
      default: ''
    },
    linkTarget: {
      type: String,
      default: 'business'
    },
    linkType: {
      type: String,
      default: 'integration-links'
    },
    selectedLink: {
      type: Object,
      default: () => {}
    }
  },
  // components: { StyledSubmit },
  data() {
    this.$emit("schema", schema);
    return {
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
      selectedPartner: {},
      tableHeader: [
        "Name of link",
        "Link",
        "Include UID?"
      ],
      inputValues: [{
        linkName: '',
        link: '',
        isIncludingUid: false
      }],
      me: {},
      requestTypes: {
        'post': 'POST',
        'get': 'GET'
      },
      editQueryParameters: [],
      fieldTypes: {
          string: 'String',
          number: 'Number',
          dateTime: 'DateTime',
          driverId: 'Driver ID',
          driverUDI: 'Driver UDI',
          driverUID: 'Driver UID',
          driverEmail: 'Driver Email',
          currentTimeStamp: 'Current Timestamp'
      },
      formLoading: false
    }
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  async created() {
    this.loading = true
    this.values = await this.selectedLink
    if(this.values.queryParameters) {
      await this.setQueryParemeters()
    }
    console.log(this.values)
    this.loading = false
  },
  methods: {
    async setQueryParemeters() {
      const queryParameters = await this.values.queryParameters
      console.log(queryParameters)
      const editQueryParameters = []
      for(const param of Object.keys(queryParameters)) {
        if(typeof(queryParameters[param]) === 'number') {
          editQueryParameters.push({
            fieldName: param,
            fieldType: 'number',
            fieldValue: queryParameters[param]
          })
        }
        else if(typeof(queryParameters[param]) === 'string') {
          if(moment(queryParameters[param], true).isValid()) {
            const dateValue = moment(queryParameters[param]).format('YYYY-MM-DD')
            const timeValue = moment(queryParameters[param]).format('HH:mm')
            editQueryParameters.push({
              fieldName: param,
              fieldType: 'dateTime',
              fieldValue: '',
              dateValue: dateValue,
              timeValue: timeValue
            })
          } else {
            if(queryParameters[param] === 'driverId') {
              editQueryParameters.push({
                fieldName: param,
                fieldType: 'driverId',
                fieldValue: 'driverId'
              })
            }
            else if(queryParameters[param] === 'driverUDI') {
              editQueryParameters.push({
                fieldName: param,
                fieldType: 'driverUDI',
                fieldValue: 'driverUDI'
              })
            }
            else if(queryParameters[param] === 'driverUID') {
              editQueryParameters.push({
                fieldName: param,
                fieldType: 'driverUID',
                fieldValue: 'driverUID'
              })
            }
            else if(queryParameters[param] === 'driverEmail') {
              editQueryParameters.push({
                fieldName: param,
                fieldType: 'driverEmail',
                fieldValue: 'driverEmail'
              })
            }
            else if(queryParameters[param] === 'currentTimeStamp') {
              editQueryParameters.push({
                fieldName: param,
                fieldType: 'currentTimeStamp',
                fieldValue: 'currentTimeStamp'
              })
            } else {
              editQueryParameters.push({
                fieldName: param,
                fieldType: 'string',
                fieldValue: queryParameters[param]
              })
            }
          }
        }
      }
      this.editQueryParameters = editQueryParameters
      console.log(this.editQueryParameters)
    },
    async onSubmit() {
      this.formLoading = true
      this.me = this.$store.getters[`account/me`];
      if(this.values.queryParameters) {
        let finalParameters = {}
        this.editQueryParameters.map((param) => {
          if(param.fieldType === 'string') {
            finalParameters[param.fieldName] = param.fieldValue
          } 
          else if (param.fieldType === 'number') {
            finalParameters[param.fieldName] = Number(param.fieldValue)
          }
          else if (param.fieldType === 'dateTime') {
            finalParameters[param.fieldName] = new Date(`${param.dateValue} ${param.timeValue}`).toISOString()
          } else if (param.fieldType === 'driverId') {
            finalParameters[param.fieldName] = 'driverId';
          } else if (param.fieldType === 'driverUDI') {
            finalParameters[param.fieldName] = 'driverUDI';
          } else if (param.fieldType === 'driverUID') {
            finalParameters[param.fieldName] = 'driverUID';
          } else if (param.fieldType === 'driverEmail') {
            finalParameters[param.fieldName] = 'driverEmail';
          } else if (param.fieldType === 'currentTimeStamp') {
            finalParameters[param.fieldName] = 'currentTimeStamp';
          }
        })
        this.values.queryParameters = finalParameters
      }
      const formData = {...this.values}

      await this.$store.dispatch(`dynamicLink/updateBusinessIntegrationLink`, formData)
        .then((response) => {
          if(response.success == true) {
            this.$emit("closeModal");
            this.$emit("submitSuccess");
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Integration Links successfully updated.",
            });
            location.reload();
          }
        }).catch((err) => {
          this.errorMessage = err;
        }).finally(() => {
          this.loading = false
        })
    },
  },
}
</script>