<template>
  <div>
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
    <div v-if="!isLoading">
      <div class="grid grid-cols-2 gap-4">
        <div class="mx-5 mb-10">
          <div class="text-black-primary text-xl">
            {{ startDate }} - {{ endDate }}
          </div>
          <div class="font-bold text-m">Date Range</div>
        </div>
        <div class="mx-5 mb-10 text-right">
          <button
            class="btn btn-primary text-sm mt-5"
            :style="{ backgroundColor: theme.secondaryColor }"
            @click.prevent="onGetReport"
          >
            Get Report
          </button>
        </div>
      </div>
      <div class="flex mb-3">
        <div class="mx-5">
          <b>{{ getRegisterType }}: </b> {{ summaryData.registerCount }}
        </div>
        <div class="mx-5">
          <b>Acknowledged: </b>
          {{ summaryData.acknowledgedRegisterCount }}
        </div>
        <div class="mx-5">
          <b>Unacknowledged: </b>
          {{ summaryData.unAcknowledgedRegisterCount }}
        </div>
      </div>
      <div class="flex">
        <div class="mx-5"><b>Investigations: </b> {{ investigationCount }}</div>
        <div class="mx-5">
          <b>Non Conformances: </b>{{ nonConformanceCount }}
        </div>
        <div class="mx-5"><b>Performances: </b>{{ performanceCount }}</div>
      </div>
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :data="summaryReportTableData"
        :hasActions="false"
      />
    </div>
    <Modal ref="reportModal" :title="modalTitle" size="lg" :height="modalHeight">
      <div v-if="modalContent == 'send-report'">
        <div class="mb-16">
          <div v-if="!sendReportloading">
            <FormulateForm v-model="sendReportModel" @submit="generateReport">
              <div class="items-center justify-between">
                <FormulateInput
                  type="email"
                  name="recipientEmail"
                  label="Email address"
                  validation="required|email"
                  @input="handleEmailCasing($event)"
                />
                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="btn btn-primary w-full mt-5"
                  :disabled="
                    !sendReportModel.recipientEmail || sendReportloading
                  "
                />
              </div>
            </FormulateForm>
          </div>

          <div
            v-else
            class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center"
          >
            <font-awesome-icon
              icon="cog"
              spin
              size="3x"
              class="text-gray-400"
            />
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Table, Modal } from "@/components/commons";
import { capitalizeFirstLetter } from "@/_helper";
import moment from "moment";
import { mapGetters } from "vuex";
import { REPORT_NAMES } from "../../../../../_helper/constants";

export default {
  name: "Summary-Report-Register",
  components: {
    Table,
    Modal,
  },
  props: {
    driverIds: Array,
    dateRange: Array,
    filledFormType: String,
    businessId: String,
  },
  data() {
    return {
      isLoading: false,
      error: false,
      errorMessage: null,
      summaryData: {
        registerCount: "N/A",
        acknowledgedRegisterCount: "N/A",
        unAcknowledgedRegisterCount: "N/A",
      },
      investigationCount: "N/A",
      nonConformanceCount: "N/A",
      performanceCount: "N/A",
      startDate: null,
      endDate: null,
      tableHeader: [
        "Driver Name",
        "Register Type",
        "Register Count",
        "Acknowledged Count",
      ],
      sendReportloading: false,
      modalContent: "",
      modalTitle: "",
      modalHeight: "90vh",
      sendReportModel: { recipientEmail: null },
    };
  },

  async mounted() {
    await this.fetchSummaryData();
  },
  methods: {
    async fetchSummaryData() {
      this.startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      this.endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");

      const payload = {
        startDate: this.startDate,
        endDate: this.endDate,
        driverIds: this.driverIds,
        businessId: this.businessId,
      };
      let mainSummaryDataPromise;
      switch (this.filledFormType) {
        case "incident":
          mainSummaryDataPromise = this.$store.dispatch(
            `incident/getSummaryReport`,
            payload
          );
          break;
        case "hazard":
          mainSummaryDataPromise = this.$store.dispatch(
            `hazard/getSummaryReport`,
            payload
          );
          break;
        case "defect":
          mainSummaryDataPromise = this.$store.dispatch(
            `defect/getSummaryReport`,
            payload
          );
          break;
        case "infringement":
          mainSummaryDataPromise = this.$store.dispatch(
            `infringement/getSummaryReport`,
            payload
          );
          break;
      }

      this.isLoading = true;
      const countPayload = {
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.filledFormType,
      };
      try {
        const [
          mainSummaryData,
          investigationCount,
          nonConformanceCount,
          performanceCount,
        ] = await Promise.all([
          mainSummaryDataPromise,
          this.$store.dispatch(
            "investigation/getCountByRegisterType",
            countPayload
          ),
          this.$store.dispatch(
            "report/getNonConformanceCountByRegisterType",
            countPayload
          ),
          this.$store.dispatch(
            "performance/getCountByRegisterType",
            countPayload
          ),
        ]);

        this.summaryData = mainSummaryData;

        this.investigationCount = investigationCount.success
          ? investigationCount.data
          : "N/A";
        this.nonConformanceCount = nonConformanceCount.success
          ? nonConformanceCount.data
          : "N/A";
        this.performanceCount = performanceCount.success
          ? performanceCount.data
          : "N/A";

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.statusCode && error.statusCode !== 404) {
          this.error = true;
          this.errorMessage = error?.message;
        }
      }
    },

    async generateReport() {
      this.sendReportloading = true;
      const payload = {
        reportType: REPORT_NAMES.REGISTER_SUMMARY_REPORT,
        startDate: this.startDate,
        endDate: this.endDate,
        recipientEmail: this.sendReportModel.recipientEmail,
        businessId: this.businessId,
        driverIds: this.driverIds,
        registerType: this.filledFormType,
      };

      try {
        await this.$store.dispatch("report/generateReport", payload);
        this.toast(
          "success",
          `An email will be sent to ${payload.recipientEmail} shortly`
        );
      } catch (error) {
        this.toast("error", "Something went wrong!");
      }
      this.sendReportloading = false;
    },

    closeModal() {
      this.$emit("closeModal");
    },
    formatSummaryTableData(data) {
      return data.map((reportData, index) => [
        {
          id: index,
          name: reportData.driverName,
          itemType: "string",
        },
        {
          id: index,
          name: this.filledFormType,
          itemType: "string",
        },
        {
          id: index,
          name: reportData.count,
          itemType: "string",
        },
        {
          id: index,
          name: reportData.acknowledgedCount,
          itemType: "string",
        },
      ]);
    },
    onGetReport() {
      this.$refs.reportModal.openModal();
      this.modalContent = "send-report";
      this.modalTitle = "Send Report To Email";
    },

    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
    handleEmailCasing(value) {
      this.sendReportModel.recipientEmail = value.toLowerCase();
    },
  },
  computed: {
    summaryReportTableData() {
      if (this.summaryData && this.summaryData.registerCountPerDriver) {
        return this.formatSummaryTableData(
          this.summaryData.registerCountPerDriver
        );
      } else {
        return [];
      }
    },
    getRegisterType() {
      return `${capitalizeFirstLetter(this.filledFormType)}s`;
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
