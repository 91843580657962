var render = function render(){var _vm=this,_c=_vm._self._c;return _c('StyledTableFooterDiv',{attrs:{"buttonColor":_vm.theme.accentColor,"buttonColorHover":_vm.theme.accentColor,"buttonCountColor":_vm.theme.primaryColor,"buttonCountColorHover":_vm.theme.accentColor}},[_c('div',{staticClass:"px-5 pt-4 border-t flex flex-row items-center justify-between"},[_c('div',[_c('span',{staticClass:"text-sm",attrs:{"styled":{ color: _vm.theme.accentColor }}},[_vm._v(" Showing "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.perPage))]),_vm._v(" of "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.totalRecords))]),_vm._v(" Entries ")])]),_c('div',{staticClass:"flex items-center mt-2 xs:mt-0"},[_c('button',{staticClass:"prev text-sm font-semibold py-2 px-4 rounded-l cursor-pointer border-t border-l border-b",class:_vm.isFirstPage || _vm.isLoading
            ? 'disabled cursor-not-allowed'
            : 'hover:bg-gray-400',style:({ backgroundColor: _vm.theme.accentColor }),attrs:{"disabled":_vm.isFirstPage || _vm.isLoading},on:{"click":_vm.onClickPreviousPage}},[_vm._v(" Prev ")]),_vm._l((_vm.pages),function(page){return _c('button',{key:page.id,staticClass:"pageNumber text-white text-sm font-semibold py-2 px-4 border cursor-pointer",class:[
          _vm.isPageActive(page.name)
            ? 'bg-logmaster-400 text-white'
            : 'text-gray-800',
          _vm.isLoading
            ? 'disabled cursor-not-allowed'
            : 'hover:bg-logmaster-400 hover:text-white',
        ],style:({
          backgroundColor: _vm.isPageActive(page.name)
            ? _vm.theme.accentColor
            : 'white',
          color: _vm.isPageActive(page.name) ? 'white' : _vm.theme.accentColor,
        }),attrs:{"disabled":page.isDisabled && _vm.isLoading},on:{"click":function($event){return _vm.onClickPage(page.name)}}},[_vm._v(" "+_vm._s(page.name)+" ")])}),_c('button',{staticClass:"next text-sm font-semibold py-2 px-4 rounded-r cursor-pointer border-t border-r border-b",class:_vm.isLastPage || _vm.isLoading
            ? 'disabled cursor-not-allowed'
            : 'hover:bg-gray-400',style:({ backgroundColor: _vm.theme.accentColor }),attrs:{"disabled":_vm.isLastPage || _vm.isLoading},on:{"click":_vm.onClickNextPage}},[_vm._v(" Next ")])],2)]),_c('div',{staticClass:"px-5 pb-4 flex flex-row items-center justify-between"},[_c('div',[_c('span',{staticClass:"text-sm",attrs:{"styled":{ color: _vm.theme.accentColor }}},[_vm._v(" Showing "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.currentPage))]),_vm._v(" of "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.totalPages))]),_vm._v(" Pages ")])])]),(_vm.paginationDescription)?_c('div',{staticClass:"px-5 pb-4 flex flex-row items-center justify-between"},[_c('div',[_c('i',{staticClass:"text-sm",attrs:{"styled":{ color: _vm.theme.accentColor }}},[_vm._v(" "+_vm._s(_vm.paginationDescription)+" ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }