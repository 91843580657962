<template>
  <div class="w-full h-full max-w-full">
    <div v-if="loading" class="text-center w-full h-screen flex items-center justify-center">
      <div>
        <ui-spinner class="m-auto"></ui-spinner> Loading...
      </div>
    </div>
    <template v-if="!loading">
      <!-- Payment Details & Contract Details -->
      <div class="mb-4 flex w-full justify-between items-stretch space-x-4" v-if="contract.activationOption == 'recurring_payment'">
        <div class="flex-1">
          <div class="block bg-neutral-bg overflow-hidden h-full rounded-lg p-5">
            <div class="flex flex-col">
              <div class="text-sm font-bold text-gray-500">Total bill on {{ formatDate(unpaidInvoice.dueDate, 'DD MMM YYYY') || 'NaN' }}</div>
              <div class="text-gray-800 font-bold text-xl my-4">
                <p>
                  ${{ totals.amountDue.toFixed(2) }}
                </p>
              </div>
              <!-- <div class="text-gray-500 font-bold mt-2 text-xs">
                <p>Switch to {{ contract.billingPeriod.name == 'Monthly' ? 'monthly' : 'yearly' }} billing</p>
              </div> -->
              <!-- <div @click.prevent="manageSeats()" class="text-gray-500 font-bold text-xs cursor-pointer">
                <p>Manage Seats</p>
              </div> -->

              <div class="flex justify-start items-center text-sm text-gray-500 font-bold">
                <p>Payment Credits: <span class="text-gray-800">${{ contract.paymentCredit?.toFixed(2) || 0 }}</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="block bg-neutral-bg overflow-hidden h-full rounded-lg p-5">
            <div class="flex flex-col">
              <div class="text-sm font-bold text-gray-500">Next payment due</div>
              <div class="text-gray-800 font-bold my-4 text-xl">
                <p>
                  {{formatDate(contract.nextBillingDate, 'DD-MM-YYYY')}}
                </p>
              </div>
              <div class="text-gray-500 font-bold text-xs">
                <p>Contract end date <span class="text-gray-700">{{ formatDate(contract.expiryDate, 'DD-MM-YYYY') }}</span></p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="block bg-neutral-bg overflow-hidden h-full rounded-lg p-5">
            <div class="flex flex-col">
              <div class="flex justify-between items-center">
                <div class="text-sm font-bold text-gray-500 mb-5">Payment History</div>
                <router-link class="text-sm font-bold text-orange-primary mb-5 cursor-pointer" to="payment-history">View All</router-link>
              </div>
              <template v-for="(history, index) of paymentHistory">
                <div class="flex justify-between mb-2" :key="`payment-history-${index}`">
                  <p class="text-sm font-bold text-gray-800">{{ formatDate(history.createdAt, 'DD-MM-YYYY') }}</p>
                  <div class="flex space-x-2" v-if="history.isSuccessful">
                    <p class="text-sm font-bold text-gray-500">${{ history.totalAmount }}</p>
                    <p class="text-sm font-bold text-orange-primary cursor-pointer" @click="viewReceipt(history)"><font-awesome-icon icon="eye" /></p>
                  </div>
                  <div class="flex space-x-2" v-else>
                    <p class="text-sm font-bold text-gray-500">{{ history.status }}</p>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div v-else class="mb-4 flex w-full justify-between items-stretch space-x-4" >
        <div class="flex-1">
          <div class="block bg-neutral-bg overflow-hidden h-full rounded-lg p-5">
            <div class="flex flex-col ml-3">
              <div class="text-gray-500 font-bold text-md my-4">
                <p>Total Amount:
                  <span class="text-gray-700">
                    ${{ totals.amountDue.toFixed(2) }}
                  </span>
                </p>
              </div>
              <div class="text-red-500 font-bold text-md my-4">
                <p>To add seats please contact your Partner</p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="block bg-neutral-bg overflow-hidden h-full rounded-lg p-5">
            <div class="flex flex-col ml-3 mt-1">
              <div class="text-sm font-bold text-gray-500">Next payment due</div>
              <div class="text-gray-800 font-bold my-4 text-xl">
                <p>
                  {{formatDate(contract.nextBillingDate, 'DD-MM-YYYY')}}
                </p>
              </div>
              <div class="text-gray-500 font-bold text-xs">
                <p>Contract end date <span class="text-gray-700">{{ formatDate(contract.expiryDate, 'DD-MM-YYYY') }}</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- All Plan details -->

      <!-- Invoice Details -->      
      <div v-if="unpaidInvoice" class="flex flex-col space-y-4 mt-4 border-b-2 border-neutral-100 pb-2">
        <div class="pt-4 pb-2 px-4 border-b-2 border-neutral-100">
          <h2 class="font-bold text-gray-800 text-2xl">Upcoming Invoice</h2>
          <div class="text-gray-500 text-sm">This is a preview of the invoice that will be billed on <span class="font-bold">{{ formatDate(unpaidInvoice.dueDate, 'DD MMM YYYY') }}</span>. It may change if the subscription is updated.</div>
        </div>
        <div class="w-full px-4">
          <div class="text-left mx-auto max-w-[510px]">    
            
            <div class="relative overflow-x-auto">
              <table class="w-full text-sm text-left text-gray-500">
                <thead class="text-xs text-gray-700 uppercase bg-gray-100">
                  <tr>
                    <th scope="col" class="px-6 py-3 rounded-l-lg">
                      Description
                    </th>
                    <th scope="col" class="px-6 py-3 text-center">
                      Quantity
                    </th>
                    <th scope="col" class="px-6 py-3 text-right">
                      Unit Price
                    </th>
                    <th scope="col" class="px-6 py-3 rounded-r-lg text-right">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="bg-white" v-for="(invoice, index) in unpaidInvoice.invoiceItems" :key="index">
                    <th scope="row" class="px-6 py-2 font-medium text-black font-bold whitespace-nowrap">
                      {{invoice.itemDescription}}
                    </th>
                    <td class="px-6 py-2 text-center text-gray-500 font-bold text-sm">
                      {{invoice.itemQuantity}}
                    </td>
                    <td class="px-6 py-2 text-right text-gray-500 font-bold text-sm">
                      ${{invoice.unitPrice.toFixed(2)}}
                    </td>
                    <td class="px-6 py-2 text-right text-gray-500 font-bold text-sm">
                      ${{invoice.amount.toFixed(2)}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex flex-col w-full pt-10">
              <div class="pt-4 border-t">
                <div class="flex justify-between mb-4 items-center" v-if="totals.billType=='payment gateway'">
                  <div class="font-bold text-sm text-gray-600 text-left flex-1">GST(10%)</div>
                  <div class="text-right w-40">
                    <div class="text-gray-500 font-bold text-sm" x-html="totalGST">${{ totals.gst.toFixed(2) }}</div>
                  </div>
                </div>
                <div class="flex justify-between mb-4 items-center" v-if="totals.billType=='payment gateway'">
                  <div class="font-bold text-sm text-gray-600 text-left flex-1">Gateway Fee({{ paymentGateway.surchargePercentageFee }}% + ${{ paymentGateway.otherFees }})</div>
                  <div class="text-right w-40">
                    <div class="text-gray-500 font-bold text-sm" x-html="totalGST">${{ totals.gatewayFee.toFixed(2) }}</div>
                  </div>
                </div>
                <div class="flex justify-between items-center mt-4">
                  <div class="text-xl font-bold text-gray-800 text-left flex-1">Total</div>
                  <div class="text-right w-40">
                    <div class="text-xl text-gray-800 font-bold" x-html="netTotal">${{ totals.amountDue.toFixed(2) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-col space-y-4 mt-4">
        <div class="pt-4 pb-2 px-4 border-b-2 border-neutral-100 flex flex-row space-x-4 justify-between items-center">
          <h2 class="font-bold text-gray-800 text-2xl">Invoices</h2>

          <div class="relative">
            <button @click="toggleDropdown()" class="btn btn-primary cursor-pointer">Actions<font-awesome-icon icon="caret-down" class="ml-2"></font-awesome-icon></button>
            <div v-show="showDropdown" class="absolute
              right-0
              py-2
              mt-2
              bg-white
              rounded-md
              shadow-xl
              w-60">
              <ul>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer text-sm"
                    @click.prevent="manageSubscription()"
                  >
                    Manage Subscription
                  </StyledIconedLink>
                </li>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer text-sm disabled"
                    @click.prevent="changeBillingPeriod()"
                  >
                    Change Billing Period
                  </StyledIconedLink>
                </li>
                <!-- <li>
                  <StyledIconedLink
                    class="border-t-2 border-neutral-100 block px-4 py-2 hover:bg-gray-100 text-red-500 cursor-pointer text-sm"
                    @click.prevent="viewDriver(data.id)"
                  >
                    Cancel Subscription
                  </StyledIconedLink>
                </li> -->
              </ul>
            </div>
          </div>
        </div>
        <Table
            :isLoading="isTableLoading"
            :tableHeader="invoicesTableHeader"
            :paginationSettings="paginationSettings"
            :data="allContractInvoices"
            @onPageChange="onPageChange($event)"
            :has-actions="false"
          >
          </Table>
      </div>
      <!-- End Invoice Details -->

      <!-- Modals for managing seats -->
      <Modal
        ref="modal"
        :title="modal.modalTitle"
        size="3xl"
        :height="modal.modalHeight"
        @closeModal="closeModal()"
      >
        <div v-if="modal.modalContent === 'add-seats'">
          <ManageSeatsForm
            @addedSeats="addedSeatsAction"
            :contract="contract"
            :current-seat-counts="currentSeatCounts"
            :payment-method="paymentMethod"
            :totals="totals"
            :payment-gateway="paymentGateway"
          />
        </div>
        <div v-if="modal.modalContent === 'manage-subscription'">
          <ManageSubscription
            :activeSolo="me.contract"
            @closeModal="closeModal"
          />
        </div>
        <div v-if="modal.modalContent === 'change-billing-period'">
          <ChangeBillingPeriodForm
            :active-data="me.contract"
            @closeModal="closeModal"
          />
        </div>

        <div v-if="modal.modalContent == 'viewReceipt'">
          <PaymentReceipt 
            :receipt-data="receiptData"
            :entity-data="me"
            @closeModal="closeModal" 
          />
        </div>
      </Modal>
      
    </template>
  </div>
</template>
<script>
import moment from 'moment';
import ManageSubscription from "@/components/forms/payment/manage-subscription";
import PaymentReceipt from "@/components/view/payment/payment-receipt";
import { Modal, Table } from "@/components/commons";
import { StyledIconedLink } from "@/styled-components";
import { ManageSeatsForm } from "@/components/forms/business"
import { ChangeBillingPeriodForm } from "@/components/forms/contract"
import { formatCleanDate, formatDate, formatPaginationSettings } from '@/_helper';
import { mapGetters } from 'vuex';
import { SEARCH_DEBOUNCE_DELAY } from '@/_helper/constants';
import _ from "lodash";
export default {
  components: {
    Modal,
    ManageSeatsForm,
    Table,
    ManageSubscription,
    StyledIconedLink,
    ChangeBillingPeriodForm,
    PaymentReceipt
  },
  data() {
    return {
      contract: {},
      paymentMethod: {},
      paymentHistory: {},
      paymentGateway: {
        gstPercentageFee: 10, //TODO clarify the percent
        surchargePercentageFee: 0,
        otherFees: 0,
      },
      hasActivePaymentGateway: false,
      publishableKey: null,
      paymentGatewayId: null,
      me: {},
      totals: {
        priceperseat: 0,
        seatCount: 0,
        subTotal: 0,
        totalGST: 0,
        gst: 0,
        gatewayFee: 0,
        amountDue: 0,
        paymentGateway: false
      },
      billPeriod: '',
      loading: true,
      currentSeatCounts: {},
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: "80vh"
      },
      depots: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      contractInvoices: [],
      invoicesTableHeader: [
        "Amount",
        "Currency",
        "Invoice ID",
        "Status",
        "Invoice Date",
      ],
      unpaidInvoice: {},
      isTableLoading: false,
      isInvoice: true,
      showDropdown: false,
      receiptData: {}
    }
  },
  async created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
    await this.getContractDetails()
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    manageSeats() {
      this.$refs.modal.openModal();
      this.modal.modalContent = "add-seats";
      this.modal.modalTitle = "Manage Seats";
    },

    manageSubscription() {
      this.modal.modalContent = "manage-subscription";
      this.modal.modalTitle = "Subscription Details";
      this.$refs.modal.toggleModal();
    },

    changeBillingPeriod() {
      this.modal.modalContent = "change-billing-period";
      this.modal.modalTitle = "Change Billing Period";
      this.$refs.modal.toggleModal();
    },


    viewReceipt(data) {
      data.id = data._id;
      this.$refs.modal.openModal();
      this.modal.modalContent = 'viewReceipt';
      this.modal.modalTitle = 'Receipt Details';
      console.log(data);
      this.receiptData = data;
      this.modal.modalId = data._id;
    },

    toast(state, message){
      this.$store.commit('setDialogNotify',true);
      this.$store.commit('setMessageNotify',{state, message});
    },

    async addedSeatsAction(isSuccessful) {
      if(isSuccessful) {
        this.toast('success', 'Seats successfully added.')
        this.closeModal();
        //refresh data initialization
        await this.getContractDetails();
      } else {
        this.toast('error', 'Failed to add seats.')
      }
    },

    async closeModal() {
      this.$refs.modal.closeModal();
    },

    async getContractDetails() {
      this.loading = true
      const me = this.$store.getters[`account/me`];
      this.me = me;
      const contract = await this.$store.dispatch(`contract/getMyContract`, {entityId: me.driver?._id});
      this.contract = contract;
      if(contract.activationOption === 'recurring_payment' || contract.hasRecurringPayment === true) {
        this.isInvoice = false;
        const paymentGateway = await this.$store.dispatch("payment/getPaymentGatewayByUid", { uid: me?.driver.parentUid, role: me?.driver.parentRole });
        if (paymentGateway) {
          this.paymentGatewayId = paymentGateway._id;
          this.publishableKey = paymentGateway.apiKey;
          this.hasActivePaymentGateway = true;
          this.paymentGateway = paymentGateway;
        }
      }
      await this.getInvoiceDetails(contract._id);
      this.loading = false
    },
    async getPaymentMethod() {
      if(!this.isInvoice) {
        await this.$store.dispatch(`payment/getPaymentMethodByEntityId`, this.me.driver?._id)
            .then((response) => {
              this.paymentMethod = response
              this.getPaymentHistory()
            })
      }

      let priceperseat = 0;
      // this.contract.businessModules.forEach((object) => {
      //   priceperseat += this.getActualPrice(object)
      //   total += this.getActualPrice(object) * object.minimums
      // })
      let subTotal = this.unpaidInvoice.total || 0;
      const gst = (subTotal / 100) * this.paymentGateway.gstPercentageFee;
      const totalGST = subTotal + gst;
      const gatewayFee = ((totalGST / 100) * this.paymentGateway.surchargePercentageFee) + this.paymentGateway.otherFees;
      const amountDue = totalGST + gatewayFee;
      this.totals = {
        priceperseat: priceperseat,
        subTotal: subTotal,
        totalGST: totalGST,
        gst: gst,
        gatewayFee: gatewayFee,
        amountDue: amountDue,
        billType: "payment gateway"
      }

    },
    async getPaymentHistory() {
      const query = {
        skip: 0,
        limit: 3,
        search: '',
        filter: { }
      }
      await this.$store.dispatch(`payment/getPaymentHistoryByEntityId`, {id: this.me.driver?._id, query})
        .then((response) => {
          this.paymentHistory = response.results
          this.loading = false
        }).catch(() => {
          this.loading = false
        })
    },
    
    // async getCurrentSeatCountForBusiness() {
    //   //check if there are depot configs
    //   this.depots = await this.$store.dispatch(`business/getSites`, {
    //     depotId: this.me?.business?._id,
    //     isDeleted: false,
    //   });

    //   let filter = {}
    //   if(this.depots.length > 0) {
    //     const siteIds = this.depots.map((x) => x._id);
    //     filter.siteId = { $in: siteIds }
    //   }

    //   await this.$store.dispatch(`driver/getCurrentSeatCountForBusiness`, { businessId: this.me.driver?._id, filter: filter })
    //     .then((response) => {
    //       this.currentSeatCounts = response.data
    //     })
    // },

    formatDate(date, format) {
      let newDate = moment(date).format(format)
      return newDate
    },
    downloadReceipt(receipt) {
      window.open(receipt, "_blank")
    },

    getActualPrice(modules) {
      let amount;
      console.log(modules)
      switch(this.contract.billingPeriod.name.toUpperCase()) {
        case "MONTHLY":
          this.billPeriod = "month"
          amount = parseFloat(modules.price.monthly).toFixed(2)
        break;
        case "QUARTERLY":
          this.billPeriod = "quarter"
          amount = parseFloat(modules.price.quarterly).toFixed(2)
        break;
        case "YEARLY":
          this.billPeriod = "year"
          amount = parseFloat(modules.price.yearly).toFixed(2)
      }

      return amount;
    },

    getSeatCount(module) {
      let moduleCount;
      switch(module.name) {
        case "EWD-FT":
          moduleCount = this.currentSeatCounts.ewdCount
        break;
        case "Fatigue Compliance":
          moduleCount = this.currentSeatCounts.fatigueCount
        break;
        case "KM-TIME":
          moduleCount = this.currentSeatCounts.kmTimeCount
        break;
        case "One API":
          moduleCount = this.currentSeatCounts.ewdCount
        break;
        default:
          moduleCount = 0
      }

      return moduleCount;
    },

    getPercentage(module) {
      let currSeatCount = this.getSeatCount(module);
      let minimumSeatCount = module.minimums

      let percentage = (currSeatCount/minimumSeatCount) * 100;
      return `${percentage}%`
    },

    //Invoices
    async getInvoiceDetails(contractId) {
      //get all invoices
      await this.getAllInvoices();
      await this.getUnpaidInvoice(contractId);
    },

    async getAllInvoices(paged = 1) {
      this.isTableLoading = true
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {}
      };

      let contractInvoices = await this.$store.dispatch(`billinginvoice/getAllContractInvoices`, { contractId: this.contract._id, query})

      if(contractInvoices?.metadata) {
        if(contractInvoices?.metadata) {
          this.paginationSettings = formatPaginationSettings(contractInvoices?.metadata)
          contractInvoices = contractInvoices.results;
        }

        const contractInvoicesTableData = this.formatItem(contractInvoices);
        this.contractInvoices = contractInvoicesTableData;
      }
      this.isTableLoading = false
    },

    async onPageChange(event) {
      if (event.page) {
        await this.getAllInvoices(event.page);
      }
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.getAllInvoices();
    },

    async getUnpaidInvoice(contractId) {
      let contractInvoice = await this.$store.dispatch(`billinginvoice/getContractUnpaidInvoice`, contractId)
      this.unpaidInvoice = contractInvoice
      await this.getPaymentMethod()
    },    

    generateTotal(subTotal) {
      const gst = (subTotal / 100) * this.paymentGateway.gstPercentageFee
      const totalGST = subTotal + gst
      const gatewayFee = ((totalGST / 100) * this.paymentGateway.surchargePercentageFee) + this.paymentGateway.otherFees
      const amountDue = totalGST + gatewayFee
      return `$${amountDue.toFixed(2)}`
    },
    
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const invoice = {
            id: item?._id,
            contractId: item?.contractId._id,
            contract: item?.contractId,
            currency: item?.currency,
            subTotal: item?.total,
            total: this.generateTotal(item?.total),
            startDate: item?.startDate ? formatDate(item?.startDate) : "",
            dueDate: item?.dueDate ? formatDate(item?.dueDate) : "",
            billDate: `${formatCleanDate(item?.dueDate)}`,
            createdAt: item?.createdAt ? formatDate(item?.createdAt) : "",
            updatedAt: item?.updatedAt ? formatDate(item?.updatedAt) : "",
            status: item?.isPaid ? 'Paid' : 'Unpaid',
            badgeType: item?.isPaid ? 'success' : 'warning',
            invoiceItems: item?.invoiceItems
          };
          nItems.push(invoice);
        });
      }
      return nItems;
    },

    parseInvoices(data) {
      return data.map((invoice) => [
        {
          contractId: invoice.contractId,
          id: invoice.id,
          name: invoice.total,
          itemType: "string",
        },
        {
          contractId: invoice.contractId,
          id: invoice.id,
          name: invoice.currency,
          itemType: "string",
        },
        {
          contractId: invoice.contractId,
          id: invoice.id,
          name: invoice.id,
          itemType: "string",
        },
        {
          contractId: invoice.contractId,
          id: invoice.id,
          name: invoice.status,
          badgeType: invoice.badgeType,
          itemType: "badge",
        },
        {
          contractId: invoice.contractId,
          id: invoice.id,
          name: invoice.billDate,
          itemType: "string",
        },
      ])
    },
  },
  computed: {
    allContractInvoices() {
      if(this.contractInvoices) {
        return this.parseInvoices(this.contractInvoices);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  }
}
</script>