import { TechProvider } from "@/api";
import _ from 'lodash'

export default{
    // @TechProvider
    async createTechProvider({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await TechProvider.createTechProvider(data)
            commit("setNewCreatedTechProvider", response.data.data);

            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getTechProvider( { state }, param ){
        try{
            const response = await TechProvider.getTechProvider(param);
            if(param.id){
                
                if(!response?.data?.data) return [];
                return response.data.data;
            }else{
                return { results : response?.data?.data, metadata:response.data.resultsMeta }
            }
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getTechProviderByName( { state }, name ){
        if(!name) throw 'Invalid name '+state.me;
        try{
            const response = await TechProvider.getTechProviderByUID(name);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async updateTechProvider( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await TechProvider.patchTechProvider(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteTechProvider( { state }, id ){
        try{
            const response = await TechProvider.deleteTechProvider(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
}