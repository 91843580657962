<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="resellerData"
      @search-table="queryForKeywords"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewReseller(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="clipboard" class="my-auto mr-2" />
                  View
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="editReseller(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" />
                  Edit
                </StyledIconedLink>
              </li>
              <li><DrillDown :uid="data.uid" role="reseller" /></li>
              <li v-if="!data.uid">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="resendInvite(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="paper-plane" class="my-auto mr-2" />
                  Resend
                </StyledIconedLink>
              </li>
              <li v-if="!data.uid">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="deleteReseller(data)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="trash" class="my-auto mr-2" />
                  Delete
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <template slot="callToActionTop">
        <button
          class="btn btn-primary"
          @click.prevent="newReseller()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          New Reseller
        </button>
      </template>
      <div>Not Found</div>
    </Table>
    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <NewConnectionForm
          @closeModal="closeModal"
          @submitSuccess="submitSuccess"
          connectionType="reseller"
        />
      </div>
      <div v-if="modalContent == 'view'">
        <ConnectionView
          @editReseller="editReseller"
          :detailsId="modalId"
          connectionType="reseller"
        />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditConnectionForm
          @closeModal="closeModal"
          @submitSuccess="submitSuccess"
          :detailsId="modalId"
          connectionType="reseller"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Table, Dropdown } from "@/components/commons/";
import {
  NewConnectionForm,
  EditConnectionForm,
} from "@/components/forms/user/connection";
import ConnectionView from "@/components/view/admin/connection";
import DrillDown from "@/components/modules/DrillDown.vue";
import { formatDate, formatPaginationSettings } from "@/_helper";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Admin-Reseller",
  components: {
    Modal,
    Dropdown,
    NewConnectionForm,
    EditConnectionForm,
    ConnectionView,
    Table,
    DrillDown,
    StyledIconedLink,
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      resellers: [],
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        "Reseller Name",
        "Email Address",
        "Contact Phone",
        "NZBN",
        "Created At",
      ],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: {
        all: "All",
      },
      hasRole: true,
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    await this.init();
    this.$emit("updateLoading", false);
  },
  methods: {
    async init(paged = 1) {
      this.isLoading = true;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {
          parentRole: "admin",
        },
      };

      if (this.filter.status != "all")
        query.filter.status = { $eq: this.filter.status };

      const resellers = await this.$store.dispatch(`reseller/getReseller`, {
        query,
      });

      if (resellers) {
        if (resellers?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            resellers?.metadata
          );
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const resellerTableData = this.formatItem(
          _.orderBy(resellers.results, ["createdAt"], ["desc"])
        );
        this.resellers = resellerTableData;
      } else {
        this.resellers = [];
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },

    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    newReseller() {
      this.modalContent = "new";
      this.modalTitle = "New Reseller";
      this.$refs.modal.openModal();
    },
    viewReseller(id) {
      this.modalId = id;
      this.modalContent = "view";
      this.modalTitle = "Reseller Info";
      this.$refs.modal.openModal();
    },
    editReseller(id) {
      this.modalId = id;
      this.modalContent = "edit";
      this.modalTitle = "Reseller Edit";
      this.$refs.modal.openModal();
    },
    async submitSuccess() {
      await this.init();
    },
    async resendInvite(resellerId) {
      if (confirm("Resend invite email to this reseller?")) {
        await this.$store
          .dispatch("reseller/resendInvite", resellerId)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: res.message,
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: res.message,
              });
            }
            this.$store.commit("setDialogNotify", true);
            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: errorMessage,
            });
          });
      }
    },
    async deleteReseller(data) {
      if (
        confirm(
          `Are you sure you want to delete ${data.name}? It cannot be undone.`
        )
      ) {
        await this.$store
          .dispatch("reseller/deleteReseller", data.id)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: res.message,
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: res.message,
              });
            }
            this.$store.commit("setDialogNotify", true);

            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: errorMessage,
            });
          });
      }
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },

    formatItem(items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const reseller = {
            uid: item?.uid,
            id: item?._id,
            name: item?.persona?.businessName,
            email: item?.persona?.contactEmail,
            phone: item?.persona?.contactPhoneNumber,
            abn: item?.persona?.abn,
            created: formatDate(item?.createdAt),
          };
          nItems.push(reseller);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseReseller(data) {
      return data.map((resellerData) => [
        {
          uid: resellerData.uid,
          id: resellerData.id,
          name: resellerData.name,
          itemType: "name",
          hasImage: true,
        },
        {
          uid: resellerData.uid,
          id: resellerData.id,
          name: resellerData.email,
          itemType: "string",
        },
        {
          uid: resellerData.uid,
          id: resellerData.id,
          name: resellerData.phone,
          itemType: "string",
        },
        {
          uid: resellerData.uid,
          id: resellerData.id,
          name: resellerData.abn,
          itemType: "string",
        },
        {
          uid: resellerData.uid,
          id: resellerData.id,
          name: resellerData.created,
          itemType: "string",
        },
      ]);
    },
  },
  computed: {
    resellerData() {
      if (this.resellers) {
        return this.parseReseller(this.resellers);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
