export default [
  {
    name: 'Business Navigation',
    items: [
      {
        name: 'Dashboard',
        link: '/business/',
        production: true
      },
      {
        name:'Drivers',
        link:'',
        subitems: [
          {
            name:'Drivers List',
            link:'/business/drivers',
            production: true
          },
          {
            name:'Manual Events',
            link:'/business/manual-events',
            production: true
          }
        ],
        production: true
      },
      {
        name:'Vehicles',
        link:'',
        production: true,
        subitems: [
          {
            name:'Vehicle List',
            link:'/business/vehicles',
            production: true
          },
        ]
      },
      {
        name:'Reports',
        link:'',
        production: true,
        subitems: [
          {
            name:'Compliance',
            link:'/business/reports/compliance',
            production: true
          },
          {
            name:'Breaches',
            link:'/business/reports/breaches',
            production: true
          },
          {
            name:'Daily Forms',
            link:'/business/reports/daily',
            production: true
          },
          {
            name:'Vehicles Report',
            link:'/business/reports/vehicles',
            production: true
          },
          {
            name:'Forms Report',
            link:'/business/forms-report',
            production: false
          },
          {
            name:'Report Scheduling',
            link:'/business/reports/scheduling',
            production: true
          },
          {
            name:'Event Modifications',
            link:'/business/reports/event/modification',
            production: true
          }
        ]
      },
      {
        name: 'Admin',
        link: '',
        subitems: [
          {
            name:'Permissions',
            link:'/business/permissions',
            production: true
          },
          {
            name:'Devices',
            link:'/business/devices',
            production: true
          },
          {
            name:'Billing',
            link:'/business/billing',
            production: true
          },
          {
            name:'Forms Setup',
            link:'/business/form-setup',
            production: true
          },
          {
            name:'Additional Services',
            link:'/business/services/subscription',
            production: true
          },
          {
            name:'Depot Config',
            link:'/business/depot-configuration',
            production: true
          },
        ],
        production: true
      },
      {
        name: 'Support',
        link: 'https://logmaster.knowledgeowl.com/',
        isExternalLink: true,
        production: true
      }
    ]
  },
  {
    name: 'TSL Management',
    items: [
      {
        name: 'TSL Licences',
        link: '/business/tsl/licences',
        production: true
      },
      {
        name: 'TSL Edits',
        link: '/business/tsl/edits',
        production: true
      },
      // {
      //   name: 'Registers',
      //   link: '/business/tsl/registers',
      //   production: true
      // }
    ],
  }
]
