import {Settings} from '@/api'
import { isEmpty } from 'lodash'

export default{
    //@PATCH: Update theme via theme options id
    async updateTheme({commit}, data){

        try {
            const themeData = await Settings.updateTheme(data)

            if(themeData.data.success){
                commit('updateTheme', data?.themeData?.themeData)
                commit('updateIsDarkMode', data?.themeData?.isDarkMode)
            }
    
        } catch (e) {
            throw new Error(e.message)
        }
    },
    
    async createTheme({commit}, data){
        try {
            const theme = await Settings.createTheme(data)
            if(theme.data.success){
                commit('updateTheme', theme.data?.themeData?.themeData)
                commit('updateIsDarkMode', theme.data?.themeData?.isDarkMode)
            }
        } catch (e) {
            throw new Error(e.message)
        }
    },

    //@GET: Fetch theme lists via account Id
    async getThemeListsById({commit, state}, accountId){
    if (!state) throw `Invalid + ${state}`

    try {
        const response = await Settings.getThemeListsById(accountId)
        
        const data = response?.data?.data

        if(!isEmpty(data)){
            commit('updateThemeLookupOptions', data)
        }

        return data

    } catch (e) {
        throw new Error(e.message)
    }
    },

    //@GET: Fetch all user themes
    async getUserThemes({commit, state}, accountId){
        if (!state) throw `Invalid + ${state}`
    
        try {
            const response = await Settings.getUserThemes(accountId)
            
            const data = response?.data?.data || []
    
            if(!isEmpty(data)){
                commit('updateThemeLookupOptions', data)
            }
    
            return data
    
        } catch (e) {
            // throw new Error(e.message)
        }
        },
        

}