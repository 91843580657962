import { API } from '@/api/config'

export default new class Settings {

    //@POST: Upload Logo
    uploadLogo(fileData){
        return API.ACCOUNTS.post(`upload-logo/upload/`, fileData);
    }

    //@GET: Fetch logo data 
    getLogoById(accountId = null){
        return API.ACCOUNTS.get(`/upload-logo/${accountId}`)
    }

    //PATCH / DELETE: Delete a logo by type
    deleteLogoById({accountId, type}){
        return API.ACCOUNTS.patch(`/upload-logo/delete-logo/${accountId}`, {type})
    }

    //@GET: Fetch all theme options per account
    getThemeListsById(accountId = null){
        return API.ACCOUNTS.get(`/theme/find-by-accountId/${accountId}`)
    }

    //@GET: Fetch all user created themes
    getUserThemes(accountId = null){
        return API.ACCOUNTS.get(`/theme/custom-themes/${accountId}`)
    }

    //PATCH: Update theme by theme options id
    updateTheme({accountId, themeData}){        
        return API.ACCOUNTS.patch(`/setting/update-theme/${accountId}`, {themeId: themeData._id, isDarkMode: themeData.isDarkMode})
    }

    //@GET: Fetch settings per account
    getUserSettings({accountId}){
        return API.ACCOUNTS.get(`/setting/${accountId}`)
    }

    //@POST: create settings per account
    createSettings(settingsData){
        return API.ACCOUNTS.post(`/setting/`, settingsData)
    }

    /** Create custom theme */
    createTheme({accountId, themeName, themeData, themeColors, isActive }){
        return API.ACCOUNTS.post(`/theme/`, {accountId, themeName, themeData, themeColors, isActive})
    }
}