<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="businessData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewBusiness(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="clipboard" class="my-auto mr-2" />
                  View
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="editBusiness(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="updatePartnerinBusiness(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="user" class="my-auto mr-2" /> Update
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="addCredit(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="coins" class="my-auto mr-2" /> Add Credit
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="toggleAFMAccess(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="certificate" class="my-auto mr-2" />
                  AFM Access
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="downloadDrivers(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="download" class="my-auto mr-2" /> Drivers CSV
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="downloadVehicles(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="download" class="my-auto mr-2" /> Vehicles CSV
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="addGeotabPassword(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                <font-awesome-icon icon="lock" class="my-auto mr-2" />
                  Geotab
                </StyledIconedLink>
              </li>
              <li><DrillDown :uid="data.uid" role="business" /></li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <BusinessPartnerContractStep @closeModal="closeModal" />
      </div>
      <div v-if="modalContent == 'view'">
        <BusinessDetailView
          :activeBusiness="activeBusiness"
          @editBusiness="editBusiness"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditBusinessPartnerStep
          :detailsId="modalId"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalContent == 'add-credit'">
        <AddContractCreditForm
          :entity-id="modalId"
          entity-role="business"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalContent == 'updatePartner'">
        <UpdateBusinessPartnerForm
          :activeBusiness="activeBusiness"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalContent == 'geotab'">
        <AddGeotabCredentialsForm
          :activeBusiness="activeBusiness"
          @closeModal="closeModal"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Table, Dropdown } from "@/components/commons/";
import BusinessDetailView from "@/components/view/business";
import {
  BusinessPartnerContractStep,
  EditBusinessPartnerStep,
  UpdateBusinessPartnerForm,
  AddGeotabCredentialsForm
} from "@/components/forms/business/";
import DrillDown from "@/components/modules/DrillDown.vue";
import AddContractCreditForm from "@/components/forms/contract/add-contract-credit-form.vue";
import { formatDate, formatPaginationSettings, convertJSONToCSV, downloadCSV } from "@/_helper";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Partner-Business",
  components: {
    Modal,
    Dropdown,
    BusinessPartnerContractStep,
    EditBusinessPartnerStep,
    Table,
    BusinessDetailView,
    DrillDown,
    UpdateBusinessPartnerForm,
    StyledIconedLink,
    AddGeotabCredentialsForm,
    AddContractCreditForm
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      businesses: [],
      businessesOrigin: [],
      activeBusiness: {},
      tableHeader: [
        "Business Name",
        "Email",
        "Contact Phone",
        "NZBN",
        "Partner",
        "Created At",
        "Enable / Disable",
        "Licenced / Unlicenced",
        "AFM Access",
      ],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: {
        root: "Root",
        all: "All",
      },
      readBusinesses: false,
      writeBusinesses: false,
      userType: null,
      hasRole: true,
      filter: {
        limit: 10,
        status: "root",
        search: "",
      },
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init(paged = 1) {
      this.isLoading = true;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status === "root") {
        query.filter.webProfiles = { $exists: true, $ne: [] };
      }

      const business = await this.$store.dispatch(`business/getBusiness`, {
        query,
      });
      if (business) {
        if (business?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            business?.metadata
          );
          this.businessesOrigin = business?.results;
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const businessTableData = this.formatItem(business.results);
        this.businesses = businessTableData;
      } else {
        this.businesses = [];
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
      this.$emit("updateLoading", false);
    },

    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    newBusiness() {
      this.modalContent = "new";
      this.modalTitle = "New Business";
      this.$refs.modal.toggleModal();
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
    },
    viewBusiness(id) {
      this.activeBusiness = this.businessesOrigin.filter(
        (item) => item._id === id
      )[0];
      this.modalContent = "view";
      this.modalTitle = "Business Info"
      this.$refs.modal.toggleModal()
      this.modalId = id
      this.modalHeight = DEFAULT_MODAL_HEIGHT
    },
    editBusiness(id) {
      this.modalContent = "edit";
      this.modalTitle = "Business Edit";
      this.$refs.modal.openModal();
      this.modalId = id;
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
    },
    addCredit(id) {
      this.modalContent = "add-credit";
      this.modalTitle = "Add Credit";
      this.$refs.modal.openModal();
      this.modalId = id;
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
    },
    updatePartnerinBusiness(id) {
      this.activeBusiness = this.businessesOrigin.filter(
        (item) => item._id === id
      )[0];
      this.modalContent = "updatePartner";
      this.modalTitle = "Move Business to Another Partner";
      this.$refs.modal.openModal();
      this.modalId = id;
      this.modalHeight = "60vh";
    },
    addGeotabPassword(id) {
      this.activeBusiness = this.businessesOrigin.filter(
        (item) => item._id === id
      )[0];

      this.modalContent = "geotab";
      this.modalTitle = "Create a Password For Geotab's Service Account";
      this.$refs.modal.openModal();
      this.modalId = id;
      this.modalHeight = "60vh";
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const business = {
            uid: item?.uid,
            id: item?._id,
            name: item?.persona?.businessName,
            email: item?.supportEmail,
            phone: item?.persona?.contactPhoneNumber,
            abn: item?.persona?.abn,
            partner: item?.partner?.persona?.businessName,
            created: formatDate(item?.createdAt),
            status: item?.isActive,
            isLicensed: item?.isLicensed,
            isAFMEnabled: item && item.isAFMEnabled ? true : false,
          };
          nItems.push(business);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseBusiness(data) {
      return data.map((businessData) => [
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.name,
          itemType: "name",
          hasImage: true,
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.email,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.phone,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.abn,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.partner,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.created,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.status,
          itemType: this.writeBusinesses ? "toggle" : "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.isLicensed ? "Licenced" : "Unlicenced",
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.isAFMEnabled ? "Enabled" : "Disabled",
          itemType: "string",
        },
      ]);
    },
    selectFilteredStatus() {
      this.selectedStatus =
        this.filterStatuses[this.selectedFilteredStatus].value;
    },
    setSecurityAccess(securityRoles) {
      let initialReadBusinesses = false;
      let initialWriteBusinesses = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.businesses.readBusinesses.value) {
          initialReadBusinesses =
            securityConfig.businesses.readBusinesses.value;
        }
        if (securityConfig.businesses.writeBusinesses.value) {
          initialWriteBusinesses =
            securityConfig.businesses.writeBusinesses.value;
        }
      });

      return {
        readBusinesses: initialReadBusinesses,
        writeBusinesses: initialWriteBusinesses,
      };
    },
    closeModal() {
      this.init();
      this.$refs.modal.closeModal();
    },
    async toggleAFMAccess(businessId) {
      if (
        confirm(
          "Are you sure you want to update the AFM access for this business?"
        )
      ) {
        const targetBusiness = this.businesses.find((business) => {
          return business.id == businessId;
        });
        const payload = {
          businessId,
          data: {
            isAFMEnabled: !targetBusiness.isAFMEnabled,
          },
        };
        try {
          await this.$store.dispatch("business/updateBusiness", payload);
          const successMessage = `AFM access is ${
            !targetBusiness.isAFMEnabled ? "enabled" : "disabled"
          } successfully`;

          await this.toast("success", successMessage);
          await this.init();
        } catch (error) {
          const errorMessage = `Failed to ${
            !targetBusiness.isAFMEnabled ? "enable" : "disable"
          } AFM access`;

          this.toast("error", errorMessage);
          await this.init();
        }
      }
    },
    async toast(state, message) {
      this.isLoading = true;

      const toastPayload = {
        state,
        message,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", toastPayload);
      this.isLoading = false;
    },
    async downloadDrivers(businessId) {
      const business = this.businesses.find(x => x.id = businessId);

      const query = {
        skip: 0,
        limit: -1,
        filter: { $or: [{status: 'linked'}, {status: 'linked-non-ewd'}] }
      }
      const driverContracts = await this.$store.dispatch(`business/getContractByBusiness`, { uid : businessId, query})
      if (driverContracts.results && driverContracts.results.length > 1) {
        const filteredContracts = _.uniqBy(driverContracts.results, 'driverId');

        let driverJSON = [];
        filteredContracts.forEach(contract => {
          driverJSON.push({
            emailAddress: contract.driver.emailAddress ?? '',
            driverName: contract.driver.driverName ?? '',
            driverDateOfBirth: formatDate(contract.driver.driverDateOfBirth, 'DD/MM/YYYY') ?? '',
            driversLicenseNumber: contract.driver.driversLicenseNumber ?? '',
            driversLicenseExpiry: formatDate(contract.driver.driversLicenseExpiry, 'DD/MM/YYYY') ?? '',
            driversLicenseState: contract.driver.driversLicenseState.split(' ')[0] ?? '',
            driversBaseLocation: contract.driver?.driversBaseLocation ?? '',
            recordKeeperAddress: contract?.profile?.recordKeeperAddress ?? '',
            driversBaseTimeZone: contract.driver?.driversBaseTimeZone ?? '',
            engineRegion: contract.driver?.engineRegion ?? '',
            bfmNumber: contract?.profile?.BFMNumber ?? ''
          });
        });
        
        let csvString = 'Email,Name,DOB,Licence number,Licence expiry date,Licence issue state,Base Location,Record Keeper Address,Base time zone,Region,BFM number if applicable\r\n';
        csvString += convertJSONToCSV(JSON.parse(JSON.stringify(driverJSON)));
        downloadCSV(csvString, `${business.name.toLowerCase().replace(/\s/g, '-')}-drivers`);
        await this.toast("success", 'CSV successfully generated.');
      } else {
        await this.toast("error", 'No drivers found.');
      }
    },
    async downloadVehicles(businessId) {
      const business = this.businesses.find(x => x.id = businessId);

      const query = {
        skip: 0,
        limit: -1,
        filter: {},
      }

      const vehicles = await this.$store.dispatch(`vehicle/getVehicleByEntityId`, {
        entityId: businessId,
        query,
      });
      if (vehicles.results && vehicles.results.length > 1) {
        let vehicleJSON = [];
        vehicles.results.forEach(vehicle => {
          vehicleJSON.push({
            vehiclePlate: vehicle.vehiclePlate ?? '',
            registrationExpiry: formatDate(vehicle.registrationExpiry, 'DD/MM/YYYY') ?? '',
            GMV: vehicle.GMV ?? '',
            driveVehicle: vehicle?.driveVehicle ?? false,
            concreteAgitator: vehicle?.concreteAgitator ?? false,
            livestockVehicle: vehicle?.livestockVehicle ?? false,
            VIN: vehicle?.VIN ?? '',
            fleetId: vehicle?.fleetId ?? '',
            vehicleClass: vehicle.vehicleClass?.name ?? '',
            vehicleType: vehicle.vehicleType?.name ?? ''
          });
        });
        
        let csvString = 'Plate Number,Registration Expiry,GVM (Tonnes),Driver Vehicle (Yes/No),Concrete Agitator (Yes/No),Livestock Vehicle (Yes/No),VIN (Optional),Fleet ID (Optional),Vehicle Class,Vehicle Type\r\n';
        csvString += convertJSONToCSV(JSON.parse(JSON.stringify(vehicleJSON)));
        downloadCSV(csvString, `${business.name.toLowerCase().replace(/\s/g, '-')}-vehicles`);
        await this.toast("success", 'CSV successfully generated.');
      } else {
        await this.toast("error", 'No vehicles found.');
      }
    }
  },
  computed: {
    businessData() {
      if (this.businesses) {
        return this.parseBusiness(this.businesses);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
