<template>
    <div class="w-10 h-10 border-8 border-blue rounded-full loader" :style="{borderColor: theme.primaryColor, borderRightColor: 'transparent'}"></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Spinner',
  props: {},
 computed: {
     ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@keyframes loader-rotate {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	.loader {
		border-right-color: transparent;
		animation: loader-rotate 1s linear infinite;
	}
</style>
