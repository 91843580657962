<template>
  <div class="bg-gray-900">
    <div class="container py-10 h-screen flex">
      <div class="login-container w-96 m-auto">
        <div class="text-center m-5 -mt-40">
          <a :href="$store.getters['baseURL']"><img src="assets/images/logo-min.png" alt="" class="w-64 m-auto"></a>
        </div>
        <div class="rounded-lg bg-gray-50 p-10 shadow">
          <ResetPassword :token="token"></ResetPassword>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ResetPassword } from  '@/components/forms'
  export default {
    name: "Account-Forgot-Password",
    components: {ResetPassword},
    props: {
      token: String
    },
    async mounted(){
      if(!this.token) window.location.href = '/404?message=Request token not found'.
      console.log('AF P');
    },
  };
</script>
