<template>
  <div class="w-full min-h-full flex-row items-center">
    <div class="w-full min-h-full flex-row">
      <FormBuilder 
         formSubmitted="formSubmitted"
         :isContractTerms="true"
         :typeOption="typeOption"
        />
    </div>
  </div>
</template>

<script>
import FormBuilder from '@/components/commons/FormBuilder';
 
export default {
   components: {
      FormBuilder
   },
   data: () => ({
      typeOption: [
         { value: 'logmaster', label: 'Logmaster Standard' },
      ],
   }),
   methods: {
      formSubmitted(res){
         this.toast('success', res.message);
      },
      toast(state,message){
         this.$store.commit('setDialogNotify',true);
         this.$store.commit('setMessageNotify',{state, message});
      },
   }
}
</script>