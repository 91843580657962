<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
        <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="text-red-500 font-bold text-sm text-center my-3 bg-red-200 border rounded border-red-500 p-3" v-if="vehicle.deleteStatus">
      This vehicle is pending for deletion
    </div>
    <div v-if="!loading">
      <div class="text-center mb-10">
        <div class="flex text-right justify-end">
          <StyledIconedLink @click.prevent="onClickEdit()" class="flex cursor-pointer mx-1.5"
          :iconHoverColor="theme.secondaryColor"
            >
            <font-awesome-icon
              icon="edit"
              class="my-auto text-xs"
          />
          </StyledIconedLink>
          <StyledIconedLink v-if="!vehicle.deleteStatus" @click.prevent="onClickDelete()" class="flex cursor-pointer mx-1.5"
          :iconHoverColor="theme.secondaryColor"
            >
            <font-awesome-icon
              icon="trash"
              class="my-auto text-xs"
          />
          </StyledIconedLink>
        </div>
        <div
          class="font-bold text-gray-600 text-4xl border-4 rounded w-max p-2 mx-auto mb-2"
        >
          {{vehicle.vehiclePlate}}
        </div>
        <div
          class="font-bold text-xs mb-5"
          :class="[vehicle.isActive ? 'text-green-500' : 'text-red-500']"
        >
          {{vehicle.isActive ? 'ACTIVE' : 'INACTIVE'}}
        </div>
      </div>
      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Vehicle Details</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{vehicle.vehiclePlate}}
        </div>
        <div class="text-xs">Plate Number</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{vehicle.registrationExpiry}}
        </div>
        <div class="text-xs">Registration Expiry</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{vehicle.gvm}}</div>
        <div class="text-xs">GVM</div>
      </div>
      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Vehicle Classification</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{vehicle.vehicleClass}}
        </div>
        <div class="text-xs">Vehicle Class</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{vehicle.vehicleType}}
        </div>
        <div class="text-xs">Vehicle Type</div>
      </div>
      <div class="mb-2 flex items-center">
        <div class="mr-2">
          <font-awesome-icon
            v-if="vehicle.driveVehicle"
            class="text-green-500"
            icon="check"
          />
          <font-awesome-icon
            v-if="!vehicle.driveVehicle"
            class="text-red-500"
            icon="times"
          />
        </div>
        <div class="text-xs">Drive Vehicle</div>
      </div>
      <div class="mb-2 flex items-center">
        <div class="mr-2">
          <font-awesome-icon
            v-if="vehicle.concreteAgitator"
            class="text-green-500"
            icon="check"
          />
          <font-awesome-icon
            v-if="!vehicle.concreteAgitator"
            class="text-red-500"
            icon="times"
          />
        </div>
        <div class="text-xs">Concrete Agitator</div>
      </div>
      <div class="mb-2 flex items-center">
        <div class="mr-2">
          <font-awesome-icon
            v-if="vehicle.livestockVehicle"
            class="text-green-500"
            icon="check"
          />
          <font-awesome-icon
            v-if="!vehicle.livestockVehicle"
            class="text-red-500"
            icon="times"
          />
        </div>
        <div class="text-xs">Livestock Vehicle</div>
      </div>
      <div class="mt-8 mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Vehicle Specification</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{vehicle.vin}}</div>
        <div class="text-xs">VIN</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{vehicle.fleetId? vehicle.fleetId : "-"}}</div>
        <div class="text-xs">Fleet ID</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{vehicle.alias? vehicle.alias : "-"}}</div>
        <div class="text-xs">Alias</div>
      </div>
      <div class="mb-6">
        <div class="text-xs mb-2">Front Couplings</div>
        <div v-if="!vehicle.frontCoupling" class="mb-2 flex items-center">
          <div class="mr-2">
            <font-awesome-icon class="text-gray-600" icon="caret-right" />
          </div>
          <div class="font-bold text-black-primary text-base">-</div>
        </div>
        <div v-for="(frontCouplings, index) in vehicle.frontCoupling" :key="index" class="mb-2 flex items-center">
          <div class="mr-2">
            <font-awesome-icon class="text-gray-600" icon="caret-right" />
          </div>
          <div class="font-bold text-black-primary text-base">{{frontCouplings}}</div>
        </div>
      </div>
      <div class="mb-6">
        <div class="text-xs mb-2">Rear Couplings</div>
        <div v-if="!vehicle.rearCoupling" class="mb-2 flex items-center">
          <div class="mr-2">
            <font-awesome-icon class="text-gray-600" icon="caret-right" />
          </div>
          <div class="font-bold text-black-primary text-base">-</div>
        </div>
        <div v-for="(rearCouplings, index) in vehicle.rearCoupling" :key="index" class="mb-2 flex items-center">
          <div class="mr-2">
            <font-awesome-icon class="text-gray-600" icon="caret-right" />
          </div>
          <div class="font-bold text-black-primary text-base">{{rearCouplings}}</div>
        </div>
      </div>
      <div class="mb-6">
        <div class="text-xs mb-2">Power Couplings</div>
        <div v-if="!vehicle.powerCoupling" class="mb-2 flex items-center">
          <div class="mr-2">
            <font-awesome-icon class="text-gray-600" icon="caret-right" />
          </div>
          <div class="font-bold text-black-primary text-base">-</div>
        </div>
        <div v-for="(powerCouplings, index) in vehicle.powerCoupling" :key="index" class="mb-2 flex items-center">
          <div class="mr-2">
            <font-awesome-icon class="text-gray-600" icon="caret-right" />
          </div>
          <div class="font-bold text-black-primary text-base">{{powerCouplings}}</div>
        </div>
      </div>
      <div class="mb-6">
        <div class="text-xs mb-2">Suspensions</div>
        <div v-if="!vehicle.suspensions" class="mb-2 flex items-center">
          <div class="mr-2">
            <font-awesome-icon class="text-gray-600" icon="caret-right" />
          </div>
          <div class="font-bold text-black-primary text-base">-</div>
        </div>
        <div v-for="(suspension, index) in vehicle.suspensions" :key="index" class="mb-2 flex items-center">
          <div class="mr-2">
            <font-awesome-icon class="text-gray-600" icon="caret-right" />
          </div>
          <div class="font-bold text-black-primary text-base">{{suspension}}</div>
        </div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{vehicle.brakeFoundation ? vehicle.brakeFoundation.name : "-"}}
        </div>
        <div class="text-xs">Brake Foundation</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{vehicle.brakeType ? vehicle.brakeType.name : "-"}}
        </div>
        <div class="text-xs">Brake System Type</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{vehicle.supplyVoltageToTrailer ? vehicle.supplyVoltageToTrailer.name : "-"}}
        </div>
        <div class="text-xs">Supply Voltage to Trailer</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{vehicle.comment1 ? vehicle.comment1 : "-"}}</div>
        <div class="text-xs">LSV, ABS, EBS, T-EBS Brake System Supplier</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{vehicle.comment2 ? vehicle.comment2 : "-"}}</div>
        <div class="text-xs">
          Trailer SARN, Trailer Brake Control System Reference Number
        </div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{vehicle.comment3 ? vehicle.comment3 : "-"}}</div>
        <div class="text-xs">Comments</div>
      </div>
      <div class="mb-5 w-full border-b pb-1">
          <div class="text-xs font-bold">Transport Service License</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{vehicle.tslNumber}}</div>
          <div class="text-xs">TSL Number</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{vehicle.tslHolderName}}</div>
          <div class="text-xs">Holder Name</div>
      </div>
      <div class="mb-5">
          <div class="font-bold text-black-primary text-base">{{vehicle.tslSerialNumber}}</div>
          <div class="text-xs">Serial Number</div>
      </div>
      <div class="mb-5" v-if="vehicle?.driverLicenceRenewalChecked">
          <div class="font-bold text-black-primary text-base">{{vehicle?.driverLicenceRenewalChecked}}</div>
          <div class="text-xs">Driver Licence Renewal Checked</div>
      </div>
      <div class="mb-5" v-if="vehicle?.passengerEndorsementExpiry">
          <div class="font-bold text-black-primary text-base">{{vehicle?.passengerEndorsementExpiry}}</div>
          <div class="text-xs">Passenger Endorsement Expiry</div>
      </div>
      <div class="mb-5" v-if="vehicle?.passengerEndorsementRenewalChecked">
          <div class="font-bold text-black-primary text-base">{{vehicle?.passengerEndorsementRenewalChecked}}</div>
          <div class="text-xs">Passenger Endorsement Renewal Checked</div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { formatDate } from "@/_helper";
  import { mapGetters } from 'vuex'
  import {StyledIconedLink} from '@/styled-components'

  export default {
    name: "Vehicle-Details-View",
    components: {StyledIconedLink},
    props: {
        detailsId:{
            type: String,
            default: '',
        }
    },
    data() {
      return {
        tslMetadata: {},
        vehicle: {},
        loading: false
      };
    },
    async mounted() {
        this.loading = true
        const vehicleDetails = await this.$store.dispatch(`vehicle/getVehicle`, {id: this.detailsId})
        if(vehicleDetails){
            this.vehicle = this.parseVehicle(vehicleDetails)
            this.loading = false
        }
    },
    methods: {
      parseVehicle(vehicle) {
        return {
          id: vehicle._id,
          isActive: vehicle.isActive,
          vehiclePlate: vehicle.vehiclePlate,
          registrationExpiry: formatDate(
            vehicle.registrationExpiry,
            "DD/MM/YYYY"
          ),
          gvm: vehicle.GVM,
          vehicleClass: vehicle.vehicleClass?.name || "N/A",
          vehicleType: vehicle.vehicleType?.name || "N/A",
          driveVehicle: vehicle.driveVehicle,
          concreteAgitator: vehicle.concreteAgitator,
          livestockVehicle: vehicle.livestockVehicle,
          vin: vehicle.VIN,
          alias: vehicle.alias,
          fleetId: vehicle.fleetId,
          frontCoupling: vehicle.frontCouplings.map(e => e.name),
          rearCoupling: vehicle.rearCouplings.map(e => e.name),
          powerCoupling: vehicle.powerCouplings.map(e => e.name),
          brakeFoundation: vehicle.brakeFoundation,
          brakeType: vehicle.brakeType,
          supplyVoltageToTrailer: vehicle.supplyVoltageToTrailer,
          suspensions: vehicle.suspensions.map(e => e.name),
          comment1: vehicle.lAETBSysSupplier,
          comment2: vehicle.tSTBCSRefNumber,
          comment3: vehicle.comments,
          deleteStatus: vehicle.deleteStatus,
          tslNumber: vehicle?.tslId?.tslNumber,
          tslHolderName: vehicle?.tslId?.holderName,
          tslSerialNumber: vehicle?.tslSerialNumber,
          passengerEndorsementExpiry: moment(vehicle.passengerEndorsementExpiry).format('YYYY-MM-DD') || null,
          driverLicenceRenewalChecked: moment(vehicle.driverLicenceRenewalChecked).format('YYYY-MM-DD') || null,
          passengerEndorsementRenewalChecked: moment(vehicle.passengerEndorsementRenewalChecked).format('YYYY-MM-DD') || null,
        };
      },
      onClickEdit() {
        this.$emit("editVehicle", this.vehicle.id);
      },
      onClickDelete() {
        if(confirm('Are you sure you want to delete this vehicle?')) {
          this.$store.dispatch(`vehicle/deleteVehicle`, this.detailsId).then(vehicle => {
          if(vehicle && vehicle._id){
            this.$emit('closeRefresh');
          }
          }).catch(error => {
            this.errorMessage = `Something went wrong please try again.`;
            if(error.message) this.errorMessage = error.message[0]
          });
        }
      }
    },
    computed: {
        ...mapGetters('theme', {
            theme: 'getColorScheme'
        })
    }
  };
</script>
