var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-16"},[(_vm.isLoading)?_c('div',{staticClass:"loading centered"},[_c('ui-spinner',{staticClass:"mx-auto"})],1):_vm._e(),(!_vm.isLoading)?_c('div',[(_vm.isDriver)?_c('div',{staticClass:"flex flex-row justify-end"},[_c('div',{staticClass:"px-2"},[_c('button',{staticClass:"btn btn-primary",style:({backgroundColor: _vm.theme.secondaryColor}),on:{"click":function($event){$event.preventDefault();return _vm.toggleDelinkCert(_vm.certContent._id)}}},[_vm._v(" Delink Certificate ")])])]):_vm._e(),_vm._m(0),_c('div',{staticClass:"text-center mb-10"},[_c('div',{staticClass:"font-bold text-black-primary text-2xl"},[_vm._v(" "+_vm._s(_vm.certContent.certNumber)+" ")]),_c('div',{staticClass:"font-bold text-xs",class:[_vm.certContent.isActive ? 'text-green-500' : 'text-red-500']},[_vm._v(" "+_vm._s(_vm.certContent.isActive ? "ACTIVE" : "INACTIVE")+" ")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold text-black-primary text-base"},[_vm._v(" "+_vm._s(_vm.certContent.type)+" ")]),_c('div',{staticClass:"text-xs"},[_vm._v("Type")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold text-black-primary text-base"},[_vm._v(" "+_vm._s(_vm.formattedDate(_vm.certContent.createdAt))+" ")]),_c('div',{staticClass:"text-xs"},[_vm._v("Date of Creation")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold text-black-primary text-base"},[_vm._v(" "+_vm._s(_vm.formattedDate(_vm.certContent.certExpiryDate))+" ")]),_c('div',{staticClass:"text-xs"},[_vm._v("Expiry Date")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold text-black-primary text-base"},[_vm._v(" "+_vm._s(_vm.formattedDate(_vm.certContent.updatedAt))+" ")]),_c('div',{staticClass:"text-xs"},[_vm._v("Last Updated")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold",class:[
          _vm.certContent.isMedicalCertRequired
            ? 'text-green-500'
            : 'text-red-500',
        ]},[_vm._v(" "+_vm._s(_vm.certContent.isMedicalCertRequired ? "REQUIRED" : "OPTIONAL")+" ")]),_c('div',{staticClass:"text-xs"},[_vm._v("Medical Certificate Requirement")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold",class:[
          _vm.certContent.isSignatureApprovalRequired
            ? 'text-green-500'
            : 'text-red-500',
        ]},[_vm._v(" "+_vm._s(_vm.certContent.isSignatureApprovalRequired ? "REQUIRED" : "OPTIONAL")+" ")]),_c('div',{staticClass:"text-xs"},[_vm._v("Signature Approval Requirement")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold",class:[
          _vm.certContent.isTrainingCertRequired
            ? 'text-green-500'
            : 'text-red-500',
        ]},[_vm._v(" "+_vm._s(_vm.certContent.isTrainingCertRequired ? "REQUIRED" : "OPTIONAL")+" ")]),_c('div',{staticClass:"text-xs"},[_vm._v("Training Certificate Requirement")])]),_vm._m(1),_c('div',{staticClass:"text-center mb-10"},[_c('div',{staticClass:"font-bold text-black-primary text-2xl"},[_vm._v(" "+_vm._s(_vm.adminDetails.username)+" ")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold text-black-primary text-base"},[_vm._v(" "+_vm._s(_vm.adminDetails.email)+" ")]),_c('div',{staticClass:"text-xs"},[_vm._v("Email")])]),_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold text-black-primary text-base"},[_vm._v(" "+_vm._s(_vm.formattedDate(_vm.adminDetails.createdAt))+" ")]),_c('div',{staticClass:"text-xs"},[_vm._v("Registered")])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5 w-full border-b pb-1"},[_c('div',{staticClass:"text-xs font-bold"},[_vm._v("Certificate Details")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-5 w-full border-b pb-1"},[_c('div',{staticClass:"text-xs font-bold"},[_vm._v("Admin Details")])])
}]

export { render, staticRenderFns }