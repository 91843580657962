export default [
    {
      type: 'text',
      name: 'businessName',
      label: 'Business Name',
      validation: 'required'
    },
    {
      name: 'abn',
      label: 'NZBN',
      validation: 'required|number'
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      validation: 'required'
    },
    {
    type: 'group',
    name: 'location',
    label: 'Location:',
    labelClass: 'font-semibold text-sm block -top-7 -left-0 absolute',
    class: 'border rounded p-5 mb-5 mt-10 relative',
    children: [
      {
        name: 'businessAddress',
        label: 'Business Address',
        validation: 'required',
        type: 'places-search-input',
      },
    ],
    },
  ]