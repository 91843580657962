<template>
  <div class="w-full mt-10 flex">
    <div class="w-1/3 p-4 border rounded border-gray-500 container m-auto">
      <ChangePassword />
    </div>
  </div>
</template>

<script>
import { ChangePassword } from '@/components/forms/account/'
export default {
    components: { ChangePassword }
}
</script>

<style>

</style>