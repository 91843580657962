<template>
  <div v-if="formFieldData && formFieldData.id" class="w-full">
    <template v-if="formFieldData.component == 'plain-text'">
      <plain-text :isDisplayValue="isDisplayValue" :control="formFieldData" style="max-width: 960px" />
    </template>
    <template v-if="formFieldData.component == 'text-control'">
      <text-input :isDisplayValue="isDisplayValue" :control="formFieldData" class="w-full" />
    </template>
    <template v-if="formFieldData.component == 'number-control'">
      <number-input :isDisplayValue="isDisplayValue" :control="formFieldData" class="w-full" />
    </template>
    <template v-if="formFieldData.component == 'email-control'">
      <email-input :isDisplayValue="isDisplayValue" :control="formFieldData" class="w-full" />
    </template>
    <template v-if="formFieldData.component == 'password-control'">
      <password-input :isDisplayValue="isDisplayValue" :control="formFieldData" class="w-full" />
    </template>
    <template v-if="formFieldData.component == 'textarea-control'">
      <text-area :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'select-control'">
      <dropdown-select :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'datepicker-control'">
      <date-picker :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'timepicker-control'">
      <time-picker :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'checkbox-control'">
      <checkbox-input :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'radiobutton-control'">
      <radio-button :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'switch-control'">
      <switch-input :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'signaturepad-control'">
      <signature-pad :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'file-control'">
      <file-document :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'image-control'">
      <file-image :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'documentlink-control'">
      <document-link :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
    <template v-if="formFieldData.component == 'vehicle-select-control'">
      <vehicle-select :isDisplayValue="isDisplayValue" :control="formFieldData" />
    </template>
  </div>
</template>
<script>
import PlainText from '@/components/controls/PlainText'
import TextInput from '@/components/controls/TextInput'
import NumberInput from '@/components/controls/NumberInput'
import EmailInput from '@/components/controls/EmailInput'
import PasswordInput from '@/components/controls/PasswordInput'
import TextArea from '@/components/controls/TextArea'
import DropdownSelect from '@/components/controls/DropdownSelect'
import DatePicker from '@/components/controls/DatePicker'
import TimePicker from '@/components/controls/TimePicker'
import CheckboxInput from '@/components/controls/CheckboxInput'
import RadioButton from '@/components/controls/RadioButton'
import SignaturePad from '@/components/controls/SignaturePad'
import FileDocument from '@/components/controls/FileDocument'
import FileImage from '@/components/controls/FileImage'
import SwitchInput from '@/components/controls/SwitchInput'
import DocumentLink from '@/components/controls/DocumentLink'
import VehicleSelect from '@/components/controls/VehicleSelect'
import { HELPER } from '@/api/helper'

export default {
  components: {
    PlainText,
    TextInput,
    NumberInput,
    EmailInput,
    PasswordInput,
    TextArea,
    DropdownSelect,
    DatePicker,
    TimePicker,
    RadioButton,
    SignaturePad,
    FileImage,
    FileDocument,
    SwitchInput,
    DocumentLink,
    CheckboxInput,
    VehicleSelect
  },
  props: {
    component: {
      type: Object,
      default: () => {}
    },
    sectionId: {
      default: ''
    },
    isDisplayValueData: {
      default: false
    }
  },

  watch: {
    component: {
      deep: true,
      handler() {
        this.setFormFieldData()
      }
    }
  },

  data: () => ({
    formFieldData: {},
    isDisplayValue: false
  }),

  created() {
    this.setFormFieldData()
  },

  methods: {
    setFormFieldData() {
      const data = HELPER.cloneDeep(this.component)
      data.sectionId = String(HELPER.cloneDeep(this.sectionId))
      this.isDisplayValue = this.isDisplayValueData
      this.formFieldData = data
    }
  }
}
</script>
