import store from '@/store'
import VueCookies from 'vue-cookies'
import { ADMIN_ROLE, PARTNER_ROLE, RESELLER_ROLE, BUSINESS_ROLE, DRIVER_ROLE  } from '@/constants/usertype'

function guards(guards){
    return async (to, from, next) => {
        // If two factor prompt exist, remove all cookies and logout
        if (localStorage.getItem('two-factor-prompt')) {
            localStorage.removeItem('two-factor-prompt')
            await logoutAccount();
            return;
        }
        
        // a flag to discard remaining guards
        let changed = false;

        // handle next for multiple guards
        const mNext = function (value)
        {
            // prevent calling next after it is already resolved with a value
            if(changed) return;

            // if we have 'value' reslove next with the value and set changed flag 
            if(typeof value != 'undefined')
            {
                changed = true;
                next(value);
            }
        };

        // call guards
        for (let i = 0; i < guards.length; i++)
        {
            if(changed) break;
            await guards[i](to, from, mNext);
        }

        // move on if none of guards resolved next with a value
        if(!changed) next();

    }
}

// @Account
// Authentication Guard
async function isNotLogged(to, from, next) {
    const token = await store.getters[`account/accessToken`];
    if (token) {
      const account = await store.dispatch(`account/getMe`);
      if (account) {
        next(`/${account.role.name}`);
      }
      // Scenario where getMe api fails is handled in interceptor response in src/api/config.js
    } else {
        next();
    }
  }
  async function authGuard(to, from, next) {
    const auth = await store.dispatch(`account/isAuthenticated`);
    if (auth) {
      next();
    } else {
      // Scenario where isAuthenticated->getMe api fails is handled in interceptor response in src/api/config.js
    }
  }
async function isAdminGuard(to, from, next){
    const role = await store.getters[`account/roleName`];
    if(role == ADMIN_ROLE.name){
        next()
    }else{
        console.error('Invalid user permission #1');
        next('/logout')
    }
}
async function isPartnerGuard(to, from, next){
    const role = await store.getters[`account/roleName`];
    if(role == PARTNER_ROLE.name || role == ADMIN_ROLE.name){
        next()
    }else{
        console.error('Invalid user permission #2');
        next('/logout')
    }
}
async function isResellerGuard(to, from, next){
    const role = await store.getters[`account/roleName`];
    if(role == RESELLER_ROLE.name || role == ADMIN_ROLE.name){
        next()
    }else{
        console.error('Invalid user permission #3');
        next('/logout')
    }
}
async function isBusinessGuard(to, from, next){
    const role = await store.getters[`account/roleName`];
    const roles = await store.getters[`account/roleNames`];
    if(role == BUSINESS_ROLE.name || role == ADMIN_ROLE.name || roles.includes('business')){
        next()
    }else{
        console.error('Invalid user permission #3');
        next('/logout')
    }
}
async function isDriverGuard(to, from, next){
    const role = await store.getters[`account/roleName`];
    const roles = await store.getters[`account/roleNames`];
    if(role == DRIVER_ROLE.name|| role == ADMIN_ROLE.name || roles.includes('driver')){
        next()
    }else{
        console.error('Invalid user permission #3');
        next('/logout')
    }
}
async function logoutAccount(){
    if(VueCookies.keys()){
        await VueCookies.keys().forEach(key => {
                VueCookies.remove(key)
        });
        window.location.href = '/login'
    }
}

async function isDriverWithContractOrSuspended(to, from, next) {
    const me = await store.getters['account/me'];
    await store.dispatch('account/updateMe', me)
    const updatedProfile = await store.getters['account/me'];
    if(updatedProfile.contract != undefined) {
        if(to.name != 'Dashboard - Driver' && me.contract.status.name == 'Suspended') {
          console.log("Account Suspended")
          next({name: 'Dashboard - Driver'})
        } else {
          next()
        }
    } 
    else if(updatedProfile.entity.parentRole == 'partner' || updatedProfile.entity.parentRole == 'reseller') { //if driver is a solo and doesn't have a contract
        if(updatedProfile.contract === undefined) {
            if(to.name != 'Dashboard - Driver') {
                console.log("No Contract Found")
                next({name: 'Dashboard - Driver'})
            } else {
                next()
            }
        }
    }
    else {
    console.log(to)
    next()
    }
}

async function loginWithUid(to, from, next) {
    await store.dispatch('account/loginDriverUID', {uid: to.params.uid})
        .then(async (response) => {
            if(response) {
                next({name: 'Platform Training - Driver'});
            } else {
                next({name: 'Login'})
            }
        }).catch(() => {
            next({name: "Login"})
        })
}

export { 
    guards, 
    isNotLogged, 
    authGuard, 
    isAdminGuard, 
    isPartnerGuard, 
    isResellerGuard,
    isBusinessGuard,
    isDriverGuard,
    logoutAccount,
    isDriverWithContractOrSuspended,
    loginWithUid
}