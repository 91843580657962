<template>
  <div>
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
    <div v-if="!isLoading">
      <div class="">
        <div class="mx-5 mb-10 text-center">
          <div
            class="text-black-primary text-xl font-bold"
            :style="{ color: theme.primaryColor }"
          >
            {{ startDate }} - {{ endDate }}
          </div>
          <div class="font-bold text-m">Date Range</div>
        </div>
        <div class="mx-5 mb-10 text-center">
          <div
            class="text-black-primary text-xl font-bold"
            :style="{ color: theme.secondaryColor }"
          >
            {{ summaryData.nonConformanceCount }}
          </div>
          <div class="font-bold text-m">Total Non Conformances</div>
        </div>
        <div class="grid grid-cols-2 gap-4 mb-10">
          <div class="text-center">
            <div
              class="text-black-primary text-xl font-bold"
              :style="{ color: theme.secondaryColor }"
            >
              {{ summaryData.pending }}
            </div>
            <div class="font-bold text-m">Pending</div>
          </div>
          <div class="text-center">
            <div
              class="text-black-primary text-xl font-bold"
              :style="{ color: theme.secondaryColor }"
            >
              {{ summaryData.accepted }}
            </div>
            <div class="font-bold text-m">Accepted</div>
          </div>
          <div class="text-center">
            <div
              class="text-black-primary text-xl font-bold"
              :style="{ color: theme.secondaryColor }"
            >
              {{ summaryData.contest }}
            </div>
            <div class="font-bold text-m">Contest</div>
          </div>
          <div class="text-center">
            <div
              class="text-black-primary text-xl font-bold"
              :style="{ color: theme.secondaryColor }"
            >
              {{ summaryData.contestRejected }}
            </div>
            <div class="font-bold text-m">Contest Rejected</div>
          </div>
          <div class="text-center">
            <div
              class="text-black-primary text-xl font-bold"
              :style="{ color: theme.secondaryColor }"
            >
              {{ summaryData.withdrawn }}
            </div>
            <div class="font-bold text-m">Withdrawn</div>
          </div>
          <div class="text-center">
            <div
              class="text-black-primary text-xl font-bold"
              :style="{ color: theme.secondaryColor }"
            >
              {{ summaryData.finalised }}
            </div>
            <div class="font-bold text-m">Finalised</div>
          </div>
          <div class="text-center">
            <div
              class="text-black-primary text-xl font-bold"
              :style="{ color: theme.secondaryColor }"
            >
              {{ summaryData.reserved }}
            </div>
            <div class="font-bold text-m">Reserved</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "Summary-Report-Investigation",
  components: {},
  props: {
    dateRange: Array,
    businessId: String,
  },
  data() {
    return {
      isLoading: false,
      error: false,
      errorMessage: null,
      startDate: null,
      endDate: null,
      summaryData: {
        nonConformanceCount: 0,
        pending: 0,
        accepted: 0,
        contest: 0,
        contestRejected: 0,
        withdrawn: 0,
        finalised: 0,
        reserved: 0,
      },
    };
  },
  mounted() {
    this.fetchSummaryData();
  },
  methods: {
    async fetchSummaryData() {
      this.startDate = moment(this.dateRange[0]).format("YYYY-MM-DD");
      this.endDate = moment(this.dateRange[1]).format("YYYY-MM-DD");

      const payload = {
        startDate: this.startDate,
        endDate: this.endDate,
        businessId: this.businessId,
      };

      this.isLoading = true;
      try {
        this.summaryData = await this.$store.dispatch(
          "report/getNonConformanceSummaryReport",
          payload
        );
        this.isLoading = false;
      } catch (error) {
        this.toast("error", "Something went wrong!");
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
