<template>
  <div>
    <div
      class="text-red-500 font-bold text-sm text-center mt-0 my-5"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </div>
    <FormulateForm
      name="resetpassword"
      v-model="forgotPasswordForm"
      @submit="onSubmit"
    >
      <FormulateInput
        type="submit"
        input-class="btn btn-primary w-full"
        :disabled="loading"
        :style="{ backgroundColor: theme.secondaryColor }"
      >
        <span v-if="loading"
          ><font-awesome-icon icon="spinner" class="mr-1 loader" />
          Submitting...</span
        >
        <span v-if="!loading">Dispute</span>
      </FormulateInput>
    </FormulateForm>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "ForgotPasswordForm",
  props: {
    msg: String,
  },
  data() {
    return {
      forgotPasswordForm: {},
      error: false,
      errorMessage: "",
      token: "",
      loading: false,
    };
  },
  mounted() {
    this.token = this.$route.params.token;
    if (!this.token) window.location.href = "/404?message=Invalid request";
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const payLoad = {
        token: this.token,
      };
      await this.$store
        .dispatch(`account/disputeChangeEmail`, payLoad)
        .then((resetPassword) => {
          if (resetPassword && resetPassword.success) {
            window.location.href =
              "/login?message=Email are successfully restored.";
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.errorMessage = err.message;
          this.$formulate.reset("resetpassword");
        });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
