import {
  Auth,
  LogmasterIntegration,
  DriverManagement,
  Business,
  Driver,
  Vehicle,
  RolesAndRespo,
} from "@/api";
import { User } from "./api-models/common/user";
import { LinkedDriver } from "./api-models/getLinkedDrivers/get-linked-drivers";
import { LoggedInUser } from "./api-models/getLoggedInUser/get-logged-in-user";
import { LinkedVehicle } from "./api-models/getVehicles/get-vehicles";
/**
 * this can be used for global settings.
 */
export let global = {};
/**
 * Evaluates the uid and role and assigns entity id
 * @param {*} uid
 * @param {*} role
 */
let getEntityByUIDRole = async (uid, role) => {
  if (role == "business") {
    let businessData = await Business.getBusinessByUID(uid);
    return businessData.data?.data;
  } else if (role == "driver") {
    let driverData = await Driver.getDriverByUID(uid);
    return driverData.data?.data;
  }
};
let evaluateRoleOfLoggedInUser = () => {
  return {
    isBusiness: logmaster.current_logged_in_role == "business",
    isDriver: logmaster.current_logged_in_role == "driver",
  };
};
export const logmaster = {
  current_addin_url: "",
  current_addin_name: "",
  current_logged_in_uid: "",
  current_logged_in_entity_id: "",
  current_logged_in_role: "",
  /**
   * This will evaluate which add-in's initialize() function to run based on the url parameters given
   * @param {*} param The route parameters from the url
   */
  evaluateAddInToRunThenInitialize: async (param) => {
    try {
      let responseConfig = await LogmasterIntegration.getConfigJsById(
        param["addInId"]
      );
      let data = responseConfig.data.data[0];
      let configToUse = data.config[0];
      let jsToUse = data.jsString;
      if (configToUse.url) {
        logmaster.current_addin_url = configToUse.url;
      }
      if (configToUse.addInName) {
        logmaster.current_addin_name = configToUse.addInName;
      }
      if (jsToUse && jsToUse != "") {
        /**
         * so far this is the only way to add custom addins from external users
         */
        eval(jsToUse);
      }
      //get logged in user
      await logmaster_api.getLoggedInUser();
      await (
        await logmaster.addins[logmaster.current_addin_name](logmaster_api)
      ).initialize(logmaster_api.initializeFinishedCallback);
    } catch (error) {
      console.log("error initializing addin", error);
    }
  },
  addins: {
    /**
     *
     * @param {*} api The main LogmasterAPI object used for communicating to and fetching from Logmaster
     * @returns {{
     *     initialize:  Function,
     *     focus: Function,
     *     blur: Function,
     * }}
     */
    logmasterSampleAddIn_IEfsVYbTzjbb2cw2D0ZtrJ82PKlxh3: async (api) => {
      return {
        /**
         * Called only once when you first access your custom page. In here you can initialize required variables and do some initial loading for your Add-In
         * @param {*} callback this is usually the logmaster_api.initializeFinishedCallback
         */
        initialize: async (callback) => {
          console.log("testing logmaster integration");
          // console.log('current logmaster', logmaster);
          let meOnInit = await api.getLoggedInUser();
          console.log("meOnInit", meOnInit);
          callback();
        },
        /**
         * This is called when the page gets focus from the user. Please see the javascript event: window.addEventListener('focus', ()=>{}); for more details.
         */
        focus: async () => {},
        /**
         * This is called when the page loses focus from the user. Please see the javascript event: window.addEventListener('blur', ()=>{}); for more details.
         */
        blur: async () => {},
      };
    },
  },
};

export const logmaster_api = {
  /**
   * This should be called after the add-in is finished "initialize"-ing
   * This will try to get the url from the given addInId
   * After fetching the url, this function will assign the url to the iframe
   */
  initializeFinishedCallback: async () => {
    let iframe = document.getElementById("logmaster-integration-main-iframe");
    let currentUrl = iframe.getAttribute("src");
    if (currentUrl != logmaster.current_addin_url) {
      iframe.setAttribute("src", logmaster.current_addin_url);
    }
    return true;
  },
  /**
   *
   * @returns LoggedInUser[]
   */
  getLoggedInUser: async () => {
    let me = await Auth.getMe();
    let actualData = me.data?.data;
    if (actualData) {
      let entity = await getEntityByUIDRole(
        actualData.uid,
        actualData.role?.name
      );
      actualData._id = entity?._id;
      actualData.username = entity?.persona?.businessName;
      logmaster.current_logged_in_uid = actualData.uid;
      logmaster.current_logged_in_entity_id = actualData._id;
      logmaster.current_logged_in_role = actualData.role?.name;
    }
    let user = new LoggedInUser(actualData);
    return user;
  },
  /**
   *
   * @returns LinkedDriver[]
   */
  getLinkedDrivers: async () => {
    if (!evaluateRoleOfLoggedInUser().isBusiness) {
      return [];
    }
    let linkedDriversFetched = await DriverManagement.getContractByBusinessId(
      logmaster.current_logged_in_entity_id,
      { skip: 0, limit: 2147483647, filter: { status: { $in: ["linked"] } } }
    );
    let linkedDrivers = [];
    if (linkedDriversFetched.data.success) {
      for (const driver of linkedDriversFetched.data?.data) {
        driver.driver.driverId = driver.driverId;
        linkedDrivers.push(new LinkedDriver(driver.driver));
      }
    }
    return linkedDrivers;
  },
  /**
   *
   * @returns LinkedVehicle[]
   */
  getLinkedVehicles: async () => {
    //only businesses have vehicles
    if (!evaluateRoleOfLoggedInUser().isBusiness) {
      return [];
    }
    let query = {
      skip: 0,
      limit: Number.MAX_SAFE_INTEGER,
      search: "",
      filter: {},
    };
    let res = await Vehicle.getVehicleByEntityId(
      logmaster.current_logged_in_entity_id,
      query
    );
    let vehicles = [];
    if (res.data.success) {
      for (const vehicle of res.data?.data) {
        vehicles.push(new LinkedVehicle(vehicle));
      }
    }
    return vehicles;
  },
  /**
   *
   * @returns User[]
   */
  getLinkedUsers: async () => {
    let res = await RolesAndRespo.getUsersV2();
    return formatUsers(res.data.data);
  },
};

let formatUsers = (users) => {
  let nItems = [];
  if (users) {
    users[0].depotLinks.map((user) => {
      if (user["linkageWebProfileId"]) {
        const obj = {
          id: user["linkageWebProfileId"]["_id"],
          email: user["linkageWebProfileId"]["email"] || "",
          userName: user["linkageWebProfileId"]["userName"] || "",
          phoneNumber: user["linkageWebProfileId"]["phoneNumber"] || "",
          isRoot: user["linkageWebProfileId"]["isRoot"],
          uid: user["linkageWebProfileId"]["uid"] || null,
        };
        let linkedUser = new User(
          obj.id,
          obj.email,
          obj.userName,
          logmaster.current_logged_in_role,
          obj.phoneNumber
        );
        nItems.push(linkedUser);
      }
    });
  }
  return nItems;
};
