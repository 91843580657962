<template>
  <div>
    <FormulateForm v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <div
          v-if="pageInitLoading && preData.selectedModules.cplValues.length > 0"
          class="text-center mb-16"
        >
          <ui-spinner class="mx-auto"></ui-spinner>
          Generating Contract Summary Details...
        </div>

        <div
          v-if="!pageInitLoading"
          class="p-5 mt-5 mb-10 border rounded-lg group"
        >
          <div class="group-title pb-5 font-bold">Contract Summary Details</div>

          <table>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Contract Period:</div>
              </td>
              <td v-if="preData.contractDuration" class="pb-1">
                <div class="ml-3">{{ preData.contractDuration.label }}</div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Billing Period:</div>
              </td>
              <td class="pb-1">
                <div v-if="preData.billingPeriod" class="ml-3">
                  {{ preData.billingPeriod.label }}
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div
          v-if="!pageInitLoading && cplValues.length > 0"
          class="p-5 mt-5 mb-10 border rounded-lg group"
        >
          <div class="group-title pb-5 font-bold">
            Core Platform Licences Pricing Summary
          </div>
          <table class="w-full text-center">
            <thead class="border-b border-line-regular">
              <th
                class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-left"
              >
                Module
              </th>
              <th
                class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right"
              >
                Customer Buy Price
              </th>
              <th
                class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right"
              >
                Your Buy Price
              </th>
              <th
                class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right"
              >
                Margin
              </th>
            </thead>
            <tbody>
              <tr v-for="(modules, index) in cplValues" :key="index">
                <td
                  :key="index"
                  class="text-main px-4 text-sm border-line-regular text-left"
                >
                  {{
                    modules.standardPricingMaster.name === "EWD-FT"
                      ? "EWD"
                      : modules.standardPricingMaster.name
                  }}
                </td>
                <td
                  class="text-main px-4 text-base border-line-regular text-right"
                >
                  ${{ modules.customerBuyPrice.toFixed(2) }}
                </td>
                <td
                  class="text-main px-4 text-base border-line-regular text-right"
                >
                  ${{ modules.sourceBuyPricing.toFixed(2) }}
                </td>
                <td
                  class="text-main px-4 text-base border-line-regular text-right"
                >
                  ${{ modules.margin }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="cplValues.length > 0"
          class="p-5 mt-5 mb-10 border rounded-lg group"
        >
          <div class="group-title pb-5 font-bold">Billing Summary</div>
          <FormulateInput
            type="checkbox"
            class="formulate-input"
            name="billedExternally"
            label="Billed via Invoice"
            v-model="billedExternally"
            :disabled="disableBillExternallyOption"
            label-class="formulate-input-label formulate-input-label--after"
            input-class=""
            wrapper-class="formulate-input-wrapper"
            outer-class=""
            element-class="formulate-input-element formulate-input-element--checkbox"
          />
          <div class="relative overflow-x-auto">
            <table class="w-full text-sm text-left text-gray-500">
              <thead class="text-xs text-gray-700 uppercase bg-gray-100">
                <tr>
                  <th scope="col" class="px-6 py-3 rounded-l-lg">Module</th>
                  <th scope="col" class="px-6 py-3 text-right">
                    Price per Seat
                  </th>
                  <th scope="col" class="px-6 py-3 text-center">Seat Count</th>
                  <th scope="col" class="px-6 py-3 rounded-r-lg text-right">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="bg-white"
                  v-for="(modules, index) in cplValues"
                  :key="index"
                >
                  <th
                    scope="row"
                    class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                  >
                    {{
                      modules.standardPricingMaster.name === "EWD-FT"
                        ? "EWD"
                        : modules.standardPricingMaster.name
                    }}
                  </th>
                  <td class="px-6 py-2 text-right">
                    ${{ modules.customerBuyPrice.toFixed(2) }}
                  </td>
                  <td class="px-6 py-2 text-center">
                    {{ modules.numberOfSeats }}
                  </td>
                  <td class="px-6 py-2 text-right">
                    ${{
                      modules.customerBuyPrice.toFixed(2) *
                      modules.numberOfSeats
                    }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr class="font-semibold text-gray-900">
                  <th scope="row" class="px-6 py-3 text-base">Total</th>
                  <td class="px-6 py-3 text-right"></td>
                  <td class="px-6 py-3 text-center">
                    {{ this.totals.seatCount }}
                  </td>
                  <td class="px-6 py-3 text-right">
                    ${{ this.totals.subTotal.toFixed(2) }}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>

        <!-- <div v-if="!pageInitLoading && smValues.length > 0" class="p-5 mt-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold bg-gray-50">Specialty Modules Pricing Summary</div>.

             <table class="table-auto leading-normal p-4 w-full">
              <thead>
                <th class="bg-gray-100 text-center p-3 font-semibold justify-center" width="40%">
                  Specialty Modules
                </th>
                <th class="bg-gray-200 text-center p-3 font-semibold justify-center">
                  Customer Buy Price
                </th>
                <th  class="bg-gray-200 text-center p-3 font-semibold justify-center">
                  Your Buy Price
                </th>
                <th class="bg-gray-200 text-center p-3 font-semibold justify-center">
                  Margin
                </th>
              </thead>

              <tr v-for="(modules, index) in smValues" :key="index">
               <td :key="index" class="px-3" width="40%">{{modules.standardPricingMaster.name === 'EWD-FT' ? 'EWD' : modules.standardPricingMaster.name}}</td>
                <td class="p-2">
                  <div v-if="modules.manualOffer" class="text-center">
                    ${{modules.manualOffer.toFixed(2)}}
                  </div>
                </td>
                <td class="p-2">
                  <div v-if="modules.sourceBuyPricing" class="text-center">
                   ${{modules.sourceBuyPricing.toFixed(2)}}
                  </div>
                </td>
                <td class="p-2">
                  <div class="text-center">
                    ${{modules.margin}}
                  </div>
                </td>
              </tr>
             </table>
          </div> -->

        <!-- <div v-if="!pageInitLoading" class="p-5 mt-5 mb-10 border rounded-lg group">
              <div class="group-title pb-5 font-bold bg-gray-50"> {{logmasterTerm.name}}</div>

              <div v-for="(section, indexOfLogmaster) in logmasterTerm.sections" :key="section.uniqueId">
                <h3 class="mb-1 text-base font-black">{{indexOfLogmaster+1}}. {{section.headline}}</h3><hr>
                <div class="mt-2">
                  <div v-if="section.subHeadline.length !== 0"><small class="italic text-small inline-block pt-4">{{section.subHeadline}} </small><hr></div>
                  <div v-for="(control) in section.controls" :key="control.uniqueId">
                    <div class="text-xs mb-4 mt-1 font-medium">
                      <FieldContainer :component="control" :isDisplayValue="true"/>
                    </div>
                  </div>
                </div>
              </div>
           </div> -->

        <div class="border-t border-neutral-light mt-8">
          <div class="flex items-center justify-end w-full mt-4 space-x-4">
            <FormulateInput
              type="button"
              label="Back"
              input-class="btn btn-cancel w-full"
              @click.prevent="onPrevious()"
            />
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-full"
              :disabled="submitLoading"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              <span v-if="submitLoading"
                ><font-awesome-icon icon="spinner" class="mr-1 loader" />
                {{ buttonText }}</span
              >
              <span v-if="!submitLoading">Submit</span>
            </FormulateInput>
          </div>
        </div>
      </div>
    </FormulateForm>
    <Modal
      ref="processingPaymentModal"
      :title="modalTitle"
      size="3xl"
      :height="paymentModalHeight"
    >
      <div class="flex flex-col items-center mx-4 my-4">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-12 sm:w-12"
        >
          <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <div class="text-center mt-4 w-full">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Processing your payment
          </h3>
        </div>
        <div class="text-center mt-2">
          <p class="text-sm text-gray-500">
            Please wait while we process your payment request.
          </p>
        </div>
      </div>
    </Modal>
    <Modal
      ref="paymentSuccessfulModal"
      :title="modalTitle"
      size="3xl"
      :height="paymentModalHeight"
    >
      <div class="flex flex-col items-center mx-4 my-4">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-12 sm:w-12"
        >
          <font-awesome-icon icon="check" class="h-6 w-6 text-green-800" />
        </div>
        <div class="text-center mt-4 w-full">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Payment successful
          </h3>
        </div>
        <div class="text-center mt-2">
          <p class="text-sm text-gray-500">
            We have received your payment successfully. Please wait as we
            redirect you back to the main page.
          </p>
        </div>
      </div>
    </Modal>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { Modal } from "@/components/commons";
import { mapGetters } from "vuex";

export default {
  name: "Summary",
  components: {
    Modal,
  },
  props: {
    preData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      pageInitLoading: false,
      submitLoading: false,
      values: {},
      errorMessage: "",
      cplValues: {},
      interChangingData: [],
      buyPricing: [],
      token: null,
      hasActivePaymentGateway: false,
      publishableKey: null,
      paymentGatewayId: null,
      paymentGateway: {},
      totals: {
        priceperseat: 0,
        seatCount: 0,
        subTotal: 0,
        totalGST: 0,
        gst: 0,
        gatewayFee: 0,
        amountDue: 0,
      },
      paymentModalHeight: "20vh",
      modalTitle: "",
      billedExternally: false,
      buttonText: "Saving data...",
      disableBillExternallyOption: false,
    };
  },
  async created() {
    this.pageInitLoading = true;
    const me = this.$store.getters[`account/me`];
    const paymentGateway = await this.$store.dispatch(
      "payment/getPaymentGatewayByUid",
      { uid: me?.uid, role: me?.role.name }
    );
    if (paymentGateway) {
      console.log(paymentGateway);
      this.paymentGatewayId = paymentGateway._id;
      this.publishableKey = paymentGateway.apiKey;
      this.hasActivePaymentGateway = true;
      this.paymentGateway = paymentGateway;
    } else {
      this.billedExternally = true;
      this.disableBillExternallyOption = true;
    }
    await this.init();
  },
  methods: {
    updateSubmitButton() {
      this.submitLoading = true;
      this.buttonText = "Validating Card Details...";
    },
    cardChange() {
      this.$refs.cardRef.submit();
    },
    tokenCreated(token) {
      this.token = token.id;
      this.submitLoading = false;
      this.buttonText = "Saving data...";
    },

    async init() {
      const me = await this.$store.getters[`account/me`];
      this.preData.entityId = me.entity._id;
      this.pageInitLoading = true;

      if (this.preData?.steps) {
        this.values = this.preData.steps?.pricingDetails || null;
        this.interChangingData = this.preData;
      }

      console.log(this.preData);

      let rolesParent = null;
      if (me?.role?.name === "partner") {
        rolesParent = await this.$store.dispatch(
          "partner/getPartnerByUID",
          me?.uid
        );
      } else if (me?.role?.name === "reseller") {
        rolesParent = await this.$store.dispatch(
          "reseller/getResellerByUID",
          me?.uid
        );
      }

      if (rolesParent) {
        const standardPricing = await this.$store.dispatch(
          `standardpricing/getStandardPricingByMongoId`,
          rolesParent._id
        );

        this.buyPricing = standardPricing.filter(
          (item) => item.isRrp === false
        );
      }

      if (me?.role?.name === "partner") {
        this.logmasterTerm = await this.$store.dispatch(
          "contract/getLogmasterTermByPartner",
          me?.uid
        );
      } else if (me?.role?.name === "reseller") {
        this.logmasterTerm = await this.$store.dispatch(
          "contract/getLogmasterTermByReseller",
          me?.uid
        );
      }
      if (this.logmasterTerm) {
        console.log(this.preData.cplPricing);

        this.cplValues = this.preData.cplPricing.map((cpl) => {
          let rrpComputedPrice = 0;

          if (this.preData.contractDuration.label === "Monthly") {
            rrpComputedPrice = Number(cpl.pricing.monthly);
            this.buyPricing.forEach((item) => {
              if (item.name == cpl.name) {
                cpl.sourceBuyPricing = Number(item.pricing.monthly);
              }
            });
          } else if (this.preData.contractDuration.label === "Yearly") {
            if (this.preData.billingPeriod.label === "Yearly") {
              rrpComputedPrice = Number(cpl.pricing.yearly);
              this.buyPricing.forEach((item) => {
                if (item.name == cpl.name) {
                  cpl.sourceBuyPricing = Number(item.pricing.yearly);
                }
              });
            } else if (this.preData.billingPeriod.label === "Monthly") {
              rrpComputedPrice = Number(cpl.pricing.yearly) / 12;
              this.buyPricing.forEach((item) => {
                if (item.name == cpl.name) {
                  cpl.sourceBuyPricing = Number(item.pricing.yearly) / 12;
                }
              });
            }
          }
          return {
            ...cpl,
            rrpPrice: rrpComputedPrice.toFixed(2),
            margin: (rrpComputedPrice - cpl.sourceBuyPricing).toFixed(2),
          };
        });

        let priceperseat = 0;
        let seatCount = 0;
        let total = 0;

        this.cplValues.forEach((object) => {
          priceperseat += object.customerBuyPrice;
          seatCount += object.numberOfSeats;
          total += object.customerBuyPrice * object.numberOfSeats;
        });

        console.log(this.cplValues);

        let subTotal = total;

        const gst = (subTotal / 100) * this.paymentGateway.gstPercentageFee;

        const totalGST = subTotal + gst;
        const gatewayFee =
          (totalGST / 100) * this.paymentGateway.surchargePercentageFee +
          this.paymentGateway.otherFees;

        const amountDue = totalGST + gatewayFee;

        this.totals = {
          priceperseat: priceperseat,
          seatCount: seatCount,
          subTotal: subTotal,
          totalGST: totalGST,
          gst: gst,
          gatewayFee: gatewayFee,
          amountDue: amountDue,
        };
      }
      this.pageInitLoading = false;
    },
    async onSubmit() {
      this.submitLoading = true;
      this.buttonText = "Saving data...";
      this.incompleteForm = true;
      const mergedModulesvalues = [...this.cplValues];
      const formatPayloadModules = mergedModulesvalues.map((modules) => ({
        minimums: modules.numberOfSeats,
        price: {
          monthly:
            this.preData.billingPeriod.label === "Monthly"
              ? Number(modules.rrpPrice)
              : 0,
          yearly:
            this.preData.billingPeriod.label === "Yearly"
              ? Number(modules.rrpPrice)
              : 0,
        },
        masterId: modules.masterId,
      }));

      const createLeadPayload = {
        leadId: this.preData.leadId,
        demoOption: this.preData.demoOption,
        businessModules: formatPayloadModules,
      };
      createLeadPayload.contractDetails = {
        billingPeriodId: this.preData.billingPeriod.value,
        businessModulesDto: formatPayloadModules,
        contractDurationId: this.preData.contractDuration.value,
        contractDurationYears: Number(this.preData.yearCount) || 0,
        activationOption:
          this.billedExternally === true ? "invoice" : "recurring_payment",
        contractTarget:
          this.preData.steps.moduleSelectionDetails.contractTarget,
      };

      if (this.billedExternally !== true) {
        // createLeadPayload.paymentDetails = {
        //   entityRole: 'business',
        //   paymentGatewayId: this.paymentGatewayId,
        //   cardToken: this.token,
        //   isDefault: true,
        //   isActive: true
        // }
        // createLeadPayload.chargeDetails = {
        //   entityRole: 'business',
        //   amount: this.totals.subTotal
        // }
      }

      createLeadPayload.billedExternally = this.billedExternally;
      this.$store
        .dispatch(`business/convertLead`, createLeadPayload)
        .then((business) => {
          if (business && business._id) {
            this.submitLoading = false;
            this.$emit("closeModal");
            this.$emit("submitSuccess");
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Business successfully created.",
            });
          }
          location.reload();
        })
        .catch((error) => {
          this.submitLoading = false;
          this.errorMessage = error?.message;
        });
    },
    onPrevious() {
      const postData = this.interChangingData;
      this.$emit("backStep", postData);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 2s linear infinite;
}
</style>
