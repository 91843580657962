<template>
    <div class="absolute right-0 w-80 mt-0 origin-top-right" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items-117" role="menu">
        <div class="absolute right-0 bg-white rounded-md shadow-lg overflow-hidden w-80">
            <div v-if="notifications.length > 0" class="py-0">
              <Item v-for="(item, index) in notifications.slice(0, 5)" 
                :key="index"
                :title="item.title" 
                :description="item.description"
                :link="`/account/notification/${item._id}`"
                :createdAt="item.createdAt"
              ></Item>
            </div>
            <div v-if="notifications.length == 0" class="p-7">
                <p>No notification found.</p>
            </div>
            <div v-if="notifications.length > 0">
                <a href="/account/notifications" class="block text-white text-center font-bold py-2" :style="{backgroundColor: theme.secondaryColor}">See all notifications</a>
            </div>
        </div>
    </div>
</template>
<script>
import Item from './NotificationItem-min'
import _ from 'lodash';
import { mapGetters } from 'vuex'

export default {
  name: 'Notification',
  components:{ Item },
  props: {},
  data(){
    return {
       notifications: [],
       me: null
    }
  },

  async created(){
    this.me = await this.$store.getters['account/me'];
  },

  async mounted() {
    let notifications = await this.$store.dispatch(`notification/getAllNotifications`);
    
    const proxySite = this.$store.getters[`account/proxySite`];
    if (!_.isEmpty(proxySite) && _.isObject(proxySite)) {
      // Filter only notications that belongs to the switched site
      notifications = notifications.filter(notification => notification?.siteId && notification?.siteId == proxySite._id);
    }
    
    this.notifications = this.parseNotifications(notifications)
    if(this.notifications == undefined) {
      this.notifications = []
    }
  },
  methods:{
     parseNotifications(data){
      if (!data) return;
      const targetedNotifs = data.map((notifications)=> {
        if(notifications.targetedWebProfile === this.me.uid) {
          return notifications
        }
        //PREVIOUS NOTIFICAIONS PRIOR TO THIS FEATURE
        else if( !notifications?.targetedWebProfile  ) {
          return notifications
        }
      }).filter((targets)=> targets!== undefined)

      return _.orderBy(targetedNotifs, ['createdAt'],['desc'])
    }
  },
   computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
}
</script>