<template>
  <div>
    <FormulateForm
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
      @onNameChange="onNameChange($event)"
    >
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
        >
          <span v-if="loading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
            data...</span
          >
          <span v-if="!loading">Submit</span>
        </FormulateInput>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import marketplaceItemSchema from "./new-marketplace-item";

export default {
  name: "Add-Marketplace-Item",
  props: {},
  data() {
    return {
      loading: false,
      values: { apiTag: null, skuPrefix: null },
      errorMessage: "",
      hasErrorMessage: false,
      schema: marketplaceItemSchema,
    };
  },

  async mounted() {
    this.loading = true;
    this.loading = false;
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    async onSubmit() {
      let formValues = JSON.parse(JSON.stringify(this.values));
      const response = confirm(
        `Important: Creating a new module will add standard pricing to all ${formValues.type}(s). Do you wish to continue?`
      );
      if (!response) return;
      this.loading = true;
      const payload = {
        name: formValues["name"],
        apiTag: formValues["apiTag"],
        skuPrefix: formValues["skuPrefix"],
        category: formValues["category"],
        type: formValues["type"],
        description: formValues["description"],
        pricing: {
          monthly: +formValues["monthly"],
          yearly: +formValues["yearly"],
        },
        rrp: {
          monthly: +formValues["monthlyRrp"],
          yearly: +formValues["yearlyRrp"],
        },
      };
      const contractModuleMasterPayload = {
        name: payload.name,
        apiTag: payload.apiTag,
        skuPrefix: payload. skuPrefix,
        minimums: 0,
        price: {
          monthly: 0,
          yearly: 0,
        },
      };
      try {
        const res = await this.$store.dispatch(
          `standardpricing/createStandardPricingMaster`,
          payload
        );
        await this.$store.dispatch(
          "standardpricing/createContractModuleMaster",
          contractModuleMasterPayload
        );
        if (res.success) {
          if (payload.type == "partner")
            await this.$store.dispatch(
              "partner/addModuleToPartners",
              res.data._id
            );
          if (payload.type == "reseller")
            await this.$store.dispatch(
              "reseller/addModuleToResellers",
              res.data._id
            );

          this.loading = false;
          this.closeModal();
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: res.message,
          });
        }
      } catch (err) {
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "error",
          message: err.message,
        });
        this.loading = false;
      }
      this.loading = false;
    },

    onNameChange(e) {
      // handles api tag generation
      const latestItemName = e.target.value.trim();
      let itemNameSplitIntoWords = latestItemName.split(" ");
      itemNameSplitIntoWords = itemNameSplitIntoWords.map((word) =>
        word.toLowerCase()
      );
      this.values.apiTag = itemNameSplitIntoWords.join("-");

      // handles sku prefix generation
      const itemNameFirstThreeLetters = latestItemName.slice(0,3).toUpperCase();
      this.values.skuPrefix = itemNameFirstThreeLetters.trim();
    },
  },
};
</script>
