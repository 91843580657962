<template>
  <div>
    <div class="w-full text-black px-3" :class="textClass" v-html="htmlContent">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    control: {
      type: Object,
      default: () => {}
    },
    isDisplayValue: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    textClass() {
      const textSize = `text-${this.control.size}`
      const fontWeight = `font-${this.control.weight}`
      const textAlignment = `text-${this.control.alignment}`
      return `${textSize} ${fontWeight} ${textAlignment}`
    },
    htmlContent() {
      const label = this.control.label ? this.control.label : ''

      return `${label} <br> ${this.control.content}`
    }
  }
}
</script>
