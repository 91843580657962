<template>
  <div class="w-full min-h-full items-center">
    <div class="font-bold text-lg mb-3 focus-within: text-green-500">
      Interview
    </div>
    <hr>

    <section class="my-6">
      <div class="mb-5">
        <div class="text-xs">Attendees:</div>
        <ul v-for="(attendees, index) in activeDocView.attendees" :key="index" class="font-bold text-black-primary text-base">
          <li>{{attendees.name}}&nbsp;</li>
        </ul>
      </div>

      <div class="mb-5">
        <div class="text-xs">Interviewers:</div>
        <ul v-for="(interviewer, index) in activeDocView.interviewers" :key="index" class="font-bold text-black-primary text-base">
          <li>{{interviewer.name}}&nbsp;</li>
        </ul>
      </div>

      <div class="mb-5">
        <div class="text-xs">Date:</div>
        <div class="font-bold text-black-primary text-base">
          {{cleanDateFormat(activeDocView.interviewDate)}}&nbsp; 
        </div>
      </div>

      <div class="mb-5">
        <div class="text-xs">Details:</div>
        <div class="text-black-primary text-base h-auto">
          {{activeDocView.interviewDetails}}&nbsp; 
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { cleanDateFormat } from '@/_helper'

export default {
  name: 'Interview-View',
  props: {
    activeDocView: null,
  },
  methods: {
    cleanDateFormat(date){
      return cleanDateFormat(date)
    }
  }
}
</script>