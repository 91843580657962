<template>
    <div>
      <div class="flex w-full" v-if="loading">
        <div class="m-auto">
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300">Loading...</span>
        </div>
      </div>
       <div v-if="passwordCreated">
        <h1 class="text-2xl text-green-600 text-center font-bold">Welcome to Logmaster Australia, your account has been created.</h1>
        <div class="text-sm text-center">Redirecting...</div>
      </div>
      <div v-if="!passwordCreated">
      <FormulateForm @submit="onSubmit" v-if="!loading">
         <div class="mb-5 font-italic text-sm">*Please set your preferred reseller account password here:</div>
            <FormulateInput
               label="Password"
               type="password"
               name="password"
               validation="required|min:6,length|matches:/[0-9]/"
               :validation-messages="{ matches: 'Passwords must include a number.' }"
            />
            <FormulateInput
               label="Confirm password"
               type="password"
               name="password_confirm"
               validation="required|confirm"
               validation-name="Password confirmation"
            />
         <div class="flex items-center justify-between w-full">
            <FormulateInput
               type="submit"
               label="Submit"
               input-class="btn btn-primary w-full"
               class="w-full"
            />
         </div>
         <div class="flex items-center justify-between w-full">
            <button class="btn btn-cancel disabled w-full mb-2" @click.prevent="cancelInTerms()">
               Cancel
            </button>
         </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
      </div>
    </div>
</template>
<script>
import Spinner from '@/components/commons/ui/Spinner'
  export default{
      name: 'Reseller-Accept-Form',
      components: { Spinner },
      data() {
        return {
          resellerPasswordForm: {},
          loading: true,
          errorMessage: '',
          isValidReseller: false,
          passwordCreated: false,
          resellerId: null
        }
      },
      async mounted(){
        if(!this.resellerId){
          this.resellerId = this.$route.params['resellerId'];
          this.resellerId = this.resellerId ? this.resellerId:this.$route.params['resellerId'];
        }
        if(this.resellerId){
            await this.$store.dispatch('reseller/getReseller', {id: this.resellerId})
            .then(reseller => {
              if(reseller){
                if(reseller.uid) window.location.href = '/login?message=Request is already accepted&state=error'
                this.loading = false
                this.isValidReseller = true
              }else{
                this.loading = false
                this.errorMessage = 'No resellers account found!'
                window.location.href = '/login?message=Reseller not found&state=error'
              }
            }).catch(error => {
                this.loading = false
                this.errorMessage = error?.message
                window.location.href = '/login?message=Invalid request&state=error'
            })
        }
      },
      methods: {
        async onSubmit(data){
          this.loading = true;
          const resellerPasswordData = JSON.parse(JSON.stringify(this.resellerPasswordForm))
          console.log(resellerPasswordData);
          if(resellerPasswordData && this.isValidReseller){
              let payload = {
                id: this.resellerId, 
                password: data.password,
                confirmPassword: data.password_confirm
              }
              await this.$store.dispatch('reseller/createResellerPassword', payload).then(reseller => {
                if(reseller){
                    try{
                    const acceptReseller = this.$store.dispatch('reseller/acceptReseller', this.resellerId)
                        this.loading = false
                        if(acceptReseller){
                          this.passwordCreated = true
                          setTimeout(() => {
                            window.location.href = '/logout'
                          }, 3003);

                        }else{
                          this.errorMessage = 'Reseller has already accepted the invitation'
                          window.location.href = '/login?message=Request already accepted&state=error'
                        }
                      }
                      catch(error){
                          this.loading = false
                          this.errorMessage = 'Something went wrong'
                          window.location.href = '/login?message=Something went wrong&state=error'
                      }
                }
              }).catch(error => {
                  this.loading = false
                  this.errorMessage = error?.message
                //   window.location.href = '/'
            })
          }
        },
        cancelInTerms(){
          window.location.href = '/'
        }
      }
  }
</script>