import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faGooglePlay,
  faAppStoreIos,
  faCcMastercard,
  faCcVisa,
} from "@fortawesome/free-brands-svg-icons";
import {
  faBell,
  faChevronDown,
  faCog,
  faUser,
  faSignOutAlt,
  faSignInAlt,
  faInfoCircle,
  faCircle,
  faEye,
  faEdit,
  faLevelDownAlt,
  faLevelUpAlt,
  faTimes,
  faHandshake,
  faTrash,
  faBuilding,
  faBriefcase,
  faSearch,
  faCheck,
  faCaretDown,
  faEnvelope,
  faDownload,
  faCircleNotch,
  faChevronLeft,
  faChevronRight,
  faCaretRight,
  faTable,
  faList,
  faFile,
  faFileAlt,
  faClipboard,
  faTools,
  faSpinner,
  faSlidersH,
  faHistory,
  faCalendarTimes,
  faUnlink,
  faMapMarkedAlt,
  faTrashAlt,
  faTruck,
  faUsers,
  faPaperPlane,
  faUpload,
  faPlus,
  faPlusCircle,
  faDollarSign,
  faExternalLinkAlt,
  faChartArea,
  faTh,
  faExclamationCircle,
  faCreditCard,
  faFileImage,
  faCertificate,
  faCheckCircle,
  faTimesCircle,
  faExclamationTriangle,
  faExchangeAlt,
  faBan,
  faArrowRight,
  faUserPlus,
  faChartBar,
  faSearchPlus,
  faFileExcel,
  faLink,
  faGlobe,
  faLock,
  faBalanceScale,
  faRecycle,
  faPlay,
  faCashRegister,
  faBorderAll,
  faCoins,
  faFilter,
  faBars,
  faEraser,
  faDatabase
} from "@fortawesome/free-solid-svg-icons";
library.add(faGooglePlay, faAppStoreIos);
library.add(
  faBell,
  faChevronDown,
  faCog,
  faUser,
  faSignOutAlt,
  faSignInAlt,
  faInfoCircle,
  faCircle,
  faEye,
  faEdit,
  faLevelDownAlt,
  faLevelUpAlt,
  faTimes,
  faTimesCircle,
  faHandshake,
  faTrash,
  faBuilding,
  faBriefcase,
  faSearch,
  faCheck,
  faCheckCircle,
  faCaretDown,
  faEnvelope,
  faDownload,
  faCircleNotch,
  faChevronLeft,
  faChevronRight,
  faCaretRight,
  faTable,
  faList,
  faFile,
  faFileAlt,
  faClipboard,
  faTools,
  faSpinner,
  faSlidersH,
  faHistory,
  faCalendarTimes,
  faUnlink,
  faMapMarkedAlt,
  faTrashAlt,
  faTruck,
  faUsers,
  faPaperPlane,
  faUpload,
  faPlus,
  faPlusCircle,
  faDollarSign,
  faExclamationCircle,
  faExternalLinkAlt,
  faChartArea,
  faTh,
  faCreditCard,
  faFileImage,
  faCertificate,
  faExclamationTriangle,
  faTimesCircle,
  faExchangeAlt,
  faBan,
  faCcMastercard,
  faCcVisa,
  faArrowRight,
  faUserPlus,
  faChartBar,
  faSearchPlus,
  faFileExcel,
  faLink,
  faGlobe,
  faLock,
  faBalanceScale,
  faRecycle,
  faPlay,
  faCashRegister,
  faBorderAll,
  faCoins,
  faFilter,
  faBars,
  faEraser,
  faDatabase
);
