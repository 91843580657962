export class User {
    id = '';
    email = '';
    name = '';
    role = '';
    phoneNumber = '';
    constructor(_id, _email, _name, _role, _phoneNumber){
        this.id = _id;
        this.email = _email;
        this.name = _name;
        this.role = _role;
        this.phoneNumber = _phoneNumber;
    }
}