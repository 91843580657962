import { Driver, DriverManagement, Event } from "@/api";
import { findRootUID } from '@/_helper';
import _ from 'lodash'

export default{
    // @Solo Driver
    async createDriver({ commit, dispatch }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Driver.createDriver(data)
            commit("setNewCreatedDriver", response.data.data);
            if(!_.isEmpty(data['create_password'])){ // Create driver with Password
                const newPassword = await dispatch('createDriverPassword',{ id:response.data.data._id, password: data['create_password']})
                return newPassword;
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },
    // @Solo Driver
    async createContractorDriver({ commit, dispatch }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Driver.createContractorDriver(data)
            commit("setNewCreatedDriver", response.data.data);
            if(!_.isEmpty(data['create_password'])){ // Create driver with Password
                const newPassword = await dispatch('createDriverPassword',{ id:response.data.data._id, password: data['create_password']})
                return newPassword;
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },

    async setToEWDDriver({ state }, payload){
        if (!payload.driverId) throw "Invalid businessId value" + state.me;
        try{
            const driverId = payload.driverId
            delete payload.driverId;
            const response = await Driver.setToEWDDriver(driverId, payload)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    
    async createSoloWithContract({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Driver.createSoloWithContract(data)
            commit("setNewCreatedDriver", response.data.data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    
    async createInviteSoloDriverContractor({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Driver.createInviteSoloDriverContractor(data)
            commit("setNewCreatedDriver", response.data.data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async createNonEWDDriver({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Driver.createNonEWDDriver(data)
            commit("setNewCreatedDriver", response.data.data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async updateNonEWDDriver({ state }, payload){
        if (!payload.driverId) throw "Invalid businessId value" + state.me;
        try{
            const response = await Driver.updateNonEWDDriver(payload.driverId, payload)
            return response.data;
        }catch(error){
            throw error.response.data
        }
    },

    async moveSoloParentEntity({ state }, payload){
        if (!payload.entityId) throw "Invalid driverId value" + state.me;
        try{
            const response = await Driver.moveSoloParentEntity(payload)
            return response.data;
        }catch(error){
            throw error.response.data
        }
    },

    async createDriverFromURL({ commit, dispatch }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Driver.createDriverFromURL(data)
            commit("setNewCreatedDriver", response.data.data);
            if(!_.isEmpty(data['create_password'])){ // Create driver with Password
                const newPassword = await dispatch('createDriverPassword',{ id:response.data.data._id, password: data['create_password']})
                return newPassword;
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },

    async createDriverPassword({ commit, state }, data){
        if(!data.id) throw 'Invalid driver id';
        if(!data.password) throw 'Invalid driver data password';
        try{
            const response = await Driver.patchDriverCreatePassword(data.id,data.password);
            if(!_.isEmpty(state.newCreatedDriver)) commit("setNewCreatedDriver", {...state.newCreatedDriver,password:response.data.data});
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getDriver( { state }, param ){
        if(typeof param === 'string') param = { uid:param, query: { skip: 0, limit: 999 } }
        try{
            if(param.id){
                const response = await Driver.getDriver(param.id);
                if(!response?.data?.data) return [];
                response.data.data.uid = findRootUID(response.data.data);
                return response.data.data;
            }else{
                const response = await Driver.getDriver(param.id, param.query);
                
                if(!response?.data?.data) return [];

                if(response.data?.resultsMeta){
                    const data = response?.data?.data.map((data)  => { 
                        data.uid = findRootUID(data);
                        return data
                    })
                    return {
                        results: data,
                        metadata: response.data.resultsMeta
                    }
                } else {
                    return response?.data?.data.map((data)  => { 
                        data.uid = findRootUID(data);
                        return data
                    })
                }
            }
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getBusinessPendingContractors( { state }, param ){
        if(typeof param === 'string') param = { uid:param, query: { skip: 0, limit: 999 } }
        try{
            const response = await Driver.getBusinessPendingContractors(param.businessId ,param.query);
            
            if(!response?.data?.data) return [];

            if(response.data?.resultsMeta){
                const data = response?.data?.data.map((data)  => { 
                    data.uid = findRootUID(data);
                    return data
                })
                return {
                    results: data,
                    metadata: response.data.resultsMeta
                }
            } else {
                return response?.data?.data.map((data)  => { 
                    data.uid = findRootUID(data);
                    return data
                })
            }
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getAllSoloWithNoContract({ state }, param) {
        if(typeof param === 'string') param = { uid:param, query: { skip: 0, limit: 999 } }
        console.log(param)
        try{
            const response = await Driver.getAllSoloWithNoContract(param.data, param.query);
            
            if(!response?.data?.data) return [];

            if(response.data?.resultsMeta){
                const data = response?.data?.data.map((data)  => { 
                    data.uid = findRootUID(data);
                    return data
                })
                return {
                    results: data,
                    metadata: response.data.resultsMeta
                }
            } else {
                return response?.data?.data.map((data)  => { 
                    data.uid = findRootUID(data);
                    return data
                })
            }
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getDriverByUID( { state }, uid ){
        if(!uid) throw 'Invalid uid '+state.me;
        try{
            const response = await Driver.getDriverByUID(uid);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getDriverByID( { state }, id ){
        if(!id) throw 'Invalid uid '+state.me;
        try{
            const response = await Driver.getDriverByID(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getContractorDriverByID( { state }, id ){
        if(!id) throw 'Invalid uid '+state.me;
        try{
            const response = await Driver.getContractorDriverByID(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getContractorDriverByEmail({ state }, email) {
        if(!email) throw "Invalid email" + state.me;
        try {
            const response = await Driver.getContractorDriverByEmail(email);
            return response.data.data
        } catch(error) {
            throw error.response.data
        }
    },

    async getDriverByEmail( { state }, email ){
        if(!email) throw 'Invalid emailaddress '+state.me;
        try{
            const response = await Driver.getDriverByEmail(email);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async updateContractorStatus( { state }, data){
        if(!data.id) throw 'Invalid id '+state.me;
        try{
            const response = await Driver.updateContractorStatus(data);
            return response.data.data
        }catch(error){
            throw error.response.data+state.me
        }
    },
    async acceptDriver( { state }, id){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Driver.acceptDriver(id);
            return response.data.data
        }catch(error){
            throw error.response.data+state.me
        }
    },
    async abortDriver( { state }, id){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Driver.abortDriver(id);
            return response.data.data
        }catch(error){
            throw error.response.data+state.me
        }
    },
    //@Driver Management
    async getContract( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await DriverManagement.getContract(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async acceptContract( { state }, contract ){
        if(!contract) throw 'Invalid contract '+state.me;
        try{
            const response = await DriverManagement.acceptContract('driver',contract);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async declineContract( { state }, contractId ){
        if(!contractId) throw 'Invalid contractId '+state.me;
        try{
            const response = await DriverManagement.declineContract('driver',contractId);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getContractByBusiness( { rootGetters } , param ){
        if(typeof param === 'string') param = { uid:param, query: { skip: 0, limit: 999 } }
        if(!param.uid) throw 'Invalid UID or ID';
        try{
            if (typeof param.query?.filter != 'object') param.query.filter = {}

            if (!('siteId' in param.query.filter)) {
                const proxySite = rootGetters[`account/proxySite`];
                if (!_.isEmpty(proxySite) && _.isObject(proxySite)) param.query.filter.siteId = proxySite._id;
            }

            const response = await DriverManagement.getContractByBusinessId(param.uid, param.query);
            if(response.data?.resultsMeta){
                return { results : response.data.data, metadata:response.data.resultsMeta }
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },
    async getContractByDriver( _ , param ){
        if(typeof param === 'string') param = { uid:param, query: { skip: 0, limit: 999 } }
        if(!param.uid) throw 'Invalid UID or ID';
        try{
            const response = await DriverManagement.getContractByDriverId(param.uid, param.query);
            if(response.data?.resultsMeta){
                return { results : response.data.data, metadata:response.data.resultsMeta }
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },
    async getExternalContractByDriver( _ , param ){
        if(typeof param === 'string') param = { uid:param, query: { skip: 0, limit: 999 } }
        if(!param.uid) throw 'Invalid UID or ID';
        try{
            const response = await DriverManagement.getExternalContractByDriver(param.uid, param.query);
            if(response.data?.resultsMeta){
                return { results : response.data.data, metadata:response.data.resultsMeta }
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },
    async getFitToDriveById( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await DriverManagement.getFitToDriveById(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getFitToDriveByBusinessId( { rootGetters } , param ){
        if(typeof param === 'string') param = { businessId:param, query: { skip: 0, limit: 999 } }
        if(!param.businessId) throw 'Invalid UID or ID';
        try{
            if (typeof param.query?.filter != 'object') param.query.filter = {}

            const proxySite = rootGetters[`account/proxySite`];
            if (!_.isEmpty(proxySite) && _.isObject(proxySite)) param.query.filter = { ...param.query.filter, siteId: proxySite._id };

            const response = await DriverManagement.getFitToDriveByBusinessId(param.businessId, param.query);
            if(response.data?.resultsMeta){
                return { results : response.data.data, metadata:response.data.resultsMeta }
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },
    async getFitToDriveByDateRange({ state }, payload){
        try{
            const response = await DriverManagement.getFitToDriveByDateRange(payload);
            if(response.data?.resultsMeta){
                return { results : response.data.data, metadata:response.data.resultsMeta }
            }else{
                return response.data.data
            }
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getCurrentSeatCountForBusiness({ state }, payload) {
        if(!payload.businessId) throw "Invalid businessId value" + state.me;
        try{
            const response = await DriverManagement.getCurrentSeatCountForBusiness(payload);
            return response.data;
        } catch(error) {
            throw error.response.data;
        }
    },
    async updateDriverProfile({ state }, payload){
        if (!payload._id) throw "Invalid businessId value" + state.me;
        try{
            const response = await Driver.patchDriver(payload._id, payload)
            return response.data;
        }catch(error){
            throw error.response.data
        }
    },
    async getDriverLicenseHistory( { state }, param ){
        try{
            const response = await Driver.getDriverLicenseHistory(param.id);
            if(!response?.data?.data) return [];
            return response.data.data;
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },


    async getManualEventByDriverId( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Event.getManualEventByDriverId(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },


    async updateSoloParentConnection( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Driver.updateSoloParentConnection(data.driverId, data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async resendSoloInvite({ state }, driverId) {
        if(!driverId) throw 'Invalid id '+state.me;
        try{
            const response = await Driver.resendSoloInvite(driverId);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async resendContractorInvite({ state }, contractorId) {
        if(!contractorId) throw 'Invalid id '+state.me;
        try{
            const response = await Driver.resendContractorInvite(contractorId);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async rescindContractorInvite({ state }, contractorId) {
        if(!contractorId) throw 'Invalid id '+state.me;
        try{
            const response = await Driver.rescindContractorInvite(contractorId);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async checkDriverTraining({state}, driverId){
        if(!driverId) throw 'Invalid DriverId' + state.me;
        try{
            const response = await Driver.checkDriverTraining(driverId)
            return response.data.data
        }catch(error){
            console.log('error:', error)
            throw error
        }
    },

    async getTrainingStatus({state}, driverId){
        if(!driverId) throw 'Invalid DriverId' + state.me;
        try{
            const response = await Driver.getTrainingStatus(driverId)
            return response.data.data
        }catch(error){
            console.log('error:', error)
            throw error
        }
    },


    async getAllTrainingStatus({state}, driverId){
        if(!driverId) throw 'Invalid DriverId' + state.me;
        try{
            const response = await Driver.getAllTrainingStatus(driverId)
            return response.data.data
        }catch(error){
            console.log('error:', error)
            throw error
        }
    },


    async getTraining({state}, id){
        if(!id) throw 'Invalid Payload' + state.me;
        try{
            const response = await Driver.getTraining(id)
            return response.data.data
        }catch(error){
            console.log('error:', error)
            throw error
        }
    },


    async declare({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await Driver.declareTraining(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getActiveDriversStatistic({ state }, query) {
        try {
        const businessId = query.businessId;
        delete query.businessId
          const response = await Driver.getActiveDriversStatistic(query, businessId);
    
          return response.data;
        } catch (error) {
          console.log(state);
          throw error.response.data;
        }
      },

      async getRestDriversStatistic({ state }, query) {
        try {
        const businessId = query.businessId;
        delete query.businessId
          const response = await Driver.getRestDriversStatistic(query, businessId);
    
          return response.data;
        } catch (error) {
          console.log(state);
          throw error.response.data;
        }
      },

      async getOneDriverStatistic({ state }, driverId) {
        try {
          const response = await Driver.getOneDriverStatistic(driverId);
    
          return response.data.data;
        } catch (error) {
          console.log(state);
          throw error.response.data;
        }
      },
}