<template>
  <div class="flex min-h-screen justify-center items-center bg-black">
    <div class="container py-10 flex h-full flex-col xl:w-1/2 lg:w-2/3 w-full px-4">
      <div class="text-center m-5">
        <a :href="$store.getters['baseURL']"
          ><img src="/assets/images/logo.png" alt="" class="w-64 m-auto"
        /></a>
      </div>
      <div v-if="!isDriverAdded" class="">
        <div
          v-if="!loading"
          class="rounded-lg bg-gray-50 p-8 shadow"
        >
          <div class="text-center mb-5">
            <h3
              class="text-lg leading-6 font-black text-black uppercase"
              id="modal-title"
            >
              {{ entity.persona.tradingName }} New Solo Driver
            </h3>
          </div>
          <div
            v-if="windowWidth > mobileViewMaxWidth"
            class="text-center grid grid-cols-3 gap-2"
          >
            <div>
              <div class="text-xs font-bold">NZBN</div>
              <div class="text-xs mb-5">{{ entity.persona.abn }}</div>
            </div>
            <div>
              <div class="text-xs font-bold">Contact Number</div>
              <div class="text-xs mb-5">
                {{ entity.persona.contactPhoneNumber }}
              </div>
            </div>
            <div>
              <div class="text-xs font-bold">Support Email</div>
              <div class="text-xs mb-5">{{ entity.supportEmail }}</div>
            </div>
          </div>
          <div v-else class="text-center">
            <div class="text-xs font-bold">NZBN</div>
            <div class="text-xs mb-5">{{ entity.persona.abn }}</div>
            <div class="text-xs font-bold">Contact Number</div>
            <div class="text-xs mb-5">
              {{ entity.persona.contactPhoneNumber }}
            </div>
            <div class="text-xs font-bold">Support Email</div>
            <div class="text-xs mb-5">{{ entity.supportEmail }}</div>
          </div>

          <NewSoloDriverForm
            :windowWidth="windowWidth"
            :mobileViewMaxWidth="mobileViewMaxWidth"
            :entity="entity"
            :full-url="fullURL"
            @newDriverAdded="newDriverAdded"
          />
        </div>
        <div
          v-else
          class="rounded-lg bg-gray-50 p-10 shadow flex flex-col items-center h-full mx-auto"
        >
          <ui-spinner class="mx-auto"></ui-spinner>
          Loading data...
        </div>
      </div>
      <div v-else>
        <div v-if="!loading" class="rounded-lg bg-gray-50 p-10 shadow">
          <div class="text-center mb-5">
            <h1 class="text-md text-center font-bold">
              An email has been sent to {{ driver?.emailAddress }}.<br />
              Continue with your email to create a password and proceed with
              your registration.
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NewSoloDriverForm } from "@/components/forms/driver";
export default {
  name: "Solo-Driver-Creation",
  components: {
    NewSoloDriverForm,
  },
  data() {
    return {
      driverId: null,
      acceptForm: false,
      entity: {},
      fullURL: "",
      loading: true,
      driver: {},
      isDriverAdded: false,
      windowWidth: window.innerWidth,
      mobileViewMaxWidth: 620,
    };
  },
  async mounted() {
    this.loading = true;
    this.init();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async init() {
      const fullURL = window.location.origin + window.location.pathname;
      const params = this.$route.params;

      const entityType = params.entity;
      const urlType = params.urlType;

      if (entityType == "partner") {
        await this.$store
          .dispatch(`partner/getPartnerByURL`, {
            entityUrl: fullURL,
            urlType: urlType,
          })
          .then((response) => {
            if (response) {
              this.entity = response;
              this.fullURL = fullURL;
              this.loading = false;
            }
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
              this.errorMessage = error?.message;
              window.location.href = "/login?message=Invalid url request&state=error";
            }
          });
      } else if (entityType == "reseller") {
        await this.$store
          .dispatch(`reseller/getResellerByUrl`, {
            entityUrl: fullURL,
            urlType: urlType,
          })
          .then((response) => {
            if (response) {
              this.entity = response;
              this.fullURL = fullURL;
              this.loading = false;
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
              this.loading = false;
              this.errorMessage = error?.message;
              window.location.href = "/login?message=Invalid url request&state=error";
            }
          });
      }
    },
    agreeInTerms() {
      this.acceptForm = true;
    },
    cancelInTerms() {
      window.location.href = "/";
    },

    newDriverAdded(driver) {
      this.isDriverAdded = true;
      this.driver = driver;
    },
  },
};
</script>
<style>

.item {
  width: auto;
  height: 80px;
  margin: 5px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
}

</style>
