<template>
  <div>
    <div>
      <div
        class="mb-3 pb-4 flex justify-between items-center w-full border-b border-gray-300"
      >
        <div>
          <button
            class="btn btn-primary flex items-center"
            @click.prevent="onGetReport"
            style="height: 38px"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Get Report
          </button>
        </div>
      </div>
      <Table
        :tableHeader="tableHeader"
        :data="reportData"
        :hasActions="false"
        :isLoading="isLoading"
        :paginationSettings="paginationSettings"
        @onPageChange="onPageChange($event)"
      ></Table>
    </div>
    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content == 'new'">
        <div class="mb-16">
          <div v-if="!sendReportloading">
            <FormulateForm v-model="sendReportModel" @submit="onSubmit">
              <div class="items-center justify-between">
                <FormulateInput
                  type="email"
                  name="recipientEmail"
                  label="Email address"
                  validation="required|email"
                  @input="handleEmailCasing($event)"
                />
                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="btn btn-primary w-full mt-5"
                  :disabled="
                    !sendReportModel.recipientEmail || sendReportloading
                  "
                />
              </div>
            </FormulateForm>
          </div>

          <div
            v-else
            class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center"
          >
            <font-awesome-icon
              icon="cog"
              spin
              size="3x"
              class="text-gray-400"
            />
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { Table, Modal } from "@/components/commons";
import { REPORT_NAMES } from "../../../_helper/constants";
import { cleanDateFormat } from "../../../_helper/index";
import { formatPaginationSettings } from "@/_helper";
export default {
  name: "Nhvr-Report",
  components: { Table, Modal },
  data() {
    return {
      selectedDateRange: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      disabledBefore: null,
      tableHeader: [
        "Driver Name",
        "Driver Email",
        "UDI",
        "Created Date",
        "Last Login Date",
        "End Date",
        "Juristiction",
        "Status",
      ],
      reportData: [],
      isLoading: false,
      paginationSettings: {
        page: 1,
        totalPages: 1,
        totalRecords: 0,
        visiblePageItemCount: 10,
      },
      sendReportModel: { recipientEmail: null },
      sendReportloading: false,
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
      pageLimit: 100,
    };
  },
  async mounted() {
    this.onSearch();
  },
  methods: {
    async onSearch() {
      this.isLoading = true;

      const totalRecords = await this.$store.dispatch(
        "report/getNhvrReportData"
      );
      this.totalRecordLength = totalRecords.length;
      this.totalRecords = totalRecords;
      this.handlePagination(totalRecords, 1);
      this.isLoading = false;
    },
    formateTableData(data) {
      return data.map((x) => {
        let status = "N/A";
        if (x.driverId?.isActive && !x.driverId?.isDemoAccount) {
          status = "Active";
        } else if (!x.driverId?.isActive) {
          status = "Inactive";
        } else if (x.driverId?.isDemoAccount) {
          status = "Trial";
        }
        return [
          {
            id: x._id,
            name: x.driverName || "N/A",
          },
          {
            id: x._id,
            name: x.emailAddress || "N/A",
          },
          {
            id: x._id,
            name: x.udi || "N/A",
          },
          {
            id: x._id,
            name: cleanDateFormat(x.createdAt) || "N/A",
          },
          {
            id: x._id,
            name: cleanDateFormat(x.lastLoggedIn) || "N/A",
          },
          {
            id: x._id,
            name:
              status === "Inactive" && x.lastLoggedIn ? x.lastLoggedIn : "N/A",
          },
          {
            id: x._id,
            name: x.driversLicenseState || "N/A",
          },
          {
            id: x._id,
            name: status,
          },
        ];
      });
    },
    onGetReport() {
      this.$refs.modal.openModal();
      this.modal.content = "new";
      this.modal.title = "Send Report To Email";
    },
    async onSubmit() {
      this.sendReportloading = true;
      const payload = {
        reportType: REPORT_NAMES.NHVR_QUARTERLY_DRIVER_REPORT,
        recipientEmail: this.sendReportModel.recipientEmail,
      };

      try {
        await this.$store.dispatch("report/generateReport", payload);
        this.toast(
          "success",
          `An email will be sent to ${payload.recipientEmail} shortly`
        );
      } catch (error) {
        this.toast("error", "Something went wrong!");
      }
      this.sendReportloading = false;
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    handleEmailCasing(value) {
      this.sendReportModel.recipientEmail = value.toLowerCase();
    },

    handlePagination(totalRecords, page) {
      const startIndex = (page - 1) * this.pageLimit;
      const endIndex = startIndex + this.pageLimit;
      totalRecords = totalRecords.slice(startIndex, endIndex);
      const formattedReportData = this.formateTableData(totalRecords);
      const paginationMetaData = {
        skip: page * this.pageLimit - this.pageLimit,
        limit: this.pageLimit,
        total: this.totalRecordLength,
        count:
          formattedReportData.length % this.pageLimit === 0
            ? this.pageLimit
            : formattedReportData.length % this.pageLimit,
      };
      this.paginationSettings = formatPaginationSettings(paginationMetaData);
      this.reportData = formattedReportData;
    },

    async onPageChange(event) {
      if (event.page) {
        this.handlePagination(this.totalRecords, event.page);
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
<style scoped lang="scss">
.compliance-container {
  min-height: calc(100vh - 92px);
}
.send-datepicker::v-deep > div {
  @apply w-full;
}
</style>
