<template>
  <div class="w-full">
    <div v-if="!isSuccess" class="h-full w-full">
      <div v-if="!isLoading" class="flex items-center p-4 bg-gray-100 rounded">
        <font-awesome-icon icon="info-circle" size="2x" class="text-blue"/>
        <strong class="ml-1 uppercase font-semibold text-sm"
        >Search a vehicle report.</strong
        >
      </div>
      <div
          v-if="isLoading"
          class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center"
      >
        <font-awesome-icon icon="cog" spin size="4x" class="text-gray-400"/>
        <h6 class="mt-4">Loading...</h6>
      </div>
    </div>

    <div v-if="isSuccess" class="w-full mt-5 compliance-tabs-wrapper">
      <div class="w-full border border-gray-200 rounded p-5">

        <h4 class="text-orange-primary mb-1 font-bold">Vehicle Report</h4>
        <table class="w-full">
          <thead>
          <tr class="bg-blue text-xs text-left text-white border">
            <th scope="col" class="px-3 py-1">
              Vehicles Added in Period
            </th>
            <th scope="col" class="px-3 py-1">
              KM Traveled in Period
            </th>
            <th scope="col" class="px-3 py-1">
              Number of Pre Starts in Period
            </th>
            <th scope="col" class="px-3 py-1">
              Deactivated in period
            </th>
            <th scope="col" class="px-3 py-1">
              Defected in period
            </th>
            <th scope="col" class="px-3 py-1">
              Defect Removed in period
            </th>
          </tr>
          </thead>
          <tbody>
          <tr class="border">
            <td class="py-2 px-3 text-sm text-center border-l border-b bg-gray-300">
              {{ reportData.vehiclesAdded }}
            </td>
            <td class="py-2 px-3 text-sm text-center border-l border-r border-b bg-gray-300">
              {{ reportData.vehiclesTravel }}
            </td>
            <td class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300">
              {{ reportData.preStarts }}
            </td>
            <td class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300">
              {{ reportData.deactivates }}
            </td>
            <td class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300">
              {{ reportData.defects }}
            </td>
            <td class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300">
              {{ reportData.defectRemoves }}
            </td>
          </tr>
          </tbody>
        </table>

        <h4 class="text-orange-primary mb-1 mt-5 font-bold">Vehicle Information</h4>
        <table class="w-full mb-5">
          <thead>
          <tr class="bg-blue text-xs text-left text-white border">
            <th scope="col" class="px-3 py-1">
              Vehicle Plate
            </th>
            <th scope="col" class="px-3 py-1">
              KM Traveled in Period
            </th>
            <th scope="col" class="px-3 py-1">
              Start Odometer
            </th>
            <th scope="col" class="px-3 py-1">
              End Odometer
            </th>
          </tr>
          </thead>
          <tbody>
          <tr class="border" v-for="(vehicle, index) in reportData.vehicles" :key="index">
            <th class="py-2 px-3 bg-blue text-xs text-left text-white">
              {{ vehicle.vehiclePlate }}
            </th>
            <td class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300">
              {{ vehicle.distance }}
            </td>
            <td class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300">
              {{ vehicle.start }}
            </td>
            <td class="py-2 px-3 text-sm text-center border-r border-b bg-gray-300">
              {{ vehicle.end }}
            </td>
          </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  name: 'VehicleReport',
  props: {
    disabledBefore: String,
    disabledAfter: String,
  },
  data () {
    return {
      isLoading: false,
      isSuccess: false,
      selectedDateRange: [],
      reportData: {},
    };
  },
  methods: {

    canSearch () {
      return (
          this.selectedDateRange.length > 1 &&
          !_.isEmpty(this.selectedDateRange[0])
      );
    },

    async onSearch (selectedDateRange) {
      this.selectedDateRange = selectedDateRange;
      this.isLoading = true;
      this.isSuccess = false;

      const me = this.$store.getters[`account/me`];
      let businessId = me?.businessId;

      const requestData = {
        businessId: businessId,
        startDate: moment().format(`${this.selectedDateRange[0]}THH:mm:ssZ`),
        endDate: moment().format(`${this.selectedDateRange[1]}THH:mm:ssZ`),
      };

      this.reportData = await this.$store.dispatch(`report/getVehicleReport`, requestData);

      this.isLoading = false;
      this.isSuccess = true;
    },

    async onSubmitSendReport (recipientEmail) {
      if (this.canSearch() && recipientEmail) {
        this.$emit('sendReportloading', true);

        const me = this.$store.getters[`account/me`];
        const data = {
          recipientEmail: recipientEmail,
          businessId: me?.businessId,
          startDate: moment().format(`${this.selectedDateRange[0]}THH:mm:ssZ`),
          endDate: moment().format(`${this.selectedDateRange[1]}THH:mm:ssZ`),
        };

        await this.$store
            .dispatch(`business/sendVehicleReport`, data)
            .then((res) => {
              this.toast('success', res.message);
            })
            .catch((err) => {
              this.toast('error', err.message);
            });

        this.$emit('sendReportloading', false);
      }
    },

    toast (state, message) {
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', { state, message });
    },

  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
};
</script>
