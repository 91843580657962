<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="text-red-500 font-bold text-sm text-center my-5" v-if="errorMessage">
      {{errorMessage}}
    </div>
    <div v-if="!loading">
      <FormulateForm @submit="onSubmit">
        <div class="mb-5">
          <label class="font-medium text-lg">Merge into <b>{{selectedVehicle.source}}</b> vehicle <b>{{selectedVehicle.vehiclePlate}}</b>.</label>
        </div>
        <FormulateInput
            type="select"
            name="mergeVehicle"
            v-model="mergeVehicleId"
            label="Select Vehicle to Merge"
            :options="vehicleSelectOptions"
            :disabled="false"
            validation="required"
            help="Listed only mergable vehicles"
        />
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
              type="submit"
              label="Yes"
              input-class="btn btn-primary w-full"
          />
        </StyledSubmit>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { StyledSubmit } from '@/styled-components'
import { formatDate } from "@/_helper";

export default {
  name: 'Merge-Vehicle',
  components: { StyledSubmit },
  props: {
    detailsId:{
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      error:false,
      errorMessage: '',
      mergeVehicleId: '',
      selectedVehicle: {},
      vehicleList: [],
      vehicleSelectOptions: [],
    };
  },
  async mounted() {
    this.loading = true;
    if (!_.isEmpty(this.detailsId)) {

      const vehicleDetails = await this.$store.dispatch(`vehicle/getVehicle`, {id: this.detailsId});
      if(vehicleDetails) { this.selectedVehicle = this.formatItem(vehicleDetails); }

      const entityId = this.$store.getters[`account/me`].business._id;
      const query = {
        skip: 0,
        limit: 15,
        filter: {
          vehiclePlate: { $eq: this.selectedVehicle.vehiclePlate},
          _id: { $nin: [this.selectedVehicle.id]}
        },
      };
      const vehicles = await this.$store.dispatch(
          `vehicle/getVehicleByEntityId`,
          { entityId, query }
      );

      if(vehicles) { this.vehicleSelectOptions = this.formatItemsForSelect(vehicles.results); }
    }
    this.loading = false;
  },
  methods: {
    async onSubmit () {
      this.loading = true;
      if (!_.isEmpty(this.detailsId)) {
        const payload = {
          mainVehicleId: this.detailsId,
          mergeVehicleId: this.mergeVehicleId,
        }

        this.$store.dispatch(`vehicle/mergeVehicles`,  payload).then(vehicle => {
          this.loading = false;
          if (vehicle && vehicle._id) {
            this.$emit('closeRefresh');
            this.$store.commit('setDialogNotify', true);
            this.$store.commit('setMessageNotify', { state: 'success', message: 'Vehicles successfully merged.' });
          }
        }).catch(error => {
          this.loading = false;
          this.$store.commit('setDialogNotify', true);
          this.$store.commit('setMessageNotify', { state: 'error', message: error.message });
        })
      } else {
        this.loading = false;
        this.errorMessage = "No Vehicle Found";
        this.$store.commit('setDialogNotify', true);
        this.$store.commit('setMessageNotify', { state: 'error', message: "Something went wrong please try again." });
      }
    },


    formatItem(item) {
      return {
        id: item._id,
        vehiclePlate: item?.vehiclePlate,
        registrationExpiry: formatDate(item?.registrationExpiry),
        gvm: item?.GMV,
        created: formatDate(item.createdAt),
        status: item?.isActive,
        source: item?.source === "app" ? "Driver Created" : "Business Created",
      };
    },

    formatItemsForSelect(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const source = item?.source === "app" ? "Driver Created " : "Business Created ";
          const vehicle = {
            value: item._id,
            label: source + 'Plate: ' + item?.vehiclePlate + ' Created at: ' + formatDate(item.createdAt),
          };
          nItems.push(vehicle);
        });
      }
      return nItems;
    }

  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>