import { logmaster, logmaster_api } from '@/logmaster-integration/logmaster-integration.js'

let getAddIns = async () => {
    try {
        return logmaster.addins[logmaster.current_addin_name](logmaster_api);
    } catch (error) {
        return error;
    }
}

export default{

    async evaluateAddInToRunThenInitialize (_, param){
        await logmaster.evaluateAddInToRunThenInitialize(param);
    },
    async focusLogmasterAddins () {
        let addIn = await getAddIns();
        await addIn?.focus();
    },
    async blurLogmasterAddins () {
        let addIn = await getAddIns();
        await addIn?.blur();
    },
}