<template>
   <div>
      <div v-if="writeDepots && treeData" class="py-8 px-5 text-sm border-t-2" style="min-height: calc(100vh - (112px + 2.75rem))">
         <div v-if="!isNotEmptyDepot" class="w-full px-1 py-2 border-b border-gray-200 opacity-40" :style="{color: theme.primaryColor}">
            <small><em>Add a region to get started.</em></small>
         </div>

         <button v-if="writeDepots" class="btn btn-primary mb-4 text-sm" @click="addNode" :style="{backgroundColor: theme.secondaryColor}">
            <font-awesome-icon icon='plus' />&nbsp; Add Root Region
         </button>

         <div class="border rounded px-5 pt-3 pb-2">
            <label class="font-bold text-sm mb-3 block border-b pb-3">Active Sites</label>
            <span v-if="!treeData.children || treeData.children.length == 0">No active sites</span>
            <VueTreeList
               v-else
               @change-name="onChangeName"
               @add-node="onAddNode"
               @delete-node="removeNode"
               ref="tree"
               :model="treeData"
               @drop="moveSite"
               @drop-before="moveSite"
               @drop-after="moveSite"

               default-tree-node-name="New Region"
               v-bind:default-expanded="false"
            >
               <template v-slot:leafNameDisplay="slotProps">
                  <div class="flex p-3 rounded-lg" :class="{ 'font-semibold text-orange-primary': slotProps.model.isLeaf }">
                     <span class="text-sm">{{ slotProps.model.name }}</span>
                  </div>
               </template>

               <span class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary" title="Add Site" slot="addLeafNodeIcon">
                  <font-awesome-icon icon="building" />
               </span>

               <span class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary" title="Add Sub Region" slot="addTreeNodeIcon">
                  <font-awesome-icon icon='map-marked-alt' />
               </span>

               <span class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary" slot="editNodeIcon">
                  <font-awesome-icon icon='edit' />
               </span>

               <span class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary" title="Region" slot="treeNodeIcon">
                  <font-awesome-icon icon='map-marked-alt' />
               </span>

               <span class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary" title="Site" slot="leafNodeIcon">
                  <font-awesome-icon icon="building" />
               </span>

               <span class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary" slot="delNodeIcon">
                  <font-awesome-icon icon='trash' />
               </span>
            </VueTreeList>
         </div>

         <div class="mt-3 border rounded px-5 pt-3 pb-2">
            <label class="font-bold text-sm mb-3 block border-b pb-3">Deactivated Sites</label>
            <span v-if="!deactivatedTreeData.children || deactivatedTreeData.children.length == 0">No deactivated sites</span>
            <VueTreeList
               v-else
               ref="tree"
               :model="deactivatedTreeData"
               @drop="removeNode"
               @drop-before="removeNode"
               @drop-after="removeNode"
               default-tree-node-name="New Region"
               v-bind:default-expanded="false"
               >
               <template v-slot:leafNameDisplay="slotProps">
                  <div class="flex p-3 rounded-lg" :class="{ 'font-semibold text-orange-primary': slotProps.model.isLeaf }">
                     <span class="text-sm">{{ slotProps.model.name }}</span>
                  </div>
               </template>

               <span class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary" title="Add Site" slot="addLeafNodeIcon">
                  <font-awesome-icon icon="building" /></span>

               <span class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary" title="Add Sub Region" slot="addTreeNodeIcon">
                  <font-awesome-icon icon='map-marked-alt' /> </span>

               <span class="icon hidden" slot="editNodeIcon"></span>

               <span class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary" title="Region" slot="treeNodeIcon">
                  <font-awesome-icon icon='map-marked-alt' /></span>

               <span class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary" title="Site" slot="leafNodeIcon">
                  <font-awesome-icon icon="building" />
               </span>

               <span class="icon hidden" slot="delNodeIcon"></span>
            </VueTreeList>
         </div>

         <Modal ref="modal" :title="modalTitle" :size="modalSize" :height="modalHeight">
            <div v-if="modalContent == 'rootRegion'">
               <NewRootRegion :tree="treeData" :newLeaf="leafParams" :entityId="entityId" @refetch="fetchDepots" @closeModal="closeAllModals" />
            </div>
            <div v-if="modalContent == 'region'">
               <NewRegion :tree="treeData" :newLeaf="leafParams" :entityId="entityId" @refetch="fetchDepots" @closeModal="closeAllModals" />
            </div>
            <div v-if="modalContent == 'new'">
               <NewSite :tree="treeData" :newLeaf="leafParams" :entityId="entityId" :rootBusinessName="rootBusinessName" @refetch="fetchDepots" @closeModal="closeAllModals" />
            </div>
            <div v-if="modalContent == 'deactivate'">
               <DeactivateSite :siteId="siteIdToDelete" @closeModal="closeAllModals"/>
            </div>
         </Modal>
      </div>
      <div v-if="!readDepots" class="text-center">
         <h1>Not authorized to view depot configuration. Please contact your {{ userType }}.</h1>
      </div>
   </div>
</template>

<script>
import {
   VueTreeList,
   Tree
} from 'vue-tree-list'
import {
   DEFAULT_MODAL_HEIGHT
} from '@/_helper/constants'
import {
   Modal
} from '@/components/commons'
import {
   NewSite,
   NewRegion,
   NewRootRegion,
   DeactivateSite
} from '@/components/forms/depot'
import {
   mapGetters
} from 'vuex'
import _ from 'lodash'

export default {
   components: {
      VueTreeList,
      Modal,
      NewSite,
      NewRegion,
      NewRootRegion,
      DeactivateSite
   },

   data() {
      return {
         modalHeight: DEFAULT_MODAL_HEIGHT,
         modalTitle: '',
         modalContent: false,
         modalSize: '2xl',
         leafParams: {},
         currentLeafPointer: false,
         entityId: '',
         treeData: new Tree([]),
         readDepots: false,
         writeDepots: false,
         userType: null,
         isNotEmptyDepot: false,
         rootBusinessName: '',
         depotLinkage: [],
         siteIdToDelete: null,
         deactivatedTreeData: new Tree([])
      }
   },

   async created() {
      const me = this.$store.getters[`account/me`]
      const proxySite = this.$store.getters[`account/proxySite`];
      let securityRoles = me?.securityRoles
      this.userType = securityRoles[0].type;
      if (securityRoles && securityRoles.length > 0) {
         const securityAccessObj = await this.setSecurityAccess(securityRoles)
         const isProxySite = !_.isEmpty(proxySite) && _.isObject(proxySite);
         this.writeDepots = isProxySite ? false : securityAccessObj.writeDepots
         this.readDepots = isProxySite ? false : securityAccessObj.readDepots
      }
      await this.fetchDepots()
   },

   methods: {
      async onChangeName(params) {
         const payload = {
            id: params.id,
            name: params.newName
         }
        await this.$store.dispatch(`business/updateDepotName`, payload)
      },
      async recursive(node) {
         for(var i = 0, count = node.children.length; i < count; i++) {
            this.recursive(node.children[i])
            if(!_.isEmpty(this.depotLinkage)){
               // Adding Web profile uid to the site
               for(var j = 0, countJ = this.depotLinkage.length; j < countJ; j++) {
                  if(this.depotLinkage[j]){
                     if(this.depotLinkage[j]?.depotConfig?._id == node.children[i]?._id){
                        node.children[i].uid = this.depotLinkage[j].business?.webProfiles[0]?.uid
                     }
                  }
               }
            }
         }
      },
      async fetchDepots() {
         const me = this.$store.getters[`account/me`]
         this.entityId = me?.business?._id
         this.rootBusinessName = me?.business?.persona?.businessName

         const depots = await this.$store.dispatch(`business/getDepot`, this.entityId)
         const depotLinkageExist = await this.$store.dispatch(`business/getDepotLinkage`, this.entityId)

         // Trigger fetching of site to display on header
         this.$store.commit('driver/setUpdateHeaderSites', depots)
         
         const deactivatedSites = await this.$store.dispatch(`business/getSites`, { depotId: this.entityId, isDeleted: true })
         if (deactivatedSites) this.deactivatedTreeData = new Tree(deactivatedSites);

         if(depotLinkageExist){
            this.depotLinkage = depotLinkageExist
         }

         if(depots.length > 0){
            for(var i = 0, count = depots.length; i < count; i++) {
               this.recursive(depots[i])
            } 
            this.isNotEmptyDepot = true
            const newDepots = depots.map(item=> {
               let tree = item;
               if (item.isRegion && item.children.length > 0) {
                  tree.delNodeDisabled = true;
               }
               return tree;
            })
            this.treeData = new Tree([...newDepots])
         }
         
         this.$emit("reLoaded")
      },

      async closeAllModals() {
         await this.fetchDepots()
         this.$refs.modal.closeModal()
      },

      onAddNode(params) {
         if (params.isLeaf) {
            params.remove()
            this.modalContent = 'new'
            this.modalTitle = 'New Site'
            this.$refs.modal.toggleModal()
            this.leafParams = params
         } else {
            params.remove()
            this.modalContent = 'region'
            this.modalTitle = 'New Sub-Region'
            this.$refs.modal.toggleModal()
            this.leafParams = params
         }
      },
      addNode() {
         this.modalContent = 'rootRegion'
         this.modalTitle = 'New Region'
         this.$refs.modal.toggleModal()
      },
      setSecurityAccess(securityRoles) {
         let initialReadDepots = true
         let initialWriteDepots = true
         securityRoles.forEach((securityRole) => {
            const securityConfig = securityRole?.securityConfig
            if (securityConfig.permissions?.readDepots?.value) {
               initialReadDepots = securityConfig?.permissions?.readDepots.value
            }
            if (securityConfig.permissions?.writeDepots?.value) {
               initialWriteDepots = securityConfig?.permissions?.writeDepots?.value
            }
         })

         return {
            readDepots: initialReadDepots,
            writeDepots: initialWriteDepots,
         };
      },
      async moveSite(e) {
         if (e.target.isRegion) {
          const result = await this.$swal.fire({
               title: `You are about to move site to another region.`,
               text: 'This action cannot be undone!',
               type: "warning",
               showCancelButton: true,
               confirmButtonText: 'Okay, move it!',
               cancelButtonText: "Cancel",
            })
            const payload = {
               targetDepotId: e.target._id,
               sourceDepotId: e.src._id,
               nodeDepotId: e.node._id,
            }

            this.$emit("updateLoading", true)

            if (result.isConfirmed) {
               await this.$store.dispatch(`business/moveSite`, payload)
               .then(()=> {
                  this.$store.commit('setDialogNotify', true)
                  this.$store.commit('setMessageNotify', {
                     state: 'success',
                     message: 'Business site successfully moved.'
                  })
               })
               .catch(err =>{
                  this.$store.commit('setDialogNotify', true)
                  this.$store.commit('setMessageNotify', {
                     state: 'error',
                     message: err.message
                  })
               })
            }
         }

         await this.fetchDepots()
      },
      async removeNode(e) {
         if (e.isRegion) {
            await this.$store.dispatch(`business/deleteRegion`, e._id)
               .then(()=> {
                  this.$store.commit('setDialogNotify', true)
                  this.$store.commit('setMessageNotify', {
                     state: 'success',
                     message: 'Region successfully deleted.'
                  })
                  e.remove();
               })
               .catch(err =>{
                  this.$store.commit('setDialogNotify', true)
                  this.$store.commit('setMessageNotify', {
                     state: 'error',
                     message: err.message
                  })
               })
         } else {
            this.siteIdToDelete = e._id ? e._id : e.node._id;

            const { value: answer } = await this.$swal.fire({
               title: `Site Deactivation`,
               text: 'Do you want to move Drivers and Vehicles?',
               type: "warning",
               showCancelButton: true,
               confirmButtonText: 'Yes, lets do it!',
               cancelButtonText: 'Cancel',
            })

            this.$emit("updateLoading", true)
            if (answer) {
               this.modalContent = 'deactivate'
               this.modalTitle = 'Site Deactivation'
               this.$refs.modal.toggleModal()
            }
         }

         await this.fetchDepots()
      },
   },
   computed: {
      ...mapGetters('theme', {
         theme: 'getColorScheme'
      })
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" rel="stylesheet/scss" scoped>
   .vtl {
      .vtl-drag-disabled {
         background-color: #d0cfcf;
         &:hover {
         background-color: #d0cfcf;
         }
      }
      .vtl-disabled {
         background-color: #d0cfcf;
      }
   }

   .icon {
      &:hover {
         cursor: pointer;
      }
   }

   .muted {
      color: gray;
      font-size: 80%;
   }
</style>
<style lang="scss">
  .swal2-title {
      font-size: 18px !important;
      padding-top: 20px !important;
      margin-top: 10px !important;
   }
   .swal2-label {
      font-size: 14px !important;
   }
</style>