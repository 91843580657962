import { GeotabUsers } from "@/api";

export default {
  async getGeotabUsers(_, param) {
    try {
      const response = await GeotabUsers.getGeotabUsers(param);

      if (response.data.data) {
        return {
          success: response.data?.success,
          data: response.data.data,
          message: response.data?.message,
          metadata: response.data?.resultsMeta,
        };
      } else {
        return {
          success: response?.data?.success,
          message: response?.data?.message,
        };
      }
    } catch (error) {
      return error;
    }
  },
};
