<template>
  <div>
    <FormulateForm
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
      @check-email="checkEmail($event)"
    >
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="loading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
            data...</span
          >
          <span v-if="!loading">Submit</span>
        </FormulateInput>
        <div v-if="entity" class="text-center text-l font-bold mb-5">
          Sign up here or speak to {{ entity.persona.tradingName }} if you have
          any questions.
        </div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import desktopSchema from "./new-business-schemaV2";
import mobileSchema from "./new-business-schema-mobile";
import { mapGetters } from "vuex";

export default {
  name: "Add-Business-Form",
  props: {
    windowWidth: Number,
    mobileViewMaxWidth: Number,
    entity: String,
  },
  data() {
    return {
      loading: false,
      values: {},
      schema: {},
      errorMessage: "",
      hasErrorMessage: false,
      newBusiness: {},
      mobileSchema: mobileSchema,
      desktopSchema: desktopSchema,
    };
  },

  async mounted() {
    this.loading = true;
    this.toggleSchema();
    this.loading = false;
  },

  watch: {
    windowWidth() {
      this.toggleSchema();
    },
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const newBusinessLead = this.values;
      let parentType = "";
      let parentEntityId = null;

      if (this.entity) {
        parentType = "partner";
        parentEntityId = this.entity._id;
      } else {
        parentType = "admin";
      }

      const payload = {
        parentType: parentType,
        parentEntityId: parentEntityId,
        type: "business",
        businessName: newBusinessLead.businessName,
        tradingName: newBusinessLead.tradingName,
        abn: newBusinessLead.abn,
        businessAddress: newBusinessLead.location[0].businessAddress,
        contactUserName: newBusinessLead.contactUserName,
        contactEmail: newBusinessLead.contactEmail,
        contactPhoneNumber: newBusinessLead.contactPhoneNumber,
      };

      this.$store
        .dispatch(`business/createBusinessLead`, payload)
        .then((response) => {
          this.toast("success", "Lead successfully created.");
          this.loading = false;
          this.$emit("newBusinessAdded", response);
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = `Something went wrong please try again.`;
          if (error.message) this.errorMessage = error.message;
          this.toast("error", this.errorMessage);
        });
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    checkEmail(value) {
      console.log("value", value);
      // this.values.emailAddress = value.toLowerCase();
    },

    toggleSchema() {
      if (this.windowWidth > this.mobileViewMaxWidth) {
        this.schema = this.desktopSchema;
      } else {
        this.schema = this.mobileSchema;
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
