<template>
    <div class="mb-16">
      <div class="text-center mb-10">
        <div class="flex text-right justify-end">
          <a @click.prevent="onClickEdit(business.id)" class="flex cursor-pointer">
            <font-awesome-icon icon="edit" class="my-auto mx-1.5 hover:text-orange-primary text-xs"/>
          </a>
          <!-- <a class="flex cursor-pointer"><font-awesome-icon icon="trash" class="my-auto mx-1.5 hover:text-orange-primary text-xs"/></a> -->
        </div>
        <div class="mx-auto w-24"><img class="rounded-md border" :src="business.LogoLightBackground" alt=""></div>
        <div class="font-bold text-black-primary text-2xl">
          {{business.name}}
        </div>
        <div class="font-bold text-xs" :class="[business.isActive ? 'text-green-500' : 'text-red-500']">{{business.isActive ? 'ACTIVE' : 'INACTIVE'}}</div>
      </div>
      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Business Details</div>
      </div>
      <!--    <div class="mb-5">
                  <div class="font-bold text-black-primary text-base">{{business.name}}</div>
                  <div class="text-xs">Business Name</div>
              </div> -->
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{business.abn}}</div>
        <div class="text-xs">NZBN</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{business.businessAddress}}</div>
        <div class="text-xs">Business Address</div>
      </div>
      <div class="mt-8 mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Contact Details</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{business.contactEmail}}</div>
        <div class="text-xs">Email Address</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{business.contactPhoneNumber}}</div>
        <div class="text-xs">Phone Number</div>
      </div>
      <div class="mt-8 mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Support Details</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{business.supportEmail}}</div>
        <div class="text-xs">Email Address</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{business.supportPhone}}</div>
        <div class="text-xs">Phone Number</div>
      </div>
    </div>
</template>
<script>

export default {
    name: 'Business-Details-View',
    data(){
      return{
        business: {}
      }
    },
    props: {
      activeBusiness: {
        type: Object,
        default: () => {}
      },
    },
    watch: {
      activeBusiness: {
        deep: true,
        handler() {
          this.setActiveBusinessData()
        }
      }
    },
    created() {
      this.loading = true
      this.setActiveBusinessData()
      setTimeout(() => this.loading = false, 300)
    },
    methods: {
        parsebusiness(business){
            return {
                id: business._id,
                name: business?.persona?.businessName,
                tradingName: business?.persona?.tradingName || '-',
                abn: business?.persona?.abn,
                businessAddress: business?.persona?.businessAddress,
                contactUserName: business.contactUserName,
                contactEmail: business?.persona?.contactEmail,
                contactPhoneNumber: business?.persona?.contactPhoneNumber,
                supportEmail: business.supportEmail,
                LogoLightBackground: business.businessLogoLightBackground,
                LogoDarkBackground: business.businessLogoDarkBackground,
                supportPhone: business?.supportPhone || '-',
                partner: business.parentRole,
                reseller: business.businessReseller,
                isActive: business.isActive
            }
        },
        setActiveBusinessData(){
            this.business = this.parsebusiness(this.activeBusiness);
        },
        onClickEdit(id){
            this.$emit('editBusiness', id)
        }
    }

}
</script>