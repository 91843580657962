import { API } from "@/api/config";
import { ObjectToQueryString } from "@/_helper";

class Report {
  getAllReportJobs() {
    return API.ACCOUNTS.get(`/report-job/`);
  }

  processReportJob(id) {
    return API.ACCOUNTS.get(`/report-job/${id}`);
  }

  downdloadReport(data) {
    return API.ACCOUNTS.post(`/report/report-transfer/download`, data);
  }

  sendReport(data) {
    return API.ACCOUNTS.post(`/report/report-transfer/send`, data);
  }

  getBreachesByDateRange(payload) {
    const driverId = payload.driverId;
    if (payload?.filter && "driverIds" in payload?.filter) {
      payload.filter.driverId = payload.filter.driverIds;
      delete payload.filter.driverIds;
    }
    return API.ACCOUNTS.post(
      `/breach-engine/findbydaterange/${driverId}`,
      payload
    );
  }

  getBreachesById(id) {
    return API.ACCOUNTS.get(`/breach-engine/${id}`);
  }

  getBreach(payload) {
    return API.ACCOUNTS.get(`/breach-engine/${payload.id}`);
  }

  sendBreachReport(data) {
    return API.ACCOUNTS.post(`/report/breach/send`, data);
  }

  sendFormsReport(data) {
    return API.ACCOUNTS.post(`/form-report/send`, data);
  }

  getNonConformance(pagination = { skip: 0, limit: 10, filter: {} }) {
    let filter = "";
    if (typeof pagination.filter === "object") {
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }
    }

    pagination.filter = filter;

    filter = JSON.stringify(pagination.filter);

    if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";
    return API.ACCOUNTS.get(`/non-conformance/${pagination}`);
  }

  getNhvrReportData() {
    return API.ACCOUNTS.post("/report/nhvr-report");
  }

  generateReport(payload) {
    return API.ACCOUNTS.post(`/report/generate-report`, payload);
  }

  getNonConformanceByRegisterId(payload) {
    // registerId is incidentId, hazardId, defectId etc.
    let { registerId, pagination = { skip: 0, limit: 10 } } = payload;
    if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";

    return API.ACCOUNTS.get(
      `/non-conformance/register/${registerId}${pagination}`
    );
  }

  getNonConformanceCountByRegisterType(payload) {
    const { startDate, endDate, type } = payload;
    return API.ACCOUNTS.get(
      `non-conformance/register-type/${type}/count?startDate=${startDate}&endDate=${endDate}`
    );
  }

  reviewNonConformance(payload) {
    const { documentId } = payload;
    delete payload.documentId;
    return API.ACCOUNTS.patch(`/non-conformance/review/${documentId}`, payload);
  }

  createNonConformance(payload) {
    return API.ACCOUNTS.post(`/non-conformance`, payload);
  }

  getDriverStats(payload) {
    return API.ACCOUNTS.post("/report/driver-stats", payload);
  }

  getCompliancePeriodReport(payload) {
    return API.ACCOUNTS.post("/report/compliance-period", payload);
  }

  sendCompliancePeriodReport(payload) {
    return API.ACCOUNTS.post("/report/compliance-period/send", payload);
  }

  getVehicleReport(payload) {
    return API.ACCOUNTS.post("/report/vehicle-report", payload);
  }

  sendVehicleReport(payload) {
    return API.ACCOUNTS.post("/report/vehicle-report/send", payload);
  }

  getNonConformanceSummaryReport(paylaod) {
    return API.ACCOUNTS.post(`non-conformance/summary-report`, paylaod)
  }

  getNonConformanceChainOfActions(id) {
    return API.ACCOUNTS.get(`non-conformance/${id}/chain/actions`)
  }
}

export default new Report();
