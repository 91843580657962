<template>
    <div v-if="visible" v-show="isActive">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Tab",
        },
        badge: {
            type: Number,
            default: null
        },
        visible: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isActive: true,
        };
    },
};
</script>
