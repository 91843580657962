<template>
  <div v-if="rendered">
    <div class="flex flex-col w-full h-screen overflow-y-auto p-2">
      <div class="flex flex-row">
        <button class="btn btn-secondary w-20 mb-4" @click.prevent="goBack">
          Back
        </button>

        <button
          class="btn btn-primary w-30 mb-4 ml-4"
          @click.prevent="toggleAddDocument"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Add Document
        </button>

        <button
          class="btn btn-primary w-30 mb-4 ml-4"
          @click.prevent="toggleDelinkCert"
        >
          Delink Certificate
        </button>
      </div>

      <div class="p-3 m-0 flex-col rounded shadow bg-white">
        <Tabs clickEvent @click="fetchTab">
          <Tab title="Accreditations">
            <div v-if="!loading">
              <Table
                v-if="currentTab == 'Accreditations'"
                :tableHeader="accreditationHeaders"
                :paginationSettings="paginationSettings"
                :data="accreditationData"
                @onPageChange="onPageChange($event)"
              >
                <template #tableFilters> </template>

                <template #default="{ data }">
                  <td class="px-5 py-2 flex flex-row">
                    <div
                      class="text-black-primary whitespace-no-wrap text-lg flex"
                    >
                      <a
                        class="flex cursor-pointer"
                        @click.prevent="toggleViewCert(data)"
                      >
                        <font-awesome-icon
                          icon="eye"
                          class="my-auto mx-1.5 hover:text-orange-primary-primary"
                        />
                      </a>

                      <a
                        class="flex cursor-pointer"
                        @click.prevent="toggleRegenerate(data)"
                        download
                      >
                        <font-awesome-icon
                          icon="certificate"
                          class="my-auto mx-1.5 hover:text-orange-primary-primary"
                        />
                      </a>
                    </div>
                  </td>
                </template>
              </Table>
            </div>
            <div v-else>
              <font-awesome-icon
                icon="circle-notch"
                spin
                size="2x"
                class="text-gray-400"
              />
            </div>
          </Tab>

          <Tab title="Induction Letters">
            <div v-if="!loading">
              <Table
                v-if="currentTab == 'Induction Letters'"
                :tableHeader="inductionHeaders"
                :paginationSettings="paginationSettings"
                :data="inductionData"
                @onPageChange="onPageChange($event)"
              >
                <template #tableFilters>
                  <div class="flex flex-row mb-1 sm:mb-0">
                    <div class="relative">
                      <select
                        v-model="filter.limit"
                        @change="filterChange"
                        class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="50">50</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <font-awesome-icon
                          icon="caret-down"
                          class="text-gray-400"
                        />
                      </div>
                    </div>

                    <div class="relative">
                      <select
                        v-model="filter.isActive"
                        @change="filterChange"
                        class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option :value="'all'">All</option>
                        <option :value="true">Active</option>
                        <option :value="false">Inactive</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <font-awesome-icon
                          icon="caret-down"
                          class="text-gray-400"
                        />
                      </div>
                    </div>

                    <div class="relative">
                      <span
                        class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                      >
                        <font-awesome-icon
                          icon="search"
                          class="text-gray-400"
                        />
                      </span>
                      <input
                        placeholder="Search"
                        class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                        v-model="filter.search"
                        @keydown="handleSearchKeyDown"
                      />
                    </div>
                  </div>
                </template>

                <template #default="{ data }">
                  <td class="px-5 py-2 flex flex-row">
                    <div
                      class="text-black-primary whitespace-no-wrap text-lg flex"
                    >
                      <a
                        class="flex cursor-pointer"
                        target="_blank"
                        :href="data.document"
                        download
                      >
                        <font-awesome-icon
                          icon="eye"
                          class="my-auto mx-1.5 hover:text-orange-primary-primary"
                        />
                      </a>
                    </div>
                  </td>
                </template>
              </Table>
            </div>
            <div v-else class="p-2 w-full text-center text-lg">
              <font-awesome-icon
                icon="circle-notch"
                spin
                size="2x"
                class="text-gray-400"
              />
            </div>
          </Tab>

          <Tab title="Medical Certificates">
            <div v-if="!loading">
              <Table
                :tableHeader="docHeaders"
                :paginationSettings="paginationSettings"
                :data="documentData"
                @onPageChange="onPageChange($event)"
              >
                <template #tableFilters>
                  <div class="flex flex-row mb-1 sm:mb-0">
                    <div class="relative">
                      <select
                        v-model="filter.limit"
                        @change="filterChange"
                        class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="50">50</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <font-awesome-icon
                          icon="caret-down"
                          class="text-gray-400"
                        />
                      </div>
                    </div>

                    <div class="relative">
                      <select
                        v-model="filter.isActive"
                        @change="filterChange"
                        class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option :value="'all'">All</option>
                        <option :value="true">Active</option>
                        <option :value="false">Inactive</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <font-awesome-icon
                          icon="caret-down"
                          class="text-gray-400"
                        />
                      </div>
                    </div>
                  </div>
                </template>

                <template #default="{ data }">
                  <td class="px-5 py-2 flex flex-row">
                    <div
                      class="text-black-primary whitespace-no-wrap text-lg flex"
                    >
                      <a
                        class="flex cursor-pointer"
                        target="_blank"
                        :href="data.document"
                        download
                      >
                        <font-awesome-icon
                          icon="eye"
                          class="my-auto mx-1.5 hover:text-orange-primary-primary"
                        />
                      </a>
                    </div>
                  </td>
                </template>
              </Table>
            </div>
            <div v-else class="p-2 w-full text-center text-lg">
              <font-awesome-icon
                icon="circle-notch"
                spin
                size="2x"
                class="text-gray-400"
              />
            </div>
          </Tab>

          <Tab title="Training Certificates">
            <div v-if="!loading">
              <Table
                :tableHeader="docHeaders"
                :paginationSettings="paginationSettings"
                :data="documentData"
                @onPageChange="onPageChange($event)"
              >
                <template #tableFilters>
                  <div class="flex flex-row mb-1 sm:mb-0">
                    <div class="relative">
                      <select
                        v-model="filter.limit"
                        @change="filterChange"
                        class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option :value="10">10</option>
                        <option :value="20">20</option>
                        <option :value="50">50</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <font-awesome-icon
                          icon="caret-down"
                          class="text-gray-400"
                        />
                      </div>
                    </div>

                    <div class="relative">
                      <select
                        v-model="filter.isActive"
                        @change="filterChange"
                        class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option :value="'all'">All</option>
                        <option :value="true">Active</option>
                        <option :value="false">Inactive</option>
                      </select>
                      <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      >
                        <font-awesome-icon
                          icon="caret-down"
                          class="text-gray-400"
                        />
                      </div>
                    </div>
                  </div>
                </template>

                <template #default="{ data }">
                  <td class="px-5 py-2 flex flex-row">
                    <div
                      class="text-black-primary whitespace-no-wrap text-lg flex"
                    >
                      <a
                        class="flex cursor-pointer"
                        target="_blank"
                        :href="data.document"
                        download
                      >
                        <font-awesome-icon
                          icon="eye"
                          class="my-auto mx-1.5 hover:text-orange-primary-primary"
                        />
                      </a>
                    </div>
                  </td>
                </template>
              </Table>
            </div>
            <div v-else class="p-2 w-full text-center text-lg">
              <font-awesome-icon
                icon="circle-notch"
                spin
                size="2x"
                class="text-gray-400"
              />
            </div>
          </Tab>
        </Tabs>
      </div>

      <Modal
        ref="modal"
        :title="modalTitle"
        :size="modalSize"
        :height="modalHeight"
      >
        <div v-if="modalContent === 'associateDriver'">
          <AccreditDriver @refetch="refetchData" @closeModal="closeModal" />
        </div>

        <div v-if="modalContent === 'regenerate'">
          <div class="text-center my-4 w-full">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-title"
            >
              Only generate if letter is outdated or did not generate, letter
              will generate within 5 - 20 minutes
            </h3>
          </div>

          <div class="mt-16">
            <div class="btns">
              <div class="pb-2">
                <button
                  class="btn btn-primary w-full"
                  @click="regenerateInduction"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  YES
                </button>
              </div>
              <button
                class="border-2 btn btn-secondary w-full"
                @click="closeModal"
              >
                NO
              </button>
            </div>
          </div>
        </div>

        <div v-if="modalContent === 'delinkCert'">
          <div class="mb-16">
            <div class="btns">
              <div class="pb-2">
                <button
                  class="btn btn-primary w-full"
                  @click="delinkCert"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  YES
                </button>
              </div>
              <button
                class="border-2 btn btn-secondary w-full"
                @click="closeModal"
              >
                NO
              </button>
            </div>
          </div>
        </div>

        <div v-if="modalContent === 'addDocuments'">
          <FormulateForm
            @submit="submitDocuments"
            ref="docForm"
            #default="{ hasErrors }"
          >
            <div class="pb-10 border-2 p-6 rounded-lg mb-6">
              <FormulateInput type="group" name="medCertData">
                <FormulateInput
                  type="file"
                  name="medCertFile"
                  label="Upload Medical Certificate"
                  accept="application/pdf"
                  validation="mime:application/pdf"
                  v-model="medCert"
                  label-class="formulate-input-label formulate-input-label--before"
                  outer-class="formulate-input"
                  input-class=""
                  wrapper-class="formulate-input-wrapper"
                  upload-area-class="formulate-input-upload-area"
                  upload-area-mask-class="formulate-input-upload-area-mask"
                  @file-removed="clearInputFile('med')"
                  @click="
                    () => {
                      errorMessage = '';
                    }
                  "
                />
                <div class="flex justify-center p-2">
                  <div class="w-1/2">
                    <p class="font-medium text-sm mb-1">Expiry Date</p>
                  </div>
                  <FormulateInput
                    v-model="medExpiryToggle"
                    type="checkbox"
                    name="expire"
                  />
                </div>

                <div v-if="medExpiryToggle">
                  <FormulateInput
                    type="date"
                    label="Medical Certificate Expiry"
                    name="expiryDate"
                    v-model="medExpiryDate"
                    required
                  />
                </div>
              </FormulateInput>
            </div>
            <div class="pb-10 border-2 p-6 rounded-lg">
              <FormulateInput type="group" name="trainingCertData">
                <FormulateInput
                  type="file"
                  name="trainingCertFile"
                  label="Upload Training Certificate"
                  accept="application/pdf"
                  validation="mime:application/pdf"
                  v-model="trainingCert"
                  label-class="formulate-input-label formulate-input-label--before"
                  outer-class="formulate-input"
                  input-class=""
                  wrapper-class="formulate-input-wrapper"
                  upload-area-class="formulate-input-upload-area"
                  upload-area-mask-class="formulate-input-upload-area-mask"
                  @file-removed="clearInputFile('training')"
                />

                <div class="flex justify-center p-2">
                  <div class="w-1/2">
                    <p class="font-medium text-sm mb-1">Expiry Date</p>
                  </div>
                  <FormulateInput
                    v-model="trainingExpiryToggle"
                    type="checkbox"
                    name="expire"
                  />
                </div>

                <div v-if="trainingExpiryToggle">
                  <FormulateInput
                    type="date"
                    label="Training Certificate Expiry"
                    name="expiryDate"
                    v-model="trainingExpiryDate"
                    required
                  />
                </div>
              </FormulateInput>
            </div>

            <FormulateInput
              type="submit"
              label="Submit"
              input-class="btn btn-primary w-full mt-3"
              :style="{ backgroundColor: theme.secondaryColor }"
              :disabled="hasErrors"
            />

            <div class="text-red-500 text-center mb-3" v-if="errorMessage">
              {{ errorMessage }}
            </div>
          </FormulateForm>
        </div>

        <div v-if="modalContent === 'viewCerts'">
          <CertContents :content="selectedCert" :isDriver="false" />
        </div>

        <div v-if="modalContent === 'viewDocuments'">
          <div
            class="flex flex-col"
            v-if="!activeMedCert && !activeTrainingCert"
          >
            <span class="font-semibold text-xl"
              >Driver has not shared or has not submitted documents</span
            >
          </div>

          <div v-else>
            <div v-if="activeMedCert">
              <span class="font-semibold text-xl">Medical Certificate</span>
              <!-- <div>
            <a :href="activeMedCert[0].document" download>{{activeMedCert[0]._id}}</a>
          </div> -->
              <td class="px-5 py-2 flex flex-row">
                <a :href="activeMedCert.document" target="_blank" download
                  >{{ activeMedCert._id }}
                  <font-awesome-icon
                    icon="eye"
                    class="my-auto mx-1.5 hover:text-orange"
                /></a>
              </td>
            </div>
            <div v-if="activeTrainingCert">
              <span class="font-semibold text-xl">Training Certificate</span>
              <div>
                <div>
                  <td class="px-5 py-2 flex flex-row">
                    <a
                      :href="activeTrainingCert.document"
                      target="_blank"
                      download
                      >{{ activeTrainingCert._id }}
                      <font-awesome-icon
                        icon="eye"
                        class="my-auto mx-1.5 hover:text-orange"
                    /></a>
                  </td>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  </div>
  <div v-else class="p-2 w-full text-center text-lg">
    <font-awesome-icon
      icon="circle-notch"
      spin
      size="2x"
      class="text-gray-400"
    />
  </div>
</template>

<script>
import Tabs from "@/components/commons/tab/Tabs";
import Tab from "@/components/commons/tab/Tab";
import _ from "lodash";
import { formatPaginationSettings } from "@/_helper/";
import { Table, Modal } from "@/components/commons";
import CertContents from "@/components/view/business/accreditation/cert-contents";
import { formatDate } from "@/_helper";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
  ROLES
} from "@/_helper/constants";
import { mapGetters } from "vuex";

export default {
  name: "view-driver-cert-content",
  components: { Tabs, Tab, Modal, Table, CertContents },
  props: {
    driverInfo: {
      type: Object,
      required: true,
      default: () => {},
    },

    certDetails: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      docHeaders: ["Document", "Status", "Created On"],
      accreditationHeaders: ["Accreditation", "Type", "Created on"],
      inductionHeaders: ["Document", "Type", "Status", "Created on"],

      activeMedCert: false,
      activeTrainingCert: false,
      inactiveMedCerts: [],
      inactiveTrainingCerts: [],

      modalTitle: "",
      modalSize: "2xl",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalContent: "",

      certs: [],

      filter: {
        limit: 10,
        search: "",
        isActive: "all",
      },

      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },

      fileTypeSelected: false,
      selectedFileType: "",
      pdf: "",
      document: false,

      medCert: false,
      trainingCert: false,
      selectedCert: false,

      trainingCertError: false,
      medCertError: false,
      hasErrors: false,
      loading: false,
      driverId: false,

      certificates: [],
      currentTab: "Accreditations",

      medExpiryToggle: false,
      trainingExpiryToggle: false,

      medExpiryDate: false,
      trainingExpiryDate: false,

      errorMessage: "",

      accreditationToRegenerate: null,

      rendered: true,
    };
  },

  async mounted() {
    console.log('here?');
    await this.init();
  },

  computed: {
    documentData() {
      if (this.certs && this.certs.length > 0) {
        let tableData = this.certs;
        return this.parseUserDocuments(tableData);
      } else {
        return [];
      }
    },

    accreditationData() {
      if (this.certs && this.certs.length > 0) {
        let tableData = this.certs;

        return this.parseAccreditation(tableData);
      } else {
        return [];
      }
    },

    inductionData() {
      if (this.certs && this.certs.length > 0) {
        let tableData = this.certs;

        return this.parseInduction(tableData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },

  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },

  methods: {
    clearInputFile(type) {
      if (type == "med") {
        this.medCert = null;
      } else {
        this.trainingCert = null;
      }
    },

    async submitDocuments(data) {
      const medCertFile = _.get(
        data,
        "medCertData[0].medCertFile.fileList[0]",
        null
      );

      const trainingCertFile = _.get(
        data,
        "trainingCertData[0].trainingCertFile.fileList[0]",
        null
      );

      if (medCertFile || trainingCertFile) {
        try {
          this.loading = true;

          let medCertPayload = null;
          let trainingCertPayload = null;

          if (medCertFile) {
            medCertPayload = {
              fileType: "DRIVER_MEDICAL",
              userMongoId: this.driverInfo.driverId,
              userType: ROLES.DRIVERS,
              file: medCertFile,
            };

            if (this.medExpiryToggle) {
              const expiryDate = _.get(data, "medCertData[0].expiryDate", null);
              if (!expiryDate) {
                this.errorMessage = "Medical Certificate details incomplete";
                this.loading = false;

                return;
              }
              medCertPayload["expiryDate"] = expiryDate;
            }
          }

          if (trainingCertFile) {
            trainingCertPayload = {
              fileType: "DRIVER_TRAINING",
              userMongoId: this.driverInfo.driverId,
              userType: ROLES.DRIVERS,
              file: trainingCertFile,
            };

            if (this.trainingExpiryToggle) {
              const expiryDate = _.get(data, "trainingCertData[0].expiryDate");
              if (!expiryDate) {
                this.errorMessage = "Training Certificate details incomplete";
                this.loading = false;
                return;
              }
              trainingCertPayload["expiryDate"] = expiryDate;
            }
          }

          await this.$store.dispatch(`accreditation/uploadUserDocument`, {
            medCertPayload,
            trainingCertPayload,
          });

          this.closeModal();
          this.$forceUpdate();
          this.fetchTab(this.currentTab);
          this.loading = false;
          this.toast("success", "Documents uploaded successfully");
          this.resetFields();
        } catch (e) {
          this.closeModal();
          this.resetFields();
          this.toast("error", "Something went wrong");
          throw e;
        }
      } else {
        this.errorMessage = "No documents selected";
        return;
      }
    },

    resetFields() {
      this.clearInputFile("med");
      this.clearInputFile("training");
      this.medExpiryToggle = false;
      this.trainingExpiryToggle = false;
      this.medExpiryDate = false;
      this.trainingExpiryDate = false;
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    async onPageChange(event) {
      await this.init(event.page);
    },

    goBack() {
      this.$emit("goBack");
    },

    async fetchTab(data) {
      this.currentTab = data;
      await this.init();
    },

    async init(paged = 1) {
      this.loading = true;

      if (this.currentTab == "Accreditations") {
        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          filter: this.filter.search,
        };

        const fetchedCerts = await this.$store.dispatch(
          `accreditation/fetchAssociatedCertificates`,
          {
            id: this.driverInfo.driverId,
            status: query,
            query,
          }
        );

        if (fetchedCerts?.data?.data?.resultsMeta) {
          this.paginationSettings = formatPaginationSettings(
            fetchedCerts?.data?.data.resultsMeta
          );
        }

        if (fetchedCerts?.data?.data?.results) {
          this.certs = fetchedCerts.data.data.results;
        } else {
          this.certs = [];
        }
      } else if (this.currentTab == "Medical Certificates") {
        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          filter: this.filter.search,
        };

        query.filter = {
          ...this.filter,
          documentType: "DRIVER_MEDICAL",
        };

        const certs = await this.$store.dispatch(
          `accreditation/fetchUserDocuments`,
          {
            id: this.driverInfo.driverId,
            query,
          }
        );

        if (certs?.data?.data.resultsMeta) {
          this.paginationSettings = formatPaginationSettings(
            certs?.data?.data.resultsMeta
          );
        }

        if (certs?.data?.data?.results) {
          this.certs = certs?.data?.data.results;
        } else {
          this.certs = [];
        }
      } else if (this.currentTab == "Induction Letters") {
        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          filter: this.filter.search,
        };

        query.filter = {
          ...this.filter,
          documentType: "INDUCTION_LETTER",
        };

        const certs = await this.$store.dispatch(
          `accreditation/fetchUserDocuments`,
          {
            id: this.driverInfo.driverId,
            query,
          }
        );

        if (certs?.data?.data.resultsMeta) {
          this.paginationSettings = formatPaginationSettings(
            certs?.data?.data.resultsMeta
          );
        }

        if (certs?.data?.data) {
          this.certs = certs?.data?.data.results;
        } else {
          this.certs = [];
        }
      } else {
        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          filter: this.filter.search,
          status: this.isActive,
        };

        query.filter = {
          ...this.filter,
          documentType: "DRIVER_TRAINING",
        };

        this.loading = true;
        const certs = await this.$store.dispatch(
          `accreditation/fetchUserDocuments`,
          {
            id: this.driverInfo.driverId,
            query,
          }
        );
        if (certs?.data?.data.resultsMeta) {
          this.paginationSettings = formatPaginationSettings(
            certs?.data?.data.resultsMeta
          );
        }

        if (certs?.data?.data) {
          this.certs = certs?.data?.data.results;
        } else {
          this.certs = [];
        }
      }
      this.loading = false;
    },

    async toggleViewDocument() {
      this.$refs.modal.openModal();
      this.modalContent = "viewDocuments";
      this.modalTitle = "View Driver Documents";
      this.modalHeight = "50vh";
      this.modalSize = "sm";

      this.activeMedCert = false;
      this.activeTrainingCert = false;

      const activeMedCert = await this.$store.dispatch(
        `accreditation/fetchUserDocuments`,
        { id: this.driverInfo.driverId, file: "DRIVER_MEDICAL" }
      );
      const activeTrainingCert = await this.$store.dispatch(
        `accreditation/fetchUserDocuments`,
        { id: this.driverInfo.driverId, file: "DRIVER_TRAINING" }
      );
      if (activeMedCert.data.data.results) {
        this.activeMedCert = activeMedCert.data.data.results[0];
      }

      if (activeMedCert.data.data.results) {
        this.activeTrainingCert = activeTrainingCert.data.data.results[0];
      }
    },

    closeModal() {
      this.$refs.modal.closeModal();
    },

    async toggleDelinkCert() {
      this.$refs.modal.openModal();
      this.modalContent = "delinkCert";
      this.modalTitle = "Delink Certificate";
      this.modalHeight = "30vh";
      this.size = "1xl";
      this.certToDelete = this.certDetails?.id;
    },

    async toggleAddDocument() {
      this.$refs.modal.openModal();
      this.modalContent = "addDocuments";
      this.modalTitle = "Add Document";
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
      this.modalSize = "2xl";
    },

    async toggleRegenerate(data) {
      this.accreditationToRegenerate = data.id;
      this.$refs.modal.openModal();
      this.modalContent = "regenerate";
      this.modalTitle = "Regenerate Letter";
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
      this.modalSize = "2xl";
    },

    async delinkCert() {
      this.rendered = false;
      const inductionDate = new Date();
      const data = {
        driverId: this.driverInfo.driverId,
        inductionDate,
      };

      await this.$store.dispatch(`accreditation/unAssignDriver`, {
        data,
        certId: this.certToDelete,
      });

      this.rendered = true;
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    async debouncedSearchString() {
      await this.fetchTab(this.currentTab);
    },

    async filterChange() {
      await this.fetchTab(this.currentTab);
    },

    toggleViewCert(data) {
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
      this.modalSize = "2xl";

      this.selectedCert = data;
      this.$refs.modal.openModal();
      this.modalContent = "viewCerts";
      this.modalTitle = "Certificate Detials";
    },

    async regenerateInduction() {
      this.rendered = false;
      await this.$store.dispatch(`accreditation/regenerate`, {
        certId: this.certDetails.id,
        userId: this.driverInfo.id,
      });

      this.rendered = true;
      this.toast("success", "Letter regenerated");
    },

    async viewAllCerts(paged = 1) {
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: 10,
        filter: {},
      };

      const fetchedCerts = await this.$store.dispatch(
        `accreditation/fetchAssociatedCertificates`,
        {
          id: this.driverInfo.driverId,
          status: query,
          query,
        }
      );

      if (fetchedCerts?.data?.data?.results) {
        this.certs = fetchedCerts.data.data.results;
      }

      this.toggle = !this.toggle;

      this.$emit("updateLoading", false);
    },

    parseUserDocuments(data) {
      if (data) {
        return this.certs.map((docData) => {
          return [
            {
              id: docData._id,
              name: docData._id,
              itemType: "string",
              document: docData.document,
            },
            {
              id: docData._id,
              name: this.getDocStatus(docData.isActive),
              itemType: "string",
            },
            {
              id: docData._id,
              name: formatDate(docData.createdAt, "DD/MM/YYYY"),
            },
          ];
        });
      }
      return [];
    },

    parseAccreditation(data) {
      if (data) {
        return this.certs.map((docData) => {
          return [
            {
              id: docData._id,
              name: docData.certNumber,
              itemType: "string",
              document: docData,
            },
            {
              id: docData._id,
              name: docData.type,
              itemType: "string",
            },
            {
              id: docData._id,
              name: formatDate(docData.createdAt, "DD/MM/YYYY"),
              itemType: "string",
            },
          ];
        });
      }
      return [];
    },

    parseInduction(data) {
      if (data) {
        return this.certs.map((docData) => {
          return [
            {
              id: docData._id,
              name: docData.certNumber,
              itemType: "string",
              document: docData.document,
              isActive: docData.isActive,
            },
            {
              id: docData._id,
              name: docData.accreditationType,
            },
            {
              id: docData._id,
              name: this.getDocStatus(docData.isActive),
              itemType: "string",
            },
            {
              id: docData._id,
              name: formatDate(docData.createdAt, "DD/MM/YYYY"),
            },
          ];
        });
      }
    },

    getDocStatus(status) {
      switch (status) {
        case true:
          return "Active";
        case false:
          return "Inactive";
      }
    },
  },
};
</script>

<style scoped>
input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
</style>
