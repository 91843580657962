<template>
  <div class="h-screen w-full flex items-center justify-center">
    <div class="flex flex-col items-center">
      <div>{{ responseMessage }}</div>
      <div class="loading mt-2 mb-8 h-4">
        <ui-spinner class="mx-auto"></ui-spinner>
      </div>
      <div>Please wait...</div>
    </div>
  </div>
</template>

<script>
import CryptoJS from "crypto-js";
import VueCookies from "vue-cookies";
import { GEOTAB_COOKIE } from "@/_helper/constants";
import { isNil } from "lodash";

export default {
  name: "Geotab-Login-Page",
  data() {
    return {
      responseMessage: null,
    };
  },
  async mounted() {
    const routeParams = this.$route.params;

    if (!routeParams) return;

    if (VueCookies.keys().includes("accessToken")) {
      const result = await this.$store.dispatch("account/getMe");
      if (result) {
        return this.handleValidAccessToken(routeParams);
      } else {
        await VueCookies.keys().forEach(key => {
                VueCookies.remove(key)
        });
        return await this.handleInvalidAccessToken(routeParams);
      }
    } else {
      await this.handleInvalidAccessToken(routeParams);
    }
  },
  methods: {
    redirectToPage(routeParams) {
      setTimeout(() => {
        if (isNil(routeParams.accountId)) {
          window.location.href = `${window.location.origin}/${routeParams.page}`;
        } else {
          if (routeParams.subPage3) {
            window.location.href = `${window.location.origin}/${routeParams.page}/${routeParams.subPage}/${routeParams.subPage2}/${routeParams.subPage3}`;
            return;
          }

          if (routeParams.subPage2) {
            window.location.href = `${window.location.origin}/${routeParams.page}/${routeParams.subPage}/${routeParams.subPage2}`;
            return;
          }

          window.location.href = `${window.location.origin}/${routeParams.page}/${routeParams.subPage}`;
        }
      }, 1000);
    },

    handleValidAccessToken(routeParams) {
      console.log("Found accessToken");
      VueCookies.set(GEOTAB_COOKIE, true, null, null, null, true, "None");
      this.redirectToPage(routeParams);
    },

    async handleInvalidAccessToken(routeParams) {
      console.log("accessToken NOT found OR invalid");
      this.responseMessage = "Connecting to Logmaster account";
      const passphrase = "41Od9TB4o02AVy";
      const bytes = CryptoJS.AES.decrypt(
        routeParams.accountId || routeParams.subPage,
        passphrase
      );
      const token = bytes.toString(CryptoJS.enc.Utf8);

      const response = await this.$store.dispatch("account/authenticateToken", {
        uid: token,
      });

      if (!response.success) {
        this.responseMessage = "Invalid account! Please login manually!";
        if (VueCookies.keys()) {
          await VueCookies.keys().forEach((key) => {
            VueCookies.remove(key);
          });
        }
        setTimeout(() => {
          window.location.href = `/login?message= ${response.message}&state=error`;
        }, 1000);
      }

      if (response.success) {
        VueCookies.set(GEOTAB_COOKIE, true, null, null, null, true, "None");

        this.responseMessage = "Connection Successful! Redirecting...";

        this.redirectToPage(routeParams);
      }
    },
  },
};
</script>
