
import Dasboard from '@/pages/admin/Dashboard';
import Partners from '@/pages/admin/Partners';
import Resellers from '@/pages/admin/Resellers';
import ResellersLookUp from '@/pages/admin/ResellersLookUp';
import ReportJobs from '@/pages/admin/ReportJobs';
import Business from '@/pages/admin/Business';
import DriversLookUp from '@/pages/admin/DriversLookUp';
import Contracts from '@/pages/admin/Contracts';
import Leads from '@/pages/LeadsMain';
import ContractTermsForms from '@/pages/admin/ContractTermsForms'
import RecordKeeper from '@/pages/admin/RecordKeeper';
import Vehicles from '@/pages/admin/Vehicles';
import TechProvider from '@/pages/admin/TechProvider';
import Users from '@/pages/Users';
import Roles from '@/pages/Roles';
import DriverEventAnnotations from '@/pages/admin/DriversEventsAnnotations'
import Forms from '@/pages/admin/Forms'
import FormBuilderCreate from '@/pages/admin/FormBuilderCreate'
import FormBuilderEdit from '@/pages/admin/FormBuilderEdit'
import ContractTermsBuilderCreate from "@/pages/admin/ContractTermsBuilderCreate";
import ContractTermsBuilderEdit from "@/pages/admin/ContractTermsBuilderEdit";
import UninstallEvents from '@/pages/admin/UninstallEvents'
import Invoice from '@/pages/Invoice'
import JSONBuilder from '@/pages/admin/JSONBuilder'
import SoloLookUp from '@/pages/admin/SoloLookUp'
import EmailList from '@/pages/admin/EmailList'
import Marketplace from '@/pages/admin/marketplace/Marketplace.vue'
import MarketplaceSubscriptions from "@/pages/admin/marketplace/MarketplaceSubscriptions.vue";
import AdhocBillingTool from "@/pages/admin/billing/AdhocBillingTool.vue";
import { NhvrReport } from "@/pages/admin/reports";


export default [
    {
        path: '/',
        name: 'Dashboard - Admin',
        component: Dasboard
    },
    {
        path: 'partners',
        name: 'Partners',
        component: Partners
    },
    {
        path: 'resellers',
        name: 'Admin Resellers',
        component: Resellers
    },
    {
        path: 'reseller-lookup',
        name: 'Resellers Look Up',
        component: ResellersLookUp
    },
    {
        path: 'business-lookup',
        name: 'Business Lookup',
        component: Business
    },
    {
        path: 'driver-lookup',
        name: 'Driver Lookup',
        component: DriversLookUp,
        meta: {
            title: 'Drivers Lookup'
        }
    },
    {
        path: 'report-jobs',
        name: 'Report Jobs',
        component: ReportJobs
    },
    {
        path: 'contract-management',
        name: 'Contract Management',
        component: Contracts
    },
    {
        path: 'lead-management',
        name: 'Lead Management',
        component: Leads
    },
    {
        path: 'contracts/terms-forms/builder',
        name: 'Contracts Term Builder - Logmaster',
        component: ContractTermsBuilderCreate
    },
    {
        path: 'contracts/terms-forms/builder/:id/edit',
        name: 'Contracts Term Builder - Logmaster (Edit)',
        component: ContractTermsBuilderEdit
    },
    {
        path: 'contracts/terms-forms',
        name: 'Contract Term Forms',
        component: ContractTermsForms
    },
    {
        path: 'record-keeper-lookup',
        name: 'Record Keeper Lookup',
        component: RecordKeeper
    },
    {
        path: 'vehicle-lookup',
        name: 'Vehicle Lookup',
        component: Vehicles
    },
    {
        path: 'solo-lookup',
        name: 'Solo Lookup',
        component: SoloLookUp
    },
    {
        path: 'tech-providers',
        name: 'Tech Providers',
        component: TechProvider
    },
    {
        path: 'driver-events-annotations',
        name: 'Driver Events and Annotations',
        component: DriverEventAnnotations
    },
    {
        path: 'users',
        name: 'Admin Users',
        component: Users
    },
    {
        path: 'form/builder',
        name: 'Form Builder',
        component: FormBuilderCreate,
        meta: {
            title: 'Create Form'
        }
    },
    {
        path: 'forms/:formType',
        name: 'Admin Forms - Business',
        component: Forms,
    },
    {
        path: 'form/builder/:id/edit',
        name: 'Form Builder (Edit)',
        component: FormBuilderEdit
    },
    {
        path: 'roles',
        name: 'Admin Roles',
        component: Roles
    },
    {
        path: 'uninstall-events',
        name: 'All Uninstall Events',
        component: UninstallEvents
    },
    {
        path: 'invoices',
        name: 'Admin Invoices',
        component: Invoice
    },
    {
        path: 'adhoc-billing-tool',
        name: 'Adhoc Billing Tool',
        component: AdhocBillingTool
    },
    {
        path: 'json-builder',
        name: 'JSON Builder',
        component: JSONBuilder
    },
    {
        path: 'emaiL-list',
        name: 'Email List',
        component: EmailList
    },
    {
        path: 'services',
        name: 'Services',
        component: Marketplace
    },
    {
        path: 'services/subscription',
        name: 'Business Subscription',
        component: MarketplaceSubscriptions
    },
    {
        path: 'report/nhvr',
        name: 'NHVR Report',
        component: NhvrReport
    }
]