export default{
    
    updateLogo(state, logoURL){
        state.logoURL = logoURL
    },

    setLogoURLs(state, logoURLs){
        state.logoURLs = logoURLs
    },

    setPortalLogoURL(state, portalLogoURL){
        state.logoURLs.portalLogoURL = portalLogoURL
    },
    setThemeId(state, themeId){
        state.themeId = themeId
    },
    setDarkModeData(state, darkModeData){
        state.darkModeData = darkModeData
    }
    
}