<template>
  <div>
    <div class="my-5 overflow-x-auto bg-grey">
      <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
        <div  class="min-w-full leading-normal">
          <div class="grid grid-cols-2 lg:grid-cols-3 md:grid-cols-2">
            <div v-for="(datas, index) in data" :key="index" class="group w-200  p-4">
              <div class="p-8 mb-4 bg-white rounded shadow-md">
                <div v-for="(ndata, index) in datas" :key="index">
                  <div v-if="ndata">
                    <h4 class="text-center"><strong>{{ndata.name}}</strong></h4>
                    <small v-if="ndata.createdAt">Date Modefied: {{formCreatedAt(ndata.createdAt)}}</small>
                    <section class="mt-4" v-for="(form, index) in ndata.sections" :key="index">
                      <div class="mt-3" v-for="(control, index) in form.controls" :key="index">
                        <FieldContainer :component="control" />
                      </div>
                    </section>
                  </div>
                </div>
                <div class="text-black-primary whitespace-no-wrap text-lg mt-4 flex">
                  <a v-if="writeForms" class="flex cursor-pointer" title="Edit"
                    :href="`/business/form-builder/${datas[0].id}/edit`"
                    ><font-awesome-icon
                      icon="edit"
                      class="my-auto mx-1.5 hover:text-orange-primary"
                  /></a>
                  <button
                    v-else
                    class="flex opacity-20 cursor-not-allowed"
                    title="Not allowed to update form status."
                    disabled="true"
                    ><font-awesome-icon
                      icon="edit"
                      class="my-auto mx-1.5 hover:text-orange-primary"
                  /></button>
                  <button 
                      v-if="writeForms"
                      @click.prevent="updateFormStatus(datas[index - 1].id)" 
                      title="Activate / Deactivate Form" class="flex cursor-pointer block"
                    >
                      <font-awesome-icon 
                        v-if="datas.isActive"
                        :icon="['fas', 'toggle-on']"
                        class="my-auto mx-1.5 text-green-900"
                        size="lg"
                        >
                      </font-awesome-icon>
                      <font-awesome-icon 
                        v-else
                        :icon="['fas', 'toggle-off']"
                        class="my-auto mx-1.5"
                        size="lg"
                        >
                      </font-awesome-icon>
                  </button>
                  <button 
                      v-else
                      class="flex opacity-20 cursor-not-allowed"
                      title="Not allowed to update form status."
                      disabled="true"
                    >
                    <font-awesome-icon 
                      v-if="datas.isActive"
                      :icon="['fas', 'toggle-on']"
                      class="my-auto mx-1.5 text-green-900"
                      size="lg"
                      >
                    </font-awesome-icon>
                    <font-awesome-icon 
                      v-else
                      :icon="['fas', 'toggle-off']"
                      class="my-auto mx-1.5"
                      size="lg"
                      >
                    </font-awesome-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FieldContainer from '@/views/builder/containers/FieldContainer.vue';
import moment from 'moment'

export default {
  components: { FieldContainer },
  props: {
    data: {
      type: Array[{
        id: Number,
        name: String,
        itemType: String,
        hasImage: Boolean,
        imageUrl: String,
        hasLink: Boolean,
        sections: Array,
        histories: Array,
        createdAt: String
      }],
      default: function(){
        return []
      },
    },
    src: {
      type: String,
      default: ''
    },
    readFormVersions: {
      type: Boolean,
      default: false
    },
    writeForms: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return{
      seachKeyword: '',
    }
  },
  emits: ['cloneSavedForm', 'clonePrebuiltForm'],
  methods: {
    onPageChange (page) {
      this.paginationSettings.page = page
      // TODO: Set the api method here for page change
    },
    cloneSavedForm(id){
      this.$emit('cloneSavedForm', id)
    },
    clonePrebuiltForm(id){
      this.$emit('clonePrebuiltForm', id)
    },
    async updateFormStatus(id){
      const data = {
        id: id,
      }
      await this.$store.dispatch('form/updateFormStatus', data)
      .then((res) => {
        const index = this.data.filter(item => {
          return (item._id === res._id)
        })
        console.log('index--',index);

        this.data.splice(index, 1, res)
      }).catch(() => {
        // this.toast('error', err.message);
      })
    },
    formCreatedAt(createdAt){
      return moment(createdAt).format('LL');
    }
  },
};
</script>
