<template>
  <StyledDiv
    @click="addNew('normal')"
    class="bg-orange-primary text-white cursor-pointer text-sm rounded-md text-center w-100 px-4 py-2 my-7"
    :color="theme.primaryColor"
    :backgroundColor="'transparent'"
    :hoverColor="theme.secondaryColor"
    :borderColor="theme.primaryColor"
    :hoverBorderColor="theme.secondaryColor"
    :style="{backgroundColor: theme.secondaryColor}"
  >
    <button class="text-white">
      <font-awesome-icon icon="plus-circle"/>&nbsp;&nbsp; Add Section
    </button>

    <!-- Toggle content -->
    <div class="mt-4" v-show="show">
      <ul class="border border-gray-400 rounded-lg text-gray-900">
        <li
          class="w-full p-4 border-b border-gray-400 rounded-t-lg bg-white hover:bg-slate-100 d-flex flex-column"
          @click.prevent="addNew('normal')"
        >
          Normal Block
          <p class="text-sm text-gray-600 mt-1">Normal block with a headline</p>
        </li>
        <li
          class="w-full p-4 rounded-b-lg bg-white hover:bg-slate-100 d-flex flex-column"
          @click.prevent="addNew('toggle')"
        >
          Toggleable Block
          <p class="text-sm text-gray-600 mt-1">
            Section block with conditional toggle (display/hide) feature
          </p>
        </li>
      </ul>
    </div>
  </StyledDiv>
</template>
<script>
import { StyledDiv } from '@/styled-components'
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    show: false
  }),
  components: {StyledDiv},
  methods: {
    showOptions() {
      this.show = !this.show
    },
    addNew(type) {
      this.$emit('addSection', type)
    }
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>
