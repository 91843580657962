import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import state from './state'
export default{
    namespaced: true,
    actions,
    mutations,
    getters,
    state
}