<template>
  <div class="h-full">
    <div v-if="!loading" class="flex flex-col">
      <div class="mb-16 flex justify-center items-center">
        <ul class="text-sm font-medium text-center text-gray-500 rounded-lg divide-x divide-gray-200 shadow flex w-full lg:w-1/2">
          <template v-for="(contractDuration, index) in contractDurationOptions">
            <li class="w-full" :key="`contract-duration-option-${index}`">
              <button @click.prevent="selectContractDuration(contractDuration)" 
                class="transition duration-300 ease-in-out inlinke-block inline-block font-semibold p-4 w-full hover:bg-orange-primary hover:text-white active focus:outline-none"
                :class="[
                  contractDuration.label == 'Monthly' ? 'rounded-l-lg' : ' rounded-r-lg',
                  selectedContractDuration.label == contractDuration.label ? 'bg-orange-primary text-white' : 'bg-white text-neutral-dark'
                  ]">
                {{ contractDuration.label }}
              </button>
            </li>
          </template>
        </ul>
      </div>
      <FormulateForm v-model="values" @submit="onSubmit">
        <div class="flex flex-wrap justify-center -mx-4 mb-8" v-if="selectedContractDuration.label == 'Yearly'">
          <div class="lg:w-1/2 w-full">
            <FormulateInput
              placeholder="1"
              required
              label="Contract Duration in years"
              name="name"
              v-model="contractDurationInYears"
              validation="required|number|between:0,100"
              type="number"
              min="1"
              max="100"
              class="block h-12 w-full px-4 leading-tight relative"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="flex flex-wrap justify-center -mx-4">
          <div class="lg:w-1/2 w-full px-4 wrapper" v-for="(billingPeriod, index) in billingPeriodFilteredOptions" :key="`billing-period-option-${index}`">
            <div 
              class="
              rounded-xl 
              relative 
              z-10 
              overflow-hidden 
              border border-gray-400 border-opacity-20
              shadow-pricing
              mb-10
              ">
                <div v-if="selectedContractDuration.label == 'Yearly'" class="text-sm leading-none bg-orange-primary text-white font-semibold uppercase py-4 text-center tracking-wide">
                  Yearly Discounts
                </div>
                <div class="py-10 px-8 sm:p-12 lg:py-10 lg:px-6 xl:p-12">
                  <span class="text-black font-bold text-xl block mb-8">
                    {{ billingPeriod.label }}
                  </span>
                  <h2 class="font-bold text-black text-4xl">
                    ${{ billingPeriod.pricing.toFixed(2) }}
                  </h2>
                  <p class="mb-8">
                    <span v-if="billingPeriod.label == 'Monthly'" class="text-base text-gray-500 font-medium">
                    Per month + GST
                    </span>
                    <span v-else-if="billingPeriod.label == 'Yearly'" class="text-base text-gray-500 font-medium">
                    Per year + GST
                    </span>
                  </p>
                  <button 
                    @click.prevent="selectBillingPeriod(billingPeriod)" 
                    class="
                    w-full
                    block
                    text-base
                    font-semibold
                    border border-orange-primary
                    rounded-md
                    text-center
                    p-4
                    hover:text-white hover:bg-orange-primary hover:border-orange-primary
                    transition
                    "
                    :class="[
                      billingPeriod.label == selectedBillingPeriod.label ? 'bg-orange-primary text-white' : 'bg-white text-orange-primary'
                    ]"
                    >
                    <span v-if="billingPeriod.label == selectedBillingPeriod.label">
                      Plan Selected
                    </span>
                    <span v-else-if="billingPeriod.label != selectedBillingPeriod.label">
                    Choose {{ billingPeriod.label }}
                    </span>
                  </button>
                </div>
            </div>
          </div>
        </div>
        <div v-if="hasActivePaymentGateway && Object.keys(selectedBillingPeriod).length > 0">
            <div class="p-5 mt-5 mb-10 border rounded-lg group">
              <div class="group-title pb-5 font-bold text-lg">Bill Summary</div>
              <div>
                <div class="py-2 mx-auto mt-5 w-full w-full">
                  <div class="flex justify-between mb-3">
                    <div class="text-gray-800 text-left flex-1">Total incl. GST</div>
                    <div class="text-right w-40">
                      <div class="text-gray-800 font-medium" x-html="netTotal">${{ totals.totalGST.toFixed(2) }}</div>
                    </div>
                  </div>
                  <div class="flex justify-between mb-4">
                    <div class="text-sm text-gray-600 text-left flex-1">GST(10%) incl. in Total</div>
                    <div class="text-right w-40">
                      <div class="text-sm text-gray-600" x-html="totalGST">${{ totals.gst.toFixed(2) }}</div>
                    </div>
                  </div>
                  <div class="flex justify-between mb-4">
                    <div class="text-sm text-gray-600 text-left flex-1">Gateway Fee({{ paymentGateway.surchargePercentageFee }}% + ${{ paymentGateway.otherFees }})</div>
                    <div class="text-right w-40">
                      <div class="text-sm text-gray-600" x-html="totalGST">{{ totals.gatewayFee.toFixed(2) }}</div>
                    </div>
                  </div>
                
                  <div class="py-2 border-t border-b">
                    <div class="flex justify-between">
                      <div class="text-xl text-gray-600 text-left flex-1">Amount due</div>
                      <div class="text-right w-40">
                        <div class="text-xl text-gray-800 font-bold" x-html="netTotal">${{ totals.amountDue.toFixed(2) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mt-8">
                  <div class="text-md mb-2 text-gray-600 text-left flex-1">Card Details</div>
                  <stripe-element-card
                    class="mb-5"
                    ref="cardRef"
                    :pk="publishableKey"
                    @token="tokenCreated"
                    @element-change="updateSubmitButton"
                    @element-blur="cardChange"
                    :hidePostalCode="true"
                  />
              </div>
            </div>
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-full"
              :disabled="!cardCheck"
            >
            <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" />{{ buttonText }}</span>  
            <span v-if="!submitLoading">Subscribe</span>  
            </FormulateInput>
        </div>
      </FormulateForm>
      <Modal ref="processingPaymentModal" :title="modalTitle" size="3xl" :height="paymentModalHeight">
        <div class="flex flex-col items-center mx-4 my-4">
          <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-12 sm:w-12">
            <ui-spinner class="mx-auto"></ui-spinner>
          </div>
          <div class="text-center mt-4 w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Processing your payment</h3>
          </div>
          <div class="text-center mt-2">
              <p class="text-sm text-gray-500">Please wait while we process your payment request.</p>
          </div>
        </div>
      </Modal>
      <Modal ref="paymentSuccessfulModal" :title="modalTitle" size="3xl" :height="paymentModalHeight">
        <div class="flex flex-col items-center mx-4 my-4">
          <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-12 sm:w-12">
            <font-awesome-icon icon="check" class="h-6 w-6 text-green-800" />
          </div>
          <div class="text-center mt-4 w-full">
            <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Payment successful</h3>
          </div>
          <div class="text-center mt-2">
              <p class="text-sm text-gray-500">We have received your payment successfully. Please wait as we redirect you back to the main page.</p>
          </div>
        </div>
      </Modal>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
    </div>
    <div v-else class="text-center my-16">
      <ui-spinner class="mx-auto mb-2"></ui-spinner>
      Generating All Subscription Plan...
    </div> 
  </div>  
</template>

<script>
import {orderBy} from 'lodash'
import { Modal } from '@/components/commons'
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { mapGetters } from 'vuex'
import moment from 'moment';

export default {
  components: {
    StripeElementCard,
    Modal
  },
  props: {
    entityId: String,
    driverType: String,
    contractorData: Object
  },
  data () {
    return {
      selectedContractDuration: {},
      contractDurationOptions: [],
      billingPeriodOptions: [],
      billingPeriodFilteredOptions: [],
      selectedBillingPeriod: {},
      loading: false,
      submitLoading: false,
      corePlatformLicencesOption: [],
      pricingType: ['Base', 'Yearly Discount'],
      hasActivePaymentGateway: false,
      publishableKey: null,
      paymentGatewayId: null,
      paymentGateway: {},
      token: null,
      totals: {
        subTotal: 0,
        totalGST: 0,
        gst: 0,
        gatewayFee: 0,
        amountDue: 0
      },
      me: {},
      cplValue: [],
      values: {},
      paymentModalHeight: '20vh',
      modalTitle: "",
      cardCheck: false,
      contract: {},
      contractDurationInYears: 1,
      buttonText: "Saving data...",
      errorMessage: ""
    }
  },
  async mounted() {
    this.init()
  },

  methods: {
    updateSubmitButton() {
      this.submitLoading = true
      this.cardCheck = false
      this.buttonText = 'Validating Card Details...'
    },
    cardChange() {
      this.$refs.cardRef.submit();
    },
    tokenCreated(token) {
      this.token = token.id;
      if(this.token) {
        this.submitLoading = false
        this.cardCheck = true
        this.buttonText = 'Saving data...'
      }
    },
    async init() {
      const me = await this.$store.getters[`account/me`];
      this.me = me;
      console.log(this.entityId)
      const paymentGateway = await this.$store.dispatch("payment/getActivePaymentGatewayByEntityId", this.entityId);
      if (paymentGateway) {
        this.paymentGatewayId = paymentGateway[0]._id;
        this.publishableKey = paymentGateway[0].apiKey;
        this.hasActivePaymentGateway = true;
        this.paymentGateway = paymentGateway[0];
      }
      this.loading = true;
      const contractDuration = await this.$store.dispatch('lookup/getContractDurationOptions')
      if(contractDuration) {
        this.contractDurationOptions = contractDuration.map((options) => ({
          value: options._id,
          label: options.name
        }))
        .filter((options) => ['Monthly', 'Yearly'].includes(options.label))

        this.contractDurationOptions = await orderBy(this.contractDurationOptions, 'label', 'asc');
        this.selectedContractDuration = this.contractDurationOptions[1]
        console.log(this.selectedContractDuration)

        console.log('contract-duration--', this.contractDurationOptions)
      }
      const pricingSchema = await this.$store.dispatch(`standardpricing/getContractModuleMaster`);
      if(pricingSchema) {
        const driverCPL = ['EWD-FT'];

        const corePlatformLicence = pricingSchema.filter((s) => driverCPL.includes(s.name))

        this.corePlatformLicencesOption = corePlatformLicence.map((values) => ({
          id: values._id,
          name: values.name === 'EWD-FT' ? 'EWD' : values.name,
          numberOfSeats: 1,
        }))
      }

      if(contractDuration){
        await this.getPricing();
      }
    },

    async getPricing() {
      this.loading = true
      const me = this.$store.getters[`account/me`];
      let rolesParent = null

      if(me.driver?.parentRole == 'partner' || this.driverType == 'contractor') {
        rolesParent = await this.$store.dispatch('partner/getPartner', { id: this.entityId })
      }

      else if (me.driver?.parentRole == 'reseller') {
        rolesParent = await this.$store.dispatch('reseller/getReseller', { id: this.entityId })
      }

      if(rolesParent) {
        this.activeRRPBusiness = await this.$store.dispatch('standardpricing/getActiveRRPforBusiness', { id: rolesParent._id })

        if(this.activeRRPBusiness) {
          console.log(this.activeRRPBusiness)

          const cplValue = this.activeRRPBusiness.filter((rrp) => rrp.name == 'EWD-FT' || rrp.name == 'EWD - Fulltime');
          this.cplValue = cplValue;
          console.log(cplValue)

      
          const billingPeriod = await this.$store.dispatch('lookup/getBillingPeriodOptions')
          if(billingPeriod){
            this.billingPeriodOptions = billingPeriod.map((options) => ({
              value: options._id,
              label: options.name
            }))
            .filter((options) => ['Monthly','Yearly'].includes(options.label))      
          if(this.selectedContractDuration.label == 'Yearly') {
            this.billingPeriodOptions = this.billingPeriodOptions.map((billing) => {
              if(billing.label == 'Yearly') {
                return {...billing, pricing: Number(cplValue[0].pricing.yearly) }
              } 
              else if (billing.label == 'Monthly') {
                return {...billing, pricing: Number(cplValue[0].pricing.yearly) / 12 }
              }
            })
          } else if (this.selectedContractDuration.label == 'Monthly') {
            this.billingPeriodOptions = this.billingPeriodOptions.map((billing) => {
              if(billing.label == 'Yearly') {
                return {...billing, pricing: Number(cplValue[0].pricing.monthly) * 12 }
              } 
              else if (billing.label == 'Monthly') {
                return {...billing, pricing: Number(cplValue[0].pricing.monthly) }
              }
            })
          }

          this.billingPeriodOptions = orderBy(this.billingPeriodOptions, 'label', 'asc')
          this.billingPeriodFilteredOptions = this.billingPeriodOptions.filter(item => item.label !== 'Monthly')
          this.loading = false;
          }
        }
      }
    },

    async selectContractDuration(contractDuration) {
      this.selectedBillingPeriod = {}
      this.selectedContractDuration = contractDuration
    },

    async selectBillingPeriod(billingPeriod) {
      this.selectedBillingPeriod = billingPeriod
      let subTotal = this.selectedBillingPeriod.pricing

      const gst = (subTotal / 100) * this.paymentGateway.gstPercentageFee
      const totalGST = subTotal + gst
      const gatewayFee = ((totalGST / 100) * this.paymentGateway.surchargePercentageFee) + this.paymentGateway.otherFees

      const amountDue = totalGST + gatewayFee

      this.totals = {
        subTotal: subTotal,
        totalGST: totalGST,
        gst: gst,
        gatewayFee: gatewayFee,
        amountDue: amountDue
      }
    },

    async onSubmit() {
      this.submitLoading = true
      this.cardCheck = true
      const mergedModulesvalues = [...this.cplValue]
      console.log(mergedModulesvalues)
      const formatPayloadModules = mergedModulesvalues.map(() => ({
        minimums: 1,
        price: {
          monthly: this.selectedBillingPeriod.label === 'Monthly' ? Number(this.selectedBillingPeriod.pricing) : 0,
          quarterly: 0, //Zero for now as we don't support it yet
          yearly: this.selectedBillingPeriod.label === 'Yearly' ? Number(this.selectedBillingPeriod.pricing) : 0,
        },
        masterId: this.corePlatformLicencesOption[0].id,
        ownerMongoId: this.me?.driver?._id,
        ownerRole: 'driver',
      }))

      console.log(formatPayloadModules)
      const payload = {
        billingPeriodId: this.selectedBillingPeriod.value,
        businessMongoId: this.me?.driver?._id,
        businessModulesDto: formatPayloadModules,
        contractDurationId: this.selectedContractDuration.value,
        contractDurationYears: this.selectedContractDuration.label == 'Yearly' ? parseInt(this.contractDurationInYears) : 0,
        activationOption: 'recurring_payment',
        contractTarget: 'solo'
      }

      payload.paymentDetails = {
        entityId: this.me?.driver?._id,
        entityRole: 'driver',
        paymentGatewayId: this.paymentGatewayId,
        cardToken: this.token,
        isDefault: true,
        isActive: true
      }
      await this.$store.dispatch('contract/createContractAndPayment', payload)
        .then((response) => {
          if (response) {
            this.contract = response.data
            console.log(this.contract)
            this.chargeCard();
          }
        })
        .catch((error) => {
          this.submitLoading = false
          this.cardCheck = false
          this.errorMessage = error?.message
        })
    },

    async chargeCard() {
      this.$refs.processingPaymentModal.toggleModal();
      this.$store.dispatch("payment/chargeCard", 
        {
          entityId: this.me?.driver?._id,
          entityRole: 'driver',
          amount: this.totals.subTotal,
          parentId: this.entityId
        }
      ).then((response) => {
          if(response) {
            this.$store.dispatch(`contract/acceptContract`, this.contract._id)
              .then(async (response) => {
                if(response) {
                  if(this.driverType == 'contractor') {
                    const newContract = {
                      from: "driver",
                      driverId: this.me?.driver?._id,
                      businessId: this.me?.driver?.entityId,
                      profile: {
                        BFM2up: false,
                        BFMSolo: false,
                        baseTimeZone: this.contractorData.baseTimeZone,
                        baseLocation: this.contractorData.baseLocation,
                        recordKeeperAddress: this.contractorData.recordKeeperAddress,
                        engineRegion: this.contractorData.engineRegion,
                      },
                      startDate: moment(),
                      isContractor: true,
                    }
                    await this.$store.dispatch(`business/createContract`, newContract).then(
                      async () => {
                        await this.$store.dispatch(`driver/updateContractorStatus`, {id: this.contractorData._id, status: 'active_contractor'})
                        this.$refs.processingPaymentModal.closeModal();
                        this.$refs.paymentSuccessfulModal.toggleModal();
                        setTimeout(() => {
                          this.$refs.paymentSuccessfulModal.closeModal();
                          this.submitLoading = false
                          this.cardCheck = false
                          this.$emit('closeModal')
                          this.$store.commit('setDialogNotify',true);
                          this.$store.commit('setMessageNotify',{state:'success',message:'Solo successfully created.'});
                          setTimeout(()=>{location.reload()},4000);
                        }, 2500);
                      },
                      (error) => {
                        this.$refs.processingPaymentModal.closeModal();
                        this.$refs.paymentSuccessfulModal.closeModal();
                        this.submitLoading = false
                        this.cardCheck = false
                        this.errorMessage = error?.message
                      }
                    );
                  } else {
                    this.$refs.processingPaymentModal.closeModal();
                    this.$refs.paymentSuccessfulModal.toggleModal();
                    setTimeout(() => {
                      this.$refs.paymentSuccessfulModal.closeModal();
                      this.submitLoading = false
                      this.cardCheck = false
                      this.$emit('closeModal')
                      this.$store.commit('setDialogNotify',true);
                      this.$store.commit('setMessageNotify',{state:'success',message:'Solo successfully created.'});
                      setTimeout(()=>{location.reload()},4000);
                    }, 2500);
                  }
                }
              })
          }
      }).catch((error) => {
          this.$refs.processingPaymentModal.closeModal();
          this.$refs.paymentSuccessfulModal.closeModal();
          this.submitLoading = false
          this.cardCheck = false
          this.errorMessage = error?.message
        });
    }

  },

  watch: {
    selectedContractDuration: {
      async handler() {
        if(this.selectedContractDuration) {
          await this.getPricing();
          this.billingPeriodFilteredOptions = this.billingPeriodOptions.filter((value) => value.label === this.selectedContractDuration.label)
        }
      }
    }
  },
  computed: {
     ...mapGetters('theme', {
    theme: 'getColorScheme'
    })
  },
}
</script>

<style>
  .box {
    position: relative;
    width: 500px;
    height: 350px;
    background-color: rgb(245, 69, 69);
    overflow: hidden;
  }

  .ribbon {
    position: absolute;
    display: inline-block;
    top: 0.3em;
    right: 0.8em;
    max-width: 5em;
    color: #fff;
    z-index: 1;
  }
  .ribbon::after {
    position: absolute;
    top: -1.5em;
    right: -6em;
    content: "";
    
    height: 5em;
    width: 15em;
    
    transform: rotatez(45deg);

    background-color: red;
    z-index:-1;
  }
</style>