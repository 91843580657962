<template>
    <StyledIconWrapper :svgWidth="width" :svgHeight="height" :fillColor="color">
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M704,610H813.68v40h-38c36.44,66.31,118.1,114.8,209.06,104.72,106.76-11.84,191.2-103.36,191.53-214.71H1216v16c-.29,1.45-.64,2.9-.87,4.36-2,13.13-3.3,26.44-6.22,39.38-22.11,98.07-104.51,175.71-203.82,192.38-9.32,1.56-18.72,2.6-28.09,3.88H946c-6.27-.73-12.56-1.29-18.8-2.21Q813,776.81,747.7,681.63c-1-1.39-2-2.73-3-4.1l-.91.4V720H704Z" transform="translate(-704 -284)"/><path d="M1216,470H1106.31V430.14H1146a209.73,209.73,0,0,0-54.87-62.08c-42-31.78-89.63-46.39-142.1-43.75-115,5.79-205.19,101.23-205.3,215.69H704V524c.29-1.45.64-2.89.86-4.35,1.7-11.52,2.79-23.16,5.14-34.53,20.69-99.85,104.94-180.66,205.39-197.18,9.49-1.56,19.07-2.64,28.61-3.94h32c1.61.3,3.21.68,4.83.9,9.06,1.22,18.24,1.88,27.18,3.68,69.94,14.08,124.92,50.81,165.2,109.64.66,1,1.5,1.8,3,3.6V360H1216Z" transform="translate(-704 -284)"/><path d="M962.08,642q-37.74,0-75.47,0c-26.24-.05-44.56-18.41-44.6-44.7q-.06-42.74,0-85.46c0-26.6,18.31-45.4,45-44.49,11.39.39,17.46-2.45,21.51-13.61,4.34-12,14.83-17.55,27.69-17.64q26.73-.17,53.47,0c12.86.1,23.34,5.77,27.59,17.76,4,11.34,10.39,13.79,21.6,13.48,24.38-.7,43,18.43,43.11,42.84q.22,44.22,0,88.46c-.11,24.67-18.74,43.22-43.43,43.33C1013.05,642.05,987.56,642,962.08,642Zm-.17-40c24.83,0,49.66,0,74.49,0,3.51,0,5.67-.56,5.66-4.84q-.16-42.75,0-85.48c0-3.45-1.34-4.79-4.75-4.72q-9.75.21-19.5,0c-12.83-.27-22.17-6.19-27.87-17.73-1.62-3.28-3.27-6.56-4.56-10-1-2.65-2.55-3.35-5.27-3.29-7.66.19-15.41.79-23-.11-11.17-1.32-17.65,1.82-21.67,13.31-4.19,12-14.72,17.58-27.58,17.81-6.67.11-13.34.26-20-.06-4.7-.23-6,1.57-6,6.09.16,27.5.08,55,.08,82.48,0,6.39.08,6.47,6.43,6.47Z" transform="translate(-704 -284)"/><path d="M961.88,578A30,30,0,1,1,992,548.36,30,30,0,0,1,961.88,578Z" transform="translate(-704 -284)"/></svg>
    </StyledIconWrapper>
</template>

<script>
import {StyledIconWrapper} from './styled'
export default {
    name: 'Refresh-Image-Icon',
    components: {StyledIconWrapper},
    props: {
        width:{
            type: String,
            default:'100%'
        },
        height: {
            type: String,
            default: '100%'
        },
        color: {
            type: String,
        }
    }
}
</script>