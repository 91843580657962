export default [
  {
    type: 'password',
    name: 'password',
    label: 'Enter a Password',
    validation: 'required'
  },
  {
    type: 'password',
    name: 'confirmPassword',
    label: 'Confirm your password',
    validation: '^required|confirm:password',
    validationName: 'Password confirmation'
  }
]