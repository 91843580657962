<template>
  <FormulateForm v-model="activeFormData" name="formBuilder" class="w-full" @submit="submitForm">
    <div class="w-full mb-16" v-if="activeTerm">
      <div class="px-5 mt-4 pb-8 mb-4">
        <FormulateInput
          type="text"
          name="name"
          placeholder="Name"
          label="Name"
          autocomplete="off"
          />
        <button type="submit" class="btn btn-primary w-full mt-3"
        :style="{backgroundColor: theme.secondaryColor}"
        >
          <span class="formulate-input-element--submit--label">Save Changes</span>
        </button>
      </div>
    </div>
  </FormulateForm>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    components: {},
    props: {
      activeTerm: {
        type: Object,
        default: () => {}
      },
      editMode: {
        type: Boolean,
        required: false,
        default: false,
      },
      isDisplayValue: {
        type: Boolean,
        required: false,
        default: false,
      }
    },
    emits: ['formSubmitted'],
    computed: {
      activeFormData: {
        get(){
          return this.activeTerm
        },
        set(newSavedForm){
          return newSavedForm;
        } 
      },
      ...mapGetters('theme', {
        theme: 'getColorScheme'
      })
    },
    data() {
      return {
        submitting: false,
      };
    },
    methods: {
      submitForm(data) {
        this.submitting = true;
        const sections = this.$store.state.form.formSections;
        const form = {
          _id: this._id || null,
          name: data.name,
          sections: sections
        }

        if(this.editMode){
          this.$store.dispatch('contract/updateContractTerms', form)
            .then((res) => {
              this.$emit('formSubmitted', res);
              this.submitting = false;
            }).catch(() => {
              this.submitting = false;
            })
        } else {
          this.$store.dispatch('contract/createContractTerms', form)
            .then((res) => {
              this.$emit('formSubmitted', res);
              this.submitting = false;
            }).catch(() => {
              this.submitting = false;
            })
        }
      }
    },
  }
</script>