<template>
  <StyledTableFooterDiv
    :buttonColor="theme.accentColor"
    :buttonColorHover="theme.accentColor"
    :buttonCountColor="theme.primaryColor"
    :buttonCountColorHover="theme.accentColor"
  >
    <div class="px-5 pt-4 border-t flex flex-row items-center justify-between">
      <div>
        <span class="text-sm" :styled="{ color: theme.accentColor }">
          Showing
          <span class="font-medium">{{ perPage }}</span>
          of
          <span class="font-medium">{{ totalRecords }}</span>
          Entries
        </span>
      </div>
      <div class="flex items-center mt-2 xs:mt-0">
        <button
          class="prev text-sm font-semibold py-2 px-4 rounded-l cursor-pointer border-t border-l border-b"
          :style="{ backgroundColor: theme.accentColor }"
          @click="onClickPreviousPage"
          :class="
            isFirstPage || isLoading
              ? 'disabled cursor-not-allowed'
              : 'hover:bg-gray-400'
          "
          :disabled="isFirstPage || isLoading"
        >
          Prev
        </button>
        <button
          :key="page.id"
          v-for="page in pages"
          class="pageNumber text-white text-sm font-semibold py-2 px-4 border cursor-pointer"
          @click="onClickPage(page.name)"
          :disabled="page.isDisabled && isLoading"
          :class="[
            isPageActive(page.name)
              ? 'bg-logmaster-400 text-white'
              : 'text-gray-800',
            isLoading
              ? 'disabled cursor-not-allowed'
              : 'hover:bg-logmaster-400 hover:text-white',
          ]"
          :style="{
            backgroundColor: isPageActive(page.name)
              ? theme.accentColor
              : 'white',
            color: isPageActive(page.name) ? 'white' : theme.accentColor,
          }"
        >
          {{ page.name }}
        </button>
        <button
          class="next text-sm font-semibold py-2 px-4 rounded-r cursor-pointer border-t border-r border-b"
          :style="{ backgroundColor: theme.accentColor }"
          @click="onClickNextPage"
          :class="
            isLastPage || isLoading
              ? 'disabled cursor-not-allowed'
              : 'hover:bg-gray-400'
          "
          :disabled="isLastPage || isLoading"
        >
          Next
        </button>
      </div>
    </div>
    <div class="px-5 pb-4 flex flex-row items-center justify-between">
      <div>
        <span class="text-sm" :styled="{ color: theme.accentColor }">
          Showing
          <span class="font-medium">{{ currentPage }}</span>
          of
          <span class="font-medium">{{ totalPages }}</span>
          Pages
        </span>
      </div>
    </div>
    <div
      v-if="paginationDescription"
      class="px-5 pb-4 flex flex-row items-center justify-between"
    >
      <div>
        <i class="text-sm" :styled="{ color: theme.accentColor }">
          {{ paginationDescription }}
        </i>
      </div>
    </div>
  </StyledTableFooterDiv>
</template>

<script>
import { StyledTableFooterDiv } from "./styled";
import { mapGetters } from "vuex";

export default {
  name: "Pagination",
  components: { StyledTableFooterDiv },
  props: {
    //This will show the current page
    currentPage: {
      type: Number,
      required: true,
    },
    //This will show the total page
    totalPages: {
      type: Number,
      required: true,
    },
    //This will show the available page items
    visiblePageItemCount: {
      type: Number,
      required: false,
      default: 5,
    },
    //This will show the total records count
    totalRecords: {
      type: Number,
      required: true,
    },
    //This will show the total records per page
    perPage: {
      type: Number,
      required: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },

    paginationDescription: {
      type: String,
      required: false,
    },
  },
  computed: {
    isFirstPage() {
      return this.currentPage === 1;
    },
    isLastPage() {
      if (this.totalPages === 0) {
        return true;
      }
      return this.currentPage === this.totalPages;
    },
    startPage() {
      //Set start page to initial page
      if (this.currentPage === 1) {
        return 1;
      }

      //Set start page to initial page if the max visible button is less than the total pages
      if (this.totalPages < this.visiblePageItemCount) {
        return 1;
      }

      //Set start page to total page count page if the current page and total page is equal
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.visiblePageItemCount + 1;
      }

      //Fallback
      return this.currentPage - 1;
    },
    lastPage() {
      //Set last page to initial page if the total value is 0
      if (this.totalPages === 0) {
        return 1;
      }
      //Set last page to total page if the total page is less than the set max visible button
      if (this.totalPages < this.visiblePageItemCount) {
        return this.totalPages;
      }
      //Fallback
      return Math.min(
        this.startPage + this.visiblePageItemCount - 1,
        this.totalPages
      );
    },
    pages() {
      const range = [];
      for (let i = this.startPage; i <= this.lastPage; i++) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage,
        });
      }
      return range;
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  methods: {
    onClickNextPage() {
      if (this.isLastPage) {
        return false;
      }
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickPreviousPage() {
      if (this.isFirstPage) {
        return false;
      }
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      this.$emit("pagechanged", page);
    },
    isPageActive(page) {
      return this.currentPage === page;
    },
    /* 
        TODO: Use this if first and last page button will be required
        onClickFirstPage() {
           if(this.isFirstPage){
               return false
           }
           this.$emit('pagechanged', 1)
       },
       onClickLastPage() {
           if(this.isLastPage){
               return false
           }
           this.$emit('pagechanged', this.totalPages)
       }, */
  },
};
</script>
