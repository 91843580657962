<template>
  <div class="w-full">
    <div v-if="!isSuccess" class="h-full w-full">
      <div v-if="!isLoading" class="flex items-center p-4 bg-gray-100 rounded">
        <font-awesome-icon icon="info-circle" size="2x" class="text-blue" />
        <strong class="ml-1 uppercase font-semibold text-sm"
          >Search a compliance report.</strong
        >
      </div>
      <div
        v-if="isLoading"
        class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center"
      >
        <font-awesome-icon icon="cog" spin size="4x" class="text-gray-400" />
        <h6 class="mt-4">Searching driver details...</h6>
      </div>
    </div>
    <div
      v-if="isSuccess"
      class="flex w-full items-center driver-info-container"
    >
      <div class="flex-1 flex">
        <div class="driver-photo">
          <div
            class="h-20 w-20 bg-gray-200 border rounded-full overflow-hidden border-3 border-gray-300 flex"
          >
            <font-awesome-icon
              icon="user"
              class="text-4xl m-auto text-gray-600"
            />
          </div>
        </div>
        <div class="flex-initial w-full px-4 driver-details">
          <span class="uppercase font-semibold flex items-center">
            <strong class="mr-1">{{ driverData.driverName || 'N/A' }}</strong>
            <span
              class="text-white text-xs mr-2 px-2 rounded"
              :style="{ backgroundColor: theme.secondaryColor }"
              >{{
                driverData['fatiguePlan'] ||
                (driverData?.isNonEWD ? 'NON-EWD' : '')
              }}</span
            >
          </span>
          <div class="grid grid-cols-2 gap-x-4 mt-1 max-w-lg">
            <div class="text-gray-500 text-sm inline">
              <span
                ><strong class="text-xs mr-1">Licence #:</strong
                >{{ driverData.driversLicenseNumber || 'N/A' }}</span
              >
            </div>
            <div class="text-gray-500 text-sm inline">
              <span
                ><strong class="text-xs mr-1">Vehicle:</strong
                >{{ driverData['vehicle'] || 'N/A' }}</span
              >
            </div>
            <div class="inline">
              <span
                class="text-blue text-xs font-semibold cursor-pointer hover:underline"
                @click="goToLogs()"
                >More Details</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSuccess" class="w-full mt-5 compliance-tabs-wrapper">
      <Tabs clickEvent @click="setTab" ref="childTabs">
        <Tab title="Graphical">
          <div class="mt-5 tab-content">
            <GraphicalSummary
              :data="graphData"
              id="graphicalSummary"
              ref="childGraphicalSummary"
              @toggleEndOfDay="toggleEndOfDay"
            />
          </div>
        </Tab>
        <Tab title="Events">
          <div class="mt-5 tab-content">
            <Event :data="eventData" />
          </div>
        </Tab>
        <Tab title="Breaches">
          <div class="mt-5 tab-content">
            <Breach :data="breachData" />
          </div>
        </Tab>
        <Tab title="Annotations">
          <div class="mt-5 tab-content">
            <Annotation :data="annotationData" />
          </div>
        </Tab>
        <Tab title="Logs">
          <div class="mt-5 tab-content">
            <Log
              :driver="driverData"
              :graphDetails="ewdDetails"
              :logs="logs"
              :reportName="'EWD'"
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { getStateSubInAddress } from '@/_helper';
import Tab from '@/components/commons/tab/Tab';
import Tabs from '@/components/commons/tab/Tabs';
import LogmasterGraph from '@logmaster/logmaster-nz-graph';
import LocationData from '@/assets/location/location.json';
import GraphicalSummary from './tabs/GraphicalSummary';
import Event from './tabs/Event';
import Breach from './tabs/Breach';
import Annotation from './tabs/Annotation';
import Log from './tabs/Log';

export default {
  name: 'Compliance',
  components: { Tabs, Tab, GraphicalSummary, Event, Breach, Annotation, Log },
  props: {
    selectedDateRange: Array,
    selectedDate: String,
    selectedDriver: Object,
    disabledBefore: String,
    disabledAfter: String,
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      logs: {
        recordKeeper: [],
        base: [],
        accreditation: [],
        device: [],
        license: [],
      },
      ewdDetails: {
        approvalHolder: 'Logmaster Pty Ltd',
        approvalNumber: 'LOLO-2022-001',
        systemName: 'Logmaster Australia',
        systemNumber: 'N/A',
      },
      elGraph: null,
      activeTab: null,
      driverData: {},
      graphData: '',
      eventData: [],
      breachData: [],
      annotationData: [],
      breaches: [],
      endOfDays: [],
      includeBreach: false,
      showEndOfDay: false
    };
  },
  methods: {
    setTab(tab) {
      this.activeTab = tab;
      if (tab == 'Graphical') this.generateLabel();
      this.$emit('updateActiveTab', tab);
    },
    canSearch() {
      return (
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },
    goToLogs() {
      this.activeTab = 'Logs';
      this.$emit('updateActiveTab', 'Logs');
      this.$refs.childTabs.selectTab(4);
    },
    formatEvent(events) {
      let formattedEvents = [];
      events.forEach((event) => {
        const location = !_.isEmpty(event['location']['address'])
          ? event['location']['address']
          : event['location']['formatted_address'];
        const rawLocation = getStateSubInAddress(location);
        event['suburb'] = !_.isEmpty(rawLocation)
          ? rawLocation['suburb']
          : null;
        event['locationAddress'] = !_.isEmpty(event['suburb'])
          ? event['suburb']
          : location;

        let tampered = [];
        if (
          _.isBoolean(event['location']['gpsTampered']) &&
          event['location']['gpsTampered']
        )
          tampered.push('GPS off');
        if (
          _.isBoolean(event['location']['mockTampered']) &&
          event['location']['mockTampered']
        )
          tampered.push('Mock GPS');
        if (_.isBoolean(event['timeTampered']) && event['timeTampered'])
          tampered.push('Time');
        event['tampered'] = tampered.join(', ');

        if (_.isEmpty(event['origin'])) event['origin'] = 'EWD';
        const timezone =
          !_.isEmpty(event['timezone']) && event['timezone'].length > 1
            ? event['timezone'].split(' ')[1]
            : event['timezone'];
        event['offset'] = timezone ? timezone : '+10:00';
        event['timeOfActivity'] = moment(event.startTimeWithTimezone)
          .utcOffset(event['offset'])
          .format('HH:mm');

        formattedEvents.push(event);

        event.metadata.forEach((metadata) => {
          const metadataObject = metadata['metadata'];
          if (
            metadataObject &&
            !_.isEmpty(metadataObject['modified-link-profile'])
          ) {
            const linkProfile = metadataObject['modified-link-profile'];
            let accreditationLog = null;
            if (!_.isEmpty(linkProfile['BFMNumber'])) {
              accreditationLog = {
                accreditationType: 'BFMNumber',
                accreditationNumber: linkProfile['BFMNumber'],
              };
            }
            if (!_.isEmpty(linkProfile['AFMNumber'])) {
              accreditationLog = {
                accreditationType: 'AFMNumber',
                accreditationNumber: linkProfile['AFMNumber'],
              };
            }

            const offset = events[events.length - 1]['timezone'].split(' ')[1];
            metadata.createdAt =
              events.length > 0
                ? events[0]?.startTimeWithTimezone
                : moment(metadata.createdAt).utcOffset(offset).format();
            const accreditationIndex = _.findIndex(
              this.logs.accreditation,
              (o) => _.isMatch(o, accreditationLog)
            );
            if (accreditationIndex == -1)
              this.logs.accreditation.push({
                ...accreditationLog,
                effectiveFrom: metadata.createdAt,
              });

            const recordKeeperLog = {
              location: linkProfile['recordKeeperAddress'],
            };
            const recordKeeperIndex = _.findIndex(this.logs.recordKeeper, (o) =>
              _.isMatch(o, recordKeeperLog)
            );
            if (recordKeeperIndex == -1)
              this.logs.recordKeeper.push({
                ...recordKeeperLog,
                effectiveFrom: metadata.createdAt,
              });

            const baseLocationLog = {
              location: linkProfile['baseLocation'],
              timeZone: linkProfile['baseTimeZone'],
            };
            const baseLocationIndex = _.findIndex(this.logs.base, (o) =>
              _.isMatch(o, baseLocationLog)
            );
            if (baseLocationIndex == -1)
              this.logs.base.push({
                ...baseLocationLog,
                effectiveFrom: metadata.createdAt,
              });
          }
        });
      });

      formattedEvents = _.orderBy(
        formattedEvents,
        ['startTimeWithTimezone', 'originalStartTime'],
        ['desc', 'desc']
      );
      return formattedEvents;
    },
    async getGraph(previousEvent = null) {
      let filteredEvents = this.eventData;
      filteredEvents = filteredEvents.filter(
        (event) => event?.status && event?.status.toLowerCase() != 'deleted'
      );
      if (!_.isEmpty(filteredEvents) && !previousEvent)
        previousEvent = filteredEvents[0];
      const activityData = {
        events: filteredEvents.reverse(),
        breaches: this.breaches.map((breach) => breach.time),
        annotations: this.annotationData,
      };

      this.elGraph = new LogmasterGraph(LocationData);
      this.graphData = this.elGraph.output(
        activityData,
        this.selectedDate,
        previousEvent
      );

      if (this.activeTab == 'Graphical') this.generateLabel();
    },
    generateLabel() {
      setTimeout(() => {
        const graphWrapper = document.getElementById('graphicalSummary');
        this.elGraph.generateEventLabel(graphWrapper, -2, -2, -2);
      }, 100);
    },
    async onSearch(
      selectedDriverId = null,
      selectedDate = null,
      activeTab = 'Graphical'
    ) {
      if (!selectedDriverId) selectedDriverId = this.selectedDriverId;
      if (!selectedDate) selectedDate = this.selectedDate;

      this.isLoading = true;
      this.isSuccess = false;

      const requestData = {
        driverId: selectedDriverId,
        from: moment(selectedDate, 'YYYY-MM-DD').format(),
        to: moment(selectedDate, 'YYYY-MM-DD').format(),
      };

      let graphData = [];
      await Promise.all([
        this.$store.dispatch(`business/getEventByDateRange`, requestData),
        this.$store.dispatch(`business/getAnnotationByDateRange`, requestData),
        this.$store.dispatch(`business/getContractByDriver`, selectedDriverId),
        this.$store.dispatch(`business/getDeviceLogByDateRange`, requestData),
        this.$store.dispatch(`business/getPreviousEvent`, requestData),
        this.$store.dispatch(`report/getBreachesByDateRange`, {
          ...requestData,
          driverId: selectedDriverId,
          filter: { driverId: selectedDriverId },
        }),
        this.$store.dispatch(`business/getEndOfDayByDateRange`, requestData),
      ]).then((data) => {
        graphData = data[4];
        this.eventData = this.formatEvent(data[0]);
        this.endOfDays = data[6].map((x) => x.date);
        this.breachData = data[5];
        this.breaches = this.breachData.map((breach) => {
          breach.time =
            typeof breach.time == 'object' ? breach.time[0] : breach.time;
          return breach;
        });

        this.annotationData = data[1];
        const drivers = data[2].results;
        if (drivers && !_.isEmpty(drivers[0])) {
          this.driverData = drivers[0]['driver'];
          this.driverData['isNonEWD'] =
            drivers[0]?.status == 'linked-non-ewd' ? true : false;
        } else {
          const me = this.$store.getters[`account/me`];
          const roles = this.$store.getters[`account/roleNames`];
          if (me.role.name === 'driver' || roles.includes('driver')) {
            this.driverData = me.driver;
            this.driverData['isNonEWD'] = false;
          }
        }

        if (!_.isEmpty(this.eventData) || !_.isNil(this.eventData)) {
          this.driverData['vehicle'] = this.eventData[0]?.vehicle;
          this.driverData['fatiguePlan'] = this.eventData[0]?.fatiguePlan;
        }

        this.driverData['driverDateOfBirth'] = moment(
          this.driverData['driverDateOfBirth']
        ).format('YYYY-MM-DD');
        const deviceLogs = data[3];
        deviceLogs.forEach((device) => {
          const offset = !_.isEmpty(this.eventData)
            ? this.eventData[this.eventData.length - 1]['timezone'].split(
                ' '
              )[1]
            : null;
          device.createdAt = !_.isEmpty(offset)
            ? moment(device.createdAt).utcOffset(offset).format()
            : moment(device.createdAt).format();
          if (!_.isEmpty(this.logs.device)) {
            const previousLog = this.logs.device[this.logs.device.length - 1];
            const previousLogObj = {
              maker: previousLog.maker,
              model: previousLog.model,
              os: previousLog.os,
            };
            const currentLogObj = {
              maker: device.maker,
              model: device.model,
              os: device.os,
            };
            if (
              JSON.stringify(previousLogObj) != JSON.stringify(currentLogObj)
            ) {
              this.logs.device.push(device);
            }
          } else {
            this.logs.device.push(device);
          }
        });
        const lastDeviceLog = _.first(
          _.orderBy(deviceLogs, ['createdAt'], ['desc'])
        );
        if (lastDeviceLog && !_.isEmpty(lastDeviceLog['app_ewd'])) {
          this.ewdDetails.approvalHolder = !_.isEmpty(
            lastDeviceLog['app_ewd']['holder']
          )
            ? lastDeviceLog['app_ewd']['holder']
            : this.ewdDetails.approvalHolder;
          this.ewdDetails.approvalNumber = !_.isEmpty(
            lastDeviceLog['app_ewd']['approval_number']
          )
            ? lastDeviceLog['app_ewd']['approval_number']
            : this.ewdDetails.approvalNumber;
          this.ewdDetails.systemName = !_.isEmpty(
            lastDeviceLog['app_ewd']['system_name']
          )
            ? lastDeviceLog['app_ewd']['system_name']
            : this.ewdDetails.systemName;
          this.ewdDetails.systemNumber = !_.isEmpty(
            lastDeviceLog['app_version']
          )
            ? lastDeviceLog['app_version']
            : this.ewdDetails.systemNumber;
        }

        this.logs.device = this.logs.device.reverse();
      });

      this.isLoading = false;
      this.isSuccess = true;
      await this.$nextTick(() => {
        if (this.$refs.childGraphicalSummary) {
          // Set graph
          this.getGraph(graphData);
        }

        if (
          this.$refs.childTabs &&
          typeof this.$refs.childTabs.selectTab === 'function'
        ) {
          let tabIndex = 0;
          switch (activeTab) {
            case 'Events':
              tabIndex = 1;
              break;
            case 'Breaches':
              tabIndex = 2;
              break;
            case 'Annotations':
              tabIndex = 3;
              break;
            case 'Logs':
              tabIndex = 4;
              break;
            default:
              tabIndex = 0;
              break;
          }
          this.$refs.childTabs.selectTab(tabIndex);
        }
      });
    },
    async downdloadReport(selectedDriverId = null, selectedDate = null) {
      if (!selectedDriverId) selectedDriverId = this.selectedDriverId;
      if (!selectedDate) selectedDate = this.selectedDate;

      const data = {
        driverId: selectedDriverId,
        startDate: moment().format(`${selectedDate}THH:mm:ssZ`),
        endDate: moment().format(`${selectedDate}THH:mm:ssZ`),
      };
      await this.$store
        .dispatch(`business/downdloadReport`, data)
        .then((res) => {
          const url = URL.createObjectURL(res.pdf);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', res.filename);
          document.body.append(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        });
      this.$emit('sendReportloading', false);
    },
    async onSubmitSendReport(recipientEmail, includeBreach) {
      if (this.canSearch() && recipientEmail) {
        this.$emit('sendReportloading', true);
        const breach = includeBreach ? { includeBreach: includeBreach } : null;
        const data = {
          recipientEmail: recipientEmail,
          driverId: this.selectedDriver.driverId,
          startDate: moment().format(`${this.selectedDateRange[0]}THH:mm:ssZ`),
          endDate: moment().format(`${this.selectedDateRange[1]}THH:mm:ssZ`),
          ...breach,
        };
        await this.$store
          .dispatch(`business/sendReport`, data)
          .then((res) => {
            this.toast('success', res.message);
          })
          .catch((err) => {
            this.toast('error', err.message);
          });
        this.$emit('sendReportloading', false);
      }
    },
    toast(state, message) {
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', { state, message });
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
};
</script>
