<template>
  <Stepper
      :stepperSteps="stepperSteps"
      @completed-step="completeStep"
      @active-step="isStepActive"
      :showNavigation="false"
      @closeModal="closeModal"
  />
</template>
<script>
import { Stepper } from '@/components/commons';
import EditPartnerPricing from "@/components/forms/admin/pricing/stepper-component/edit-partner-pricing.vue";
import EditPartnerRrpPricing from "@/components/forms/admin/pricing/stepper-component/edit-partner-rrp-pricing.vue";
export default {
  name: 'Edit-Pricing-Form',
  components: { Stepper },
  props: {
    editMode:{
      type: Boolean,
      default: false,
    },
    detailsId:{
      type: String,
      default: '',
    },
    connectionType:{
      type: String,
      default: 'partner',
    },
  },
  data(){
    return{
      stepperSteps: [
        {
          stepIcon: 'table',
          stepTitle: 'Buy Product Pricing',
          stepSequence: 'first',
          stepComponent: EditPartnerPricing,
          isCompleted: false,
          detailsId: this.detailsId,
          connectionType: this.connectionType
        },
        {
          stepIcon: 'table',
          stepTitle: 'RRP Pricing',
          stepSequence: 'second',
          stepComponent: EditPartnerRrpPricing,
          isCompleted: false,
          detailsId: this.detailsId,
          connectionType: this.connectionType
        },
      ],
      activeStep: 0,
    }
  },
  methods: {
    completeStep(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          step.isCompleted = true;
        }
      })
    },
    isStepActive(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          if(step.isCompleted === true) {
            step.isCompleted = false;
          }
        }
      })
    },
    closeModal(){
      this.$emit('closeModal')
    },
  },
}
</script>