import { FilledForm } from "@/api"
import _ from 'lodash'

export default{
    async updateResultStatus({ state }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data '+state.me
        try{
            const response = await FilledForm.updateResultStatus(data)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
}