export default [
    {
      component: 'div',
      class: 'grid grid-cols-2 gap-4',
      children: [
        {
              type: 'text',
              name: 'businessName',
              label: 'Business Name',
              validation: 'required'
        },
        {
              type: 'text',
              name: 'tradingName',
              label: 'Trading Name',
              validation: 'required'
        },
        ]
      },
      {
      component: 'div',
      class: 'grid grid-cols-2 gap-4',
      children: [
      {
          type: 'text',
          name: 'abn',
          label: 'NZBN',
          validation: 'required' 
      },
      {
          type: 'text',
          name: 'contactUserName',
          label: 'Contact Username',
          validation: 'required'
      },
  ],
  },
  {
      component: 'div',
      class: 'grid grid-cols-2 gap-4',
      children: [
          {
              type: 'email',
              name: 'contactEmail',
              label: 'Contact Email',
              validation: '^required|email'
          },
          {
              type: 'text',
              name: 'contactPhoneNumber',
              label: 'Contact Phone Number',
              validation: 'required'
          },
      ]
  },
    {
    component: 'div',
    class: 'grid grid-cols-2 gap-4',
    children: [
      {
          type: 'email',
          name: 'supportEmail',
          label: 'Support Email',
          validation: '^required|email'
      },
      {
          type: 'text',
          name: 'supportPhoneNumber',
          label: 'Support Phone Number',
          validation: 'required'
      },
    ]
    },
    { 
      type: 'group',
      name: 'location',
      label: 'Location:',
      labelClass: 'font-semibold text-sm block -top-7 -left-0 absolute',
      class: 'border rounded p-5 mb-5 mt-10 relative',
      children: [
        {
          name: 'businessAddress',
          label: 'Business Address',
          validation: 'required',
          type: 'places-search-input',
        },
       
      ]
    },
  ]