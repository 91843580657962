export const FATIGUE = [
    {
        name: 'Fatigue Compliance',
        items: [
            {
                name: 'Management',
                link: '',
                subitems: [
                    {
                        name: 'Fatigue Dashboard',
                        link: '/business/fatigue-management/dashboard',
                        production: true
                    },
                    {
                        name: 'Conformance',
                        link: '/business/fatigue-management/conformance',
                        production: true
                    },
                    {
                        name: 'Fatigue Accreditation',
                        link: '/business/fatigue-management/accreditation',
                        production: true
                    },
                    {
                        name: 'Registers',
                        link: '/business/fatigue-management/registers',
                        production: true
                    },
                ],
                production: true
            },
            {
                name: 'Forms',
                link: '',
                subitems: [
                    {
                        name: 'Create Template',
                        link: '/business/fatigue-management/forms/new-template/prestart',
                        production: true
                    },
                    {
                        name: 'Defaults',
                        link: '/business/fatigue-management/form/',
                        production: true
                    },
                ],
                production: true
            }
        ]
    }
]

export const KM_TIME = [
    {
        name: 'KM & Time',
        items: [
            {
                name: 'KM & Time Report',
                link: '/business/reports/km-time',
                production: true
            }
        ]
    }
]

export const ONE_API = [
    {
        name: 'One API',
        items: [
            {
                name: 'Dashboard',
                link: '/business/api',
                production: true
            }
        ]
    }
]

export const EWD_PT = {}

export const EWD_FT = {}