<template>
    <div class="page-container px-2 pt-3">
        <Stepper 
            :stepperSteps="stepperSteps" 
            @completed-step="completeStep" 
            @active-step="isStepActive" 
            :showNavigation="false"  
            @closeModal="closeModal"
        />
    </div>
</template>

<script>
import { Stepper } from '@/components/commons';
import { EventDetails, ManualEventCreate, DocumentUpload, Confirmation } from './stepper-component';

export default {
    name: "AddManualEvent",
    props: {
        soloDriver: {
            type: Object,
            default: null
        }
    },
    components: { Stepper },
    data() {
        return {
            stepperSteps: [
                {
                    stepIcon: 'list',
                    stepTitle: 'Details',
                    stepSequence: 'first',
                    stepComponent: EventDetails,
                    soloDriver: this.soloDriver,
                    isCompleted: true
                },
                {
                    stepIcon: 'clipboard',
                    stepTitle: 'Manual Events',
                    stepSequence: 'second',
                    stepComponent: ManualEventCreate,
                    isCompleted: false
                },
                {
                    stepIcon: 'upload',
                    stepTitle: 'Documents',
                    stepSequence: 'third',
                    stepComponent: DocumentUpload,
                    isCompleted: false
                },
                {
                    stepIcon: 'file',
                    stepTitle: 'Confirm',
                    stepSequence: 'fourth',
                    stepComponent: Confirmation,
                    isCompleted: false
                },
            ],
            activeStep: 0,
        }
    },
    methods: {
        completeStep(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    step.isCompleted = true;
                }
            })
        },
        isStepActive(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    if(step.isCompleted === true) {
                        step.isCompleted = false;
                    }
                }
            })
        },
        closeModal(){ 
            this.$emit('closeModal')
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .page-container {
        min-height: calc(100vh - 136px);
    }
</style>