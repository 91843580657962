<template>
   <div class="flex">
      <date-picker 
         v-model="selectedDateRange"
         :date="selectedDateRange" 
         prefix-class="lhv"
         class="border-rounded"
         ref="childDatePicker" 
         placeholder="YYYY-MM-DD ~ YYYY-MM-DD" 
         @change="setDate($event)" 
         range 
         required
      ></date-picker>
   </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';

export default {
   inheritAttrs: false,
   components: { DatePicker },
   props: {
      date: Array,
      noNavigation: Boolean
   },
   data() {
      return {
         selectedDateRange: this.date
      }
   },
   methods: {
      async onClear() {
         this.selectedDateRange = []
      },
      async setDate(event){
         await this.$emit('selected', event)
      },
   }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .lhv-datepicker::v-deep {
    width: 100%;
    .lhv-input {
      height: auto;
      font-weight: 500;
      padding: 11px 40px 11px 12px;
      @apply border-gray-300 hover:border-orange-primary focus:border-orange-primary text-sm;
    }

    &.no-nav .lhv-input {
      @apply rounded;
    }
  }
</style>