<template>
    <a class="flex flex-row items-center">
        <div class="h-10 w-10 bg-gray-200 border rounded-full overflow-hidden border-3 border-gray-300 flex">
            <font-awesome-icon icon="user" class="text-xl m-auto text-gray-600" />
        </div>
        <span b-tooltip.hover :title="username" class="flex flex-col ml-2 text-left">
            <span class="truncate w-24 font-semibold text-xs leading-none" :style="{color: theme.primaryColor}">{{firstName}}</span>
            <span class="truncate w-24 text-gray-500 text-xs leading-none mt-1 h-3.5 capitalize" :style="{color: theme.primaryColor}">{{role}}</span>
        </span>
    </a>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'AccountInfo',
  props: {},
  data(){
      return {
        username:'',
        role:'',
        site: '',
        firstName: ''
      }
  },
  watch: {
    '$store.state.account.proxySite': async function() {
      const proxySite = this.$store.getters[`account/proxySite`];
      if (!_.isEmpty(proxySite) && _.isObject(proxySite)) this.role = proxySite.name;
    },
  },
  mounted(){
    const me = this.$store.getters[`account/me`];
    this.username = me.email || 'UserHere';
    if (me.role.name !== 'driver') {
      this.firstName = me?.webProfile?.userName.split(' ')[0] || 'User';
    } else {
      this.firstName = me?.driver?.driverName.split(' ')[0] || 'Driver';
    }
    
    this.role = me.role.name || 'Role';
      
    const proxyRole = this.$store.getters[`account/proxyRole`];
    if (proxyRole) this.role = proxyRole; 

    const proxySite = this.$store.getters[`account/proxySite`];
    if (!_.isEmpty(proxySite) && _.isObject(proxySite)) this.role = proxySite.name;
  },
   computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
