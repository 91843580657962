<template>
    <div class="page-container px-2 pt-3">
        <div class="w-full" v-if="loading">
            <div class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center">
                <Spinner class="mx-auto mb-2"></Spinner>
                <span class="font-semibold text-gray-400">Loading data...</span>
            </div>
        </div>
        <div v-else>
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500">
                    <thead ref="tableHead" class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th 
                                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider" 
                                :style="{backgroundColor: theme.primaryColor}"
                            >
                                Plate<br/>Number
                            </th>
                            <th 
                                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider" 
                                :style="{backgroundColor: theme.primaryColor}"
                            >
                                Registration<br/>Expiry
                            </th>
                            <th 
                                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider" 
                                :style="{backgroundColor: theme.primaryColor}"
                            >
                                GVM
                            </th>
                            <th 
                                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider" 
                                :style="{backgroundColor: theme.primaryColor}"
                            >
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr 
                            v-for="(vehicle, index) in vehicles" :key="index"
                            :ref="getRowRef(vehicle.vehiclePlate)"
                            class="bg-white border-b hover:bg-gray-50 relative"
                        >
                            <td class="py-4 px-6">{{ vehicle.vehiclePlate }}</td>
                            <td class="py-4 px-6">{{ formatDateToAU(vehicle.registrationExpiry) }}</td>
                            <td class="py-4 px-6">{{ vehicle.GMV }}</td>
                            <td class="py-4 px-6">
                                <div class="flex items-center">
                                    <font-awesome-icon 
                                        spin 
                                        icon="spinner" 
                                        class="mr-1 text-green"
                                        v-if="vehicle.status == 'processing'"
                                    />
                                    <font-awesome-icon 
                                        icon="check-circle" 
                                        class="mr-1 text-green-400"
                                        v-if="vehicle.status == 'done'"
                                    />
                                    <span 
                                        class="capitalize" 
                                        v-if="vehicle.status != 'error'"
                                    >
                                        {{ vehicle.status }}
                                    </span>

                                    <font-awesome-icon 
                                        icon="times-circle" 
                                        class="mr-1 text-red-400"
                                        v-if="vehicle.status == 'error'"
                                    />
                                    <span 
                                        class="text-xs" 
                                        v-if="vehicle.status == 'error'"
                                    >
                                        {{ vehicle.errorMessage }}
                                    </span>
                                </div>
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>

            <FormulateInput
                type="submit"
                :label="hasSubmitted ? 'Close' : 'Import'"
                :input-class="hasSubmitted ? 'btn bg-gray-200 w-full mt-5' : 'btn btn-primary w-full mt-5'"
                :disabled="submitting"
                @click="submit()"
            />
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { formatDate } from "@/_helper";
import Spinner from '@/components/commons/ui/Spinner';

export default {
    name: 'Import-Vehicle',
    components: { Spinner },
    props: {
        preData: null
    },
    data() {
        return {
            hasSubmitted: false,
            submitting: false,
            loading: false,
            vehicles: []
        };
    },
    mounted() {
        if(!_.isEmpty(this.preData)) {
            this.vehicles = this.preData.selectedVehicles;
        }
    }, 
    methods: {
        formatDateToAU(dateToFormat) {
            return moment(dateToFormat, 'YYYY-MM-DD').format('DD/MM/YYYY')
        },
        getRowRef(name) {
            return 'ref' + name.replace(/\s/g, '').toLowerCase()
        },
        async submit() {
            if (this.hasSubmitted) {
                this.$emit('closeRefresh')
            } else {
                this.submitting = true;
                for (const vehicle of this.vehicles) {
                    vehicle.status = 'processing';
                    this.$nextTick(() => {
                        this.$refs[this.getRowRef(vehicle.vehiclePlate)][0].scrollIntoView({ block: 'center', scrollBehavior: 'smooth' })
                    });
                    await this.saveVehicle(vehicle)
                        .then(() => vehicle.status = 'done')
                        .catch((error) => {
                            vehicle.status = 'error';
                            vehicle.errorMessage = error;
                        });
                }
                this.submitting = false;
                this.hasSubmitted = true;
            }
        },
        async saveVehicle(vehicle) {
            return new Promise((resolve, reject) => {
                const parsedVehicle = this.parseForSubmit(vehicle)
                const proxySite = this.$store.getters[`account/proxySite`];
                if (!_.isEmpty(proxySite) && _.isObject(proxySite)) parsedVehicle.siteId = proxySite._id;

                this.$store.dispatch(`vehicle/createVehicle`, parsedVehicle)
                    .then(() => resolve(), (error) => reject(error?.message))
            });
        },
        parseForSubmit(values){
            const me = this.$store.getters[`account/me`];
            const entityID = me.businessId;

            let vehicle = {
                vehiclePlate: values.vehiclePlate,
                registrationExpiry: formatDate(
                    values.registrationExpiry,
                    "yyyy-MM-DD"
                ),
                GMV: Number(values?.GMV ? values.GMV : values.GVM),
                vehicleClassId: values.vehicleClass?._id,
                vehicleTypeId: values.vehicleType?._id,
                driveVehicle: typeof values.driveVehicle === "boolean"? values.driveVehicle : values.driveVehicle ==='true',
                concreteAgitator: typeof values.concreteAgitator === "boolean"? values.concreteAgitator : values.concreteAgitator ==='true',
                livestockVehicle: typeof values.livestockVehicle === "boolean"? values.livestockVehicle : values.livestockVehicle ==='true',
                VIN: values.vin? values.vin : undefined,
                alias: values.alias? values.vin : undefined,
                frontCouplingIds: values.frontCouplings? values.frontCouplings : undefined,
                rearCouplingIds: values.rearCouplings? values.rearCouplings : undefined,
                powerCouplingIds: values.powerCouplings? values.powerCouplings : undefined,
                brakeFoundationId: values.brakeFoundation? values.brakeFoundation : undefined,
                brakeTypeId: values.brakeType? values.brakeType : undefined,
                supplyVoltageToTrailerId: values.supplyVoltageToTrailer? values.supplyVoltageToTrailer : undefined,
                lAETBSysSupplier: values.lAETBSysSupplier,
                tSTBCSRefNumber: values.tSTBCSRefNumber,
                comments: values.comments,
                fleetId: values.fleetId,
                entityId: entityID
            }

            if (!vehicle.GMV) vehicle.GMV = 0;

            return vehicle;
        }
    },
    computed: {
        ...mapGetters('theme', {
            theme: 'getColorScheme'
        })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
