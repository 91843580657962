<template>
  <div>
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
    <div v-if="!isLoading && !performances.length">
      <div class="text-left">
        <button
          v-if="hasBackButton"
          @click="handleBackButton()"
          class="btn bg-gray-200"
        >
          <font-awesome-icon icon="arrow-left" />&nbsp; Back
        </button>
      </div>
      <div class="text-center mb-10">No Record Found</div>
    </div>
    <div v-if="!isLoading && performances.length">
      <div class="text-left">
        <button
          v-if="hasBackButton"
          @click="handleBackButton()"
          class="btn bg-gray-200"
        >
          <font-awesome-icon icon="arrow-left" />&nbsp; Back
        </button>
      </div>
      <div class="text-center mb-10">
        <div class="font-bold text-black-primary text-2xl">
          {{ driverName }}
        </div>
        <div class="text-xs">Driver Name</div>
      </div>
      <div
        v-for="(element, index) in performances"
        :key="index"
        class="mb-5 text-center"
      >
        <div
          class="text-black-primary cursor-pointer hover:text-orange-primary"
          @click.prevent="viewDocument(element._id)"
        >
          {{ index + 1 }} - {{ element.periodicCheck }} ({{ element.status }})
        </div>
      </div>
      <Pagination
        :is-loading="isLoading"
        :total-pages="pagination.totalPages"
        :current-page="pagination.page"
        :total-records="pagination.totalRecords"
        :visible-page-item-count="pagination.visiblePageItemCount"
        @pagechanged="onPageChange"
      />
    </div>

    <Modal
      ref="modal"
      :title="modalTitle"
      :size="modalSize"
      :bodyHeight="modalHeight"
    >
      <div v-if="modalContent === 'view'">
        <ViewPerformance :propsPerformance="selectedPerformance" />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Pagination } from "@/components/commons";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import { formatPaginationSettings } from "@/_helper";
import ViewPerformance from "@/components/view/view-performance";
export default {
  name: "Performance-List",
  components: { Modal, ViewPerformance, Pagination },
  props: {
    formId: String,
    driverName: String,
    hasBackButton: Boolean,
  },
  data() {
    return {
      isLoading: false,
      error: false,
      errorMessage: null,
      modalSize: "2xl",
      modalTitle: "",
      modalContent: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      performances: [],
      pageLimit: 5,
      pagination: {
        page: 1,
        totalPages: 1,
        totalRecords: 1,
        visiblePageItemCount: 1,
      },
      selectedPerformance: null,
    };
  },

  async mounted() {
    await this.fetchPerformance();
  },
  methods: {
    async fetchPerformance(page = 1) {
      try {
        this.isLoading = true;
        const payload = {
          registerId: this.formId,
          pagination: {
            skip: page * this.pageLimit - this.pageLimit,
            limit: this.pageLimit,
          },
        };
        const result = await this.$store.dispatch(
          "performance/getByRegisterId",
          payload
        );
        this.pagination = formatPaginationSettings(result.resultsMeta);
        this.performances = result.data;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.statusCode && error.statusCode !== 404) {
          this.error = true;
          this.errorMessage = error?.message;
        }
      }
    },

    async onPageChange(page) {
      if (page) {
        await this.fetchPerformance(page);
      }
    },

    viewDocument(id) {
      this.$refs.modal.openModal();
      this.selectedPerformance = this.performances.find(
        (item) => item._id === id
      );
      this.modalContent = "view";
      this.modalTitle = "Performance Document Details";
    },

    closeModal() {
      this.$emit("closeModal");
    },

    handleBackButton() {
      this.$emit("handleSummaryOrDetailedReportClick", "detailed");
    },
  },
};
</script>
