<template>
  <div class="mb-16">
    <div v-if="!loading" class="flex flex-col items-center space-y-4">
      <div class="flex flex-col items-start w-full">
        <div class="text-sm font-bold text-gray-500">Current Plan</div>
        <div class="text-lg font-bold text-gray-800">{{ activeData.contractType == 'business' ? 'Business' : 'Solo' }} {{ activeData.billingPeriod.name }}</div>
      </div>

      <div class="flex flex-col items-start w-full">
        <div class="flex flex-wrap justify-center w-full">
          <div class="w-1/2 px-4 wrapper">
            <div class="rounded-xl relative z-10 overflow-hidden border border-gray-400 border-opacity-20 shadow-pricing">
              <div class="py-10 px-4 text-center">
                <span class="text-gray-800 font-bold text-xl block mb-8">Monthly</span>
                <div class="flex flex-col">
                  <div class="flex justify-between items-center border-b-2 p-2" v-for="(module, index) in contract.businessModules" :key="`seat-modules-${index}`">
                    <div class="font-semibold text-sm text-gray-800">{{ module.name }}</div>
                    <div class="font-semibold text-sm text-gray-500">${{ getPriceBillingPeriodStatic(module, 'monthly') }} / seat</div>
                  </div>
                </div>
                <button class="mt-12 w-full block text-base font-semibold border rounded-md text-center p-2" 
                  :class="[
                    contract.billingPeriod?.name == 'Monthly' ? 'bg-white text-gray-500 border-gray-500 cursor-not-allowed' : selectedBillingPeriod == 'Monthly' ? 'bg-orange-primary border-orange-primary text-white hover:text-orange-primary hover:bg-white hover:border-orange-primary' : 'bg-white text-orange-primary border-orange-primary hover:text-white hover:bg-orange-primary hover:border-orange-primary transition cursor-pointer'
                  ]"
                  @click.prevent="selectPlan('Monthly')"
                >
                  <span v-if="contract.billingPeriod?.name == 'Monthly'">
                    Active Plan
                  </span>

                  <span v-else-if="selectedBillingPeriod == 'Monthly'">
                    Plan Selected
                  </span>

                  <span v-else>
                    Choose Monthly
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div class="w-1/2 px-4 wrapper">
            <div class="rounded-xl relative z-10 overflow-hidden border border-gray-400 border-opacity-20 shadow-pricing">
              <div class="py-10 px-4 text-center">
                <span class="text-gray-800 font-bold text-xl block mb-8">Yearly</span>
                <div class="flex flex-col">
                  <div class="flex justify-between items-center border-b-2 p-2" v-for="(module, index) in contract.businessModules" :key="`seat-modules-${index}`">
                    <div class="font-semibold text-sm text-gray-800">{{ module.name }}</div>
                    <div class="font-semibold text-sm text-gray-500">${{ getPriceBillingPeriodStatic(module, 'yearly') }} / seat</div>
                  </div>
                </div>
                <button class="mt-12 w-full block text-base font-semibold border rounded-md text-center p-2" 
                  :class="[
                    contract.billingPeriod?.name == 'Yearly' ? 'bg-white text-gray-500 border-gray-500 cursor-not-allowed' : selectedBillingPeriod == 'Yearly' ? 'bg-orange-primary border-orange-primary text-white hover:text-orange-primary hover:bg-white hover:border-orange-primary' : 'bg-white text-orange-primary border-orange-primary hover:text-white hover:bg-orange-primary hover:border-orange-primary transition cursor-pointer'
                  ]"
                  @click.prevent="selectPlan('Yearly')"
                >
                  <span v-if="contract.billingPeriod?.name == 'Yearly'">
                    Active Plan
                  </span>

                  <span v-else-if="selectedBillingPeriod == 'Yearly'">
                    Plan Selected
                  </span>

                  <span v-else>
                    Choose Yearly
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="contract.contractType == 'business'" class="flex flex-col items-start w-full my-4">
        <div class=" font-bold text-gray-800">Modify Seat Count</div>
        <div class="flex flex-col">
        </div>
      </div> -->
    </div>
    <FormulateForm v-model="values" @submit="onSubmit" v-if="!loading">
      <div class="items-center justify-between mt-4">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="loading || hasErrorMessage"
        >
          <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" />{{ buttonText }}</span>  
          <span v-if="!submitLoading">Submit</span>  
        </FormulateInput>
      </div>        
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">{{errorMessage}}</div>
  </div>
</template>

<script>
// import { StripeElementCard } from "@vue-stripe/vue-stripe";
export default {
  props: {
    activeData: Object,
  },
  data() {return {
      values: {},
      loading: false,
      hasErrorMessage: true,
      errorMessage: '',
      buttonText: "Saving data...",
      submitLoading: false,
      billPeriod: '',
      contract: {},
      selectedBillingPeriod: '',
    }
  },
  async mounted () {
    this.loading = true
    this.contract = await this.activeData
    this.loading = false
  },

  methods: {

    getActualPrice(module) {
      let amount;
      switch(this.contract.billingPeriod.name.toUpperCase()) {
        case "MONTHLY":
          this.billPeriod = "month"
          amount = parseFloat(module.price.monthly).toFixed(2)
        break;
        case "QUARTERLY":
          this.billPeriod = "quarter"
          amount = parseFloat(module.price.quarterly).toFixed(2)
        break;
        case "YEARLY":
          this.billPeriod = "year"
          amount = parseFloat(module.price.yearly).toFixed(2)
      }

      return amount;
    },

    async onSubmit() {
      try {
        this.submitLoading = true;
        const payload = {
          newBillingPeriod: this.selectedBillingPeriod,
          contractId: this.contract._id,
        }
        
        const updateContractBillingPeriod = await this.$store.dispatch("contract/updateContractBillingPeriod", payload);

        console.log("Contract Billing Period Updated--", updateContractBillingPeriod);
        this.submitLoading = false;
        this.$store.commit('setDialogNotify',true);
        this.$store.commit('setMessageNotify',{state:'success',message:'Billing period successfully updated.'});
        this.$emit('closeModal');
        setTimeout(()=>{location.reload()},2500);
      } catch (error) {
        this.submitLoading = false;
        this.$store.commit('setDialogNotify',true);
        this.$store.commit('setMessageNotify',{state:'error',message:'Error changing billing period.'});
        console.log(error);
      }
    },

    async selectPlan(billPeriod) {
      if(this.selectedBillingPeriod == billPeriod) {
        this.hasErrorMessage = true
        this.selectedBillingPeriod = ''
      } else {
        this.hasErrorMessage = false
        this.selectedBillingPeriod = billPeriod
      }
    },

    getPriceBillingPeriodStatic(module, billingPeriod) {
      let amount;
      switch(billingPeriod.toUpperCase()) {
        case "MONTHLY":
          amount = parseFloat(module.price.monthly).toFixed(2)
        break;
        case "QUARTERLY":
          amount = parseFloat(module.price.quarterly).toFixed(2)
        break;
        case "YEARLY":
          amount = parseFloat(module.price.yearly).toFixed(2)
      }

      return amount;
    },
  }
}
</script>