<template>
  <div>
    <Table 
    :tableHeader="tableHeader" 
    :data="paymentData"
  >
    <template #default="{data}">
      <td class="px-5 py-2">
        <div class="text-black-primary whitespace-no-wrap text-lg flex">
          <a class="flex cursor-pointer"
            @click.prevent="deletePayment(data.id)"
            ><font-awesome-icon
              icon="trash"
              class="my-auto mx-1.5 hover:text-orange-primary"
          /></a>
        </div>
      </td>
    </template>
    <template slot="callToActionTop">
      <button class="btn btn-primary" @click.prevent="newPayment()" v-if="hasActivePaymentGateway" :style="{backgroundColor: theme.secondaryColor}">
        New Payment Method
      </button>
    </template>
    </Table>
    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <NewPaymentMethod @closeRefresh="closeRefresh" :publishableKey="publishableKey" :paymentGatewayId="paymentGatewayId"/>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Table } from '@/components/commons/'
import { NewPaymentMethod } from "@/components/forms/payment";
import { formatDate } from '@/_helper'
import { DEFAULT_MODAL_HEIGHT } from '@/_helper/constants'
import _ from 'lodash'
import { mapGetters } from 'vuex';

export default{
  name:"Partner-Payment",
  components:{ Modal, NewPaymentMethod, Table },
  data(){
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: '',
      modalId: '',
      payments: [],
      selectedId: '',
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        'Name',
        'Last 4 Digits',
        'Created',
        'Default',
        'Status'
      ],
      tableData: [],
      hasActivePaymentGateway: false,
      publishableKey: null,
      paymentGatewayId: null
    }
  },
  async mounted(){
    const me = await this.$store.getters[`account/me`];
    const paymentGateway = await this.$store.dispatch("payment/getPaymentGatewayByUid", { uid: me?.business?.parentUid, role: me?.business?.parentRole });
    if (paymentGateway) {
      this.paymentGatewayId = paymentGateway._id;
      this.publishableKey = paymentGateway.apiKey; 
      this.hasActivePaymentGateway = true;
    }

    this.init()
  },
  methods: {
    async init(){
      this.isLoading = true;
      this.$emit("updateLoading", true);
      const me = await this.$store.getters[`account/me`];
      const payments = await this.$store.dispatch(`payment/getAllPaymentMethodByEntityId`, me?.business?._id);

      if(payments){
        const paymentTableData = this.formatItem(
          _.orderBy(payments,["createdAt"], ["desc"])
        );
        this.payments = paymentTableData
      }else{
        this.payments = [];
      }
      this.isLoading = false;
      this.$emit("updateLoading", false);

    },
    newPayment(){
      this.modalContent = 'new';
      this.modalTitle = 'New Payment'
      this.$refs.modal.toggleModal();
    },
    deletePayment(id) {
      if (confirm("Are you sure you want to delete this payment method?")) {
        this.$store
          .dispatch(`payment/deletePaymentMethod`, id)
          .then((payment) => {
            if (payment && payment._id) {
              this.init();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
    closeRefresh() {
      this.$refs.modal.closeModal();
      this.init();
    },
    formatItem(items){
      let nItems = [];
      if(items){
        items.forEach(item => {
          const payment = {
            id: item?._id,
            paymentName: item?.paymentName || '',
            last4: item?.last4 || '',
            created: formatDate(item?.createdAt),
            isDefault: item?.isDefault,
            isActive: item?.isActive,
          }
          nItems.push(payment)
        });
      }
      return nItems;
    },
    parsePayment(data) {
      return data.map((paymentData) => ([
        {id: paymentData.id, name: paymentData.paymentName, itemType: 'string'},
        {id: paymentData.id, name: paymentData.last4, itemType: 'string'},
        {id: paymentData.id, name: paymentData.created, itemType: 'string'},
        {id: paymentData.id, name: paymentData.isDefault, itemType: 'toggle', editable: false},
        {id: paymentData.id, name: paymentData.isActive, itemType: 'label', isStatus: true},
      ]))
    },
  },
  computed: {
    paymentData() {
        if(this.payments){
          return this.parsePayment(this.payments)
        }else{
            return [];
        }  
    },
    ...mapGetters('theme', {
          theme: 'getColorScheme'
      })
  }
}
</script>