<template>
    <div
        :data-type="context.type"
        :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
    >
        <list-select
            ref="selectRef"
            :options="options"
            :context="context"
            :isLoading="isLoading"
            :hasPrevPage="hasPrevPage"
            :hasNextPage="hasNextPage"
            noResultText="No partners found."
            @prevPage="onPrevPage"
            @nextPage="onNextPage"
            @search="onSearch"
        >
            <template slot="option" slot-scope="option">
                <div class="text-sm">
                    <h3 class="m-0 font-semibold">{{ option.persona.businessName }}</h3>
                    <em class="text-xs">{{ option.persona.abn }}</em>
                </div>
            </template>
            <template slot="selected-option" slot-scope="option">
                <div class="selected text-sm">
                    {{ option.persona.businessName }}
                </div>
            </template>
        </list-select>
    </div>
</template>
<script>
import { debounce } from 'lodash'
import { formatPaginationSettings } from '@/_helper'
import ListSelect from '@/components/commons/ui/list-select/ListSelect'

const SoloPartnerSelect = {
    name: 'SoloPartnerSelect',
    components: {
        ListSelect
    },
    props: {
        context: {
            type: Object,
            required: true
        }
    },
    mounted() {
        this.$refs.selectRef.onSearch();
    },
    data: () => ({
        partners: [],
        options: [],
        isLoading: false,
        hasPrevPage: false,
        hasNextPage: false,
        filter: {
            limit: 10,
            status: 'all',
            search: '',
        },
        paginationSettings: {
            page: 1,
            totalPages: 5,
            totalRecords: 50,
            visiblePageItemCount: 3
        }
    }),
    methods: {
        async onSearch(search = '') {
            debounce(async () => {
                this.filter.search = search
                this.options = []
                this.isLoading = true
                const query = {
                    skip: ( this.paginationSettings.page * this.filter.limit ) - this.filter.limit,
                    limit: this.filter.limit,
                    search: this.filter.search,
                    filter: {}
                }

                query.filter.hasPaymentGateway = true

                if(this.filter.status != 'all') query.filter.status = { $eq : this.filter.status }

                const partners = await this.$store.dispatch(`partner/getPartner`, {query})
                if (partners) {
                    if (partners?.metadata){
                        this.paginationSettings = formatPaginationSettings(partners?.metadata)
                        this.hasNextPage = this.paginationSettings.page < this.paginationSettings.totalPages
                        this.hasPrevPage = this.paginationSettings.page > 1;
                    } else {
                        this.paginationSettings = formatPaginationSettings()
                    }
                    
                    this.partners = partners.results
                    this.options = this.partners
                }
                this.isLoading = false
            }, 500)();
        },
        onPrevPage() {
            if (this.hasPrevPage) {
                this.paginationSettings.page = this.paginationSettings.page - 1
                this.onSearch();
            }
        },
        onNextPage() {
            if (this.hasNextPage) {
                this.paginationSettings.page = this.paginationSettings.page + 1
                this.onSearch();
            }
        }
    }
}

export const VueFormulateSoloPartnerSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      SoloPartnerSelect,
    },
    library: {
      "solo-partner-select": {
        classification: "select",
        component: "SoloPartnerSelect",
      },
    }
  })
}
export default SoloPartnerSelect
</script>