<template>
  <div>
    <div v-show="!loading">
      <div v-if="formControl">
        <FormulateInput
          type="text"
          name="control_id"
          v-model="formControl.id"
          hidden
          validation="required"
        />
        <FormulateInput
          type="text"
          name="name"
          v-model="formControl.name"
          validation="required"
          hidden
          class="mb-4"
        />
        <FormulateInput
          type="text"
          name="control_label"
          v-model="formControl.label"
          label="Label"
          placeholder="Enter label here"
          validation="required"
          class="mb-4"
        />
        <FormulateInput
          type="select"
          name="control_alignment"
          v-model="formControl.alignment"
          :options="textAlignmentOptions"
          label="Text Alignment"
          validation="required"
          class="mb-4"
        />
        <FormulateInput
          type="select"
          name="control_size"
          v-model="formControl.size"
          :options="fontSizeOptions"
          label="Font Size"
          validation="required"
          class="mb-4"
        />
        <FormulateInput
          type="select"
          name="control_weight"
          v-model="formControl.weight"
          :options="fontWeightOptions"
          label="Font Weight"
          validation="required"
          class="mb-4"
        />
        <div class="flex flex-col overflow-x-auto">
          <label class="font-medium text-sm mb-1">Text Content</label>
          <wysiwyg class="bg-white" v-model="formControl.content" />
        </div>
      </div>
    </div>
    <div v-if="loading" class="w-full text-center overflow-y-auto px-12 text-lg inset-0 flex flex-col justify-center items-center">
      <font-awesome-icon icon="cog" spin size="3x" class="text-gray-400 mt-12"/>
      <h6 class="mt-4">Loading</h6>
    </div>
  </div>
</template>
<script>
import { HELPER } from '@/api/helper'
import _ from 'lodash'

export default {
  props: {
    watchedCurrentSection: {
      type: Object,
      default: null
    },
    watchedCurrentControl: {
      type: Object,
      default: null
    },
    uniqueId: {
      type: String,
      default: ''
    },
  },

  data: () => ({
    formControl: {},
    currentSection: {},
    required: false,
    loading: false,
    textAlignmentOptions: {
      'left': 'Left',
      'right': 'Right',
      'center': 'Center'
    },
    fontStyleOptions: {
      'not-italic': 'Regular',
      'italic': 'Italic',
    },
    fontSizeOptions: {
      'xs': 'Extra Small',
      'sm': 'Small',
      'base': 'Normal',
      'lg': 'Large',
      'xl': 'Extra Large',
      '2xl': '2XL',
      '3xl': '3XL',
      '4xl': '4XL',
      '5xl': '5XL',
      '6xl': '6XL',
      '7xl': '7XL',
      '8xl': '8XL',
      '9xl': '9XL',
    },
    fontWeightOptions: {
      'light': 'Light',
      'normal': 'Normal',
      'medium': 'Medium',
      'semibold': 'SemiBold',
      'bold': 'Bold'
    }
  }),

watch: {
    formControl: {
      deep: true,
      handler(newVal) {
        const formData = {
          control: newVal,
          sectionUniqueId: this.currentSection?.uniqueId
        }
        this.$emit('updateFormControl', formData)
      }
    },

    uniqueId(newVal) {
      this.updateSelectedData(newVal)
    },

    required(newVal) {
      if(newVal == true) {
        this.formControl.validation.push('required')
      } else {
        const index = this.formControl.validation.indexOf('required')
        this.formControl.validation.splice(index, 1)
      }
    }
  },

  created() {
    this.updateSelectedData()
  },

  methods: {
    addRequiredValidation() {
      this.formControl.validation.push('required')
    },
    
    async updateSelectedData() {
      this.loading = true
      
      this.currentSection = HELPER.cloneDeep(this.watchedCurrentSection)
      let control = HELPER.cloneDeep(this.watchedCurrentControl)

      if(!_.isEmpty(control)){
        this.formControl = control
        this.formControl.validation = control.validation || []
      } else {
        this.formControl = null
      }
      
      setTimeout(() => {
        this.loading = false
      }, 300)
    }
  }
}
</script>