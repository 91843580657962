<template>
  <PortalContainer @dataLoading="dataLoading">
    <div class="flex w-full h-screen" v-if="loading">
      <div class="m-auto">
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Loading...</span>
      </div>
    </div>
    <router-view :key="$route.fullPath" @updateLoading="updateLoading" class="account-view w-full" v-bind:class="{ hidden: loading }"></router-view>
  </PortalContainer>
</template>

<script>
import Spinner from '@/components/commons/ui/Spinner'
import PortalContainer from '@/components/containers/Portal.vue'

export default{
  name:"AccountPage",
  metaInfo: {
    title: 'Account',
  },
  components:{
    PortalContainer,
    Spinner
  },
  data(){
    return { loading:false }
  },
  watch: {
    loading: function (newV) {
      this.loading = newV;
    }
  },
  methods: {
    updateLoading(status) {
      this.loading = status;
    },
    dataLoading(loadingMessage) {
      this.$emit('dataLoading', loadingMessage);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
