<template>
  <div>
    <div v-if="loading" class="loading absolute mx-auto my-24 w-full h-full translate-y-2/4 flex flex-col items-center">
        <ui-spinner class="mx-auto mb-3"></ui-spinner>
        Loading....
    </div>

    <div v-if="!loading">
      <section>
         <div class="w-full">
            <button @click="showFormButtonsBtn" class="btn bg-gray-200 text-sm mb-4 float-left">
              <font-awesome-icon icon="plus"/>&nbsp; Add Document                  
              <font-awesome-icon v-if="!showFormButtons" icon="angle-down" class=" ml-2"/>
              <font-awesome-icon v-else icon="angle-up" class="ml-2"/>
            </button>

            <button @click="backToViewDocs(activeInvestigation)" class="btn bg-gray-200 text-sm mb-4 ml-2 float-left">
              <span v-if="!isFromAddDocs"><font-awesome-icon icon="arrow-left"/>&nbsp; Back </span>
              <span v-else>View</span> 
            </button>

            <button @click="submitDocuments" class="btn text-white bg-orange-200 w-60 text-sm mb-3 float-right" :class="submitLoading? 'bg-orange-100':''">
               <font-awesome-icon icon="save"/>&nbsp;
               <span v-if="!submitLoading"> Save Changes </span>
               <span v-else> Saving changes... </span>                     
            </button>
            <br/>
         </div>
         <div class="relative">
            <div v-if="showFormButtons" class="absolute bg-white rounded-md mx-auto md:divide-x-2 md:divide-y-0 flex-col divide-y p-4 pt-5 w-80 h-70 z-10 mt-3">
               <button @click="addNewDoc(docFormTypeList.IMMEDIATE_ACTION)" class="btn bg-gray-100 mb-2 h-10 border text-sm  w-full hover:text-orange-primary">
                  Immediate Action
               </button>
               <button @click="addNewDoc(docFormTypeList.INTERVIEW)" class="btn bg-gray-100 mb-2 h-10 border text-sm w-full hover:text-orange-primary">
                  Interview      
               </button>
               <button @click="addNewDoc(docFormTypeList.EVIDENCE_COLLECTION)" class="btn bg-gray-100 mb-2 h-10 border text-sm  w-full hover:text-orange-primary">
                  Evidence Collection
               </button>
               <button @click="addNewDoc(docFormTypeList.FINDINGS)" class="btn bg-gray-100 mb-2 h-10 border text-sm  w-full hover:text-orange-primary">
                  Findings          
               </button>
            </div>
         </div>
         <div v-if="mainLoading" class="relative">
            <div class="mx-auto my-24 w-full">
              <ui-spinner class="mx-auto mb-3"></ui-spinner>
            </div>
         </div>
      </section>     

      <section>
         <div class="flex w-full mt-5" v-if="!activeDocumentLoading">
            <aside class="w-36 border-r h-40">

               <div class="overflow-y-auto flex flex-col bg-gray-100 border-b">
                  <strong class="py-4 pl-4  text-gray-500 uppercase text-sm">Docs ({{createdDocuments.length}})</strong><hr>
               </div>

               <div v-if="!asideLoading">
                  <div v-if="createdDocuments.length && activeDocView" class="w-full overflow-y-auto h-60">
                     <div class="text-sm py-3 pl-3 border-b cursor-pointer"
                        v-for="(data, key) in createdDocuments" :key="key"
                        :class="[(activeDocView.docId == data.docId) ? 'bg-green-100' : '']"
                        @click="setActiveDocView(data)">
                        <nobr
                           v-if="data"                 
                           >{{ cleanDataType(data.docFormType).substring(0,6)+" .."  }}
                        </nobr>
                        <FolderIcon :a="activeDocView.docId" :b="data.docId"/>
                     </div>
                  </div>
                  <div v-if="!createdDocuments.length" class="w-full px-1 py-2 border-b border-gray-300 opacity-40">
                     <small><em>No docs found</em></small>
                  </div>
               </div>
               <div v-if="asideLoading">
                  <div class="loading translate-y-2/4 mt-12">
                     <ui-spinner class="mx-auto mb-3"></ui-spinner>
                  </div>
               </div>
            </aside>

            <div class="h-full w-full bg-gray-100">

               <div v-if="!activeDocViewLoading && createdDocuments.length && activeDocView" class="pb-4 px-5 pt-3 mb-5 h-auto">
                  <div class="float-right mt-1">
                     <button @click="editActiveDoc(activeDocView)" title="Edit Document" class="text-red-900 pb-8">
                        <span class="cursor-pointer">
                           <font-awesome-icon icon="edit" class="text-gray-600 hover:text-orange-primary"/>
                        </span>
                     </button>
                     <button @click="removeActiveDoc" title="Remove Document" class="text-red-900 pb-8 ml-4">
                        <span class="cursor-pointer">
                           <font-awesome-icon icon="trash" class="text-gray-600 hover:text-orange-primary"/>
                        </span>
                     </button>
                  </div>

                  <div class="mt-2">

                     <ImmediateActionView :activeDocView="activeDocView" v-if="activeDocView.docFormType == docFormTypeList.IMMEDIATE_ACTION"/>

                     <InterviewView :activeDocView="activeDocView" v-if="activeDocView.docFormType == docFormTypeList.INTERVIEW"/>

                     <EvidenceCollectionView :activeDocView="activeDocView" v-if="activeDocView.docFormType == docFormTypeList.EVIDENCE_COLLECTION"/>

                     <FindingsView :activeDocView="activeDocView" v-if="activeDocView.docFormType == docFormTypeList.FINDINGS"/>
                     
                  </div>
               </div>
               <div v-if="!createdDocuments.length" class="h-full w-full">
                  <div class="bg-white-100 h-80 border-orange-500 text-orange-700 p-4">
                     <p class="font-bold">Empty</p>
                     <p>Empty.</p>
                  </div>
               </div>
               <div v-if="activeDocViewLoading">
                  <div class="loading my-24 translate-y-2/4 flex flex-col items-center mx-auto py-20 h-auto mb-20 w-full pl-5 ">
                     <ui-spinner class="mx-auto mb-3"></ui-spinner>
                     Loading....
                  </div>
               </div>
            </div>
         </div>
         <div v-if="activeDocumentLoading">
            <div class="loading my-24 translate-y-2/4 flex flex-col items-center mx-auto py-20 h-auto mb-20 w-full pl-5 ">
               <ui-spinner class="mx-auto mb-3"></ui-spinner>
               Loading....
            </div>
         </div>
      </section>
   </div>

   <Modal ref="docModal" class="z-40" :title="modal.modalTitle" size="2xl" :height="modal.modalHeight">

      <FormulateForm @submit="saveDocument">
         
         <ImmediateActionForm
            ref="ImmediateActionForm"
            v-if="activeDocForm.docFormType == docFormTypeList.IMMEDIATE_ACTION"
            :isEditMode="isEditMode"
            :activeDocForm="activeDocForm"
         />

         <InterviewForm
            ref="InterviewForm"
            v-if="activeDocForm.docFormType == docFormTypeList.INTERVIEW"
            :isEditMode="isEditMode"
            :activeDocForm="activeDocForm"
         />

         <EvidenceCollectionForm
            ref="EvidenceCollectionForm"
            v-if="activeDocForm.docFormType == docFormTypeList.EVIDENCE_COLLECTION"
            :isEditMode="isEditMode"
            :activeDocForm="activeDocForm"
         />

         <FindingsForm
            ref="FindingsForm"
            v-if="activeDocForm.docFormType == docFormTypeList.FINDINGS"
            :isEditMode="isEditMode"
            :activeDocForm="activeDocForm"
         />

         <FormulateInput
            type="submit"
            label="Save"
            input-class="btn btn-primary w-full mt-2"
            :style="{backgroundColor: theme.secondaryColor}"
         />

         <button @click.prevent="closeModal" class="btn btn-cancel w-full mb-7">
            Cancel
         </button>

      </FormulateForm>
   </Modal>

  </div>
</template>
<script>
import { removeStringUnderscore } from '@/_helper'
import { DOCUMENT_FORM_TYPES } from '@/_helper/constants'
import { Modal } from '@/components/commons'
import {
   EvidenceCollectionForm,
   FindingsForm,
   ImmediateActionForm,
   InterviewForm
} from './documents/doc-forms'
import {
   EvidenceCollectionView,
   FindingsView,
   ImmediateActionView,
   InterviewView
} from './documents/doc-view-data'
import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import FolderIcon from '@/components/commons/icons/folder-icon-open-close'
import { mapGetters } from 'vuex'

export default{
   name: 'Documents',
   props: {
      activeInvestigation: null,
      isFromAddDocs: {
         default: false
      }
   },
   components: { 
      Modal, 
      EvidenceCollectionForm, 
      FindingsForm, 
      ImmediateActionForm, 
      InterviewForm,
      EvidenceCollectionView,
      FindingsView,
      ImmediateActionView,
      InterviewView,
      FolderIcon
   },
   data(){
      return{
         loading: true,
         submitLoading: false,
         mainLoading: false,
         asideLoading: false,
         interChangingData: [],
         showFormButtons: false,
         hasDocumentData: false,
         modal:{
            modalContent: false,
            modalTitle: '',
            modalId: '',
            modalHeight: '65vh'
         },
         activeDocForm: {
            docFormType: null
         },
         createdDocuments: [],
         values: null,
         activeDocumentLoading: false,
         activeDocViewLoading: false,
         activeDocView: null,
         docFormTypeList: {
            IMMEDIATE_ACTION: DOCUMENT_FORM_TYPES.IMMEDIATE_ACTION,
            INTERVIEW: DOCUMENT_FORM_TYPES.INTERVIEW,
            EVIDENCE_COLLECTION: DOCUMENT_FORM_TYPES.EVIDENCE_COLLECTION,
            FINDINGS: DOCUMENT_FORM_TYPES.FINDINGS
         },
         isEditMode: false,
         activeDocToEdit: []
      }
   },
   watch: {
      activeInvestigation: {
         deep: true,
            handler() {
            this.setInvestigationDocuments()
         }
      }
   },
   mounted(){
      this.loading = true
      this.setInvestigationDocuments()
      setTimeout(() => {
         this.loading = false
      }, 600)
   },
   methods: {
      setInvestigationDocuments(){
         if(_.isEmpty(this.activeInvestigation)){
            this.activeDocumentLoading = true
         } else {
            setTimeout(() => {
               this.activeDocumentLoading = false
            }, 500)
         }
         let Arr = [];
         if(!_.isEmpty(this.activeInvestigation?.findings)){
            Arr.push(...this.activeInvestigation.findings)
         }
         if(!_.isEmpty(this.activeInvestigation?.evidenceCollections)){
            Arr.push(...this.activeInvestigation.evidenceCollections)
         }
         if(!_.isEmpty(this.activeInvestigation?.immediateActions)){
            Arr.push(...this.activeInvestigation.immediateActions)
         }
         if(!_.isEmpty(this.activeInvestigation?.interviews)){
            Arr.push(...this.activeInvestigation.interviews)
         }
         this.createdDocuments = Arr
         if(this.createdDocuments.length){
            this.setActiveDocView(this.createdDocuments[0])
         }
      },
      setActiveDocView(item){
         this.activeDocViewLoading = true
         this.activeDocView = item
         setTimeout(() => {
            this.activeDocViewLoading = false
         }, 500)
      },
      removeActiveDoc(){
        if(confirm(`Are you sure you want to this ${this.activeDocView.docFormType}`)){
          this.createdDocuments = this.createdDocuments.filter(item => item.docId != this.activeDocView.docId)
          if(this.createdDocuments.length){
              this.setActiveDocView(this.createdDocuments[0])
          } else this.activeDocView = null
          this.reloadAside()
        } return
      },
      addNewDoc(doc){
        this.$refs.docModal.openModal();
        this.activeDocForm.docFormType = doc
        this.modal.modalTitle = `Create ${this.cleanDataType(doc)}`
        this.isEditMode = false
        this.showFormButtons = false
      },
      editActiveDoc(doc){
        this.$refs.docModal.openModal();
        this.activeDocForm = {...this.activeDocForm, ...doc} 
        this.activeDocForm.docFormType = doc.docFormType
        this.modal.modalTitle = `Edit ${this.cleanDataType(doc.docFormType)}`
        this.isEditMode = true
      },
      closeModal(){
        this.$refs.docModal.closeModal()
      },
      showFormButtonsBtn(){
        this.showFormButtons = !this.showFormButtons
      },
      cleanDataType(str){
        return removeStringUnderscore(str)
      },
      reloadAside(){
         this.asideLoading = true
         setTimeout(() => {
            this.asideLoading = false
         }, 400)
      },
      saveDocument(){
         let formValues, docId = uuidv4(), interviewData
         switch ( this.activeDocForm.docFormType ){
            case this.docFormTypeList.IMMEDIATE_ACTION:
               formValues = this.$refs.ImmediateActionForm.formValues
               break
            case this.docFormTypeList.INTERVIEW:
               interviewData = this.$refs.InterviewForm.formValues
               interviewData.attendees = this.$refs.InterviewForm.selectedAttendees
               formValues = interviewData
               break
            case this.docFormTypeList.EVIDENCE_COLLECTION:
               formValues = this.$refs.EvidenceCollectionForm.formValues
               break
            case this.docFormTypeList.FINDINGS:
               formValues = this.$refs.FindingsForm.formValues
               break
         }

         if(!this.isEditMode){
            const ext = {
               docFormType: this.activeDocForm.docFormType,
               docId: docId
            }
            formValues = {...formValues,  ...ext}
            this.createdDocuments.push(formValues)
         } else {
            const oldValues = this.createdDocuments.filter(item => item.docId == formValues.docId)[0]
            this.createdDocuments[this.createdDocuments.indexOf(oldValues)] = formValues
         }
         this.setActiveDocView(formValues, false)
         this.$refs.docModal.closeModal()
         this.showFormButtons = false
         this.reloadAside()
      },
      async submitDocuments(){
         this.submitLoading = true
         const me = this.$store.getters[`account/me`]
         const businessId = me?.business?._id

         let payload = {}
         payload.findings = this.createdDocuments.filter(item => item.docFormType == this.docFormTypeList.FINDINGS)
         payload.evidenceCollections = this.createdDocuments.filter(item => item.docFormType == this.docFormTypeList.EVIDENCE_COLLECTION)
         payload.immediateActions = this.createdDocuments.filter(item => item.docFormType == this.docFormTypeList.IMMEDIATE_ACTION)
         payload.interviews = this.createdDocuments.filter(item => item.docFormType == this.docFormTypeList.INTERVIEW)
         payload.id = this.activeInvestigation._id
         payload.businessId = businessId

         if(_.isEmpty(payload.findings)
            && _.isEmpty(payload.evidenceCollections)
            && _.isEmpty(payload.immediateActions)
            && _.isEmpty(payload.interviews)
         ){
            this.$store.commit('setDialogNotify',true)
            this.$store.commit('setMessageNotify',{state: 'error', message: 'No document found.'})
            this.submitLoading = false
            return false
         }
         await this.$store.dispatch('investigation/updateInvestigation', payload)
         .then(() =>{
            setTimeout(() =>{
               this.$store.commit('setDialogNotify',true)
               this.$store.commit('setMessageNotify',{state: 'success', message: 'Investigation successfully updated.'})
               this.$emit('backToViewDocs', payload.id)
            }, 600)
         })
         .catch((err) =>{
            this.submitLoading = false
            this.$store.commit('setDialogNotify',true)
            this.$store.commit('setMessageNotify',{state: 'error', message: err.message})
         })
      },
      backToViewDocs(item){
         this.$emit('backToViewDocs', item._id)
      }
   },
   computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>