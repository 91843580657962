export default {
  setAdditionalRules(state, value) {
    state.additionalRules.push(value);
  },

  setAFMrules(state, value) {
    state.AFMrules.push(value);
  },

  setDriversToAssociate(state, value) {
    state.driversToAssociate.push(value);
  },
};
