<template>
  <div>
    <div class="border-2 rounded-lg p-2 justify-center mb-6">
      <FormulateInput
        type="select"
        v-model="leftContent.value"
        label="Left Content"
        :options="[
          { value: '{{driverName}}', label: 'Driver Name' },
          { value: '{{inductionDate}}', label: 'Induction Date' },
          { value: '{{accreditationNumber}}', label: 'Accreditation Number' },
          // { value: '{{todayDate}}', label: 'Today(Date)' },
          { value: '{{adminNumber}}', label: 'Admin Number' },
        ]"
      />
    </div>

    <div class="border-2 rounded-lg p-2 justify-center mb-6">
      <FormulateInput
        type="select"
        label="Right Content"
        v-model="rightContent.value"
        :options="[
          { value: '{{driverName}}', label: 'Driver Name' },
          { value: '{{inductionDate}}', label: 'Induction Date' },
          { value: '{{accreditationNumber}}', label: 'Accreditation Number' },
          // { value: '{{todayDate}}', label: 'Today(Date)' },
          { value: '{{adminNumber}}', label: 'Admin Number' },
        ]"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { HELPER } from "@/api/helper";
export default {
  name: "document-part-controller",

  props: {
    editContent: {
      type: Object,
    },

    selectedType: {
      type: String,
    },

    selectedUUID: {
      type: String,
    },
  },

  data() {
    return {
      currentContainerVariables: [],
      currentContent: "",
      currentSelectedPart: false,
      currentSelectedIndex: -1,

      isLoading: false,

      leftContent: false,

      rightContent: false,
    };
  },

  mounted() {
    if (this.selectedUUID) {
      this.fetchCurrentPart(this.selectedUUID);
    }
  },

  computed: {
    docParts: {
      get() {
        return this.$store.state.accreditation.docParts;
      },
      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    contentModel: {
      get() {
        return this.currentSelectedPart?.contentPreview;
      },
      set(newVal) {
        this.currentSelectedPart.contentPreview = newVal;
      },
    },

    configuringStatus: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.selectedUUID;
          }
        );

        return model?.isConfiguring;
      },
    },

    contentVariables: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.selectedUUID;
          }
        );

        return model.contentVariables;
      },
    },
  },

  watch: {
    selectedUUID: function (data) {
      this.fetchCurrentPart(data);
    },
  },

  methods: {
    configureWildCards() {
      const isConfiguring =
        this.docParts[this.currentSelectedIndex]?.isConfiguring;

      const previews = this.docParts[this.currentSelectedIndex]?.contentPreview;

      if (!isConfiguring) {
        this.generateVariables(previews);
        this.docParts[this.currentSelectedIndex].isConfiguring = !isConfiguring;
      } else {
        this.docParts[this.currentSelectedIndex].content = [];
        this.docParts[this.currentSelectedIndex].contentVariables = [];

        this.docParts[this.currentSelectedIndex].isConfiguring = !isConfiguring;
      }
    },

    addWildCard() {
      const textContent =
        this.docParts[this.currentSelectedIndex].contentPreview;

      this.docParts[this.currentSelectedIndex].contentPreview =
        textContent + " {{variable}} ";
    },

    fetchCurrentPart(partUUID) {
      this.isLoading = true;
      const currentPart = _.find(this.docParts, (part) => {
        return part.uuid == partUUID;
      });

      if (currentPart) {
        const currentIndex = _.findIndex(this.docParts, (part) => {
          return part.uuid == partUUID;
        });

        this.leftContent = _.find(currentPart.content, (data) => {
          return data.part === "left";
        });

        this.rightContent = _.find(currentPart.content, (data) => {
          return data.part === "right";
        });

        this.currentSelectedPart = currentPart;
        this.currentSelectedIndex = currentIndex;
      } else {
        this.currentSelectedPart = false;
        this.currentSelectedIndex = -1;
      }
      this.isLoading = false;
    },

    generateVariables(data) {
      const arrContent = data.split(" ");

      let i;

      const paragraphVariables = [];

      for (i = 0; i < arrContent.length; i++) {
        const partId = "part-" + "section-" + HELPER.getUUIDv4();
        if (arrContent[i].match(/{{\s*[\w.]+\s*}}/g)) {
          paragraphVariables.push({ type: "", index: i, partId });
        }
      }

      this.docParts[this.currentSelectedIndex].content = arrContent;
      this.docParts[this.currentSelectedIndex].contentVariables =
        paragraphVariables;
    },

    updatePreview(data) {
      const previewIndex = _.find(this.contentVariables, (contentData) => {
        const model = contentData.partId === data;

        return model;
      });

      this.docParts[this.currentSelectedIndex].content[
        previewIndex.index
      ] = `{{${previewIndex.type}}}`;

      const contentArr = this.docParts[this.currentSelectedIndex].content;

      this.docParts[this.currentSelectedIndex].contentPreview =
        contentArr.join(" ");
    },
  },
};
</script>
