<template>
  <section v-show="dialog">
    <div 
      v-if="messageNotify" 
      id="toast-danger" 
      style="z-index: 119!important" 
      class="flex items-center absolute w-full max-w-xs p-4 space-x-4 text-gray-500 bg-white divide-x divide-gray-200 rounded-lg shadow top-5 right-5 dark:text-gray-400 dark:divide-gray-700 space-x dark:bg-gray-800" 
      :class="{
         'animate-bounce ': messageNotify.animate || messageNotify.state == 'success'
      }" 
      role="alert">

      <div class="inline-flex items-center justify-center flex-shrink-0 w-8 rounded-lg h-8" 
         :class="{
            'text-green-500 bg-green-100  dark:bg-green-800 dark:text-green-200': messageNotify.state == 'success',
            'text-red-500 bg-red-100  dark:bg-red-800 dark:text-red-200': messageNotify.state == 'error',
            'text-blue-400 bg-blue-100  dark:bg-red-800 dark:text-red-200': messageNotify.state == 'info',
            'text-orange-400 bg-orange-100  dark:bg-orange-800 dark:text-orange-200': messageNotify.state == 'warning'
         }" 
      >
                        
         <svg v-if="messageNotify.state == 'success'" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
         
         <svg v-if="messageNotify.state == 'error'" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>

         <svg v-if="messageNotify.state == 'warning'"  class="w-5 h-5" fill="#fff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>

         <svg v-if="messageNotify.state == 'info'"  class="w-5 h-5" fill="#fff" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>

         </div>
         <div class="ml-3 text-sm font-normal w-full">{{messageNotify.message}}</div>
         <button @click.prevent="handleCloseDialog(false)" type="button" class="float-right ml-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" data-dismiss-target="#toast-success" aria-label="Close">
            <span class="sr-only">Close</span>
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
         </button>
      </div>
   </section> 
</template>

<script>
import { mapState } from 'vuex';
export default {
   computed: {
      ...mapState({
         dialogNotify: state => state.app.dialogNotify,
         messageNotify: state => state.app.messageNotify
      }),
      dialog: {
         set(value) {
               this.handleCloseDialog(value);
               return value;
         },
         get() {
               return this.dialogNotify;
         }
      }
   },
   watch: {
      dialog(v) {
         if (v) {
            setTimeout(() => {
               this.$store.commit('setDialogNotify', false);
            }, 4000);
         }
      },
   },
   methods: {
      handleCloseDialog(value) {
         this.$store.commit('setDialogNotify', value);
      }
   },
   created() {
      this.$store.commit('setDialogNotify', true);
   }
};
</script>
