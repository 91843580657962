<template>
  <div>
    <div class="text-center mt-3">
      <div class="font-semi text-black-primary text-2xl mb-4">
        {{term.name}}
      </div>
    </div>
    <div class="mb-5 bg-white pt-3 pb-8 px-3" v-if="term">
      <div class="flex items-center font-bold mb-5">
        <div class="flex items-center">
          <div class="flex items-center">
            <div class="flex flex-col">
              <div class="flex-auto bg-white">Logmaster Terms</div>
            </div>
          </div>
        </div>
        <div v-if="!term.isLocked && !isEdit" class="ml-auto cursor-pointer">
          <font-awesome-icon
            icon="edit"
            class="text-1xl"
            @click.prevent="editContractTermWithComponents({name: term.name,contractTermId: term.id, type: 'logmaster'})"
          />
        </div>
      </div>

      <div class="mb-9" v-for="(section, indexOfLogmaster) in term.logmasterTerm.sections" :key="section.uniqueId">
        <h3 class="mb-1 text-base font-black">{{indexOfLogmaster+1}}. {{section.headline}}</h3><hr>
        <div class="mt-2">
          <div v-if="section.subHeadline.length !== 0"><small class="italic text-small inline-block pt-4">{{section.subHeadline}} </small><hr></div>
          <div v-for="(control) in section.controls" :key="control.uniqueId">
            <div class="text-xs mb-4 mt-1 font-medium">
              <FieldContainer :component="control" :isDisplayValue="true"/>
            </div>
          </div>     
        </div>
      </div>
    </div>

    <Collapse class="bg-white py-10 px-3" :isCollapsed="collapsed">
      <template slot="collapseHeader">
        <div class="flex items-center font-bold">
          <div class="flex items-center px-5">
            <div class="flex items-center">
              <div class="icon-container mr-2 bg-gray-700">
                <font-awesome-icon
                  icon="file"
                  style="height: 15px; width: 15px"
                />
              </div>
              <div class="flex flex-col">
                <div class="flex-auto bg-white px-3">Contract Terms Check Consent</div>
              </div>
            </div>
          </div>
          <div class="ml-auto cursor-pointer pr-6">
            <font-awesome-icon
              :icon="collapsed ? 'caret-down' : 'caret-right'"
              class="text-2xl"
              @click="onCollapsed()"
            />
          </div>
        </div>
      </template>
      <template #default="{props}">
        <Transition name="slide">
          <div v-if="props.collapsed" class="items-center mt-5 ml-12">
            <div class="mb-3 text-xs">
              This End User Licence Agreement (EULA) is a legal EULA between you and Logmaster Pty Ltd
              (ACN 635 942 022) of Level 2, 23 Foster Street, Surry Hills, 2010, NSW, Australia (Logmaster, we, us or our)
              and applies to your use of the Logmaster Platforms (Platforms). The Platforms are owned and operated by Logmaster.
            </div>
            <div class="mb-3 text-xs">
              By accessing and/or using the Logmaster Platforms and related services, you agree to be bound by the terms of this EULA
              which includes our Privacy Policy. You should review these terms and our Privacy Policy carefully, and immediately cease
              accessing and/or using the Logmaster Platforms if you do not agree to these terms.
            </div>

            <div v-show="term.signature" class="w-full flex flex-col items-end p-4">
              <img
                class="signature"
                :src="term.signature"
                alt=""
              />
              <div class="w-1/2 border-t mt-3 text-center">Signature</div>
            </div>
          </div>
        </Transition>
      </template>
    </Collapse>

    <div class="mb-5 bg-white pt-4 pb-4 px-3" v-if="!isEdit && !term.isLocked">
      <div class="text-left">
        <div class="font-bold text-black-primary mb-6">
          Signature
        </div>
      </div>
      <div>
        <VueSignaturePad
            type="signature"
            :id="123123"
            width="100%"
            class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mt-1 mb-2"
            ref="signaturePad"
            :options="{ onEnd }"
        />
      </div>
      <br/>
    </div>

    <div class="flex space-x-2 items-left flex-row-reverse" v-if="!isEdit && !term.isLocked">
      <button @click.prevent="undo()" class="btn btn-light bg-transparent text-blue-700 font-semibold py-2 px-4 border border-blue-500 rounded">
        Undo
      </button>
      <button @click.prevent="save()" class="btn btn-primary bg-transparent text-blue-700 font-semibold py-2 px-4 border border-blue-500 rounded"
      :style="{backgroundColor: theme.secondaryColor}"
      >
        Save
      </button>
    </div>

    <br/><br/>
  </div>
</template>
<script>
import { Collapse } from "@/components/commons";
import FieldContainer from '@/views/builder/containers/FieldContainer.vue';
import { mapGetters } from 'vuex'

  export default {
    components: { Collapse, FieldContainer },
    props: {
      activeTerm: {
        type: Object,
        default: () => {}
      },
      isEdit: {
        type: Boolean,
        required: false,
        default: false,
      }
    },
    watch: {
      activeTerm: {
        deep: true,
        handler() {
          this.setActiveTerm()
        }
      }
    },
    emits: ['editContractTermWithComponents'],
    created() {
      this.setActiveTerm();
      if(this.term.partnerTerm && this.term.logmasterTerm && this.term.logmasterTerm.sections) {
        this.partnerIndex = this.term.logmasterTerm.sections.length + 1;
      }
      if(this.term.resellerTerm && this.term.partnerTerm && this.term.partnerTerm.sections) {
        this.resellerIndex = this.partnerIndex + this.term.partnerTerm.sections.length;
      } else {
        this.resellerIndex = this.partnerIndex;
      }
    },
    data() {
      return {
        term: {},
        collapsed: true,
        resellerIndex: 0,
        partnerIndex: 0,
        signature: null,
      };
    },
    methods: {
      setActiveTerm(){
        this.term = this.activeTerm;
      },
      onCollapsed() {
        this.collapsed = !this.collapsed;
      },
      editContractTermWithComponents(contractTermId){
        this.$emit('editContractTermWithComponents', contractTermId);
      },
      onEnd() {
        const { data } = this.$refs.signaturePad.saveSignature();
        this.signature = data;
      },
      undo() {
        this.$refs.signaturePad.undoSignature();
      },
      async save() {
        const payload = {
          id: this.activeTerm.id,
          signature: this.signature
        };
        this.$store.dispatch('contract/saveSignatureContractTermGroup', payload)
            .then((res) => {
              this.$emit('formSubmitted', res);
              this.submitting = false;
              this.toast('success', "Signed successfully!");
              window.location.href = `/business/contracts/terms`;
            }).catch((err) => {
          this.submitting = false;
          this.toast('error', err.message);
        })
      },
      toast(state,message){
        this.$store.commit('setDialogNotify',true);
        this.$store.commit('setMessageNotify',{state, message});
      },
    },
    computed: {
     ...mapGetters('theme', {
			theme: 'getColorScheme'
		})
  }
  }
</script>

<style>
  .signature {
    width: 450px !important;
    height: 75px;
  }
</style>
