<template>
  <div class="min-h-full flex-row account-view">
    <div v-if="true" class="w-full min-h-full flex-row items-left">
        <div>
            <div class="text-center">
                <div v-if="canEdit" class="flex text-right justify-end"> 
                    <StyledIconedLink @click.prevent="onClickEdit(connection.id)" class="flex cursor-pointer mx-1.5"
                    :iconHoverColor="theme.secondaryColor"
                    >
                        <font-awesome-icon icon="edit" class="my-auto"/>
                    </StyledIconedLink>
                </div>
                <div class="font-bold text-gray-900 text-2xl"> 
                    {{connection.businessName}}
                </div>
            </div>
            <div class="mb-5 w-full border-b pb-1">
                <div class="text-xs font-bold">Reseller Details</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{connection.tradingName}}</div>
                <div class="text-xs">Trading Name</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{connection.abn}}</div>
                <div class="text-xs">NZBN</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{connection.contactEmail}}</div>
                <div class="text-xs">Contact Email Address</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{connection.contactPhoneNumber}}</div>
                <div class="text-xs">Contact Phone Number</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{connection.businessAddress}}</div>
                <div class="text-xs">Business Address</div>
            </div>
            <div class="mt-8 mb-5 w-full border-b pb-1">
                <div class="text-xs font-bold">Support Details</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{connection.supportEmail}}</div>
                <div class="text-xs">Support Email Address</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{connection.supportPhone}}</div>
                <div class="text-xs">Support Phone Number</div>
            </div>
        </div>

        <Collapse :isCollapsed="collapsed">
            <template slot="collapseHeader">
                <div class="flex items-center font-bold">
                    <div class="flex items-center">
                        <div class="flex items-center">
                        <div class="icon-container mr-2 bg-gray-700">
                            <font-awesome-icon
                            icon="dollar-sign"
                            style="height: 15px; width: 15px"
                            />
                        </div>
                        <div class="flex flex-col">
                            <div class="flex-auto">Pricing Details</div>
                        </div>
                        </div>
                    </div>
                    <div class="ml-auto cursor-pointer mr-3">
                        <font-awesome-icon
                            :icon="collapsed ? 'caret-down' : 'caret-right'"
                            class="text-2xl"
                            @click="onCollapsed()"
                        />
                    </div>
                </div>
            </template>
            <template #default="{props}">
            <div class="pb-4 overflow-y-scroll">
                <div class="overflow-x-auto h-full">
                    <Transition name="slide">
                        <div v-if="props.collapsed">
                            <div>
                            <div class="pb-4 flex w-full">
                                <aside class="w-56 h-full border-r">
                                    <div class="overflow-y-auto flex flex-col border-b mb-2">
                                        <p class="text-sm text-gray-700 py-4 px-3">Versions</p>
                                    </div>
                                    <div class="mt-2 overflow-y-auto h-80 w-56">
                                        <div>
                                            <button 
                                                class="text-left w-full px-1 py-2 border-b border-gray-200"
                                                :class="{ 'bg-green-100': selectedPricing == 'active' }"
                                                type="button"
                                                @click="updatedSelectedPricing('active')"
                                                >
                                                <font-awesome-icon
                                                    v-if="selectedPricing == 'active'"
                                                    :icon="['fas', 'folder-open']"
                                                    class="my-auto mx-1.5"
                                                    size="sm"
                                                >
                                                </font-awesome-icon>
                                                <font-awesome-icon 
                                                    v-else
                                                    :icon="['fas', 'folder']"
                                                    class="my-auto mx-1.5"
                                                    size="sm"
                                                >
                                                </font-awesome-icon>
                                                <small>Active
                                                    <sup 
                                                        v-show="hasPreActive"
                                                        class="bg-red-500 py-1 px-2 rounded-xl text-white"
                                                        >Expire within {{activeExpireDate}}
                                                    </sup>
                                                </small>
                                            </button>
                                        </div>
                                        <div class="mt-5 py-2 border-b">
                                            <small>Edit History</small>
                                        </div>
                                        <div v-if="!hasPreActive">
                                            <div class="w-full px-1 py-2 border-b border-gray-200 opacity-40">
                                                <small><em>No edit history found</em></small>
                                            </div>
                                        </div>
                                        <div>
                                            <button
                                                v-show="hasPreActive"
                                                class="text-left w-full px-1 py-2 border-b border-gray-200" 
                                                type="button"
                                                :class="{ 'bg-green-100': selectedPricing == 'pre-active' }"
                                                @click="updatedSelectedPricing('pre-active')"
                                                >
                                                <font-awesome-icon 
                                                    v-if="selectedPricing == 'pre-active'"
                                                    :icon="['fas', 'folder-open']"
                                                    class="my-auto mx-1.5"
                                                    size="sm"
                                                >
                                                </font-awesome-icon>
                                                <font-awesome-icon 
                                                    v-else
                                                    :icon="['fas', 'folder']"
                                                    class="my-auto mx-1.5"
                                                    size="sm"
                                                >
                                                </font-awesome-icon>
                                                <small>Pre-Active 
                                                    <sup class="bg-orange-50 py-1 px-2 rounded-xl text-black">Effect within {{preActiveEffectDate}}
                                                </sup></small>
                                            </button>
                                        </div>
                                        <div v-for="(unused, index) in unusedVersionPricng" :key="index">
                                             <button
                                                @click="updatedSelectedPricing('default', unused)"
                                                class="text-left w-full px-1 py-2 border-b border-gray-200"
                                                :class="{ 'bg-green-100': selectedUnusedId == unused[0].version }"
                                                >
                                                <font-awesome-icon
                                                    v-if="selectedUnusedId == unused[0].version"
                                                    :icon="['fas', 'folder-open']"
                                                    class="my-auto mx-1.5"
                                                    size="sm"
                                                >
                                                </font-awesome-icon>
                                                <font-awesome-icon 
                                                    v-else
                                                    :icon="['fas', 'folder']"
                                                    class="my-auto mx-1.5"
                                                    size="sm"
                                                >
                                                </font-awesome-icon>
                                                <small>Version {{unused[0].version}}</small>
                                            </button>
                                        </div>
                                    </div>
                                </aside>
                                <div class="w-full flex flex-col">
                                <div class="flex justify-between items-center border-b mb-2">
                                    <div class="w-full">
                                    <div class="flex border-b">
                                        <div class="w-1/2">
                                            <p class="text-sm text-gray-700 p-4">Pricing Details</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <div v-if="!isPricingLoading" class="p-4 mb-12">
                                    <div class="rounded-lg overflow-hidden">
                                        <table v-if="pricingData.length > 0" class="table-auto text-center leading-normal w-full border-collapse border border-gray-200 mb-6 pb-5">
                                            <thead>
                                                <tr>
                                                    <th class="w-56 bg-gray-100 border-b text-left px-5 py-3 border-gray-200 text-sm font-semibold text-gray-600 uppercase">
                                                        Buy Pricing
                                                    </th>
                                                    <th class="bg-gray-200 px-2">Monthly</th>
                                                    <th class="bg-gray-200 px-2">Yearly</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(buy, index) in pricingData" :key="index">
                                                    <td class="text-left px-5 py-2 text-sm bg-gray-100">
                                                    {{ buy.name }}
                                                    </td>
                                                    <td class="px-2 py-1">
                                                    ${{ parseFloat(buy.pricing.monthly).toFixed(2) }}
                                                    </td>
                                                    <td class="px-2 py-1">
                                                    ${{ parseFloat(buy.pricing.yearly).toFixed(2) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table v-if="rrpData.length > 0" class="table-auto text-center leading-normal w-full border-collapse border border-gray-200 mb-6 pb-5">
                                            <thead>
                                                <tr>
                                                    <th class="w-56 bg-gray-100 border-b text-left px-5 py-3 border-gray-200 text-sm font-semibold text-gray-600 uppercase">
                                                        RRP
                                                    </th>
                                                    <th class="bg-gray-200 px-2">Monthly</th>
                                                    <th class="bg-gray-200 px-2">Yearly</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(rrp, index) in rrpData" :key="index">
                                                    <td class="text-left px-5 py-2 text-sm bg-gray-100">
                                                    {{ rrp.name }}
                                                    </td>
                                                    <td class="px-2 py-1">
                                                    ${{ parseFloat(rrp.pricing.monthly).toFixed(2) }}
                                                    </td>
                                                   
                                                    <td class="px-2 py-1">
                                                    ${{ parseFloat(rrp.pricing.yearly).toFixed(2) }}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table v-if="prepaidPricing && prepaidRrpData" class="table-auto rounded-lg text-center leading-normal w-full border-collapse border border-gray-200 mb-6 pb-5">
                                            <thead>
                                                <tr>
                                                    <td class="bg-gray-100 border-b text-sm font-semibold py-3 w-40 text-left pl-6 pr-2" rowspan="6">
                                                        Package
                                                    </td>
                                                    <th class="px-6 py-2 bg-gray-200">
                                                        Packs
                                                    </th>
                                                    <th class="px-6 py-2 bg-gray-200">Buy</th>
                                                    <th class="px-6 py-2 bg-gray-200">RRP</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <td class="text-left px-5 py-2 text-sm bg-gray-100" rowspan="6">
                                                    <p>Pre-paid EWD</p>
                                                </td>
                                                <tr>
                                                    <td class="pl-8 py-1 text-left">
                                                        1 Day
                                                    </td>
                                                    <td class="px-2 py-1">
                                                        ${{prepaidRrpData.pricing.oneDay}}
                                                    </td>
                                                    <td class="px-2 py-1">
                                                        ${{prepaidPricingData.pricing.oneDay}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-7 py-1 text-left">
                                                        5 Days
                                                    </td>
                                                    <td class="px-2 py-1">
                                                        ${{prepaidRrpData.pricing.fiveDays}}
                                                    </td>
                                                    <td class="px-2 py-1">
                                                        ${{prepaidPricingData.pricing.fiveDays}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-6 py-1 text-left">
                                                        10 Days
                                                    </td>
                                                    <td class="px-2 py-1">
                                                        ${{prepaidPricingData.pricing.tenDays}}
                                                    </td>
                                                    <td class="px-2 py-1">
                                                        ${{prepaidRrpData.pricing.tenDays}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-6 py-1 text-left">
                                                        25 Days
                                                    </td>
                                                    <td class="px-2 py-1">
                                                        ${{prepaidRrpData.pricing.twentyFiveDays}}
                                                    </td>
                                                    <td class="px-2 py-1">
                                                        ${{prepaidPricingData.pricing.twentyFiveDays}}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td class="pl-6 py-1 text-left">
                                                        50 Days
                                                    </td>
                                                    <td class="px-2 py-1">
                                                        ${{prepaidRrpData.pricing.fiftyDays}}
                                                    </td>
                                                    <td class="px-2 py-1">
                                                        ${{prepaidPricingData.pricing.fiftyDays}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div v-if="isPricingLoading" class="mb-80 p-4 h-80">
                                    Loading...
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>     
        </div>
        </template>
    </Collapse>
    </div> <br/><br/>
    <div v-if="!true" class="w-full min-h-full text-center mt-5">
      <h1>Not authorized to view terms RRPVersions. Please contact your business admin.</h1>
    </div>
    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
        <EditPartnerRRPForm 
            :buyPricing="pricing"
            :prepaid="prepaidPricing"
            :connection="connection"
            @closeModal="closeModal"
        />
    </Modal>
   </div>
</template>
<script>
import { Collapse } from "@/components/commons";
import {DEFAULT_MODAL_HEIGHT} from '@/_helper/constants';
import {Modal} from '@/components/commons';
import {EditPartnerRRPForm} from "@/components/forms/partner";
import moment from 'moment';
import _ from 'lodash';
import { mapGetters } from 'vuex'
import {StyledIconedLink} from '@/styled-components'

export default {
    components: {Modal, EditPartnerRRPForm, Collapse, StyledIconedLink},
      props: {
        detailsId:{
            type: String,
            default: '',
        },
        connectionType:{
            type: String
        },
        canEdit:{
            type: Boolean,
            default: true
        },
    },
    data(){
      return{
        connection: {},
        pricingData: [],
        rrpData: [],
        loading: false,
        modalHeight: DEFAULT_MODAL_HEIGHT,
        modalTitle: '',
        pricing: null,
        prepaidPricing: null,
        activePricing: [],
        collapsed: true,
        selectedPricing: 'active',
        arrVersions: [],
        hasPreActive: false,
        preActiveEffectDate: null,
        activeExpireDate: null,
        isPricingLoading: false,
        unusedVersionPricng: [],
        selectedUnusedId: false,
      }
    },
    async mounted(){
       await this.init();
    },
    methods: {
        async init(){
            await this.$emit("updateLoading", true);
            this.isPricingLoading = true;
            let conDetails;
            switch (this.connectionType) {
                case 'partner':
                    conDetails = await this.$store.dispatch(`partner/getPartner`, {id: this.detailsId})
                    break;
                case 'reseller':
                    conDetails = await this.$store.dispatch(`reseller/getReseller`, {id: this.detailsId})
                    break;
            }

            if(conDetails){
                const allPricingVersions = await this.$store.dispatch(`standardpricing/getStandardPricingVersionsByMongoId`, conDetails._id);
                if(allPricingVersions){

                    Object.keys(allPricingVersions).forEach(key => {
                        this.arrVersions.push(allPricingVersions[key])
                    });

                    this.setActive();

                    this.connection = this.parsePartner(conDetails)
                }
                this.$emit("updateLoading", false);
                this.isPricingLoading = false;
            }
        },
        parsePartner(connection){
            return {
                id: connection._id,
                abn: connection.persona.abn,
                businessName: connection.persona.businessName,
                tradingName: connection.persona.tradingName,
                businessAddress: connection.persona.businessAddress,
                currentStep: connection.persona.currentStep,
                contactPhoneNumber: connection.persona.contactPhoneNumber,
                contactEmail: connection.persona.contactEmail,
                contactUserName: connection.persona.contactUserName,
                supportEmail: connection.supportEmail,
                supportPhone: connection.supportPhone
            }
        },
        editRRP(){
            this.$refs.modal.openModal();
            this.modalTitle = 'Edit RRP'
        },
        closeModal(){
            this.$refs.modal.closeModal();
        },
        onCollapsed() {
            this.collapsed = !this.collapsed;
        },
        onClickEdit(conId){
            switch (this.connectionType) {
                case 'partner':
                    this.$emit('editPartner', conId);
                    break;
                case 'reseller':
                    this.$emit('editReseller', conId);
                    break;
            }
        },
        async updatedSelectedPricing(pricing, versionId = null){
            if(pricing){
                this.selectedPricing = pricing; 
            }
            await this.setActive(versionId);
        },
        setActive(unused){
            this.isPricingLoading = true;
            let activePricing, isActivePricing=[], isPreActivePricing=[], unusedVersionPricng=[];

            this.arrVersions.forEach((item) => {
                // Is Active
                let active = item.filter(obj=>obj.isActive == true);
                if(!_.isEmpty(active)){
                    let expireGiven = moment(active[0].expiryDate);
                    let current = moment().startOf('day');
                    let expireDate = parseInt(moment.duration(expireGiven.diff(current)).asDays());
                    this.activeExpireDate = `${expireDate} ${'day'}${expireDate !== 1 ? 's' : ''}`;
                    isActivePricing.push(active);
                }

                // Is Pre-active
                let preActive = item.filter(obj=>obj.isPreActive == true);
                if(!_.isEmpty(preActive)){
                    let effectGiven = moment(preActive[0].effectiveDate);
                    let current = moment().startOf('day');
                    let effDate = parseInt(moment.duration(effectGiven.diff(current)).asDays());
                    this.preActiveEffectDate = `${effDate} ${'day'}${effDate !== 1 ? 's' : ''}`;
                    this.hasPreActive = true;
                    isPreActivePricing.push(preActive);
                }

                // Is unused versions
                let unusedVersions = item.filter(obj => obj.isActive !== true && obj.isPreActive != true);
                if(!_.isEmpty(unusedVersions)){
                    unusedVersionPricng.push(unusedVersions);
                }
            });
            this.unusedVersionPricng = unusedVersionPricng;

            // Fixed Selection
            switch (this.selectedPricing){
                case 'active':
                    activePricing = isActivePricing[0];
                    this.selectedUnusedId = null;
                    break;
                case 'pre-active':
                    activePricing = isPreActivePricing[0];
                    this.selectedUnusedId = null;
                    break;
                default:
                    activePricing = unused;
                    this.selectedUnusedId = unused[0].version
            }
        
            // Selected standard pricing object
            if(activePricing){
                const data = activePricing.filter(item => item.name === 'Pre-Paid EWD');
                if(data) {
                    this.prepaidPricingData = data.filter(item => item.isRrp === false)[0];
                    this.prepaidRrpData = data.filter(item => item.isRrp === true)[0];
                }
                this.prepaidPricing = {
                    pricing: this.prepaidPricingData?.pricing,
                    rrp: this.prepaidRrpData?.pricing,
                    standardPricingMasterId: this.prepaidRrpData?.standardPricingMaster?._id
                }

                const nPricing = activePricing.filter(item => item.name != 'Pre-Paid EWD');
                if(nPricing) {
                    this.pricingData = nPricing.filter(item => item.isRrp === false);
                    this.rrpData = nPricing.filter(item => item.isRrp === true);

                    // Merge pricing
                    const pricing  = [];
                    this.pricingData.forEach((i) => {
                        this.rrpData.forEach(j => {
                            if(i.name === j.name){
                                i.rrp = j.pricing;
                                pricing.push(i)
                            }
                        });
                    });
                    this.pricing = pricing;
                }
            }
            setTimeout(()=>{this.isPricingLoading = false},250);
        }
    },
    computed: {
        ...mapGetters('theme', {
            theme: 'getColorScheme'
        })
    }
}
</script>