<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit" class="relative">
      <div class="w-full" v-if="loading">
        <div class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center">
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300">Fetching data...</span>
        </div>
      </div>
      <div class="flex items-center font-semibold mb-1">
        <div class="mr-2 bg-gray-700 rounded-full flex justify-center items-center" style="width: 30px; height: 30px;">
          <font-awesome-icon icon="list" color="white" size="xs"/>
        </div>
        <div class="flex flex-col">Event Details</div>
      </div>
      <div class="border-b-2 border-gray-300 mb-4"></div>
      <div class="grid grid-cols-2 gap-4" v-if="!soloDriver">
        <FormulateInput
          label="Driver"
          name="selectedDriver"
          type="driver-select"
          placeholder="Select a driver"
          element-class="mb-2 mt-1"
          validation="required"
          :getOptionKey="option => option.driver.udi"
          :getOptionLabel="option => option.driver.driverName"
          :filterable="false"
          :clearable="false"
          @selected="values.selectedDriver = $event"
        />
        <div class="w-full">
          <label class="text-sm mb-2 block font-semibold form-label pr-2 text-neutral-dark">Event Date</label>
          <DatePicker
            ref="childDatePicker" 
            class="event-datepicker"
            :date="values.selectedDateRange"
            placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
            :displayEvent="true"
            :displayBreach="true"
            :selectedDriver="values.selectedDriver"
            @selected="values.selectedDateRange = $event"
            range noNavigation :clearable="false"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <FormulateInput
          label="Fatigue Plan"
          name="selectedPlan"
          type="general-select"
          element-class="mb-2 mt-1"
          placeholder="Select a plan"
          :clearable="false"
          :options="fatiguePlans"
          :reduce="option => option.value"
          validation="required"
        />
        <FormulateInput
          label="Time Zone"
          name="selectedTimezone"
          type="general-select"
          element-class="mb-2 mt-1"
          placeholder="Select a timezone"
          :clearable="false"
          :options="timezones"
          :reduce="option => option.value"
          validation="required"
        />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <div class="w-full" v-if="soloDriver">
          <label class="text-sm mb-2 block font-semibold form-label pr-2 text-neutral-dark">Event Date</label>
          <DatePicker
            ref="childDatePicker" 
            class="event-datepicker"
            :date="values.selectedDateRange"
            placeholder="YYYY-MM-DD ~ YYYY-MM-DD" 
            :displayEvent="true"
            :displayBreach="true"
            :selectedDriver="values.selectedDriver"
            @selected="values.selectedDateRange = $event"
            range noNavigation :clearable="false"
          />
        </div>
        <FormulateInput
          v-if="soloDriver"
          label="Vehicle"
          name="selectedVehicle"
          type="text"
          validation="required"
        />
        <FormulateInput
          v-if="!soloDriver"
          :label="isManualDriver ? 'Vehicle (Optional)' : 'Vehicle'"
          name="selectedVehicle"
          type="vehicle-select"
          placeholder="Select a vehicle"
          element-class="mb-2 mt-1"
          :getOptionKey="option => option._id"
          :getOptionLabel="option => option.vehiclePlate"
          :filterable="false"
          :clearable="false"
          validation="validateVehicle"
          :validation-rules="{ 
            validateVehicle: ({ value }) => {
              if (isManualDriver) return true;
              else if (!value) return false;
              else return true;
            } 
          }"
          :validation-messages="{ validateVehicle: 'Vehicle is required.' }"
        />
      </div>

      <div v-if="showTwoupDetails()">
        <div class="flex items-center font-semibold mb-1 mt-5">
          <div class="mr-2 bg-gray-700 rounded-full flex justify-center items-center" style="width: 30px; height: 30px;">
            <font-awesome-icon icon="user" color="white" size="xs"/>
          </div>
          <div class="flex flex-col">Two-Up Driver</div>
        </div>
        <div class="border-b-2 border-gray-300 mb-4"></div>
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            label="Full Name"
            name="twoUpName"
            type="text"
            validation="required"
          />
          <FormulateInput
            label="UDI/PG No."
            name="twoUpUDI"
            type="text"
            validation="required"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            label="Licence Number"
            name="twoUpLicenseNumber"
            type="text"
            validation="required"
          />
          <FormulateInput
            label="Fatigue Plan"
            name="twoUpFatiguePlan"
            type="general-select"
            element-class="mb-2 mt-1"
            placeholder="Select a plan"
            :clearable="false"
            :options="fatiguePlans"
            :reduce="option => option.value"
            validation="required"
          />
        </div>
      </div>

      <div class="items-center justify-between">
        <FormulateInput
          language="en"
          type="submit"
          label="Next"
          input-class="btn btn-primary w-full"
          :disabled="loading"
          :style="{backgroundColor: theme.secondaryColor}"
        />
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>

<style lang="scss" scoped>
.event-datepicker,
.event-datepicker::v-deep > div {
  @apply w-full;
}
.event-datepicker::v-deep .lhv-datepicker.no-nav .lhv-input {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
</style>

<script>
import _ from 'lodash';
import moment from 'moment';
import Spinner from '@/components/commons/ui/Spinner'
import DatePicker from '@/components/commons/field/DatePicker';
import { STATES } from '@/constants/location'
import { TIMEZONE } from '@/constants/location'
import { FATIGUE_PLAN } from '@/constants/fatigue-plan'
import { mapGetters } from 'vuex'

export default {
  name: 'Manual-Event-Event-Details',
  components: { Spinner, DatePicker },
  props: {
    soloDriver: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      twoUpFatigue: [FATIGUE_PLAN.BFM_TWO_UP, FATIGUE_PLAN.STANDARD_TWO_UP],
      fatiguePlans: [],
      timezones: TIMEZONE,
      states: STATES,
      loading: false,
      isManualDriver: false,
      values: {
        selectedDriver: this.soloDriver ? this.soloDriver : null,
        selectedDateRange: [
          moment().format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        ],
        selectedPlan: null,
        selectedTimezone: null,
        selectedVehicle: null,
        twoUpName: null,
        twoUpUDI: null,
        twoUpLicenseNumber: null,
        twoUpLicenseState: null,
        twoUpFatiguePlan: null
      },
      errorMessage:'',
      schema: [],
      
      disabledBefore: null,
      eventDates: [],
      breachDates: [],
    }
  },
  async mounted() {
    _.mapValues(FATIGUE_PLAN, (val, key) => this.fatiguePlans.push({ value: val, label: key.replace(/_/g, ' ') }) );

    if (this.soloDriver) {
      this.values.selectedTimezone = this.soloDriver?.driver?.driversBaseTimeZone || null;
    }
  },
  watch: {
    'values.selectedDriver': {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal != newVal) {
          this.isManualDriver = newVal.status == 'linked-non-ewd';
          this.values.selectedPlan = newVal?.driver?.driversBaseFatiguePlan || null;
          this.values.selectedTimezone = newVal?.driver?.driversBaseTimeZone || null;
        }
      }
    },
  },
  methods: {
    showTwoupDetails() {
      return !_.isEmpty(this.values['selectedPlan']) && this.twoUpFatigue.includes(this.values['selectedPlan']);
    },
    async onSubmit() {
      if (!_.isNil(this.values.selectedVehicle)) {
        this.values.selectedVehicle = (typeof this.values.selectedVehicle == 'object') ? this.values.selectedVehicle.vehiclePlate : this.values.selectedVehicle;
      }
      this.$emit('nextStep', { eventDetails: JSON.parse(JSON.stringify(this.values)), isManualDriver: this.isManualDriver })
    },
  },
   computed: {
     ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
  },
}
</script>