<template>
  <div>
    <div>
      <Tabs>
        <Tab title="Active">
          <ActiveFatigueDashboard></ActiveFatigueDashboard>
        </Tab>
        <Tab title="Next Night Rest">
          <NightRestFatigueDashboard></NightRestFatigueDashboard>
        </Tab>
        <Tab title="Next Twenty Four Hour Rest">
          <TwentyFourHourRestFatigueDashboard></TwentyFourHourRestFatigueDashboard>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>

<script>
import Tabs from "@/components/commons/tab/Tabs.vue";
import Tab from "@/components/commons/tab/Tab.vue";
import ActiveFatigueDashboard from "./ActiveFatigueDashboard.vue";
import NightRestFatigueDashboard from "./NightRestFatigueDashboard.vue";
import TwentyFourHourRestFatigueDashboard from "./TwentyFourHourRestFatigueDashboard.vue";
export default {
  name: "Fatigue-Dashboard",
  components: {
    ActiveFatigueDashboard,
    NightRestFatigueDashboard,
    TwentyFourHourRestFatigueDashboard,
    Tab,
    Tabs,
  },
};
</script>
