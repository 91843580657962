<template>
    <div class="mb-16">
      <FormulateForm v-model="values" :schema="schema" @submit="onSubmit" #default="{ hasErrors }">
        <div class="items-center justify-between">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{backgroundColor: theme.secondaryColor}"
          />
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
    </div>
</template>

<script>
import { convertAddressObject } from '@/_helper'
import schema from './new-driver-schema';
//import _ from 'lodash';
import { mapGetters } from 'vuex'

export default {
  name: 'New-Driver-Form',
  props: {
    msg: String
  },
  data() {
    this.$emit('schema', schema)
    return {
      loading: false,
      values: {},
      schema: schema,
      errorMessage:''
    };
  },
  methods: {
    updateSchema: function(schema) {
      this.schema = schema
    },
    async onSubmit() {
        this.loading = true
        let newDriver = JSON.parse(JSON.stringify(this.values));
        if(newDriver){
            if(newDriver['businessId']){
              let business = await this.$store.dispatch(`business/getBusiness`, {id: newDriver['businessId']})
              if(business) newDriver['persona'] = business['persona']
            }else{
              let partner = await this.$store.dispatch(`partner/getPartner`,newDriver['partnerId'])
              if(partner) newDriver['persona'] = partner['persona']
            }
            newDriver['isDemoAccount'] = false;
            newDriver['driverRecordKeeperAddress'] = newDriver['persona']['businessAddress']
            newDriver['create_password'] = newDriver['create_password'][0];
            newDriver['billingAddress'] = convertAddressObject(newDriver['billingAddress'][0])
            newDriver['supportEmail'] = newDriver['persona']['contactEmail']
            newDriver['supportPhone'] = newDriver['persona']['contactPhoneNumber']

            console.log('newDriver--',newDriver);
            this.$store.dispatch(`driver/createDriver`, newDriver).then(driver => {
              this.loading = false;
              if(driver && driver._id){
                console.log('New Driver - ',driver);
                location.reload();
              }
            }).catch(error => {
              this.errorMessage = `Something went wrong please try again.`;
              if(error.message) this.errorMessage = error.message[0]
            });
        }

    },
  },
   computed: {
     ...mapGetters('theme', {
    theme: 'getColorScheme'
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
