<template>
    <div class="flex flex-col p-6 bg-white shadow-md hover:shodow-lg rounded mb-4">
      <div class="flex items-center justify-between">
        <a :href="link">
            <div class="flex items-center">
              <font-awesome-icon icon="info-circle" class="text-3xl text-black-primary"/>
              <div class="flex flex-col ml-4">
                  <div class="text-orange font-medium leading-none">{{title}}
                  </div>
                  <p class="text-sm text-gray-600 leading-none mt-1" v-if="description">{{description}}</p>
              </div>
         
            </div>
        </a>
        <!-- <button @click="acknowledge(id)" class="btn btn-primary">Acknowledge</button> -->
        <span v-show="isCompleted" class="bg-orange-100 text-white text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded dark:bg-orange-200 dark:text-white">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check-square" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="mr-1 svg-inline--fa fa-check-square fa-w-14 fa-sm"><path fill="currentColor" d="M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z" class=""></path></svg>
          Acknowledged
        </span>
        <sup v-if="formattedDate" class="float-right pt-2"><em>
            {{formattedDate}}
            </em></sup>
      </div>
    </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'NotificationItem',
  props: {
    id: String,
    title: String,
    description: String,
    link: String,
    isCompleted: Boolean,
    createdAt: {
      required: true
    },
  },
  data(){
    return {
      formattedDate: null
    }
  },
  mounted(){
    this.formattedDate = moment(this.createdAt).fromNow();
  }
}
</script>
