<template>
  <div class="w-full overflow-y-auto border border-gray-200 rounded">
    <table class="min-w-full">
      <thead class="bg-gray-50">
        <tr class="text-gray-700 text-xs tracking-wider uppercase">
          <th scope="col" class="py-3 px-5 text-left">Breach Time</th>
          <th scope="col" class="py-3 px-5 text-left">Time of Breach</th>
          <th scope="col" class="py-3 px-5 text-left">Option</th>
          <th scope="col" class="py-3 px-5 text-left">Period</th>
          <th scope="col" class="py-3 px-5 text-left">Work/Rest</th>
          <th scope="col" class="py-3 px-5 text-left">Counting Point Start</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="breaches.length == 0" class="bg-white border-b text-xs text-gray-500 font-medium">
          <td colspan="10" class="py-3 px-5 text-sm text-center">
            No breaches this day
          </td>
        </tr>
        <tr
          v-for="(breach, index) in breaches"
          :key="index"
          class="bg-white border-b text-xs text-gray-500 font-medium"
        >
          <td class="py-3 px-5 text-black-primary capitalize">
            {{ breach.breachTime || '' }}
          </td>
          <td class="py-3 px-5 text-black-primary capitalize">
            {{ formatTime(breach.time, breach.timezone) }}
          </td>
          <td class="py-3 px-5">
            {{ breach.option }}
          </td>
          <td class="py-3 px-5">
            {{ breach.period }}
          </td>
          <td class="py-3 px-5">
            {{ breach.type }}
          </td>
          <td class="py-3 px-5">
            {{ breach.startPoint ? formatTime(breach.startPoint, breach.timezone) : '' }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
    name: "Breach",
    props: {
        data: Array
    },
    data() {
      return {
        breaches: []
      }
    },
    mounted() {
      this.breaches = this.data.reverse();
    },
    methods: {
      capitalizeLevel(level) {
        return _.startCase(level.replace('-', ' '));
      },
      formatTime(time, timezone){
        return moment(time).utcOffset(timezone).format('YYYY-MM-DD HH:mm:ssZ')
      },
    }
};
</script>