var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"loading absolute mx-auto w-full h-full translate-y-2/4 flex flex-col items-center"},[_c('ui-spinner',{staticClass:"mx-auto"}),_vm._v(" Generating modules options... ")],1):_vm._e(),(!_vm.loading)?_c('FormulateForm',{on:{"submit":_vm.onSubmit},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}},[_c('div',{staticClass:"items-center justify-between"},[_c('FormulateInput',{staticClass:"custom-options checkbox",attrs:{"options":_vm.corePlatformLicencesOptions,"type":"checkbox","placeholder":"Select an option","label":"Select a Core Platform Licences","name":"selectedCPL"},model:{value:(_vm.cplOptionSelectionModel),callback:function ($$v) {_vm.cplOptionSelectionModel=$$v},expression:"cplOptionSelectionModel"}}),(_vm.showCPLSeatCount)?_c('div',{staticClass:"p-5 border rounded-lg group mb-10"},[_c('div',{staticClass:"seatCountTitle pb-5 font-bold bg-gray-50 group-title"},[_vm._v(" Set Core Platform Licences Seat Count and Target ")]),(_vm.setCPLSeatCountLoading)?_c('div',{staticClass:"text-center"},[_c('ui-spinner',{staticClass:"mx-auto"}),_vm._v(" Generating seat count form... ")],1):_vm._e(),(!_vm.setCPLSeatCountLoading)?_c('div',[_c('FormulateInput',{staticClass:"w-1/2",attrs:{"type":"number","label":"EWD seat count","name":"corePlatformLicencesCount","placeholder":"Input number of seat(s)","validation":"^required","validation-messages":{
              required: 'Core Platform Licences seat is required',
            }}}),(_vm.isFatigueSelected)?_c('FormulateInput',{staticClass:"formulate-input",attrs:{"type":"checkbox","name":"billedExternally","label":"Customize seat count for fatigue compliance","label-class":"formulate-input-label formulate-input-label--after","input-class":"","wrapper-class":"formulate-input-wrapper","outer-class":"","help":"*Check this box if you want fatigue compliance seat count to be different from EWD","element-class":"formulate-input-element formulate-input-element--checkbox"},model:{value:(_vm.customSeatCountFC),callback:function ($$v) {_vm.customSeatCountFC=$$v},expression:"customSeatCountFC"}}):_vm._e(),(_vm.customSeatCountFC)?_c('FormulateInput',{staticClass:"w-1/2",attrs:{"type":"number","label":"Fatigue Compliance seat count","name":"fatigueComplianceSeatCount","placeholder":"Input number of seat(s)","validation":"^required","validation-messages":{
              required: 'Fatigue Compliance seat count is required',
            }}}):_vm._e(),_c('FormulateInput',{staticClass:"custom-options",attrs:{"name":"contractTarget","options":[
              { value: 'driver', label: 'Drivers' },
              { value: 'vehicle', label: 'Vehicles' },
            ],"type":"radio","placeholder":"Select an option","label":"Select a target for seat count","validation":"^required","validation-messages":{
              required: 'Contract Target is required.',
            }}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"border-t border-neutral-light mt-8"},[_c('div',{staticClass:"flex items-center justify-end w-full mt-4 space-x-4"},[_c('FormulateInput',{attrs:{"type":"button","label":"Back","input-class":"btn btn-cancel w-full"},on:{"click":function($event){$event.preventDefault();return _vm.onPrevious()}}}),_c('FormulateInput',{style:({ backgroundColor: _vm.theme.secondaryColor }),attrs:{"type":"submit","label":"Next","input-class":"btn btn-primary w-full"}})],1)])],1)]):_vm._e(),(_vm.errorMessage)?_c('div',{staticClass:"text-red-500 text-center mb-3"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }