<template>
    <Stepper 
        :stepperSteps="stepperSteps" 
        @completed-step="completeStep" 
        @active-step="isStepActive" 
        :showNavigation="false"  
        @closeRefresh="closeRefresh"
      />
  </template>
  
  <script>
  import { Stepper } from '@/components/commons'
  import { SelectFile, SelectDriver, ImportDriver }  from './stepper-component'
  export default {
    name: 'New-Driver-Import',
    props: {
      isNonEwdImport: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    components: {Stepper},
    data(){
        return{
            stepperSteps: [
                 {
                    stepIcon: 'upload',
                    stepTitle: 'Upload File',
                    stepSequence: 'first',
                    stepComponent: SelectFile,
                    isCompleted: true,
                    isNonEwdImport: this.isNonEwdImport
                },
                {
                    stepIcon: 'users',
                    stepTitle: 'Select Drivers',
                    stepSequence: 'second',
                    stepComponent: SelectDriver,
                    isCompleted: false,
                    isNonEwdImport: this.isNonEwdImport
                },
                {
                    stepIcon: 'download',
                    stepTitle: 'Import',
                    stepSequence: 'third',
                    stepComponent: ImportDriver,
                    isCompleted: false,
                    isNonEwdImport: this.isNonEwdImport
                },
            ],
            activeStep: 0,
        }
    },
     methods: {
        completeStep(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    step.isCompleted = true;
                }
            })
        },
        isStepActive(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    if(step.isCompleted === true) {
                        step.isCompleted = false;
                    }
                }
            })
        },
        closeRefresh(){ 
          this.$emit('closeRefresh')
        }
    },
    
  }
  </script>