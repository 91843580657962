<template>
    <div class="relative">
        <div class="mt-2 mb-5 font-bold text-l" :style="{color: theme.primaryColor}">Overview</div>
        <div class="flex flex-col md:flex-col lg:flex-row">
            <div class="flex flex-col xs:w-full sm:w-full lg:w-1/2 mr-2">
                <div class="flex w-full flex-col sm:flex-col md:flex-col lg:flex-row">
                    <Card :cardBackgroundColor="'white'" :cardWidth="'100%'" class="sm:w-full sm:mr-2 md:mr-5">
                        <template slot="cardContent">
                            <div class="flex items-center w-full justify-between pb-3">
                                <div class="icon-container">
                                    <font-awesome-icon icon="wrench" class="text-2xl" :style="{color: theme.primaryColor}"/>
                                </div>
                                <div class="text-xs font-bold text-right" :style="{color: theme.accentColor}">Last 24 Hours Pre-Start Checks</div>
                            </div>
                            <div class="pb-3 text-center font-bold text-3xl text-card-orange">
                                {{ preStartCheckCount }}
                            </div>
                            <div class="text-sm" :style="{color: theme.primaryColor}">24 hours</div>
                        </template>
                    </Card>
                    <Card :cardBackgroundColor="'white'" :cardWidth="'100%'" class="sm:w-full sm:ml-2 md:mr-5">
                        <template slot="cardContent">
                            <div class="flex items-center w-full justify-between pb-3">
                                <div class="icon-container">
                                    <font-awesome-icon icon="book-medical" class="text-2xl" :style="{color: theme.primaryColor}"/>
                                </div>
                                <div class="text-xs font-bold text-right" :style="{color: theme.primaryColor}">Last 24 Hours Fitness Declarations</div>
                            </div>
                            <div class="pb-3 text-center font-bold text-3xl text-card-red">
                              {{ fitnessDeclarationCount }}
                            </div>
                            <div class="text-sm" :style="{color: theme.primaryColor}">24 hours</div>
                        </template>
                    </Card>
                </div>
            </div>
            <div class="sm:w-full md:w-full lg:w-1/2">
                <div class="flex flex-col">
                    <div>
                        <Card :cardBackgroundColor="'white'" :cardWidth="'100%'" class="mr-5 py-5">
                            <template slot="cardContent">
                                <div class="font-bold text-l mb-3" :style="{color: theme.primaryColor}">Status</div>
                                <div class="p-2">
                                    <div class="flex justify-around py-2">
                                        <div class="text-center">
                                            <div class="font-bold" :style="{color: theme.primaryColor}">{{ driverCount }}</div>
                                            <div class="text-sm" :style="{color: theme.primaryColor}">Drivers</div>
                                        </div>
                                        <div class="text-center">
                                            <div class="font-bold" :style="{color: theme.primaryColor}">{{ vehicleCount }}</div>
                                            <div class="text-sm" :style="{color: theme.primaryColor}">Registered Vehicles</div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div>
                <Card :cardBackgroundColor="theme.logmasterBackgroundColor1" :cardWidth="'100%'" class="sm:mx-0 md:mr-5">
                    <template slot="cardContent">
                        <div class="mb-3">
                            <span class="text-logmaster-500 font-bold text-l" :style="{color: theme.logmasterTextColor3}">
                                Notifications
                            </span>
                            <span class="float-right text-xs pr-2">
                                <a @click="refreshNotifications()"><font-awesome-icon icon="retweet"></font-awesome-icon></a>
                            </span>
                            <span v-if="notifications.length > 5" class="float-right text-xs pr-2">
                                <a href="/account/notifications">More</a>
                            </span>
                        </div>
                        <div class="flex w-full h-full" v-if="notificationLoading">
                            <div class="m-auto">
                                <Spinner class="mx-auto mb-2"></Spinner>
                                <span class="font-semibold text-gray-300">Loading...</span>
                            </div>
                        </div>
                        <table v-if="notifications && !notificationLoading"  class="min-w-full leading-normal">
                            <tbody>
                              <tr v-for="(notification, index) in notifications.slice(0, 5)" :key="index" class="hover:bg-gray-200 border-b text-sm">
                                  <td v-if="notification.title">
                                    <p><b>{{ notification.title }}</b></p>
                                  </td>
                                  <td v-if="notification.description">
                                      <a class="block py-3 px-0 overflow-hidden"
                                      >{{ notification.description }} </a>
                                  </td>
                                  <td>
                                    <a v-if="notification.createdAt" class="block py-3 px-0 overflow-hidden text-xs float-right"
                                    >{{showDate(notification.createdAt)}}</a>
                                  </td>
                                  <td>
                                    <a v-if="notification.createdAt" class="block py-3 px-0 overflow-hidden text-xs float-right"
                                    >{{getHumanDate(notification.createdAt)}}</a>
                                  </td>
                                  <td v-if="!notification.showForm" >
                                    <a class="block py-3 px-0 overflow-hidden text-xs font-bold float-right max-w-2xl"
                                    >{{ notification.note }}</a>
                                  </td>
                                  <td v-if="notification.actionItems.length > 0 && !notification.showForm" title="Edit Note" class="mr-0 p-0">
                                      <button @click="notification.showForm = !notification.showForm" class="btn text-sm btn-sm btn-primary ml-4" :style="{backgroundColor: theme.secondaryColor}">
                                          <font-awesome-icon icon="sticky-note"></font-awesome-icon>
                                      </button>
                                  </td>
                                  <td v-if="notification.showForm" title="Note" class="mr-0 p-0">
                                    <FormulateInput type="text" v-model="notification.note" placeholder="Note" class="text-xs ml-4 pt-4"/>
                                  </td>
                                  <td v-if="notification.showForm" title="Note" class="mr-0 p-0">
                                      <button @click="saveNote(notification); notification.showForm = false" class="btn text-sm btn-sm btn-primary ml-4" title="Save" :style="{backgroundColor: theme.secondaryColor}">
                                          <font-awesome-icon icon="check"></font-awesome-icon>
                                      </button>
                                      <button @click="notification.showForm = false" class="btn text-sm btn-sm btn-primary ml-4" title="Close" :style="{backgroundColor: theme.secondaryColor}">
                                        <font-awesome-icon icon="times"></font-awesome-icon>
                                      </button>
                                  </td>
                                  <td v-if="notification.actionItems.length > 0" title="Acknowledge" class="mr-0 p-0">
                                    <button @click="acknowledge(notification)" class="btn text-sm btn-sm btn-primary ml-4" :style="{backgroundColor: theme.secondaryColor}">
                                      <font-awesome-icon icon="trash"></font-awesome-icon>
                                    </button>
                                  </td>
                                  <td title="View" class="mr-0 p-0">
                                    <button @click="view(notification)" class="btn text-sm btn-sm btn-primary ml-4" :style="{backgroundColor: theme.secondaryColor}">
                                      <font-awesome-icon icon="eye"></font-awesome-icon>
                                    </button>
                                  </td>
                              </tr>
                            </tbody>
                        </table>
                        <div v-if="notifications.length === 0">
                            <p>No notification found.</p>
                        </div>
                    </template>
                </Card>
            </div>
        </div>
        <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
            <div v-if="modal.content === 'view'">
                <BreachDetailsView
                    :activeBreach="activeBreach"
                />
            </div>
        </Modal>
        <Modal ref="pricingModal" :title="modalTitle" size="3xl" :height="pricingModalHeight" :permanent-modal="false">
            <div class="py-8 px-4 mx-auto w-full max-w-full h-full lg:py-16 lg:px-4 overflow-auto">
                <div class="w-full px-4">
                    <div class="text-center mx-auto mb-[60px] lg:mb-20 max-w-[510px]">    
                        <h2 class="font-bold text-3xl sm:text-4xl md:text-[40px] text-black mb-4">Bill Summary</h2>
                        <p class="text-base text-body-color">Confirm your billing to start using our services.</p>
                    </div>
                </div>
                <BusinessContractConfirmationForm :active-data="me" :is-demo="isDemo" />
            </div>
        </Modal>

        <Modal ref="startDemoModal" :title="modalTitle" size="3xl" :height="pricingModalHeight" :permanent-modal="false">
            <div class="py-8 px-4 mx-auto w-full max-w-full h-full lg:py-16 lg:px-4 overflow-auto">
                <div class="w-full px-4">
                    <div class="text-center mx-auto mb-[60px] lg:mb-20 max-w-[510px]">    
											<h2 class="font-bold text-3xl sm:text-4xl md:text-[40px] text-black mb-4">Start the full experience</h2>
											<p class="text-base text-body-color">To ensure you have a full experience before starting your contract we are giving you a <b>{{ demoOption }}</b> demo.</p>
                    </div>
                </div>
                <BusinessContractConfirmationForm :active-data="me" :is-demo="isDemo" />
            </div>
        </Modal>


        <Modal ref="paymentFailedModal" size="lg" :height="pricingModalHeight">
            <PaymentFailedForm
            :payment-method="paymentMethod"
            @closeModal="closeModal"
            :amount="amount"
            :parent-entity-id="parentEntityId"
            :suspension-date="suspensionDate"
            :account-suspended="isAccountSuspended"
            :entity-details="entityDetails"
            />
        </Modal>

        <Modal ref="paymentUpdateModal" size="lg" :height="pricingModalHeight">
            <PaymentUpdateForm
            role="business"
            @closeModal="closeModal"
            :entity-details="entityDetails"
            :amount="amount"
            :parent-entity-id="parentEntityId"
            :suspension-date="suspensionDate"
            :account-suspended="isAccountSuspended"
            />
        </Modal>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { formatDate } from '@/_helper';
import { Card, Modal } from '@/components/commons';
import Spinner from '@/components/commons/ui/Spinner';
import BreachDetailsView from '@/components/view/breach';
import BusinessContractConfirmationForm from '@/components/forms/business/business-contract-confirmation-form'
import { PaymentFailedForm, PaymentUpdateForm } from '@/components/forms/payment'

export default {
    name: "Card-Component",
    components: { Card, Modal, Spinner, BreachDetailsView, BusinessContractConfirmationForm,
    PaymentFailedForm,
    PaymentUpdateForm },
    data() {
      return {
        pricingModalHeight: '',
        modalTitle: "",
        preStartCheckCount: 0,
        fitnessDeclarationCount: 0,
        driverCount: 0,
        vehicleCount: 0,
        activeBreach: {},
        modal: {
          title: '',
          content: null,
          height: '50vh',
        },
        notifications: [],
        notificationLoading: false,
        me: {},
        showAccountAlert: false,
        parentEntityId: '',
        suspensionDate: '',
        isAccountSuspended: false,
        paymentMethod: {},
        contract: {},
        amount: 0,
        demoOption: '',
        isDemo: false,
        entityDetails: {}
      }
    },
    async mounted() {
        this.$emit("updateLoading", true);

        let query = {
            skip: 0,
            limit: 10,
            filter: {
                status: { $in: ['linked', 'linked-non-ewd', 'contractor', 'pending'] }
            }
        }
        const me = this.$store.getters[`account/me`];
        this.me = me
        // Reads from businessId to get business site id, otherwise use main business id
        const businessId = me.businessId? me.businessId: me.business?._id

        await this.checkContractAndPayment();
        let driverContract = await this.$store.dispatch(`business/getContractByBusiness`, { uid : businessId, query})
        this.driverCount = driverContract?.metadata?.total || 0;

        query.filter =  {
            isActive: { $eq: true }
        }
        const vehicles = await this.$store.dispatch(`vehicle/getVehicleByEntityId`, { entityId: businessId, query});
        this.vehicleCount = vehicles?.metadata?.total || 0;

        let notifications = await this.$store.dispatch(`notification/getAllUnAcknowledgedNotifications`);
        if(!notifications){
            this.notifications = [];
        } else {
            const proxySite = this.$store.getters[`account/proxySite`];
            if (!_.isEmpty(proxySite) && _.isObject(proxySite)) {
                // Filter only notications that belongs to the switched site
                notifications = notifications.filter(notification => notification?.siteId && notification?.siteId == proxySite._id);
            }

            this.notifications = this.formatItem(notifications);
        }
        query = {
            skip: 0, 
            limit: 2000,
        }

        query.filter = {
            "createdAt" : {
                $lt: new Date(),
                $gte: new Date(new Date().setDate(new Date().getDate()-1))
            }
        };

        const fitnessDeclarations = await this.$store.dispatch(`driver/getFitToDriveByBusinessId`,{businessId, query});
        this.fitnessDeclarationCount = fitnessDeclarations?.metadata?.count || 0;

        query.filter = {
            "createdAt" : {
                $lt: new Date(),
                $gte: new Date(new Date().setDate(new Date().getDate()-1))
            }
        };

        const preStartChecks = await this.$store.dispatch(`vehicle/getPrestartByBusinessId`,{businessId, query});
        this.preStartCheckCount = preStartChecks?.metadata?.count || 0;

        this.$emit("updateLoading", false);
    },
    methods: {
        async checkContractAndPayment() {
            if(this.me.contract.activationOption === 'recurring_payment') {
                this.contract = await this.me?.contract
                this.entityDetails.role = this.contract.contractType == 'business' ? 'business' : 'driver'
                this.entityDetails.entityId = this.contract.businessMongoId
                if(!this.contract.hasRecurringPayment && !this.contract.contractAccepted || !this.contract.startDate) {
                    if(this.contract.businessMetaData.demoOption === 'NO_DEMO') {
                        this.$refs.pricingModal.toggleModal();
                        this.isDemo = false
                    } else {
                        this.isDemo = true
                        this.demoOption = this.contract.businessMetaData.demoOption
                        this.$refs.startDemoModal.toggleModal();
                    }
                } else {
                    this.parentEntityId = this.me.business?.entityId
                    if(this.me.contract.hasRecurringPayment == true && this.me.contract.lastPaymentSuccessful == false) {
                        this.showAccountAlert = true
                        await this.getPaymentMethod(this.me.business?._id);
                    } else if(this.me.contract.status.name == 'Cancelled') {
                        this.showAccountAlert = true
                        console.log("Contract expiring")
                    }
                    else {
                        await this.getActivePaymentMethod(this.me.business?._id);
                    }
                }
            } else {
                this.contract = this.me?.contract

            }
        },
        async getActivePaymentMethod(businessId) {
            console.log("Test", businessId);
          await this.$store.dispatch(`payment/getPaymentMethodByEntityId`, businessId)
            .then((response) => {
                console.log("Test Success", response);
              if(response) {
                this.paymentMethod = response
                this.pageLoading = false
              } else {
                this.showAccountAlert = true
                this.errorMessage = "No active payment method found."
                this.$refs.paymentUpdateModal.toggleModal();
                this.pageLoading = false
              }
            })
            .catch((err) => {
                console.log("Test Fail");
              this.showAccountAlert = true
              this.errorMessage = err
              this.$refs.paymentUpdateModal.toggleModal();
              this.pageLoading = false
            })
        },
        async getPaymentMethod(businessId) {
          await this.$store.dispatch(`payment/getPaymentMethodByEntityId`, businessId)
            .then(async (response) => {
              this.paymentMethod = response
            //   let total = 0
            //   this.contract.businessModules.forEach((object) => {
            //     total += this.getActualPrice(object) * object.minimums
            //   })
            //   let today = moment(new Date(), 'YYYY-MM-DD')

            //   let dateDiff;
            //   switch(this.contract.contractDuration.name.toUpperCase()) {
            //     case "YEARLY":
            //       dateDiff = today.diff(moment(this.contract.lastPaymentDate, 'YYYY-MM-DD'), 'month');
            //       if(dateDiff <= 0) dateDiff = 1
            //     break;
            //     case "MONTHLY":
            //       dateDiff = 1
            //     break;
            //   }
              //get unpaid invoice
              let contractInvoice = await this.$store.dispatch(`billinginvoice/getContractUnpaidInvoice`, this.contract._id)
              this.showAccountAlert = true
              this.amount = contractInvoice.total
              console.log(this.amount)
              this.suspensionDate = moment(this.contract.suspensionDate).format('MMMM DD, YYYY')
              this.isAccountSuspended = this.contract.status.name == 'Suspended' ? true : false
              this.pageLoading = false
              this.$refs.paymentFailedModal.toggleModal();
            })
            .catch((err) => {
              console.log(err)
              this.showAccountAlert = true
              this.errorMessage = err
              this.$refs.paymentUpdateModal.toggleModal();
              this.pageLoading = false
            })
        },

        getActualPrice(modules) {
        let amount;
        switch(this.contract.billingPeriod.name.toUpperCase()) {
            case "MONTHLY":
            this.billPeriod = "month"
            amount = modules.price.monthly.toFixed(2)
            break;
            case "QUARTERLY":
            this.billPeriod = "quarter"
            amount = modules.price.quarterly.toFixed(2)
            break;
            case "YEARLY":
            this.billPeriod = "year"
            amount = modules.price.yearly.toFixed(2)
        }

        return amount;
        },

        getHumanDate : function (date) {
            return date ? moment(date).fromNow() : ''
        },
        showDate(date) {return formatDate(date)},
        async acknowledge(notification){
            const payload = {
                isCompleted: true,
                id: notification.actionItems[notification.actionItems.length-1]._id,
            }
            await this.$store.dispatch(`notification/updateItemStatus`,payload)
            .then(()=> {
                this.$store.commit('setDialogNotify',true);
                this.$store.commit('setMessageNotify',{state:'success',message: 'Notification successfully acknowledged.'});
                this.notifications = this.notifications.filter(n => n.id !== notification.id);
            }).catch((err) => {
                this.$store.commit('setDialogNotify',true);
                this.$store.commit('setMessageNotify',{state:'error',message:err.message});
            })
        },
        async saveNote(notification){
            const payload = {
                note: notification.note,
                isCompleted: false,
                id: notification.actionItems[notification.actionItems.length-1]._id,
            }
            await this.$store.dispatch(`notification/updateItemStatus`,payload)
                .then(()=> {
                  this.$store.commit('setDialogNotify',true);
                  this.$store.commit('setMessageNotify',{state:'success',message: 'Note successfully saved.'});
                }).catch((err) => {
                  this.$store.commit('setDialogNotify',true);
                  this.$store.commit('setMessageNotify',{state:'error',message:err.message});
                })
        },
        closeModal() {
          this.$refs.modal.closeModal();
          this.$refs.paymentFailedModal.closeModal();
          this.$refs.pricingModal.closeModal();
          this.$refs.startDemoModal.closeModal();
        },
        formatItem(items) {
            let nItems = [];
            if(items){
                items.map(item => {
                    const note = item['actionItems'][item.actionItems.length-1] ? item['actionItems'][item.actionItems.length-1]['note'] : '';
                    const vehicle = {
                        id:item['_id'],
                        actionItems:item['actionItems'] || [],
                        createdAt:item['createdAt'] || '',
                        description:item['description'] || '',
                        metadata:item['metadata'] || '',
                        title:item['title'] || '',
                        note: note || '',
                        showForm: false,
                    }
                    nItems.push(vehicle)
                });
            }
            return nItems;
        },
        view(notification) {
            switch (notification.title) {
                case 'Fitness Declaration Concern':
                    this.viewFitnessDeclaration();
                    break;
                case 'Prestart Check Concern':
                    this.viewPreStart();
                    break;
                case 'Driver on Breaches':
                    this.viewBreach(notification);
                    break;
                case 'Driver Breach':
                    this.viewBreach(notification);
                    break;
                case 'Vehicle Registration':
                    this.viewVehicle();
                    break;
                case 'Licence Expiry':
                    this.viewDriver();
                    break;
                case 'Event Modification':
                    this.viewEventModification();
                    break;
                default:
                    this.$store.commit('setDialogNotify',true);
                    this.$store.commit('setMessageNotify',{state:'error',message:"Details Unavailable!"});
            }
        },
        async viewBreach(notification){
            this.$refs.modal.openModal();
            if(notification.metadata.entityJson && notification.metadata.entityJson.breach) {
                const payload = { id: notification.metadata.entityJson.breach};
                const breach = await this.$store.dispatch(`report/getBreach`, payload);
                this.modal.content = 'view';
                this.modal.title = 'Breach Info';
                this.activeBreach =  {
                  breachData: breach,
                  driverData: notification.metadata.entityJson
                }
            } else {
                this.$refs.modal.closeModal();
                this.$store.commit('setDialogNotify',true);
                this.$store.commit('setMessageNotify',{state:'error',message:"Unable to display breach"});
            }
        },
        viewPreStart() {
            this.$router.push("/business/vehicles-prestart-checks");
        },
        viewFitnessDeclaration() {
            this.$router.push("/business/drivers-fitness-declarations");
        },
        viewDriver(){
            this.$router.push("/business/drivers");
        },
        viewVehicle(){
            this.$router.push("/business/vehicles");
        },
        viewEventModification(){
            this.$router.push("/business/reports/event/modification");
        },
        async refreshNotifications() {
            const proxySite = this.$store.getters[`account/proxySite`];
            this.notificationLoading = true;
                let notifications = await this.$store.dispatch(`notification/getAllUnAcknowledgedNotifications`);
                if(!notifications){
                    this.notifications = [];
                } else {
                    if (!_.isEmpty(proxySite) && _.isObject(proxySite)) {
                        // Filter only notications that belongs to the switched site
                        notifications = notifications.filter(notification => notification?.siteId && notification?.siteId == proxySite._id);
                    }
                    this.notifications = this.formatItem(notifications);
                }
            this.notificationLoading = false;
        },
    },
     computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
}



</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .stack{
        margin-left: -8px;
    }
</style>