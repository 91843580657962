<template>
  <div class="relative inline-block text-left text-gray-800" v-click-outside="closeMenu">
    <div>
      <button
        @click="initiateMenuOpen()"
        type="button"
        class="inline-flex items-center justify-between w-full"
        :class="buttonClass"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
      >
        <slot name="button" />
      </button>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="isMenuOpen"
        class="origin-center absolute -left-24 -top-4 mt-2 w-56 rounded shadow-md text-sm overflow-hidden z-20"
      >
        <div 
          class="rounded-md bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div>
            <div class="p-2 flex items-center cursor-pointer" @click.prevent="closeMenu">
              <div class="w-full text-xs flex items-center justify-center text-orange-primary">
                <font-awesome-icon :icon="['fas', 'filter']"/>
              </div>
            </div>
          </div>
          <div class="border-t-2 border-orange-primary"></div>
          <div>
            <div class="p-4">
              <slot name="menu" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  props: {
    buttonClass: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      isMenuOpen: false
    }
  },

  methods: {
    closeMenu() {
      this.isMenuOpen = false
    },
    initiateMenuOpen() {
      this.isMenuOpen = !this.isMenuOpen
      this.$emit('openMenu');
    }
  }
}
</script>