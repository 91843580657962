<template>
  <div class="w-full min-h-full flex-row items-center">
    <div class="flex flex-col w-full mt-5">
      <header class="flex justify-between text-lg font-bold items-center py-3" :style="{color: theme.primaryColor}">
        {{ pageTitle() }}
      </header>
      <hr>
      <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th scope="col" class="py-3 px-6">
              Form name
            </th>
            <th scope="col" class="py-3 px-6 text-center">
              Version
            </th>
            <th scope="col" class="py-3 px-6">
              Action
            </th>
          </tr>
          </thead>
          <tbody v-if="isLoading">
            <tr class="border-b border-gray-200 text-sm bg-white hover:bg-gray-100 cursor-default">
              <td colspan="3" class="text-center p-4 loading">
                <ui-spinner class="mx-auto"></ui-spinner>
              </td>
            </tr>
          </tbody>
          <tbody v-if="formSettings && !isLoading">
            <tr v-for="(setting, index) in formSettings" :key="index"
                class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
              <th scope="row" class="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                {{ setting.name }}
              </th>
              <td class="py-4 px-6 text-center">
                {{ setting.version || 'Default' }}
              </td>
              <td class="py-4 px-6">
                <div @click="updateFormStatus(setting.formType)"
                    class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                  <input
                      type="checkbox"
                      :checked="setting.isActive"
                      name="toggle"
                      class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                  />
                  <label
                      for="toggle"
                      class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
                  ></label>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody v-if="!formSettings && !isLoading">
            <tr class="border-b border-gray-200 text-sm bg-white hover:bg-gray-100 cursor-default">
              <td colspan="3" class="text-center p-4 loading">
                No Record Found
              </td>
            </tr>
            <tr class="border-b border-gray-200 text-sm bg-white hover:bg-gray-100 cursor-default">
              <td colspan="3" class="text-center p-4 loading">
                <button
                    @click.prevent="createFormSetting()"
                    class="btn mb-4 btn-primary w-full">
                 Create form setup
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import _ from "lodash";

export default {
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
  data () {
    return {
      contractId: null,
      featureType: null,
      localFeatureType: ['EWD', 'fatigue_compliance'],
      EWDTypes: ['Prestart', 'Fitness Declaration'],
      EWDData: null,
      fatigueComplianceEWDTypes: ['Indident', 'Hazard'],
      fatigueCompliancedData: null,
      formSetup: {},
      isLoading: false,
      businessId: null,
      siteId: null,
      formSettings: [],
      isRootBusiness: true,
    }
  },
  async mounted () {
    await this.init()
  },
  methods: {
    async init () {
      this.isLoading = true;
      this.featureType = this.$route.params.featureType
      let data = this.localFeatureType.find(item => item === this.featureType)
      if (!data) window.location.href = "/error-404"

      const proxySite = this.$store.getters[`account/proxySite`];
      const isProxySite = !_.isEmpty(proxySite) && _.isObject(proxySite);
      this.isRootBusiness = !isProxySite;

      const me = this.$store.getters[`account/me`]
      // Reads from businessId to get business site id, otherwise use main business id
      this.businessId = me.businessId ? me.businessId : me?.business?._id;
      this.siteId = proxySite?._id;

      this.$store.dispatch('form/getFormSettingByBusinessId', this.businessId)
        .then(data => {
          if(data) {
            this.contractId = data._id;
            this.EWDData = data?.EWD
            this.fatigueCompliancedData = data?.fatigueCompliance;
            this.isLoading = false
            switch (this.featureType) {
              case 'EWD':
                this.formSettings = this.EWDData || []
                break;
              case 'fatigue_compliance':
                this.formSettings = this.fatigueCompliancedData || []
                break;
              default:
                this.formSettings = []
            }
          } else {
            this.formSettings = false;
            this.isLoading = false
          }
        })
        .catch(err => {
          this.$store.commit('setDialogNotify', true)
          this.$store.commit('setMessageNotify', { state: 'error', message: err.message })
          this.isLoading = false
        })
    },
    pageTitle () {
      if (this.featureType) {
        const string = this.featureType.replace(/_/g, " ")
        return `${string.replace(/(^\w|\s\w)/g, m => m.toUpperCase())} Forms`
      }
    },
    async updateFormStatus (formType) {
      this.isLoading = true;
      const payload = {
        id: this.contractId,
        formType: formType,
      }
      if (this.isRootBusiness) {
        payload.businessId = this.businessId;
      } else {
        payload.businessId = this.businessId;
        payload.siteId = this.siteId;
      }

      await this.$store.dispatch('form/updateBusinessFormSetting', payload)
          .then((data) => {
            this.contractId = data?.data?._id;
            this.EWDData = data?.data?.EWD
            this.fatigueCompliancedData = data?.data?.fatigueCompliance;
            if (this.EWDData) {
              switch (this.featureType) {
                case 'EWD':
                  this.formSettings = this.EWDData || []
                  break;
                case 'fatigue_compliance':
                  this.formSettings = this.fatigueCompliancedData || []
                  break;
                default:
                  this.formSettings = []
              }
              this.isLoading = false
            }
            this.$store.commit('setDialogNotify', true)
            this.$store.commit('setMessageNotify', { state: 'success', message: data.message })
          })
          .catch(err => {
            this.$store.commit('setDialogNotify', true)
            this.$store.commit('setMessageNotify', { state: 'error', message: err.message })
          })
    },
    async createFormSetting() {
      this.isLoading = true;

      const payload = {
        siteId: this.siteId,
        businessId: this.businessId,
      };

      await this.$store.dispatch('form/createSiteFormSetting', payload)
          .then(async (data) => {
            this.$store.commit('setDialogNotify', true)
            this.$store.commit('setMessageNotify', { state: 'success', message: data.message })

            await this.init();
          })
          .catch(err => {
            this.$store.commit('setDialogNotify', true)
            this.$store.commit('setMessageNotify', { state: 'error', message: err.message })
          })
    }
  }
}
</script>