<template>
  <div class="w-full min-h-full items-center">
    <div class="font-bold text-lg mb-3 focus-within: text-green-500">
      Immediate Action Sheet
    </div>

    <section class="my-6">
      <div class="mb-5">
        <div class="text-xs">Affected Driver:</div>
        <div class="font-bold text-black-primary text-base">
          {{activeDocView.actionDriverStringBasedName || activeDocView.actionDriverName }}&nbsp; 
        </div>
      </div>

      <div class="mb-5">
        <div class="text-xs">Date:</div>
        <div class="font-bold text-black-primary text-base">
          {{cleanDateFormat(activeDocView.actionDate)}}&nbsp; 
        </div>
      </div>

      <div class="mb-5">
        <div class="text-xs">Details:</div>
        <div class="text-black-primary text-base h-auto">
          {{activeDocView.actionTakenDetails}}&nbsp; 
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import { cleanDateFormat } from '@/_helper'

export default {
  name: 'Immediate-Action-View',
  props: {
    activeDocView: null,
  },
  methods: {
    cleanDateFormat(date){
      return cleanDateFormat(date)
    }
  }
}
</script>