<template>
   <footer class="pt-4 relative">

  </footer>
</template>

<script>
export default {
  name: 'PortalFooter',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
