<template>
  <div>
    <Table :tableHeader="tableHeader" :data="paymentData">
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="editPayment(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <template slot="callToActionTop">
        <button
          class="btn btn-primary"
          @click.prevent="newPayment()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          New Payment Gateway
        </button>
      </template>
    </Table>
    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <NewPaymentGateway @closeRefresh="closeRefresh" />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditPaymentGateway
          @closeRefresh="closeRefresh"
          :detailsId="selectedId"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Table, Dropdown } from "@/components/commons/";
import {
  NewPaymentGateway,
  EditPaymentGateway,
} from "@/components/forms/payment";
import { formatDate } from "@/_helper";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Partner-Payment",
  components: {
    Modal,
    Dropdown,
    NewPaymentGateway,
    EditPaymentGateway,
    Table,
    StyledIconedLink,
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      payments: [],
      selectedId: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        "Platform",
        "API Key",
        "API Secret",
        "Gateway Fee",
        "Created",
        "Default",
        "Status",
      ],
      tableData: [],
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      this.$emit("updateLoading", true);

      const me = this.$store.getters[`account/me`];
      const payments = await this.$store.dispatch(
        `payment/getPaymentGatewayByEntityId`,
        me?.entity?._id
      );

      if (payments) {
        const paymentTableData = this.formatItem(
          _.orderBy(payments, ["createdAt"], ["desc"])
        );
        this.payments = paymentTableData;
      } else {
        this.payments = [];
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
      this.$emit("updateLoading", false);
    },
    newPayment() {
      this.modalContent = "new";
      this.modalTitle = "New Payment";
      this.$refs.modal.toggleModal();
    },
    editPayment(id) {
      this.selectedId = id;
      this.modalContent = "edit";
      this.modalTitle = "Payment Edit";
      this.$refs.modal.openModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
    closeRefresh() {
      this.$refs.modal.closeModal();
      this.init();
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const payment = {
            id: item?._id,
            platform: _.startCase(item?.platform) || "",
            apiKey: `${item?.apiKey.slice(0, 10)}...` || "",
            apiSecret: `${item?.apiSecret.slice(0, 10)}...` || "",
            gatewayFee:
              `${item?.surchargePercentageFee}% + $${item?.otherFees.toFixed(
                2
              )}` || "",
            created: formatDate(item?.createdAt),
            isDefault: item?.isDefault,
            isActive: item?.isActive,
          };
          nItems.push(payment);
        });
      }
      return nItems;
    },
    parsePayment(data) {
      return data.map((paymentData) => [
        { id: paymentData.id, name: paymentData.platform, itemType: "string" },
        { id: paymentData.id, name: paymentData.apiKey, itemType: "string" },
        { id: paymentData.id, name: paymentData.apiSecret, itemType: "string" },
        {
          id: paymentData.id,
          name: paymentData.gatewayFee,
          itemType: "string",
        },
        { id: paymentData.id, name: paymentData.created, itemType: "string" },
        {
          id: paymentData.id,
          name: paymentData.isDefault,
          itemType: "toggle",
          editable: false,
        },
        {
          id: paymentData.id,
          name: paymentData.isActive,
          itemType: "label",
          isStatus: true,
        },
      ]);
    },
  },
  computed: {
    paymentData() {
      if (this.payments) {
        return this.parsePayment(this.payments);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
