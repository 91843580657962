
<template>
    <span class="float-right pr-1">
        <font-awesome-icon
            v-if="a == b"
            :icon="['fas', 'folder-open']"
            class="my-auto mx-1.5 text-orange-200"
            size="lg"
        ></font-awesome-icon>
        <font-awesome-icon 
            v-else
            :icon="['fas', 'folder']"
            class="my-auto mx-1.5 text-gray-200"
            size="lg"
        ></font-awesome-icon>
    </span>
</template>

<script>
export default {
    name: 'Folder-icon-closed-open',
    props: {
        a:{
            type: String
        },
        b: {
            type: String
        }
    }
}
</script>