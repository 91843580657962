<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="vehiclesData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option>10</option>
              <option>20</option>
              <option selected="selected">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewVehicle(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="clipboard" class="my-auto mr-2" />
                  View
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="editVehicle(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <div>Not Found</div>
    </Table>

    <Modal
      ref="modal"
      :title="modalTitle"
      :size="modalSize"
      :height="modalHeight"
    >
      <div v-if="modalContent == 'new'">
        <NewVehicle />
      </div>
      <div v-if="modalContent == 'view'">
        <VehicleDetailView @editVehicle="editVehicle" :detailsId="selectedId" />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditVehicle :detailsId="selectedId" />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Table, Dropdown } from "@/components/commons";
import { NewVehicle, EditVehicle } from "@/components/forms/vehicle";
import VehicleDetailView from "@/components/view/vehicle";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import { formatDate, formatPaginationSettings } from "@/_helper";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "VehicleLookup",
  components: {
    Table,
    Modal,
    Dropdown,
    NewVehicle,
    EditVehicle,
    VehicleDetailView,
    StyledIconedLink,
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      vehicles: [],
      tempVehicles: [], //to delete once API is ready
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalSize: "lg",
      tableHeader: [
        "Plate Number",
        "Registration Expiry",
        "GVM",
        "Vehicle Class",
        "Vehicle Type",
        "Date Created",
        "Status",
        // TODO: Optional to enable if needed
        // 'Drive Vehicle',
        // 'Concrete Agitator',
        // 'Livestock Vehicle',
      ],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      vehicleData: {},
      selectedId: "",
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: {
        all: "All",
      },
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
    };
  },
  async mounted() {
    this.init();
    //TODO: This will show the notification modal
    // setTimeout(() => {
    //    this.notifyVehicle()
    //   }, 2000)
  },
  methods: {
    async init(paged = 1) {
      this.isLoading = true;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status != "all")
        query.filter.status = { $eq: this.filter.status };

      const vehicle = await this.$store.dispatch(`vehicle/getVehicle`, {
        query,
      });
      if (vehicle) {
        if (vehicle?.metadata) {
          this.paginationSettings = formatPaginationSettings(vehicle?.metadata);
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const vehicleTableData = this.formatItem(vehicle.results);
        this.vehicles = vehicleTableData;
      } else {
        this.vehicles = [];
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    newVehicle() {
      this.$refs.modal.openModal();
      this.modalContent = "new";
      this.modalTitle = "Add Vehicle";
    },
    viewVehicle(id) {
      //Pass dummy data, to be updated to pass only ID once API is ready
      this.selectedId = id;
      this.vehicleData = this.tempVehicles.filter(
        (item) => this.selectedId === item._id
      )[0];
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Vehicle Info";
    },
    editVehicle: function (id) {
      //Pass dummy data, to be updated to pass only ID once API is ready
      this.selectedId = id;
      this.vehicleData = this.tempVehicles.filter(
        (item) => this.selectedId === item._id
      )[0];
      this.$refs.modal.openModal();
      this.modalContent = "edit";
      this.modalTitle = "Vehicle Edit";
    },
    notifyVehicle() {
      this.$refs.modal.openModal();
      this.modalContent = "notification";
      this.modalTitle = "Vehicle Approval";
      this.modalSize = "4xl";
    },
    showConfig() {
      this.$refs.configModal.openModal();
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const vehicle = {
            id: item._id,
            vehiclePlate: item?.vehiclePlate,
            registrationExpiry: formatDate(item?.registrationExpiry),
            gvm: String(item.GMV),
            vehicleClass: item?.vehicleClass?.name,
            vehicleType: item?.vehicleType?.name,
            created: formatDate(item.createdAt),
            status: item.isActive,
          };
          nItems.push(vehicle);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseVehicles(data) {
      return data.map((vehicleData) => [
        {
          id: vehicleData.id,
          name: vehicleData.vehiclePlate,
          itemType: "string",
        },
        {
          id: vehicleData.id,
          name: vehicleData.registrationExpiry,
          itemType: "string",
        },
        { id: vehicleData.id, name: vehicleData.gvm, itemType: "string" },
        {
          id: vehicleData.id,
          name: vehicleData.vehicleClass,
          itemType: "string",
        },
        {
          id: vehicleData.id,
          name: vehicleData.vehicleType,
          itemType: "string",
        },
        { id: vehicleData.id, name: vehicleData.created, itemType: "string" },
        { id: vehicleData.id, name: vehicleData.status, itemType: "toggle" },
      ]);
    },
  },
  computed: {
    vehiclesData() {
      if (this.vehicles) {
        return this.parseVehicles(this.vehicles);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
