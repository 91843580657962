import { User } from "../common/user";

export class LoggedInUser extends User{
    isDisabled = false;
    isSuspended = false;

    constructor(me){
        let phoneNumber = '';
        if(Array.isArray(me.securityRoles)){
            let securityRole = me.securityRoles[0];
            let linkedId = securityRole.linkageId;
            let persona = linkedId.persona;
            phoneNumber = persona.contactPhoneNumber;
        }
        super(me._id, me.email, me.username, me.role.name, phoneNumber);
        this.isDisabled = me.isDisabled;
        this.isSuspended = me.isSuspended;
    }
}