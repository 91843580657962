import { Report } from "@/api"
import _ from 'lodash'

export default {
    async getAllReportJobs(){
      try{
        console.log('jhere')
          const response = await Report.getAllReportJobs();
          return response.data.data
      }catch(error){
          throw error.response.data
      }
    },

    async processReportJob(_, id){
      try{
          const response = await Report.processReportJob(id);
          return response.data.data
      }catch(error){
          throw error.response.data
      }
    },

    async getBreachesByDateRange({ state }, payload){
        try{
            const response = await Report.getBreachesByDateRange(payload);
            if(response.data?.resultsMeta){
                return { results : response.data.data, metadata:response.data.resultsMeta }
            }else{
                return response?.data?.data || []
            }
        }catch(error){
            console.log(state);
            throw error?.response?.data || 'Invalid data object'
        }
    },

    async getBreachesById( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Report.getBreachesById(id);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },
    
    async getBreach({ state }, payload){
        try{
            const response = await Report.getBreach(payload);
            if(response.data?.resultsMeta){
                return { results : response.data.data, metadata:response.data.resultsMeta }
            }else{
                return response.data.data
            }
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async sendBreachReport({ state }, payload){
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'
        try{
            const response = await Report.sendBreachReport(payload)
            return response.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    async getNonConformance({ state }, payload) {
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'
        try {
          const response = await Report.getNonConformance(payload);
          return response;
        } catch (error) {
          console.log(state);
          throw error.response.data
        }
      },

      async getNonConformanceByRegisterId({ state }, payload) {
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'
        try {
          const response = await Report.getNonConformanceByRegisterId(payload);
          return response.data;
        } catch (error) {
          console.log(state);
          throw error.response.data
        }
      },

      async getNonConformanceCountByRegisterType({ state }, payload) {
        if (!state) throw `Invalid + ${state}`;
        try {
          const response = await Report.getNonConformanceCountByRegisterType(payload);
          return response.data;
        } catch (error) {
          throw error.response.data;
        }
      },

      async reviewNonConformance({ state }, payload) {
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'
        try {
          const response = await Report.reviewNonConformance(payload);
          return response;
        } catch (error) {
          console.log(state);
          throw error.response.data
        }
      },

      async createNonConformance({ state }, payload) {
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'
        try {
          const response = await Report.createNonConformance(payload);
          return response;
        } catch (error) {
          console.log(state);
          throw error.response.data
        }
      },

      async getDriverStats({state}, payload) {
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'
        try {
          const response = await Report.getDriverStats(payload);
          return response.data.data;
        } catch (error) {
          console.log(state);
          throw error.response.data
        }
      },

      async getNhvrReportData({state}) {
        try {
          const response = await Report.getNhvrReportData();
          return response.data.data;
        } catch (error) {
          console.log(state);
          throw error.response.data
        }
      },

      async generateReport({ state }, payload) {
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'
        try {
          const response = await Report.generateReport(payload);
          return response;
        } catch (error) {
          console.log(state);
          throw error.response.data
        }
      },

      async getCompliancePeriodReport({state}, payload) {
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'
        try {
          const response = await Report.getCompliancePeriodReport(payload);
          return response.data;
        } catch (error) {
          console.log(state);
          throw error.response.data
        }
      },

      async getVehicleReport({state}, payload) {
        if(!payload || _.isEmpty(payload)) throw 'Invalid data object'
        try {
          const response = await Report.getVehicleReport(payload);
          return response.data;
        } catch (error) {
          console.log(state);
          throw error.response.data
        }
      },

      async getNonConformanceSummaryReport({ state }, payload) {
        if (!state) throw `Invalid + ${state}`;
        try {
          const response = await Report.getNonConformanceSummaryReport(payload);
          return response.data.data;
        } catch (error) {
          throw error.response.data;
        }
      },

      async getNonConformanceChainOfActions({ state }, id) {
        if (!state) throw `Invalid + ${state}`;
        try {
          const response = await Report.getNonConformanceChainOfActions(id);
          return response.data.data;
        } catch (error) {
          throw error.response.data;
        }
      },


}