<template>
  <div>
    <div class="w-full min-h-full flex-row items-center">
      <FormBuilder
        :isDefault="true"
        :editMode="true" 
        :_id="formId" 
        :pulledFormData="pulledFormData" 
        />
    </div>
  </div>
</template>

<script>
import FormBuilder from '@/components/commons/FormBuilder';

export default {
  components: {
    FormBuilder
  },
  data: () => ({
    formData: null,
    isRenderer: true,
    readOnly: false,
    formId: null,
    pulledFormData: null,
    writeForms: false
  }),
  methods: {
    async getDefaultFormByFormId(){
      this.formId = this.$route.params.id;
      this.pulledFormData = await this.$store.dispatch(`form/getDefaultFormByFormId`, this.formId)
    },
  },
  async mounted(){
    this.$emit("updateLoading", true);
    this.getDefaultFormByFormId();
    this.$emit("updateLoading", false);
  },
}
</script>