import Dashboard from '@/pages/partner/Dashboard'
import Resellers from '@/pages/partner/Resellers'
import Businesses from '@/pages/partner/Businesses'
import Drivers from '@/pages/partner/Drivers'
import Users from '@/pages/Users';
import Roles from '@/pages/Roles';
import BuyPricing from '@/pages/partner/BuyPricing';
import RRP from '@/pages/partner/RRP';
import PartnerBusinessContract from "@/pages/partner/PartnerBusinessContract";
import SupportDetails from "@/pages/partner/SupportDetails";
import Solo from "@/pages/partner/Solo";
import Invoice from '@/pages/Invoice';
import PaymentGateway from "@/pages/partner/PaymentGateway";
import Leads from '@/pages/LeadsMain';
import PartnerBusinessLicenceManagement from "@/pages/partner/business-billing/BusinessContract";

export default [
    {
      path: '/',
      name: 'Dashboard - Partner',
      component: Dashboard
    },
    {
      path: 'resellers',
      name: 'Resellers',
      component: Resellers
    },
    {
      path: 'businesses',
      name: 'Businesses',
      component: Businesses
    },
    {
      path: 'businesses/billing/:id',
      name: 'Business Licence Management',
      component: PartnerBusinessLicenceManagement
    },
    {
      path: 'solo',
      name: 'Solo',
      component: Solo
    },
    {
      path: 'drivers',
      name: 'Drivers',
      component: Drivers
    },
    {
      path: 'users',
      name: 'Partner Users',
      component: Users,
    },
    {
      path: 'roles',
      name: 'Partner Roles',
      component: Roles
    },
    {
      path: 'buy-pricing',
      name: 'Partner Buy Pricing',
      component: BuyPricing
    },
    {
      path: 'rrp',
      name: 'Partner RRP',
      component: RRP
    },
    {
      path: 'invoices',
      name: 'Partner Invoices',
      component: Invoice,
    },
    {
      path: 'payment',
      name: 'Payment Gateway',
      component: PaymentGateway
    },
    {
      path: 'lead-management',
      name: 'Lead Management',
      component: Leads
    },
    // {
    //   path: 'contracts/terms',
    //   name: 'Partner Contract Terms',
    //   component: ContractTerms
    // },
    // {
    //   path: 'contracts/terms-forms/builder',
    //   name: 'Contracts Term Builder - Partner',
    //   component: ContractTermsBuilderCreate
    // },
    // {
    //   path: 'contracts/terms-forms/builder/:id/edit',
    //   name: 'Contracts Term Builder - Partner (Edit)',
    //   component: ContractTermsBuilderEdit
    // },
    // {
    //   path: 'contracts/terms-forms',
    //   name: 'PartnerContractTermForms',
    //   component: ContractTermsForms,
    // },
    {
      path: 'contract-management',
      name: 'Business Contract - Partner',
      component: PartnerBusinessContract
    },
    {
        path: 'support/details',
        name: 'Partner Support Details',
        component: SupportDetails
    },
]