export default {
  AFMrules: [],
  additionalRules: [],
  driversToAssociate: [],

  //array of words
  docPartContents: [],

  //reactive variables to be inserted in paragraph
  docVariables: [],

  //has unqiue id will hold references to doc vars and part contents
  docParts: [],

  selectedContainer: {},

};
