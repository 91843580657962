<template>
  <div>
    <div class="mb-16">
      <div
        class="font-bold text-lg mb-5"
        :class="[isPassed ? 'text-green-500' : 'text-red-500']"
      >
        {{ isPassed ? "FIT" : "UNFIT" }} TO DRIVE
      </div>

      <div class="w-full">
        <section v-for="(section, i) in sections" :key="i" class="mb-3">
          <div v-if="section.value">
            <div v-for="(control, ia) in section.value" :key="ia" class="mb-3">
              <div v-if="!isPillet(control?.value?.value)">
                <div
                  v-if="
                    control.component == formControls.CHECKBOX ||
                    control.component == formControls.RADIO
                  "
                >
                  <div class="mb-1">
                    <div class="font-bold text-black-primary text-base">
                      {{ control.value?.value }}&nbsp;
                      <span v-if="!control.value?.isIssue">
                        <font-awesome-icon
                          icon="check-circle"
                          class="text-1xl text-green-400"
                        />
                      </span>
                      <span v-else>
                        <font-awesome-icon
                          icon="times-circle"
                          class="text-1xl text-red-600"
                        />
                      </span>
                    </div>
                    <div class="text-xs">{{ control.label }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr />
        </section>
      </div>

      <div class="w-full">
        <div v-for="(section, j) in sections" :key="j" class="mb-3">
          <div v-if="section.value" class="mb-3">
            <div v-for="(control, ja) in section.value" :key="ja">
              <div
                v-if="
                  control.component == formControls.CHECKBOX ||
                  control.component == formControls.RADIO
                "
              >
                <div
                  class="mb-2 flex items-center"
                  v-if="isPillet(control?.value?.value)"
                >
                  <div class="option-pill-view">
                    <div
                      v-if="control.value?.isIssue"
                      class="bg-red-400 text-white w-11 text-center"
                    >
                      {{ control?.value?.value }}
                    </div>
                    <div
                      v-else
                      class="bg-green-400 text-white w-11 text-center"
                    >
                      {{ control?.value?.value }}
                    </div>
                    <div class="bg-gray-200">&nbsp;-&nbsp;&nbsp;</div>
                  </div>

                  <div class="text-xs">
                    {{ control.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <hr />
        </div>
      </div>

      <template slot="collapseHeader">
        <div class="flex items-center font-bold">
          <div class="flex items-center">
            <div class="flex items-center">
              <div class="icon-container mr-2 bg-gray-700">
                <font-awesome-icon
                  icon="file"
                  style="height: 15px; width: 15px"
                />
              </div>
              <div class="flex flex-col">
                <div class="flex-auto">Fitness Declaration Consent</div>
              </div>
            </div>
          </div>
          <div class="ml-auto cursor-pointer">
            <font-awesome-icon
              :icon="collapsed ? 'caret-down' : 'caret-right'"
              class="text-2xl"
              @click="onCollapsed()"
            />
          </div>
        </div>
      </template>
      <template>
        <div class="flex flex-col">
          <div class="w-full text-left items-start pt-7 mr-2">
            <span class="pl-6 text-sm">{{
              cleanDateFormat(savedData?.date)
            }}</span>
            <div class="w-40 border-t mt-1 pt-1 text-center text-xs">
              Date submitted
            </div>
          </div>
          <div class="w-full text-right flex flex-col items-end mr-2">
            <section
              v-for="(section, l) in sections"
              :key="l"
              class="w-full text-right flex flex-col items-end"
            >
              <div v-if="section.value" class="mb-3">
                <div v-for="(control, la) in section.value" :key="la">
                  <div
                    v-if="
                      control.component == formControls.SIGNATURE ||
                      control.component == formControls.IMAGE
                    "
                    class="mt-5"
                  >
                    <img
                      class="border-2 w-60 bg-gray-700 h-32"
                      :src="control.value"
                      :alt="control.label"
                    />
                    <div class="text-left mt-1 text-xs">
                      {{ control.label }}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <hr />
            </section>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { FORM_CONTROLS } from "@/_helper/constants";
import { cleanDateFormat, isPilletOptionAvailable } from "@/_helper";

export default {
  name: "Fitness-Declaration-Details-View",
  props: {
    activeFitnessDeclaration: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    activeFitnessDeclaration: {
      deep: true,
      handler() {
        this.setActiveFitnessDeclaration();
      },
    },
  },
  created() {
    this.setActiveFitnessDeclaration();
    setTimeout(() => (this.loading = false), 400);
  },
  data() {
    return {
      loading: true,
      driver: {},
      sections: {},
      collapsed: true,
      isPassed: false,
      formControls: FORM_CONTROLS,
      savedData: {},
    };
  },
  methods: {
    setActiveFitnessDeclaration() {
      this.driver = this.activeFitnessDeclaration?.driver;
      this.sections = this.handleSectionsDataStructure(
        this.activeFitnessDeclaration?.savedData?.sections
      );
      this.isPassed = this.activeFitnessDeclaration?.isPassed;
      this.savedData = this.activeFitnessDeclaration?.savedData;
    },
    onCollapsed() {
      this.collapsed = !this.collapsed;
    },
    isPillet(option) {
      return isPilletOptionAvailable(option);
    },
    cleanDateFormat(date) {
      return cleanDateFormat(date, true, true);
    },
    handleSectionsDataStructure(sections = []) {
      if (!sections || !sections.length) {
        return sections;
      }

      // If sections is old data structure, return sections as it is.
      if (sections[0]["value"]["AlchoholorDrugs"]) {
        return sections;
      }

      const keys = Object.keys(sections[0]["value"]);
      const updatedValueForSection = {};

      keys.forEach((key) => {
        const sectionValue = sections[0]["value"][key];
        const {
          value: { fieldId, value },
          label,
          component,
          icon,
        } = sectionValue;

        let usableValue = value;
        if (value["value"]) {
          usableValue = value["value"];
        }

        if (fieldId !== "signature") {
          updatedValueForSection[`${fieldId}`] = {
            value: {
              value: usableValue,
              ...(typeof value["isIssue"] === "boolean"
                ? { isIssue: value["isIssue"] }
                : {}),
            },
            label,
            component,
            icon,
          };
        } else {
          updatedValueForSection[`${fieldId}`] = {
            value: usableValue,
            label,
            component,
            icon,
          };
        }
      });

      sections[0] = {
        ...sections[0],
        value: updatedValueForSection,
      };
      return sections;
    },
  },
};
</script>
