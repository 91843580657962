<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="scheduledReportData"
      @onPageChange="onPageChange($event, 'associatedDriver')"
    >
      <template slot="callToActionTop">
        <button
          class="btn btn-primary"
          @click.prevent="createSchedule()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Add Scheduled Report
        </button>
      </template>

      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0"></div>
      </template>

      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="toggleEditSched(data?.editData)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="toggleDeleteSched(data?.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="trash" class="my-auto mr-2" /> Delete
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>
    <Modal
      ref="modal"
      :title="modalTitle"
      size="lg"
      :height="modalHeight"
      @closeModal="closeModal"
    >
      <div v-if="modalContent == 'new'">
        <SchedulingReportForm
          @closeModal="closeModal"
          @refetch="init"
          @submit="submit"
          :editData="editData"
          :selectedEntityType="selectedEntityType"
          :isKmTimeModuleFound="isKmTimeModuleFound"
          :driverId="driverId"
        />
      </div>
      <div v-if="modalContent == 'view'"></div>
      <div v-if="modalContent == 'edit'">
        <SchedulingReportForm
          @closeModal="closeModal"
          @refetch="init"
          @submit="submit"
          :editData="editData"
          :selectedEntityType="selectedEntityType"
          :isKmTimeModuleFound="isKmTimeModuleFound"
          :driverId="driverId"
          :submitType="'edit'"
        />
      </div>
      <div v-if="modalContent == 'delete'">
        <div class="mb-16">
          <div class="btns">
            <div class="pb-2">
              <button
                class="btn btn-primary w-full"
                @click="deleteSchedule()"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                YES
              </button>
            </div>
            <button
              class="border-2 rounded-lg btn btn-secondary w-full"
              @click="closeModal"
            >
              NO
            </button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import moment from "moment";
import { Modal, Table, Dropdown } from "@/components/commons";
import _ from "lodash";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { formatPaginationSettings } from "@/_helper/";
import SchedulingReportForm from "@/components/forms/Report-Scheduling-Form.vue";
import { mapGetters } from "vuex";
import { REPORT_NAMES, BUSINESS_REPORT_OPTIONS } from "../_helper/constants";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "ScheduledReport",
  components: {
    Modal,
    Table,
    Dropdown,
    SchedulingReportForm,
    StyledIconedLink,
  },
  data() {
    return {
      selectedDateRange: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      tableHeader: [
        "Report Name",
        "Report Interval",
        "Report Duration (Days)",
        "Report Time",
      ],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      createScheduleModel: null,
      selectedEntity: {},
      selectedEntityType: null,
      modalContent: false,
      modalHeight: "90vh",
      modalTitle: "",
      schedules: [],
      recipients: [],
      editData: {},
      certToDelete: null,
      reportNames: REPORT_NAMES,
      isLoading: false,
      isKmTimeModuleFound: false,
      driverId: null,
    };
  },
  async mounted() {
    this.isLoading = true;
    const me = this.$store.getters[`account/me`];
    if (me && me.driver) {
      this.selectedEntityType = "driver";
      this.driverId = me.driver?._id;
    } else if (me && me.business) {
      this.selectedEntityType = "business";
      me.business.modules.map((module) => {
        if (module.apiTag == "km-time") {
          this.isKmTimeModuleFound = true;
        }
      });
    }
    this.selectedEntity = me?.entity;
    await this.init();
    this.isLoading = false;
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
  methods: {
    createSchedule() {
      this.modalHeight = "90vh";
      this.modalSize = "lg";
      this.$refs.modal.openModal();
      this.modalContent = "new";
      this.modalTitle = "Send Report To Email";
    },
    closeModal() {
      this.editData = {};
      this.$refs.modal.closeModal();
    },
    debouncedSearchString() {
      this.init();
    },
    async submit(data, submitType) {
      if (submitType === "create") {
        try {
          this.$emit("updateLoading", true);
          await this.$store.dispatch(
            "reportscheduling/createReportSchedule",
            data
          );
          this.closeModal();
          this.init();
          this.toast("success", "Successfully created schedule");
          this.$emit("updateLoading", false);
        } catch (e) {
          this.toast("error", "Something went wrong");
        }
      } else {
        try {
          this.$emit("updateLoading", true);
          await this.$store.dispatch("reportscheduling/updateSchedule", data);
          this.closeModal();
          this.init();
          this.toast("success", "Successfully updated schedule");
          this.$emit("updateLoading", false);
        } catch (e) {
          this.toast("error", "Something went wrong");
        }
      }
    },
    async deleteSchedule() {
      try {
        this.$emit("updateLoading", true);
        await this.$store.dispatch(
          "reportscheduling/deleteSchedule",
          this.certToDelete
        );
        this.closeModal();
        this.init();
        this.toast("success", "Successfully deleted schedule");
        this.$emit("updateLoading", false);
      } catch (e) {
        this.toast("error", "Something went wrong");
      }
    },
    async init(paged = 1) {
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };
      const scheds = await this.$store.dispatch(
        "reportscheduling/getReportSchedule",
        {
          query,
        }
      );
      this.schedules = scheds;
      if (scheds?.data?.resultsMeta) {
        this.paginationSettings = formatPaginationSettings(
          scheds?.data?.resultsMeta
        );
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
    },
    async filterChange() {
      await this.init();
    },
    handleStritEmailCase(value) {
      this.createScheduleModel.email = value.toLowerCase();
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    async onPageChange(event) {
      await this.init(event.page);
    },
    toggleEditSched(data) {
      this.modalHeight = "90vh";
      this.modalSize = "lg";
      this.editData = data;
      this.$refs.modal.openModal();
      this.modalContent = "edit";
      this.modalTitle = "Edit Schedule Content";
    },
    toggleDeleteSched(data) {
      this.modalHeight = "30vh";
      this.modalSize = "lg";
      this.certToDelete = data;
      this.$refs.modal.openModal();
      this.modalContent = "delete";
      this.modalTitle = "Delete Schedule Content";
    },
    getDayDuration(data) {
      if (data > 0 && data <= 7) {
        return "1 Week";
      } else if (data > 7 && data <= 14) {
        return "2 Weeks";
      } else if (data > 14 && data <= 21) {
        return "3 Weeks";
      } else {
        return "4 Weeks";
      }
    },
    getMonthString(date) {
      const suffix = date > 0
        ? ["th", "st", "nd", "rd"][
            (date > 3 && date < 21) || date % 10 > 3 ? 0 : date % 10
          ]
        : "";

      return `${date}${suffix} of a month`.toUpperCase()
    },
    parseWeekDay(data) {
      return data.charAt(0) + data.substring(1).toLowerCase();
    },
    parseScheduledReports(schedules) {
      if (schedules) {
        return this?.schedules?.data?.data?.map((schedData) => {
          return [
            {
              id: schedData?._id,
              name: BUSINESS_REPORT_OPTIONS.find(
                (option) => option.value == schedData?.reportName
              ).label,
              itemType: "string",
              recipients: schedData?.recipients,
              editData: schedData,
            },
            {
              id: schedData?._id,
              name: schedData?.interval,
              itemType: "string",
              recipients: schedData?.recipients,
              editData: schedData,
            },
            {
              id: schedData?._id,
              name: schedData?.reportDurationInDays,
              itemType: "string",
              recipients: schedData?.recipients,
              editData: schedData,
            },
            {
              id: schedData?._id,
              name:
                schedData.interval == "WEEKLY"
                  ? schedData?.scheduledDay
                  : this.getMonthString(Number(schedData?.scheduledDay)),
              itemType: "string",
            },
          ];
        });
      }
    },
  },
  computed: {
    scheduledReportData() {
      if (this.schedules) {
        let tableData = this.schedules;
        return this.parseScheduledReports(tableData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
<style scoped></style>
