<template>
  <div class="mb-16">
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!isLoading">
      <div class="text-center mb-10">
        <div class="font-bold text-black-primary text-2xl">
          {{ performance.auditee.userName }}
        </div>
        <div class="text-s">Auditee</div>
        <div class="font-bold text-xs">
          {{ performance.status }}
        </div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ performance.startDate }}
        </div>
        <div class="text-s">Start Date</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ performance.endDate }}
        </div>
        <div class="text-s">End Date</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ performance.raisedBy.userName }}
        </div>
        <div class="text-s">Raised By</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ performance.durationInMonths + " Months" }}
        </div>
        <div class="text-s">Duration</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ performance.periodicCheck }}
        </div>
        <div class="text-s">Periodic Check</div>
      </div>

      <div
        v-if="performance.status == 'CONCLUDED' && performance.outcome"
        class="mb-5"
      >
        <div class="font-bold text-black-primary text-base">
          {{ performance.outcome }}
        </div>
        <div class="text-s">Performance Outcome</div>
      </div>

      <div
        v-for="(checkUpNote, index) in performance.checkUpNotes"
        :key="index"
      >
        <div class="font-bold text-black-primary text-base text-s">
          Checkup number {{ index + 1 }}
        </div>

        <div class="text-black-primary text-base">
          Date: {{ checkUpNote.date }}
        </div>

        <div class="text-black-primary text-base mb-5">
          Note: {{ checkUpNote.note }}
        </div>
      </div>

      <div class="mt-8" v-if="performance.auditee.parentRole == 'driver'">
        <button class="btn btn-primary w-full" @click="fetchPerformanceData">
          {{ reviewHelperButtonText }}
        </button>
      </div>

      <div v-if="isReviewHelperTriggered && !reviewHelperLoading">
        <section class="h-full w-full mt-3 border rounded-lg group bg-gray-100">
          <header class="flex">
            <div class="pb-2 font-bold pt-3 px-3">Breaches</div>
          </header>
          <hr />

          <div class="flex w-full px-5 py-5 bg-white">
            <table>
              <tr
                v-for="(breachDate, index) in Object.keys(
                  breachesGroupedByDate
                )"
                :key="index"
              >
                <td class="pb-1">
                  <div class="font-semibold">{{ breachDate }}</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">
                    {{ breachesGroupedByDate[breachDate].length }} Breach(es)
                  </div>
                </td>
              </tr>
              <tr v-if="Object.keys(breachesGroupedByDate).length == 0">
                <td>
                  <div>No Breaches Found</div>
                </td>
              </tr>
            </table>
          </div>
        </section>
        <br />
        <section class="h-full w-full mt-3 border rounded-lg group bg-gray-100">
          <header class="flex">
            <div class="pb-2 font-bold pt-3 px-3">Fit To Drive</div>
          </header>
          <hr />

          <div class="flex w-full px-5 py-5 bg-white">
            <table>
              <tr
                v-for="(fitToDriveDate, index) in Object.keys(
                  fitToDriveGroupedByDate
                )"
                :key="index"
              >
                <td class="pb-1">
                  <div class="font-semibold">{{ fitToDriveDate }}</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">
                    {{ fitToDriveGroupedByDate[fitToDriveDate].length }} Failed
                    Form(s)
                  </div>
                </td>
              </tr>
              <tr v-if="Object.keys(fitToDriveGroupedByDate).length == 0">
                <td>
                  <div>No Failed Fit To Drive Forms Found</div>
                </td>
              </tr>
            </table>
          </div>
        </section>
        <br />

        <section class="h-full w-full mt-3 border rounded-lg group bg-gray-100">
          <header class="flex">
            <div class="pb-2 font-bold pt-3 px-3">Infringements</div>
          </header>
          <hr />

          <div class="flex w-full px-5 py-5 bg-white">
            <table>
              <tr
                v-for="(infringementDate, index) in Object.keys(
                  infringementGroupedByDate
                )"
                :key="index"
              >
                <td class="pb-1">
                  <div class="font-semibold">{{ infringementDate }}</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">
                    {{ infringementGroupedByDate[infringementDate].length }}
                    Failed Form(s)
                  </div>
                </td>
              </tr>
              <tr v-if="Object.keys(infringementGroupedByDate).length == 0">
                <td>
                  <div>No Infringements Found</div>
                </td>
              </tr>
            </table>
          </div>
        </section>
        <br />

        <section class="h-full w-full mt-3 border rounded-lg group bg-gray-100">
          <header class="flex">
            <div class="pb-2 font-bold pt-3 px-3">Defects</div>
          </header>
          <hr />

          <div class="flex w-full px-5 py-5 bg-white">
            <table>
              <tr
                v-for="(defectDate, index) in Object.keys(defectsGroupedByDate)"
                :key="index"
              >
                <td class="pb-1">
                  <div class="font-semibold">{{ defectDate }}</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">
                    {{ defectsGroupedByDate[defectDate].length }} Failed Form(s)
                  </div>
                </td>
              </tr>
              <tr v-if="Object.keys(defectsGroupedByDate).length == 0">
                <td>
                  <div>No Defects Found</div>
                </td>
              </tr>
            </table>
          </div>
        </section>
        <br />
      </div>
      <div v-if="reviewHelperLoading">
        <ui-spinner class="mx-auto"></ui-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "lodash";

export default {
  name: "performance-details",
  components: {},

  props: {
    propsPerformance: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    propsPerformance: {
      deep: true,
      handler() {
        this.setActivePerformance();
      },
    },
  },
  created() {
    this.isLoading = true;
    this.setActivePerformance();
    setTimeout(() => (this.isLoading = false), 300);
  },
  data() {
    return {
      isLoading: false,
      error: false,
      errorMessage: null,
      performance: null,
      isReviewHelperTriggered: false,
      reviewHelperLoading: false,
      reviewHelperButtonText: "Review Helper",
      breachesGroupedByDate: [],
      fitToDriveGroupedByDate: [],
      infringementGroupedByDate: [],
      defectsGroupedByDate: [],
    };
  },

  methods: {
    formattedDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    setActivePerformance() {
      this.performance = this.propsPerformance;
    },

    async fetchPerformanceData() {
      this.isReviewHelperTriggered = true;
      this.reviewHelperLoading = true;
      this.reviewHelperButtonText = "Loading..";
      const {
        startDate,
        endDate,
        auditee: { uid },
        business,
      } = this.performance;

      const targetDriver = await this.$store.dispatch(
        "driver/getDriverByUID",
        uid
      );

      const breachPayload = {
        filter: { entityId: business?._id },
        driverId: targetDriver?._id,
        from: startDate,
        to: endDate,
      };

      const formPayload = {
        businessId: business?._id,
        driverId: targetDriver?._id,
        startDate,
        endDate,
        isPassed: false,
      };

      const promises = [
        this.$store.dispatch(`report/getBreachesByDateRange`, breachPayload),
        this.$store.dispatch(`driver/getFitToDriveByDateRange`, formPayload),
        this.$store.dispatch(
          `infringement/getInfringementByDateRange`,
          formPayload
        ),
        this.$store.dispatch(`defect/getDefectByDateRange`, formPayload),
      ];

      const results = await Promise.all(promises);
      const rawBreaches = results[1];
      const rawFitToDriveForms = results[1];
      const rawInfringementForms = results[2];
      const rawDefectsForms = results[3];

      this.breachesGroupedByDate = _.groupBy(rawBreaches, (breach) => {
        return moment(breach.time).format("YYYY-MM-DD");
      });

      this.fitToDriveGroupedByDate = _.groupBy(rawFitToDriveForms, (form) => {
        return moment(form.createdAt).format("YYYY-MM-DD");
      });

      this.infringementGroupedByDate = _.groupBy(
        rawInfringementForms,
        (form) => {
          return moment(form.createdAt).format("YYYY-MM-DD");
        }
      );
      this.defectsGroupedByDate = _.groupBy(rawDefectsForms, (form) => {
        return moment(form.createdAt).format("YYYY-MM-DD");
      });

      this.reviewHelperLoading = false;
      this.reviewHelperButtonText = "Refresh Data";
    },
  },
};
</script>

<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
