<template>
  <div>
    <FormulateForm name="formBuilder" v-model="values">
      <div v-if="loading" class="p-2 w-full text-center text-lg">
        <font-awesome-icon
          icon="circle-notch"
          spin
          size="2x"
          class="text-gray-400"
        />
      </div>
      <div v-if="!loading">
        <div class="text-center mt-3">
          <div class="font-semi text-black-primary text-2xl mb-4">
            Service Subscription
          </div>
        </div>

        <FormulateInput
          type="text"
          name="marketplaceItemId"
          placeholder="Service Mongo ID"
          label="Service Mongo ID"
          autocomplete="off"
        />

        <FormulateInput
          type="text"
          name="businessId"
          placeholder="Business Mongo ID"
          label="Business Mongo ID"
          autocomplete="off"
        />

        <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
          <button
            class="btn col-span-2 btn-primary mt-2"
            @click.prevent="onSubmit"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Submit
          </button>
          <button
            @click.prevent="closeModal"
            class="btn bg-transparent border col-span-2 btn-info"
          >
            Cancel
          </button>
        </div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { BUSINESS_ROLE } from "@/constants/usertype";
import { mapGetters } from "vuex";

export default {
  name: "Subscribe-Marketplace-Item",
  props: {},
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: "",
      hasErrorMessage: false,
    };
  },

  async mounted() {},
  methods: {
    async onSubmit() {
      this.loading = true;
      let formValues = JSON.parse(JSON.stringify(this.values));

      const business = await this.$store.dispatch("business/getBusiness", {
        id: formValues.businessId,
      });
      const marketplaceItem = await this.$store.dispatch(
        "business/getMarketplaceItem",
        formValues.marketplaceItemId
      );

      const contractModuleMaster = await this.$store.dispatch(
        `business/getContractModuleMaster`
      );
      const contractModule = contractModuleMaster.find(
        (contract) =>
          contract.apiTag === marketplaceItem.standardPricingMaster.apiTag
      );
      const payload = {
        minimums: 0,
        price: {
          monthly: marketplaceItem.pricing?.monthly,
          yearly: marketplaceItem.pricing?.yearly,
        },
        masterId: contractModule._id,
        ownerMongoId: business._id,
        ownerRole: BUSINESS_ROLE.name,
      };

      this.$store
        .dispatch("business/createContractModule", payload)
        .then(() => {
          this.toast("success", "Module successfully subscribed.");
        })
        .catch((err) => {
          this.toast("error", ...err.message);
        });

      this.loading = false;
    },
    closeModal() {
      this.$emit("closeModal");
    },
    toast(state, message) {
      this.loading = false;
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
