import { Performance } from "@/api";

export default {
  async createPerformance({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Performance.createPerformance(payload);
      return response;
    } catch (e) {
      console.log("error:", e);
    }
  },

  async getPerformance({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Performance.getPerformance(payload);
      return response;
    } catch (e) {
      console.log("error:", e);
    }
  },

  async updatePerformance({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Performance.updatePerformance(payload);
      return response;
    } catch (e) {
      console.log("error:", e);
    }
  },

  async getByRegisterId({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Performance.getByRegisterId(payload);
      return response.data;
    } catch (error) {
      console.log("error:", error);
      throw error.response.data;
    }
  },

  async getCountByRegisterType({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Performance.getCountByRegisterType(payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getSummaryReport({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Performance.getSummaryReport(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getChainOfActions({ state }, id) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Performance.getChainOfActions(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
