var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.sections),function(section,index){return _c('section',{key:index,staticClass:"mb-3"},[_c('div',[_c('h3',{staticClass:"font-bold text-lg text-green-500"},[_vm._v(_vm._s(section.sectionHeader))]),(section.sectionSubHeadline)?_c('h5',{staticClass:"text-xs text-gray-400 mb-3"},[_vm._v("("+_vm._s(section.sectionSubHeadline)+")")]):_vm._e()]),(section.value)?_c('div',{staticClass:"mb-3"},_vm._l((section.value),function(control,index){return _c('div',{key:index},[(control.component == _vm.formControls.TEXT 
          || control.component == _vm.formControls.PLAIN_TEXT
          || control.component == _vm.formControls.NUMBER
          || control.component == _vm.formControls.PASSWORD
          || control.component == _vm.formControls.EMAIL
          || control.component == _vm.formControls.TEXTAREA
          || control.component == _vm.formControls.SELECT
          || control.component == _vm.formControls.RADIO
          || control.component == _vm.formControls.SWITCH
          )?_c('div',{staticClass:"mt-2"},[(control.value?.value?.value)?_c('p',{staticClass:"text-sm font-bold text"},[_vm._v(_vm._s(control.value.value.value))]):(control.value?.value)?_c('p',{staticClass:"text-sm font-bold text"},[_vm._v(_vm._s(control.value.value))]):_c('p',{staticClass:"text-sm font-bold text"},[_vm._v(_vm._s(control.value))]),_c('div',{staticClass:"text-left text-xs"},[_vm._v(_vm._s(control.label)+" ")])]):_vm._e(),(control.component === _vm.formControls.CHECKBOX)?_c('div',{staticClass:"mt-2"},[(control.value?.value?.value)?_c('div',_vm._l((control.value?.value?.value),function(checkbox,ky){return _c('p',{key:ky,staticClass:"text-sm font-bold text"},[_vm._v(" "+_vm._s(checkbox.value)+" ")])}),0):_vm._e(),_c('div',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(control.label))])]):_vm._e(),(control.component == _vm.formControls.DATE_PICKER)?_c('div',{staticClass:"mt-2"},[(control.value?.value)?_c('p',{staticClass:"text-sm font-bold"},[_vm._v(_vm._s(_vm.cleanDateFormat(control.value.value)))]):_c('p',{staticClass:"text-sm font-bold"},[_vm._v(_vm._s(_vm.cleanDateFormat(control.value)))]),_c('div',{staticClass:"text-left text-xs"},[_vm._v(_vm._s(control.label)+" ")])]):_vm._e(),(control.component == _vm.formControls.TIME_PICKER)?_c('div',{staticClass:"mt-5"},[(control.value?.value)?_c('p',{staticClass:"text-sm font-bold"},[_vm._v(_vm._s(_vm.cleanDateFormatWithTime(control.value.value)))]):_c('p',{staticClass:"text-sm font-bold"},[_vm._v(_vm._s(_vm.cleanDateFormatWithTime(control.value)))]),_c('div',{staticClass:"text-left text-xs"},[_vm._v(_vm._s(control.label)+" ")])]):_vm._e(),(control.component == _vm.formControls.SIGNATURE || control.component == _vm.formControls.IMAGE)?_c('div',{staticClass:"mt-5"},[_c('img',{staticClass:"border-2 w-60 bg-gray-700 h-32",attrs:{"src":control.value,"alt":control.label}}),_c('div',{staticClass:"text-left mt-1 text-xs"},[_vm._v(_vm._s(control.label)+" ")])]):_vm._e()])}),0):_vm._e(),_c('br'),_c('hr')])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }