<template>
  <div>
    <FormulateInput
      v-if="isDisplayValue"
      type="number"
      :id="control.id"
      :name="control.name"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
      :min="control.min"
      :max="control.max"
      v-model="control.value"
      :required="control.required"
    />
    <FormulateInput
      v-else
      type="number"
      :id="control.id"
      :name="control.name"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
      :min="control.min"
      :max="control.max"
    />
    </div>
</template>

<script>
export default {
  props: {
    control: {
      type: Object,
      default: () => {}
    },
    isDisplayValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>
