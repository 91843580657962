<template>
  <div>
    <section v-for="(section, index) in sections" :key="index" class="mb-3">
      <div>
        <h3 class="font-bold text-lg text-green-500">{{section.sectionHeader}}</h3>
        <h5 v-if="section.sectionSubHeadline" class="text-xs text-gray-400 mb-3">({{section.sectionSubHeadline}})</h5>
      </div>

      <div v-if="section.value" class="mb-3">
        <div v-for="(control, index) in section.value" :key="index">
          
          <div 
            v-if="control.component == formControls.TEXT 
            || control.component == formControls.PLAIN_TEXT
            || control.component == formControls.NUMBER
            || control.component == formControls.PASSWORD
            || control.component == formControls.EMAIL
            || control.component == formControls.TEXTAREA
            || control.component == formControls.SELECT
            || control.component == formControls.RADIO
            || control.component == formControls.SWITCH
            "
            class="mt-2">
            <p v-if="control.value?.value?.value" class="text-sm font-bold text">{{ control.value.value.value }}</p>
            <p v-else-if="control.value?.value" class="text-sm font-bold text">{{ control.value.value }}</p>
            <p v-else class="text-sm font-bold text">{{ control.value }}</p>
            <div class="text-left text-xs">{{ control.label }} </div>
          </div>

          <div v-if="control.component === formControls.CHECKBOX" class="mt-2">
            <div v-if="control.value?.value?.value">
              <p v-for="(checkbox, ky) in control.value?.value?.value" :key="ky"
                class="text-sm font-bold text">
                {{ checkbox.value }}
              </p>
            </div>
            <div class="text-xs"> {{ control.label }}</div>
          </div>

          <div 
            v-if="control.component == formControls.DATE_PICKER"
            class="mt-2">
            <p v-if="control.value?.value" class="text-sm font-bold">{{ cleanDateFormat(control.value.value) }}</p>
            <p v-else class="text-sm font-bold">{{ cleanDateFormat(control.value) }}</p>
            <div class="text-left text-xs">{{ control.label }} </div>
          </div>
          
          <div 
            v-if="control.component == formControls.TIME_PICKER"
            class="mt-5">
            <p v-if="control.value?.value" class="text-sm font-bold">{{ cleanDateFormatWithTime(control.value.value) }}</p>
            <p v-else class="text-sm font-bold">{{ cleanDateFormatWithTime(control.value) }}</p>
            <div class="text-left text-xs">{{ control.label }} </div>
          </div>

          <div v-if="control.component == formControls.SIGNATURE || control.component == formControls.IMAGE"  class="mt-5">
            <img class="border-2 w-60 bg-gray-700 h-32" :src="control.value" :alt="control.label"/>
            <div class="text-left mt-1 text-xs">{{ control.label }} </div>
          </div>
        </div>
      </div>
      <br><hr>
    </section>
  </div>
</template>
<script>
import { FORM_CONTROLS } from '@/_helper/constants'
import { cleanDateFormat, formatDate } from '@/_helper'

export default {
  name: "Filled-Form-Sections-View",
  props: {
    selectedSections: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    selectedSections: {
      deep: true,
      handler() {
        this.setSelectedSections()
      }
    }
  },
  mounted() {
    this.setSelectedSections();
  },
  data() {
    return {
      sections: [],
      formControls: FORM_CONTROLS
    };
  },
  methods: {
    setSelectedSections(){
      this.sections = this.selectedSections
    },
    cleanDateFormat(date){
      if(date['_seconds']) {
        return cleanDateFormat(new Date(date['_seconds'] * 1000));
      }
      return cleanDateFormat(date)
    },
    cleanDateFormatWithTime(date){
      if(date['_seconds']) {
        return formatDate(new Date(date['_seconds'] * 1000));
      }
      return formatDate(date)
    }
  },
}
</script>