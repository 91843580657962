<template>
<div>
  <div class="mb-16">
    <div class="font-bold text-lg mb-5"
        :class="[isPassed ? 'text-green-500' : 'text-red-500']"
      >
        VEHICLE CHECK {{isPassed ? 'PASSED' : 'FAILED'}}
    </div>
    <div class="mb-1">
      <div class="font-bold text-black-primary text-base">
          {{vehicle.vehiclePlate}}
      </div>
      <div class="text-xs"> Plate Number</div>
    </div><br/>

    <div class="w-full">
      <div v-for="(section, j) in sections" :key="j" class="mb-3">

        <div v-if="section.value" class="mb-3">
          <div v-for="(control, ja) in section.value" :key="ja">

            <div
                v-if="control.component == formControls.CHECKBOX
                || control.component == formControls.RADIO"
                >

              <div  class="mb-2 flex items-center" v-if="isPillet(control?.value?.value)">
                <div class="option-pill-view">

                  <div v-if="control.value?.isIssue" class='bg-red-400 text-white w-11 text-center'> {{control?.value?.value}} </div>
                  <div v-else class='bg-green-400 text-white w-11 text-center'> {{control?.value?.value}} </div>
                  <div class="bg-gray-200">&nbsp;-&nbsp;&nbsp;</div>

                </div>

                <div class="text-xs">
                  {{ control.label }}
                </div>
              </div>
            </div>

          </div>
        </div>
        <br><hr>
      </div>
    </div>

    <template>
      <div class="flex flex-col">
        <div class="w-full text-left items-start pt-7 mr-2">
          <span class="pl-6 text-sm">{{ cleanDateFormat(savedData?.date) }}</span>
          <div class="w-40 border-t mt-1 pt-1 text-center text-xs">Date submitted</div>
        </div>
        <div class="w-full text-right flex flex-col items-end mr-2">
          <section v-for="(section, l) in sections" :key="l" class="w-full text-right flex flex-col items-end">
            <div v-if="section.value" class="mb-3">
              <div v-for="(control, la) in section.value" :key="la">
                <div v-if="control.component == formControls.SIGNATURE || control.component == formControls.IMAGE"  class="mt-5">
                  <img class="border-2 w-60 bg-gray-700 h-32" :src="control.value" :alt="control.label"/>
                  <div class="text-left mt-1 text-xs">{{ control.label }} </div>
                </div>
              </div>
            </div>
            <br><hr>
          </section>
        </div>
      </div>
    </template>
  </div>
</div>
</template>
<script>
import { FORM_CONTROLS } from '@/_helper/constants'
import { cleanDateFormat, isPilletOptionAvailable } from '@/_helper'

export default {
  name: "Pre-Start-Check-Details-View",
  props: {
    activePreStartCheck: {
      type: Object,
      default: () => {}
    },
  },
  watch: {
    activePreStartCheck: {
      deep: true,
      handler() {
        this.setActivePreStartCheckData()
      }
    }
  },
  created() {
    this.loading = true
    this.setActivePreStartCheckData()
    setTimeout(() => this.loading = false, 400)
  },
  data() {
    return {
      vehicle: {},
      vehiclePrestart: {},
      isPassed: false,
      collapsed: true,
      loading: true,
      formControls: FORM_CONTROLS,
      savedData: {}
    };
  },
  methods: {
    setActivePreStartCheckData(){
      this.vehicle = this.activePreStartCheck?.vehicle
      this.isPassed = this.activePreStartCheck?.isPassed
      this.sections = this.activePreStartCheck?.savedData?.sections
      this.savedData = this.activePreStartCheck?.savedData
    },
    onCollapsed() {
      this.collapsed = !this.collapsed;
    },
    isPillet(option){
      return isPilletOptionAvailable(option)
    },
    cleanDateFormat(date){
      return cleanDateFormat(date, true, true)
    }
  }
}
</script>