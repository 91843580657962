<template>
  <div>
    <div v-if="readSolo" class="h-full flex flex-col">
      <Tabs clickEvent @click="fetchTab" class="flex flex-col h-full">
        <Tab title="All with Contract" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="soloContractData"
            @onPageChange="onPageChange($event)"
          >
            <template slot="tableFilters">
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template
              v-if="writeSolo && !noPaymentGateway"
              slot="callToActionTop"
            >
              <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                <button
                  class="btn btn-primary"
                  @click.prevent="generateBillingReport()"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Generate Billing Report
                </button>
                <button
                  class="btn btn-primary"
                  @click.prevent="showInviteLink()"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Show invite link
                </button>
                <button
                  class="btn btn-primary"
                  @click.prevent="newDriver()"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Add / Link Driver
                </button>
              </div>
            </template>

            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="clipboard"
                          class="my-auto mr-2"
                        />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editDriver(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                    <li v-if="data.status == 'Pending'">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="resendInvite(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="paper-plane"
                          class="my-auto mr-2"
                        />
                        Resend
                      </StyledIconedLink>
                    </li>
                    <li><DrillDown :uid="data.uid" role="driver" /></li>
                  </ul>
                </Dropdown>
              </td>
            </template>
          </Table>
        </Tab>
        <Tab title="Pending from signup" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="noContractHeader"
            :paginationSettings="paginationSettings"
            :data="driversWithNoContractData"
            @onPageChange="onPendingDriverPageChange($event)"
          >
            <template slot="tableFilters">
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>

            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li v-if="data.status == 'Pending'">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="remindDriverContract(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="paper-plane"
                          class="my-auto mr-2"
                        />
                        Resend
                      </StyledIconedLink>
                    </li>
                    <li><DrillDown :uid="data.uid" role="driver" /></li>
                  </ul>
                </Dropdown>
              </td>
            </template>
          </Table>
        </Tab>
      </Tabs>
    </div>
    <div v-if="!readSolo && hasRole" class="w-full min-h-full text-center mt-5">
      <h1>
        Not authorized to view drivers. Please contact your business admin.
      </h1>
    </div>

    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>
    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <DriverSoloContractStep
          @closeModal="closeModal"
          parent-entity="partner"
        />
      </div>
      <div v-if="modalContent == 'view'">
        <ViewSolo :activeSolo="activeSolo" @editDriver="editDriver" />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditLicenseDetails
          :id="activeSolo.businessMetaData._id"
          @updatedDriver="updateContractDriverData"
        />
      </div>
      <div v-if="modalContent == 'editCard'"></div>
      <div v-if="modalContent == 'inviteLink'">
        <InviteLink
          :isSolo="true"
          :isUrlAvailable="isUrlAvailable"
          :shareableUrl="shareableUrl"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalContent == 'billingReport'">
        <GenerateBillingReport
          :entityId="me.entity?._id"
          parentRole="partner"
          contractType="partner-to-driver"
          @closeModal="closeModal"
        />
      </div>
    </Modal>
    <Modal
      ref="paymentModal"
      :title="modalTitle"
      size="3xl"
      :height="paymentModalHeight"
    >
      <div class="sm:flex sm:items-start mx-4 my-8">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-12 sm:w-12"
        >
          <svg
            class="h-6 w-6 text-orange-primary"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            No Active Payment Gateway
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              If you want to continue adding solo drivers you need to make sure
              that your payment gateway is active or create/set a payment
              gateway to default.
            </p>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          @click.prevent="goToPaymentGateway()"
          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn btn-primary text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Create/Set a Payment Gateway
        </button>
        <button
          @click="closeModal()"
          class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Table, Dropdown } from "@/components/commons";
import Tabs from "@/components/commons/tab/Tabs";
import Tab from "@/components/commons/tab/Tab";
import ViewSolo from "@/components/view/driver/view-solo-driver-details";
import DriverSoloContractStep from "@/components/forms/driver/driver-solo-contract/driver-solo-contract-step.vue";
import EditLicenseDetails from "@/components/forms/driver/edit-license-details.vue";
import GenerateBillingReport from "@/components/forms/payment/generate-billing-report.vue";
import { formatDate, formatPaginationSettings } from "@/_helper";
import _ from "lodash";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import DrillDown from "@/components/modules/DrillDown.vue";
import { mapGetters } from "vuex";
import InviteLink from "./InviteLink.vue";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Solo",
  components: {
    Modal,
    Table,
    Dropdown,
    DriverSoloContractStep,
    DrillDown,
    ViewSolo,
    EditLicenseDetails,
    InviteLink,
    StyledIconedLink,
    GenerateBillingReport,
    Tab,
    Tabs,
  },
  data() {
    return {
      partnerid: "",
      isLoading: false,
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalContent: "",
      modalTitle: "",
      modalId: "",
      soloContracts: [],
      tableHeader: [
        "Full Name",
        "Email Address",
        "Phone Number",
        "Contract Duration",
        "Billing Period",
        "Last Payment Date",
        "Status",
      ],
      driversWithNoContract: [],
      noContractHeader: [
        "Full Name",
        "Email Address",
        "Phone Number",
        "Status",
      ],
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      filterStatuses: {
        all: "All",
        linked: "Linked",
        pending: "Pending",
      },
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: "80vh",
      },
      modalKey: 0,
      readSolo: false,
      writeSolo: false,
      hasRole: true,
      noPaymentGateway: false,
      paymentModalHeight: "20vh",
      urlModalHeight: "300px",
      partnerLink: process.env.VUE_APP_URL,
      me: {},
      shareableUrl: "",
      isUrlAvailable: false,
      submitLoading: false,
      copyLink: false,
      rawData: [],
      activeSolo: {},
    };
  },

  async mounted() {
    this.isLoading = true;
    this.$emit("updateLoading", true);
    this.me = this.$store.getters[`account/me`];

    this.shareableUrl = this.me?.entity?.soloUrl;
    if (this.shareableUrl) {
      this.isUrlAvailable = true;
    }
    let securityRoles = this.me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      this.userType = securityRoles[0].type;
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readSolo = securityAccessObj.readSolo;
      this.writeSolo = securityAccessObj.writeSolo;
    } else {
      this.hasRole = false;
    }

    this.$emit("updateLoading", false);
    this.init();
  },
  methods: {
    async fetchTab(data) {
      switch (data) {
        case "All with Contract":
          await this.init();
          break;
        case "Pending from signup":
          await this.getPendingSolo();
          break;
      }
    },
    //Security Roles
    async getSecurityRolesByIds(ids) {
      const promises = [];
      for (let i = 0; i < ids.length; i++) {
        const promise = this.$store.dispatch(`rolesandrespo/getRoles`, ids[i]);
        promises.push(promise);
      }

      return await Promise.all(promises);
    },
    setSecurityAccess(securityRoles) {
      let initialReadSolo = false;
      let initialWriteSolo = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.solo.readSolo.value) {
          initialReadSolo = securityConfig.solo.readSolo.value;
        }
        if (securityConfig.solo.writeSolo.value) {
          initialWriteSolo = securityConfig.solo.writeSolo.value;
        }
      });

      return {
        readSolo: initialReadSolo,
        writeSolo: initialWriteSolo,
      };
    },
    //End Security Roles

    //initialize content
    async init(paged = 1) {
      this.isLoading = true;
      const entityID = this.me.entity?._id;

      const payments = await this.$store.dispatch(
        `payment/getActivePaymentGatewayByEntityId`,
        entityID
      );
      if (payments.length <= 0) {
        this.noPaymentGateway = true;
        this.paymentModalHeight = "250px";
        this.$refs.paymentModal.toggleModal();
      } else {
        this.noPaymentGateway = false;
      }

      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      await this.$store
        .dispatch(`contract/getPartnerDriverContract`, {
          partnerId: entityID,
          query,
        })
        .then((response) => {
          if (response) {
            if (response?.metadata) {
              this.paginationSettings = formatPaginationSettings(
                response?.metadata
              );
            } else {
              this.paginationSettings = formatPaginationSettings();
            }

            this.rawData = response.results;

            const soloTableData = this.formatItem(
              _.orderBy(response.results, ["createdAt"], ["desc"])
            );
            this.soloContracts = soloTableData;
          } else {
            this.soloContracts = [];
          }
        })
        .catch((error) => {
          this.soloContracts = [];
          console.log(error);
        });

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },

    async getPendingSolo(paged = 1) {
      this.isLoading = true;

      const entityID = this.me.entity?._id;
      const entityRole = "partner";

      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      await this.$store
        .dispatch(`driver/getAllSoloWithNoContract`, {
          data: {
            entityId: entityID,
            entityRole: entityRole,
          },
          query,
        })
        .then((response) => {
          if (response) {
            if (response?.metadata) {
              this.paginationSettings = formatPaginationSettings(
                response?.metadata
              );
            } else {
              this.paginationSettings = formatPaginationSettings();
            }

            const pendingDrivers = this.formatPendingDrivers(
              _.orderBy(response.results, ["createdAt"], ["desc"])
            );
            this.driversWithNoContract = pendingDrivers;
            console.log(response.results);
          } else {
            this.driversWithNoContract = [];
          }
        })
        .catch((error) => {
          this.driversWithNoContract = [];
          console.log(error);
        });

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },

    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async onPendingDriverPageChange(event) {
      if (event.page) {
        await this.getPendingSolo(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },

    newDriver() {
      this.modalContent = "new";
      this.modalTitle = "New Solo";
      this.$refs.modal.toggleModal();
    },

    editDriver(id) {
      this.modalId = id;
      this.activeSolo = this.rawData.filter((item) => item._id === id)[0];
      this.modalContent = "edit";
      this.modalTitle = "Solo Update";
      this.$refs.modal.openModal();
    },

    viewDriver(id) {
      this.activeSolo = this.rawData.filter((item) => item._id === id)[0];
      console.log(this.activeSolo);
      this.modalContent = "view";
      this.modalTitle = "Solo Info";
      this.$refs.modal.toggleModal();
      this.modalId = id;
    },

    editCard(id) {
      this.modalId = id;
      this.modalContent = "editCard";
      this.modalTitle = "Update Card Details";
      this.$refs.modal.openModal();
    },

    showInviteLink() {
      this.modalContent = "inviteLink";
      this.modalTitle = "Invite Solo Drivers";
      this.$refs.modal.toggleModal();
    },

    closeModal() {
      this.$refs.modal.closeModal();
      this.$refs.paymentModal.closeModal();
    },

    goToPaymentGateway() {
      this.$router.push("payment");
    },

    generateBillingReport() {
      this.modalContent = "billingReport";
      this.modalTitle = "Generate Billing Report";
      this.$refs.modal.toggleModal();
    },

    resendInvite(contractId) {
      if (confirm("Resend invite email to this driver?")) {
        this.$store
          .dispatch("contract/resendInvite", contractId)
          .then((driver) => {
            if (driver) {
              location.reload();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },

    remindDriverContract(driverId) {
      if (confirm("Resend invite email to this driver?")) {
        this.$store
          .dispatch("driver/resendSoloInvite", driverId)
          .then((driver) => {
            if (driver) {
              location.reload();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },

    formatItem: function (items) {
      let nItems = [];
      if (items) {
        let status;
        items.forEach((item) => {
          switch (item?.status?.name) {
            case "Accepted":
              status = "Active";
              break;
            case "Suspended":
              status = "Suspended";
              break;
            case "Cancelled":
              status = "Cancelled";
              break;
            case "Saved":
              status = "Active";
              break;
            case "Business_Linked":
              status = "Business Linked";
              break;
            default:
              status = "Pending";
          }
          const solo = {
            id: item?._id,
            name: item?.businessMetaData?.driverName,
            email: item?.businessMetaData?.emailAddress,
            phoneNumber: item?.businessMetaData?.persona.contactPhoneNumber,
            contractDuration: item?.contractDuration?.name,
            billingPeriod: item?.billingPeriod?.name,
            lastPaymentDate: formatDate(item?.lastPaymentDate),
            status: status,
            uid: item?.businessMetaData?.uid,
          };
          nItems.push(solo);
        });
      }
      return nItems;
    },

    formatPendingDrivers: function (items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const solo = {
            id: item?._id,
            name: item?.driverName,
            email: item?.emailAddress,
            phoneNumber: item?.persona.contactPhoneNumber,
            status: "Pending",
            uid: item?.uid,
          };
          nItems.push(solo);
        });
      }
      return nItems;
    },

    parsePendingDrivers(data) {
      return data.map((driverData) => [
        {
          id: driverData.id,
          name: driverData.name,
          itemType: "name",
          hasImage: true,
          status: driverData.status,
          uid: driverData.uid,
        },
        { id: driverData.id, name: driverData.email, itemType: "string" },
        { id: driverData.id, name: driverData.phoneNumber, itemType: "string" },
        { id: driverData.id, name: driverData.status, itemType: "string" },
      ]);
    },

    parseDrivers(data) {
      return data.map((driverData) => [
        {
          id: driverData.id,
          name: driverData.name,
          itemType: "name",
          hasImage: true,
          status: driverData.status,
          uid: driverData.uid,
        },
        { id: driverData.id, name: driverData.email, itemType: "string" },
        { id: driverData.id, name: driverData.phoneNumber, itemType: "string" },
        {
          id: driverData.id,
          name: driverData.contractDuration,
          itemType: "string",
        },
        {
          id: driverData.id,
          name: driverData.billingPeriod,
          itemType: "string",
        },
        {
          id: driverData.id,
          name: driverData.lastPaymentDate,
          itemType: "string",
        },
        { id: driverData.id, name: driverData.status, itemType: "string" },
      ]);
    },
    selectFilteredStatus() {
      this.selectedStatus =
        this.filterStatuses[this.selectedFilteredStatus].value;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },

    async updateContractDriverData() {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", {
        state: "success",
        message: "Solo successfully updated.",
      });
      this.closeModal();
      this.init();
    },
  },
  computed: {
    soloContractData() {
      if (this.soloContracts) {
        return this.parseDrivers(this.soloContracts);
      } else {
        return [];
      }
    },
    driversWithNoContractData() {
      if (this.driversWithNoContract) {
        return this.parsePendingDrivers(this.driversWithNoContract);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
