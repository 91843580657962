<template>
  <div>
    <div
      class="relative h-30 flex flex-row grow-0 justify-between border-solid border-2 p-2 hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
      @click="onSelectComponent"
    >
      <div
        class="border border-gray-400 hover:border-orange-100 transition ease-in-out hover:duration-300 text-gray-400 rounded-md w-full p-4 flex flex-col items-stretch mr-4"
      >
        <draggable
          @change="onDropComponent($event, 'left')"
          group="footer"
          :list="leftHeaderContent"
          tag="ul"
          sort="false"
          class="flex-1"
        >
          <div
            v-for="component in leftHeaderContent.slice(0, 1)"
            :key="component.uuid"
            class="w-30"
          >
            <div
              v-if="component.type === 'Signature Line'"
              class="relative h-full border-solid border-2 p-4 hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
            >
              <span>Signature Line</span>
            </div>

            <div
              v-if="component.type === 'Admin Signature'"
              class="relative h-full border-solid border-2 p-2 hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
            >
              <span>Admin Signature</span>
            </div>
          </div>
        </draggable>
      </div>

      <div
        class="border border-gray-400 hover:border-orange-100 transition ease-in-out hover:duration-300 text-gray-400 rounded-md w-full p-4 flex flex-col items-stretch"
      >
        <draggable
          @change="onDropComponent($event, 'right')"
          group="footer"
          :list="rightHeaderContent"
          tag="ul"
          sort="false"
          class="flex-1"
        >
          <div
            v-for="component in rightHeaderContent.slice(0, 1)"
            :key="component.uuid"
          >
            <div
              v-if="component.type === 'Signature Line'"
              class="relative h-full border-solid border-2 p-2 hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
            >
              <span>Signature Line</span>
            </div>

            <div
              v-if="component.type === 'Admin Signature'"
              class="relative h-full border-solid border-2 p-2 hover:border-dashed hover:border-orange-100 transition ease-in-out hover:duration-300"
            >
              <span>Admin Signature</span>
            </div>
          </div>
        </draggable>
      </div>
      <div
        class="absolute bottom-0 right-0 h-6 w-fit space-x-2 bg-orange-100 flex justify-between items-center py-2 px-1"
        v-if="currentSelected.uuid === dynamicContent.uuid"
      >
        <!-- FIRST OR LAST  -->
        <template v-if="isFirst || isLast">
          <button class="text-white" @click.prevent="removeComponent()">
            <font-awesome-icon :icon="['fas', 'trash']" />
          </button>

          <button
            v-if="isFirst && docParts.length > 1 && !aboveFooter"
            class="text-white"
            @click.prevent="moveDownComponent()"
          >
            <font-awesome-icon :icon="['fas', 'arrow-down']" />
          </button>

          <button
            v-if="isLast && docParts.length > 1 && !belowHeader"
            class="text-white"
            @click.prevent="moveUpComponent()"
          >
            <font-awesome-icon :icon="['fas', 'arrow-up']" />
          </button>
        </template>

        <!-- BELOW HEADER-->
        <template v-else-if="belowHeader">
          <button class="text-white" @click.prevent="removeComponent()">
            <font-awesome-icon :icon="['fas', 'trash']" />
          </button>

          <button
            v-if="belowHeader"
            class="text-white"
            @click.prevent="moveDownComponent()"
          >
            <font-awesome-icon :icon="['fas', 'arrow-down']" />
          </button>
        </template>

        <template v-else>
          <button class="text-white" @click.prevent="copyComponent()">
            <font-awesome-icon :icon="['fas', 'copy']" />
          </button>

          <button class="text-white" @click.prevent="removeComponent()">
            <font-awesome-icon :icon="['fas', 'trash']" />
          </button>

          <button class="text-white" @click.prevent="moveUpComponent()">
            <font-awesome-icon :icon="['fas', 'arrow-up']" />
          </button>

          <button class="text-white" @click.prevent="moveDownComponent()">
            <font-awesome-icon :icon="['fas', 'arrow-down']" />
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "lodash";
import { HELPER } from "@/api/helper";
export default {
  props: {
    dynamicContent: {
      type: Object,
    },
  },

  components: {
    draggable,
  },

  computed: {
    currentSelected: {
      get() {
        return this.$store.state.accreditation.selectedContainer;
      },

      set(newVal) {
        this.$store.state.accreditation.selectedContainer = newVal;
      },
    },

    docParts: {
      get() {
        return this.$store.state.accreditation.docParts;
      },

      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    partIndex: {
      get() {
        const model = _.findIndex(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.dynamicContent.uuid;
          }
        );

        return model;
      },
    },

    content: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.dynamicContent.uuid;
          }
        );

        return model.content;
      },
    },

    currentPart: {
      get() {
        const model = _.find(
          this.$store.state.accreditation.docParts,
          (data) => {
            return data.uuid === this.dynamicContent.uuid;
          }
        );

        return model;
      },
    },

    leftHeaderContent: {
      get() {
        const model = _.filter(this.currentPart?.content, (data) => {
          return data.part === "left";
        });

        return model;
      },
    },

    rightHeaderContent: {
      get() {
        const model = _.filter(this.currentPart?.content, (data) => {
          return (
            data.parentComponent === this.dynamicContent.uuid &&
            data.part === "right"
          );
        });

        return model;
      },
      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    centerHeaderContent: {
      get() {
        const model = _.filter(this.currentPart.content, (data) => {
          return (
            data.parentComponent === this.dynamicContent.uuid &&
            data.part === "center"
          );
        });

        return model;
      },
      set(newVal) {
        this.$store.state.accreditation.docParts = newVal;
      },
    },

    selectedContainer: {
      get() {
        return this.$store.state.accreditation.selectedContainer;
      },

      set(newVal) {
        this.$store.state.accreditation.selectedContainer = newVal;
      },
    },

    isFirst: {
      get() {
        return this.partIndex === 0 ? true : false;
      },
    },

    isLast: {
      get() {
        return this.partIndex === this.docParts.length - 1 ? true : false;
      },
    },

    belowHeader: {
      get() {
        const index = this.partIndex - 1;

        if (index > -1 && this.docParts[index].type === "Headers") {
          return true;
        }

        return false;
      },
    },

    aboveFooter: {
      get() {
        const index = this.partIndex + 1;

        if (
          index < this.docParts.length &&
          this.docParts[index].type === "Closing"
        ) {
          return true;
        }

        return false;
      },
    },
  },

  methods: {
    onSelectComponent() {
      console.log("currentSelected:", this.currentSelected);
      console.log("currentPart:", this.currentPart);
      this.currentSelected = this.currentPart;
    },

    removeComponent() {
      this.$store.state.accreditation.docParts.splice(this.partIndex, 1);
      this.$store.state.accreditation.selectedContainer = false;
    },

    moveUpComponent() {
      const mutatedArr = this.swap(
        this.docParts,
        this.docParts[this.partIndex - 1],
        this.docParts[this.partIndex]
      );

      this.docParts = mutatedArr;
    },

    moveDownComponent() {
      const mutatedArr = this.swap(
        this.docParts,
        this.docParts[this.partIndex + 1],
        this.docParts[this.partIndex]
      );

      this.docParts = mutatedArr;
    },

    copyComponent() {
      let tempComponent = { ...this.currentPart };
      const tempContent = _.cloneDeep(this.currentPart);
      delete tempComponent.content;

      let component = HELPER.cloneDeep(tempComponent);
      const generatedParentId = `${component.type}` + HELPER.getUUIDv4();

      component.content = [];

      if (tempContent && tempContent?.content?.length > 0) {
        const clonedTemp = tempContent.content.map((data) => {
          const generatedContentId = `${component.type}` + HELPER.getUUIDv4();
          data.uuid = generatedContentId;
          data.id = generatedContentId;
          data.parentComponent = generatedParentId;
          return data;
        });

        component.content = [...clonedTemp];
      }

      delete component.uuid;

      component.uuid = generatedParentId;

      this.docParts.push(component);
    },

    swap(list, a, b) {
      return list.map(function (item) {
        if (item === a) {
          return b;
        } else if (item === b) {
          return a;
        }

        return item;
      });
    },

    onDropComponent(data, part) {
      if ("added" in data) {
        const { element } = data.added;

        let newPart = HELPER.cloneDeep(element);
        newPart.part = part;
        newPart.parentComponent = this.currentPart.uuid;

        const existingPart = _.find(this.content, (data) => {
          return (
            data.part === part && data.parentComponent === this.currentPart.uuid
          );
        });

        if (existingPart) {
          const contentExistingIndex = _.findIndex(this.content, (data) => {
            return data.part === part;
          });

          this.content[contentExistingIndex] = newPart;
        } else {
          this.content.push(newPart);
        }
      }
    },
  },
};
</script>
