<template>
  <div>
    <Table
      v-if="readTechProviders"
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="techProvidersData"
      @search-table="queryForKeywords"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewTechProvider(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                </StyledIconedLink>
              </li>
              <li v-if="writeTechProviders">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="editTechProvider(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                </StyledIconedLink>
              </li>
              <li v-if="writeTechProviders">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="deleteTechProvider(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="trash" class="my-auto mr-2" /> Delete
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <template slot="callToActionTop">
        <button
          v-if="writeTechProviders"
          class="btn btn-primary"
          @click.prevent="newTechProvider()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          New Tech Provider
        </button>
      </template>
      <template slot="informationalPanel">
        <div
          class="flex p-3 mt-3 text-sm text-blue-700 bg-white rounded-lg shadow"
          role="alert"
        >
          <div>
            <span
              class="font-bold text-xs text-white px-2 py-1 mr-1 rounded"
              :style="{ backgroundColor: `${theme.secondaryColor}B5` }"
              >GET</span
            >
            <span class="text-italic text-xs">{{ ewdApiLink }}</span>
          </div>
        </div>
      </template>
      <div>Not Found</div>
    </Table>

    <div v-if="!readTechProviders && hasRole" class="text-center">
      <h1>
        Not authorized to view tech providers. Please contact your
        {{ userType }}.
      </h1>
    </div>

    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal ref="modal" :title="modalTitle" size="lg" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <NewTechProvider @closeRefresh="closeRefresh" />
      </div>
      <div v-if="modalContent == 'view'">
        <TechProviderView
          @editTechProvider="editTechProvider"
          @deleteTechProvider="deleteTechProvider"
          :detailsId="modalId"
        />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditTechProvider @closeRefresh="closeRefresh" :detailsId="modalId" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { API } from "@/api/config";
import { Modal, Table, Dropdown } from "@/components/commons/";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import { formatDate, formatPaginationSettings } from "@/_helper";
import TechProviderView from "@/components/view/techprovider";
import {
  NewTechProvider,
  EditTechProvider,
} from "@/components/forms/techprovider/";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Admin-TechProviders",
  components: {
    Modal,
    Table,
    Dropdown,
    TechProviderView,
    NewTechProvider,
    EditTechProvider,
    StyledIconedLink,
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      techproviders: [],
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        "Name",
        "Inbound API Key",
        "Outbound API Key",
        "URL",
        "Created At",
        "Enable / Disable",
      ],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: {
        all: "All",
        linked: "Linked",
        pending: "Pending",
      },
      userType: null,
      readTechProviders: false,
      writeTechProviders: false,
      hasRole: true,
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      ewdApiLink: `${API.ACCOUNTS.defaults.baseURL}ewdapi`,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    newTechProvider: function () {
      this.modalContent = "new";
      this.modalTitle = "New Tech Provider";
      this.$refs.modal.openModal();
    },
    viewTechProvider: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Tech Provider Info";
      this.modalId = id;
    },
    editTechProvider: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "edit";
      this.modalTitle = "Tech Provider Edit";
      this.modalId = id;
    },
    deleteTechProvider(id) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store
          .dispatch(`techprovider/deleteTechProvider`, id)
          .then((techprovider) => {
            if (techprovider && techprovider._id) {
              this.init();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },
    async init(paged = 1) {
      this.isLoading = true;
      this.$emit("updateLoading", true);
      const me = this.$store.getters[`account/me`];
      let securityRoles = me?.securityRoles;
      if (securityRoles && securityRoles.length > 0) {
        this.userType = securityRoles[0].type;
        const securityAccessObj = await this.setSecurityAccess(securityRoles);
        this.readTechProviders = securityAccessObj.readTechProviders;
        this.writeTechProviders = securityAccessObj.writeTechProviders;
      } else {
        this.hasRole = false;
      }

      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status != "all")
        query.filter.status = { $eq: this.filter.status };

      const techprovider = await this.$store.dispatch(
        `techprovider/getTechProvider`,
        { query }
      );
      if (techprovider) {
        if (techprovider?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            techprovider?.metadata
          );
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const techprovidersTableData = this.formatItem(
          _.orderBy(techprovider.results, ["createdAt"], ["desc"])
        );
        this.techproviders = techprovidersTableData;
      } else {
        this.techproviders = [];
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
      this.$emit("updateLoading", false);
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    closeRefresh() {
      this.$refs.modal.closeModal();
      this.init();
    },
    formatItem: function (items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const techprovider = {
            id: item["_id"],
            name: item["name"] || "",
            inboundApiKey: item["inboundApiKey"] || "",
            outboundApiKey: item["outboundApiKey"] || "",
            created: formatDate(item["createdAt"]),
            url: item["url"] || "",
            status: item["isActive"],
          };
          nItems.push(techprovider);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseTechProvider(data) {
      return data.map((techProviderData) => [
        {
          id: techProviderData.id,
          name: techProviderData.name,
          itemType: "name",
        },
        {
          id: techProviderData.id,
          name: techProviderData.inboundApiKey,
          itemType: "string",
        },
        {
          id: techProviderData.id,
          name: techProviderData.outboundApiKey,
          itemType: "string",
        },
        {
          id: techProviderData.id,
          name: techProviderData.url,
          itemType: "string",
        },
        {
          id: techProviderData.id,
          name: techProviderData.created,
          itemType: "string",
        },
        {
          id: techProviderData.id,
          name: techProviderData.status,
          itemType: this.writeTechProviders ? "toggle" : "string",
        },
      ]);
    },
    setSecurityAccess(securityRoles) {
      let initialReadTechProviders = false;
      let initialWriteTechProviders = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.techProviders.readTechProviders.value) {
          initialReadTechProviders =
            securityConfig.techProviders.readTechProviders.value;
        }
        if (securityConfig.techProviders.writeTechProviders.value) {
          initialWriteTechProviders =
            securityConfig.techProviders.writeTechProviders.value;
        }
      });

      return {
        readTechProviders: initialReadTechProviders,
        writeTechProviders: initialWriteTechProviders,
      };
    },
  },
  computed: {
    techProvidersData() {
      if (this.techproviders) {
        return this.parseTechProvider(this.techproviders);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>

<style>
.loading.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
