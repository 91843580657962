<template>
    <div
        :data-type="context.type"
        :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
    >
        <list-select
            :context="context"
            :isLoading="isLoading"
            :options="context.options"
        />
    </div>
</template>
<script>
import ListSelect from '@/components/commons/ui/list-select/ListSelect';

const GeneralSelect = {
    name: 'GeneralSelect',
    components: {
        ListSelect
    },
    props: {
        context: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        isLoading: false
    }),
}

export const VueFormulateGeneralSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      GeneralSelect,
    },
    library: {
      "general-select": {
        classification: "select",
        component: "GeneralSelect",
      },
    },
  });
}

export default GeneralSelect;
</script>