<template>
  <div>
    Admin Dashboard
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default{
  name:"Admin-Dashboard",
  components:{},
  computed: {
     ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>