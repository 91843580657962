<template>
  <div class="w-full min-h-full flex-row items-center">
    <div class="w-full min-h-full flex-row">
      <FormBuilder
         :isForm="true"
         :isEditMaster="true"
        />
    </div>
  </div>
</template>
<script>
import FormBuilder from '@/components/commons/FormBuilder'

export default {
   components: {
      FormBuilder
   },
   data: () => ({
      formData: null,
      isRenderer: true,
      readOnly: false,
   }),
}
</script>