<template>
  <fragment>
        <ul class="flex flex-col py-0 border-b border-t border-orange-primary" v-if="proxyRole">
            <li>
                <a
                  @click.prevent="closeDrillDown()"
                  class="cursor-pointer relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-800 text-white border-l-4 border-transparent hover:border-orange-primary pr-6"
                >
                  <span class="text-sm tracking-wide truncate ml-6">Back to <span class="capitalize">{{ this.mainRole }}</span> Account </span>
                </a>
            </li>
            <li v-if="proxyRole == PARTNER_ROLE.name || hasPartner">
                <a
                  @click.prevent="drillDown(hasPartner ? hasPartner.uid:current.uid, PARTNER_ROLE.name)"
                  class="cursor-pointer relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-800 text-white border-l-4 border-transparent hover:border-orange-primary pr-6"
                  :class="{ 'active': proxyRole == PARTNER_ROLE.name }"
                >
                  <span class="text-sm tracking-wide truncate ml-6"><font-awesome-icon icon="level-up-alt" class="my-auto mx-1.5 hover:text-orange transform rotate-90"/>
                    {{hasPartner ? hasPartner.supportEmail:current.email}}
                  </span>
                </a>
            </li>
            <li v-if="proxyRole == RESELLER_ROLE.name || hasReseller">
                <a
                  @click.prevent="drillDown(hasReseller ? hasReseller.uid:current.uid, RESELLER_ROLE.name)"
                  class="cursor-pointer relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-800 text-white border-l-4 border-transparent hover:border-orange-primary pr-6"
                  :class="{ 'active': proxyRole == RESELLER_ROLE.name }"
                >
                  <span class="text-sm tracking-wide truncate ml-8"><font-awesome-icon icon="level-up-alt" class="my-auto mx-1.5 hover:text-orange transform rotate-90"/>
                    {{hasReseller ? hasReseller.supportEmail:current.email}}
                  </span>
                </a>
            </li>
            <li v-if="proxyRole == BUSINESS_ROLE.name || hasBusiness">
                <a
                  @click.prevent="drillDown(hasBusiness ? hasBusiness.uid:current.uid, BUSINESS_ROLE.name)"
                  class="cursor-pointer relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-800 text-white border-l-4 border-transparent hover:border-orange-primary pr-6"
                  :class="{ 'active': proxyRole == BUSINESS_ROLE.name }"
                >
                  <span class="text-sm tracking-wide truncate ml-10"><font-awesome-icon icon="level-up-alt" class="my-auto mx-1.5 hover:text-orange transform rotate-90"/>
                    {{hasBusiness ? hasBusiness.supportEmail:current.email}}
                  </span>
                </a>
            </li>
            <li v-if="proxyRole == DRIVER_ROLE.name">
                <a class="active relative flex flex-row items-center h-10 focus:outline-none hover:bg-gray-800 text-white border-l-4 border-transparent hover:border-orange-primary pr-6">
                  <span class="text-sm tracking-wide truncate ml-12"><font-awesome-icon icon="level-up-alt" class="my-auto mx-1.5 hover:text-orange transform rotate-90"/>
                    {{current.email}}
                  </span>
                </a>
            </li>
        </ul>
  </fragment>
</template>

<script>
import VueCookies from 'vue-cookies';
import { PARTNER_ROLE, RESELLER_ROLE, BUSINESS_ROLE, DRIVER_ROLE } from '@/constants/usertype'
import _ from 'lodash'

export default {
  name: 'Proxy-Nav',
  props: {},
  data(){
    return {
      current: {},
      mainRole : null,
      proxyRole : null,
      proxySite: null,
      hasPartner: false,
      hasReseller: false,
      hasBusiness: false,
      PARTNER_ROLE,
      RESELLER_ROLE,
      BUSINESS_ROLE,
      DRIVER_ROLE,
      parents:[]
    };
  },
  async mounted(){
    this.proxyRole = this.$store.getters[`account/proxyRole`];
    this.proxySite = this.$store.getters[`account/proxySite`];
    this.mainRole = this.$store.getters[`account/mainRole`];

    if (this.proxyRole) {
      const me = this.$store.getters[`account/me`];
      if (me) {
        this.current = me;
        let userData = await this.$store.dispatch(`${this.proxyRole}/get${_.startCase(this.proxyRole)}ByUID`,me.uid);
        if (userData?.parentRole && userData?.parentUid) {
          if (userData?.parentRole == PARTNER_ROLE.name && this.mainRole != PARTNER_ROLE.name && this.mainRole != BUSINESS_ROLE.name && this.mainRole != DRIVER_ROLE.name) {
            this.hasPartner = await this.$store.dispatch(`${userData?.parentRole}/get${_.startCase(userData?.parentRole)}ByUID`,userData?.parentUid);
          }
          
          if (userData?.parentRole == BUSINESS_ROLE.name && this.mainRole != BUSINESS_ROLE.name) {
            this.hasBusiness = await this.$store.dispatch(`${userData?.parentRole}/get${_.startCase(userData?.parentRole)}ByUID`,userData?.parentUid);
            if (this.hasBusiness?.parentRole == PARTNER_ROLE.name && this.mainRole != BUSINESS_ROLE.name) {
              this.hasPartner = await this.$store.dispatch(`${this.hasBusiness?.parentRole}/get${_.startCase(this.hasBusiness?.parentRole)}ByUID`,this.hasBusiness?.parentUid);
            }
          }
        }
        // if(userData['partner']){
        //   this.hasPartner = userData['partner'];
        // }
        // if(userData['reseller']){
        //   this.hasReseller = userData['reseller'];
        // }
        // if(userData['business']){
        //   this.hasBusiness = userData['business'];
        //   if(this.hasBusiness){
        //     if(this.hasBusiness['reseller']){
        //       this.hasReseller = this.hasBusiness['reseller']
        //     }
        //   }
        // }
      }
    }
  },
  methods: {
    // getParent: async function(userData){
    //   let parentData = await this.$store.dispatch(`${userData?.parentRole}/get${_.startCase(userData?.parentRole)}ByUID`,userData?.parentUid);
    //   this.parents.push(parentData)
    //   if(parentData?.parentRole && parentData?.parentUid && parentData?.parentRole != 'admin'){
    //     await this.getParent(parentData)
    //   }
    // },
    closeDrillDown: async function(){
      VueCookies.remove('proxyUser');
      VueCookies.remove('proxyRole');
      VueCookies.remove('proxySite');
      VueCookies.remove("proxyDriverProfileUid");
      VueCookies.remove('accessProxyToken');
      VueCookies.remove('refreshProxyToken');
      const mainRole = this.$store.getters[`account/mainRole`];
      window.location.href = this.$store.getters['allURL'][mainRole]['default'];
    },
    drillDown: async function(uid,role){
      if (!uid) uid = this.current.uid;
      this.$store.commit('account/setProxyRole', role)
      await this.$store.dispatch(`account/proxyUser`, { uid })
      window.location.href = this.$store.getters['allURL'][role]['default'];
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a.active{
  @apply outline-none bg-gray-800 border-orange-primary font-semibold;
}
</style>
