<template>
  <div>
    <div v-if="readFormVersions" class="w-full min-h-full flex-row items-left">
      <hr>
      <FormTemplate 
          v-if="forms.length"
          :data="computedForms"
          @cloneForm="cloneForm"
          :readFormVersions="readFormVersions"
          :writeForms="writeForms"
          src="forms"
        >
      </FormTemplate>
      <div v-if="!readFormVersions" class="bg-white-100 border-l-4 border-orange-500 text-orange-700 p-4" role="alert">
        <p class="font-bold">Empty</p>
        <p>Form versions not found.</p>
      </div>
    </div>
    <div v-if="!readFormVersions" class="w-full min-h-full text-center mt-5">
      <h1>Not authorized to view form versions. Please contact your business admin.</h1>
    </div>
  </div>
</template>
<script>
import { FormTemplate } from '@/components/commons';
import {formatDate} from '@/_helper'
import _ from 'lodash'

export default{
  name:"Business-Forms",
  components:{ FormTemplate },
  data(){
    return { 
      forms: [],
      activeForm: null,
      BusinessId: '',
      isFormActivated: false,
      readFormVersions: false,
      writeForms: false
    }
  },
  async mounted(){
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readFormVersions = securityAccessObj.readFormVersions;
      this.writeForms = securityAccessObj.writeForms;
    }
    this.init();
    this.$emit("updateLoading", false);
  },
  methods: {
    async init() {
      const formId = this.$route.params.id;
      const forms = await this.$store.dispatch(`form/getBusinessFormVersionsByParentId`, formId);
      if(forms){
        const formData = this.formFormatItem(_.orderBy(forms, ['createdAt'],['desc']));
        this.forms = formData;
        this.activeForm = formData[0];
      }
    },
    async cloneForm(formId) {
      return await this.$store.dispatch(`form/cloneForm`, formId)   
      .then((res) => {
          this.toast('success', res.message);
          this.submitting = false;
        }).catch(err => {
          this.toast('error', err.message);
          this.submitting = false;
        })
    },
    setSecurityAccess(securityRoles) {
      let initialReadForms = false;
      let initialWriteForms = false;
      securityRoles = JSON.parse(securityRoles);
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.forms.readFormVersions?.value) {
          initialReadForms = securityConfig.forms.readFormVersions?.value;
        }
        if (securityConfig.forms.readFormVersions?.value) {
          initialWriteForms = securityConfig.forms.writeForms?.value;
        }
      });
      return {
        readFormVersions: initialReadForms,
        writeForms: initialWriteForms
      };
    },
    formFormatItem(items){
      let fItems = [];
      if(items){
        items.map(item => {
          const form = {
            id:item['_id'],
            isActive:item['isActive'],
            name:item['name'] || '',
            createdAt: formatDate(item['createdAt']) || '',
            sections:item['sections'] || '',
            histories:item['histories'] || '',
          }
          fItems.push(form);
        });
      }
      return fItems;
    },
    parseForms(data){
      return data.map((formData) => ([
        { id: formData.id, name: formData.name, itemType: 'string'},
        { id: formData.id, isActive: formData.isActive, itemType: 'string'},
        { id: formData.id, sections: formData.sections, itemType: 'array'},
        { id: formData.id, histories: formData.histories, itemType: 'array'},
        { id: formData.id, createdAt: formData.createdAt, itemType: 'string'},
      ]));
    },
    toast(state,message){
      this.$store.commit('setDialogNotify',true);
      this.$store.commit('setMessageNotify',{state, message});
    },
  },
  computed: {
    computedForms(){
      return this.forms? this.parseForms(this.forms) : [];
    }
  },
}
</script>