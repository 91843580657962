<template>
  <div>
    <FormulateForm v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">

        <div v-if="pageInitLoading && preData.selectedModules.cplValues.length > 0" class="text-center mb-16">
          <ui-spinner class="mx-auto"></ui-spinner>
          Generating Contract Summary Details...
        </div>
        <div v-if="!pageInitLoading">
          <div class="p-5 mt-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold ">Contract Summary Details</div>

            <table>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Contract Period: </div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">{{preData.contractDuration.label}}</div>
                </td>
              </tr>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Billing Period: </div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">{{preData.billingPeriod.label}}</div>
                </td>
              </tr>
              
            </table>
          </div>

          <div v-if="cplValues.length > 0" class="p-5 mt-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold ">Core Platform Licences Pricing Summary</div>
              <table class="w-full text-center">
                <thead class="border-b border-line-regular">
                  <th class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-left">  
                    Module
                  </th>
                  <th class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right">
                    Customer Buy Price
                  </th>
                  <th class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right">
                    Your Buy Price
                  </th>
                  <th class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right">
                    Margin
                  </th>
                </thead>
                <tbody>
                  <tr v-for="(modules, index) in cplValues" :key="index">
                    <td :key="index" class="text-main px-4 text-sm border-line-regular text-left">
                      {{modules.standardPricingMaster.name === 'EWD-FT' ? 'EWD' : modules.standardPricingMaster.name}}
                    </td>
                    <td class="text-main px-4 text-base border-line-regular text-right">
                      ${{modules.customerBuyPrice.toFixed(2)}}
                    </td>
                    <td class="text-main px-4 text-base border-line-regular text-right">
                      ${{modules.sourceBuyPricing.toFixed(2)}}
                    </td>
                    <td class="text-main px-4 text-base border-line-regular text-right">
                      ${{modules.margin}}
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
        </div>

        

        <div v-if="cplValues.length > 0" class="p-5 mt-5 mb-10 border rounded-lg group">
          <div class="group-title pb-5 font-bold">Billing Summary</div>
          
          <div class="py-2 ml-auto mt-5 w-full border-t">
            <div class="flex justify-between mb-3">
              <div class="text-gray-800 text-right flex-1">Total incl. GST</div>
              <div class="text-right w-40">
                <div class="text-gray-800 font-medium" x-html="netTotal">${{ totals.totalGST.toFixed(2) }}</div>
              </div>
            </div>
            <div class="flex justify-between mb-4">
              <div class="text-sm text-gray-600 text-right flex-1">GST(10%) incl. in Total</div>
              <div class="text-right w-40">
                <div class="text-sm text-gray-600" x-html="totalGST">${{ totals.gst.toFixed(2) }}</div>
              </div>
            </div>
            <div class="flex justify-between mb-4">
              <div class="text-sm text-gray-600 text-right flex-1">Gateway Fee({{ paymentGateway.surchargePercentageFee }}% + ${{ paymentGateway.otherFees }})</div>
              <div class="text-right w-40">
                <div class="text-sm text-gray-600" x-html="totalGST">{{ totals.gatewayFee.toFixed(2) }}</div>
              </div>
            </div>
          </div>

          <div v-if="hasActivePaymentGateway" class="p-5 mt-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold ">Payment Details</div>
            <div>
                <FormulateInput
                  type="checkbox"
                  class="formulate-input"
                  name="billedExternally"
                  label="Billed Externally"
                  v-model="billedExternally"
                  label-class="formulate-input-label formulate-input-label--after"
                  input-class=""
                  wrapper-class="formulate-input-wrapper"
                  outer-class=""
                  element-class="formulate-input-element formulate-input-element--checkbox"
                />
                <div v-if="!billedExternally">
                  <label class="font-medium text-sm mb-1">Card Details</label>
                  <stripe-element-card
                    class="mb-5"
                    ref="cardRef"
                    :pk="publishableKey"
                    @token="tokenCreated"
                    @element-change="updateSubmitButton"
                    @element-blur="cardChange"
                    :hidePostalCode="true"
                  />
                </div>
            </div>
          </div>
        </div>    
        <div class="border-t border-neutral-light mt-8">
          <div class="flex items-center justify-end w-full mt-4 space-x-4">
            <FormulateInput
              type="button"
              label="Back"
              input-class="btn btn-cancel w-full"
              @click.prevent="onPrevious()"
            />
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-full"
              :disabled="submitLoading"
              :style="{backgroundColor: theme.secondaryColor}"
            >
            <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> {{ buttonText }}</span>  
            <span v-if="!submitLoading">Submit</span>  
            </FormulateInput>

          </div>
        </div>
      </div>
    </FormulateForm>
    <Modal ref="processingPaymentModal" :title="modalTitle" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-12 sm:w-12">
          <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <div class="text-center mt-4 w-full">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Processing your payment</h3>
        </div>
        <div class="text-center mt-2">
            <p class="text-sm text-gray-500">Please wait while we process your payment request.</p>
        </div>
      </div>
    </Modal>
    <Modal ref="paymentSuccessfulModal" :title="modalTitle" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-12 sm:w-12">
          <font-awesome-icon icon="check" class="h-6 w-6 text-green-800" />
        </div>
        <div class="text-center mt-4 w-full">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Payment successful</h3>
        </div>
        <div class="text-center mt-2">
            <p class="text-sm text-gray-500">We have received your payment successfully. Please wait as we redirect you back to the main page.</p>
        </div>
      </div>
    </Modal>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>

<script>
import { Modal } from '@/components/commons'
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { mapGetters } from 'vuex'
export default {
  name: "Summary",
  components: {
    StripeElementCard,
    Modal
  },
  props: {
    preData: {
      type: Object,
      required: false,
      default: null,
      },
  },
  data() {
  return {
    loading: false,
    pageInitLoading: true,
    submitLoading: false,
    values: {},
    errorMessage:'',
    cplValues: {},
    smValues: {},
    logmasterTerm: {},
    interChangingData: [],
    linkDriver: {},
    newDriver: {},
    token: null,
    hasActivePaymentGateway: false,
    publishableKey: null,
    paymentGatewayId: null,
    paymentGateway: {},
    totals: {
      subTotal: 0,
      totalGST: 0,
      gst: 0,
      gatewayFee: 0,
      amountDue: 0
    },
    paymentModalHeight: '20vh',
    modalTitle: "",
    billedExternally: false,
    buttonText: 'Saving data...'
  }
},
async mounted(){
  this.pageInitLoading = true
  const me = this.$store.getters[`account/me`];
  const paymentGateway = await this.$store.dispatch("payment/getPaymentGatewayByUid", { uid: me?.uid, role: me?.role.name });
  if (paymentGateway) {
    this.paymentGatewayId = paymentGateway._id;
    this.publishableKey = paymentGateway.apiKey;
    this.hasActivePaymentGateway = true;
    this.paymentGateway = paymentGateway;
  }
  // console.log(paymentGateway)
  await this.init()
},
methods: {
  updateSubmitButton() {
    this.submitLoading = true
    this.buttonText = 'Validating Card Details...'
  },
  cardChange() {
    this.$refs.cardRef.submit();
  },
  tokenCreated(token) {
    this.token = token.id;
    this.submitLoading = false
    this.buttonText = 'Saving data...'
  },
  async init(){
    // this.preData.businessData.entityId = await this.$store.getters[`account/me`].entity._id;
    if(this.preData) {
      if(this.preData['linkDriver']) {
        this.linkedDriver = this.preData['linkDriver'];
      }
      else if(this.preData['newDriver']) {
        this.newDriver = this.preData['newDriver'];
      }
    }
    console.log("Pre data ", this.preData)
    console.log("Driver Data", this.newDriver)
    const me = this.$store.getters[`account/me`];
    if(this.preData?.steps){
        this.values = this.preData.steps?.pricingDetails || null
        this.interChangingData = this.preData
    }
    let rolesParent = null;
    if (me?.role?.name === 'partner') {
      rolesParent = await this.$store.dispatch('partner/getPartnerByUID', me?.uid)
    
    } else if (me?.role?.name === 'reseller') {
      rolesParent = await this.$store.dispatch('reseller/getResellerByUID', me?.uid)
    }
    if(rolesParent){
      const standardPricing = await this.$store.dispatch(`standardpricing/getStandardPricingByMongoId`,  rolesParent._id);
      
      this.buyPricing = standardPricing.filter((item) =>
        item.isRrp === false
      )
    }
    if (me?.role?.name === 'partner') {
      this.logmasterTerm = await this.$store.dispatch('contract/getLogmasterTermByPartner', me?.uid);
    } else if (me?.role?.name === 'reseller') {
      this.logmasterTerm = await this.$store.dispatch('contract/getLogmasterTermByReseller', me?.uid);
    }
    if(this.logmasterTerm){
     
      this.cplValues = this.preData.cplPricing.map((cpl) => {
        let rrpComputedPrice = 0
        if(this.preData.contractDuration.label === 'Monthly'){
          rrpComputedPrice = Number(cpl.pricing.monthly)
            this.buyPricing.forEach((item) => {
            if(item.name == cpl.name){
              cpl.sourceBuyPricing = Number(item.pricing.monthly)
            }
          })
        }
        else if(this.preData.contractDuration.label === 'Yearly') {
          if(this.preData.billingPeriod.label === 'Yearly'){
            rrpComputedPrice = Number(cpl.pricing.yearly)
            this.buyPricing.forEach((item) => {
              if(item.name == cpl.name){
                cpl.sourceBuyPricing = Number(item.pricing.yearly)
              }
            })
          }
          else if (this.preData.billingPeriod.label === 'Monthly') {
            rrpComputedPrice = Number(cpl.pricing.yearly) / 12
            this.buyPricing.forEach((item) => {
              if(item.name == cpl.name){
                cpl.sourceBuyPricing = Number(item.pricing.yearly) / 12
              }
            })
          }
        }
        return {
            ...cpl,
            rrpPrice: rrpComputedPrice.toFixed(2),
            margin: (rrpComputedPrice - cpl.sourceBuyPricing).toFixed(2)
        }
      })
      let priceperseat = 0;
      let seatCount = 0
      let total = 0
      this.cplValues.forEach((object) => {
        priceperseat += object.customerBuyPrice
        seatCount += object.numberOfSeats
        total += object.customerBuyPrice * object.numberOfSeats
      });
        let subTotal = total
        console.log(this.cplValues)
        const gst = (subTotal / 100) * this.paymentGateway.gstPercentageFee
        const totalGST = subTotal + gst
        const gatewayFee = ((totalGST / 100) * this.paymentGateway.surchargePercentageFee) + this.paymentGateway.otherFees
        const amountDue = totalGST + gatewayFee
        this.totals = {
          priceperseat: priceperseat,
          seatCount: seatCount,
          subTotal: subTotal,
          totalGST: totalGST,
          gst: gst,
          gatewayFee: gatewayFee,
          amountDue: amountDue
        }
        console.log(this.totals)
    }
    this.pageInitLoading = false
    console.log(this.cplValues)
  },
  async onSubmit() {
  this.submitLoading = true
  this.buttonText = 'Saving data...'
  this.incompleteForm = true
  const me = this.$store.getters[`account/me`];
  const mergedModulesvalues = [...this.cplValues]
  const formatPayloadModules = mergedModulesvalues.map((modules) => ({
      minimums: modules.numberOfSeats,
      price: {
          monthly: this.preData.billingPeriod.label === 'Monthly' ? Number(modules.rrpPrice) : 0,
          quarterly: 0, //Zero for now as we don't support it yet
          yearly: this.preData.billingPeriod.label === 'Yearly' ? Number(modules.rrpPrice) : 0,
      },
      masterId: modules.masterId,
      ownerMongoId: me.entity._id,
      ownerRole: 'driver'
  }));
  const entityID = me.entity._id;
  if(this.newDriver) {
    this.newDriver['entityId'] = entityID
    this.newDriver['isDemoAccount'] = false;
    // this.$store.dispatch(`driver/createDriver`, this.newDriver)
    //   .then((response) => {
    //     if(response && response._id) {
    //       this.createDriverContract(response, formatPayloadModules)
    //     }
    //   }).catch((error) => {
    //       this.submitLoading = false
    //       this.errorMessage = error?.message
    //   });
    const driverContractData = {...this.newDriver}
    driverContractData.contractDetails = {
      billingPeriodId: this.preData.billingPeriod.value,
      businessModulesDto: formatPayloadModules,
      contractDurationId: this.preData.contractDuration.value,
      contracDurationYears: Number(this.preData.yearCount) || 0,
      activationOption: 'recurring_payment',
      contractTarget: 'solo'
    }
    driverContractData.paymentDetails = {
      entityRole: 'driver',
      paymentGatewayId: this.paymentGatewayId,
      cardToken: this.token,
      isDefault: true,
      isActive: true
    }
    driverContractData.chargeDetails = {
      entityRole: 'driver',
      amount: this.totals.subTotal
    }
    driverContractData.billedExternally = this.billedExternally
    this.$store.dispatch(`driver/createSoloWithContract`, driverContractData)
      .then((response) => {
        if (response && response._id) {
          if(this.billedExternally !== true) {
            this.chargeCard(response);
          } else {
            this.submitLoading = false
            this.$emit('closeModal')
            this.$store.commit('setDialogNotify',true);
            this.$store.commit('setMessageNotify',{state:'success',message:'Solo successfully created.'});
            setTimeout(()=>{location.reload()},4000);
          }
        }
      }).catch((error) => {
          this.submitLoading = false
          this.errorMessage = error?.message
      });
  } else if(this.linkedDriver) {
    this.createDriverContract(this.linkedDriver, formatPayloadModules)
  }
  },
  onPrevious(){
    const postData = this.interChangingData
    this.$emit('backStep', postData)
  },
  createDriverContract(data, modules) {
    const payload = {
      billingPeriodId: this.preData.billingPeriod.value,
      businessMongoId: data._id,
      businessModulesDto: modules,
      contractDurationId: this.preData.contractDuration.value,
      contracDurationYears: Number(this.preData.yearCount) || 0
    }
    this.$store.dispatch('contract/createDriverContract', payload)
      .then((response) => {
        if(response) {
          this.createDriverPaymentMethod(data);
        }
      })
      .catch((error) => {
        this.submitLoading = false
        this.errorMessage = error?.message
      })
  },
  async createDriverPaymentMethod(driver) {
    this.$store.dispatch("payment/createCardPaymentMethod", {
      entityId: driver._id,
      entityRole: 'driver',
      paymentGatewayId: this.paymentGatewayId,
      cardToken: this.token,
      isDefault: true,
      isActive: true,
    }).then((response) => {
        if(response) {
          if(this.billedExternally !== true) {
            this.chargeCard(driver);
          } else {
            this.submitLoading = false
            this.$emit('closeModal')
            this.$store.commit('setDialogNotify',true);
            this.$store.commit('setMessageNotify',{state:'success',message:'Solo successfully created.'});
            setTimeout(()=>{location.reload()},4000);
          }
        }
    })
      .catch((error) => {
        this.submitLoading = false
        this.incompleteForm = false
        this.errorMessage = error?.message
      });
  },
  async chargeCard(driver) {
    this.$refs.processingPaymentModal.toggleModal();
    this.$store.dispatch("payment/chargeCard", 
      {
        entityId: driver._id,
        entityRole: 'driver',
        amount: this.totals.subTotal
      }
    ).then((response) => {
        if(response) {
          this.$refs.processingPaymentModal.closeModal();
          this.$refs.paymentSuccessfulModal.toggleModal();
          setTimeout(() => {
            this.$refs.paymentSuccessfulModal.closeModal();
            this.submitLoading = false
            this.$emit('closeModal')
            this.$store.commit('setDialogNotify',true);
            this.$store.commit('setMessageNotify',{state:'success',message:'Solo successfully created.'});
            setTimeout(()=>{location.reload()},4000);
          }, 2500);
        }
    }).catch((error) => {
        this.$refs.processingPaymentModal.closeModal();
        this.$refs.paymentSuccessfulModal.closeModal();
        this.submitLoading = false
        this.incompleteForm = false
        this.errorMessage = error?.message
        this.$emit('closeModal')
        this.$store.commit('setDialogNotify',true);
        this.$store.commit('setMessageNotify',{state:'error',message:'Charging card failed. Continue setup after account login.'});
      });
  }
},
computed: {
   ...mapGetters('theme', {
  theme: 'getColorScheme'
  })
},
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 2s linear infinite;
}
.custom-options.checkbox {
  .formulate-input-element {
    flex-direction: column;
  }
}
.custom-class {
  .formulate-input-element {
    display: flex;
    margin-right: 0.5em;
  }
  input {
    width: auto;
  }
  .formulate-input-wrapper {
    display: flex;
    width: 100%;
    label::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }
  .formulate-input-group-item {
    padding: 0.5em 0 0 0.5em;
    position: relative;
    margin-right: 1em;
    margin-bottom: 0 !important;
    .formulate-input-wrapper {
      display: flex;
      width: 100%;
      label {
        font-size: 0.8rem !important;
      }
    }
    label::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
    &[data-has-value] {
      background-color: #e0f2fe;
    }
  }
}
</style>