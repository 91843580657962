<template>
	<div class="mb-16">
		<div class="loading centered" v-if="loading">
			<ui-spinner class="mx-auto"></ui-spinner>
		</div>
		<div v-if="!loading">
			<StyledSubmit :backgroundColor="theme.secondaryColor">
				<FormulateForm v-model="values" :schema="addRemarksSchema" @submit="onSubmit" #default="{ hasErrors }">
					<FormulateInput type="submit" label="Submit" input-class="btn btn-primary w-full"
						:disabled="hasErrors || loading" />
				</FormulateForm>
			</StyledSubmit>
			<div class="text-red-500 text-center mb-3" v-if="error">
				{{ errorMessage }}
			</div>
		</div>
	</div>
</template>

<script>
import addRemarksSchema from "./new-remarks-schema";
import { mapGetters } from 'vuex'
import {StyledSubmit} from '@/styled-components'

export default {
	name: "Add-Remarks-Form",
	props: {
		detailsId: {
			type: String,
			default: ""
		}
	},
	components: {StyledSubmit},
	data() {
		return {
			error: false,
			errorMessage: "",
			loading: false,
			values: {},
			schema: addRemarksSchema
		}
	},
	methods: {
		async onSubmit() {
			try {
				const newRemarks = JSON.parse(JSON.stringify(this.values));
				newRemarks.id = this.detailsId;
				await this.$store.dispatch(`devices/addDeviceRemarks`, newRemarks);

				location.reload();
			} catch(error) {
				this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) this.errorMessage = error.message;
			}
		}
	},
  computed: {
    addRemarksSchema() {
      return addRemarksSchema;
    },
     ...mapGetters('theme', {
    theme: 'getColorScheme'
    })
  },
}
</script>