<template>
  <DashboardCard
    :cardIcon="{name: 'briefcase', bg: 'bg-gray-700', textBg: 'text-gray-700'}"
    :cardTitle="title"
    :notificationCount="count"
    class="mr-5"
  >
<!--    <template #default="{props}">-->
<!--      <Transition name="slide">-->
<!--        <div v-if="props.collapsed" class="flex items-center mt-5 ml-12">-->
<!--          <div class="block relative">-->
<!--            <span-->
<!--              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"-->
<!--            >-->
<!--              <font-awesome-icon icon="search" class="text-gray-400" />-->
<!--            </span>-->
<!--            <input-->
<!--              placeholder="Search"-->
<!--              class="h-full appearance-none rounded-l rounded-r-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"-->
<!--              @input="queryForKeywords($event.target.value)"-->
<!--            />-->
<!--          </div>-->
<!--          <div class="flex flex-row">-->
<!--            <div class="relative">-->
<!--              <select-->
<!--                v-model="selectedFilteredSubCategory"-->
<!--                @change="selectFilteredCategory"-->
<!--                class="appearance-none h-full border border-l-none border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"-->
<!--                style="line-height: 1.13"-->
<!--              >-->
<!--                <option-->
<!--                  v-for="(filterSubCategory,index) in filterSubCategories"-->
<!--                  :key="index"-->
<!--                  :value="index"-->
<!--                >-->
<!--                  {{ filterSubCategory }}-->
<!--                </option>-->
<!--              </select>-->
<!--              <div-->
<!--                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"-->
<!--              >-->
<!--                <font-awesome-icon icon="caret-down" class="text-gray-400" />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="flex flex-row">-->
<!--            <div class="relative">-->
<!--              <select-->
<!--                v-model="selectedFilteredStatus"-->
<!--                @change="selectFilteredStatus"-->
<!--                class="appearance-none h-full border border-l-none rounded-r -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"-->
<!--                style="line-height: 1.13"-->
<!--              >-->
<!--                <option-->
<!--                  v-for="(filterStatus,index) in filterStatuses"-->
<!--                  :key="index"-->
<!--                  :value="index"-->
<!--                >-->
<!--                  {{ filterStatus }}-->
<!--                </option>-->
<!--              </select>-->
<!--              <div-->
<!--                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"-->
<!--              >-->
<!--                <font-awesome-icon icon="caret-down" class="text-gray-400" />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </Transition>-->
<!--    </template>-->
<!--    <template slot="cardTable">-->
<!--      <table class="min-w-full leading-normal">-->
<!--        <tbody>-->
<!--          <tr class="hover:bg-gray-200 border-b text-sm">-->
<!--            <td class="p-3">01/03/2022</td>-->
<!--            <td class="p-3">Dashboard Malfunction</td>-->
<!--            <td class="p-3">Critical</td>-->
<!--            <td class="p-3">Pending</td>-->
<!--            <td class="p-3">-->
<!--              <button class="btn btn-primary text-sm">Acknowledge</button>-->
<!--            </td>-->
<!--            <td class="p-3">-->
<!--              <a class="flex cursor-pointer"-->
<!--                ><font-awesome-icon-->
<!--                  icon="level-down-alt"-->
<!--                  class="my-auto mx-1.5 hover:text-orange-primary text-xl"-->
<!--              /></a>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr class="hover:bg-gray-200 border-b text-sm">-->
<!--            <td class="p-3">01/03/2022</td>-->
<!--            <td class="p-3">Engine Failure</td>-->
<!--            <td class="p-3">Critical</td>-->
<!--            <td class="p-3">Completed</td>-->
<!--            <td class="p-3"></td>-->
<!--            <td class="p-3">-->
<!--              <a class="flex cursor-pointer"-->
<!--                ><font-awesome-icon-->
<!--                  icon="level-down-alt"-->
<!--                  class="my-auto mx-1.5 hover:text-orange-primary text-xl"-->
<!--              /></a>-->
<!--            </td>-->
<!--          </tr>-->
<!--          <tr class="hover:bg-gray-200 border-b text-sm">-->
<!--            <td class="p-3">03/03/2022</td>-->
<!--            <td class="p-3">Engine Failure</td>-->
<!--            <td class="p-3">Critical</td>-->
<!--            <td class="p-3">In Process</td>-->
<!--            <td class="p-3">-->
<!--              <button class="btn btn-primary text-sm">Acknowledge</button>-->
<!--            </td>-->
<!--            <td class="p-3">-->
<!--              <a class="flex cursor-pointer"-->
<!--                ><font-awesome-icon-->
<!--                  icon="level-down-alt"-->
<!--                  class="my-auto mx-1.5 hover:text-orange-primary text-xl"-->
<!--              /></a>-->
<!--            </td>-->
<!--          </tr>-->
<!--        </tbody>-->
<!--      </table>-->
<!--    </template>-->
  </DashboardCard>
</template>

<script>
import { DashboardCard } from "@/components/commons"

export default {
  name: "BusinessLinked",
  components: { DashboardCard },
  data() {
    return {
      title: 'Total Business Linked',
      count: '0',
      selectedFilteredStatus: 0,
      filterStatuses: ["All", "Linked", "Pending", "Unlinked"],
      selectedFilteredSubCategory: 0,
      filterSubCategories: ["All", "Minor", "Major", "Critical", "Severe"],
    }
  },
  async mounted() {
    const query = {
      skip: 0,
      limit: 10,
    }

    const me = this.$store.getters[`account/me`];
    const businessList = await this.$store.dispatch(`reseller/getBusinessByResellerUID`, {id: me.uid, query});
    this.count = businessList?.metadata?.total?.toString() || '0';
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
