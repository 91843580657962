import { TIMEZONE } from '@/constants/location'
import { REGION } from '@/constants/region'

export default [
    {
      type: 'group',
      name: 'profile',
      children: [
        {
        type: 'group',
        name: 'recordKeeperAddress',
        label: 'Record Keeper Address:',
        labelClass: 'font-semibold text-sm block -top-7 -left-0 absolute',
        class: 'border rounded p-5 mb-5 mt-10 relative',
        children: [
          {
            name: 'record-keeper-street',
            label: 'Business Address',
            validation: 'required',
            type: 'places-search-input',
            id: 'places-search-record-keeper'
          },
        ]
      },
      {
        type: 'group',
        name: 'baseLocation',
        label: 'Base Location:',
        labelClass: 'font-semibold text-sm block -top-7 -left-0 absolute',
        class: 'border rounded p-5 mb-5 mt-10 relative',
        children: [
          {
            name: 'base-location-street',
            label: 'Business Address',
            validation: 'required',
            type: 'places-search-input',
            id: 'places-search-base-location'
          },
        ]
      },
      {
        name: 'baseTimeZone',
        type: 'select',
        label: 'Base Time Zone',
        options: TIMEZONE,
        validation: 'required'
      },
      {
        name: 'engineRegion',
        type: 'select',
        label: 'Region',
        options: REGION,
        validation: 'required'
      },
      // {
      //   type: "group",
      //   name: "certificate",
      //   label: "Accreditation (Optional)",
      //   labelClass: "font-semibold text-sm block -top-7 -left-0 absolute",
      //   class: "border rounded p-5 mb-5 mt-10 relative",
      //   children: [
      //     {
      //       type: "cert-select",
      //       name: "BFMNumber",
      //       label: "BFM Number",
      //       validation: "optional",
      //     },
      //     {
      //       type: "date",
      //       name: "inductionDate",
      //       label: "Induction Date",
      //       validation: "optional",
      //     },
      //   ],
      // },
    ]
  },
]