<template>
  <div>
    <VueEditor v-model="content"></VueEditor>
    <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
      <button
        class="btn col-span-2 btn-primary mt-2"
        @click.prevent="onSubmit"
        :style="{ backgroundColor: theme.secondaryColor }"
      >
        Submit
      </button>
      <button
        @click.prevent="closeModal"
        class="btn bg-transparent border col-span-2 btn-info"
      >
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Marketplace-Item-Detail",
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: "",
      hasErrorMessage: false,
      content: null,
    };
  },

  async mounted() {
    this.loading = true;
    let item = await this.$store.dispatch(
      `standardpricing/getStandardPricingMaster`,
      this.id
    );
    this.values = this.parseMarketplaceItem(item);
    this.content = this.values.userInterface;
    this.loading = false;
  },
  methods: {
    async getFormData() {
      this.loading = false;
    },

    closeModal() {
      this.$emit("closeModal");
    },

    parseMarketplaceItem(item) {
      return {
        name: item["name"],
        apiTag: item["apiTag"],
        category: item["category"],
        type: item["type"],
        userInterface: item["userInterface"],
        description: item["description"],
        monthly: item["pricing"]["monthly"],
        yearly: item["pricing"]["yearly"],
        monthlyRrp: item["rrp"]["monthly"],
        yearlyRrp: item["rrp"]["yearly"],
      };
    },

    async onSubmit() {
      this.loading = true;
      const payload = {
        id: this.id,
        userInterface: this.content,
      };
      await this.$store
        .dispatch("standardpricing/updateStandardPricingMasterDetail", payload)
        .then((res) => {
          if (res.success) {
            this.loading = false;
            this.closeModal();
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: res.message,
            });
          }
        })
        .catch((err) => {
          this.loading = false;
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
        });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
