<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <FormulateForm
      name="editBusinessForm"
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
    >
      <div class="text-sm mb-1 font-semibold form-label pr-2 text-neutral-dark">Transport Service Licence</div>
      <div class="border rounded p-5 mb-5 mt-1 relative">
        <FormulateInput
          name="tsl"
          label="Licences"
          type="general-select"
          element-class="mb-2 mt-1"
          placeholder="Select a TSL type"
          :clearable="false"
          :options="tsls"
          :getOptionKey="(option) => option.tslNumber"
          :getOptionLabel="(option) => option.tslNumber"
          validation="required"
          multiple
        />
        <div v-if="showTlsExtraFields">
          <FormulateInput
            type="date"
            name="passengerEndorsementExpiry"
            label="Passenger Endorsement Expiry"
            validation="required"
          />
          <FormulateInput
            type="date"
            name="driverLicenceRenewalChecked"
            label="Driver Licence Renewal Checked"
            validation="required"
          />
          <FormulateInput
            type="date"
            name="passengerEndorsementRenewalChecked"
            label="Passenger Endorsement Renewal Checked"
            validation="required"
          />
        </div>
      </div>

      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>

    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import schema from "../driver/stepper-component/business-profile-create-schema";
import { mapGetters } from "vuex";

export default {
  name: "Edit-Business-Profile-Form",
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },

  data() {
    this.$emit("schema", schema);
    return {
      profile: {},
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
      showTlsExtraFields: false,
      tsls: []
    };
  },

  watch: {
    "values.tsl": async function () {
      const hasSpslOrLpsl = this.values.tsl.filter(tsl => tsl.tslType == 'SPSL' || tsl.tslType == 'LPSL');
      this.showTlsExtraFields = hasSpslOrLpsl.length > 0 ? true : false;
    },
  },

  async mounted() {
    this.loading = true;
    const me = this.$store.getters[`account/me`];
    this.tsls = await this.$store.dispatch(
        `tsl/getTSLByEntity`, me?.businessId
    );
    const profileDetails = await this.$store.dispatch(
      `business/getContractById`,
      this.detailsId
    );
    if (profileDetails) {
      const profile = this.parseProfile(profileDetails.profile);

      this.values = {
        profile: [
          {
            recordKeeperAddress: [
              {
                "record-keeper-street": profile.recordKeeperAddress,
              },
            ],
            baseLocation: [
              {
                "base-location-street": profile.baseLocation,
              },
            ],
            baseTimeZone: profile.baseTimeZone
          },
        ]
      };

      if (profileDetails?.tslMetadata) {
        this.values.tsl = profileDetails?.tslMetadata?.licenses;
        this.values.passengerEndorsementExpiry = profileDetails?.tslMetadata?.passengerEndorsementExpiry;
        this.values.driverLicenceRenewalChecked = profileDetails?.tslMetadata?.driverLicenceRenewalChecked;
        this.values.passengerEndorsementRenewalChecked = profileDetails?.tslMetadata?.passengerEndorsementRenewalChecked;
      }

      this.loading = false;
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let parsedProfile = this.parseForSubmit(this.values);
      delete parsedProfile.tsl;
      delete parsedProfile.passengerEndorsementExpiry;
      delete parsedProfile.driverLicenceRenewalChecked;
      delete parsedProfile.passengerEndorsementRenewalChecked;

      const updateProfile = await this.$store.dispatch(
        `business/updateContract`,
        { data: parsedProfile, isApproval:false }
      );
      if (updateProfile && updateProfile._id) {
        this.loading = false;
        this.$emit("closeModal");
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "success",
          message: "Business successfully updated.",
        });
      }

      this.loading = false;
      this.$emit("closeRefresh");
    },
    parseProfile(profile) {
      return {
        AFMNumber: profile.AFMNumber || "-",
        baseLocation: profile.baseLocation || "-",
        baseTimeZone: profile.baseTimeZone || "-",
        recordKeeperAddress: profile.recordKeeperAddress || "-"
      };
    },
    parseForSubmit(values) {
      let data = {
        id: this.detailsId,
        profile: {
          baseLocation:
            `${values.profile[0].baseLocation[0]["base-location-street"]}` ||
            "-",
          baseTimeZone: values.profile[0].baseTimeZone || "-",
          recordKeeperAddress:
            `${values.profile[0].recordKeeperAddress[0]["record-keeper-street"]}` ||
            "-",
        },
      };

      if (values.tsl && values.tsl.length > 0) {
        data["tslMetadata"] = {
          licenses: values.tsl.map(x => x._id)
        }

        if (this.showTlsExtraFields) {
          data["tslMetadata"]["passengerEndorsementExpiry"] = values.passengerEndorsementExpiry;
          data["tslMetadata"]["driverLicenceRenewalChecked"] = values.driverLicenceRenewalChecked;
          data["tslMetadata"]["passengerEndorsementRenewalChecked"] = values.passengerEndorsementRenewalChecked;
        }
      }

      return data;
    },
    programaticSubmit() {
      this.$formulate.submit("editBusinessForm");
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
