<template>
  <div>
    <FormulateForm v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <div class="p-5 mt-5 mb-10 border rounded-lg group">
          <div class="group-title pb-5 font-bold">
            Duration and Product Count Details
          </div>

          <table>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Contract Period:</div>
              </td>
              <td v-if="interChangingData.contractDuration" class="pb-1">
                <div class="ml-3">
                  {{ interChangingData.contractDuration.label }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Billing Period:</div>
              </td>
              <td v-if="interChangingData.billingPeriod" class="pb-1">
                <div class="ml-3">
                  {{ interChangingData.billingPeriod.label }}
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div
          v-if="
            loading && interChangingData.selectedModules.cplValues.length > 0
          "
          class="text-center mb-16"
        >
          <ui-spinner class="mx-auto"></ui-spinner>
          Generating Core platform licences pricing table...
        </div>
        <div
          v-if="!loading && cplValues.length > 0"
          class="p-5 mt-5 mb-10 border rounded-lg group"
        >
          <div class="group-title pb-5 font-bold">
            Core Platform Licences Pricing Details
          </div>
          .
          <table class="w-full text-center">
            <thead class="border-b border-line-regular">
              <th
                class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-left"
              >
                Modules
              </th>
              <th
                class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-left"
              >
                Payment Type
              </th>
              <th
                class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right"
              >
                {{
                  interChangingData?.billingPeriod?.label === "Monthly"
                    ? "Price/Month"
                    : "Price/Year"
                }}
              </th>
              <th
                class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right"
              >
                Seat Count
              </th>
              <th
                class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right"
              >
                {{
                  interChangingData?.billingPeriod?.label === "Monthly"
                    ? "Total/Month"
                    : "Total/Year"
                }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(modules, index) in cplValues" :key="index">
                <td
                  :key="index"
                  class="text-main px-4 text-sm border-line-regular text-left"
                >
                  {{ modules.name }}
                </td>
                <td
                  v-if="interChangingData.contractDuration.label !== 'Yearly'"
                  class="text-main px-4 text-base border-line-regular text-right"
                >
                  {{ defaultContractRRP }}
                </td>
                <td
                  v-if="interChangingData.contractDuration.label === 'Yearly'"
                  class="text-main px-4 text-base border-line-regular text-left"
                >
                  {{ pricingTypeModel[modules._id] }}
                </td>
                <td
                  class="text-main px-4 text-base border-line-regular text-right"
                >
                  ${{ modules.buyPrice }}
                </td>
                <td
                  class="text-main px-4 text-base border-line-regular text-right"
                >
                  {{ modules.masterId.numberOfSeats }}
                </td>
                <td
                  class="text-main px-4 text-base border-line-regular text-right"
                >
                  ${{ modules.masterId.numberOfSeats * modules.buyPrice }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- <div v-if="loading && interChangingData.selectedModules.specialtyModulesValues.length > 0" class="text-center mb-16">
            <ui-spinner class="mx-auto"></ui-spinner>
            Generating Specialty modules pricing table...
          </div>
          <div v-if="!loading && specialtyModulesValues.length > 0" class="p-5 mt-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold bg-gray-50">Specialty Modules Pricing Details</div>.

             <table class="table-auto leading-normal p-4 w-full">
              <thead>
                <th class="bg-gray-100 text-center p-3 font-semibold">
                  Specialty Modules
                </th>
                <th class="bg-gray-200 text-center p-3 font-semibold">
                  Payment Type
                </th>
                <th v-if="interChangingData.contractDuration" class="bg-gray-200 text-center p-3 font-semibold justify-center" width="30%">
                  {{interChangingData.contractDuration.label === 'Yearly' ? 'Manual Entry' : 'Reduce Sell Price'}}
                </th>
              </thead>

              <tr v-for="(modules, index) in specialtyModulesValues" :key="index">
                <td :key="index" class="px-3">{{modules.name}}</td>
                <td v-if="interChangingData.contractDuration.label !== 'Yearly'" class="p-2" width="50%">
                  <div class="flex items-center justify-center w-full">
                    <FormulateInput
                        type="radio"
                        :options="defaultContractRRPOptions"
                        v-model="defaultContractRRP"
                        class="custom-options w-full items-center justify-center pt-3"
                        :name="`smPricingType-${modules._id}`"
                        validation="^required"
                        :validation-messages="{required: 'Required.'}"
                        disabled
                    />
                  </div>
                </td>
                <td v-if="interChangingData.contractDuration.label === 'Yearly'" class="p-2" width="50%">
                  <div class="flex items-center justify-center w-full">
                   <FormulateInput
                        type="radio"
                        :options="pricingType"
                        class="custom-options w-full items-center justify-center pt-3"
                        :name="`smPricingType-${modules._id}`"
                         validation="^required"
                        :validation-messages="{required: 'Required.'}"
                        v-model="pricingTypeModel[modules._id]"
                    />
                  </div>
                </td>
                 <td class="p-2">
                  <div class="flex items-center justify-center">
                    <FormulateInput
                        :name="`offerPrice-${modules._id}`"
                        placeholder="$0"
                        class="w-1/2 pt-3"
                        validation="required|number"
                        :validation-messages="{required: 'Required.'}"
                        :value="modules.rrpPrice"
                      />
                  </div>
                </td>
              </tr>
             </table>
          </div> -->
        <div class="border-t border-neutral-light mt-8">
          <div class="flex items-center justify-end w-full mt-4 space-x-4">
            <FormulateInput
              type="button"
              label="Back"
              input-class="btn btn-cancel w-full"
              @click.prevent="onPrevious()"
              :disabled="errorMessage"
            />
            <FormulateInput
              type="submit"
              label="Next"
              :style="{ backgroundColor: theme.secondaryColor }"
              input-class="btn btn-primary w-full"
            />
          </div>
        </div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import { ROLES } from "@/_helper/constants";
import { roundValue } from "@/_helper";
// import { omitBy } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Pricing",
  props: {
    preData: {
      type: Object,
      required: false,
      default: null,
    },
    postData: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: null,
      PartnerPricingModel: [],
      cplValues: [],
      cplValuesLoading: false,
      pricingType: ["Base", "Yearly Discount"],
      defaultContractRRP: "Base",
      defaultContractRRPOptions: ["Base"],
      pricingTypeModel: [],
      activeRRPBusiness: [],
      interChangingData: [],
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const me = this.$store.getters[`account/me`];
      let rolesParent = null;

      if (this.preData?.steps) {
        this.values = this.preData.steps?.pricingDetails || null;
        this.interChangingData = this.preData;
      }
      if (this.postData?.steps) {
        this.values = this.postData.steps?.pricingDetails || null;
        this.interChangingData = this.postData;
      }

      if (me?.role?.name && me?.role?.name === ROLES.PARTNERS) {
        rolesParent = await this.$store.dispatch(
          "partner/getPartnerByUID",
          me?.uid
        );
      }

      if (me?.role?.name && me?.role?.name === ROLES.RESELLERS) {
        rolesParent = await this.$store.dispatch(
          "reseller/getResellerByUID",
          me?.uid
        );
      }

      if (rolesParent) {
        this.activeRRPBusiness = await this.$store.dispatch(
          "standardpricing/getActiveRRPforBusiness",
          { id: rolesParent._id }
        );

        if (this.activeRRPBusiness) {
          console.log(this.interChangingData);
          const extractCPLValues =
            this.interChangingData.selectedModules.cplValues.map(
              (selected) => selected.apiTag
            );
          console.log(extractCPLValues);

          if (extractCPLValues) {
            console.log(this.activeRRPBusiness)
            this.cplValues = this.activeRRPBusiness
              .filter((rrp) =>
                extractCPLValues.includes(rrp.standardPricingMaster.apiTag)
              )
              .map((cpl) => ({
                ...cpl,
                masterId: this.interChangingData.selectedModules.cplValues.find(
                  (master) => master.apiTag == cpl.standardPricingMaster.apiTag
                ),
                billingPeriod:
                  this.interChangingData.billingPeriod.label.toLowerCase(),
              }));

            if (this.interChangingData?.steps?.pricingDetails) {
              this.cplValues.forEach(
                (cpl) =>
                  (this.pricingTypeModel = {
                    ...this.pricingTypeModel,
                    ...{
                      [cpl._id]:
                        this.interChangingData?.steps?.pricingDetails[
                          `cplPricingType-${cpl._id}`
                        ],
                    },
                  })
              );
            } else {
              this.cplValues.forEach(
                (cpl) =>
                  (this.pricingTypeModel = {
                    ...this.pricingTypeModel,
                    ...{ [cpl._id]: "Base" },
                  })
              );
            }
          }

          this.loading = false;

          this.cplValues = this.cplValues.map((cpl) => {
            let rrpComputedPrice = 0;
            if (this.interChangingData.contractDuration.label === "Yearly") {
              if (this.interChangingData.billingPeriod.label === "Monthly") {
                rrpComputedPrice = Number(cpl.pricing.yearly) / 12;
              } else {
                rrpComputedPrice = Number(cpl.pricing.yearly);
              }
            } else if (
              this.interChangingData.contractDuration.label === "Monthly"
            ) {
              rrpComputedPrice = Number(cpl.pricing.monthly);
            }

            return {
              ...cpl,
              rrpPrice: roundValue(rrpComputedPrice),
              buyPrice: roundValue(rrpComputedPrice),
            };
          });

          if (this.interChangingData.contractDuration.label == "Yearly") {
            this.pricingType = ["Yearly Discount"];
            this.pricingTypeModel[this.cplValues[0]._id] = this.pricingType[0];
          }
        } else {
          this.loading = false;
          this.errorMessage = "RRP For parent business not found.";
        }
      }

    },
    async onSubmit() {
      const extractSelectedCPLValues = this.cplValues.map((cpl) => ({
        ...cpl,
        manualOffer: +cpl.buyPrice,
        pricingType: this.pricingTypeModel[cpl._id],
        numberOfSeats:
          Number(
            this.interChangingData.selectedModules.cplValues.find(
              (cplSeats) => {
                return cplSeats.apiTag === cpl.standardPricingMaster.apiTag
              }
            )?.numberOfSeats
          ) || 0,
        masterId:
          this.interChangingData.selectedModules.cplValues.find((cplSeats) => {
            return cplSeats.apiTag === cpl.standardPricingMaster.apiTag
          })?.id || null,
        customerBuyPrice: roundValue(
          this.fetchPaymentType(
            this.interChangingData.contractDuration.label.toLowerCase(),
            cpl.pricing,
            cpl.billingPeriod
          )
        ),
      }));
      const preData = {
        ...this.interChangingData,
        cplPricing: extractSelectedCPLValues,
        steps: {
          ...this.interChangingData.steps,
          ...{ pricingDetails: this.values },
        },
      };
      this.$emit("nextStep", preData);
    },

    fetchPaymentType(contractDuration, pricingObj, billingPeriod) {
      let offerValues = 0;

      if (contractDuration === "monthly") {
        let baseOfferValues = Number(pricingObj.monthly);

        if (billingPeriod === "yearly")
          baseOfferValues = Number(pricingObj.monthly) * 12;

        offerValues = baseOfferValues;
      }
      if (contractDuration === "yearly") {
        let baseOfferValues = Number(pricingObj.yearly);

        if (billingPeriod === "monthly")
          baseOfferValues = Number(pricingObj.yearly) / 12;

        offerValues = baseOfferValues;
      }

      return offerValues;
    },
    onPrevious() {
      const pricingData = this.values;
      const postData = {
        ...this.interChangingData,
        steps: {
          ...this.interChangingData.steps,
          ...{ pricingDetails: pricingData },
        },
      };
      this.$emit("backStep", postData);
    },
  },
  computed: {
    // pricingTypeModelComputed: function () {
    //   return JSON.stringify(this.pricingTypeModel);
    // },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  watch: {
    // pricingTypeModelComputed: function (newValue_str, oldValue_str) {
    //   const changedValue = omitBy(JSON.parse(newValue_str), function (v, k) {
    //     return JSON.parse(oldValue_str)[k] === v;
    //   });
    //   const changeKey = Object.keys(changedValue).toString();
    //   const changeValue = Object.values(changedValue).toString();
    //   const moduleData = this.activeRRPBusiness.find(
    //     (modules) => modules._id === changeKey
    //   );
    //   if (this.values) {
    //     this.values[`offerPrice-${changeKey}`] = roundValue(
    //       this.fetchPaymentType(
    //         changeValue,
    //         moduleData?.pricing,
    //         this.interChangingData.billingPeriod.label.toLowerCase()
    //       )
    //     );
    //   }
    // },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.box-shadow-none div:nth-child(2) {
  width: 50%;
  input {
    box-shadow: none !important;
  }
}
</style>
