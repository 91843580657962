<template>
    <div class="mb-16">
      <FormulateForm v-model="values" :schema="schema" @submit="onSubmit" #default="{ hasErrors }">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{backgroundColor: theme.secondaryColor}"
          />
      </FormulateForm>
    </div>
</template>

<script>
import schema from './edit-depot-name-schema'
import { mapGetters } from 'vuex'

export default {
  name: 'Edit-Depot-Form',
  props: {
    data: {},
    id:{},
  },
  data() {
    return {
      error:false,
      errorMessage: '',
      loading: false,
      values: {},
      schema: schema,
      currentDepot:{}
    };
  },
  mounted(){
    this.values = {depotName: this.data}
    this.currentDepot = this.id
  },
  methods: {
    onSubmit: async function(data) {
      const { depotName } = data
      if( depotName !== this.data){
        const depotPayload = {
          "name": depotName
        }
        try{
            await this.$store.dispatch(`business/patchDepot`, {payload: depotPayload, id: this.currentDepot});
            location.reload()
        } catch (e){
          console.log(e)
        }
      }
    },
  },
  computed: {
      ...mapGetters('theme', {
      theme: 'getColorScheme'
      })
  },
}
</script>