<template>
  <div class="w-full overflow-y-auto border border-gray-200 rounded">
    <table class="min-w-full">
      <thead class="bg-gray-50">
        <tr class="text-gray-700 text-xs tracking-wider uppercase">
          <th scope="col" class="py-3 px-5 text-center">Activity</th>
          <th scope="col" class="py-3 px-5 text-center">
            Time of<br />Activity
          </th>
          <th scope="col" class="py-3 px-5 text-left">Location</th>
          <th scope="col" class="py-3 px-5 text-center">Odometer</th>
          <th scope="col" class="py-3 px-5 text-center">Vehicle</th>
          <th
            scope="col"
            class="py-3 px-5 text-center"
            style="min-width: 130px"
          >
            Work and<br />Rest Option
          </th>
          <th scope="col" class="py-3 px-5 text-left">Comments</th>
          <th scope="col" class="py-3 px-5 text-center">Origin</th>
          <th scope="col" class="py-3 px-5 text-left">Entry Timestamp</th>
          <th scope="col" class="py-3 px-5 text-left">Status</th>
          <th scope="col" class="py-3 px-5 text-left">Tampered</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="events.length == 0" class="bg-white border-b text-xs text-gray-500 font-medium">
          <td colspan="9" class="py-3 px-5 text-sm text-center">
            No events this day
          </td>
        </tr>
        <tr
          v-for="(event, index) in events"
          :key="index"
          class="bg-white border-b text-xs text-gray-500 font-medium"
        >
          <td class="py-3 px-5 text-center text-black-primary capitalize">
            {{ event.type }}
          </td>
          <td class="py-3 px-5 text-center">
            {{ event.timeOfActivity }}
          </td>
          <td class="py-3 px-5">
            {{ event.locationAddress }}
          </td>
          <td class="py-3 px-5 text-center">
            {{ event.odometer }}
          </td>
          <td class="py-3 px-5 text-center">
            {{ event.vehicle }}
          </td>
          <td class="py-3 px-5 text-center uppercase">
            {{ event.fatiguePlan }}
          </td>
          <td class="py-3 px-5">
            {{ event.comment }}
          </td>
          <td class="py-3 px-5 text-center capitalize">
            {{ event.origin }}
          </td>
          <td class="py-3 px-5">
            {{ event.originalStartTime }}
          </td>
          <td class="py-3 px-5 uppercase">
            {{ event.status }}
          </td>
          <td class="py-3 px-5 text-center">
            {{ event.tampered }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import moment from 'moment';
export default {
    name: "Event",
    props: {
      data: Array
    },
    data() {
      return {
        events: []
      }
    },
    mounted() {
      this.events = this.data;
    },
    watch: {
      data: {
        handler: function(newValue) { 
          this.events = newValue;
        },
        deep: true
      }
    },
    filters: {
      formatDate(value, format){
        return moment(value).format(format)
      }
    },
};
</script>
