<template>
  <div class="flex flex-col w-full h-screen">
    <div>
      <div class="flex flex-col">
        <div>
          <button
            class="btn btn-primary p-0"
            @click.prevent="toggleCurateSignature()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Curate Accreditation
          </button>
        </div>

        <Table
          :isLoading="loading"
          :tableHeader="tableHeader"
          :paginationSettings="paginationSettings"
          :data="tableData"
          @onPageChange="onPageChange($event)"
        >
          <template #default="{ data }">
            <td class="px-5 py-2 text-center relative">
              <Dropdown ref="dropdown">
                <ul class="py-2 text-sm">
                  <li>
                    <StyledIconedLink
                      class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                      @click.prevent="toggleViewCert(data)"
                      :iconHoverColor="theme.secondaryColor"
                    >
                      <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                    </StyledIconedLink>
                  </li>
                   <li>
                    <StyledIconedLink
                      class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                      @click.prevent="toggleRenewCert(data)"
                      :iconHoverColor="theme.secondaryColor"
                    >
                      <font-awesome-icon :icon="['fas', 'retweet']" class="my-auto mr-2" /> Renew
                    </StyledIconedLink>
                  </li>
                  <li>
                    <StyledIconedLink
                      class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                      @click.prevent="toggleEditCert(data)"
                      :iconHoverColor="theme.secondaryColor"
                    >
                    <font-awesome-icon
                      icon="edit"
                      class="my-auto mr-2"
                    />
                    Edit
                    </StyledIconedLink>
                  </li>
                </ul>
              </Dropdown>
            </td>
          </template>

          <template slot="tableFilters">
            <div class="flex flex-row mb-1 sm:mb-0">
              <div class="relative">
                <select
                  v-model="filter.limit"
                  @change="filterChange"
                  class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                >
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </select>
                <div
                  class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                  <font-awesome-icon icon="caret-down" class="text-gray-400" />
                </div>
              </div>
              <div class="relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </div>
          </template>
        </Table>
      </div>
    </div>

    <Modal
      ref="modal"
      @closeModal="closeModal"
      :title="modalTitle"
      :size="modalSize"
      :height="modalHeight"
    >
      <div v-if="!isFormLoading">
        <div
          class="flex flex-col pb-12"
          v-if="modalContent === 'curateAccreditation'"
        >
          <div>
            <div class="center-text py-2">Certification Type</div>
            <v-select
              :options="formType"
              :searchable="false"
              :clearable="false"
              v-model="selectedFormType"
              v-on:input="onFormTypeChange"
              class="mb-5 bg-white"
            >
              <template
                slot="option"
                class="hover:bg-red-200"
                slot-scope="option"
              >
                {{ option.label }}
              </template>
            </v-select>
          </div>

          <div v-if="selectedFormType === 'BFM'">
            <BFMForm
              @submit="onSubmit"
              @closeModal="closeModal"
              :editData="null"
            />
          </div>

          <div v-else>
            <AFMForm
              @submit="onSubmit"
              @closeModal="closeModal"
              :editData="null"
            />
          </div>
        </div>
        <div v-if="modalContent === 'viewCerts'">
          <CertContents :content="selectedCert" />
        </div>
        <div v-if="modalContent == 'editBFM'">
          <BFMForm
            @submit="onSubmit"
            @closeModal="closeModal"
            :editData="editData"
            :isEdit="true"
          />
        </div>
        <div v-if="modalContent == 'editAFM'">
          <AFMForm
            @submit="onSubmit"
            @closeModal="closeModal"
            :editData="editData"
            :isEdit="true"
          />
        </div>
             <div v-if="modalContent == 'renewCert'">
        <FormulateForm @submit="submitDocuments" #default="{ hasErrors }">
          <FormulateInput type="group" name="renew">
            <FormulateInput
              type="date"
              name="expirationDate"
              label="Expiration Date"
              validation="required"
            />

            <FormulateInput
              type="file"
              name="renewCert"
              label="Upload Certificate"
              accept="application/pdf"
              validation="mime:application/pdf"
              v-model="renewCert"
              label-class="formulate-input-label formulate-input-label--before"
              outer-class="formulate-input"
              input-class=""
              wrapper-class="formulate-input-wrapper"
              upload-area-class="formulate-input-upload-area"
              upload-area-mask-class="formulate-input-upload-area-mask"
              @file-removed="clearInputFile('renewal')"
            />

            <FormulateInput
              type="submit"
              label="Save Changes"
              input-class="btn btn-primary w-full"
              :style="{ backgroundColor: theme.secondaryColor }"
              :disabled="hasErrors"
            />
          </FormulateInput>
          <div class="text-red-500 text-center mb-3" v-if="errorMessage">
            {{ errorMessage }}
          </div>
        </FormulateForm>
      </div>
      </div>
      <div v-else>
        <div class="text-center">
          <font-awesome-icon
            icon="circle-notch"
            spin
            size="2x"
            class="text-gray-400"
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal, Dropdown } from "@/components/commons";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import { BFMForm, AFMForm } from "@/components/forms/business";
import { Table } from "@/components/commons";
import { formatPaginationSettings, formatDate } from "@/_helper/";
import _ from "lodash";
import CertContents from "@/components/view/business/accreditation/cert-contents";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "configure-accreditation",
  components: {
    CertContents,
    Modal,
    Dropdown,
    BFMForm,
    AFMForm,
    Table,
    StyledIconedLink,
  },

  async mounted() {
    await this.fetchAccreditation();
  },

  data() {
    return {
      loading: false,
      modalSize: "2xl",
      modalContent: "",
      modalTitle: "Type your name as your signature",
      imageBuffer: {},
      imageType: "image/png",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      certificates: [],
      tableHeader: ["Certifications", "Type", "Created On"],

      renewCert: null,
      hasErrors: false,

      adminEmail: "",
      adminUserName: "",
      adminParentrole: "",
      adminPhoneNumber: "",

      certSelected: false,
      certNumber: "",
      createdAt: "",
      isActive: "",
      isMedicalCertRequired: "",
      isSignatureApprovalRequired: "",
      isTrainingCertRequired: "",
      type: "",
      depotId: "",
      certExpiryDate: "",
      adminTrainingCertFile: "",

      errorMessage: "",

      formType: [
        { type: "AFM", label: "AFM" },
        { type: "BFM", label: "BFM" },
      ],

      selectedFormType: "AFM",

      filter: {
        limit: 10,
        status: "all",
        search: "",
      },

      filterStatuses: {
        all: "All",
        linked: "Linked",
        pending: "Pending",
      },

      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },

      selectedCert: false,
      isFormLoading: false,
      editData: {},
    };
  },

  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      },
    );
  },

  methods: {
    async fetchAccreditation(paged = 1) {
      try {
        this.loading = true
        const me = this.$store.getters[`account/me`];
        const isAFMEnabled = me?.business?.isAFMEnabled;

        if (!isAFMEnabled) {
          this.formType = this.formType.filter((x) => {
            return x.type !== "AFM";
          });
          this.selectedFormType = "BFM";
        }

        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {},
        };

        const accreditations = await this.$store.dispatch(
          `accreditation/getAllAccreditation`,
          { userData: me, pagination: query },
        );

        if (accreditations.data?.data?.results) {
          this.certificates = JSON.parse(
            JSON.stringify(accreditations.data.data.results),
          );
        } else {
          this.certificates = [];
        }

        if (accreditations?.data?.data.resultsMeta) {
          this.paginationSettings = formatPaginationSettings(
            accreditations.data.data.resultsMeta,
          );
        }

        if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
        this.loading = false;
      } catch (e) {
        console.log("error:", e);
      }
    },

    async submitDocuments(renewalData) {
      try {
        this.closeModal();
        const data = this.selectedCert;
        const { businessId, _id } = data.document;

        this.loading = true;

        let renewalPayload = null;

        const renewalFile = _.get(
          renewalData,
          "renew[0].renewCert.fileList[0]",
          null
        );

        const expiryDate = _.get(renewalData, "renew[0].expirationDate", null);

        if (renewalData && expiryDate) {
          renewalPayload = {
            renewalFile,
            businessId,
            expiryDate,
          };
          await this.$store.dispatch(`accreditation/renewCertificate`, {
            renewalPayload,
            id: _id,
          });

          this.loading = false;
          this.toast("success", "Documents uploaded successfully");
        } else {
          this.errorMessage = "No documents selected";
          return;
        }
      } catch (e) {
        this.toast("error", `Renewal Failed: ${e}`);
      }
    },

    clearInputFile(type) {
      if (type == "med") {
        this.medCert = null;
      } else if (type == "renewal") {
        this.renewCert = null;
      } else {
        this.trainingCert = null;
      }
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    async debouncedSearchString() {
      await this.fetchAccreditation();
    },

    async filterChange() {
      await this.fetchAccreditation();
    },

    async onPageChange(event) {
      await this.fetchAccreditation(event.page);
    },

    onFormTypeChange(data) {
      this.selectedFormType = data.type;
    },

    parseAccreditaionData(data) {
      return data.map((accreditationData) => {
        return [
          {
            id: accreditationData._id,
            name: accreditationData.certNumber,
            itemType: "string",
            document: accreditationData,
          },
          {
            id: accreditationData._id,
            name: accreditationData.type,
            itemType: "string",
          },
          {
            id: accreditationData?._id,
            name: formatDate(accreditationData.createdAt),
          },
        ];
      });
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    async onSubmit(formData) {
      this.loading = true;
      this.isFormLoading = true;
      this.selectedFormType = formData.type;

      if (!formData.isEdit) {
        if (this.selectedFormType === "BFM") {
          const selectedTemplateId = _.get(
            formData,
            "data.selectedTemplate.id",
            null,
          );

          if (!_.isEmpty(selectedTemplateId)) {
            delete formData.data.selectedTemplate;

            formData.data.documentTemplate = selectedTemplateId;
          }

          const { data, file, adminSig } = formData;
          data.type = this.selectedFormType;
          if (!_.has(data, "isSignatureApprovalRequired")) {
            data.isSignatureApprovalRequired = false;
          }

          if (!_.has(data, "isMedicalCertRequired")) {
            data.isMedicalCertRequired = false;
          }

          if (!_.has(data, "isTrainingCertRequired")) {
            data.isTrainingCertRequired = false;
          }

          data.certNumber = data.certNumber.trim();

          try {
            const createdCert = await this.$store.dispatch(
              `accreditation/submitAccreditation`,
              data,
            );
            if (createdCert?.data?.success) {
              await this.$store.dispatch(
                `accreditation/uploadAccreditationFile`,
                {
                  fileType: "certificate",
                  certId: createdCert.data.data._id.trim(),
                  increaseVersion: false,
                  file: file,
                },
              );
              await this.$store
                .dispatch(`accreditation/uploadAccreditationFile`, {
                  fileType: "adminSignature",
                  certId: createdCert.data.data._id.trim(),
                  increaseVersion: false,
                  file: adminSig,
                  mimetype: this.imageType,
                })
                .catch((err) => {
                  throw err;
                });
              this.loading = false;
              this.toast("success", "Certificate created successfully");
              this.fetchAccreditation();
              this.closeModal();
              this.rules = [];
              this.additionalRules = [];
            } else {
              throw new Error("Something went wrong");
            }
          } catch (e) {
            this.loading = false
            this.toast("error", "Something Went Wrong");
            console.log("error:", e);
          }
        } else {
          const {
            ruleSet,
            rules,
            type,
            certNumber,
            certExpiryDate,
            isSignatureApprovalRequired,
            isMedicalCertRequired,
            isTrainingCertRequired,
            file,
            adminSig,
            documentTemplate,
          } = formData;

          const payload = {
            ruleSet,
            rules,
            type,
            certNumber,
            certExpiryDate,
            isSignatureApprovalRequired,
            isMedicalCertRequired,
            isTrainingCertRequired,
            documentTemplate: documentTemplate?.id ?? null,
          };

          try {
            const createdCert = await this.$store.dispatch(
              `accreditation/submitAccreditation`,
              payload,
            );

            if (createdCert?.data?.success) {
              await this.$store
                .dispatch(`accreditation/uploadAccreditationFile`, {
                  fileType: "certificate",
                  certId: createdCert.data.data._id.trim(),
                  increaseVersion: false,
                  file: file,
                })
                .catch((err) => {
                  throw new Error(err);
                });

              await this.$store
                .dispatch(`accreditation/uploadAccreditationFile`, {
                  fileType: "adminSignature",
                  certId: createdCert.data.data._id.trim(),
                  increaseVersion: false,
                  file: adminSig,
                  mimetype: this.imageType,
                })
                .catch((err) => {
                  throw err;
                });

              this.rules = [];
              this.additionalRules = [];
              this.toast("success", "Certificate created successfully");
              this.fetchAccreditation();
              this.closeModal();
            } else {
              throw new Error("Something went wrong");
            }
          } catch (e) {
            this.loading = false;
            this.toast(
              "error",
              "Something went wrong, please check if Rule set name is not yet taken",
            );
          }
        }
      } else {
        if (this.selectedFormType == "AFM") {
          const formattedSubmitData = {
            ..._.omit(formData, [
              "documentTemplate",
              "file",
              "isEdit",
              "rules",
              "adminSig",
              "ruleSet",
            ]),
          };

          const formattedEditData = {
            ..._.omit(this.editData, [
              "__v",
              "_id",
              "rules",
              "version",
              "updatedAt",
              "createdAt",
              "adminSignature",
              "adminTrainingCertFile",
              "adminUid",
              "businessId",
              "ruleSetId",
              "pendingDrivers",
              "drivers",
              "isActive",
              "depotId",
              "ruleSet",
              "documentTemplate",
              "certFile",
              "adminSignature",
            ]),
          };

          const submittedRules = JSON.parse(JSON.stringify(formData.rules));
          const edittedRules = this.editData.rules.map((data) =>
            _.omit(data, ["breaches"]),
          );

          const submittedRuleSet = JSON.parse(JSON.stringify(formData.ruleSet));
          const edittedRuleSet = JSON.parse(
            JSON.stringify(this.editData.ruleSet),
          );

          const ruleSetDiff = this.getObjectDiff(
            JSON.parse(JSON.stringify(submittedRuleSet)),
            JSON.parse(JSON.stringify(edittedRuleSet)),
          );

          let willRulesUpdate = false;

          if (submittedRules.length !== edittedRules.length) {
            willRulesUpdate = true;
          } else {
            for (
              let i = 0;
              !willRulesUpdate && i < submittedRules.length;
              i++
            ) {
              const rules = this.getObjectDiff(
                JSON.parse(JSON.stringify(edittedRules[i])),
                JSON.parse(JSON.stringify(submittedRules[i])),
              );

              if (rules.length > 0) {
                willRulesUpdate = true;
                break;
              }
            }
          }

          let adminSig;
          let certFile;

          if (ruleSetDiff.length > 0) {
            formattedSubmitData["ruleSet"] = submittedRuleSet;
          }

          if (
            formData?.documentTemplate?.id &&
            formData?.documentTemplate?.id !== this.editData?.documentTemplate
          ) {
            formattedSubmitData["documentTemplate"] =
              formData?.documentTemplate?.id;
          }

          if (formData?.adminSig) {
            adminSig = formData.adminSig;
          }

          if (formData?.file) {
            certFile = formData?.file;
          }

          if (willRulesUpdate) {
            formattedSubmitData["rules"] = submittedRules;
          }

          const formDiff = this.getObjectDiff(
            formattedEditData,
            formattedSubmitData,
          );

          if (formDiff.length == 0) {
            this.toast("error", "No new changes");
            this.closeModal();
            this.loading = false;
            return;
          }

          const finalObjectPayload = {};

          for (const field of formDiff) {
            // eslint-disable-next-line no-prototype-builtins
            if (formattedSubmitData.hasOwnProperty(field)) {
              finalObjectPayload[field] = formattedSubmitData[field];
            }
          }

          try {
            const editCert = await this.$store.dispatch(
              `accreditation/editCert`,
              {
                id: this.editData._id,
                payload: finalObjectPayload,
              },
            );

            if (editCert?.data?.success) {
              if (adminSig) {
                await this.$store
                  .dispatch(`accreditation/uploadAccreditationFile`, {
                    fileType: "adminSignature",
                    certId: this.editData._id,
                    increaseVersion: false,
                    file: adminSig,
                  })
                  .catch((err) => {
                    throw new Error(err);
                  });
              }

              if (certFile) {
                await this.$store
                  .dispatch(`accreditation/uploadAccreditationFile`, {
                    fileType: "certificate",
                    certId: this.editData._id,
                    increaseVersion: false,
                    file: certFile,
                  })
                  .catch((err) => {
                    throw new Error(err);
                  });
              }
            }
              this.toast("success", "Certificate updated successfully");
              this.fetchAccreditation();
              this.closeModal();
          } catch (e) {
            this.toast("error", `Something went wrong, ${e}`);
            this.closeModal();
          }
        } else {
          let adminSig;
          let certFile;

          const formattedEditData = _.omit(this.editData, [
            "__v",
            "_id",
            "rules",
            "version",
            "updatedAt",
            "createdAt",
            "adminSignature",
            "adminTrainingCertFile",
            "adminUid",
            "businessId",
            "ruleSetId",
            "pendingDrivers",
            "drivers",
            "isActive",
            "depotId",
            "ruleSet",
            "documentTemplate",
            "certFile",
            "adminSignature",
            "type",
          ]);

          const formattedSubmitData = _.omit(formData.data, [
            "selectedTemplate",
          ]);

          if (
            formData.data?.selectedTemplate?.id &&
            formData.data?.selectedTemplate?.id !== this.editData?.documentTemplate
          ) {
            formattedSubmitData["documentTemplate"] =
              formData.data?.selectedTemplate?.id;
          }

          if (formData?.adminSig) {
            adminSig = formData.adminSig;
          }

          if (formData?.file) {
            certFile = formData?.file;
          }

          const bfmDiff = this.getObjectDiff(
            JSON.parse(JSON.stringify(formattedSubmitData)),
            JSON.parse(JSON.stringify(formattedEditData)),
          );

          if (bfmDiff.length == 0) {
            this.toast("error", "No new changes");
            this.closeModal();
            this.loading = false;
            return;
          }

          const finalObjectPayload = {};

          for (const field of bfmDiff) {
            // eslint-disable-next-line no-prototype-builtins
            if (formattedSubmitData.hasOwnProperty(field)) {
              finalObjectPayload[field] = formattedSubmitData[field];
            }
          }


          try {
            const editCert = await this.$store.dispatch(
              `accreditation/editCert`,
              {
                id: this.editData._id,
                payload: finalObjectPayload,
              },
            );

            if (editCert?.data?.success) {
              if (adminSig) {
                await this.$store
                  .dispatch(`accreditation/uploadAccreditationFile`, {
                    fileType: "adminSignature",
                    certId: this.editData._id,
                    increaseVersion: false,
                    file: adminSig,
                  })
                  .catch((err) => {
                    throw new Error(err);
                  });
              }

              if (certFile) {
                await this.$store
                  .dispatch(`accreditation/uploadAccreditationFile`, {
                    fileType: "certificate",
                    certId: this.editData._id,
                    increaseVersion: false,
                    file: certFile,
                  })
                  .catch((err) => {
                    throw new Error(err);
                  });
              }
            }
            this.toast("success", "Certificate updated successfully");
            this.fetchAccreditation();
            this.closeModal();
          } catch (e) {
            this.toast("error", `Something went wrong, ${e}`);
            this.closeModal();
          }
        }
      }
    },

    getObjectDiff(obj1, obj2) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!obj2.hasOwnProperty(key)) {
          result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
        }
        return result;
      }, Object.keys(obj2));

      return diff;
    },

    closeModal() {
      this.$store.state.accreditation.AFMrules = [];
      this.$store.state.accreditation.additionalRules = [];
      this.$refs.modal.closeModal();
    },

    toggleCurateSignature() {
      this.$refs.modal.openModal();
      this.modalContent = "curateAccreditation";
      this.modalTitle = "Curate Accreditation";
    },

    toggleViewCert(data) {
      this.selectedCert = data;
      this.$refs.modal.openModal();
      this.modalContent = "viewCerts";
      this.modalTitle = "Certificate Details";
    },

    toggleRenewCert(data) {
      this.selectedCert = data;
      this.$refs.modal.openModal();
      this.modalContent = "renewCert";
      this.modalTitle = "Renew Certification";
    },

    toggleEditCert(data) {
      this.editData = data?.document;

      if (data?.document?.type == "BFM") {
        this.selectedFormType == "BFM";
        this.selectedCert = data;
        this.$refs.modal.openModal();
        this.modalContent = "editBFM";
        this.modalTitle = "Edit Certification";
      } else {
        this.selectedFormType == "AFM";
        this.selectedCert = data;
        this.$refs.modal.openModal();
        this.modalContent = "editAFM";
        this.modalTitle = "Edit Certification";
      }
    },
  },

  computed: {
    tableData() {
      if (this.certificates) {
        return this.parseAccreditaionData(this.certificates);
      } else {
        return [];
      }
    },

    rules: {
      get() {
        return this.$store.state.accreditation.AFMrules;
      },
      set(newVal) {
        this.$store.state.accreditation.AFMrules = newVal;
      },
    },

    additionalRules: {
      get() {
        return this.$store.state.accreditation.additionalRules;
      },
      set(newVal) {
        this.$store.state.accreditation.AFMrules = newVal;
      },
    },

    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style scoped>
.img {
  height: 10%;
  widows: 10%;
}
</style>