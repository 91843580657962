import { RolesAndRespo } from "@/api";
import _ from "lodash";

export default {
  async createRole({ commit }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object";
    try {
      const response = await RolesAndRespo.createRole(data);
      commit("setNewCreatedRole", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async getRoles({ state }, id) {
    try {
      const response = await RolesAndRespo.getRoles(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },
  async patchRole({ commit, state }, data) {
    if (!data._id) throw "Invalid role id";
    try {
      const id = data._id;
      const targetFields = {
        name: data.name,
        description: data.description,
      };
      data = JSON.parse(JSON.stringify(targetFields));

      const response = await RolesAndRespo.patchRole(id, data);
      if (!_.isEmpty(state.newCreatedRole))
        commit("setNewCreatedRole", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async deleteRole({ state }, id) {
    try {
      const response = await RolesAndRespo.deleteRole(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async linkRespo({ commit, state }, data) {
    if (!data.id) throw "invalid role id";
    try {
      const { id, responsibilityId } = data;
      let body = {
        responsibilityId: responsibilityId,
      };
      body = JSON.parse(JSON.stringify(body));

      const response = await RolesAndRespo.linkRespo(id, body);
      if (!_.isEmpty(state.newCreatedRole))
        commit("setNewCreatedRole", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async unlinkRespo({ commit, state }, data) {
    if (!data.id) throw "invalid role id";
    try {
      const { id, responsibilityId } = data;
      let body = {
        responsibilityId: responsibilityId,
      };
      body = JSON.parse(JSON.stringify(body));

      const response = await RolesAndRespo.unlinkRespo(id, body);
      if (!_.isEmpty(state.newCreatedRole))
        commit("setNewCreatedRole", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async linkParentRole({ commit, state }, data) {
    if (!data.id) throw "invalid role id";
    try {
      const { id, parentRoleId } = data;
      let body = {
        parentRoleId,
      };
      body = JSON.parse(JSON.stringify(body));

      const response = await RolesAndRespo.linkParentRole(id, body);
      if (!_.isEmpty(state.newCreatedRole))
        commit("setNewCreatedRole", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async unlinkParentRole({ commit, state }, data) {
    if (!data.id) throw "invalid role id";
    try {
      const { id, parentRoleId } = data;
      let body = {
        parentRoleId,
      };
      body = JSON.parse(JSON.stringify(body));

      const response = await RolesAndRespo.unlinkParentRole(id, body);
      if (!_.isEmpty(state.newCreatedRole))
        commit("setNewCreatedRole", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async createRespo({ commit }, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object";
    try {
      const response = await RolesAndRespo.createRespo(data);
      commit("setNewCreatedRespo", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getRespo({ state }, id) {
    try {
      const response = await RolesAndRespo.getRespo(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async getRespos({ state }, payload) {
    try {
      const { page, pageSize, uid, businessId } = payload;
      const response = await RolesAndRespo.getRespos({
        page,
        pageSize,
        uid,
        businessId,
      });
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async patchRespo({ commit, state }, data) {
    if (!data._id) throw "Invalid responsibility id";
    try {
      const id = data._id;
      const targetFields = {
        name: data.name,
        description: data.description,
      };
      data = JSON.parse(JSON.stringify(targetFields));

      const response = await RolesAndRespo.patchRespo(id, data);
      if (!_.isEmpty(state.newCreatedRespo))
        commit("setNewCreatedRespo", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async deleteRespo({ state }, id) {
    try {
      const response = await RolesAndRespo.deleteRespo(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async createUser(state, data) {
    if (!data || _.isEmpty(data)) throw "Invalid data object";
    try {
      const response = await RolesAndRespo.createUser(data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async resendInvite(state, id) {
    try {
      const response = await RolesAndRespo.resendInvite(id);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
  async deleteBusinessUser({ state }, id) {
    try {
      const response = await RolesAndRespo.deleteBusinessUser(id);
      return response.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async createWebProfilePassword(state, data) {
    if (!data.id) throw "Invalid Web Profile id";
    if (!data.password) throw "Invalid data password";
    try {
      const response = await RolesAndRespo.patchWebProfileCreatePassword(
        data.id,
        data.password
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async acceptWebProfile({ state }, id) {
    if (!id) throw `Invalid id ${Number(state.me)}`;

    try {
      const response = await RolesAndRespo.acceptWebProfile(id);
      return response.data.data;
    } catch (error) {
      throw error.response;
    }
  },

  async getUsers({ state }) {
    try {
      const response = await RolesAndRespo.getUsers();
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async getUsersV2({ state }) {
    try {
      const response = await RolesAndRespo.getUsersV2();
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async getUser({ state }, id) {
    try {
      const response = await RolesAndRespo.getUser(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async getWebProfilesByUID({ state }, uid) {
    try {
      const response = await RolesAndRespo.getWebProfilesByUID(uid);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me
    }
  },

  async getWebProfilesByEmail({ state }, email) {
    try {
      const response = await RolesAndRespo.getWebProfilesByEmail(email);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me
    }
  },

  async getWebProfileByUID({ state }, uid) {
    try {
      const response = await RolesAndRespo.getWebProfileByUID(uid);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me
    }
  },

  async updateEmailPreferences(state, data) {
    if (!data.id) throw "Invalid user id";
    try {
      const response = await RolesAndRespo.patchEmailPreferences(data.id, data);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async patchUser(state, data) {
    if (!data._id) throw "Invalid user id";
    try {
      const id = data._id;
      let targetFields = {
        email: data.email,
        userName: data.userName,
        phoneNumber: data.phoneNumber,
        depotSites: data.depotSites,
        site: data?.site
      };
      data = JSON.parse(JSON.stringify(targetFields));
      const response = await RolesAndRespo.patchUser(id, data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async linkUser({ commit, state }, data) {
    if (!data.id) throw "invalid role id";
    try {
      const { id, uid } = data;
      let body = {
        uid,
      };
      body = JSON.parse(JSON.stringify(body));

      const response = await RolesAndRespo.linkUser(id, body);
      if (!_.isEmpty(state.newCreatedRole))
        commit("setNewCreatedRole", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async unlinkUser({ commit, state }, data) {
    if (!data.id) throw "invalid role id";
    try {
      const { id, uid } = data;
      let body = {
        uid,
      };
      body = JSON.parse(JSON.stringify(body));

      const response = await RolesAndRespo.unlinkUser(id, body);
      if (!_.isEmpty(state.newCreatedRole))
        commit("setNewCreatedRole", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getSecurityConfig({ state }, id) {
    try {
      const response = await RolesAndRespo.getSecurityConfig(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async patchSecurityConfig({ state }, data) {
    if (!data.roleId) throw "Invalid role id";
    try {
      let { roleId, securityConfigs } = data;
      securityConfigs = JSON.parse(JSON.stringify(securityConfigs));
      const response = await RolesAndRespo.patchSecurityConfig(
        roleId,
        securityConfigs
      );
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async getAuditTrails({ state }, payload) {
    try {
      const response = await RolesAndRespo.getAuditTrails(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async getDepotsByOwnerUid({ state }, uid) {
    try {
      const response = await RolesAndRespo.getDepotsByOwnerUid(uid);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },

  async linkDepot({ commit, state }, data) {
    if (!data.id) throw "invalid role id";
    try {
      const { id, depotId } = data;
      let body = {
        depotId,
      };
      body = JSON.parse(JSON.stringify(body));

      const response = await RolesAndRespo.linkDepot(id, body);
      if (!_.isEmpty(state.newCreatedRole))
        commit("setNewCreatedRole", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async unlinkDepot({ commit, state }, data) {
    if (!data.id) throw "invalid role id";
    try {
      const { id, depotId } = data;
      let body = {
        depotId,
      };
      body = JSON.parse(JSON.stringify(body));

      const response = await RolesAndRespo.unlinkDepot(id, body);
      if (!_.isEmpty(state.newCreatedRole))
        commit("setNewCreatedRole", response.data.data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async setTwoFactor({ state }, payload) {
    try {
      const response = await RolesAndRespo.setTwoFactor(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data + state.me;
    }
  },
};
