<template>
  <div>
    <Table
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="contractTermsData"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
                v-model="recordsPerPage"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
                v-model="selectedFilteredStatus"
                @change="selectFilteredStatus"
                class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option v-for="(filterStatus,index) in filterStatuses" :key="index" :value="index">{{ filterStatus }}</option>
            </select>
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
        </div>
      </template>
      <template #default="{data}">
        <td class="px-5 py-2">
          <div class="text-black-primary whitespace-no-wrap flex">
            <a class="flex cursor-pointer" @click.prevent="viewContractTerm(data.id)"
            ><font-awesome-icon
                icon="clipboard"
                class="my-auto mx-1.5 hover:text-orange-primary-primary"
            /></a>
            <a class="flex cursor-pointer" @click.prevent="cloneContractTerm(data.id)"
            ><font-awesome-icon
                icon="clone"
                class="my-auto mx-1.5 hover:text-orange-primary-primary"
            /></a>
            <font-awesome-icon
                v-if="data.isLocked"
                icon="lock"
                class="my-auto mx-1.5 hover:text-orange-primary-primary opacity-90"
            />
          </div>
        </td>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent === 'view'">
        <TermRequest
            :activeTerm="activeTerm"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import {Modal, Table} from '@/components/commons'
import {DEFAULT_MODAL_HEIGHT} from '@/_helper/constants'
import {formatDate} from '@/_helper'
import _ from 'lodash'
import TermRequest from '@/components/view/business/contracts/TermRequest'

export default {
  components: {
    Table, Modal, TermRequest
  },
  data(){
    return {
      modalContent: '',
      modalTitle: '',
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        'Name',
        'Business Id',
        'Date Created',
        'Status',
      ],
      contractTerms: [],
      activeTerm: null,
      recordsPerPage: 50,
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      selectedFilteredStatus: 0,
      filterStatuses: [
        "All",
      ],
      readContractTerms: false,
      writeContractTerms: false,
      termType: '',
    }
  },
  async mounted(){
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readContractTerms = securityAccessObj.readContractTerms;
      this.writeContractTerms = securityAccessObj.writeContractTerms;
    }
    await this.init();
  },
  methods: {
    async init() {
      const contractTerms = await this.$store.dispatch(`contract/getContractTerms`);
      if(contractTerms){
        this.contractTerms = this.formatItem(_.orderBy(contractTerms, ['createdAt'], ['desc']));
      }
      this.$emit("updateLoading", false);
    },
    viewContractTerm(contractTermId){
      this.$refs.modal.openModal();
      this.activeTerm = this.contractTerms.filter(item => item.id === contractTermId)[0];
      this.modalContent = 'view';
      this.modalTitle = 'Contract Terms Details';
    },
    selectFilteredStatus(){
      this.selectedStatus = this.filterStatuses[this.selectedFilteredStatus]
    },
    formatItem(items) {
      let nItems = [];
      if(items){
        items.map(item => {
          const contractTerms = {
            id:item['_id'],
            name:item['name'] || '',
            date: formatDate(item['createdAt']) || '',
            businessId:item['businessId'] || '',
            status: item['status'],
            sections: item['sections'] || [],
          }
          nItems.push(contractTerms)
        });
      }
      return nItems;
    },
    parsePreContractTerm(data){
      return data.map((contractTermData) => ([
        {id: contractTermData.id,
          name: contractTermData.name,
          itemType: 'name',
          hasImage: false,
          isDefault: contractTermData.isDefault,
          isLocked: contractTermData.isLocked
        },
        {id: contractTermData.id, name: contractTermData.businessId, itemType: 'string'},
        {id: contractTermData.id, name: contractTermData.date, itemType: 'string'},
        {id: contractTermData.id, name: contractTermData.status, itemType: 'string'},
      ]))
    },
    setSecurityAccess(securityRoles){
      let iReadContractTerms = false;
      let iWriteContractTerms = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.contractTerms?.readContractTerms?.value) {
          iReadContractTerms = securityConfig.contractTerms.readContractTerms.value;
        }
        if (securityConfig.contractTerms?.writeContractTerms?.value) {
          iWriteContractTerms = securityConfig.contractTerms.writeContractTerms.value;
        }
      });
      return {
        readContractTerms: iReadContractTerms,
        writeContractTerms: iWriteContractTerms,
      };
    },
    toast(state,message){
      this.$store.commit('setDialogNotify',true);
      this.$store.commit('setMessageNotify',{state, message});
    },
    async cloneContractTerm(uid){
      await this.$store.dispatch(`contract/cloneContractTerms`, uid)
          .then(res => {
            this.contractTerms.push(res)
            this.toast('success', `${res.name} successfully cloned!`);
          }).catch(() => {
            this.toast('error', 'Error required fields.');
          });
      await this.init();
    },
  },
  computed: {
    contractTermsData() {
      if(this.contractTerms){
        let preContractTermComputedData = this.contractTerms

        if(this.selectedFilteredStatus){
          preContractTermComputedData = preContractTermComputedData.filter((item) =>
              this.selectedStatus === item.status
          )
        }
        return this.parsePreContractTerm(preContractTermComputedData)
      }else{
        return [];
      }
    }
  }
}
</script>