export const DEFAULT_TEMPLATE = [
  {
    uuid: "Headers-cf60efdc-e56b-4841-83b0-fca9b6417f39",
    type: "Headers",
    content: [
      {
        part: "left",
        value:
          `${process.env.VUE_APP_LOGO}`,
        filePayload: false,
        uuid: "Header-09472fb8-e0c3-43fb-a3cb-f29a8ddee108",
        isSection: false,
        parentComponent: "Headers-cf60efdc-e56b-4841-83b0-fca9b6417f39",
        type: "image/jpeg",
      },
      {
        part: "right",
        value: "",
        filePayload: false,
        uuid: "Header-dccc2748-902d-417b-bea9-347297739279",
        isSection: false,
        parentComponent: "Headers-cf60efdc-e56b-4841-83b0-fca9b6417f39",
      },
    ],
    contentPreview: "",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "Title-8ff9d343-0de4-4574-81d8-b7faabef2ba1",
    type: "Title",
    content: [],
    contentPreview: "Induction approval",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "Addresses-d3b98140-7fe0-4d4c-ae07-6f394f45c8f0",
    type: "Addresses",
    content: [
      {
        part: "left",
        value: "{{accreditationNumber}}",
      },
      // {
      //   part: "right",
      //   value: "{{todayDate}}",
      // },
    ],
    contentPreview: "",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "schemeType-3e6d60d0-5593-4e62-bb4b-5b9cb7fc4106",
    type: "schemeType",
    content: [],
    contentPreview: "",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "Paragraph-4c3decf3-dd48-402b-a186-2eccc7662a57",
    type: "Paragraph",
    content: [],
    contentPreview:
      "(Note: the expiry date may be added after each module – optional only)\n",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "Title-c4efe471-a209-4336-b58a-2a417cfdc4c8",
    type: "Title",
    content: [],
    contentPreview: "To whom it may concern",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "Paragraph-c3129e6b-f606-4469-8c90-85c27fb2bbd7",
    type: "Paragraph",
    content: [
      "I",
      "advise",
      "that",
      "",
      "{{driverName}}",
      ",",
      "is",
      "operating",
      "under",
      "the",
      "National",
      "Heavy",
      "Vehicle",
      "Accreditation",
      "Scheme",
      "(NHVAS)",
      "Modules",
      "indicated",
      "above",
      "for",
      "",
      "{{accreditationNumber}}",
      "",
    ],
    contentPreview:
      "I advise that  {{driverName}} , is operating under the National Heavy Vehicle Accreditation Scheme (NHVAS) Modules indicated above for  {{accreditationNumber}} ",
    contentVariables: [
      {
        type: "{{driverName}}",
        index: 4,
        partId: "part-section-fd091922-7c14-4403-8413-71c05cf82907",
      },
      {
        type: "{{accreditationNumber}}",
        index: 21,
        partId: "part-section-fc85fa0a-0f70-4c74-9150-27c532a42518",
      },
      // {
      //   type: "{{driverName}}",
      //   index: 4,
      //   partId: "part-section-d53faffe-10f4-4e3a-916b-d9a28ef264c7",
      //   parentUuid: "Paragraph-c3129e6b-f606-4469-8c90-85c27fb2bbd7",
      // },
      // {
      //   type: "{{accreditationNumber}}",
      //   index: 21,
      //   partId: "part-section-d53faffe-10f4-4e3a-916b-d9a28ef264c7",
      //   parentUuid: "Paragraph-c3129e6b-f606-4469-8c90-85c27fb2bbd7",
      // },
    ],
    isConfiguring: true,
    isSection: true,
  },
  {
    uuid: "Paragraph-b99f60ae-fcf9-4621-8cba-521800e97ee7",
    type: "Paragraph",
    content: [
      "{{driverName}}",
      "has",
      "been",
      "inducted",
      "our",
      "NHVAS",
      "management",
      "system",
      "and",
      "meets",
      "the",
      "requirements",
      "relating",
      "to",
      "drivers",
      "operating",
      "under",
      "our",
      "heavy",
      "vehicle",
      "accreditation.",
    ],
    contentPreview:
      "{{driverName}} has been inducted our NHVAS management system and meets the requirements relating to drivers operating under our heavy vehicle accreditation.",
    contentVariables: [
      {
        type: "{{driverName}}",
        index: 0,
        partId: "part-section-1358d042-824a-41c1-b0ac-0ea75972720a",
      },
      // {
      //   type: "{{driverName}}",
      //   index: 0,
      //   partId: "part-section-6cc1b5c6-1b74-4fd4-979d-96e4282d4c94",
      //   parentUuid: "Paragraph-b99f60ae-fcf9-4621-8cba-521800e97ee7",
      // },
    ],
    isConfiguring: true,
    isSection: true,
  },
  {
    uuid: "Paragraph-6fe28cbf-3ded-49bd-a2a9-ba8522bc01da",
    type: "Paragraph",
    content: [
      "While",
      "operating",
      "under",
      "the",
      "relevant",
      "management",
      "system,",
      "",
      "{{driverName}}",
      "will",
      "carry",
      "this",
      "letter",
      "and",
      "a",
      "copy",
      "of",
      "the",
      "current",
      "accreditation",
      "certificate",
      "for",
      "the",
      "NHVAS",
      "accreditation",
      "module/s",
      "indicated",
      "above,",
      "in",
      "accordance",
      "with",
      "section",
      "468",
      "of",
      "Heavy",
      "Vehicle",
      "National",
      "Law",
    ],
    contentPreview:
      "While operating under the relevant management system,  {{driverName}} will carry this letter and a copy of the current accreditation certificate for the NHVAS accreditation module/s indicated above, in accordance with section 468 of Heavy Vehicle National Law",
    contentVariables: [
      {
        type: "{{driverName}}",
        index: 8,
        partId: "part-section-e3ab88e9-0ab9-4307-adc5-ed89f68f8767",
      },
      // {
      //   type: "{{driverName}}",
      //   index: 8,
      //   partId: "part-section-cdf57071-dcee-49f0-affd-1fecd3a377d4",
      //   parentUuid: "Paragraph-6fe28cbf-3ded-49bd-a2a9-ba8522bc01da",
      // },
    ],
    isConfiguring: true,
    isSection: true,
  },
  {
    uuid: "Paragraph-c4707bf9-56fe-4c24-925a-984b9ce8e026",
    type: "Paragraph",
    content: [],
    contentPreview: "Yours sincrely",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "Closing-19ac556d-f691-4755-a817-5441ccd903b4",
    type: "Closing",
    content: [
      {
        id: "Admin Signature8b899483-c4f5-46eb-ad84-34f3308ba365",
        uuid: "Admin Signature8b899483-c4f5-46eb-ad84-34f3308ba365",
        type: "Admin Signature",
        isSection: false,
        part: "left",
        parentComponent: "Closing-19ac556d-f691-4755-a817-5441ccd903b4",
      },
    ],
    contentPreview: "",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "Paragraph-90197a04-1526-4f82-9886-62bfcbc0ef41",
    type: "Paragraph",
    content: [],
    contentPreview: "{{driverName}}",
    contentVariables: [
      {
        type: "{{driverName}}",
        index: 0,
        partId: "part-section-51ef4f55-8af0-4e25-baa9-b874f597ad4c",
      },
    ],
    isConfiguring: true,
    isSection: true,
  },
  {
    uuid: "Paragraph-808aa67a-4cca-45c4-8812-ccc1c10327ee",
    type: "Paragraph",
    content: [],
    contentPreview: "TITLE",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "Paragraph-1e7df35d-ebf0-4fd1-baf4-e3ab7551e6db",
    type: "Paragraph",
    content: ["{{adminNumber}}", "and", "AFTER", "HOURS", "CONTACT", "NUMBER"],
    contentPreview: "{{adminNumber}} and AFTER HOURS CONTACT NUMBER",
    contentVariables: [
      {
        type: "{{adminNumber}}",
        index: 0,
        partId: "part-section-adb0f9e5-1eb2-47dd-942d-96ac5b3bfc73",
      },
    ],
    isConfiguring: true,
    isSection: true,
  },
  {
    uuid: "Paragraph-b6f32db9-d734-4df9-afc6-3f02ac6e43a3",
    type: "Paragraph",
    content: [],
    contentPreview: "EMAIL ADDRESS",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "Title-635ab122-87af-4085-8ada-971383b6cbd7",
    type: "Title",
    content: [],
    contentPreview:
      "The following documents will be carried by the driver: [delete those not relevant]",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "bulletPoints-ee183ae0-7d0f-425e-a396-e046bc3f950a",
    type: "bulletPoints",
    content: [
      {
        value: "Copy of signed Induction Approval document",
      },
      {
        value: "Copy of the current NHVAS Accreditation Certificate",
      },
      {
        value: "National Driver Work Diary",
      },
    ],
    contentPreview: "",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "Title-291f82ae-38fc-4631-8ba3-e3e0a3ee7ffc",
    type: "Title",
    content: [],
    contentPreview: "Advanced Fatigue Management Module",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
  {
    uuid: "bulletPoints-7a6ec98a-ae48-4ce6-9f73-dab1be5d3fb9",
    type: "bulletPoints",
    content: [
      {
        value: "Copy of signed Induction Approval document",
      },
      {
        value:
          "Copy of the current NHVAS Accreditation Certificate and operating limits table",
      },
      {
        value: "National Driver Work Diary",
      },
      {
        value: "Roster schedules (if applicable)",
      },
    ],
    contentPreview: "",
    contentVariables: [],
    isConfiguring: false,
    isSection: true,
  },
];
