import { API } from '@/api/config';
import { ObjectToQueryString } from '../../../_helper';

export default new (class BillingInvoice {
  getAllInvoice(
    pagination = { skip: 0, limit: 10, filter: {}, userMongoId: {} },
  ) {
    let filter = '';
    if (typeof pagination.filter === 'object') {
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw 'Invalid Filter object';
      }
    }

    pagination.filter = filter;

    filter = JSON.stringify(pagination.filter);

    if (pagination) pagination = '?' + ObjectToQueryString(pagination) + '&';

    return API.ACCOUNTS.get(`billing-invoice/${pagination}`);
  }

  getOneInvoice(id) {
    return API.ACCOUNTS.get(`billing-invoice/${id}`);
  }

  //Subscription Invoices
  getAllContractInvoices(param, query = { skip: 0, limit: 10, filter: {} }) {
    let filter = '';
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/subscription-invoice/all-invoices/contract/${param.contractId}${query}`);
  }

  getContractUnpaidInvoice(contractId) {
    return API.ACCOUNTS.get(`/subscription-invoice/current-invoice/contract/${contractId}`);
  }

  getInvoiceByPaymentHistory(contractId, payload) {
    return API.ACCOUNTS.post(`/subscription-invoice/current-invoice/payment-history/${contractId}`, payload);
  }

  createBacktrackInvoice(payload) {
    return API.ACCOUNTS.post(`/subscription-invoice/create-backtrack-invoice`, payload);
  }
})();
