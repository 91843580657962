<template>
  <div class="flex w-full">
    <StyledButton
      :borderColor="theme.secondaryColor"
      :hoverBorderColor="theme.secondaryColor"
      v-if="!noNavigation"
      class="px-3 text-sm text-gray-300 border border-r-0 border-gray-300 rounded rounded-tr-none rounded-br-none disabled:opacity-50" 
      :disabled="disabledPrevBtn"
      @click="prev"
    >
      <font-awesome-icon icon="chevron-left" />
    </StyledButton>
    <InputElementWrapper :hoverBorderColor="theme.secondaryColor">
      <date-picker 
        v-bind="$attrs"
        v-model="value" 
        valueType="format" 
        prefix-class="lhv"
        :partial-update="true"
        :default-value="new Date()"
        :class="{ 'no-nav': noNavigation, 'has-error': isTouched && (!value || !value[0]) }"
        :disabled-date="disableCalendarDates"
        @blur="isTouched = true"
        @change="$emit('selected', value)"
        @open="getBreachAndEventDates(null)"
        @calendar-change="getBreachAndEventDates"
      >
    </date-picker>
    </InputElementWrapper>
    <StyledButton
      :borderColor="theme.secondaryColor" 
      :hoverBorderColor="theme.secondaryColor" 
      v-if="!noNavigation"
      :disabled="disabledNextBtn"
      class="px-3 text-sm text-gray-300 border border-l-0 border-gray-300 rounded rounded-tl-none rounded-bl-none disabled:opacity-50" 
      @click="next"
    >
      <font-awesome-icon icon="chevron-right" />
    </StyledButton>
  </div>
</template>

<script>
  import $ from 'jquery';
  import _ from 'lodash';
  import moment from 'moment';
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import { mapGetters } from 'vuex'
  import { StyledButton, InputElementWrapper } from '@/styled-components'

  export default {
    inheritAttrs: false,
    components: { DatePicker, StyledButton, InputElementWrapper },
    props: {
      date: [String, Array],
      noNavigation: Boolean,
      disabledBefore: String,
      disabledAfter: {
        type: String,
        default: moment().format('YYYY-MM-DD')
      },
      disabledBetween: {
        type: Array,
        default() { return [] }
      },
      disabledDates: {
        type: Array,
        default() { return [] }
      },
      selectedDriver: {
        type: Object,
        default: null
      },
      selectedDrivers: {
        type: Array,
        default() { return [] }
      },
      displayEvent: {
        type: Boolean,
        default: false
      },
      displayBreach: {
        type: Boolean,
        default: false
      },
      selectAllData: Object
    },
    data() {
      return {
        value: null,
        isTouched: false,
        dateFormat: 'YYYY-MM-DD',
        disabledPrevBtn: false,
        disabledNextBtn: false,
        datePickerPopup: null,
        datePickerEvents: [],
        datePickerBreaches: [],
      }
    },
    async mounted() {
      this.value = this.date;
      if (this.value == null) {
        this.value = moment().format(this.dateFormat);
      }

      await this.getBreachAndEventDates();
      this.observeDatePickerPopup();
    },
    beforeDestroy() {
      this.datePickerEvents = this.datePickerBreaches = [];
    },
    watch: {
      value: {
        handler: function (newVal) {
          this.disabledPrevBtn = this.isPrevDisable(newVal) ? true : false;
          this.disabledNextBtn = this.isNextDisable(newVal) ? true : false;
        }, immediate: true
      }
    },
    methods: {
      clear() {
        this.value = null
      },
      isPrevDisable(dateValue) {
        return this.isDateNotBefore(moment(dateValue).subtract(1, 'days').format(this.dateFormat));
      },
      prev() {
        if (!this.isPrevDisable(this.value)) {
          this.value = moment(`${this.value} 00:00:00`).subtract(1, 'days').format(this.dateFormat);
          this.$emit('selected', this.value);
        }
      },
      isNextDisable(dateValue) {
        return this.isDateNotAfter(moment(dateValue).add(1, 'days').format(this.dateFormat))
            || moment().format(this.dateFormat) == moment(dateValue).format(this.dateFormat)
      },
      next(){
        if (!this.isNextDisable(this.value)) {
          this.value = moment(`${this.value} 00:00:00`).add(1, 'days').format(this.dateFormat);
          this.$emit('selected', this.value);
        }
      },


      isDateNotBefore(date) {
        return this.disabledBefore && moment(date, this.dateFormat).isBefore(this.disabledBefore);
      },
      isDateNotAfter(date) {
        return this.disabledAfter && moment(date, this.dateFormat).isAfter(this.disabledAfter);
      },
      isDateToday(date) {
        return moment().format(this.dateFormat) == date;
      },
      isDateBetween(date) {
        return this.disabledBetween.length > 1 && moment(date, this.dateFormat).isBetween(this.disabledBetween[0], this.disabledBetween[1]);
      },
      isDateInDisabledDates(date) {
        return this.disabledDates.includes(date)
      },
      disableCalendarDates(currentDate) {
        const date = moment(currentDate, this.dateFormat).format(this.dateFormat);
        return this.isDateNotBefore(date) || this.isDateNotAfter(date) || this.isDateBetween(date) || this.isDateInDisabledDates(date);
      },
      observeDatePickerPopup() {
        const observer = new MutationObserver((mutationsList) => {
          for (const mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
              for (const addedNode of mutation.addedNodes) {
                if (addedNode.classList && addedNode.classList.contains('lhv-datepicker-popup')) {
                  this.datePickerPopup = addedNode;
                  this.updateCalendar();
                }
              }
            }
          }
        });

        observer.observe(document.body, { childList: true, subtree: true });
      },
      updateCalendar() {
        const cells = $(this.datePickerPopup).find('.lhv-table .cell');
        const groupedEvents = _.groupBy(this.datePickerEvents);
        const groupedBreaches = _.groupBy(this.datePickerBreaches);
        if (cells && cells.length > 0) {
          $.each(cells, (index, value) => {
            const cell = $(value);
            cell.removeClass('has-event has-breach');

            if (!_.isEmpty(this.datePickerEvents) || !_.isEmpty(this.datePickerBreaches)) {
              if (cell.attr('title') in groupedEvents || cell.attr('title') in groupedBreaches) {
                const eventCount = !_.isEmpty(groupedEvents[cell.attr('title')]) ? groupedEvents[cell.attr('title')].length : 0;
                const breachCount = !_.isEmpty(groupedBreaches[cell.attr('title')]) ? groupedBreaches[cell.attr('title')].length : 0;
                cell.append(`<div class="calendar-indicator-wrapper">
                  <div class="calendar-indicator">
                    <div class="calendar-breach">${breachCount}</div>
                    <div class="calendar-event">${eventCount}</div>
                  </div>
                </div>`);
              }

              if (this.datePickerEvents.includes(cell.attr('title'))) cell.addClass('has-event')
              if (this.datePickerBreaches.includes(cell.attr('title'))) cell.addClass('has-breach');
            }
          })
        }
      },
      async getBreachAndEventDates(calendarDate = null) {
        if (this.selectedDrivers.length > 0 || this.selectedDriver) {
          this.datePickerEvents = this.datePickerBreaches = [];

          let filter = null;
          if (this.selectedDrivers.length > 0) {
            filter = {};
            if (this.selectAllData) {
              filter.selectAll = true;
              if (this.selectAllData?.except) filter.driverId = { $nin: this.selectAllData?.except }

              const me = this.$store.getters[`account/me`];
              if (me?.businessId) filter.entityId = me?.businessId;
            } else {
              const driverIds = this.selectedDrivers.map(driver => driver.driverId);
              filter.driverIds = { $in: _.union(driverIds, this.selectedDrivers[0]?.driverId) }
            }
          }

          const currentDate = Array.isArray(this.value) ? this.value[0] : this.value;
          const momentDate = calendarDate ? moment(calendarDate) : moment(currentDate);
          const startDate = _.cloneDeep(momentDate).startOf('month').subtract(1, 'week').format();
          const endDate = _.cloneDeep(momentDate).endOf('month').add(1, 'week').format();

          const driverId = this.selectedDrivers.length > 0 ? this.selectedDrivers[0].driverId : this.selectedDriver.driverId;
          const requestData = {
            filter,
            driverId,
            from: moment(startDate, 'YYYY-MM-DD').format(),
            to: moment(endDate, 'YYYY-MM-DD').format()
          }

          let requests = [];
          if (this.displayBreach) requests.push(this.$store.dispatch(`report/getBreachesByDateRange`, requestData))
          if (this.displayEvent) requests.push(this.$store.dispatch(`business/getEventByDateRange`, requestData))

          if (requests.length > 0) {
            await Promise.all(requests).then(data => {
              this.datePickerBreaches = data[0] && data[0].length > 0 ? data[0].map(x => moment(x.time).format('YYYY-MM-DD')) : [];
              if (data[1]) this.datePickerEvents = data[1] && data[1].length > 0 ? data[1].map(x => moment(x.startTimeWithTimezone).format('YYYY-MM-DD')) : [];


            })
            this.updateCalendar();
          }
        }
      },
    },
    computed: {
       ...mapGetters('theme', {
        theme: 'getColorScheme'
      })
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .lhv-datepicker::v-deep {
    width: 100%;
    .lhv-input {
      height: auto;
      font-weight: 500;
      padding: 11px 40px 12px 12px;
      @apply border-gray-300 rounded-none text-sm;
    }

    &.has-error .lhv-input {
      @apply border-red-300
    }

    &.no-nav .lhv-input {
      @apply rounded;
    }
  }
</style>
<style lang="scss">
.lhv-datepicker-popup {
  font-family: Montserrat, ui-sans-serif, system-ui !important;
  .lhv-btn {
    font-size: 14px !important;
    font-weight: bold !important;
    text-transform: uppercase !important;
  }
  .lhv-calendar {
    width: 320px !important;
  }
  .lhv-calendar-content {
    height: 290px !important;
  }
  .lhv-date-row {
    &:first-child .cell {
      border-top: 1px solid #e1e1e1;
    }
    .cell:first-child {
      border-left: 1px solid #e1e1e1;
    }
  }
  .cell {
    @apply relative;
    border-right: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    color: rgba(33, 37, 41, 0.8);

    &:hover {
      background-color: #fafafa !important;
    }
    & > div:first-child {
      position: absolute;
      top: 3px;
      left: 3px;
    }

    &.today {
      font-weight: bold;
      color: #ff9900 !important;

      & > div:first-child {
        top: 1px !important;
      }
    }
    &.disabled {
      background-color: transparent !important;
    }
    &.active:not(.today) {
      color: #212529 !important;
    }

    &.has-event .calendar-event {
      display: flex !important;
      background-color: rgb(16, 185, 129);
    }
    &.has-breach .calendar-breach {
      display: flex !important;
      background-color: rgb(238, 68, 68);
    }
    &.has-event:not(.has-breach) .calendar-breach,
    &.has-breach:not(.has-event) .calendar-event {
      @apply hidden;
    }

    .calendar-indicator-wrapper {
      width: 100%;
      left: 50%;
      bottom: 1px;
      transform: translateX(-50%);
      @apply absolute;

      .calendar-indicator {
        @apply flex items-center justify-center flex-wrap;

        .calendar-event,
        .calendar-breach {
          height: 15px;
          min-width: 16px;
          font-size: 10px;
          margin: 0.5px;
          padding-top: 1px;
          @apply rounded hidden justify-center items-center font-bold text-white;
        }
      }
    }
  }
}
</style>