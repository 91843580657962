<template>
  <div>
    <FormulateForm v-model="values" :schema="schema" @submit="onSubmit" #default="{ hasErrors }">
      <div class="items-center justify-between">
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Next"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
          />
        </StyledSubmit>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>
<script>
import schema from './details-schema.js';
import { mapGetters } from 'vuex'

export default {
  name: "Business-Details",
  props: {
    msg: String,
    postData: null,
  },
  data() {
    return {
      loading: false,
      values: {},
      schema: schema,
      errorMessage:'',
      interChangingData: {}
    }
  },
  
  mounted(){
    if(this.postData?.steps){
      this.values = this.postData.steps?.businessDetails || null
      this.interChangingData = this.postData
    }
  }, 

  methods: {
    async onSubmit() {
      this.loading = true
      const businessDetails = JSON.parse(JSON.stringify(this.values))
      if(businessDetails){
        const preData = {...this.interChangingData, steps: {...this.interChangingData?.steps, ...{businessDetails: businessDetails}}}
        this.$emit('nextStep', preData)
      }
    },
  },
  computed: {
    ...mapGetters('theme', {
    theme: 'getColorScheme'
    })
  },
}
</script>
