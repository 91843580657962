import {Settings} from '@/api'
// import {isEmpty} from 'lodash'
export default{

    //@POST: Upload logo and logo details
    async uploadLogo({ state, commit }, fileData) {
        if (!state) throw `Invalid + ${state}`
        try {
          const response = await Settings.uploadLogo(fileData)

          if(response.data.success){
            const { portalLogo } = response.data?.data
            commit('setPortalLogoURL', portalLogo.url)
          }

          return response.data

        } catch (e) {
          throw new Error(e.message)
        }
      },

    //@GET: Fetch specific logo URL via mongo id
    async getLogoById({state, commit}, accountId){
      if (!state) throw `Invalid + ${state}`

      try {
        const response = await Settings.getLogoById(accountId)
        if(response.data.success){
          const { portalLogo } = response.data?.data
          if(portalLogo?.url){
            commit('setPortalLogoURL', portalLogo.url)
          }
        }
       
        return response.data?.data

      } catch (e) {
        throw new Error(e.message)
      }
    },

    //@PATCH: Update logo type object to null to remove current logo data
    async deleteLogoById({state}, {accountId, type}){
      if (!state) throw `Invalid + ${state}`

      try{
        const response = await Settings.deleteLogoById({accountId, type})

        return response.data

      } catch(e) {
        throw new Error(e.message)
      }
    },

    //@PATCH: Update theme in settings
    async updateTheme({state}, {accountId, type}){
      if (!state) throw `Invalid + ${state}`

      try{
        const response = await Settings.updateTheme({accountId, type})

        return response.data

      } catch(e) {
        throw new Error(e.message)
      }
    },

    //@GET: Action to fetch settings per account
    async getUserSettings({commit, state}, accountId){
      if (!state) throw `Invalid + ${state}`

      try{
        const response = await Settings.getUserSettings({accountId})

        if(response?.data?.data?.themeId){
          commit('setThemeId', response.data.data.themeId)
        }
        if(response?.data?.data?.themeId){
          commit('setDarkModeData', {
            darkModeId: response.data.data.darkModeThemeId,
            isDarkMode: response.data.data.isDarkMode
          })
        }

        return response.data


      } catch(e) {
        throw new Error(e.message)
      }
    },

    //@POST: Create a settings per account
    async createSettings({state}, settingsData){
      if (!state) throw `Invalid + ${state}`

      try{
        const response = await Settings.createSettings(settingsData)

        return response.data


      } catch(e) {
        throw new Error(e.message)
      }
    }
}