// Rest API Configuration --------
import axios from "axios";
import { setup } from "axios-cache-adapter";
import { servicesList } from "./services";
import store from "@/store";
import { GEOTAB_URL } from "../_helper/constants";
import VueCookies from "vue-cookies";

let APIs = [];
let environment = process.env.VUE_APP_ENV;

Object.keys(servicesList).forEach(async (mservices) => {
  let url;
  if (environment == "development") {
    url = servicesList[mservices].SANDBOX_URL;
  } else if (environment == "staging") {
    url = servicesList[mservices].STAGING_URL;
  } else if (environment == "production") {
    url = servicesList[mservices].PRODUCTION_URL;
  } else if (environment == "local") {
    url = servicesList[mservices].LOCAL_URL;
  } else {
    throw new Error("Incorrect value set for VUE_APP_ENV");
  }
  const api = setup({
    baseURL: servicesList[mservices].PORT
      ? `${url}:${servicesList[mservices].PORT}`
      : `${url}`,
    cache: {
      maxAge: 3 * 1000,
    },
  });
  // api intercept for token
  api.interceptors.request.use(function (config) {
    const token = store.getters[`account/accessToken`];
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

  // api intercept if forbidden resource (expired token), auto logout
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalConfig = error.config;
      if (error.response?.status === 401) {
        if (error.response.data?.errorName === "TokenExpiredError") {
          // Check if refresh token is stored
          const refreshToken = store.getters[`account/refreshToken`];
          if (!refreshToken) {
            sessionStorage.setItem("expired", true);
            window.location.href = "/logout";
            return;
          } else {
            const isProxy = store.getters["account/proxyUser"];
            const loggedIUser = await store.dispatch("account/refreshToken", {
              jwt: refreshToken,
              isProxy,
            });

            const geotabUrl = await VueCookies.get(GEOTAB_URL);

            if (geotabUrl) {
              window.location.href = geotabUrl;
              return;
            }

            // If user attempted to navigate to home directory. example: 'http//:logmaster.au/'
            if (window.location.pathname === "/") {
              const defaultUserRedirect =
                store.getters["allURL"][loggedIUser["role"]]["default"];
              window.location.href = defaultUserRedirect;
              return;
            }

            // Retry failed request
            originalConfig.headers["Authorization"] =
              "Bearer " + store.getters["account/accessToken"];
            return axios.request(originalConfig);
          }
        } else {
          sessionStorage.setItem("expired", true);
          window.location.href = "/logout";
          return;
        }
      } else if (
        error.response?.status === 400 &&
        error.config.url === "/auth/refresh"
      ) {
        sessionStorage.setItem("expired", true);
        window.location.href = "/logout";
        return;
      } else {
        throw error;
      }
    }
  );

  APIs[mservices] = api;
});
export const API = APIs;
