<template>
    <Stepper
        :stepperSteps="stepperSteps"
        @completed-step="completeStep"
        @active-step="isStepActive"
        :showNavigation="false"
        @closeRefresh="closeRefresh"
    />
</template>
<script>
import { Stepper } from '@/components/commons'
import {DriverLookup, CreateDriver, CreateBusinessProfile }  from './stepper-component'
export default {
    name: 'Add-or-Link-Driver',
    components: {Stepper},
    data(){
        return{
            stepperSteps: [
                {
                    stepIcon: 'search',
                    stepTitle: 'Driver Lookup',
                    stepSequence: 'first',
                    stepComponent: DriverLookup,
                    isCompleted: true
                },
                {
                    stepIcon: 'user',
                    stepTitle: 'Create / Select',
                    stepSequence: 'second',
                    stepComponent: CreateDriver,
                    isCompleted: false
                },
                {
                    stepIcon: 'briefcase',
                    stepTitle: 'Business Profile',
                    stepSequence: 'third',
                    stepComponent: CreateBusinessProfile,
                    isCompleted: false
                }
            ],
            activeStep: 0
        }
    },
     methods: {
        completeStep(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    step.isCompleted = true;
                }
            })
        },
        isStepActive(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence){
                    if(step.isCompleted === true) {
                        step.isCompleted = false;
                    }
                }
            })
        },
        closeRefresh(){
            this.$emit('closeRefresh')
        }
    }
}
</script>