export default [
    {
      name:'Admin Navigation',
      items:[
        {
          name:'Dashboard',
          link:'/admin/'
        },
        {
          name:'Partners',
          link:'/admin/partners'
        },
        {
          name:'Support',
          link:'',
          subitems: [
            {
              name:'Business Lookup',
              link:'/admin/business-lookup'
            },
            {
              name:'Vehicle Lookup',
              link:'/admin/vehicle-lookup'
            },
            {
              name:'Driver Lookup',
              link:'/admin/driver-lookup'
            },
            {
              name:'Solo Lookup',
              link:'/admin/solo-lookup'
            },
            {
              name:'Tech Providers',
              link:'/admin/tech-providers'
            },
            {
              name:'All Uninstall Events',
              link:'/admin/uninstall-events'
            },
            {
              name:'Email List',
              link:'/admin/email-list'
            },
            {
              name:'Report Jobs',
              link:'/admin/report-jobs'
            },
            // {
            //   name:'Record Keeper Lookup',
            //   link:'/admin/record-keeper-lookup'
            // },
            // {
            //   name:'Driver Lookup',
            //   link:'/admin/driver-lookup'
            // },
          ]
        },
        {
          name:'Contract Management',
          link:'/admin/contract-management'
        },
        {
          name:'Lead Management',
          link:'/admin/lead-management'
        },
        {
          name:'Contracts',
          link:'',
          subitems: [
            {
              name:'Create Term Form',
              link:'/admin/contracts/terms-forms/builder'
            },
            {
              name:'Terms Forms',
              link:'/admin/contracts/terms-forms'
            },
            // {
            //   name:'Terms',
            //   link:'/admin/contracts/terms'
            // },
            // {
            //   name:'Requests',
            //   link:'/admin/contracts/requests'
            // }
          ]
        },
        {
          name:'Billing',
          link:'',
          subitems: [
            {
              name:'Invoices',
              link:'/admin/invoices'
            },
            {
              name:'Adhoc Billing Tool',
              link:'/admin/adhoc-billing-tool'
            },
          ]
        },
        {
          name:'Permissions',
          link:'',
          subitems: [
            {
              name:'User List',
              link:'/admin/users'
            },
            {
              name:'Role List',
              link:'/admin/roles'
            },
          ]
        },
        {
          name:'Debug',
          link:'',
          subitems:[
            {
              name:'JSON builder',
              link:'/admin/json-builder'
            }
          ]
        },
        {
          name:'Services',
          link:'',
          subitems: [
            {
              name:'Modules',
              link:'/admin/services',
            },
            {
              name:'Business Subscription',
              link:'/admin/services/subscription',
            },
          ],
        },
        {
          name: 'Reports',
          link: '',
          subitems: [
            {
              name: 'NHVR report',
              link: '/admin/report/nhvr'
            }
          ]
        }
      ]
    },
]