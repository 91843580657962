<template>
  <div>
    <div v-if="readReports">
      <div
        class="mb-3 pb-4 flex justify-between items-center w-full border-b border-gray-300"
      >
        <div class="flex items-center">
          <div class="mr-3" style="width: 13.5rem">
            <div class="block font-bold text-xs text-grey-500 mb-1">Driver</div>
            <FormulateInput
              name="selectedDriver"
              type="driver-multiselect"
              placeholder="Select a driver"
              style="margin-bottom: 0px"
              @selected="onSelectDrivers"
            />
          </div>
          <div class="mr-3" style="width: auto">
            <div class="block font-bold text-xs text-grey-500 mb-1">
              Date of Report
            </div>
            <DatePicker
              ref="childDatePicker"
              :date="selectedDateRange"
              placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
              :disabledBefore="disabledBefore"
              @selected="selectedDateRange = $event"
              range
              noNavigation
              :clearable="false"
            />
          </div>
          <div class="mt-6">
            <button
              :disabled="!selectedDrivers || selectedDrivers.length < 1"
              :class="{
                'cursor-not-allowed':
                  !selectedDrivers || selectedDrivers.length < 1,
              }"
              class="btn btn-primary flex items-center"
              @click.prevent="onSearch"
              style="height: 38px"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Search
            </button>
          </div>
        </div>
        <div>
          <button
            :disabled="!selectedDrivers || selectedDrivers.length < 1"
            :class="{
              'cursor-not-allowed':
                !selectedDrivers || selectedDrivers.length < 1,
            }"
            class="btn btn-primary flex items-center"
            @click.prevent="onGetReport"
            style="height: 38px"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Get Report
          </button>
        </div>
      </div>
      <Table
        :tableHeader="tableHeader"
        :data="reportData"
        :hasActions="false"
        :isLoading="isLoading"
        :paginationSettings="paginationSettings"
        @onPageChange="onPageChange($event)"
      ></Table>
    </div>
    <div
      v-if="!readReports && hasRole"
      class="w-full min-h-full text-center mt-5"
    >
      <h1>
        Not authorized to view reports. Please contact your business admin.
      </h1>
    </div>
    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>
    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content == 'new'">
        <div class="mb-16">
          <div v-if="!sendReportloading">
            <FormulateForm v-model="sendReportModel" @submit="onSubmit">
              <div class="items-center justify-between">
                <FormulateInput
                  type="email"
                  name="recipientEmail"
                  label="Email address"
                  validation="required|email"
                  @input="handleEmailCasing($event)"
                />
                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="btn btn-primary w-full mt-5"
                  :disabled="
                    !sendReportModel.recipientEmail || sendReportloading
                  "
                />
              </div>
            </FormulateForm>
          </div>

          <div
            v-else
            class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center"
          >
            <font-awesome-icon
              icon="cog"
              spin
              size="3x"
              class="text-gray-400"
            />
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import DatePicker from "@/components/commons/field/DatePicker";
import { mapGetters } from "vuex";
import { Table, Modal } from "@/components/commons";
import { REPORT_NAMES } from "../../_helper/constants";
import { formatPaginationSettings } from "@/_helper";
export default {
  name: "Forms-Report-Page",
  components: { DatePicker, Table, Modal },
  data() {
    return {
      selectedDateRange: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      readReports: false,
      writeReports: false,
      disabledBefore: null,
      hasRole: true,
      selectedDrivers: [],
      tableHeader: [
        "Driver Name",
        "Driver Email",
        "Total Distance",
        "Total Hours",
        "Unlocked Events Flag",
        "Incorrect Entries Flag",
      ],
      reportData: [],
      isLoading: false,
      paginationSettings: {
        page: 1,
        totalPages: 1,
        totalRecords: 0,
        visiblePageItemCount: 10,
      },
      sendReportModel: { recipientEmail: null },
      sendReportloading: false,
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
      pageLimit: 10,
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readReports = securityAccessObj.readReports;
    } else {
      this.hasRole = false;
    }
    this.$emit("updateLoading", false);
  },
  methods: {
    validatePayload() {
      return (
        (this.selectedDrivers || this.selectedDrivers.length > 0) &&
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },
    setSecurityAccess: (securityRoles) => {
      let initialReadReports = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.reports.readReports?.value) {
          initialReadReports = securityConfig.reports.readReports.value;
        }
      });
      return {
        readReports: initialReadReports,
      };
    },
    async onSearch() {
      const isValidated = this.validatePayload();
      if (!isValidated) return;
      this.isLoading = true;
      const payload = {
        startDate: this.selectedDateRange[0],
        endDate: this.selectedDateRange[1],
        driverIds: this.selectedDrivers,
      };

      const driverStats = await this.$store.dispatch(
        "report/getDriverStats",
        payload
      );
      this.totalRecordLength = driverStats.summary.length;
      this.totalRecords = driverStats.summary;
      this.handlePagination(driverStats.summary, 1);
      this.isLoading = false;
    },
    onSelectDrivers(drivers) {
      const driverIds = drivers.map((driver) => {
        return driver.driverId;
      });
      this.selectedDrivers = driverIds;
    },
    formateTableData(data) {
      return data.map((x) => {
        return [
          {
            id: x._id,
            name: x.driverName,
            itemType: "string",
          },
          {
            id: x._id,
            name: x.email,
            itemType: "string",
          },
          {
            id: x._id,
            name: x.totalDistance,
            itemType: "number",
          },
          {
            id: x._id,
            name: x.totalHours,
            itemType: "number",
          },
          {
            id: x._id,
            name: x.unlockedEventFlag,
            itemType: "boolean",
          },
          {
            id: x._id,
            name: x.summaryIncorrectEntryFlag,
            itemType: "boolean",
          },
        ];
      });
    },
    onGetReport() {
      this.$refs.modal.openModal();
      this.modal.content = "new";
      this.modal.title = "Send Report To Email";
    },
    async onSubmit() {
      this.sendReportloading = true;
      const payload = {
        reportType: REPORT_NAMES.DRIVER_STATS_REPORT,
        startDate: this.selectedDateRange[0],
        endDate: this.selectedDateRange[1],
        driverIds: this.selectedDrivers,
        recipientEmail: this.sendReportModel.recipientEmail,
      };

      try {
        await this.$store.dispatch("report/generateReport", payload);
        this.toast(
          "success",
          `An email will be sent to ${payload.recipientEmail} shortly`
        );
      } catch (error) {
        this.toast("error", "Something went wrong!");
      }
      this.sendReportloading = false;
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    handleEmailCasing(value) {
      this.sendReportModel.recipientEmail = value.toLowerCase();
    },
    handlePagination(totalRecords, page) {
      const startIndex = (page - 1) * this.pageLimit;
      const endIndex = startIndex + this.pageLimit;
      totalRecords = totalRecords.slice(startIndex, endIndex);
      const formattedReportData = this.formateTableData(totalRecords);
      const paginationMetaData = {
        skip: page * this.pageLimit - this.pageLimit,
        limit: this.pageLimit,
        total: this.totalRecordLength,
        count:
          formattedReportData.length % this.pageLimit === 0
            ? this.pageLimit
            : formattedReportData.length % this.pageLimit,
      };
      this.paginationSettings = formatPaginationSettings(paginationMetaData);
      this.reportData = formattedReportData;
    },
    async onPageChange(event) {
      if (event.page) {
        this.handlePagination(this.totalRecords, event.page);
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
<style scoped lang="scss">
.compliance-container {
  min-height: calc(100vh - 92px);
}
.send-datepicker::v-deep > div {
  @apply w-full;
}
</style>
