<template>
    <div>
      <div class="flex w-full" v-if="loading">
        <div class="m-auto">
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300">Loading...</span>
        </div>
      </div>
       <div v-if="passwordCreated">
        <h1 class="text-2xl text-green-600 text-center font-bold">Welcome to Logmaster Australia, your account has been created.</h1>
        <div class="text-sm text-center">Redirecting...</div>
      </div>
      <div v-if="!passwordCreated">
      <FormulateForm @submit="onSubmit" v-if="!loading">
         <div class="mb-5 font-italic text-sm">*Please set your preferred partner account password here:</div>
            <FormulateInput
               label="Password"
               type="password"
               name="password"
               validation="required|min:6,length|matches:/[0-9]/"
               :validation-messages="{ matches: 'Passwords must include a number.' }"
            />
            <FormulateInput
               label="Confirm password"
               type="password"
               name="password_confirm"
               validation="required|confirm"
               validation-name="Password confirmation"
            />
         <div class="flex items-center justify-between w-full">
            <FormulateInput
               type="submit"
               label="Submit"
               input-class="btn btn-primary w-full"
               class="w-full"
            />
         </div>
         <div class="flex items-center justify-between w-full">
            <button class="btn btn-cancel disabled w-full mb-2" @click.prevent="cancelInTerms()">
               Cancel
            </button>
         </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
      </div>
    </div>
</template>

<script>
import Spinner from '@/components/commons/ui/Spinner'

    export default{
        name: 'Parter-Accept-Form',
        components: { Spinner },
        data() {
          return {
            partnerPasswordForm: {},
            loading: true,
            errorMessage: '',
            isValidParter: false,
            passwordCreated: false,
            partnerId: null
          }
        },
        async mounted(){
          if(!this.partnerId){
            this.partnerId = this.$route.params['partnerId'];
            this.partnerId = this.partnerId ? this.partnerId:this.$route.params['partnerId'];
          }
          if(this.partnerId){
              await this.$store.dispatch('partner/getPartner', {id: this.partnerId})
              .then(partner => {
                if(partner){
                  if(partner.uid) window.location.href = '/login?message=Request is already accepted&state=error'
                  this.loading = false
                  this.isValidParter = true
                }else{
                  this.loading = false
                  this.errorMessage = 'No partners account found!'
                  window.location.href = '/login?message=partner not found&state=error'
                }
              }).catch(error => {
                  this.loading = false
                  this.errorMessage = error?.message
                  window.location.href = '/login?message=Invalid request&state=error'
              })
          }
        },
        methods: {
          async onSubmit(data){
            this.loading = true;
               let payload = {
                  id: this.partnerId, 
                  password: data.password,
                  confirmPassword: data.password_confirm
               }
               await this.$store.dispatch('partner/createPartnerPassword', payload)
               .then((partner) => {
                 if(partner){
                      try{
                      const acceptParter = this.$store.dispatch('partner/acceptPartner', this.partnerId)
                          this.loading = false
                          if(acceptParter){
                            this.passwordCreated = true
                            setTimeout(() => {
                              window.location.href = '/logout'
                            }, 3003);
                          }else{
                            this.errorMessage = 'Partner has already accepted the invitation'
                             window.location.href = '/login?message=Request already accepted'
                          }
                        }
                        catch(error){
                           this.loading = false
                            this.errorMessage = 'Something went wrong'
                            window.location.href = '/login?message=Something went wrong'
                        }
                  }
                }).catch(error => {
                    this.loading = false
                    this.errorMessage = error?.message;
                    console.log('message:', error);
                  //   window.location.href = '/'
              })
         },
         cancelInTerms(){
            window.location.href = '/'
         }
        }
    }
</script>