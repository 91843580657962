import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class Vehicle {
    // @Vehicle
    createVehicle(data){ // Create vehicle
        return API.ACCOUNTS.post(`/vehicle`, data)
    }
    getVehicle({ id = null, query }){ // Get single Vehicle by Id or Get all Vehicle
        if(id){
            return API.ACCOUNTS.get(`/vehicle/${id}`)
        }else{
            let filter = '';
            if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
            query.filter = filter;
            if(query) query = '?'+ObjectToQueryString(query);
            return API.ACCOUNTS.get(`/vehicle/${query}`)
        }
    }
    getVehicleByUID( uid,  query){
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/vehicle/find-by-uid/${uid}${query}`)
    }

    getVehicleByEntityId( entityId,  query){
        let filter = ''
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object' }
        query.filter = filter
        if(query) query = '?'+ObjectToQueryString(query)
        return API.ACCOUNTS.get(`/vehicle/find-by-entity/${entityId}${query}`)
    }

    getDuplicatedVehicleByEntityId( entityId) {
        return API.ACCOUNTS.get(`/vehicle/find-duplicated-by-entity/${entityId}`)
    }

    getDefectedVehicleByEntityId( entityId,  query){
        let filter = ''
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object' }
        query.filter = filter
        if(query) query = '?'+ObjectToQueryString(query)
        return API.ACCOUNTS.get(`/vehicle/find-defected-by-entity/${entityId}${query}`)
    }

    getVehicleByPartnerUID( uid,  query){
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/vehicle/find-by-partner-uid/${uid}${query}`)
    }
    getVehicleByResellerUID( uid,  query){
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/vehicle/find-by-reseller-uid/${uid}${query}`)
    }
    patchVehicle( data ){ // Update single Vehicle by Id
        return API.ACCOUNTS.patch(`/vehicle/${data.id}`, data)
    }
    deleteVehicle( id = null ){ // Delete Vehicle by Id
        return API.ACCOUNTS.delete(`/vehicle/${id}`)
    }

    activate( id ){ // Active disabled vehicle by id
        return API.ACCOUNTS.patch(`/vehicle/activate/${id}`)
    }

    deactivate( id ){ // Disable active vehicle by id
        return API.ACCOUNTS.patch(`/vehicle/deactivate/${id}`)
    }

    defect( id ){ // Set as defected vehicle by id
        return API.ACCOUNTS.patch(`/vehicle/defected/${id}`)
    }

    setNonDefect( id ){ // Set as non defect vehicle by id
        return API.ACCOUNTS.patch(`/vehicle/non-defected/${id}`)
    }

    mergeVehicles(data){ // Create vehicle
        return API.ACCOUNTS.post(`/vehicle/merge`, data)
    }

    updateOdometer( data ){ // Update single Vehicle Odometer by Id
        return API.ACCOUNTS.patch(`/vehicle/odometer/${data.id}`, data)
    }
}
