<template>
  <FormulateForm @submit="onNext">
    <div class="text-center text-2xl">
      RRP
    </div>
    <div class="mt-5">
      <div class="loading mt-32 h-32" v-if="loading">
        <ui-spinner class="mx-auto"></ui-spinner>
      </div>
      <div class="mt-5 overflow-x-auto">
        <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
          <table v-if="!loading" class="table-auto leading-normal p-4">
            <thead>
            <tr>
              <th class="bg-gray-100 w-2/5 text-left">
                Product
              </th>
              <th class="bg-gray-200">
                Monthly
              </th>
              <th class="bg-gray-200">
                Yearly
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(product,index) in values" :key="index" class="border-t-2">
              <td v-if="product" class="w-80 pl-5 pr-8 text-sm bg-gray-100">
                {{ ( product.name === 'EWD-FT')? 'EWD' :  product.name}}
              </td>
              <td class="pt-5 px-5 text-center">
                <div class="flex relative pb-9 h-40">
                  <span class="pt-2 w-0">$</span>
                  <FormulateInput
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0"
                      input-class="w-48 h-7 ml-3 text-right py-4 px-0 border outline-none"
                      error-behavior="live"
                      :value="parsePrice(product.rrp.monthly)"
                      :name="`${removeSpaces(product.name)}.rrp.monthly`"
                      v-on:input="perMonthInput($event,index,false,product.pricing.monthly)"
                      autocomplete="off"
                  />
                  <ul
                      ref="perMonthRef"
                      v-html="perMonthDesc[index]"
                      class="absolute mt-12 ml-6 opacity-90 text-xs list-disc text-left"
                  ></ul>
                </div>
              </td>
              <td class="pt-5 px-5 text-center">
                <div class="flex relative pb-9 h-40">
                  <span class="pt-2 w-0">$</span>
                  <FormulateInput
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0"
                      input-class="w-48 h-7 ml-3 text-right py-4 px-0 border outline-none"
                      error-behavior="live"
                      :value="parsePrice(product.rrp.yearly)"
                      :name="`${removeSpaces(product.name)}.rrp.yearly`"
                      v-on:input="yearlyPerMonthInput($event,index,false,product.pricing.yearly)"
                      autocomplete="off"
                  />
                  <ul
                      ref="yearlyPerMonthRef"
                      class="absolute mt-12 ml-8 opacity-90 text-xs list-disc text-left"
                      v-html="yearlyPerMonthDesc[index]"
                  ></ul>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
        <button class="btn col-span-2 btn-primary mt-2" :style="{backgroundColor: theme.secondaryColor}">Submit</button>
        <button @click.prevent="onPrevious" class="btn bg-transparent border col-span-2 btn-info mt-2">Back</button>
      </div>
    </div>
  </FormulateForm>
</template>
<script>
import _ from "lodash";
import { mapGetters } from 'vuex'

export default {
  name: 'Edit-Partner-RRP-Pricing',
  props: {
    detailsId:{
      type: String,
      default: null,
    },
    connectionType: {
      type: String,
      default: null,
    },
    preData: null,
    postData: null
  },
  data() {
    return {
      errorMessage: '',
      loading: false,
      values: {},
      perMonthDesc: [],
      yearlyPerMonthDesc: [],
      interChangingData: []
    };
  },
  mounted() {
    this.interChangingData = this.preData ? this.preData: this.postData
    this.values = this.interChangingData.pricing;

    this.values.forEach((value, index) => {
      this.perMonthInput(
          this.parsePrice(value.rrp.monthly),index,true,this.parsePrice(value.pricing.monthly)
      )
      this.yearlyPerMonthInput(
          this.parsePrice(value.rrp.yearly),index,true,this.parsePrice(value.pricing.yearly)
      )
    })
  },
  methods: {
    parsePrice(price){
      return parseFloat(price).toFixed(2);
    },

    parsePriceString(marginPercent, margenProfit, profit, price){
      if(parseFloat(price.RRP) >= parseFloat(price.buyPrice)){
        return `<li>${marginPercent}% Margin per month</li><li>$${profit} Margin per month</li><li>$${margenProfit} Per month per seat</li>`;
      } else {
        return `<div class="text-red-500">RRP must not lower than the product buy price.</div>`;
      }
    },

    perMonthInput(RRP, index, init, buyPrice){
      let percentage = this.parsePrice(((RRP-buyPrice)/RRP)*100);
      let margenProfit = this.parsePrice(RRP/1);
      const profit = this.parsePrice(RRP-buyPrice);

      const price = {
        RRP: RRP,
        buyPrice: buyPrice
      }

      if(init){
        this.perMonthDesc.push(this.parsePriceString(percentage,margenProfit,profit, price));
      } else {
        this.$parent.$children[0].$refs.perMonthRef[index].innerHTML
            = this.parsePriceString(percentage,margenProfit,profit, price);
      }
    },

    yearlyPerMonthInput(RRP, index, init, buyPrice){
      let percentage = this.parsePrice(((RRP-buyPrice)/RRP)*100);
      let margenProfit = this.parsePrice(RRP/12);
      const profit = this.parsePrice((RRP-buyPrice)/12);
      const price = {
        RRP: RRP,
        buyPrice: buyPrice
      }
      if(init){
        return this.yearlyPerMonthDesc.push(this.parsePriceString(percentage,margenProfit,profit, price));
      } else {
        this.$parent.$children[0].$refs.yearlyPerMonthRef[index].innerHTML
            = this.parsePriceString(percentage,margenProfit,profit, price)
      }
    },

    removeSpaces(string){
      return string.replace(/\s+/g, '');
    },

    onNext(formData) {
      this.loading = true;

      let buyPricing = [];
      let rrpPricing = [];
      let setValues = [];

      console.log("Values--",this.values)

      // format values for validation
      this.values.forEach((obj, ind) => {
        if(obj){
          let objData = Object(formData)
          let rrp = {
            monthly: this.parsePrice(objData[`${this.removeSpaces(obj.name)}.rrp.monthly`]),
            yearly: this.parsePrice(objData[`${this.removeSpaces(obj.name)}.rrp.yearly`]),
          }
          buyPricing.push({
            name: obj.name,
            monthly: this.interChangingData.pricing[ind].pricing.monthly,
            yearly: this.interChangingData.pricing[ind].pricing.yearly,
            isRrp: false
          });
          
          rrpPricing.push({
            name: obj.name,
            monthly: rrp.monthly,
            yearly: rrp.yearly,
            isRrp: true
          });

          const item = {
            name: obj.name,
            pricing: this.interChangingData.pricing[ind].pricing,
            rrp: rrp,
          }

          setValues.push(item);
        }
      });

      // Validate value
      let err = [];
      setValues.forEach(price => {
        if(price.name === price.name){
          if(parseFloat(price.pricing.monthly) > parseFloat(price.rrp.monthly)
              || parseFloat(price.pricing.yearly) > parseFloat(price.rrp.yearly)
          ){
            err.push(price.pricing.standardPricingMasterId)
          }
        }
      });

      // Validation toast
      if(!_.isEmpty(err)){
        this.$store.commit('setDialogNotify',true);
        this.$store.commit('setMessageNotify',{state:'error',message:'RRP must not lower than the product buy price.'});
        return;
      }

      const payload = {
        ownerId: this.detailsId,
        type: this.connectionType,
        pricing: [...buyPricing, ...rrpPricing],
      };

      console.log("Form Data--", payload)
      try {
        this.$store.dispatch(`standardpricing/updateAllStandardPricingByMongoId`, payload).then(() => {
          this.$emit('closeModal')
          this.$store.commit('setDialogNotify',true);
          this.$store.commit('setMessageNotify',{state:'success',message:'Pricing successfully updated.'});
          setTimeout(()=>{location.reload()},4000);
        });
      } catch (err) {
        this.$store.commit('setDialogNotify',true);
        this.$store.commit('setMessageNotify',{state:'error',message:err.message});
      }

      this.loading = false;
    },

    onPrevious() {
      const data = {...this.interChangingData, ...{ pricing: this.values }}
      this.$emit('backStep', data)
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>
<style scoped lang="scss">
th {
  @apply px-5 py-3 border-gray-200 text-sm font-semibold text-gray-600 uppercase;
}
</style>