<template>
  <div
    class="absolute right-0 top-0 bottom-0 w-80 h-screen"
    :style="{ backgroundColor: theme.primaryColor }"
  >
    <div
      class="relative p-3 h-screen z-50 bg-white"
      :style="{ backgroundColor: theme.primaryColor, color: 'white' }"
    >
      <div>
        <div class="flex justify-between items-center">
          <div class="text-l font-bold mb-8">Sidebar Theme settings</div>
          <font-awesome-icon
            @click="onCloseDynamicTheme"
            icon="times"
            class="mx-1.5 hover:text-orange-primary text-lg cursor-pointer"
            :style="{ color: theme.primaryColor }"
          />
        </div>
        <div class="mb-5">
          <div class="text-md font-bold mb-5">Menu</div>
          <div class="flex justify-between mb-3">
            <div class="mr-5 text-xs">Background Color</div>
            <!-- TODO: Create a component for color picker -->
            <Verte picker="square" model="hex" menuPosition="right" />
          </div>
          <div class="flex justify-between mb-3">
            <div class="mr-5 text-xs">Text Color</div>
            <!-- TODO: Create a component for color picker -->
            <Verte picker="square" model="hex" menuPosition="right">
              <svg viewBox="0 0 24 24">
                <path d="M0 20h24v4H0z" />
                <path
                  style="fill: #000"
                  d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
                />
              </svg>
            </Verte>
          </div>
        </div>

        <div class="border-b-2 w-full my-2"></div>

        <div class="mb-5">
          <div class="text-md font-bold mb-5">Footer</div>
          <div class="flex justify-between mb-3">
            <div class="mr-5 text-xs">Background Color</div>
            <!-- TODO: Create a component for color picker -->
            <Verte picker="square" model="hex" menuPosition="right" />
          </div>
          <div class="flex justify-between mb-3">
            <div class="mr-5 text-xs">Text Color</div>
            <!-- TODO: Create a component for color picker -->
            <Verte picker="square" model="hex" menuPosition="right">
              <svg viewBox="0 0 24 24">
                <path d="M0 20h24v4H0z" />
                <path
                  style="fill: #000"
                  d="M11 3L5.5 17h2.25l1.12-3h6.25l1.12 3h2.25L13 3h-2zm-1.38 9L12 5.67 14.38 12H9.62z"
                />
              </svg>
            </Verte>
          </div>
        </div>

        <div class="border-b-2 w-full my-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Verte from "verte";
import "verte/dist/verte.css";
import { mapGetters } from "vuex";

export default {
  name: "Right-Panel",
  components: { Verte },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  methods: {
    onCloseDynamicTheme() {
      this.$store.commit("theme/updateEnableDynamicTheme", false);
    },
  },
};
</script>
