<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="filledReportData"
      @onPageChange="onPageChange($event)"
    >
      <template #tableFilters>
        <div class="flex flex-row mb-1 sm:mb-0">
          <div>
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div>
            <select
              v-model="filter.isAcknowledged"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="filterStatus.value"
              >
                {{ filterStatus.label }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div>
            <input
              placeholder="Search"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
          <div>
            <DatePickerWithRange
              class="ml-2"
              ref="childDatePicker"
              @selected="handleDateChange"
              :date="filter.selectedDateRangeData"
            />
          </div>
          <div>
            <FormulateInput
              name="selectedDriver"
              type="driver-multiselect"
              placeholder="Select a driver"
              style="margin-bottom: 0px"
              @selected="onSelectDrivers"
            />
          </div>
        </div>
      </template>
      <template #callToActionTop>
        <div class="flex flex-row"></div>
        <div class="mr-2">
          <button
            class="btn btn-primary"
            :style="{ backgroundColor: theme.primaryColor }"
            @click.prevent="handleSummaryOrDetailedReportClick('summary')"
          >
            Summary Report
          </button>
        </div>
        <div class="mr-2">
          <button
            class="btn btn-primary"
            :style="{ backgroundColor: theme.primaryColor }"
            @click.prevent="handleSummaryOrDetailedReportClick('detailed')"
          >
            Detailed Report
          </button>
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewFilledReport(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                  ><font-awesome-icon
                    icon="clipboard"
                    class="my-auto mr-2"
                  />View</StyledIconedLink
                >
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="newInvestigation(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                  ><font-awesome-icon
                    icon="search"
                    class="my-auto mr-2"
                  />Investigate</StyledIconedLink
                >
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="listNonConformances(data.id, data.driverName)"
                  :iconHoverColor="theme.secondaryColor"
                  ><font-awesome-icon
                    icon="file-excel"
                    class="my-auto mr-2"
                  />Non Conformances</StyledIconedLink
                >
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="listPerformances(data.id, data.driverName)"
                  :iconHoverColor="theme.secondaryColor"
                  ><font-awesome-icon
                    icon="chart-bar"
                    class="my-auto mr-2"
                  />Performances</StyledIconedLink
                >
              </li>
              <li v-if="!data.isAcknowledged">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="acknowledgeReport(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                  ><font-awesome-icon
                    icon="handshake"
                    class="my-auto mr-2"
                  />Achknowledge</StyledIconedLink
                >
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>
    <Modal
      ref="modal"
      :title="modalTitle"
      size="3xl"
      :height="modalHeight"
      @closeModal="closeModal"
    >
      <div v-if="modalContent === 'view'">
        <FilledFormDetailsView :activeFilledForm="viewedFilledForm" />
      </div>
      <div v-if="modalContent === 'new'">
        <div class="w-full" v-if="activeForm">
          <div class="bg-white shadow-md rounded px-5 mt-4 pt-5 pb-8 mb-4">
            <h6
              class="font-medium text-center pb-3 leading-tight text-base mt-5 mb-3 text-blue-600"
            >
              {{ activeForm.name }}
            </h6>

            <section
              v-for="(section, index) in activeForm.sections"
              :key="index"
              class="mb-12"
            >
              <div class="mt-1 pb-3">
                <h6
                  class="text-large font-semibold text-left leading-tight inline-block align-middle"
                >
                  {{ section.headline }}
                </h6>
                <span v-if="section.subHeadline"> - </span
                ><small class="italic text-small inline-block pt-4">{{
                  section.subHeadline
                }}</small>
              </div>
              <hr />

              <div
                class="mt-5"
                v-for="(control, index) in section.controls"
                :key="index"
                :style="{ color: theme.primaryColor }"
              >
                <FieldContainer :component="control" />
              </div>
            </section>
          </div>
        </div>
      </div>
      <div v-if="modalContent === 'acknowledge'">
        <AcknowledgeForm
          :loggedInWebProfile="loggedInWebProfile"
          :filledFormType="type"
          :formId="formId"
          :businessId="businessId"
          @toast="toast"
        />
      </div>
      <div v-if="modalContent === 'newInvestigation'">
        <AddInvestigationRegister :selectedFilledForm="selectedFilledForm" />
      </div>
      <div v-if="modalContent === 'listI'">
        <InvestigationList
          :formId="formId"
          :driverName="driverName"
          @toast="toast"
          @handleSummaryOrDetailedReportClick="
            handleSummaryOrDetailedReportClick
          "
          :hasBackButton="hasBackButton"
        />
      </div>
      <div v-if="modalContent === 'listNC'">
        <NonConformanceList
          :formId="formId"
          :driverName="driverName"
          @toast="toast"
          :entityWebProfileId="loggedInWebProfile._id"
          :entityMongoId="businessId"
          :entityType="'business'"
          @handleSummaryOrDetailedReportClick="
            handleSummaryOrDetailedReportClick
          "
          :hasBackButton="hasBackButton"
        />
      </div>
      <div v-if="modalContent === 'listP'">
        <PerformanceList
          :formId="formId"
          :driverName="driverName"
          @handleSummaryOrDetailedReportClick="
            handleSummaryOrDetailedReportClick
          "
          :hasBackButton="hasBackButton"
        />
      </div>
      <div v-if="modalContent === 'summary'">
        <SummaryReport
          :driverIds="filter.selectedDrivers"
          :dateRange="filter.selectedDateRangeData"
          :filledFormType="type"
          :businessId="businessId"
        />
      </div>
      <div v-if="modalContent === 'detailed'">
        <DetailedReport
          :driverIds="filter.selectedDrivers"
          :dateRange="filter.selectedDateRangeData"
          :filledFormType="type"
          :businessId="businessId"
          @listNonConformances="listNonConformances"
          @listPerformances="listPerformances"
          @listInvestigations="listInvestigations"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Table, Modal, Dropdown } from "@/components/commons";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import FilledFormDetailsView from "@/components/view/business/forms/filled-forms/filled-form-view";
import FieldContainer from "@/views/builder/containers/FieldContainer.vue";
import { AcknowledgeForm } from "@/components/forms/business/";
import { AddInvestigationRegister } from "@/components/forms/business/investigation-register";
import { NonConformanceList } from "@/components/view/business/fatigue-management/non-conformance";
import { PerformanceList } from "@/components/view/business/fatigue-management/performance";
import { InvestigationList } from "@/components/view/business/fatigue-management/investigation";
import SummaryReport from "@/components/view/business/fatigue-management/forms/SummaryReport.vue";
import DetailedReport from "@/components/view/business/fatigue-management/forms/DetailedReport.vue";
import DatePickerWithRange from "@/components/commons/field/DatePickerWithRange";
import {
  formatDate,
  formatPaginationSettings,
  capitalizeFirstLetter,
} from "@/_helper";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";

import _ from "lodash";
import { mapGetters } from "vuex";
import moment from "moment";

import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Register-Table",
  components: {
    Table,
    Modal,
    FilledFormDetailsView,
    FieldContainer,
    AcknowledgeForm,
    DatePickerWithRange,
    AddInvestigationRegister,
    NonConformanceList,
    PerformanceList,
    SummaryReport,
    Dropdown,
    StyledIconedLink,
    DetailedReport,
    InvestigationList,
  },
  props: {
    type: String,
  },
  data() {
    return {
      modalContent: "",
      modalTitle: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      isLoading: false,
      tableHeader: [
        "Driver Name",
        "Email Address",
        "Licence",
        "Date Submitted",
        "Acknowledged",
        "Acknowledged By",
      ],
      filter: {
        limit: 10,
        isAcknowledged: -1,
        search: "",
        selectedDateRangeData: [],
        selectedDrivers: [],
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      filledReportRawData: [],
      filledReportFormattedItems: [],
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: [
        {
          label: "All",
          value: -1,
        },
        {
          label: "Acknowledged",
          value: true,
        },
        {
          label: "Not Acknowledged",
          value: false,
        },
      ],
      viewedFilledForm: null,
      activeForm: null,
      me: null,
      loggedInWebProfile: null,
      formId: null,
      businessId: null,
      selectedFilledForm: {},
      driverName: null,
      hasBackButton: false,
    };
  },
  async mounted() {
    this.init(undefined, undefined);
  },
  methods: {
    async init(paged = 1, selectedDateRangeData = []) {
      this.isLoading = true;
      const data = await this.getReportData(paged, selectedDateRangeData);

      this.filledReportRawData = [];
      this.filledReportFormattedItems = [];
      if (data) {
        this.paginationSettings = formatPaginationSettings(
          new Object(data["metadata"])
        );

        this.filledReportRawData = data.results;
        this.filledReportFormattedItems = this.formatItem(
          _.orderBy(data.results, ["createdAt"], ["desc"])
        );
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    async viewFilledReport(id) {
      this.$refs.modal.openModal();
      this.modalContent = "view";
      let data;
      switch (this.type) {
        case "incident":
          this.modalTitle = "Incident Details";
          data = await this.$store.dispatch(`incident/getIncidentById`, id);
          break;
        case "hazard":
          this.modalTitle = "Hazard Details";
          data = await this.$store.dispatch(`hazard/getHazardById`, id);
          break;
        case "defect":
          this.modalTitle = "Defect Details";
          data = await this.$store.dispatch(`defect/getDefectById`, id);
          break;
        case "infringement":
          this.modalTitle = "Infringement Details";
          data = await this.$store.dispatch(
            `infringement/getInfringementById`,
            id
          );
          break;
      }

      if (!_.isEmpty(data)) {
        this.viewedFilledForm = data;
      }
    },
    parseFilledReport(data) {
      return data.map((reportData) => [
        {
          id: reportData.id,
          name: reportData.name,
          itemType: "name",
          hasImage: false,
          isAcknowledged: reportData.isAcknowledged,
          driverName: reportData.name,
        },
        {
          id: reportData.id,
          name: reportData.email,
          itemType: "string",
          isAcknowledged: reportData.isAcknowledged,
          driverName: reportData.name,
        },
        {
          id: reportData.id,
          name: reportData.license,
          itemType: "string",
          isAcknowledged: reportData.isAcknowledged,
          driverName: reportData.name,
        },
        {
          id: reportData.id,
          name: reportData.date,
          itemType: "string",
          isAcknowledged: reportData.isAcknowledged,
          driverName: reportData.name,
        },
        {
          id: reportData.id,
          name: reportData.isAcknowledged,
          itemType: "boolean",
          isAcknowledged: reportData.isAcknowledged,
          driverName: reportData.name,
        },
        {
          id: reportData.id,
          name: reportData.acknowledgedBy,
          itemType: "string",
          isAcknowledged: reportData.isAcknowledged,
          driverName: reportData.name,
        },
      ]);
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            id: item._id,
            name: item?.driver?.driverName,
            email: item?.driver?.emailAddress,
            license: item?.driver?.driversLicenseNumber,
            phone: item?.driver?.supportPhone,
            date: formatDate(item?.savedData?.date),
            isAcknowledged: item.isAcknowledged ? item.isAcknowledged : false,
            acknowledgedBy:
              item.acknowledgedBy && item.acknowledgedBy.userName
                ? item.acknowledgedBy.userName
                : "N/A",
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page, undefined);
      }
    },
    async filterChange() {
      await this.init(undefined, undefined);
    },
    async handleDateChange(selectedDateRangeData) {
      await this.init(undefined, selectedDateRangeData);
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init(undefined, undefined);
    },
    async getReportData(paged, selectedDateRangeData) {
      this.me = this.$store.getters[`account/me`];
      this.loggedInWebProfile = this.me?.webProfile;
      const businessId = this.me?.business?._id;
      this.businessId = businessId;

      let formTemplates = await this.$store.dispatch("form/getFormByType", {
        businessId,
        formType: this.type,
      });

      if(formTemplates && formTemplates.length > 0) {
        this.activeForm = formTemplates.find((form) => {
          return form.isActivated;
        });
      }

      if (!this.activeForm || this.activeForm.length == 0) {
        [this.activeForm] = await this.$store.dispatch(
          "form/getFormMastersByType",
          this.type
        );
      }

      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.isAcknowledged != -1)
        query.filter = {
          ...query.filter,
          isAcknowledged: this.filter.isAcknowledged,
        };

      if (
        selectedDateRangeData &&
        selectedDateRangeData.length != 0 &&
        selectedDateRangeData.some((date) => date)
      ) {
        query.from = moment(selectedDateRangeData[0], "YYYY-MM-DD").format();
        query.to = moment(selectedDateRangeData[1], "YYYY-MM-DD").format();
        this.filter.selectedDateRangeData = [
          new Date(moment(selectedDateRangeData[0])),
          new Date(moment(selectedDateRangeData[1])),
        ];
      }

      if (
        this.filter.selectedDrivers &&
        this.filter.selectedDrivers.length != 0
      ) {
        query.filter = {
          ...query.filter,
          driverId: { $in: this.filter.selectedDrivers },
        };
      }

      switch (this.type) {
        case "incident":
          return await this.$store.dispatch(
            `incident/getIncidentByBusinessId`,
            { businessId, query }
          );
        case "hazard":
          return await this.$store.dispatch(`hazard/getHazardByBusinessId`, {
            businessId,
            query,
          });
        case "defect":
          return await this.$store.dispatch(`defect/getDefectByBusinessId`, {
            businessId,
            query,
          });
        case "infringement":
          return await this.$store.dispatch(
            `infringement/getInfringementByBusinessId`,
            { businessId, query }
          );
        default:
          return;
      }
    },
    handleReportClick() {
      // TODO: figure out how to create registers from portal
      return;
      // this.$refs.modal.openModal();
      // this.modalContent = "new";
      // switch (this.type) {
      //   case "incident":
      //     this.modalTitle = "Incident";
      //     break;
      //   case "hazard":
      //     this.modalTitle = "Hazard";
      //     break;

      //   case "defect":
      //     this.modalTitle = "Defect";
      //     break;

      //   case "infringement":
      //     this.modalTitle = "Infringement";
      //     break;

      //   default:
      //     return;
      // }
    },
    handleSummaryOrDetailedReportClick(reportType) {
      if (!this.filter.selectedDrivers || !this.filter.selectedDrivers.length) {
        alert("Please select drivers!");
        return;
      }

      if (
        !this.filter.selectedDateRangeData ||
        !this.filter.selectedDateRangeData.length
      ) {
        alert("Please select date range!");
        return;
      }

      this.modalContent = reportType;
      this.modalTitle = `${capitalizeFirstLetter(
        this.type
      )} ${capitalizeFirstLetter(reportType)} Report`;
      this.$refs.modal.openModal();
    },
    acknowledgeReport(formId) {
      this.modalContent = "acknowledge";
      this.modalTitle = `${capitalizeFirstLetter(this.type)} Acknowledgement`;
      this.formId = formId;
      this.$refs.modal.openModal();
    },
    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
    onSelectDrivers(drivers) {
      const driverIds = drivers.map((driver) => {
        return driver.driverId;
      });
      this.filter.selectedDrivers = driverIds;

      this.init(undefined, undefined);
    },
    newInvestigation(formId) {
      this.formId = formId;
      this.modalContent = "newInvestigation";
      this.modalTitle = "Create New Investigation";
      this.selectedFilledForm = {
        id: this.formId,
        type: capitalizeFirstLetter(this.type),
      };
      this.$refs.modal.openModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
    listNonConformances(formId, driverName, hasBackButton = false) {
      this.formId = formId;
      this.driverName = driverName;
      this.modalContent = "listNC";
      this.modalTitle = "Linked Non Conformances";
      this.hasBackButton = hasBackButton;
      this.$refs.modal.openModal();
    },
    listPerformances(formId, driverName, hasBackButton = false) {
      this.formId = formId;
      this.driverName = driverName;
      this.modalContent = "listP";
      this.modalTitle = "Linked Performances";
      this.hasBackButton = hasBackButton;
      this.$refs.modal.openModal();
    },
    listInvestigations(formId, driverName, hasBackButton = false) {
      this.formId = formId;
      this.driverName = driverName;
      this.modalContent = "listI";
      this.modalTitle = "Linked Investigations";
      this.hasBackButton = hasBackButton;
      this.$refs.modal.openModal();
    },
  },
  computed: {
    filledReportData() {
      if (this.filledReportFormattedItems) {
        return this.parseFilledReport(this.filledReportFormattedItems);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
