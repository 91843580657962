import {MODULE_CATEGORIES} from '../../../_helper/constants'
export default [
    {
        type: 'text',
        name: 'name',
        label: 'Name',
        validation: 'required',
        '@change': 'onNameChange'
    },
    {
        type: 'text',
        name: 'description',
        label: 'Description',
        validation: 'required'
    },
    {
        type: 'text',
        name: 'apiTag',
        label: 'API Tag',
        validation: 'required',
        help: "An identifier that is used in the API level. Tip: use default value."
    },
    {
        type: 'text',
        name: 'skuPrefix',
        label: 'SKU Prefix',
        validation: 'required',
        help: "An identifier that is used when invoicing clients. Tip: use default value."
    },
    {
        type: 'select',
        name: 'type',
        label: 'Type',
        options: [
            { value: 'partner', label: 'Partner' },
            { value: 'reseller', label: 'Reseller' },
        ],
        validation: 'required'
    },
    {
        type: 'select',
        name: 'category',
        label: 'Category',
        options: MODULE_CATEGORIES,
        validation: 'required'
    },
    {
        type: "group",
        component: 'div',
        class: 'grid grid-cols-2 gap-4',
        children: [
            {
                type: 'number',
                step: '0.01',
                min: '0',
                name: 'monthly',
                label: 'Monthly Price',
                validation: 'required|number'
            },
            {
                type: 'number',
                step: '0.01',
                min: '0',
                name: 'yearly',
                label: 'Yearly Price',
                validation: 'required|number'
            },
        ]
    },
    {
        type: "group",
        component: 'div',
        class: 'grid grid-cols-2 gap-4',
        children: [
            {
                type: 'number',
                step: '0.01',
                min: '0',
                name: 'monthlyRrp',
                label: 'Monthly RRP',
                validation: 'required|number'
            },
            {
                type: 'number',
                step: '0.01',
                min: '0',
                name: 'yearlyRrp',
                label: 'Yearly RRP',
                validation: 'required|number'
            },
        ]
    },
]