<template>
  <div>
      <div class="text-green-500 font-bold text-sm text-center mt-0 my-5" v-if="successMessage">
        {{successMessage}}
      </div>
      <div class="text-red-500 font-bold text-sm text-center mt-0 my-5" v-if="errorMessage">
        {{errorMessage}}
      </div>
      <FormulateForm name="changePassword" v-model="changePasswordForm" @submit="onSubmit">
        <FormulateInput
            type="password"
            name="currentPassword"
            label="Current Password"
            validation="^required"
        />
        <FormulateInput
            type="password"
            name="newPassword"
            label="New Password"
            validation="^required"
        />
        <FormulateInput
            type="password"
            name="newPassword_confirm"
            label="Confirm New Password"
            validation="required|confirm"
            error-behavior="submit"
        />
        <div class="flex items-center justify-between">
            <FormulateInput
                type="submit"
                label="Submit"
                input-class="btn btn-primary"
                :disabled="loading"
                :style="{backgroundColor: theme.secondaryColor}"
            />
            <div v-if="loading" class="text-center"><ui-spinner class="m-auto"></ui-spinner></div>
        </div>
      </FormulateForm>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'ChangePassword',
    data() {
        return {
            changePasswordForm: {},
            userId: '',
            uid:'',
            errorMessage: '',
            successMessage: '',
            loading: false
        }
    },
    async mounted() {
        const me = this.$store.getters[`account/me`];
        this.userId = me._id
        this.uid = me.uid
    },
    methods: {
        async onSubmit() {
            if(!this.uid) this.errorMessage = `Something went wrong please try again.`;

            this.loading = true;
            const payLoad = {
                uid:this.uid,
                current: this.changePasswordForm.currentPassword,
                password: this.changePasswordForm.newPassword,
                confirm: this.changePasswordForm.newPassword_confirm
            }
            try{
                let res =  await this.$store.dispatch(`account/changePassword`,payLoad);
                if(res.success){
                    this.successMessage = 'Password successfully updated.';
                    this.$formulate.reset('changePassword');
                    this.loading = false;
                }
            }catch( error ){
                this.error = true
                this.errorMessage = error.message;
                this.loading = false;
            }
        }
    },
     computed: {
      ...mapGetters('theme', {
      theme: 'getColorScheme'
      })
  }
}
</script>

<style>

</style>