<template>
  <FormulateForm @submit="onNext">
    <div class="loading mt-32 h-32" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading" class="text-center text-2xl">
      Buy Product Pricing
    </div>
    <div class="my-5 overflow-x-auto">
      <div class="inline-block min-w-full rounded-lg overflow-hidden">
        <table v-if="!loading" class="table-auto leading-normal p-4">
          <thead>
            <tr>
              <th class="bg-gray-100 w-2/5 text-left">
                Product
              </th>
              <th class="bg-gray-200">
                Monthly
              </th>
              <th class="bg-gray-200">
                Yearly
              </th>
            </tr>
          </thead>
          <tbody v-if="values">
            <tr v-for="(product,index) in values" :key="index" class="border-t-2">
              <td class="w-80 pl-5 pr-9 text-sm bg-gray-100">
                {{ ( product.name == 'EWD-FT')? 'EWD' :  product.name}}
              </td>
              <td class="pt-3 px-5 text-center">
                <div class="flex">
                  <span class="pt-1 pl-4 w-0">$</span>
                  <FormulateInput
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0"
                      class="p-0 w-36"
                      input-class="w-36 h-7 ml-3 text-right py-4 px-0 border outline-none"
                      error-behavior="live"
                      :value="parsePrice(product.pricing.monthly)"
                      :name="`${removeSpaces(product.name)}.pricing.monthly`"
                      autocomplete="off"
                    />
                </div>
              </td>
              <td class="pt-3 px-6 text-center">
                <div class="flex relative pt-2 pb-3">
                  <span class="pt-2 pl-4 w-0">$</span>
                  <FormulateInput
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0"
                      class="p-0 w-36"
                      input-class="w-36 h-7 ml-3 text-right py-4 px-0 border outline-none"
                      error-behavior="live"
                      :value="parsePrice(product.pricing.yearly)"
                      :name="`${removeSpaces(product.name)}.pricing.yearly`"
                      v-on:input="yearlyPerMonthInput($event, index, false)"
                      autocomplete="off"
                    />
                    <small ref="yearlyPerMonthRef" class="absolute mt-9 ml-4 opacity-90 w-full text-xs">
                      ({{yearlyPerMonthDesc[index] || `$0.00 Per Month`}})
                    </small>
                </div>
                <FormulateInput
                  type="hidden"
                  :value="parsePrice(product.rrp.monthly)"
                  :name="`${removeSpaces(product.name)}.rrp.monthly`"
                />
                <FormulateInput
                  type="hidden"
                  :value="parsePrice(product.rrp.yearly)"
                  :name="`${removeSpaces(product.name)}.rrp.yearly`"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr/><br/>
        <!-- <div class="mt-5">
          <div class="rounded-lg">
            <table v-if="!loading" class="table-auto shadow leading-normal">
            <tr>
              <td class="bg-gray-100 w-2/5 text-left pl-6 pr-2" rowspan="6">
                <p class="w-64 pl-1">Pre-paid EWD</p>
              </td>
              <th class="bg-gray-200">
                <p class="text-center"> Packs </p>
              </th>
              <th class="bg-gray-200">
                <p class="text-center"> Product Pricing </p>
              </th>
            </tr>
            <tr class="border-t-2">
              <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                1 Day
              </td>
              <td class="w-full pt-3 pr-7 text-center">
                <div class="flex relative">
                  <span class="pt-3 pl-11 w-0">$</span>
                    <FormulateInput
                      v-if="prepaidEWDPricing.oneDay"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0"
                      class="p-0 w-full	"
                      input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                      :value="parsePrice(prepaidEWDPricing.oneDay)"
                      name="pricingOneDay"
                    />
                  </div>
                </td>
                </tr>
                <tr class="border-t-2">
                  <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                    5 Days
                  </td>
                  <td class="w-full pt-3 pr-7 text-center">
                    <div class="flex relative">
                      <span class="pt-3 pl-11 w-0">$</span>
                      <FormulateInput
                        v-if="prepaidEWDPricing.fiveDays"
                        type="number"
                        step="0.01"
                        min="0"
                        placeholder="0"
                        class="p-0 w-full	"
                        input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                        :value="parsePrice(prepaidEWDPricing.fiveDays)"
                        name="pricingFiveDays"
                      />
                    </div>
                  </td>
                </tr>
                <tr class="border-t-2">
                    <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                      10 Days
                    </td>
                    <td class="w-full pt-3 pr-7 text-center">
                      <div class="flex relative">
                        <span class="pt-3 pl-11 w-0">$</span>
                        <FormulateInput
                          v-if="prepaidEWDPricing.tenDays"
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          class="p-0 w-full	"
                          input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                          :value="parsePrice(prepaidEWDPricing.tenDays)"
                          name="pricingTenDays"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="border-t-2">
                    <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                      25 Days
                    </td>
                    <td class="w-full pt-3 pr-7 text-center">
                      <div class="flex relative">
                        <span class="pt-3 pl-11 w-0">$</span>
                        <FormulateInput
                          v-if="prepaidEWDPricing.twentyFiveDays"
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          class="p-0 w-full	"
                          input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                          :value="parsePrice(prepaidEWDPricing.twentyFiveDays)"
                          name="pricingTwentyFiveDays"
                        />
                      </div>
                    </td>
                  </tr>
                  <tr class="border-t-2">
                    <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                      50 Days
                    </td>
                    <td class="w-full	pt-3 pr-7 text-center">
                      <div class="flex relative">
                        <span class="pt-3 pl-11 w-0">$</span>
                        <FormulateInput
                          v-if="prepaidEWDPricing.fiftyDays"
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          class="p-0 w-full	"
                          input-class="h-7 ml-3 mt-1 text-right py-4 px-0 border outline-none"
                          :value="parsePrice(prepaidEWDPricing.fiftyDays)"
                          name="pricingFiftyDays"
                        />
                    </div>
                  </td>
                </tr>
            </table>
          </div>
        </div> -->
      </div>
    </div>
    <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
      <button v-if="!loading" class="btn col-span-2 btn-primary mt-2" :style="{backgroundColor: theme.secondaryColor}">Next</button>
      <button @click.prevent="onPrevious" class="btn bg-transparent border col-span-2 btn-info mt-2">Back</button>
    </div>
  </FormulateForm>
</template>
<script>
import _ from "lodash";
import { mapGetters } from 'vuex'

export default {
  name: 'Add-Standard-Pricing',
  props: {
    msg: String,
    preData: null,
    postData: null
  },
  data() {
    return {
      errorMessage: '',
      loading: false,
      values: {},
      // prepaid: {},
      prepaidEWDPricing: {},
      prepaidEWDPricingWatch: null,
      prepaidEWDRrp: {},
      standardPricingMasterId: null,
      prepaidEWDName: null,
      yearlyPerMonthDesc: [],
      interChangingData: []
    };
  },
  async mounted(){
    this.loading = true
    let masterPricing;

    this.interChangingData = this.preData ? this.preData: this.postData

    if(!_.isEmpty(this.interChangingData?.pricing)){
      masterPricing = this.interChangingData.pricing;

    } else {
      switch (this.interChangingData.connectionType) {
        case 'partner':
          masterPricing = await this.$store.dispatch(`standardpricing/getStandardPricingMasterPartner`);
          break;
        case 'reseller':
          masterPricing = await this.$store.dispatch(`standardpricing/getStandardPricingMasterReseller`);
          break;
      }
    }
    if(masterPricing){
      // first arg null default

      // this.prepaid = this.parsePrepaidModules(this.interChangingData?.prepaid, masterPricing);
      // this.prepaidEWDPricing = this.prepaid?.pricing;
      // this.prepaidEWDRrp = this.prepaid?.rrp;
      // this.prepaidEWDName = this.prepaid?.name;
      this.values = this.parseModules(_.orderBy(masterPricing,['_id'],['asc']));
      this.loading = false;
    }
  },
  methods: {
    parsePrice(price){
      return parseFloat(price).toFixed(2)
    },

    parseModules(arr){
      return arr.filter(item => (item.name != 'EWD-PT') && (item.name != 'Pre-Paid EWD'));
    },

    // parsePrepaidModules(newPrepaid = null, masterPricing){
    //   let obj = {};

    //   // New prepaid set by the user
    //   if(newPrepaid){
    //     obj = newPrepaid;
    //     this.standardPricingMasterId = obj.standardPricingMasterId;
    //   }
    //   else {
    //     if(masterPricing){
    //       // Prepaid set by master
    //       obj = masterPricing.filter(item => item.name == 'Pre-Paid EWD')[0];
    //       this.standardPricingMasterId = obj._id;
    //     }
    //   }
    //   return obj;
    // },

    yearlyPerMonthInput(input, index, init){
      let price = this.parsePrice(input/12);
      if(init){
        return this.yearlyPerMonthDesc.push(`$${price || 0.00} Per Month`);
      } else {
        this.$parent.$children[0].$refs.yearlyPerMonthRef[index].innerHTML = `$${price || 0.00} Per Month`;
      }
    },

    removeSpaces(string){
      return string.replace(/\s+/g, '');
    },

    onNext(formData) {
      let setValues = [];
      this.values.forEach((obj) => {
        if(obj){
          let data = Object(formData)
          const pricing = {
            monthly: this.parsePrice(data[`${this.removeSpaces(obj.name)}.pricing.monthly`]),
            yearly: this.parsePrice(data[`${this.removeSpaces(obj.name)}.pricing.yearly`])
          }
          let rrp = {
            monthly: this.parsePrice(data[`${this.removeSpaces(obj.name)}.rrp.monthly`]),
            yearly: this.parsePrice(data[`${this.removeSpaces(obj.name)}.rrp.yearly`])
          }

          const dto = {
            name: obj.name,
            standardPricingMasterId: obj.standardPricingMasterId ? obj.standardPricingMasterId : obj._id, // optional either from user or master
            pricing: pricing,
            rrp: rrp
          }
          setValues.push(dto);
        }
      });

      // let prepaidData = formData;
      // const prepaidPricing = {
      //   oneDay: this.parsePrice(prepaidData.pricingOneDay),
      //   fiveDays: this.parsePrice(prepaidData.pricingFiveDays),
      //   tenDays: this.parsePrice(prepaidData.pricingTenDays),
      //   twentyFiveDays:this.parsePrice(prepaidData.pricingTwentyFiveDays),
      //   fiftyDays: this.parsePrice(prepaidData.pricingFiftyDays)
      // }

      // let prepaid = {
      //   pricing: prepaidPricing,
      //   rrp: this.prepaidEWDRrp,
      //   standardPricingMasterId: this.standardPricingMasterId,
      //   name: this.prepaidEWDName
      // }

      const data = {...this.interChangingData, ...{
        pricing: setValues,
        // prepaid: prepaid
      }}
      this.$emit('nextStep', data);
    },

    onPrevious() {
      const data = {...this.interChangingData, ...{
        pricing: this.values,
        // prepaid: this.prepaid
      }}

      this.$emit('backStep', data)
    },
  },
   computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
   }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  th {
    @apply px-5 py-3 border-gray-700 text-sm font-semibold text-gray-600 uppercase;
  }
</style>