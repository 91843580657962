<template>
  <div>
    <div class="text-center text-lg font-semibold">Server Status <font-awesome-icon icon="circle" v-bind:class="[overall ? 'text-green-500' : 'text-red-500']" class="ml-1 text-sm" /></div>
    <div v-for="(server, index) in status" :key="index">
      <div class="flex justify-between px-2 py-1">
        <p class="flex text-gray-700">
          <font-awesome-icon icon="circle" v-bind:class="[server.status ? 'text-green-500' : 'text-red-500']" class="text-xs my-auto mr-3" />
          {{index}}
        </p>
      </div>
  </div>
  </div>
</template>

<script>
import { serverStatus } from '@/api/helper'
import _ from 'lodash'

export default {
  name: 'ServerInfo',
  props: {
    title: String
  },
  data(){
    return { status: {}, overall: false}
  },
  async mounted(){
    this.status = await serverStatus();
    if(!_.find(this.status, { status : false})) this.overall = true
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
