<template>
  <div>
    <div>
      <div v-if="loading" class="loading items-center">
        <ui-spinner class="mx-auto mb-3 text-center"></ui-spinner>
      </div>
      <div v-if="!loading">
        <div class="text-center">
          <div
            v-for="(data, index) in chainOfActions"
            :key="data.sourceEntity.id"
          >
            <div
              class="flex-col"
              :style="{
                color: !data.isTarget
                  ? theme.primaryColor
                  : theme.secondaryColor,
              }"
            >
              <div class="text-xs">
                {{ formatDate(data.sourceEntity.createdAt) }}
              </div>
              <div
                class="font-bold cursor-pointer hover:text-orange-primary"
                @click.prevent="handleActionClick(data)"
              >
                {{ getSource(data.source) }}
              </div>
            </div>

            <div v-if="index !== chainOfActions.length - 1" class="mb-5">
              <font-awesome-icon icon="arrow-down" class="text-black-400" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal
      ref="modal"
      :title="modalTitle"
      :size="modalSize"
      :bodyHeight="modalHeight"
    >
      <div v-if="modalContent === 'viewNonConformance'">
        <NonConformanceModal
          :propsDocument="nonConformance"
          :entityWebProfileId="entityWebProfileId"
          :entityMongoId="entityMongoId"
          :entityType="entityType"
          @toast="toast"
        />
      </div>
      <div v-if="modalContent == 'viewInvestigation'">
        <ViewInvestigationRegisterDetails
          @addDocuments="addDocuments"
          :activeInvestigation="investigation"
        />
      </div>
      <div v-if="modalContent == 'addInvestigationDocuments'">
        <AddInvestigationDocumets
          @backToViewDocs="viewInvestigationFromAddDocsModal"
          :activeInvestigation="investigation"
          :isFromAddDocs="false"
        />
      </div>
      <div v-if="modalContent === 'viewPerformance'">
        <ViewPerformance :propsPerformance="performance" />
      </div>
      <div v-if="modalContent === 'viewRegister'">
        <FilledFormDetailsView :activeFilledForm="register" />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal } from "@/components/commons";
import ViewPerformance from "@/components/view/view-performance";
import NonConformanceModal from "@/components/view/view-non-conformance";
import ViewInvestigationRegisterDetails from "@/components/view/business/investigation-register/investigation-register-details";
import FilledFormDetailsView from "@/components/view/business/forms/filled-forms/filled-form-view";
import { capitalizeFirstLetter, formatDate } from "@/_helper";
import { mapGetters } from "vuex";
import { AddInvestigationDocumets } from "@/components/forms/business/investigation-register/";
export default {
  name: "Chain-Of-Actions-Modal",
  components: {
    Modal,
    ViewPerformance,
    NonConformanceModal,
    ViewInvestigationRegisterDetails,
    FilledFormDetailsView,
    AddInvestigationDocumets,
  },
  data() {
    return {
      loading: false,
      chainOfActions: [],
      modalSize: "2xl",
      modalTitle: "",
      modalContent: "",
      modalHeight: null,
      nonConformance: null,
      investigation: null,
      performance: null,
      register: null,
      entityWebProfileId: null,
      entityMongoId: null,
      entityType: null,
      isFromAddDocs: false,
    };
  },
  props: {
    entityId: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  mounted() {
    const me = this.$store.getters["account/me"];

    this.entityMongoId = me.entity._id;
    this.entityType = this.me?.role?.name;

    if (me?.entity?._id && me.entity.webProfiles.length) {
      this.entityWebProfileId = me.entity.webProfiles.find((profile) => {
        return profile.uid === me.uid;
      })?._id;
    }
    this.fetchChainOfActions();
  },

  methods: {
    async fetchChainOfActions() {
      this.loading = true;
      try {
        switch (this.type) {
          case "investigation":
            this.chainOfActions = await this.$store.dispatch(
              "investigation/getChainOfActions",
              this.entityId
            );
            break;

          case "performance":
            this.chainOfActions = await this.$store.dispatch(
              "performance/getChainOfActions",
              this.entityId
            );
            break;

          case "nonConformance":
            this.chainOfActions = await this.$store.dispatch(
              "report/getNonConformanceChainOfActions",
              this.entityId
            );
            break;
        }
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.toast("error", "Something Went Wrong");
      }
    },
    getSource(source) {
      if (source.toLowerCase() === "nonconformance") {
        return "Non Conformance";
      }
      return capitalizeFirstLetter(source);
    },

    formatDate(date) {
      return formatDate(date, "DD/MM/YYYY");
    },

    handleActionClick(data) {
      const { source, sourceEntity } = data;

      this.$refs.modal.openModal();

      switch (source) {
        case "nonConformance":
          this.modalContent = "viewNonConformance";
          this.modalTitle = "Non Conformance Document Details";
          this.nonConformance = sourceEntity;
          break;
        case "performance":
          this.modalContent = "viewPerformance";
          this.modalTitle = "Performance Document Details";
          this.performance = sourceEntity;
          break;
        case "investigation":
          this.modalContent = "viewInvestigation";
          this.modalTitle = "Investigation Details";
          this.investigation = sourceEntity;
          break;
        default:
          this.modalContent = "viewRegister";
          this.modalTitle = `${capitalizeFirstLetter(source)} Details`;
          this.register = sourceEntity;
          break;
      }
    },

    toast(type, message) {
      this.$emit("toast", type, message);
    },

    async addDocuments() {
      this.$refs.modal.openModal();
      this.modalContent = "addInvestigationDocuments";
      this.modalTitle = "Add Documents";
    },

    viewInvestigationFromAddDocsModal() {
      this.$refs.modal.openModal();
      this.modalContent = "viewInvestigation";
      this.modalTitle = "Investigation Details";
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
