var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-16"},[_c('div',{staticClass:"font-bold text-lg mb-5",class:[_vm.isPassed ? 'text-green-500' : 'text-red-500']},[_vm._v(" "+_vm._s(_vm.isPassed ? "FIT" : "UNFIT")+" TO DRIVE ")]),_c('div',{staticClass:"w-full"},_vm._l((_vm.sections),function(section,i){return _c('section',{key:i,staticClass:"mb-3"},[(section.value)?_c('div',_vm._l((section.value),function(control,ia){return _c('div',{key:ia,staticClass:"mb-3"},[(!_vm.isPillet(control?.value?.value))?_c('div',[(
                  control.component == _vm.formControls.CHECKBOX ||
                  control.component == _vm.formControls.RADIO
                )?_c('div',[_c('div',{staticClass:"mb-1"},[_c('div',{staticClass:"font-bold text-black-primary text-base"},[_vm._v(" "+_vm._s(control.value?.value)+"  "),(!control.value?.isIssue)?_c('span',[_c('font-awesome-icon',{staticClass:"text-1xl text-green-400",attrs:{"icon":"check-circle"}})],1):_c('span',[_c('font-awesome-icon',{staticClass:"text-1xl text-red-600",attrs:{"icon":"times-circle"}})],1)]),_c('div',{staticClass:"text-xs"},[_vm._v(_vm._s(control.label))])])]):_vm._e()]):_vm._e()])}),0):_vm._e(),_c('br'),_c('hr')])}),0),_c('div',{staticClass:"w-full"},_vm._l((_vm.sections),function(section,j){return _c('div',{key:j,staticClass:"mb-3"},[(section.value)?_c('div',{staticClass:"mb-3"},_vm._l((section.value),function(control,ja){return _c('div',{key:ja},[(
                control.component == _vm.formControls.CHECKBOX ||
                control.component == _vm.formControls.RADIO
              )?_c('div',[(_vm.isPillet(control?.value?.value))?_c('div',{staticClass:"mb-2 flex items-center"},[_c('div',{staticClass:"option-pill-view"},[(control.value?.isIssue)?_c('div',{staticClass:"bg-red-400 text-white w-11 text-center"},[_vm._v(" "+_vm._s(control?.value?.value)+" ")]):_c('div',{staticClass:"bg-green-400 text-white w-11 text-center"},[_vm._v(" "+_vm._s(control?.value?.value)+" ")]),_c('div',{staticClass:"bg-gray-200"},[_vm._v(" -  ")])]),_c('div',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(control.label)+" ")])]):_vm._e()]):_vm._e()])}),0):_vm._e(),_c('br'),_c('hr')])}),0),_c('template',{slot:"collapseHeader"},[_c('div',{staticClass:"flex items-center font-bold"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"icon-container mr-2 bg-gray-700"},[_c('font-awesome-icon',{staticStyle:{"height":"15px","width":"15px"},attrs:{"icon":"file"}})],1),_vm._m(0)])]),_c('div',{staticClass:"ml-auto cursor-pointer"},[_c('font-awesome-icon',{staticClass:"text-2xl",attrs:{"icon":_vm.collapsed ? 'caret-down' : 'caret-right'},on:{"click":function($event){return _vm.onCollapsed()}}})],1)])]),[_c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"w-full text-left items-start pt-7 mr-2"},[_c('span',{staticClass:"pl-6 text-sm"},[_vm._v(_vm._s(_vm.cleanDateFormat(_vm.savedData?.date)))]),_c('div',{staticClass:"w-40 border-t mt-1 pt-1 text-center text-xs"},[_vm._v(" Date submitted ")])]),_c('div',{staticClass:"w-full text-right flex flex-col items-end mr-2"},_vm._l((_vm.sections),function(section,l){return _c('section',{key:l,staticClass:"w-full text-right flex flex-col items-end"},[(section.value)?_c('div',{staticClass:"mb-3"},_vm._l((section.value),function(control,la){return _c('div',{key:la},[(
                    control.component == _vm.formControls.SIGNATURE ||
                    control.component == _vm.formControls.IMAGE
                  )?_c('div',{staticClass:"mt-5"},[_c('img',{staticClass:"border-2 w-60 bg-gray-700 h-32",attrs:{"src":control.value,"alt":control.label}}),_c('div',{staticClass:"text-left mt-1 text-xs"},[_vm._v(" "+_vm._s(control.label)+" ")])]):_vm._e()])}),0):_vm._e(),_c('br'),_c('hr')])}),0)])]],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col"},[_c('div',{staticClass:"flex-auto"},[_vm._v("Fitness Declaration Consent")])])
}]

export { render, staticRenderFns }