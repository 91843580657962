import { Investigation } from "@/api";
import * as _ from "lodash";

export default {
  async createInvestigation({ state }, payload) {
    if (!payload || _.isEmpty(payload)) throw "Invalid data " + state.me;
    try {
      const response = await Investigation.createInvestigation(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getInvestigation({ state }, payload) {
    try {
      const response = await Investigation.getInvestigation(payload);
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response.data.data;
      }
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },

  async getInvestigationsByBusinessId(_, param) {
    if (typeof param === "string")
      param = { businessId: param, query: { skip: 0, limit: 999 } };
    if (!param.businessId) throw "Invalid UID or ID";
    try {
      const response = await Investigation.getInvestigationsByBusinessId(
        param.businessId,
        param.query
      );
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response.data.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async getInvestigationsByDateRange({ state }, payload) {
    try {
      const response = await Investigation.getInvestigationsByDateRange(
        payload
      );
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response.data.data;
      }
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },

  async updateInvestigation({ state }, payload) {
    if (!payload || _.isEmpty(payload)) throw "Invalid data " + state.me;
    try {
      const response = await Investigation.updateInvestigation(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async reOpenInvestigation({ state }, payload) {
    if (!payload || _.isEmpty(payload)) throw "Invalid data " + state.me;
    try {
      const response = await Investigation.reOpenInvestigation(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async finalise({ state }, payload) {
    if (!payload || _.isEmpty(payload)) throw "Invalid data " + state.me;
    try {
      const response = await Investigation.finalise(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getByRegisterId({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Investigation.getByRegisterId(payload);
      return response.data;
    } catch (error) {
      console.log("error:", error);
      throw error.response.data;
    }
  },

  async getCountByRegisterType({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Investigation.getCountByRegisterType(payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getSummaryReport({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Investigation.getSummaryReport(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getChainOfActions({ state }, id) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Investigation.getChainOfActions(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
