<template>
  <div class="mb-16">
    <Table
        :isLoading="loading"
        :tableHeader="tableHeader"
        :data="trainingData"
    >
      <template #default="{ data }">
        <td class="px-5 py-2">
          <div class="text-black-primary whitespace-no-wrap text-lg flex">
            <StyledIconedLink
                class="flex cursor-pointer mx-1.5"
                :iconColor="data.statusColor"
                :iconHoverColor="theme.secondaryColor"
            >
              <font-awesome-icon
                  icon="circle"
                  class="my-auto mx-1.5"
              />
            </StyledIconedLink>
          </div>
        </td>
      </template>
    </Table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { formatDate } from "@/_helper";
import { Table } from '@/components/commons'
import { StyledIconedLink } from '@/styled-components'
import { DRIVER_TRAINING_STATUSES } from "@/_helper/constants";

export default {
  components: { Table, StyledIconedLink },
  props: {
    driverMongoId: String,
  },
  data() {
    return {
      trainings: [],
      loading: false,
      tableHeader: [
        'Training Name',
        'Status',
        'Last Completed Date'
      ],
    }
  },
  async mounted() {
    this.loading = true;
    await this.init();
    this.loading = false;
  },
  methods: {
    async init() {
      let trainings = await this.$store.dispatch(`driver/getTrainingStatus`, this.driverMongoId);
      if(trainings) {
        this.trainings = this.formatItem(trainings);
      }
    },

    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          if(item?.status) {
            switch (item?.status) {
              case DRIVER_TRAINING_STATUSES.FINISHED:
                item.statusColor = "#00FF00";
                item.status = "Finished";
                break;
              case DRIVER_TRAINING_STATUSES.YET_FINISHED:
                item.statusColor = "#FF9900";
                item.status = "Yet Finished"
                break;
              case DRIVER_TRAINING_STATUSES.NOT_FINISHED:
                item.statusColor = "#C00000";
                item.status = "Not Finished"
                break;
            }
          }
          const driver = {
            id: item?._id,
            name: item?.name,
            status: item?.status || "Not Finished",
            statusColor: item?.statusColor || "#C00000",
            lastCompletedDate: item?.lastCompletedDate? formatDate(item?.lastCompletedDate, 'YYYY-MM-DD') : "N/A",
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },

    parseTrainings(data) {
      return data.map((training) => [
        {
          id: training.id,
          name: training.name,
          itemType: 'name',
          statusColor: training.statusColor,
        },
        {
          id: training.id,
          name: training.status,
          itemType: 'string',
        },
        {
          id: training.id,
          name: training.lastCompletedDate,
          itemType: 'string',
        },
      ]);
    },

  },

  computed: {
    trainingData() {
      if (this.trainings) {
        return this.parseTrainings(this.trainings);
      } else {
        return [];
      }
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>