<template>
  <Stepper
    :stepperSteps="stepperSteps"
    @completed-step="completeStep"
    @active-step="isStepActive"
    :showNavigation="false"
    @closeModal="closeModal"
  />
</template>
<script>
import { Stepper } from "@/components/commons";
import {
  DriverLookup,
  InvestigationDetails,
  LinkingData,
  Summary,
} from "./stepper-component";

export default {
  name: "Add-Investigation-Register-Form",
  components: { Stepper },
  props: {
    selectedPerformance: {
      type: Object,
      default: () => {},
    },
    // selectedFilledForm is either incidents, hazards, defects or infringements.
    selectedFilledForm: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    selectedPerformance: {
      deep: true,
      handler() {
        this.setActivePerformance();
      },
    },
    selectedFilledForm: {
      deep: true,
      handler() {
        this.setActiveForm();
      },
    },
  },
  created() {
    this.setActivePerformance();
    this.setActiveForm();
  },
  data() {
    return {
      stepperSteps: [
        {
          stepIcon: "search",
          stepTitle: "Lookup",
          stepSequence: "first",
          stepComponent: DriverLookup,
          isCompleted: true,
          performance: null,
          filledForm: null,
        },
        {
          stepIcon: "file",
          stepTitle: "Investigation Details",
          stepSequence: "second",
          stepComponent: InvestigationDetails,
          isCompleted: false,
          performance: null,
          filledForm: null,
        },
        {
          stepIcon: "link",
          stepTitle: "Link Data",
          stepSequence: "third",
          stepComponent: LinkingData,
          isCompleted: false,
          performance: null,
          filledForm: null,
        },
        {
          stepIcon: "file",
          stepTitle: "Summary",
          stepSequence: "fourth",
          stepComponent: Summary,
          isCompleted: false,
          performance: null,
          filledForm: null,
        },
      ],
      activeStep: 0,
    };
  },
  methods: {
    completeStep(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          step.isCompleted = true;
        }
      });
    },
    isStepActive(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          if (step.isCompleted === true) {
            step.isCompleted = false;
          }
        }
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    setActivePerformance() {
      this.stepperSteps[0].performance = this.selectedPerformance;
    },
    setActiveForm() {
      this.stepperSteps[0].filledForm = this.selectedFilledForm;
    },
  },
};
</script>
