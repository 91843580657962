export default [
  {
    type: 'text',
    name: 'linkName',
    label: 'Link Name',
    validation: 'required'
  },
  {
    type: 'text',
    name: 'link',
    label: 'Link',
    validation: 'required'
  }
]