<template>
  <div
    class="shadowed absolute w-96 right-0 px-5 py-2 mt-3 bg-white"
    v-click-outside="onClickOutside"
  >
    <div
      class="w-full text-sm font-medium font-bold"
      :style="{color: theme.primaryColor}"
    >
      Dashboard Settings
    </div>

    <!-- <div class="flex my-5 items-center justify-between">
      <p>Dark Mode</p>
      <Toggle :componentProps="{vModel: isDarkModeValue}" @change="onChange" />
    </div> -->

    <!-- <div v-if="enableLogoUpload" class="flex my-5 items-center justify-between">
      <p>Upload Logo</p>
      <font-awesome-icon
        icon="image"
        class="text-2xl cursor-pointer"
        @click="showUploadImage()"
      />
    </div> -->

     <!-- <div class="flex my-5 items-center justify-between">
      <p>Enable Dynamic Theme</p>
      <Toggle :componentProps="{vModel: isDarkModeValue}" @change="onUpdateDynamicTheme" />
    </div> -->

    <!-- TODO: Remove under development component once the feature is delivered-->

    <div class=" mb-3 flex">
      <div class="flex flex-col">
        <div class="flex items-center justify-between pt-2">
          <p>Dashboard Color Theme</p>
        </div>
        <ColorThemeSelect :options="colorOptions" :value="activeColorOptions" @onChange="onChangeTheme"/>
      </div>
    </div>

    <!-- Feature under development -->
    <!-- <UnderDevelopment :hasLogo="false">
          <template slot="content">
              <div class="flex my-5 p-2">
                <div> -->
    <!-- <a class="font-bold" href="#" :style="{color: theme.primaryColor}">Dashboard Customization -->
    <!-- <a class="font-bold text-gray-600 pointer-events-none">Dashboard Customization
                  <font-awesome-icon icon="external-link-alt"/> 
                  </a>
                </div>
              </div>
          </template>
        </UnderDevelopment> -->
  </div>
</template>

<script>
  // import Toggle from "@/components/commons/ui/toggle/Toggle.vue";
  // import UnderDevelopment from '@/components/commons/ui/overlays/UnderDevelopment.vue'
  import { ENABLED_LOGO_UPLOAD_ACCOUNT } from "@/_helper/constants";
  import ColorThemeSelect from '@/components/commons/ui/select/color-select'

  export default {
    name: "Dashboard-Settings",
    components: { ColorThemeSelect },
    data() {
      return {
        enableLogoUpload: false,
        accountDetails: null,
        colorOptions: []
      };
    },
    async mounted() {
      this.accountDetails = await this.$store.getters["account/me"];
      const themeOptions = await this.$store.dispatch('theme/getThemeListsById', this.accountDetails.uid)
      this.colorOptions = themeOptions
      if (this.accountDetails.role.name) {
        console.log('THEME OPTIONS')
        this.enableLogoUpload = ENABLED_LOGO_UPLOAD_ACCOUNT.includes(
          this.accountDetails.role.name
        );
      }

    },
    methods: {
      onChange({ checked }) {
        if(checked){
          const darkModeTheme = this.themeOptions.find((theme) => theme.isDarkMode)
          this.$store.dispatch("theme/updateTheme", {accountId: this.accountDetails.uid, themeData: darkModeTheme})
          localStorage.setItem('activeTheme', JSON.stringify(darkModeTheme))

        }else{
          const previousTheme = this.themeOptions.find((theme) => theme._id === this.themeId)
          this.$store.dispatch("theme/updateTheme", {accountId: this.accountDetails.uid, themeData: previousTheme})
          localStorage.setItem('activeTheme', JSON.stringify(previousTheme))
        }
      },
      onUpdateDynamicTheme({checked}){
          //  Update the theme to the hardcoded dark mode theme
          this.$store.commit("theme/updateEnableDynamicTheme", checked)
      },
      onClickOutside() {
        this.$emit("toggleSetting");
      },
      showUploadImage() {
        this.$emit("toggleSetting");
        this.$store.dispatch(
          "modal/updateActiveModalData",
          JSON.parse(
            JSON.stringify({ name: true, height: "80vh", title: "Upload Logo" })
          )
        );
      },
     onChangeTheme(themeData){
        console.log('I WAS EMITTED --->', themeData)
        // Update theme in local storage here
        localStorage.setItem('activeTheme', JSON.stringify(themeData))
        this.$store.commit('theme/updateActiveTheme', themeData)
        // this.$store.dispatch('theme/updateTheme', {accountId: this.accountDetails.uid, themeData})  // is this even needed? why update on the backend 

      }
    },
    computed: {
      isDarkModeValue(){
        return this.$store.getters['theme/getIsDarkMode']
      },
      isEnableDynamicTheme(){
        return this.$store.getters['theme/getIsEnableDynamicTheme']
      },
      theme(){
        return this.$store.getters['theme/getColorScheme']
      },
      themeOptions(){
        return this.$store.getters['theme/getThemeLists']
      },
      activeColorOptions(){
        return this.$store.getters['theme/getActiveTheme']
      },
      // colorOptions(){
      //   return this.$store.getters['theme/getColorOptions']
      // },
      themeId(){
        return this.$store.getters['settings/getThemeId']
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
