
import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

class DriverManagement {

    createContract(data){ // this also called link driver to business
        return API.ACCOUNTS.post(`/driver-contract`, data)
    }

    updateContract(data, isApproval){ // update only the profile of the contract payload
        return API.ACCOUNTS.patch(`/driver-contract/${data.id}?isApproval=${isApproval}`, data)
    }

    delinkContract(driverId,businessId){
        let delinkData = {
            driverId: driverId,
            businessId: businessId
        }
        return API.ACCOUNTS.post(`/driver-contract/delink`, delinkData)
    }

    updateNonEWDContractStatus(data){
        return API.ACCOUNTS.post(`/driver-contract/update-non-ewd-status`, data)
    }

    resendInvite(by,contractId){
        let resendData = {
            by: by,
            contractId: contractId
        }
        return API.ACCOUNTS.post(`/driver-contract/resend-invite`, resendData)
    }

    rescindInvite(by,contractId){
        let resendData = {
            by: by,
            contractId: contractId
        }
        return API.ACCOUNTS.post(`/driver-contract/rescind-invite`, resendData)
    }

    getContract( id = null ){
        if(id){
            return API.ACCOUNTS.get(`/driver-contract/${id}`)
        }else{
            return API.ACCOUNTS.get(`/driver-contract`)
        }
    }

    getContractByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
        let filter = '';

        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}

        query.filter = filter;

        if(query) query = '?'+ObjectToQueryString(query);

        return API.ACCOUNTS.get(`/driver-contract/business/${businessId}/${query}`)
    }

    getContractByDriverId(driverId, query = { skip:0, limit:10, filter:{} }){
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/driver-contract/driver/${driverId}${query}`)
    }

    getContractByResellerUID(resellerUID, query = { skip:0, limit:10, filter:{} }){
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/driver-contract/reseller/${resellerUID}${query}`)
    }

    getContractByPartnerUID(partnerUID, query = { skip:0, limit:10, filter:{} }){
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/driver-contract/partner/${partnerUID}${query}`)
    }

    getExternalContractByDriver(driverId, query = { skip:0, limit:10, filter:{} }){
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/driver-contract/external/driver/${driverId}${query}`)
    }

    getContractFilter(filter){
        console.log(filter)
        return API.ACCOUNTS.get(`/driver-contract/filter/${{status: 'delinked'}}`)
    }

    getCurrentSeatCountForBusiness(data) {
        let filter = '';
        if(typeof data.filter === 'object') {
            try{ 
                filter = JSON.stringify(data.filter) 
            }
            catch(e) { 
                throw 'Invalid Filter object'
            }
        }
        data.filter = filter;
        if(data.filter) data.filter = '?'+ObjectToQueryString({filter: data.filter});
        return API.ACCOUNTS.get(`/driver-contract/business-current-seat-count/${data.businessId}${data.filter}`);
    }

    acceptContract( by, contract ){
        let acceptData = {
            by: by,
            ...contract
        }
        return API.ACCOUNTS.post(`/driver-contract/accept`, acceptData)
    }

    declineContract( by, contractId ){
        let declineData = {
            by: by,
            contractId: contractId
        }
        return API.ACCOUNTS.post(`/driver-contract/decline`, declineData)
    }

    getDeviceLogByDateRange(driverId, from, to){
        return API.ACCOUNTS.post(`/devicelog/find-by-date-range/${driverId}`, { from, to })
    }

    getVehiclesByBusiness(businessId){
        return API.ACCOUNTS.get(`/vehicle/find-by-uid/${businessId}`)
    }

    //DeviceIdentityLogs
    getAllDeviceIdentityByUninstalledFlag(data, query = { skip: 0, limit: 10, filter: {}, from: '', to: '' }) {
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.post(`/devicelog/identity/find-by-uninstalledFlag/${data.uninstalledFlag}${query}`)
    }

    getBusinessDeviceIdentityByUninstalledFlag(data, query = { skip: 0, limit: 10, filter: {}, from: '', to: '' }) {
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.post(`/devicelog/identity/find-by-business-identity/${data.businessId}${query}`, data)
    }

    getDeviceIdentity(id) {
        return API.ACCOUNTS.post(`/devicelog/identity/${id}`)
    }

    updateDeviceIdentity(data) {
        return API.ACCOUNTS.patch(`/devicelog/update-device-identity/${data.id}`, data)
    }

    acknowledgeDevice(data) {
        return API.ACCOUNTS.post(`/devicelog/identity/acknowledge/${data.id}`, data)
    }

    addDeviceRemarks(data) {
        return API.ACCOUNTS.post(`/devicelog/identity/remarks/${data.id}`, data)
    }

    // Flow App State
    getFitToDriveByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/fit-to-drive/find-by-businessId/${businessId}${query}`)
    }

    getFitToDriveById( query ){
        return API.ACCOUNTS.get(`/fit-to-drive/${query}`);
    }

    getFitToDriveByDateRange( payload ){
        return API.ACCOUNTS.post('/fit-to-drive/find-by-date-range', payload);
    }

    getDriverIdsByBusinessId(id) {
        return API.ACCOUNTS.get(`/driver-contract/business/${id}/driver-ids`)
    }
}

export default new DriverManagement