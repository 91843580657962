<template>
  <FormulateInput type="group" name="rules">
    <div v-for="(fields, index) in rules" :key="index">
      <div class="bg-gray-200 p-4 my-4 rounded-lg">
        <div class="flex justify-end">
          <font-awesome-icon
            icon="times"
            class="my-auto text-xl mx-1.5 hover:text-orange-primary cursor-pointer"
            @click.prevent="deleteRule(index)"
          />
        </div>

        <FormulateInput
          type="text"
          label="Rule"
          validation="^required"
          v-model="fields.rule"
        />

        <FormulateInput
          type="text"
          name="countingPoint"
          label="Counting Point (Hours and Minutes)"
          validation="^required"
          v-model="fields.countingPoint"
          @keypress="isNumber"
          @blur="formatAFM($event, index, 'countingPoint')"
          @click="clearField"
        />

        <FormulateInput
          type="text"
          label="Counting Period (Hours and Minutes)"
          name="countingPeriod"
          validation="^required"
          v-model="fields.countingPeriod"
          @keypress="isNumber"
          @blur="formatAFM($event, index, 'countingPeriod')"
          @click="clearField"
        />

        <div class="flex justify-center p-2">
          <div class="w-1/2">
            <p class="font-medium text-sm mb-1">Continuous Rest</p>
          </div>
          <div class="w-1/2">
            <FormulateInput
              type="checkbox"
              name="continuousRest"
              v-model="fields.continuousRest"
              value="false"
              @click="checkboxClick"
            />
          </div>
        </div>

        <div class="flex justify-center p-2">
          <div class="w-1/2">
            <p class="font-medium text-sm mb-1">Night Rest</p>
          </div>
          <div class="w-1/2">
            <FormulateInput
              type="checkbox"
              name="nightRest"
              v-model="fields.nightRest"
            />
          </div>
        </div>

        <div class="flex justify-center p-2">
          <div class="w-1/2">
            <p class="font-medium text-sm mb-1">Consecutive</p>
          </div>
          <div class="w-1/2">
            <FormulateInput
              type="checkbox"
              name="consecutive"
              v-model="fields.consecutive"
              value="false"
              @click="checkboxClick"
            />
          </div>
        </div>

        <div class="flex justify-center p-2">
          <div class="w-1/2">
            <p class="font-medium text-sm mb-1">Stationary</p>
          </div>
          <div class="w-1/2">
            <FormulateInput
              type="checkbox"
              name="stationary"
              v-model="fields.stationary"
              value="false"
              @click="checkboxClick"
            />
          </div>
        </div>

        <FormulateInput
          type="select"
          name="countingType"
          label="Counting Type"
          validation="^required"
          :options="countingTypeOptions"
          v-model="fields.countingType"
        />

        <FormulateInput
          type="text"
          name="maxWork"
          label="Max Work (Hours and Minutes)"
          v-model="fields.maxWork"
          @keypress="isNumber"
          @blur="formatAndVerifyMaxWork($event, index, 'maxWork')"
          @click="clearField"
        />

        <FormulateInput
          type="text"
          label="Rest Required (Hours and Minutes)"
          name="restRequired"
          v-model="fields.restRequired"
          @keypress="isNumber"
          @blur="formatAndVerifyMaxWork($event, index, 'restRequired')"
          @click="clearField"
        />

        <button
          class="btn btn-secondary text-center"
          @click.prevent="addAdditionals(index)"
        >
          Add Additionals <font-awesome-icon icon="plus-circle" />
        </button>

        <AdditionalContainer
          :additionalIndeces="fields.additionals"
          :latestIndex="fields.latestIndex"
          :afmRuleIndex="index"
          @deleteAdditional="deleteAdditional"
          @addAdditionals="addAdditionals"
        />
      </div>
    </div>
  </FormulateInput>
</template>

<script>
import AdditionalContainer from "./additional-container.vue";
export default {
  name: "afm-rules-container",

  components: { AdditionalContainer },

  mounted() {
    this.rules = this.afmRules;
  },

  computed: {
    afmRuleList() {
      return this.$store.state.accreditation.AFMrules;
    },
  },

  props: {
    afmRules: {
      type: Array,
      default: () => {},
    },
  },

  data() {
    return {
      rules: false,
      countingTypeOptions: [
        { value: "Work", label: "Work" },
        { value: "Rest", label: "Rest" },
      ],
      countingTypeChoice: "Work",
    };
  },

  methods: {
    addAdditionals(afmIndex, type) {
      this.$emit("addAdditionals", afmIndex, type);
    },

    deleteAdditional(afmRuleIndex, addtionalIndex) {
      this.$emit("deleteAdditional", afmRuleIndex, addtionalIndex);
    },

    checkboxClick(event) {
      return event.target.checked;
    },

    isNumber(event) {
      event = event ? event : window.event;
      let charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },

    clearField(event) {
      event.target.value = "";
    },

    clamp(val, min, max, isHours) {
      const ret = val > max ? max : val < min ? min : val;
      if (isHours) {
        if (ret.length < 2) {
          const zeroes = "0".repeat(1) + ret;
          return zeroes;
        }
        return ret;
      }
      return ret.toString().length % 2 === 0 ? ret : "0" + ret;
    },

    deleteRule(index) {
      this.$emit("deleteRule", index);
    },

    verifyMaxWork(index) {
      const countingPeriod = this.afmRuleList[index].countingPeriod;
      const maxWork = this.afmRuleList[index].maxWork;

      if (countingPeriod && maxWork) {
        const countingPeriodSplit = countingPeriod.split(":");
        const countingPeriodInMins =
          Number(countingPeriodSplit[0]) * 60 + Number(countingPeriodSplit[1]);

        const maxWorkSplit = maxWork.split(":");
        const maxWorkInMins =
          Number(maxWorkSplit[0]) * 60 + Number(maxWorkSplit[1]);

        if (maxWorkInMins > countingPeriodInMins) {
          const message = "Max Work cannot be greater than counting period";

          this.afmRuleList[index].maxWork = "";

          this.toast("error", message);
        }
      } else if (!countingPeriod) {
        this.afmRuleList[index].maxWork = "";
        this.toast("error", "Counting Period is missing");
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    formatAFM(event, index, field) {
      const inputVal = event.target.value;
      let final;
      //only seconds
      if (
        inputVal?.length > 0 &&
        inputVal?.length <= 2 &&
        inputVal.indexOf(":") === -1
      ) {
        let minutes = this.clamp(inputVal.slice(0), 0, 59);
        final = "0:" + minutes;
        //hours and minutes
      } else if (
        inputVal?.length > 2 &&
        inputVal?.length <= 6 &&
        inputVal.indexOf(":") === -1
      ) {
        const secondsIndex = inputVal.length - 2;
        const hours = this.clamp(
          inputVal.slice(0, secondsIndex),
          0,
          9999,
          true
        );
        const minutes = this.clamp(
          inputVal.slice(secondsIndex, inputVal.length),
          0,
          59
        );
        final = hours + ":" + minutes;
      } else {
        this.afmRuleList[index][`${field}`] = "";
      }

      this.afmRuleList[index][`${field}`] = final;

      if (field === "countingPeriod") {
        this.verifyMaxWork(index);
      }
    },

    // check if either maxwork or restrequired is inputted
    formatAndVerifyMaxWork(event, index, field) {
      const inputVal = event.target.value;
      let final;
      //only seconds
      if (inputVal?.length > 0 && inputVal?.length <= 2) {
        let minutes = this.clamp(inputVal.slice(0), 0, 59);
        final = "0:" + minutes;
        //hours and minutes
      } else if (inputVal?.length > 2 && inputVal?.length <= 6) {
        const secondsIndex = inputVal.length - 2;
        const hours = this.clamp(
          inputVal.slice(0, secondsIndex),
          0,
          9999,
          true
        );
        const minutes = this.clamp(
          inputVal.slice(secondsIndex, inputVal.length),
          0,
          59
        );
        final = hours + ":" + minutes;
      } else {
        this.afmRuleList[index][`${field}`] = "";
      }

      this.afmRuleList[index][`${field}`] = final;

      this.afmRuleList[index].validMaxWork = !!(
        this.afmRuleList[index].maxWork || this.afmRuleList[index].restRequired
      );

      if (field === "maxWork") {
        this.verifyMaxWork(index);
      }
    },
  },
};
</script>

<style scoped></style>
