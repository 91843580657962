<template>
  <div class="card" :class="[cardBackground]" :style="cardStyle">
    <slot name="cardContent" />
  </div>
</template>

<script>
export default {
  name: "Dashboard-Card",
  props: {
    cardBackground: {
      type: String,
      default: "bg-white",
    },
    cardWidth: {
      type: String,
      default: "auto",
    },
    cardBackgroundColor: {
      type: String,
      default: "auto",
    },
    maxCardHeight: {
      type: String,
    },
    minCardHeight: {
      type: String,
    },
  },
  data() {
    return {
      cardStyle: {},
    };
  },
  created() {
    this.cardStyle = {
      width: this.cardWidth,
      backgroundColor: this.cardBackgroundColor,
      ...(this.maxCardHeight ? { maxHeight: this.maxCardHeight } : {}),
      ...(this.minCardHeight ? { minHeight: this.minCardHeight } : {}),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card {
  position: relative;
  width: fit-content;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 2rem;
  box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.15);
  transition-property: all;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  height: fit-content;
  .icon-container {
    margin-right: 1rem;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .separator-v {
    border-right: 2px solid #e5e7eb;
    margin: 0 2rem;
    height: 35px;
  }
  .separator-h {
    border-bottom: 2px solid #e5e7eb;
    width: 100%;
  }
}
</style>
