<template>
  <div class="mb-16">
    <FormulateForm v-model="values" :schema="schema" @submit="onNext">
      <div v-if="loading" class="text-center mt-28"><ui-spinner class="m-auto"></ui-spinner> Loading...</div>
      <div v-if="!loading" class="items-center justify-between">
        <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
          <button class="btn col-span-2 btn-primary mt-2" :style="{backgroundColor: theme.secondaryColor}">Next</button>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>
<script>
import schema from './partner-business-profile-schema';
import { mapGetters } from 'vuex'
export default {
  name: 'New-Partner-Profile-Form',
  props: {
    msg: String
  },
  data() {
    return {
      errorMessage: '',
      loading: false,
      values: {},
      schema: schema
    };
  },
  methods: {
    onNext() {
      this.$emit('nextStep', { businessProfile: this.values.businessProfile[0]})
    },
  },
   computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
  },
}
</script>