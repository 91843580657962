import { ReportScheduling } from '@/api';

export default {
  async createReportSchedule({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await ReportScheduling.createReportSchedule(payload);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async getReportSchedule({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { query: pagination } = payload;
      const response = await ReportScheduling.getReportSchedule(pagination);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async updateSchedule({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await ReportScheduling.updateSchedule(payload);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async deleteSchedule({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await ReportScheduling.deleteSchedule(payload);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },
};