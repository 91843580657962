<template>
    <div class="flex items-center justify-center">
        <label class="flex items-center cursor-pointer">
            <StyledDiv class="relative" :toggleColor="theme.primaryColor">
                <input type="checkbox" class="sr-only" 
                    v-model="checked"
                    :id="componentProps.id"
                    @change="onChange"
                >
                <div class="line block w-10 h-6 rounded-full" :style="{backgroundColor: theme.lineColor || 'rgb(209 213 219)'}"></div>
                <div class="dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"></div>
            </StyledDiv>
        </label>
    </div>
</template>

<script>

import {StyledDiv} from './styled'
import { mapGetters } from 'vuex'

export default {
    name: 'Toggle',
    components: {StyledDiv},
    props: {
        componentProps: {
            type: Object,
            default(props) {
                return {vModel: props.vModel, id: props.id}
                }
        }
    },
    emits: ["change"],
    data(){
        return{
            checked: this.componentProps.vModel
        }
    },
    methods: {
        onChange(){
            this.$emit('change', {checked: this.checked, id: this.componentProps.id})
        }
    },
    computed: {
        ...mapGetters('theme', {
        theme: 'getColorScheme'
        })
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.shadowed {
     box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 5px 5px 10px -1px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
}


.box-pallete{
  height: 14px;
  width: 14px;
  border-radius: 5px;
}



</style>