<template>
    <div class="mb-16">
      <FormulateForm v-model="values" :schema="schema" @submit="onSubmit" #default="{ hasErrors }">
        <div class="items-center justify-between">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{backgroundColor: theme.secondaryColor}"
          />
        </div>
      </FormulateForm>
    </div>
</template>

<script>
// import { convertAddressObject } from '@/_helper'
// import _ from 'lodash';
import schema from './new-driver-schema'; //Using the create driver schema for now
import { mapGetters } from 'vuex'

export default {
  name: 'Edit-Driver-Form',
  props: {
    msg: String
  },
  data() {
    return {
      loading: false,
      values: {},
      schema: schema
    };
  },
  methods: {
    async onSubmit() {
        //add the edit api integration for submission here
    },

  },
  computed: {
     ...mapGetters('theme', {
    theme: 'getColorScheme'
    })
  },
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
