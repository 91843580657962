<template>
  <div class="mb-2">
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>

    <div v-if="!isLoading">
      <div class="text-center mb-10">
        <div class="font-bold text-black-primary text-2xl">
          {{ driverPersonalDetails?.driverName ?? "None" }}
        </div>
        <div
          class="font-bold text-xs"
          :class="[
            driverPersonalDetails?.isActive ? 'text-green-500' : 'text-red-500',
          ]"
        >
          {{ driverPersonalDetails?.isActive ? "ACTIVE" : "INACTIVE" }}
        </div>
      </div>

      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Driver Details</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ driverPersonalDetails?.emailAddress ?? "None" }}
        </div>
        <div class="text-xs">Driver Email Address</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ formattedDate(driverPersonalDetails?.createdAt) ?? "None" }}
        </div>
        <div class="text-xs">Registered</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{
            formattedDate(driverPersonalDetails?.driverDateOfBirth) ?? "None"
          }}
        </div>
        <div class="text-xs">Date of Birth</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ driverPersonalDetails?.driverRecordKeeperAddress ?? "None" }}
        </div>
        <div class="text-xs">Driver Record Keeper Address</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ driverPersonalDetails?.driversBFMNumber ?? "None" }}
        </div>
        <div class="text-xs">Drivers BFM Number</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ driverPersonalDetails?.driversBaseLocation ?? "None" }}
        </div>
        <div class="text-xs">Drivers Base Location</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ driverPersonalDetails?.driversBaseTimeZone ?? "None" }}
        </div>
        <div class="text-xs">Drivers Base Timezone</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{
            formattedDate(driverPersonalDetails?.driversLicenseExpiry) ?? "None"
          }}
        </div>
        <div class="text-xs">Driver's License Expiry</div>
      </div>

      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Certificate Details</div>
      </div>

      <div class="text-center mb-10">
        <div class="font-bold text-black-primary text-2xl">
          {{ certDetails?.certNumber ?? "None" }}
        </div>
        <div
          class="font-bold text-xs"
          :class="[certDetails?.certNumber ? 'text-green-500' : 'text-red-500']"
        >
          {{ certDetails?.isActive ? "ACTIVE" : "INACTIVE" }}
        </div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ certDetails?.type }}
        </div>
        <div class="text-xs">Type</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ formattedDate(certDetails?.createdAt) ?? "None" }}
        </div>
        <div class="text-xs">Date of Creation</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{
            certDetails?.certExpiryDate
              ? formattedDate(certDetails?.certExpiryDate)
              : "None"
          }}
        </div>
        <div class="text-xs">Expiry Date</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ formattedDate(certDetails?.updatedAt) ?? "None" }}
        </div>
        <div class="text-xs">Last Updated</div>
      </div>

      <div class="mb-5">
        <div
          class="font-bold"
          :class="[
            certDetails.isMedicalCertRequired
              ? 'text-green-500'
              : 'text-red-500',
          ]"
        >
          {{ certDetails?.isMedicalCertRequired ? "REQUIRED" : "OPTIONAL" }}
        </div>
        <div class="text-xs">Medical Certificate Requirement</div>
      </div>

      <div class="mb-5">
        <div
          class="font-bold"
          :class="[
            certDetails?.isSignatureApprovalRequired
              ? 'text-green-500'
              : 'text-red-500',
          ]"
        >
          {{
            certDetails.isSignatureApprovalRequired ? "REQUIRED" : "OPTIONAL"
          }}
        </div>
        <div class="text-xs">Signature Approval Reqiurement</div>
      </div>

      <div class="mb-5">
        <div
          class="font-bold"
          :class="[
            certDetails?.isTrainingCertRequired
              ? 'text-green-500'
              : 'text-red-500',
          ]"
        >
          {{ certDetails.isTrainingCertRequired ? "REQUIRED" : "OPTIONAL" }}
        </div>
        <div class="text-xs">Training Certificate Requirement</div>
      </div>

      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Documents</div>
      </div>

      <div class="mb-5 w-fit flex" v-if="driverDocs?.driverMedical?.document">
        <a
          class="flex cursor-pointer"
          target="_blank"
          :href="driverDocs?.driverMedical?.document"
        >
          <div class="text-md font-bold">View Medical Certificate</div>
          <font-awesome-icon
            icon="file"
            class="my-auto mx-1.5 hover:text-orange-primary-primary"
          />
        </a>
      </div>

      <div class="mb-5 w-fit flex" v-if="driverDocs?.driverTraining?.document">
        <a
          class="flex cursor-pointer"
          target="_blank"
          :href="driverDocs?.driverTraining?.document"
        >
          <div class="text-md font-bold">View Training Certificate</div>
          <font-awesome-icon
            icon="file"
            class="my-auto mx-1.5 hover:text-orange-primary-primary"
          />
        </a>
      </div>

      <div class="mb-5 w-fit flex" v-if="certDetails?.certFile">
        <a
          class="flex cursor-pointer"
          target="_blank"
          :href="certDetails?.certFile"
        >
          <div class="text-md font-bold">View Compliance Certificate</div>
          <font-awesome-icon
            icon="file"
            class="my-auto mx-1.5 hover:text-orange-primary-primary"
          />
        </a>
      </div>

      <div v-if="!driverDocs?.driverMedical" class="mb-5">
        <div class="text-md font-bold">
          Driver does not have a medical certificate
        </div>
      </div>

      <div v-if="!driverDocs?.driverTraining" class="mb-5">
        <div class="text-md font-bold">
          Driver does not have a training certificate
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-between">
      <div v-if="!isLoading" class="bg-red-500 p-2 border-2 rounded-lg">
        <button @click.prevent="reject" class="text-md font-bold text-white">
          Reject
        </button>
      </div>
      <div v-if="!isLoading" class="flex flex-row justify-between">
        <div class="bg-green-500 p-2 border-2 rounded-lg">
          <button @click.prevent="approve" class="text-md font-bold text-white">
            Approve
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "reviewDriverDocument",
  props: {
    driver: {
      type: Object,
      default: () => {},
    },

    cert: {
      type: Object,
      default: () => {},
    },
  },

  created() {
    this.driverDetails = this.driver;
    this.driverPersonalDetails = this?.driver?.docData?.docData;
  },

  destroyed() {
    this.driverDetails = false;
  },

  async mounted(paged = 1) {
    this.certDetails = this.cert?.certDetails;
    this.driverDocs["driverDetails"] = this?.driver?.docData;
    this.isLoading = true;
    const query = {
      skip: paged * this.filter.limit - this.filter.limit,
      limit: this.filter.limit,
      filter: {
        isActive:true,
        search:''
      },
    };

    const medCertQuery = {
      skip: query.skip,
      limit: query.limit,
      filter:{
        ...query.filter,
        documentType: 'DRIVER_MEDICAL',
      }
    }

    const medCert = await this.$store.dispatch(
      `accreditation/fetchUserDocuments`,
      {
        id: this.driverDetails.driverId,
        query: medCertQuery,
      }
    );

    const trainingQuery = {
      skip: query.skip,
      limit: query.limit,
      filter:{
        ...query.filter,
        documentType: 'DRIVER_TRAINING',
      }
    }

    const trainingCert = await this.$store.dispatch(
      `accreditation/fetchUserDocuments`,
      {
        id: this.driverDetails.driverId,
        query: trainingQuery,
      }
    );

    if (medCert.data?.data?.results?.length > 0) {
      this.driverDocs["driverMedical"] = medCert.data?.data?.results[0];
    }

    if (trainingCert.data?.data?.results?.length > 0) {
      this.driverDocs["driverTraining"] = trainingCert.data?.data?.results[0];
    }

    this.isLoading = false;
  },

  methods: {
    formattedDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    async approve() {
      this.isLoading = true;
      this.$emit("approveDriver", this.driverDetails?.id, this.cert?.id, true);
    },

    async reject() {
      this.isLoading = true;
      this.$emit("rejectDriver", this.driverDetails?.id, this.cert?.id, false);
    },
  },

  data() {
    return {
      driverDetails: false,
      driverPersonalDetails: false,
      certDetails: false,

      filter: {
        limit: 10,
      },
      driverDocs: {},

      isLoading: false,
    };
  },
};
</script>

<style scoped></style>
