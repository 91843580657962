<template>
  <Stepper
    :stepperSteps="stepperSteps"
    @completed-step="completeStep"
    @active-step="isStepActive"
    :showNavigation="false"
    @closeModal="closeModal"
  />
</template>
<script>
import { Stepper } from "@/components/commons";
import {
  Details,
  AccountInfo,
  DurationTerms,
  ModuleSelection,
  Pricing,
  Summary
} from "../business-stepper-component";

export default {
  name: "Business-Partner-Contract-Step",
  components: { Stepper },
  data() {
    return {
      stepperSteps: [
        {
          stepIcon: "briefcase",
          stepTitle: "Business Details",
          stepSequence: "first",
          stepComponent: Details,
          isCompleted: true,
        },
        {
          stepIcon: "user",
          stepTitle: "Admin Account Details",
          stepSequence: "second",
          stepComponent: AccountInfo,
          isCompleted: false,
        },
        {
          stepIcon: "check",
          stepTitle: "Module Selection",
          stepSequence: "third",
          stepComponent: ModuleSelection,
          isCompleted: false,
          detailsId: this.businessId
        },
        {
          stepIcon: "calendar",
          stepTitle: "Duration Terms",
          stepSequence: "fourth",
          stepComponent: DurationTerms,
          isCompleted: false,
        },
        {
          stepIcon: "dollar-sign",
          stepTitle: "Pricing",
          stepSequence: "fifth",
          stepComponent: Pricing,
          isCompleted: false,
        },
        {
          stepIcon: "file",
          stepTitle: "Contract Summary",
          stepSequence: "sixth",
          stepComponent: Summary,
          isCompleted: false,
        },

      ],
      activeStep: 0,
    };
  },
  methods: {
    completeStep(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          step.isCompleted = true;
        }
      });
    },
    isStepActive(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          if (step.isCompleted === true) {
            step.isCompleted = false;
          }
        }
      });
    },
    closeModal(){ 
      this.$emit('closeModal')
    }
  },
};
</script>
