<template>
  <Stepper
    :stepperSteps="stepperSteps"
    @completed-step="completeStep"
    @active-step="isStepActive"
    :showNavigation="false"
    @closeModal="closeModal"
  />
</template>

<script>
  import { Stepper } from "@/components/commons";
  import {
    DriverLookup, 
    CreateDriver, 
    DurationTerms,
    Pricing,
    Summary
  } from "../driver-stepper-component";
export default {
  name: "Driver-Solo-Contract-Step",
  components: { Stepper },
  data() {
    return {
      stepperSteps: [
        {
            stepIcon: 'search',
            stepTitle: 'Driver Lookup',
            stepSequence: 'first',
            stepComponent: DriverLookup,
            isCompleted: true,

        },
        {
            stepIcon: 'user',
            stepTitle: 'Create / Select',
            stepSequence: 'second',
            stepComponent: CreateDriver,
            isCompleted: false,

        },
        {
          stepIcon: "calendar",
          stepTitle: "Duration Terms",
          stepSequence: "fourth",
          stepComponent: DurationTerms,
          isCompleted: false,
        },
        {
          stepIcon: "dollar-sign",
          stepTitle: "Pricing",
          stepSequence: "fifth",
          stepComponent: Pricing,
          isCompleted: false,
        },
        {
          stepIcon: "file",
          stepTitle: "Contract Summary",
          stepSequence: "sixth",
          stepComponent: Summary,
          isCompleted: false,
        },

      ],
    }
  },
  methods: {
    completeStep(payload) {
        this.stepperSteps.forEach((step) => {
            if (step.stepSequence === payload.stepSequence) {
                step.isCompleted = true;
            }
        })
    },
    isStepActive(payload) {
        this.stepperSteps.forEach((step) => {
            if (step.stepSequence === payload.stepSequence) {
                if(step.isCompleted === true) {
                    step.isCompleted = false;
                }
            }
        })
    },
    closeModal(){ 
        this.$emit('closeModal')
    }
},
}
</script>