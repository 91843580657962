<template>
  <div>
    <Table
      v-if="readRoles"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="rolesData"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="recordsPerPage"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="selectedFilteredStatus"
              @change="selectFilteredStatus"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            @input="queryForKeywords($event.target.value)"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewRole(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                </StyledIconedLink>
              </li>
              <li v-if="writeRoles">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="editRole(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                </StyledIconedLink>
              </li>
              <li v-if="readSecurityConfig">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewSecurityConfig(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="sliders-h" class="my-auto mr-2" />
                  Security Config
                </StyledIconedLink>
              </li>
              <li v-if="readUserRoleAuditTrail">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewAuditTrail(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="history" class="my-auto mr-2" />
                  Audit Trail
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <template slot="callToActionTop">
        <button
          v-if="writeRoles"
          class="btn btn-primary"
          @click.prevent="newRole()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Add Role
        </button>
      </template>
    </Table>

    <div v-if="!readRoles && hasRole" class="text-center">
      <h1>Not authorized to view roles. Please contact your {{ userType }}.</h1>
    </div>

    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <div class="mb-16">
          <AddRole />
        </div>
      </div>
      <div v-if="modalContent == 'view'">
        <RoleDetailsView @editRole="editRole" :detailsId="modalId" />
      </div>
      <div v-if="modalContent == 'edit'">
        <UpdateRole :detailsId="modalId" @closeModal="closeModal" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal, Table, Dropdown } from "@/components/commons";
import { AddRole, UpdateRole } from "@/components/forms/role/";
import RoleDetailsView from "@/components/view/role/";
// import { DRIVER_ROLE  } from '@/constants/usertype'
import { formatDate } from "@/_helper";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Roles",
  components: {
    Modal,
    Dropdown,
    AddRole,
    RoleDetailsView,
    Table,
    UpdateRole,
    StyledIconedLink,
  },
  data() {
    return {
      modalContent: false,
      modalTitle: "",
      modalId: "",
      roles: [],
      modalHeight: "80vh",
      tableHeader: ["Title", "Description", "Date Created"],
      tableData: [],
      recordsPerPage: 50,
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: ["All", "Active", "Inactive"],
      readRoles: false,
      writeRoles: false,
      readSecurityConfig: false,
      readUserRoleAuditTrail: false,
      userType: null,
      hasRole: true,
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      this.userType = securityRoles[0].type;
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readRoles = securityAccessObj.readRoles;
      this.writeRoles = securityAccessObj.writeRoles;
      this.readSecurityConfig = securityAccessObj.readSecurityConfig;
      this.readUserRoleAuditTrail = securityAccessObj.readUserRoleAuditTrail;
    } else {
      this.hasRole = false;
    }
    if (this.hasRole) {
      const roles = await this.$store.dispatch(`rolesandrespo/getRoles`, null);
      if (roles) {
        this.roles = this.formatItem(roles);
      }
    }

    if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
    this.$emit("updateLoading", false);
  },
  methods: {
    newRole: function () {
      this.$refs.modal.openModal();
      this.modalContent = "new";
      this.modalTitle = "Add Role";
    },
    viewRole: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Role Info";
      this.modalId = id;
    },
    editRole: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "edit";
      this.modalTitle = "Role Edit";
      this.modalId = id;
    },
    viewSecurityConfig: (id) => {
      window.location.href = `/security-role/${id}/security-configuration`;
    },
    viewAuditTrail: (id) => {
      window.location.href = `/security-role/${id}/user/null/audit-trail`;
    },
    closeModal: function () {
      this.$refs.modal.closeModal();
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const role = {
            id: item["_id"],
            name: item["name"] || "",
            description: item["description"] || "",
            createdAt: formatDate(item["createdAt"]) || "",
            isActive: item["isActive"] ? "Active" : "Inactive" || "",
            isAdmin: item["isAdmin"],
          };
          nItems.push(role);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseRoles(data) {
      return data.map((roleData) => [
        {
          id: roleData.id,
          name: roleData.name,
          itemType: "string",
          isAdmin: roleData.isAdmin,
        },
        {
          id: roleData.id,
          name: roleData.description,
          itemType: "string",
          isAdmin: roleData.isAdmin,
        },
        {
          id: roleData.id,
          name: roleData.createdAt,
          itemType: "string",
          isAdmin: roleData.isAdmin,
        },
      ]);
    },
    selectFilteredStatus() {
      this.selectedStatus = this.filterStatuses[this.selectedFilteredStatus];
    },
    //TODO: set a proper naming convention and move to helper file??
    filterRow(keyword, attributeToFilter) {
      return keyword
        .toLowerCase()
        .split(" ")
        .every((v) => attributeToFilter.trim().toLowerCase().includes(v));
    },
    setSecurityAccess: (securityRoles) => {
      let initialReadRoles = false;
      let initialWriteRoles = false;
      let initialReadSecurityConfig = false;
      let initialReadUserRoleAuditTrail = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.permissions.readRoles.value) {
          initialReadRoles = securityConfig.permissions.readRoles.value;
        }
        if (securityConfig.permissions.writeRoles.value) {
          initialWriteRoles = securityConfig.permissions.writeRoles.value;
        }
        if (securityConfig.permissions.readSecurityConfig.value) {
          initialReadSecurityConfig =
            securityConfig.permissions.readSecurityConfig.value;
        }
        if (securityConfig.permissions.readUserRoleAuditTrail.value) {
          initialReadUserRoleAuditTrail =
            securityConfig.permissions.readUserRoleAuditTrail.value;
        }
      });
      return {
        readRoles: initialReadRoles,
        writeRoles: initialWriteRoles,
        readSecurityConfig: initialReadSecurityConfig,
        readUserRoleAuditTrail: initialReadUserRoleAuditTrail,
      };
    },
  },
  computed: {
    rolesData() {
      if (this.roles) {
        let rolesComputedData = this.roles;
        if (this.selectedFilteredStatus) {
          rolesComputedData = rolesComputedData.filter((item) =>
            this.filterRow(this.selectedStatus, item.status)
          );
        }
        if (this.recordsPerPage) {
          rolesComputedData = _.take(rolesComputedData, this.recordsPerPage);
        }
        if (this.searchKeyword) {
          rolesComputedData = rolesComputedData.filter(
            (item) =>
              this.filterRow(this.searchKeyword, item.name) ||
              this.filterRow(this.searchKeyword, item.description) ||
              this.filterRow(this.searchKeyword, item.isActive)
          );
        }
        if (!this.searchKeyword && !this.selectedStatus) {
          return this.parseRoles(this.roles);
        }
        return this.parseRoles(rolesComputedData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
