<template>
  <div class="notification-items">
    <NotificationItem 
      v-for="(item, index) in notifications" 
      :key="index"
      :id="item._id"
      :title="item.title" 
      :description="item.description"
      :link="`/account/notification/${item._id}`"
      :isCompleted="item.isCompleted"
      :createdAt="item.createdAt"
    ></NotificationItem>
  </div>
</template>
<script>
import NotificationItem from '@/components/commons/notification/NotificationItem';
import _ from 'lodash';
export default{
  name:"Notification",
  metaInfo: {
    title: 'Notification',
  },
  components:{ NotificationItem },
  data(){
    return {
      notifications: [],
    }
  },
  async mounted(){
    this.$emit("updateLoading", true);
    const notifications = await this.$store.dispatch(`notification/getAllNotifications`);
    this.notifications = this.parseNotifications(notifications)
    this.$emit("updateLoading", false);
  },

  async created(){
    this.me = await this.$store.getters['account/me'];
  },

  methods:{
    parseNotifications(data){
      if (!data) return;
      const targetedNotifs = data.map((notifications)=> {
        if(notifications.targetedWebProfile === this.me.uid) {
          return notifications
        }
        //PREVIOUS NOTIFICAIONS PRIOR TO THIS FEATURE
        else if( !notifications?.targetedWebProfile  ) {
          return notifications
        }
      }).filter((targets)=> targets!== undefined)

      return _.orderBy(targetedNotifs, ['createdAt'],['desc'])
    }
  }
}
</script>