
import Dashboard from '@/pages/reseller/Dashboard'
import Businesses from '@/pages/reseller/Businesses'
import Users from '@/pages/Users';
import Roles from '@/pages/Roles';
import BuyPricing from '@/pages/reseller/BuyPricing';
import RRP from '@/pages/reseller/RRP';
import ResellerBusinessContract from '@/pages/reseller/ResellerBusinessContract';
import SupportDetails from "@/pages/reseller/SupportDetails";
import Solo from "@/pages/reseller/Solo";
import PaymentGateway from "@/pages/reseller/PaymentGateway";
import Invoice from '@/pages/Invoice';

export default [
    {
      path: '/',
      name: 'Dashboard - Reseller',
      component: Dashboard
    },
    {
      path: 'businesses',
      name: 'Businesses - Reseller',
      component: Businesses
    },
    {
      path: 'solo',
      name: 'Solo',
      component: Solo
    },
    {
      path: 'users',
      name: 'ResellerUsers',
      component: Users,
    },
    {
      path: 'invoices',
      name: 'Reseller Invoices',
      component: Invoice,
    },
    {
      path: 'payment',
      name: 'Payment Gateway',
      component: PaymentGateway
    },
    // {
    //     path: 'contracts/terms',
    //     name: 'Reseller Contract Terms',
    //     component: ContractTerms
    // },
    // {
    //     path: 'contracts/terms-forms/builder',
    //     name: 'Contracts Term Builder - Reseller',
    //     component: ContractTermsBuilderCreate
    // },
    // {
    //     path: 'contracts/terms-forms/builder/:id/edit',
    //     name: 'Contracts Term Builder - Reseller (Edit)',
    //     component: ContractTermsBuilderEdit
    // },
    // {
    //     path: 'contracts/terms-forms',
    //     name: 'PartnerContractTermForms',
    //     component: ContractTermsForms,
    // },
    {
      path: 'roles',
      name: 'ResellerRoles',
      component: Roles
    },
    {
      path: 'buy-pricing',
      name: 'Reseller Buy Pricing',
      component: BuyPricing
    },
    {
      path: 'rrp',
      name: 'Reseller RRP',
      component: RRP
    },
    {
      path: 'contract-management',
      name: 'Business Contract - Reseller',
      component: ResellerBusinessContract
    },
    {
        path: 'support/details',
        name: 'Reseller Support Details',
        component: SupportDetails
    },
]