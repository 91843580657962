import { Payment } from "@/api";

export default {
  // @Payment Gateway
  async createPaymentGateway({ state }, data){
    if (!data) throw 'Invalid data object' +state.me;
    try {
        const response = await Payment.createPaymentGateway(data);
        return response.data.data;
    } catch (error) {
        throw error.response.data;
    }
  },
  async getPaymentGateway({ state }, id) {
    if(!id) throw 'Invalid uid '+state.me;
    try {
      const response = await Payment.getPaymentGateway(id);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async getPaymentGatewayByEntityId({ state }, id) {
    if(!id) throw 'Invalid uid '+state.me;
    try {
      const response = await Payment.getPaymentGatewayByEntityId(id);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async getActivePaymentGatewayByEntityId({ state }, id) {
    if(!id) throw 'Invalid uid '+state.me;
    try {
      const response = await Payment.getActivePaymentGatewayByEntityId(id);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async getPaymentGatewayByUid({ state }, data) {
    if (!data) throw 'Invalid data object' +state.me;
    try {
      const response = await Payment.getPaymentGatewayByUid(data);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async updatePaymentGateway({ state }, data){
    if (!data) throw 'Invalid data object' +state.me;
    try {
        const response = await Payment.patchPaymentGateway(data);
        return response.data.data;
    } catch (error) {
        throw error.response.data;
    }
  },
  async deletePaymentGateway({ state }, id){
    if (!id) throw 'Invalid id' +state.me;
    try {
        const response = await Payment.deletePaymentGateway(id);
        return response.data.data;
    } catch (error) {
        throw error.response.data;
    }
  },


  // @Payment Method
  async createCardPaymentMethod({ state }, data){
    if (!data) throw 'Invalid data object' +state.me;
    try {
        const response = await Payment.createCardPaymentMethod(data);
        return response.data.data;
    } catch (error) {
        throw error.response.data;
    }
  },

  async chargeCard({ state }, data) {
    if (!data) throw 'Invalid data object' +state.me;
    try {
        const response = await Payment.chargeCard(data);
        return response.data.data;
    } catch (error) {
        throw error.response.data;
    }
  },

  async getPaymentMethod({ state }, id) {
    if(!id) throw 'Invalid uid '+state.me;
    try {
      const response = await Payment.getPaymentMethod(id);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async getPaymentMethodByEntityId({ state }, id) {
    if(!id) throw 'Invalid uid '+state.me;
    try {
      const response = await Payment.getPaymentMethodByEntityId(id);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async getPaymentMethodByCardId({ state }, stripeCardId) {
    if(!stripeCardId) throw 'Invalid card id '+state.me;
    try {
      const response = await Payment.getPaymentMethodByCardId(stripeCardId);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async getAllPaymentMethodByEntityId({ state }, id) {
    if(!id) throw 'Invalid uid '+state.me;
    try {
      const response = await Payment.getAllPaymentMethodByEntityId(id);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async updatePaymentMethod({ state }, data){
    if (!data) throw 'Invalid data object' +state.me;
    try {
        const response = await Payment.patchPaymentMethod(data);
        return response.data.data;
    } catch (error) {
        throw error.response.data;
    }
  },
  async deletePaymentMethod({ state }, id) {
    if(!id) throw 'Invalid uid '+state.me;
    try {
      const response = await Payment.deletePaymentMethod(id);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },
  async getActivePaymentGatewayByUserType({ state }, userType) {
    
    try {
      const response = await Payment.getActivePaymentGatewayByUserType(userType);
      return response.data.data;
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },

  //Payment History
  
  async getPaymentHistoryByEntityId({ state }, payload) {
    if(!payload.id) throw 'Invalid id' + state.me;
    const params = {
      id: payload.id
    }
    const pagination = payload.query
    try {
      const response = await Payment.getPaymentHistoryByEntityId(params, pagination);
      if(response.data?.resultsMeta){
          return {
              results: response.data.results,
              metadata: response.data.resultsMeta,
              status: response.data.statusCode,
              message: response.data.message
          }
      }else{
          return response.data.data
      }
    } catch(error) {
      throw error.response.data;
    }
  },
};