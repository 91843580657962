import { render, staticRenderFns } from "./pricing.vue?vue&type=template&id=c4771614&scoped=true&"
import script from "./pricing.vue?vue&type=script&lang=js&"
export * from "./pricing.vue?vue&type=script&lang=js&"
import style0 from "./pricing.vue?vue&type=style&index=0&id=c4771614&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4771614",
  null
  
)

export default component.exports