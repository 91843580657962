<template>
  <div>
    <div v-if="loading" class="mx-auto my-24 h-80 w-full translate-y-2/4 flex flex-col items-center">
      <ui-spinner class="mx-auto mb-3"></ui-spinner>
      Loading....
    </div>
    <div v-if="!loading">
      <div class="w-full h-full items-center">

        <div class="mb-5 relative">
          <div class="h-full">
            <button class="absolute ml-20 z-30" @click.prevent="showDriverListFromPerson">
              <small v-if="!isDriverListOpenFromPerson" class="btn-primary btn-xs px-3 shadow rounded-lg"> + Select from driver list </small>
              <small v-else class="bg-red-600  text-white btn-xs px-3 shadow rounded-lg"> 
                <font-awesome-icon icon="times"/>&nbsp; Close
              </small>
            </button>

            <div class="relative">
              <div v-if="isDriverListOpenFromPerson" class="w-full px-3 h-96 pb-3 -mt-6 pt-7 absolute z-30 bg-white shadow-lg">
                <div class="content-center items-center w-full">
                  <div classs="w-full content-center">
                    <label class="block text-gray-700 text-sm font-bold mb-2" for="username">
                      Search driver
                    </label>
                    <FormulateInput
                      type="driver-select"
                      placeholder="Select a driver"
                      style="margin-bottom: 0px"
                      class="pb-4 content-center"
                      :getOptionKey="option => option.driver.udi"
                      :getOptionLabel="option => option.driver.driverName"
                      :filterable="false"
                      :clearable="false"
                      validation="required"
                      @input="onChange($event, 'person')" 
                    />
                    <hr/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <label>Person</label>
          <div class="pb-2 border rounded-lg w-full">
            <input 
              class="shadow-sm border border-gray-400 rounded px-3  py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2" 
              v-model="newPerson" 
              @keydown.enter.prevent="addPerson"
              placeholder="Add person"
              onkeypress="return /[0-9a-zA Z]/i.test(event.key)"
              />

            <ul v-if="newPerson && leftOverPersons.length" class="w-full px-3 pb-3 pt-1 absolute z-30 bg-white shadow-lg overflow-y-auto max-h-44">
              <li v-for="(result, i) in leftOverPersons"
                :key="i" 
                @click="addPerson(result, true)" 
                class="cursor-pointer py-2 px-4 w-full border-b border-gray-200 dark:border-gray-600">
                {{ result.name }}
              </li>
            </ul>

            <div class="flex items-center flex-wrap px-2 pb-1">
                <span class="py-2 invisible">:</span>
                <small v-for="(data, index) in selectedPersons" class="bg-green-400 text-white px-4 py-2 my-1 mr-1 rounded-full" :key="index">
                  {{ data.name }}<button @click.prevent="removePerson(data)" class="ml-2 mr-1 px-1"><b>x</b></button>
                </small>
            </div>
          </div>
        </div>
        <br/>

          <FormulateInput
              type="date"
              name="evidenceCollectionDate"
              label="Date"
              validation="required"
              min="2018-12-01"
              v-model="formValues.evidenceCollectionDate"
            />

          <div class="w-full mb-3">
            <label class="font-medium text-sm mb-1">Files:</label>
            <vue-dropzone 
                class="mb-5 relative cursor-pointer" 
                ref="myVueDropzone" 
                id="dropzone" 
                :options="dropzoneOptions" 
                :duplicateCheck="true"
                :useCustomSlot="true"
                @vdropzone-file-added="addFile"
              >
              <div class="dropzone-custom-content absolute">
                <h3 class="dropzone-custom-title text-lg">Drag and drop to upload content</h3>
                <div class="subtitle text-gray-500">...or click to select a PDF or Image file from your computer.</div>
              </div>
            </vue-dropzone>

          <div class="overflow-x-auto relative shadow-md sm:rounded-lg bg-white">
            <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead class="text-xs border text-gray-700 uppercase dark:text-gray-400">
                    <tr>
                        <th scope="col" class="w-20 py-3 px-6 bg-gray-50 dark:bg-gray-800">
                            Image
                        </th>
                        <th scope="col" class="py-3 px-6">
                            File Name
                        </th>
                        <th scope="col" class="w-24 py-3 px-6 text-center">
                            Type
                        </th>
                        <th scope="col" class="w-24 py-3 px-6 text-center">
                            Action
                        </th>
                    </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in formValues.evidenceCollectionFiles" :key="index" class="border-b border-gray-200 dark:border-gray-700">
                    <th scope="row" class="pt-4 pb-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white dark:bg-gray-800">
                        <img v-if="item.isImage" width="50" class="shadow-lg" :src="item.file">
                        <font-awesome-icon v-else icon="file-pdf" class="text-red-700 shadow-lg text-4xl ml-2"/>
                    </th>
                    <td class="pt-4 pb-6 px-6">
                        {{item.originalfileName}}
                    </td>
                    <td class="text-center py-4 px-6">
                        {{(item.isImage)? 'Image' : 'PDF'}}
                    </td>
                    <td @click.prevent="removeFile(item, item.isImage)" class="py-4 px-6 hover:text-orange-primary cursor-pointer text-center">
                        <font-awesome-icon icon="trash" title="Remove" class="text-gray-500 shadow-lg text-1xl hover:text-orange-primary"/>
                    </td>
                  </tr>
                  <tr v-if="!formValues.evidenceCollectionFiles.length" class="border-b border-gray-200 dark:border-gray-700">
                    <td colspan="3" class="py-8 text-sm text-center text-gray-400">
                      No files found
                    </td>
                  </tr>
                </tbody>
            </table>
          </div>
        </div>
        <br>
      
        <FormulateInput
          type="textarea"
          name="details"
          label="Details"
          validation="required"
          placeholder="Write the details here..."
          class="mb-4"
          v-model="formValues.evidenceCollectionDetails"
          rows="8"
        />
      </div>
    </div>
  </div>
</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash'

export default {
  name: 'Evidence-Collection-Form',
  components: {
    vueDropzone: vue2Dropzone
  },
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    activeDocForm: {
      default: null,
    }
  },
  data() {
    return {
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'http://localhost',
        previewsContainer: false,
        addRemoveLinks: true,
        maxFiles: 5,
        maxFilesize: 12, // 12 mb
        acceptedFiles: "image/*,application/pdf",
      },
      formValues: {
        evidenceCollectionFiles: [],
      },
      fileReader: null,
      loading: true,
      businessUsers: [],

      newPerson: '',
      selectedPersons: [],
      alreadyAssignedPersons: [],

      isDriverListOpenFromPerson: false,
      isDriverListOpenFromInterviewer: false
    }
  },
  watch: {
    activeDocForm: {
      deep: true,
      handler() {
        this.setActiveDocForm()
      }
    }
  },
  computed: {
    leftOverPersons(){
      let setArr = [], results = []
      if(this.alreadyAssignedPersons.length > 0 && this.businessUsers.length > 0){
        let thisUsers = this.alreadyAssignedPersons

        if(this.alreadyAssignedPersons.length > 0){
            setArr = this.businessUsers.filter(function (val) {
              for (let i = 0; i < thisUsers.length; i++) {
                  let arr = thisUsers[i];
                  if(val.userId == arr.userId) return false
              }
              return true
            })
        }
        else {
          setArr = this.businessUsers
        }
      }
      else {
        setArr = this.businessUsers
      }

      // Search accurate match query
      if((this.newPerson) && setArr.length){
        const thisType = this.newPerson
        results = setArr.filter((item) => thisType.toLowerCase().split(' ').every(v => item.name.toLowerCase().includes(v)))
      } else {
        results = setArr
      }

      // Sort by name
      if(results.length){
        return results.sort((a, b) => a.name.localeCompare(b.name))
      } else {
        return results
      }
    },
  },
  async created(){
    const users = await this.$store.dispatch(
      `rolesandrespo/getUsersV2`,
      null
    )
    if(users.length > 0) this.businessUsers = this.formatUsers(users)
    this.setActiveDocForm()
    this.loading = false
  },
  methods: {
    setActiveDocForm(){
      if(this.isEditMode) this.formValues = this.activeDocForm
    },
    addFile(file) {
      if(this.formValues.evidenceCollectionFiles.length > 4){
        this.$store.commit('setDialogNotify',true)
        this.$store.commit('setMessageNotify',{state: 'error', message: 'Attached files exceeding maximum items of 5.'})
        return false
      }

      // For uploading image files
      let existingImages = this.formValues.evidenceCollectionFiles.filter(item => item.originalfileName == file.name)

      const acceptedImageFiles = ['image/jpeg','image/png','image/gif','image/jpg']

      // Prevent duplicate entries
      if(acceptedImageFiles.includes(file.type) && _.isEmpty(existingImages)){
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let formatImage = {
            file: reader.result,
            fileName: uuidv4(),
            fileType: file.type,
            originalfileName: file.name,
            isImage: true
          }
          this.formValues.evidenceCollectionFiles.push(formatImage)
        } 
        return
      }

      // For uploading PDF files
      let existingPDFs = this.formValues.evidenceCollectionFiles.filter(item => item.originalfileName == file.name)

      if(_.isEmpty(existingPDFs)){
       const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          let formatPDF = {
            file: reader.result,
            fileName: uuidv4(),
            fileType: file.type,
            originalfileName: file.name
          }
          this.formValues.evidenceCollectionFiles.push(formatPDF)
        }
        return
      }
    },
    removeFile(file){
      this.formValues.evidenceCollectionFiles = this.formValues.evidenceCollectionFiles.filter(item => item.fileName != file.fileName)
    },
    addPerson(data, fromList = false) {
      let name = data.name || this.newPerson
      let exiting = this.selectedPersons.filter(item => item.name == name)
      if(_.isEmpty(exiting)){
        if(!fromList){
          if(_.isEmpty(this.newPerson)) return
          data = {
            userId: null,
            name: this.newPerson,
            userType: 'stringbase'
          }
        } else {
          data.userType = 'business'
        }
        this.selectedPersons.push(data)
        this.alreadyAssignedPersons.push(data)
      } 
      this.newPerson = ''
      this.formValues.persons = this.selectedPersons
    },
    removePerson(user) {
      this.selectedPersons.splice(this.selectedPersons.indexOf(user), 1)
      this.alreadyAssignedPersons.splice(this.alreadyAssignedPersons.indexOf(user), 1)
    },
    formatUsers(users) {
      let nItems = [];
      if (users) {
        const mainProfile = {
          userId: users[0]._id,
          name: users[0].userName
        }
        nItems.push(mainProfile);
        users[0].depotLinks.map((user) => {
            if (user["linkageWebProfileId"]) {
              const obj = {
                  userId: user["linkageWebProfileId"]["_id"],
                  name: user["linkageWebProfileId"]["userName"] || "",
              };
              nItems.push(obj);
            }
        })
      } return nItems
    },
    showDriverListFromPerson(){
      this.isDriverListOpenFromPerson = !this.isDriverListOpenFromPerson
    },
    onChange(data, from){
      if(data){
        data = {
          userId: data.driver._id,
          name: data.driver.driverName,
          userType: 'driver'
        }
      }
      if(from == 'person'){
        this.isDriverListOpenFromPerson = false
        let name = data.name
        let exiting = this.selectedPersons.filter(item => item.name == name)
        if(_.isEmpty(exiting)){
          this.selectedPersons.push(data)
          this.alreadyAssignedPersons.push(data)
        }
      }
    },
  }
}
</script>
<style>
.dropzone-custom-content {
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  opacity: .9;
}
.dropzone-custom-title {
  margin-top: 0;
  color: #00b782;
}
.subtitle {
  color: #314b5f;
}
</style>