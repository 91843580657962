<template>
    <div class="mb-16">
        <div class="flex flex-col justify-center items-center w-full mb-5 relative">
            <div class="w-full" v-if="loading">
                <div class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center">
                    <Spinner class="mx-auto mb-2"></Spinner>
                    <span class="font-semibold text-gray-400">Loading vehicles...</span>
                </div>
            </div>
            <label class="flex flex-col justify-center items-center w-full h-full bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100">
                <div class="flex flex-col justify-center items-center pt-10 pb-10">
                    <font-awesome-icon
                        icon="upload"
                        class="mb-3 w-14 h-14 text-gray-400 text-6xl"
                    />
                    <p class="mb-2 text-lg text-gray-500"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                    <p class="text-xs text-gray-500">CSV FILE ONLY</p>
                </div>
                <FormulateInput
                    type="file"
                    name="file"
                    accept=".csv"
                    class="hidden"
                    label="Select CSV file"
                    @change="onFileChange"
                />
            </label>
            <a :href="csvTemplate" class="text-orange-200 text-sm mt-5 hover:underline" target="_blank">Download CSV Template</a>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import jCsv from 'jquery-csv';
import Spinner from '@/components/commons/ui/Spinner';

export default {
    name: "Select-File",
    components: { Spinner },
    data() {
        return {
            csvTemplate: process.env.VUE_APP_VEHICLE_CSV_URL,
            loading: false,
            vehicles: [],
        };
    },
    methods: {
        formatDate(dateToFormat) {
            let momentDate = moment(dateToFormat)
            if (!momentDate.isValid()) momentDate = moment(dateToFormat, 'DD/MM/YYYY')

            return momentDate.format('YYYY-MM-DD');
        },
        onFileChange(e) {
            this.loading = true;
            const reader = new FileReader()
            reader.readAsText(e.target.files[0]);
            reader.onload = (event) => {
                const csv = event.target.result;
                const data = jCsv.toArrays(csv);
                console.log(data)
                
                data.slice(1).forEach(value => {
                    this.vehicles.push({
                        vehiclePlate: value[0],
                        registrationExpiry: this.formatDate(value[1]),
                        GMV: Number(value[2]),
                        driveVehicle: value[3].toLowerCase() == 'yes' ? 'true' : 'false',
                        concreteAgitator: value[4].toLowerCase() == 'yes' ? 'true' : 'false',
                        livestockVehicle: value[5].toLowerCase() == 'yes' ? 'true' : 'false',
                        vin: value[6],
                        fleetId: value[7],
                        vehicleClass: value[8] ? value[8].trim() : 'HV',
                        vehicleType: value[9] ? _.startCase(value[9].toLowerCase()) : 'Prime Mover',
                        frontCouplings: [],
                        rearCouplings: [],
                        powerCouplings: [],
                        brakeType: null,
                        brakeFoundation: null,
                        supplyVoltageToTrailer: null,
                        lAETBSysSupplier: null,
                        tSTBCSRefNumber: null,
                        comments: null,
                        status: 'pending',
                        checked: false,
                    })
                });

                console.log(this.vehicles)

                this.loading = false;
                this.$emit("nextStep", { vehicles: this.vehicles });
            }
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
