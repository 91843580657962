<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
        <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="text-red-500 font-bold text-sm text-center my-5" v-if="errorMessage">
      {{errorMessage}}
    </div>
    <FormulateForm
      v-model="values"
      @submit="onSubmit"
      #default="{ hasErrors }"
    >
      <FormulateInput
        type="text"
        name="apiKey"
        label="API Key"
        validation="required"
      />
      <FormulateInput
        type="text"
        name="apiSecret"
        label="API Secret"
        validation="required"
      />
      <div class="grid grid-cols-2 gap-4">
        <div>
          <div class="font-medium text-sm mb-1">Surcharge Fee (%)</div>
          <div class="relative flex">
            <div class="formulate-input-icon">%</div>
            <FormulateInput
              type="text"
              name="surchargePercentageFee"
              placeholder="0.00"
              :outer-class="['w-full']"
              :wrapper-class="['flex flex-col']"
              :input-class="['pl-16']"
            />
          </div>
        </div>
        <div>
          <div class="font-medium text-sm mb-1">Other Fees</div>
          <div class="relative flex">
            <div class="formulate-input-icon">$</div>
            <FormulateInput
              type="text"
              name="otherFees"
              placeholder="0.00"
              :outer-class="['w-full']"
              :wrapper-class="['flex flex-col']"
              :input-class="['pl-16']"
            />
          </div>
        </div>
      </div>
      <div class="custom-options">
        <FormulateInput
          type="radio"
          name="default"
          label="Set as default payment?"
          :options="{ 'true': 'Yes', 'false': 'No' }"
          value="false"
          validation="required"
        />
      </div>
      <div class="custom-options">
        <FormulateInput
          type="radio"
          name="status"
          label="Set as active?"
          :options="{ 'true': 'Yes', 'false': 'No' }"
          value="true"
          validation="required"
        />
      </div>
      
      <FormulateInput
        type="submit"
        label="Submit"
        input-class="btn btn-primary w-full"
        :disabled="hasErrors || loading || submitting"
        :style="{backgroundColor: theme.secondaryColor}"
      >
        <span v-if="submitting"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving data...</span>  
        <span v-if="!submitting">Submit</span>  
      </FormulateInput>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'


export default {
  name: "Edit-Payment-Gateway-Form",
  props: {
    detailsId:{
      type: String,
      default: '',
    },
    msg: String,
  },
  data() {
    return {
      error: false,
      errorMessage: "",
      loading: false,
      submitting: false,
      values: {}
    };
  },
  async mounted() {
    this.loading = true;
    const paymentGateway = await this.$store.dispatch("payment/getPaymentGateway", this.detailsId);
    if (paymentGateway) {
      this.values = this.parsePaymentGateway(paymentGateway);
    }
    this.loading = false;
  },
  methods: {
    async onSubmit() {
      this.submitting = true;
      try {
        await this.$store.dispatch("payment/updatePaymentGateway", {
          id: this.detailsId,
          apiKey: this.values.apiKey,
          apiSecret: this.values.apiSecret,
          surchargePercentageFee: this.values.surchargePercentageFee,
          otherFees: this.values.otherFees,
          isDefault: this.values.default === "true" ? true : false,
          isActive: this.values.status === "true" ? true : false,
        });
        this.submitting = false;
        this.$emit('closeRefresh');
      } catch (error) {
        this.submitting = false;
        this.errorMessage = error.message;
      }
    },
    parsePaymentGateway(payment) {
      return {
        id: payment._id,
        platform: payment.platform,
        apiKey: payment.apiKey,
        apiSecret: payment.apiSecret,
        surchargePercentageFee: payment.surchargePercentageFee,
        otherFees: payment.otherFees,
        default: payment.isDefault.toString(),
        status: payment.isActive.toString()
      }
    }
  },
   computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
  },
};
</script>

<style lang="scss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>
