import { Prestart } from "@/api"
import _ from "lodash"

export default{
    async getPrestartByBusinessId( { rootGetters } , param ){
        if(typeof param === 'string') param = { businessId:param, query: { skip: 0, limit: 999 } }
        if(!param.businessId) throw 'Invalid UID or ID';
        try{
            if (typeof param.query?.filter != 'object') param.query.filter = {}

            const proxySite = rootGetters[`account/proxySite`];
            if (!_.isEmpty(proxySite) && _.isObject(proxySite)) param.query.filter = { ...param.query.filter, siteId: proxySite._id };

            const response = await Prestart.getPrestartByBusinessId(param.businessId, param.query)
            if(response.data?.resultsMeta){
                return { results : response.data.data, metadata:response.data.resultsMeta }
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },

    async getPrestartById( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Prestart.getPrestartById(id);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async getOldPrestartById( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Prestart.getOldPrestartById(id);
            return response.data.data;
        }catch(error){
            throw error.response.data
        }
    },

    async saveNote({ state }, data) {
        try {
            const response = await Prestart.saveNote(data);
            return response.data.data;
        } catch (error) {
            console.log(state);
            throw error.response.data;
        }
    },
}