<template>
  <div>
    <div class="flex w-full" v-if="loading">
      <div class="m-auto">
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Loading...</span>
      </div>
    </div>
    <div v-if="passwordCreated">
      <h1 class="text-2xl text-green-600 text-center font-bold">Welcome to Logmaster Australia, your account has been created.</h1>
      <div class="text-sm text-center">Redirecting...</div>
    </div>
    <div v-if="!passwordCreated && !hasDriverBusinessProfile">
      <FormulateForm
        v-model="webProfilePasswordForm"
        @submit="onSubmit"
        v-if="!loading"
      >
        <FormulateInput
          type="password"
          name="password"
          label="Password"
          validation="^required"
          :validation-messages="{
            matches: 'Password must contain at least 1 number.',
          }"
        />
        <FormulateInput
          type="password"
          name="confirmPassword"
          label="Confirm Password"
          validation="^required"
          :validation-messages="{
            matches: 'Password must contain at least 1 number.',
          }"
        />
        <div class="flex items-center justify-between w-full">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            class="w-full"
          />
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">
        {{ errorMessage }}
      </div> 
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/commons/ui/Spinner";

export default {
  name: "User-Accept-Form",
  components: { Spinner },
  data() {
    return {
      webProfilePasswordForm: {},
      loading: true,
      errorMessage: "",
      isValidWebProfile: false,
      passwordCreated: false,
      hasDriverBusinessProfile: false
    };
  },
  props: {
    webProfileId: {
      type: String,
      default: null,
    },
  },
  async mounted() {
    try {
      const webProfile = await this.$store.dispatch(
        "rolesandrespo/getUser",
        this.webProfileId
      );
      if (webProfile) {
        this.loading = false;
        this.isValidWebProfile = true;
        this.hasDriverBusinessProfile = webProfile?.hasDriverBusinessProfile;

        if (this.hasDriverBusinessProfile) {
          this.webProfilePasswordForm = {
            password: 'dummayPassword',
            confirmPassword: 'dummayPassword'
          }
          await this.onSubmit();
        }
      } else {
        this.loading = false;
        this.errorMessage = "No business account found!";
        window.location.href = "/login?message=No account found&state=error";
      }
    } catch (error) {
      this.loading = false;
      this.errorMessage = error?.message;
      window.location.href = "/login?message=Invalid request&state=error";
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const webProfilePasswordData = JSON.parse(
        JSON.stringify(this.webProfilePasswordForm)
      );
      if (webProfilePasswordData && this.isValidWebProfile) {
        const updatedWebProfile = await this.$store.dispatch(
          "rolesandrespo/createWebProfilePassword",
          { id: this.webProfileId, password: webProfilePasswordData }
        );
        if (updatedWebProfile) {
          try {
            const acceptedWebProfile = await this.$store.dispatch(
              "rolesandrespo/acceptWebProfile",
              this.webProfileId
            );
            this.loading = false;
            if (acceptedWebProfile) {
              this.passwordCreated = true
              setTimeout(() => {
                window.location.href = '/logout'
              }, 3003);
            } else {
              this.errorMessage = 'User has already accepted the invitation'
              window.location.href = '/login?message=Request already accepted&state=error'
            }
          } catch (error) {
            this.loading = false;
            this.errorMessage = 'Something went wrong'
            window.location.href = '/login?message=Something went wrong&state=error'
          }
        } else {
          this.loading = false;
          this.errorMessage = "User password already created.";
          window.location.href = "/login?message=Request already accepted&state=error";
        }
      }
    },
  },
};
</script>