<template>
  <div>
    <div class="flex justify-between items-center w-full mb-4 mt-5">
      <div class="flex">
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2 cursor-pointer" @click="searchDriver(seachKeyword)">
            <font-awesome-icon icon="search" class="text-gray-400"/>
          </span>
          <input
            v-on:keyup.enter="searchDriver(seachKeyword)"
            type="email"
            placeholder="Search Driver..."
            class="h-full appearance-none rounded border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="seachKeyword"
          />
        </div>
      </div>
    </div>
    <div class="mb-16">
      <div class="text-red-500 font-bold text-sm text-center my-5" v-if="errorMessage.length">
        {{errorMessage[0]}}
      </div>  
      <Table
        v-if="drivers.length"
        :tableHeader="tableHeader"
        :data="drivers"
        :hasFilters="false"
        :hasActions="false"
      >
        <template #default="{data}">
         
          <fragment v-if="data">
            <td class="px-2.5 py-2">
              <button class="btn btn-primary mt-2" @click="nexStep(data)"
              :style="{backgroundColor: theme.secondaryColor}"
              >Select</button>
            </td>
          </fragment>
            
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
    import {Table} from '@/components/commons'
    import { mapGetters } from 'vuex'

    export default{
      name: 'Driver-Lookup',
      components: {Table},
      data(){
        return{
          tableHeader: [
                'Email Address',
                'Driver Name',
                'Licence Number',
                'Action'
            ],
            drivers: [],
            seachKeyword: '',
            errorMessage: [],
            searchedDrivers: [],
          }
      },
  
  methods: {
    //Format table data
    parseDrivers(data){
      console.log('parseDrivers-',data);
      return data.map((driverData) => ([
        {id: driverData._id, name: driverData.emailAddress, itemType: 'email'},
        {id: driverData._id, name: driverData.driverName, itemType: 'name', hasLink: true},
        {id: driverData._id, name: driverData.driversLicenseNumber, itemType: 'string'},
      ]))
    },
    validateEmail:(email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async searchDriver(search) {
      if(!this.validateEmail(search)) this.errorMessage.push('Invalid email address please try again.')
      if(this.validateEmail(search)){
        this.errorMessage = [];
        try{
          const soloDrivers = await this.$store.dispatch(`driver/getDriverByEmail`,search);
          this.searchedDrivers = soloDrivers
          console.log('w--',soloDrivers);
          if(soloDrivers){
             this.drivers =this.parseDrivers([soloDrivers])
          }else{
            this.drivers = [];
          }
        }catch(error){
          this.errorMessage = [error.message];
          this.drivers = [];
        }
      }
    },
    nexStep(data){
      data.type='driver'
      data.name = this.searchedDrivers.persona.contactUserName
      this.$emit('onSelect', data)
    }
  },
  computed: {
      ...mapGetters('theme', {
      theme: 'getColorScheme'
      })
  },
}
</script>