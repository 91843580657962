<template>
  <div>
    <div>
      <ComplianceReport :isActive="activeTab === 'NZ'" />
    </div>
  </div>
</template>


<script>
import ComplianceReport from "@/pages/business/ComplianceReport.vue";
export default {
  name: "ReportCompliance",
  components: { ComplianceReport },
  data(){
    return{
      activeTab: 'NZ'
    }
  },
  methods: {
    getActiveTab (tab){
      this.activeTab = tab
    }
  }
}
</script>