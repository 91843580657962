<template>
  <aside>
    <div class="overflow-y-auto flex flex-col border-b">
      <p class="text-sm py-4 px-3" :style="{color: theme.primaryColor}">Component Settings</p>
    </div>
    
    <div v-if="formFieldData.component" class="pl-5 pr-2 mt-2">
      <template v-if="formFieldData.component == 'plain-text'">
        <TextSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'text-control'">
        <TextInputSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'number-control'">
        <NumberSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'email-control'">
        <EmailSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'password-control'">
        <PasswordSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'textarea-control'">
        <TextAreaSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'select-control'">
        <SelectSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'datepicker-control'">
        <DatePickerSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'timepicker-control'">
        <TimePickerSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'checkbox-control'">
        <CheckBoxSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'radiobutton-control'">
        <RadioSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'switch-control'">
        <SwitchSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'image-control'">
        <ImageSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'signaturepad-control'">
        <SignatureSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'documentlink-control'">
        <DocumentLinkSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'file-control'">
        <FileDocumentSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
      <template v-if="formFieldData.component == 'vehicle-select-control'">
        <VehicleSelectSettings 
          v-on="$listeners" 
          :uniqueId="watchUniqueId" 
          :watchedCurrentSection="watchedCurrentSection"
          :watchedCurrentControl="watchedCurrentControl"
          />
      </template>
    </div>
    <div v-show="!watchUniqueId" class="border-orange-500 text-gray-300 p-4" role="alert">
      <p class="font-bold">-</p>
    </div>
  </aside>
</template>
<script>
import TextSettings from '@/views/builder/form-settings/TextSettings'
import TextInputSettings from '@/views/builder/form-settings/TextInputSettings'
import NumberSettings from '@/views/builder/form-settings/NumberSettings'
import EmailSettings from '@/views/builder/form-settings/EmailSettings'
import PasswordSettings from '@/views/builder/form-settings/PasswordSettings'
import TextAreaSettings from '@/views/builder/form-settings/TextAreaSettings'
import SelectSettings from '@/views/builder/form-settings/SelectSettings'
import DatePickerSettings from '@/views/builder/form-settings/DatePickerSettings'
import TimePickerSettings from '@/views/builder/form-settings/TimePickerSettings'
import CheckBoxSettings from '@/views/builder/form-settings/CheckBoxSettings'
import RadioSettings from '@/views/builder/form-settings/RadioSettings'
import SwitchSettings from '@/views/builder/form-settings/SwitchSettings'
import ImageSettings from '@/views/builder/form-settings/ImageSettings'
import SignatureSettings from '@/views/builder/form-settings/SignatureSettings'
import DocumentLinkSettings from '@/views/builder/form-settings/DocumentLinkSettings'
import FileDocumentSettings from '@/views/builder/form-settings/FileDocumentSettings'
import VehicleSelectSettings from '@/views/builder/form-settings/VehicleSelectSettings'
import { HELPER } from '@/api/helper';
import { mapGetters } from 'vuex'

export default {
  components: {
    TextSettings,
    TextInputSettings,
    NumberSettings,
    EmailSettings,
    PasswordSettings,
    TextAreaSettings,
    SelectSettings,
    DatePickerSettings,
    TimePickerSettings,
    RadioSettings,
    SwitchSettings,
    ImageSettings,
    SignatureSettings,
    DocumentLinkSettings,
    CheckBoxSettings,
    FileDocumentSettings,
    VehicleSelectSettings
  },
  props: {
    uniqueId: {
      type: String,
      default: ''
    },
    currentSection: {
      type: Object,
      default: null
    },
    currentControl: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    formFieldData: {},
    watchUniqueId: '',
    watchedCurrentSection: null
  }),

  watch: {
    selectedControl(newVal) {
      this.formFieldData = HELPER.cloneDeep(newVal)
    },
    uniqueId(newVal) {
      this.watchUniqueId = newVal
    },
    currentSection(newVal) {
      this.watchedCurrentSection = newVal
    },
    currentControl(newVal) {
      this.watchedCurrentControl = newVal
    }
  },

  created() {
    this.formFieldData = HELPER.cloneDeep(this.selectedControl)
  },

  computed: {
    selectedControl: {
      get() {
        return this.$store.state.form.selectedControl
      },
      set(newSelectedControl) {
        return newSelectedControl
      }
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>
