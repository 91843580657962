export default [
  {
      type: 'group',
      name: 'persona',
      children: [
        // {
        //   type: 'text',
        //   name: 'contactUserName',
        //   label: 'Username',
        //   validation:'required:trim'
        // },
        {
          component: 'div',
          class: 'grid grid-cols-2 gap-4',
          children: [
            {
              type: 'email',
              name: 'contactEmail',
              label: 'Contact Email',
              validation: '^required|email'
            },
            {
              type: 'text',
              name: 'contactPhoneNumber',
              label: 'Contact Phone Number',
              validation: 'required'
            },
          ]
        },
      ]
    }
  ]