<template>
  <div :data-type="context.type">
    <div>
      <a class="text-blue-400" :href="context.value">{{ context.value }}</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    context: {
      type: Object,
      required: false
    },
    isDisplayValue: {
      type: Boolean,
      default: false
    }
  },

  methods: {}
}
</script>
