<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit" class="relative">
      <div class="w-full" v-if="loading">
        <div
          class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
        >
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300"
            >Initializing events...</span
          >
        </div>
      </div>
      <div class="mb-2">
        <div class="grid grid-cols-5 text-xs">
          <div class="flex flex-col">
            <h6 class="font-bold px-3 py-2 text-gray-500 bg-gray-300">
              Driver Name
            </h6>
            <p
              class="flex-1 items-center flex px-3 py-2 border-2 border-t-0 border-gray-300"
            >
              {{ driver }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="font-bold px-3 py-2 text-gray-500 bg-gray-300">
              Fatigue Plan
            </h6>
            <p
              class="flex-1 items-center flex px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300 uppercase"
            >
              {{ values.selectedPlan }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="font-bold px-3 py-2 text-gray-500 bg-gray-300">
              Vehicle
            </h6>
            <p
              class="flex-1 items-center flex px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ values.selectedVehicle || '&nbsp;' }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="font-bold px-3 py-2 text-gray-500 bg-gray-300">
              Event Date
            </h6>
            <p
              v-if="
                values.selectedDateRange && values.selectedDateRange.length > 0
              "
              class="flex-1 items-center flex px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ values.selectedDateRange[0] }} to<br />{{
                values.selectedDateRange[1]
              }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="font-bold px-3 py-2 text-gray-500 bg-gray-300">
              Time Zone
            </h6>
            <p
              class="flex-1 items-center flex px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ values.selectedTimezone }}
            </p>
          </div>
        </div>
      </div>
      <div class="w-full flex h-10">
        <StyledButton
          @click.prevent="goToDate(true)"
          :disabled="disabledPrevBtn"
          :borderColor="theme.secondaryColor"
          :hoverBorderColor="theme.secondaryColor"
          class="px-3 text-sm text-gray-300 border-2 border-gray-300 disabled:opacity-50"
        >
          <font-awesome-icon icon="chevron-left" />
        </StyledButton>
        <div
          class="manual-event-date flex-1 text-center justify-center border-2 border-gray-300 border-l-0 border-r-0 text-gray-300 text-sm relative leading-10"
          :class="[
            value.isActive ? 'active' : 'hidden',
            `manual-event-date-${value.dateOfSheet}`,
          ]"
          :style="{ color: theme.secondaryColor }"
          v-for="value in Object.values(eventDetails)"
          :key="value.dateOfSheet"
        >
          <span>{{ formatDate(value.dateOfSheet, 'MMM DD, YYYY') }}</span>
        </div>
        <StyledButton
          @click.prevent="goToDate(false)"
          :disabled="disabledNextBtn"
          :borderColor="theme.secondaryColor"
          :hoverBorderColor="theme.secondaryColor"
          class="px-3 text-sm text-gray-300 border-2 border-gray-300 disabled:opacity-50"
        >
          <font-awesome-icon icon="chevron-right" />
        </StyledButton>
      </div>

      <div class="flex overflow-x-hidden">
        <div
          class="manual-event-container w-full"
          v-for="value in Object.values(eventDetails)"
          :class="[
            value.isActive ? 'active' : 'hidden',
            `manual-event-date-${value.dateOfSheet}`,
          ]"
          :key="value.dateOfSheet"
        >
          <div
            :id="`childLhv${value.dateOfSheet.replace(/-/g, '')}`"
            class="manual-events inline-block w-full"
          ></div>

          <Tabs class="manual-event-tabs mt-3">
            <Tab title="Events">
              <div class="overflow-x-auto border-2 border-gray-300 mt-3">
                <table class="min-w-full">
                  <thead class="bg-gray-300">
                    <tr class="text-gray-700 text-xs tracking-wider uppercase">
                      <th scope="col" class="py-3 px-5 text-center">
                        Activity
                      </th>
                      <th scope="col" class="py-3 px-5 text-center">
                        Time of<br />Activity
                      </th>
                      <th scope="col" class="py-3 px-5 text-left">Location</th>
                      <th scope="col" class="py-3 px-5 text-center">
                        Odometer
                      </th>
                      <th scope="col" class="py-3 px-5 text-center">Vehicle</th>
                      <th
                        scope="col"
                        class="py-3 px-5 text-center"
                        style="min-width: 130px"
                      >
                        Work and<br />Rest Option
                      </th>
                      <th scope="col" class="py-3 px-5 text-left">Comments</th>
                      <th scope="col" class="py-3 px-5 text-center">Origin</th>
                      <th scope="col" class="py-3 px-5 text-left">
                        Entry Timestamp
                      </th>
                      <th scope="col" class="py-3 px-5 text-left">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-show="value.events.length > 0"
                      v-for="(event, index) in value.events"
                      :key="index"
                      class="bg-white border-b text-xs text-gray-500 font-medium"
                    >
                      <td
                        class="py-3 px-5 text-center text-black-primary capitalize"
                      >
                        {{ event.type }}
                      </td>
                      <td class="py-3 px-5 text-center">
                        {{ event.timeOfActivity }}
                      </td>
                      <td class="py-3 px-5">
                        {{ event.locationAddress }}
                      </td>
                      <td class="py-3 px-5 text-center">
                        {{ event.odometer }}
                      </td>
                      <td class="py-3 px-5 text-center">
                        {{ event.vehicle }}
                      </td>
                      <td class="py-3 px-5 text-center uppercase">
                        {{ event.fatiguePlan }}
                      </td>
                      <td class="py-3 px-5">
                        {{ event.comment }}
                      </td>
                      <td class="py-3 px-5 text-center capitalize">
                        {{ event.origin }}
                      </td>
                      <td class="py-3 px-5">
                        {{ event.originalStartTime }}
                      </td>
                      <td class="py-3 px-5 uppercase">
                        {{ event.status }}
                      </td>
                    </tr>
                    <tr v-show="value.events.length == 0">
                      <td colspan="10" class="py-3 px-5 text-center text-sm">
                        No events found.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab>
            <Tab title="Breaches" :badge="value.breaches.length">
              <div class="overflow-x-auto border-2 border-gray-300 mt-2">
                <table class="min-w-full">
                  <thead class="bg-gray-300">
                    <tr class="text-gray-700 text-xs tracking-wider uppercase">
                      <th scope="col" class="py-3 px-5 text-center">
                        Breach Time
                      </th>
                      <th scope="col" class="py-3 px-5 text-left">Option</th>
                      <th scope="col" class="py-3 px-5 text-left">Rule</th>
                      <th scope="col" class="py-3 px-5 text-left">Rule Type</th>
                      <th scope="col" class="py-3 px-5 text-left">Level</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-show="value.breaches.length > 0"
                      v-for="(breach, index) in value.breaches"
                      :key="index"
                      class="bg-white border-b text-xs text-gray-500 font-medium"
                    >
                      <td
                        class="py-3 px-5 text-center text-black-primary capitalize"
                      >
                        {{ formatTime(breach.time, breach.timezone) }}
                      </td>
                      <td class="py-3 px-5">
                        {{ breach.option }}
                      </td>
                      <td class="py-3 px-5">
                        {{ breach.period }}
                      </td>
                      <td class="py-3 px-5">
                        {{ breach.type }}
                      </td>
                      <td class="py-3 px-5">
                        {{ cleanLevel(breach.level) }}
                      </td>
                    </tr>
                    <tr v-show="value.breaches.length == 0">
                      <td colspan="10" class="py-3 px-5 text-center text-sm">
                        No breaches found.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>

      <div class="grid grid-cols-2 gap-4 my-5">
        <button
          class="btn bg-gray-300 hover:bg-gray-400 w-full mb-3"
          @click.prevent="onPrevious()"
        >
          Back
        </button>
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="submitting"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
            data...</span
          >
          <span v-if="!submitting">Submit</span>
        </FormulateInput>
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import { StyledButton } from '@/styled-components';
import { getStateSubInAddress } from '@/_helper';
import Tab from '@/components/commons/tab/Tab';
import Tabs from '@/components/commons/tab/Tabs';
import Spinner from '@/components/commons/ui/Spinner';
import LocationData from '@/assets/location/location.json';
import LogmasterManualEvents from '@logmaster/logmaster-manual-events';
import { mapGetters } from 'vuex';

export default {
  name: 'Manual-Event-Confirmation',
  props: {
    msg: String,
    preData: null,
  },
  components: { Spinner, Tabs, Tab, StyledButton },
  data() {
    return {
      submitting: false,
      manualEvent: null,
      loading: false,
      values: {},
      errorMessage: '',
      schema: [],
      file: null,
      breaches: [],
      eventDetails: [],
      dateFormat: 'YYYY-MM-DD',
      disabledPrevBtn: false,
      disabledNextBtn: false,
      dateRange: [],
    };
  },
  computed: {
    driver: {
      get() {
        return this.values.selectedDriver?.driver?.driverName;
      },
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
  async mounted() {
    if (!_.isEmpty(this.preData['file'])) {
      this.file = this.preData['file'];
    }

    if (!_.isEmpty(this.preData['breaches'])) {
      this.breaches = this.preData['breaches'];
    }

    if (!_.isEmpty(this.preData['eventDetails'])) {
      this.loading = true;

      // Setup manual event styles
      const logmasterManualEvents = new LogmasterManualEvents();
      $('head').append(
        `<style type="text/css">${logmasterManualEvents.getStyles()}</style>`
      );

      for (const [index, details] of this.preData['eventDetails'].entries()) {
        if (index == 0) this.values = details;

        details.isActive = false;
        details.manualEvent = null;
        this.eventDetails[details.dateOfSheet] = details;
      }
      Object.values(this.eventDetails)[0].isActive = true;
      this.disabledPrevBtn = true;
      this.disabledNextBtn =
        Object.values(this.eventDetails).length > 1 ? false : true;
      this.dateRange = this.values.selectedDateRange;

      // Add delay for the dom elements to get created
      setTimeout(() => {
        for (const value of Object.values(this.eventDetails)) {
          value.events.forEach((event) => {
            const location = !_.isEmpty(event['location']['address'])
              ? event['location']['address']
              : event['location']['formatted_address'];
            const rawLocation = getStateSubInAddress(location);
            event['suburb'] = !_.isEmpty(rawLocation)
              ? rawLocation['suburb']
              : null;
            event['locationAddress'] = !_.isEmpty(event['suburb'])
              ? event['suburb']
              : location;

            if (_.isEmpty(event['timezone']))
              event['timezone'] = value.selectedTimezone;
            const timezone =
              !_.isEmpty(event['timezone']) && event['timezone'].length > 1
                ? event['timezone'].split(' ')[1]
                : event['timezone'];
            event['offset'] = timezone ? timezone : '+10:00';
            event['timeOfActivity'] = moment(event.startTimeWithTimezone)
              .utcOffset(event['offset'])
              .format('HH:mm');
          });

          value.manualEvent = $(
            `#childLhv${value.dateOfSheet.replace(/-/g, '')}`
          );
          value.manualEvent.ManualEvent({
            isViewMode: true,
            isDateVisible: true,
            driverId: value.selectedDriver?.driverId,
            vehicleId: value.selectedVehicle,
            timezone: value.selectedTimezone,
            fatiguePlan: value.selectedPlan,
            locationData: LocationData,
            currentDate: value.dateOfSheet,
            currentEvents: value.events,
            previousEventType: value.previousEventType,
            previousEvent: value.previousEvent,
          });

          value.breaches = value.breaches.filter((breach) => {
            const breachTime = moment(breach.time)
              .utcOffset(breach.timezone)
              .format('YYYY-MM-DD');
            return value.dateOfSheet == breachTime;
          });
          value.manualEvent.ManualEvent('setBreaches', value.breaches);
        }
        this.loading = false;
      }, 1000);
    }
  },
  methods: {
    onPrevious() {
      this.$emit('backStep');
    },
    async onSubmit() {
      const metadata = {
        pending: false,
        eventId: null,
        metadata: {
          'two-up-driver-information': {
            udi: this.values.twoUpUDI,
            driverName: this.values.twoUpName,
            '2up_fatigue_plan': this.values.twoUpFatiguePlan,
            driversLicenseNumber: this.values.twoUpLicenseNumber,
            driversLicenseState: this.values.twoUpLicenseState,
          },
        },
      };

      const me = this.$store.getters[`account/me`];
      let entityId = me[me.role?.name]._id;
      let entityRole = me.role?.name;

      if (me.role.name == 'driver') {
        entityId = me[me.role?.name].entityId;
        entityRole = me[me.role?.name].parentRole;
      }

      let historicEvents = [];
      let allBreaches = [];
      for (const value of Object.values(this.eventDetails)) {
        value.breaches.forEach((breach) => (breach.entityId = entityId));
        const events = _.cloneDeep(value.events).filter((event) => {
          if (
            event.origin.toLowerCase() == 'historic' &&
            _.isEmpty(event.eventId)
          ) {
            delete event.date;
            delete event.eventId;
            delete event.stationary;
            delete event.startTime;
            delete event.endIndex;
            delete event.endTime;
            delete event.locationAddress;
            delete event.offset;
            delete event.startIndex;
            delete event.suburb;
            delete event.timeOfActivity;
            if (_.isEmpty(event.comment)) event.comment = null;
            event.origin = event.origin.toLowerCase();
            event['metadata'] = [];
            if (!_.isEmpty(this.values.twoUpUDI))
              event['metadata'].push(metadata);
            return event;
          }
        });

        historicEvents = [...historicEvents, ...events];
        allBreaches = [...allBreaches, ...value.breaches];
      }

      const data = {
        events: historicEvents,
        breaches: allBreaches,
        file: this.file,
        entityId: entityId,
        entityRole: entityRole,
        dateRange: this.dateRange.join(' to '),
      };

      this.submitting = true;
      await this.$store
        .dispatch(`business/createManualEvents`, data)
        .then(() => {
          this.toast('success', 'Manual events saved.');
          this.$emit('closeModal');
        })
        .catch((err) => {
          this.toast('error', err.message);
        });
      this.submitting = false;
    },
    async toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', message);
    },
    formatTime(time, timezone) {
      return moment(time).utcOffset(timezone).format('YYYY-MM-DD HH:mm:ssZ');
    },
    cleanLevel(level) {
      if (typeof level == 'object') level = level[0];
      return _.startCase(
        level == 'no-breach' ? level.replace('-', ' ') : level.split('-')[0]
      );
    },
    formatDate(date) {
      return moment(date, this.dateFormat).format('MMMM DD, YYYY');
    },
    isDateNotBefore(date) {
      return moment(date, this.dateFormat).isBefore(this.dateRange[0]);
    },
    isDateNotAfter(date) {
      return moment(date, this.dateFormat).isAfter(this.dateRange[1]);
    },
    isPrevDisable(dateValue) {
      return this.isDateNotBefore(
        moment(dateValue, this.dateFormat)
          .subtract(1, 'days')
          .format(this.dateFormat)
      );
    },
    isNextDisable(dateValue) {
      return (
        this.isDateNotAfter(
          moment(dateValue, this.dateFormat)
            .add(1, 'days')
            .format(this.dateFormat)
        ) ||
        moment().format(this.dateFormat) ==
          moment(dateValue, this.dateFormat).format(this.dateFormat)
      );
    },
    goToDate(isPrevDate = false) {
      let currentActive = Object.values(this.eventDetails).find(
        (x) => x.isActive
      );
      const newDate = isPrevDate
        ? moment(currentActive.dateOfSheet, this.dateFormat).subtract(1, 'days')
        : moment(currentActive.dateOfSheet, this.dateFormat).add(1, 'days');
      const activeDate = newDate.format(this.dateFormat);

      // Set current active to false, send new value as active
      currentActive.isActive = false;
      this.eventDetails[activeDate].isActive = true;
      $(`.manual-event-date-${currentActive.dateOfSheet}`).hide();
      $(
        `.manual-event-date-${this.eventDetails[activeDate].dateOfSheet}`
      ).show();
      this.setNavigationButton(this.eventDetails[activeDate].dateOfSheet);
    },
    setNavigationButton(date) {
      this.disabledPrevBtn = this.isPrevDisable(date) ? true : false;
      this.disabledNextBtn = this.isNextDisable(date) ? true : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.manual-events::v-deep {
  .timetable-container {
    @apply border-2 border-gray-300 rounded-b-none rounded-r-none;

    .manual-event-date {
      @apply border-r-0 rounded-none;
    }
    .timetable-header_work,
    .timetable-header_rest {
      @apply border-r-0;
    }

    #events-table .g-bg-blue-200 {
      @apply border-gray-300;
    }
    .ruler-text .time-block:last-child,
    .ruler-text .time-block:first-child span {
      top: 2px !important;
    }
  }
}
.manual-event-tabs::v-deep ul li button {
  @apply text-sm font-bold;
}
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>
