<template>
  <nav
    class="w-full"
    :style="{
      backgroundColor: theme.accentColor,
    }"
  >
    <ul class="top-nav">
      <li
        class="nav-item"
        v-for="(menuItem, index) in menu"
        :key="index"
        @click.prevent="toggleDropDown(menuItem.name)"
      >
        <div v-if="menuItem.link && !menuItem.isExternalLink">
          <router-link :to="menuItem.link">
            <div class="text-sm tracking-wide truncate">
              {{ menuItem.name }}
            </div>
          </router-link>
        </div>
        <div v-else-if="menuItem.link && menuItem.isExternalLink">
          <a :href="menuItem.link" target="_blank">
            <div class="text-sm tracking-wide truncate" @click="navigateToExternalLink(menuItem.link)">
              {{ menuItem.name }}
            </div>
          </a>
        </div>
        <div v-else>
          <div
            class="text-sm tracking-wide truncate"
            :style="{
              ...(menuItem.isHeaderItem && {
                fontWeight: 'bold',
                cursor: 'default',
              }),
            }"
          >
            {{ menuItem.name }}
          </div>
        </div>

        <ul
          v-show="activeSubMenu === menuItem.name"
          class="submenu"
          :style="{
            backgroundColor: theme.primaryColor,
          }"
        >
          <li
            v-for="(subMenuItem, subMenuIndex) in filteredItems(
              menuItem.subitems
            )"
            :key="subMenuIndex"
          >
            <router-link
              :index="subMenuIndex"
              :to="subMenuItem.link"
              :class="{ active: isActive(subMenuItem.link) }"
            >
              <span class="text-xs">{{ subMenuItem.name }}</span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import businessMenus from "./business";
import { BUSINESS_ROLE } from "@/constants/usertype";
export default {
  name: "Topbar",
  components: {},
  props: {},
  data() {
    let accountMenu = [];
    const role = this.$store.getters[`account/roleName`];
    const me = this.$store.getters["account/me"];
    if (role === BUSINESS_ROLE.name) {
      accountMenu = businessMenus;
    }

    if (me?.menuItems?.length > 0) {
      const fatigueModuleMenus = me.menuItems.filter((item) => {
        return item.name === "Fatigue Compliance";
      });

      const kmAndTimeReportModuleMenu = me.menuItems.find((item) => {
        return item.name == "KM & Time";
      });

      const oneAPIModuleMenu = me.menuItems.filter((item) => {
        return item.name === "One API";
      });

      if (kmAndTimeReportModuleMenu) {
        const reportsMenu = accountMenu[0].items.find((menu) => {
          return menu.name == "Reports";
        });

        if (reportsMenu) {
          const reportsMenuIndex = accountMenu[0].items.indexOf(reportsMenu);
          const targetSubItems =
            accountMenu[0].items[reportsMenuIndex].subitems;
          if (
            reportsMenuIndex > 0 &&
            !targetSubItems.includes(kmAndTimeReportModuleMenu.items[0])
          ) {
            accountMenu[0].items[reportsMenuIndex].subitems.push(
              kmAndTimeReportModuleMenu.items[0]
            );
          }
        }
      }

      accountMenu = accountMenu.concat(fatigueModuleMenus);
      accountMenu = accountMenu.concat(oneAPIModuleMenu);
    }

    accountMenu = accountMenu.map((menu) => {
      const { name: headerName, items } = menu;

      const isHeaderItem = items.some((item) => item.name === headerName);
      const isProductionItem = items.some((item) => item.production);

      if (!isHeaderItem) {
        items.unshift({
          isHeaderItem: true,
          name: headerName,
          link: null,
          production: isProductionItem,
        });
      }

      return items;
    });

    return {
      menu: _.flatten(accountMenu),
      me,
      activeSubMenu: null,
    };
  },

  methods: {
    filteredItems(items) {
      if (!items) {
        return [];
      }

      const filteredNavs =
        this.me.contract?.status?.name === "Suspended"
          ? items.filter((data) => !data.hiddenOnSuspendedAccount)
          : items;

      return process.env.NODE_ENV === "production"
        ? filteredNavs.filter((data) => data.production)
        : filteredNavs;
    },
    isActive(link) {
      return this.$route.path == link ? true : false;
    },
    toggleDropDown(menuName) {
      if (this.activeSubMenu === menuName) {
        this.activeSubMenu = null;
      } else {
        this.activeSubMenu = menuName;
      }
    },
    navigateToExternalLink(link) {
      window.open(link, "_blank");
    }
  },

  computed: {
    ...mapGetters({
      theme: "theme/getColorScheme",
    }),
  },
};
</script>

<style scoped lang="scss">
nav {
  color: #fff;
}

.top-nav {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  // overflow-y: hidden;
  // overflow-x: auto;
}

.nav-item {
  position: relative;
  padding: 15px;
  cursor: pointer;
}

.nav-item:hover {
}

/* Style for submenus */
.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  list-style-type: none;
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.submenu li {
  padding: 10px;
}

/* Show submenus on hover */
.nav-item:hover .submenu {
  display: block;
}

/* Ensure submenus stay open when hovered */
.submenu:hover {
  display: block;
}

/* Style for links */
a {
  text-decoration: none;
  color: #fff;
}
</style>
