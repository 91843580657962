import { render, staticRenderFns } from "./ResellerListSelect.vue?vue&type=template&id=0bc8cd33&scoped=true&"
import script from "./ResellerListSelect.vue?vue&type=script&lang=js&"
export * from "./ResellerListSelect.vue?vue&type=script&lang=js&"
import style0 from "./ResellerListSelect.vue?vue&type=style&index=0&id=0bc8cd33&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bc8cd33",
  null
  
)

export default component.exports