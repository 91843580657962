<template>
    <div>
        <div class="loading centered" v-if="loading">
            <ui-spinner class="mx-auto"></ui-spinner>
        </div>

        <div v-if="!loading" class="mb-16">
            <div class="text-center mb-10">
                <div class="font-bold text-black-primary text-2xl"> 
                    {{driver.name}}
                </div>
                <div class="font-bold text-xs" :class="[driver.isActive ? 'text-green-500' : 'text-red-500']">{{driver.isActive ? 'ACTIVE' : 'INACTIVE'}}</div>
            </div>
            <div class="mb-5 w-full border-b pb-1">
                <div class="text-xs font-bold">Driver Details</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{driver.email}}</div>
                <div class="text-xs">Email Address</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{driver.phone}}</div>
                <div class="text-xs">Phone</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{driver.birthday}}</div>
                <div class="text-xs">Date Of Birth</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{driver.license}}</div>
                <div class="text-xs">Licence</div>
            </div>
        </div>
    </div>
</template>
<script>
import {formatDate, convertAddressObject} from '@/_helper'

export default {
    name: 'View-Driver-Details',
    props: {
        activeDriver: {},
        showDelink:{
            type: Boolean,
            default: false,
        },
        showInvite:{
            type: Boolean,
            default: true,
        },
    },
    watch: {
      activeDriver: {
        deep: true,
        handler() {
          this.setactiveDriverData()
        }
      }
    },
    data(){
        return{
            driver: null,
            profile: {},
            status: '',
            businessid: '',
            driverid: '',
            loading: true
        }
    },
    async mounted(){
        this.setactiveDriverData()
        await setTimeout(() => this.loading = false, 300)
    },
    methods: {
        setactiveDriverData(){
            this.driver = this.parseDriver(this.activeDriver);
        },
        parseDriver(driver){
            return {
                baseLocation: convertAddressObject(driver.driversBaseLocation) || "-",
                baseTimezone: driver.driversBaseTimeZone || "-",
                birthday: formatDate(driver.driverDateOfBirth, 'DD/MM/YYYY') || "-",
                bfmNumber: driver.driversBFMNumber || "-",
                driverId: driver._id || "-",
                email: driver.emailAddress || "-",
                isActive: driver.isActive || "-",
                license: driver.driversLicenseNumber || "-",
                name: driver.driverName || "-",
                phone: driver.persona.contactPhoneNumber || "-"
            }
        }
    }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    .centered{
        position: absolute;
        top: 50%; right: 50%;
        transform: translate(50%,-50%);
    }
</style>
