<template>
  <div class="flex min-h-screen justify-center items-center bg-black">
    <div class="container py-10 flex h-full flex-col lg:w-1/2 md:w-2/3 w-full px-4">
      <div class="text-center m-5">
        <a :href="$store.getters['baseURL']"
          ><img src="/assets/images/logo.png" alt="" class="w-64 m-auto"
        /></a>
      </div>
      <div class="">
        <div
          v-if="!loading"
          class="rounded-lg bg-gray-50 p-8 shadow"
        >
          <div class="text-center mb-5">
            <h3
              class="text-lg leading-6 font-black text-black uppercase"
              id="modal-title"
            >
              New Business
            </h3>
          </div>

          <NewBusinessForm
            :windowWidth="windowWidth"
            :mobileViewMaxWidth="mobileViewMaxWidth"
            @newBusinessAdded="newBusinessAdded"
          />
        </div>
        <div
          v-else
          class="rounded-lg bg-gray-50 p-10 shadow flex flex-col items-center h-full mx-auto"
        >
          <ui-spinner class="mx-auto"></ui-spinner>
          Loading data...
        </div>
      </div>
      <!-- <div v-else>
        <div v-if="!loading" class="rounded-lg bg-gray-50 p-10 shadow">
          <div class="text-center mb-5">
            <h1 class="text-md text-center font-bold">
              An email has been sent to {{ business?.contactEmail }}.<br />
              Continue with your email to create a password and proceed with
              your registration.
            </h1>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { NewBusinessForm } from "@/components/forms/business";
export default {
  name: "Business-Creation",
  components: {
    NewBusinessForm,
  },
  data() {
    return {
      businessId: null,
      loading: false,
      business: {},
      isBusinessAdded: false,
      windowWidth: window.innerWidth,
      mobileViewMaxWidth: 620,
    };
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    newBusinessAdded(business) {
      this.isBusinessAdded = true;
      this.business = business.data;
      window.location.href = "/login?message=Lead Created Successfully";
    },
  },
};
</script>
<style scoped lang="scss">
.item {
  width: auto;
  height: 80px;
  margin: 5px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
}
</style>
