<template>
  <div class="mb-16">
    <div v-if="!hasProfile && !isLoading">
      <FormulateForm
        v-model="values"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <FormulateInput
          type="email"
          label="Email Address"
          name="email"
          required
          validation="^required"
          placeholder="Email Address"
        />

        <FormulateInput
          type="submit"
          label="Update Email"
          name="newEmail"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors || isLoading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </FormulateForm>
    </div>
    <div v-else-if="!isLoading && hasProfile">
      <div class="text-center mt-2">
        <p class="text-md text-gray-500">
          Driver already has an existing profile.
        </p>
      </div>
    </div>
    <div v-else-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "edit-driver-email",
  props: {
    detailsId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      hasProfile: false,
      values: {
        newEmail: "",
      },
    };
  },

  async mounted() {
    this.isLoading = true;
    const driverDetails = await this.$store.dispatch(
      `business/getContractById`,
      this.detailsId
    );

    if (driverDetails && driverDetails?.driver?.webProfiles?.length > 0) {
      this.hasProfile = true;
    }
    this.isLoading = false;
  },

  methods: {
    async onSubmit(data) {
      this.isLoading = true;
      const { email } = data;

      const updateProfile = await this.$store.dispatch(
        `business/updateDriverEmail`,
        { contractId: this.detailsId, email: email.toLowerCase() }
      );

      if (updateProfile?.data?.success) {
        this.toast("success", updateProfile?.data?.message);
      } else {
        this.toast("error", updateProfile?.data?.message);
      }

      this.$emit("refetch", "Pending");
      this.$emit("closeModal");
      this.isLoading = false;
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
