<template>
  <div>
    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
    <FormulateForm v-model="loginForm" @submit="onSubmit">
      <FormulateInput
        type="text"
        name="usernameEmail"
        label="Username"
        validation="^required"
        :validation-messages="{
          matches: 'Password must contain at least 1 number.',
        }"
      />
      <FormulateInput
        type="password"
        name="password"
        label="Password"
        validation="^required"
        :validation-messages="{
          matches: 'Password must contain at least 1 number.',
        }"
      />
      <div class="w-full">
        <FormulateInput
          type="submit"
          label="Log In"
          input-class="btn btn-primary w-full"
          v-if="!isLoading"
        />
        <FormulateInput
          type="button"
          input-class="btn btn-primary w-full"
          v-else
        >
          <font-awesome-icon :icon="['fas', 'spinner']" spin />&nbsp; Logging
          in....
        </FormulateInput>
      </div>
      <!-- Commented code block will be used later as requested on ticket LHV-866 -->
      <!-- <div class="flex items-center justify-between"> -->
      <div class="w-full">
        <!-- <Button
          class="btn btn-primary w-full text-xs mr-2"
        >Help Centre</Button> -->
        <Button
          @click.prevent="toggleForm('forgetPassword')"
          class="btn mb-4 btn-cancel w-full"
          >Forgot Password?</Button
        >
        <div class="w-full mb-3 text-center text-l text-black-primary">
          For any assistance with Logmaster<br />please contact the office on
          (02) 7228 6269
        </div>
<!--        <div class="grid-container">-->
<!--          <div class="text-right">-->
<!--            <Button-->
<!--              @click.prevent="navigateToSignUpDriver()"-->
<!--              class="btn text-black-primary"-->
<!--              >SOLO</Button-->
<!--            >-->
<!--          </div>-->
<!--          <div class="text-center mt-1">|</div>-->
<!--          <div class="text-left">-->
<!--            <Button-->
<!--              @click.prevent="navigateToSignUpBusiness()"-->
<!--              class="btn text-black-primary"-->
<!--              >BUSINESS</Button-->
<!--            >-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { GEOTAB_URL } from "@/_helper/constants";
import VueCookies from "vue-cookies";
export default {
  name: "LoginForm",
  props: {
    msg: String,
  },
  data() {
    return {
      loginForm: {},
      error: false,
      errorMessage: "",
      isLoading: false,
    };
  },
  mounted() {
    if (this.$route.query.message) {
      const acceptedStates = ['success', 'error'];
      let state = this.$route.query.state? this.$route.query.state: 'success';
      const isAcceptedState = acceptedStates.includes(state);
      if (!isAcceptedState) state = 'success';
      this.toast(state, this.$route.query.message);
    }

    const expiredToken = sessionStorage.getItem('expired');
    if (expiredToken) {
      sessionStorage.removeItem('expired');
      this.toast('error', 'Authorization token has expired, please login again.');
    }
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      try {
        const login = await this.$store.dispatch(
          `account/login`,
          this.loginForm
        );
        if (login && login._id) {
          // Get webprofile and check if 2FA was enabled
          const webprofiles = await this.$store.dispatch(`rolesandrespo/getWebProfilesByUID`, login.uid)
          const webprofile = webprofiles.filter(profile => profile?.twoFactorData)
          if (webprofile.length > 0) {
            localStorage.setItem('two-factor-prompt', true);
            await this.$swal.fire({
                title: '2-Step Verification',
                input: 'text',
                inputLabel: 'Get a verification code from the Google Authenticator app',
                inputValue: '',
                showCancelButton: false,
                confirmButtonText: 'Verify',
                inputValidator: async (value) => {
                  if (!value) return 'Verification code is required.'

                  const valid = await this.$store.dispatch(`account/verifyCode`, { secret: webprofile[0].twoFactorData?.secret, code: value });
                  if (!valid.success) {
                    return 'Verification code is invalid.'
                  }
                }
            })
            localStorage.removeItem('two-factor-prompt');
          }
          
          let redirect = "/";
          const geotabUrl = await VueCookies.get(GEOTAB_URL);
          if (login["role"]) redirect = this.$store.getters["allURL"][login["role"]["name"]]["default"];
          if (geotabUrl) redirect = geotabUrl;
          window.location.href = redirect;
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = error.message;
        this.isLoading = false;
      }
    },
    toggleForm(form) {
      this.$emit("toggleForm", form);
    },
    navigateToSignUpDriver() {
      this.$router.push("soloproviders");
    },
    navigateToSignUpBusiness() {
      this.$router.push("businessproviders");
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-gap: 20px;
  max-height: 400px;
}
</style>
