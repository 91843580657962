import { 
    createBreachEngineFromLastEvent,
    BreachStatus
} from '@logmaster/logmaster-nz-breach';

const generateBreaches = (driverId, businessId, events, timezone) => {
    const breachEngine = createBreachEngineFromLastEvent(
        events,
        timezone
    );

    return getBreachCountingPoints(breachEngine.countingPointEvents, driverId, businessId);
}

function getBreachCountingPoints(countingPointEvents, driverId, businessId) {
    const breachCountingPointsEvents = countingPointEvents.sort((a, b) => b.countingPointStart - a.countingPointStart).filter(countingPoint => {
        if (Array.isArray(countingPoint.breachResult)) {
            return (countingPoint.breachResult.includes(BreachStatus.SUBSTANTIAL_BREACH) ||
                countingPoint.breachResult.includes(BreachStatus.CRITICAL_BREACH) ||
                countingPoint.breachResult.includes(BreachStatus.MINOR_BREACH) ||
                countingPoint.breachResult.includes(BreachStatus.SEVERE_BREACH));
        }
        return (countingPoint.breachingPoint &&
            countingPoint.breachResult !== BreachStatus.NO_BREACH &&
            countingPoint.breachResult !== BreachStatus.COMPLETED);
    })

    const now = new Date();
    const breachesMapped = breachCountingPointsEvents.map(cpe => {
        return {
            level: cpe.breachResult,
            option: cpe.rulesetName,
            period: cpe.rule.Rule,
            time: cpe.breachingPointFormatted,
            timezone: cpe.timezone,
            type: cpe.countingType,
            created: now,
            driverId: driverId,
            business: businessId,
            location: cpe.events ? cpe.events[cpe.events.length-1].location.address : '',
            startPoint: cpe.countingPointStartWithTimezone,
            breachTime: cpe.countingType === 'Work' ? cpe.totalWorkFormatted : cpe.totalRestFormatted,
            noBreach: cpe.rule.Breaches.NoBreach
        }
    })

    return breachesMapped;
}

export { generateBreaches }