export default{
    getColorScheme:(state) => {
        return state.colorScheme
    },
    getIsDarkMode:(state) => {
        return state.isDarkMode
    },
    getThemeLists:(state) => {
        return state.themeOptions
    },
    getActiveTheme:(state) => {
        return state.activeTheme
    },
    getIsInitializedTheme:(state) => {
        return state.initializedTheme
    },
    getColorOptions:(state) => {
        return state.themeOptions.filter((theme) => !theme.isDarkMode)
    },
    getIsEnableDynamicTheme:(state) => {
        return state.isEnableDynamicTheme
    },
}