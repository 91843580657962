<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading" class="text-center mb-10">
      <div class="flex text-right justify-end">
        <a
          v-if="writeRoles"
          @click.prevent="onClickEdit()"
          class="flex cursor-pointer"
          ><font-awesome-icon
            icon="edit"
            class="my-auto mx-1.5 hover:text-orange-primary text-xs"
        /></a>
        <a
          v-if="!role.isAdmin && writeRoles"
          @click.prevent="onClickDelete()"
          class="flex cursor-pointer"
          ><font-awesome-icon
            icon="trash"
            class="my-auto mx-1.5 hover:text-orange-primary text-xs"
        /></a>
      </div>
      <div
        class="
          font-bold
          text-gray-600 text-4xl
          border-4
          rounded
          w-max
          p-2
          mx-auto
          mb-2
        "
      >
        {{ role.name }}
      </div>
      <div
        class="font-bold text-xs mb-5"
        :class="[role.isActive ? 'text-green-500' : 'text-red-500']"
      >
        {{ role.isActive ? "ACTIVE" : "INACTIVE" }}
      </div>
    </div>
    <div class="mb-5 w-full border-b pb-1">
      <div class="text-xs font-bold">Role Details</div>
    </div>
    <div class="mb-5">
      <div class="font-bold text-black-primary text-base">
        {{ role.description }}
      </div>
      <div class="text-xs">Role Description</div>
    </div>
    <div class="mb-5">
      <div class="font-bold text-black-primary text-base">
        <div class="whitespace-no-wrap text-lg flex">
          {{ role.parentRoleId ? role.parentRoleId.name : "None" }}
          <a
            v-if="!!role.parentRoleId && writeRoles"
            @click.prevent="onClickUnlinkParentRole(role.parentRoleId)"
            class="flex cursor-pointer"
            ><font-awesome-icon
              icon="trash"
              class="my-auto mx-1.5 hover:text-orange-primary text-xs"
          /></a>
        </div>
      </div>
      <div class="text-xs">Parent Role</div>
    </div>
    <div v-if="role.depotId" class="mb-5 w-full border-b pb-1">
      <div class="text-xs font-bold">Depot Details</div>
    </div>
    <div v-if="role.depotId" class="mb-5">
      <div class="font-bold text-black-primary text-base">
        {{ role.depotId }}
      </div>
      <div class="text-xs">Depot id</div>
    </div>
    <div class="mb-5 w-full border-b pb-1">
      <div class="text-xs font-bold">Linked Responsibilities</div>
    </div>
    <div class="mb-5">
      <ul>
        <li v-for="(respo, index) in role.responsibilities" :key="index">
          <div class="whitespace-no-wrap text-lg flex">
            {{ index + 1 }}. {{ respo.name }}
            <a
              v-if="writeRoles"
              @click.prevent="onClickUnlinkRespo(respo)"
              class="flex cursor-pointer"
              ><font-awesome-icon
                icon="trash"
                class="my-auto mx-1.5 hover:text-orange-primary text-xs"
            /></a>
          </div>
        </li>
      </ul>
      <ul
        v-if="role && role.responsibilities && role.responsibilities.length < 1"
      >
        <li>No responsibility linked.</li>
      </ul>
    </div>
    <div class="mb-5 w-full border-b pb-1">
      <div class="text-red-500 text-center mb-3" v-if="error">
        {{ errorMessage }}
      </div>
      <div class="text-xs font-bold">Linked Users</div>
    </div>
    <div class="mb-5">
      <ul>
        <li v-for="(user, index) in role.users" :key="index">
          <div class="whitespace-no-wrap text-lg flex">
            {{ index + 1 }}. {{ user.userName }}
            <a
              v-if="writeRoles"
              @click.prevent="onClickUnlinkUser(user)"
              class="flex cursor-pointer"
              ><font-awesome-icon
                icon="trash"
                class="my-auto mx-1.5 hover:text-orange-primary text-xs"
            /></a>
          </div>
        </li>
      </ul>
      <ul v-if="role && role.users && role.users.length < 1">
        <li>No users linked.</li>
      </ul>
    </div>
    <div class="mb-5 w-full border-b pb-1">
      <div class="text-red-500 text-center mb-3" v-if="error">
        {{ errorMessage }}
      </div>
      <div class="text-xs font-bold">Linked Depots</div>
    </div>
    <div class="mb-5">
      <ul>
        <li v-for="(depot, index) in role.depots" :key="index">
          <div class="whitespace-no-wrap text-lg flex">
            {{ index + 1 }}. {{ depot.name }}
            <a
              v-if="writeRoles"
              @click.prevent="onClickUnlinkDepot(depot)"
              class="flex cursor-pointer"
              ><font-awesome-icon
                icon="trash"
                class="my-auto mx-1.5 hover:text-orange-primary text-xs"
            /></a>
          </div>
        </li>
      </ul>
      <ul v-if="role && role.depots && role.depots.length < 1">
        <li>No depots linked.</li>
      </ul>
    </div>
  </div>
</template>

<script>
// import { formatDate } from "@/_helper";

export default {
  name: "Role-Details-View",
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      role: {
        id: null,
        responsibilities: [],
        uids: [],
        name: null,
      },
      loading: false,
      writeRoles: false,
      error: false,
      errorMessage: "",
    };
  },
  async mounted() {
    this.loading = true;
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.writeRoles = securityAccessObj.writeRoles;
    }

    const roleDetails = await this.$store.dispatch(
      `rolesandrespo/getRoles`,
      this.detailsId
    );
    if (roleDetails) {
      this.role = this.parseRole(roleDetails);
    }
    this.loading = false;
  },
  methods: {
    parseRole(role) {
      return {
        id: role._id,
        name: role.name,
        description: role.description,
        isActive: role.isActive,
        responsibilities: role.responsibilities,
        depotId: role.depotId,
        parentRoleId: role.parentRoleId,
        users: role.uids,
        isAdmin: role.isAdmin,
        depots: role.depotIds,
      };
    },
    setSecurityAccess: (securityRoles) => {
      let initialWriteRoles = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.permissions.writeRoles.value) {
          initialWriteRoles = securityConfig.permissions.writeRoles.value;
        }
      });

      return {
        writeRoles: initialWriteRoles,
      };
    },
    onClickEdit() {
      this.$emit("editRole", this.detailsId);
    },
    async onClickDelete() {
      try {
        const result = confirm(
          `Are you sure you want to deactivate the following role "${this.role.name}" ?`
        );
        if (result) {
          await this.$store.dispatch(
            `rolesandrespo/deleteRole`,
            this.detailsId
          );
          location.reload();
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) this.errorMessage = error.message[0];
      }
    },
    async onClickUnlinkRespo(respo) {
      try {
        const payload = {
          id: this.detailsId,
          responsibilityId: respo._id,
        };
        const result = confirm(
          `Are you sure you want to unlink the following responsibility "${respo.name}" ?`
        );
        if (result) {
          await this.$store.dispatch(`rolesandrespo/unlinkRespo`, payload);
          location.reload();
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) this.errorMessage = error.message[0];
      }
    },

    async onClickUnlinkParentRole(parentRole) {
      try {
        const payload = {
          id: this.detailsId,
          parentRoleId: parentRole._id,
        };
        const result = confirm(
          `Are you sure you want to unlink "${parentRole.name}" as a parent role?`
        );
        if (result) {
          await this.$store.dispatch(`rolesandrespo/unlinkParentRole`, payload);
          location.reload();
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) this.errorMessage = error.message[0];
      }
    },
    async onClickUnlinkUser(user) {
      try {
        const payload = {
          id: this.detailsId,
          uid: user.uid,
        };
        const result = confirm(
          `Are you sure you want to unlink the following user "${user.userName}" ?`
        );
        if (result) {
          await this.$store.dispatch(`rolesandrespo/unlinkUser`, payload);
          location.reload();
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;

        if (error.message) {
          //TODO: display error on pop up
          alert(error.message);
          this.errorMessage = error.message;
        }
      }
    },
    async onClickUnlinkDepot(depot) {
      try {
        const payload = {
          id: this.detailsId,
          depotId: depot._id,
        };
        const result = confirm(
          `Are you sure you want to unlink the following depot "${depot.name}" ?`
        );
        if (result) {
          await this.$store.dispatch(`rolesandrespo/unlinkDepot`, payload);
          location.reload();
        }
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;

        if (error.message) {
          //TODO: display error on pop up
          alert(error.message);
          this.errorMessage = error.message;
        }
      }
    },
  },
};
</script>
