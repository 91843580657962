<template>
  <div class="flex flex-col items-center">
    <div class="w-1/2 border-2 p-8" :style="{color: theme.primaryColor}">
      <h2>Regenerate new JSON once done editing</h2>

      <FormulateForm @submit="onSubmit" #default="{ hasErrors }">
        <FormulateInput type="text" label="File Name" v-model="objName" />

        <FormulateInput
          type="text"
          name="period"
          label="Period"
          @keypress="isNumber"
          @click="clearField($event, 'period')"
          v-model="description[0].period"
          @blur="formatAdditionals($event, 'period')"
        />

        <FormulateInput
          type="text"
          name="work"
          label="Work"
          @keypress="isNumber"
          @click="clearField($event, 'work')"
          v-model="description[0].work"
          @blur="formatAdditionals($event, 'work')"
        />

        <FormulateInput
          type="text"
          name="rest"
          label="Rest"
          @keypress="isNumber"
          @click="clearField($event, 'rest')"
          v-model="description[0].rest"
          @blur="formatAdditionals($event, 'rest')"
        />

        <FormulateInput
          label="Filetype"
          :options="{
            json: 'JSON',
            txt: 'Text File',
          }"
          v-model="fileType"
          type="select"
        />

        <input type="file" accept=".txt, .json" @change="fileSelected" />

        <FieldContainer :rulesContent="ewd" ref="ewdContaner" />

        <div class="flex justify-end">
          <button
            class="btn btn-secondary border-2 rounded-lg my-2"
            @click.prevent="addEWD()"
          >
            Add Block
          </button>
        </div>

        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Generate JSON"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors"
          />
        </StyledSubmit>

        <h2>JSON CONTENT:</h2>

        <div
          v-if="formattedJson"
          class="flex flex-col bg-gray-200 rounded-lg p-2"
        >
          <div v-if="objName" class="p-2">
            <h2>FILENAME:</h2>
            <p>{{ `${objName}.${fileType} ` }}</p>
          </div>
          <div v-else class="flex flex-col bg-gray-200 rounded-lg p-2">
            <h2>FILENAME:</h2>
            <p>{{ `rules.${fileType} ` }}</p>
          </div>
          <a
            class="flex cursor-pointer justify-end"
            @click.prevent="downloadJson"
            name="Download JSON"
          >
            <font-awesome-icon
              icon="download"
              class="my-auto mx-1.5 hover:text-orange-primary-primary"
              name="Download JSON"
          /></a>
          <pre ref="jsonContent">{{ formattedJson }}</pre>

          <!-- <button @click.prevent="downloadJson">DOWNLOAD JSON</button> -->
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import FieldContainer from '@/components/debug/json-builder/field-container.vue';
import { mapGetters } from 'vuex'
import {StyledSubmit} from '@/styled-components'

export default {
  name: 'JSON-builder',
  components: { FieldContainer, StyledSubmit },
  data() {
    return {
      objName: '',
      description: [
        {
          period: '',
          work: '',
          rest: '',
          breach: 'None',
        },
      ],
      fileType: 'json',

      formattedJson: false,
    };
  },

  computed: {
    ewd: {
      get() {
        return this.$store.state.jsonbuilder.ewd;
      },
      set(newVal) {
        this.$store.state.jsonbuilder.ewd = newVal;
      },
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },

  methods: {
    onSubmit() {
      // TO DO ADD VALIDATION

      let isValid = true;

      const ewd = this.formatEWDs();

      if (
        this.description[0].period === '' &&
        this.description[0].work === '' &&
        this.description[0].rest === ''
      ) {
        isValid = false;
      }

      if (isValid) {
        const formattedDescription = JSON.parse(
          JSON.stringify(...this.description),
        );

        let formattedJson = {
          description: formattedDescription,
          ewd,
        };

        this.formattedJson = formattedJson;
        this.toast('success', 'New JSON generated');
      } else {
        this.toast('error', 'Description is empty');
      }
    },

    fileSelected(event) {
      if (event.target.value) {
        if (this.formattedJson) {
          this.formattedJson = false;
          this.ewd = [];
        }

        const files = event.target.files;

        const fileName = files[0].name.split('.');

        this.objName = fileName[0];

        const fr = new FileReader();

        fr.onload = (e) => {
          const result = JSON.parse(e.target.result);
          this.extractJSONContents(result);
          const formatted = JSON.stringify(result, null, 2);
          this.formattedJson = formatted;
        };

        fr.readAsText(files.item(0));
        this.$refs.ewdContaner.fileSelected();
      } else {
        this.formattedJson = false;
        this.ewd = [];
        event.target.value = null;
        this.$refs.ewdContaner.clearAllContent();

        this.description[0].period = '';
        this.description[0].work = '';
        this.description[0].rest = '';

        this.objName = '';
        this.toast('error', 'No file selected');
      }
    },

    extractJSONContents(jsonData) {
      const description = jsonData.description;
      const ewd = jsonData.ewd;

      if (description && ewd) {
        this.description[0].rest = description.rest;
        this.description[0].period = description.period;
        this.description[0].work = description.work;

        let i;

        if (ewd.length > 0 && ewd) {
          for (i = 1; i < ewd.length; i += 2) {
            const rawWorkTime = ewd[i - 1].startTime.split('+');
            const rawRestTime = ewd[i].startTime.split('+');

            const formattedWorkTime = rawWorkTime[0];
            const formattedRestTime = rawRestTime[0];

            this.ewd.push({
              work: {
                eventType: 'Work',
                startTime: formattedWorkTime,
              },
              rest: {
                eventType: 'Rest',
                startTime: formattedRestTime,
              },
            });
          }
        }
      }
    },

    toIsoString(date) {
      let tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function (num) {
          return (num < 10 ? '0' : '') + num;
        };

      return (
        date.getFullYear() +
        '-' +
        pad(date.getMonth() + 1) +
        '-' +
        pad(date.getDate()) +
        'T' +
        pad(date.getHours()) +
        ':' +
        pad(date.getMinutes()) +
        ':' +
        pad(date.getSeconds()) +
        dif +
        pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' +
        pad(Math.abs(tzo) % 60)
      );
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', message);
    },

    formatEWDs() {
      const ewdPool = [];

      this.ewd.map((data) => {
        const rawWorkDate = new Date(data.work.startTime);

        const rawRestDate = new Date(data.rest.startTime);

        const workDate = this.toIsoString(rawWorkDate);
        const restDate = this.toIsoString(rawRestDate);

        ewdPool.push({ eventType: 'Work', startTime: workDate });
        ewdPool.push({ eventType: 'Rest', startTime: restDate });
      });

      return ewdPool;
    },

    addEWD() {
      this.ewd.push({
        work: {
          eventType: 'Work',
          startTime: '',
        },
        rest: { eventType: 'Rest', startTime: '' },
      });
    },

    downloadJson() {
      if (this.formattedJson) {
        let textToWrite = this.$refs.jsonContent.innerText;

        let blob = new Blob([textToWrite], {
          type: 'text/plain',
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        if (this.objName) {
          link.download = `${this.objName}.${this.fileType}`;
        } else {
          link.download = `rules.${this.fileType}`;
        }

        link.click();
      } else {
        this.toast('error', 'No rules to download');
      }
    },

    clamp(val, min, max, isHours) {
      const ret = val > max ? max : val < min ? min : val;
      if (isHours) {
        if (ret.length < 2) {
          const zeroes = '0'.repeat(1) + ret;
          return zeroes;
        }
        return ret;
      }
      return ret.toString().length % 2 === 0 ? ret : '0' + ret;
    },

    formatAdditionals(event, field) {
      const inputVal = event.target.value;

      let final;
      //only seconds
      if (
        inputVal?.length > 0 &&
        inputVal?.length <= 2 &&
        inputVal.indexOf(':') === -1
      ) {
        let minutes = this.clamp(inputVal.slice(0), 0, 59);
        final = '0:' + minutes;
        //hours and minutes
      } else if (
        inputVal?.length > 2 &&
        inputVal?.length <= 6 &&
        inputVal.indexOf(':') === -1
      ) {
        const secondsIndex = inputVal.length - 2;
        const hours = this.clamp(
          inputVal.slice(0, secondsIndex),
          0,
          9999,
          true,
        );
        const minutes = this.clamp(
          inputVal.slice(secondsIndex, inputVal.length),
          0,
          59,
        );
        final = hours + ':' + minutes;
      } else {
        this.description[0][`${field}`] = '';
      }

      this.description[0][`${field}`] = final;
    },

    isNumber(event) {
      event = event ? event : window.event;
      let charCode = event.which ? event.which : event.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        event.preventDefault();
      } else {
        return true;
      }
    },

    clearField(event, field) {
      event.target.value = '';

      if (event.target.value !== '') {
        this.description[0][`${field}`] = '';
      }
    },
  },
};
</script>

<style scoped></style>
