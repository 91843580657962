import {StandardPricing} from "@/api";
import _ from 'lodash'

export default{
    // @StandardPricingMaster
    async createStandardPricingMaster({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await StandardPricing.createStandardPricingMaster(data)
            commit("setNewCreatedStandardPricingMaster", response.data.data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },
    async getStandardPricingMaster( { state }, id ){
        try{
            const response = await StandardPricing.getStandardPricingMaster(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getStandardPricingMasterPartner( { state } ){
        try{
            const response = await StandardPricing.getStandardPricingMasterPartner();
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getStandardPricingMasterReseller( { state } ){
        try{
            const response = await StandardPricing.getStandardPricingMasterReseller();
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async updateStandardPricingMaster( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await StandardPricing.patchStandardPricingMaster(data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteStandardPricingMaster( { state }, id ){
        try{
            const response = await StandardPricing.deleteStandardPricingMaster(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    // @StandardPricing
    async createStandardPricing({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await StandardPricing.createStandardPricing(data)
            commit("setNewCreatedStandardPricing", response.data.data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async createNewRRPVersion({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await StandardPricing.createNewRRPVersion(data)
            commit("setNewCreatedStandardPricing", response.data.data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async createStandardPrepaidPricing({ commit }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await StandardPricing.createStandardPrepaidPricing(data)
            commit("setNewCreatedStandardPricing", response.data.data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getStandardPricing( { state }, id ){
        try{
            const response = await StandardPricing.getStandardPricing(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getStandardPricingByMongoId( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await StandardPricing.getStandardPricingByMongoId(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getAllStandardPricingByMongoId( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await StandardPricing.getAllStandardPricingByMongoId(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getStandardPricingVersionsByMongoId( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await StandardPricing.getStandardPricingVersionsByMongoId(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getStandardRRPVersionsByMongoId( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await StandardPricing.getStandardRRPVersionsByMongoId(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async updateStandardPricing( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await StandardPricing.updateStandardPricing(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async updateAllStandardPricingByMongoId( { state }, data ){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'+state.me
        try{
            const response = await StandardPricing.updateAllStandardPricingByMongoId(data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async deleteStandardPricing( { state }, id ){
        try{
            const response = await StandardPricing.deleteStandardPricing(id);
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },

    //Actions for fetching all active rrp for the business 

    async getActiveRRPforBusiness({state}, id){
        if(!id) throw `Invalid mongoId ${state.me}`

        try{
            const response = await StandardPricing.getActiveRRPforBusiness(id)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    
    //Actions for fetching all contract module master
    async getContractModuleMaster(){

        try{
            const response = await StandardPricing.getContractModuleMaster()
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async createContractModuleMaster({state}, payload){
        if (!state) throw `Invalid + ${state}`
        try{
            const response = await StandardPricing.createContractModuleMaster(payload)
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    //@POST: Upload logo and logo details
    async uploadLogo({ state }, fileData) {
        if (!state) throw `Invalid + ${state}`
        try {
            const response = await StandardPricing.uploadLogo(fileData)

            return response.data

        } catch (e) {
            throw new Error(e.message)
        }
    },

    //@PATCH: Update User Interface of Detail page
    async updateStandardPricingMasterDetail({ state }, data) {
        if (!state) throw `Invalid + ${state}`
        try {
            const response = await StandardPricing.updateStandardPricingMasterDetail(data)

            return response.data

        } catch (e) {
            throw new Error(e.message)
        }
    },
}