import { API } from '@/api/config'

export default new class LogmasterIntegration {

    //@GET: Fetch single config based on addInId
    getConfigJsById(mongoId){
        return API.ACCOUNTS.get(`/logmaster-integration/${mongoId}`);
    }

    //@GET: Fetch all configs and json
    getAllConfigJsById(){
        return API.ACCOUNTS.get(`/logmaster-integration/fetch-config-only`);
    }
}