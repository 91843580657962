import { EmailList } from "@/api";

export default {
  async fetchEmailList({ state }, query) {
    if (!state) throw "Invalid State" + state.me;
    try {
      const response = await EmailList.fetchEmailList(query);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async exportEmails({state}, query){
    if(!state) throw "Invalid State"+ state.me
    try{
      const response = await EmailList.exportEmails(query)
      return response;
    } catch(e){
      throw new Error(e)
    }
  }
};
