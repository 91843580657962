<template>
    <div>
      <div class="text-red-500 text-center mb-3" v-if="error">{{errorMessage}}</div>
      <div class="text-center text-xl -mt-3 mb-3 font-bold">Forgot Password</div>
      <FormulateForm name="forgotpassword" v-model="forgotPasswordForm" @submit="onSubmit">
        <FormulateInput
          type="text"
          name="email"
          label="Email"
          error-behavior="submit"
          validation="required|email"
        />
        <div class="items-center grid grid-cols-2 gap-4 justify-between">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
          />
          <button @click.prevent="hideForgotPassword" class="btn mb-3 bg-gray-400 hover:bg-gray-500 text-white w-full">Cancel</button>
        </div>
      </FormulateForm>

      <div class="text-green-500" v-if="success">Reset password request successfully sent.</div>
      <div class="text-red-500 font-bold text-sm text-center mt-0 my-5" v-if="errorMessage">
        {{errorMessage}}
      </div>
    </div>
</template>

<script>

export default {
  name: 'ForgotPasswordEmailForm',
  props: {
    msg: String
  },
  data() {
    return {
      forgotPasswordForm: {},
      success:false,
      error: false,
      errorMessage: ''
    };
  },
  methods: {
    async onSubmit() {
      try{
        const response = await this.$store.dispatch(`account/requestResetPassword`,this.forgotPasswordForm)
        if (!response.success) {
          throw new Error(response.message);
        }
        this.forgotPasswordForm.email = '';
        this.$formulate.reset('forgotpassword')
        this.success = true
      }catch( error ){
        this.error = true
        this.errorMessage = error.message
      }
    },
    hideForgotPassword() {
      this.$emit("toggleForm", 'login')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
