<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="text-red-500 font-bold text-sm text-center my-5" v-if="errorMessage">
      {{errorMessage}}
    </div>
    <div v-if="!loading">
      <FormulateForm @submit="onSubmit">
        <div class="mb-5">
          <label class="font-medium text-lg">Are you sure you want to disable this <b>{{vehiclePlate}}</b> vehicle.</label>
        </div>
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
              type="submit"
              label="Yes"
              input-class="btn btn-primary w-full"
          />
        </StyledSubmit>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { StyledSubmit } from '@/styled-components'

export default {
  name: 'Approval-Disable-Vehicle',
  components: { StyledSubmit },
  props: {
    detailsId:{
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      error:false,
      errorMessage: '',
      vehiclePlate: '',
    };
  },
  async mounted() {
    this.loading = true;
    if (!_.isEmpty(this.detailsId)) {
      const vehicleDetails = await this.$store.dispatch(`vehicle/getVehicle`, {id: this.detailsId});
      if(vehicleDetails) {this.vehiclePlate = vehicleDetails.vehiclePlate}
    }
    this.loading = false;
  },
  methods: {
    async onSubmit () {
      this.loading = true;
      if (!_.isEmpty(this.detailsId)) {
        this.$store.dispatch(`vehicle/deactivateVehicle`,  this.detailsId).then(vehicle => {
          this.loading = false;
          if (vehicle && vehicle._id) {
            this.$emit('closeRefresh');
            this.$store.commit('setDialogNotify', true);
            this.$store.commit('setMessageNotify', { state: 'success', message: 'Vehicle successfully updated.' });
          }
        }).catch(error => {
          this.loading = false;
          this.$store.commit('setDialogNotify', true);
          this.$store.commit('setMessageNotify', { state: 'error', message: error.message });
        })
      } else {
        this.loading = false;
        this.errorMessage = "No Vehicle Found";
        this.$store.commit('setDialogNotify', true);
        this.$store.commit('setMessageNotify', { state: 'error', message: "Something went wrong please try again." });
      }
    }
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>