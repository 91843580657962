<template>
  <div class="collapse-wrapper">
    <slot name="collapseHeader" />
    <div class="separator-h my-2" />
    <div>
      <slot :props="{collapsed:isCollapsed}" />
    </div>
    <div>
      <Transition name="slide-down">
      <div v-show="isCollapsed" class="overflow-hidden bg-gray-100 rounded-lg">
        <slot name="cardTable" />
      </div>
      </Transition>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Collapsible",
     props: {
        isCollapsed:{
            type: Boolean,
            default: false,
        }
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .collapse-wrapper {
    position: relative;
    padding: 1rem 0;
    border-radius: 10px;
    margin-bottom: 2rem;
    transition-property: all;
    transition-duration: 0.5s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    height: fit-content;
    .icon-container {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        color: #ffffff;
      }
    }
    .separator-v {
      border-right: 2px solid #e5e7eb;
      margin: 0 2rem;
      height: 35px;
    }
    .separator-h {
      border-bottom: 2px solid #e5e7eb;
      width: 100%;
    }
  }

</style>
