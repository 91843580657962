<template>
    <div>
        <div class="flex flex-row mb-5 w-full border-b pb-1">
            <div class="flex md:ml-0 ml-12 mt-3">
                <div class="font-bold">Suspension</div>
            </div>
            <div class="ml-auto">
                <button class="btn btn-primary" @click.prevent="itemAdd" v-if="!loading" :style="{backgroundColor: theme.secondaryColor}">
                    Add
                </button>
            </div>
        </div>
        <div class="overflow-y-auto">
            <div class="loading mt-32 h-32" v-if="loading">
                <ui-spinner class="mx-auto"></ui-spinner>
            </div>
            <table class="min-w-full leading-normal table-fixed" v-if="!loading">
                <thead>
                <tr>
                    <th class="px-5 py-3 w-4/5 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Name
                    </th>
                    <th class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Status
                    </th>
                    <th class="px-5 py-3 border-gray-200 bg-gray-200 text-right text-xs font-semibold text-gray-600 uppercase tracking-wider">
                        Actions
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr
                        v-for="(configItem, index) in foundations" :key="index"
                        class="border-b border-gray-200 text-sm bg-white hover:bg-gray-100 cursor-default"
                        :class="configItem.isEdit? 'bg-yellow-50 hover:bg-yellow-50' : ''"
                >
                    <td class="px-2 py-2">
                        <input class="w-11/12" :disabled="!configItem.isEdit" type="text" v-model="configItem.name">
                    </td>
                    <td class="px-2 py-2 text-center">
                        <div v-if="!configItem.isDefault && configItem.id">
                            <input type="checkbox"
                                   :disabled="!configItem.isEdit"
                                   v-model="configItem.isActive">
                        </div>
                        <div v-if="configItem.isDefault">
                            <span class="text-xs text-gray-500 italic">default</span>
                        </div>
                    </td>
                    <td class="px-2 py-2 flex justify-end">
                        <div v-if="!configItem.isDefault">
                            <div v-if="!configItem.isEdit" class="flex">
                                <a class="flex cursor-pointer"
                                   @click.prevent="itemEdit(index)"
                                ><font-awesome-icon
                                        icon="edit"
                                        class="my-auto mx-1.5 hover:text-orange-primary"
                                /></a>
                            </div>
                            <div v-if="configItem.isEdit" class="flex">
                                <a class="flex cursor-pointer"
                                   @click.prevent="itemSave(configItem.id, configItem.name, configItem.isActive)"
                                ><font-awesome-icon
                                        icon="check"
                                        class="my-auto mx-1.5 hover:text-orange-primary"
                                /></a>
                                <a class="flex cursor-pointer"
                                   @click.prevent="itemDiscard(index)"
                                ><font-awesome-icon
                                        icon="times"
                                        class="my-auto mx-1.5 hover:text-orange-primary"
                                /></a>
                            </div>
                        </div>
                        <div v-if="configItem.isDefault">
                            <div v-if="!configItem.isEdit" class="flex">
                                <font-awesome-icon
                                        icon="edit"
                                        class="my-auto mx-1.5 text-gray-200"
                                />
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { orderBy } from 'lodash'
import { mapGetters } from 'vuex'
export default {
    name: "Suspension",
    data() {
        return {
            loading: false,
            foundations: [],
            dataholder: {
                name: ''
            }
        }
    },
    mounted() {
        this.loading = true
        this.init()
    },
    methods: {
        async init() {
            const me = this.$store.getters[`account/me`];
            const configData = await this.$store.dispatch(`vehicle/getSuspensionByUID`, me.uid)
            if(configData){
                this.foundations = this.formatItem(orderBy(configData, ['createdAt'],['asc']));
                this.loading = false
            }
        },
        itemEdit(index) {
            this.dataholder.name = this.foundations[index].name
            this.foundations[index].isEdit = true;
        },
        async itemSave(id, name, status) {
            if(id) {
                this.$store.dispatch(`vehicle/updateSuspension`, {id:id, name:name, isActive:status}).then(item => {
                    if(item && item._id){
                        this.init()
                    }
                })} else {
                this.$store.dispatch(`vehicle/createSuspension`, {name:name}).then(item => {
                    if(item && item._id){
                        this.init()
                    }
                })}
        },
        itemDiscard(index) {
            if(this.foundations[index].id == null) {
                this.foundations.splice(this.foundations.indexOf(index), 1);
            }
            else {
                this.foundations[index].name = this.dataholder.name
                this.foundations[index].isEdit = false;
            }
        },
        itemAdd() {
            const valObj = this.foundations.filter(function(elem){
                if(elem.id == null && elem.isEdit === true) {
                    return elem;
                }
            });
            if(valObj.length === 0) {
                this.foundations.push({
                    id: null,
                    name: '',
                    compat: null,
                    isActive: true,
                    isEdit: true
                })
            }
        },
        formatItem(items) {
            let nItems = [];
            if(items){
                items.map(item => {
                    const tempItem = {
                        id:item['_id'],
                        name:item['name'],
                        isActive:item['isActive'],
                        isDefault:item['isDefault'],
                        isEdit:false
                    }
                    nItems.push(tempItem)
                });
            }
            return nItems;
        },
    },
    computed: {
        ...mapGetters('theme', {
            theme: 'getColorScheme'
        })
    }
}
</script>

<style>
</style>