<template>
  <div>
    <div v-if="loading" class="loading centered">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading" id="printCovered" class="mb-16">
      <div class="text-center mb-8">
        <div class="flex text-right justify-end">
          <button id="printBtn" class="h-8 pl-2 pr-4 m-2 text-sm transition-colors duration-150 bg-blue-300 text-white hover:bg-blue-200 rounded-md focus:shadow-outline"
            @click="printThis('printCovered')">
            <font-awesome-icon
              icon="print"
              class="my-auto mx-1.5 text-sm"/>
              Print
          </button>
        </div>

        <div class="font-bold text-black-primary text-2xl mt-3">
          {{driver?.driverName}}
        </div>
        <div class="font-bold text-xs"
          :class="[(isPassed) ? 'text-green-500' : 'text-red-500']"
        >
          {{isPassed ? 'FIT' : 'UNFIT'}} TO DRIVE
        </div>
      </div>

      <div class="w-full" v-if="isDefault">
        <section v-for="(section, i) in sections" :key="i" class="mb-3">
          <div v-if="section.value">
            <div v-for="(control, ia) in section.value" :key="ia" class="mb-3">

              <div v-if="!isPillet(control?.value?.value)">
                <div
                  v-if="control.component == formControls.CHECKBOX
                  || control.component == formControls.RADIO"
                  >

                  <div class="mb-1">
                    <div class="font-bold text-black-primary text-base">
                      {{control.value?.value}}&nbsp;
                      <span v-if="(!control.value?.isIssue)">
                        <font-awesome-icon
                          icon="check-circle"
                          class="text-1xl text-green-400"
                        />
                      </span>
                      <span v-else>
                        <font-awesome-icon
                          icon="times-circle"
                          class="text-1xl text-red-600"
                        />
                      </span>
                    </div>
                    <div class="text-xs"> {{ control.label }} </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <br><hr>
        </section>
      </div>

      <div class="w-full" v-if="isDefault">
        <div v-for="(section, j) in sections" :key="j" class="mb-3">
          <div v-if="section.value" class="mb-3">
            <div v-for="(control, ja) in section.value" :key="ja">

              <div
                  v-if="control.component == formControls.CHECKBOX
                  || control.component == formControls.RADIO"
                  >

                <div  class="mb-2 flex items-center" v-if="isPillet(control?.value?.value)">
                  <div class="option-pill-view">

                    <div v-if="control.value?.isIssue" class='bg-red-400 text-white w-11 text-center'> {{control?.value?.value}} </div>
                    <div v-else class='bg-green-400 text-white w-11 text-center'> {{control?.value?.value}} </div>
                    <div class="bg-gray-200">&nbsp;-&nbsp;&nbsp;</div>

                  </div>

                  <div class="text-xs">
                    {{ control.label }}
                  </div>
                </div>
              </div>

            </div>
          </div>
          <br><hr>
        </div>
      </div>

      <div class="w-full" v-if="!isDefault">
        <section v-for="(section, i) in sections" :key="i" class="mb-3">
          <div v-if="section.value">
            <div v-for="(control, ia) in section.value" :key="ia" class="mb-3">
              <div>
                <div
                    v-if="control.component === formControls.TEXT
                    || control.component === formControls.PLAIN_TEXT
                    || control.component === formControls.NUMBER
                    || control.component === formControls.PASSWORD
                    || control.component === formControls.EMAIL
                    || control.component === formControls.TEXTAREA
                    || control.component === formControls.SELECT
                    || control.component === formControls.CHECKBOX
                    || control.component === formControls.RADIO
                    || control.component === formControls.SWITCH">
                  <div class="mb-1">
                    <div v-if="control.value?.value?.value" class="font-bold text-black-primary text-base">
                      {{control.value?.value?.value}}&nbsp;
                      <span v-if="control.value?.value?.isIssue">
                        <font-awesome-icon
                          icon="times-circle"
                          class="text-1xl text-red-600"
                        />
                      </span>
                    </div>
                    <div v-else class="font-bold text-black-primary text-base">
                      {{control.value?.value}}&nbsp;
                      <span v-if="control.value?.isIssue">
                        <font-awesome-icon
                              icon="times-circle"
                              class="text-1xl text-red-600"
                        />
                      </span>
                    </div>
                    <div class="text-xs"> {{ control.label }} </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br><hr>
        </section>
      </div>

      <Collapse :isCollapsed="collapsed">
        <template slot="collapseHeader">
          <div class="flex items-center font-bold">
            <div class="flex items-center">
              <div class="flex items-center">
                <div class="icon-container mr-2 bg-gray-700">
                  <font-awesome-icon
                    icon="file"
                    style="height: 15px; width: 15px"
                  />
                </div>
                <div class="flex flex-col">
                  <div class="flex-auto">Fitness Declaration Consent</div>
                </div>
              </div>
            </div>
            <div class="ml-auto cursor-pointer">
              <font-awesome-icon
                :icon="collapsed ? 'caret-down' : 'caret-right'"
                class="text-2xl"
                @click="onCollapsed()"
              />
            </div>
          </div>
        </template>
        <template #default="{props}">
          <Transition name="slide">
            <div v-if="props.collapsed" class="items-center px-5">
                          <!-- <section v-for="(section, k) in sections" :key="k" class="w-full text-sm">
                <div v-if="section.value" class="mb-3">
                  <div v-for="(control, ka) in section.value" :key="ka">
                    <div v-if="control.component == formControls.PLAIN_TEXT" class="mt-5">
                      <div v-html="control?.value?.value"></div>
                    </div>
                  </div>
                </div>
                <br><hr>
              </section> -->
              <div class="mb-3 text-xs">
                You are about to submit your fit to drive declaration for the
                day. Once you have submitted it, it cannot be corrected.
              </div>
              <div class="mb-3 text-xs">
                By submitting your fit to drive declaration, you are declaring
                that the entries:
              </div>
              <ul class="list-disc mb-3 ml-8 text-xs">
                <li>Were made by you personally</li>
                <li>Are not false or misleading</li>
              </ul>
              <div class="mb-3 text-xs">
                Confirm that the information in the fit to drive form is
                correct, [OR], if you are not sure that the information in the
                fit to drive form is correct, correct it now prior to
                submitting your fit to drive declaration record.
              </div>
              <div class="mb-3 text-xs">
                Entries may be used in legal proceedings for an offence against
                the Heavy Vehicle National Law Act 2012, or another law of a State
                or the Commonwealth of Australia. Making a false or misleading
                entry in an electronic work diary is an offence punishable by a
                fine of over $10,000.
            </div>
            <hr>

            <div class="flex flex-col">
              <div class="w-full text-left items-start pt-7 mr-2">
                <span class="pl-6 text-sm">{{ cleanDateFormat(savedData?.date) }}</span>
                <div class="w-40 border-t mt-1 pt-1 text-center text-xs">Date submitted</div>
              </div>
              <div class="w-full text-right flex flex-col items-end mr-2">
                <section v-for="(section, l) in sections" :key="l" class="w-full text-right flex flex-col items-end">
                  <div v-if="section.value" class="mb-3">
                    <div v-for="(control, la) in section.value" :key="la">
                      <div v-if="control.component === formControls.SIGNATURE || control.component === formControls.IMAGE" class="mt-5">
                        <img class="border-2 w-60 bg-gray-700 h-32" :src="control.value?.value ? control.value?.value : control.value" :alt="control.label"/>
                        <div class="text-left mt-1 text-xs">{{ control.label }} <b>{{driver.driverName}}</b></div>
                      </div>
                    </div>
                  </div>
                  <br><hr>
                </section>
              </div>
            </div>

            </div>
          </Transition>
        </template>
      </Collapse>

    </div>
  </div>
</template>
<script>
import { FORM_CONTROLS } from '@/_helper/constants'
import { print } from '@/_helper'
import { Collapse } from "@/components/commons"
import { cleanDateFormat, isPilletOptionAvailable } from '@/_helper'

export default {
  name: "Fitness-Declaration-Details-View",
  components: { Collapse },
  props: {
    activeFitnessDeclaration: {
      type: Object,
      default: () => {}
    },
  },
  watch: {
    activeFitnessDeclaration: {
      deep: true,
      handler() {
        this.setActiveFitnessDeclaration()
      }
    }
  },
  created() {
    this.setActiveFitnessDeclaration()
    setTimeout(() => this.loading = false, 400)
  },
  data() {
    return {
      loading: true,
      driver: {},
      sections: {},
      collapsed: true,
      isPassed: false,
      isDefault: true,
      formControls: FORM_CONTROLS,
      savedData: {}
    };
  },
  methods: {
    setActiveFitnessDeclaration(){
      this.driver = this.activeFitnessDeclaration?.driver;
      this.sections = this.activeFitnessDeclaration?.savedData?.sections;
      this.isPassed = this.activeFitnessDeclaration?.isPassed;
      this.isDefault = this.activeFitnessDeclaration?.isDefault;
      this.savedData = this.activeFitnessDeclaration?.savedData;
    },
    onCollapsed() {
      this.collapsed = !this.collapsed;
    },
    isPillet(option){
      return isPilletOptionAvailable(option)
    },
    printThis(toPrintId) {
      return print(toPrintId)
    },
    cleanDateFormat(date){
      return cleanDateFormat(date, true, true)
    }
  },
}
</script>
<style>
  .fitness-signature{
    width: 200px!important;
    height: 90px!important;
    background: rgb(68, 63, 63);
    border: 3px solid;
    margin-top: 10px;
  }

  @media print {
    #printCovered {
      display:flex;
      justify-content:center;
      align-items:center;
      height:100%;
    }

    body { -webkit-print-color-adjust: exact }

    #printBtn {
      display: none;
    }
  }

  @page { size: auto }
</style>