<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <StyledSubmit :backgroundColor="theme.secondaryColor">
      <FormulateForm
        v-if="!loading"
        v-model="values"
        :schema="schema"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <div class="items-center justify-between">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          />
        </div>
        <div class="text-red-500 text-center mb-3" v-if="errorMessage">
          {{ errorMessage }}
        </div>
      </FormulateForm>
    </StyledSubmit>
  </div>
</template>

<script>
import schema from "./create-lead-schema";
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";

export default {
  name: "Create-Lead-Form",
  props: {
    msg: String,
    entityType: String,
    entityId: String,
  },
  components: { StyledSubmit },
  data() {
    this.$emit("schema", schema);
    return {
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
    };
  },
  methods: {
    updateSchema: function (schema) {
      this.schema = schema;
    },
    async onSubmit() {
      this.loading = true;
      const newBusinessLead = this.values;
      // let parentType;
      // let parentEntityId = null;

      const payload = {
        parentType: this.entityType,
        parentEntityId: this.entityId,
        type: "business",
        businessName: newBusinessLead.businessName,
        tradingName: newBusinessLead.tradingName,
        abn: newBusinessLead.abn,
        businessAddress: newBusinessLead.location[0].businessAddress,
        contactUserName: newBusinessLead.contactUserName,
        contactEmail: newBusinessLead.contactEmail,
        contactPhoneNumber: newBusinessLead.contactPhoneNumber,
      };

      this.$store
        .dispatch(`business/createBusinessLead`, payload)
        .then(() => {
          this.toast("success", "Lead successfully created.");
          this.loading = false;
          this.$emit("closeRefresh");
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = `Something went wrong please try again.`;
          if (error.message) this.errorMessage = error.message;
          this.toast("error", this.errorMessage);
        });
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style></style>
