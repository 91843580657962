<template>
  <div>
    <div class="mb-16 bg-white pt-3 pl-8 pr-8 pb-8">
      <div class="mt-8 mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Support Details</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{email}}</div>
        <div class="text-xs">Email Address</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{phone}}</div>
        <div class="text-xs">Phone Number</div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  name: "Support-Details",
  data() {
    return {
      email: '',
      phone: '',
    }
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];
    const partner = await this.$store.dispatch(`partner/getPartnerByUID`, me?.uid);
    this.email = partner?.supportEmail || '---';
    this.phone = partner?.supportPhone || '---';
  }
}
</script>