import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class Hazard {
  getHazardByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
    let filter = '';
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/hazard/find-by-businessId/${businessId}${query}`)
  }

  getHazardById( query ){
      return API.ACCOUNTS.get(`/hazard/${query}`);
  }

  getSummaryReport( payload ){
    return API.ACCOUNTS.post(`/hazard/summary-report`, payload);
  }

  getDetailedReport( payload ){
    return API.ACCOUNTS.post(`/hazard/detailed-report`, payload);
  }

}