<template>
  <div class="mb-16">
    <FormulateForm
      v-model="values"
      @submit="onSubmit"
      #default="{ hasErrors }"
    >
      <label class="font-medium text-sm mb-1">Card Details</label>
      <stripe-element-card
        class="mb-5"
        ref="cardRef"
        :pk="publishableKey"
        @token="tokenCreated"
        @element-blur="cardChange"
        :hidePostalCode="true"
      />
      <div class="custom-options">
        <FormulateInput
          type="radio"
          name="default"
          label="Set as default payment?"
          :options="{ 'true': 'Yes', 'false': 'No' }"
          value="false"
          validation="required"
        />
      </div>
      <div class="custom-options">
        <FormulateInput
          type="radio"
          name="status"
          label="Set as active?"
          :options="{ 'true': 'Yes', 'false': 'No' }"
          value="true"
          validation="required"
        />
      </div>

      <FormulateInput
        type="submit"
        input-class="btn btn-primary w-full"
        :disabled="hasErrors || loading"
        :style="{backgroundColor: theme.secondaryColor}"
      >
        <span v-if="loading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving data...</span>  
        <span v-if="!loading">Submit</span>  
      </FormulateInput>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
import { mapGetters } from 'vuex'

export default {
  name: "New-Payment-Method-Form",
  props: {
    publishableKey: {
      type: String,
      default: '',
    },
    paymentGatewayId: String,
    msg: String,
  },
  components: { StripeElementCard },
  data() {
    return {
      token: null,
      error: false,
      errorMessage: "",
      loading: false,
      values: {}
    };
  },
  computed: {
    apiKeyLabel: {
      get(){
        if (this.values.paymentPlatform == 'Stripe') return 'Secret Key';
        else if (this.values.paymentPlatform == 'Paypal') return 'Client ID';
        else return 'Access Token';
      },
    },
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
  },
  methods: {
    cardChange() {
      this.$refs.cardRef.submit();
    },
    tokenCreated(token) {
      this.token = token.id;
    },
    async onSubmit() {
      if (this.token) {
        try {
          this.loading = true;
          const me = await this.$store.getters[`account/me`];
          await this.$store.dispatch("payment/createCardPaymentMethod", {
            entityId: me.business._id,
            entityRole: me.role?.name,
            paymentGatewayId: this.paymentGatewayId,
            cardToken: this.token,
            isDefault: this.values.default === "true" ? true : false,
            isActive: this.values.status === "true" ? true : false,
          });
          this.loading = false;
          this.$emit('closeRefresh');
        } catch (error) {
          this.errorMessage = `Something went wrong please try again.`;
        }
      }
    },
  }
};
</script>

<style lang="scss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 1s linear infinite;
}
</style>
