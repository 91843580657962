import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class Partner {
    // @Partner
    createPartner(data){ // Create partner
        return API.ACCOUNTS.post(`/partner`, data)
    }
    getPartner({id, query}){ // Get single Partner by Id or Get all Partner
        if(id){
            return API.ACCOUNTS.get(`/partner/${id}`)
        }else{
            let filter = '';
            if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
            query.filter = filter;
            if(query) query = '?'+ObjectToQueryString(query);
            return API.ACCOUNTS.get(`/partner/${query}`)
        }
    }

    getPartnersRaw () {
        return API.ACCOUNTS.get(`/partner/find-all-raw`)
    }

    getPartnersWithPaymentGateway () {
        return API.ACCOUNTS.get(`/partner/find-all-with-payment-gateway`)
    }

    acceptPartner( id = null ) {
        return API.ACCOUNTS.get(`/partner/accept/${id}`)
    }


    getPartnerByUID( uid = null ){ // Get single Partner by Id or Get all Partner
        return API.ACCOUNTS.get(`/partner/find-one-by-uid/${uid}`)
    }

    getPartnerByWebProfileId (id) {
        return API.ACCOUNTS.post(`/partner/find-by-web-profile/${id}`)
    }

    getPartnerResellersByUID({id, query}){ // Get all reseller by partner UID
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/partner/resellers/${id}/${query}`)
    }

    getPartnerResellersById({ id, query }) {
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/partner/find-resellers/${id}/${query}`)
    }

    getPartnerBusinessesById({ id, query }) {
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/partner/find-businesses/${id}/${query}`)
    }

    patchPartner( id = null, data ){ // Update single Partner by Id
        delete data.id;
        return API.ACCOUNTS.patch(`/partner/${id}`, data)
    }
    
    patchPartnerCreatePassword( id = null, data ){ // Call this to create Partner password
        return API.ACCOUNTS.patch(`/partner/create-password/${id}`, data)
    }


    getResellersByPartner(uid){ // Get all Resellers directly Linked to Partner by uid
        return API.ACCOUNTS.get(`/partner/resellers/${uid}`)
    }
    getBusinessByPartner({id, query}){ // Get all Businesses directly Linked to Partner by uid
        let filter = '';
        if(query && typeof query.filter === 'object'){
            try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
            query.filter = filter;
            query = '?'+ObjectToQueryString(query);
        } 

        if(query){
            return API.ACCOUNTS.get(`/partner/business/${id}/${query}`)
        }else{
            return API.ACCOUNTS.get(`/partner/business/${id}`)
        }
    }
    getPartnerOfLogmasterByUID( uid){ // Get all Partner directly linked to Logmaster by UID
        return API.ACCOUNTS.get(`/logmaster/partners/${uid}`)
    }

    generateShareableUrl(id, data) {
        return API.ACCOUNTS.patch(`/partner/generate-url/${id}`, data);
    }

    getPartnerByURL(data) {
        return API.ACCOUNTS.post(`/partner/find-partner-by-url`, data);
    }

    resendInvite(data){
        return API.ACCOUNTS.post(`/partner/resend-invite`, data)
    }
        
    deletePartner( id ){ // Update single Partner by Id
        return API.ACCOUNTS.delete(`/partner/${id}`)
    }

    getPartnerByEmail( emailAddress ){
        return API.ACCOUNTS.post(`/partner/find-by-email`, { emailAddress : emailAddress})
    }

    updatePartnersSoloOrphan(ids) {
        return API.ACCOUNTS.patch(`/partner/update-solo-orphan-partners`, ids);
    }

    removeFromOrphan(data){
        return API.ACCOUNTS.patch(`/partner/remove-from-orphan`, data)
    }
    
    addModuleToPartners(standardPricingMasterId) {
        return API.ACCOUNTS.post(`/partner/module`, { standardPricingMasterId })
    }

    getDashboardData(uid) {
        return API.ACCOUNTS.get(`/partner/dashboard/data/uid/${uid}`);
    }
}