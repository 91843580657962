export const REGION = [
  {
    label: "NHVR",
    value: "NHVR",
  },
  {
    label: "WAHVA",
    value: "WAHVA",
  },
];
