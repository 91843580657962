<template>
  <div>
    <div v-if="writeForms" class="w-full min-h-full flex-row items-center">
      <FormBuilder 
         :editMode="true" 
         :_id="termId" 
         :pulledFormData="pulledFormData" 
         :isContractTerms="true"
         :typeOption="typeOption"
      />
    </div>
    <div v-if="!writeForms" class="w-full min-h-full text-center mt-5">
      <h1>Not authorized to view form versions. Please contact your business admin.</h1>
    </div>
  </div>
</template>

<script>
import FormBuilder from '@/components/commons/FormBuilder';

export default {
   components: {
     FormBuilder
   },
   data: () => ({
      typeOption: [
         { value: 'reseller', label: 'Reseller Standard' },
         { value: 'partner', label: 'Partner Standard' },
         { value: 'logmaster', label: 'Logmaster Standard' },
      ],
      formData: null,
      isRenderer: true,
      readOnly: false,
      termId: null,
      pulledFormData: null,
      writeForms: false
   }),
   methods: {
      async init(ids) {
         this.termId = this.$route.params.id;
         this.pulledFormData = await this.$store.dispatch(`contract/getContractTermsByUID`, this.termId)
      
         if(ids){
            const promises = [];
            for (let i = 0; i < ids.length; i++) {
               const promise = this.$store.dispatch(`rolesandrespo/getRoles`, ids[i]);
               promises.push(promise);
            }
            return await Promise.all(promises);
         }
      },
      // setSecurityAccess(securityRoles){
      setSecurityAccess(){
        // let initialReadForms = false;
        // let initialWriteUsers = false;
         let initialReadForms = true;
         let initialWriteUsers = true;
         // securityRoles.forEach((securityRole) => {
         //    const securityConfig = securityRole.securityConfig;
         //    if (securityConfig.forms.readForms?.value) {
         //       initialReadForms = securityConfig.forms.readForms.value;
         //    }
         //    if (securityConfig.forms.writeForms?.value) {
         //       initialWriteUsers = securityConfig.forms.writeForms.value;
         //    }
         // });
         return {
            readForms: initialReadForms,
            writeForms: initialWriteUsers,
         };
      },
   },
   async mounted(){
      this.$emit("updateLoading", true);
      const me = this.$store.getters[`account/me`];
      let securityRoles = me?.securityRoles;
      if (securityRoles && securityRoles.length > 0) {
         const securityAccessObj = await this.setSecurityAccess(securityRoles);
         this.readForms = securityAccessObj.readForms;
         this.writeForms = securityAccessObj.writeForms;
      }
      await this.init();
      this.$emit("updateLoading", false);
   }
}
</script>