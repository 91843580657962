export default{

    getPortalLogoURL:(state) => {
        return state.logoURLs.portalLogoURL
    },
    getThemeId:(state) => {
        return state.themeId
    },
    getDarkModeData:(state) => {
        return state.darkModeData
    },

}