<template>
  <div class="mb-16">
      <FormulateForm v-model="values" @submit="onSubmit">
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="text"
            name="driverName"
            label="Full Name"
            validation="required"
          />
          <FormulateInput
            type="date"
            name="driverDateOfBirth"
            label="Date Of Birth"
            validation="required"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="text"
            name="driversLicenseNumber"
            label="Licence Number"
            validation="required"
          />
          <FormulateInput
            type="date"
            name="driversLicenseExpiry"
            label="Licence Expiry"
            validation="required"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="select"
            name="driversBaseFatiguePlan"
            label="Base Fatigue Plan"
            :options="fatiguePlans"
            validation="required"
          />
          <FormulateInput
            type="select"
            name="driversBaseTimeZone"
            label="Base Time Zone"
            :options="timezones"
            validation="required"
          />
        </div>
      
        <div class="items-center justify-between mt-4">
          <FormulateInput
            type="submit"
            input-class="btn btn-primary w-full"
            :disabled="loading"
            :style="{backgroundColor: theme.secondaryColor}"
          >
          <span v-if="loading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Submitting..</span>  
          <span v-if="!loading">Submit</span>  
          </FormulateInput>
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { STATES, TIMEZONE } from '@/constants/location'
import { FATIGUE_PLAN } from '@/constants/fatigue-plan'
import { REGION } from '@/constants/region'

export default {
  data() {
    return {
      values: {},
      loading: false,
      hasErrorMessage: false,
      errorMessage: '',
      states: STATES,
      timezones: TIMEZONE,
      regions: REGION,
      fatiguePlans: []
    }
  },
  mounted() {
    this.fatiguePlans = Object.entries(FATIGUE_PLAN).map(fatigue => {
      return { label: fatigue[0].replace(/_/g, ' '), value: fatigue[1] }
    });
  },
  methods: {
    async onSubmit() {
      this.errorMessage = ''
      this.loading = true
      const me = this.$store.getters[`account/me`];
      const entityID = me.business._id;

      const payload = {
        ...this.values,
        demoOption: 'NO_ACCOUNT',
        isDemoAccount: false,
        entityId: entityID,
        businessId: entityID
      }
      await this.$store.dispatch(`driver/createNonEWDDriver`, payload)
        .then(()=>{
          this.$emit('closeRefresh')
          this.$store.commit('setDialogNotify',true)
          this.$store.commit('setMessageNotify',{state:'success',message:'Non-EWD Driver successfully created.'})
        })
        .catch((err) => {
          this.errorMessage = err.message
          this.loading = false
        })
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>