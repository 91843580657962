<template>
  <div class="w-full min-h-full flex-row items-center">
    <div class="flex w-full mt-5">
      <div class="flex mx-auto border border-gray-600 bg-gray-300 text-gray-600 p-5 rounded-md">
          <font-awesome-icon icon="tools" class="my-auto text-9xl" />
      </div>
    </div>
    <div class="flex flex-col w-full mt-5">
      <div class="text-xl mx-auto uppercase mb-2" :style="{color: theme.primaryColor}">
        BUILDING YOU THE BEST
      </div>
      <div class="text-xs mx-auto uppercase mb-2" :style="{color: theme.primaryColor}">(Feature will be available soon)</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
}
</script>

<style>

</style>