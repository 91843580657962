<template>
  <div class="mb-16">
    <div class="relative">
      <div class="w-full" v-if="loading">
        <div class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center">
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300">Initializing...</span>
        </div>
      </div>
      <div class="table-top-header text-xs">
        <div class="grid grid-cols-5 mb-5">
          <div class="flex flex-col">
            <h6 class="font-bold px-3 py-2 text-gray-500 bg-gray-300">Driver Name</h6>
            <p class="flex-1 items-center flex px-3 py-2 border-2 border-t-0 border-gray-300">{{ driver }}</p>
          </div>
          <div class="flex flex-col">
            <h6 class="font-bold px-3 py-2 text-gray-500 bg-gray-300">Fatigue Plan</h6>
            <p class="flex-1 items-center flex px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300 uppercase">{{ params.selectedPlan }}</p>
          </div>
          <div class="flex flex-col">
            <h6 class="font-bold px-3 py-2 text-gray-500 bg-gray-300">Vehicle</h6>
            <p class="flex-1 items-center flex px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300">{{ params.selectedVehicle || '&nbsp;' }}</p>
          </div>
          <div class="flex flex-col">
            <h6 class="font-bold px-3 py-2 text-gray-500 bg-gray-300">Event Date</h6>
            <p v-if="params.selectedDateRange && params.selectedDateRange.length > 0" class="flex-1 items-center flex px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300">
              {{ params.selectedDateRange[0] }} to<br/>{{ params.selectedDateRange[1] }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="font-bold px-3 py-2 text-gray-500 bg-gray-300">Time Zone</h6>
            <p class="flex-1 items-center flex px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300">{{ params.selectedTimezone }}</p>
          </div>
        </div>
      </div>
      <div class="table-legends"></div>
      <div class="w-full flex h-10">
        <StyledButton
          @click.prevent="goToDate(true)"
          :disabled="disabledPrevBtn"
          :borderColor="theme.secondaryColor"
          :hoverBorderColor="theme.secondaryColor"
          class="px-3 text-sm text-gray-300 border-2 border-gray-300 disabled:opacity-50"
        >
          <font-awesome-icon icon="chevron-left" />
        </StyledButton>
        <div
          class="manual-event-date flex-1 text-center justify-center border-2 border-gray-300 border-l-0 border-r-0 text-gray-300 text-sm relative leading-10"
          :class="[ value.isActive ? 'active' : 'hidden', `manual-event-date-${value.dateOfSheet}` ]"
          :style="{ color: theme.secondaryColor }"
          v-for="value in Object.values(values)"
          :key="value.dateOfSheet"
        >
          <span>{{ formatDate(value.dateOfSheet, 'MMM DD, YYYY') }}</span>
        </div>
        <StyledButton
          @click.prevent="goToDate(false)"
          :disabled="disabledNextBtn"
          :borderColor="theme.secondaryColor"
          :hoverBorderColor="theme.secondaryColor"
          class="px-3 text-sm text-gray-300 border-2 border-gray-300 disabled:opacity-50"
        >
          <font-awesome-icon icon="chevron-right" />
        </StyledButton>
      </div>
      <div class="flex overflow-x-hidden">
        <div
          class="manual-event-container w-full"
          v-for="value in Object.values(values)"
          :class="[ value.isActive ? 'active' : 'hidden', `manual-event-date-${value.dateOfSheet}` ]"
          :key="value.dateOfSheet"
        >
          <div
            @click.prevent="monitorEvents($event, false, value.dateOfSheet)"
            :id="`childLhv${value.dateOfSheet.replace(/-/g, '')}`"
            class="manual-events inline-block w-full"
          ></div>
        </div>
      </div>

      <div class="mt-5 text-sm text-red-400" v-if="hasEmptyFields && !isManualDriver">
        * Please enter the required manual event's location and odometer.
      </div>
      <div class="grid grid-cols-2 gap-4 my-5">
        <button class="btn bg-gray-300 hover:bg-gray-400 w-full mb-3" @click="onPrevious()">Back</button>
        <button class="btn btn-primary w-full mb-3" :disabled="loading" @click="onSubmit()" :style="{backgroundColor: theme.secondaryColor}">Next</button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import _ from 'lodash';
import moment from 'moment';
import { generateBreaches } from '@/_helper/breach';
import Spinner from '@/components/commons/ui/Spinner';
import LocationData from '@/assets/location/location.json';
import LogmasterManualEvents from '@logmaster/logmaster-manual-events';
import { StyledButton } from '@/styled-components'
import { mapGetters } from 'vuex'

export default {
  name: 'Manual-Events-Create',
  props: {
    msg: String,
    preData: null,
  },
  components: { Spinner, StyledButton },
  data() {
    return {
      hasEmptyFields: false,
      params: {},
      manualEvent: null,
      isManualDriver: false,
      loading: false,
      values: [],
      errorMessage:'',
      schema: [],
      businessId: null,
      dateFormat: 'YYYY-MM-DD',
      disabledPrevBtn: false,
      disabledNextBtn: false,
      dateRange: []
    }
  },
  computed: {
    driver: {
      get() {
        return this.params.selectedDriver?.driver?.driverName;
      }
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];
    this.businessId = me?.business?._id;
    if (me.role.name == 'driver') this.businessId = me[me.role.name].entityId;

    this.isManualDriver = this.preData['isManualDriver'];

    if (!_.isEmpty(this.preData['eventDetails'])) {
      this.params = this.preData['eventDetails'];

      // Setup manual event styles
      const logmasterManualEvents = new LogmasterManualEvents();
      $('head').append(`<style type="text/css">${logmasterManualEvents.getStyles()}</style>`);

      this.loading = true;
      this.dateRange = this.params.selectedDateRange;
      const startDate = moment(this.dateRange[0], this.dateFormat);
      const endDate = moment(this.dateRange[1], this.dateFormat);

      // Loop the date range first to dynamically add the dom elements
      for (var m = startDate; m.diff(endDate, 'days') <= 0; m.add(1, 'days')) {
        const currentDate = m.format(this.dateFormat);

        this.values[currentDate] = {
          ...this.params,
          isActive: false,
          dateOfSheet: currentDate
        }
      }

      // Set first value as active
      const values = Object.values(this.values);
      values[0].isActive = true;
      this.disabledPrevBtn = true;
      this.disabledNextBtn = values.length > 1 ? false : true;

      // After dom elements were created in a loop, initialize manual event to each of those element
      for (let value of Object.values(this.values)) {
        const currentDate = value.dateOfSheet;
        const requestData = {
          driverId: this.params.selectedDriver?.driverId,
          from: moment(currentDate, this.dateFormat).format(),
          to: moment(currentDate, this.dateFormat).format()
        }
      
        await Promise.all([
          this.$store.dispatch(`business/getEventByDateRange`, requestData),
          this.$store.dispatch(`business/getPreviousEvent`, requestData),
          this.$store.dispatch(`business/getEventByDateRange`, {
              driverId: this.params.selectedDriver?.driverId,
              from: moment(currentDate, this.dateFormat).subtract(28, 'days').format(),
              to: moment(currentDate, this.dateFormat).add(28, 'days').format()
          }),
        ]).then(data => {
          const previousEvent = data[1];
          const previousEventType = !_.isEmpty(previousEvent) ? previousEvent.type : 'rest';

          value.currentEvents = data[0];
          value.eventsForBreach = data[2];
          value.dateOfSheet = currentDate;
          value.previousEvent = previousEvent;
          value.previousEventType = previousEventType;
          value.breaches = [];
          value.eventsForBreachDates = data[2].map(event => event.startTimeWithTimezone);
          
          value.manualEvent = $(`#childLhv${currentDate.replace(/-/g, '')}`);
          value.manualEventSettings = {
            isNZMode: true,
            isViewMode: false,
            isEditMode: false,
            isDateVisible: true,
            isManualDriver: this.isManualDriver,
            driverId: this.params.selectedDriver?.driverId,
            vehicleId: this.params.selectedVehicle,
            timezone: this.params.selectedTimezone,
            fatiguePlan: this.params.selectedPlan,
            locationData: LocationData,
            currentDate: currentDate,
            currentEvents: value.currentEvents,
            previousEventType: value.previousEventType,
            previousEvent: previousEvent
          }
          value.manualEvent.ManualEvent(value.manualEventSettings);

          this.monitorEvents(null, true, currentDate);
        })

        this.loading = false;
      }
    }
  },
  methods: {
    onPrevious() {
      this.$emit('backStep')
    },
    checkEventData() {
      this.hasEmptyFields = false;
      for (const value of Object.values(this.values)) {
        const events = value.manualEvent.ManualEvent('getEvents');
        this.values[value.dateOfSheet].events = events;
        const emptyFields = events.filter(event => _.isEmpty(event['odometer']) || _.isEmpty(event['location']['formatted_address']));
        if (!_.isEmpty(emptyFields)) this.hasEmptyFields = true;
      }
      return this.hasEmptyFields;
    },
    async onSubmit() {
      this.hasEmptyFields = this.checkEventData();
      const values = _.cloneDeep(Object.values(this.values));
      if (!this.hasEmptyFields || this.isManualDriver) this.$emit('nextStep', { eventDetails: values })
    },
    monitorEvents(evt, forceDisplay = false, currentDate = null) {
      if ($(evt?.target).attr('class') == 'breach-element') return;
      if ((evt && $(evt?.target).attr('class') && !$(evt?.target).attr('class').includes('breach')) || forceDisplay || currentDate) {
        // Loop through events and check for new ones
        if (this.values[currentDate]?.manualEvent) {
          let eventsForBreach = _.cloneDeep(this.values[currentDate].eventsForBreach);
          this.values[currentDate].manualEvent.ManualEvent('getEvents').forEach(event => {
            if (!this.values[currentDate]?.eventsForBreachDates.includes(event.startTimeWithTimezone)) {
              eventsForBreach.push(event);
            }
          });

          const events = eventsForBreach.map(event => {
            event.startTime = new Date(event.startTime);
            return event;
          });

          if (events.length > 0) {
            this.values[currentDate].breaches = generateBreaches(this.params.selectedDriver?.driverId, this.businessId, events, this.params.selectedTimezone);
            this.values[currentDate].manualEvent.ManualEvent('setBreaches', this.values[currentDate].breaches);
          }
        }
      }
    },
    formatDate(date) {
      return moment(date, this.dateFormat).format('MMMM DD, YYYY')
    },
    isDateNotBefore(date) {
      return moment(date, this.dateFormat).isBefore(this.dateRange[0]);
    },
    isDateNotAfter(date) {
      return moment(date, this.dateFormat).isAfter(this.dateRange[1]);
    },
    isPrevDisable(dateValue) {
      return this.isDateNotBefore(moment(dateValue, this.dateFormat).subtract(1, 'days').format(this.dateFormat));
    },
    isNextDisable(dateValue) {
      return this.isDateNotAfter(moment(dateValue, this.dateFormat).add(1, 'days').format(this.dateFormat))
        || moment().format(this.dateFormat) == moment(dateValue, this.dateFormat).format(this.dateFormat)
    },
    goToDate(isPrevDate = false) {
      if (!this.checkEventData()) {
        let currentActive = Object.values(this.values).find(x => x.isActive);
        const newDate = isPrevDate ? moment(currentActive.dateOfSheet, this.dateFormat).subtract(1, 'days') : moment(currentActive.dateOfSheet, this.dateFormat).add(1, 'days');
        const activeDate = newDate.format(this.dateFormat);
        
        // Set current active to false, send new value as active
        currentActive.isActive = false;
        this.values[activeDate].isActive = true;
        $(`.manual-event-date-${currentActive.dateOfSheet}`).hide();
        $(`.manual-event-date-${this.values[activeDate].dateOfSheet}`).show();
        this.setNavigationButton(this.values[activeDate].dateOfSheet);

        // Loop through each dates and adjust previousEvent accordingly
        let previousEvent = Object.values(this.values)[0].manualEventSettings.previousEvent;
        for (const [loopIndex, loopData] of Object.values(this.values).entries()) {
          if (loopData.events.length > 0 && loopIndex != Object.values(this.values).length - 1) {
            previousEvent = _.last(loopData.events);
          }
          if (loopIndex == 0) continue;

          if (previousEvent.type != loopData.manualEventSettings.previousEvent.type) {
            const loopDate = loopData.dateOfSheet;
            this.values[loopDate].manualEvent.ManualEvent("destroy");
            this.values[loopDate].manualEvent = $(`#childLhv${loopData.dateOfSheet.replace(/-/g, '')}`);

            this.values[loopDate].manualEventSettings.previousEventType = previousEvent.type;
            this.values[loopDate].manualEventSettings.previousEvent = previousEvent;
            this.values[loopDate].previousEvent = previousEvent;
            this.values[loopDate].previousEventType = previousEvent.type;
            this.values[loopDate].manualEvent.ManualEvent(loopData.manualEventSettings);
          }
        }
      }
    },
    setNavigationButton(date) {
      this.disabledPrevBtn = this.isPrevDisable(date) ? true : false;
      this.disabledNextBtn = this.isNextDisable(date) ? true : false;
    }
  }
}
</script>

<style lang="scss" scoped>
  .manual-events::v-deep {
    .timetable-container {
      @apply border-2 border-gray-300 rounded-b-none rounded-r-none;

      .manual-event-date {
        @apply border-r-0 rounded-none;
      }
      .timetable-header_work, .timetable-header_rest {
        @apply border-r-0;
      }

      #events-table .g-bg-blue-200 {
        @apply border-gray-300;
      }

      .ruler-text .time-block:last-child, 
      .ruler-text .time-block:first-child span {
          top: 2px !important
      }
    }
  }
</style>