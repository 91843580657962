export const ADMIN_ROLE = {
    name: 'admin',
    description: 'A super user that administrates all accounts.',
}

export const DRIVER_ROLE = {
    name: 'driver',
    description: 'A user account for drivers.',
}

export const BUSINESS_ROLE = {
    name: 'business',
    description: 'A user account for company owners.',
}

export const SAFETY_OFFICER_ROLE = {
    name: 'safetyOfficer',
    description: 'A user account for safety officers.',
}

export const RESELLER_ROLE = {
    name: 'reseller',
    description: 'A user account for resellers.',
}

export const PARTNER_ROLE = {
    name: 'partner',
    description: 'A user account for partners.',
}