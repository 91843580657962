var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full"},[_c('aside',{staticClass:"w-96"},[_c('div',{staticClass:"flex flex-row justify-between"},[_c('button',{staticClass:"w-1/2",on:{"click":function($event){$event.preventDefault();return _vm.addBullet()}}},[_c('font-awesome-icon',{attrs:{"icon":"plus-circle"}}),_vm._v(" Add Bullet ")],1),_c('button',{staticClass:"w-1/2",on:{"click":function($event){$event.preventDefault();return _vm.addWildCard()}}},[_c('font-awesome-icon',{attrs:{"icon":"plus-circle"}}),_vm._v(" Add Wildcard ")],1)]),_c('div',{staticClass:"flex flex-col p-4"},_vm._l((_vm.content),function(fields,index){return _c('div',{key:index},[(fields.type && fields.type === 'wildcard')?_c('div',[_c('div',{staticClass:"flex flex-row justify-between"},[_c('button',{staticClass:"pb-4",on:{"click":function($event){$event.preventDefault();return _vm.removeBullet(index)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash']}})],1),_c('FormulateInput',{staticClass:"w-10/12",attrs:{"type":"select","options":[
                { value: '{{driverName}}', label: 'Driver Name' },
                { value: '{{inductionDate}}', label: 'Induction Date' },
                {
                  value: '{{accreditationNumber}}',
                  label: 'Accreditation Number',
                },
                // { value: '{{todayDate}}', label: 'Today(Date)' },
                { value: '{{adminNumber}}', label: 'Admin Number' },
              ]},model:{value:(fields.value),callback:function ($$v) {_vm.$set(fields, "value", $$v)},expression:"fields.value"}})],1)]):_c('div',[_c('div',{staticClass:"flex flex-row justify-between"},[_c('button',{staticClass:"pb-4",on:{"click":function($event){$event.preventDefault();return _vm.removeBullet(index)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash']}})],1),_c('FormulateInput',{staticClass:"w-10/12",attrs:{"type":"text"},model:{value:(fields.value),callback:function ($$v) {_vm.$set(fields, "value", $$v)},expression:"fields.value"}})],1)])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }