import { Contract } from "@/api";

export default{

    //Actions to fetch contract duration options
    async getContractDurationOptions(){
        try{

            const response = await Contract.getContractDurationOptions()
            return response.data.data

        }catch(error){
            throw error.response.data
        }
    },

    //Actions to fetch contract duration options
    async getBillingPeriodOptions(){
        try{
            const response = await Contract.getBillingPeriodOptions()
            return response.data.data

        }catch(error){
            throw error.response.data
        }
    }

}