<template>
  <div>
    <div v-if="!isLoading">
      <FormulateForm
        v-model="bfmFormModel"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <FormulateInput
          type="text"
          name="certNumber"
          label="Certification Number"
          validation="^required"
          :validation-messages="{
            required: 'Please select enter certification number.',
          }"
        />

        <FormulateInput
          type="date"
          name="certExpiryDate"
          label="Expiration Date"
          validation="^required"
          :validation-messages="{
            required: 'Please select expiration date.',
          }"
        />

        <div class="flex justify-center p-2">
          <div class="w-1/2">
            <p class="font-medium text-sm mb-1">Require Admin Signature</p>
          </div>
          <div class="w-1/2">
            <FormulateInput
              type="checkbox"
              name="isSignatureApprovalRequired"
            />
          </div>
        </div>

        <div class="flex justify-center p-2">
          <div class="w-1/2">
            <p class="font-medium text-sm mb-1">Require Training Certificate</p>
          </div>
          <div class="w-1/2">
            <FormulateInput type="checkbox" name="isTrainingCertRequired" />
          </div>
        </div>

        <div class="flex justify-center p-2">
          <div class="w-1/2">
            <p class="font-medium text-sm mb-1">Require Medical Certificate</p>
          </div>
          <div class="w-1/2">
            <FormulateInput type="checkbox" name="isMedicalCertRequired" />
          </div>
        </div>

        <div class="flex flex-col px-2" v-if="isEdit">
          <div class="flex flex-col" v-if="!willUpdateCert">
            <button
              class="btn btn-primary mt-2"
              @click.prevent="willUpdateCert = true"
            >
              Update Certificate
            </button>
          </div>
          <div v-else>
            <div>
              <div class="center-text">Update Certificate</div>
              <input
                type="file"
                ref="fileInput"
                accept="application/pdf"
                @change="onFilePicked($event, 'pdf')"
              />

              <button
                class="btn btn-primary mb-4"
                @click.prevent="onPickFile"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Upload file
              </button>
            </div>
            <div class="flex flex-col">
              <button
                class="btn btn-primary mt-2"
                @click.prevent="willUpdateCert = false"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        <div v-else>
          <div>
            <div class="center-text">Update Certificate</div>
            <input
              type="file"
              ref="fileInput"
              accept="application/pdf"
              @change="onFilePicked($event, 'pdf')"
            />

            <button
              class="btn btn-primary mb-4"
              @click.prevent="onPickFile"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Upload file
            </button>
          </div>
        </div>

        <div class="mt-4">
          <FormulateInput
            name="selectedTemplate"
            label="Induction Template"
            type="template-select"
            placeholder="Select a template"
            style="margin-top: 0px"
            :getOptionKey="(option) => option.id"
            :getOptionLabel="(option) => option.name"
            @selected="selectedDriver = $event"
            :filterable="false"
            :clearable="true"
          />
        </div>

        <div v-if="!isEdit" class="flex flex-col pb-6 pt-6">
          <FormulateInput
            type="hidden"
            name="adminSignature"
            class="invisible"
            validation="^required"
            v-model="imageBuffer"
          />
          <!-- DRAWN SIGNATURE -->
          <div
            v-if="!isTypeSignature && !isUploadSignature"
            class="flex flex-col p-2"
          >
            <div class="w-full p-2">Sign Below</div>
            <VueSignaturePad
              :options="{ onEnd }"
              id="signature"
              class="canvas bg-gray-200"
              ref="signaturePad"
              scaleToDevicePixelRatio
            />
            <button @click.prevent="toggleTypeSignature">Type Signature</button>
            <button @click.prevent="toggleUploadSignature">
              Upload Signature
            </button>
            <button @click.prevent="clearSignature">Clear Signature</button>
          </div>

          <!-- UPLOADED SIGNATURE -->

          <div
            v-else-if="isUploadSignature && !typeSignature"
            class="w-full items-center"
          >
            <div class="center-text">Upload Signature</div>
            <div class="flex">
              <input
                type="file"
                ref="fileSignatureInput"
                accept="image/png, image/jpeg"
                name="uploadedSignature"
                @change="onFilePicked($event, 'image')"
              />

              <button
                class="btn btn-primary"
                @click.prevent="onSignatureFilePick"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Upload file
              </button>
            </div>

            <div v-if="!imageBuffer" class="items-center flex flex-col">
              <button @click.prevent="cancelTypeSignature">Back</button>
            </div>
          </div>

          <!-- TYPED SIGNATURE -->

          <div
            v-else-if="typeSignature && !isUploadSignature"
            class="flex flex-col"
          >
            <div class="w-full p-2">Typed Signature</div>
          </div>

          <div class="flex flex-col" v-else>
            <div class="w-full p-2">Type Signature Below</div>

            <v-select
              :options="options"
              label="title"
              :searchable="false"
              :clearable="false"
              v-model="selectedFont"
              v-on:input="onFontChange"
              class="mb-5"
            >
              <template slot="option" slot-scope="option">
                {{ option.title }}
                <img :src="option.cardImage" />
              </template>
            </v-select>

            <input
              class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2"
              id="text"
              v-model="signature"
            />

            <button class="p-2" @click.prevent="saveSignature">
              Save Signature
            </button>
            <button @click.prevent="cancelTypeSignature">Back</button>
          </div>
        </div>
        <div class="flex flex-col pb-6 pt-6" v-else>
          <div class="flex flex-col" v-if="!willUpdateSignature">
            <button
              class="btn btn-primary mt-2"
              @click.prevent="willUpdateSignature = true"
            >
              Update Signature
            </button>
          </div>

          <div class="flex flex-col pb-6" v-else>
            <FormulateInput
              type="hidden"
              name="adminSignature"
              class="invisible"
              validation="^required"
              v-model="imageBuffer"
            />
            <!-- DRAWN SIGNATURE -->
            <div
              v-if="!isTypeSignature && !isUploadSignature"
              class="flex flex-col p-2"
            >
              <div class="w-full p-2">
                {{ isEdit ? "Update Signature" : "Sign Below" }}
              </div>
              <VueSignaturePad
                :options="{ onEnd }"
                id="signature"
                class="canvas bg-gray-200"
                ref="signaturePad"
                scaleToDevicePixelRatio
              />
              <button @click.prevent="toggleTypeSignature">
                Type Signature
              </button>
              <button @click.prevent="toggleUploadSignature">
                Upload Signature
              </button>
              <button @click.prevent="clearSignature">Clear Signature</button>
            </div>

            <!-- UPLOADED SIGNATURE -->

            <div
              v-else-if="isUploadSignature && !typeSignature"
              class="w-full items-center"
            >
              <div class="center-text">Upload Signature</div>
              <div class="flex">
                <input
                  type="file"
                  ref="fileSignatureInput"
                  accept="image/png, image/jpeg"
                  name="uploadedSignature"
                  @change="onFilePicked($event, 'image')"
                />

                <button
                  class="btn btn-primary"
                  @click.prevent="onSignatureFilePick"
                >
                  Upload file
                </button>
              </div>

              <div v-if="!imageBuffer" class="items-center flex flex-col">
                <button @click.prevent="cancelTypeSignature">Back</button>
              </div>
            </div>

            <!-- TYPED SIGNATURE -->

            <div
              v-else-if="typeSignature && !isUploadSignature"
              class="flex flex-col"
            >
              <div class="w-full p-2">Typed Signature</div>
            </div>

            <div class="flex flex-col" v-else>
              <div class="w-full p-2">Type Signature Below</div>

              <v-select
                :options="options"
                label="title"
                :searchable="false"
                :clearable="false"
                v-model="selectedFont"
                v-on:input="onFontChange"
                class="mb-5"
              >
                <template slot="option" slot-scope="option">
                  {{ option.title }}
                  <img :src="option.cardImage" />
                </template>
              </v-select>

              <input
                class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2"
                id="text"
                v-model="signature"
              />

              <button class="p-2" @click.prevent="saveSignature">
                Save Signature
              </button>
              <button @click.prevent="cancelTypeSignature">Back</button>
            </div>
            <button
              class="btn btn-primary mt-2"
              @click.prevent="willUpdateSignature = false"
            >
              Cancel
            </button>
          </div>
        </div>

        <div
          class="p-2 items-center flex flex-col"
          v-if="imageBuffer && renderPreview"
        >
          <div class="p-2 items-center flex flex-col">Preview</div>
          <img v-bind:src="imageBuffer" alt="" />
          <button @click.prevent="revertTypeSignature">Cancel</button>
        </div>

        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors || isLoading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </FormulateForm>
    </div>
    <div v-if="isLoading">
      <div class="text-center">
        <font-awesome-icon
          icon="circle-notch"
          spin
          size="2x"
          class="text-gray-400"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createCanvas } from "canvas";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "bfm-form",

  props: {
    editData: {
      type: Object,
      default: () => null,
    },

    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  async mounted() {
    this.isLoading = true;

    const fontOptions = this.fonts.map((data) => {
      const buffer = this.drawSamples(data);
      return { cardImage: buffer, title: data };
    });

    this.options = fontOptions;

    if (this.editData) {
      let docTemplate;

      const {
        certNumber,
        certExpiryDate,
        isMedicalCertRequired,
        isSignatureApprovalRequired,
        isTrainingCertRequired,
      } = this.editData;

      if (this.editData?.documentTemplate) {
        docTemplate = await this.$store.dispatch(
          "accreditation/fetchOneTemplate",
          {
            templateId: this.editData?.documentTemplate,
          },
        );
      }

      this.bfmFormModel = {
        certNumber: certNumber ?? "",
        certExpiryDate: certExpiryDate ?? "",
        isMedicalCertRequired,
        isTrainingCertRequired,
        isSignatureApprovalRequired,
      };

      if (docTemplate && docTemplate.data && docTemplate.data.data) {
        const { _id, name } = docTemplate.data.data;
        this.bfmFormModel["selectedTemplate"] = {
          id: _id,
          name,
        };
      }
    }
    this.isLoading = false;
  },

  data() {
    return {
      loading: false,
      signature: "",
      imageBuffer: false,

      isTypeSignature: false,
      isUploadSignature: false,
      typeSignature: false,
      renderPreview: false,

      isLoading: false,

      pdf: false,
      fonts: [
        "Arial",
        "Arial Black",
        "Comic Sans MS",
        "Courier New",
        "Impact",
        "PT Sans",
      ],
      options: false,
      selectedFont: "Arial",

      willUpdateCert: false,
      willUpdateSignature: false,

      bfmFormModel: {},
    };
  },

  methods: {
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.imageBuffer = data;
    },

    getExtension(filename) {
      if (filename) {
        let parts = filename.split(".");
        return parts[parts.length - 1];
      } else {
        return;
      }
    },

    onSubmit(data) {
      if (this.willUpdateSignature && this.isEdit && !this.imageBuffer) {
        this.toast("error", "No Admin Signature");
        return;
      }

      if (this.willUpdateCert && this.isEdit && !this.pdf) {
        this.toast("error", "No Certificate");
        return;
      }

      this.$emit("closeModal");
      this.$emit("submit", {
        data,
        file: this.pdf,
        adminSig: this.imageBuffer,
        isEdit: this.isEdit,
        type: "BFM",
      });
    },

    onSignatureFilePick() {
      this.$refs.fileSignatureInput.click();
    },

    onPickFile() {
      this.$refs.fileInput.click();
    },

    revertTypeSignature() {
      this.isTypeSignature = false;
      this.isUploadSignature = false;
      this.imageBuffer = false;
      this.typeSignature = false;
      this.renderPreview = false;

      if (this.$refs.fileSignatureInput?.value) {
        this.$refs.fileSignatureInput.value = null;
      }
    },

    cancelTypeSignature() {
      this.isTypeSignature = false;
      this.isUploadSignature = false;
      this.renderPreview = false;
    },

    clearSignature() {
      this.$refs.signaturePad.openSignaturePad();
      this.isTypeSignature = false;
      this.$refs.signaturePad.clearSignature();
      this.imageBuffer = false;
    },

    drawSamples(font) {
      const width = 1200;
      const height = 200;

      const canvas = createCanvas(width, height);
      const context = canvas.getContext("2d");

      context.font = `bold 25pt '${font}'`;
      context.textAlign = "center";
      context.fillStyle = "#000000";

      context.lineWidth = 5;

      context.beginPath();
      context.moveTo(0, 120);
      context.lineTo(500, 120);
      context.stroke();

      context.beginPath();
      context.moveTo(600, 120);
      context.lineTo(1200, 120);
      context.stroke();

      context.fillText("JD", 900, 100);
      context.fillText("John Doe", 300, 100);
      const buffer = canvas.toDataURL("image/png");

      return buffer;
    },

    onFontChange(data) {
      this.selectedFont = data.title;
    },

    async onFilePicked(event, type) {
      const extension = this.getExtension(event?.target?.files[0]?.name);
      let isValid = false;

      if (type === "pdf" && extension === "pdf") {
        isValid = true;
      }
      if (type === "image" && (extension === "jpg" || extension === "png")) {
        isValid = true;
      }

      if (isValid && extension) {
        const fileReader = new FileReader();
        if (type === "pdf") {
          const files = event.target.files;
          this.pdf = files[0];
        } else if (extension === "png" || extension === "jpg") {
          fileReader.addEventListener("load", () => {
            this.imageBuffer = fileReader.result;
          });

          fileReader.readAsDataURL(event.target.files[0]);
        }
      } else {
        this.toast("error", "Invalid file format");

        if (type === "pdf") {
          // eslint-disable-next-line no-unused-vars
          let fileInput = this.$refs.fileInput;
          fileInput.type = "text";
          fileInput.type = "file";
          this.pdf = false;
        }

        if (type === "image") {
          // eslint-disable-next-line no-unused-vars
          let sigInput = this.$refs.fileSignatureInput;
          sigInput.type = "text";
          sigInput.type = "file";
          this.imageBuffer = false;
        }
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    toggleUploadSignature() {
      if (this.isTypeSignature) {
        this.typeSignature = false;
      }
      this.isUploadSignature = true;
      this.imageBuffer = false;
      this.renderPreview = true;
    },

    toggleTypeSignature() {
      if (this.isUploadSignature) {
        this.isUploadSignature = false;
      }

      this.isTypeSignature = true;
      this.$refs.signaturePad.clearSignature();
      this.$refs.signaturePad.openSignaturePad();
      this.imageBuffer = false;
      this.renderPreview = true;
    },

    saveSignature() {
      if (!_.isEmpty(this.imageBuffer) && this.isTypeSignature) {
        this.isTypeSignature = true;
        this.$refs.signaturePad.clearSignature();
      }

      const width = 1200;
      const height = 200;

      const canvas = createCanvas(width, height);
      const context = canvas.getContext("2d");

      context.font = `bold 25pt '${this.selectedFont}'`;
      context.textAlign = "center";
      context.fillStyle = "#000000";

      context.lineWidth = 5;

      context.beginPath();
      context.moveTo(0, 120);
      context.lineTo(500, 120);
      context.stroke();

      context.beginPath();
      context.moveTo(600, 120);
      context.lineTo(1200, 120);
      context.stroke();
      let initials;

      initials = this.signature
        .match(/(\b\S)?/g)
        .join("")
        .toUpperCase();

      if (!_.isEmpty(initials) && !_.isEmpty(this.signature)) {
        context.fillText(initials.trim(), 900, 100);
        context.fillText(this.signature.trim(), 300, 100);
        const buffer = canvas.toDataURL("image/png");

        this.typeSignature = true;
        this.imageBuffer = buffer;
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style scoped>
input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
</style>
