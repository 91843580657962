<template>
  <div>
    <FormulateInput
      v-if="isDisplayValue"
      type="file"
      :id="control.id"
      :name="control.name"
      :help="control.help"
      :label="control.label"
      label-class="formulate-input-label formulate-input-label--before"
      outer-class="formulate-input"
      input-class=""
      wrapper-class="formulate-input-wrapper"
      upload-area-class="formulate-input-upload-area"
      upload-area-mask-class="formulate-input-upload-area-mask"
      v-model="control.value"
      :required="control.required"
    />
    <FormulateInput
      v-else
      type="file"
      :id="control.id"
      :name="control.name"
      :help="control.help"
      :label="control.label"
      label-class="formulate-input-label formulate-input-label--before"
      outer-class="formulate-input"
      input-class=""
      wrapper-class="formulate-input-wrapper"
      upload-area-class="formulate-input-upload-area"
      upload-area-mask-class="formulate-input-upload-area-mask"
    />
  </div>
</template>

<script>
export default {
  props: {
    control: {
      type: Object,
      default: () => {}
    },
    isDisplayValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>
