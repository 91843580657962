<template>
  <div>
    <div>
      <Table
        :isLoading="loading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="tableData"
        @onPageChange="onPageChange($event)"
        :hasActions="true"
      >
        <template #default="{ data }">
          <td class="px-5 py-2 text-center relative">
            <Dropdown ref="dropdown">
              <ul class="py-2 text-sm">
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="viewPerformance(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                  </StyledIconedLink>
                </li>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="
                      updatePerformance(data.id, data.performance.status)
                    "
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon
                      :icon="
                        data.performance.status == 'ONGOING'
                          ? 'edit'
                          : 'history'
                      "
                      class="my-auto mr-2"
                    />
                    Update
                  </StyledIconedLink>
                </li>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="newInvestigation(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="search" class="my-auto mr-2" />
                    Investigate
                  </StyledIconedLink>
                </li>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click="viewChainOfActions(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="link" class="my-auto mr-2" />
                    Actions Chain
                  </StyledIconedLink>
                </li>
              </ul>
            </Dropdown>
          </td>
        </template>
        <template #tableFilters>
          <div class="flex flex-row mb-1 sm:mb-0">
            <div>
              <select
                v-model="filter.limit"
                @change="fetchViewPerformanceManagement"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>

            <!-- Disabling Search for now until we figure out how to search in BE. -->
            <!-- <div>
              <input
                placeholder="Search"
                autofill="true"
                class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                v-model="filter.search"
                @keyup="handleSearch"
              />
            </div> -->

            <div class="block relative">
              <DatePickerWithRange
                class="ml-2"
                ref="childDatePicker"
                @selected="selectDateRange"
                :date="selectedDateRangeData"
              />
            </div>
          </div>
        </template>
        <template #callToActionTop>
          <div class="flex flex-row"></div>
          <div class="mr-2">
            <button
              v-if="isBusiness"
              class="btn btn-primary"
              :style="{ backgroundColor: theme.primaryColor }"
              @click.prevent="handleSummaryOrDetailedReportClick('summary')"
            >
              Summary Report
            </button>
          </div>
          <!-- <div class="mr-2">
            <button
              class="btn btn-primary"
              :style="{ backgroundColor: theme.primaryColor }"
              @click.prevent="handleSummaryOrDetailedReportClick('detailed')"
            >
              Detailed Report
            </button>
          </div> -->
        </template>
      </Table>
    </div>

    <Modal ref="modal" :title="modalTitle" :size="modalSize">
      <div v-if="modalContent === 'viewPerformance'">
        <ViewPerformance :propsPerformance="selectedPerformance" />
      </div>

      <div v-if="modalContent === 'updatePerformance'">
        <UpdatePerformance :propsPerformance="selectedPerformance" />
      </div>

      <div v-if="modalContent === 'newInvestigation'">
        <AddInvestigationRegister
          @closeModal="closeModal"
          :selectedPerformance="selectedPerformance"
        />
      </div>
      <div v-if="modalContent === 'summary'">
        <SummaryReport
          :dateRange="selectedDateRangeData"
          :businessId="entityMongoId"
        />
      </div>
      <div v-if="modalContent === 'viewChainOfActions'">
        <ViewChainOfActionsModal
          :entityId="performanceId"
          :type="'performance'"
          @toast="toast"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Table, Modal, Dropdown } from "@/components/commons";
import { ROLES } from "@/_helper/constants";
import {
  formatPaginationSettings,
  formatDate,
  capitalizeFirstLetter,
} from "@/_helper/";
import ViewPerformance from "@/components/view/view-performance";
import UpdatePerformance from "@/components/view/update-performance";
import { AddInvestigationRegister } from "@/components/forms/business/investigation-register";
import { PERFORMANCE_STATUSES } from "../_helper/constants";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import DatePickerWithRange from "@/components/commons/field/DatePickerWithRange";
import moment from "moment";
import { SummaryReport } from "@/components/view/business/fatigue-management/performance";
import ViewChainOfActionsModal from "../components/view/business/fatigue-management/ViewChainOfActions.vue";

export default {
  name: "performance",
  components: {
    Modal,
    Table,
    ViewPerformance,
    UpdatePerformance,
    AddInvestigationRegister,
    Dropdown,
    StyledIconedLink,
    DatePickerWithRange,
    SummaryReport,
    ViewChainOfActionsModal,
  },
  data() {
    return {
      loading: false,
      modalSize: "2xl",
      modalTitle: "",
      modalContent: "",
      tableHeader: [
        "Start Date",
        "End Date",
        "Duration",
        "Raised By",
        "Raised Against",
        "Periodic Check",
        "Next Checkup",
        "Date Raised",
        "Status",
      ],
      performances: [],
      entityWebProfileId: null,
      entityMongoId: null,
      entityRole: null,
      filter: {
        limit: 10,
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      selectedPerformance: {},
      isBusiness: null,
      selectedDateRangeData: [],
      performanceId: null,
    };
  },
  async mounted() {
    await this.fetchViewPerformanceManagement();
  },
  methods: {
    async fetchViewPerformanceManagement(page = 1) {
      try {
        this.loading = true;
        const me = this.$store.getters["account/me"];
        this.entityRole = me.role.name;
        this.entityMongoId = me.entity._id;
        this.isBusiness = this.entityRole == ROLES.BUSINESS;
        const query = {
          skip: page * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: { auditee: null, business: null },
        };

        if (me?.entity?._id && me.entity.webProfiles.length) {
          this.entityWebProfileId = me.entity.webProfiles.find((profile) => {
            return profile.uid === me.uid;
          })?._id;
          if (me.role.name == ROLES.DRIVERS) {
            query.filter.auditee = this.entityWebProfileId;
            delete query.filter.business;
          } else {
            query.filter.business = this.entityMongoId;
            delete query.filter.auditee;
          }
          const performances = await this.$store.dispatch(
            "performance/getPerformance",
            query
          );
          if (performances.data?.data) {
            this.performances = JSON.parse(
              JSON.stringify(performances.data.data)
            );

            this.paginationSettings = formatPaginationSettings(
              performances.data.resultsMeta
            );
          }

          if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
          this.loading = false;
        } else {
          throw "Something went wrong";
        }
      } catch (e) {
        this.loading = false;
        this.toast("error", "Error fetching performances");
        throw e;
      }
    },
    async onPageChange(event) {
      await this.fetchViewPerformanceManagement(event.page);
    },
    parseData(performances) {
      return performances.map((performance) => {
        return [
          {
            id: performance._id,
            name: performance.startDate,
            itemType: "string",
            performance,
          },
          {
            id: performance._id,
            name: performance.endDate,
            itemType: "string",
            performance,
          },
          {
            id: performance._id,
            name: performance.durationInMonths + " Months",
            itemType: "string",
            performance,
          },
          {
            id: performance._id,
            name: performance.raisedBy ? performance.raisedBy.userName : "",
            itemType: "string",
            performance,
          },
          {
            id: performance._id,
            name: performance.auditee ? performance.auditee.userName : "",
            itemType: "string",
            performance,
          },
          {
            id: performance._id,
            name: performance.periodicCheck,
            itemType: "string",
            performance,
          },
          {
            id: performance._id,
            name:
              performance.status == PERFORMANCE_STATUSES.CONCLUDED
                ? "N/A"
                : performance.nextPeriodicCheck,
            itemType: "string",
            performance,
          },
          {
            id: performance._id,
            name: formatDate(performance.createdAt),
            itemType: "string",
            performance,
          },
          {
            id: performance._id,
            name: capitalizeFirstLetter(performance.status),
            itemType: "string",
            performance,
          },
        ];
      });
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
    viewPerformance(id) {
      this.$refs.modal.openModal();
      this.selectedPerformance = this.performances.filter(
        (item) => item._id === id
      )[0];
      this.modalContent = "viewPerformance";
      this.modalTitle = "Performance Management Details";
    },
    updatePerformance(id, status) {
      this.$refs.modal.openModal();
      this.selectedPerformance = this.performances.filter(
        (item) => item._id === id
      )[0];
      this.modalContent = "updatePerformance";
      if (status == "ONGOING") this.modalTitle = "Update Performance";
      if (status == "CONCLUDED") this.modalTitle = "Re-open Performance";
    },
    newInvestigation(performanceId) {
      this.$refs.modal.openModal();
      this.selectedPerformance = this.performances.filter(
        (item) => item._id === performanceId
      )[0];
      this.modalContent = "newInvestigation";
      this.modalTitle = "Create New Investigation";
    },
    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
    handleSearch() {
      this.selectDateRange(this.selectedDateRangeData);
    },
    async selectDateRange(selectedDateRangeData = [null], paged = 1) {
      try {
        this.loading = true;
        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          // Disabling search for now until we figure out how to search in BE.
          // search: this.filter.search,
          filter: { auditee: null, business: null },
        };

        if (
          selectedDateRangeData &&
          selectedDateRangeData.length != 0 &&
          selectedDateRangeData[0]
        ) {
          query.from = moment(selectedDateRangeData[0], "YYYY-MM-DD").format();
          query.to = moment(selectedDateRangeData[1], "YYYY-MM-DD").format();
          this.selectedDateRangeData = [
            new Date(moment(selectedDateRangeData[0])),
            new Date(moment(selectedDateRangeData[1])),
          ];
        } else {
          this.selectedDateRangeData = [];
        }

        if (this.entityRole === ROLES.DRIVERS) {
          query.filter.auditee = this.entityWebProfileId;
          delete query.filter.business;
        } else {
          query.filter.business = this.entityMongoId;
          delete query.filter.auditee;
        }

        const performances = await this.$store.dispatch(
          "performance/getPerformance",
          query
        );

        if (performances.data?.data) {
          this.performances = JSON.parse(
            JSON.stringify(performances.data.data)
          );

          this.paginationSettings = formatPaginationSettings(
            performances.data.resultsMeta
          );
        }

        this.loading = false;
      } catch (e) {
        this.loading = false;
        this.toast("error", "Error fetching performances");
        throw e;
      }
    },
    handleSummaryOrDetailedReportClick(reportType) {
      if (!this.selectedDateRangeData || !this.selectedDateRangeData.length) {
        alert("Please select date range!");
        return;
      }

      this.modalContent = reportType;
      this.modalTitle = `Performance ${capitalizeFirstLetter(
        reportType
      )} Report`;
      this.$refs.modal.openModal();
    },
    viewChainOfActions(performanceId) {
      this.$refs.modal.openModal();
      this.modalContent = "viewChainOfActions";
      this.modalTitle = "Chain Of Actions";
      this.performanceId = performanceId;
    },
  },
  computed: {
    tableData() {
      if (this.performances) {
        return this.parseData(this.performances);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style></style>
