<template>
  <div id="app">
    <notify-dialog />
    <div class="flex w-full h-screen" v-if="loadingMessage">
      <div class="m-auto">
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-500">{{ loadingMessage }}</span>
      </div>
    </div>
    <router-view :key="$route.fullPath" @dataLoading="dataLoading"/>
  </div>
</template>

<script>
import Vue from 'vue';
import { Loader } from 'google-maps';
import Spinner from '@/components/commons/ui/Spinner'
import NotifyDialog from '@/components/commons/notification/NotifyDialog';
  export default {
    name: 'App',
    components: {Spinner, NotifyDialog},
    metaInfo: {
      title: 'Welcome',
      titleTemplate: `%s | ${process.env.VUE_APP_NAME ? process.env.VUE_APP_NAME:'Logmaster'}`,
      base: { href: process.env.VUE_APP_URL }
    },
    data(){
      return { 
        urlRoutes : false,
        loadingMessage: null
      }
    },
    async created(){
      await this.$store.dispatch(`setallURL`,{routes:this.$router.options.routes})
      this.urlRoutes = this.$store.getters['allURL'];

      // Load google map
      const loader = new Loader(process.env.VUE_APP_GOOGLE_MAPS_API_KEY, {
        types: ['address'],
        libraries: ['places']
      });
      Vue.prototype.$google = await loader.load();
    },
    methods: {
      dataLoading(loadingMessage) {
        this.loadingMessage = loadingMessage;
      },
    }
  }
</script>