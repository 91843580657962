<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="prestartsData"
      @onPageChange="onPageChange($event)"
      :has-multi-filter="true"
      @onMultiFilter="onMultiFilter"
    >
      <template #tableFilters>
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[42px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewPreStartCheck(data.id, data.isDefault)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="clipboard" class="my-auto mr-2" />
                  View
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="viewEditNote(data.id, data.note)"
                    :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="sticky-note" class="my-auto mr-2" />
                  Note
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent === 'view'">
        <PreStartCheckDetailsView
          v-if="!isOldView"
          :activePreStartCheck="activePreStartCheck"
        />
        <PreStartCheckDetailsOldView
          v-else
          :activePreStartCheck="activePreStartCheck"
        />
      </div>

      <div v-if="modalContent === 'edit-note'">
        <div class="mb-16">
          <div class="loading centered" v-if="loading">
            <ui-spinner class="mx-auto"></ui-spinner>
          </div>
          <div v-if="!loading">
            <FormulateForm @submit="onSubmit">
              <FormulateInput
                  type="text"
                  name="note"
                  v-model="note"
                  label="Note"
                  :disabled="false"
              />
              <StyledSubmit :backgroundColor="theme.secondaryColor">
                <FormulateInput
                    type="submit"
                    label="Save"
                    input-class="btn btn-primary w-full"
                />
              </StyledSubmit>
            </FormulateForm>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Table, Modal, Dropdown } from "@/components/commons";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import PreStartCheckDetailsView from "@/components/view/business/prestart";
import PreStartCheckDetailsOldView from "@/components/view/business/prestart/oldView";
import { formatDate, formatPaginationSettings } from "@/_helper";
import _ from "lodash";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { StyledIconedLink, StyledSubmit } from "@/styled-components";
import { mapGetters } from "vuex";

export default {
  name: "Pre-Start-Check",
  components: {
    StyledSubmit,
    Table,
    Modal,
    Dropdown,
    PreStartCheckDetailsOldView,
    PreStartCheckDetailsView,
    StyledIconedLink,
  },
  data() {
    return {
      modalContent: "",
      modalTitle: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        {
          name: "Driver Name",
          type: "text",
          fieldName: "driver.driverName",
          isFilterable: true
        },
        {
          name: "Plate Number",
          type: "text",
          fieldName: "vehicle.vehiclePlate",
          isFilterable: true
        },
        {
          name: "Vehicle Class",
          type: "text",
          fieldName: "vehicle.vehicleClass.name",
          isFilterable: true
        },
        {
          name: "Vehicle Type",
          type: "text",
          fieldName: "vehicle.vehicleType.name",
          isFilterable: true
        },
        {
          name: "Result",
          type: "text",
          fieldName: "isPassed",
          isFilterable: true
        },
        {
          name: "Date Submitted",
          type: "text",
          fieldName: "savedData.date",
          isFilterable: true
        },
        {
          name: "Note",
          type: "text",
          fieldName: "note",
          isFilterable: true
        },
      ],
      prestartOrgnData: [],
      prestartformatItems: [],
      activePreStartCheck: null,
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: {
        all: "All",
      },
      readprestart: false,
      hasRole: true,
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      isLoading: false,
      isOldView: false,
      multiFilter: {},
      note: '',
      loading: false,
      selectedFormId: '',
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async onMultiFilter(data) {
      this.multiFilter = {...data};
      this.init();
    },
    async init(paged = 1) {
      this.isLoading = true;
      const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status != "all")
        query.filter.status = { $eq: this.filter.status };
      // Reads from businessId to get business site id, otherwise use main business id
      const businessId = me.businessId? me.businessId: me.business?._id


      if(Object.keys(this.multiFilter).length > 0) {
        query.filter = {...this.multiFilter}
      }
      let data = await this.$store.dispatch(`vehicle/getPrestartByBusinessId`, {
        businessId,
        query,
      });

      if (data) {
        this.paginationSettings = formatPaginationSettings(
          new Object(data["metadata"])
        );

        this.prestartOrgnData = data.results;
        this.prestartformatItems = this.formatItem(
          _.orderBy(data.results, ["createdAt"], ["desc"])
        );
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    async viewPreStartCheck(id, isDefault) {
      let data;
      if (typeof isDefault == "boolean") {
        data = await this.$store.dispatch(`prestart/getPrestartById`, id);
        this.isOldView = false;
      } else {
        data = await this.$store.dispatch(`prestart/getOldPrestartById`, id);
        this.isOldView = true;
      }

      if (!_.isEmpty(data)) {
        this.activePreStartCheck = data;
      }
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Pre Start Check Details";
    },
    parsePreStartCheck(data) {
      return data.map((prestartData) => [
        {
          id: prestartData.id,
          name: prestartData.driverName,
          itemType: "name",
          hasImage: false,
          isDefault: prestartData?.isDefault,
          note: prestartData?.note,
        },
        {
          id: prestartData.id,
          name: prestartData?.vehiclePlate,
          itemType: "string",
        },
        {
          id: prestartData.id,
          name: prestartData?.vehicleClass,
          itemType: "string",
        },
        {
          id: prestartData.id,
          name: prestartData?.vehicleType,
          itemType: "string",
        },
        {
          id: prestartData.id,
          name: prestartData.isPassed,
          itemType: "boolean",
        },
        { id: prestartData.id, name: prestartData.date, itemType: "string" },
        { id: prestartData.id, name: prestartData.note, itemType: "string" },
      ]);
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const prestart = {
            id: item._id,
            vehiclePlate: item?.vehicle?.vehiclePlate,
            vehicleClass: item?.vehicle?.vehicleClass?.name || '-',
            vehicleType: item?.vehicle?.vehicleType?.name || '-',
            gmv: item?.vehicle?.GMV,
            isPassed: item?.isPassed ? "Pass" : "Fail",
            isDefault:
              typeof item?.isDefault == "boolean" ? item?.isDefault : null,
            date: formatDate(item?.savedData?.date),
            driverName: item?.driver?.driverName,
            note: item?.note,
          };
          nItems.push(prestart);
        });
      }
      return nItems;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    async viewEditNote(id, note) {
      this.loading = true;
      this.$refs.modal.openModal();
      this.modalContent = "edit-note";
      this.modalTitle = "Edit Note";
      this.selectedFormId = id;
      this.note = note;
      this.loading = false;
    },
    async onSubmit(){
      this.loading = true;
      const payload = {
        note: this.note,
        id: this.selectedFormId,
      }
      await this.$store.dispatch(`prestart/saveNote`,payload)
          .then(()=> {
            this.$store.commit('setDialogNotify',true);
            this.$store.commit('setMessageNotify',{state:'success',message: 'Note successfully saved.'});
          }).catch((err) => {
            this.$store.commit('setDialogNotify',true);
            this.$store.commit('setMessageNotify',{state:'error',message:err.message});
          });
      this.loading = false;
      this.$refs.modal.closeModal();
      await this.init();
    },
  },
  computed: {
    prestartsData() {
      if (this.prestartformatItems) {
        return this.parsePreStartCheck(this.prestartformatItems);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
