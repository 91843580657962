<template>
  <div class="h-full">
    <div class="flex flex-col" v-if="!loading">
      <div class="mb-4 flex justify-center items-center">
        <div
          class="p-5 border border-gray-200 shadow-md rounded-lg group w-full"
        >
          <div>
            <div class="py-2 mx-auto w-full w-full">
              <div class="relative overflow-x-auto">
                <table class="w-full text-sm text-left text-gray-500">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-100">
                    <tr>
                      <th scope="col" class="px-6 py-3 rounded-l-lg">Module</th>
                      <th scope="col" class="px-6 py-3 text-right">
                        Price per Seat
                      </th>
                      <th scope="col" class="px-6 py-3 text-center">
                        Seat Count
                      </th>
                      <th scope="col" class="px-6 py-3 rounded-r-lg text-right">
                        Total
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="bg-white"
                      v-for="(modules, index) in contract.businessModules"
                      :key="index"
                    >
                      <th
                        scope="row"
                        class="px-6 py-2 font-medium text-gray-900 whitespace-nowrap"
                      >
                        {{ modules.name === "EWD-FT" ? "EWD" : modules.name }}
                      </th>
                      <td class="px-6 py-2 text-right">
                        ${{ getBuyPrice(modules) }}
                      </td>
                      <td class="px-6 py-2 text-center">
                        {{ modules.minimums }}
                      </td>
                      <td class="px-6 py-2 text-right">
                        ${{ getTotalPrice(modules) }}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="font-semibold text-gray-900">
                      <th scope="row" class="px-6 py-3 text-base">Total</th>
                      <td class="px-6 py-3 text-right">
                      </td>
                      <td class="px-6 py-3 text-center">
                        {{ this.totals.seatCount }}
                      </td>
                      <td class="px-6 py-3 text-right">
                        ${{ totals.subTotal.toFixed(2) }}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div v-if="totals.subTotal > 0" class="flex justify-between my-4 items-center">
                <div class="font-bold text-sm text-gray-600 text-left flex-1">
                  GST(10%)
                </div>
                <div class="text-right w-40">
                  <div
                    class="text-gray-500 font-bold text-sm"
                    x-html="totalGST"
                  >
                    ${{ totals.gst.toFixed(2) }}
                  </div>
                </div>
              </div>
              <div v-if="totals.subTotal > 0" class="flex justify-between mb-4 items-center">
                <div class="font-bold text-sm text-gray-600 text-left flex-1">
                  Gateway Fee({{ paymentGateway.surchargePercentageFee }}% + ${{
                    paymentGateway.otherFees
                  }})
                </div>
                <div class="text-right w-40">
                  <div
                    class="text-gray-500 font-bold text-sm"
                    x-html="totalGST"
                  >
                    ${{ totals.gatewayFee.toFixed(2) }}
                  </div>
                </div>
              </div>
              <div class="pt-2 border-t">
                <div class="flex justify-between items-center mt-5">
                  <div class="text-sm font-bold text-gray-600 text-left flex-1">
                    Amount due
                  </div>
                  <div class="text-right w-40">
                    <div
                      class="text-xl text-gray-800 font-bold"
                      x-html="netTotal"
                    >
                      ${{ totals.amountDue.toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Payment Details & Contract Details -->
      <div class="mt-4 flex w-full justify-between items-stretch space-x-4">
        <!-- <div class="flex-1">
          <div class="block bg-neutral-bg overflow-hidden h-full rounded-lg p-5">
            <div class="flex flex-col">
              <div class="text-sm font-bold text-gray-500">Payment Details</div>
              <div class="text-gray-800 font-bold mt-5 text-sm">
                <p>
                  <font-awesome-icon icon="credit-card" class="mr-2"/>•••• •••• •••• {{paymentMethod.last4}}
                </p>
              </div>
              <div class="text-gray-500 font-bold mt-5 text-xs">
                <p>Billing start date will be set after confirmation.</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="flex-1">
          <div
            class="block bg-neutral-bg overflow-hidden h-full rounded-lg p-5"
          >
            <div class="flex flex-col">
              <div class="text-sm font-bold text-gray-500">
                Contract Details
              </div>
              <div class="flex justify-between mt-5">
                <p class="text-sm font-bold text-gray-800">Contract Duration</p>
                <p class="text-sm font-bold text-gray-500">
                  {{ contract.contractDuration.name }}
                </p>
              </div>
              <div class="flex justify-between mt-2">
                <p class="text-sm font-bold text-gray-800">Billing Period</p>
                <p class="text-sm font-bold text-gray-500">
                  {{ contract.billingPeriod.name }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4 flex w-full justify-between items-stretch space-x-4">
        <!-- <div class="flex-1">
          <div class="block bg-neutral-bg overflow-hidden h-full rounded-lg p-5">
            <div class="flex flex-col">
              <div class="text-sm font-bold text-gray-500">Payment Details</div>
              <div class="text-gray-800 font-bold mt-5 text-sm">
                <p>
                  <font-awesome-icon icon="credit-card" class="mr-2"/>•••• •••• •••• {{paymentMethod.last4}}
                </p>
              </div>
              <div class="text-gray-500 font-bold mt-5 text-xs">
                <p>Billing start date will be set after confirmation.</p>
              </div>
            </div>
          </div>
        </div> -->

        <div
          class="block bg-neutral-bg overflow-hidden h-full rounded-lg p-5 w-full"
        >
          <label class="font-medium text-sm mb-1">Card Details</label>
          <stripe-element-card
            class="mb-5"
            ref="cardRef"
            :pk="publishableKey"
            @token="tokenCreated"
            @element-change="updateSubmitButton"
            @element-blur="cardChange"
            :hidePostalCode="true"
          />
        </div>
      </div>
      <!-- Buttons -->
      <div class="flex w-full mt-10 justify-center items-center space-x-4">
        <FormulateInput
          type="button"
          @click.prevent="chargeCard"
          input-class="btn btn-primary w-full"
          :disabled="submitLoading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="submitLoading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" />
            {{ buttonText }}</span
          >
          <span v-if="!submitLoading">{{ submitText }}</span>
        </FormulateInput>
      </div>
    </div>
    <!-- Modals -->
    <Modal
      ref="processingPaymentModal"
      :title="modalTitle"
      size="3xl"
      :height="paymentModalHeight"
    >
      <div class="flex flex-col items-center mx-4 my-4">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-12 sm:w-12"
        >
          <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <div class="text-center mt-4 w-full">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Processing your payment
          </h3>
        </div>
        <div class="text-center mt-2">
          <p class="text-sm text-gray-500">
            Please wait while we process your payment request.
          </p>
        </div>
      </div>
    </Modal>
    <Modal
      ref="paymentSuccessfulModal"
      :title="modalTitle"
      size="3xl"
      :height="paymentModalHeight"
    >
      <div class="flex flex-col items-center mx-4 my-4">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-12 sm:w-12"
        >
          <font-awesome-icon icon="check" class="h-6 w-6 text-green-800" />
        </div>
        <div class="text-center mt-4 w-full">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Payment successful
          </h3>
        </div>
        <div class="text-center mt-2">
          <p class="text-sm text-gray-500">
            We have received your payment successfully. Please wait as we
            redirect you back to the main page.
          </p>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Modal } from "@/components/commons";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeElementCard,
    Modal,
  },
  props: {
    activeData: {
      type: Object,
      default: () => {},
    },
    isDemo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      submitLoading: false,
      paymentModalHeight: "20vh",
      modalTitle: "",
      totals: {
        priceperseat: 0,
        seatCount: 0,
        subTotal: 0,
        totalGST: 0,
        gst: 0,
        gatewayFee: 0,
        amountDue: 0,
      },
      hasActivePaymentGateway: false,
      publishableKey: null,
      paymentGatewayId: null,
      paymentGateway: {},
      token: null,
      contract: {},
      loading: true,
      paymentMethod: {},
      buttonText: "Saving data...",
      submitText: "Accept & Pay",
    };
  },
  async mounted() {
    this.contract = await this.activeData.contract;
    console.log(this.contract);
    this.submitText =
      this.isDemo == false ? "Accept & Pay" : "Accept & Start Demo";
    const paymentGateway = await this.$store.dispatch(
      "payment/getPaymentGatewayByUid",
      {
        uid: this.activeData?.entity.parentUid,
        role: this.activeData?.entity.parentRole,
      }
    );
    if (paymentGateway) {
      this.paymentGatewayId = paymentGateway._id;
      this.publishableKey = paymentGateway.apiKey;
      this.hasActivePaymentGateway = true;
      this.paymentGateway = paymentGateway;

      let amount = 0;
      let priceperseat = 0;
      let seatcount = 0;
      let totalSubTotal = 0;
      let billingPeriod = this.contract.billingPeriod.name.toUpperCase();
      this.contract.businessModules.forEach((module) => {
        if (billingPeriod === "YEARLY") {
          priceperseat = module.price.yearly;
        } else if (billingPeriod === "MONTHLY") {
          priceperseat = module.price.monthly;
        }
        totalSubTotal += priceperseat * module.minimums
        seatcount += module.minimums;
      });

      amount = totalSubTotal

      const gst = (amount / 100) * this.paymentGateway.gstPercentageFee;
      const totalGST = amount + gst;
      const gatewayFee =
        (totalGST / 100) * this.paymentGateway.surchargePercentageFee +
        this.paymentGateway.otherFees;
      const amountDue = totalGST + gatewayFee;

      if(amount > 0) {
        this.totals = {
          seatCount: seatcount,
          subTotal: amount,
          totalGST: totalGST,
          gst: gst,
          gatewayFee: gatewayFee,
          amountDue: amountDue,
        };
      } else {
        this.totals = {
          seatCount: seatcount,
          subTotal: amount,
          totalGST: 0,
          gst: 0,
          gatewayFee: 0,
          amountDue: 0,
        };
      }

      await this.getPaymentMethod(this.contract.businessMongoId);
    }
    this.loading = false;
  },

  methods: {
    updateSubmitButton() {
      this.submitLoading = true;
      this.buttonText = "Validating Card Details...";
    },
    cardChange() {
      this.$refs.cardRef.submit();
    },
    tokenCreated(token) {
      this.token = token.id;
      this.submitLoading = false;
      this.buttonText = "Saving data...";
    },
    async getPaymentMethod(businessId) {
      await this.$store
        .dispatch(`payment/getPaymentMethodByEntityId`, businessId)
        .then((response) => {
          if (response) {
            this.paymentMethod = response;
          }
        })
        .catch((err) => {
          // this.showAccountAlert = true
          this.errorMessage = err;
          // this.$refs.paymentUpdateModal.toggleModal();
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async chargeCard() {
      this.submitLoading = true;
      this.buttonText = "Saving data...";
      if (this.isDemo !== true) {
        this.$refs.processingPaymentModal.toggleModal();
      }
      const params = {
        updateContractStatus: {
          contractStatusName: "Accepted",
          remarks: "Accepted",
        },
        paymentDetails: {
          entityRole: "business",
          paymentGatewayId: this.paymentGatewayId,
          cardToken: this.token,
          isDefault: true,
          isActive: true,
        },
        id: this.contract._id,
        isDemo: this.isDemo,
      };
      if (this.isDemo !== "true") {
        params.chargeBusinessDetails = {
          entityId: this.contract.businessMongoId,
          entityRole: "business",
          amount: this.totals.subTotal,
        };
      }
      this.$store
        .dispatch("contract/acceptContractAndChargePayment", params)
        .then((response) => {
          if (response) {
            if (this.isDemo !== true) {
              this.$refs.processingPaymentModal.closeModal();
              this.$refs.paymentSuccessfulModal.closeModal();
            }
            setTimeout(() => {
              this.$refs.paymentSuccessfulModal.closeModal();
              this.submitLoading = false;
              this.$emit("closeModal");
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: "Contract successfully accepted.",
              });
              setTimeout(() => {
                location.reload();
              }, 3000);
            }, 2500);
          }
        })
        .catch((error) => {
          if (this.isDemo !== true) {
            this.$refs.processingPaymentModal.closeModal();
            this.$refs.paymentSuccessfulModal.closeModal();
          }
          this.submitLoading = false;
          this.incompleteForm = false;
          this.errorMessage = error?.message;
          this.$emit("closeModal");
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: "Failed to process. Continue setup after account login.",
          });
        })
        .finally(() => {
          this.submitLoading = false;
          this.buttonText = "Accept & Pay";
        });
    },
    getBuyPrice(module) {
      if (this.contract.billingPeriod.name.toUpperCase() == "MONTHLY") {
        return module.price.monthly;
      } else if (this.contract.billingPeriod.name.toUpperCase() === "YEARLY") {
        return module.price.yearly;
      }
    },
    getTotalPrice(module) {
      if (this.contract.billingPeriod.name.toUpperCase() == "MONTHLY") {
        return Number(module.price.monthly) * Number(module.minimums);
      } else if (this.contract.billingPeriod.name.toUpperCase() === "YEARLY") {
        return Number(module.price.yearly) * Number(module.minimums);
      }
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
