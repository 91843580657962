<template>
  <div>
    <div class="text-center">
      <div class="font-bold text-xl" :class="eventTypeClass">
        {{ event.type }}
      </div>
    </div>
    <div class="mb-5 border-b">
      <div class="text-xs font-bold">Driver Details</div>
    </div>
    <div class="mb-5">
      <div class="font-bold">
        {{ event.driverId.driverName }}
      </div>
      <div class="text-xs">Name</div>
    </div>
    <div class="mb-5">
      <div class="font-bold">
        {{ event.driverId.driverName }}
      </div>
      <div class="text-xs">Email</div>
    </div>
    <div class="mb-5">
      <div class="font-bold">
        {{ event.businessId.persona.businessName }}
      </div>
      <div class="text-xs">Business Name</div>
    </div>
    <div class="mb-5">
      <div class="font-bold">
        {{ event.businessId.supportEmail }}
      </div>
      <div class="text-xs">Business Email</div>
    </div>
    <div class="mb-5 border-b">
      <div class="text-xs font-bold">Event Changes</div>
    </div>
    <div v-if="event.eventChanges.length">
      <div
        v-for="(eventChange, index) in event.eventChanges"
        :key="index"
        class="grid grid-cols-2"
      >
        <div class="mb-5">
          <div class="font-bold text-xs">
            {{ eventChange.from ?? "N/A" }}
          </div>
          <div class="text-xs">{{ eventChange.field }} (Old value)</div>
        </div>
        <div class="mb-5">
          <div class="font-bold text-xs">
            {{ eventChange.to }}
          </div>
          <div class="text-xs">{{ eventChange.field }} (New value)</div>
        </div>
      </div>
    </div>
    <div v-else class="text-xs mb-5">No changes recorded.</div>
    <div v-if="event.isBreachCleared" class="mb-5 border-b">
      <div class="text-xs font-bold">Breaches</div>
    </div>
    <div v-if="event.isBreachCleared" class="mb-5">
      <div
        class="font-bold"
        :class="event.isBreachCleared ? 'text-green-500' : 'text-red-500'"
      >
        {{ event.isBreachCleared }}
      </div>
      <div class="text-xs">Breaches Cleared</div>
    </div>
    <div v-if="event.isBreachCleared">
      <div v-for="(breach, index) in event.clearedBreaches" :key="index">
        <div class="text-xs">Breach #{{ index + 1 }}</div>
        <div class="grid grid-cols-3 border-t mb-2">
          <div class="mb-5 mt-2">
            <div class="font-bold text-xs">
              {{ breach.breachTime }}
            </div>
            <div class="text-xs">Breach Time</div>
          </div>
          <div class="mb-5 mt-2">
            <div class="font-bold text-xs">
              {{ breach.level }}
            </div>
            <div class="text-xs">Level</div>
          </div>
          <div class="mb-5 mt-2">
            <div class="font-bold text-xs">
              {{ breach.location }}
            </div>
            <div class="text-xs">Location</div>
          </div>
          <div class="mb-5 mt-2">
            <div class="font-bold text-xs">
              {{ breach.option }}
            </div>
            <div class="text-xs">Option</div>
          </div>
          <div class="mb-5 mt-2">
            <div class="font-bold text-xs">
              {{ breach.period }}
            </div>
            <div class="text-xs">Period</div>
          </div>
          <div class="mb-5 mt-2">
            <div class="font-bold text-xs">
              {{ breach.type }}
            </div>
            <div class="text-xs">Type</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { EVENT_MODIFICATION_TYPES } from "@/_helper/constants";
export default {
  name: "Event-Modification-Detail",
  components: {},
  props: {
    eventData: {
      type: Object,
    },
  },
  data() {
    return {
      eventTypeClass: "",
      event: null,
    };
  },

  created() {
    this.event = this.formatData(this.eventData);
  },
  methods: {
    formatData(eventData) {
      switch (eventData.type) {
        case "event-deletion":
          this.eventTypeClass = "text-red-500";
          break;
        case "event-edit":
          this.eventTypeClass = "text-yellow-500";
          break;
      }
      return {
        ...eventData,
        type: EVENT_MODIFICATION_TYPES[eventData.type],
      };
    },
  },
};
</script>
