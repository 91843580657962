<template>
  <div class="bg-black flex min-h-screen justify-center items-center ">
    <div class="container py-10 h-full flex flex-col xl:w-1/2 lg:w-2/3 w-full">
      <div class="text-center m-5">
        <a :href="$store.getters['baseURL']"
          ><img
            src="assets/images/logo.png"
            alt=""
            class="w-full m-auto"
            style="width: 40%"
        /></a>
      </div>
      <div class="rounded-lg bg-gray-50 p-10 shadow">
        <SignUpForm :isSolo="isSolo" :formTitle="formTitle" />
      </div>
    </div>
  </div>
</template>

<script>
import { SignUpForm } from "@/components/forms";
export default {
  data() {
    return {
      isSolo: null,
      formTitle: null,
    };
  },
  components: {
    SignUpForm,
  },
  mounted() {
    if (this.$route.path == "/soloproviders") {
      this.isSolo = true;
      this.formTitle = "Pick A Logmaster Provider For A Solo Driver";
    } else if (this.$route.path == "/businessproviders") {
      this.isSolo = false;
      this.formTitle = "Business Auto Signup Page";
    }
    console.log("route..", this.$route.path);
  },
  methods: {
    toggleForm(form) {
      this.activeForm = form;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
