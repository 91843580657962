<template>
  <div class="flex flex-col w-full h-screen">
    <div v-if="!toggle">
      <Table
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :isLoading="isLoading"
        :data="accreditationData"
        @onPageChange="onPageChange($event, 'associatedDriver')"
      >
        <template slot="tableFilters">
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>

            <div class="relative">
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>

            <div class="relative">
              <span
                class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
              >
                <font-awesome-icon icon="search" class="text-gray-400" />
              </span>
              <input
                placeholder="Search"
                class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                v-model="filter.search"
                @keydown="handleSearchKeyDown"
              />
            </div>
          </div>
        </template>

        <template #default="{ data }">
          <td class="px-5 py-2 text-center relative">
            <Dropdown ref="dropdown">
              <ul class="py-2 text-sm">
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="associateDrivers(data)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="clipboard" class="my-auto mr-2" />
                    Examine
                  </StyledIconedLink>
                </li>
              </ul>
            </Dropdown>
          </td>
        </template>

        <template slot="callToActionTop"> </template>
      </Table>
    </div>
    <div v-else>
      <ManageAccreditationContent
        :certDetails="toggle"
        :isRecordKeeper="isRecordKeeper"
        @unToggle="unToggle"
        @reToggle="reToggle"
      />
    </div>
  </div>
</template>

<script>
import { Table, Dropdown } from "@/components/commons";
import { filterRow, formatPaginationSettings, formatDate } from "@/_helper/";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import ManageAccreditationContent from "@/components/view/business/accreditation/manage-accreditation-content.vue";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "manage-accreditation",
  components: { Table, Dropdown, ManageAccreditationContent, StyledIconedLink },
  data() {
    return {
      tableHeader: ["Certificates", "Type", "Created On"],
      associatedDrivers: [],
      certs: [],
      toggle: false,
      selectedUser: false,
      certToDelete: false,
      isLoading: false,

      modalTitle: "",
      modalSize: "2xl",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalContent: "",

      filter: {
        limit: 10,
        search: "",
      },

      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },

      isRecordKeeper: null,
    };
  },

  async mounted() {
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.isRecordKeeper = securityAccessObj.inductDrivers;
    }
    await this.refetchData();
  },

  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },

  methods: {
    setSecurityAccess(securityRoles) {
      let initialInductDrivers = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.drivers.inductDrivers.value) {
          initialInductDrivers = securityConfig.drivers.inductDrivers.value;
        }
      });
      return {
        inductDrivers: initialInductDrivers,
      };
    },

    onPickMed() {
      this.$refs.medCertFile.click();
    },

    onPickTrain() {
      this.$refs.trainingFile.click();
    },

    unToggle() {
      this.toggle = false;
    },

    async onPageChange(event, type) {
      if (type === "associatedDriver") {
        await this.refetchData(event.page);
      }
    },

    async reToggle(id) {
      this.unToggle();
      const updatedCertResult = await this.$store.dispatch(
        `accreditation/findOneCert`,
        {
          id,
        }
      );

      const updatedCert = {
        id: updatedCertResult?.data?.data?._id,
        name: updatedCertResult?.data?.data?.certNumber,
        itemType: "string",
        associatedDrivers: JSON.parse(
          JSON.stringify(updatedCertResult?.data?.data?.drivers)
        ),
        certDetails: updatedCertResult?.data?.data,
      };

      await this.refetchData();
      this.toggle = updatedCert;
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    async debouncedSearchString() {
      await this.refetchData();
    },

    async filterChange() {
      await this.refetchData();
    },

    async refetchData(paged = 1) {
      this.$emit("updateLoading", true);
      this.isLoading = true;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      const me = await this.$store.getters[`account/me`];

      const associatedCerts = await this.$store.dispatch(
        `accreditation/getAllAccreditation`,
        { userData: me, pagination: query }
      );

      if (associatedCerts?.data?.data?.resultsMeta) {
        this.paginationSettings = formatPaginationSettings(
          associatedCerts?.data?.data?.resultsMeta
        );
      }

      if ((this.associatedDrivers = associatedCerts?.data?.data?.results)) {
        this.associatedDrivers = associatedCerts?.data?.data?.results;
      } else {
        this.associatedDrivers = [];
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.$emit("updateLoading", false);
      this.isLoading = false;
    },

    associateDrivers(data) {
      this.toggle = data;
    },

    closeModal() {
      this.$refs.modal.closeModal();
    },

    parseAccreditation(data) {
      if (data) {
        return this?.associatedDrivers?.map((driverData) => {
          return [
            {
              id: driverData?._id,
              name: driverData?.certNumber,
              itemType: "string",
              associatedDrivers: JSON.parse(
                JSON.stringify(driverData?.drivers)
              ),
              certDetails: driverData,
            },
            {
              id: driverData?._id,
              name: driverData?.type,
              itemType: "string",
            },
            {
              id: driverData?._id,
              name: formatDate(driverData.createdAt),
            },
          ];
        });
      }
      return [];
    },
  },

  computed: {
    accreditationData() {
      if (this.associatedDrivers) {
        let tableData = this.associatedDrivers;
        if (this.searchKeyword) {
          tableData = tableData.filter((item) =>
            filterRow(this.searchKeyword, item.linkageId)
          );
        }

        return this.parseAccreditation(tableData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style scoped>
input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
</style>
