<template>
    <div>
        <div class="flex flex-row mb-5 w-full border-b pb-1">
            <div class="flex md:ml-0 ml-12 mt-3">
                <div class="font-bold">Front Couplings</div>
            </div>
            <div class="ml-auto">
                <button class="btn btn-primary" @click.prevent="itemAdd" v-if="!loading" :style="{backgroundColor: theme.secondaryColor}">
                    Add
                </button>
            </div>
        </div>
        <div class="overflow-y-auto">
            <div class="loading mt-32 h-32" v-if="loading">
                <ui-spinner class="mx-auto"></ui-spinner>
            </div> 
            <table class="min-w-full leading-normal table-fixed" v-if="!loading"> 
                <thead>
                    <tr>
                        <th class="px-5 py-3 w-4/5 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Name
                        </th>
                        <!-- <th class="px-5 py-3 w-2/5 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Compatibility
                        </th> -->
                        <th class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Status
                        </th>
                        <th class="px-5 py-3 border-gray-200 bg-gray-200 text-right text-xs font-semibold text-gray-600 uppercase tracking-wider">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="(configItem, index) in frontcouplings" :key="index"
                    class="border-b border-gray-200 text-sm bg-white hover:bg-gray-100 cursor-default"
                    :class="configItem.isEdit? 'bg-yellow-50 hover:bg-yellow-50' : ''"
                    >
                        <td class="px-2 py-2">
                            <input class="w-11/12" :disabled="!configItem.isEdit" type="text" v-model="configItem.name">
                        </td>
                        <!-- <td class="px-2 py-2">
                            <select :disabled="!configItem.isEdit" v-model="configItem.compat">
                                <option value="">- Select -</option>
                                <option v-for="rearcpl in rearcouplings" :key="rearcpl.name" :value="rearcpl._id">
                                    {{ rearcpl.name }}
                                </option>
                            </select>
                        </td> -->
                        <td class="px-2 py-2 text-center">
                            <div v-if="!configItem.isDefault && configItem.id">
                                <input type="checkbox"
                                :disabled="!configItem.isEdit"
                                v-model="configItem.isActive">
                            </div>
                            <div v-if="configItem.isDefault">
                                <span class="text-xs text-gray-500 italic">default</span>
                            </div>
                        </td>
                        <td class="px-2 py-2 flex justify-end">
                            <div v-if="!configItem.isDefault">
                                <div v-if="!configItem.isEdit" class="flex">
                                    <a class="flex cursor-pointer"
                                    @click.prevent="itemEdit(index)"
                                    ><font-awesome-icon
                                        icon="edit"
                                        class="my-auto mx-1.5 hover:text-orange-primary"
                                    /></a>
                                </div>
                                <div v-if="configItem.isEdit" class="flex">
                                    <a class="flex cursor-pointer"
                                    @click.prevent="itemSave(configItem.id, configItem.name, configItem.isActive)"
                                    ><font-awesome-icon
                                        icon="check"
                                        class="my-auto mx-1.5 hover:text-orange-primary"
                                    /></a>
                                    <a class="flex cursor-pointer"
                                    @click.prevent="itemDiscard(index)"
                                    ><font-awesome-icon
                                        icon="times"
                                        class="my-auto mx-1.5 hover:text-orange-primary"
                                    /></a>
                                </div>
                            </div>
                            <div v-if="configItem.isDefault">
                                <div v-if="!configItem.isEdit" class="flex">
                                    <font-awesome-icon
                                        icon="edit"
                                        class="my-auto mx-1.5 text-gray-200"
                                    />
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { orderBy } from 'lodash'
import { mapGetters } from 'vuex'

export default {
    name: "Vehicle-Config-Front-Couplings",
    data() {
        return {
            loading: false,
            frontcouplings: [],
            rearcouplings: [],
            dataholder: {
                name: '',
                select: 0
            }
        }
    },
    async mounted() {
        this.loading = true
        this.init()
    },
    methods: {
        async init() {
            this.loading = true
            const me = this.$store.getters[`account/me`];
            const configData = await this.$store.dispatch(`vehicle/getFrontCouplingByUID`, me.uid)
            if(configData){
                this.frontcouplings = this.formatItem(orderBy(configData, ['createdAt'],['asc']));
                this.loading = false
            } 
        },
        itemEdit(index) {
            this.dataholder.name = this.frontcouplings[index].name
            this.dataholder.select = this.frontcouplings[index].compat
            this.frontcouplings[index].isEdit = true;
        },
        itemSave(id, name, status) {
            if(id) {
            this.$store.dispatch(`vehicle/updateFrontCoupling`, {id:id, name:name, isActive:status}).then(item => {
                if(item && item._id){
                    this.init()
                }
            })} else {
            this.$store.dispatch(`vehicle/createFrontCoupling`, {name:name}).then(item => {
                if(item && item._id){
                    this.init()
                }
            })}
        },
        itemDiscard(index) {
            if(this.frontcouplings[index].id == null) {
                this.frontcouplings.splice(this.frontcouplings.indexOf(index), 1);
            }
            else {
                this.frontcouplings[index].name = this.dataholder.name
                this.frontcouplings[index].compat = this.dataholder.select
                this.frontcouplings[index].isEdit = false;
            }
        },
        itemAdd() {
            var valObj = this.frontcouplings.filter(function(elem){
                if(elem.id == null && elem.isEdit == true) {
                    return elem;
                }
            });

            if(valObj.length == 0) {
                this.frontcouplings.push({
                    id: null,
                    name: '',
                    compat: null,
                    isActive: true,
                    isEdit: true
                })
            }
        },
        formatItem(items) {
            let nItems = [];
            if(items){
                items.map(item => {
                const tempItem = {
                    id:item['_id'],
                    name:item['name'],
                    compat:item['compat'],
                    isActive:item['isActive'],
                    isDefault:item['isDefault'],
                    isEdit:false
                }
                nItems.push(tempItem)
                });
            }
            return nItems;
        },
    },
    computed: {
        ...mapGetters('theme', {
        theme: 'getColorScheme'
        })
    }
}
</script>

<style>

</style>