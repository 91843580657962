<template>
  <div>
    <div>
      <Tabs>
        <Tab title="NHVR">
          <NHVRBreachesReport></NHVRBreachesReport>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>


<script>
import Tabs from "@/components/commons/tab/Tabs.vue";
import Tab from "@/components/commons/tab/Tab.vue";
import NHVRBreachesReport from "@/pages/business/NHVRBreachesReport";
export default {
  name: "ReportBreaches",
  components: { NHVRBreachesReport, Tab, Tabs },
}
</script>