import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class Contract {
    // @Contract
    createContract(data){ // Create contract
        return API.ACCOUNTS.post(`/business-contract`, data)
    }

    createDriverContract(data) {
        return API.ACCOUNTS.post(`/business-contract/driver-contract`, data)
    }

    createContractAndPayment(data) {
        return API.ACCOUNTS.post(`/business-contract/driver-contract-and-payment`, data)
    }
    getContract( id = null ){ // Get single Contract by Id or Get all Contract
        if(id){
            return API.ACCOUNTS.get(`/business-contract/${id}`)
        }else{
            return API.ACCOUNTS.get(`/business-contract`)
        }
    }
    getMyContract( data ){ // Get single Contract by Id or Get all Contract
        return API.ACCOUNTS.get(`/business-contract/my-contract/${data.entityId}`)
    }
    getContractByUID( uid ){
        return API.ACCOUNTS.get(`/business-contract/find-by-uid/${uid}`)
    }

    generateBillingReport(data) {
        return API.ACCOUNTS.post(`/business-contract/generate-billing-report/${data.entityId}`, data)
    }

    updateContract( contractId, data ){
        return API.ACCOUNTS.patch(`/business-contract/update-business-contract${contractId}`, data)
    }

    updateDriverContract( contractId, data ){
        return API.ACCOUNTS.patch(`/business-contract/update-solo-contract/${contractId}`, data)
    }

    updateLastPaymentDate( contractId, data ){
        return API.ACCOUNTS.patch(`/business-contract/update-last-payment-date/${contractId}`, data)
    }

    updatePaymentTypeAndContract( contractId, data ){
        return API.ACCOUNTS.patch(`/business-contract/update-payment-type-and-contract/${contractId}`, data)
    }
    
    updateBusiness(businessId, data){ 
        return API.ACCOUNTS.patch(`/business/${businessId}`, data)
    }

    updateContractCredit( data ){
        return API.ACCOUNTS.patch(`/business-contract/update-contract-credit/${data.contractId}`, data)
    }

    updateContractBillingPeriod( data ){
        return API.ACCOUNTS.patch(`/business-contract/update-contract-billing-period/${data.contractId}`, data)
    }

    deleteContract( id = null ){ // Delete Contract by Id
        return API.ACCOUNTS.delete(`/business-contract/${id}`)
    }
    
    getSingleBusinessContractByBusinessMongoId(id){
        return API.ACCOUNTS.get(`/business-contract/find-one-active-by-business-mongo-id/${id}`)
    }

    getSingleBusinessContractByPartnerId(id){
        return API.ACCOUNTS.get(`/business-contract/find-one-active-by-partner-id/${id}`)
    }
    
    updateBusinessContractFormSetup(data){
        return API.ACCOUNTS.patch(`/business-contract/update-form-setup/${data.id}`, data)
    }

    acceptContractAndChargePayment(data) {
        return API.ACCOUNTS.patch(`/business-contract/accept-contract-and-charge-payment/${data.id}`, data)
    }
    
    getActiveBusinessContractFormSetupByBusinessId(id){
        return API.ACCOUNTS.get(`/business-contract/active-form-setup-by-business-id/${id}`)
    }

    getAllContracts(query) {
        let filter = '';
        console.log(query)
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/business-contract${query}`)
    }

    getBusinessContracts(data,  query = { skip: 0, limit: 10, filter: {} }) {
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/business-contract/find-all-by-business-mongo-id/${data.businessId}${query}`)
    }

    getPartnerBusinessContracts(data, query = { skip: 0, limit: 10, filter: {} }) {
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/business-contract/find-business-contracts-by-partner-mongo-id/${data.partnerId}${query}`)
    }

    getPartnerDriverContracts(data, query = { skip: 0, limit: 10, filter: {} }) {
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/business-contract/find-driver-contracts-by-partner-mongo-id/${data.partnerId}${query}`)
    }

    getResellerDriverContracts(data, query = { skip: 0, limit: 10, filter: {} }) {
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/business-contract/find-driver-contracts-by-reseller-mongo-id/${data.resellerId}${query}`)
    }

    acceptContract({contractId, remarks}){
        return API.ACCOUNTS.patch(`/business-contract/accept/${contractId}`, {remarks})
    }

    rejectContract({contractId, remarks}){
        return API.ACCOUNTS.patch(`/business-contract/reject/${contractId}`, {remarks})
    }

    // @ContractTerms
    createContractTerms(data){
        return API.ACCOUNTS.post(`/contract-term`, data)
    }

    getContractTerms(){
        return API.ACCOUNTS.get(`/contract-term`)
    }

    getContractTermsByUID(uid){
        return API.ACCOUNTS.get(`/contract-term/${uid}`)
    }

    getLogmasterTermByPartner(uid){
        return API.ACCOUNTS.get(`/contract-term/logmaster-default/partner/${uid}`)
    }

    getLogmasterTermByReseller(uid){
        return API.ACCOUNTS.get(`/contract-term/logmaster-default/reseller/${uid}`)
    }

    getLogmasterTerms(){
        return API.ACCOUNTS.get(`/contract-term/logmaster/logmaster`)
    }

    getPartnerTermByReseller(data){
        return API.ACCOUNTS.post(`/contract-term/partner-default/${data.id}`, data)
    }

    getPartnerTermsByPartner(uid){
        return API.ACCOUNTS.get(`/contract-term/partner/${uid}`)
    }

    getResellerTermsByReseller(uid){
        return API.ACCOUNTS.get(`/contract-term/reseller/${uid}`)
    }

    updateContractTerms(data){
        return API.ACCOUNTS.patch(`/contract-term/${data.id}`, data)
    }

    updateContractTermUser(data){
        return API.ACCOUNTS.patch(`/contract-term/user/${data.id}`, data)
    }

    cloneContractTerms(uid){
        return API.ACCOUNTS.post(`/contract-term/clone/${uid}`)
    }

    deactivateContractTerms(uid){
        return API.ACCOUNTS.post(`/contract-term/deactivate/${uid}`)
    }

    setContractTermsActivateStatus(uid){
        return API.ACCOUNTS.post(`/contract-term/activate/${uid}`)
    }

    // @ContractTermsGroup
    createContractTermGroup(data){
        return API.ACCOUNTS.post(`/contract-term-group`, data)
    }

    saveSignatureContractTermGroup(data){
        return API.ACCOUNTS.post(`/contract-term-group/signature/${data.id}`, data)
    }

    getContractTermsGroups(id = null){
        if(id){
            return API.ACCOUNTS.get(`/contract-term-group/${id}`)
        }else{
            return API.ACCOUNTS.get(`/contract-term-group`)
        }
    }

    getContractTermGroupsByPartner(partnerUid){
        return API.ACCOUNTS.get(`/contract-term-group/partner/${partnerUid}`)

    }

    getContractTermGroupsByReseller(resellerUid){
        return API.ACCOUNTS.get(`/contract-term-group/reseller/${resellerUid}`)

    }

    getContractTermsGroupByBusiness(id){
        return API.ACCOUNTS.get(`/contract-term-group/business/${id}`)
    }

    getContractTermsGroupsByType(type){
        return API.ACCOUNTS.get(`/contract-term-group/find-by-terms-type/${type}`)
    }

    //Create contract for new businesses
    createBusinessContract(data){
        return API.ACCOUNTS.post(`/business-contract/business-contract`, data)
    }

    //Fetch Contact Duration Options
    getContractDurationOptions(){
        return API.ACCOUNTS.get(`/contract-duration`)
    }

    //Fetch Contact Duration Options
    getBillingPeriodOptions(){
        return API.ACCOUNTS.get(`/billing-period`)
    }

    resendInvite(contractId){
        return API.ACCOUNTS.post(`/business-contract/resend-invite/${contractId}`)
    }

    cancelSubscription(entityId) {
        return API.ACCOUNTS.post(`/business-contract/cancel-subscription/${entityId}`)
    }

    cancelDemoAndContract(entityId) {
        return API.ACCOUNTS.post(`/business-contract/cancel-demo-and-contract/${entityId}`)
    }

    addSeatCount(data) {
        return API.ACCOUNTS.post(`/business-contract/add-seat-count/${data.contractId}`, data);
    }
}