<template>
  <div class="w-full min-h-full flex-row items-center">
    <div class="w-full min-h-full flex-row">
      <FormBuilder 
          :isDefault="true" 
          formSubmitted="formSubmitted"
        />
    </div>
  </div>
</template>
<script>
import FormBuilder from '@/components/commons/FormBuilder';
 
export default {
  components: {
    FormBuilder
  },
  data: () => ({
    formData: null,
    isRenderer: true,
    readOnly: false,
  }),
  methods: {
    formSubmitted(res){
      this.toast('success', res.message);
    },
    toast(state, msg){
      const message = {
        state: state,
        message: msg
      }
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', message);
    }
  }
}
</script>