<template>
    <div
        :data-type="context.type"
        :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
    >
        <list-select
            ref="selectRef"
            :options="options"
            :context="context"
            :isLoading="isLoading"
            :hasPrevPage="hasPrevPage"
            :hasNextPage="hasNextPage"
            noResultText="No drivers found."
            v-bind="omit(context.attributes, 'class')"
            :class="{ 'multiple-selection': 'multiple' in context.attributes }"
            @prevPage="onPrevPage"
            @nextPage="onNextPage"
            @search="onSearch"
        >
            <template slot="option" slot-scope="option">
                <div class="flex items-center overflow-hidden">
                    <div v-if="hasCheckbox" class="flex items-center mr-2">
                        <input v-model="option.isChecked" type="checkbox" class="w-5 h-5 text-blue-600 bg-gray-100 rounded"/>
                    </div>
                    <div class="text-sm w-full">
                        <h3 class="m-0 font-semibold truncate">
                            {{ option.driver.driverName }} 
                        </h3>
                        <div class="flex items-center">
                            <span style="font-size: 12px;">{{ option.driver.udi }}</span>
                            <span 
                                v-if="option.status == 'linked-non-ewd'" 
                                class="font-normal rounded text-white px-1 ml-1" 
                                style="font-size: 7px; background-color: rgb(255, 153, 0)"
                            >
                                NON-EWD
                            </span>
                            <span 
                                v-if="option.status == 'delinked'" 
                                class="font-normal rounded text-white px-1 ml-1" 
                                style="font-size: 7px; background-color: rgb(239, 68, 68)"
                            >
                                DELINKED
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <template slot="selected-option" slot-scope="option">
                <div class="selected text-sm whitespace-nowrap">
                    {{ option.driver.driverName }}
                </div>
            </template>
        </list-select>
    </div>
</template>
<script>
import { debounce, uniqWith } from 'lodash';
import { formatPaginationSettings } from '@/_helper';
import ListSelect from '@/components/commons/ui/list-select/ListSelect';

const DriverSelect = {
    name: 'DriverSelect',
    components: {
        ListSelect
    },
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    mounted() {
        if (this.context.attributes?.isIncludeDelinked) this.isIncludeDelinked = this.context.attributes?.isIncludeDelinked;
        if (this.context.attributes?.hasCheckbox) this.hasCheckbox = this.context.attributes.hasCheckbox;
        this.$refs.selectRef.onSearch();
    },
    data: () => ({
        drivers: [],
        options: [],
        isLoading: false,
        isIncludeDelinked: false,
        hasPrevPage: false,
        hasNextPage: false,
        hasCheckbox: false,
        filter: {
            limit: 10,
            status: 'all',
            search: '',
        },
        paginationSettings: {
            page: 1,
            totalPages: 5,
            totalRecords: 50,
            visiblePageItemCount: 3,
        }
    }),
    methods: {
        omit(obj, keys) {
            let result = {};
            for (const [key, value] of Object.entries(obj)) {
                if (!keys.includes(key)) {
                    result[key] = value;
                }
            }
            return result;
        },
        async onSearch(search = '') {
            debounce(async () => {
                this.filter.search = search;
                this.options = [];
                this.isLoading = true;
                const me = this.$store.getters[`account/me`];

                let filter = [{status: 'linked'}, {status: 'linked-non-ewd'}]
                if (this.isIncludeDelinked) filter.push({status: 'delinked'});

                let query = {
                    skip: ( this.paginationSettings.page * this.filter.limit ) - this.filter.limit,
                    limit: this.filter.limit,
                    search: this.filter.search,
                    filter: { $or: filter }
                }

                const businessid = me?.business?._id;
                const driverContract = await this.$store.dispatch(`business/getContractByBusiness`, { uid : businessid, query });
                if (driverContract) {
                    if (driverContract?.metadata){
                        this.paginationSettings = formatPaginationSettings(driverContract?.metadata);
                        this.hasNextPage = this.paginationSettings.page < this.paginationSettings.totalPages;
                        this.hasPrevPage = this.paginationSettings.page > 1;
                    } else {
                        this.paginationSettings = formatPaginationSettings();
                    }
                    
                    this.drivers = uniqWith(driverContract.results, (dataA, dataB) => dataA.driverId === dataB.driverId && dataA.businessId === dataB.businessId);
                    this.options = this.drivers.map(driver =>  { driver.isChecked = false; return driver; });
                }
                this.isLoading = false;
            }, 500)();
        },
        onPrevPage() {
            if (this.hasPrevPage) {
                this.paginationSettings.page = this.paginationSettings.page - 1;
                this.onSearch();
            }
        },
        onNextPage() {
            if (this.hasNextPage) {
                this.paginationSettings.page = this.paginationSettings.page + 1;
                this.onSearch();
            }
        }
    }
}

export const VueFormulateDriverSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      DriverSelect,
    },
    library: {
      "driver-select": {
        classification: "select",
        component: "DriverSelect",
      },
    },
  });
}

export default DriverSelect;
</script>
<style lang="scss" scoped>
.multiple-selection::v-deep {
    .vs__dropdown-toggle {
        @apply overflow-hidden;

        .vs__selected-options {
            @apply flex-nowrap
        }
    }

    .vs__dropdown-menu .vs__dropdown-option {
        @apply px-2
    }
}
</style>