<template>
  <div>
    <FormulateInput
      v-if="isDisplayValue"
      type="checkbox"
      class="custom-class"
      :id="control.id"
      :name="control.name"
      :options="control.options"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
      v-model="control.value"
      :required="control.required"
      label-class=""
      input-class=""
      wrapper-class=""
      outer-class=""
      element-class=""
      checked
    />
    <FormulateInput
      v-else
      type="checkbox"
      class="custom-class"
      :id="control.id"
      :name="control.name"
      :options="control.options"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
      label-class=""
      input-class=""
      wrapper-class=""
      outer-class=""
      element-class=""
      checked
    />
  </div>
</template>

<script>
export default {
  props: {
    control: {
      type: Object,
      default: () => {}
    },
    isDisplayValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>