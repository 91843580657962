import { API } from "@/api/config";
import { ObjectToQueryString } from "@/_helper";

export default new (class EmailList {
  // @EmailList
  fetchEmailList(query) {
    let filter = "";
    if (typeof query.filter === "object")
      try {
        filter = JSON.stringify(query.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }
    query.filter = filter;
    if (query) query = "?" + ObjectToQueryString(query);

    return API.ACCOUNTS.get(`/web-profile/find-all-by-query${query}`);
  }

  exportEmails(query){
    let filter = "";
    if (typeof query.filter === "object")
      try {
        filter = JSON.stringify(query.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }
    if (query) filter = "?" + ObjectToQueryString(query);


    return API.ACCOUNTS.get(`/web-profile/export-emails${filter}`);
  }
})();
