<template>
  <div>
    <label v-if="control">{{control.label}}</label>
    <VueSignaturePad
      ref="signaturePad"
      :options="{ onEnd }"
      class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mt-1 mb-2"
    />
    <FormulateInput
        ref="signaturePadInput"
        type="hidden"
        :id="control.id"
        :name="control.name"
        :options="control.options"
        class="invisible"
        v-model="signature"
      />
  </div>
</template>
<script>
export default {
  data: () => ({
    signature: null
  }),
  props: {
    control: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    onEnd() {
      const { data } = this.$refs.signaturePad.saveSignature();
      this.signature = data;
    }
  }
}
</script>