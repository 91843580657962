<template>
  <div class="flex flex-col w-full h-screen">
    <div v-if="rendered">
      <div class="flex flex-col">
        <Table
          :isLoading="loading"
          :tableHeader="tableHeader"
          :paginationSettings="paginationSettings"
          :data="tableData"
          @onPageChange="onPageChange($event)"
        >
          <template #default="{ data }">
            <td class="px-5 py-2 text-center relative">
              <Dropdown ref="dropdown">
                <ul class="py-2 text-sm">
                  <li>
                    <StyledIconedLink
                      class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                      @click.prevent="toggleViewCert(data)"
                      :iconHoverColor="theme.secondaryColor"
                    >
                      <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                    </StyledIconedLink>
                  </li>
                  <li>
                    <StyledIconedLink
                      class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                      @click.prevent="toggleRegenerate(data)"
                      :iconHoverColor="theme.secondaryColor"
                    >
                      <font-awesome-icon
                        icon="certificate"
                        class="my-auto mr-2"
                      />
                      Regenerate
                    </StyledIconedLink>
                  </li>
                </ul>
              </Dropdown>
            </td>
          </template>

          <template slot="tableFilters">
            <div class="relative">
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
            <div class="block relative">
              <span
                class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
              >
                <font-awesome-icon icon="search" class="text-gray-400" />
              </span>
              <input
                placeholder="Search"
                class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                v-model="filter.search"
                @keydown="handleSearchKeyDown"
              />
            </div>
          </template>
        </Table>
      </div>
    </div>

    <Modal
      ref="modal"
      :title="modalTitle"
      :size="modalSize"
      :height="modalHeight"
    >
      <div v-if="modalContent === 'viewCerts'">
        <CertContents :content="selectedCert" @closedModal="resetModal" />
      </div>

      <div v-if="modalContent == 'regenerate'">
        <div class="text-center my-4 w-full">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Only generate if letter is outdated or did not generate
          </h3>
        </div>

        <div class="btns">
          <div class="pb-2">
            <button class="btn btn-primary w-full" @click="regenerateInduction">
              YES
            </button>
          </div>
          <button
            :disabled="hasErrors || loading"
            class="btn btn-secondary w-full"
            @click.prevent="onPickMed"
          >
            NO
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Table, Modal, Dropdown } from "@/components/commons";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import _ from "lodash";
import { formatPaginationSettings } from "@/_helper/";
import CertContents from "@/components/view/business/accreditation/cert-contents";
import { mapGetters } from "vuex";

export default {
  name: "manage-accreditation",
  components: {
    CertContents,
    Modal,
    Table,
    Dropdown,
  },

  data() {
    return {
      loading: false,
      hasErrors: false,

      modalSize: "2xl",
      modalContent: "",
      modalTitle: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      certificates: [],
      tableHeader: ["Certifications"],

      rendered: true,

      filter: {
        limit: 10,
        status: "all",
        search: "",
      },

      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },

      accreditationToRegenerate: null,
    };
  },

  async mounted() {
    await this.fetchAccreditation();
  },

  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },

  methods: {
    async fetchAccreditation(paged = 1) {
      try {
        this.loading = true;
        const me = this.$store.getters[`account/me`];

        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {},
        };

        const fetchedDriver = await this.$store.dispatch(
          `driver/getDriverByEmail`,
          me.email
        );

        const certifications = await this.$store.dispatch(
          `accreditation/fetchAssociatedCertificates`,

          { id: fetchedDriver._id, status: true, query }
        );

        if (certifications.data?.data?.results) {
          this.certificates = JSON.parse(
            JSON.stringify(certifications.data.data.results)
          );
        } else {
          this.certificates = [];
        }

        if (certifications?.data?.data.resultsMeta) {
          this.paginationSettings = formatPaginationSettings(
            certifications.data.data.resultsMeta
          );
        }

        if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
        this.loading = false;
      } catch (e) {
        this.toast("error", "Error in fetching");
        throw e;
      }
    },

    async toggleRegenerate(data) {
      this.accreditationToRegenerate = data;
      this.$refs.modal.openModal();
      this.modalContent = "regenerate";
      this.modalTitle = "Regenerate Letter";
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
      this.modalSize = "2xl";
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    resetModal() {
      this.modalContent = "";
      this.modalTitle = "";
    },

    async regenerateInduction() {
      try {
        this.closeModal();
        this.loading = true;
        const me = await this.$store.dispatch(`account/getMe`);
        const driver = await this.$store.dispatch(
          `driver/getDriverByEmail`,
          me.email
        );

        const { document } = this.accreditationToRegenerate;

        const query = {
          filter: {
            businessId: document.businessId,
            accreditationType: document.type,
          },
        };

        const cert = await this.$store.dispatch(
          "accreditation/fetchCertificationByCertNumber",
          {
            certNumber: document,
            query,
          }
        );

        const certId = cert.data.data[0]._id;

        this.rendered = false;

        await this.$store.dispatch(`accreditation/regenerate`, {
          certId: certId,
          userId: driver._id,
        });

        this.loading = false;

        this.rendered = true;

        this.toast("success", "Letter regenerated");
      } catch (e) {
        this.toast("error", `${e.message}`);
      }
    },

    async onPageChange(event) {
      await this.fetchAccreditation(event.page);
    },

    async debouncedSearchString() {
      await this.fetchAccreditation();
    },

    async filterChange() {
      await this.fetchAccreditation();
    },

    parseAccreditaionData(data) {
      return data.map((accreditationData) => {
        return [
          {
            id: accreditationData._id,
            name: accreditationData.certNumber,
            itemType: "string",
            document: accreditationData,
          },
        ];
      });
    },

    triggerLoading(status) {
      this.$emit("updateLoading", status);
      this.loading = status;
    },

    closeModal() {
      this.$refs.modal.closeModal();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    toggleCurateSignature() {
      this.$refs.modal.openModal();
      this.modalContent = "curateAccreditation";
      this.modalTitle = "Curate Accreditation";
    },

    toggleViewCert(data) {
      this.selectedCert = data;
      this.$refs.modal.openModal();
      this.modalContent = "viewCerts";
      this.modalTitle = "Certificate Detials";
    },
  },

  toast(state, msg) {
    const message = {
      state: state,
      message: msg,
    };
    this.$store.commit("setDialogNotify", true);
    this.$store.commit("setMessageNotify", message);
  },

  computed: {
    tableData() {
      if (this.certificates) {
        return this.parseAccreditaionData(this.certificates);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style scoped>
.img {
  height: 10%;
  widows: 10%;
}

input[type="file"]::-webkit-file-upload-button {
  visibility: hidden;
}
</style>
