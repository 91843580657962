import { DynamicLink } from '@/api'
import _ from 'lodash'

export default {
  // @DynamicLink
  async createBusinessIntegrationLink({ commit }, data) {
    if(!data || _.isEmpty(data)) throw 'Invalid data object'
    try {
      const response = await DynamicLink.createBusinessIntegrationLink(data);
      commit("setNewIntegrationLink", response.data.data)
      return response.data
    } catch(error) {
      throw error.response
    }
  },
  async updateBusinessIntegrationLink({ commit }, payload) {
    if(!payload || _.isEmpty(payload)) throw 'Invalid data object'
    try {
      payload.id = payload._id  
      const response = await DynamicLink.updateBusinessIntegrationLink(payload);
      commit("setNewIntegrationLink", response.data)
      return response.data
    } catch(error) {
      throw error.response
    }
  },
  async createPromoSignupLink({ commit }, data) {
    if(!data || _.isEmpty(data)) throw 'Invalid data object'
    try {
      const response = await DynamicLink.createPromoSignupLink(data);
      commit("setNewPromoSignupLink", response.data.data)
      return response.data.data
    } catch(error) {
      throw error.response.data
    }
  },

  async createBatchBusinessIntegrationLinks({ commit }, data) {
    if(!data || _.isEmpty(data)) throw 'Invalid data object'
    try {
      const response = await DynamicLink.createBatchBusinessIntegrationLinks(data);
      commit("setNewIntegrationLinks", response.data.data)
      return response.data
    } catch(error) {
      throw error.response.data
    }
  },

  async getBusinessIntegrationLinks(payload) {
    const pagination = payload.query
    try {
      const response = await DynamicLink.getBusinessIntegrationLinks(pagination);
      if(response.data?.resultsMeta){
        return {
            results: response.data.data,
            metadata: response.data.resultsMeta
        }
      }else{
          return response.data
      }
    } catch(error) {
      throw error.response.data;
    }
  },
  async getPartnerBusinessIntegrationLinks({ state }, payload) {
    if(!payload.partnerId) throw 'Invalid partnerID' + state.me;
    const params = {
        partnerId: payload.partnerId,
    }
    const pagination = payload.query
    try {
      const response = await DynamicLink.getPartnerBusinessIntegrationLinks(params, pagination);
      if(response.data?.resultsMeta){
        return {
            results: response.data.data,
            metadata: response.data.resultsMeta
        }
      }else{
          return response.data
      }
    } catch(error) {
      throw error.response.data;
    }

  },
  async getMyBusinessIntegrationLinks({ state }, payload) {
    if(!payload.businessId) throw 'Invalid businessID' + state.me;
    const params = {
        businessId: payload.businessId,
    }
    const pagination = payload.query
    try {
      const response = await DynamicLink.getMyBusinessIntegrationLinks(params, pagination);
      if(response.data?.resultsMeta){
        return {
            results: response.data.data,
            metadata: response.data.resultsMeta
        }
      }else{
          return response.data
      }
    } catch(error) {
      throw error.response.data;
    }
  },
  async deleteLink({ state }, payload) {
    if(!payload.id) throw 'Invalid linkID' + state.me;
    try { 
      console.log(payload)
      const response = await DynamicLink.deleteLink(payload);
      return response.data
    } catch(error) {
      throw error.response
    }
  },
}