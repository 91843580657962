<template>
  <div>
    <div v-show="!loading">
      <div v-if="formControl">
        <FormulateInput
          type="text"
          name="control_id"
          v-model="formControl.id"
          hidden
          validation="required"
        />
        <FormulateInput
          type="text"
          name="name"
          v-model="formControl.name"
          validation="required"
          hidden
          class="mb-4"
        />
        <FormulateInput
          type="text"
          name="control_label"
          v-model="formControl.label"
          label="Label"
          placeholder="Enter label here"
          validation="required"
          class="mb-4"
        />
        <FormulateInput
          type="text"
          name="control_help"
          v-model="formControl.help"
          label="Helper"
          placeholder="Enter helper here"
          class="mb-4"
        />
        <div class="font-medium text-sm mb-2 border-b pb-1">Options</div>
        <table class="table-auto text-xs text-left mb-1">
          <thead>
            <tr>
              <th class="font-slim">Failure Result</th>
              <th class="pl-8 pt-0">Value</th>
              <th class="pl-8">Text</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(option, index) in formControl.options">
              <tr :key="option.uniqueId">
                <td class="p-0">
                  <FormulateInput
                    type="checkbox"
                    name="control_options"
                    v-model="option.isIssue"
                    class="p-0 mr-4"
                  />
                </td>
                <td>
                  <FormulateInput
                    type="text"
                    name="control_options"
                    v-model="option.value"
                    placeholder="Enter value here"
                    validation="required"
                  />
                </td>
                <td>
                  <FormulateInput
                    type="text"
                    name="control_options"
                    v-model="option.label"
                    placeholder="Enter label here"
                    validation="required"
                    class="pl-1"
                  />
                </td>
                <td class="pt-0 pb-4">
                  <font-awesome-icon
                    @click="removeOption(index)"
                    icon="minus-circle"
                    class="text-sm ml-1 -mt-12 cursor-pointer text-red-300 hover:text-red-500 transition ease-in-out hover:duration-300"
                    size="sm"
                  />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div
          class="text-xs text-blue-400 ml-1 mb-4 cursor-pointer"
          @click="addOption()"
        >
          Add Option
        </div><br/>
        <div class="font-medium text-sm mb-2 border-b pb-1">Validations</div>
        <FormulateInput
          v-model="formControl.required"
          type="checkbox"
          wrapper-class="flex h-5"
          input-class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300"
          label-class="ml-2 text-sm font-medium"
          label="Required"
        />
      </div>
    </div>
    <div v-if="loading" class="w-full text-center overflow-y-auto px-12 text-lg inset-0 flex flex-col justify-center items-center">
      <font-awesome-icon icon="cog" spin size="3x" class="text-gray-400 mt-12"/>
      <h6 class="mt-4">Loading</h6>
    </div>
  </div>
</template>
<script>
import { HELPER } from '@/api/helper'
import _ from 'lodash'

export default {
  props: {
    watchedCurrentSection: {
      type: Object,
      default: null
    },
    watchedCurrentControl: {
      type: Object,
      default: null
    },
    uniqueId: {
      type: String,
      default: ''
    },
  },

  data: () => ({
    required: false,
    formControl: {},
    currentSection: {},
    loading: false
  }),

  watch: {
    formControl: {
      deep: true,
      handler(newVal) {
        const formData = {
          control: newVal,
          sectionUniqueId: this.currentSection?.uniqueId
        }
        this.$emit('updateFormControl', formData)
      }
    },

    uniqueId(newVal) {
      this.updateSelectedData(newVal)
    },

    required(newVal) {
      if(newVal == true) {
        this.formControl.validation.push('required')
      } else {
        const index = this.formControl.validation.indexOf('required')
        this.formControl.validation.splice(index, 1)
      }
    }
  },

  created() {
    this.updateSelectedData()
  },

  methods: {
    addRequiredValidation() {
      this.formControl.validation.push('required')
    },
    
    async updateSelectedData() {
      this.loading = true
      
      this.currentSection = HELPER.cloneDeep(this.watchedCurrentSection)
      let control = HELPER.cloneDeep(this.watchedCurrentControl)

      if(!_.isEmpty(control)){
        this.formControl = control
        this.formControl.validation = control.validation || []
      } else {
        this.formControl = null
      }
      
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
    removeOption(index) {
      this.formControl.options.splice(index, 1)
    },
    addOption() {
      const uniqueId = HELPER.getUUIDv4()
      const total = this.formControl.options.length - 2
      this.formControl.options.push({
        value: 'option' + total,
        label: 'Option' + total,
        uniqueId: uniqueId
      })
    }
  }
}
</script>