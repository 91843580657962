<template>
  <div class="mb-16">
    <div v-if="isDefault" class="btns">
      <h2>Current template is already the default</h2>
    </div>
    <div v-else>
      <div class="pb-2">
        <button class="btn btn-primary w-full" @click="confirmDefault">
          YES
        </button>
      </div>
      <button class="border-2 btn btn-secondary w-full" @click="closeModal">
        NO
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm-Default-Dialog",
  props: {
    templateId: String,
  },

  data() {
    return {
      templateToDefault: false,
      isDefault: false,
    };
  },

  async mounted() {
    this.templateToDefault = this.templateId;
    const { business } = await this.$store.getters["account/me"];

    const templateData = await this.$store.dispatch("accreditation/isDefault", {
      templateId: this.templateToDefault,
      businessId: business._id,
    });

    this.isDefault = templateData.data;
  },

  methods: {
    closeModal() {
      this.$emit("closeModal");
    },

    async confirmDefault() {
      try {
        await this.$store.dispatch("accreditation/setDefaultTemplate", {
          templateId: this.templateToDefault,
        });
        this.$emit("closeModal");
        this.$emit("refetch");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
<style scoped></style>
