<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <FormulateForm v-model="values" @submit="onSubmit">
        <div class="items-center justify-between">
          <FormulateInput
            name="emailAddress"
            type="text"
            label="Email Address"
            :disabled="true"
          />
          <div class="w-full mb-5">
            <label
              class="text-sm mb-2 block font-semibold form-label pr-2 text-neutral-dark"
              >Event Date</label
            >
            <DatePicker
              ref="childDatePicker"
              class="event-datepicker"
              :date="values.selectedDateRange"
              placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
              @selected="values.selectedDateRange = $event"
              range
              noNavigation
              :clearable="false"
            />
          </div>
          <FormulateInput
            name="comment"
            type="textarea"
            label="Comment"
            rows="8"
            validation="^required"
            :validation-messages="{ required: 'Comment is required.' }"
          />
          <FormulateInput
            type="submit"
            input-class="btn btn-primary w-full"
            :style="{ backgroundColor: theme.secondaryColor }"
          />
        </div>
      </FormulateForm>
    </div>

    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { formatDate } from '@/_helper';
import DatePicker from '@/components/commons/field/DatePicker';
import { STATES } from '@/constants/location';

export default {
  name: 'Set-Driver-Trial-Data',
  props: {
    id: String,
  },
  components: { DatePicker },
  data() {
    return {
      loading: false,
      values: {},
      driver: {},
      states: STATES,
      error: false,
      errorMessage: '',
    };
  },
  async mounted() {
    this.loading = true;
    const driverData = await this.$store.dispatch(`driver/getDriver`, {
      id: this.id,
    });
    console.log(driverData);
    this.driver = driverData;
    this.values = this.parseDriver(driverData);
    this.values.selectedDateRange = [
      moment().format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ];
    this.loading = false;
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const data = {
        driverId: this.id,
        comment: this.values.comment,
        from: this.values.selectedDateRange[0],
        to: this.values.selectedDateRange[1]
      }

      console.log('data', data)

      await this.$store
        .dispatch(`business/setDriverTrialData`, data)
        .then(() => {
          this.loading = false;
          this.toast("success", "Driver data set as trial.");
          this.$emit('closeRefresh');
        })
        .catch((error) => {
            this.loading = false;
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
        });
        
    },
    parseDriver(driver) {
      return {
        driverDateOfBirth:
          formatDate(driver.driverDateOfBirth, 'YYYY-MM-DD') || '-',
        driverName: driver.driverName || '-',
        driversBFMNumber: driver.driversBFMNumber || '-',
        driversBaseLocation: driver.driversBaseLocation || '-',
        driversBaseTimeZone: driver.driversBaseTimeZone || '-',
        driversLicenseExpiry:
          formatDate(driver.driversLicenseExpiry, 'YYYY-MM-DD') || '-',
        driversLicenseNumber: driver.driversLicenseNumber || '-',
        driversLicenseState: driver.driversLicenseState || '-',
        emailAddress: driver.emailAddress || '-',
        udi: driver.udi || '-',
        isActive: driver.isActive,
        id: driver._id,
      };
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
};
</script>

<style lang="scss" scoped>
.event-datepicker,
.event-datepicker::v-deep > div {
  @apply w-full;

  input {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}
</style>