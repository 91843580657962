<template>
   <div class="w-full mt-5">
      <button v-if="!submitting" type="submit" class="btn btn-primary w-full" :style="{backgroundColor: theme.secondaryColor}">
         <span class="formulate-input-element--submit--label">{{label}}</span>
      </button>
      <button v-if="submitting" disabled class="btn btn-primary w-full opacity-90 cursor-not-allowed" :style="{backgroundColor: theme.secondaryColor}">
         <font-awesome-icon icon="spinner" spin />
         <span class="ml-3 formulate-input-element--submit--label">{{submittingLabel}}</span>
      </button>
   </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
   props: {
      label: {
         default: 'Save Changes'
      },
      submitting: {
         default: false
      },
      submittingLabel: {
         default: 'Saving changes...'
      }
   },
    computed: {
        ...mapGetters('theme', {
        theme: 'getColorScheme'
        })
    }
}
</script>

 
 
 
 
 