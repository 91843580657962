<template>
  <div class="flex flex-col items-center">
    <div class="w-full border-2 p-8 mb-20">
      <h2 class="mb-1.5">New JSON query</h2>

      <FormulateForm @submit="onSubmit" #default="{ hasErrors }"  class="formulate-input-element-decorator">

        <FormulateInput type="select" label="Object" v-model="object" :options="objectOptions"/>
        <FormulateInput
            type="checkbox"
            label="Fields"
            v-model="fields"
            :options="fieldOptions"
            class="custom-options checkbox"
            help="If you don't select Any fields, all fields will be returned"/>


        <button class="btn btn-primary w-full" @click.prevent="addFilter()">
          Add Filter
        </button>
        <div v-for="(filter, index) in filters" :key="index">
          <FormulateInput type="select" label="Filter" v-model="filter.label" :options="filterOptions"/>
          <FormulateInput v-if="filter.label === 'status' && object === 'solo-driver'" type="select" label="Filter Value" v-model="filter.value" :options="statusFilterOptions"/>
          <FormulateInput v-else type="text" label="Filter Value" v-model="filter.value"/>
        </div>


        <FormulateInput type="select" label="Time Filter" v-model="timeFilter" :options="timeFilterOptions"/>
        <div v-if="timeFilter === 'custom'">
          <FormulateInput type="date" label="From Date" v-model="fromDate" />
          <FormulateInput type="time" label="From Time" v-model="fromTime" />
          <FormulateInput type="date" label="To Date" v-model="toDate" />
          <FormulateInput type="time" label="To Time" v-model="toTime" />
        </div>

        <FormulateInput
            type="submit"
            label="Generate JSON"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors"
        />

        <h2>JSON CONTENT:</h2>

        <div
            v-if="formattedJson"
            class="flex flex-col bg-gray-200 rounded-lg p-2"
        >
          <div v-if="objName" class="p-2">
            <h2>FILENAME:</h2>
            <p>{{ `${objName}.${fileType} ` }}</p>
          </div>
          <div v-else class="flex flex-col bg-gray-200 rounded-lg p-2">
            <h2>FILENAME:</h2>
            <p>{{ `query.${fileType} ` }}</p>
          </div>
          <a
              class="flex cursor-pointer justify-end"
              @click.prevent="downloadJson"
              name="Download JSON"
          >
            <font-awesome-icon
                icon="download"
                class="my-auto mx-1.5 hover:text-orange-primary-primary"
                name="Download JSON"
            /></a>
          <pre ref="jsonContent">{{ formattedJson }}</pre>

          <FormulateInput type="text" label="Name" v-model="name"/>
          <button class="btn btn-primary" @click.prevent="saveQuery()">
            Save JSON query
          </button>
        </div>

      </FormulateForm>
    </div>
  </div>
</template>

<script>

export default {
  name: 'New-Query-JSON',

  data() {
    return {
      query: {
        object: '',
        filter: '',
        fields: [],
        from: '',
        to: '',
      },

      name: '',
      object: '',
      fromDate: '',
      fromTime: '',
      toDate: '',
      toTime: '',
      fields: [],
      filters: [
        {
          label: "",
          value: ""
        }
      ],
      timeFilter: '',
      timeFilterOptions: [
        { value: 'custom', label: 'Custom' },
        { value: 'hour', label: 'Last Hour' },
        { value: 'day', label: 'Last Day' },
        { value: 'week', label: 'Last Week' },
        { value: 'month', label: 'Last Month' },
      ],

      objName: '',

      fileType: 'json',

      formattedJson: false,

      objectOptions: [
        { value: 'solo-driver', label: 'Driver' },
        { value: 'breach-engine', label: 'Breach Engine' },
        { value: 'event', label: 'Event' },
        { value: 'prestart', label: 'Forms - Pre Start' },
        { value: 'fit-to-drive', label: 'Forms - Fitness Declaration' },
        { value: 'hazard', label: 'Forms - Hazard' },
        { value: 'defect', label: 'Forms - Defect' },
        { value: 'infringement', label: 'Forms - Infringement' },
        { value: 'incident', label: 'Forms - Incident' },
      ],
      fieldOptions: [],
      filterOptions: [],
      statusFilterOptions: [
        { value: 'linked', label: 'Linked' },
        { value: 'delinked', label: 'Delinked' },
        { value: 'pending', label: 'Pending' },
        { value: 'linked-non-ewd', label: 'Linked Non-EWD' },
        { value: 'delinked-non-ewd', label: 'Delinked Non-EWD' },
      ],
      driverOptions: [
        { value: 'status', label: 'Status' },
        { value: 'driver', label: 'All Driver Data' },
        { value: 'driver.udi', label: 'UDI' },
        { value: 'driver.emailAddress', label: 'Email' },
        { value: 'driver.driverName', label: 'Name' },
        { value: 'driver.driverDateOfBirth', label: 'Date of Birth' },
        { value: 'driver.driversLicenseNumber', label: 'License Number' },
        { value: 'driver.driversLicenseState', label: 'License State' },
        { value: 'driver.driversLicenseExpiry', label: 'License Expiry' },
        { value: 'driver.driversBaseLocation', label: 'Base Location' },
        { value: 'driver.driversBaseTimeZone', label: 'Base Timezone' },
        { value: 'driver.driverRecordKeeperAddress', label: 'Record Keeper Address' },
        { value: 'driver.driversBFMNumber', label: 'BFM Number' },
        { value: 'driver.billingAddress', label: 'Billing Address' },
        { value: 'driver.isActive', label: 'Active Status' },
        { value: 'driver.contactNumber', label: 'Contact Number' },
        { value: 'driver.createdAt', label: 'Created At' },
        { value: 'driverSettings', label: 'All Driver Settings Data' },
        { value: 'driverSettings.currentVehiclePlate', label: 'Current Vehicle Plate' },
        { value: 'driverSettings.currentVehicle', label: 'Current Vehicle' },
        { value: 'driverSettings.activeRuleset', label: 'Active Ruleset' },
        { value: 'driverSettings.activeTimezone', label: 'Active Timezone' },
        { value: 'driverSettings.currentAppVersion', label: 'Current App Version' },
        { value: 'driverSettings.lastEOD', label: 'Last EOD' },
        { value: 'driverSettings.pending', label: 'Is Pending' },
        { value: 'driverSettings.isBiometricsOptedIn', label: 'Is Biometrics Opted In' },
        { value: 'driverSettings.locationEnabled', label: 'Is Location Enabled' },
      ],
      driverFilterOptions: [
        { value: 'status', label: 'Status' },
        { value: 'driverId', label: 'ID' },
        { value: 'driver.udi', label: 'UDI' },
        { value: 'driver.emailAddress', label: 'Email' },
        { value: 'driver.driverName', label: 'Name' },
        { value: 'driver.driverDateOfBirth', label: 'Date of Birth' },
        { value: 'driver.driversLicenseNumber', label: 'License Number' },
        { value: 'driver.driversLicenseState', label: 'License State' },
        { value: 'driver.driversBFMNumber', label: 'BFM Number' },
        { value: 'driver.contactNumber', label: 'Contact Number' },
        { value: 'driverSettings.currentVehiclePlate', label: 'Current Vehicle Plate' },
        { value: 'driverSettings.currentVehicle', label: 'Current Vehicle' },
        { value: 'driverSettings.activeRuleset', label: 'Active Ruleset' },
        { value: 'driverSettings.activeTimezone', label: 'Active Timezone' },
        { value: 'driverSettings.pending', label: 'Is Pending' },
      ],
      breachEngineOptions: [
        { value: 'entityId', label: 'Business ID' },
        { value: 'level', label: 'Level' },
        { value: 'option', label: 'Option' },
        { value: 'period', label: 'Period' },
        { value: 'time', label: 'Time' },
        { value: 'timezone', label: 'Time Zone' },
        { value: 'type', label: 'Type' },
        { value: 'location', label: 'Location' },
        { value: 'createdAt', label: 'Created At' },
        { value: 'driver.udi', label: 'Driver UDI' },
        { value: 'driver.emailAddress', label: 'Driver Email' },
        { value: 'driver.driverName', label: 'Driver Name' },
        { value: 'driver.driverDateOfBirth', label: 'Driver Date of Birth' },
        { value: 'driver.driversLicenseNumber', label: 'Driver License Number' },
        { value: 'driver.driversLicenseExpiry', label: 'Driver License Expiry' },
        { value: 'driver.driversBaseLocation', label: 'Driver Base Location' },
        { value: 'driver.driversBaseTimeZone', label: 'Driver Base Timezone' },
        { value: 'driver.driverRecordKeeperAddress', label: 'Driver Record Keeper Address' },
        { value: 'driver.driversBFMNumber', label: 'Driver BFM Number' },
        { value: 'driver.billingAddress', label: 'Driver Billing Address' },
        { value: 'driver.isActive', label: 'Driver Active Status' },
        { value: 'driver.contactNumber', label: 'Driver Contact Number' },
        { value: 'driver.createdAt', label: 'Driver Created At' },
      ],
      breachFilterOptions: [
        { value: 'level', label: 'Level' },
        { value: 'type', label: 'Type' },
        { value: 'driver.udi', label: 'Driver UDI' },
        { value: 'driver.emailAddress', label: 'Driver Email' },
        { value: 'driver.driverName', label: 'Driver Name' },
        { value: 'driver.driverDateOfBirth', label: 'Driver Date of Birth' },
        { value: 'driver.driversBFMNumber', label: 'Driver BFM Number' },
        { value: 'driver.contactNumber', label: 'Driver Contact Number' },
      ],
      eventOptions: [
        { value: 'eventId', label: 'Event ID' },
        { value: 'startTime', label: 'Start Time' },
        { value: 'originalStartTime', label: 'Original Start Time' },
        { value: 'startTimeWithTimezone', label: 'Start Time With Timezone' },
        { value: 'location', label: 'Location' },
        { value: 'odometer', label: 'Odometer' },
        { value: 'comment', label: 'Comment' },
        { value: 'fatiguePlan', label: 'Fatigue Plan' },
        { value: 'status', label: 'Status' },
        { value: 'stationary', label: 'Stationary' },
        { value: 'type', label: 'Type' },
        { value: 'isDeleted', label: 'Deleted Status' },
        { value: 'timezone', label: 'Timezone' },
        { value: 'origin', label: 'Origin' },
        { value: 'createdAt', label: 'Created At' },
        { value: 'driver.udi', label: 'Driver UDI' },
        { value: 'driver.emailAddress', label: 'Driver Email' },
        { value: 'driver.driverName', label: 'Driver Name' },
        { value: 'driver.driverDateOfBirth', label: 'Driver Date of Birth' },
        { value: 'driver.driversLicenseNumber', label: 'Driver License Number' },
        { value: 'driver.driversLicenseExpiry', label: 'Driver License Expiry' },
        { value: 'driver.driversBaseLocation', label: 'Driver Base Location' },
        { value: 'driver.driversBaseTimeZone', label: 'Driver Base Timezone' },
        { value: 'driver.driverRecordKeeperAddress', label: 'Driver Record Keeper Address' },
        { value: 'driver.driversBFMNumber', label: 'Driver BFM Number' },
        { value: 'driver.billingAddress', label: 'Driver Billing Address' },
        { value: 'driver.isActive', label: 'Driver Active Status' },
        { value: 'driver.contactNumber', label: 'Driver Contact Number' },
        { value: 'driver.createdAt', label: 'Driver Created At' },
        { value: 'vehicle.uid', label: 'Vehicle UID' },
        { value: 'vehicle.ownerUid', label: 'Vehicle Owner ID' },
        { value: 'vehicle.isActive', label: 'Vehicle Active Status' },
        { value: 'vehicle.vehiclePlate', label: 'Vehicle Plate' },
        { value: 'vehicle.fleetId', label: 'Vehicle Fleet ID' },
        { value: 'vehicle.registrationExpiry', label: 'Vehicle Registration Expiry' },
        { value: 'vehicle.registrationNumber', label: 'Vehicle Registration Number' },
        { value: 'vehicle.GMV', label: 'Vehicle GMV' },
        { value: 'vehicle.vehicleClass', label: 'Vehicle Class' },
        { value: 'vehicle.vehicleType', label: 'Vehicle Type' },
        { value: 'vehicle.driveVehicle', label: 'Vehicle Drive Vehicle' },
        { value: 'vehicle.concreteAgitator', label: 'Vehicle Concrete Agitator' },
        { value: 'vehicle.livestockVehicle', label: 'Vehicle Live Stock Vehicle' },
        { value: 'vehicle.VIN', label: 'Vehicle VIN' },
        { value: 'vehicle.alias', label: 'Vehicle Alias' },
        { value: 'vehicle.frontCouplings', label: 'Vehicle Front Couplings' },
        { value: 'vehicle.rearCouplings', label: 'Vehicle Rear Couplings' },
        { value: 'vehicle.powerCouplings', label: 'Vehicle Power Couplings' },
        { value: 'vehicle.brakeFoundation', label: 'Vehicle Brake Foundation' },
        { value: 'vehicle.brakeType', label: 'Vehicle Brake Type' },
        { value: 'vehicle.supplyVoltageToTrailer', label: 'Vehicle Supply Voltage To Trailer' },
        { value: 'vehicle.lAETBSysSupplier', label: 'Vehicle LAETB Sys Supplier' },
        { value: 'vehicle.tSTBCSRefNumber', label: 'Vehicle TSTBCS Ref Number' },
        { value: 'vehicle.comments', label: 'Vehicle Comments' },
        { value: 'vehicle.deleteStatus', label: 'Vehicle Deleted Status' },
        { value: 'vehicle.firebaseVehicleId', label: 'Vehicle Firebase Vehicle ID' },
        { value: 'vehicle.source', label: 'Vehicle Source' },
        { value: 'vehicle.businessId', label: 'Vehicle Business ID' },
        { value: 'vehicle.entityId', label: 'Vehicle Entity ID' },
        { value: 'vehicle.createdAt', label: 'Vehicle Created At' },
      ],
      eventFilterOptions: [
        { value: 'status', label: 'Status' },
        { value: 'type', label: 'Type' },
        { value: 'driverId', label: 'Driver ID' },
        { value: 'driver.udi', label: 'Driver UDI' },
        { value: 'driver.emailAddress', label: 'Driver Email' },
        { value: 'driver.driverName', label: 'Driver Name' },
        { value: 'driver.driverDateOfBirth', label: 'Driver Date of Birth' },
        { value: 'driver.driversBFMNumber', label: 'Driver BFM Number' },
        { value: 'driver.contactNumber', label: 'Driver Contact Number' },
        { value: 'vehicle.uid', label: 'Vehicle UID' },
        { value: 'vehicle.ownerUid', label: 'Vehicle Owner ID' },
        { value: 'vehicle.vehiclePlate', label: 'Vehicle Plate' },
        { value: 'vehicle.registrationNumber', label: 'Vehicle Registration Number' },
        { value: 'vehicle.GMV', label: 'Vehicle GMV' },
        { value: 'vehicle.VIN', label: 'Vehicle VIN' },
        { value: 'vehicle.alias', label: 'Vehicle Alias' },
        { value: 'vehicle.firebaseVehicleId', label: 'Vehicle Firebase Vehicle ID' },
      ],

      preStartOption: [
        { value: 'formId', label: 'Form ID' },
        { value: 'isPassed', label: 'Is Passed' },
        { value: 'isDefault', label: 'Is Default' },
        { value: 'createdAt', label: 'Created At' },
        { value: 'driverId', label: 'Driver ID' },
        { value: 'driver.udi', label: 'Driver UDI' },
        { value: 'driver.emailAddress', label: 'Driver Email' },
        { value: 'driver.driverName', label: 'Driver Name' },
        { value: 'driver.driverDateOfBirth', label: 'Driver Date of Birth' },
        { value: 'driver.driversLicenseNumber', label: 'Driver License Number' },
        { value: 'driver.driversLicenseState', label: 'Driver License State' },
        { value: 'driver.driversLicenseExpiry', label: 'Driver License Expiry' },
        { value: 'driver.driversBaseLocation', label: 'Driver Base Location' },
        { value: 'driver.driversBaseTimeZone', label: 'Driver Base Timezone' },
        { value: 'driver.driverRecordKeeperAddress', label: 'Driver Record Keeper Address' },
        { value: 'driver.driversBFMNumber', label: 'Driver BFM Number' },
        { value: 'driver.billingAddress', label: 'Driver Billing Address' },
        { value: 'driver.isActive', label: 'Driver Active Status' },
        { value: 'driver.contactNumber', label: 'Driver Contact Number' },
        { value: 'driver.createdAt', label: 'Driver Created At' },
        { value: 'vehicleId', label: 'Vehicle ID' },
        { value: 'vehicle.uid', label: 'Vehicle UID' },
        { value: 'vehicle.ownerUid', label: 'Vehicle Owner ID' },
        { value: 'vehicle.isActive', label: 'Vehicle Active Status' },
        { value: 'vehicle.vehiclePlate', label: 'Vehicle Plate' },
        { value: 'vehicle.fleetId', label: 'Vehicle Fleet ID' },
        { value: 'vehicle.registrationExpiry', label: 'Vehicle Registration Expiry' },
        { value: 'vehicle.registrationNumber', label: 'Vehicle Registration Number' },
        { value: 'vehicle.GMV', label: 'Vehicle GMV' },
        { value: 'vehicle.vehicleClass', label: 'Vehicle Class' },
        { value: 'vehicle.vehicleType', label: 'Vehicle Type' },
        { value: 'vehicle.driveVehicle', label: 'Vehicle Drive Vehicle' },
        { value: 'vehicle.concreteAgitator', label: 'Vehicle Concrete Agitator' },
        { value: 'vehicle.livestockVehicle', label: 'Vehicle Live Stock Vehicle' },
        { value: 'vehicle.VIN', label: 'Vehicle VIN' },
        { value: 'vehicle.alias', label: 'Vehicle Alias' },
        { value: 'vehicle.frontCouplings', label: 'Vehicle Front Couplings' },
        { value: 'vehicle.rearCouplings', label: 'Vehicle Rear Couplings' },
        { value: 'vehicle.powerCouplings', label: 'Vehicle Power Couplings' },
        { value: 'vehicle.brakeFoundation', label: 'Vehicle Brake Foundation' },
        { value: 'vehicle.brakeType', label: 'Vehicle Brake Type' },
        { value: 'vehicle.supplyVoltageToTrailer', label: 'Vehicle Supply Voltage To Trailer' },
        { value: 'vehicle.lAETBSysSupplier', label: 'Vehicle LAETB Sys Supplier' },
        { value: 'vehicle.tSTBCSRefNumber', label: 'Vehicle TSTBCS Ref Number' },
        { value: 'vehicle.comments', label: 'Vehicle Comments' },
        { value: 'vehicle.deleteStatus', label: 'Vehicle Deleted Status' },
        { value: 'vehicle.firebaseVehicleId', label: 'Vehicle Firebase Vehicle ID' },
        { value: 'vehicle.source', label: 'Vehicle Source' },
        { value: 'vehicle.businessId', label: 'Vehicle Business ID' },
        { value: 'vehicle.entityId', label: 'Vehicle Entity ID' },
        { value: 'vehicle.createdAt', label: 'Vehicle Created At' },
      ],
      preStartFilterOption: [
        { value: 'isPassed', label: 'Is Passed' },
        { value: 'isDefault', label: 'Is Default' },
        { value: 'driverId', label: 'Driver ID' },
        { value: 'driver.udi', label: 'Driver UDI' },
        { value: 'driver.emailAddress', label: 'Driver Email' },
        { value: 'driver.driverName', label: 'Driver Name' },
        { value: 'driver.contactNumber', label: 'Driver Contact Number' },
        { value: 'vehicleId', label: 'Vehicle ID' },
        { value: 'vehicle.uid', label: 'Vehicle UID' },
        { value: 'vehicle.vehiclePlate', label: 'Vehicle Plate' },
        { value: 'vehicle.registrationNumber', label: 'Vehicle Registration Number' },
        { value: 'vehicle.GMV', label: 'Vehicle GMV' },
        { value: 'vehicle.VIN', label: 'Vehicle VIN' },
        { value: 'vehicle.alias', label: 'Vehicle Alias' },
        { value: 'vehicle.firebaseVehicleId', label: 'Vehicle Firebase Vehicle ID' },
      ],

      formsOption: [
        { value: 'formId', label: 'Form ID' },
        { value: 'isPassed', label: 'Is Passed' },
        { value: 'isDefault', label: 'Is Default' },
        { value: 'createdAt', label: 'Created At' },
        { value: 'driverId', label: 'Driver ID' },
        { value: 'driver.udi', label: 'Driver UDI' },
        { value: 'driver.emailAddress', label: 'Driver Email' },
        { value: 'driver.driverName', label: 'Driver Name' },
        { value: 'driver.driverDateOfBirth', label: 'Driver Date of Birth' },
        { value: 'driver.driversLicenseNumber', label: 'Driver License Number' },
        { value: 'driver.driversLicenseState', label: 'Driver License State' },
        { value: 'driver.driversLicenseExpiry', label: 'Driver License Expiry' },
        { value: 'driver.driversBaseLocation', label: 'Driver Base Location' },
        { value: 'driver.driversBaseTimeZone', label: 'Driver Base Timezone' },
        { value: 'driver.driverRecordKeeperAddress', label: 'Driver Record Keeper Address' },
        { value: 'driver.driversBFMNumber', label: 'Driver BFM Number' },
        { value: 'driver.billingAddress', label: 'Driver Billing Address' },
        { value: 'driver.isActive', label: 'Driver Active Status' },
        { value: 'driver.contactNumber', label: 'Driver Contact Number' },
        { value: 'driver.createdAt', label: 'Driver Created At' },
      ],
      formsFilterOption: [
        { value: 'isPassed', label: 'Is Passed' },
        { value: 'isDefault', label: 'Is Default' },
        { value: 'driverId', label: 'Driver ID' },
        { value: 'driver.udi', label: 'Driver UDI' },
        { value: 'driver.emailAddress', label: 'Driver Email' },
        { value: 'driver.driverName', label: 'Driver Name' },
        { value: 'driver.contactNumber', label: 'Driver Contact Number' },
      ],
    };
  },

  methods: {
    onSubmit() {
      //Setting filters
      const queryFilter = {};
      this.filters.forEach(filter => {
        if(filter.label) {
          if(filter.value === "true") { filter.value = true;} else if(filter.value === "false") { filter.value = false;}
          queryFilter[filter.label] = { "$eq": filter.value };
        }
      })

      this.query.filter = queryFilter;
      this.query.object = this.object;
      this.query.fields = this.fields;

      if(this.timeFilter && this.timeFilter !== 'custom') {
        this.query.from = this.timeFilter;
      }

      if(this.fromDate && this.toDate) {
        if(this.fromTime.length !== 0 && this.toTime.length !== 0) {
          this.query.from = this.fromDate + 'T' + this.fromTime + ':00.000Z';
          this.query.to = this.toDate + 'T' + this.toTime + ':59.000Z';
        } else {
          this.query.from = this.fromDate + 'T00:00:00.000Z';
          this.query.to = this.toDate + 'T23:59:59.000Z';
        }
      } else {
        delete this.query.from;
        delete this.query.to;
      }

      this.formattedJson = this.query
      this.toast('success', 'New JSON generated');
    },

    addFilter() {
      let array = this.filters;
      array.push({label: "", value: ""});
      this.filters = array;
    },

    async saveQuery() {
      this.submitLoading = true
      const me = await this.$store.getters[`account/me`];

      const payload = {
        name: this.name,
        businessId: me?.business?._id,
        query: this.query
      };

      await this.$store.dispatch(`business/saveQuery`, payload).then((response) => {
        if(response) {
          this.toast('success', 'JSON Query Saved!');
          this.$emit('closeModal');
        }
      })
          .catch((error) => {
            this.toast('error', 'Failed to Save JSON Query');
            console.error(error);
          })
      this.submitLoading = false;
    },

    downloadJson() {
      if (this.formattedJson) {
        let textToWrite = this.$refs.jsonContent.innerText;

        let blob = new Blob([textToWrite], {
          type: 'text/plain',
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        if (this.objName) {
          link.download = `${this.objName}.${this.fileType}`;
        } else {
          link.download = `Query.${this.fileType}`;
        }

        link.click();
      } else {
        this.toast('error', 'No query to download');
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', message);
    },

    resetFields(){
      this.fields = [];
      this.filters =[
        {
          label: "",
          value: ""
        }
      ];
    },

    clearField(event, field) {
      event.target.value = '';

      if (event.target.value !== '') {
        this.description[0][`${field}`] = '';
      }
    },
  },
  watch: {
    object: function (newV) {
      switch (newV) {
        case 'solo-driver':
          this.fieldOptions = this.driverOptions;
          this.filterOptions = this.driverFilterOptions;
          this.resetFields();
          break;
        case 'breach-engine':
          this.fieldOptions = this.breachEngineOptions;
          this.filterOptions = this.breachFilterOptions;
          this.resetFields();
          break;
        case 'event':
          this.fieldOptions = this.eventOptions;
          this.filterOptions = this.eventFilterOptions;
          this.resetFields();
          break;
        case 'prestart':
          this.fieldOptions = this.preStartOption;
          this.filterOptions = this.preStartFilterOption;
          this.resetFields();
          break;
        case 'fit-to-drive':
        case 'hazard':
        case 'defect':
        case 'infringement':
        case 'incident':
          this.fieldOptions = this.formsOption;
          this.filterOptions = this.formsFilterOption;
          this.resetFields();
          break;

      }
    }
  },
};
</script>

<style scoped></style>
