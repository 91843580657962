import styled from 'vue-styled-components'

const checkboxProps = {backgroundColor: String, buttonColor: String, buttonColorHover:String, buttonCountColor: String, buttonCountColorHover: String}

export const StyledTableFooterDiv = styled("div", checkboxProps)`
    background-color: ${({backgroundColor}) => backgroundColor || '#FFFFFF'};
   .prev, .next{
    color: #FFFFFF;
    background-color: ${({buttonColor}) => buttonColor || 'rgba(58,121,162,0.8)'};

    &:hover{
        background-color: ${({buttonColorHover}) => buttonColorHover || '#3a79a2'};
    }
   }

   .pageNumber{
    background-color: #FFFFFF;
    color: ${({buttonCountColor}) => buttonCountColor || 'rgba(58,121,162,0.8)'};
    &:hover{
        background-color: ${({buttonCountColorHover}) => buttonCountColorHover}!important;
        color: #FFFFFF!important;
    }
   }
`