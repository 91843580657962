export default {
  updateTheme(state, colorScheme) {
    state.colorScheme = colorScheme;
  },
  updateIsDarkMode(state, darkMode) {
    state.isDarkMode = darkMode;
  },
  updateThemeLookupOptions(state, themeLookupLists) {
    state.themeOptions = themeLookupLists;
  },
  updateActiveTheme(state, themeData) {
    state.activeTheme = themeData;
    state.colorScheme = themeData.themeData;
  },
  setInitializedTheme(state, initializedTheme) {
    state.initializedTheme = initializedTheme;
  },
  updateEnableDynamicTheme(state, isEnableDynamicTheme) {
    state.isEnableDynamicTheme = isEnableDynamicTheme;
  },
};
