<template>
  <div class="mb-16">
    <div class="mb-6">
      <pre>{{ rootBusinessName }} - {{ depotName }}</pre>
    </div>
    <FormulateForm @submit="onSubmit">
      <FormulateInput
        type="text"
        name="depotName"
        label="Business Site Name"
        validation= 'required'
        v-model="depotName"
      />
      <StyledSubmit :backgroundColor="theme.secondaryColor">
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-full"
          :disabled="submitLoading"
          :style="{backgroundColor: theme.secondaryColor}"
          >
          <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving data...</span>  
          <span v-if="!submitLoading">Submit</span>  
        </FormulateInput>
      </StyledSubmit>
    </FormulateForm>
  </div>
</template>

<script>
import schema from './new-depot-schema'
import { mapGetters } from 'vuex'
import { StyledSubmit } from '@/styled-components'

export default {
  name: 'New-Site-Form',
  props: {
    tree: Object,
    newLeaf: Object,
    entityId: String,
    rootBusinessName: String,
  },
  data() {
    return {
      error:false,
      errorMessage: '',
      loading: false,
      depotName: '',
      schema: schema,
      newTree: false,
      submitLoading: false
      
    };
  },
  components: { StyledSubmit },
  mounted(){
    this.newTree = this.tree
  },
  methods: {
    async onSubmit(data) {
      this.submitLoading = true
      let ids = [];
      let treePointer = this.newLeaf;
      while(treePointer.name !== "root") {
        ids.push(treePointer.id)
        treePointer = treePointer.parent
      }
          
      ids.shift()

      ids.reverse()

      let children = [...this.newTree.children] 

      treePointer = this.newTree 

      let idx = 0;
      while(treePointer.id !== ids[ids.length-1] && children) { 
        if (idx > ids.length) break
        treePointer = children.find(obj => ids[idx] == obj.id)
        children = treePointer.children
        idx += 1
      }

      const newNode = {
        children: [],
        isLeaf: true,
        name: data.depotName,
        entityId: this.entityId,
        type: "",
        id: this.newLeaf.id,
        isRoot: false,
        isActive: true,
        pid: this.newLeaf.pid,
        editNodeDisabled: false,
        dragDisabled: false,
        addTreeNodeDisabled: true,
        delNodeDisabled: false
      }

      treePointer.addChildren(newNode)
      this.getNewTree(ids[0])
    },

    getNewTree(rootId) {
      var vm = this
      function _dfs(oldNode) {
        var newNode = {}

        for (var k in oldNode) {
          if (k !== 'children' && k !== 'parent') {
            newNode[k] = oldNode[k]
          }
        }

        if (oldNode.children && oldNode.children.length > 0) {
          newNode.children = []
          for (var i = 0, len = oldNode.children.length; i < len; i++) {
            newNode.children.push(_dfs(oldNode.children[i]))
          }
        }
        return newNode
      }

      vm.newTree = _dfs(vm.newTree)
      
      const parsedTree = vm.newTree.children.find((obj)=> obj.id === rootId)

      this.createDepot(parsedTree)
    },

    async createDepot(parsedTree) {
      parsedTree.newDepotId = this.newLeaf.id
      parsedTree.newDepotIsRegion = false

      await this.$store.dispatch('business/patchDepot', { payload: parsedTree, id: parsedTree._id})
        .then(()=> {
          this.$emit('refetch')
          this.$emit('closeModal')
          this.$store.commit('setDialogNotify',true)
          this.$store.commit('setMessageNotify',{state:'success',message:'New business site successfully added.'})
          this.submitLoading = false
        })
        .catch(err => {
          this.$emit('refetch')
          this.$emit('closeModal')
          this.$store.commit('setDialogNotify',true)
          this.$store.commit('setMessageNotify',{state:'error',message: err.message})
          this.submitLoading = false
        })
    }
  },

  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
}
</script>
 