<template>
  <div>
    <FormulateInput
      type="radio"
      class="custom-class"
      :id="control.id"
      :name="control.name"
      :options="control.options"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
      v-model="control.value"
      :required="control.required"
      label-class=""
      input-class=""
      wrapper-class=""
      outer-class=""
      element-class=""
      checked
    />
  </div>
</template>
<script>
export default {
  props: {
    control: {
      type: Object,
      default: () => {}
    },
    isDisplayValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
//Vehicle Class Radio button custom design

.custom-class {
  .formulate-input-element {
    display: flex;
    margin-right: 0.5em;
  }
  input {
    width: auto;
  }

  .formulate-input-wrapper {
    display: flex;
    width: 100%;

    label::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }

  .formulate-input-group-item {
    padding: 0.5em 0 0 0.5em;
    position: relative;
    margin-right: 1em;
    margin-bottom: 0 !important;

    .formulate-input-wrapper {
      display: flex;
      width: 100%;

      label {
        font-size: 0.8rem !important;
      }
    }

    label::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }

    &[data-has-value] {
      background-color: #e0f2fe;
    }
  }
}

.custom-options.checkbox {
  .formulate-input-element {
    flex-direction: column;
  }
}
</style>
