<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <FormulateForm v-model="values" @submit="onSubmit">
        <div class="items-center justify-between">
          <FormulateInput
            name="driverName"
            type="text"
            placeholder="driverName"
            label="Full Name"
            validation="^required"
            :validation-messages="{ required: 'Name is required.' }"
          />
          <FormulateInput
            name="driverDateOfBirth"
            type="date"
            placeholder="driverDateOfBirth"
            label="Date of Birth"
            validation="^required"
            :validation-messages="{ required: 'Date of Birth is required.' }"
          />
          <FormulateInput
            name="driversLicenseNumber"
            type="text"
            placeholder="driversLicenseNumber"
            label="Licence Number"
            validation="^required"
            :validation-messages="{ required: 'Licence Number is required.' }"
          />
          <FormulateInput
            name="driversLicenseExpiry"
            type="date"
            placeholder="driversLicenseExpiry"
            label="Licence Expiry"
            validation="^required"
            :validation-messages="{ required: 'Licence Expiry is required.' }"
          />
          <FormulateInput
              type="submit"
              input-class="btn btn-primary w-full"
              :style="{backgroundColor: theme.secondaryColor}"
          />
        </div>
      </FormulateForm>
    </div>

    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>

import {formatDate} from "@/_helper";
import { mapGetters } from 'vuex'

export default {
  name: "Edit-Licence-Details",
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      values: {},
      driver: {},
      error: false,
      errorMessage: "",
    };
  },
  async mounted() {
    this.loading = true;
    const driverData = await this.$store.dispatch(`driver/getDriver`, {
      id: this.id,
    });
    this.driver = driverData;
    this.values = this.parseDriver(driverData);
    this.loading = false;
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      this.driver.driverName = this.values.driverName;
      this.driver.driverDateOfBirth = this.values.driverDateOfBirth;
      this.driver.driversLicenseExpiry = this.values.driversLicenseExpiry;
      this.driver.driversLicenseNumber = this.values.driversLicenseNumber;
      await this.$store.dispatch(`driver/updateDriverProfile`, this.driver)
          .then(driver => {
            this.loading = false;
            if(driver){
              driver.contractId = this.id
              this.$emit('updatedDriver', driver);
            }
          }).catch(error => {
            this.errorMessage = `Something went wrong please try again.`;
            if(error.message) this.errorMessage = error.message[0]});
      this.loading = false;
    },
    parseDriver(driver) {
      return {
        driverDateOfBirth:
          formatDate(driver.driverDateOfBirth, "YYYY-MM-DD") || "-",
        driverName: driver.driverName || "-",
        driversBFMNumber: driver.driversBFMNumber || "-",
        driversBaseLocation: driver.driversBaseLocation || "-",
        driversBaseTimeZone: driver.driversBaseTimeZone || "-",
        driversLicenseExpiry:
          formatDate(driver.driversLicenseExpiry, "YYYY-MM-DD") || "-",
        driversLicenseNumber: driver.driversLicenseNumber || "-",
        emailAddress: driver.emailAddress || "-",
        udi: driver.udi || "-",
        isActive: driver.isActive,
        id: driver._id,
      };
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
