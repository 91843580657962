 
 import { API } from '@/api/config'
 import { ObjectToQueryString } from '@/_helper';

 export default new class GeotabVehicles{
    // Get geotab vehicles
    fetchGeotabVehicles({query}){
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/vehicle-external/find-all-not-yet-synced-to-logmaster/${query}`)
    }
}