<template>
    <Stepper 
        :stepperSteps="computedStepperSteps" 
        @completed-step="completeStep" 
        @active-step="isStepActive" 
        :showNavigation="false"  
        @closeRefresh="closeRefresh"
      />
</template>

<script>
import { Stepper } from '@/components/commons'
import {CreateDriver, CreateBusinessProfile }  from './stepper-component'
export default {
    name: 'Link-Geotab-Driver',
    components: {Stepper},
    props: {
        stepperData: null,
    },
    data(){
        return{
            stepperSteps: [
                 {
                    stepIcon: 'user',
                    stepTitle: 'Link Driver Details',
                    stepSequence: 'first',
                    stepComponent: CreateDriver,
                    isCompleted: false,

                },
                 {
                    stepIcon: 'briefcase',
                    stepTitle: 'Business Profile',
                    stepSequence: 'second',
                    stepComponent: CreateBusinessProfile,
                    isCompleted: false,
                },
            ],
            activeStep: 1,
        }
    },
     methods: {
        completeStep(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    step.isCompleted = true;
                }
            })
        },
        isStepActive(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    if(step.isCompleted === true) {
                        step.isCompleted = false;
                    }
                }
            })
        },
        closeRefresh(){ 
            this.$emit('closeRefresh')
        }
    },
    computed: {
        computedStepperSteps(){
            if(this.stepperData){
            return this.stepperSteps.map((step) => ({
                ...step,
                defaultData: this.stepperData
            }))
            }else{
                return this.stepperSteps
            }
        }
    }
    
}
</script>