import { render, staticRenderFns } from "./new-query-json-form.vue?vue&type=template&id=4ea4643f&scoped=true&"
import script from "./new-query-json-form.vue?vue&type=script&lang=js&"
export * from "./new-query-json-form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ea4643f",
  null
  
)

export default component.exports