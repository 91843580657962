<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit" #default="{ hasErrors }">
      <div v-if="loading" class="text-center">
        <ui-spinner class="m-auto"></ui-spinner> Loading...
      </div>
      <div v-if="!loading" class="items-center justify-between">
        <FormulateInput
          type="text"
          name="username"
          label="Service Account"
          validation="required"
        />
        <FormulateInput
          type="text"
          name="database"
          label="Database"
          validation="required"
        />
        <FormulateInput
          type="text"
          name="server"
          label="Server"
          validation="required"
        />
        <FormulateInput
          type="password"
          name="password"
          label="Password"
          validation="required"
        />
        <FormulateInput
          type="password"
          name="confirmPassword"
          label="Confirm Password"
          validation="required"
        />
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          />
        </StyledSubmit>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";

export default {
  name: "Add-Geotab-Credentials",
  props: {
    activeBusiness: {
      type: Object,
      default: () => {},
    },
  },
  components: { StyledSubmit },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: "",
    };
  },
  methods: {
    async onSubmit() {
      const { password, confirmPassword, database, server, username } =
        this.values;
      if (password !== confirmPassword) {
        this.toast("error", "Passwords must match");
        return;
      }

      try {
        await this.$store.dispatch("business/createGeotabCredentials", {
          businessId: this.activeBusiness._id,
          password,
          confirmPassword,
          database,
          server,
          username,
        });
        this.$emit("closeModal");
        this.toast("success", "Geotab password created");
      } catch (error) {
        const errorMessage = error.errors?.message? error.errors.message: "Geotab authentication failed";
        this.toast("error", errorMessage);
      }
    },

    async toast(state, message) {
      const toastPayload = {
        state,
        message,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", toastPayload);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
