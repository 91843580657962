<template>
    <div class="mb-16">
      <FormulateForm v-model="values" @submit="onSubmit" #default="{ hasErrors }">
        <div class="items-center justify-between">
          
          <div class="p-5 mt-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold bg-gray-50">Duration and Product Count Details</div>

            <table>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Contract Period: </div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">{{preData.contractDuration.label}}</div>
                </td>
              </tr>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Billing Period: </div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">{{preData.billingPeriod.label}}</div>
                </td>
              </tr>
              
            </table>
          </div>

          <div class="p-5 mt-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold bg-gray-50">Core Platform Licences Pricing Details</div>.
            
             <table v-if="!loading" class="table-auto leading-normal p-4 w-full">
              <thead>
                <th class="bg-gray-100 text-center p-3 font-semibold justify-center">
                  Specialty Modules
                </th>
                <th class="bg-gray-200 text-center p-3 font-semibold justify-center">
                  Base
                </th>
                <th v-if="preData.contractDuration.label !== 'Yearly'" class="bg-gray-200 text-center p-3 font-semibold justify-center">
                  Reduce Sell Price
                </th>
                <th v-if="preData.contractDuration.label === 'Yearly'" class="bg-gray-200 text-center p-3 font-semibold justify-center">
                  Yearly Discount
                </th>
                <th v-if="preData.contractDuration.label === 'Yearly'" class="bg-gray-200 text-center p-3 font-semibold justify-center">
                  Manual Entry
                </th>
              </thead>

              <tr v-for="(modules, index) in preData.selectedModules.cplValues" :key="index">
               <td :key="index" class="p-3">{{modules.name}}</td>
                <td class="p-2">
                  <div class="flex items-center justify-center">
                    <FormulateInput
                        type="checkbox"
                        :name="`cplRRP-${modules.id}`"
                        class="w-full pt-3 custom-options checkbox flex justify-center"
                    />
                  </div>
                </td>
                <td v-if="preData.contractDuration.label !== 'Yearly'" class="p-2">
                  <div class="flex items-center justify-center">
                    <span class="mr-3">$</span>
                    <FormulateInput
                        type="number"
                        :name="`rsp_${modules.id}`"
                        placeholder="0"
                        class="w-1/2 pt-3"
                      />
                  </div>
                </td>
                 <td v-if="preData.contractDuration.label === 'Yearly'" class="p-2">
                  <div class="flex items-center justify-center">
                    <FormulateInput
                        type="checkbox"
                        :name="`yearlyDiscount-${modules.id}`"
                        class="w-full pt-3 custom-options checkbox flex justify-center"
                    />
                  </div>
                </td>
                 <td v-if="preData.contractDuration.label === 'Yearly'" class="p-2">
                  <div class="flex items-center justify-center">
                    <span class="mr-3">$</span>
                    <FormulateInput
                        type="number"
                        :name="`me_${modules.id}`"
                        placeholder="0"
                        class="w-1/2 pt-3"
                      />
                  </div>
                </td>
              </tr>
             </table>
          </div>


          <div class="p-5 mt-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold bg-gray-50">Specialty Modules Pricing Details</div>.
            
             <table v-if="!loading" class="table-auto leading-normal p-4 w-full">
              <thead>
                <th class="bg-gray-100 text-center p-3 font-semibold">
                  Specialty Modules
                </th>
                <th class="bg-gray-200 text-center p-3 font-semibold">
                  Base
                </th>
                <th v-if="preData.contractDuration.label !== 'Yearly'" class="bg-gray-200 text-center p-3 font-semibold">
                  Reduce Sell Price
                </th>
                <th v-if="preData.contractDuration.label === 'Yearly'" class="bg-gray-200 text-center p-3 font-semibold">
                  Yearly Discount
                </th>
                <th v-if="preData.contractDuration.label === 'Yearly'" class="bg-gray-200 text-center p-3 font-semibold">
                  Manual Entry
                </th>
              </thead>

              <tr v-for="(modules, index) in preData.selectedModules.specialtyModulesValues" :key="index">
                <td :key="index" class="p-3">{{modules.name}}</td>
                <td class="p-2">
                  <div class="flex items-center justify-center">
                    <FormulateInput
                        type="checkbox"
                        :name="`cplRRP-${modules.id}`"
                        class="w-full pt-3 custom-options checkbox flex justify-center"
                    />
                  </div>
                </td>
                <td v-if="preData.contractDuration.label !== 'Yearly'" class="p-2">
                  <div class="flex items-center justify-center">
                    <span class="mr-3">$</span>
                    <FormulateInput
                        type="number"
                        :name="`rsp_${modules.id}`"
                        placeholder="0"
                        class="w-1/2 pt-3"
                      />
                  </div>
                </td>
                 <td v-if="preData.contractDuration.label === 'Yearly'" class="p-2">
                  <div class="flex items-center justify-center">
                    <FormulateInput
                        type="checkbox"
                        :name="`yearlyDiscount-${modules.id}`"
                        class="w-full pt-3 custom-options checkbox flex justify-center"
                    />
                  </div>
                </td>
                 <td v-if="preData.contractDuration.label === 'Yearly'" class="p-2">
                  <div class="flex items-center justify-center">
                    <span class="mr-3">$</span>
                    <FormulateInput
                        type="number"
                        :name="`me_${modules.id}`"
                        placeholder="0"
                        class="w-1/2 pt-3"
                      />
                  </div>
                </td>
              </tr>
             </table>
          </div>
          
          <FormulateInput
            type="submit"
            label="Next"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{backgroundColor: theme.secondaryColor}"
          />
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

  export default {
    name: "Pricing",
    props: {
      preData: {},
    },
    data() {
    return {
      loading: false,
      values: {},
      errorMessage:''
    }
  },
  async mounted(){
    this.init()
  },
  methods: {
    async init(){
      console.log("preData", this.preData)
      const me = this.$store.getters[`account/me`];

      //  const query = {
      //   skip: ( paged * this.filter.limit ) - this.filter.limit,
      //    limit: this.filter.limit,
      //   search: this.filter.search,
      //   filter: {}
      // } 

      // if(this.filter.status != 'all') query.filter.status = { $eq : this.filter.status }
      const resellerParent = await this.$store.dispatch('reseller/getResellerByUID', me?.uid)
      if(resellerParent){
        const activeRRPBusiness = await this.$store.dispatch('standardpricing/getActiveRRPforBusiness',  {id: resellerParent?.parentUid})
        const pricingData = await this.$store.dispatch(`standardpricing/getStandardPricingByMongoId`, resellerParent?.parentUid)
        console.log("pricingData", pricingData)
      console.log("activeRRPBusiness", activeRRPBusiness)
      }
      
    },
    async onSubmit() {
      this.loading = true
      const businessDetails = JSON.parse(JSON.stringify(this.values))
      if(businessDetails){
        this.$emit('nextStep', { businessDetails })
      }
    }
  },
   computed: {
      ...mapGetters('theme', {
      theme: 'getColorScheme'
      })
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.box-shadow-none div:nth-child(2){
  width: 50%;
  input{ 
    box-shadow: none!important;
    }
}
</style>