/* eslint-disable no-control-regex */
import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper'

export default new class Business {
    // @Business
    createBusiness(data){ // Create business
        return API.ACCOUNTS.post(`/business`, data)
    }
    createBusinessWithContract(data) {
        return API.ACCOUNTS.post('/business/create-business-with-contract', data)
    }
    getBusiness({ id = null, query}){ // Get single Business by Id or Get all Business
        if(id){
            return API.ACCOUNTS.get(`/business/${id}`)
        }else{
            let filter = '';
            if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
            query.filter = filter;
            if(query) query = '?'+ObjectToQueryString(query);
            return API.ACCOUNTS.get(`/business${query}`)
        }
    }

    updateBusiness(businessId, data){
        return API.ACCOUNTS.patch(`/business/${businessId}`, data)
    }

    getBusinessByUID( uid = null ){ // Get single Business by Id or Get all Business
        return API.ACCOUNTS.get(`/business/find-one-by-uid/${uid}`)
    }

    getBusinessBySiteId( siteId = null ){ // Get single Business by site id
        return API.ACCOUNTS.get(`/business/find-one-by-site-id/${siteId}`)
    }

    patchBusiness( id = null, data ){ // Update single Business by Id
        return API.ACCOUNTS.patch(`/business/${id}`, data)
    }

    patchBusinessCreatePassword( id = null, data ){ // Call this to create Business password
        return API.ACCOUNTS.patch(`/business/create-password/${id}`, data)
    }

    acceptBusiness( id = null ) {
        return API.ACCOUNTS.get(`/business/accept/${id}`)
    }

    // start of depot
    createDepot(data) {
        return API.ACCOUNTS.post(`/depot-config`, data)
    }

    getDepot( entityId = null ) {
        return API.ACCOUNTS.get(`/depot-config/get-depot-configs-by-entityId/${entityId}`)
    }

    getDepotLinkage( parentBusinessId = null ) {
        return API.ACCOUNTS.get(`/depot-config-linkage/find-all-by-parent/${parentBusinessId}`)
    }

    getDepotLink(id){
        return API.ACCOUNTS.get(`/depot-config-linkage/${id}`)
    }

    getSites(entityId = null, isDeleted = false) {
        return API.ACCOUNTS.get(`/depot-config/get-sites/${entityId}?isDeleted=${isDeleted}`)
    }

    getSingleDepot ( id ) {
        return API.ACCOUNTS.get(`/depot-config/${id}`)
    }

    patchDepot(data) {
        const { id, payload } = data
        return API.ACCOUNTS.patch(`/depot-config/${id}`, payload)
    }

    updateDepotName(id, payload){
        return API.ACCOUNTS.patch(`/depot-config/update-name/${id}`, payload)
    }

    createDepotLink(data){
        return API.ACCOUNTS.post(`/depot-config-linkage`, data)
    }

    getSingleDepotLinks(id){
        return API.ACCOUNTS.get(`/depot-config-linkage/find-all-by-parent/${id}`)
    }

    patchDepotLink(id, data){
        return API.ACCOUNTS.patch(`/depot-config-linkage/${id}`, data)
    }

    moveSite(data){
        return API.ACCOUNTS.post(`/depot-config/move`, data)
    }

    deleteRegion(id){
        return API.ACCOUNTS.delete(`/depot-config/remove/${id}`)
    }

    moveSiteDriverVehicle(data) {
        return API.ACCOUNTS.post(`/depot-config/move-driver-vehicle`, data)
    }
    // end of depot

    updateLicenseStatus(businessId, data) {
        return API.ACCOUNTS.patch(`/business/license-status/${businessId}`, data)
    }

    updateBusinessParentConnection(businessId, data) {
        return API.ACCOUNTS.patch(`/business/update-parent-connection/${businessId}`, data)
    }

    getDemoOptions() {
        return API.ACCOUNTS.get('/user/enums/demo-options');
    }

    resendBusinessInvite(data){
        return API.ACCOUNTS.post(`/business/resend-invite`, data)
    }

    deleteBusiness( id ){
        return API.ACCOUNTS.delete(`/business/${id}`)
    }
    createBusinessLead(payload){ 
        return API.ACCOUNTS.post(`/business/lead`, payload)
    }
    getBusinessLead(pagination = { skip: 0, limit: 10, filter: {} }){
        let filter = "";    
        if (typeof pagination.filter === "object") {
          try {
            filter = JSON.stringify(pagination.filter);
          } catch (e) {
            throw "Invalid Filter object";
          }
        }
        pagination.filter = filter;
    
        if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";
        return API.ACCOUNTS.get(`/business/lead/me${pagination}`)
    }
    getLead(id){ 
        return API.ACCOUNTS.get(`/business/lead/${id}`)
    }
    deleteLead(id){ 
        return API.ACCOUNTS.delete(`/business/lead/${id}`)
    }
    transferLead(payload){ 
        return API.ACCOUNTS.patch(`/business/lead/transfer`, payload)
    }
    convertLead(payload){ 
        return API.ACCOUNTS.post(`/business/lead/convert`, payload)
    }
    getTokenByBusiness(id){ // Get token By Business ID
        return API.ACCOUNTS.get(`/api/get-token/${id}`)
    }

    generateToken(){ // Get token By Business ID
        return API.ACCOUNTS.post(`/api/generate`)
    }

    saveQuery(data){ // Save Query
        return API.ACCOUNTS.post(`/api/query`, data)
    }

    getQuery(data, query = { skip: 0, limit: 10, filter: {} }){ // Get queries By Business ID
        if(query) query = '?'+ ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/api/query/${data.businessId}${query}`)
    }

    saveApiUsageDisableOption(data){ // Save One API Usage Disable Option
        return API.ACCOUNTS.post(`/api/usage`, data)
    }

    getApiUsageDisableOption(businessId){ // Get One API Usage Disable Option
        return API.ACCOUNTS.get(`/api/usage/${businessId}`)
    }

    getApiUsagePercentage(businessId){ // Get One API Usage Disable Option
        return API.ACCOUNTS.get(`/api/usage-percentage-month/${businessId}`)
    }

    getMarketplaceItems( id ){
        return API.ACCOUNTS.get(`/standard-pricing/find-all-active-rrp-to-business/${id}`)
    }

    getMarketplaceItemByID( id ){
        return API.ACCOUNTS.get(`/standard-pricing/${id}`)
    }

    getContractModuleMaster(){
        return API.ACCOUNTS.get(`/contract-module-master/`)
    }

    createContractModule(data){
        return API.ACCOUNTS.post(`/contract-select-module/`, data)
    }

    deleteContractModule(id){
        return API.ACCOUNTS.delete(`/contract-select-module/${id}`,)
    }

    updateDriverEmail(payload){
        return API.ACCOUNTS.patch(`/driver-contract/update-driver-email`, payload)
    }

    createGeotabCredentials(payload) {
        return API.ACCOUNTS.post('/business/geotab/credentials', payload);
    }

    toggleStatsPolling() {
        return API.ACCOUNTS.post('/business/driver-statistic-polling/toggle');
    }
}