<template>
  <div>
    <div v-if="readReports"> 
      <div class="flex flex-col h-full">
        <div class="p-5 bg-white shadow-md rounded overflow-hidden relative compliance-container">
          <div class="mb-3 pb-4 flex items-center w-full border-b border-gray-300">
            <div class="mr-3 w-52">
              <div class="block font-bold text-xs text-grey-500 mb-1">Form Type:</div>
              <FormulateInput
                name="selectedPlan"
                type="general-select"
                :clearable="false"
                v-model="formType"
                :options="formTypeOption"
                :reduce="option => option.value"
                validation="required"
                class="text-sm"
                style="margin-bottom: 0px"
              />
            </div>
            <div class="mr-3" style="width: 15rem">
              <div class="block font-bold text-xs text-grey-500 mb-1">Driver:</div>
              <FormulateInput
                @selected="selectedDriver = $event"
                name="selectedDriver"
                type="driver-select"
                placeholder="Select a driver"
                style="margin-bottom: 0px"
                :getOptionKey="option => option.driver.udi"
                :getOptionLabel="option => option.driver.driverName"
                :filterable="false"
                :clearable="false"
                validation="required"
              />
            </div>
            <div class="mr-3 w-52">
              <div class="block font-bold text-xs text-grey-500 mb-1">Date Range:</div>
              <DatePicker 
                ref="childDatePicker" 
                :date="selectedDateRange"
                placeholder="YYYY-MM-DD ~ YYYY-MM-DD" 
                :disabledBefore="disabledBefore"
                :disabledAfter="disabledAfter"
                @selected="selectedDateRange = $event" 
                range noNavigation :clearable="false"
              />
            </div>
          </div>

          <div class="flex w-full items-center driver-info-container">
            <FormsReport
              ref="childFormsReport"
              :selectedDateRange="selectedDateRange"
              :selectedDriver="selectedDriver"
              :formType="formType"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="!readReports && hasRole" class="w-full min-h-full text-center mt-5">
      <h1>Not authorized to view reports. Please contact your business admin.</h1>
    </div>
    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import DatePicker from '@/components/commons/field/DatePicker';
import FormsReport from '@/components/view/business/forms-report'
import { mapGetters } from 'vuex'

export default {
  name: "Forms-Report-Page",
  components: { DatePicker, FormsReport },
  data() {
    return {
      selectedDateRange: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      selectedDriver: null,
      readReports: false,
      writeReports: false,
      disabledBefore: null,
      disabledAfter: moment().format('YYYY-MM-DD'),
      hasRole: true,
      formType: 'prestart',
      formTypeOption: [
        { value: 'prestart', label: 'Prestart' },
        { value: 'fitness_declaration', label: 'Fitness Declaration' },
        // { value: 'hazard', label: 'Hazard' },
        // { value: 'incident', label: 'Incident' },
      ]
    };
  },
  watch: {
    selectedDriver: {
      handler: function () {
        this.onSearch()
      },
      deep: true
    },
    selectedDateRange: {
      handler: function () {
        this.onSearch()
      },
      deep: true
    }
  },
  async mounted(){
    this.$emit("updateLoading", true)
    const me = this.$store.getters[`account/me`]
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles)
      this.readReports = securityAccessObj.readReports
    } else {
      this.hasRole = false
    }
    
    await this.onSearch();
    this.$emit("updateLoading", false)
  },
  methods: {
    canSearch() {
      return !_.isEmpty(this.selectedDriver) && this.selectedDateRange.length > 1 && !_.isEmpty(this.selectedDateRange[0]);
    },
    setSecurityAccess: (securityRoles) => {
      let initialReadReports = false
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig
        if (securityConfig.reports.readReports?.value) {
          initialReadReports = securityConfig.reports.readReports.value
        }
      });
      return {
        readReports: initialReadReports
      }
    },
    async onSearch() {
      if (this.canSearch()) {
        const me = this.$store.getters[`account/me`];
        await this.$store.dispatch(
          `business/getContractByDriver`, 
          { 
            uid: this.selectedDriver.driverId, 
            query: { skip:0, limit:10, filter: { businessId: me?.business?._id, driverId: this.selectedDriver.driverId} } 
          }
        ).then(({ results }) => {
          if (results.length) {
            const sortedContracts = _.orderBy(results, ['startDate'], ['desc']);
            const contract = _.first(sortedContracts);
            this.isContractor = contract?.isContractor ? true : false;
            const contractDate = contract.startDate ? contract.startDate : contract.createdAt;
            const dateToDisable = this.isContractor && contract?.contractorAccess  == 'moving-forward' ? moment(contractDate, this.dateFormat) : moment(contractDate, this.dateFormat).subtract(56, 'days');
            this.disabledBefore = dateToDisable.format(this.dateFormat);
            if (contract?.endDate) this.disabledAfter = moment(contract.endDate).format('YYYY-MM-DD');
          } else {
            this.disabledBefore = moment(this.selectedDriver.createdAt).subtract(56, 'days').format(this.dateFormat);
          }
        });
        this.$refs.childFormsReport.onSearch()
      }
    },
  },
   computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>
<style scoped lang="scss">
  .active {
    @apply border-orange-primary
  }
  .vertical {
    min-width: 7px;
    line-height: 7px;
    writing-mode: tb-rl;
    writing-mode: vertical-rl;
    white-space: nowrap;
    text-align: center; 
    transform: rotate(180deg);
  }
  .compliance-container {
    min-height: calc(100vh - 92px);
  }
</style>
