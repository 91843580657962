/* eslint-disable */
import { Geotab } from "@/api";

export default {
  async syncGeotabData({}, payload) {
    try {
      const response = await Geotab.syncGeotabData(payload);
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
