<template>
    <div class="page-container px-2 pt-3">
        <div class="w-full" v-if="loading">
            <div class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center">
                <Spinner class="mx-auto mb-2"></Spinner>
                <span class="font-semibold text-gray-400">Loading data...</span>
            </div>
        </div>
        <div v-else>
            <label class="block mb-2 text-md font-medium text-gray-900">Select the vehicles to import</label>
            <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" class="p-4" :style="{backgroundColor: theme.primaryColor}">
                                <div class="flex items-center">
                                    <input type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded" @change="selectAll()">
                                    <label class="sr-only">checkbox</label>
                                </div>
                            </th>
                            <th
                                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                                :style="{backgroundColor: theme.primaryColor}"
                            >
                                Plate<br/>Number
                            </th>
                            <th
                                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                                :style="{backgroundColor: theme.primaryColor}"
                            >
                                Registration<br/>Expiry
                            </th>
                            <th
                                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                                :style="{backgroundColor: theme.primaryColor}"
                            >
                                GVM
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(vehicle, index) in vehicles"
                            :key="index"
                            :data-import-id="index"
                            :ref="getRowRef(index)"
                            :class="{ 'hover:bg-red-50': vehicle.hasFieldError }"
                            class="bg-white hover:bg-gray-50 relative"
                        >
                            <td class="p-4 w-4">
                                <div v-if="!vehicle.hasFieldError">
                                    <div class="flex items-center">
                                        <input
                                            v-model="vehicle.checked"
                                            type="checkbox"
                                            class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                                        >
                                        <label class="sr-only">checkbox</label>
                                    </div>
                                </div>
                                <div v-else class="absolute top-0 left-0 right-0 bottom-0 bg-red w-full border-b border-white">
                                    <div class="relative w-full h-full flex items-center justify-center">
                                        <div class="absolute inset-0 bg-red-100 z-0 flex px-5">
                                            <div class="flex items-center justify-center">
                                                <font-awesome-icon
                                                    icon="exclamation-triangle"
                                                    class="text-xl m-auto text-red-700"
                                                />
                                            </div>
                                            <div class="flex-1 flex flex-col justify-center pl-3">
                                                <div v-if="vehicle.hasFieldError" class="font-medium text-red-700 py-1">
                                                    <span v-if="vehicle.missingFields.length && vehicle.invalidFields.length">Vehicle data has missing and invalid fields.</span>
                                                    <span v-else-if="vehicle.missingFields.length">Vehicle data has missing fields.</span>
                                                    <span v-else-if="vehicle.invalidFields.length">Vehicle data has invalid fields.</span>
                                                </div>
                                            </div>
                                            <div class="flex items-center justify-center capitalize text-sm font-medium text-right whitespace-nowrap">
                                                <a @click.prevent="editVehicle(index, vehicle)" class="text-grey-400 hover:underline cursor-pointer">Edit</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td class="py-4 px-6">{{ vehicle.vehiclePlate }}</td>
                            <td class="py-4 px-6">{{ formatDateToAU(vehicle.registrationExpiry) }}</td>
                            <td class="py-4 px-6">{{ vehicle.GMV }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <FormulateInput
                type="submit"
                label="Next"
                input-class="btn btn-primary w-full mt-5"
                :disabled="loading"
                @click="nextPage()"
            />
        </div>
        <div class="hidden" id="default-edit-vehicle-template">
            <tr id="edit-vehicle-template" class="border-b border-grey-400">
                <td colspan="5">
                    <div class="w-full px-5 py-3">
                        <EditVehicle :importVehicle="values" @closeImportEdit="closeEdit($event)" :detailsId="null" />
                    </div>
                </td>
            </tr>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { isEmpty, isNil, first } from 'lodash';
import schema from '../../new-vehicle-schema';
import Spinner from '@/components/commons/ui/Spinner';
import { EditVehicle } from '@/components/forms/vehicle';

export default {
    name: 'Select-Vehicle',
    components: { Spinner, EditVehicle },
    props: {
        preData: null
    },
    async mounted() {
        await this.getVehicleClasses();

        if(!isNil(this.preData)) {
            this.vehicles = [];
            await this.preData.vehicles.forEach(async (vehicle) => {
                this.vehicles.push(await this.validateField(vehicle))
            })
        }
    },
    data() {
        return {
            loading: false,
            schema: schema,
            vehicles: [],
            values: null,
            isSelectAll: false,
            selectedIndex: null,
            vehicleClasses: []
        };
    },
    methods: {
        formatDateToAU(dateToFormat) {
            return moment(dateToFormat, 'YYYY-MM-DD').format('DD/MM/YYYY')
        },
        getRowRef(index) {
            return 'ref' + index;
        },
        async validateField(vehicle) {
            vehicle.invalidFields = [];
            vehicle.missingFields = [];
            vehicle.hasFieldError = false;

            if(vehicle.vehicleClass !== 'HV' || vehicle.vehicleClass !== 'MV' || vehicle.vehicleClass !== 'LV') {
                vehicle.vehicleClass = 'hv'
            } else {
                vehicle.vehicleClass = vehicle.vehicleClass ? vehicle.vehicleClass : 'hv';
            }

            if (typeof vehicle.vehicleClass == 'object') vehicle.vehicleClass = vehicle.vehicleClass.name;
            const vehicleClass = this.vehicleClasses.find(vehicleClass => vehicleClass?.name.toLowerCase() == vehicle?.vehicleClass.toLowerCase());
            vehicle.vehicleClassId = vehicleClass?._id;
            vehicle.vehicleClass = vehicleClass;

            if (vehicle.vehicleClassId) {
                vehicle.vehicleType = await this.getVehicleType(vehicle);
            }
            vehicle.vehicleTypeId = vehicle.vehicleType?._id;

            Object.entries(vehicle).forEach(async ([key, value]) => {
                if ([
                    'checked', 'invalidfields', 'missingfields', 'hasfielderror',
                    'gvm', 'gmv', 'vin', 'fleetid', 'frontcouplings', 'rearcouplings', 'powercouplings',
                    'brakefoundation', 'braketype', 'supplyvoltagetotrailer',
                    'laetbsyssupplier', 'tstbcsrefnumber', 'comments', 'id', 'alias'
                ].includes(key.toLowerCase())) return;

                if (typeof value == 'boolean') value = String(value);
                if (isEmpty(value)) vehicle.missingFields.push(key);
                if (key == 'vehiclePlate' && !isEmpty(value) && !/^[A-Za-z0-9]*$/.test(value)) {
                    vehicle.invalidFields.push(key);
                }
            })
            vehicle.hasFieldError = vehicle.invalidFields.length > 0 || vehicle.missingFields.length > 0;
            return vehicle;
        },
        selectAll() {
            this.isSelectAll = !this.isSelectAll;
            this.vehicles.forEach(vehicle => {
                if (!vehicle.hasFieldError) vehicle.checked = this.isSelectAll ? true : false;
            })
        },
        nextPage() {
            const selectedVehicles = this.vehicles.filter(vehicle => vehicle.checked);
            this.$emit('nextStep', { vehicles: this.vehicles, selectedVehicles });
        },
        async editVehicle(index, vehicle) {
            vehicle.gvm = vehicle.GMV;
            this.values = vehicle;
            this.selectedIndex = index;
            $($("#edit-vehicle-template").detach()).insertAfter(`tr[data-import-id="${index}"]`);
            this.$nextTick(() => this.$refs[this.getRowRef(index)][0].scrollIntoView({ scrollBehavior: 'smooth' }));
        },
        async closeEdit(vehicle = null) {
            if (vehicle) {
                vehicle.status = 'pending';
                vehicle.checked = false;
                if (vehicle?.GVM) vehicle.GMV = vehicle.GVM;
                this.values = await this.validateField(vehicle);
                this.vehicles[this.selectedIndex] = vehicle;
            }

            this.selectedIndex = null;
            $($("#edit-vehicle-template").detach()).appendTo(`#default-edit-vehicle-template`);
        },
        async getVehicleClasses() {
            const vehicleClasses = await this.$store.dispatch(`vehicle/getVehicleClass`)
            this.vehicleClasses = vehicleClasses ? vehicleClasses : [];
        },
        async getVehicleType(vehicle) {
            const vehicleTypes = await this.$store.dispatch(`vehicle/getVehicleTypeSearch`, { vehicleClassId: vehicle.vehicleClassId });
            if(vehicleTypes) {
                const defaultVehicleType = first(vehicleTypes);
                const name = !isNil(vehicle.vehicleType) ? vehicle.vehicleType.toLowerCase() : defaultVehicleType?.name.toLowerCase();
                const vehicleType = vehicleTypes.find(vehicleType => vehicleType.name.toLowerCase() == name);
                vehicle.vehicleType = !isNil(vehicleType) ? vehicleType : defaultVehicleType;
            }

            return vehicle.vehicleType;
        }
    },
    computed: {
        ...mapGetters('theme', {
            theme: 'getColorScheme'
        })
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
