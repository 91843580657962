
import { ADMIN_ROLE, PARTNER_ROLE } from '@/constants/usertype'
import _ from 'lodash'

export default{
    state: {
        appName: process.env.VUE_APP_NAME,
        baseURL: process.env.VUE_APP_URL,
        allURL:{},
        dialogNotify: false,
        messageNotify: null
    },
    actions:{
        async setallURL({state , dispatch, commit}, { routes, parent }){
            let URL = {}
            if(routes){
                routes.forEach(async(route) => {
                    let base = route.path.replace('/', '').replace('*', '')
                    if(base && route.component || route.beforeEnter){
                        if(_.isEmpty(route.children)){
                            if(parent){
                                URL[base] = `${state.baseURL}/${parent}/${route.path}`
                            }else{
                                URL[base] = `${state.baseURL}${route.path}`
                            }
                        }else{
                            let childURL = await dispatch('setallURL', { routes:route.children, parent:base } )
                            URL[base] = { default: `${state.baseURL}${route.path}`,...childURL }
                        }
                    }
                });
            }
            if(!parent) commit('setallURL',URL);
            return URL;
        }
    },
    mutations:{
        setallURL(state,payload){
            state.allURL = payload;
        },
        setDialogNotify(state, payload) {
            state.dialogNotify = payload;
        },
        setMessageNotify(state, payload) {
            state.messageNotify = payload;
        }
    },
    getters: {
        appName(state){
            return state.appName;
        },
        baseURL(state){
            return state.baseURL;
        },
        allURL(state){
            return state.allURL;
        },
        accountURL(state){
            return state.allURL['account'];
        },
        adminURL(state){
            return state.allURL[ADMIN_ROLE.name];
        },
        partnerURL(state){
            return state.allURL[PARTNER_ROLE.name];
        }
    },
}