<template>
  <div class="flex flex-col items-center">
    <div class="w-full border-2 p-8 mb-20">
      <h2>JSON CONTENT:</h2>
      <div class="flex flex-col bg-gray-200 rounded-lg p-2">
        <div v-if="objName" class="p-2">
          <h2>FILENAME:</h2>
          <p>{{ `${objName}.${fileType} ` }}</p>
        </div>
        <div v-else class="flex flex-col bg-gray-200 rounded-lg p-2">
          <h2>FILENAME:</h2>
          <p>{{ `query.${fileType} ` }}</p>
        </div>
        <a
            class="flex cursor-pointer justify-end"
            @click.prevent="downloadJson"
            name="Download JSON"
        >
          <font-awesome-icon
              icon="download"
              class="my-auto mx-1.5 hover:text-orange-primary-primary"
              name="Download JSON"
          /></a>
        <pre ref="jsonContent">{{ formattedJson }}</pre>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'View-Query-JSON',

  data() {
    return {
      objName: 'query',
      fileType: 'json',
    };
  },

  props: {
    formattedJson: {
      type: Object,
      default: () => {}
    },
  },

  methods: {
    downloadJson() {
      if (this.formattedJson) {
        let textToWrite = this.$refs.jsonContent.innerText;

        let blob = new Blob([textToWrite], {
          type: 'text/plain',
        });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);

        if (this.objName) {
          link.download = `${this.objName}.${this.fileType}`;
        } else {
          link.download = `Query.${this.fileType}`;
        }

        link.click();
      } else {
        this.toast('error', 'No query to download');
      }
    },
  },
};
</script>

<style scoped></style>
