<template>
    <div>
      <FormulateForm v-model="values" @submit="onSubmit">
        <div class="items-center justify-between">

          <div v-if="pageInitLoading && preData.selectedModules.cplValues.length > 0" class="text-center mb-16">
            <ui-spinner class="mx-auto"></ui-spinner>
            Generating Contract Summary Details...
          </div>

          <div v-if="!pageInitLoading" class="p-5 mt-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold">Contract Summary Details</div>

            <table>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Contract Period: </div>
                </td>
                <td v-if="preData.contractDuration" class="pb-1">
                  <div class="ml-3">{{preData.contractDuration.label}}</div>
                </td>
              </tr>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Billing Period: </div>
                </td>
                <td class="pb-1">
                  <div v-if="preData.billingPeriod" class="ml-3">{{preData.billingPeriod.label}}</div>
                </td>
              </tr>
              
            </table>
          </div>

          <div v-if="!pageInitLoading && cplValues.length > 0" class="p-5 mt-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold">Core Platform Licences Pricing Summary</div>
              <table class="w-full text-center">
                <thead class="border-b border-line-regular">
                  <th class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-left">  
                    Module
                  </th>
                  <th class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right">
                    Customer Buy Price
                  </th>
                  <th class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right">
                    Your Buy Price
                  </th>
                  <th class="py-1 pb-3 px-4 text-base font-semibold cursor-pointer text-right">
                    Margin
                  </th>
                </thead>
                <tbody>
                  <tr v-for="(modules, index) in cplValues" :key="index">
                    <td :key="index" class="text-main px-4 text-sm border-line-regular text-left">
                      {{modules.standardPricingMaster.name === 'EWD-FT' ? 'EWD' : modules.standardPricingMaster.name}}
                    </td>
                    <td class="text-main px-4 text-base border-line-regular text-right">
                      ${{modules.customerBuyPrice.toFixed(2)}}
                    </td>
                    <td class="text-main px-4 text-base border-line-regular text-right">
                      ${{modules.sourceBuyPricing.toFixed(2)}}
                    </td>
                    <td class="text-main px-4 text-base border-line-regular text-right">
                      ${{modules.margin}}
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>

          <StyledSubmit :backgroundColor="theme.secondaryColor">
              <FormulateInput
                type="submit"
                input-class="btn btn-primary w-full"
                :disabled="submitLoading"
                :style="{backgroundColor: theme.secondaryColor}"
              >
              <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving data...</span>  
              <span v-if="!submitLoading">Submit</span>  
              </FormulateInput>
          </StyledSubmit>

          <button class="btn btn-cancel w-full mb-2" @click.prevent="onPrevious()">
              Back
          </button>

        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {StyledSubmit} from '@/styled-components'

  export default {
    name: "Edit-Summary",
    components: {StyledSubmit}, 
    props: {
      preData: {
        type: Object,
        required: false,
        default: null,
        },
    },
    data() {
    return {
      loading: false,
      pageInitLoading: false,
      submitLoading: false,
      values: {},
      errorMessage:'',
      cplValues: {},
      logmasterTerm: {},
      interChangingData: [],
      buyPricing: []
    }
  },
  async mounted(){
    await this.init()
  },
  methods: {
    async init(){
      this.preData.businessData.entityId = await this.$store.getters[`account/me`].entity._id;

      this.pageInitLoading = true
      const me = this.$store.getters[`account/me`];

      if(this.preData?.steps){
          this.values = this.preData.steps?.pricingDetails || null
          this.interChangingData = this.preData
      }

      let rolesParent = null;
      if (me?.role?.name === 'partner') {
        rolesParent = await this.$store.dispatch('partner/getPartnerByUID', me?.uid)
      
      } else if (me?.role?.name === 'reseller') {
        rolesParent = await this.$store.dispatch('reseller/getResellerByUID', me?.uid)
      }

      if(rolesParent){
        const standardPricing = await this.$store.dispatch(`standardpricing/getStandardPricingByMongoId`,  rolesParent._id);
        
        this.buyPricing = standardPricing.filter((item) =>
          item.isRrp === false
        )
      }
        
      this.cplValues = this.preData.cplPricing.map((cpl) => {

        let rrpComputedPrice = 0

        if(cpl.pricingType === 'Base'){
          if(this.preData.billingPeriod.label === 'Monthly'){
            rrpComputedPrice = Number(cpl.pricing.monthly)
              this.buyPricing.forEach((item) =>{
              if(item.name == cpl.name){
                cpl.sourceBuyPricing = Number(item.pricing.monthly)
              }
            })
          }
          if(this.preData.billingPeriod.label === 'Yearly'){
            rrpComputedPrice = Number(cpl.pricing.monthly) * 12
            this.buyPricing.forEach((item) =>{
              if(item.name == cpl.name){
                cpl.sourceBuyPricing = Number(item.pricing.monthly) * 12
              }
            })
          }
        }

        if(cpl.pricingType === 'Yearly Discount'){
          if(this.preData.billingPeriod.label === 'Monthly'){
            rrpComputedPrice = Number(cpl.pricing.yearly) / 12
            this.buyPricing.forEach((item) =>{
              if(item.name == cpl.name){
                cpl.sourceBuyPricing = Number(item.pricing.yearly) / 12
              }
            })
          }

          if(this.preData.billingPeriod.label === 'Yearly'){
            rrpComputedPrice = Number(cpl.pricing.yearly)
            this.buyPricing.forEach((item) =>{
              if(item.name == cpl.name){
                cpl.sourceBuyPricing = Number(item.pricing.yearly)
              }
            })
          }
        }
        
        return {
            ...cpl,
            rrpPrice: rrpComputedPrice.toFixed(2),
            margin: (rrpComputedPrice - cpl.sourceBuyPricing).toFixed(2)
        }
      })

      this.pageInitLoading = false
    },
    async onSubmit() {
      this.submitLoading = true

      const mergedModulesvalues = [...this.cplValues]
      const formatPayloadModules = mergedModulesvalues.map((modules) => ({
        minimums: modules.numberOfSeats,
        price: {
          monthly: this.preData.billingPeriod.label === 'Monthly' ? Number(modules.rrpPrice) : 0,
          yearly: this.preData.billingPeriod.label === 'Yearly' ? Number(modules.rrpPrice) : 0,
        },
        masterId: modules.masterId,
        ownerMongoId: this.preData.businessData.id,
        ownerRole: 'business',
      }))

      let businessPayload = {
        businessId: this.preData.businessData.id,
        data: this.preData.businessData
      }

      this.$store.dispatch(`business/updateBusiness`, businessPayload).then((business) => {
        if(business && business._id){
          const contractData = {
            billingPeriodId: this.preData.billingPeriod.value,
            businessMongoId: business._id,
            businessModulesDto: formatPayloadModules,
            contractDurationId: this.preData.contractDuration.value,
            contractDurationYears: Number(this.preData.yearCount) || 0,
            partnerMongoId: this.preData.partnerMongoId,
            contractType: this.preData.contractType
          }

          let contractPayload = {
            contractId: this.preData.contractId,
            data: contractData
          }

          this.$store.dispatch('contract/updateContract', contractPayload).then((response) => {
            if(response.success){
              this.submitLoading = false
              this.$emit('closeModal')
              this.$emit('submitSuccess')
              this.$store.commit('setDialogNotify',true)
              this.$store.commit('setMessageNotify',{state:'success',message:'Business successfully updated.'})
            }
          }).catch((error) => {
              this.submitLoading = false
              this.submitLoading = false
              this.errorMessage = error?.message
          })
        }
      }).catch((error) => {
        this.submitLoading = false
        this.errorMessage = error?.message
      });
    },
    onPrevious(){
      const postData = this.interChangingData
      this.$emit('backStep', postData)
    }
  },
   computed: {
    ...mapGetters('theme', {
    theme: 'getColorScheme'
    })
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@keyframes loader-rotate {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	.loader {
		border-right-color: transparent;
		animation: loader-rotate 2s linear infinite;
	}
</style>