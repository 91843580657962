<template>
  <div class="py-8 px-5" style="min-height: calc(100vh - (112px + 2.75rem))">
    <iframe id="logmaster-integration-main-iframe" width="100%" height="100%" :src="external_url">

    </iframe>
  </div>
</template>

<script>

export default {
  name: "Logmaster-Integration",
  data() {
    return {
      external_url: '',
    };
  },
  async mounted() {
    let params = this.$route.params;
    await this.$store.dispatch('logmasterIntegration/evaluateAddInToRunThenInitialize', params);
    await this.detectPageEvent('focus', 'focusLogmasterAddins');
    await this.detectPageEvent('blur', 'blurLogmasterAddins');
  },
  methods: {
    async detectPageEvent(eventName, apiFunctionToDispatch) {
      window.addEventListener(eventName, async () => {
        await this.$store.dispatch(`logmasterIntegration/${apiFunctionToDispatch}`);
      });
    }
  },
  computed: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
