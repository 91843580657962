<template>
  <div class="w-full">
    <div class="flex w-full items-center driver-info-container">
      <div
        v-if="loading"
        class="loading mx-auto w-full h-full translate-y-2/4 flex flex-col items-center"
      >
        <ui-spinner class="mx-auto mb-3"></ui-spinner>
        Loading....
      </div>
      <div v-if="!loading" class="flex-1 flex">
        <div>
          <div
            class="flex w-full flex-col sm:flex-col md:flex-col lg:flex-row flex-wrap"
          >
            <Card
              :cardBackgroundColor="'white'"
              :cardWidth="'100%'"
              class="sm:w-full sm:mr-2 md:mr-5 flex-1 marketplace-card"
              v-for="(marketPlaceItem, index) in marketplaceItems"
              :key="index"
            >
              <template slot="cardContent">
                <div class="flex items-center w-full justify-between p-10">
                  <div class="w-1/3">
                    <div
                      class="h-24 w-24 bg-gray-200 border rounded-full overflow-hidden border-3 border-gray-300 flex"
                    >
                      <img
                        v-if="marketPlaceItem.logo"
                        :src="marketPlaceItem.logo"
                        alt=""
                        class="object-cover"
                        style="max-width: 200px"
                      />
                      <font-awesome-icon
                        v-if="!marketPlaceItem.logo"
                        icon="user"
                        class="text-5xl m-auto text-gray-600"
                      />
                    </div>
                  </div>
                  <div class="w-2/3">
                    <div
                      class="text-xl font-bold mb-3"
                      :style="{ color: theme.secondaryColor }"
                    >
                      {{ marketPlaceItem.name }}
                    </div>
                    <div
                      class="text-base mb-3"
                      :style="{ color: theme.secondaryColor }"
                    >
                      {{ marketPlaceItem.description }}
                    </div>
                    <div
                      class="text-sm flex flex-row-reverse"
                      :style="{ color: theme.secondaryColor }"
                    >
                      <button
                        class="btn btn-primary text-white"
                        @click.prevent="showWarning(marketPlaceItem)"
                        :style="{ backgroundColor: theme.secondaryColor }"
                        v-if="marketPlaceItem.subscribed"
                      >
                        Unsubscribe
                      </button>
                      <button
                        class="btn btn-primary text-white"
                        @click.prevent="subscribe(marketPlaceItem)"
                        :style="{ backgroundColor: theme.secondaryColor }"
                        v-if="!marketPlaceItem.subscribed"
                      >
                        Subscribe
                      </button>
                    </div>
                  </div>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
    </div>
    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content === 'view'">
        <div>
          <div class="loading centered" v-if="submitting">
            <ui-spinner class="mx-auto"></ui-spinner>
          </div>

          <div v-if="!submitting" class="mb-16">
            <div class="text-center mb-10">
              <div class="font-bold text-black-primary text-2xl">
                {{ marketplaceItem.name }}
              </div>
            </div>
            <div>
              <div class="mb-4 w-full border-b pb-1"></div>
              <div class="mb-5">
                <div class="font-bold text-black-primary text-base">
                  To unsubscribe this module you need to contact your partner.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { formatDate } from "@/_helper";
import { Modal, Card } from "@/components/commons";
import { PARTNER_ROLE, RESELLER_ROLE } from "@/constants/usertype";

export default {
  name: "MarketplaceSubscriptionTemp",
  components: { Modal, Card },
  data() {
    return {
      loading: false,
      submitting: false,
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
      subscribed: false,
      marketplaceItem: {},
      marketplaceItems: [],
      me: null,
      subscribedItems: [],
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.$emit("updateLoading", true);
      this.me = this.$store.getters[`account/me`];
      this.me?.business?.modules?.map((module) =>
        this.subscribedItems.push(module.apiTag)
      );
      this.marketplaceItems = await this.getMarketplaceItems();
      this.$emit("updateLoading", false);
    },

    async getMarketplaceItems() {
      let parentId;

      if (this.me?.business?.parentRole === RESELLER_ROLE.name) {
        const reseller = await this.$store.dispatch(
          `reseller/getResellerByUID`,
          this.me?.business?.parentUid
        );
        parentId = reseller?._id;
      } else if (this.me?.business?.parentRole === PARTNER_ROLE.name) {
        const partner = await this.$store.dispatch(
          `partner/getPartnerByUID`,
          this.me?.business?.parentUid
        );
        parentId = partner?._id;
      }

      const items = await this.$store.dispatch(
        `business/getMarketplaceItems`,
        parentId
      );
      return this.formatMarketplaceItems(items);
    },

    async subscribe(marketplaceItem) {
      this.loading = true;
      let seatCount = 1;
      const contractModuleMaster = await this.$store.dispatch(
        `business/getContractModuleMaster`
      );
      const contractModule = contractModuleMaster.find(
        (contract) =>
          contract.apiTag === marketplaceItem.standardPricingMaster.apiTag
      );
      const monthly = marketplaceItem.pricing?.monthly;
      const yearly = marketplaceItem.pricing?.yearly;
      const businessLicenceManagement = await this.$store.dispatch(
        "contract/getBusinessContract",
        { businessId: this.me?.business?._id }
      );
      if (businessLicenceManagement.results[0]?.businessModules[0]?.minimums) {
        seatCount =
          businessLicenceManagement.results[0].businessModules[0].minimums;
      }
      const payload = {
        minimums: seatCount,
        price: {
          monthly: Number(monthly),
          yearly: Number(yearly),
        },
        masterId: contractModule._id,
        ownerMongoId: this.me?.business?._id,
        ownerRole: this.me?.role?.name,
      };

      try {
        await this.$store.dispatch("business/createContractModule", payload);
        await this.init();
        await this.$store.dispatch(`account/updateMenuItems`, this.me);
        this.loading = false;
      } catch (err) {
          let errorMessage = `Something went wrong please try again.`;
          if (err.message) errorMessage = err.message[0];
        this.toast("error", errorMessage);
        this.loading = false;
      }
    },

    async showWarning(marketplaceItem) {
      this.$refs.modal.openModal();
      this.modal.content = "view";
      this.modal.title = "Reminder";
      this.marketplaceItem = marketplaceItem;
    },

    momentFormat(date, isShort = false) {
      if (date) {
        if (isShort) {
          return date ? moment(date).format("MMM D, YYYY") : "-";
        } else {
          return date ? moment(date).format("MMMM D, YYYY") : "-";
        }
      }
    },
    formatMarketplaceItems(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          if (item.standardPricingMaster.apiTag != "ewd-ft") {
            const marketplaceItem = {
              id: item["_id"],
              name: item["standardPricingMaster"]["name"] || "",
              createdAt: formatDate(item["createdAt"]) || "",
              isActive: item["isActive"] ? "Active" : "Inactive" || "",
              isRrp: item["isRrp"] ? "RRP" : "Non RRP" || "",
              ownerMongoId: item["ownerMongoId"] || "",
              ownerRole: item["ownerRole"] || "",
              type: item["type"] || "",
              logo: item["standardPricingMaster"]["logo"] || "",
              pricing: item["pricing"] || {},
              standardPricingMaster: item["standardPricingMaster"] || {},
              description: item["standardPricingMaster"]["description"]?.slice(
                0,
                70
              ),
              subscribed: this.subscribedItems.includes(
                item.standardPricingMaster.apiTag
              ),
            };
            nItems.push(marketplaceItem);
          }
        });
      }
      return nItems;
    },

    closeModal() {
      this.$refs.modal.closeModal();
    },

    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.marketplace-card {
  flex: 1 1 32%;
  list-style: none;
}
</style>
