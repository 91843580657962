<template>
  <div>
    <div
      v-if="!selectedDriver"
      class="flex flex-col w-full h-screen overflow-y-auto p-2"
    >
      <div class="flex flex-row justify-between">
        <button class="btn btn-secondary w-20 mb-4" @click.prevent="unToggle">
          Back
        </button>
        <button class="btn btn-primary" @click.prevent="toggleEditContent">
          Edit Accreditation
        </button>
      </div>
      <div class="p-3 m-0 flex-col rounded shadow bg-white">
        <Tabs clickEvent @click="fetchTab">
          <Tab title="Accredited Drivers">
            <Table
              :isLoading="isLoading"
              :tableHeader="tableHeader"
              :paginationSettings="paginationSettings"
              :data="accreditedTableData"
              @onPageChange="onPageChange($event)"
            >
              <template slot="tableFilters">
                <div class="flex flex-row mb-1 sm:mb-0">
                  <div class="relative">
                    <select
                      v-model="filter.limit"
                      @change="filterChange"
                      class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="50">50</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <font-awesome-icon
                        icon="caret-down"
                        class="text-gray-400"
                      />
                    </div>
                  </div>
                  <div class="relative">
                    <span
                      class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                    >
                      <font-awesome-icon icon="search" class="text-gray-400" />
                    </span>
                    <input
                      placeholder="Search"
                      class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                      v-model="filter.search"
                      @keydown="handleSearchKeyDown"
                    />
                  </div>
                </div>
              </template>

              <template #default="{ data }">
                <td class="px-5 py-2">
                  <div
                    class="text-black-primary whitespace-no-wrap text-lg flex"
                  >
                    <a
                      class="flex cursor-pointer"
                      @click.prevent="viewDriverDetails(data)"
                      ><font-awesome-icon
                        icon="clipboard"
                        class="my-auto mx-1.5 hover:text-orange-primary-primary"
                    /></a>
                  </div>
                </td>
              </template>

              <template slot="callToActionTop"> </template>
            </Table>
          </Tab>

          <Tab title="Pending Drivers">
            <Table
              :isLoading="isLoading"
              :tableHeader="tableHeader"
              :paginationSettings="paginationSettings"
              :data="accreditedTableData"
              @onPageChange="onPageChange($event)"
            >
              <template slot="tableFilters">
                <div class="flex flex-row mb-1 sm:mb-0">
                  <div class="relative">
                    <select
                      v-model="filter.limit"
                      @change="filterChange"
                      class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="50">50</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <font-awesome-icon
                        icon="caret-down"
                        class="text-gray-400"
                      />
                    </div>
                  </div>
                  <div class="relative">
                    <span
                      class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                    >
                      <font-awesome-icon icon="search" class="text-gray-400" />
                    </span>
                    <input
                      placeholder="Search"
                      class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                      v-model="filter.search"
                      @keydown="handleSearchKeyDown"
                    />
                  </div>
                </div>
              </template>

              <template #default="{ data }">
                <!-- <td class="px-5 py-2">
                  <div
                    class="text-black-primary whitespace-no-wrap text-lg flex"
                  >
                    <input
                      type="checkbox"
                      @change="check($event, data)"
                      :checked="isChecked(data)"
                    />
                  </div>
                </td> -->
                <td class="px-5 py-2">
                  <div
                    class="text-black-primary whitespace-no-wrap text-lg flex"
                  >
                    <a
                      class="flex cursor-pointer"
                      @click.prevent="viewDriverDetails(data)"
                      ><font-awesome-icon
                        icon="eye"
                        class="my-auto mx-1.5 hover:text-orange-primary-primary"
                    /></a>

                    <a
                      v-if="isRecordKeeper || isRecordKeeperTableLevel"
                      class="flex cursor-pointer"
                      @click.prevent="toggleReviewDriver(data)"
                      ><font-awesome-icon
                        icon="clipboard"
                        class="my-auto mx-1.5 hover:text-orange-primary-primary"
                    /></a>
                  </div>
                </td>
              </template>

              <template slot="callToActionTop">
                <div class="flex">
                  <div v-if="hasDriversToAssociate" class="pr-2">
                    <button
                      class="btn btn-primary"
                      @click.prevent="toggleAddDriver()"
                    >
                      Add Driver
                    </button>
                  </div>
                </div>
              </template>
            </Table>
          </Tab>

          <Tab title="Non-Accredited Drivers">
            <Table
              :isLoading="isLoading"
              :tableHeader="tableHeader"
              :paginationSettings="paginationSettings"
              :data="tableData"
              @onPageChange="onPageChange($event)"
            >
              <template slot="tableFilters">
                <div class="flex flex-row mb-1 sm:mb-0">
                  <div class="relative">
                    <select
                      v-model="filter.limit"
                      @change="filterChange"
                      class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      <option :value="10">10</option>
                      <option :value="20">20</option>
                      <option :value="50">50</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <font-awesome-icon
                        icon="caret-down"
                        class="text-gray-400"
                      />
                    </div>
                  </div>
                  <div class="relative">
                    <span
                      class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                    >
                      <font-awesome-icon icon="search" class="text-gray-400" />
                    </span>
                    <input
                      placeholder="Search"
                      class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                      v-model="filter.search"
                      @keydown="handleSearchKeyDown"
                    />
                  </div>
                </div>
              </template>

              <template #default="{ data }">
                <td class="px-5 py-2">
                  <div
                    class="text-black-primary whitespace-no-wrap text-lg flex"
                  >
                    <input
                      type="checkbox"
                      @change="check($event, data)"
                      :checked="isChecked(data)"
                    />
                  </div>
                </td>
              </template>

              <template slot="callToActionTop">
                <div class="flex">
                  <div v-if="hasDriversToAssociate" class="pr-2">
                    <button
                      class="btn btn-primary"
                      @click.prevent="toggleAddDriver()"
                      :style="{backgroundColor: theme.secondaryColor}"
                    >
                      Add Driver
                    </button>
                  </div>
                </div>
              </template>
            </Table>
          </Tab>
        </Tabs>
      </div>
    </div>
    <div v-else>
      <DriverCertContent
        :driverInfo="selectedDriver"
        :certDetails="certDetails"
        @goBack="deSelectDriver"
        @refetch="refetch"
      />
    </div>

    <Modal
      ref="modal"
      :title="modalTitle"
      :size="modalSize"
      :height="modalHeight"
    >
      <div v-if="modalContent == 'associateDriver'">
        <div v-if="!isLoading">
          <FormulateForm @submit="associateDrivers">
            <div class="items-center justify-between">
              <FormulateInput
                type="date"
                name="inductionDate"
                label="Induction Date"
                validation="required"
              />

              <FormulateInput
                type="submit"
                label="Save Changes"
                input-class="btn btn-primary w-full"
              />
            </div>
          </FormulateForm>
        </div>
        <div v-else class="p-2 w-full text-center text-lg">
          <font-awesome-icon
            icon="circle-notch"
            spin
            size="2x"
            class="text-gray-400"
          />
        </div>
      </div>

      <div v-if="modalContent == 'reviewDriver'">
        <ReviewDriverDocument
          :driver="driverToReview"
          :cert="certDetails"
          @approveDriver="reviewDriver"
          @rejectDriver="reviewDriver"
        />
      </div>

      <div class="flex-col" v-if="modalContent == 'editCertContent'">
        <FormulateInput
          :value="selectedTemplate"
          name="selectedTemplate"
          label="Template"
          type="template-select"
          placeholder="Select a template"
          style="margin-top: 0px"
          :getOptionKey="(option) => option.id"
          :getOptionLabel="(option) => option.name"
          @selected="selectedTemplate = $event"
          :filterable="false"
          :clearable="true"
        />

        <div class="pt-20">
          <button
            class="btn btn-primary w-full"
            type="submit"
            @click.prevent="editTemplate"
          >
            Submit
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Tabs from "@/components/commons/tab/Tabs";
import Tab from "@/components/commons/tab/Tab";
import { Table, Modal } from "@/components/commons";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import _ from "lodash";
import { formatPaginationSettings, formatDate } from "@/_helper";
import DriverCertContent from "./view-driver-cert-content";
import ReviewDriverDocument from "./review-driver-document";
import { mapGetters } from "vuex";

export default {
  name: "manage-accreditation-content",
  components: {
    DriverCertContent,
    Table,
    Tabs,
    Tab,
    Modal,
    ReviewDriverDocument,
  },
  props: {
    certDetails: {
      type: Object,
      default: function () {
        return {};
      },
      required: true,
    },

    isRecordKeeper: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  async mounted() {
    this.loading = true;

    const templateId = this.certDetails?.certDetails?.documentTemplate;

    if (templateId) {
      const templateDoc = await this.$store.dispatch(
        "accreditation/fetchOneTemplate",
        { templateId }
      );

      this.selectedTemplate = {
        name: templateDoc.data.data.name,
        id: templateDoc.data.data._id,
      };
    }

    //IF RECIEVE NULL REFETCH ROLES IN THIS LEVEL
    if (this.isRecordKeeper == null) {
      const me = this.$store.getters[`account/me`];
      let securityRoles = me?.securityRoles;

      if (securityRoles && securityRoles.length > 0) {
        const securityAccessObj = await this.setSecurityAccess(securityRoles);
        this.isRecordKeeperTableLevel = securityAccessObj.inductDrivers;
      }
    }

    await this.init();
  },

  computed: {
    tableData() {
      if (this.accreditationData) {
        return this.parseAccreditaionData(this.accreditationData);
      } else {
        return [];
      }
    },

    accreditedTableData() {
      if (this.accreditationData) {
        return this.parseAccreditedDrivers(this.accreditationData);
      } else {
        return [];
      }
    },

    driversToAssociate: {
      get() {
        return this.$store.state.accreditation.driversToAssociate;
      },
      set(newVal) {
        this.$store.state.accreditation.driversToAssociate = newVal;
      },
    },
    hasDriversToAssociate() {
      return this.$store.state.accreditation.driversToAssociate?.length > 0;
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
  data() {
    return {
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      certContent: false,
      tableHeader: [
        "Full Name",
        "Email Address",
        "Licence",
        "Date Created",
        "Status",
      ],
      accreditedTableHeader: ["Certificates", "Type"],

      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },

      modalTitle: "",
      modalSize: "2xl",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalContent: "",

      accreditationData: false,
      currentTab: "Accredited Drivers",
      isLoading: false,
      accreditedDrivers: [],
      selectedDriver: false,
      driverToReview: false,

      isRecordKeeperTableLevel: false,

      editModel: {},

      selectedTemplate: false,
    };
  },
  methods: {
    async associateDrivers(data) {
      try {
        this.isLoading = true;
        const driverDetails =
          this.$store.state.accreditation.driversToAssociate;

        const driverIds = driverDetails.map((driver) => driver.driverId);

        const payloadData = {
          driverIds,
          inductionDate: data.inductionDate,
        };

        await this.$store.dispatch("accreditation/assignDriver", {
          data: payloadData,
          certId: this.certDetails.id,
        });

        this.currentTab = "Non-Accredited Drivers";
        this.filter.search = "";

        this.driversToAssociate = [];
        this.isLoading = false;
        this.toast("success", "Drivers successfully associated");
        this.$emit("reToggle", this.certDetails.id);
        this.$store.state.accreditation.driversToAssociate = [];
      } catch (e) {
        this.isLoading = false;
        this.toast("error", "Something went wrong");
        throw e;
      }
    },

    async editTemplate() {
      this.$refs.modal.closeModal();
      try {
        this.isLoading = true;
        const { id } = this.certDetails;

         if (!_.isEmpty(this.selectedTemplate?.id)) {
          const payload = {
            certId: id,
            templateId: this.selectedTemplate.id,
          };
          await this.$store.dispatch("accreditation/editCertTemplate", payload);
        } else {
          await this.$store.dispatch("accreditation/removeTemplate", {
            certId: id,
          });
        }

        this.currentTab = "Non-Accredited Drivers";
        this.filter.search = "";
        this.driversToAssociate = [];
        this.isLoading = false;
        this.toast("success", "Template has been updated");

        this.$refs.modal.closeModal();
        this.$emit("reToggle", this.certDetails.id);
        this.isLoading = false;
      } catch (e) {
        this.toast("error", e.message);
        throw new Error(e);
      }
    },

    setSecurityAccess(securityRoles) {
      let initialInductDrivers = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.drivers.inductDrivers.value) {
          initialInductDrivers = securityConfig.drivers.inductDrivers.value;
        }
      });
      return {
        inductDrivers: initialInductDrivers,
      };
    },

    closeModal() {
      this.$refs.modal.closeModal();
    },

    async reviewDriver(driverDetails, cert, isApproved) {
      try {
        await this.$store.dispatch("accreditation/reviewDriver", {
          driverId: driverDetails,
          id: cert,
          isApproved,
        });
        if (isApproved) {
          this.toast("success", "Driver has been approved");
        } else {
          this.toast("success", "Driver has been rejected");
        }
        this.$emit("reToggle", this.certDetails.id);
      } catch (e) {
        this.toast("error", "Something went wrong");
        throw e;
      }
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    deSelectDriver() {
      this.selectedDriver = false;
      this.driversToAssociate = [];
    },
    viewDriverDetails(data) {
      this.selectedDriver = data;
    },
    refetch(data) {
      this.selectedDriver = false;
      this.selectedDriver = data;
    },
    toggleReviewDriver(data) {
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
      this.modalSize = "2xl";
      this.driverToReview = data;

      this.selectedCert = data;
      this.$refs.modal.openModal();
      this.modalContent = "reviewDriver";
      this.modalTitle = "Review Driver Documents";
    },
    toggleEditContent(data) {
      this.modalHeight = "200vh";
      this.modalSize = "2xl";
      this.selectedCert = data;

      this.$refs.modal.openModal();
      this.modalContent = "editCertContent";
      this.modalTitle = "Edit Certificate Content";
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    unToggle() {
      this.$emit("unToggle");
      this.driversToAssociate = [];
    },
    isChecked(driverData) {
      const indexOfSection = this.driversToAssociate.findIndex((object) => {
        return object.driverId == driverData.driverId;
      });
      return indexOfSection > -1;
    },
    async fetchTab(data) {
      this.currentTab = data;
      this.init();
    },

    async filterChange() {
      await this.init();
    },

    async init(paged = 1) {
      this.isLoading = true;

      if (this.currentTab === "Non-Accredited Drivers") {
        const me = this.$store.getters[`account/me`];

        const certDetails = this.certDetails.certDetails;

        let associatedIds = [];

        certDetails?.drivers?.map((data) => {
          associatedIds.push(data.id);
        });

        certDetails?.pendingDrivers?.map((data) => {
          associatedIds.push(data.id);
        });

        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {
            driverId: { $nin: [...associatedIds] },
            "driver.webProfiles": { $size: 1 },
          },
        };

        const driverContract = await this.$store.dispatch(
          `business/getContractByBusiness`,
          {
            uid: me.business._id,
            query,
          }
        );

        if (driverContract?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            driverContract.metadata
          );
        }

        if (driverContract?.results) {
          this.accreditationData = this.formatItem(
            _.orderBy(driverContract.results, ["createdAt"], ["desc"])
          );
        }

        this.isLoading = false;
      } else if (this.currentTab === "Pending Drivers") {
        this.isLoading = true;
        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {},
        };

        const associatedCerts = await this.$store.dispatch(
          `accreditation/fetchDriversProfile`,
          { certId: this.certDetails.id, query, isPending: true }
        );

        console.log("associated:", associatedCerts);

        const driverProfiles = associatedCerts?.data?.data?.targetUser;

        console.log("driverProfiles:", driverProfiles);

        if (!_.isEmpty(driverProfiles)) {
          this.accreditationData = this.formatAssociatedDriver(
            _.orderBy(driverProfiles, ["createdAt"], ["desc"])
          );
        } else {
          this.accreditationData = [];
        }

        if (associatedCerts?.data?.data?.resultsMeta) {
          this.paginationSettings = formatPaginationSettings(
            associatedCerts.data.data.resultsMeta
          );
        }

        this.isLoading = false;
      } else if (this.currentTab === "Accredited Drivers") {
        this.isLoading = true;
        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {},
        };

        const associatedCerts = await this.$store.dispatch(
          `accreditation/fetchDriversProfile`,
          { certId: this.certDetails.id, query, isPending: false }
        );

        const driverProfiles = associatedCerts?.data?.data?.targetUser;

        if (!_.isEmpty(driverProfiles)) {
          this.accreditationData = this.formatAssociatedDriver(
            _.orderBy(driverProfiles, ["createdAt"], ["desc"])
          );
        } else {
          this.accreditationData = [];
        }

        if (associatedCerts?.data?.data?.resultsMeta) {
          this.paginationSettings = formatPaginationSettings(
            associatedCerts.data.data.resultsMeta
          );
        }

        this.isLoading = false;
      }
    },
    toggleAddDriver() {
      this.$refs.modal.openModal();
      this.modalContent = "associateDriver";
      this.modalTitle = "Add Driver";
      this.modalSize = "2xl";
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
    },
    parseAccreditaionData(data) {
      return data.map((driverData) => {
        return [
          {
            driverId: driverData.driverId,
            id: driverData._id,
            name: driverData.name,
            itemType: "name",
            hasImage: true,
            status: driverData.status,
          },
          {
            driverId: driverData.driverId,
            id: driverData.id,
            name: driverData.email,
            itemType: "string",
          },
          {
            driverId: driverData.driverId,
            id: driverData.id,
            name: driverData.license,
            itemType: "string",
          },
          {
            driverId: driverData.driverId,
            id: driverData.id,
            name: driverData.created,
            itemType: "string",
          },
          {
            driverId: driverData.driverId,
            id: driverData.id,
            name: driverData.status,
            itemType: "string",
          },
        ];
      });
    },

    parseAccreditedDrivers(data) {
      return data.map((driverData) => {
        return [
          {
            driverId: driverData.driverId,
            id: driverData.id,
            name: driverData.name,
            itemType: "name",
            hasImage: true,
            status: driverData.status,
            docData: driverData,
            certData: driverData,
          },
          {
            driverId: driverData.driverId,
            id: driverData.id,
            name: driverData.email,
            itemType: "string",
          },
          {
            driverId: driverData.driverId,
            id: driverData.id,
            name: driverData.license,
            itemType: "string",
          },
          {
            driverId: driverData.driverId,
            id: driverData.id,
            name: driverData.created,
            itemType: "string",
          },
          {
            driverId: driverData.driverId,
            id: driverData.id,
            name: driverData.status,
            itemType: "string",
          },
        ];
      });
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            id: item?._id,
            driverId: item?.driverId,
            name: item?.driver?.driverName || "",
            email: item?.driver?.emailAddress || "",
            license: item?.driver?.driversLicenseNumber || "",
            phone: item?.driver?.supportPhone || "",
            created: formatDate(item?.createdAt) || "",
            status: item?.status?.toUpperCase() || "",
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },

    formatAssociatedDriver(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            id: item?._id,
            driverId: item?._id,
            name: item?.driverName || "",
            email: item?.emailAddress || "",
            license: item?.driversLicenseNumber || "",
            created: formatDate(item?.createdAt) || "",
            status: item?.isActive ? "Active" : "Inactive" || "",
            docData: item,
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    check(data, driverData) {
      if (data.target.checked) {
        this.driversToAssociate.push(driverData);
      } else {
        const indexOfSection = this.driversToAssociate.findIndex((object) => {
          return object.driverId == driverData.driverId;
        });
        this.driversToAssociate.splice(indexOfSection, 1);
      }
    },
    async onPageChange(event) {
      await this.init(event.page);
    },
  },
};
</script>
<style scoped></style>
