export default [
        {
          type: 'text',
          name: 'vehiclePlate',
          label: 'Plate Number',
          validation: 'required|alphanumeric'
        },
        {
          type: 'date',
          name: 'registrationExpiry',
          label: 'Registration Expiry',
          validation: '^required|date'
        },
        {
          type: 'number',
          name: 'gvm',
          label: 'GVM (Tonnes)',
          step:"0.01",
          min:"0",
          validation: 'required'
        },
        {
          component: 'div',
          class: 'custom-options',
          children: [
            {
              type: 'radio',
              name: 'driveVehicle',
              label: 'Drive Vehicle',
              options: {
                'true': 'Yes',
                'false': 'No',
              },
              value: 'true',
              validation: 'required',
            },
          ]
        },
        {
          component: 'div',
          class: 'custom-options',
          children: [
            {
              type: 'radio',
              name: 'concreteAgitator',
              label: 'Concrete Agitator',
              options: {
                'true': 'Yes',
                'false': 'No',
              },
              value: 'false',
              validation: 'required',
            },
          ]
        },
        {
          component: 'div',
          class: 'custom-options',
          children: [
            {
              type: 'radio',
              name: 'livestockVehicle',
              label: 'Livestock Vehicle',
              options: {
                'true': 'Yes',
                'false': 'No',
              },
              value: 'false',
              validation: 'required',
            },
          ]
        },
        {
          type: 'text',
          name: 'vin',
          label: 'VIN (Optional)',
        },
        {
          type: 'text',
          name: 'fleetId',
          label: 'Fleet ID (Optional)',
        },
        /* {
          component: 'div',
          class: 'custom-options',
          children: [
            {
              type: 'radio',
              name: 'vehicleClass',
              label: 'Vehicle Class',
              options: {
                'LV': 'Light Vehicle',
                'MV': 'Medium Vehicle',
                'HV': 'Heavy Vehicle'
              },
              value: 'LV',
              validation: 'required',
            },
          ]
        },
        {
          name: 'vehicleTypeLV',
          type: 'select',
          label: 'Vehicle Type LV',
          options: {
            '0': ' - Select - ',
            '1': 'Car',
            '2': 'LAM Motorbike',
            '3': 'Light Rigid Bus',
            '4': 'Light Rigid Tractor',
            '5': 'Light Rigid Truck',
            '6': 'Moped',
            '7': 'Motorbike',
           
          },
          validation: 'required'
        },
        {
          name: 'vehicleTypeMV',
          type: 'select',
          label: 'Vehicle Type MV',
          options: {
            '0': ' - Select - ',
            '1': 'Medium Rigid Bus',
            '2': 'Medium Rigid Tractor',
            '3': 'Medium Rigid Truck',
          },
          validation: 'required'
        },
        {
          name: 'vehicleTypeHV',
          type: 'select',
          label: 'Vehicle Type HV',
          options: {
            '0': ' - Select - ',
            '1': 'Agriculture Vehicle',
            '2': 'Bus',
            '3': 'Prime Mover',
            '4': 'Rigid Truck',
            '5': 'Special Purpose Vehicle (SPV)',
            '6': 'Tow Truck'
          },
          validation: 'required'
        },
        {
          type: 'text',
          name: 'alias',
          label: 'Alias (Name)',
        },
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'checkbox',
              name: 'frontCouplings',
              label: 'Front Couplings',
              options: {
                'mm50Ball': '50mm Ball',
                'mm127Ball': '127mm Ball',
                'mm50KingPin': '50mm King Pin',
                'mm90KingPin': '90mm King Pin',
                'mm50PinType': '50mm Pin-Type',
                'hook': 'Hook',
              },
            },
          ]
        },
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'checkbox',
              name: 'rearCouplings',
              label: 'Rear Couplings',
              options: {
                'mm50Ball': '50mm Ball',
                'mm127Ball': '127mm Ball',
                'mm50PinType': '50mm Pin-Type',
                'hook': 'Hook',
                'fifthWheel50mm': 'Fifth Wheel 50mm',
                'fifthWheel90mm': 'Fifth Wheel 90mm',
              },
            },
          ]
        },
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'checkbox',
              name: 'powerCouplings',
              label: 'Power Couplings',
              options: {
                'pin7as4735': '7Pin AS4735',
                'pin7Australian': '7Pin Australian',
                'v24AbsEbs' : '24V ABS/EBS',
                'v12AbsEbs' : '12V ABS/EBS',
                'pin7Flat' : '7Pin Flat',
                'pin12Flat' : '12Pin Flat'
              },
            },
          ]
        },
        {
          component: 'div',
          class: 'custom-options',
          children: [
            {
              type: 'radio',
              name: 'brakeFoundation',
              label: 'Brake Foundation',
              options: {
                'disc': 'Disc',
                'drum': 'Drum',
              },
              value: 'disc',
            },
          ]
        },
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'radio',
              name: 'brakeSystemType',
              label: 'Brake System Type',
              options: {
                'abs': 'ABS',
                'absUnpowered': 'ABS-Unpowered',
                'absWithESC': 'ABS with ESC',
                'basic': 'Basic',
                'ebsTruckNoESC': 'EBS - Truck (No ESC)',
                'ebsWithESC': 'EBS with ESC',
                'lsv': 'LSV',
                'tEBS': 'T-EBS',
                'tEBSUnpowered': 'T-EBS-Unpowered',
                'tEBSWithRollStability ': 'T-EBS with Roll Stability ',
              },
              value: 'abs',
            },
          ]
        },
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'radio',
              name: 'supplyVoltageToTrailer',
              label: 'Supply Voltage to Trailer',
              options: {
                'v12': '12V',
                'v24': '24V',
                'Multi Voltage': 'Multi Voltage'
              },
              value: 'pin7as4735'
            },
          ]
        },
        {
          type: 'textarea',
          name: 'lAETBSysSupplier',
          label: 'LSV, ABS, EBS, T-EBS Brake System Supplier',
        },
        {
          type: 'textarea',
          name: 'tSTBCSRefNumber',
          label: 'Trailer SARN, Trailer Brake Control System Reference Number',
        },
        {
          type: 'textarea',
          name: 'comments',
          label: 'Comments',
        } */
  ]