<template>
  <div>
    <div v-for="(ewd, index) in ewdContent" :key="index">
      <div class="bg-gray-200 rounded-lg p-12 my-4">
        <div class="flex justify-end">
          <font-awesome-icon
            icon="times"
            class="my-auto text-xl mx-1.5 hover:text-orange-primary cursor-pointer"
            @click.prevent="deleteEWD(index, ewd.generatedId)"
          />
        </div>
        <div v-if="index !== 0">
          <div class="mb-5">
            <div class="text-xs">Rest Period</div>
            <div
              class="font-bold text-lg"
              :class="checkRestPeriodStatus(index)"
            >
              {{ calculateRestPeriod(index) }}
            </div>
          </div>
        </div>

        <FormulateInput
          type="datetime-local"
          name="startTime"
          label="Start Time(Work)"
          validation="required"
          v-model="ewdContent[index].work.startTime"
          @input="calculateWorkDuration(index)"
        />

        <div class="mb-5">
          <div class="text-xs">Work Duration</div>
          <div class="font-bold text-lg">
            {{ calculateWorkDuration(index) }}
          </div>
        </div>

        <FormulateInput
          type="datetime-local"
          name="endTime"
          label="Start Time(Rest)"
          validation="required"
          v-model="ewdContent[index].rest.startTime"
          @input="calculateWorkDuration(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
export default {
  name: 'field-container',
  props: {
    rulesContent: {
      type: Array,
      default: () => {
        return {};
      },
    },
  },

  mounted() {
    this.ewdContent = this.rulesContent;
  },

  computed: {
    ewd: {
      get() {
        return this.$store.state.jsonbuilder.ewd;
      },
      set(newVal) {
        this.$store.state.jsonbuilder.ewd = newVal;
      },
    },
  },

  data() {
    return {
      ewdContent: [],
    };
  },

  methods: {
    onSubmit(data) {
      console.log('formContent:', data);
    },

    clearAllContent() {
      this.ewdContent = [];
    },

    fileSelected() {
      this.ewdContent = this.ewd;
    },

    deleteEWD(index) {
      this.ewdContent.splice(index, 1);
    },

    calculateWorkDuration(index) {
      const a = moment(this.ewdContent[index].work.startTime);
      const b = moment(this.ewdContent[index].rest.startTime);

      const rawRest = `${a._i}`.split('T');
      const rawWork = `${b._i}`.split('T');

      const workDiff = moment(
        `${rawWork[0]} ${rawWork[1]}`,
        'YYYY-MM-DD HH:mm',
      );

      const restDiff = moment(
        `${rawRest[0]} ${rawRest[1]}`,
        'YYYY-MM-DD HH:mm',
      );

      const hours = workDiff.diff(restDiff, 'hours');

      const mins = moment
        .utc(
          moment(`${rawWork[0]} ${rawWork[1]}`, 'YYYY-MM-DD HH:mm').diff(
            moment(`${rawRest[0]} ${rawRest[1]}`, 'YYYY-MM-DD HH:mm'),
          ),
        )
        .format('mm');

      return hours && mins ? `${hours}:${mins}` : '';
    },

    calculateRestPeriod(index) {
      const a = moment(this.ewdContent[index - 1].rest.startTime);
      const b = moment(this.ewdContent[index].work.startTime);

      const rawRest = `${a._i}`.split('T');
      const rawWork = `${b._i}`.split('T');

      const restTime = moment(
        `${rawRest[0]} ${rawRest[1]}`,
        'YYYY-MM-DD HH:mm',
      );
      const beforeTime = moment(`${rawRest[0]} 21:59`, 'YYYY-MM-DD HH:mm');

      const afterTime = moment(`${rawWork[0]} 7:59`, 'YYYY-MM-DD HH:mm');

      const isNR = restTime.isBetween(beforeTime, afterTime);

      const hours = b.diff(a, 'hours');

      const mins = moment
        .utc(moment(b, 'HH:mm:ss').diff(moment(a, 'HH:mm:ss')))
        .format('mm');

      let retValue = hours || mins ? `${hours}:${mins}` : '';

      if (isNR) {
        retValue += ' NR';
      }

      return retValue === 'NaN:Invalid date' ? '' : retValue;
    },

    checkRestPeriodStatus(index) {
      const a = moment(this.ewdContent[index - 1].rest.startTime);
      const b = moment(this.ewdContent[index].work.startTime);

      let timeColor;

      const hours = b.diff(a, 'hours');

      const minHours = Math.floor(hours * 60);

      const mins = moment
        .utc(moment(b, 'HH:mm:ss').diff(moment(a, 'HH:mm:ss')))
        .format('mm');

      const parsedMins = parseInt(mins, 10);

      const finalTime = parseInt(minHours + parsedMins);

      if (finalTime <= 15) {
        timeColor = 'text-red-500';
      } else if (finalTime > 15 && finalTime < 300) {
        //UNKNOWN COLOR
        timeColor = 'text-yellow-500';
      } else if (finalTime >= 300 && finalTime < 360) {
        timeColor = 'text-yellow-500';
      } else if (finalTime >= 360 && finalTime < 420) {
        timeColor = 'text-pink-500';
      } else if (finalTime >= 420 && finalTime <= 1440) {
        timeColor = 'text-green-500';
      } else if (finalTime > 1440) {
        timeColor = 'text-purple-500';
      }

      return timeColor;
    },
  },
};
</script>

<style scoped></style>
