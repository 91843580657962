<template>
  <div class="mb-16">
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!isLoading">
      <div v-if="isDriver" class="flex flex-row justify-end">
        <div class="px-2">
          <button
            class="btn btn-primary"
            @click.prevent="toggleDelinkCert(certContent._id)"
            :style="{backgroundColor: theme.secondaryColor}"
          >
            Delink Certificate
          </button>
        </div>
      </div>

      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Certificate Details</div>
      </div>

      <div class="text-center mb-10">
        <div class="font-bold text-black-primary text-2xl">
          {{ certContent.certNumber }}
        </div>
        <div
          class="font-bold text-xs"
          :class="[certContent.isActive ? 'text-green-500' : 'text-red-500']"
        >
          {{ certContent.isActive ? "ACTIVE" : "INACTIVE" }}
        </div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ certContent.type }}
        </div>
        <div class="text-xs">Type</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ formattedDate(certContent.createdAt) }}
        </div>
        <div class="text-xs">Date of Creation</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ formattedDate(certContent.certExpiryDate) }}
        </div>
        <div class="text-xs">Expiry Date</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ formattedDate(certContent.updatedAt) }}
        </div>
        <div class="text-xs">Last Updated</div>
      </div>

      <div class="mb-5">
        <div
          class="font-bold"
          :class="[
            certContent.isMedicalCertRequired
              ? 'text-green-500'
              : 'text-red-500',
          ]"
        >
          {{ certContent.isMedicalCertRequired ? "REQUIRED" : "OPTIONAL" }}
        </div>
        <div class="text-xs">Medical Certificate Requirement</div>
      </div>

      <div class="mb-5">
        <div
          class="font-bold"
          :class="[
            certContent.isSignatureApprovalRequired
              ? 'text-green-500'
              : 'text-red-500',
          ]"
        >
          {{
            certContent.isSignatureApprovalRequired ? "REQUIRED" : "OPTIONAL"
          }}
        </div>
        <div class="text-xs">Signature Approval Requirement</div>
      </div>

      <div class="mb-5">
        <div
          class="font-bold"
          :class="[
            certContent.isTrainingCertRequired
              ? 'text-green-500'
              : 'text-red-500',
          ]"
        >
          {{ certContent.isTrainingCertRequired ? "REQUIRED" : "OPTIONAL" }}
        </div>
        <div class="text-xs">Training Certificate Requirement</div>
      </div>

      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Admin Details</div>
      </div>

      <div class="text-center mb-10">
        <div class="font-bold text-black-primary text-2xl">
          {{ adminDetails.username }}
        </div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ adminDetails.email }}
        </div>
        <div class="text-xs">Email</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ formattedDate(adminDetails.createdAt) }}
        </div>
        <div class="text-xs">Registered</div>
      </div>
    </div>
  </div>
</template>

<script>
// import { DEFAULT_MODAL_HEIGHT } from '@/_helper/constants';
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "cert-detail-modal",
  components: {
    // Modal,
  },

  props: {
    content: {
      type: Object,
      required: true,
    },

    isDriver: {
      type: Boolean,
      default: false,
    },
  },

  destroyed() {
    this.$emit("closedModal");
  },

  async mounted() {
    this.isLoading = true;
    this.certContent = this.content.document;

    const adminDetails = await this.$store.dispatch(`account/getUser`, {
      id: this.certContent.adminUid,
      by: "uid",
    });

    this.adminDetails = adminDetails.data;
    this.isLoading = false;
  },

  data() {
    return {
      certContent: false,
      adminDetails: false,
      isLoading: false,
    };
  },

  methods: {
    formattedDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },

    toggleDelinkCert(certId) {
      this.$emit("toggleDelinkCert", certId);
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
