<template>
  <div class="-mt-8 mb-8">
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="driversData"
      @onPageChange="onPageChange($event)"
    >
      <template #default="{ data }">
        <td class="px-5 py-2">
          <FormulateInput
            class="mt-3 w-full"
            type="general-select"
            element-class="w-56"
            placeholder="Delink driver"
            :options="depots"
            :getOptionKey="(option) => option._id"
            :getOptionLabel="(option) => option.name"
            validation="required"
            :clearable="false"
            @input="selectSite($event, data)"
          >
            <template slot="option" slot-scope="option">
              <div class="text-sm">
                <h3 class="m-0 font-semibold">{{ option.value }}</h3>
                <em class="text-xs">{{ option.label }}</em>
              </div>
            </template>
          </FormulateInput>
        </td>
      </template>
    </Table>

    <FormulateForm v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Next"
          input-class="btn btn-primary w-full"
          :disabled="isLoading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { orderBy, findIndex } from "lodash";
import { mapGetters } from "vuex";
import { Table } from "@/components/commons";
import { formatDate, formatPaginationSettings } from "@/_helper";

export default {
  name: "Move-Drivers",
  props: {
    detailsId: null,
  },
  components: {
    Table,
  },
  data() {
    return {
      isLoading: false,
      values: {},
      errorMessage: "",
      depots: [],
      drivers: [],
      updatedDrivers: [],
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      tableHeader: ["Full Name", "Email Address"],
    };
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];

    const depots = await this.$store.dispatch(`business/getSites`, {
      depotId: me?.business?._id,
      isDeleted: false,
    });
    this.depots = depots.filter((x) => x._id != this.detailsId);
    this.depots.unshift({
      _id: me?.business?._id,
      name: "Main Business",
      isRoot: true,
    });

    this.init();
  },
  methods: {
    async init(paged = 1) {
      this.isLoading = true;

      const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        filter: {
          $or: [{ status: "linked" }, { status: "linked-non-ewd" }],
          siteId: this.detailsId,
        },
      };

      let driverContract = await this.$store.dispatch(
        `business/getContractByBusiness`,
        { uid: me?.businessId, query }
      );
      if (driverContract) {
        if (driverContract?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            driverContract?.metadata
          );
          driverContract = driverContract.results;
        }

        const driverTableData = this.formatItem(
          orderBy(driverContract, ["createdAt"], ["desc"])
        );
        this.drivers = driverTableData;
      }

      this.isLoading = false;
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            id: item?._id,
            driverId: item?.driverId,
            name: item?.driver?.driverName,
            email: item?.driver?.emailAddress || "-",
            license: item?.driver?.driversLicenseNumber,
            phone: item?.driver?.supportPhone || "-",
            status: item?.status ? item?.status.toUpperCase() : null,
            created: item?.createdAt ? formatDate(item?.createdAt) : "",
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    parseDrivers(data) {
      return data.map((driver) => [
        {
          driverId: driver.driverId,
          id: driver.id,
          name: driver.name,
          itemType: "name",
          hasImage: false,
          status: driver.status,
        },
        {
          driverId: driver.driverId,
          id: driver.id,
          name: driver.email,
          itemType: "string",
        },
      ]);
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async onSubmit() {
      this.$emit("nextStep", {
        drivers: JSON.parse(JSON.stringify(Object.values(this.updatedDrivers))),
      });
    },
    selectSite(site, driver) {
      const driverIndex = findIndex(
        this.drivers,
        (x) => x.driverId == driver.driverId
      );

      const updatedDriver = this.drivers[driverIndex];
      const siteData = site?.isRoot
        ? { driverId: updatedDriver.driverId, siteId: null }
        : { driverId: updatedDriver.driverId, siteId: site._id };
      this.updatedDrivers[updatedDriver.driverId] = siteData;
    },
  },
  computed: {
    driversData() {
      return this.drivers ? this.parseDrivers(this.drivers) : [];
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
