<template>
  <div>
    <div class="mb-3 bg-white pt-3 pl-8 pr-8">
      <div class="mt-8 mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Support Details</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{email}}</div>
        <div class="text-xs">Email Address</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{phone}}</div>
        <div class="text-xs">Phone Number</div>
      </div>
    </div>

    <div class="mb-3 bg-white pt-3 pl-8 pr-8 pb-8">
      <div class="mt-8 mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Partner Support Details</div>
      </div>
      <div v-if="partnerPersona" class="mb-5">
        <div class="font-bold text-black-primary text-base">{{partnerPersona.businessName}}</div>
        <div class="text-xs">Business Name</div>
      </div>
      <div v-if="partnerPersona" class="mb-5">
        <div class="font-bold text-black-primary text-base">{{partnerPersona.businessAddress}}</div>
        <div class="text-xs">Business Address</div>
      </div>
      <div v-if="partner" class="mb-5">
        <div class="font-bold text-black-primary text-base">{{partner.supportEmail}}</div>
        <div class="text-xs">Email Address</div>
      </div>
      <div v-if="partner" class="mb-5">
        <div class="font-bold text-black-primary text-base">{{partner.supportPhone}}</div>
        <div class="text-xs">Phone Number</div>
      </div>
    </div>
  </div>
</template>
<script>
export default{
  name:"Support-Details",
  data() {
    return {
      email: '',
      phone: '',
      partner: {},
      partnerPersona: {}
    }
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];

    const reseller = await this.$store.dispatch(`reseller/getResellerByUID`, me?.uid);
    
    if(reseller){
      const partner = await this.$store.dispatch(`partner/getPartnerByUID`, reseller.parentUid);

      if(partner){
        this.partner = partner
        this.partnerPersona = partner?.persona
      }
    }

    this.email = reseller?.supportEmail || '---';
    this.phone = reseller?.supportPhone || '---';
  }
}
</script>