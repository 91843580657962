<template>
  <div>
    <div v-if="readSolo">
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="soloContractData"
        @onPageChange="onPageChange($event)"
      >
        <template slot="tableFilters">
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400"/>
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400"/>
            </span>
            <input
              placeholder="Search"
              class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
        </template>
        <template v-if="writeSolo && !noPaymentGateway" slot="callToActionTop">
          <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
            <button class="btn btn-primary" @click.prevent="showInviteLink()" :style="{backgroundColor: theme.secondaryColor}">
              Show invite link
            </button>
            <button class="btn btn-primary" @click.prevent="newDriver()" :style="{backgroundColor: theme.secondaryColor}">
              Add / Link Driver
            </button>
          </div>
        </template>
        
      <template #default="{data}">
        <td class="px-5 py-2">
        <div class="text-black-primary whitespace-no-wrap text-lg flex">
          <StyledIconedLink v-if="data.status == 'PENDING'" 
          class="flex cursor-pointer mx-1.5" title="Resend Invite Email" 
          @click.prevent="resendInvite(data.id)"
          :iconHoverColor="theme.secondaryColor"
          >
            <font-awesome-icon icon="paper-plane" class="my-auto mx-1.5"/>
          </StyledIconedLink>
          <!-- <a v-if="data.status == 'LINKED'" class="flex cursor-pointer disabled" title="Delink" @click.prevent="delinkDriver(data.driverId,businessid)"><font-awesome-icon icon="unlink" class="my-auto mx-1.5 hover:text-orange-primary"/></a> -->
          <StyledIconedLink class="flex cursor-pointer mx-1.5"
            @click.prevent="viewDriver(data.id)"
            :iconHoverColor="theme.secondaryColor"
            >
            <font-awesome-icon
               icon="clipboard"
              class="my-auto mx-1.5"
            />
          </StyledIconedLink>
          <StyledIconedLink class="flex cursor-pointer mx-1.5"
            @click.prevent="editDriver(data.id)"
            :iconHoverColor="theme.secondaryColor"
              ><font-awesome-icon
                icon="edit"
                class="my-auto mx-1.5"
            />
          </StyledIconedLink>
          <!-- <a class="flex cursor-pointer"
          @click.prevent="editCard(data.id)"
            ><font-awesome-icon
              icon="credit-card"
              class="my-auto mx-1.5 hover:text-orange-primary"
          /></a> -->
          <DrillDown :uid="data.uid" role="driver"/>
        </div>
      </td>
      </template>
      </Table>
    </div>
    <div v-if="!readSolo && hasRole" class="w-full min-h-full text-center mt-5">
      <h1>Not authorized to view drivers. Please contact your business admin.</h1>
    </div>

    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>
    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <DriverSoloContractStep @closeModal="closeModal" parent-entity="partner" />
      </div>
      <div v-if="modalContent == 'view'">
        <ViewSolo
          :activeSolo="activeSolo"
          @editDriver="editDriver"
        />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditLicenseDetails
          :id="activeSolo.businessMetaData._id"
          @updatedDriver ="updateContractDriverData"
        />
      </div>
      <div v-if="modalContent == 'editCard'">
      </div>
    </Modal>
    <Modal ref="paymentModal" :title="modalTitle" size="3xl" :height="paymentModalHeight">
      <div class="sm:flex sm:items-start mx-4 my-8">
        <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-100 sm:mx-0 sm:h-12 sm:w-12">
          <svg class="h-6 w-6 text-orange-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        </div>
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">No Active Payment Gateway</h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">If you want to continue adding solo drivers you need to make sure that your payment gateway is active or create/set a payment gateway to default.</p>
          </div>
        </div>
      </div>
      <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button type="button" @click.prevent="goToPaymentGateway()" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn btn-primary text-base font-medium text-white focus:outline-none sm:ml-3 sm:w-auto sm:text-sm" :style="{backgroundColor: theme.secondaryColor}">Create/Set a Payment Gateway</button>
        <button @click="closeModal()" class="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancel</button>
      </div>
    </Modal>    
    <Modal ref="urlModal" :title="modalTitle" size="3xl" :height="urlModalHeight">
      <div>
        <div v-if="!isUrlAvailable" class="flex flex-col items-center mx-4 my-8">
          <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4" id="modal-title">No Link Generated</h3>
          <button class="btn btn-primary" @click.prevent="generateLink()" :style="{backgroundColor: theme.secondaryColor}">
            <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Generating url...</span>  
            <span v-if="!submitLoading">Generate Link</span>  
          </button>
        </div>
        <div v-else class="flex flex-col items-center mx-4 my-8">
          <h3 class="text-lg leading-6 font-medium text-gray-900 mb-4" id="modal-title">{{ shareableUrl }}</h3>
          <div class="flex items-center space-x-2">
            <button class="btn btn-primary" @click.prevent="copyShareableLink()" :style="{backgroundColor: theme.secondaryColor}">
              <span v-if="copyLink">Copied!</span>  
              <span v-if="!copyLink">Copy Link</span>  
            </button>
            <button class="btn btn-primary" @click.prevent="generateLink()" :style="{backgroundColor: theme.secondaryColor}">
              <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Generating url...</span>  
              <span v-if="!submitLoading">Generate New Link</span>  
            </button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Table } from '@/components/commons'
import ViewSolo from '@/components/view/driver/view-solo-driver-details'
import DriverSoloContractStep from '@/components/forms/driver/driver-solo-contract/driver-solo-contract-step.vue';
import EditLicenseDetails from '@/components/forms/driver/edit-license-details.vue';
import { formatDate, formatPaginationSettings } from '@/_helper'
import _ from 'lodash'
import { DEFAULT_MODAL_HEIGHT, SEARCH_DEBOUNCE_DELAY } from '@/_helper/constants'
import DrillDown from '@/components/modules/DrillDown.vue'
import { mapGetters } from 'vuex'
import {StyledIconedLink} from '@/styled-components'

export default {
  name: 'Solo',
  components: {
    Modal,
    Table,
    DriverSoloContractStep,
    DrillDown,
    ViewSolo,
    EditLicenseDetails,
    StyledIconedLink
  },
  data () {
    return {
      partnerid: '', 
      isLoading: false,
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalContent: "",
      modalTitle: "",
      modalId: "",
      soloContracts:[],
      tableHeader: [
        'Full Name',
        'Email Address',
        'Phone Number',
        'Licence',
        'Date Created',
        'Status',
      ],
      filter: {
        limit: 10,
        status: 'all',
        search: '',
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3
      },
      filterStatuses: {
        all:"All",
        linked:"Linked",
        pending:"Pending"
      },
      modal:{
        modalContent: false,
        modalTitle: '',
        modalId: '',
        modalHeight: '80vh',
      },
      modalKey: 0,
      readSolo: false,
      writeSolo: false,
      hasRole: true,
      noPaymentGateway: false,
      paymentModalHeight: '20vh',
      urlModalHeight: '300px',
      partnerLink: process.env.VUE_APP_URL,
      me: {},
      shareableUrl: '',
      isUrlAvailable: false,
      submitLoading: false,
      copyLink: false,
      rawData: [],
      activeSolo: {}
    }
  },

  async mounted () {
    this.isLoading = true;
    this.$emit("updateLoading", true);
    this.me = this.$store.getters[`account/me`];

    this.shareableUrl = this.me?.entity?.soloUrl;
    if(this.shareableUrl) {
      this.isUrlAvailable = true
    }
    let securityRoles = this.me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      this.userType = securityRoles[0].type;
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readSolo = securityAccessObj.readSolo;
      this.writeSolo = securityAccessObj.writeSolo;
    } else {
      this.hasRole = false;
    }
    
    this.$emit("updateLoading", false);
    this.init();
  },
  methods: {
    //Security Roles
    async getSecurityRolesByIds(ids) {
      const promises = [];
      for (let i = 0; i < ids.length; i++) {
        const promise = this.$store.dispatch(`rolesandrespo/getRoles`, ids[i]);
        promises.push(promise);
      }

      return await Promise.all(promises);
    },
    setSecurityAccess(securityRoles) {
      let initialReadSolo = false;
      let initialWriteSolo = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.solo.readSolo.value) {
          initialReadSolo =
            securityConfig.solo.readSolo.value;
        }
        if (securityConfig.solo.writeSolo.value) {
          initialWriteSolo =
            securityConfig.solo.writeSolo.value;
        }
      });

      return {
        readSolo: initialReadSolo,
        writeSolo: initialWriteSolo,
      };
    },
    //End Security Roles

    //initialize content
    async init(paged = 1) {
      this.isLoading = true
      const entityID = this.me.entity?._id;

      const payments = await this.$store.dispatch(`payment/getActivePaymentGatewayByEntityId`, entityID);
      if(payments.length <= 0) {
        this.noPaymentGateway = true
        this.paymentModalHeight = '250px';
        this.$refs.paymentModal.toggleModal();
      } else {
        this.noPaymentGateway = false
      }

      const query = {
        skip: ( paged * this.filter.limit ) - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: { }
      }

      await this.$store.dispatch(`contract/getResellerDriverContract`, {resellerId: entityID, query})

        .then((response) => {
          if(response) {
            if(response?.metadata) {
              this.paginationSettings = formatPaginationSettings(response?.metadata)
            } else {
              this.paginationSettings = formatPaginationSettings()
            }

            this.rawData = response.results

            const soloTableData = this.formatItem(_.orderBy(response.results, ['createdAt'],['desc']));
            this.soloContracts = soloTableData
          } else {
            this.soloContracts = [];
          }
        })
        .catch((error) => {
          this.soloContracts = [];
          console.log(error)
        })
        this.isLoading = false;
    },

    async onPageChange(event){
      if(event.page){
        await this.init(event.page)
      }
    },

    async generateLink() {
      this.submitLoading = true
      await this.$store.dispatch(`reseller/generateShareableUrl`, {
        id: this.me.entity?._id,
        urlType: 'solo',
        baseUrl: process.env.VUE_APP_URL
      }).then((response) => {
        if(response) {
          setTimeout(()=>{
            this.fetchUser();
          },4000);
        }
      })
      .catch((error) => {
        this.soloContracts = [];
        console.log(error)
      })
    },

    async fetchUser() {
      await this.$store.dispatch(`account/getMe`)
        .then((response) => {
          if(response) {
            this.updateMe(response);
          }
        })
    },

    async updateMe(user) {
      if(user?.entity || user?.business || user?.driver) {
        this.$emit('fetchedProfile', user)
      } else {
        await this.$store.dispatch(`account/updateMe`, user)
          .then((response) => {
            this.submitLoading = false
            this.$store.commit('setDialogNotify',true);
            this.$store.commit('setMessageNotify',{state:'success',message:'Shareable url created successfully.'});
            this.me = this.$store.getters[`account/me`];
            this.shareableUrl = this.me?.entity?.soloUrl;
            if(this.shareableUrl) {
              this.isUrlAvailable = true
            }
            console.log(response)
            console.log(this.isUrlAvailable)
          })
      }
    },

    async copyShareableLink() {
      this.copyLink = true;
      navigator.clipboard.writeText(this.shareableUrl);
    setTimeout(()=>{
      this.copyLink = false;
      },4000);
    },

    async filterChange(){
      await this.init()
    },
    handleSearchKeyDown(){
      this.stoppedTyping()
    },
    debouncedSearchString(){
      this.init()
    },

    newDriver() {
      this.modalContent = "new";
      this.modalTitle = "New Solo";
      this.$refs.modal.toggleModal();
    },

    editDriver(id) {
      this.modalId = id;
      this.activeSolo = this.rawData.filter(item => item._id === id)[0];
      this.modalContent = 'edit';
      this.modalTitle = 'Solo Update';
      this.$refs.modal.openModal();
    },

    viewDriver(id) {
      this.activeSolo = this.rawData.filter(item => item._id === id)[0];
      console.log(this.activeSolo)
      this.modalContent = 'view';
      this.modalTitle = 'Solo Info';
      this.$refs.modal.toggleModal();
      this.modalId = id;
    },

    editCard(id) {
      this.modalId = id;
      this.modalContent = 'editCard';
      this.modalTitle = 'Update Card Details';
      this.$refs.modal.openModal();      
    },

    showInviteLink: function () {
      this.modalContent = "new";
      this.modalTitle = "Invite Solo Drivers";
      this.$refs.urlModal.toggleModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
      this.$refs.paymentModal.closeModal();
    },

    goToPaymentGateway() {
      this.$router.push('payment'); 
    },

    resendInvite(contractId){
      if(confirm('Resend invite email to this driver?')) {
        this.$store.dispatch('contract/resendInvite', contractId).then(driver => {
          if(driver){
              location.reload();
          }
        }).catch(error => {
            this.errorMessage = `Something went wrong please try again.`;
            if(error.message) this.errorMessage = error.message[0]
        }); 
      }
    },
    formatItem: function (items) {
      let nItems = [];
      if (items) {
        let status
        items.forEach((item) => {
          switch(item?.status?.name) {
            case 'Accepted':
              status = 'Active'
            break;
            case 'Suspended':
              status = 'Suspended'
            break;
            case 'Saved':
              status = 'Active'
            break;
            default:
              status = 'Pending'
          }
          const solo = {
            id: item?._id,
            name: item?.businessMetaData?.driverName,
            email: item?.businessMetaData?.emailAddress,
            phoneNumber: item?.businessMetaData?.persona.contactPhoneNumber,
            contractDuration: item?.contractDuration?.name,
            billingPeriod: item?.billingPeriod?.name,
            lastPaymentDate: formatDate(item?.lastPaymentDate),
            status: status,
            uid: item?.businessMetaData?.uid
          }
          nItems.push(solo)
        })
      }
      return nItems;
    },
    parseDrivers(data) {
      return data.map((driverData) => ([
        { id: driverData.id, name: driverData.name, itemType: 'name', hasImage: true, status:driverData.status, uid:driverData.uid},
        { id: driverData.id, name: driverData.email, itemType: 'string'},
        { id: driverData.id, name: driverData.phoneNumber, itemType: 'string'},
        { id: driverData.id, name: driverData.contractDuration, itemType: 'string'},
        { id: driverData.id, name: driverData.billingPeriod, itemType: 'string'},
        { id: driverData.id, name: driverData.lastPaymentDate, itemType: 'string'},
        { id: driverData.id, name: driverData.status, itemType: 'string'},
      ]))
    },
    selectFilteredStatus() {
      this.selectedStatus =
        this.filterStatuses[this.selectedFilteredStatus].value;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },

    async updateContractDriverData() {
      this.$store.commit('setDialogNotify',true);
      this.$store.commit('setMessageNotify',{state:'success',message:'Solo successfully updated.'});
      this.closeModal();
      this.init();
    }
  },
  computed: {
    soloContractData() {
      if (this.soloContracts) {
        return this.parseDrivers(this.soloContracts);
      } else {
        return [];
      }
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
  created() {
    this.stoppedTyping = _.debounce(this.debouncedSearchString, SEARCH_DEBOUNCE_DELAY, {
      leading: false,
      trailing: true,
    })
  },
}
</script>