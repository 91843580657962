<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit">
      <FormulateInput
        type="date"
        name="lastPaymentDate"
        v-model="values.lastPaymentDate"
        label="Last Payment Date"
        validation="required"
      />
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
        />
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">{{errorMessage}}</div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    activeSolo: Object,
  },
  data() {
    return {
      values: {},
      loading: false,
      hasErrorMessage: false,
      errorMessage: '',
    }
  },

  async mounted () {
    const lastPaymentDate = moment(this.activeSolo.lastPaymentDate).format('YYYY-MM-DD')
    this.values.lastPaymentDate = lastPaymentDate
  },

  methods: {
    async onSubmit() {
      this.loading = true
      // console.log(this.values)
      await this.$store.dispatch('contract/updateLastPaymentDate', {
        contractId: this.activeSolo._id, lastPaymentDate: this.values.lastPaymentDate
        })
        .then(async (response) => {
          if(response) {
            this.$store.commit('setDialogNotify',true)
            this.$store.commit('setMessageNotify',{state:'success',message: 'Last payment date updated successfully.'})
            this.$emit('closeModal')
          }
        }).catch((err) => {
            this.$store.commit('setDialogNotify',true)
            this.$store.commit('setMessageNotify',{state:'error',message: err.message})
        }).finally(() => {
          this.loading = false
        })
    }
  }
}
</script>