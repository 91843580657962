<template>
    <div class="mb-16">
      <div class="loading mt-32 h-32" v-if="loading">
        <ui-spinner class="mx-auto"></ui-spinner>
      </div>
      <FormulateForm v-model="accountInfo" v-if="!loading" @submit="onSubmit" class="mt-7">
        <div v-if="loading" class="text-center"><ui-spinner class="m-auto"></ui-spinner> Loading...</div>
        <div v-if="!loading" class="items-center justify-between">
          <FormulateInput
            type="text"
            label="Username"
            name="contactUserName"
            validation="required:trim"
            aria-autocomplete="off"
            autocomplete="off"
            @keydown.space.prevent
          />

          <div class="grid grid-cols-2 gap-4">
            <FormulateInput
              type="email"
              label="Contact Email"
              name="contactEmail"
              validation="email"
              @keydown.space.prevent
              autocomplete="off"
              @input="handleStritEmailCase($event)"
            />

            <FormulateInput
              type="text"
              label="Contact Phone Number"
              name="contactPhoneNumber"
              validation="required:trim"
              @keydown.space.prevent
              autocomplete="off"
            />
          </div>

          <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
            <button class="btn col-span-2 btn-primary mt-2" :style="{backgroundColor: theme.secondaryColor}">Submit</button>
            <button @click.prevent="onPrevious" class="btn bg-transparent border col-span-2 btn-info mt-2">Back</button>
          </div>
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
    </div>
</template>
<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'New-Reseller-Form',
  props: {
    msg: String,
    preData: null,
    postData: null
  },
  data() {
    return {
      error:false,
      errorMessage: '',
      loading: false,
      accountInfo: {},
      interChangingData: []
    };
  },
  mounted(){
    this.interChangingData = this.preData ? this.preData: this.postData
    this.accountInfo = this.interChangingData.accountInfo
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      this.errorMessage = '';

      const accountInfo = JSON.parse(JSON.stringify(this.accountInfo));
      const entityID = this.$store.getters[`account/me`].entity._id;

      const businessDetails = this.interChangingData.businessDetails;
      // const prepaid = this.interChangingData.prepaid;
      const pricing = this.interChangingData.pricing;
      const conType = this.interChangingData.connectionType;

      if(businessDetails && accountInfo && pricing
      // && prepaid
      ){

        const buyPricingPayload = this.parsePricingPayload(pricing, conType, false)
        const RRPPayload = this.parsePricingPayload(pricing, conType, true)
        // const buyPricingPreapaidPayload = this.parsePrepaidPricingPayload(prepaid, conType, false)
        // const RRPPrepaidPayload = this.parsePrepaidPricingPayload(prepaid, conType, true)

        // Encapsulate standard pricing
        const mergedPayload = this.parseMergedPayload([
            ...buyPricingPayload,
            ...RRPPayload,
            // ...buyPricingPreapaidPayload,
            // ...RRPPrepaidPayload
          ]);

        // Format business address
        const businessAddress = businessDetails.businessAddress

        const conData = {
          persona: {
            businessName: businessDetails.businessName,
            tradingName: businessDetails.tradingName,
            abn: businessDetails.abn,
            businessAddress,
            contactUserName: accountInfo.contactUserName,
            contactEmail: accountInfo.contactEmail,
            contactPhoneNumber: accountInfo.contactPhoneNumber
          },
          supportEmail: businessDetails.supportEmail,
          supportPhone: businessDetails.supportPhoneNumber,
          entityId: entityID,
          standardPricing: mergedPayload
        }
        try {
          switch (conType) {
            case 'partner':
              await this.$store.dispatch(`partner/createPartner`, conData)
                .then(() => {
                  // Then
                  this.submitting = false
                  this.loading = true
                  this.$emit('closeModal');
                  this.$emit('submitSuccess')
                  this.$store.commit('setDialogNotify',true)
                  this.$store.commit('setMessageNotify',{state:'success',message:`${conType.charAt(0).toUpperCase() + conType.slice(1)} successfully created.`});
                })
                .catch((err) => {
                  this.submitting = false;
                  this.loading = false;
                  this.$store.commit('setDialogNotify',true);
                  this.$store.commit('setMessageNotify',{state:'error',message:err.message});
                })
              break;

            case 'reseller':
              await this.$store.dispatch(`reseller/createReseller`, conData)
                .then(() => {
                  // Then
                  this.submitting = false;
                  this.loading = true;
                  this.$emit('closeModal')
                  this.$emit('submitSuccess')
                  this.$store.commit('setDialogNotify',true)
                  this.$store.commit('setMessageNotify',{state:'success',message:`${conType.charAt(0).toUpperCase() + conType.slice(1)} successfully created.`});
                })
                .catch((err) => {
                  this.submitting = false;
                  this.loading = false;
                  this.$store.commit('setDialogNotify',true);
                  this.$store.commit('setMessageNotify',{state:'error',message:err.message});
                })
              break;
          }
        } catch (err) {
          this.submitting = false;
          this.loading = true;
          this.$store.commit('setDialogNotify',true);
          this.$store.commit('setMessageNotify',{state:'error',message:err.message});
        }
        this.submitting = false;
        this.loading = false;
      }
    },
    parseMergedPayload(standardPricings){
      const standardPricingData = {};
      if(!_.isEmpty(standardPricings)){
        standardPricingData.standardPricings = standardPricings;
      }
      return standardPricingData;
    },
    parsePricingPayload(pricing, conType, isRrp) {
      let standardPricings = []
      if(!_.isEmpty(pricing)){
        standardPricings = pricing.map(data => {
          let standardPricing = {};
          standardPricing.ownerRole = conType;
          standardPricing.isRrp = isRrp;
          standardPricing.pricing = isRrp? {
            monthly:data.rrp.monthly,
            yearly:data.rrp.yearly
          } : {
            monthly:data.pricing.monthly,
            yearly:data.pricing.yearly
          }
          standardPricing.standardPricingMasterId = data.standardPricingMasterId;
          return standardPricing;
        });
      }
      return standardPricings;
    },
    // parsePrepaidPricingPayload(data, conType, isRrp) {
    //   let standardPricing = {};
    //   standardPricing.ownerRole = conType;
    //   standardPricing.isRrp = isRrp;
    //   standardPricing.pricing = isRrp? {
    //     oneDay:data.rrp.oneDay,
    //     fiveDays:data.rrp.fiveDays,
    //     tenDays:data.rrp.tenDays,
    //     twentyFiveDays:data.rrp.twentyFiveDays,
    //     fiftyDays:data.rrp.fiftyDays
    //   } : {
    //     oneDay:data.pricing.oneDay,
    //     fiveDays:data.pricing.fiveDays,
    //     tenDays:data.pricing.tenDays,
    //     twentyFiveDays:data.pricing.twentyFiveDays,
    //     fiftyDays:data.pricing.fiftyDays
    //   }
    //   standardPricing.standardPricingMasterId = data.standardPricingMasterId;
    //   return [standardPricing];
    // },
    onPrevious() {
      const data = {...this.interChangingData, ...{
        accountInfo: this.accountInfo,
      }}

      this.$emit('backStep', data)
    },
    handleStritEmailCase(value){
      this.accountInfo.contactEmail = value.toLowerCase()
    }
  },
   computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
   }
}
</script>