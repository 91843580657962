import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class TechProvider {
    // @TechProvider
    createTechProvider(data){ // Create auth-tech-provider
        return API.ACCOUNTS.post(`/auth-tech-provider`, data)
    }
    getTechProvider({ id = null, query }){ // Get single TechProvider by Id or Get all TechProvider
        if(id){
            return API.ACCOUNTS.get(`/auth-tech-provider/${id}`)
        }else{
            let filter = '';
            if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
            query.filter = filter;
            if(query) query = '?'+ObjectToQueryString(query);
            return API.ACCOUNTS.get(`/auth-tech-provider${query}`)
        }
    }
    getTechProviderByName( name ){ // Get single TechProvider by name
        return API.ACCOUNTS.get(`/auth-tech-provider/find-by-name/${name}`)
    }
    patchTechProvider( data ){ // Update single TechProvider by Id
        return API.ACCOUNTS.patch(`/auth-tech-provider/${data.id}`, data)
    }
    deleteTechProvider( id = null ){ // Delete TechProvider by Id
        return API.ACCOUNTS.delete(`/auth-tech-provider/${id}`)
    }
}
