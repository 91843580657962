import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Fragment from 'vue-fragment'
import VueMeta from 'vue-meta'
import vSelect from 'vue-select'
import vMultiSelect from 'vue-multiselect'

Vue.config.productionTip = false

// Common Components
import { Header, Footer } from '@/components/commons';
import { Spinner, Modal } from '@/components/commons/ui';
import Button from '@/components/controls/Button'

// Theme
import './theme/index.scss'
import './theme/font-awesome'
import 'vue-select/dist/vue-select.css'
import 'sweetalert2/dist/sweetalert2.min.css';

// Other
import VueCookies from 'vue-cookies'
import VueFormulate from '@braid/vue-formulate'
import ToggleSwitch from '@/components/controls/ToggleSwitch'
import SignaturePad from '@/components/controls/SignaturePad'
import DocumentLinkComponent from '@/components/controls/DocumentLinkComponent'
import VueSweetalert2 from 'vue-sweetalert2';

// Vue Formulate Plugin
import { VueFormulateDriverSelect } from "@/components/commons/ui/list-select/DriverSelect";
import { VueFormulateDriverMultiSelect } from "@/components/commons/ui/list-select/DriverMultiSelect";
import { VueFormulateTSLMultiSelect } from "@/components/commons/ui/list-select/TSLMultiSelect";
import { VueFormulatePartnerSelect } from "@/components/commons/ui/list-select/PartnerSelect";
import { VueFormulatePartnerBusinessSelect } from "@/components/commons/ui/list-select/PartnerBusinessSelect";
import { VueFormulateSoloPartnerSelect } from "@/components/commons/ui/list-select/SoloPartnerSelect";
import { VueFormulateSoloResellerSelect } from "@/components/commons/ui/list-select/SoloResellerSelect";
import { VueFormulateBusinessContractSelect } from "@/components/commons/ui/list-select/BusinessContractSelect";
import { VueFormulateSoloDriverContractSelect } from "@/components/commons/ui/list-select/SoloDriverContractSelect";
import { VueFormulateVehicleSelect } from "@/components/commons/ui/list-select/VehicleSelect";
import { VueFormulateGeneralSelect } from "@/components/commons/ui/list-select/GeneralSelect";
import { VueFormulateCertSelect } from "@/components/commons/ui/list-select/CertSelect";
import { VueFormulatePlacesSearch } from "@/components/commons/ui/inputs/places-search";
import { VueFormulateTemplateSelect } from "@/components/commons/ui/list-select/TemplateSelect";
import { VueFormulateWebProfileSelect } from "@/components/commons/ui/list-select/WebProfileSelect";
import VueFormulateDatepicker from "vue-formulate-datepicker"
import wysiwyg from 'vue-wysiwyg'

// Commons
Vue.component('common-header', Header)
Vue.component('common-footer', Footer)
// UI
Vue.component('ui-spinner', Spinner)
Vue.component('ui-modal', Modal)
Vue.component('v-select', vSelect)
Vue.component('v-multiselect', vMultiSelect)
Vue.component('v-btn', Button)

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import font awesome icon component */
import VueSignaturePad from 'vue-signature-pad'
import Vue2Editor from "vue2-editor";

/* import specific icons */
import {
  faToggleOff,
  faTerminal,
  faFont,
  faList,
  faCalendarPlus,
  faClock,  
  faCopy,
  faSignature,
  faImage,
  faLock,
  faCalculator,
  faAt,
  faLink,
  fas,
  faPlusCircle,
  faMinusCircle,
  faPrint,
  faFilePdf
} from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
  faToggleOff,
  faTerminal,
  faFont,
  faList,
  faCalendarPlus,
  faClock,
  faCopy,
  faSignature,
  faImage,
  faLock,
  faCalculator,
  faAt,
  faLink,
  fas,
  faPlusCircle,
  faMinusCircle,
  faPrint,
  faFilePdf
)

var sweetAlertOption = {
  title: '',
  text: '',
  type: null,
  allowOutsideClick: false,
  showConfirmButton: true,
  showCancelButton: false,
  closeOnConfirm: true,
  closeOnCancel: true,
  confirmButtonText: 'OK',
  confirmButtonColor: '#FF9900',
  cancelButtonText: 'Cancel',
  imageUrl: null,
  imageSize: null,
  timer: null,
  customClass: '',
  html: false,
  animation: true,
  allowEscapeKey: true,
  inputType: 'text',
  inputPlaceholder: '',
  inputValue: '',
  showLoaderOnConfirm: false
};


// Others
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.use(Fragment.Plugin);
Vue.use(VueMeta)
Vue.use(Vue2Editor);
Vue.use(VueSignaturePad)
Vue.use(VueSweetalert2, sweetAlertOption);
Vue.use(wysiwyg, {
  hideModules: {
    "bold": true,
    "italic": true,
    "underline": true,
    "justifyLeft": true,
    "justifyCenter": true,
    "justifyRight": true,
    "image": true,
    "table": true,
    "removeFormat": true,
    "link": true,
    "code": true,
    "orderedList": true,
    "unorderedList": true,
    "separator": true,
    "headings": true
  }
})

Vue.use(VueFormulate,{
  classes: {
    outer: 'mb-3',
    input: 'shadow-sm border border-gray-300 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mt-1 mb-2 font-medium text-sm text-neutral-dark-500',
    label: 'text-sm mb-1 font-semibold form-label pr-2 text-neutral-dark',
    help: 'text-xs mb-1 text-gray-600',
    error: 'text-red-500 text-xs mb-1',
  },
  validationNameStrategy: ['label','name'],
  library: {
    switch: {
      component: ToggleSwitch
    },
    signature: {
      component: SignaturePad
    },
    documentlink: {
      component: DocumentLinkComponent
    }
  },
  plugins: [ VueFormulateDatepicker, VueFormulateDriverMultiSelect, VueFormulateTSLMultiSelect, VueFormulateDriverSelect, VueFormulateVehicleSelect, VueFormulateGeneralSelect, VueFormulatePlacesSearch, VueFormulateCertSelect, VueFormulatePartnerSelect, VueFormulateSoloPartnerSelect, VueFormulateSoloResellerSelect, VueFormulateTemplateSelect, VueFormulateWebProfileSelect, VueFormulatePartnerBusinessSelect, VueFormulateBusinessContractSelect, VueFormulateSoloDriverContractSelect ]
})
Vue.use(VueCookies)

Vue.directive("click-outside", {
  bind(el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener("click", el.clickOutsideEvent)
  },
  unbind(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent)
  },
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
