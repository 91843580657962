import { API } from '@/api/config'

export default new class StandardPricing {
    // @StandardPricingMaster
    createStandardPricingMaster(data){ // Create standard-pricing
        return API.ACCOUNTS.post(`/standard-pricing-master`, data)
    }
    getStandardPricingMaster( id = null ){ // Get single StandardPricingMaster by Id or Get all StandardPricingMaster
        if(id){
            return API.ACCOUNTS.get(`/standard-pricing-master/${id}`)
        }else{
            return API.ACCOUNTS.get(`/standard-pricing-master`)
        }
    }
    getStandardPricingMasterPartner( ){ // Get all StandardPricingMaster for Partners
        return API.ACCOUNTS.get(`/standard-pricing-master/partner`)
    }
    getStandardPricingMasterReseller( ){ // Get all StandardPricingMaster for Resellers
        return API.ACCOUNTS.get(`/standard-pricing-master/reseller`)
    }
    patchStandardPricingMaster( data ){ // Update single StandardPricingMaster by Id
        return API.ACCOUNTS.patch(`/standard-pricing-master/${data.id}`, data)
    }
    deleteStandardPricingMaster( id = null ){ // Delete StandardPricingMaster by Id
        return API.ACCOUNTS.delete(`/standard-pricing-master/${id}`)
    }

    // @StandardPricing
    createStandardPricing(data){ // Create standard-pricing
        return API.ACCOUNTS.post(`/standard-pricing`, data)
    }
    createNewRRPVersion(data){ // Create standard-pricing
        return API.ACCOUNTS.post(`/standard-pricing/rrp`, data)
    }
    createStandardPrepaidPricing(data){ // Create standard-pricing
        return API.ACCOUNTS.post(`/standard-pricing/prepaid`, data)
    }
    getStandardPricing( id = null ){ // Get single StandardPricing by Id or Get all StandardPricing
        if(id){
            return API.ACCOUNTS.get(`/standard-pricing/${id}`)
        }else{
            return API.ACCOUNTS.get(`/standard-pricing`)
        }
    }
    getStandardPricingByMongoId( id = null ){ // Get all StandardPricing by Mongo Id
        return API.ACCOUNTS.get(`/standard-pricing/get-active-pricing-by-owner-mongo-id/${id}`)
    }
    getAllStandardPricingByMongoId( id = null ){ // Get all StandardPricing by Mongo Id
        return API.ACCOUNTS.get(`/standard-pricing/get-all-pricing-by-owner-mongo-id/${id}`)
    }
    getStandardPricingVersionsByMongoId( id = null ){ // Get all StandardPricing versions by Mongo Id
        return API.ACCOUNTS.get(`/standard-pricing/get-all-pricing-versions-by-owner-mongo-id/${id}`)
    }
    getStandardRRPVersionsByMongoId( id = null ){ // Get all StandardPricing versions by Mongo Id
        return API.ACCOUNTS.get(`/standard-pricing/get-all-rrp-versions-by-owner-mongo-id/${id}`)
    }
    updateStandardPricing( data ){ // Update single StandardPricing by Id
        return API.ACCOUNTS.post(`/standard-pricing/update`, data)
    }
    updateAllStandardPricingByMongoId( data ){ // Update single StandardPricing by Id
        return API.ACCOUNTS.post(`/standard-pricing/update-buy-and-rrp-pricing`, data)
    }
    
    deleteStandardPricing( id = null ){ // Delete StandardPricing by Id
        return API.ACCOUNTS.delete(`/standard-pricing/${id}`)
    }
    //Fetch all active RRP for the Business
    getActiveRRPforBusiness({id}){
        return API.ACCOUNTS.get(`/standard-pricing/find-all-active-rrp-to-business/${id}`)
    }

    //Fetch all contract module master
    getContractModuleMaster(){
        return API.ACCOUNTS.get(`/contract-module-master`)
    }

    createContractModuleMaster(payload){
        return API.ACCOUNTS.post(`/contract-module-master`, payload)
    }

    uploadLogo(fileData){
        return API.ACCOUNTS.post(`/standard-pricing-master/logo/upload/`, fileData);
    }

    updateStandardPricingMasterDetail(data){
        return API.ACCOUNTS.patch(`/standard-pricing-master/${data.id}/user-interface/`, data);
    }

}
