<template>
  <div>
    <div class="relative">
      <button type="button" @click="toggleDropdown()" class="relative w-full text-sm bg-white border border-gray-400 hover:border-orange-primary focus:border-orange-primary rounded pl-3 pr-7 py-2 text-left cursor-pointer">
                <span class="flex items-center">
                    <span class="block truncate" :id="selected.id">
                        {{ selected.name || 'Select a partner' }}
                    </span>
                </span>
        <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd">
                        </path>
                    </svg>
                </span>
      </button>
      <div v-if="isOpen" class="absolute mt-1 w-full z-10 rounded-md bg-white border border-gray-400 pt-2 pb-2.5">
        <FormulateInput type="search" v-model="search" name="search" placeholder="Search Name or ID of Partner" class="text-xs mx-2 select-search-input"/>

        <div class="relative max-h-56 py-1 overflow-auto">
          <ul v-if="!isLoading" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="text-base focus:outline-none sm:text-sm">
            <li
                v-for="(partner, index) in filteredList"
                :key="index"
                @click="selectPartner(partner)"
                role="option"
                class="text-sm text-black-primary cursor-pointer select-none hover:bg-indigo-500 hover:text-white relative px-3 py-2"
            >
              <div class="flex flex-col justify-center">
                <p class="mb-0 block font-normal truncate">
                  {{ partner.name }}
                </p>
                <span class="text-xs text-gray-400"> {{ partner.id }}</span>
              </div>
            </li>
          </ul>
          <div v-if="isLoading" class="p-2 w-full text-center text-lg">
            <font-awesome-icon icon="circle-notch" spin size="2x" class="text-gray-400"/>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.select-search-input::v-deep {
  margin-bottom: 0.5rem;
  input {
    margin-bottom: 0;
  }
}
</style>

<script>

export default {
  name: 'PartnerListSelect',
  props: {
    partner: {
      type: String,
      required: false,
      default: '',
    },
    partners: {
      type: Array,
      required: true,
    }
  },
  data() {
    return {
      isLoading: false,
      isOpen: false,
      selected: {},
      search: '',
    }
  },
  async mounted() {
    this.isLoading = true;
    if(this.partner) this.selected = this.partners.find((partner) => partner.id === this.partner);
    this.isLoading = false;
  },
  computed: {
    filteredList() {
      return this.partners.filter(partner => {
        return partner.name?.toLowerCase().includes(this.search.toLowerCase()) ||
            partner.id?.toLowerCase().includes(this.search.toLowerCase())
      })
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectPartner(partner) {
      this.$emit('selected', partner);
      this.selected = partner;
      this.isOpen = false;
    },
    clear() {
      this.selected = {}
    }
  }
}
</script>