<template>
  <div class="mb-16">
    <FormulateForm v-model="values" :schema="schema" @submit="onSubmit">
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
          :style="{backgroundColor: theme.secondaryColor}"
        > 
        <span v-if="loading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving data...</span>  
        <span v-if="!loading">Submit</span>  
        </FormulateInput>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>
<script>
import schema from './business-profile-create-schema';
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'Add-Business-Profile-Form',
  props: {
    msg: String,
    isBusinessDriverProfile: {
      type: Boolean,
      default: false
    },
    preData: null,
  },
  data() {
    this.$emit('schema', schema)
    return {
      driverId: null,
      newDriver: {},
      loading: false,
      values: {},
      schema: schema,
      errorMessage:''
    };
  },
  mounted() {
    if(!_.isEmpty(this.preData)){
      if(!_.isEmpty(this.preData['linkDriver'])){
        this.driverId = this.preData['linkDriver']
      }
      if(!_.isEmpty(this.preData['newDriver'])){
        this.newDriver = this.preData['newDriver']
      }
    }
  },
  methods: {
    async onSubmit() {
        this.errorMessage = ''
        this.loading = true
        const me = this.$store.getters[`account/me`]
        const business = await this.$store.dispatch(`business/getBusinessByUID`,me.uid)

        if(business){
          const newContract = JSON.parse(JSON.stringify(this.values))
          newContract['profile'] = newContract['profile'][0]
          newContract['profile']['recordKeeperAddress'] = newContract['profile']['recordKeeperAddress'][0]['record-keeper-street']
          newContract['profile']['baseLocation'] = newContract['profile']['baseLocation'][0]['base-location-street']
          newContract['businessId'] = business._id
          newContract['startDate'] = new Date;
          newContract['profile']['BFMSolo'] = newContract['profile']['BFMSolo'] == true
          newContract['profile']['BFM2up'] = newContract['profile']['BFM2up'] == true

          const payload = {
            ...this.newDriver,
            driverContract: newContract
          }

          await this.$store.dispatch(`driver/setToEWDDriver`, payload)
          .then(() => {
            this.loading = false
            this.$store.commit('setDialogNotify',true)
            this.$store.commit('setMessageNotify',{state:'success',message:'Successfully set as an EWD Driver.'})
            this.$emit('closeRefresh')
          })
          .catch((error) => {
            this.loading = false;
            this.errorMessage = error?.message;
          });
        }
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>