export class LinkedVehicle {
    id = '';
    isActive = false;
    vehiclePlate = '';
    registrationExpiry = null;
    GMV = 0;
    vehicleClass = '';
    vehicleType = '';
    driveVehicle = false;
    concreteAgitator = false;
    livestockVehicle = false;
    frontCouplings = [];
    rearCouplings = [];
    powerCouplings = [];
    brakeFoundation = '';
    brakeType = '';
    comments = '';
    lAETBSysSupplier = '';
    supplyVoltageToTrailer = '';
    tSTBCSRefNumber = ''

    constructor(vehicle){
        this.id = vehicle._id;
        this.isActive = vehicle.isActive;
        this.vehiclePlate = vehicle.vehiclePlate;
        this.registrationExpiry = vehicle.registrationExpiry;
        this.GMV = vehicle.GMV;
        this.vehicleClass = vehicle.vehicleClass?.name;
        this.vehicleType = vehicle.vehicleType?.name;
        this.driveVehicle = vehicle.driveVehicle;
        this.concreteAgitator = vehicle.concreteAgitator;
        this.livestockVehicle = vehicle.livestockVehicle;
        this.frontCouplings = vehicle.frontCouplings?.map((val) => {
            return val.name;
        });
        this.rearCouplings = vehicle.rearCouplings?.map((val) => {
            return val.name;
        });
        this.powerCouplings = vehicle.powerCouplings?.map((val) => {
            return val.name;
        });
        this.brakeFoundation = vehicle.brakeFoundation?.name;
        this.brakeType = vehicle.brakeType?.name;
        this.comments = vehicle.comments;
        this.lAETBSysSupplier = vehicle.lAETBSysSupplier;
        this.supplyVoltageToTrailer = vehicle.supplyVoltageToTrailer?.name;
        this.tSTBCSRefNumber = vehicle.tSTBCSRefNumber;
    }

}