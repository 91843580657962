<template>
	<div class="flex bg-white shadow-md rounded w-full">
		<div class="flex flex-col w-full p-4">
			<!-- Title -->
			<div class="flex flex-row justify-start items-center space-x-4 mb-8">
				<div class="text-lg font-semibold">
					Seat Details
				</div>
			</div>
			
			<div v-if="isLoading" class="text-center"><Spinner class="m-auto mb-2"></Spinner> Loading seat data...</div>
			<div v-else>
			<!-- Contents -->
				<div class="flex flex-col lg:flex-row justify-between items-start lg:space-x-4 mb-4">
					<!-- Seat counts -->
					<div class="flex flex-row lg:flex-col justify-between items-start w-full lg:w-auto mb-4 lg:mb-0">
						<div class="text-md font-medium">
							Seat Count: {{ seatsTotal.total }}
						</div><div class="text-md font-medium">
							Remaining: {{ seatsTotal.remaining }}
						</div>
					</div>

					<!-- Full count details -->
					<div class="flex flex-row w-full lg:w-auto justify-center lg:justify-between items-start space-x-4">
						<div class="flex flex-col w-20 text-center justify-center items-center" v-for="(seat, index) in seats" :key="`seat-${index}`">
							<div class="text-md font-medium">
								{{ seat.name }}
							</div>
							<div class="border-2 px-4 text-center justify-center items-center w-full" :class="seat.isActive ? ' border-orange-200' : ''">
								{{ seat.count }}
							</div>
						</div>
					</div>
				</div>

				<!-- Add Seats -->
				<div class="flex flex-col">
					<div class="flex mb-4 justify-center w-full lg:justify-start">
						<button class="btn btn-primary w-full md:w-1/2 lg:w-1/6" @click="addSeats()"
						:style="{backgroundColor: theme.secondaryColor}"
						>
							Add Seats
						</button>
					</div>
					
					<div class="flex flex-col lg:flex-row lg:space-x-4 space-y-4 lg:space-y-0" v-if="showAddSeats">
						<div class="flex flex-col xl:w-3/4 w-full">
							<div class="flex flex-row items-end space-x-4 w-full">
								<div class="text-md font-medium mb-1">
									Core Platform Licencing
								</div>
								<div class="flex flex-row justify-between items-start space-x-4 flex-1">
									<div class="flex flex-col w-1/4 text-center justify-center items-center" v-for="(seat, index) in seats" :key="`seat-${index}`">
										<div class="text-md font-medium">
											{{ seat.name }}
										</div>
										<div class="border-2 px-4 text-center justify-center items-center w-full">
											{{ seat.count }}
										</div>
									</div>
								</div>
								<button class="btn btn-primary h-6 text-center inline-flex items-center justify-center mb-1" @click="addCPLSeats()"
								:style="{backgroundColor: theme.secondaryColor}"
								>
									<font-awesome-icon icon="plus" class="text-sm w-4 h-4" />
								</button>
							</div>
						</div>
						<div class="border-2 xl:w-1/4 w-full h-full p-4" v-if="showAddCPLSeats">
							<FormulateForm v-model="formData" @submit="onSubmit" #default="{ hasErrors }">
								<FormulateInput
									type="number"
									name="quantity"
									label="Quantity"
									validation="required|number|min:1"
									min="1"
									v-model="formData.quantity"
								/>
								<div class="flex flex-row justify-between items-center mb-4">
									<div class="text-xs">
										Total Licence Count
									</div>
									<div class="text-xs font-medium">
										{{totalLicenceCount}}
									</div>
								</div>

								<!-- If core platform licencing show included contracts -->
								<table class="table-auto w-full mb-1">
									<tbody>
										<tr v-for="(data, index) in cplData" :key="`cpl-table-${index}`" class="border-2">
											<td class="text-xs">{{ data.name }}</td>
											<td class="border-l-2 w-1/4 text-center">
												<font-awesome-icon icon="check" v-if="data.isActive" class="text-xs w-2 h-2" />
											</td>
										</tr>
									</tbody>
								</table>

								<div class="flex flex-row justify-between items-center mb-4">
									<div v-if="!billThisMonth" class="text-xs font-light italic">
										*Note: You will be billed next month with the total of ${{ totalPayable }} per seat.
									</div>
									<div v-else class="text-xs font-light italic">
										*Note: You will be billed this month with the total of ${{ totalPayable }} per seat.
									</div>
								</div>

								<div class="flex flex-row justify-between items-center mb-4">
									<div class="text-xs font-semibold">
										Total
									</div>
									<div class="text-xs font-semibold">
										${{totalPrice}}
									</div>
								</div>
								<div class="flex flex-row justify-between items-center mb-4">
									<div class="text-xs font-semibold">
										Total Bill for {{ billThisMonth ? 'this month' : 'next month' }}
									</div>
									<div class="text-xs font-semibold">
										${{currentTotalPayable}}
									</div>
								</div>
								

								<FormulateInput
									type="password"
									name="password"
									label="Confirm with Password"
									validation="required"
									min="1"
									v-model="formData.password"
								/>
								
								<FormulateInput type="submit" label="Buy Now" input-class="btn btn-primary w-full"
									:disabled="hasErrors || loading" 
									:style="{backgroundColor: theme.secondaryColor}"/>
							</FormulateForm>
						</div>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';
import Spinner from '@/components/commons/ui/Spinner'
import { mapGetters } from 'vuex'

export default {
  components: {
    Spinner
  },
	props: {
		seats: {
			type: Array,
			default: () => []
		},
		seatsTotal: {
			type: Object,
			default: () => {}
		},
		isLoading: {
			type: Boolean,
			default: false
		}
	},

	data () {
		return {
			remainingSeats: 0,
			usedSeats: 0,
			loading: false,
			addSeatDetailsSchema: [
				{
					type: "number",
					name: "quantity",
					label: "Quantity",
					placeholder: "0",
					validation: "required|number|min:1",
					min: "1",
				}
			],
			formData: {
				quantity: 0
			},
			cplData: [
				{
					name: 'EWD',
					isActive: true
				},
				{
					name: 'Fatigue Management',
					isActive: true
				},
				{
					name: 'Payroll Assist',
					isActive: false
				},
				{
					name: 'Training',
					isActive: true
				},
			],

			showAddSeats: false,
			showAddCPLSeats: false,
			billThisMonth: false,
			totalPayable: 0,
			daysRemaining: 0
		}
	},

	async mounted() {
			const currentDate = moment(new Date(), 'YYYY-MM-DD')

			const currentDay = parseInt(currentDate.format('DD'));
			const dayDiff = 28 - parseInt(currentDate.format('DD'));

			const thisMonthTotal = (dayDiff/28).toFixed(2);

			let billThisMonth = false
			let totalPayable = 0

			if (currentDay < 10) {
				billThisMonth = true
				totalPayable = parseFloat(thisMonthTotal).toFixed(2)
			} else if (currentDay >= 10) {
				billThisMonth = false
				totalPayable = (parseFloat(thisMonthTotal) + 1).toFixed(2)
			}

			this.billThisMonth = billThisMonth
			this.totalPayable = totalPayable
			this.daysRemaining = dayDiff
	},

	methods: {
		addSeats() {

			this.showAddSeats = !this.showAddSeats

		},

		addCPLSeats() {
			this.showAddCPLSeats = !this.showAddCPLSeats
		},

		onSubmit() {

			this.$emit('onAddSeats', this.formData)
		}
	},
	computed: {
		totalLicenceCount() {
			const contract = this.seats.find(seat => seat.name === 'Contract');
			const extra = this.seats.find(seat => seat.name === 'Extra');
			
			const total = contract.count + extra.count

			const qtyTotal = parseInt(total) + parseInt(this.formData.quantity)

			const result = `${total} ~ ${qtyTotal}`
			return result
		},

		totalPrice() {
			const total = this.formData.quantity * 1;
			return total
		},

		currentTotalPayable() {
			const total = this.formData.quantity * this.totalPayable;
			return total.toFixed(2)
		},
		...mapGetters('theme', {
			theme: 'getColorScheme'
		})
	}
}
</script>