<template>
  <div>
      <div class="text-red-500 font-bold text-sm text-center mt-0 my-5" v-if="errorMessage">
        {{errorMessage}}
      </div>
      <FormulateForm name="resetpassword" v-model="forgotPasswordForm" @submit="onSubmit">
        <FormulateInput
            type="password"
            name="newPassword"
            label="New Password"
            validation="^required"
        />
        <FormulateInput
            type="password"
            name="newPassword_confirm"
            label="Confirm New Password"
            validation="required|confirm"
            error-behavior="submit"
        />
        <div class="flex items-center justify-between">
            <FormulateInput
                type="submit"
                label="Submit"
                input-class="btn btn-primary"
            />
        </div>
      </FormulateForm>
  </div>
</template>


<script>

export default {
  name: 'ForgotPasswordForm',
  props: {
    msg: String,
  },
  data() {
    return {
      forgotPasswordForm: {},
      error: false,
      errorMessage: '',
      token: ''
    };
  },
  mounted(){
    this.token = this.$route.params['token'];
    if(!this.token) window.location.href = '/404?message=Invalid token request'
  },
  methods: {
    async onSubmit() {
      // Put forgot password logic here
      console.log("test--",this.forgotPasswordForm);
      try{
        const data = {
          token: this.token,
          password: this.forgotPasswordForm.newPassword,
          confirm: this.forgotPasswordForm.newPassword_confirm,
        }
        const resetPassword = await this.$store.dispatch(`account/resetPassword`,data)
        if(resetPassword && resetPassword.success){
          window.location.href = '/login?message=Password successfully changed.'
        }else{
          this.errorMessage = 'Invalid token or password please try again.';
          this.$formulate.reset('resetpassword')
          //window.location.href = '/login?message=Invalid token or password'
        }
      }catch( error ){
        this.error = true
        this.errorMessage = error.message
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
