<template>
  <div class="pb-12">
    <FormulateForm
      class="py-2"
      name="schedForm"
      ref="form"
      v-model="createScheduleModel"
      @submit="onSubmitSchedule"
      #default="{ hasErrors }"
    >
      <div class="items-center justify-between">
        <FormulateInput
          type="select"
          name="scheduledDay"
          validation="required"
          validation-error="required"
          label="Scheduled Week Day"
          :options="[
            { value: 'SUNDAY', label: 'Sunday' },
            { value: 'MONDAY', label: 'Monday' },
            { value: 'TUESDAY', label: 'Tuesday' },
            { value: 'WEDNESDAY', label: 'Wednesday' },
            { value: 'THURSDAY', label: 'Thursday' },
            { value: 'FRIDAY', label: 'Friday' },
            { value: 'SATURDAY', label: 'Saturday' },
          ]"
        />

        <div v-if="submitType != 'edit'">
          <FormulateInput
            type="select"
            name="reportName"
            validation="required"
            validation-error="required"
            label="Report"
            :value="getReportType"
            :options="reportOptions"
            @change="reportOnChange($event)"
          />
        </div>

        <FormulateInput
          type="select"
          name="reportDurationInDays"
          label="Report Duration"
          :value="getReportDuration"
          :options="[
            { value: 7, label: '1 Week' },
            { value: 14, label: '2 Weeks' },
            { value: 21, label: '3 Weeks' },
            { value: 28, label: '4 Weeks' },
          ]"
        />

        <div
          v-if="
            submitType == 'edit' &&
            !editToggle &&
            selectedEntityType != 'driver'
          "
          class="flex items-center flex-wrap px-2 pb-1"
        >
          <button
            class="w-full text-sm mb-12 btn btn-primary"
            :style="{ backgroundColor: theme.secondaryColor }"
            @click="toggleDriverEdit"
          >
            Edit Drivers
          </button>
        </div>

        <div
          v-if="
            selectedEntityType != 'driver' &&
            submitType == 'edit' &&
            editToggle == true
          "
        >
          <FormulateInput
            label="Driver(s)"
            name="selectedDriver"
            type="driver-multiselect"
            placeholder="Select a driver"
            style="margin-bottom: 0px"
            @selected="onSelectDrivers"
            class="pb-5"
            :pushRaw="true"
            :defaultSelectedDriverIds="selectedDrivers"
          />

          <div class="flex items-center flex-wrap px-2 pb-1">
            <span class="py-2 invisible">:</span>
            <small
              v-for="(data, index) in selectedDrivers"
              class="bg-green-400 text-white px-4 py-2 my-1 mr-1 rounded-full"
              :key="index"
            >
              {{ data?.driverName ?? data?.driver?.driverName }}
            </small>
          </div>
        </div>

        <div
          v-else-if="selectedEntityType != 'driver' && submitType == 'create'"
        >
          <FormulateInput
            label="Driver(s)"
            name="selectedDriver"
            type="driver-multiselect"
            placeholder="Select a driver"
            style="margin-bottom: 0px"
            @selected="onSelectDrivers"
            class="pb-5"
            :defaultSelectedDriverIds="selectedDrivers"
          />

          <div class="flex items-center flex-wrap px-2 pb-1">
            <span class="py-2 invisible">:</span>
            <small
              v-for="(data, index) in selectedDrivers"
              class="bg-green-400 text-white px-4 py-2 my-1 mr-1 rounded-full"
              :key="index"
            >
              {{ data?.driverName ?? data?.driver?.driverName }}
            </small>
          </div>
        </div>

        <div v-else-if="selectedEntityType != 'driver' && submitType == 'edit'">
          <div class="flex items-center flex-wrap px-2 pb-1">
            <p
              class="text-sm mb-1 font-semibold form-label pr-2 text-neutral-dark"
            >
              Assigned Drivers:
            </p>
            <span class="py-2 invisible">:</span>
            <small
              v-for="(data, index) in selectedDrivers"
              class="bg-green-400 text-white px-4 py-2 my-1 mr-1 rounded-full"
              :key="index"
            >
              {{ data?.driverName ?? data?.driver?.driverName }}
            </small>
          </div>
        </div>

        <div class="text-red-500 text-center mb-3" v-if="validationError">
          {{ validationError }}
        </div>

        <FormulateInput
          type="submit"
          :label="checkType()"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import {
  REPORT_NAMES,
  BUSINESS_REPORT_OPTIONS,
  DRIVER_REPORT_OPTIONS,
} from "@/_helper/constants";
export default {
  name: "day-interval-form",
  props: {
    editData: {
      type: Object,
      default: () => ({}),
    },
    selectedEntityType: {
      type: String,
    },
    isKmTimeModuleFound: {
      type: Boolean,
    },
    driverId: {
      type: String,
    },
  },
  data() {
    return {
      createScheduleModel: {
        reportDurationInDays: 7,
        reportName: REPORT_NAMES.COMPLIANCE_REPORT,
        scheduledDay: "SUNDAY",
      },
      submitType: "create",
      reportOptions: [],
      reportNames: REPORT_NAMES,
      isDriverStatsReportSelected: false,
      businessReportOptions: BUSINESS_REPORT_OPTIONS,
      driverReportOptions: DRIVER_REPORT_OPTIONS,
      selectedDrivers: [],
      validationError: "",
      editToggle: null,
      isLoading: false,
    };
  },
  destroyed() {
    this.$emit("closeModal");
  },
  async mounted() {
    this.isLoading = true;
    switch (this.selectedEntityType) {
      case "business":
        if (!this.isKmTimeModuleFound) {
          this.businessReportOptions = this.businessReportOptions.filter(
            (report) => {
              return report.value != this.reportNames.DRIVER_STATS_REPORT;
            }
          );
        }

        this.reportOptions = this.businessReportOptions;
        break;
      case "driver":
        this.reportOptions = this.driverReportOptions;
        break;
      default:
        this.reportOptions = [];
    }

    if (!_.isEmpty(this.editData)) {
      this.submitType = "edit";
      this.editToggle = false;
    } else {
      this.submitType = "create";
      this.editToggle = null;
    }
    this.isLoading = false;
  },

  async created() {
    this.isLoading = true;
    if (!_.isEmpty(this.editData) && this.selectedEntityType !== "driver") {
      this.selectedDrivers = await this.fetchDriversToEdit(
        this.editData.metadata.driverIds
      );
      this.createScheduleModel.reportDurationInDays =
        this.editData.interval == "MONTHLY"
          ? 7
          : this.editData.reportDurationInDays;
      this.createScheduleModel.scheduledDay = this.getScheduledWeekDay(
        this.editData.scheduledDayMomentJs
      );
      this.submitType = "edit";
    }
    this.isLoading = false;
  },

  methods: {
    onSubmitSchedule(data) {
      const metadata = {
        driverIds: [],
      };

      if (this.selectedEntityType == "business") {
        const driverIds = this.selectedDrivers.map((data) => {
          return data?.driverId;
        });

        if (driverIds && driverIds.length == 0) {
          this.validationError = "No Drivers Selected";
          return;
        }

        metadata.driverIds = driverIds;
      } else {
        metadata.driverIds = [this.driverId];
      }

      let scheduledDayMomentJs;
      switch (data.scheduledDay) {
        case "SUNDAY":
          scheduledDayMomentJs = 0;
          break;
        case "MONDAY":
          scheduledDayMomentJs = 1;
          break;
        case "TUESDAY":
          scheduledDayMomentJs = 2;
          break;
        case "WEDNESDAY":
          scheduledDayMomentJs = 3;
          break;
        case "THURSDAY":
          scheduledDayMomentJs = 4;
          break;
        case "FRIDAY":
          scheduledDayMomentJs = 5;
          break;
        case "SATURDAY":
          scheduledDayMomentJs = 6;
          break;
      }
      data["scheduledDayMomentJs"] = scheduledDayMomentJs;

      data.metadata = metadata;
      data.interval = "WEEKLY";

      this.$emit("submit", data);
    },

    toggleDriverEdit() {
      this.editToggle = !this.editToggle;
    },

    getScheduledWeekDay(date) {
      switch (date) {
        case 0:
          return "SUNDAY";
        case 1:
          return "MONDAY";
        case 2:
          return "TUESDAY";
        case 3:
          return "WEDNESDAY";
        case 4:
          return "THURSDAY";
        case 5:
          return "FRIDAY";
        case 6:
          return "SATURDAY";
      }
    },

    async fetchDriversToEdit(data) {
      const me = this.$store.getters[`account/me`];
      const drivers = await Promise.all(
        data.map(async (driverId) => {
          const driverDetails = await this.$store.dispatch(
            "driver/getDriverByID",
            driverId
          );

          return { name: driverDetails.driverName, id: driverDetails._id };
        })
      );

      const paginationSettings = {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      };

      let driverContracts = [];

      for (const driverDetails of drivers) {
        const filter = {
          limit: 10,
          status: "all",
          search: "",
        };
        filter.search = driverDetails.name;

        const query = {
          skip: paginationSettings.page * filter.limit - filter.limit,
          limit: filter.limit,
          search: filter.search,
          filter: { $or: [{ status: "linked" }, { status: "linked-non-ewd" }] },
        };

        const businessid = me?.business?._id;

        const driverContract = await this.$store.dispatch(
          `business/getContractByBusiness`,
          { uid: businessid, query }
        );

        driverContracts.push(...driverContract.results);
      }

      return driverContracts;
    },

    reportOnChange(event) {
      if (event && event.target.value == this.reportNames.DRIVER_STATS_REPORT) {
        this.isDriverStatsReportSelected = true;
      } else {
        this.isDriverStatsReportSelected = false;
      }
    },

    onSelectDrivers(drivers) {
      this.selectedDrivers = drivers;
    },

    removeSelectedDriver(index) {
      this.selectedDrivers.splice(index, 1);
    },

    checkType() {
      return this.submitType === "create" ? "Create" : "Update Schedule";
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),

    getReportDuration: {
      get() {
        return this.createScheduleModel.reportDurationInDays;
      },
    },

    getReportType: {
      get() {
        return this.createScheduleModel.reportName;
      },
    },
  },
};
</script>
