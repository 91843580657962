<template>
  <div>
    <div>
      <Tabs>
        <Tab title="Prestart">
          <FormDetails :form-type="'prestart'"></FormDetails>
        </Tab>
        <Tab title="Fitness Declaration">
          <FormDetails :form-type="'fitness_declaration'"></FormDetails>
        </Tab>
        <Tab title="Hazard">
          <FormDetails :form-type="'hazard'"></FormDetails>
        </Tab>
        <Tab title="Incident">
          <FormDetails :form-type="'incident'"></FormDetails>
        </Tab>
        <Tab title="Defect">
          <FormDetails :form-type="'defect'"></FormDetails>
        </Tab>
        <Tab title="Infringement">
          <FormDetails :form-type="'infringement'"></FormDetails>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>


<script>
import FormDetails from "@/components/view/business/fatigue-management/forms/Details.vue"
import Tabs from "@/components/commons/tab/Tabs.vue";
import Tab from "@/components/commons/tab/Tab.vue";
export default {
  name: "Forms",
  components: {Tab, Tabs, FormDetails},
}
</script>