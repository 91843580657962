<template>
    <div
        :data-type="context.type"
        :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
    >
        <multiselect 
            ref="refMultiselect"
            v-model="value"
            :options="options"
            :multiple="true"
            :loading="isLoading"
            :close-on-select="false"
            :clear-on-select="false"
            :showNoResults="true"
            :showNoOptions="true"
            :object="true"
            selectLabel="Click to select"
            deselectLabel="Click to deselect"
            placeholder="Type to search"
            track-by="_id"
            label="tslNumber"
            @input="onInput"
            @select="onSelect"
            @remove="onRemove"
            @search-change="onSearch"
        >
            <span slot="noResult" class="text-xs">Oops! No TSL found.</span>
            <span slot="noOptions" class="text-xs">Oops! No TSL found.</span>
            <template slot="selection" slot-scope="{ values }">
                <span class="multiselect__single">{{ !isSelectAll ? values.length : tslCount }} TSL selected</span>
            </template>
            <template slot="beforeList">
                <li class="flex items-center px-3 py-3 cursor-pointer" @click="onSelectAll">
                    <input
                        type="checkbox"
                        v-model="isSelectAll"
                        class="mr-2 w-4 h-4 text-blue-600 bg-gray-100 rounded"
                    />
                    <span class="text-sm">Select All (<strong>{{ paginationSettings.totalRecords }}</strong> Drivers)</span>
                </li>
            </template>
            <div v-if="!isLoading && (hasPrevPage || hasNextPage)" slot="afterList" class="pagination flex">
                <button :disabled="!hasPrevPage" @click.prevent="onPrevPage" class="rounded-tl rounded-bl" :class="{ disabled: !hasPrevPage }">Prev</button>
                <button :disabled="!hasNextPage" @click.prevent="onNextPage" class="rounded-tr rounded-br" :class="{ disabled: !hasNextPage }">Next</button>
            </div>
        </multiselect>
    </div>
</template>
<script>
import { debounce } from 'lodash';
import Multiselect from 'vue-multiselect';
import { formatPaginationSettings } from '@/_helper';

const TSLMultiSelect = {
    name: 'TSLMultiSelect',
    components: {
        Multiselect
    },
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    data: () => ({
        isLoading: false,
        hasPrevPage: false,
        hasNextPage: false,
        isSelectAll: false,
        tsls: [],
        filter: {
            limit: 10,
            status: 'all',
            search: '',
        },
        paginationSettings: {
            page: 1,
            totalPages: 5,
            totalRecords: 50,
            visiblePageItemCount: 3,
        },
        tslCount: 0,
        value: [],
        options: [],
        me: null,
    }),
    async mounted() {
        this.me = this.$store.getters[`account/me`];
        this.onSearch();
    },
    methods: {
        async onSearch(search = '') {
            debounce(async () => {
                this.filter.search = search;
                this.options = [];
                this.isLoading = true;

                const me = this.$store.getters[`account/me`];
                const tsls = await this.$store.dispatch(
                    `tsl/getTSLByEntity`, me?.businessId
                );

                console.log('tsls', tsls)
                
                if (tsls) {
                    if (tsls?.metadata){
                        this.paginationSettings = formatPaginationSettings(tsls?.metadata);
                        this.hasNextPage = this.paginationSettings.page < this.paginationSettings.totalPages;
                        this.hasPrevPage = this.paginationSettings.page > 1;
                    } else {
                        this.paginationSettings = formatPaginationSettings();
                    }
                    
                    this.tsls = tsls
                    if (this.isSelectAll) this.value = this.tsls;
                    this.options = this.tsls;
                }
                this.isLoading = false;
            }, 500)();
        },
        onPrevPage() {
            if (this.hasPrevPage) {
                this.paginationSettings.page = this.paginationSettings.page - 1;
                this.onSearch();
            }
        },
        onNextPage() {
            if (this.hasNextPage) {
                this.paginationSettings.page = this.paginationSettings.page + 1;
                this.onSearch();
            }
        },
        onSelectAll() {
            this.isSelectAll = !this.isSelectAll;
            this.value = this.isSelectAll ? this.allDrivers : [];
            if (!this.isSelectAll) this.$emit('clear');
            else this.onInput();
        },
        onSelect(driver) {
            if (this.isSelectAll && this.unSelectedDrivers.includes(driver.driverId)) {
                this.unSelectedDrivers = this.unSelectedDrivers.filter(x => x != driver.driverId);
            }
        },
        onRemove(driver) {
            if (this.isSelectAll && !this.unSelectedDrivers.includes(driver.driverId)) {
                this.unSelectedDrivers.push(driver.driverId);
            }
        },
        onInput() {
            if (this.isSelectAll) {
                let dataParam = {}
                if (this.unSelectedDrivers.length > 0) dataParam.except = this.unSelectedDrivers;

                this.tslCount = this.paginationSettings.totalRecords - this.unSelectedDrivers.length;
                dataParam.tslCount = this.tslCount;
                this.$emit('selected', this.value, dataParam)
            } else {
                this.unSelectedDrivers = [];
                this.$emit('selected', this.value);
            }
        },
    }
}

export const VueFormulateTSLMultiSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      TSLMultiSelect,
    },
    library: {
      "tsl-multiselect": {
        classification: "select",
        component: "TSLMultiSelect",
      },
    },
  });
}

export default TSLMultiSelect;
</script>
<style lang="scss" scoped>
.multiselect::v-deep {
    .multiselect__placeholder {
        display: none;
    }
    .multiselect__tags {
        min-height: 45px;
        padding: 12px 40px 0 8px;
        @apply border-gray-300
    }
    .multiselect__input {
        @apply placeholder-gray-400
    }
    .multiselect__input, 
    .multiselect__single {
        font-size: 14px;
        font-weight: 500;
    }
    .multiselect__select {
        height: 43px;
    }
    .multiselect__option {
        @apply text-sm;
    }

    .pagination {
        @apply flex text-sm;
        margin: 0.25rem 0.25rem 0;

        button {
            flex-grow: 1;
            @apply text-gray-900 focus:outline-none bg-white border border-gray-400 hover:bg-gray-100;

            &.disabled {
                @apply text-gray-300 border-gray-200 bg-gray-50 hover:bg-gray-50 cursor-not-allowed
            }
            &:hover {
                cursor: pointer;
            }
        }
    }
}
</style>