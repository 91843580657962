export default [
  {
    type: "text",
    name: "name",
    label: "Role title",
    validation: "required",
  },
  {
    type: "text",
    name: "description",
    label: "Role description",
    validation: "required",
  },
];
