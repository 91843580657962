export default [
  {
    type: 'text',
    name: 'name',
    label: 'Name',
    validation: 'required'
  },
  {
    type: 'text',
    name: 'inboundApiKey',
    label: 'Inbound API Key',
    validation: 'required'
  },
  {
    type: 'text',
    name: 'url',
    label: 'URL',
    validation: 'required'
  }
]