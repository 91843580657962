<template>
    <div v-if="showModal">
      <div class="fixed w-screen max-h-full top-0 left-0 z-20 overflow-auto">
          <div class="flex justify-center h-screen antialiased">
            <div class="flex flex-col w-11/12 sm:w-5/6 lg:w-2/3 mx-auto my-auto" :class="['max-w-'+size]">
              <div class="rounded-xl border border-neutral-light shadow-xl relative overflow-hidden h-full flex flex-col bg-white">
                <!-- Modal Header -->
                <div class="modal-header flex flex-row justify-between p-5 border-b border-neutral-light rounded-tl-lg rounded-tr-lg z-auto" v-if="title">
                  <p class="text-xl font-semibold text-dark-500 dark:text-white">{{title}}</p>
                  <StyledIconed :iconHoverColor="theme.secondaryColor" >
                    <font-awesome-icon v-if="!permanentModal" icon="times" class="my-auto text-lg mx-1.5 text-dark-500 cursor-pointer z-50" @click.prevent="toggleModal()"/>
                  </StyledIconed>
                </div>
                <div class="close-only absolute top-3 right-3 z-50" v-if="!title">
                  <StyledIconed :iconHoverColor="theme.secondaryColor" >
                    <font-awesome-icon v-if="!permanentModal" icon="times" class="my-auto text-xl mx-1.5 cursor-pointer z-50" @click.prevent="toggleModal()"/>
                  </StyledIconed>
                </div>
                <!-- Modal Content -->
                <div class="flex flex-col overflow-y-auto relative" :style="{height: bodyHeight}" :class="[usepadding? 'px-6 py-5' : '']">
                  <slot></slot>
                </div>
                <div class="modal-footer hidden flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg">
                  <p class="font-semibold text-gray-600">Cancel</p>
                  <button class="btn btn-primary px-4 py-2 text-white font-semibold bg-blue-500 rounded" :style="{backgroundColor: theme.secondaryColor}">
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="fixed w-screen h-screen top-0 left-0 bg-black opacity-20 z-10 modal-backdrop"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {StyledIconed} from '@/styled-components'

export default {
  name: 'Modal',
  components: {StyledIconed},
  props: {
    title:{
      type: String,
      default:''
    },
    size:{
      type: String,
      default:'2xl'
    },
    height:{
      type: String,
      default: 'auto'
    },
    bodyHeight:{
      type: String,
      default: '100%'
    },
    usepadding:{
      type: Boolean,
      default: true
    },
    permanentModal: {
      type: Boolean,
      default: false
    }
  },
  data(){
      return { showModal: false }
  },
  methods:{
    toggleModal: function(){
      this.showModal = !this.showModal;
      if (!this.showModal) this.$emit('closeModal');
      return this.showModal;
    },
    //Added specific modal control for open and close
    closeModal: function(){
      return this.showModal = false
    },
    openModal: function(){
      return this.showModal = true
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
