<template>
  <div>
    <div class="mb-16 bg-white pt-3 pl-8 pr-8 pb-8">
      <div class="flex items-center font-bold mt-8 mb-5 w-full border-b pb-1">
        <div class="flex items-center">
          <div class="flex items-center">
            <div class="flex flex-col">
              <div class="text-xs font-bold">Licence Details</div>
            </div>
          </div>
        </div>
        <div class="ml-auto cursor-pointer">
          <font-awesome-icon
            icon="edit"
            class="text-1xl"
            @click.prevent="showEditForm()"
          />
        </div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ soloDriver.driverName }}
        </div>
        <div class="text-xs">Name</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ soloDriver.driverDateOfBirth }}
        </div>
        <div class="text-xs">Date of Birth</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ soloDriver.driversLicenseNumber }}
        </div>
        <div class="text-xs">Licence Number</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">{{soloDriver.driversLicenseExpiry}}</div>
        <div class="text-xs">Licence Expiry</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ soloDriver.udi }}
        </div>
        <div class="text-xs">UDI</div>
      </div>
    </div>

    <Table
      :tableHeader="tableHeader"
      :data="licenseHistoryData"
      @search-table="queryForKeywords"
      :has-actions="false"
    >
      <template slot="callToActionTableField">
        <td class="px-5 py-2">
          <div class="text-black-primary whitespace-no-wrap text-lg flex">
            <a class="flex cursor-pointer" @click.prevent=""
              ><font-awesome-icon
                icon="lock"
                class="my-auto mx-1.5 hover:text-orange-primary"
            /></a>
          </div>
        </td>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="lg" :height="modalHeight">
      <div v-if="modalContent === 'edit'">
        <EditLicenseDetails
          :id="soloDriver.id"
          @updatedDriver="onDriverUpdated"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { formatDate } from "@/_helper";
import { Modal, Table } from "@/components/commons";
import EditLicenseDetails from "@/components/forms/driver/edit-license-details";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import _ from "lodash";

export default {
  name: "LicenseDetails",
  components: { Modal, Table, EditLicenseDetails },
  data() {
    return {
      soloDriver: "",
      licenseHistory: [],
      modalTitle: "",
      modalContent: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        'Drivers Licence Expiry ',
        'Drivers Licence Number',
        'Changed Date',
        'UDI'
      ],
      searchKeyword: "",
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      const me = this.$store.getters[`account/me`];
      this.soloDriver = this.parseDriver(me?.driver);
      const soloDriverHistory = await this.$store.dispatch(
        `driver/getDriverLicenseHistory`,
        { id: this.soloDriver?.id }
      );
      this.licenseHistory = this.formatLicenseHistory(
        _.orderBy(soloDriverHistory, ["createdAt"], ["desc"])
      );
    },
    showEditForm() {
      this.$refs.modal.openModal();
      this.modalContent = "edit";
      this.modalTitle = "Edit Licence Details";
    },
    parseDriver(driver) {
      return {
        driverDateOfBirth:
          formatDate(driver.driverDateOfBirth, "DD/MM/YYYY") || "-",
        driverName: driver.driverName || "-",
        driversBFMNumber: driver.driversBFMNumber || "-",
        driversBaseLocation: driver.driversBaseLocation || "-",
        driversBaseTimeZone: driver.driversBaseTimeZone || "-",
        driversLicenseExpiry:
          formatDate(driver.driversLicenseExpiry, "DD/MM/YYYY") || "-",
        driversLicenseNumber: driver.driversLicenseNumber || "-",
        emailAddress: driver.emailAddress || "-",
        udi: driver.udi || "-",
        isActive: driver.isActive,
        id: driver._id,
      };
    },
    formatLicenseHistory(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const tempItem = {
            id:item['_id'],
            driversLicenseExpiry: formatDate(item['driversLicenseExpiry'], 'DD/MM/YYYY') || "-",
            driversLicenseNumber: item['driversLicenseNumber'] || "-",
            udi: item['udi'] || "-",
            createdAt: formatDate(item['createdAt'], 'DD/MM/YYYY HH:mm') || "-",
          }
          nItems.push(tempItem)
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseLicenseHistory(data) {
      return data.map((history) => [
        {
          id: history.id,
          name: history.driversLicenseExpiry,
          itemType: "string",
        },
        {
          id: history.id,
          name: history.driversLicenseNumber,
          itemType: "string",
        },
        { id: history.id, name: history.createdAt, itemType: "string" },
        { id: history.id, name: history.udi, itemType: "string" },
      ]);
    },
    async onDriverUpdated() {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", {
        state: "success",
        message: "Solo successfully updated.",
      });
      await this.init();
      this.$refs.modal.closeModal();
    },
  },
  computed: {
    licenseHistoryData() {
      if (this.searchKeyword) {
        const filteredMockData = this.licenseHistory.filter(
          (item) =>
            this.searchKeyword
              .toLowerCase()
              .split(" ")
              .every((v) =>
                item.driversLicenseNumber.trim().toLowerCase().includes(v)
              ) ||
            this.searchKeyword
              .toLowerCase()
              .split(" ")
              .every((v) => item.udi.trim().toLowerCase().includes(v))
        );
        return this.parseLicenseHistory(filteredMockData);
      } else {
        return this.parseLicenseHistory(this.licenseHistory);
      }
    },
  },
};
</script>

<style scoped></style>
