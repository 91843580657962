<template>
  <div
    :data-type="context.type"
    :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
  >
    <list-select
      ref="selectRef"
      :options="options"
      :context="context"
      :isLoading="isLoading"
      :hasPrevPage="hasPrevPage"
      :hasNextPage="hasNextPage"
      noResultText="No templates found."
      @prevPage="onPrevPage"
      @nextPage="onNextPage"
      @search="onSearch"
    >
      <template slot="option" slot-scope="option">
        <div class="text-sm">
          <h3 class="m-0 font-semibold">{{ option.name }}</h3>
          <em class="text-xs">{{ option.id }}</em>
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected text-sm">
          {{ option.name }}
        </div>
      </template>
    </list-select>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { formatPaginationSettings } from "@/_helper";
import ListSelect from "@/components/commons/ui/list-select/ListSelect";

const TemplateSelect = {
  name: "TemplateSelect",
  components: {
    ListSelect,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    this.$refs.selectRef.onSearch();
  },

  data: () => ({
    paginationSettings: {
      page: 1,
      totalPages: 5,
      totalRecords: 50,
      visiblePageItemCount: 3,
    },
    options: [],
    isLoading: false,
    hasPrevPage: false,
    hasNextPage: false,
    filter: {
      limit: 10,
      status: "all",
      search: "",
    },
  }),

  methods: {
    async onSearch(search = "") {
      const { business } = await this.$store.getters["account/me"];

      debounce(async () => {
        this.filter.search = search;
        this.options = [];
        this.isLoading = true;

        const query = {
          skip:
            this.paginationSettings.page * this.filter.limit -
            this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {},
        };

        const templateDocs = await this.$store.dispatch(
          "accreditation/fetchTemplatesByBusiness",
          { query, businessId: business._id }
        );

        if (templateDocs) {
          this.paginationSettings = formatPaginationSettings(
            templateDocs?.data?.data?.resultsMeta
          );
          this.hasNextPage =
            this.paginationSettings.page < this.paginationSettings.totalPages;
          this.hasPrevPage = this.paginationSettings.page > 1;
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const optionContents = templateDocs?.data?.data?.targetDocument.map(
          (data) => {
            return { name: data.name, id: data._id };
          }
        );

        this.options = optionContents;

        this.isLoading = false;
      }, 1000)();
    },

    onPrevPage() {
      if (this.hasPrevPage) {
        this.paginationSettings.page = this.paginationSettings.page - 1;
        this.onSearch();
      }
    },

    onNextPage() {
      if (this.hasNextPage) {
        this.paginationSettings.page = this.paginationSettings.page + 1;
        this.onSearch();
      }
    },
  },
};

export const VueFormulateTemplateSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      TemplateSelect,
    },
    library: {
      "template-select": {
        classification: "select",
        component: "TemplateSelect",
      },
    },
  });
};

export default TemplateSelect;
</script>

<style></style>
