import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class Defect {
  getDefectByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
    let filter = '';
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/defect/find-by-businessId/${businessId}${query}`)
  }

  getDefectById( query ){
      return API.ACCOUNTS.get(`/defect/${query}`);
  }

  getDefectByDateRange( payload ){
    return API.ACCOUNTS.post('/defect/date-range', payload);
  }

  getSummaryReport( payload ){
    return API.ACCOUNTS.post(`/defect/summary-report`, payload);
  }

  getDetailedReport( payload ){
    return API.ACCOUNTS.post(`/defect/detailed-report`, payload);
  }
}