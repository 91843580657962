<template>
  <FormulateForm @submit="onNext">
      <div class="text-center text-2xl">
        RRP
      </div>
      <div class="mt-5">
        <div class="loading mt-32 h-32" v-if="loading">
          <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <div class="mt-5 overflow-x-auto">
          <div class="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table v-if="!loading" class="table-auto leading-normal p-4">
              <thead>
                <tr>
                  <th class="bg-gray-100 w-2/5 text-left">
                    Product
                  </th>
                  <th class="bg-gray-200">
                    Monthly
                  </th>
                  <th class="bg-gray-200">
                    Yearly
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(product,index) in values" :key="index" class="border-t-2">
                  <td v-if="product" class="w-80 pl-5 pr-8 text-sm bg-gray-100">
                    {{ ( product.name == 'EWD-FT')? 'EWD' :  product.name}}
                  </td>
                  <td class="pt-5 px-5 text-center">
                    <div class="flex relative pb-9 h-40">
                      <span class="pt-2 w-0">$</span>
                      <FormulateInput
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          input-class="w-48 h-7 ml-3 text-right py-4 px-0 border outline-none"
                          error-behavior="live"
                          :value="parsePrice(product.rrp.monthly)"
                          :name="`${removeSpaces(product.name)}.rrp.monthly`"
                          v-on:input="perMonthInput($event,index,false,product.pricing.monthly)"
                          autocomplete="off"
                        />
                        <ul
                          ref="perMonthRef"
                          v-html="perMonthDesc[index]"
                          class="absolute mt-12 ml-6 opacity-90 text-xs list-disc text-left"
                        ></ul>
                    </div>
                  </td>
                  <td class="pt-5 px-5 text-center">
                    <div class="flex relative pb-9 h-40">
                      <span class="pt-2 w-0">$</span>
                      <FormulateInput
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          input-class="w-48 h-7 ml-3 text-right py-4 px-0 border outline-none"
                          error-behavior="live"
                          :value="parsePrice(product.rrp.yearly)"
                          :name="`${removeSpaces(product.name)}.rrp.yearly`"
                          v-on:input="yearlyPerMonthInput($event,index,false,product.pricing.yearly)"
                          autocomplete="off"
                        />
                        <ul
                          ref="yearlyPerMonthRef"
                          class="absolute mt-12 ml-8 opacity-90 text-xs list-disc text-left"
                          v-html="yearlyPerMonthDesc[index]"
                        ></ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- <div class="mt-5 mb-4" v-if="prepaidEWDRrp">
              <div class="rounded-lg">
                <table v-if="!loading" class="table-auto leading-normal">
                <tr>
                  <td class="bg-gray-100 w-2/5 text-left pl-6 pr-2" rowspan="6">
                    <p class="w-40 pl-1">Pre-paid EWD</p>
                  </td>
                  <th class="bg-gray-200">
                    <p class="text-center"> Packs </p>
                  </th>
                  <th class="bg-gray-200">
                    <p class="text-center"> RRP </p>
                  </th>
                </tr>
                <tr class="border-t-2">
                  <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                    1 Day
                  </td>
                  <td class="w-full pt-3 pr-7 pb-12 text-center">
                    <div class="flex relative">
                      <span class="pt-4 pl-11 w-0">$</span>
                        <FormulateInput
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          class="p-0 w-full	"
                          input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                          :value="parsePrice(prepaidEWDRrp.oneDay)"
                          :name="`rrpOneDay`"
                          v-on:input="prepaidPerDayInput($event,prepaidEWDPricing.oneDay,1)"
                        />
                        <ul
                          class="absolute mt-12 ml-12 pl-6 opacity-90 text-xs list-disc text-left"
                          v-html="prepaidOneDayDesc"
                        ></ul>
                      </div>
                    </td>
                    </tr>
                    <tr class="border-t-2">
                      <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                        5 Days
                      </td>
                      <td class="w-full pt-3 pr-7 pb-12 text-center">
                      <div class="flex relative">
                        <span class="pt-4 pl-11 w-0">$</span>
                        <FormulateInput
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="0"
                          class="p-0 w-full	"
                          input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                          :value="parsePrice(prepaidEWDRrp.fiveDays)"
                          :name="`rrpFiveDays`"
                          v-on:input="prepaidPerDayInput($event,prepaidEWDPricing.fiveDays,5)"
                        />
                        <ul
                          class="absolute mt-12 ml-12 pl-6 opacity-90 text-xs list-disc text-left"
                          v-html="prepaidFiveDaysDesc"
                        ></ul>
                      </div>
                      </td>
                      </tr>
                      <tr class="border-t-2">
                        <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                          10 Days
                        </td>
                        <td class="w-full pt-3 pr-7 pb-12 text-center">
                          <div class="flex relative">
                            <span class="pt-4 pl-11 w-0">$</span>
                            <FormulateInput
                              type="number"
                              step="0.01"
                              min="0"
                              placeholder="0"
                              class="p-0 w-full	"
                              input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                              :value="parsePrice(prepaidEWDRrp.tenDays)"
                              :name="`rrpTenDays`"
                              v-on:input="prepaidPerDayInput($event,prepaidEWDPricing.tenDays,10)"
                            />
                            <ul
                              class="absolute mt-12 ml-12 pl-6 opacity-90 text-xs list-disc text-left"
                              v-html="prepaidTenDaysDesc"
                            ></ul>
                          </div>
                        </td>
                      </tr>
                      <tr class="border-t-2">
                        <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                          25 Days
                        </td>
                        <td class="w-full pt-3 pr-7 pb-12 text-center">
                          <div class="flex relative">
                            <span class="pt-4 pl-11 w-0">$</span>
                            <FormulateInput
                              type="number"
                              step="0.01"
                              min="0"
                              placeholder="0"
                              class="p-0 w-full	"
                              input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                              :value="parsePrice(prepaidEWDRrp.twentyFiveDays)"
                              :name="`rrpTwentyFiveDays`"
                              v-on:input="prepaidPerDayInput($event,prepaidEWDPricing.twentyFiveDays,25)"
                            />
                            <ul
                              class="absolute mt-12 ml-12 pl-6 opacity-90 text-xs list-disc text-left"
                              v-html="prepaidTwentyFiveDaysDesc"
                            ></ul>
                          </div>
                        </td>
                      </tr>
                      <tr class="border-t-2">
                        <td class="w-3/5 pl-5 pr-9 text-center text-sm">
                          50 Days
                        </td>
                        <td class="w-full pt-3 pr-7 pb-12 text-center">
                          <div class="flex relative">
                            <span class="pt-3 pl-11 w-0">$</span>
                            <FormulateInput
                              v-if="prepaidEWDPricing.fiftyDays"
                              type="number"
                              step="0.01"
                              min="0"
                              placeholder="0"
                              class="p-0 w-full	"
                              input-class="h-7 ml-3 mt-1 text-right py-4 px-0 border outline-none"
                              :value="parsePrice(prepaidEWDRrp.fiftyDays)"
                              :name="`rrpFiftyDays`"
                              v-on:input="prepaidPerDayInput($event,prepaidEWDPricing.twentyFiveDays,50)"
                            />
                            <ul
                              ref="prepaidFiftyDaysRef"
                              class="absolute mt-12 ml-12 pl-6 opacity-90 text-xs list-disc text-left"
                              v-html="prepaidFiftyDaysDesc"
                            ></ul>
                        </div>
                      </td>
                    </tr>
                </table>
              </div>
            </div> -->
          </div>
        </div>
        <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
          <button class="btn col-span-2 btn-primary mt-2" :style="{backgroundColor: theme.secondaryColor}">Next</button>
          <button @click.prevent="onPrevious" class="btn bg-transparent border col-span-2 btn-info mt-2">Back</button>
        </div>
      </div>
  </FormulateForm>
</template>
<script>
import _ from "lodash";
import { mapGetters } from 'vuex'

export default {
  name: 'Standard-Pricing-RRP',
  props: {
    msg: String,
    preData: null,
    postData: null
  },
  data() {
    return {
      errorMessage: '',
      loading: false,
      values: {},
      perMonthDesc: [],
      yearlyPerMonthDesc: [],
      prepaidOneDayDesc: null,
      prepaidFiveDaysDesc: null,
      prepaidTenDaysDesc: null,
      prepaidTwentyFiveDaysDesc: null,
      prepaidFiftyDaysDesc: null,
      prepaidEWDRrp: null,
      prepaidEWDPricing: null,
      interChangingData: []
    };
  },
  mounted() {
    this.interChangingData = this.preData ? this.preData: this.postData
    this.values = this.interChangingData.pricing;
    // this.prepaidEWDRrp = this.interChangingData.prepaid.rrp;
    // this.prepaidEWDPricing = this.interChangingData.prepaid.pricing;

    this.values.forEach((value, index) => {
      this.perMonthInput(
        this.parsePrice(value.rrp.monthly),index,true,this.parsePrice(value.pricing.monthly)
      )
      this.yearlyPerMonthInput(
        this.parsePrice(value.rrp.yearly),index,true,this.parsePrice(value.pricing.yearly)
      )
    })
    // // Pre-paid init
    // this.prepaidPerDayInput(
    //   this.prepaidEWDRrp.oneDay,this.prepaidEWDPricing.oneDay,1
    // )
    // this.prepaidPerDayInput(
    //   this.prepaidEWDRrp.fiveDays,this.prepaidEWDPricing.fiveDays,5
    // )
    // this.prepaidPerDayInput(
    //   this.prepaidEWDRrp.tenDays,this.prepaidEWDPricing.tenDays,10
    // )
    // this.prepaidPerDayInput(
    //   this.prepaidEWDRrp.twentyFiveDays,this.prepaidEWDPricing.twentyFiveDays,25
    // )
    // this.prepaidPerDayInput(
    //   this.prepaidEWDRrp.fiftyDays,this.prepaidEWDPricing.fiftyDays,50
    // )
  },
  methods: {
    parsePrice(price){
      return parseFloat(price).toFixed(2);
    },
    parsePriceString(marginPercent, margenProfit, profit, price){
      if(parseFloat(price.RRP) >= parseFloat(price.buyPrice)){
        return `<li>${marginPercent}% Margin per month</li><li>$${profit} Margin per month</li><li>$${margenProfit} Per month per seat</li>`;
      } else {
        return `<div class="text-red-500">RRP must not lower than the product buy price.</div>`;
      }
    },
    // parsePrepaidPriceString(marginPercent, margenProfit, profit, price){
    //   if(parseFloat(price.RRP) > parseFloat(price.buyPrice)){
    //     return `<li>${marginPercent}% Margin</li><li>$${profit} Margin</li><li>$${margenProfit} Per day</li>`;
    //   } else {
    //     return `<li class="text-red-500">RRP must not lower than the product buy price.</li>`;
    //   }
    // },
    perMonthInput(RRP, index, init, buyPrice){
      let percentage = this.parsePrice(((RRP-buyPrice)/RRP)*100);
      let margenProfit = this.parsePrice(RRP/1);
      const profit = this.parsePrice(RRP-buyPrice);

      const price = {
        RRP: RRP,
        buyPrice: buyPrice
      }

      if(init){
        this.perMonthDesc.push(this.parsePriceString(percentage,margenProfit,profit, price));
      } else {
        this.$parent.$children[0].$refs.perMonthRef[index].innerHTML
          = this.parsePriceString(percentage,margenProfit,profit, price);
      }
    },
    yearlyPerMonthInput(RRP, index, init, buyPrice){
      let percentage = this.parsePrice(((RRP-buyPrice)/RRP)*100);
      let margenProfit = this.parsePrice(RRP/12);
      const profit = this.parsePrice((RRP-buyPrice)/12);
      const price = {
        RRP: RRP,
        buyPrice: buyPrice
      }
      if(init){
        return this.yearlyPerMonthDesc.push(this.parsePriceString(percentage,margenProfit,profit, price));
      } else {
        this.$parent.$children[0].$refs.yearlyPerMonthRef[index].innerHTML
          = this.parsePriceString(percentage,margenProfit,profit, price)
      }
    },
    // prepaidPerDayInput(RRP, buyPrice, days){
    //   let percentage = this.parsePrice(((RRP-buyPrice)/RRP)*100);
    //   let margenProfit = this.parsePrice(RRP/days);
    //   const profit = this.parsePrice(((RRP-buyPrice)/days)*days);
    //   const price = {
    //     RRP: RRP,
    //     buyPrice: buyPrice
    //   }
    //   let prepaid = this.parsePrepaidPriceString(percentage,margenProfit,profit, price);
    //   switch (days) {
    //     case 1:
    //       this.prepaidOneDayDesc = prepaid;
    //       break;
    //     case 5:
    //       this.prepaidFiveDaysDesc = prepaid;
    //       break;
    //     case 10:
    //       this.prepaidTenDaysDesc = prepaid;
    //       break;
    //     case 25:
    //       this.prepaidTwentyFiveDaysDesc = prepaid;
    //       break;
    //     case 50:
    //       this.prepaidFiftyDaysDesc = prepaid;
    //       break;
    //   }
    // },
    removeSpaces(string){
      return string.replace(/\s+/g, '');
    },
    onNext(formData) {

      let setValues = [];

      // format values for validation
      this.values.forEach((obj, ind) => {
        if(obj){
          let objData = Object(formData)
          let rrp = {
            monthly: this.parsePrice(objData[`${this.removeSpaces(obj.name)}.rrp.monthly`]),
            yearly: this.parsePrice(objData[`${this.removeSpaces(obj.name)}.rrp.yearly`]),
          }
          const data = {
            name: obj.name,
            pricing: this.interChangingData.pricing[ind].pricing,
            standardPricingMasterId: this.interChangingData.pricing[ind].standardPricingMasterId,
            rrp: rrp
          }
          setValues.push(data);
        }
      });

      // Validate value
      let err = [];
      setValues.forEach(price => {
        if(price.name === price.name){
          if(parseFloat(price.pricing.monthly) > parseFloat(price.rrp.monthly)
          || parseFloat(price.pricing.yearly) > parseFloat(price.rrp.yearly)
          ){
            err.push(price.pricing.standardPricingMasterId)
          }
        }
      });

      // let prepaidData = formData;
      // const prepaidPRrp = {
      //   oneDay: this.parsePrice(prepaidData.rrpOneDay),
      //   fiveDays: this.parsePrice(prepaidData.rrpFiveDays),
      //   tenDays: this.parsePrice(prepaidData.rrpTenDays),
      //   twentyFiveDays: this.parsePrice(prepaidData.rrpTwentyFiveDays),
      //   fiftyDays: this.parsePrice(prepaidData.rrpFiftyDays)
      // }
      // let prepaid = {
      //   pricing: this.prepaidEWDPricing,
      //   rrp: prepaidPRrp,
      //   standardPricingMasterId: this.interChangingData.prepaid.standardPricingMasterId,
      //   name: this.interChangingData.prepaid.name
      // }

      // if(parseFloat(prepaid.pricing.oneDay) > parseFloat(prepaid.rrp.oneDay)
      // || parseFloat(prepaid.pricing.fiveDays) > parseFloat(prepaid.rrp.fiveDays)
      // || parseFloat(prepaid.pricing.tenDays) > parseFloat(prepaid.rrp.tenDays)
      // || parseFloat(prepaid.pricing.twentyFiveDays) > parseFloat(prepaid.rrp.twentyFiveDays)
      // || parseFloat(prepaid.pricing.fiftyDays) > parseFloat(prepaid.rrp.fiftyDays)
      // ){
      //   err.push(prepaid.pricing.standardPricingMasterId)
      // }

      // Validation toast
      if(!_.isEmpty(err)){
        this.$store.commit('setDialogNotify',true);
        this.$store.commit('setMessageNotify',{state:'error',message:'RRP must not lower than the product buy price.'});
        return;
      }

      const data = {...this.interChangingData, ...{
        pricing: setValues,
        // prepaid: prepaid,
      }}

      this.$emit('nextStep', data)
    },
    onPrevious() {
      const data = {...this.interChangingData, ...{
        pricing: this.values
      }}

      this.$emit('backStep', data)
    },
  },
  computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
   }
}
</script>
<style scoped lang="scss">
th {
  @apply px-5 py-3 border-gray-200 text-sm font-semibold text-gray-600 uppercase;
}
</style>