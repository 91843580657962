import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

class Payment {

    // @Payment Gateway
    createPaymentGateway(data) {
        return API.ACCOUNTS.post(`/payment-gateway`, data);
    }

    getPaymentGateway(id) {
        return API.ACCOUNTS.get(`/payment-gateway/${id}`)
    }

    getPaymentGatewayByEntityId(id) {
        return API.ACCOUNTS.get(`/payment-gateway/find-by-entity-id/${id}`)
    }

    getActivePaymentGatewayByEntityId(id) {
        return API.ACCOUNTS.get(`/payment-gateway/find-active-by-entity-id/${id}`)
    }

    getPaymentGatewayByUid(data) {
        return API.ACCOUNTS.post(`/payment-gateway/find-by-uid`, data)
    }

    patchPaymentGateway(data) {
        return API.ACCOUNTS.patch(`/payment-gateway/${data.id}`, data);
    }

    deletePaymentGateway(id) {
        return API.ACCOUNTS.delete(`/payment-gateway/${id}`);
    }

    // @Payment Method
    createCardPaymentMethod(data) {
        return API.ACCOUNTS.post(`/payment-method/card`, data);
    }

    chargeCard(data) {
        return API.ACCOUNTS.post(`/payment-method/charge`, data);
    }

    getPaymentMethod(id) {
        return API.ACCOUNTS.get(`/payment-method/${id}`)
    }

    getPaymentMethodByEntityId(id) {
        return API.ACCOUNTS.get(`/payment-method/find-active-by-entity-id/${id}`)
    }

    getPaymentMethodByCardId(stripeCardId) {
        return API.ACCOUNTS.get(`/payment-method/find-active-by-stripe-card-id/${stripeCardId}`)
    }


    getAllPaymentMethodByEntityId(id) {
        return API.ACCOUNTS.get(`/payment-method/find-by-entity-id/${id}`)
    }

    patchPaymentMethod(data) {
        return API.ACCOUNTS.patch(`/payment-method/card/${data.id}`, data);
    }

    deletePaymentMethod(id) {
        return API.ACCOUNTS.delete(`/payment-method/${id}`)
    }

    getPaymentHistoryByEntityId(data, query = { skip: 0, limit: 10, filter: {}, from: '', to: '' }) {
        let filter = '';
        if (typeof query.filter === 'object') try { filter = JSON.stringify(query.filter) } catch (e) { throw 'Invalid Filter object' }
        query.filter = filter;
        if (query) query = '?' + ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/payment-history/find-by-entity-id/${data.id}${query}`)
    }
    getActivePaymentGatewayByUserType(userType) {
        return API.ACCOUNTS.get(`payment-gateway/active/users/type/${userType}`)

    }

}

export default new Payment