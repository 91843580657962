<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <FormulateForm v-model="values" @submit="onSubmit">
        <FormulateInput
          type="email"
          name="email"
          label="User email"
          validation="^required|email"
          @input="handleStritEmailCase($event)"
          :disabled="this.driverId"
        />
        <FormulateInput
          type="text"
          name="userName"
          label="Full Name"
          validation="required"
        />
        <FormulateInput
          type="text"
          name="phoneNumber"
          label="Phone No."
          validation="required"
        />
        <FormulateInput
          type="select"
          name="roleId"
          label="Role"
          validation="required"
          :options="roleOptions"
        />
        <div>
          <label class="text-sm font-semibold form-label pr-2 text-neutral-dark"
            >User Access</label
          >
          <div class="flex items-center mt-2">
            <input
              type="checkbox"
              v-model="rootAccess"
              :checked="rootAccess"
              class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2 cursor-pointer"
            />
            <span class="ml-2 text-sm">Allow access to main business</span>
          </div>
        </div>

        <div class="mb-4 mt-5">
          <div
            v-if="depots.length"
            class="mt-3 border rounded p-5"
            id="depotContainer"
          >
            <label class="font-bold text-sm mb-3 block"
              >Please select which business site</label
            >
            <DepotTree
              v-for="depot in depots"
              :key="depot._id"
              :item="depot"
              class="my-3"
              @tick="onTick($event)"
              @default="setDefault($event)"
            />
          </div>
        </div>

        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            input-class="btn btn-primary w-full"
            :disabled="submitLoading"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <span v-if="submitLoading"
              ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
              data...</span
            >
            <span v-if="!submitLoading">Submit</span>
          </FormulateInput>
        </StyledSubmit>
      </FormulateForm>
    </div>

    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import DepotTree from "./depot-tree.vue";
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";
import { findDepotById } from "@/_helper/";

export default {
  name: "New-User-Form",
  props: {
    msg: String,
    defaultData: null,
    driverId: null
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  components: {
    StyledSubmit,
    DepotTree,
  },
  data() {
    return {
      me: null,
      error: false,
      errorMessage: "",
      loading: false,
      submitLoading: false,
      values: {},
      roleOptions: [],
      depots: [],
      defaultSite: null,
      rootAccess: false,
    };
  },
  async mounted() {
    this.loading = true;
    const roles = await this.$store.dispatch(`rolesandrespo/getRoles`, null);
    if (roles) this.roleOptions = this.formatRoles(roles);

    if (this.driverId) {
      const driverDetails = await this.$store.dispatch(`driver/getDriverByID`, this.driverId);
      this.values.email = driverDetails?.emailAddress;
      this.values.userName = driverDetails?.driverName;
    }

    this.me = this.$store.getters[`account/me`];
    this.depots = await this.$store.dispatch(
      `business/getDepot`,
      this.me?.entity?._id
    );
    if (this.defaultData) {
      const { email, userName, phoneNumber, webProfileJobId } = this.defaultData;
      this.values = {
        email,
        userName,
        phoneNumber,
        webProfileJobId
      };
    }
    this.loading = false;

    // Set first site as default
    setTimeout(() => {
      const firstSite = $(".btn-defaultsite");
      if (firstSite.length) {
        $(firstSite[0]).removeClass("isNotDefault");
        this.defaultSite = $(firstSite[0]).attr("data-id");
      }
    }, 100);
  },
  watch: {
    rootAccess: {
      immediate: true,
      handler() {
        this.showDefaultButton();
      },
    },
  },
  methods: {
    showDefaultButton() {
      let checkedSiteIds = [];
      const checkedDepots = $("#depotContainer").find("input:checkbox");
      $.each(checkedDepots, (index, value) => {
        const depotParent = $(value).parent();
        const defaultBtn = depotParent.find(`.btn-defaultsite`);
        if (value.checked) {
          const isLeaf = depotParent.attr("data-leaf");
          if (isLeaf) checkedSiteIds.push(depotParent.attr("data-id"));
        }

        if (value.checked && !this.rootAccess) defaultBtn.show();
        else defaultBtn.hide();
      });

      // If default site is not on the checked sites, set first site as the default
      if (!checkedSiteIds.includes(this.defaultSite)) {
        this.setDefault({ _id: checkedSiteIds[0] });
      }
    },
    setDefault(item) {
      $(".btn-defaultsite").addClass("isNotDefault");
      $(`#default${item._id}`).removeClass("isNotDefault");

      this.defaultSite = item._id;
    },
    onTick(depot) {
      const elDepot = $("#depotContainer").find(`[data-id="${depot._id}"]`);
      const depth = elDepot.attr("data-depth");

      elDepot
        .parent()
        .parent()
        .children(":first-child")
        .find("input")
        .attr("checked", depot.isChecked);

      // Handle auto check/uncheck of child depots
      const childCheckboxes = elDepot.parent().find("input:checkbox");
      $.each(childCheckboxes, (index, checkbox) => {
        if (index != 0) $(checkbox).attr("checked", depot.isChecked);
      });

      // Handle auto check/uncheck of depot parents
      let element = elDepot;
      for (let index = depth; index >= 0; index--) {
        element = $(element).parent();
        if (index == depth) continue;

        const elCheckbox = element.children(":first-child").find("input");
        if (depot.isChecked) elCheckbox.attr("checked", true);
        else {
          const depotId = element.children(":first-child").attr("data-id");
          const checkedChildren = $.map(
            element.find("input:checkbox:checked"),
            (child) => {
              if ($(child).parent().attr("data-id") == depotId) return false;
              return child;
            }
          );

          if (checkedChildren.length == 0 && depotId != depot.pid) {
            elCheckbox.attr("checked", false);
          }
        }
      }

      // Hide/show default button
      this.showDefaultButton();
    },
    async onSubmit() {
      let depotSites = [];

      // Get checked site/region
      const checkedDepots = $("#depotContainer").find("input:checkbox:checked");
      $.each(checkedDepots, (index, value) => {
        const depotId = $(value).parent().attr("data-id");
        const depot = findDepotById(depotId, this.depots);
        if (!depot.isRegion) depotSites.push(depot._id);
      });

      this.submitLoading = true;

      const site = this.rootAccess ? null : this.defaultSite;
      const newUser = JSON.parse(JSON.stringify(this.values));
      let payload = {
        ...newUser,
        depotSites,
        site,
      };

      if (this.driverId) payload.hasDriverBusinessProfile = true;

      await this.$store
        .dispatch(`rolesandrespo/createUser`, payload)
        .then(() => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "User successfully created.",
          });
          this.submitLoading = false;
          this.$emit("closeRefresh");
        })
        .catch((err) => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
          this.submitLoading = false;
        });
    },
    formatRoles(roles) {
      const roleOptions = [];
      roles.forEach((role) => {
        const option = {
          value: role._id,
          label: role.name,
        };
        roleOptions.push(option);
      });

      return roleOptions;
    },
    handleStritEmailCase(value) {
      this.values.email = value.toLowerCase();
    },
  },
};
</script>
