<template>
  <div class="border-2 border-gray-300 p-7 rounded mb-16">
    <FormulateForm v-model="values" @submit="onSubmit" class="relative">
      <FormulateInput
        type="file"
        name="file"
        label="Select your document to upload"
        @change="onFileChange()"
      />

      <div class="grid grid-cols-2 gap-4 my-5">
        <button
          class="btn bg-gray-300 hover:bg-gray-400 w-full mb-3"
          @click.prevent="onPrevious()"
        >
          Back
        </button>
        <FormulateInput
          language="en"
          type="submit"
          label="Next"
          input-class="btn btn-primary w-full"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'Manual-Event-Document-Upload',
  props: {
    msg: String,
    preData: null,
  },
  data() {
    return {
      loading: false,
      file: [],
      values: {},
      errorMessage: '',
      schema: [],
      breaches: [],
      eventDetails: [],
    };
  },
  async mounted() {
    if (!_.isEmpty(this.preData['breaches'])) {
      this.breaches = this.preData['breaches'];
    }

    if (!_.isEmpty(this.preData['eventDetails'])) {
      this.eventDetails = this.preData['eventDetails'];
    }
  },
  methods: {
    onPrevious() {
      this.$emit('backStep');
    },
    async fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    onFileChange() {
      const files = this.values.file.files;
      files.forEach(async (file) => {
        this.file = {
          filename: file.name,
          filetype: file.file.type,
          fileimage: await this.fileToBase64(file.file),
        };
      });
    },
    async onSubmit() {
      this.$emit('nextStep', {
        eventDetails: this.eventDetails,
        breaches: this.breaches,
        file: this.file,
      });
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
};
</script>
