
import { API } from '@/api/config'

class Auth {
    //Auth
    async authenticate(data){
        return await API.ACCOUNTS.post(`/auth/signin`, data)
    }
    async authenticateUID(data){
        return await API.ACCOUNTS.post(`/auth/signin-via-uid`, data)
    }
    async authenticateToken(data){
        return await API.ACCOUNTS.post(`/auth/signin-via-token`, data)
    }
    async verifyCode(data){
        return await API.ACCOUNTS.post(`/auth/verify-code`, data)
    }
    async resetPassword(token,data){
        return await API.ACCOUNTS.patch(`/public/change-password/${token}`, data)
    }
    async changeEmailSetPassword(uid,data){
        return await API.ACCOUNTS.patch(`/public/change-email-set-password/${uid}`, data)
    }

    async disputeChangeEmail(data){
        return await API.ACCOUNTS.post(`/public/dispute-change-email`, data)
    }
    
    async requestResetPassword(data){
        return await API.ACCOUNTS.patch(`/public/forgot-password`, data)
    }
    async getSecurityRoles(data){
        return await API.ACCOUNTS.post(`/auth/security-roles`, data)
    }
    async getMe(){
        return API.ACCOUNTS.get(`/auth/me`) 
    }
    async getUser(id){
        return await API.ACCOUNTS.get(`/user/${id}`)
    }
    async getUserByUID(id){
        return await API.ACCOUNTS.get(`/user/findbyuid/${id}`)
    }
    async getRole(id){
        return await API.ACCOUNTS.get(`/role/${id}`)
    }
    async accountChangePassword( uid, data ){
        return await API.ACCOUNTS.patch(`/user/changepassword/${uid}`, data)
    }
    async changeEmail( uid, data ){
        return await API.ACCOUNTS.patch(`/user/change-email/${uid}`, data)
    }
    async updateUserPassword( id, data ){ // OLD
        return await API.ACCOUNTS.patch(`/user/updatepassword/${id}`, data)
    }

    async getLogmasterByWebProfileId(id) {
        return API.ACCOUNTS.post(`/logmaster/find-by-web-profile/${id}`)
    }
    async refreshToken(data){
        return API.ACCOUNTS.post(`/auth/refresh`, data)
    }
}

export default new Auth