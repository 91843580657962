<template>
    <div class="mb-16">
      <FormulateForm v-model="values" @submit="onSubmit" #default="{ hasErrors }">
        <div v-if="loading" class="text-center"><ui-spinner class="m-auto"></ui-spinner> Loading...</div>
        <div v-if="!loading" class="items-center justify-between">
          <FormulateInput
            type="text"
            name="fullname"
            label= 'Full Name'
            validation= 'required'
          />
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{backgroundColor: theme.secondaryColor}"
          />
        </StyledSubmit>
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
    </div>
</template>

<script>
import { convertAddressObject } from '@/_helper'
import schema from './new-business-schema';
import { mapGetters } from 'vuex'
import { StyledSubmit } from '@/styled-components'

export default {
  name: 'New-Business-Form',
  props: {
    msg: String
  },
  components: {StyledSubmit}, 
  data() {
    this.$emit('schema', schema)
    return {
      loading: false,
      values: {},
      schema: schema,
      errorMessage:''
    };
  },
  methods: {
    updateSchema: function(schema) {
      this.schema = schema
    },
    onSubmit() {
      const newBusiness = JSON.parse(JSON.stringify(this.values));
      newBusiness['persona'] = newBusiness.persona[0];
      if(newBusiness){
        newBusiness['persona']['businessAddress'] = convertAddressObject(newBusiness['persona']['businessAddress'])
        newBusiness['isDemoAccount'] = false;
        newBusiness['create_password'] = newBusiness['create_password'][0];
        newBusiness['supportEmail'] = newBusiness['persona']['contactEmail']
        newBusiness['supportPhone'] = newBusiness['persona']['contactPhoneNumber']
        this.loading = true;
        this.$store.dispatch(`business/createBusiness`, newBusiness).then(business => {
          if(business && business._id){
            console.log('New Business - ', business);
            location.reload();
          }
        }).catch(error => {
          this.errorMessage = `Something went wrong please try again.`;
          if(error.message) this.errorMessage = error.message[0]
        });
      }
    },

  },
   computed: {
      ...mapGetters('theme', {
      theme: 'getColorScheme'
      })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
