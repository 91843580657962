<template>
    <div>
        <vue-dropzone 
        :id="id" 
        ref="dropzone" 
        :options="dropzoneOptions" 
        :useCustomSlot="true" 
        :include-styling="false" 
        v-on:vdropzone-thumbnail="customThumbnail"
        v-on:vdropzone-file-added="added"
        >
            <slot></slot>
        </vue-dropzone>
    </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'


export default {
    name: 'Dropzone',
    components: {vueDropzone: vue2Dropzone },
    props: {
        dropzoneOpts: {
            type: Object,
            required: true,
            default() {
                return { 
                    url: 'https://httpbin.org/post',
                    thumbnailWidth: 50,
                    thumbnailHeight: 50,
                    maxFilesize: 0.5,
                    headers: { "My-Awesome-Header": "header value" },
                    uploadMultiple: false,
                    addRemoveLinks: true,
                    maxFiles: 1,
                    }
            }
        },
        id:{
            type: String,
            required: false,
            default: 'dropzone',
        },
        dropzoneRef:{
            type: String,
            required: false,
            default: 'ref',
        },
        customTemplate: {
            type: Function,
            required: false,
            default: function () {
                return `<div class="dz-preview dz-file-preview">
                        <div class="dz-image">
                            <div data-dz-thumbnail-bg></div>
                        </div>
                        <div class="dz-details">
                            <div class="dz-size"><span data-dz-size></span></div>
                            <div class="dz-filename"><span data-dz-name></span></div>
                        </div>
                        <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                        <div class="dz-error-message"><span data-dz-errormessage></span></div>
                        <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                        <div class="dz-error-mark"><font-awesome-icon icon="times" /></div>
                        <div class="dropzone-remove flex items-center justify-center w-full cursor-pointer" data-dz-remove>
                            <svg id="dropzone-remove" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 336.03 360.13"><path d="M828,431.92c-8.09,0-15.58,0-23.07,0s-13-5.16-12.93-12.11,5.38-11.88,12.76-11.88q155.24,0,310.48,0c7.36,0,12.72,5.06,12.79,11.86.07,7-5.34,12.08-12.9,12.13s-15,0-23.09,0v4.8q0,119.82,0,239.65c0,21-12.87,37.71-32.75,42.44a46.79,46.79,0,0,1-10.76,1.15q-88.31.1-176.62,0C846.24,720,828,701.67,828,676q0-119.64,0-239.28Zm240,.26H852V675.44c0,13.4,7.2,20.56,20.64,20.56H1047c14,0,21-7,21-20.94V432.18Z" transform="translate(-791.95 -359.89)"/><path d="M960.15,359.89q23.25,0,46.5,0c7.9,0,13.37,5,13.31,12.09-.05,6.94-5.46,11.9-13.12,11.9q-46.87,0-93.75,0c-7.65,0-13.07-5-13.12-11.91-.05-7.11,5.38-12.07,13.31-12.08Q936.71,359.87,960.15,359.89Z" transform="translate(-791.95 -359.89)"/><path d="M908.16,563.85c0-23.75,0-47.51,0-71.26,0-9.15,8.23-15,16.33-11.81,4.69,1.84,7.6,6.24,7.63,11.83.06,10.88,0,21.75,0,32.63V632.51c0,1.25,0,2.5,0,3.75A12.1,12.1,0,0,1,919.93,648a11.93,11.93,0,0,1-11.74-12.12C908.13,611.86,908.17,587.86,908.16,563.85Z" transform="translate(-791.95 -359.89)"/><path d="M1011.77,564.13q0,35.82,0,71.64a11.82,11.82,0,0,1-9,11.84,11.52,11.52,0,0,1-12.92-5.3,15.23,15.23,0,0,1-1.92-7.08q-.17-71.26-.07-142.52c0-7.4,5.08-12.7,11.91-12.77s12.06,5.33,12.07,12.93Q1011.82,528.5,1011.77,564.13Z" transform="translate(-791.95 -359.89)"/></svg>
                            <span class="font-xs">Remove</span>
                        </div>
                    </div>
                ` 
            }
        },
        customThumbnail: {
            type: Function,
            required: false,
            default: function(file, dataUrl) {
                    var j, len, ref, thumbnailElement;
                    if (file.previewElement) {
                        file.previewElement.classList.remove("dz-file-preview");
                        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
                        for (j = 0, len = ref.length; j < len; j++) {
                            thumbnailElement = ref[j];
                            thumbnailElement.alt = file.name;
                            thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
                        }
                        return setTimeout(((function() {
                            return function() {
                                return file.previewElement.classList.add("dz-image-preview");
                            };
                        })(this)), 1);
                    }
                }
        },
        dropzoneFileValue: {
            type: Object,
        },
        submit: {
            type: Function,
            required: false,
            default: function(file, dataUrl) {  
                    var j, len, ref, thumbnailElement;
                    if (file.previewElement) {
                        file.previewElement.classList.remove("dz-file-preview");
                        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
                        for (j = 0, len = ref.length; j < len; j++) {
                            thumbnailElement = ref[j];
                            thumbnailElement.alt = file.name;
                            thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
                        }
                        return setTimeout(((function() {
                            return function() {
                                return file.previewElement.classList.add("dz-image-preview");
                            };
                        })(this)), 1);
                    }
                }
        }
       
    },
    async mounted(){
        //Still under testing. Please don't use for now as there is some inconsistencies
        if(this.dropzoneFileValue){
            this.$refs.dropzone.manuallyAddFile(this.dropzoneFileValue, this.dropzoneFileValue.url) 
        }
    },
    data() {
        return{
            dropzoneOptions: {
                ...this.dropzoneOpts,
                previewTemplate: this.customTemplate(),
            },
            currentFile: null
        }
    },
    methods:{
        added(file) {
        if (this.currentFile !== null) {
            this.$refs.dropzone.removeFile(this.currentFile) 
        }
        this.currentFile = file
        this.$emit('getFile', file)
        } 
    }


}
</script>