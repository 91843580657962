<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading" class="text-center mb-10">
      <div class="flex text-right justify-end">
        <StyledIconedLink
          v-if="writeUsers"
          @click.prevent="onClickEdit()"
          :iconHoverColor="theme.secondaryColor"
          class="flex cursor-pointer mx-1.5"
          ><font-awesome-icon
            icon="edit"
            class="my-auto text-xs"
        /></StyledIconedLink>
        <StyledIconedLink
            @click.prevent="onClickDelete()"
            class="flex cursor-pointer mx-1.5"
            :iconHoverColor="theme.secondaryColor"
        ><font-awesome-icon
              icon="trash"
              class="my-auto text-xs"
          />
        </StyledIconedLink>
      </div>
      <div
        class="
          font-bold
          text-gray-600 text-4xl
          border-4
          rounded
          w-max
          p-2
          mx-auto
          mb-2
        "
      >
        {{ user.userName }}
      </div>
      <div
        class="font-bold text-xs mb-5"
        :class="[user.isActive ? 'text-green-500' : 'text-red-500']"
      >
        {{ user.isActive ? "ACTIVE" : "INACTIVE" }}
      </div>
    </div>
    <div class="mb-5 w-full border-b pb-1">
      <div class="text-xs font-bold">User Details</div>
    </div>
    <div class="mb-5">
      <div class="font-bold text-black-primary text-base">
        {{ user.email }}
      </div>
      <div class="text-xs">User Email</div>
    </div>
    <div class="mb-5">
      <div class="font-bold text-black-primary text-base">
        {{ user.phoneNumber }}
      </div>
      <div class="text-xs">User Phone No.</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {StyledIconedLink} from '@/styled-components'

export default {
  name: "User-Details-View",
  components: {StyledIconedLink}, 
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      user: {},
      loading: false,
      writeUsers: false,
      error: false,
      errorMessage: "",
    };
  },
  async mounted() {
    this.loading = true;
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.writeUsers = securityAccessObj.writeUsers;
    }

    const userDetails = await this.$store.dispatch(
      `rolesandrespo/getUser`,
      this.detailsId
    );
    if (userDetails) {
      this.user = this.parseUser(userDetails);
    }
    this.loading = false;
  },
  methods: {
    parseUser(user) {
      return {
        id: user._id,
        email: user.email,
        userName: user.userName,
        phoneNumber: user.phoneNumber,
        isActive: user.isActive,
      };
    },
    setSecurityAccess: (securityRoles) => {
      let initialWriteUsers = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.permissions.writeUsers.value) {
          initialWriteUsers = securityConfig.permissions.writeUsers.value;
        }
      });

      return {
        writeUsers: initialWriteUsers,
      };
    },
    onClickEdit() {
      this.$emit("editUser", this.detailsId);
    },
    onClickDelete() {
      if(confirm('Are you sure you want to delete this vehicle?')) {
        this.$store.dispatch(`business/deleteBusiness`, "123").then(user => {
          if(user && user._id){
            this.$emit('closeRefresh');
          }
        }).catch(error => {
          this.errorMessage = `Something went wrong please try again.`;
          if(error.message) this.errorMessage = error.message[0]
        });
      }
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
};
</script>
