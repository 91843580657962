<template>
  <StyledIconedLink
    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
    v-if="uid && role && enabled"
    @click.prevent="DrillDown(uid, role)"
    :iconHoverColor="theme.secondaryColor"
  >
    <font-awesome-icon icon="level-down-alt" class="my-auto mr-2" /> Drill Down
  </StyledIconedLink>
</template>
<script>
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "DrillDOwn",
  props: {
    uid: String,
    role: String,
  },
  components: { StyledIconedLink },
  data() {
    return { enabled: true };
  },
  mounted() {},
  methods: {
    DrillDown: async function (id, roleName) {
      this.$store.commit("account/setProxyRole", roleName);
      await this.$store.dispatch(`account/proxyUser`, { uid: id });
      window.location.href = this.$store.getters["allURL"][roleName]["default"];
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
