
export default {
  setSelectedControl(state, value) {
    state.selectedControl = value
  },

  setFormControls(state, value) {
    state.formControls = value
  },

  setUpdatedControl(state, value) {
    state.updatedControl = value
  },

  setFormSections(state, value) {
    state.formSections = [...value]
  },

  setSelectedSection(state, value) {
    state.selectedSection = value
  },

  setSelectedControlSection(state, value) {
    const response = state.formSections.find(obj => obj.uniqueId == value.uniqueId)
    state.selectedControlSection = response
  },
}
