<template>
  <div>
    <Table
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="tslsData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="block relative">
          <FormulateInput
            type="driver-multiselect"
            placeholder="Select a driver"
            style="margin-bottom: 0px; width: 220px"
            :isSelectAllEnabled="false"
            validation="required"
            @selected="onSelect"
            @clear="onClear"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown>
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewTSL(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="xl" :height="modalHeight">
      <div v-if="modalContent == 'view'">
          <div class="mb-16">
            <div class="text-center mb-10">
                <div class="font-bold text-black-primary text-2xl"> 
                    {{modalData.driverName}}
                </div>
                <div class="font-bold text-sm text-neutral-dark">{{ modalData.date }}</div>
            </div>
            <div class="mb-5 w-full border-b pb-1"></div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{modalData.field}}</div>
                <div class="text-xs">Field</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{modalData.from || '-'}}</div>
                <div class="text-xs">From</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{modalData.to || '-'}}</div>
                <div class="text-xs">To</div>
            </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { Modal, Table, Dropdown } from "@/components/commons";
import { formatPaginationSettings } from "@/_helper";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "TSL-Management",
  components: {
    Modal,
    Table,
    Dropdown,
    StyledIconedLink
  },
  data() {
    return {
      tslChanges: [],
      modalHeight: "65vh",
      modalContent: false,
      modalTitle: "",
      modalData: null,
      tableHeader: ["Driver", "Date", "Field", "From", "To"],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 10,
        visiblePageItemCount: 3,
      },
      filter: {
        limit: 10,
        search: "",
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      selectedDrivers: []
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    await this.init();
    this.$emit("updateLoading", false);
  },
  methods: {
    async init(paged = 1) {
      const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.selectedDrivers.length > 0) {
        query.filter = {
          driverId: { $in: this.selectedDrivers.map(x => x.driverId) }
        }
      }

      const changes = await this.$store.dispatch(
        `tsl/getTSLChangesByBusinessId`,
        { businessId: me.businessId, query }
      );

      if (changes) {
        if (changes?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            changes?.metadata
          );
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const tslChangesTableData = _.orderBy(
          changes.results,
          ["createdAt"],
          ["desc"]
        );
        this.tslChanges = tslChangesTableData;

        this.$emit("updateLoading", false);
      } else {
        this.tslChanges = [];
        this.$emit("updateLoading", false);
      }
    },
    onClear() {
      this.selectedDrivers = [];
    },
    async onSelect(drivers) {
      this.selectedDrivers = drivers;
      await this.init();
    },
    viewTSL(id) {
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "TSL Edit";
      this.modalData = _.find(this.tslChanges, (tsl) => tsl._id == id);
    },
    parseTSLChanges(data) {
      let parseData = [];
      Object.values(data).forEach((tslData) => {
        parseData.push([
          {
            id: tslData.id,
            name: tslData.driverName,
            itemType: "driverName",
            hasImage: false,
          },
          { id: tslData.id, name: tslData.date, itemType: "string" },
          { id: tslData.id, name: tslData.field, itemType: "string" },
          { id: tslData.id, name: tslData.from, itemType: "string" },
          { id: tslData.id, name: tslData.to, itemType: "string" },
        ]);
      });
      return parseData;
    },
    async onPageChange(event) {
      if (event.page) await this.init(event.page);
    },

    async filterChange() {
      await this.init();
    },
    filterRow(keyword, attributeToFilter) {
      return keyword
        .toLowerCase()
        .split(" ")
        .every((v) => attributeToFilter.trim().toLowerCase().includes(v));
    },
    async onSearch() {
      await this.init();
    },
    async toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    async closeModal() {
      this.$refs.modal.closeModal();
      this.$emit("updateLoading", true);
      await this.init();
      this.$emit("updateLoading", false);
    },
  },
  computed: {
    tslsData() {
      if (this.tslChanges) {
        let tslsComputedData = [];
        this.tslChanges.map((tsl) => {
          tsl.driverName = tsl.driver.driverName;
          tsl.date = moment(tsl.date).format('YYYY-MM-DD');
          tslsComputedData.push({
            id: tsl._id,
            driverName: tsl.driverName,
            date: tsl.date,
            field: tsl.field,
            from: tsl.from,
            to: tsl.to,
          });
        });

        return this.parseTSLChanges(tslsComputedData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      isDarkModeValue: "getIsDarkMode",
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
::v-deep .lhv-datepicker.no-nav .lhv-input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
</style>
