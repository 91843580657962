<template>
  <div>
    <FormulateInput
      v-if="isDisplayValue"
      type="password"
      :id="control.id"
      :name="control.name"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
      v-model="control.value"
      :required="control.required"
    />
    <FormulateInput
      v-else
      type="password"
      :id="control.id"
      :name="control.name"
      :placeholder="control.placeholder"
      :help="control.help"
      :label="control.label"
    />
  </div>
</template>

<script>
import { HELPER } from '@/api/helper';
export default {
  props: {
    control: {
      type: Object,
      default: () => {}
    },
    isDisplayValue: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    validation() {
      const control = HELPER.cloneDeep(this.control)
      const validation = []
      if (control.required == 'required') {
        validation.push(['required'], ['number'])
      }

      if (control.min != '') {
        validation.push(['min', control.min, 'length'])
      }
      if (control.max != '') {
        validation.push(['max', control.max, 'length'])
      }

      return validation
    }
  }
}
</script>
