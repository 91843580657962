<template>
  <div>
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
    <div v-if="!isLoading">
      <FormulateForm
        v-model="values"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <FormulateInput
          label="Acknowledged By (You)"
          type="text"
          v-model="loggedInWebProfile.userName"
          :disabled="true"
          style="color: blueviolet"
        />
        <FormulateInput
          label="Acknowledged Date"
          type="date"
          name="acknowledgeDate"
        />
        <div class="w-full p-2">Sign Below</div>
        <VueSignaturePad
          id="signature"
          class="canvas bg-gray-200"
          ref="signaturePad"
          scaleToDevicePixelRatio
        />
        <button
          class="btn btn-secondary w-full mb-2"
          @click.prevent="clearSignature"
        >
          Clear Signature
        </button>
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors || isLoading"
        />
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { ROLES } from "../../../_helper/constants";
export default {
  name: "Acknowledgement-Form",
  components: {},
  props: {
    loggedInWebProfile: Object,
    filledFormType: String,
    formId: String,
    businessId: String,
  },
  data() {
    return {
      isLoading: false,
      values: {acknowledgeDate: moment().format("YYYY-MM-DD")},
      error: false,
      errorMessage: null,
      signatureBuffer: null,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      const { acknowledgeDate } = this.values;
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.signatureBuffer = data;
      if (isEmpty) {
        this.isLoading = false;
        this.error = true;
        this.errorMessage = "Please provide your signature";
      }
      const payload = {
        acknowledgeDate,
        acknowledgeSignature: null,
        filledFormType: this.filledFormType,
        formId: this.formId,
      };

      const uploadedSignature = await this.uploadSignature();
      if (!this.error && uploadedSignature) {
        // createdUserDocument.document is link of where signature is stored
        payload.acknowledgeSignature = uploadedSignature[0].data.data.document;
        try {
          await this.$store.dispatch("form/acknowledgeForm", payload);
          this.isLoading = false;
          this.$emit("closeModal");
          this.$emit(
            "toast",
            "success",
            "Acknowledgement successfully submitted."
          );
          setTimeout(() => {
            location.reload();
          }, 2000);
        } catch (e) {
          this.isLoading = false;
          this.$emit("closeModal");
          this.$emit("toast", "error", "Opps, something went wrong.");
        }
      }
    },
    clearSignature() {
      this.$refs.signaturePad.openSignaturePad();
      this.$refs.signaturePad.clearSignature();
    },
    async uploadSignature() {
      try {
        const signaturePayload = {
          fileType: "SIGNATURE",
          userMongoId: this.businessId,
          userType: ROLES.BUSINESS,
          file: this.signatureBuffer,
          isBase64: true,
          mimetype: "image/png",
        };
        return this.$store.dispatch(`accreditation/uploadUserDocument`, {
          signaturePayload,
        });
      } catch (e) {
        this.isLoading = false;
        this.error = true;
        this.errorMessage = "Upload failed";
        return;
      }
    },
  },
};
</script>
