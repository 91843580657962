<template>
  <div>
    <div v-for="content in currentSelected.content" :key="content.uuid">
      <div
        class="border-2 rounded-lg p-2 justify-center mb-6"
        v-if="content.part === 'left'"
      >
        <div class="flex flex-row justify-between">
          <label class="formulate-input-label formulate-input-label--before"
            >Left Content</label
          >
          <button @click.prevent="removeContent(content.id)">
            <font-awesome-icon :icon="['fas', 'trash']" />
          </button>
        </div>

        <div v-if="content.type === 'Admin Signature'">
          <!-- <FormulateInput
            label="Signer's Name"
            type="text"
            v-model="content.value"
            validation="max:50,length"
          /> -->
          <span>Admin Signature</span>
        </div>

        <div v-if="content.type === 'Signature Line'">
          <!-- <FormulateInput
            label="Signer's Name"
            type="text"
            v-model="content.value"
            validation="max:50,length"
          /> -->
          <span>Signature Line</span>
        </div>
      </div>

      <div
        class="border-2 rounded-lg p-2 justify-center mb-6"
        v-if="content.part === 'right'"
      >
        <div class="flex flex-row justify-between mb-2">
          <label class="formulate-input-label formulate-input-label--before"
            >Right Content</label
          >
          <button @click.prevent="removeContent(content.id)">
            <font-awesome-icon :icon="['fas', 'trash']" />
          </button>
        </div>

        <div v-if="content.type === 'Signature Line'">
          <!-- <FormulateInput
            label="Signer's Name"
            type="text"
            v-model="content.value"
            validation="max:50,length"
          /> -->
          <span>Signature Line</span>
        </div>

        <div v-if="content.type === 'Admin Signature'">
          <!-- <FormulateInput
            label="Signer's Name"
            type="text"
            v-model="content.value"
          /> -->
          <span>Admin Signature</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  props: {
    selectedType: {
      type: String,
    },
    selected: {
      type: Object,
    },
  },

  computed: {
    currentSelected: {
      get() {
        return this.$store.state.accreditation.selectedContainer;
      },
      set(newVal) {
        this.$store.state.accreditation.selectedContainer = newVal;
      },
    },

    docParts: {
      get() {
        return this.$store.state.accreditation.docParts;
      },
    },

    currentContent: {
      get() {
        const model = _.find(this.docParts, (data) => {
          return data.uuid === this.selected.uuid;
        });

        return model;
      },
    },
  },

  mounted() {
    console.log("selected:", this.selected);
  },

  methods: {
    removeContent(uuid) {
      const index = _.findIndex(this.currentContent.content, (data) => {
        return data.uuid === uuid;
      });

      this.currentContent.content.splice(index, 1);
    },
  },
};
</script>

<style scoped></style>
