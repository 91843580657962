<template>
  <div class="w-full overflow-y-auto border border-gray-200 rounded">
    <table class="min-w-full">
      <thead class="bg-gray-50">
        <tr class="text-gray-700 text-xs tracking-wider uppercase">
          <th scope="col" class="py-3 px-5 text-left">Annotation Flag</th>
          <th scope="col" class="py-3 px-5 text-left">Time of Intercept</th>
          <th scope="col" class="py-3 px-5 text-left">Location</th>
          <th scope="col" class="py-3 px-5 text-left">Annotation</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="annotations.length == 0" class="bg-white border-b text-xs text-gray-500 font-medium">
          <td colspan="9" class="py-3 px-5 text-sm text-center">
            No annotations this day
          </td>
        </tr>
        <tr
          v-for="(annotation, index) in annotations"
          :key="index"
          class="bg-white border-b text-xs text-gray-500 font-medium"
        >
          <td class="py-3 px-5 text-black-primary capitalize">
            {{ annotation.flag }}
          </td>
          <td class="py-3 px-5">
            {{ annotation.timeOfIntercept }}
          </td>
          <td class="py-3 px-5">
            {{ annotation.location }}
          </td>
          <td class="py-3 px-5">
            {{ annotation.annotation }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
    name: "Annotation",
    props: {
        data: Array
    },
    data() {
      return {
        annotations: []
      }
    },
    mounted() {
      this.annotations = this.data.reverse();
    }
};
</script>