<template>
  <div v-if="!isRecordKeeper" class="text-center">
    <h1>
      Not authorized to create document templates. Please contact your business
      admin.
    </h1>
  </div>
  <div v-else>
    <DocumentBuilder :editData="DEFAULT_TEMPLATE" />
  </div>
</template>
<script>
import DocumentBuilder from "@/components/forms/business/accreditation/document-builder";
import { DEFAULT_TEMPLATE } from "@/constants/induction-template";

export default {
  name: "document-builder",
  components: { DocumentBuilder },
  data() {
    return {
      isRecordKeeper: false,
      DEFAULT_TEMPLATE: DEFAULT_TEMPLATE,
    };
  },

  async mounted() {
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.isRecordKeeper = securityAccessObj.inductDrivers;
    }
  },

  methods: {
    setSecurityAccess(securityRoles) {
      let initialInductDrivers = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.drivers.inductDrivers.value) {
          initialInductDrivers = securityConfig.drivers.inductDrivers.value;
        }
      });
      return {
        inductDrivers: initialInductDrivers,
      };
    },
  },
};
</script>

<style scoped></style>
