<template>
  <div>
    <Table
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="eventsData"
    >
      <template slot="tableFilters">
        <div class="relative">
          <select
            v-model="filter.limit"
            @change="filterChange"
            class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option :value="10">10</option>
            <option :value="20">20</option>
            <option :value="50">50</option>
          </select>
          <div
            class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
          >
            <font-awesome-icon icon="caret-down" class="text-gray-400" />
          </div>
        </div>
        <DatePicker
          ref="childDatePicker"
          :date="selectedDateRange"
          @selected="filterByDate($event)"
          range
          noNavigation
          :clearable="false"
          class="mb-1 mt-1"
        />
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewEvent(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <template slot="callToActionTop">
        <button
          class="btn btn-primary"
          @click.prevent="newEvent()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Add Manual Event
        </button>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="5xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <div class="mb-16">
          <AddManualEvent @closeModal="closeModal" :soloDriver="driver" />
        </div>
      </div>
      <div v-if="modalContent == 'view'">
        <EventDetailsView :eventsData="modalData" />
      </div>
    </Modal>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { Modal, Table, Dropdown } from "@/components/commons";
import { formatPaginationSettings } from "@/_helper";
import DatePicker from "@/components/commons/field/DatePicker";
import {
  AddManualEvent,
  EventDetailsView,
} from "@/components/forms/driver/manual-event";

export default {
  name: "ManualEvent",
  components: {
    Modal,
    Table,
    Dropdown,
    DatePicker,
    AddManualEvent,
    EventDetailsView,
  },
  data() {
    return {
      driver: null,
      manualEvents: [],
      modalHeight: "80vh",
      modalContent: false,
      modalTitle: "",
      modalData: null,
      tableHeader: ["Event Date", "Total Events", "Entry Timestamp"],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 10,
        visiblePageItemCount: 3,
      },
      filter: {
        limit: 10,
        search: "",
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      selectedDateRange: [
        moment().subtract(7, "days").format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    this.selectedDateRange[0] = moment()
      .subtract(7, "days")
      .format("YYYY-MM-DD");
    this.selectedDateRange[1] = moment().format("YYYY-MM-DD");

    const me = this.$store.getters[`account/me`];
    this.driver = {
      driverId: me?.driver?._id,
      driver: me?.driver,
    };

    this.init();
    this.$emit("updateLoading", false);
  },
  methods: {
    async init(paged = 1) {
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        filter: { driverId: this.driver.driverId },
      };

      if (this.selectedDateRange.length > 0) {
        (query.from = moment().format(
          `${this.selectedDateRange[0]}THH:mm:ssZ`
        )),
          (query.to = moment().format(
            `${this.selectedDateRange[1]}THH:mm:ssZ`
          ));
      }

      const manualEvents = await this.$store.dispatch(
        `business/getManualEventByEntityId`,
        { entityId: this.driver.driver.entityId, query }
      );
      if (manualEvents) {
        if (manualEvents?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            manualEvents?.metadata
          );
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const manualEventTableData = _.orderBy(
          manualEvents.results,
          ["startTimeWithTimezone"],
          ["desc"]
        );
        this.manualEvents = manualEventTableData;

        this.$emit("updateLoading", false);
      } else {
        this.manualEvents = [];
        this.$emit("updateLoading", false);
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
    },
    viewEvent(id) {
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Manual Event";
      this.modalData = _.find(this.manualEvents, (event) => event._id == id);
    },
    newEvent() {
      this.$refs.modal.openModal();
      this.modalContent = "new";
      this.modalTitle = "Add Manual Event";
    },
    parseEvents(data) {
      let parseData = [];
      Object.values(data).forEach((eventData) => {
        parseData.push([
          { id: eventData.id, name: eventData.eventDate, itemType: "string" },
          { id: eventData.id, name: eventData.totalEvents, itemType: "number" },
          { id: eventData.id, name: eventData.created, itemType: "string" },
        ]);
      });
      return parseData;
    },
    async filterChange() {
      await this.init();
    },
    async filterByDate(event) {
      this.selectedDateRange = event;
      await this.init();
    },
    filterRow(keyword, attributeToFilter) {
      return keyword
        .toLowerCase()
        .split(" ")
        .every((v) => attributeToFilter.trim().toLowerCase().includes(v));
    },
    async onSearch() {
      await this.init();
    },
    async toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    async closeModal() {
      this.$refs.modal.closeModal();
      this.$emit("updateLoading", true);
      await this.init();
      this.$emit("updateLoading", false);
    },
  },
  computed: {
    eventsData() {
      if (this.manualEvents) {
        let eventsComputedData = [];
        this.manualEvents.map((manualEvent) => {
          const timezone = manualEvent.timezone.split(" ")[1];
          eventsComputedData.push({
            id: manualEvent._id,
            eventDate: moment(manualEvent.startTimeWithTimezone)
              .utcOffset(timezone)
              .format("YYYY-MM-DD"),
            created: moment(manualEvent.createdAt).utcOffset(timezone).format(),
            totalEvents: manualEvent.events.length,
          });
        });

        return this.parseEvents(eventsComputedData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      isDarkModeValue: "getIsDarkMode",
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
::v-deep .lhv-datepicker.no-nav .lhv-input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
</style>
