import { STATES, TIMEZONE } from '@/constants/location'

export default [
    {
      type: 'group',
      name: 'profile',
      children: [
        {
        type: 'group',
        name: 'recordKeeperAddress',
        label: 'Record Keeper Address:',
        labelClass: 'font-semibold text-sm block -top-7 -left-0 absolute',
        class: 'border rounded p-5 mb-5 mt-10 relative',
        children: [
          {
            name: 'street',
            label: 'Street Address',
            validation: 'required'
          },
          {
            component: 'div',
            class: 'grid grid-cols-2 gap-4',
            children: [
              {
                name: 'city',
                label: 'City',
                validation: 'required'
              },
              {
                name: 'state',
                type: 'select',
                label: 'State',
                options: STATES,
                validation: 'required'
              },
            ]
          }
        ]
      },
      {
        type: 'group',
        name: 'baseLocation',
        label: 'Base Location:',
        labelClass: 'font-semibold text-sm block -top-7 -left-0 absolute',
        class: 'border rounded p-5 mb-5 mt-10 relative',
        children: [
          {
            name: 'street',
            label: 'Street Address',
            validation: 'required'
          },
          {
            component: 'div',
            class: 'grid grid-cols-2 gap-4',
            children: [
              {
                name: 'city',
                label: 'City',
                validation: 'required'
              },
              {
                name: 'state',
                type: 'select',
                label: 'State',
                options: STATES,
                validation: 'required'
              },
            ]
          }
        ]
      },
      {
        name: 'baseTimeZone',
        type: 'select',
        label: 'Base Time Zone',
        options: TIMEZONE,
        validation: 'required'
      },
      {
        type: 'text',
        name: 'BFMNumber',
        label: 'BFM Number',
      },
    ]
  },
  
  
]