<template>
  <div class="w-full mt-10 flex">
    <div class="w-90 px-1 py-3 rounded border-gray-500 mx-auto">
      <ChangeEmail />
    </div>
  </div>
</template>

<script>
import { ChangeEmail } from '@/components/forms/account/'
export default {
    components: { ChangeEmail }
}
</script>