<template>
  <div class="relative">
    <div class="w-full h-full" v-if="loading">
      <div
        class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Calculating...</span>
      </div>
    </div>
    <div class="overflow-x-auto mt-2">
      <p class="flex items-center text-sm mb-3">
        <font-awesome-icon
          icon="info-circle"
          size="lg"
          class="text-blue mr-1"
        />
        By checking the box, you hereby declare that the breach is correct.
      </p>
      <table class="min-w-full border-2 border-gray-300">
        <thead class="bg-gray-300">
          <tr class="text-gray-700 text-xs tracking-wider uppercase">
            <th scope="col" class="py-3 px-5 text-center">Declare</th>
            <th scope="col" class="py-3 px-5 text-left">Breach Time</th>
            <th scope="col" class="py-3 px-5 text-left">Option</th>
            <th scope="col" class="py-3 px-5 text-left">Rule</th>
            <th scope="col" class="py-3 px-5 text-left">Rule Type</th>
            <th scope="col" class="py-3 px-5 text-left">Level</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-show="breaches.length > 0"
            v-for="(breach, index) in breaches"
            :key="index"
            class="bg-white border-b text-xs text-gray-500 font-medium"
          >
            <td class="py-3 px-5 text-center">
              <input
                type="checkbox"
                v-model="breach.isChecked"
                class="mr-2 w-4 h-4 text-blue-600 bg-gray-100 rounded"
              />
            </td>
            <td class="py-3 px-5 text-black-primary capitalize">
              {{ formatTime(breach.time, breach.timezone) }}
            </td>
            <td class="py-3 px-5">
              {{ breach.option }}
            </td>
            <td class="py-3 px-5">
              {{ breach.period }}
            </td>
            <td class="py-3 px-5">
              {{ breach.type }}
            </td>
            <td class="py-3 px-5">
              {{ cleanLevel(breach.level) }}
            </td>
          </tr>
          <tr v-show="breaches.length == 0">
            <td colspan="10" class="py-3 px-5 text-center text-sm">
              No breaches found.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="grid grid-cols-2 gap-4 my-5">
      <button
        class="btn bg-gray-300 hover:bg-gray-400 w-full mb-3"
        @click="onPrevious()"
      >
        Back
      </button>
      <button
        class="btn btn-primary w-full mb-3"
        :disabled="loading"
        @click="onSubmit()"
        :style="{ backgroundColor: theme.secondaryColor }"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { generateBreaches } from '@/_helper/breach';
import Spinner from '@/components/commons/ui/Spinner';

export default {
  name: 'Check-For-Breaches',
  props: {
    msg: String,
    preData: null,
  },
  components: { Spinner },
  data() {
    return {
      params: {},
      loading: false,
      values: [],
      errorMessage: '',
      schema: [],
      businessId: null,
      breaches: [],
    };
  },
  computed: {
    driver: {
      get() {
        return this.params.selectedDriver?.driver?.driverName;
      },
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];
    this.businessId = me?.business?._id;
    if (me.role.name == 'driver') this.businessId = me[me.role.name].entityId;

    if (!_.isEmpty(this.preData['eventDetails'])) {
      this.params = this.preData['eventDetails'];

      this.loading = true;
      const driverId = this.params[0].selectedDriver?.driverId;
      const dateRange = this.params[0].selectedDateRange;
      const timeZone = this.params[0].selectedTimezone;

      // Get all manual events
      let allEvents = [];
      let existingBreaches = [];

      const requestData = {
        driverId,
        from: moment(dateRange[0], this.dateFormat).format(),
        to: moment(dateRange[1], this.dateFormat).format(),
      };

      await Promise.all([
        this.$store.dispatch(`report/getBreachesByDateRange`, requestData),
        this.$store.dispatch(`business/getEventByDateRange`, {
          driverId,
          from: moment(dateRange[0], this.dateFormat)
            .subtract(28, 'days')
            .format(),
          to: moment(dateRange[1], this.dateFormat).add(28, 'days').format(),
        }),
      ]).then((data) => {
        existingBreaches = data[0];
        allEvents = data[1];
      });

      for (const param of Object.values(this.params)) {
        const manualEvents = param.events.filter((x) => _.isEmpty(x.eventId));
        allEvents = [...allEvents, ...manualEvents];
      }

      let generatedBreaches = generateBreaches(
        driverId,
        this.businessId,
        allEvents,
        timeZone
      );

      // Remove existing breaches from generated breaches
      existingBreaches.forEach((breach) => {
        _.remove(
          generatedBreaches,
          (x) => x.time == breach.time.replace('T', ' ')
        );
      });

      // Sort breaches
      generatedBreaches = _.sortBy(generatedBreaches, ['time']).reverse();

      // Add isChecked property to each generated breaches
      generatedBreaches.forEach((breach) => (breach.isChecked = true));

      // Remove duplicate breaches
      generatedBreaches = _.uniqWith(
        generatedBreaches,
        (breachA, breachB) =>
          breachA.time === breachB.time &&
          breachA.option === breachB.option &&
          breachA.period === breachB.period &&
          breachA.type === breachB.type &&
          this.cleanLevel(breachA.level) === this.cleanLevel(breachB.level)
      );

      this.breaches = generatedBreaches;

      this.loading = false;
    }
  },
  methods: {
    cleanLevel(level) {
      if (typeof level == 'object') level = level[0];
      return _.startCase(
        level == 'no-breach' ? level.replace('-', ' ') : level.split('-')[0]
      );
    },
    formatTime(time, timezone) {
      return moment(time).utcOffset(timezone).format('YYYY-MM-DD HH:mm:ssZ');
    },
    onPrevious() {
      this.$emit('backStep');
    },
    async onSubmit() {
      const declaredBreaches = this.breaches.filter((x) => x.isChecked);
      this.$emit('nextStep', { eventDetails: this.params, breaches: declaredBreaches })
    },
  },
};
</script>
