<template>
  <div>
    <Table
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="contractsData"
      @search-table="queryForKeywords"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option>5</option>
              <option>10</option>
              <option>20</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="selectedFilteredStatus"
              @change="selectFilteredStatus"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus.label }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            @input="queryForKeywords($event.target.value)"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewContract(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="editContract(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="deleteContract(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="trash" class="my-auto mr-2" /> Delete
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <template slot="callToActionTop">
        <button
          class="btn btn-primary"
          @click.prevent="newContract()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          New Contract
        </button>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="lg" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <NewContract @closeRefresh="closeRefresh" />
      </div>
      <div v-if="modalContent == 'view'">
        <ContractView
          @editContract="editContract"
          @deleteContract="deleteContract"
          :detailsId="modalId"
        />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditContract @closeRefresh="closeRefresh" :detailsId="modalId" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal, Table, Dropdown } from "@/components/commons/";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import { formatDate } from "@/_helper";
import ContractView from "@/components/view/contract";
import { NewContract, EditContract } from "@/components/forms/contract/";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Admin-Contracts",
  components: {
    Modal,
    Table,
    Dropdown,
    ContractView,
    NewContract,
    EditContract,
    StyledIconedLink,
  },
  data() {
    return {
      modalContent: false,
      modalTitle: "",
      modalId: "",
      contracts: [],
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        "Business",
        "Reseller",
        "Partner",
        "Status",
        "Start Date",
        "Expiry Date",
      ],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: [],
    };
  },
  mounted() {
    this.initStatusFilter();
    this.init();
  },
  methods: {
    newContract: function () {
      this.modalContent = "new";
      this.modalTitle = "New Contract";
      this.$refs.modal.openModal();
    },
    viewContract: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Contract Info";
      this.modalId = id;
    },
    editContract: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "edit";
      this.modalTitle = "Contract Edit";
      this.modalId = id;
    },
    deleteContract(id) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store
          .dispatch(`contract/deleteContract`, id)
          .then((contract) => {
            if (contract && contract._id) {
              this.init();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
      console.log("id for delete: ", id);
    },
    async initStatusFilter() {
      const statuses = await this.$store.dispatch(`contract/getContractStatus`);
      const newStatuses = statuses.map((status) => ({
        label: status.name,
        value: status._id,
      }));

      this.filterStatuses = [
        {
          label: "Show all",
          value: "",
        },
        ...newStatuses,
      ];
    },
    async init() {
      this.$emit("updateLoading", true);
      const contracts = await this.$store.dispatch(`contract/getContract`);
      if (contracts) {
        this.contracts = this.formatItem(
          _.orderBy(contracts, ["startDate"], ["desc"])
        );

        this.$emit("updateLoading", false);
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.$emit("updateLoading", false);
    },
    closeRefresh() {
      this.$refs.modal.closeModal();
      this.init();
    },
    formatItem: function (items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const contract = {
            id: item["_id"],
            businessName: item["businessMetaData"]?.persona?.businessName || "",
            resellerName: item["resellerMetaData"]?.persona?.businessName || "",
            partnerName: item["partnerMetaData"]?.persona?.businessName || "",
            status: item["status"]?.name || "",
            startDate: formatDate(item["startDate"]),
            expiryDate: formatDate(item["expiryDate"]),
            statusId: item["status"]?._id,
          };
          nItems.push(contract);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseContract(data) {
      return data.map((contractData) => [
        {
          id: contractData.id,
          name: contractData.businessName,
          itemType: "name",
        },
        {
          id: contractData.id,
          name: contractData.resellerName,
          itemType: "string",
        },
        {
          id: contractData.id,
          name: contractData.partnerName,
          itemType: "string",
        },
        { id: contractData.id, name: contractData.status, itemType: "string" },
        {
          id: contractData.id,
          name: contractData.startDate,
          itemType: "string",
        },
        {
          id: contractData.id,
          name: contractData.expiryDate,
          itemType: "string",
        },
      ]);
    },
    selectFilteredStatus() {
      this.selectedStatus =
        this.filterStatuses[this.selectedFilteredStatus].value;
    },
  },
  computed: {
    contractsData() {
      if (this.contracts) {
        let contractComputedData = this.contracts;

        if (this.selectedFilteredStatus) {
          contractComputedData = contractComputedData.filter(
            (item) => this.selectedStatus === item.statusId
          );
        }
        if (this.searchKeyword) {
          contractComputedData = contractComputedData.filter(
            (item) =>
              this.searchKeyword
                .toLowerCase()
                .split(" ")
                .every((v) =>
                  item.partnerName.trim().toLowerCase().includes(v)
                ) ||
              this.searchKeyword
                .toLowerCase()
                .split(" ")
                .every((v) =>
                  item.resellerName.trim().toLowerCase().includes(v)
                ) ||
              this.searchKeyword
                .toLowerCase()
                .split(" ")
                .every((v) =>
                  item.businessName.trim().toLowerCase().includes(v)
                )
          );
        }

        if (!this.searchKeyword && this.selectedStatus === "") {
          return this.parseContract(this.contracts);
        }

        return this.parseContract(contractComputedData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style>
.loading.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
