<template>
  <div class="page-content-header bg-white">
    <div class="bg-black bg-opacity-50">
      <div class="container text-center h-48 flex">
        <h1 class="text-white font-bold text-5xl m-auto">{{title}}</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .page-content-header{
    background-image: url('/assets/images/page-header-bg.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
</style>
