export default {
    // @Vehicle
    setNewCreatedVehicle(state, value){
        state.newCreatedVehicle = value
    },

    // @VehicleClass
    setNewCreatedVehicleClass(state, value){
        state.newCreatedVehicleClass = value
    },

    // @VehicleType
    setNewCreatedVehicleType(state, value){
        state.newCreatedVehicleType = value
    },

    // @RearCoupling
    setNewCreatedRearCoupling(state, value){
        state.newCreatedRearCoupling = value
    },

    // @FrontCoupling
    setNewCreatedFrontCoupling(state, value){
        state.newCreatedFrontCoupling = value
    },

    // @CouplingCompatibility
    setNewCreatedCouplingCompatibility(state, value){
        state.newCreatedCouplingCompatibility = value
    },

    // @WiringPlugConnector
    setNewCreatedWiringPlugConnector(state, value){
        state.newCreatedWiringPlugConnector = value
    },

    // @WiringPlugVoltage
    setNewCreatedWiringPlugVoltage(state, value){
        state.newCreatedWiringPlugVoltage = value
    },

    // @BrakeFoundation
    setNewCreatedBrakeFoundation(state, value){
        state.newCreatedBrakeFoundation = value
    },

    // @BrakeType
    setNewCreatedBrakeType(state, value){
        state.newCreatedBrakeType = value
    },
}