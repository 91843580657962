<template>
  <Stepper 
      :stepperSteps="stepperSteps" 
      @completed-step="completeStep" 
      @active-step="isStepActive" 
      :showNavigation="false"  
      @closeRefresh="closeRefresh"
    />
</template>

<script>
import { Stepper } from '@/components/commons'
import { CreateDriver, CreateBusinessProfile }  from './stepper-component'
export default {
  name: 'New-Business-Driver-Profile',
  components: {Stepper},
  data(){
      return{
          stepperSteps: [
               {
                  stepIcon: 'user',
                  stepTitle: 'Create / Select',
                  stepSequence: 'first',
                  stepComponent: CreateDriver,
                  isBusinessDriverProfile: true,
                  isCompleted: true,

              },
               {
                  stepIcon: 'briefcase',
                  stepTitle: 'Business Profile',
                  stepSequence: 'second',
                  stepComponent: CreateBusinessProfile,
                  isBusinessDriverProfile: true,
                  isCompleted: false,
              },
          ],
          activeStep: 0,
      }
  },
   methods: {
      completeStep(payload) {
          this.stepperSteps.forEach((step) => {
              if (step.stepSequence === payload.stepSequence) {
                  step.isCompleted = true;
              }
          })
      },
      isStepActive(payload) {
          this.stepperSteps.forEach((step) => {
              if (step.stepSequence === payload.stepSequence) {
                  if(step.isCompleted === true) {
                      step.isCompleted = false;
                  }
              }
          })
      },
      closeRefresh(){ 
        this.$emit('closeRefresh')
      }
  },
  
}
</script>