<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <FormulateForm v-model="values" @submit="onSubmit">
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="text"
            name="driverName"
            label="Full Name"
            validation="required"
          />
          <FormulateInput
            type="date"
            name="driverDateOfBirth"
            label="Date Of Birth"
            validation="required"
          />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <FormulateInput
              type="text"
              name="driversLicenseNumber"
              label="Licence Number"
              validation="required"
            />
            <FormulateInput
              type="date"
              name="driversLicenseExpiry"
              label="Licence Expiry"
              validation="required"
            />
          </div>
          <div class="grid grid-cols-2 gap-4">
            <FormulateInput
              type="select"
              name="driversBaseFatiguePlan"
              label="Base Fatigue Plan"
              :options="fatiguePlans"
              validation="required"
            />
            <FormulateInput
              type="select"
              name="driversBaseTimeZone"
              label="Base Time Zone"
              :options="timezones"
              validation="required"
            />
          </div>
        
          <div class="items-center justify-between mt-4">
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-full"
              :disabled="submitLoading"
              :style="{backgroundColor: theme.secondaryColor}"
            >
            <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Submitting..</span>  
            <span v-if="!submitLoading">Submit</span>  
            </FormulateInput>
          </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {formatDate} from '@/_helper'
import { STATES } from '@/constants/location'
import { TIMEZONE } from '@/constants/location'
import { FATIGUE_PLAN } from '@/constants/fatigue-plan'
import { REGION } from '@/constants/region'

export default {
  props: {
    detailsId:{
      type: String,
      default: '',
    }
  },
  data() {
    return {
      values: {},
      loading: false,
      submitLoading: false,
      hasErrorMessage: false,
      errorMessage: '',
      states: STATES,
      timezones: TIMEZONE,
      fatiguePlans: [],
      regions: REGION
    }
  },   
  async mounted(){
    this.loading = true
    this.fatiguePlans = Object.entries(FATIGUE_PLAN).map(fatigue => {
      return { label: fatigue[0].replace(/_/g, ' '), value: fatigue[1] }
    });
    const driverDetails = await this.$store.dispatch(`driver/getDriverByID`, this.detailsId)
    this.values = this.parseDriver(driverDetails)
    this.driverId = driverDetails._id
    this.loading = false
  },
  methods: {
    parseDriver(driver){
      return {
        driversBaseLocation: driver.driversBaseLocation,
        driverDateOfBirth: formatDate(driver.driverDateOfBirth, 'YYYY-MM-DD'),
        bfmNumber: driver.driversBFMNumber,
        driversLicenseNumber: driver.driversLicenseNumber,
        driversBaseFatiguePlan: driver.driversBaseFatiguePlan,
        driversBaseTimeZone: driver.driversBaseTimeZone,
        driversLicenseExpiry: formatDate(driver.driversLicenseExpiry, 'YYYY-MM-DD'),
        driverName: driver.driverName
      }
    },
    async onSubmit() {
      this.errorMessage = ''
      this.submitLoading = true
      const me = this.$store.getters[`account/me`];
      const entityID = me.business._id;

      const payload = {
        ...this.values,
        entityId: entityID,
        businessId: entityID,
        driverId: this.driverId
      }

      await this.$store.dispatch(`driver/updateNonEWDDriver`, payload)
        .then(()=>{
          this.$emit('closeRefresh')
          this.$store.commit('setDialogNotify',true)
          this.$store.commit('setMessageNotify',{state:'success',message:'Non-EWD Driver successfully updated.'})
          this.submitLoading = false
        })
        .catch((err) => {
          this.errorMessage = err.message
          this.submitLoading = false
        })
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>