<template>
  <div class="mb-16">
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!isLoading" id="printCovered">
      <div class="text-center mb-10">
        <div class="flex text-right justify-end">
          <button
            id="printBtn"
            class="h-8 pl-2 pr-4 m-2 text-sm transition-colors duration-150 bg-blue-300 text-white hover:bg-blue-200 rounded-md focus:shadow-outline"
            @click="printThis('printCovered')"
          >
            <font-awesome-icon icon="print" class="my-auto mx-1.5 text-sm" />
            Print
          </button>
        </div>
        <div class="font-bold text-black-primary text-2xl">
          {{ document.ncrNumber }}
        </div>
        <div class="text-xs">NCR Number</div>
        <div class="font-bold text-xs" :class="statusTextColor">
          {{ document.status }}
        </div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ formattedDate(document.ncrDate) }}
        </div>
        <div class="text-xs">NCR Date</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ document.description }}
        </div>
        <div class="text-xs">Description</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ document.raisedBy.userName }}
        </div>
        <div class="text-xs">Raised By</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ document.auditee.userName }}
        </div>
        <div class="text-xs">Raised Against</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ document.shortTermSuggestion }}
        </div>
        <div class="text-xs">Short Term Suggestion</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ document.longTermSuggestion }}
        </div>
        <div class="text-xs">Long Term Suggestion</div>
      </div>

      <div v-if="document.contestReason" class="mb-8">
        <div class="font-bold text-black-primary text-base">
          {{ document.contestReason }}
        </div>
        <div class="text-xs">Contest Reason</div>
      </div>

      <div class="grid grid-cols-2 gap-4">
        <div v-if="document.auditorSignature">
          <div class="text-xs mb-5">Auditor's Signature</div>
          <img
            :src="renderImageByUrl(document.auditorSignature)"
            alt=""
            style="max-width: 200px"
          />
        </div>
        <div v-if="document.auditeeSignature">
          <div class="text-xs mb-5">Auditee's Signature</div>
          <img
            :src="renderImageByUrl(document.auditeeSignature)"
            alt="Awaiting Signature"
            style="max-width: 200px"
          />
        </div>
      </div>

      <section class="h-full w-full mt-3 border rounded-lg group bg-gray-100">
        <header class="flex">
          <div class="w-3/4 pb-2 xt-xl font-bold pt-4 px-3">
            Investigation Documents ({{ investigationDocs.length }})
          </div>
          <div class="w-1/4 pb-2 xt-xl font-bold pt-3"></div>
        </header>
        <hr />

        <div class="flex w-full">
          <aside class="w-36 border-r h-40">
            <div>
              <div
                v-if="investigationDocs.length && activeInvestigationDoc"
                class="rounded w-full overflow-y-auto h-60"
              >
                <div
                  class="text-sm py-3 pl-3 border-b cursor-pointer bg-white"
                  v-for="(data, key) in investigationDocs"
                  :key="key"
                  :class="[
                    activeInvestigationDoc.docId == data.docId
                      ? 'bg-green-100'
                      : '',
                  ]"
                  @click="setActiveInvestigationDoc(data)"
                >
                  <span v-if="data"
                    >{{
                      cleanDataType(data.docFormType).substring(0, 6) + "..."
                    }}
                  </span>
                  <FolderIcon
                    :a="activeInvestigationDoc.docId"
                    :b="data.docId"
                  />
                </div>
              </div>
              <div
                v-if="!investigationDocs.length"
                class="w-full pl-2 py-2 border-b border-gray-300 opacity-40"
              >
                <small><em>No docs found</em></small>
              </div>
            </div>
          </aside>

          <div class="h-full w-full bg-white px-6 py-7 shadow rounded-lg mb-6">
            <div v-if="!activeInvestigationDocLoading">
              <div v-if="investigationDocs.length && activeInvestigationDoc">
                <ImmediateActionView
                  :activeDocView="activeInvestigationDoc"
                  v-if="
                    activeInvestigationDoc.docFormType ==
                    docFormTypeList.IMMEDIATE_ACTION
                  "
                />

                <InterviewView
                  :activeDocView="activeInvestigationDoc"
                  v-if="
                    activeInvestigationDoc.docFormType ==
                    docFormTypeList.INTERVIEW
                  "
                />

                <EvidenceCollectionView
                  :activeDocView="activeInvestigationDoc"
                  v-if="
                    activeInvestigationDoc.docFormType ==
                    docFormTypeList.EVIDENCE_COLLECTION
                  "
                />

                <FindingsView
                  :activeDocView="activeInvestigationDoc"
                  v-if="
                    activeInvestigationDoc.docFormType ==
                    docFormTypeList.FINDINGS
                  "
                />
              </div>

              <div v-if="!investigationDocs.length" class="h-full w-full">
                <div
                  class="bg-white-100 h-80 border-orange-500 text-orange-700 py-4"
                >
                  <p class="font-bold">Empty</p>
                  <p>Empty.</p>
                </div>
              </div>
            </div>

            <div v-if="activeInvestigationDocLoading">
              <div
                class="loading my-24 translate-y-2/4 flex flex-col items-center mx-auto py-20 h-auto mb-20 w-full pl-5"
              >
                <ui-spinner class="mx-auto mb-3"></ui-spinner>
                Loading....
              </div>
            </div>
          </div>
        </div>
      </section>

      <div v-if="!auditeeView && document.status === 'PENDING'">
        <button
          @click="allowUpdateAsBusiness()"
          class="btn btn-primary w-full mt-5"
        >
          Update on behalf of {{ document.auditee.userName }}
        </button>
      </div>

      <div class="mb-5" v-if="displayForm">
        <div class="text-red-500 text-center mt-3" v-if="error">
          {{ errorMessage }}
        </div>

        <div class="font-bold py-4">Non Conformance Action</div>
        <FormulateForm
          v-model="payload"
          @submit="onSubmit"
          #default="{ hasErrors }"
        >
          <FormulateInput
            type="select"
            v-model="selectedStatus"
            label="Status"
            :options="statusOptions"
            @change="onChangeStatus($event)"
          ></FormulateInput>

          <FormulateInput
            v-if="isContested"
            type="text"
            name="reason"
            label="Contest Reason"
          />
          <div v-if="auditeeView" class="w-full p-2">Sign Below</div>
          <VueSignaturePad
            v-if="auditeeView"
            id="signature"
            class="canvas bg-gray-200"
            ref="signaturePad"
            scaleToDevicePixelRatio
          />
          <button
            v-if="auditeeView"
            class="btn btn-secondary w-full mb-2"
            @click.prevent="clearSignature"
          >
            Clear Signature
          </button>
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || isLoading"
          />
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { print, removeStringUnderscore } from "@/_helper";
import {
  NON_CONFORMANCE_STATUSES,
  NON_CONFORMANCE_STATUS_OPTION_AUDITEE,
  NON_CONFORMANCE_STATUS_OPTION_AUDITOR,
  DOCUMENT_FORM_TYPES,
} from "@/_helper/constants";
import {
  EvidenceCollectionView,
  FindingsView,
  ImmediateActionView,
  InterviewView,
} from "@/components/forms/business/investigation-register/documents/doc-view-data";
import FolderIcon from "@/components/commons/icons/folder-icon-open-close";

export default {
  name: "non-conformance-details",
  components: {
    EvidenceCollectionView,
    FindingsView,
    ImmediateActionView,
    InterviewView,
    FolderIcon,
  },

  props: {
    propsDocument: {
      type: Object,
      default: () => {},
    },
    entityWebProfileId: {
      type: String,
      required: true,
    },
    entityMongoId: {
      type: String,
      required: true,
    },
    entityType: {
      type: String,
      required: true,
    },
  },
  watch: {
    propsDocument: {
      deep: true,
      handler() {
        this.setActiveDocument();
      },
    },
  },
  created() {
    this.isLoading = true;
    this.setActiveDocument();
    setTimeout(() => (this.isLoading = false), 300);
  },
  data() {
    return {
      isLoading: false,
      payload: { reason: "" },
      error: false,
      errorMessage: "",
      signatureBuffer: null,
      document: null,
      statusOptions: [],
      statusTextColor: null,
      selectedStatus: null,
      isContested: false,
      auditeeView: null,
      displayForm: false,
      docFormTypeList: {
        IMMEDIATE_ACTION: DOCUMENT_FORM_TYPES.IMMEDIATE_ACTION,
        INTERVIEW: DOCUMENT_FORM_TYPES.INTERVIEW,
        EVIDENCE_COLLECTION: DOCUMENT_FORM_TYPES.EVIDENCE_COLLECTION,
        FINDINGS: DOCUMENT_FORM_TYPES.FINDINGS,
      },
      investigation: null,
      investigationDocs: [],
      activeInvestigationDoc: null,
      activeInvestigationDocLoading: false,
    };
  },
  mounted() {
    this.setInvestigation();
    this.auditeeView = this.entityWebProfileId == this.document.auditee._id;
    this.init();
  },
  methods: {
    init() {
      switch (this.document.status) {
        case NON_CONFORMANCE_STATUSES.ACCEPTED:
        case NON_CONFORMANCE_STATUSES.FINALISED:
          this.statusTextColor = "text-green-500";
          break;
        case NON_CONFORMANCE_STATUSES.CONTESTED:
        case NON_CONFORMANCE_STATUSES.CONTEST_REJECTED:
          this.statusTextColor = "text-red-500";
          break;
        default:
          this.statusTextColor = "text-grey-500";
      }
      this.selectedStatus = this.document.status;
      if (this.auditeeView) {
        this.statusOptions = NON_CONFORMANCE_STATUS_OPTION_AUDITEE;
        //default value
        this.selectedStatus = NON_CONFORMANCE_STATUSES.ACCEPTED;
        if (this.document.status == NON_CONFORMANCE_STATUSES.PENDING)
          this.displayForm = true;
      } else {
        this.statusOptions = NON_CONFORMANCE_STATUS_OPTION_AUDITOR.filter(
          (option) => {
            if (this.document.status === NON_CONFORMANCE_STATUSES.CONTESTED)
              return NON_CONFORMANCE_STATUS_OPTION_AUDITOR;
            if (this.document.status !== NON_CONFORMANCE_STATUSES.CONTESTED)
              return option.value !== NON_CONFORMANCE_STATUSES.CONTEST_REJECTED;
          }
        );
        //default value
        this.selectedStatus = NON_CONFORMANCE_STATUSES.FINALISED;
        if (
          !["PENDING", "FINALISED", "WITHDRAWN"].includes(this.document.status)
        ) {
          this.displayForm = true;
        }
      }
    },
    clearSignature() {
      this.$refs.signaturePad.openSignaturePad();
      this.$refs.signaturePad.clearSignature();
    },
    formattedDate(date) {
      return moment(date).format("MMMM Do YYYY");
    },
    async onSubmit() {
      this.isLoading = true;
      try {
        let payload = {
          auditeeSignature: null,
          status: this.selectedStatus,
          documentId: this.document._id,
          contestReason: null,
          isAuditor: !this.auditeeView,
        };
        if (this.isContested) {
          if (!this.payload.reason) {
            this.isLoading = false;
            this.error = true;
            this.errorMessage = "Please provide a reason for your contest";
          }
          payload.contestReason = this.payload.reason;
        }
        if (this.auditeeView) {
          payload = await this.handleAuditeeSubmit(payload);
        }

        if (!this.error) {
          await this.$store.dispatch("report/reviewNonConformance", payload);
          this.$emit("closeModal");
          this.$emit(
            "toast",
            "success",
            "Non Conformance successfully updated."
          );
        }
      } catch (e) {
        this.isLoading = false;
        this.error = true;
        this.errorMessage = "Something Went Wrong";
        this.$emit("toast", "error", this.errorMessage);
      }
    },
    async handleAuditeeSubmit(payload) {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.signatureBuffer = data;
      if (isEmpty) {
        this.isLoading = false;
        this.error = true;
        this.errorMessage = "Please provide your signature";
      }

      let createdUserDocument;
      try {
        // TODO: fetch existing signature on load
        // Use signaturePad.fromDataURL(data, {width: 100, height: 100}) where data is buffer of already saved signature
        const signaturePayload = {
          fileType: "SIGNATURE",
          userMongoId: this.entityMongoId,
          userType: this.entityType,
          file: this.signatureBuffer,
          isBase64: true,
          mimetype: "image/png",
        };
        createdUserDocument = await this.$store.dispatch(
          `accreditation/uploadUserDocument`,
          {
            signaturePayload,
          }
        );
      } catch (e) {
        this.isLoading = false;
        this.error = true;
        this.errorMessage = "Upload failed";
        this.$emit("toast", "error", this.errorMessage);
      }
      if (!this.error && createdUserDocument) {
        // createdUserDocument.document is link of where signature is stored
        payload.auditeeSignature = createdUserDocument[0].data.data.document;
      }

      return payload;
    },
    renderImageByUrl(imageUrl) {
      return imageUrl;
    },
    setActiveDocument() {
      const { auditee, bundle = {} } = this.propsDocument;
        const isNonEwdDriver = Object.keys(bundle).length > 0;
        let auditeeName;
        if (isNonEwdDriver) {
          auditeeName = bundle && bundle.driver ? bundle.driver.driverName : "";
        } else {
          auditeeName = auditee ? auditee.userName : "";
        }
        this.document = {
          ...this.propsDocument,
          auditee: {
            ...this.propsDocument.auditee,
            userName: auditeeName
          }

        }
    },
    printThis(toPrintId) {
      return print(toPrintId);
    },
    onChangeStatus(event) {
      const status = event.target.value;
      this.isContested = status == "CONTESTED";
    },
    setInvestigation() {
      const { investigation } = this.document;
      if (!investigation) return;
      this.investigation = investigation;

      const {
        findings = [],
        evidenceCollections = [],
        immediateActions = [],
        interviews = [],
      } = investigation;
      const docs = [
        ...findings,
        ...evidenceCollections,
        ...immediateActions,
        ...interviews,
      ];

      this.investigationDocs = docs;
      if (docs.length > 0) this.activeInvestigationDoc = docs[0];
    },
    setActiveInvestigationDoc(item) {
      this.activeInvestigationDocLoading = true;
      this.activeInvestigationDoc = item;
      setTimeout(() => {
        this.activeInvestigationDocLoading = false;
      }, 200);
    },
    cleanDataType(str) {
      return removeStringUnderscore(str);
    },
    allowUpdateAsBusiness() {
      const isConfirmed = confirm(
        `You are about to allow non conformance update on behalf of ${this.document.auditee.userName}. Do you wish to proceed?`
      );

      if (isConfirmed) {
        this.auditeeView = true;
        this.statusOptions = NON_CONFORMANCE_STATUS_OPTION_AUDITEE;
        this.init();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
@media print {
  #printCovered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  #printBtn {
    display: none;
  }
}
@page {
  size: auto;
}
</style>
