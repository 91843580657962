
//import DriverAccept from '@/pages/mail/solo-driver/accept';
// import DriverAbort from '@/pages/mail/solo-driver/abort';

// import DriverContractDecline from '@/pages/mail/solo-driver/contract/decline';

//Public
import { BusinessAccept } from '@/pages/public/business/'
import { DriverAccept, DriverContractAccept, ContractorAcceptPage } from '@/pages/public/driver/'
import { PartnerAccept, PartnerContractAccept } from '@/pages/public/partner/'
import { AccountForgotPassword } from '@/pages/public/account/'
import { ResellerAccept, ResellerContractAccept } from '@/pages/public/reseller/'
import SoloDriverCreatePage from '@/pages/public/driver/SoloDriverCreatePage'
import GeotabLoginPage from '@/pages/public/geotab/GeotabLoginPage'
import ChangeEmailPage from '@/pages/public/account/AccountChangeEmail'
import DisputeChangeEmailPage from '@/pages/public/account/DisputeChangeEmailPage'

import BusinessCreatePage from '@/pages/public/business/BusinessCreatePage'
import GeneralBusinessCreatePage from '@/pages/public/business/GeneralBusinessCreatePage'

export default [
    // {
    //     path: 'solo-driver/accept/:driverId',
    //     name: 'Create a Password',
    //     component: DriverAccept
    // },
    // {
    //     path: 'solo-driver/abort/:driverId',
    //     name: 'Abort Request',
    //     component: DriverAbort
    // },
    // {
    //     path: 'solo-driver/contract/accept/:contractId',
    //     name: 'Contract Accept',
    //     component: DriverContractAccept
    // },
    // {
    //     path: 'solo-driver/contract/decline/:contractId',
    //     name: 'Contract Decline',
    //     component: DriverContractDecline
    // },
    {
        path: '/account/forgot-password/:token',
        component: AccountForgotPassword,
        props: (route) => ({ token: route.params.token }),
    },
    {
        path: '/business/accept/:id',
        component: BusinessAccept,
        props: (route) => ({ acceptId: route.params.id }),
    },
    {
        path: '/solo-driver/accept/:acceptid',
        component: DriverAccept,
        props: (route) => ({ acceptId: route.params.id }),
    },
    {
        path: '/solo-driver/accept-contractor/:acceptId',
        component: ContractorAcceptPage,
        props: (route) => ({ acceptId: route.params.id }),
    },
    {
        path: '/solo-driver/contract/accept/:contractType/:contractId',
        name: 'Contract Accept',
        component: DriverContractAccept
    },
    {
        path: '/partner/accept/:partnerId',
        component: PartnerAccept
    },
    {
        path: '/partner/contract/accept/:partnerId',
        name: 'Partner Accept',
        component: PartnerContractAccept
    },
    {
        path: '/reseller/accept/:resellerId',
        component: ResellerAccept
    },
    {
        path: '/reseller/contract/accept/:resellerId',
        name: 'Reseller Accept',
        component: ResellerContractAccept
    },
    //Solo Driver invite link
    {
        path: '/:entity/:urlslug/new-driver/:urlType/:uniqueId',
        name: 'Solo Driver Create',
        component: SoloDriverCreatePage
    },
    {
        path: '/:entity/:urlslug/new-business/:urlType/:uniqueId',
        name: 'Business Create',
        component: BusinessCreatePage
    },
    {
        path: '/generalbusiness',
        name: 'Business Create',
        component: GeneralBusinessCreatePage
    },
    //Incrementally add routes as needed for succeeding pages require in Geotab
    {
        path: '/geotab/:page/:subPage?/:accountId?/:subPage2?/:subPage3?',
        name: 'Geotab Login',
        component: GeotabLoginPage
    },
    {
        path: '/account/change-email/accept/:token',
        name: 'Change Email',
        component: ChangeEmailPage,
        props: (route) => ({ token: route.params.token }),
    },
    {
        path: '/account/change-email/dispute/:token',
        name: 'Dispute Change Email',
        component: DisputeChangeEmailPage,
        props: (route) => ({ token: route.params.token }),
    }
]