<template>
    <div class="mb-16">
      <div class="loading centered" v-if="loading">
          <ui-spinner class="mx-auto"></ui-spinner>
      </div>
      <div class="text-red-500 font-bold text-sm text-center my-5" v-if="errorMessage">
        {{errorMessage}}
      </div>
      <div v-if="!loading">
        <FormulateForm v-model="values" :schema="schema" @submit="onSubmit">
          <div class="text-sm mb-1 font-semibold form-label pr-2 text-neutral-dark">Transport Service Licence</div>
          <div class="border rounded p-5 mb-5 mt-1 relative">
            <FormulateInput
              name="tslId"
              label="Licences"
              type="general-select"
              element-class="mb-2 mt-1"
              placeholder="Select a TSL type"
              :clearable="false"
              :options="tsls"
              :getOptionKey="(option) => option.tslNumber"
              :getOptionLabel="(option) => option.tslNumber"
              validation="required"
            />
            <FormulateInput
              type="text"
              name="tslSerialNumber"
              label="Serial Number"
              validation="required"
            />
            <div v-if="showTlsExtraFields">
              <FormulateInput
                type="date"
                name="passengerEndorsementExpiry"
                label="Passenger Endorsement Expiry"
                validation="required"
              />
              <FormulateInput
                type="date"
                name="driverLicenceRenewalChecked"
                label="Driver Licence Renewal Checked"
                validation="required"
              />
              <FormulateInput
                type="date"
                name="passengerEndorsementRenewalChecked"
                label="Passenger Endorsement Renewal Checked"
                validation="required"
              />
            </div>
          </div>

          <div v-if="!importVehicle" class="items-center justify-between">
            <StyledSubmit :backgroundColor="theme.secondaryColor">
              <FormulateInput
                type="submit"
                label="Submit"
                input-class="btn btn-primary w-full"
              />
            </StyledSubmit>
          </div>
          <div v-else class="grid grid-cols-2 gap-4">
              <FormulateInput
                type="button"
                label="Close"
                input-class="btn btn-cancel w-full"
                @click.prevent="$emit('closeImportEdit')"
              />
              <StyledSubmit :backgroundColor="theme.secondaryColor">
                <FormulateInput
                  type="submit"
                  label="Save"
                  input-class="btn btn-primary w-full"
                />
              </StyledSubmit>
            </div>
        </FormulateForm>
      </div>
    </div>
</template>

<script>
import moment from 'moment'
import schema from './new-vehicle-schema'; //Using the create driver schema for now
import { formatDate } from "@/_helper";
import _ from 'lodash'
import { VEHICLE_CLASS } from '@/_helper/constants'
import { mapGetters } from 'vuex'
import {StyledSubmit} from '@/styled-components'

export default {
  name: 'Edit-Vehicle-Form',
  components: {StyledSubmit}, 
  props: {
    msg: String,
    detailsId:{
      type: String,
      default: '',
    },
    importVehicle: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      tsls: [],
      showTlsExtraFields: false,
      loading: false,
      error:false,
      errorMessage: '',
      schema: schema,
      values: {},
      classSchema: [],
      typeSchema: [],
      businessUID: '',
      origVehicleClass: '',
      origVehicleType: '',
      frontCouplingSchema: [],
      rearCouplingSchema: [],
      brakeTypeSchema: [],
      wiringPlugVoltageSchema: [],
      powerCouplingSchema: [],
      suspensionSchema: [],
      commentSchema: [
        {
          type: 'textarea',
          name: 'lAETBSysSupplier',
          label: 'LSV, ABS, EBS, T-EBS Brake System Supplier (Optional)',
        },
        {
          type: 'textarea',
          name: 'tSTBCSRefNumber',
          label: 'Trailer SARN, Trailer Brake Control System Reference Number (Optional)',
        },
        {
          type: 'textarea',
          name: 'comments',
          label: 'Comments (Optional)',
        }
      ]
    };
  },
  async mounted() {
      this.businessUID = this.$store.getters[`account/me`].uid;
      const me = this.$store.getters[`account/me`];
      this.tsls = await this.$store.dispatch(
        `tsl/getTSLByEntity`, me?.businessId
      );
      this.entityId = me.business._id;
      this.loading = true

      if (!_.isEmpty(this.detailsId)) {
        const vehicleDetails = await this.$store.dispatch(`vehicle/getVehicle`, {id: this.detailsId});
        this.setValues(vehicleDetails);
      }
  },
  methods: {
    setValues(vehicleDetails) {
      this.values = this.parseVehicle(vehicleDetails)
      this.origVehicleClass = this.values.vehicleClass
      this.origVehicleType = this.values.vehicleType
      this.getVehicleClasses()
      this.getVehicleFrontCouplings()
      this.getVehicleRearCouplings()
      this.getPowerCouplings()
      this.getVehicleBrakeTypes()
      this.getVehicleBrakeFoundations()
      this.getVehicleWiringPlugVoltages()
      this.getSuspensions()
    },
    updateSchema() {
      if(
        this.classSchema
        && this.typeSchema
        && this.frontCouplingSchema
        && this.rearCouplingSchema
        && this.brakeTypeSchema
        && this.powerCouplingSchema
        && this.brakeFoundationSchema
        && this.wiringPlugVoltageSchema
        && this.suspensionSchema
        && this.commentSchema
      ) {
        this.schema = [
          ...schema,
          ...this.classSchema,
          ...this.typeSchema,
          ...this.frontCouplingSchema,
          ...this.rearCouplingSchema,
          ...this.powerCouplingSchema,
          ...this.brakeTypeSchema,
          ...this.brakeFoundationSchema,
          ...this.wiringPlugVoltageSchema,
          ...this.suspensionSchema,
          ...this.commentSchema,]
      }

      this.loading = false
    },
    async onSubmit() {
      this.loading = true
      const parsedVehicle = this.parseForSubmit(this.values)

      if (!this.importVehicle) {
        this.$store.dispatch(`vehicle/updateVehicle`, parsedVehicle).then(vehicle => {
          this.loading = false;
          if(vehicle && vehicle._id){
            this.$emit('closeRefresh');
            this.$store.commit('setDialogNotify',true)
            this.$store.commit('setMessageNotify',{state:'success',message:'Vehicle successfully updated.'})
          }
        }).catch(error => {
          this.loading = false;
          this.$store.commit('setDialogNotify',true)
          this.$store.commit('setMessageNotify',{state:'error',message:error.message})
        })
      } else {
        this.$emit('closeImportEdit', parsedVehicle)
      }
    },
    async getVehicleTypes() {
      let typeOptions = {}
      const vehicleTypes = await this.$store.dispatch(`vehicle/getActiveVehicleTypeSearch`, {vehicleClassId:this.values.vehicleClass });
      if(vehicleTypes){
          this.types = vehicleTypes
          _.orderBy(this.types, ['createdAt'],['asc']).forEach(type => {
              typeOptions[type._id] = `${type['name']}`
          });
      }
      this.typeSchema = [
        {
          name: 'vehicleType',
          type: 'select',
          label: 'Vehicle Type',
          options: {
            '': ' - Select - ',
            ...typeOptions
          },
          validation: 'required'
        }
      ]
      if(vehicleTypes){
        this.updateSchema()
      }
    },
    async getVehicleClasses() {
      let classOptions = {}
      const vehicleClasses = await this.$store.dispatch(`vehicle/getVehicleClass`)
      if(vehicleClasses) {
        //ordering should be control by the BE. This is a temporary fix to control the order for vehicle types
        let updateVehicleClasses = vehicleClasses.map((vehicleTypes) => {
          return _.assign(vehicleTypes, _.find(VEHICLE_CLASS, ['name', vehicleTypes.name]))
        })
        updateVehicleClasses =  _.sortBy(updateVehicleClasses, ['order'])
        updateVehicleClasses.forEach(vclass => {
            classOptions[vclass._id] = `${vclass['name']}`
        }); 
      }
      this.classSchema = [
        {
          component: 'div',
          class: 'custom-options',
          children: [
            {
              type: 'radio',
              name: 'vehicleClass',
              label: 'Vehicle Class',
              options: {
                ...classOptions
              },
              validation: 'required',
            },
          ]
        }
      ]

      if(this.values.vehicleClass) {
        this.getVehicleTypes()
      }
    },
    async getVehicleFrontCouplings() {
      let frontCouplingOptions = {}
      const frontCouplings = await this.$store.dispatch(`vehicle/getActiveFrontCouplingByUID`, this.businessUID)
      if(frontCouplings) {
        frontCouplings.forEach(vclass => {
            frontCouplingOptions[vclass._id] = `${vclass['name']}`
        });
      }
      this.frontCouplingSchema = [
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'checkbox',
              name: 'frontCouplings',
              label: 'Front Couplings (Optional)',
              options: {
                ...frontCouplingOptions
              },
            },
          ]
        }
      ]
    },
    async getVehicleRearCouplings() {
      let rearCouplingOptions = {}
      const rearCouplings = await this.$store.dispatch(`vehicle/getActiveRearCouplingByUID`, this.businessUID)
      if(rearCouplings) {
        rearCouplings.forEach(vclass => {
            rearCouplingOptions[vclass._id] = `${vclass['name']}`
        });
      }
      this.rearCouplingSchema = [
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'checkbox',
              name: 'rearCouplings',
              label: 'Rear Couplings (Optional)',
              options: {
                ...rearCouplingOptions
              },
            },
          ]
        }
      ]
    },
    async getPowerCouplings() {
      let powerCouplingOptions = {}
      const powerCouplings = await this.$store.dispatch(`vehicle/getActiveWiringPlugConnectorByUID`, this.businessUID)
      if(powerCouplings) {
        powerCouplings.forEach(vclass => {
            powerCouplingOptions[vclass._id] = `${vclass.name}`
        });
      }
      this.powerCouplingSchema = [
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'checkbox',
              name: 'powerCouplings',
              label: 'Power Couplings (Optional)',
              options: {
                ...powerCouplingOptions
              },
            },
          ]
        }
      ]
    },
    async getVehicleBrakeTypes() {
      let brakeTypeOptions = {}
      const brakeTypes = await this.$store.dispatch(`vehicle/getActiveBrakeTypeByUID`, this.businessUID)
      if(brakeTypes) {
        brakeTypes.forEach(vclass => {
            brakeTypeOptions[vclass._id] = `${vclass['name']}`
        });
      }
      this.brakeTypeSchema = [
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'radio',
              name: 'brakeType',
              label: 'Brake System Type (Optional)',
              options: {
                ...brakeTypeOptions
              }
            },
          ]
        }
      ]
    },
    async getVehicleBrakeFoundations() {
      let brakeFoundationOptions = {}
      const brakeFoundations = await this.$store.dispatch(`vehicle/getActiveBrakeFoundationByUID`, this.businessUID)
      if(brakeFoundations) {
        brakeFoundations.forEach(vclass => {
            brakeFoundationOptions[vclass._id] = `${vclass['name']}`
        });
      }
      this.brakeFoundationSchema = [
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'radio',
              name: 'brakeFoundation',
              label: 'Brake Foundation',
              options: {
                ...brakeFoundationOptions
              }
            },
          ]
        }
      ]
    },
    async getVehicleWiringPlugVoltages() {
      let wiringPlugVoltageOptions = {}
      const wiringPlugVoltages = await this.$store.dispatch(`vehicle/getActiveWiringPlugVoltageByUID`, this.businessUID)
      if(wiringPlugVoltages) {
        wiringPlugVoltages.forEach(vclass => {
            wiringPlugVoltageOptions[vclass._id] = `${vclass['name']}`
        });
      }
      this.wiringPlugVoltageSchema = [
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'radio',
              name: 'supplyVoltageToTrailer',
              label: 'Supply Voltage to Trailer',
              options: {
                ...wiringPlugVoltageOptions
              }
            },
          ]
        }
      ]
    },
    async getSuspensions() {
      let suspensionOptions = {}
      const suspensions = await this.$store.dispatch(`vehicle/getActiveSuspensionByUID`, this.businessUID)
      if(suspensions) {
        suspensions.forEach(suspension => {
          suspensionOptions[suspension._id] = `${suspension['name']}`
        })
      }
      this.suspensionSchema = [
        {
          component: 'div',
          class: 'custom-options checkbox',
          children: [
            {
              type: 'checkbox',
              name: 'suspensions',
              label: 'Suspension (Optional)',
              options: {
                ...suspensionOptions
              }
            }
          ]
        }
      ]
    },
    parseVehicle(vehicle) {
      return {
        vehiclePlate: vehicle.vehiclePlate,
        registrationExpiry: formatDate(
          vehicle.registrationExpiry,
          "yyyy-MM-DD"
        ),
        gvm: vehicle.GMV,
        vehicleClass: vehicle?.vehicleClass?._id || null,
        vehicleType: vehicle?.vehicleType?._id || null,
        driveVehicle: vehicle.driveVehicle.toString(),
        concreteAgitator: vehicle.concreteAgitator,
        livestockVehicle: vehicle.livestockVehicle,
        vin: vehicle.VIN,
        alias: vehicle.alias,
        frontCouplings: vehicle.frontCouplings.map(frontCoupling => { return frontCoupling._id }),
        rearCouplings: vehicle.rearCouplings.map(rearCoupling => { return rearCoupling._id }),
        powerCouplings: vehicle.powerCouplings.map(powerCoupling => { return powerCoupling._id }),
        brakeFoundation: vehicle.brakeFoundation?._id || null,
        brakeType: vehicle.brakeType?._id || null,
        supplyVoltageToTrailer: vehicle.supplyVoltageToTrailer?._id || null,
        lAETBSysSupplier: vehicle.lAETBSysSupplier,
        tSTBCSRefNumber: vehicle.tSTBCSRefNumber,
        comments: vehicle.comments,
        suspensions: vehicle.suspensions,
        source: vehicle.source,
        tslId: vehicle.tslId,
        tslSerialNumber: vehicle.tslSerialNumber,
        passengerEndorsementExpiry: moment(vehicle.passengerEndorsementExpiry).format('YYYY-MM-DD') || null,
        driverLicenceRenewalChecked: moment(vehicle.driverLicenceRenewalChecked).format('YYYY-MM-DD') || null,
        passengerEndorsementRenewalChecked: moment(vehicle.passengerEndorsementRenewalChecked).format('YYYY-MM-DD') || null,
      };
    },
    parseForSubmit(values){
      return{
        id: this.detailsId,
        vehiclePlate: values.vehiclePlate,
        registrationExpiry: values.registrationExpiry,
        GMV: parseFloat(values.gvm),
        vehicleClassId: values.vehicleClass,
        vehicleTypeId: values.vehicleType,
        driveVehicle: typeof values.driveVehicle === "boolean"? values.driveVehicle : values.driveVehicle ==='true',
        concreteAgitator: typeof values.concreteAgitator === "boolean"? values.concreteAgitator : values.concreteAgitator ==='true',
        livestockVehicle: typeof values.livestockVehicle === "boolean"? values.livestockVehicle : values.livestockVehicle ==='true',
        VIN: values.vin? values.vin : undefined,
        alias: values.alias? values.vin : undefined,
        frontCouplings: values.frontCouplings? values.frontCouplings : undefined,
        rearCouplings: values.rearCouplings? values.rearCouplings : undefined,
        powerCouplings: values.powerCouplings? values.powerCouplings : undefined,
        brakeFoundation: values.brakeFoundation? values.brakeFoundation : undefined,
        brakeType: values.brakeType? values.brakeType : undefined,
        supplyVoltageToTrailer: values.supplyVoltageToTrailer? values.supplyVoltageToTrailer : undefined,
        lAETBSysSupplier: values.lAETBSysSupplier,
        tSTBCSRefNumber: values.tSTBCSRefNumber,
        comments: values.comments,
        suspensions: values.suspensions,
        entityId: this.entityId,
        source: values.source,
        tslId: values.tslId,
        tslSerialNumber: values.tslSerialNumber,
        passengerEndorsementExpiry: values.passengerEndorsementExpiry ? values.passengerEndorsementExpiry : undefined,
        driverLicenceRenewalChecked: values.driverLicenceRenewalChecked ? values.driverLicenceRenewalChecked : undefined,
        passengerEndorsementRenewalChecked: values.passengerEndorsementRenewalChecked ? values.passengerEndorsementRenewalChecked : undefined,
      }
    }
  },
  watch: {
    'values.vehicleClass'() {
      if(this.values.vehicleClass !== this.origVehicleClass) {
        this.values.vehicleType = ''
      } else {
        this.values.vehicleType = this.origVehicleType
      }
      if(this.values.vehicleClass) {
        this.getVehicleTypes()
      }
    },
    'importVehicle'(newVal) {
      if (this.importVehicle) {
        if (newVal.invalidFields.includes('vehiclePlate')) {
          setTimeout(() => {
            const elForm = this.$refs.vehicleForm.$el;
            elForm.querySelector('[name="vehiclePlate"]').focus();
          }, 500)
        }

        this.setValues(newVal);
      }
    },
    'values.tslId'() {
      const tslId = typeof this.values.tslId == 'object' ? this.values.tslId._id : this.values.tslId;
      const tslData = _.find(this.tsls, tsl => tsl._id == tslId);
      this.showTlsExtraFields = tslData && (tslData.tslType == 'SPSL' || tslData.tslType == 'LPSL');
    },
  },
  computed: {
     ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
