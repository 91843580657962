import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class Investigation {
   createInvestigation(payload){
      return API.ACCOUNTS.post(`investigation`, payload)
   }

   updateInvestigation(payload){
      return API.ACCOUNTS.patch(`investigation/${payload.id}`, payload)
   }

   reOpenInvestigation(payload){
      return API.ACCOUNTS.patch(`investigation/re-open/${payload.id}`, payload)
   }
   
   finalise(payload){
      return API.ACCOUNTS.patch(`investigation/finalise/${payload.id}`, payload)
   }

   getInvestigationsByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
      let filter = '';
      if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
      query.filter = filter;
      if(query) query = '?'+ObjectToQueryString(query);
      return API.ACCOUNTS.get(`/investigation/find-by-businessId/${businessId}${query}`)
   }

   getInvestigationsByDateRange(payload){
      return API.ACCOUNTS.post('investigation/find-by-date-range', payload)
   }

   getInvestigation(id){
      return API.ACCOUNTS.get(`investigation/${id}`)
   }

   getByRegisterId(payload) {
      // registerId is incidentId, hazardId, defectId etc.
      let { registerId, pagination = { skip: 0, limit: 10 } } = payload;
      if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";
  
      return API.ACCOUNTS.get(
        `/investigation/register/${registerId}${pagination}`
      );
    }

   getCountByRegisterType(payload){
      const {type, startDate, endDate} = payload
      return API.ACCOUNTS.get(`investigation/register-type/${type}/count?startDate=${startDate}&endDate=${endDate}`)
   }

   getSummaryReport(payload) {
      return API.ACCOUNTS.post(`investigation/summary-report`, payload)
   }

   getChainOfActions(id) {
      return API.ACCOUNTS.get(`investigation/${id}/chain/actions`)
   }
}