<template>
  <div class="w-full">
    <Tabs clickEvent>
      <Tab title="Partner Services">
        <div class="flex flex-row-reverse container mb-5 mt-5">
          <button
            class="btn btn-primary"
            @click.prevent="showSubscribe()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Subscribe Service
          </button>
        </div>
        <div class="flex w-full items-center driver-info-container"></div>
      </Tab>
    </Tabs>
    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content === 'new'">
        <SubscribeMarketplaceItem
          @closeModal="closeModal"
        ></SubscribeMarketplaceItem>
      </div>
    </Modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Modal } from "@/components/commons";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import Tab from "@/components/commons/tab/Tab.vue";
import Tabs from "@/components/commons/tab/Tabs.vue";
import SubscribeMarketplaceItem from "@/components/forms/marketplace/subscribe-marketplace-item.vue";

export default {
  name: "Marketplace",
  components: {
    Tab,
    Tabs,
    Modal,
    SubscribeMarketplaceItem,
  },
  data() {
    return {
      isLoading: false,
      modal: {
        title: "",
        content: null,
        height: DEFAULT_MODAL_HEIGHT,
      },
      partnerItems: [],
      resellerItems: [],
      selectedItemID: "",
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {},
    showSubscribe() {
      this.modal.content = "new";
      this.modal.title = "New Service Subscription";
      this.$refs.modal.openModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
    toast(state, message) {
      this.isLoading = false;
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
