<template>
  <div
    class="flex items-center space-x-2 `formulate-input-element formulate-input-element--${context.class}`"
    :data-type="context.type"
  >
    <span
      class="toggle-wrapper"
      role="checkbox"
      @click="toggle"
      @keydown.space.prevent="toggle"
    >
      <span class="toggle-background" :class="backgroundStyles" />
      <span class="toggle-indicator" :style="indicatorStyles" />
    </span>
  </div>
</template>
<script>
export default {
  props: {
    control: {
      type: Object,
      default: () => {}
    },
    context: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    checked: false
  }),
  computed: {
    backgroundStyles() {
      return {
        'gold-mid': this.checked,
        'gray-lighter': !this.checked
      }
    },
    indicatorStyles() {
      return { transform: this.checked ? 'translateX(14px)' : 'translateX(0)' }
    }
  },
  methods: {
    toggle() {
      this.checked = !this.checked
      this.$emit('input', this.checked)
    }
  }
}
</script>
<style scoped>
.gold-mid {
  background-color: #ff9c41;
}

.gray-lighter {
  background-color: #c2c2c2;
}

.toggle-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 32px;
  height: 18px;
  border-radius: 9999px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  display: inline-block;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 2px;
  background-color: #ffffff;
  border-radius: 9999px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease;
}
</style>
